import apiService from '../../../../services/api.service';

export const getActivitiesData = async (projectId: number) => {
  try {
    const response = await apiService.get(
      `/projects/${projectId}/activities?limit=500&offset=0`
    );
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};
