import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Skeleton } from 'primereact/skeleton';
import { Rating } from 'primereact/rating';
import { InputTextarea } from 'primereact/inputtextarea';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { WidthProvider, Responsive } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { Accordion, AccordionTab } from 'primereact/accordion';

import PFDropdown from '../../shared/PFPrime/PFDropdown';
import PFInputText from '../../shared/PFPrime/PFInputText';
import { updateQuestionnaireStore } from '../../../redux/slices/questionnaire.slice';
import PFButton from '../../shared/PFPrime/PFButton';
import { getConvertedDate, UTCConvertor } from '../../../utils/date.helper';
import { checkPermission } from '../../../utils/Helpers';
import permissions from '../../../config/permissions';
import TableLoader from '../../shared/Loader/TableLoader';
import { useConfirmDialogContext } from '../../../contexts/ConfirmDialog';

import {
  addAnswers,
  deleteAnswers,
  getAnswers,
  updateAnswers,
} from './questionnaireService';
import { QUESTION_TYPES } from './questionnaire.contants';

const ResponsiveGridLayout = WidthProvider(Responsive);

const AnswerComponent = ({
  module,
  targetId,
  isRenderConfirmationPopup = true,
  checkListName = '',
  projectDetailsData,
  action,
  toast,
}) => {
  const { showConfirmationDialog } = useConfirmDialogContext();
  if (
    !module?.selectedModule?.module_id ||
    !module?.selectedModule?.module_name ||
    !targetId ||
    !module.isShowCheckListPopup
  ) {
    return <></>;
  }
  const [tableData, setTableData] = useState({});
  const [activeIndex, setActiveIndex] = useState(0);
  const prevTableDataRef = useRef(tableData);

  const { isAnswerLoading, refreshList, isNewAnswer } = useSelector(
    state => state.questionnaire
  );
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      answers: [],
      label: '',
    },
    validationSchema: Yup.object().shape({
      label: Yup.string().trim().required(),
    }),
    onSubmit: async values => {
      let isError = false;
      try {
        const answerBody = [];
        values.answers.forEach(eachAnswer => {
          if (
            (![null, undefined, ''].includes(eachAnswer?.answer) ||
              [QUESTION_TYPES.table.id, QUESTION_TYPES.card.id].includes(
                eachAnswer.question_type
              )) &&
            !isDisable(eachAnswer?.is_editable)
          ) {
            if (
              [
                QUESTION_TYPES.rating10.id,
                QUESTION_TYPES.rating5.id,
                QUESTION_TYPES.text.id,
              ].includes(eachAnswer.question_type)
            ) {
              answerBody.push({
                question_id: eachAnswer.question_id,
                answer: eachAnswer.answer || null,
              });
            } else if (eachAnswer.question_type === QUESTION_TYPES.list.id) {
              answerBody.push({
                question_id: eachAnswer.question_id,
                answer:
                  typeof eachAnswer.answer === 'object'
                    ? eachAnswer.answer?.option_id
                    : eachAnswer.answer || null,
              });
            } else if (eachAnswer.question_type === QUESTION_TYPES.table.id) {
              const questionId = eachAnswer.question_id;
              const tableAnswer = formatTableAnswerForAPI(
                tableData,
                questionId,
                eachAnswer
              );
              if (
                Array.isArray(tableAnswer?.answer) &&
                tableAnswer.answer.length
              ) {
                answerBody.push(tableAnswer);
              }
            } else if (eachAnswer.question_type === QUESTION_TYPES.card.id) {
              // New condition for card type: convert answer into array of {question_format_id, answer}
              const cardAnswer =
                eachAnswer.answer && Array.isArray(eachAnswer.answer)
                  ? eachAnswer.answer.map(field => ({
                      question_format_id: field.question_format_id,
                      answer: field.answer,
                    }))
                  : [];
              if (Array.isArray(cardAnswer) && cardAnswer.length) {
                answerBody.push({
                  question_id: eachAnswer.question_id,
                  answer: cardAnswer,
                });
              }
            }
          }
        });
        if (!Array.isArray(answerBody) || !answerBody?.length) {
          if (toast?.current?.show) {
            toast.current.show({
              severity: 'error',
              summary: 'Error',
              detail: 'No answer provided',
              life: 3000,
            });
          }
          isError = true;
          return;
        }
        dispatch(updateQuestionnaireStore({ isAnswerLoading: true }));
        const functionName = isNewAnswer ? addAnswers : updateAnswers;
        const response = await functionName(
          module.selectedModule.module_id,
          targetId,
          {
            answers: answerBody,
            label: values.label,
            version: !isNewAnswer ? module?.selectedModule?.version : undefined,
          }
        );

        if (response?.status && response?.data) {
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: response?.message,
            life: 3000,
          });
          await dispatch(
            updateQuestionnaireStore({
              refetchInspectionDetails: true,
              isNewAnswer: false,
            })
          );
          formik.setFieldValue('answers', response?.data);
          action();
        }
      } catch (ex) {
        if (toast?.current?.show) {
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: ex?.response?.data?.message || 'something went wrong',
            life: 3000,
          });
        }
        dispatch(
          updateQuestionnaireStore({
            isAnswerLoading: false,
          })
        );
      } finally {
        if (!isError) {
          dispatch(
            updateQuestionnaireStore({
              refreshList: !refreshList,
            })
          );
        }
      }
    },
    enableReinitialize: true,
  });

  const tableValidation = () => {
    for (const questionKey in tableData) {
      if (Object.hasOwnProperty.call(tableData, questionKey)) {
        const question = tableData[questionKey];
        const hasCompleteRow = question.answer.some(answerGroup =>
          answerGroup.every(
            field =>
              field.answer !== null &&
              field.answer !== undefined &&
              field.answer !== ''
          )
        );
        if (!hasCompleteRow) {
          return false;
        }
      }
    }
    return true;
  };

  const answerQuestionValidation = () => {
    let attempt = [];

    formik?.values?.answers.forEach(field => {
      if (
        field?.question_type === QUESTION_TYPES.text.id ||
        field?.question_type === QUESTION_TYPES.rating5.id ||
        field?.question_type === QUESTION_TYPES.rating10.id ||
        field?.question_type === QUESTION_TYPES.list.id
      ) {
        if (
          field.answer === null ||
          field.answer === undefined ||
          field.answer === ''
        ) {
          attempt.push(false);
        } else {
          attempt.push(true);
        }
      } else if (field?.question_type === QUESTION_TYPES.card.id) {
        const fields =
          field?.answer?.map(ans => {
            return (
              ans.answer !== null &&
              ans.answer !== undefined &&
              ans.answer !== ''
            );
          }) || [];

        if (fields.filter(filled => !filled).length) {
          attempt.push(false);
        } else if (fields.length) {
          attempt.push(true);
        } else {
          attempt.push(false);
        }
      } else if (field?.question_type === QUESTION_TYPES.table.id) {
        attempt.push(tableValidation());
      }
    });
    const validAttempts = attempt.filter(result => result);
    return validAttempts.length ? true : false;
  };

  const isDisable = isEditable => {
    if (isNewAnswer) return false;
    if (isEditable === true) return false;
    if (isEditable === false) return true;
    return false;
  };
  const handleFormValueChange = (value, index) => {
    const { answers = [] } = formik.values;
    if (
      Array.isArray(answers) &&
      answers.length &&
      answers[index] &&
      !isDisable(answers[index]?.is_editable)
    ) {
      const answersCopy = [...answers];
      const answeredQuestion = { ...answersCopy[index] };
      answeredQuestion.answer = value;
      answersCopy[index] = answeredQuestion;
      formik.setFieldValue('answers', answersCopy);
    }
  };
  const clearAnswers = async () => {
    dispatch(updateQuestionnaireStore({ isAnswerLoading: true }));
    const clearData = formik.values.answers.map(obj => {
      if (obj.question_type === QUESTION_TYPES.card.id) {
        return {
          ...obj,
          format_field_types: obj.format_field_types.map(sub => ({
            ...sub,
            answer: '',
          })),
        };
      } else if (obj.question_type === QUESTION_TYPES.table.id) {
        return obj;
      } else if (
        obj.question_type === QUESTION_TYPES.list.id ||
        obj.question_type === QUESTION_TYPES.text.id ||
        obj.question_type === QUESTION_TYPES.rating5.id ||
        obj.question_type === QUESTION_TYPES.rating10.id
      ) {
        return {
          ...obj,
          answer: '',
        };
      } else {
        return obj;
      }
    });

    const isEmptyObject = obj => Object.keys(obj).length === 0;
    if (!isEmptyObject(tableData)) {
      for (const questionKey in tableData) {
        setTableData(prev => {
          const updatedSection = { ...prev[questionKey] };

          updatedSection.answer = updatedSection.answer.map(row =>
            row.map(item => ({
              ...item,
              answer: '', // Reset answer value
            }))
          );
          return {
            ...prev,
            [questionKey]: updatedSection,
          };
        });
      }
    }

    formik.setFieldValue('answers', clearData);

    dispatch(
      updateQuestionnaireStore({
        isAnswerLoading: false,
      })
    );
  };
  const resetAnswers = async () => {
    try {
      await dispatch(updateQuestionnaireStore({ isAnswerLoading: true }));
      const response = await deleteAnswers(
        module.selectedModule.module_id,
        targetId,
        module.selectedModule.version
      );
      if (response?.status) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: response?.message || 'Answers refreshed successfully',
          life: 3000,
        });
        action();
      }
    } catch (ex) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: ex?.response?.data?.message || 'something went wrong',
        life: 3000,
      });
    } finally {
      dispatch(
        updateQuestionnaireStore({
          isAnswerLoading: false,
        })
      );
    }
  };
  const callGetAnswers = async () => {
    dispatch(updateQuestionnaireStore({ isAnswerLoading: true }));
    try {
      const response = await getAnswers(
        module.selectedModule.module_id,
        targetId,
        module.selectedModule?.version ? false : true,
        module.selectedModule?.version
      );
      if (response?.status && response?.data) {
        if (response?.data?.result?.[0]) {
          formik.setFieldValue(
            'label',
            response?.data?.result?.[0]?.label
              ? response?.data?.result?.[0]?.label
              : checkListName
          );
        }
        formik.setFieldValue('answers', response?.data?.result);

        const initialTableAnswerData = convertTableAnswerToResponseFormat(
          response?.data?.result && response?.data?.result
        );
        prevTableDataRef.current = JSON.parse(
          JSON.stringify(initialTableAnswerData)
        );
        setTableData(initialTableAnswerData);
        await dispatch(
          updateQuestionnaireStore({
            isNewAnswer: !!response.data?.isNew,
            refetchInspectionDetails: true,
          })
        );
      }
    } catch (ex) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: ex?.response?.data?.message || 'something went wrong',
        life: 3000,
      });
    } finally {
      dispatch(updateQuestionnaireStore({ isAnswerLoading: false }));
    }
  };

  useEffect(() => {
    callGetAnswers();
  }, [module.selectedModule.module_id, refreshList]);

  const getCardFields = eachAnswer => {
    if (eachAnswer.question_type !== QUESTION_TYPES.card.id) return [];
    if (
      eachAnswer.answer &&
      Array.isArray(eachAnswer.answer) &&
      eachAnswer.answer.length
    ) {
      return eachAnswer.answer;
    }
    if (
      eachAnswer.format_field_types &&
      Array.isArray(eachAnswer.format_field_types)
    ) {
      return eachAnswer.format_field_types.map((field, index) => ({
        ...field,
        answer: field.answer || '',
        config: {
          x: field.config?.x ?? index % 6,
          y: field.config?.y ?? Math.floor(index / 6),
          w: field.config?.w ?? 3,
          h: field.config?.h ?? 2,
        },
      }));
    }
    return [];
  };

  // Generates a layout array for react-grid-layout from card fields.
  const generateCardLayout = cardFields => {
    return cardFields.map(field => ({
      i: String(field.question_format_id),
      x: field.config.x,
      y: field.config.y,
      w: field.config.w,
      h: field.config.h,
    }));
  };

  // Called when the layout changes; updates the card fields in Formik state.
  const onCardLayoutChange = (
    cardFields,
    currentLayout,
    eachAnswer,
    answerIndex
  ) => {
    const updatedFields = cardFields.map(field => {
      const layoutItem = currentLayout.find(
        item => String(item.i) === String(field.question_format_id)
      );
      return {
        ...field,
        config: {
          ...field.config,
          x: layoutItem?.x,
          y: layoutItem?.y,
          w: layoutItem?.w,
          h: layoutItem?.h,
        },
      };
    });
    const answersCopy = [...formik.values.answers];
    answersCopy[answerIndex] = {
      ...answersCopy[answerIndex],
      answer: updatedFields,
    };
    formik.setFieldValue('answers', answersCopy);
  };

  // Sort data according to config position
  const sortTableAnswerFields = field => {
    const sortedColumns = field
      .slice()
      .sort(
        (a, b) =>
          (a?.position || a?.config?.position || 0) -
          (b?.position || b?.config?.position || 0)
      );

    return sortedColumns || [];
  };

  const getTableAnswerColumns = fields => {
    if (!Array.isArray(fields) || !fields.length) return [];
    // For update mode, merge columns across rows by unique header (format_question_label)
    if (Array.isArray(fields[0])) {
      return sortTableAnswerFields(fields[0]);
    }
    // For create mode, simply sort the fields
    return sortTableAnswerFields(fields);
  };

  const transformRowsToObjects = rows => {
    if (!rows || !Array.isArray(rows)) return [];
    const finalRows = rows.map((row, rowIndex) => {
      const rowObj = { _rowId: rowIndex }; // assign a unique key for each row
      row.forEach(field => {
        rowObj.row_number = field.row_number;
        rowObj[
          `row_${field.row_number}_position_${field?.position || field?.config?.position}`
        ] = field.answer;
        rowObj[
          `options_row_${field.row_number}_position_${field?.position || field?.config?.position}`
        ] =
          Array.isArray(field.options) && field.options.length > 0
            ? field.options
            : [];
        rowObj[`is_deleted`] = field?.is_deleted ? true : false;
      });
      return rowObj;
    });
    return finalRows;
  };

  // Get table headings
  const getFieldLabel = fieldId => {
    return QUESTION_TYPES?.[fieldId]?.label || fieldId;
  };

  const convertTableAnswerToResponseFormat = apiData => {
    const formattedData = {};

    apiData?.forEach(question => {
      if (question.question_type === QUESTION_TYPES.table.id) {
        const parentId = question.question_id;
        let answerData = [];

        if (
          Array.isArray(question.format_field_types) &&
          question.format_field_types.length
        ) {
          // Check if update mode (array of arrays)
          if (Array.isArray(question.format_field_types[0])) {
            answerData = question.format_field_types.map(row =>
              row.map(field => ({
                question_format_id: field.question_format_id,
                answer: field.answer || '',
                format_question_label: field.format_question_label,
                config: field.config,
                position: field.position || field?.config?.position,
                options: field.options,
                row_number: field.row_number,
                format_question_type: field.format_question_type,
              }))
            );
          } else {
            // Create mode: wrap array of objects into one row
            answerData = [
              question.format_field_types.map(field => ({
                question_format_id: field.question_format_id,
                answer: field.answer || '',
                format_question_label: field.format_question_label,
                config: field.config,
                position: field.position || field?.config?.position,
                options: field.options,
                row_number: field.row_number,
                format_question_type: field.format_question_type,
              })),
            ];
          }
        } else {
          answerData = [[]];
        }

        formattedData[parentId] = {
          question_id: parentId,
          answer: answerData,
          // Also keep the original fields for column definitions
          format_field_types: question.format_field_types,
        };
      }
    });
    return formattedData;
  };

  const formatTableAnswerForAPI = (tableData, questionId, eachAnswer) => {
    // Determine the key for tableData
    const tableKey =
      eachAnswer?.question_id in tableData
        ? eachAnswer.question_id
        : questionId;

    // If there is no entry for this question, return an empty answer array.
    if (!tableData[tableKey]) {
      return { question_id: tableKey, answer: [] };
    }

    // Filter out any "empty" rows.
    const filteredRows = tableData[tableKey].answer
      .map(row =>
        row.filter(
          field => !(field.is_new === true && field.is_deleted === true)
        )
      )
      .filter(
        row =>
          Array.isArray(row) &&
          row.length > 0 &&
          row.some(field => field?.answer)
      )
      .map((row, index) =>
        row.map(field => ({
          question_format_id: field.question_format_id,
          answer: field.answer,
          is_new: field.is_new ? true : undefined,
          is_deleted: field.is_deleted ? true : undefined,
          row_number: index + 1,
        }))
      );

    return {
      question_id: eachAnswer.question_id ? eachAnswer.question_id : tableKey,
      answer: filteredRows,
    };
  };

  // Handles changes in each field
  const handleTableAnswerRowEdit = (
    questionId,
    rowIndex,
    columnId,
    colPosition,
    value
  ) => {
    setTableData(prevData => {
      const updatedData = { ...prevData };
      if (!updatedData[questionId].answer[rowIndex]) {
        updatedData[questionId].answer[rowIndex] = [];
      }
      const fieldIndex = updatedData[questionId].answer[rowIndex].findIndex(
        item => (item?.position || item?.config?.position) === colPosition
      );
      if (fieldIndex > -1) {
        updatedData[questionId].answer[rowIndex][fieldIndex].answer = value;
      } else {
        updatedData[questionId].answer[rowIndex].push({
          question_format_id: columnId,
          answer: value,
          config: { position: colPosition },
          position: colPosition,
        });
      }
      return updatedData;
    });
  };

  // Add new row below the selected row
  const handleTableAnswerAddRow = (questionId, rowIndex) => {
    setTableData(prevData => {
      const updatedData = { ...prevData };

      // Get the reference row (either the current one or the first one)
      const referenceRow =
        updatedData[questionId]?.answer[rowIndex] ||
        updatedData[questionId]?.answer[0] ||
        [];

      // Create a new row with structure and options
      const newRow = referenceRow.map(field => {
        const newField = {
          ...field,
          answer: '', // Reset the answer
          options: field.options ? [...field.options] : [], // Preserve dropdown options
          row_number: updatedData[questionId].answer.length + 1, // Increment row number,
        };
        // If the module is not new, mark the field as new.
        if (!module.isNewRecord) {
          newField.is_new = true;
          // Remove is_deleted if its value is undefined.
          if (newField.is_deleted === undefined) {
            delete newField.is_deleted;
          }
        }
        return newField;
      });

      // Insert the new row below the current row
      updatedData[questionId].answer.splice(rowIndex + 1, 0, newRow);
      let originalRows = updatedData[questionId]?.answer || [];

      updatedData[questionId].answer = originalRows.map((row, index) =>
        row.map(field => ({
          ...field,
          row_number: index + 1, // Reset row numbers sequentially
        }))
      );

      return updatedData;
    });
  };

  // Copy row and insert below
  const handleTableAnswerCopyRow = (questionId, rowIndex) => {
    setTableData(prevData => {
      const updatedData = { ...prevData };
      const copiedRow = JSON.parse(
        JSON.stringify(prevData[questionId].answer[rowIndex] || [])
      );
      const newRow = copiedRow.map(field => {
        const newField = { ...field };
        if (!module.isNewRecord) {
          newField.is_new = true;
          if (newField.is_deleted === undefined) {
            delete newField.is_deleted;
          }
        }
        return newField;
      });

      updatedData[questionId].answer.splice(rowIndex + 1, 0, newRow);
      let originalRows = updatedData[questionId]?.answer || [];
      // Reset row numbers
      updatedData[questionId].answer = originalRows.map((row, index) =>
        row.map(field => ({
          ...field,
          row_number: index + 1, // Reset row numbers sequentially
        }))
      );
      return updatedData;
    });
  };

  // Remove selected row
  const handleTableAnswerRemoveRow = (tableKey, rowIndex) => {
    setTableData(prevData => {
      const updatedTable = { ...prevData };

      if (!module.isNewRecord) {
        if (
          updatedTable[tableKey] &&
          removeOnDeletedRow(updatedTable[tableKey].answer)?.length > 1 &&
          updatedTable[tableKey].answer?.length > 1
        ) {
          const copiedRow = JSON.parse(
            JSON.stringify(prevData[tableKey].answer[rowIndex] || [])
          );

          const newRow = copiedRow.map(field => ({
            ...field,
            is_deleted: true,
          }));

          updatedTable[tableKey].answer[rowIndex] = newRow;

          const newAndDeleteRow = updatedTable[tableKey].answer[rowIndex].every(
            field => field.is_deleted === true && field.is_new === true
          );

          if (newAndDeleteRow) {
            updatedTable[tableKey].answer = updatedTable[
              tableKey
            ].answer.filter((_, index) => index !== rowIndex);
          }
        }
      } else {
        if (
          updatedTable[tableKey] &&
          updatedTable[tableKey].answer?.length > 1
        ) {
          updatedTable[tableKey].answer = updatedTable[tableKey].answer.filter(
            (_, index) => index !== rowIndex
          );
        }
      }
      // Reset row numbers
      let originalRows = updatedTable[tableKey]?.answer || [];
      updatedTable[tableKey].answer = originalRows.map((row, index) =>
        row.map(field => ({
          ...field,
          row_number: index + 1, // Reset row numbers sequentially
        }))
      );

      return updatedTable;
    });
  };

  const RenderModificationDetail = ({ answer }) => {
    let name = null,
      date = null;
    if (!isNewAnswer) {
      if (answer?.modified_by_user) {
        name = `${answer?.modified_by_user?.first_name} ${answer?.modified_by_user?.last_name}`;
        date = answer?.modified_at;
      } else if (answer?.created_by_user) {
        name = `${answer?.created_by_user?.first_name} ${answer?.created_by_user?.last_name}`;
        date = answer?.created_at;
      }
    }
    return (
      name && (
        <div className="flex flex-column md:flex-row gap-1 md:gap-5 justify-content-end mt-2 mb-1 mr-2">
          <div className="flex gap-2 px-2">
            {isAnswerLoading ? (
              <Skeleton width="5rem" height="1rem" />
            ) : (
              <>
                <div className="font-semibold">
                  Modified By: <span className="capitalize">{name}</span>
                </div>
              </>
            )}
          </div>
          {date && (
            <div className="flex gap-2 px-2">
              {isAnswerLoading ? (
                <Skeleton width="5rem" height="1rem" />
              ) : (
                <>
                  <div className="font-semibold">
                    Modified At: <span>{getConvertedDate(date)}</span>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      )
    );
  };

  const confirmClear = () => {
    showConfirmationDialog({
      message: 'Please confirm if you would like to clear all answers?',
      header: 'Clear Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: clearAnswers,
      rejectLabel: 'Cancel',
      acceptLabel: 'Clear',
      acceptIcon: 'pi pi-eraser',
    });
  };

  const confirmReset = () => {
    showConfirmationDialog({
      message:
        'This action will delete all your responses against this checklist, would you still like to delete?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: resetAnswers,
      rejectLabel: 'Cancel',
      acceptLabel: 'Delete',
      acceptIcon: 'pi pi-trash',
    });
  };
  const ActionComponent = () => (
    <div className="flex w-full justify-content-end mt-2 mb-2">
      {/* <div className="flex">
    <h4 className="text-xl font-semibold font-semibold">
      Module: {module?.selectedModule?.module_name}
    </h4>
  </div> */}
      <div className="flex align-items-center gap-2">
        <PFButton
          onClick={confirmClear}
          size="small"
          label="Clear"
          outlined="outlined"
          iconPos="left"
          disabled={
            isAnswerLoading ||
            !Array.isArray(formik.values?.answers) ||
            !formik.values.answers.length
          }
        />

        {checkPermission(permissions.questionnaire.projectDeleteAnswer) &&
          module.selectedModule?.version && (
            <PFButton
              onClick={confirmReset}
              size="small"
              label="Delete"
              outlined="outlined"
              className="p-button-danger"
              iconPos="left"
              disabled={isAnswerLoading}
            />
          )}

        <PFButton
          label="Submit"
          size="small"
          onClick={formik.handleSubmit}
          disabled={
            isAnswerLoading ||
            !Array.isArray(formik.values?.answers) ||
            !formik.values.answers.length ||
            !formik.isValid ||
            !answerQuestionValidation()
          }
        />
      </div>
    </div>
  );

  const rowClass = product => {
    if (Array.isArray(product)) {
      return product[0]?.is_deleted ? 'hidden' : '';
    } else {
      return product?.is_deleted ? 'hidden' : '';
    }
  };

  const removeOnDeletedRow = rows => {
    if (!rows || !Array.isArray(rows)) return [];

    let validRows = rows;

    if (rows.length > 1) {
      validRows = rows.filter(row => {
        // Filter out rows that are entirely marked as deleted.
        return !row.every(field => field.is_deleted === true);
      });
    }

    // Ensure at least one row is returned even if all rows were filtered out.
    if (validRows.length === 0 && rows.length > 0) {
      validRows = [rows[0]];
    }

    return validRows;
  };
  return (
    <>
      <div className="flex flex-column w-full relative mb-5">
        {isRenderConfirmationPopup && <ConfirmDialog />}

        {module?.selectedModule?.module_name && (
          <>
            {projectDetailsData && (
              <div className="w-full my-2">
                <Accordion
                  activeIndex={activeIndex}
                  onTabChange={e => {
                    setActiveIndex(e.index);
                  }}
                >
                  <AccordionTab
                    header="Project Details"
                    headerClassName="font-bold"
                    contentClassName="project-details-data"
                  >
                    <div className="m-0 grid">
                      {projectDetailsData?.source_system?.source_name && (
                        <div className="col-12 md:col-4">
                          <p className="m-0">
                            <span className="font-bold">Source: </span>
                            {projectDetailsData?.source_system?.source_name}
                          </p>
                        </div>
                      )}
                      {projectDetailsData?.project_number && (
                        <div className="col-12 md:col-4">
                          <p className="m-0">
                            <span className="font-bold">Project#: </span>
                            {projectDetailsData?.project_number}
                          </p>
                        </div>
                      )}
                      {projectDetailsData?.po_number && (
                        <div className="col-12 md:col-4">
                          <p className="m-0">
                            <span className="font-bold">PO#: </span>
                            {projectDetailsData?.po_number}
                          </p>
                        </div>
                      )}
                      {projectDetailsData?.project_type?.project_type && (
                        <div className="col-12 md:col-4">
                          <p className="m-0">
                            <span className="font-bold">Type: </span>
                            {projectDetailsData?.project_type?.project_type}
                          </p>
                        </div>
                      )}
                      {projectDetailsData?.project_category?.category && (
                        <div className="col-12 md:col-4">
                          <p className="m-0">
                            <span className="font-bold">Category: </span>
                            {projectDetailsData?.project_category?.category}
                          </p>
                        </div>
                      )}
                      {(projectDetailsData?.customer?.first_name ||
                        projectDetailsData?.customer?.last_name) && (
                        <div className="col-12 md:col-4">
                          <p className="m-0">
                            <span className="font-bold">Client Name: </span>
                            {(projectDetailsData?.customer?.first_name || '') +
                              ' ' +
                              (projectDetailsData?.customer?.last_name || '')}
                          </p>
                        </div>
                      )}
                      {projectDetailsData?.status?.status && (
                        <div className="col-12 md:col-4">
                          <p className="m-0">
                            <span className="font-bold">Status: </span>
                            {projectDetailsData?.status?.status}
                          </p>
                        </div>
                      )}
                      {projectDetailsData?.modified_at && (
                        <div className="col-12 md:col-4">
                          <p className="m-0">
                            <span className="font-bold">Follow-Up Date: </span>
                            {UTCConvertor(
                              projectDetailsData?.modified_at,
                              false
                            )}
                          </p>
                        </div>
                      )}
                      {projectDetailsData?.created_at && (
                        <div className="col-12 md:col-4">
                          <p className="m-0">
                            <span className="font-bold">Completion Date: </span>
                            {UTCConvertor(
                              projectDetailsData?.created_at,
                              false
                            )}
                          </p>
                        </div>
                      )}
                    </div>
                  </AccordionTab>
                </Accordion>
              </div>
            )}
            <div className=" flex-column w-full my-2">
              <span className="p-float-label">
                <PFInputText
                  i="pi pi-search"
                  type="text"
                  value={formik.values.label}
                  onChange={e => formik?.setFieldValue('label', e.target.value)}
                  disabled={
                    !formik.values.answers || !isNewAnswer || isAnswerLoading
                  }
                />
                <label htmlFor="clientFirstName">
                  Checklist Name <span style={{ color: 'red' }}>*</span>
                </label>
              </span>
            </div>
          </>
        )}
        {isAnswerLoading &&
        (!Array.isArray(formik.values?.answers) ||
          !formik.values.answers.length) ? (
          <div className="p-card flex flex-column mb-4 p-2">
            <div className="flex w-full">
              <Skeleton className="mb-2" width="30rem" height="3rem" />
            </div>
            <div className="flex flex-column">
              <div className="flex w-full">
                <Skeleton className="mb-2" height="6rem" />
              </div>
            </div>
          </div>
        ) : Array.isArray(formik.values?.answers) &&
          formik.values.answers.length > 0 ? (
          <>
            {formik.values.answers.map((eachAnswer, index) => {
              const isTable = [QUESTION_TYPES.table.id].includes(
                eachAnswer?.question_type
              );
              const cardClass = isTable
                ? 'pl-0 pr-0 pt-0 '
                : 'pl-3 pr-3 pt-1 pb-3';
              return (
                <div
                  className={`p-card flex flex-column mb-4 border-600 ${cardClass}`}
                  key={index}
                >
                  <div className="flex flex-column gap-2">
                    {isAnswerLoading ? (
                      <Skeleton className="mb-2" height="6rem" />
                    ) : (
                      !isTable && (
                        <h3 className="font-semibold w-10 p-1">
                          {index + 1}. {eachAnswer?.question}
                        </h3>
                      )
                    )}

                    {eachAnswer?.question_type ===
                      QUESTION_TYPES.rating10.id && (
                      <div className="flex w-full">
                        {isAnswerLoading ? (
                          <Skeleton className="mb-2" height="6rem" />
                        ) : (
                          <Rating
                            value={formik.values?.answers?.[index]?.answer}
                            onChange={e =>
                              handleFormValueChange(e.value, index)
                            }
                            cancel={false}
                            stars={10}
                            disabled={isDisable(
                              formik.values?.answers?.[index]?.is_editable
                            )}
                          />
                        )}
                      </div>
                    )}
                    {eachAnswer?.question_type ===
                      QUESTION_TYPES.rating5.id && (
                      <div className="flex w-full">
                        {isAnswerLoading ? (
                          <Skeleton className="mb-2" height="6rem" />
                        ) : (
                          <Rating
                            value={formik.values?.answers?.[index]?.answer}
                            onChange={e =>
                              handleFormValueChange(e.value, index)
                            }
                            cancel={false}
                            disabled={isDisable(
                              formik.values?.answers?.[index]?.is_editable
                            )}
                          />
                        )}
                      </div>
                    )}
                    {eachAnswer?.question_type === QUESTION_TYPES.list.id &&
                      Array.isArray(eachAnswer?.options) &&
                      eachAnswer.options.length > 0 && (
                        <div className="flex w-full">
                          {isAnswerLoading ? (
                            <Skeleton className="mb-2" height="6rem" />
                          ) : (
                            <span className="p-float-label w-full md:w-4">
                              <PFDropdown
                                value={
                                  typeof formik.values?.answers?.[index]
                                    ?.answer === 'object' &&
                                  formik.values?.answers?.[index]?.answer
                                    ?.option_id
                                    ? formik.values?.answers?.[index]?.answer
                                    : formik.values?.answers?.[index]?.answer
                                      ? eachAnswer?.options.find(
                                          e =>
                                            e.option_id ==
                                            formik.values?.answers?.[index]
                                              ?.answer
                                        )
                                      : ''
                                }
                                onChange={(name, value) =>
                                  handleFormValueChange(value, index)
                                }
                                options={eachAnswer?.options}
                                disabled={isDisable(
                                  formik.values?.answers?.[index]?.is_editable
                                )}
                                optionLabel="label"
                                placeholder="Select an option"
                                inputId={`list_${index}`}
                                name={`list_${index}`}
                                className="w-full"
                                appendTo="self"
                              />
                              <label htmlFor={`list_${index}`}>Select</label>
                            </span>
                          )}
                        </div>
                      )}
                    {eachAnswer?.question_type === QUESTION_TYPES.text.id && (
                      <div className="flex w-full">
                        {isAnswerLoading ? (
                          <Skeleton className="mb-2" height="6rem" />
                        ) : (
                          <InputTextarea
                            id={`text_${index}`}
                            name={`text_${index}`}
                            rows={5}
                            value={formik.values?.answers?.[index]?.answer}
                            onChange={e =>
                              handleFormValueChange(e?.target?.value, index)
                            }
                            className="w-full"
                            disabled={isDisable(
                              formik.values?.answers?.[index]?.is_editable
                            )}
                          />
                        )}
                      </div>
                    )}
                    {isTable && (
                      <div className="w-full">
                        {isAnswerLoading ? (
                          <TableLoader columnCount={4} noOfRow={5} />
                        ) : (
                          <DataTable
                            value={transformRowsToObjects(
                              tableData[eachAnswer?.question_id]?.answer
                            )}
                            header={
                              <h3 className="p-0">
                                {index + 1}. {eachAnswer?.question}
                              </h3>
                            }
                            scrollable
                            scrollHeight="400px"
                            stripedRows
                            tableStyle={{ tableLayout: 'fixed', width: '100%' }}
                            showGridlines
                            rowClassName={rowClass}
                          >
                            {!isDisable(eachAnswer?.is_editable) && (
                              <Column
                                header="Actions"
                                style={{ width: '150px', textAlign: 'center' }}
                                body={(rowData, { rowIndex }) => {
                                  const tableKey = eachAnswer.question_id;
                                  return (
                                    <div className="flex gap-2 justify-content-center">
                                      <i
                                        className="pi pi-copy text-xl cursor-pointer text-blue-500"
                                        onClick={() =>
                                          handleTableAnswerCopyRow(
                                            tableKey,
                                            rowIndex
                                          )
                                        }
                                      />
                                      <i
                                        className="pi pi-plus text-xl cursor-pointer text-green-500"
                                        onClick={() =>
                                          handleTableAnswerAddRow(
                                            tableKey,
                                            rowIndex
                                          )
                                        }
                                      />
                                      <i
                                        className="pi pi-trash text-xl cursor-pointer text-red-500"
                                        onClick={() =>
                                          handleTableAnswerRemoveRow(
                                            tableKey,
                                            rowIndex
                                          )
                                        }
                                      />
                                    </div>
                                  );
                                }}
                              />
                            )}
                            {getTableAnswerColumns(
                              eachAnswer?.format_field_types
                            ).map(column => (
                              <Column
                                key={column.question_format_id}
                                field={`${column?.position}`}
                                header={column.format_question_label}
                                style={{
                                  width: column?.config?.styles
                                    ? column?.config?.styles?.width
                                    : getTableAnswerColumns(
                                          eachAnswer?.format_field_types
                                        )?.length >= 2
                                      ? '350px'
                                      : 'auto',
                                  ...((column?.format_question_type ===
                                    QUESTION_TYPES.rating10.id ||
                                    column?.format_question_type ===
                                      QUESTION_TYPES.rating5.id) && {
                                    overflow: 'auto',
                                  }),
                                }}
                                body={(rowData, { rowIndex }) => {
                                  const answerKey = `row_${rowData.row_number}_position_${column?.position}`;
                                  const currentValue = rowData[answerKey];
                                  const tableKey = eachAnswer.question_id;
                                  const fieldType =
                                    column?.format_question_type;
                                  const optionsKey = `options_row_${rowData?.row_number}_position_${column?.position}`;

                                  if (fieldType === QUESTION_TYPES.list.id) {
                                    return (
                                      <Dropdown
                                        options={rowData[optionsKey] || []}
                                        optionLabel="label"
                                        optionValue="option_id"
                                        placeholder="Select"
                                        panelClassName="z-index-9999"
                                        name="select"
                                        className="w-full"
                                        disabled={isDisable(
                                          eachAnswer?.is_editable
                                        )}
                                        value={Number(currentValue)}
                                        onChange={e =>
                                          handleTableAnswerRowEdit(
                                            tableKey,
                                            rowIndex,
                                            column?.question_format_id,
                                            column?.position,
                                            e.value
                                          )
                                        }
                                      />
                                    );
                                  }
                                  if (fieldType === QUESTION_TYPES.text.id) {
                                    return (
                                      <InputText
                                        value={currentValue}
                                        disabled={isDisable(
                                          eachAnswer?.is_editable
                                        )}
                                        className="w-full"
                                        onChange={e =>
                                          handleTableAnswerRowEdit(
                                            tableKey,
                                            rowIndex,
                                            column?.question_format_id,
                                            column?.position,
                                            e.target.value
                                          )
                                        }
                                      />
                                    );
                                  }
                                  if (fieldType === QUESTION_TYPES.rating5.id) {
                                    return (
                                      <Rating
                                        value={currentValue}
                                        disabled={isDisable(
                                          eachAnswer?.is_editable
                                        )}
                                        cancel={false}
                                        onChange={e =>
                                          handleTableAnswerRowEdit(
                                            tableKey,
                                            rowIndex,
                                            column?.question_format_id,
                                            column?.position,
                                            e.value
                                          )
                                        }
                                      />
                                    );
                                  }
                                  if (
                                    fieldType === QUESTION_TYPES.rating10.id
                                  ) {
                                    return (
                                      <Rating
                                        value={currentValue}
                                        disabled={isDisable(
                                          eachAnswer?.is_editable
                                        )}
                                        stars={10}
                                        cancel={false}
                                        onChange={e =>
                                          handleTableAnswerRowEdit(
                                            tableKey,
                                            rowIndex,
                                            column?.question_format_id,
                                            column?.position,
                                            e.value
                                          )
                                        }
                                      />
                                    );
                                  }
                                  return (
                                    <span>{getFieldLabel(fieldType)}</span>
                                  );
                                }}
                              />
                            ))}
                          </DataTable>
                        )}
                      </div>
                    )}
                    {eachAnswer?.question_type === QUESTION_TYPES.card.id && (
                      <div className="w-full">
                        {isAnswerLoading ? (
                          <Skeleton className="mb-2" height="6rem" />
                        ) : (
                          (() => {
                            const cardFields = getCardFields(eachAnswer);
                            const layout = generateCardLayout(cardFields);
                            return (
                              <div className="p-2 md:p-4 border-1 border-400 border-round-xl w-full">
                                <ResponsiveGridLayout
                                  className="layout"
                                  layouts={{ lg: layout }}
                                  breakpoints={{
                                    lg: 1920,
                                    md: 1440,
                                    sm: 780,
                                    xs: 480,
                                  }}
                                  cols={{ lg: 6, md: 4, sm: 3, xs: 1 }}
                                  containerPadding={[10, 10]}
                                  rowHeight={80}
                                  compactType={null}
                                  preventCollision={true}
                                  isDraggable={false}
                                  isResizable={false}
                                >
                                  {cardFields.map(field => (
                                    <div
                                      key={field.question_format_id}
                                      data-grid={{
                                        x: field.config.x,
                                        y: field.config.y,
                                        w: field.config.w,
                                        h: field.config.h,
                                        static: isDisable(
                                          eachAnswer?.is_editable
                                        ),
                                      }}
                                      className="p-2 shadow"
                                    >
                                      <div className="font-bold">
                                        {field.format_question_label}
                                      </div>
                                      <div>
                                        {(() => {
                                          switch (field.format_question_type) {
                                            case QUESTION_TYPES.text.id:
                                              return (
                                                <PFInputText
                                                  value={field.answer || ''}
                                                  disabled={isDisable(
                                                    eachAnswer?.is_editable
                                                  )}
                                                  onChange={e => {
                                                    const updatedFields =
                                                      cardFields.map(f =>
                                                        f.question_format_id ===
                                                        field.question_format_id
                                                          ? {
                                                              ...f,
                                                              answer:
                                                                e.target.value,
                                                            }
                                                          : f
                                                      );
                                                    onCardLayoutChange(
                                                      updatedFields,
                                                      layout,
                                                      eachAnswer,
                                                      index
                                                    );
                                                  }}
                                                />
                                              );
                                            case QUESTION_TYPES.list.id:
                                              return (
                                                <Dropdown
                                                  value={Number(field.answer)}
                                                  options={field.options || []}
                                                  optionLabel="label"
                                                  optionValue="option_id"
                                                  panelClassName="z-index-9999"
                                                  disabled={isDisable(
                                                    eachAnswer?.is_editable
                                                  )}
                                                  className="w-full"
                                                  onChange={e => {
                                                    const updatedFields =
                                                      cardFields.map(f =>
                                                        f.question_format_id ===
                                                        field.question_format_id
                                                          ? {
                                                              ...f,
                                                              answer: e.value,
                                                            }
                                                          : f
                                                      );
                                                    onCardLayoutChange(
                                                      updatedFields,
                                                      layout,
                                                      eachAnswer,
                                                      index
                                                    );
                                                  }}
                                                />
                                              );
                                            case QUESTION_TYPES.rating5.id:
                                              return (
                                                <Rating
                                                  value={field.answer}
                                                  disabled={isDisable(
                                                    eachAnswer?.is_editable
                                                  )}
                                                  style={{ padding: '10px' }}
                                                  className="bg-white border-1 border-round-md border-300 overflow-auto"
                                                  cancel={false}
                                                  onChange={e => {
                                                    const updatedFields =
                                                      cardFields.map(f =>
                                                        f.question_format_id ===
                                                        field.question_format_id
                                                          ? {
                                                              ...f,
                                                              answer: e.value,
                                                            }
                                                          : f
                                                      );
                                                    onCardLayoutChange(
                                                      updatedFields,
                                                      layout,
                                                      eachAnswer,
                                                      index
                                                    );
                                                  }}
                                                />
                                              );
                                            case QUESTION_TYPES.rating10.id:
                                              return (
                                                <Rating
                                                  value={field.answer}
                                                  stars={10}
                                                  disabled={isDisable(
                                                    eachAnswer?.is_editable
                                                  )}
                                                  style={{ padding: '10px' }}
                                                  className="bg-white border-1 border-round-md border-300 custom-rating overflow-auto"
                                                  cancel={false}
                                                  onChange={e => {
                                                    const updatedFields =
                                                      cardFields.map(f =>
                                                        f.question_format_id ===
                                                        field.question_format_id
                                                          ? {
                                                              ...f,
                                                              answer: e.value,
                                                            }
                                                          : f
                                                      );
                                                    onCardLayoutChange(
                                                      updatedFields,
                                                      layout,
                                                      eachAnswer,
                                                      index
                                                    );
                                                  }}
                                                />
                                              );
                                            default:
                                              return (
                                                <span>
                                                  {field.format_question_label}
                                                </span>
                                              );
                                          }
                                        })()}
                                      </div>
                                    </div>
                                  ))}
                                </ResponsiveGridLayout>
                              </div>
                            );
                          })()
                        )}
                      </div>
                    )}
                  </div>
                  <RenderModificationDetail
                    answer={formik.values?.answers?.[index]}
                  />
                </div>
              );
            })}
          </>
        ) : (
          <>
            <div className="w-full flex align-items-center justify-content-center mt-2">
              <h2 className="font-semibold">No question found</h2>
            </div>
          </>
        )}
      </div>
      <div className="absolute w-full bg-white bottom-0 left-0 z-2 px-3 py-1">
        <ActionComponent />
      </div>
    </>
  );
};

export default AnswerComponent;
