import React, { useState, useEffect } from 'react';

import PFFormikError from '../../../shared/PFPrime/PFFormikError';
import PFInputText from '../../../shared/PFPrime/PFInputText';
import PFButton from '../../../shared/PFPrime/PFButton';
import PFDataTable from '../../../shared/PFPrime/PFDataTable';
import {
  getEmailTypes,
  emailSubmit,
  columns,
} from '../helpers/EmailDialogBody';

const EmailDialogBody = ({ emailCollection, data, mode }) => {
  const [updateVlue, setUpdateValue] = useState('');
  const [btnLabel, setBtnLabel] = useState('Add');
  const [emailObject, setEmailObject] = useState([]);
  const initionalMailCategory = getEmailTypes;
  const { handleSubmit, handleChange, values, errors, touched } = emailSubmit(
    setEmailObject,
    emailObject?.length <= 0 ? initionalMailCategory[0]?.email_type : '',
    updateVlue,
    setUpdateValue,
    setBtnLabel,
    btnLabel
  );

  const [emailTypeOption, setEmailTypeOption] = useState([]);

  useEffect(() => {
    if (data?.customer_emails) {
      setEmailObject(data?.customer_emails);
    }
  }, [data]);

  useEffect(() => {
    setEmailTypeOption(initionalMailCategory);
    if (emailObject?.length > 0) {
      emailCollection('customer_emails', emailObject);
    } else {
      emailCollection('customer_emails', '');
    }
  }, [getEmailTypes, emailObject]);

  const deleteEmail = number => {
    const updatedEmailObject = emailObject.filter(
      (item, index) => index !== number
    );
    setEmailObject(updatedEmailObject);
  };

  const updateEmail = (rowData, rowIndex, isPrimary = false) => {
    if (isPrimary) {
      setEmailObject(preValue => {
        const updatedEmailObject = preValue.map((item, index) => {
          if (index === rowIndex) {
            item['is_primary'] = isPrimary;
          } else {
            item['is_primary'] = !isPrimary;
          }
          return item;
        });
        return updatedEmailObject;
      });
    } else {
      setUpdateValue({ rowData: rowData, rowIndex: rowIndex });
      setBtnLabel('Update');
    }
  };

  return (
    <>
      {mode !== 'view' && (
        <form className="w-full" onSubmit={handleSubmit}>
          <div className="grid mt-2">
            <div className="col-12 md:col-6 lg:col-6">
              <div className="w-full">
                <span className="p-float-label">
                  <PFInputText
                    inputId="email"
                    name="email"
                    value={values?.email || ''}
                    onChange={handleChange}
                    className={touched?.email && errors?.email ? 'error' : ''}
                  />
                  <label htmlFor="email" className="text-sm">
                    Email <span style={{ color: 'red' }}>*</span>
                  </label>
                </span>
                <PFFormikError
                  touched={touched}
                  errors={errors}
                  field="email"
                />
              </div>
            </div>
            <div className="col-12 md:col-6 lg:col-2">
              <div className="w-full">
                <PFButton
                  icon="pi pi-plus"
                  rounded={true}
                  type="button"
                  severity="primary"
                  onClick={handleSubmit}
                  outlined={false}
                />
              </div>
            </div>
          </div>
        </form>
      )}
      <div className={`mt-2 h-16rem`}>
        <PFDataTable
          columns={columns(
            emailTypeOption,
            deleteEmail,
            mode,
            initionalMailCategory[0]?.email_type,
            updateEmail
          )}
          data={emailObject}
          scrollable
          scrollHeight="250px"
          stripedRows={true}
        />
      </div>
    </>
  );
};

export default EmailDialogBody;
