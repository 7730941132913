// **** React Imports ****
import React from 'react';
import { Grid, TextField, CircularProgress } from '@material-ui/core';
import moment from 'moment';
import { Autocomplete } from '@material-ui/lab';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import permissions from '../../../config/permissions';
import GenericTable from '../../shared/GenericTable/GenericTable';
import GenericDialog from '../../shared/Dialog/GenericDialog';
import { useAlerts } from '../../shared/Alerts/alertsService';
import { checkPermission } from '../../../utils/Helpers';
import {
  NEEDS_SUPPORT_CATEGORIES,
  NEEDS_SUPPORT_LABELS,
  NEEDS_SUPPORT_TYPES,
  NEED_SUPPORT_PAYMENT_TYPE,
  PROJECT_TYPES,
} from '../../../constants';

import { getNeedSupportData, createSupport } from './NeedSupport.service';
import { useStyles } from './NeedSupport.styles';

const NeedSupportTableColumns = [
  {
    id: 'support_category',
    value: 'Support Category',
  },
  {
    id: 'support_type',
    value: 'Support Type',
  },
  {
    id: 'comments',
    value: 'Description',
  },
  {
    id: 'exception_payment_type',
    value: 'Exception Payment Type',
  },
  {
    id: 'created_by',
    value: 'Created By',
  },
  {
    id: 'created_at',
    value: 'Created At',
  },
  {
    id: 'support_status',
    value: 'Status',
  },
  {
    id: 'status_updated_at',
    value: 'Status Updated At',
  },
];

// ******** NeedSupport ************
const NeedSupport = ({ formik, setReloadForm }) => {
  const classes = useStyles();
  const [needSupportResponse, setNeedSupportResponse] = React.useState({});
  const [supportCategoryList, setSupportCategoryList] = React.useState([]);
  const [exceptionPaymentTypeList, setExceptionPaymentTypeList] =
    React.useState([]);
  const [supportTypeList, setSupportTypeList] = React.useState([]);
  const [loading, setLoading] = React.useState();
  const [isOpen, setIsOpen] = React.useState(false);
  const [formAction, setFormAction] = React.useState('add');
  const [dialogSettings, setDialogSettings] = React.useState({
    title: 'View Technician',
    button1Text: '',
    button2Text: 'Add',
    showButton1: true,
    showButton2: true,
  });
  const [rowIndex, setRowIndex] = React.useState();

  const [reloadList, setReloadList] = React.useState(false);
  const { setAlert } = useAlerts();

  let presentationNeedSupportList = [];
  const userId = localStorage.getItem('user_id');
  React.useEffect(() => {
    getNeedSupportData(
      formik?.values?.project_id,
      setLoading,
      setNeedSupportResponse
    );
  }, [formik?.values?.projectId]);

  React.useEffect(() => {
    if (reloadList) {
      getNeedSupportData(
        formik?.values?.project_id,
        setLoading,
        setNeedSupportResponse
      );
    }
  }, [reloadList]);

  React.useEffect(() => {
    switch (formik?.values?.project_type?.project_type) {
      case PROJECT_TYPES.MEASUREMENT:
        setSupportCategoryList(NEEDS_SUPPORT_CATEGORIES.MEASUREMENT);
        break;
      case PROJECT_TYPES.INSTALLATION:
        setSupportCategoryList(NEEDS_SUPPORT_CATEGORIES.INSTALLATION);
        break;
      case PROJECT_TYPES.WORK_ORDER:
        setSupportCategoryList(NEEDS_SUPPORT_CATEGORIES.WORK_ORDER);
        break;
      default:
        break;
    }
  }, [formik?.values?.project_type?.project_type]);

  // **** Form Validation Schema ****
  const NeedSupportValidationSchema = Yup.object().shape({
    support_category: Yup.string().required(),
    support_type: Yup.string().required(),
    exception_payment_type: Yup.string().when('support_category', {
      is: category => category === 'Products',
      then: Yup.string().required(),
      otherwise: Yup.string().optional().nullable(),
    }),
    comments: Yup.string()
      .trim()
      .required()
      .test(
        'len',
        'Enter a valid comment',
        val => val && val.trim().length >= 1
      ),
  });

  // **** Data Modification *****
  if (needSupportResponse?.items?.length) {
    presentationNeedSupportList = needSupportResponse.items.map(val => {
      return {
        support_category: {
          value: val?.support_category,
        },
        support_type: {
          value: val?.support_type,
        },
        comments: {
          value: val?.comments,
        },
        exception_payment_type: {
          value: val?.exception_payment_type,
        },
        created_by: {
          value: `${val?.created_user?.first_name || ''} ${val?.created_user?.last_name || ''}`,
        },
        created_at: {
          value: moment(val?.created_at).format('YYYY-DD-MM hh:mm A'),
        },
        support_status: {
          value: val?.support_status,
        },
        status_updated_at: {
          value: val?.status_updated_at
            ? moment(val.status_updated_at).format('YYYY-DD-MM hh:mm A')
            : '',
        },
      };
    });
  }

  // **** Formik Form Values ****
  const needSupportFormik = useFormik({
    initialValues: {
      support_category:
        (formAction !== 'add' &&
          presentationNeedSupportList[rowIndex]?.support_category?.value) ||
        null,
      support_type:
        (formAction !== 'add' &&
          presentationNeedSupportList[rowIndex]?.support_type?.value) ||
        null,
      comments:
        (formAction !== 'add' &&
          presentationNeedSupportList[rowIndex]?.comments?.value) ||
        null,
      exception_payment_type:
        (formAction !== 'add' &&
          presentationNeedSupportList[rowIndex]?.exception_payment_type
            ?.value) ||
        null,
      support_status:
        (formAction !== 'add' &&
          presentationNeedSupportList[rowIndex]?.support_status?.value) ||
        null,
    },
    onSubmit: (values, { resetForm }) => {
      if (formAction === 'add') {
        createSupport(
          formik?.values?.project_id,
          {
            support_category: needSupportFormik?.values?.support_category,
            support_type: needSupportFormik?.values?.support_type,
            comments: needSupportFormik?.values?.comments,
            exception_payment_type: needSupportFormik?.values
              ?.exception_payment_type
              ? needSupportFormik?.values?.exception_payment_type
              : null,
            created_by: userId,
          },
          setLoading,
          setAlert,
          setReloadList,
          setIsOpen
        );
      }
      setReloadList(false);
      setReloadForm(true);
      resetForm();
    },
    validationSchema: NeedSupportValidationSchema,
    enableReinitialize: true,
  });

  //*** Need Support - Action Handlers ***/

  const addSupport = () => {
    setFormAction('add');

    setDialogSettings(prevState => ({
      ...prevState,
      showButton2: true,
      button2Text: 'Save',
      title: 'Add Support Request',
    }));
    setIsOpen(true);
  };

  const viewNeedSupport = (action, index) => {
    setRowIndex(index);
    setFormAction(action);
    setDialogSettings(prevState => ({
      ...prevState,
      showButton2: action === 'view' ? false : true,
      title: action === 'view' && 'View Support Request',
    }));
    setIsOpen(true);
  };

  const handleSupportCategoryChange = (event, value) => {
    needSupportFormik.setFieldValue('support_category', value);
    switch (value) {
      case NEEDS_SUPPORT_LABELS.PRODUCTS:
        setSupportTypeList(NEEDS_SUPPORT_TYPES.PRODUCTS);
        break;
      case NEEDS_SUPPORT_LABELS.LABOR:
        setSupportTypeList(NEEDS_SUPPORT_TYPES.LABOR);
        break;
      case NEEDS_SUPPORT_LABELS.REFUNDS:
        setSupportTypeList(NEEDS_SUPPORT_TYPES.REFUNDS);
        break;
      case NEEDS_SUPPORT_LABELS.MISSING_DOCUMENTS:
        setSupportTypeList(NEEDS_SUPPORT_TYPES.MISSING_DOCUMENTS);
        break;
      case NEEDS_SUPPORT_LABELS.CUSTOMERS:
        setSupportTypeList(NEEDS_SUPPORT_TYPES.CUSTOMERS);
        break;
      case NEEDS_SUPPORT_LABELS.PERMITS:
        setSupportTypeList(NEEDS_SUPPORT_TYPES.PERMITS);
        break;
      default:
        break;
    }
    needSupportFormik.setFieldValue('support_type', '');
    needSupportFormik.setFieldValue('exception_payment_type', null);
  };

  const handleSupportTypeChange = (event, value) => {
    needSupportFormik.setFieldValue('support_type', value);
    needSupportFormik.setFieldValue('exception_payment_type', null);
    switch (value) {
      case NEEDS_SUPPORT_LABELS.DAMAGED_PRODUCT:
        setExceptionPaymentTypeList(NEED_SUPPORT_PAYMENT_TYPE.DAMAGED_PRODUCT);
        break;
      case NEEDS_SUPPORT_LABELS.PRODUCT_NOT_READY_AT_JOBSITE:
        setExceptionPaymentTypeList(
          NEED_SUPPORT_PAYMENT_TYPE.PRODUCT_NOT_READY_AT_JOBSITE
        );
        break;
      case NEEDS_SUPPORT_LABELS.PRODUCT_NOT_READY_AT_STORE:
        setExceptionPaymentTypeList(
          NEED_SUPPORT_PAYMENT_TYPE.PRODUCT_NOT_READY_AT_STORE
        );
        break;
      case NEEDS_SUPPORT_LABELS.MISSING_OR_INCORRECT_PRODUCT:
        setExceptionPaymentTypeList(
          NEED_SUPPORT_PAYMENT_TYPE.MISSING_OR_INCORRECT_PRODUCT
        );
        break;
      case NEEDS_SUPPORT_LABELS.NO_PRODUCT_ATTACHED:
        setExceptionPaymentTypeList(
          NEED_SUPPORT_PAYMENT_TYPE.NO_PRODUCT_ATTACHED
        );
        break;
      default:
        break;
    }
  };

  const getNeedSupportViewEditForm = () => {
    return (
      <Grid
        container
        spacing={2}
        direction="column"
        className={formAction === 'add' && classes.formEdit}
      >
        <Grid item>
          <Autocomplete
            id="support_category"
            name="support_category"
            required={formAction === 'add'}
            options={supportCategoryList || []}
            disableListWrap
            renderInput={params => (
              <TextField
                {...params}
                label="Support Category"
                required={formAction === 'add'}
              />
            )}
            value={needSupportFormik?.values?.support_category || ''}
            onChange={handleSupportCategoryChange}
            onBlur={needSupportFormik.handleBlur}
            disabled={formAction === 'view'}
          />
        </Grid>
        <Grid item>
          <Autocomplete
            id="support_type"
            name="support_type"
            required={formAction === 'add'}
            options={supportTypeList || []}
            disableListWrap
            renderInput={params => (
              <TextField
                {...params}
                label="Support Type"
                required={formAction === 'add'}
              />
            )}
            value={needSupportFormik?.values?.support_type || ''}
            onChange={handleSupportTypeChange}
            onBlur={needSupportFormik.handleBlur}
            disabled={
              formAction === 'view' ||
              !needSupportFormik?.values?.support_category
            }
          />
        </Grid>
        <Grid item>
          <TextField
            id="comments"
            label="Description"
            name="comments"
            required={formAction === 'add'}
            placeholder="Max 500 characters"
            multiline
            maxRows={20}
            inputProps={{ maxLength: 500 }}
            onChange={event => {
              needSupportFormik.setFieldValue('comments', event?.target?.value);
            }}
            onBlur={needSupportFormik.handleBlur}
            InputProps={{
              readOnly: formAction === 'view' ? true : false,
            }}
            value={needSupportFormik?.values?.comments}
            InputLabelProps={{ shrink: true }}
            className={classes.input}
          />
        </Grid>
        {needSupportFormik?.values?.support_category ===
          NEEDS_SUPPORT_LABELS.PRODUCTS && (
          <Grid item>
            <Autocomplete
              id="exception_payment_type"
              name="exception_payment_type"
              required={formAction === 'add'}
              options={exceptionPaymentTypeList || []}
              disableListWrap
              renderInput={params => (
                <TextField
                  {...params}
                  label="Exception Payment Type"
                  required={formAction === 'add'}
                />
              )}
              value={needSupportFormik?.values?.exception_payment_type || ''}
              onChange={(event, value) =>
                needSupportFormik.setFieldValue('exception_payment_type', value)
              }
              onBlur={needSupportFormik.handleBlur}
              disabled={
                formAction === 'view' ||
                !needSupportFormik?.values?.support_type
              }
            />
          </Grid>
        )}
      </Grid>
    );
  };

  if (loading) {
    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={3}
      >
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <Grid spacing={2} direction="column">
        <Grid item>
          <GenericTable
            title="Need Support"
            headerLinks={
              checkPermission(permissions.viewEditProject.tabProjectSupportAdd)
                ? [
                    {
                      label: 'Add Item',
                      handler: addSupport,
                    },
                  ]
                : []
            }
            columnData={NeedSupportTableColumns}
            rowData={presentationNeedSupportList}
            showActions={{
              view: checkPermission(
                permissions.viewEditProject.tabProjectSupportView
              ),
            }}
            handleView={index => viewNeedSupport('view', index)}
          />
        </Grid>
      </Grid>
      <GenericDialog
        fullwidth
        isOpen={isOpen}
        handleClose={() => {
          setIsOpen(false);
          needSupportFormik?.handleReset();
        }}
        handleSave={needSupportFormik.handleSubmit}
        dialogSettings={dialogSettings}
        disabledButton2={
          !needSupportFormik?.dirty || !needSupportFormik?.isValid
        }
        disabledButton1={false}
      >
        <form>{getNeedSupportViewEditForm()}</form>
      </GenericDialog>
    </>
  );
};

export default NeedSupport;
