import * as Yup from 'yup';

export const formikInitialValues = {
  provider_number: '',
  firm_name: '',
  firm_certification_number: '',
  renovator_name: '',
  renovator_certification_number: '',
  lead_response_reason: '',
  fixed_renovator_details: 'no',
  lrrp_activity_autocomplete: 'no',
  tested_lead_negative: {
    test_kit_manufacturer: '',
    component_location_tested: '',
  },
  tested_lead_positive: {
    test_kit_manufacturer: '',
    component_location_tested: '',
  },
  presumed_lead: {
    notification_confirmation: '',
    is_common_area_involved: 'no',
    reason_common_area: '',
    work_area_signage: 'no',
    signage_explanation: '',
    work_areas_contained: '',
    prohibited_practices: 'no',
    waste_contained: 'no',
    worksite_properly_cleaned: 'no',
    post_renovation_cleaning_method: '',
    number_of_wet_cloths: '',
    number_of_dry_cloths: '',
    cleaning_verification_results: '',
    is_child_occupied_facility: 'no',
    reason_child_facility: '',
  },
};

export const formikMapping = (LrrpConfigurationFormik, response) => {
  LrrpConfigurationFormik.setFieldValue(
    'provider_number',
    response?.values?.provider_number || ''
  );
  if (response?.values?.default_lead_configuration?.tested_lead_negative) {
    LrrpConfigurationFormik.setFieldValue(
      'tested_lead_negative',
      response?.values?.default_lead_configuration?.tested_lead_negative
    );
  }
  if (response?.values?.default_lead_configuration?.tested_lead_positive) {
    LrrpConfigurationFormik?.setFieldValue(
      'tested_lead_positive',
      response?.values?.default_lead_configuration?.tested_lead_positive
    );
  }
  if (response?.values?.default_lead_configuration?.presumed_lead) {
    LrrpConfigurationFormik.setFieldValue(
      'presumed_lead',
      response?.values?.default_lead_configuration?.presumed_lead
    );
  }

  if (response?.epa_lead_assessment_required) {
    LrrpConfigurationFormik.setFieldValue(
      'epa_lead_assessment_required',
      response?.values?.epa_lead_assessment_required
    );
  }

  if (response?.epa_lead_remediation) {
    LrrpConfigurationFormik.setFieldValue(
      'epa_lead_remediation',
      response?.values?.epa_lead_remediation
    );
  }
  LrrpConfigurationFormik.setFieldValue(
    'renovator_certification_number',
    response?.values?.default_lead_configuration?.renovator_certification_number
  );
  LrrpConfigurationFormik.setFieldValue(
    'renovator_name',
    response?.values?.default_lead_configuration?.renovator_name
  );
  LrrpConfigurationFormik.setFieldValue(
    'firm_certification_number',
    response?.values?.firm_certification_number || ''
  );
  LrrpConfigurationFormik.setFieldValue(
    'firm_name',
    response?.values?.default_lead_configuration?.firm_name
  );
  LrrpConfigurationFormik.setFieldValue(
    'lead_response_reason',
    response?.values?.default_lead_response_reason || ''
  );
  LrrpConfigurationFormik.setFieldValue(
    'lrrp_activity_autocomplete',
    response?.values?.lead_autocomplete_activity ? 'yes' : 'no' || ''
  );
  LrrpConfigurationFormik.setFieldValue(
    'fixed_renovator_details',
    response?.values?.default_lead_configuration?.fixed_renovator_details || ''
  );
};

export const formikProjectMapping = (LrrpConfigurationFormik, response) => {
  LrrpConfigurationFormik.setFieldValue(
    'tested_lead_negative',
    response?.tested_lead_negative
  );
  LrrpConfigurationFormik.setFieldValue(
    'tested_lead_positive',
    response?.tested_lead_positive
  );
  LrrpConfigurationFormik.setFieldValue(
    'presumed_lead',
    response?.presumed_lead
  );
  LrrpConfigurationFormik.setFieldValue(
    'lead_response_reason',
    response?.default_lead_response_reason
  );
  LrrpConfigurationFormik.setFieldValue(
    'lswp_required',
    response?.lswp_required
  );
  LrrpConfigurationFormik.setFieldValue(
    'lead_test_req',
    response?.lead_test_req
  );
  LrrpConfigurationFormik.setFieldValue(
    'lswp_followed',
    response?.lswp_followed
  );
  LrrpConfigurationFormik.setFieldValue(
    'hd_lead_test_result',
    response?.hd_lead_test_result
  );
  LrrpConfigurationFormik.setFieldValue(
    'epa_override_reason_code_id',
    response?.epa_override_reason_code_id
  );
  LrrpConfigurationFormik.setFieldValue(
    'epa_lead_assessment_required',
    response?.epa_lead_assessment_required
  );
  LrrpConfigurationFormik.setFieldValue(
    'epa_lead_remediation',
    response?.epa_lead_remediation
  );
  LrrpConfigurationFormik.setFieldValue('test_info', response?.test_info);
  LrrpConfigurationFormik.setFieldValue(
    'epa_renovator_name',
    response?.epa_renovator_name || ''
  );
  LrrpConfigurationFormik.setFieldValue(
    'epa_renovator_cert',
    response?.epa_renovator_cert || ''
  );
};

export const lrrpConfigFormikValidationSchema = Yup.object().shape({
  provider_number: Yup.string().required('Provider number is required'),
  firm_name: Yup.string(),
  firm_certification_number: Yup.string(),
  renovator_name: Yup.string(),
  renovator_certification_number: Yup.string(),
  lead_response_reason: Yup.string().required(
    'Lead response reason is required'
  ),
  fixed_renovator_details: Yup.string().oneOf(['yes', 'no']),
  lrrp_activity_autocomplete: Yup.string().oneOf(['yes', 'no']),
  tested_lead_negative: Yup.object().when('lead_response_reason', {
    is: 'Tested Lead Negative',
    then: Yup.object().shape({
      test_kit_manufacturer: Yup.string().required(
        'Test kit manufacturer is required'
      ),
      component_location_tested: Yup.string().required(
        'Component location tested is required'
      ),
    }),
    otherwise: Yup.object().shape({
      test_kit_manufacturer: Yup.string(),
      component_location_tested: Yup.string(),
    }),
  }),
  tested_lead_positive: Yup.object().when('lead_response_reason', {
    is: 'Tested Lead Positive',
    then: Yup.object().shape({
      test_kit_manufacturer: Yup.string()
        .trim()
        .required('Test kit manufacturer is required'),
      component_location_tested: Yup.string()
        .trim()
        .required('Component location tested is required'),
      notification_confirmation: Yup.string().required(
        'Notification confirmation is required'
      ),
      is_common_area_involved: Yup.string()
        .oneOf(['yes', 'no', 'N/A'])
        .required(),
      reason_common_area: Yup.string()
        .trim()
        .when('is_common_area_involved', {
          is: 'N/A',
          then: Yup.string().required('Reason for N/A is required'),
          otherwise: Yup.string(),
        }),
      work_area_signage: Yup.string().oneOf(['yes', 'no', 'N/A']).required(),
      signage_explanation: Yup.string()
        .trim()
        .when('work_area_signage', {
          is: 'N/A',
          then: Yup.string().required('Reason for N/A is required'),
          otherwise: Yup.string(),
        }),
      prohibited_practices: Yup.string().oneOf(['yes', 'no', 'N/A']).required(),
      waste_contained: Yup.string().oneOf(['yes', 'no', 'N/A']).required(),
      worksite_properly_cleaned: Yup.string()
        .oneOf(['yes', 'no', 'N/A'])
        .required(),
      post_renovation_cleaning_method: Yup.string()
        .oneOf(['Cleaning verification', 'Dust clearance'])
        .required(),
      cleaning_verification_results: Yup.string()
        .trim()
        .when('post_renovation_cleaning_method', {
          is: 'Cleaning verification',
          then: Yup.string().required(
            'Cleaning verification results is required'
          ),
          otherwise: Yup.string(),
        }),
      number_of_wet_cloths: Yup.string().when(
        'post_renovation_cleaning_method',
        {
          is: 'Cleaning verification',
          then: Yup.string().required('Number of wet cloths is required'),
          otherwise: Yup.string(),
        }
      ),
      number_of_dry_cloths: Yup.string().when(
        'post_renovation_cleaning_method',
        {
          is: 'Cleaning verification',
          then: Yup.string().required('Number of dry cloths is required'),
          otherwise: Yup.string(),
        }
      ),
      is_child_occupied_facility: Yup.string()
        .oneOf(['yes', 'no', 'N/A'])
        .required(),
      reason_child_facility: Yup.string()
        .trim()
        .when('is_child_occupied_facility', {
          is: 'N/A',
          then: Yup.string().required('Reason for N/A is required'),
          otherwise: Yup.string(),
        }),
    }),
    otherwise: Yup.object().shape({
      test_kit_manufacturer: Yup.string(),
      component_location_tested: Yup.string(),
    }),
  }),
  presumed_lead: Yup.object().shape({
    notification_confirmation: Yup.string(),
    is_common_area_involved: Yup.string().oneOf(['yes', 'no']),
    reason_common_area: Yup.string(),
    work_area_signage: Yup.string().oneOf(['yes', 'no']),
    signage_explanation: Yup.string(),
    work_areas_contained: Yup.string(),
    prohibited_practices: Yup.string().oneOf(['yes', 'no']),
    waste_contained: Yup.string().oneOf(['yes', 'no']),
    worksite_properly_cleaned: Yup.string(),
    post_renovation_cleaning_method: Yup.string(),
    number_of_wet_cloths: Yup.string(),
    number_of_dry_cloths: Yup.string(),
    cleaning_verification_results: Yup.string(),
    is_child_occupied_facility: Yup.string().oneOf(['yes', 'no']),
    reason_child_facility: Yup.string(),
  }),
});
