// **** External Utilities ****
import axios from 'axios';

import ApiService from '../../../services/api.service';
import { CONFIG } from '../../../constants';
import { URL_CONSTANTS } from '../../../constants/urlConstants';
// Get Installer Data
export const getInstallerByProject = async projectId => {
  try {
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/projects/${projectId}/project-installer`
    );
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

// Get Installer Options
export const getInstallerOptions = async () => {
  try {
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/auth/user/report/get-installers`
    );
    const sortedInstallerList = response?.data?.sort((a, b) => {
      const nameA = `${a.first_name} ${a.last_name}`.toUpperCase();
      const nameB = `${b.first_name} ${b.last_name}`.toUpperCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    return sortedInstallerList;
  } catch (error) {
    console.error(error);
    return Promise.reject('Error');
  }
};

// Get Installer Type Options
export const getInstallerTypeOptions = async () => {
  try {
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/system/installer-work-type`
    );
    return response?.data;
  } catch (error) {
    console.error(error);
    return Promise.reject('Error');
  }
};

//Add Installer
export const createInstaller = async (
  projectId,
  values,
  setLoading,
  setAlert,
  setReloadList,
  setOpen
) => {
  try {
    setLoading(true);
    const response = await axios.post(
      `${URL_CONSTANTS.API.BASE_URL}/projects/${projectId}/project-installer`,
      values
    );
    if (response) {
      setReloadList(true);
      if (
        response &&
        !response?.data?.installer_work_type &&
        !response?.data?.installer_detail &&
        response?.data?.date_scheduled_start &&
        response?.data?.date_scheduled_end
      ) {
        setAlert(
          'success',
          'Scheduling date and time added successfully.',
          false,
          true
        );
      } else {
        setAlert('success', 'Technician added successfully.', false, true);
      }
      setOpen(false);
      return response.data;
    } else {
      setAlert('error', 'Unable to add technician.', false, true);
    }
  } catch (error) {
    setAlert('error', 'Error while creating technician.', false, true);
    console.error(error);
  } finally {
    setLoading(false);
  }
};

//Verify Installer
export const verifyInstaller = async (projectId, values) => {
  let result = false;
  try {
    const response = await axios.post(
      `${URL_CONSTANTS.API.BASE_URL}/projects/${projectId}/project-installer/verify`,
      values && values?.date_scheduled_start && values?.date_scheduled_end
        ? { ...values }
        : { worktypeid: values?.worktypeid, installer_id: values?.installer_id }
    );
    if (response.data.warning) {
      result = response.data;
    }
  } catch (error) {
    console.log('Verify Technician API Call Error');
    console.error(error);
  }
  return result;
};

//Update Installer
export const updateInstaller = async (
  projectId,
  projectInstallerId,
  values,
  setLoading,
  setAlert,
  setReloadList,
  setOpen
) => {
  try {
    setLoading(true);
    const response = await axios.patch(
      `${URL_CONSTANTS.API.BASE_URL}/projects/${projectId}/project-installer/${projectInstallerId}`,
      values
    );
    if (response) {
      setReloadList(true);
      if (
        response &&
        !response?.data?.installer_work_type &&
        !response?.data?.installer_detail &&
        response?.data?.date_scheduled_start &&
        response?.data?.date_scheduled_end
      ) {
        setAlert(
          'success',
          'Scheduling date and time updated successfully.',
          false,
          true
        );
      } else {
        setAlert('success', 'Technician updated successfully.', false, true);
      }
    }
  } catch (error) {
    setAlert('error', 'Error while updating technician.', false, true);
    console.error(error);
  } finally {
    setOpen(false);
    setLoading(false);
  }
};

//Delete Installer
export const deleteProjectInstaller = async (
  projectId,
  projectInstallerId,
  setLoading,
  setAlert,
  setConfirmDialog,
  setReloadCalender,
  reloadCalender
) => {
  try {
    setConfirmDialog(prevState => ({
      ...prevState,
      isOpen: false,
    }));
    setLoading(true);
    const response = await axios.delete(
      `${URL_CONSTANTS.API.BASE_URL}/projects/${projectId}/project-installer/${projectInstallerId}`
    );
    if (response) {
      if (setReloadCalender) {
        setReloadCalender(!reloadCalender);
      }
      setAlert('success', 'Technician deleted successfully.', false, true);
    }
  } catch (error) {
    setAlert('error', 'Error while deleting technician', false, true);
    console.error(error);
  } finally {
    setLoading(false);
  }
};

//Get reschedule Reason
export const getRescheduleReasonOptions = async (
  projectType,
  sourceId,
  setRescheduleReasonOptions,
  setBackdropLoading
) => {
  try {
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/system/reason?reasonType=${projectType}&sourceSystemId=${sourceId}`
    );
    const homeDepotRescheduleReason = response?.data?.filter(
      item => item?.reason_type === 'Reschedule Reason'
    );
    setRescheduleReasonOptions(
      sourceId === 2 ? homeDepotRescheduleReason : response?.data
    );
  } catch (error) {
    console.log(error);
  } finally {
    setBackdropLoading(false);
  }
};

//Get Pick up location dropdown
export const getPickUpLocation = async setPickUpLocation => {
  try {
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/system/location`
    );
    setPickUpLocation(response?.data);
  } catch (error) {
    console.log(error);
  }
};

//Get state dropdown values
export const getStateOptions = async setStateOptions => {
  try {
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/system/state`
    );
    setStateOptions(response?.data);
  } catch (error) {
    console.log(error);
  }
};

export const updateLatLongForProjectInstallationAddress = async (
  projectId,
  projectDetailsData,
  setProjectDetailsData
) => {
  try {
    const response = await axios.patch(
      `${URL_CONSTANTS.API.BASE_URL}/projects/${projectId}`,
      {
        project_type_id: projectDetailsData.project_type.project_type_id,
        installation_address: [
          {
            ...projectDetailsData.installation_address,
            ...{
              address1: `${projectDetailsData.installation_address?.address1} `,
            },
          },
        ],
      }
    );
    if (
      response?.data &&
      response?.data?.installation_address?.latitude &&
      response?.data?.installation_address?.longitude
    ) {
      setProjectDetailsData(response?.data);
    }
  } catch (error) {
    console.log(
      'Error: While updating latitude and longitude of Project Installation Address'
    );
    console.error(error);
  }
};

export const updateProject = async (
  projectId,
  payload,
  apiMessageResponse,
  historyProp,
  setReloadFormProp,
  setLoadingProp,
  setAlertProp
) => {
  try {
    setLoadingProp(true);
    const response = await axios.patch(
      `${URL_CONSTANTS.API.BASE_URL}/projects/${projectId}`,
      payload
    );
    if (response) {
      historyProp.push(`/project/view/${response.data?.project_id}`);
      setReloadFormProp(true);
      setAlertProp('success', apiMessageResponse, false, true);
    }
  } catch (error) {
    setAlertProp('error', error?.response?.data?.error);
    setLoadingProp(false);
  }
};
export const confirmAppointment = async (
  projectId,
  historyProp,
  setReloadFormProp,
  setLoadingProp,
  setConfirmLoading,
  setAlertProp
) => {
  try {
    setLoadingProp(true);
    setConfirmLoading(true);
    const response = await axios.patch(
      `${URL_CONSTANTS.API.BASE_URL}/projects/${projectId}/project-button-action/confirm-appointment`
    );
    if (response) {
      historyProp.push(`/project/view/${projectId}`);
      setReloadFormProp(true);
      setConfirmLoading(false);
      setAlertProp('success', response?.data?.message, false, true);
    }
  } catch (error) {
    setAlertProp('error', error?.response?.data?.error);
    setConfirmLoading(false);
    setLoadingProp(false);
  }
};
//Create Custom Address
export const createCustomAddress = async values => {
  try {
    return await ApiService.post(`/system/address`, values);
  } catch (error) {
    console.error('Catch Error: createCustomAddress', error);
  }
};

//Schedule Reason
export const getScheduleReasonOptions = async (
  setScheduleReasonOptions,
  projectTypeId,
  values
) => {
  try {
    const response = await axios.get(
      `${CONFIG?.API_BASE_URL}/system/scheduling-reason/list?project_type_id=${projectTypeId}&searchString=${values}`
    );
    setScheduleReasonOptions(response?.data);
  } catch (error) {
    console.log(error);
  }
};

export const addScheduleReasonOptions = async values => {
  try {
    const response = await axios.post(
      `${CONFIG?.API_BASE_URL}/system/scheduling-reason`,
      values
    );
    return response;
  } catch (error) {
    console.log('error', error);
  }
};

// Schedule Pending Reason
export const getSchedulePendingReasonOptions = async (
  setScheduleReasonOptions,
  values
) => {
  try {
    const response = await axios.get(
      `${CONFIG?.API_BASE_URL}/system/scheduling-reason/list?searchString=${values}`
    );

    setScheduleReasonOptions(response?.data);
  } catch (error) {
    console.log(error);
  }
};

export const addSchedulePendingReasonOptions = async values => {
  try {
    const response = await axios.post(
      `${CONFIG?.API_BASE_URL}/system/scheduling-reason`,
      values
    );

    return response;
  } catch (error) {
    console.log('error', error);
  }
};

export const getVendorList = async () => {
  try {
    const response = await ApiService.get(`/projects/vendor/list`);
    return response;
  } catch (error) {
    console.error(error);
  }
};
