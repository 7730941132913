import { format } from 'date-fns';
import moment from 'moment';
export const renderDate = (
  date: Date | string | null | undefined,
  dateFormat: string
) => {
  if (!date) {
    return null; // Handle null or undefined
  }
  // Convert to Date and format
  const formatDate = new Date(date);
  // Format the date with date-fns
  return format(formatDate, dateFormat);
};

export const convertGMTToISOString = (value = '') => {
  if (value) {
    try {
      return moment(new Date(`${value} GMT+0000`).toISOString()).format(
        'MM-DD-YYYY hh:mm A'
      );
    } catch (err) {
      console.log('Error while converting Date', err);
      return null;
    }
  }
  return null;
};
