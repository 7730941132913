import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: true,
  selectedDashboards: [],
  isSelectDashboardDirty: false,
};

const userFormDashboardsSlice = createSlice({
  name: 'userFormDashboards',
  initialState: initialState,
  reducers: {
    setUserDashboardsForm(state, { payload }) {
      state.isLoading = Object.prototype.hasOwnProperty.call(
        payload,
        'isLoading'
      )
        ? payload.isLoading
        : state.isLoading;
      state.isSelectDashboardDirty = Object.prototype.hasOwnProperty.call(
        payload,
        'isSelectDashboardDirty'
      )
        ? payload.isSelectDashboardDirty
        : state.isSelectDashboardDirty;
      state.selectedDashboards = Object.prototype.hasOwnProperty.call(
        payload,
        'selectedDashboards'
      )
        ? payload.selectedDashboards
        : state.selectedDashboards;
    },
  },
});

export const { setUserDashboardsForm } = userFormDashboardsSlice.actions;

export const userFormDashboardsReducer = userFormDashboardsSlice.reducer;
