import React from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import * as Yup from 'yup';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';

import {
  BillingItem,
  PaymentStatus,
  PaymentType,
} from '../Interface/billing.interface';
import {
  createNewPaymentRequest,
  editPaymentRequest,
  getPaymentStatus,
  getPaymentType,
} from '../service/billing.service';
import { syntheticEvent } from '../../../../utils/event.helper';
import { ACTION } from '../../../../constants/form-actions.constant';

interface PaymentRequestsFormProps {
  dialogFormObj: {
    visible: boolean;
    formAction: string | null;
    index: number | null;
  };
  updateDialogFormObj: (
    // eslint-disable-next-line no-unused-vars
    newState: Partial<PaymentRequestsFormProps['dialogFormObj']>
  ) => void;
  paymentRequestsDisplayList: BillingItem[] | undefined;
  project_id: number;
  loadBillingData: () => void;
  toast: React.RefObject<Toast | null>;
}

const PaymentRequestsForm: React.FC<PaymentRequestsFormProps> = ({
  paymentRequestsDisplayList,
  project_id,
  loadBillingData,
  toast,
  dialogFormObj,
  updateDialogFormObj,
}) => {
  const [paymentStatusOptions, setPaymentStatusOptions] = React.useState<
    PaymentStatus[] | []
  >([]);
  const [paymentTypeOptions, setPaymentTypeOptions] = React.useState<
    PaymentType[] | []
  >([]);
  const [dropdownLoading, setDropdownLoading] = React.useState<boolean>(false);
  const [saveLoading, setSaveLoading] = React.useState<boolean>(false);

  const loadPaymentStatus = async () => {
    setDropdownLoading(true);
    const response = await getPaymentStatus('payment');
    if (!response?.error && response?.data) {
      setPaymentStatusOptions(response?.data);
    } else {
      toast.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: response?.message,
        life: 3000,
      });
    }
    setDropdownLoading(false);
  };
  const loadPaymentType = async () => {
    setDropdownLoading(true);
    const response = await getPaymentType();
    if (!response?.error && response?.data) {
      setPaymentTypeOptions(response?.data);
    } else {
      toast.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: response?.message,
        life: 3000,
      });
    }
    setDropdownLoading(false);
  };
  React.useEffect(() => {
    if (dialogFormObj.visible) {
      if (!paymentStatusOptions?.length) loadPaymentStatus();
      if (!paymentTypeOptions?.length) loadPaymentType();
    }
  }, [dialogFormObj.visible]);
  const PaymentValidationSchema = Yup.object().shape({
    payment_number: Yup.string().required('Payment Number is required'),
    status: Yup.object()
      .required('Payment Status is required')
      .test(
        'is-not-empty',
        'Payment Status is required',
        value => value && Object.keys(value).length > 0
      ),
    payment_type: Yup.object()
      .required('Payment Type is required')
      .test(
        'is-not-empty',
        'Payment Type is required',
        value => value && Object.keys(value).length > 0
      ),
    originating_po: Yup.number()
      .typeError('Originating PO must be a number')
      .min(1)
      .positive()
      .integer()
      .nullable(true),
    check_number: Yup.number()
      .typeError('Check Number must be a number')
      .min(1)
      .positive()
      .integer()
      .nullable(true),
    check_amount: Yup.number()
      .typeError('Check Amount must be a number')
      .min(1)
      .max(
        99999999.99,
        'Check Amount must be less than or equal to 99999999.99'
      )
      .nullable(true),
    requested_amount: Yup.number()
      .nullable()
      .transform((value, originalValue) => {
        if (originalValue === undefined || originalValue === '') return null;
        return Number(originalValue);
      })
      .typeError('Requested Amount must be a valid number')
      .min(1, 'Requested Amount must be at least 1')
      .max(
        99999999.99,
        'Requested Amount must be less than or equal to 99999999.99'
      )
      .required('Requested Amount is required'),
  });

  // **** Formik Form Values ****
  const paymentRequestsFormik = useFormik<BillingItem>({
    initialValues: {
      payment_number: '',
      status: {},
      check_date: null,
      check_number: null,
      payment_type: {},
      originating_po: null,
      check_amount: null,
      requested_amount: null,
    },
    onSubmit: async values => {
      setSaveLoading(true);
      let response;

      if (dialogFormObj.formAction === ACTION.ADD) {
        response = await createNewPaymentRequest(project_id, values);
      } else {
        const index = dialogFormObj.index ?? null;
        const paymentRequestId =
          index !== null &&
          paymentRequestsDisplayList?.[index]?.payment_request_id
            ? paymentRequestsDisplayList[index]?.payment_request_id
            : null;
        response = await editPaymentRequest(
          project_id,
          values,
          paymentRequestId
        );
      }
      if (!response?.error && response.data) {
        toast.current?.show({
          severity: 'success',
          summary: 'Success',
          detail: response.message,
          life: 3000,
        });
        paymentRequestsFormik.handleReset(syntheticEvent);
        updateDialogFormObj({ visible: false });
        loadBillingData();
      } else {
        toast.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: response.message,
          life: 3000,
        });
      }
      setSaveLoading(false);
    },
    validationSchema: PaymentValidationSchema,
    enableReinitialize: true,
    initialTouched: {},
  });

  React.useEffect(() => {
    const formValues: BillingItem = {
      payment_number: '',
      status: {}, // or provide an empty object or default value
      check_date: null,
      check_number: null,
      payment_type: {}, // or provide a default value
      originating_po: null,
      check_amount: null,
      requested_amount: null,
    };

    // Only proceed if index and formAction are valid
    if (
      dialogFormObj.formAction !== ACTION.ADD &&
      dialogFormObj.index != null // Handles both null and undefined
    ) {
      const currentPaymentRequest =
        paymentRequestsDisplayList?.[dialogFormObj.index];

      if (currentPaymentRequest) {
        formValues.payment_number = currentPaymentRequest.payment_number ?? '';

        // Find status based on the current payment status
        formValues.status =
          paymentStatusOptions.find(
            status =>
              status.status_id === currentPaymentRequest.payment_status_id
          ) ?? {}; // Fallback to empty object if not found

        // Handle check_date correctly (either Date or a fallback Date)
        if (currentPaymentRequest.check_date == null) {
          formValues.check_date = null; // Handle the case where check_date is null or undefined
        } else if (currentPaymentRequest.check_date instanceof Date) {
          formValues.check_date = currentPaymentRequest.check_date;
        } else {
          formValues.check_date = new Date(
            currentPaymentRequest.check_date as string | number
          );
        }
        formValues.check_number = currentPaymentRequest.check_number ?? null;

        // Find payment type
        formValues.payment_type =
          paymentTypeOptions?.find(
            type => type?.payment_type === currentPaymentRequest.payment_type
          ) ?? {}; // Fallback to empty object if not found

        formValues.originating_po =
          currentPaymentRequest.originating_po ?? null;
        formValues.check_amount = currentPaymentRequest.check_amount ?? null;
        formValues.requested_amount =
          currentPaymentRequest.requested_amount ?? null;
      }
    }

    // Set the form values to Formik
    paymentRequestsFormik.setValues(formValues);
  }, [
    dialogFormObj.index,
    dialogFormObj.visible,
    dialogFormObj.formAction,
    paymentStatusOptions,
    paymentTypeOptions,
  ]);

  const footerContent = (
    <div>
      <Button
        label="Cancel"
        onClick={e => {
          updateDialogFormObj({ visible: false });
          paymentRequestsFormik.handleReset(e);
        }}
        type="button"
        size="small"
      />
      <Button
        label="Save"
        onClick={() => paymentRequestsFormik.handleSubmit()}
        loading={saveLoading}
        autoFocus
        disabled={
          !(paymentRequestsFormik?.dirty && paymentRequestsFormik?.isValid) ||
          dialogFormObj.formAction === ACTION.VIEW
        }
        size="small"
        type="button"
      />
    </div>
  );

  const handleDateChange = (value: Date | null | undefined) => {
    // Convert UTC date to local date
    return value
      ? paymentRequestsFormik.setFieldValue('check_date', value)
      : null;
  };

  return (
    <Dialog
      header={
        dialogFormObj.formAction === ACTION.ADD
          ? 'Add Payment Request'
          : dialogFormObj.formAction === ACTION.EDIT
            ? 'Edit Payment Request'
            : 'View Payment Request'
      }
      pt={{
        header: { className: 'border-bottom-1 border-400 py-1' },
        headerTitle: { className: 'text-base' },
        content: { className: 'overflow-visible pb-1' },
      }}
      visible={dialogFormObj.visible}
      style={{ width: '25vw' }}
      onHide={() => {
        if (!dialogFormObj.visible) return;
        updateDialogFormObj({ visible: false });
        paymentRequestsFormik.handleReset(syntheticEvent);
      }}
      footer={footerContent}
    >
      <form className="mt-2">
        <div className="col-12">
          <span className="p-float-label w-full">
            <InputText
              id="payment_number"
              value={paymentRequestsFormik?.values?.payment_number || ''}
              disabled={dialogFormObj.formAction === ACTION.VIEW}
              className="w-full"
              onChange={e => {
                paymentRequestsFormik.handleBlur(e);
                paymentRequestsFormik.setFieldValue(
                  'payment_number',
                  e.target.value
                );
              }}
            />
            <label htmlFor="payment_number" className="text-xs">
              Payment Number<span className="text-red-500">*</span>
            </label>
          </span>
          {paymentRequestsFormik.touched.payment_number &&
          paymentRequestsFormik.errors.payment_number ? (
            <div className="text-xs text-red-500">
              {paymentRequestsFormik.errors.payment_number}
            </div>
          ) : null}
        </div>
        <div className="col-12">
          <span className="p-float-label w-full">
            <Dropdown
              value={paymentRequestsFormik.values.status}
              id="status"
              onChange={paymentRequestsFormik.handleChange}
              onBlur={paymentRequestsFormik.handleBlur}
              options={paymentStatusOptions}
              optionLabel="status"
              className="w-full"
              showClear
              disabled={dialogFormObj.formAction === ACTION.VIEW}
              appendTo={() => document.body}
              emptyMessage={
                dropdownLoading ? (
                  <div className="flex justify-center py-2">
                    <ProgressSpinner
                      style={{ width: '20px', height: '20px' }}
                    />
                    <span className="ml-2">Loading...</span>
                  </div>
                ) : (
                  'No Payment Status available'
                )
              }
            />
            <label htmlFor="status" className="text-xs">
              Payment Status<span className="text-red-500">*</span>
            </label>
          </span>
          {paymentRequestsFormik.touched.status &&
          paymentRequestsFormik.errors.status ? (
            <div className="text-xs text-red-500">
              {paymentRequestsFormik.errors.status}
            </div>
          ) : null}
        </div>
        <div className="col-12">
          <span className="p-float-label w-full">
            <Dropdown
              value={paymentRequestsFormik.values.payment_type}
              id="payment_type"
              onChange={paymentRequestsFormik.handleChange}
              onBlur={paymentRequestsFormik.handleBlur}
              disabled={dialogFormObj.formAction === ACTION.VIEW}
              options={paymentTypeOptions}
              optionLabel="payment_type"
              className="w-full"
              showClear
              emptyMessage={
                dropdownLoading ? (
                  <div className="flex justify-center py-2">
                    <ProgressSpinner
                      style={{ width: '20px', height: '20px' }}
                    />
                    <span className="ml-2">Loading...</span>
                  </div>
                ) : (
                  'No Payment Type available'
                )
              }
            />
            <label htmlFor="payment_type" className="text-xs">
              Payment Type<span className="text-red-500">*</span>
            </label>
          </span>
          {paymentRequestsFormik.touched.payment_type &&
          paymentRequestsFormik.errors.payment_type ? (
            <div className="text-xs text-red-500">
              {paymentRequestsFormik.errors.payment_type}
            </div>
          ) : null}
        </div>
        <div className="col-12">
          <span className="p-float-label w-full">
            <InputNumber
              inputId="originating_po"
              className="w-full"
              inputClassName="w-full"
              placeholder=""
              value={paymentRequestsFormik.values.originating_po}
              onValueChange={e =>
                paymentRequestsFormik.setFieldValue(
                  'originating_po',
                  e.value ? Number(e.value) : ''
                )
              }
              disabled={dialogFormObj.formAction === ACTION.VIEW}
              useGrouping={false}
              min={1}
            />
            <label htmlFor="originating_po" className="text-xs">
              Originating Labor PO
            </label>
          </span>
          {paymentRequestsFormik.touched.originating_po &&
          paymentRequestsFormik.errors.originating_po ? (
            <div className="text-xs text-red-500">
              {paymentRequestsFormik.errors.originating_po}
            </div>
          ) : null}
        </div>
        <div className="col-12">
          <span className="p-float-label w-full">
            <Calendar
              id="check_date"
              name="check_date"
              className="w-full"
              selectionMode="single"
              onChange={e => {
                handleDateChange(e?.value);
              }}
              disabled={dialogFormObj.formAction === ACTION.VIEW}
              value={paymentRequestsFormik?.values?.check_date}
            />
            <label htmlFor="check_date">Check Date</label>
          </span>
        </div>
        <div className="col-12">
          <span className="p-float-label w-full">
            <InputNumber
              inputId="check_number"
              className="w-full"
              inputClassName="w-full"
              placeholder=""
              value={paymentRequestsFormik.values.check_number}
              onBlur={paymentRequestsFormik.handleBlur}
              onValueChange={e =>
                paymentRequestsFormik.setFieldValue(
                  'check_number',
                  e.value ? Number(e.value) : ''
                )
              }
              useGrouping={false}
              min={1}
              disabled={dialogFormObj.formAction === ACTION.VIEW}
            />
            <label htmlFor="check_number" className="text-xs">
              Check Number
            </label>
          </span>
          {paymentRequestsFormik.touched.check_number &&
          paymentRequestsFormik.errors.check_number ? (
            <div className="text-xs text-red-500">
              {paymentRequestsFormik.errors.check_number}
            </div>
          ) : null}
        </div>

        <div className="col-12 w-full">
          <span className="p-float-label w-full">
            <InputNumber
              inputId="check_amount"
              className="w-full"
              inputClassName="w-full"
              placeholder=""
              mode="currency"
              currency="USD"
              value={paymentRequestsFormik.values.check_amount}
              onBlur={paymentRequestsFormik.handleBlur}
              onValueChange={e =>
                paymentRequestsFormik.setFieldValue(
                  'check_amount',
                  e.value ? Number(e.value) : ''
                )
              }
              disabled={dialogFormObj.formAction === ACTION.VIEW}
              useGrouping={false}
              min={1}
            />
            <label htmlFor="check_amount" className="text-xs">
              Check Amount
            </label>
          </span>
          {paymentRequestsFormik.touched.check_amount &&
          paymentRequestsFormik.errors.check_amount ? (
            <div className="text-xs text-red-500">
              {paymentRequestsFormik.errors.check_amount}
            </div>
          ) : null}
        </div>
        <div className="col-12">
          <span className="p-float-label w-full">
            <InputNumber
              inputId="requested_amount"
              inputClassName="w-full"
              className="w-full"
              placeholder=""
              mode="currency"
              currency="USD"
              value={paymentRequestsFormik.values.requested_amount}
              onChange={e => {
                paymentRequestsFormik.setTouched({
                  requested_amount: true,
                });
                const value = e.value;

                paymentRequestsFormik
                  .setFieldValue(
                    'requested_amount',
                    value ? Number(value) : null
                  )
                  .then(() => {
                    paymentRequestsFormik.validateField('requested_amount');
                  });
              }}
              disabled={dialogFormObj.formAction === ACTION.VIEW}
              useGrouping={false}
              min={1}
            />
            <label htmlFor="requested_amount" className="text-xs">
              Requested Amount<i className="text-red-400">*</i>
            </label>
          </span>
          {paymentRequestsFormik.touched.requested_amount &&
          paymentRequestsFormik.errors.requested_amount ? (
            <div className="text-xs text-red-500">
              {paymentRequestsFormik.errors.requested_amount}
            </div>
          ) : null}
        </div>
      </form>
    </Dialog>
  );
};
export default PaymentRequestsForm;
