import React, { useRef } from 'react';
import { useParams } from 'react-router';
import { FileUpload } from 'primereact/fileupload';
import { Image } from 'primereact/image';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Button } from 'primereact/button';

import {
  addEditChargeback,
  fetchPhotos,
  uploadPhotos,
} from '../../services/ChargebackService';
import SkeletonLoader from '../../../../shared/Loader/skeleton';
import { useConfirmDialogContext } from '../../../../../contexts/ConfirmDialog';

const Photos = ({ toast, disabledFields }) => {
  const { showConfirmationDialog } = useConfirmDialogContext();
  const [files, setFiles] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [refreshList, setRefreshList] = React.useState(false);
  const { chargebackId } = useParams();
  const fileUpload = useRef(null);

  // Helper to upload photos
  const uploadSavePhotos = async files => {
    if (files?.length) {
      setLoading(true);
      const uploadPhotosResponse = await uploadPhotos(chargebackId, files);

      if (uploadPhotosResponse?.statusCode === 200) {
        toast?.current?.show({
          severity: 'success',
          summary: uploadPhotosResponse?.message,
          life: 2000,
        });
        setRefreshList(prevState => !prevState);
      } else setLoading(false);
    }
  };
  // Helper to delete photos
  const confirmDelete = value => {
    showConfirmationDialog({
      message:
        'Please confirm if you would like to cancel your unsaved changes or delete this record?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        const deleteResponse = await addEditChargeback(
          {
            photos: {
              chargeback_photo_id: value,
            },
          },
          chargebackId,
          true
        );
        if (deleteResponse?.status) {
          toast?.current?.show({
            severity: 'success',
            summary: deleteResponse?.message,
            life: 2000,
          });
          setRefreshList(prevState => !prevState);
        } else {
          toast?.current?.show({
            severity: 'error',
            summary: deleteResponse?.message,
            life: 2000,
          });
        }
      },
    });
  };
  const headerTemplate = options => {
    const { className, chooseButton, uploadButton, cancelButton } = options;
    return (
      <div
        className={className}
        style={{
          backgroundColor: 'transparent',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {loading ? (
          <div className="flex gap-3">
            <SkeletonLoader
              columnCount={1}
              columnWidth="8rem"
              columnHeight="3rem"
            />
            <SkeletonLoader
              columnCount={1}
              columnWidth="8rem"
              columnHeight="3rem"
            />
            <SkeletonLoader
              columnCount={1}
              columnWidth="8rem"
              columnHeight="3rem"
            />
          </div>
        ) : (
          <>
            {chooseButton}
            <Button {...uploadButton.props} />
            <Button {...cancelButton.props} icon={null} outlined />
          </>
        )}
      </div>
    );
  };
  const callFetchPhotos = async () => {
    setLoading(true);
    let response = await fetchPhotos(chargebackId);
    setFiles(response?.data?.result);
    setLoading(false);
  };
  React.useEffect(() => {
    callFetchPhotos();
  }, [refreshList]);

  return (
    <div className="card mt-3">
      <div className="flex flex-wrap">
        {files?.length
          ? files?.map(file => {
              return (
                <>
                  <div className="col-2 border-500 border-1 m-2">
                    <div
                      id={file?.chargeback_photo_id}
                      className="opacity-1 flex justify-content-end pb-2"
                    >
                      <i
                        className="pi pi-trash cursor-pointer text-red-500"
                        disabled={disabledFields}
                        onClick={() => confirmDelete(file?.chargeback_photo_id)}
                      ></i>
                    </div>

                    <Image
                      key={file?.chargeback_photo_id}
                      src={file?.url}
                      alt="User Image"
                      imageStyle={{ width: '100%', height: '150px' }}
                    />
                  </div>
                </>
              );
            })
          : null}
      </div>

      <FileUpload
        ref={fileUpload}
        multiple
        customUpload
        accept="image/*"
        maxFileSize={1000000}
        emptyTemplate={
          !disabledFields && (
            <p className="m-0">Drag and drop files to here to upload.</p>
          )
        }
        uploadHandler={e => {
          uploadSavePhotos(e?.files);
          fileUpload.current.clear();
        }}
        disabled={disabledFields}
        headerTemplate={headerTemplate}
      />
      <ConfirmDialog />
    </div>
  );
};

export default Photos;
