import React, { useEffect, useState, useRef } from 'react';
import './technician.css';
import { useParams } from 'react-router';
import { Avatar } from 'primereact/avatar';
import { Skeleton } from 'primereact/skeleton';

import PFButton from '../../shared/PFPrime/PFButton';
import {
  checkDefaultPo,
  formatDateTimeMDY,
  formatNumber,
  formatTimeHM,
} from '../../../utils/Helpers';
import apiService from '../../../services/api.service';
import PFTableLoader from '../../shared/Loader/PFTableLoader';

import {
  getDefaultPo,
  getLaborItems,
  getProjectInstallers,
} from './ProjectItemsService';

const TechnicianPurchaseOrder = () => {
  const { projectId, technicianId, technicianUserId } = useParams();
  const toast = useRef(null);

  const clientDetails = JSON.parse(localStorage.getItem('client_details'));
  const [loading, setLoading] = useState(true);
  const [projectDetails, setProjectDetails] = useState([]);
  const [laborItemsList, setLaborItemsList] = useState([]);
  const [technicianInfo, setTechinicanInfo] = useState([]);
  const [statusType, setStatusType] = useState([]);
  const [storeData, setStoreData] = useState([]);
  const [scheduleInfo, setScheduleInfo] = useState([]);
  const [defaultPoList, setDefaultPoList] = useState([]);

  const currentPath = window?.location?.pathname;

  const fetchProjectDetails = async () => {
    try {
      setLoading(true);
      const response = await apiService.get(`/projects/${projectId}`);

      if (response) {
        setProjectDetails(response);
      }
    } catch (err) {
      toast.current.show({
        severity: 'error',
        summary: err,
        detail: 'Something went wrong.',
        life: '2500',
      });
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  function getUniqueObjects(dataList) {
    const uniqueObjects = new Map();

    dataList?.forEach(item => {
      const projectItemId = item?.project_item_id;
      const projectItemUserId = item?.project_item_user?.project_item_user_id;

      if (projectItemId !== undefined && projectItemUserId !== undefined) {
        const key = `${projectItemId}-${projectItemUserId}`;
        if (!uniqueObjects?.has(key)) {
          uniqueObjects?.set(key, item);
        }
      } else if (
        projectItemId !== undefined &&
        !uniqueObjects?.has(projectItemId?.toString())
      ) {
        uniqueObjects?.set(projectItemId?.toString(), item);
      }
    });

    return Array.from(uniqueObjects.values());
  }
  const getUniqueList = list => {
    const technicianItems = list?.filter(list => {
      return (
        list?.project_item_user &&
        list?.project_item_user?.installer_details?.installer_id ==
          technicianId &&
        (!list?.project_item_user ||
          list?.project_item_user?.labor_item_visibility === 1)
      );
    });
    return technicianItems;
  };

  const getTechnicianDetails = async technicianId => {
    try {
      setLoading(true);
      const response = await apiService.get(
        `/auth/user/manage/${technicianId}`
      );
      if (response) {
        setTechinicanInfo(response);
      }
    } catch (err) {
      toast.current.show({
        severity: 'error',
        summary: err,
        detail: 'Something went wrong.',
        life: '2500',
      });
    } finally {
      setLoading(false);
    }
  };
  const getStatus = async () => {
    try {
      setLoading(true);
      const response = await apiService.get(
        `/system/status?statusType=ProjectItem`
      );
      if (response) {
        setStatusType(response);
      }
    } catch (err) {
      toast.current.show({
        severity: 'error',
        summary: err,
        detail: 'Something went wrong.',
        life: '2500',
      });
    } finally {
      setLoading(false);
    }
  };

  const getStoreData = async storeId => {
    try {
      setLoading(true);
      const response = await apiService.get(`/stores/${storeId}`);
      if (response) {
        setStoreData(response);
      }
    } catch (err) {
      toast.current.show({
        severity: 'error',
        summary: err,
        detail: 'Something went wrong.',
        life: '2500',
      });
    } finally {
      setLoading(false);
    }
  };

  const currentDate = new Date();

  const day = currentDate.getDate();
  const month = currentDate.getMonth() + 1;
  const year = currentDate.getFullYear();
  const formattedDate = `${month}-${day}-${year}`;
  useEffect(() => {
    fetchProjectDetails();
    getLaborItems(projectId)
      .then(res => {
        const uniqueObjects = getUniqueObjects(
          res?.data?.status && res?.data?.status === true ? res?.data?.data : []
        );

        const uniqueList = getUniqueList(uniqueObjects);

        if (uniqueList?.length) {
          setLaborItemsList(uniqueList);
        }
      })
      .catch(err => {
        setLaborItemsList([]);
        console.error(err);
      });
    getTechnicianDetails(technicianUserId);
    getStatus();

    getProjectInstallers(projectId)
      .then(res => {
        const response = res?.data?.find(list => {
          return list?.installer_id == technicianId;
        });

        setScheduleInfo(response);
      })

      .catch(err => {
        setScheduleInfo([]);
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (projectDetails && projectDetails?.store) {
      getStoreData(projectDetails?.store?.store_id);
    }
  }, [projectDetails]);

  const tableData = laborItemsList?.length
    ? laborItemsList.map(data => {
        return {
          item_number: data?.item?.item_number,
          item_desc: data?.item?.item_desc,
          item_quantity: data?.project_item_user?.quantity,
          labor_total: data?.project_item_user?.labor_cost_ea_value
            ? `$${formatNumber(
                !isNaN(parseFloat(data?.project_item_user?.labor_cost_ea_value))
                  ? data.project_item_user?.labor_cost_ea_value
                  : 0,
                2,
                true
              )}`
            : `$${formatNumber(0, 2, true)}`,
          status: data?.project_item_user?.status_id
            ? statusType?.find(status => {
                return status?.status_id === data?.project_item_user?.status_id;
              })?.status
            : '',
          completion_date:
            data?.project_item_user?.status_id === 255
              ? data?.project_item_user?.completion_date &&
                formatDateTimeMDY(data?.project_item_user?.completion_date)
              : null,
          note: data?.project_item_user?.note_text,
        };
      })
    : [];
  let laborTotal = 0;
  for (let i = 0; i < laborItemsList?.length; i++) {
    const li = laborItemsList[i];
    if (li?.project_item_user?.labor_cost_ea_value) {
      laborTotal += li.project_item_user?.labor_cost_ea_value * 1;
    }
  }

  let defaultPolaborTotal = 0;
  for (let i = 0; i < defaultPoList?.length; i++) {
    const li = defaultPoList[i];
    if (li?.labor_cost_ea_value) {
      defaultPolaborTotal += li?.labor_cost_ea_value * 1;
    }
  }

  const convertTo12HourFormat = timeString => {
    const [hours, minutes] = timeString?.split(':') || [];

    let parsedHours = parseInt(hours, 10);

    const period = parsedHours >= 12 ? 'PM' : 'AM';

    parsedHours = parsedHours % 12 || 12;

    const paddedMinutes = minutes?.padStart(2, '0');

    const twelveHourTimeString = `${parsedHours}:${paddedMinutes} ${period}`;

    return twelveHourTimeString;
  };
  useEffect(() => {
    if (projectId) {
      getDefaultPo(projectId, setDefaultPoList);
    }
  }, [projectId]);

  return (
    <>
      {!loading ? (
        <div className="labor-container w-12" id="labor-container">
          <div className="header">
            <table className="w-12">
              <tr>
                <td>
                  <h3>{clientDetails?.client_name}</h3>
                </td>
                <td className="text-right">
                  <h1 className="text-2xl my-0 pt-0 text-blue">
                    Technician Purchase Order
                  </h1>
                  <h5 className="m-0">{formattedDate}</h5>
                </td>
              </tr>
            </table>
          </div>

          <div className="grid px-3 py-5 bg-white m-0">
            {/* Project Information */}
            <div className="col-6" style={{ borderRight: '1px solid #bdbdbd' }}>
              <h3 className="text-blue m-0">Project Information</h3>
              {projectDetails?.source_system?.logo_url !== null ? (
                <img
                  src={projectDetails?.source_system?.logo_url}
                  alt={projectDetails?.source_system?.source_name}
                  className="my-3"
                />
              ) : null}
              <table>
                <tr>
                  <td className="text-xs text-grey w-7rem">
                    <span className="icon-container">
                      <i
                        className="pi pi-file text-xs pi-container"
                        aria-hidden="true"
                      ></i>
                    </span>
                    Project# :
                  </td>
                  <td>
                    <strong className="text-grey text-xs">
                      {' '}
                      {projectDetails?.project_number}
                    </strong>
                  </td>
                </tr>
                {/* More rows */}
                <tr>
                  <td className="text-xs text-grey w-8rem">
                    <i
                      className="pi pi-file text-xs pi-container"
                      aria-hidden="true"
                    ></i>{' '}
                    PO# :
                  </td>
                  <td>
                    <strong className="text-grey text-xs">
                      {projectDetails?.po_number
                        ? projectDetails.po_number
                        : ''}
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td className="text-xs text-grey w-8rem">
                    <i
                      className="pi pi-shopping-cart text-xs pi-container"
                      aria-hidden="true"
                    ></i>
                    Store Name:
                  </td>
                  <td>
                    <strong className="text-grey text-xs">
                      {storeData?.store_name ? storeData?.store_name : ''}
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td className="text-xs text-grey w-8rem">
                    <i
                      className="pi pi-map-marker text-xs pi-container"
                      aria-hidden="true"
                    ></i>
                    Store Address:
                  </td>
                  <td>
                    <strong className="text-grey text-xs">
                      {storeData
                        ? `${storeData?.address1} ${storeData?.city}
                          ${storeData?.state}`
                        : ''}
                    </strong>
                  </td>
                </tr>
              </table>
            </div>

            <div className="col-6 pl-4">
              <h3 className="text-blue mt-0 mb-4">Customer Information</h3>
              <table>
                <tr>
                  <td className="text-xs text-grey w-9rem">
                    <i
                      className="pi pi-user  text-xs pi-container"
                      aria-hidden="true"
                    ></i>{' '}
                    Name :
                  </td>
                  <td>
                    <strong className="text-grey text-xs">
                      {projectDetails?.customer
                        ? `${projectDetails?.customer?.first_name} ${projectDetails?.customer?.last_name}`
                        : ''}
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td className="text-xs text-grey w-9rem">
                    <i
                      className="pi pi-phone  text-xs pi-container"
                      aria-hidden="true"
                    ></i>{' '}
                    Contact Number:
                  </td>
                  <td>
                    <strong className="text-grey text-xs">
                      {projectDetails?.customer
                        ? `${projectDetails?.customer?.primary_phone}`
                        : ''}
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td className="text-xs text-grey w-9rem">
                    <i
                      className="pi pi-envelope  text-xs pi-container"
                      aria-hidden="true"
                    ></i>
                    Email:
                  </td>
                  <td>
                    <strong className="text-grey text-xs word_break">
                      {projectDetails?.customer?.customer_emails
                        ? projectDetails?.customer?.customer_emails?.email
                        : ''}
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td className="text-xs text-grey w-7rem">
                    <i
                      className="pi pi-map-marker text-xs pi-container"
                      aria-hidden="true"
                    ></i>
                    Address:
                  </td>
                  <td>
                    <strong className="text-grey text-xs">
                      {projectDetails?.customer?.address
                        ? `${projectDetails?.customer?.address?.address1}, ${projectDetails?.customer?.address?.city},
                          ${projectDetails?.customer?.address?.state}`
                        : ''}
                    </strong>
                  </td>
                </tr>
              </table>
            </div>

            {/* More sections */}
            <div
              style={{ borderBottom: '1px solid #bdbdbd' }}
              className="w-12 my-5"
            ></div>

            <div
              className="col-12 lg:col-6 md:h-12rem"
              style={{ borderRight: ' 1px solid #bdbdbd' }}
            >
              <h3 className="text-blue mt-0 mb-4">Schedule Information</h3>
              {checkDefaultPo(currentPath) ? null : (
                <>
                  <table>
                    <tr className="pt-2">
                      <td className="text-xs text-grey w-7rem">From:</td>
                      <td>
                        <i
                          className="pi pi-calendar text-xs pi-container"
                          aria-hidden="true"
                        ></i>
                        <strong className="text-grey text-xs">
                          {scheduleInfo?.date_scheduled_start
                            ? `${formatDateTimeMDY(scheduleInfo?.date_scheduled_start)} `
                            : ''}
                        </strong>
                      </td>
                      <td className="pl-2">
                        <i
                          className="pi pi-clock text-xs pi-container"
                          aria-hidden="true"
                        ></i>
                        <strong className="text-grey text-xs">
                          {scheduleInfo?.date_scheduled_start
                            ? ` ${formatTimeHM(scheduleInfo?.date_scheduled_start)}`
                            : ''}
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-xs text-grey w-7rem">To:</td>
                      <td>
                        <i
                          className="pi pi-calendar text-xs pi-container"
                          aria-hidden="true"
                        ></i>
                        <strong className="text-grey text-xs">
                          {scheduleInfo?.date_scheduled_end
                            ? `${formatDateTimeMDY(scheduleInfo?.date_scheduled_end)} `
                            : ''}
                        </strong>
                      </td>
                      <td className="pl-2">
                        <i
                          className="pi pi-clock text-xs pi-container"
                          aria-hidden="true"
                        ></i>
                        <strong className="text-grey text-xs">
                          {scheduleInfo?.date_scheduled_end
                            ? ` ${formatTimeHM(scheduleInfo?.date_scheduled_end)}`
                            : ''}
                        </strong>
                      </td>
                    </tr>
                  </table>

                  <table>
                    <tr>
                      <td className="text-xs text-grey w-7rem">Arriving At:</td>
                      <td>
                        {scheduleInfo?.installer_arrival_start_time ? (
                          <>
                            <i
                              className="pi pi-clock text-xs pi-container"
                              aria-hidden="true"
                            ></i>
                            <strong className="text-grey text-xs">
                              {convertTo12HourFormat(
                                scheduleInfo?.installer_arrival_start_time
                              )}
                            </strong>
                          </>
                        ) : null}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-xs text-grey w-7rem">
                        Expected Completion At:
                      </td>
                      <td>
                        {scheduleInfo?.installer_arrival_end_time ? (
                          <>
                            <i
                              className="pi pi-clock text-xs pi-container"
                              aria-hidden="true"
                            ></i>
                            <strong className="text-grey text-xs">
                              {convertTo12HourFormat(
                                scheduleInfo?.installer_arrival_end_time
                              )}
                            </strong>
                          </>
                        ) : null}
                      </td>
                    </tr>
                  </table>
                </>
              )}
            </div>

            <div className="col-12 lg:col-6 pl-4">
              <h3 className="text-blue mt-0 mb-4">Technician Information</h3>
              {checkDefaultPo(currentPath) ? null : (
                <div className="flex">
                  <div className="mr-2 mt-2">
                    {technicianInfo?.profile_image_url !== null ? (
                      <img
                        src={technicianInfo?.profile_image_url}
                        alt={`${technicianInfo?.first_name}  ${technicianInfo.last_name}`}
                        className="w-3rem"
                      />
                    ) : (
                      <Avatar
                        label={`${technicianInfo?.first_name[0]}  ${technicianInfo.last_name[0]}`}
                        className="w-2rem text-xs bg-primary uppercase"
                        style={{}}
                        shape="circle"
                      />
                    )}
                  </div>
                  <table>
                    <tr>
                      <td className="text-xs text-grey w-9rem">
                        <i
                          className="pi pi-user text-xs pi-container"
                          aria-hidden="true"
                        ></i>{' '}
                        Name :
                      </td>
                      <td>
                        <strong className="text-grey text-xs">
                          {technicianInfo?.first_name
                            ? `${technicianInfo?.first_name}  ${technicianInfo.last_name}`
                            : ''}
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-xs text-grey w-9rem">
                        <i
                          className="pi pi-phone  text-xs pi-container"
                          aria-hidden="true"
                        ></i>
                        Contact Number:
                      </td>
                      <td>
                        <strong className="text-grey text-xs">
                          {technicianInfo?.contact_no
                            ? technicianInfo?.contact_no
                            : ''}
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-xs text-grey w-9rem">
                        <i
                          className="pi pi-envelope  text-xs pi-container"
                          aria-hidden="true"
                        ></i>
                        Email:
                      </td>
                      <td>
                        <strong className="text-grey text-xs word_break">
                          {technicianInfo?.email ? technicianInfo?.email : ''}
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-xs text-grey w-9rem">
                        <i
                          className="pi pi-map-marker  text-xs pi-container"
                          aria-hidden="true"
                        ></i>
                        Address:
                      </td>
                      <td>
                        <strong className="text-grey text-xs">
                          {technicianInfo?.home_address
                            ? technicianInfo?.home_address
                            : ''}
                        </strong>
                      </td>
                    </tr>
                  </table>
                </div>
              )}
            </div>

            <div
              style={{ borderBottom: '1px solid #bdbdbd' }}
              className="w-12 my-5"
            ></div>

            <div className="flex align-items-center text-sm text-blue">
              To Be Paid:
              <h3 className="text-3xl my-0 text-blue pl-2">{`$${
                checkDefaultPo(currentPath)
                  ? defaultPolaborTotal?.toFixed(2)
                  : laborTotal?.toFixed(2)
              }`}</h3>
            </div>

            <p className="text-blue w-12">Labor Items</p>
            {/* Default PO start */}
            {checkDefaultPo(currentPath) ? (
              <table
                className="w-12 table labor-table text-center"
                style={{ fontSize: '14px', textAlign: 'left' }}
              >
                <thead className="labor-table text-blue">
                  <tr>
                    <th className="labor-table">Item No</th>
                    <th className="labor-table">Description</th>
                    <th className="labor-table">Quantity</th>
                    <th className="labor-table">Labor Total</th>
                    <th className="labor-table">Status</th>
                    <th className="labor-table">Completion Date</th>
                  </tr>
                </thead>
                <tbody>
                  {defaultPoList?.map((row, index) => {
                    return (
                      <tr key={index}>
                        <td className="labor-table">
                          {row?.item?.item_number}
                        </td>
                        <td className="labor-table">{row?.item?.item_desc}</td>
                        <td className="labor-table">{row?.item_quantity}</td>
                        <td className="labor-table">${row?.labor_cost_ea}</td>
                        <td className="labor-table">{row?.status}</td>
                        <td className="labor-table">{row?.completion_date}</td>
                      </tr>
                    );
                  })}
                </tbody>
                <tfoot className="bg-blue-800 text-white">
                  <tr>
                    <td className="labor-table">
                      <strong className="text-white">Total</strong>
                    </td>
                    <td className="labor-table" colSpan="1"></td>
                    <td className="labor-table" colSpan="1"></td>
                    <td className="labor-table">
                      <strong className="text-white">{`$${
                        checkDefaultPo(currentPath)
                          ? defaultPolaborTotal?.toFixed(2)
                          : laborTotal?.toFixed(2)
                      }`}</strong>
                    </td>
                    <td className="labor-table" colSpan="1"></td>

                    <td className="labor-table" colSpan="1"></td>
                  </tr>
                </tfoot>
              </table>
            ) : null}
            {/* Default PO end*/}

            {checkDefaultPo(currentPath) ? null : (
              <table
                className="w-12 table labor-table text-center"
                style={{ fontSize: '14px', textAlign: 'left' }}
              >
                <thead className="labor-table text-blue">
                  <tr>
                    <th className="labor-table">Item No</th>
                    <th className="labor-table">Description</th>
                    <th className="labor-table">Quantity</th>
                    <th className="labor-table">Labor Total</th>
                    <th className="labor-table">Status</th>
                    <th className="labor-table">Completion Date</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData?.map((row, index) => {
                    return (
                      <tr key={index}>
                        <td className="labor-table">{row?.item_number}</td>
                        <td className="labor-table">{row?.item_desc}</td>
                        <td className="labor-table">{row?.item_quantity}</td>
                        <td className="labor-table">{row?.labor_total}</td>
                        <td className="labor-table">{row?.status}</td>
                        <td className="labor-table">{row?.completion_date}</td>
                      </tr>
                    );
                  })}
                </tbody>
                <tfoot className="bg-blue-800 text-white">
                  <tr>
                    <td className="labor-table">
                      <strong className="text-white">Total</strong>
                    </td>
                    <td className="labor-table" colSpan="1"></td>
                    <td className="labor-table" colSpan="1"></td>
                    <td className="labor-table">
                      <strong className="text-white">{`$${laborTotal?.toFixed(2)}`}</strong>
                    </td>
                    <td className="labor-table" colSpan="1"></td>

                    <td className="labor-table" colSpan="1"></td>
                  </tr>
                </tfoot>
              </table>
            )}
          </div>
        </div>
      ) : (
        <>
          <div className="w-12 flex justify-content-center mb-4">
            <Skeleton width="98%" height="6rem"></Skeleton>
          </div>
          <div className="w-12 flex justify-content-around">
            <Skeleton width="48%" height="10rem"></Skeleton>
            <Skeleton width="48%" height="10rem"></Skeleton>
          </div>
          <div className="w-12 flex justify-content-around mt-5 mb-4">
            <Skeleton width="48%" height="10rem"></Skeleton>
            <Skeleton width="48%" height="10rem"></Skeleton>
          </div>
          <PFTableLoader />
        </>
      )}
      <div className="text-center flex w-12 justify-content-center">
        <PFButton
          severity="primary"
          size="small"
          label="Print"
          onClick={() => window.print()}
          id="print-btn"
        />
      </div>
    </>
  );
};

export default TechnicianPurchaseOrder;
