import React, { useRef, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { useFormik } from 'formik';
import type { FormikProps } from 'formik';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { InputNumber } from 'primereact/inputnumber';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';

import {
  filterMasterData,
  getFieldType,
  isUpdateDisabled,
} from '../constant/constant';
import { bulkUpdateApiCall } from '../service/widget.service';
import { encrypt } from '../../../../utils/security.helper';
import {
  BulkUpdateWidgetReportTypes,
  FormValues,
} from '../types/bulk-update-widget-report.types';
import {
  Field,
  FilteredValue,
  FilterMasterDataList,
} from '../types/render-column-fields.types';

const BulkUpdateWidgetReport: React.FC<BulkUpdateWidgetReportTypes> = ({
  tableConfiguration,
  selectedCells,
  masterData,
  annotation,
  reportTablePrimaryKey,
  refreshDashboardList,
  showAlert,
  setSelectedCells,
}) => {
  const filterMasterDataList = filterMasterData(masterData);
  const toast = useRef<Toast | null>(null);
  const [visible, setVisible] = useState(false);

  const filterEditableRecords = () => {
    if (!annotation) {
      return [];
    }
    return (
      tableConfiguration?.filter(
        item =>
          !!(
            item?.measureName &&
            annotationHasValue &&
            annotation?.[item.measureName]?.meta?.isEditable
          )
      ) || []
    );
  };

  const formik = useFormik<FormValues>({
    initialValues: {
      fields: [
        {
          columnField: '',
          dropdownField: '',
          inputField: '',
          inputFieldNumber: null,
        },
      ],
    },
    onSubmit: async (values, { resetForm }) => {
      const isLength =
        values?.fields?.length > 0 && Array.isArray(values?.fields);
      const transformData = (
        data: FormValues['fields']
      ): { column: string; value?: string | number | Date[] }[] => {
        return isLength
          ? data?.map(field => {
              const records = filterEditableRecords();

              const measureName =
                (Array.isArray(records) &&
                  records?.length > 0 &&
                  records?.find(item => item?.alice === field?.columnField)
                    ?.measureName) ||
                '';

              const isColumnReference =
                annotation[measureName]?.meta?.columnReference;
              const newObj: {
                column: string;
                value?: string | number | Date[];
              } = {
                column: field?.columnField,
              };
              if (isColumnReference?.column) {
                newObj.column = isColumnReference.column;
              }

              const table = isColumnReference?.column
                ? isColumnReference.table
                : '';
              if (field?.dropdownField) newObj.value = field?.dropdownField;
              if (field?.inputField) newObj.value = field?.inputField;
              if (field?.inputFieldNumber)
                newObj.value = field?.inputFieldNumber;

              const finalObj = { ...newObj, table };
              return finalObj;
            })
          : [];
      };

      const formikData = annotationHasValue && transformData(values?.fields);
      const projectNumbers =
        Array.isArray(selectedCells) &&
        selectedCells?.map(item => String(item[reportTablePrimaryKey]));

      const payload = {
        project_ids: projectNumbers,
        editable_update: formikData,
      };

      try {
        const jsonStringify = JSON.stringify(payload);
        const response = await bulkUpdateApiCall({
          update_encrypted_data: encrypt(jsonStringify),
        });

        if (response && response?.status === true) {
          showAlert('success', 'Success!', response?.message);
          refreshDashboardList();
          resetForm();
          setSelectedCells([]);
        } else {
          showAlert('warn', 'Error!', response?.message);
        }
      } catch (error) {
        console.error(error);
      }
    },
  });

  const addField = () => {
    formik.setFieldValue('fields', [
      ...formik.values.fields,
      {
        columnField: '',
        dropdownField: '',
        inputField: '',
        inputFieldNumber: null,
      },
    ]);
  };

  const removeField = (index: number) => {
    const newFields = [...formik.values.fields];
    newFields.splice(index, 1);
    formik.setFieldValue('fields', newFields);
  };
  const selectedColumnValues = formik.values.fields.map(
    field => field.columnField
  );

  const annotationHasValue = annotation ? Object.keys(annotation)?.length : 0;

  const renderFieldByType = (
    fieldType: string,
    field: Field,
    index: number,
    formik: FormikProps<FormValues>,
    filterMasterDataList: FilterMasterDataList,
    filteredValue: FilteredValue | null
  ) => {
    switch (fieldType) {
      case 'dropdown':
        return (
          <>
            <Dropdown
              value={field.dropdownField}
              options={
                filterMasterDataList[filteredValue?.measureName || ''] ?? []
              }
              onChange={e => {
                formik.setFieldValue(
                  `fields.${index}.dropdownField`,
                  e?.target?.value
                );
              }}
              filter
              placeholder="Select a value..."
              className="w-full"
              pt={{
                input: {
                  className: 'height-40',
                },
              }}
            />
          </>
        );
      case 'string':
        return (
          <>
            <InputText
              value={field.inputField}
              onChange={e => {
                formik.setFieldValue(
                  `fields.${index}.inputField`,
                  e?.target?.value
                );
              }}
              placeholder="Enter the value"
              className="w-full"
            />
          </>
        );

      case 'number':
        return (
          <InputNumber
            onValueChange={e => {
              formik.setFieldValue(
                `fields.${index}.inputFieldNumber`,
                e?.value
              );
            }}
            onChange={e => {
              formik.setFieldValue(
                `fields.${index}.inputFieldNumber`,
                e?.value
              );
            }}
            maxFractionDigits={5}
            value={field.inputFieldNumber}
            placeholder="Enter a number"
            className="w-full"
          />
        );
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        header="Confirmation Message"
        visible={visible}
        className="w-11 lg:w-4"
        onHide={() => {
          if (!visible) return;
          setVisible(false);
        }}
        draggable={false}
      >
        <p>
          Are you sure you want to update the selected
          <span className="mx-1">{selectedCells?.length}</span>
          record(s) in bulk ? This action cannot be undone.
        </p>
        <div className="flex justify-content-end">
          <Button
            label="Cancel"
            className="p-button-text"
            onClick={() => setVisible(false)}
          />
          <Button
            label="Confirm"
            className="p-button-primary"
            onClick={() => {
              setVisible(false);
              formik.handleSubmit();
            }}
          />
        </div>
      </Dialog>
      <Accordion className="mb-3">
        <AccordionTab header="Multi Record Edit">
          <form className="p-fluid">
            <>
              <div className="grid">
                {formik?.values?.fields?.map((field, index) => {
                  const filteredValue = filterEditableRecords()?.find(
                    item => item?.alice === field?.columnField
                  );
                  const fieldType = getFieldType(
                    filteredValue?.measureName || '',
                    filterMasterDataList,
                    annotation
                  );
                  return (
                    <div key={index} className="col-12 lg:col-6 pb-0">
                      <div className="grid w-full">
                        <div className="col-12 md:col xl:col">
                          <Dropdown
                            value={field?.columnField}
                            options={filterEditableRecords()
                              ?.filter(item => item?.alice !== '')
                              ?.map(item => ({
                                ...item,
                                disabled: selectedColumnValues?.includes(
                                  item?.alice
                                ),
                              }))}
                            optionLabel="alice"
                            optionValue="alice"
                            onChange={e => {
                              formik.setFieldValue(
                                `fields.${index}.columnField`,
                                e.value
                              );
                            }}
                            placeholder="Select a value..."
                            className="w-full"
                            pt={{
                              input: {
                                className: 'height-40',
                              },
                            }}
                            filter
                          />
                        </div>
                        <div className="col-12 md:col xl:col">
                          {renderFieldByType(
                            fieldType,
                            field,
                            index,
                            formik,
                            filterMasterDataList,
                            filteredValue ?? null
                          )}
                        </div>
                        <div className="col-fixed flex w-8rem">
                          <Button
                            type="button"
                            outlined
                            icon="pi pi-minus-circle"
                            severity="danger"
                            className="p-button-sm w-3rem"
                            onClick={() => removeField(index)}
                            disabled={formik?.values?.fields?.length === 1}
                          />

                          {index === formik?.values?.fields?.length - 1 &&
                            formik?.values?.fields?.length > 0 && (
                              <Button
                                type="button"
                                icon="pi pi-plus-circle"
                                outlined
                                onClick={addField}
                                disabled={
                                  formik?.values?.fields?.length >=
                                  filterEditableRecords()?.length
                                }
                                className="p-button-primary w-3rem ml-2"
                                size="small"
                              />
                            )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
            <div className="flex align-items-center">
              <Button
                type="button"
                label="Update"
                className="p-button-primary w-auto p-button-sm mt-1"
                disabled={isUpdateDisabled(
                  selectedCells,
                  formik?.values?.fields,
                  filterEditableRecords,
                  filterMasterDataList,
                  annotation
                )}
                onClick={() => setVisible(true)}
              />
              <p className="mb-0 ml-2 mt-0">
                {selectedCells?.length > 0
                  ? selectedCells?.length
                  : selectedCells?.length}
                <span className="ml-1">Records are selected for updating.</span>
              </p>
            </div>
          </form>
        </AccordionTab>
      </Accordion>
    </>
  );
};

export default BulkUpdateWidgetReport;
