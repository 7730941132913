import PFTabView from '../../shared/PFPrime/PFTabView';

import SMSComponent from './components/SMSComponent';
import EmailComponent from './components/EmailComponent';
import NotesComponent from './components/NotesComponent';

const CommunicationsContainer = ({
  customerId,
  communicationType,
  communicationId,
  opportunityId,
  quoteId,
  invoiceId,
  paymentId,
  primaryEmailAddress,
}) => {
  const CommunicationModel = {
    tabs: [
      { header: 'Notes', disabled: false },
      { header: 'SMS', disabled: false },
      { header: 'Email', disabled: false },
    ],
    components: {
      Notes: (
        <NotesComponent
          customerId={customerId}
          communicationType={communicationType}
          communicationId={communicationId}
          opportunityId={opportunityId}
          quoteId={quoteId}
          invoiceId={invoiceId}
          paymentId={paymentId}
        />
      ),
      SMS: (
        <SMSComponent
          customerId={customerId}
          communicationType={communicationType}
          communicationId={communicationId}
          opportunityId={opportunityId}
          quoteId={quoteId}
          invoiceId={invoiceId}
          paymentId={paymentId}
        />
      ),
      Email: (
        <EmailComponent
          customerId={customerId}
          communicationType={communicationType}
          communicationId={communicationId}
          opportunityId={opportunityId}
          quoteId={quoteId}
          invoiceId={invoiceId}
          paymentId={paymentId}
          primaryEmailAddress={primaryEmailAddress}
        />
      ),
    },
  };
  return (
    <div>
      <PFTabView model={CommunicationModel} />
    </div>
  );
};

export default CommunicationsContainer;
