import React, { useState } from 'react';

import { formateNumber } from '../helpers/Quotes';
import PFInputNumber from '../../../shared/PFPrime/PFInputNumber';
import PFFormikError from '../../../shared/PFPrime/PFFormikError';
const TaxEditor = ({
  rowData,
  editingRows,
  submitAction,
  onBlurTax,
  retailerPercentage,
}) => {
  const { values, touched, errors, setFieldValue, setValues } = submitAction;
  const [value, setValue] = useState(formateNumber(values?.tax));
  const isEditing = editingRows[rowData.rowIndex];
  return isEditing ? (
    <div className="vertical-align-top">
      <span className="p-float-label">
        <PFInputNumber
          inputId="tax"
          name="tax"
          minFractionDigits={2}
          value={value}
          mode={'decimal'}
          onChange={(name, value) => setValue(value)}
          onBlur={() => {
            setFieldValue('tax', value);
            onBlurTax(value, values, setValues, retailerPercentage);
          }}
          className={touched?.tax && errors?.tax ? 'error' : ''}
          version={2}
          inputStyle={{ width: '100px' }}
          suffix="%"
        />
      </span>
      <PFFormikError touched={touched} errors={errors} field="tax" />
    </div>
  ) : (
    <div className="pt-2">
      <div className="w-full">{`${formateNumber(rowData?.tax).toFixed(2)}%`}</div>
      <div className="w-full">
        <span className="font-bold">{`$${formateNumber(rowData?.taxAmount).toFixed(2)}`}</span>
      </div>
    </div>
  );
};

export default TaxEditor;
