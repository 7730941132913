import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import { URL_CONSTANTS } from '../constants/urlConstants';
import { setQuoteStatus as statusAction } from '../redux/slices/quoteStatusMaster.slice';

const useQuoteStatus = () => {
  const dispatch = useDispatch();
  const { status } = useSelector(state => state?.masters?.quoteStatusReducer);
  const [quoteStatus, setQuoteStatus] = useState(status);

  const getQuoteStatus = async () => {
    try {
      if (quoteStatus.length === 0) {
        const response = await axios.get(
          `${URL_CONSTANTS.API.BASE_URL}/system/status?statusType=Quote`
        );
        setQuoteStatus(response?.data);
        dispatch(statusAction(response?.data));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getQuoteStatus();
  }, []);

  return { quoteStatus };
};

export default useQuoteStatus;
