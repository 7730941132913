import * as Yup from 'yup';

export const contactInitialValues = {
  contactPerson: '',
  primaryPhone: '',
  alternativePhone: '',
  primaryEmail: '',
  alternativeEmail: '',
  primaryAddress: '',
};

export const contactValidationSchema = {
  contactPerson: Yup.string().max(50).required('Required'),
  primaryPhone: Yup.string().max(150).required('Required'),
  alternativePhone: Yup.string().max(150).nullable(),
  primaryEmail: Yup.string().email('must be a valid email').max(150),
  alternativeEmail: Yup.string()
    .email('must be a valid email')
    .max(150)
    .nullable(),
  primaryAddress: Yup.number().required('Required'),
};
