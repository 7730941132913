import React, { useCallback } from 'react';
import { DataTable } from 'primereact/datatable';
import type {
  DataTableExpandedRows,
  DataTablePageEvent,
  DataTableValueArray,
} from 'primereact/datatable';
import { Column } from 'primereact/column';
import type { ColumnBodyOptions } from 'primereact/column';
import { Skeleton } from 'primereact/skeleton';
import { InputText } from 'primereact/inputtext';
import { debounce } from 'lodash';
import { Divider } from 'primereact/divider';

import {
  ColumnMeta,
  EmailApiResponse,
  EmailItem,
  SearchQuery,
} from '../Interface/email.interface';
import { renderDate } from '../../../../utils/dates.helper';

const EmailTable: React.FC<{
  loading: boolean;
  // eslint-disable-next-line no-unused-vars
  loadEmailData: (query: SearchQuery) => Promise<void>;
  emailList: EmailApiResponse | null;
}> = ({ loading, loadEmailData, emailList }) => {
  const [searchQuery, setSearchQuery] = React.useState({
    limit: 10,
    offset: 0,
    search: '',
    first: 0,
    rows: 10,
  });

  const getFilteredQuery = (
    query: SearchQuery
  ): Omit<SearchQuery, 'first' | 'rows'> => {
    const { search, limit, offset } = query;
    return search
      ? { limit: limit, offset: offset, search: search }
      : { limit: limit, offset: offset };
  };
  const [expandedRows, setExpandedRows] = React.useState<
    DataTableExpandedRows | DataTableValueArray | undefined
  >(undefined);

  const debounceFetchEmail = useCallback(
    debounce(query => {
      const queryParam = getFilteredQuery(query);
      console.log(query, 'QUERY');
      loadEmailData(queryParam);
    }, 500),
    [] // Empty array ensures it's only created once
  );

  React.useEffect(() => {
    debounceFetchEmail(searchQuery);
  }, [searchQuery, debounceFetchEmail]);

  // Dynamic body template
  const dynamicBodyTemplate = (
    rowData: EmailItem,
    options: ColumnBodyOptions
  ): React.ReactNode => {
    if (loading) {
      return <Skeleton width="100%" height="1.5rem" />;
    }

    if (options.field === 'created_at') {
      return renderDate(rowData[options.field], 'yyyy-MM-dd hh:mm:ss a');
    }
    if (options.field === 'name') {
      return rowData['LastEmail']['from'][0]['name'];
    }
    if (options.field === 'reviewed') {
      return (
        <span className="text-green-600">
          {rowData['LastEmail']['reviewed'] ? 'Reviewed' : null}
        </span>
      );
    }
    if (options.field === 'attachment') {
      return (
        <span>
          {rowData['LastEmail']['attachments']?.length ? (
            <span>
              <i className="pi pi-paperclip"></i>
            </span>
          ) : null}
        </span>
      );
    }

    // Default rendering for other fields
    const value = rowData[options.field as keyof EmailItem];
    return typeof value === 'string' || typeof value === 'number'
      ? value
      : null;
  };
  const onPageChange = (event: DataTablePageEvent) => {
    setSearchQuery(prev => ({
      ...prev,
      offset: event?.page ? event?.page * event?.rows : 0,
      limit: event.rows,
      first: event.first,
      rows: event.rows,
    }));
  };
  const allowExpansion = () => {
    return true;
  };

  const columns: ColumnMeta[] = [
    {
      field: 'name',
      header: 'Name',
      expander: true,
    },
    {
      field: 'subject',
      header: 'Subject',
    },
    {
      field: 'created_at',
      header: 'Date',
    },
    {
      field: 'reviewed',
      header: 'Status',
      hide: emailList?.items.some(item => item.LastEmail.reviewed === 1)
        ? false
        : true,
    },
    {
      field: 'attachment',
      header: '',
    },
  ];
  const header = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <h3 className="text-base font-bold">Emails</h3>
      <div className="flex align-items-center gap-2">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            className="p-inputtext-sm"
            placeholder="Search keyword"
            value={searchQuery?.search ? searchQuery?.search : ''}
            onChange={e => {
              setSearchQuery(prev => ({
                ...prev,
                search: e.target.value,
              }));
            }}
          />
        </span>
      </div>
    </div>
  );
  const downloadPdf = (url: string, fileName: string) => {
    try {
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      link.target = '_blank';
      link.click();
    } catch (e) {
      console.error('Error in downloading email attachment', e);
    }
  };
  const rowExpansionTemplate = (data: EmailItem) => {
    return (
      <>
        <div className=" align-items-start flex-column lg:justify-content-between lg:flex-row">
          <div className="grid">
            <div className="col-2">
              <div className="text-500 font-medium">Sender:</div>
            </div>
            <div className="col-6">
              <div>
                {Array.isArray(data.LastEmail.from) &&
                data.LastEmail.from?.length
                  ? data.LastEmail.from?.map((email, index) => {
                      return (
                        <span key={index}>
                          <span className="font-bold">{email.name || ''}</span>
                          <span> &lt;{email.address || ''}&gt;</span>
                        </span>
                      );
                    })
                  : null}
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="col-2">
              <div className="text-500 font-medium">To:</div>
            </div>
            <div className="col-6">
              <div>
                {data.LastEmail.to?.length
                  ? data.LastEmail.to?.map((email, index) => {
                      return (
                        <span key={index}>
                          <span className="font-bold">{email.name || ''}</span>
                          <span> &lt;{email.address || ''}&gt;</span>
                        </span>
                      );
                    })
                  : null}
              </div>
            </div>
          </div>
          {data?.LastEmail?.attachments?.length ? (
            <div className="grid">
              <div className="col-2">
                <div className="text-500 font-medium">Attachments:</div>
              </div>
              {data?.LastEmail?.attachments?.map((attachment, index) => {
                const emailAttachment = attachment as {
                  preSignedUrl: string;
                  fileName: string;
                };
                return (
                  <button
                    key={index}
                    className="mr-5 mt-1 flex items-center cursor-pointer text-primary bg-transparent border-none p-0"
                    onClick={event => {
                      event.preventDefault();
                      if (emailAttachment?.preSignedUrl) {
                        downloadPdf(
                          emailAttachment.preSignedUrl,
                          emailAttachment.fileName
                        );
                      }
                    }}
                  >
                    <i className="pi pi-download mr-2"></i>
                    <span className="hover:underline">
                      {emailAttachment?.fileName}
                    </span>
                  </button>
                );
              })}
            </div>
          ) : null}
          {data?.LastEmail?.reviewed ? (
            <div>
              <span className="text-500 font-medium">
                Reviewed by{' '}
                <span className="font-bold">
                  {data?.LastEmail?.reviewed_by?.first_name || null}{' '}
                  {data?.LastEmail?.reviewed_by?.last_name || null}
                </span>
              </span>
              On&nbsp;
              <span className="font-bold">
                {data?.LastEmail?.reviewed_at
                  ? renderDate(
                      data?.LastEmail?.reviewed_at,
                      'yyyy-MM-dd hh:mm:ss a'
                    )
                  : null}
              </span>
            </div>
          ) : null}
        </div>
        <Divider />
        <div
          dangerouslySetInnerHTML={{
            __html: data.LastEmail.body_text.replace(/\n/g, '<br>'),
          }}
        ></div>
      </>
    );
  };

  return (
    <div className="col-12 mt-4">
      <DataTable
        value={emailList?.items}
        totalRecords={emailList?.count}
        header={header}
        paginator
        rows={searchQuery.rows}
        rowsPerPageOptions={[10, 20, 50, 100]}
        onPage={onPageChange}
        first={searchQuery.first}
        expandedRows={expandedRows}
        onRowToggle={e => setExpandedRows(e.data)}
        rowExpansionTemplate={rowExpansionTemplate}
        lazy
      >
        <Column expander={allowExpansion} style={{ width: '3rem' }} />
        {columns.map(col =>
          !col.hide ? (
            <Column
              key={col.field}
              field={col.field}
              header={col.header}
              body={dynamicBodyTemplate}
              expander={col.expander}
            />
          ) : null
        )}
      </DataTable>
    </div>
  );
};

export default EmailTable;
