import { useFormik } from 'formik';
import * as Yup from 'yup';

export const initialValues = {
  itemId: '',
  itemTypeId: '',
  itemDescription: '',
  itemNumber: '',
  quantity: '',
  unitPrice: '',
  totalCost: 0,
  retailEach: '',
  totalRetail: 0,
  discountType: 'Amount',
  discount: 0,
  totalDiscount: 0,
  tax: 0,
  taxAmount: 0,
  totalRevenue: 0,
  margin: 0,
  totalPrice: 0,
  rowIndex: 0,
  revenueFromAPI: 0,
};
const validationSchema = () => {
  return Yup.object().shape({
    itemId: Yup.number().required('required'),
    quantity: Yup.number().required('required').nullable(),
    unitPrice: Yup.number().required('required').nullable(),
    retailEach: Yup.number().required('required').nullable(),
    discountType: Yup.string().nullable(),
    discount: Yup.number().nullable(),
    tax: Yup.number().nullable(),
    margin: Yup.number().nullable().default(0),
  });
};

export const itemSubmit = (
  setGridData,
  setIsForm,
  formInitValue,
  setEditingRows,
  setFormInitValue,
  setItems
) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formInitValue,
    validationSchema: () => validationSchema(formik.values),
    onSubmit: values => {
      if (values?.rowIndex > 0) {
        setGridData(preValues => {
          const index = preValues.findIndex(
            obj => obj.rowIndex === values?.rowIndex
          );
          preValues[index] = values;
          formik?.resetForm();
          setEditingRows({});
          setIsForm(false);
          setItems([...preValues]);
          return [...preValues];
        });
      } else {
        setGridData(preValues => {
          const data = preValues?.filter(i => i?.rowIndex != 0);
          values.rowIndex = data?.length + 1;
          formik?.resetForm();
          setEditingRows({});
          setIsForm(false);
          setItems([values, ...data]);
          return [values, ...data];
        });
      }
    },
  });

  return formik;
};
