import React, { useState } from 'react';

import SMSList from './SMSList';

const SMSComponent = ({
  customerId,
  communicationType,
  communicationId,
  opportunityId,
  quoteId,
  invoiceId,
  paymentId,
  isVersionActive,
}) => {
  const [reload, setReload] = useState(false);
  return (
    <div className="p-3">
      <SMSList
        customerId={customerId}
        reload={reload}
        communicationType={communicationType}
        communicationId={communicationId}
        setReload={setReload}
        opportunityId={opportunityId}
        quoteId={quoteId}
        invoiceId={invoiceId}
        paymentId={paymentId}
        isVersionActive={isVersionActive}
      />
    </div>
  );
};

export default SMSComponent;
