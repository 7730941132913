import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: true,
  toggleButtonLabel: 'Select All',
  clientList: [],
};

const warrantyConfigSlice = createSlice({
  name: 'warrantyConfig',
  initialState: initialState,
  reducers: {
    warrantyConfigUpdateClientList(state, { payload }) {
      state.clientList = Object.prototype.hasOwnProperty.call(
        payload,
        'clientList'
      )
        ? payload.clientList
        : state.clientList;
    },
    warrantyConfigUpdateComponent(state, { payload }) {
      state.isLoading = Object.prototype.hasOwnProperty.call(
        payload,
        'isLoading'
      )
        ? payload.isLoading
        : state.isLoading;
      state.toggleButtonLabel = Object.prototype.hasOwnProperty.call(
        payload,
        'toggleButtonLabel'
      )
        ? payload.toggleButtonLabel
        : state.toggleButtonLabel;
    },
  },
});

export const { warrantyConfigUpdateClientList, warrantyConfigUpdateComponent } =
  warrantyConfigSlice.actions;

export const warrantyConfigReducer = warrantyConfigSlice.reducer;
