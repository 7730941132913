import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userList: [],
  nextUserId: null,
  prevUserId: null,
  nextPageOffset: null,
  prevPageOffset: null,
  userSearchQuery: {
    limit: 10,
    offset: 0,
    searchString: '',
    sortKey: 'created_user_id',
    sortDirection: 'ASC',
  },
  userSelectedFilters: { statusIds: [18] },
};

const userManagementSlice = createSlice({
  name: 'userManagement',
  initialState: initialState,
  reducers: {
    setUserManagementComponent(state, { payload }) {
      state.userList = Object.prototype.hasOwnProperty.call(payload, 'userList')
        ? payload.userList
        : state.userList;
      state.nextUserId = Object.prototype.hasOwnProperty.call(
        payload,
        'nextUserId'
      )
        ? payload.nextUserId
        : state.nextUserId;
      state.prevUserId = Object.prototype.hasOwnProperty.call(
        payload,
        'prevUserId'
      )
        ? payload.prevUserId
        : state.prevUserId;
      state.userSearchQuery = Object.prototype.hasOwnProperty.call(
        payload,
        'userSearchQuery'
      )
        ? payload.userSearchQuery
        : state.userSearchQuery;
      state.userSelectedFilters = Object.prototype.hasOwnProperty.call(
        payload,
        'userSelectedFilters'
      )
        ? payload.userSelectedFilters
        : state.userSelectedFilters;
      state.nextPageOffset = Object.prototype.hasOwnProperty.call(
        payload,
        'nextPageOffset'
      )
        ? payload.nextPageOffset
        : state.nextPageOffset;
      state.prevPageOffset = Object.prototype.hasOwnProperty.call(
        payload,
        'prevPageOffset'
      )
        ? payload.prevPageOffset
        : state.prevPageOffset;
    },
  },
});

export const { setUserManagementComponent } = userManagementSlice.actions;

export const userManagementReducer = userManagementSlice.reducer;
