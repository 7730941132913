import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import {
  Grid,
  Button,
  TextField,
  Select,
  MenuItem,
  IconButton as MuiIconButton,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Close as MuiCloseIcon } from '@material-ui/icons';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { Skeleton } from 'primereact/skeleton';
import { Card } from 'primereact/card';

import {
  LEAD_RESPONSE_REASON,
  StatusColors,
  SOURCE_SYSTEMS,
  workTypeConstants,
} from '../../../constants';
import LrrpConfigurationForm from '../../Admin/Configuration/LrrpConfigurationComponents/index';
import { formikProjectMapping } from '../../Admin/Configuration/LrrpConfiguration.constant';
import ApiService from '../../../services/api.service';
import { updateProject } from '../ProjectInfo/ProjectDetails.service';
import { useAlerts } from '../../shared/Alerts/alertsService';
import GenericLeadReasonDataTable from '../../Admin/Configuration/LrrpConfigurationComponents/GenericLeadReasonDataTable';

import { getHdEpaDocs, getHomeDepotOverrideReasons } from './EPALead.service';
import HomeDepotFields from './HomeDepotFields';

const validationSchema = () =>
  Yup.object().shape({
    epa_lead_assessment_required: Yup.number().optional().nullable(),
    epa_lead_remediation: Yup.number().optional().nullable(),

    tested_lead_negative: Yup.object().when('lead_response_reason', {
      is: 'Tested Lead Negative',
      then: Yup.object().shape({
        test_kit_manufacturer: Yup.string(),
        component_location_tested: Yup.string(),
        test_info: Yup.array()
          .of(
            Yup.object().shape({
              lead_result: Yup.string().required('Lead result is required'),
              test_kit_manufacturer: Yup.string().required(
                'Test kit manufacturer is required'
              ),
              component_location_tested: Yup.string().required(
                'Component location tested is required'
              ),
            })
          )
          .min(1),
      }),
      otherwise: Yup.object().shape({
        test_kit_manufacturer: Yup.string(),
        component_location_tested: Yup.string(),
      }),
    }),

    tested_lead_positive: Yup.object().when('lead_response_reason', {
      is: 'Tested Lead Positive',
      then: Yup.object().shape({
        test_kit_manufacturer: Yup.string().trim(),
        component_location_tested: Yup.string().trim(),
        notification_confirmation: Yup.string().required(
          'Notification confirmation is required'
        ),
        is_common_area_involved: Yup.string()
          .oneOf(['yes', 'no', 'N/A'])
          .required(),
        reason_common_area: Yup.string()
          .trim()
          .when('is_common_area_involved', {
            is: 'N/A',
            then: Yup.string().required('Reason for N/A is required'),
            otherwise: Yup.string(),
          }),
        work_area_signage: Yup.string().oneOf(['yes', 'no', 'N/A']).required(),
        signage_explanation: Yup.string()
          .trim()
          .when('work_area_signage', {
            is: 'N/A',
            then: Yup.string().required('Reason for N/A is required'),
            otherwise: Yup.string(),
          }),
        prohibited_practices: Yup.string()
          .oneOf(['yes', 'no', 'N/A'])
          .required(),
        waste_contained: Yup.string().oneOf(['yes', 'no', 'N/A']).required(),
        worksite_properly_cleaned: Yup.string()
          .oneOf(['yes', 'no', 'N/A'])
          .required(),
        post_renovation_cleaning_method: Yup.string()
          .oneOf(['Cleaning verification', 'Dust clearance'])
          .required(),
        cleaning_verification_results: Yup.string()
          .trim()
          .when('post_renovation_cleaning_method', {
            is: 'Cleaning verification',
            then: Yup.string().required(
              'Cleaning verification results is required'
            ),
            otherwise: Yup.string(),
          }),
        number_of_wet_cloths: Yup.string().when(
          'post_renovation_cleaning_method',
          {
            is: 'Cleaning verification',
            then: Yup.string().required('Number of wet cloths is required'),
            otherwise: Yup.string(),
          }
        ),
        number_of_dry_cloths: Yup.string().when(
          'post_renovation_cleaning_method',
          {
            is: 'Cleaning verification',
            then: Yup.string().required('Number of dry cloths is required'),
            otherwise: Yup.string(),
          }
        ),
        is_child_occupied_facility: Yup.string()
          .oneOf(['yes', 'no', 'N/A'])
          .required(),
        reason_child_facility: Yup.string()
          .trim()
          .when('is_child_occupied_facility', {
            is: 'N/A',
            then: Yup.string().required('Reason for N/A is required'),
            otherwise: Yup.string(),
          }),
        test_info: Yup.array()
          .of(
            Yup.object().shape({
              lead_result: Yup.string().required('Lead result is required'),
              test_kit_manufacturer: Yup.string().required(
                'Test kit manufacturer is required'
              ),
              component_location_tested: Yup.string().required(
                'Component location tested is required'
              ),
            })
          )
          .min(1),
      }),
      otherwise: Yup.object().shape({
        test_kit_manufacturer: Yup.string(),
        component_location_tested: Yup.string(),
      }),
    }),
    presumed_lead: Yup.object().shape({
      notification_confirmation: Yup.string(),
      is_common_area_involved: Yup.string().oneOf(['yes', 'no', 'N/A']),
      reason_common_area: Yup.string(),
      work_area_signage: Yup.string().oneOf(['yes', 'no', 'N/A']),
      signage_explanation: Yup.string(),
      work_areas_contained: Yup.string(),
      prohibited_practices: Yup.string().oneOf(['yes', 'no', 'N/A']),
      waste_contained: Yup.string().oneOf(['yes', 'no', 'N/A']),
      worksite_properly_cleaned: Yup.string(),
      post_renovation_cleaning_method: Yup.string(),
      number_of_wet_cloths: Yup.string(),
      number_of_dry_cloths: Yup.string(),
      cleaning_verification_results: Yup.string(),
      is_child_occupied_facility: Yup.string().oneOf(['yes', 'no', 'N/A']),
      reason_child_facility: Yup.string(),
    }),
    lead_response_reason: Yup.string()
      .notOneOf(['placeholder'])
      .required('lead_response_reason is required'),
    other: Yup.object().when('lead_response_reason', {
      is: 'Other',
      then: Yup.object().shape({
        lrrp_explanation: Yup.string().required('Lrrp Explanation is required'),
        document_required: Yup.string().oneOf(['yes', 'no', 'N/A']),
        wo_explanation: Yup.string().required(
          'Work Order Explanation is required'
        ),
      }),
    }),
  });

const EPALead = ({
  projectId,
  projectDetailsData,
  epaLeadReasontoUpdate,
  setEPALeadReasontoUpdate,
  setReloadForm,
  setOpenpopup,
  formik,
  redirectBasePath,
  setUnsavedTestInfo,
  unsavedTestInfo,
}) => {
  const [option, setOption] = useState('');
  const [loading, setLoading] = useState(true);
  const [showLRRPTriggerMessage, setShowLRRPTriggerMessage] = useState(false);

  const [homeDepotOverrideOptions, setHomeDepotOverrideOptions] = useState([]);
  const [hdEpaDocs, setHdEpaDocs] = useState([]);
  const [renovatorInfo, setRenovatorInfo] = useState(null);
  let history = useHistory();
  const { setAlert } = useAlerts();
  useEffect(async () => {
    if (
      projectDetailsData &&
      projectDetailsData?.source_system_id === SOURCE_SYSTEMS?.LOWES
    ) {
      const installerData = await ApiService.get(
        `/projects/${projectId}/project-installer`
      );
      if (installerData?.length > 0) {
        const scheduleInstaller = installerData?.find(
          item => item.worktypeid == workTypeConstants?.SCHEDULED
        );
        const schedulerUserId =
          scheduleInstaller?.installer_detail?.user_id || null;

        if (schedulerUserId) {
          const userDetailsRequest = await ApiService.get(
            `/auth/user/manage/${schedulerUserId}`
          );
          if (userDetailsRequest) {
            const { renovator_name, renovator_certification_number } =
              userDetailsRequest;
            setRenovatorInfo({
              name: renovator_name,
              certificate: renovator_certification_number,
            });
          }
        }
      }
    }
  }, [projectDetailsData]);

  const LrrpConfigurationFormik = useFormik({
    initialValues: {
      ...{
        epa_lead_assessment_required: '',
        epa_lead_remediation: '',
        lead_response_reason: '',

        tested_lead_negative: {
          test_kit_manufacturer: '',
          component_location_tested: '',
          test_info: [],
        },

        tested_lead_positive: {
          test_kit_manufacturer: '',
          component_location_tested: '',
          notification_confirmation: '',
          is_common_area_involved: 'no',
          reason_common_area: '',
          work_area_signage: 'no',
          signage_explanation: '',
          work_areas_contained: '',
          prohibited_practices: 'no',
          waste_contained: 'no',
          worksite_properly_cleaned: 'no',
          post_renovation_cleaning_method: '',
          number_of_wet_cloths: '',
          number_of_dry_cloths: '',
          cleaning_verification_results: '',
          is_child_occupied_facility: 'no',
          reason_child_facility: '',
          test_info: [],
        },
        presumed_lead: {
          notification_confirmation: '',
          is_common_area_involved: 'no',
          reason_common_area: '',
          work_area_signage: 'no',
          signage_explanation: '',
          work_areas_contained: '',
          prohibited_practices: 'no',
          waste_contained: 'no',
          worksite_properly_cleaned: 'no',
          post_renovation_cleaning_method: '',
          number_of_wet_cloths: '',
          number_of_dry_cloths: '',
          cleaning_verification_results: '',
          is_child_occupied_facility: 'no',
          reason_child_facility: '',
        },
        lswp_required: '',
        lead_test_req: '',
        lswp_followed: '',
        hd_lead_test_result: '',
        epa_override_reason_code_id: '',
        other: {
          lrrp_explanation: '',
          document_required: 'no',
          wo_explanation: '',
        },
      },
      ...(projectDetailsData &&
      projectDetailsData?.source_system_id === SOURCE_SYSTEMS?.LOWES
        ? {
            epa_renovator_name: '',
            epa_renovator_cert: '',
          }
        : {}),
    },
    onSubmit: async values => {
      setLoading(true);
      const key = values?.lead_response_reason
        ?.toLocaleLowerCase()
        ?.split(' ')
        .join('_');
      if (projectDetailsData?.source_system_id === SOURCE_SYSTEMS.HOME_DEPOT) {
        const response = await ApiService.post(
          `/system/lrrp-configuration/${projectId}`,
          {
            lswp_required: values?.lswp_required,
            lead_test_req: values?.lead_test_req,
            lswp_followed: values?.lswp_followed,
            hd_lead_test_result: values?.hd_lead_test_result,
            epa_override_reason_code_id: values?.epa_override_reason_code_id,
            year_built: values?.year_built,
          }
        );
        if (response === true && epaLeadReasontoUpdate) {
          updateProject(
            projectId,
            {
              ...formik.values,
              status: {
                item_type_id: null,
                note_category_id: null,
                status: 'Completed',
                status_id: 10,
                status_type: 'Project',
              },
              status_id: 10,
            },
            history,
            setReloadForm,
            setLoading,
            setAlert,
            redirectBasePath
          );
        }

        history.push(`/project/view/${projectId}`);
      } else {
        // For test Info Card - start
        const updatedValues = { ...values };
        if (key === 'tested_lead_negative' || key === 'tested_lead_positive') {
          if (
            updatedValues[key]?.test_info &&
            Array.isArray(updatedValues[key]?.test_info)
          ) {
            updatedValues[key].test_info = updatedValues[key].test_info.map(
              item => {
                const { ...rest } = item;
                return rest;
              }
            );
          }
        }
        // For test Info Card - end
        const response = await ApiService.post(
          `/system/lrrp-configuration/${projectId}`,
          {
            lead_response_reason: values.lead_response_reason,
            [key]: updatedValues[key],
            epa_lead_assessment_required: values?.epa_lead_assessment_required,
            epa_lead_remediation: values?.epa_lead_remediation,
            ...(projectDetailsData &&
            projectDetailsData?.source_system_id === SOURCE_SYSTEMS?.LOWES
              ? {
                  epa_renovator_name: values?.epa_renovator_name,
                  epa_renovator_cert: values?.epa_renovator_cert,
                }
              : {}),
          }
        );
        if (response === true && epaLeadReasontoUpdate) {
          updateProject(
            projectId,
            {
              ...formik.values,
              status: {
                item_type_id: null,
                note_category_id: null,
                status: 'Completed',
                status_id: 10,
                status_type: 'Project',
              },
              status_id: 10,
            },
            history,
            setReloadForm,
            setLoading,
            setAlert,
            redirectBasePath
          );
        }

        history.push(`/project/view/${projectId}`);
      }
      setUnsavedTestInfo([]);
      setOpenpopup(false);
      setEPALeadReasontoUpdate(false);
      setLoading(false);
    },
    enableReinitialize: true,
    validationSchema: value => validationSchema(value, projectDetailsData),
  });
  const getData = async () => {
    setLoading(true);
    const response = await ApiService.get(
      `/system/lrrp-configuration/${projectId}`
    );
    formikProjectMapping(LrrpConfigurationFormik, response);
    setOption(response.default_lead_response_reason);
    setLoading(false);
  };

  const getHomeDepotData = async () => {
    setLoading(true);
    const [overrideReasons, hdEpaDocsResponse] = await Promise.all([
      getHomeDepotOverrideReasons(),
      getHdEpaDocs(projectId),
    ]);
    setHomeDepotOverrideOptions(overrideReasons);
    setHdEpaDocs(hdEpaDocsResponse?.items);
    setLoading(false);
  };

  useEffect(() => {
    if (projectDetailsData?.source_system_id !== SOURCE_SYSTEMS.HOME_DEPOT) {
      getData();
    }
  }, [projectId]);

  useEffect(() => {
    if (
      projectDetailsData &&
      projectDetailsData.source_system_id === SOURCE_SYSTEMS.HOME_DEPOT
    ) {
      getHomeDepotData();
    }
  }, [projectId]);

  const triggerSubmit = async () => {
    setLoading(true);
    await ApiService.get(`/system/lrrp-configuration-trigger/${projectId}`);
    setShowLRRPTriggerMessage(true);
    setLoading(false);
  };

  const handleSubmitButton = async () => {
    await LrrpConfigurationFormik.handleSubmit();
    await triggerSubmit();
  };

  const projectOtherDetailsData =
    projectDetailsData?.project_type?.project_type_id === 3 &&
    projectDetailsData?.project_type?.project_type === 'Work Order';

  return (
    <>
      {showLRRPTriggerMessage &&
        projectDetailsData.source_system_id !== SOURCE_SYSTEMS.HOME_DEPOT && (
          <Grid item xs={12}>
            <Alert
              severity="success"
              action={
                <MuiIconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setShowLRRPTriggerMessage(false);
                  }}
                >
                  <MuiCloseIcon fontSize="inherit" />
                </MuiIconButton>
              }
            >
              Lead response reason is saved and would be passed to Source as
              soon as the activity gets open
            </Alert>
          </Grid>
        )}
      {projectDetailsData.source_system_id !== SOURCE_SYSTEMS.HOME_DEPOT && (
        <>
          <Card
            className="w-full"
            header={
              <div className="w-12 text-right p-1 flex justify-content-between align-items-center">
                <h3>Lead Detail</h3>
                {projectDetailsData &&
                  projectDetailsData?.source_system_id !==
                    SOURCE_SYSTEMS.HOME_DEPOT && (
                    <span>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={handleSubmitButton}
                        disabled={!LrrpConfigurationFormik?.isValid}
                      >
                        {epaLeadReasontoUpdate
                          ? 'Submit and Complete'
                          : 'Submit'}
                      </Button>
                    </span>
                  )}
              </div>
            }
            pt={{
              header: {
                className: 'pl-3 pr-3',
              },
              body: {
                className: 'pt-0',
              },
              content: {
                className: 'pt-0',
              },
            }}
          >
            <Grid
              container
              item
              direction="row"
              spacing={2}
              style={{ marginTop: '8px' }}
            >
              {projectDetailsData &&
                projectDetailsData?.source_system_id !==
                  SOURCE_SYSTEMS.HOME_DEPOT && (
                  <Grid item xs={6}>
                    <div className="grid flex-row">
                      <div className="col-12 lg:col-12">
                        <label
                          htmlFor="leadAssessment"
                          style={{
                            color: '#777e98',
                            fontSize: '0.9rem',
                            fontWeight: 400,
                          }}
                        >
                          Lead Assessment :
                        </label>
                        {loading ? (
                          <Skeleton className="w-full" height="2rem" />
                        ) : (
                          <Select
                            label="Lead Assessment"
                            value={
                              LrrpConfigurationFormik?.values
                                ?.epa_lead_assessment_required
                            }
                            onChange={event => {
                              LrrpConfigurationFormik.setFieldValue(
                                'epa_lead_assessment_required',
                                event.target?.value
                              );
                            }}
                            disabled={true}
                          >
                            <MenuItem value={1}>Yes</MenuItem>
                            <MenuItem value={0}>No</MenuItem>
                          </Select>
                        )}
                      </div>
                      <div className="col-12 lg:col-12">
                        <label
                          htmlFor="leadRemediation"
                          style={{
                            color: '#777e98',
                            fontSize: '0.9rem',
                            fontWeight: 400,
                          }}
                        >
                          Lead Remediation :
                        </label>
                        {loading ? (
                          <Skeleton className="w-full" height="2rem" />
                        ) : (
                          <Select
                            label="Lead Remediation"
                            value={
                              LrrpConfigurationFormik?.values
                                ?.epa_lead_remediation
                            }
                            onChange={event => {
                              LrrpConfigurationFormik.setFieldValue(
                                'epa_lead_remediation',
                                event.target.value
                              );
                            }}
                            disabled={true}
                          >
                            <MenuItem value={1}>Yes</MenuItem>
                            <MenuItem value={0}>No</MenuItem>
                          </Select>
                        )}
                      </div>
                    </div>
                  </Grid>
                )}
              {projectDetailsData &&
                projectDetailsData?.source_system_id ===
                  SOURCE_SYSTEMS?.LOWES && (
                  <Grid item xs={6}>
                    <div className="grid flex-row">
                      <div className="col-12 lg:col-12">
                        <label
                          htmlFor="renovatorName"
                          style={{
                            color: StatusColors?.PF_GRAY,
                            fontSize: '0.9rem',
                            fontWeight: 400,
                          }}
                        >
                          Renovator Name
                        </label>
                        {loading ? (
                          <Skeleton className="w-full" height="2rem" />
                        ) : (
                          <TextField
                            value={
                              LrrpConfigurationFormik?.values
                                ?.epa_renovator_name || renovatorInfo?.name
                            }
                            onChange={event => {
                              LrrpConfigurationFormik.setFieldValue(
                                'epa_renovator_name',
                                event.target?.value
                              );
                              setRenovatorInfo(null);
                            }}
                          />
                        )}
                      </div>
                      <div className="col-12 lg:col-12">
                        <label
                          style={{
                            color: StatusColors?.PF_GRAY,
                            fontSize: '0.9rem',
                            fontWeight: 400,
                          }}
                        >
                          Renovator Cert#{' '}
                        </label>
                        {loading ? (
                          <Skeleton className="w-full" height="2rem" />
                        ) : (
                          <TextField
                            value={
                              LrrpConfigurationFormik?.values
                                ?.epa_renovator_cert ||
                              renovatorInfo?.certificate
                            }
                            onChange={event => {
                              LrrpConfigurationFormik.setFieldValue(
                                'epa_renovator_cert',
                                event.target?.value
                              );
                              setRenovatorInfo(null);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </Grid>
                )}
              {projectDetailsData.source_system_id !==
                SOURCE_SYSTEMS.HOME_DEPOT && (
                <>
                  <Grid item xs={12} md={6}>
                    <label>
                      Lead Response Reason{' '}
                      <span style={{ color: '#f44336' }}>*</span>
                    </label>
                    {loading ? (
                      <Skeleton className="w-full" height="2rem" />
                    ) : (
                      <Select
                        label="Lead Response Reason"
                        value={
                          LrrpConfigurationFormik?.values?.lead_response_reason
                        }
                        onChange={event => {
                          LrrpConfigurationFormik.setFieldValue(
                            'lead_response_reason',
                            event.target.value
                          );
                          setOption(event.target.value);
                        }}
                      >
                        <MenuItem
                          key="placeholder"
                          value="placeholder"
                          disabled
                        >
                          <span style={{ color: '#9E9E9E' }}>
                            {' '}
                            Please make a selection
                          </span>
                        </MenuItem>
                        {LEAD_RESPONSE_REASON.map(reason =>
                          projectOtherDetailsData || reason !== 'Other' ? (
                            <MenuItem key={reason} value={reason}>
                              {reason}
                            </MenuItem>
                          ) : null
                        )}
                      </Select>
                    )}
                  </Grid>
                </>
              )}
            </Grid>
            {projectDetailsData.source_system_id !==
              SOURCE_SYSTEMS.HOME_DEPOT && (
              <Grid item xs={12}>
                <LrrpConfigurationForm
                  option={option}
                  LrrpConfigurationFormik={LrrpConfigurationFormik}
                  loading={loading}
                  setUnsavedTestInfo={setUnsavedTestInfo}
                  unsavedTestInfo={unsavedTestInfo}
                />
              </Grid>
            )}
          </Card>

          {projectDetailsData.source_system_id !==
            SOURCE_SYSTEMS.HOME_DEPOT && (
            <>
              {option === LEAD_RESPONSE_REASON[1] && (
                <GenericLeadReasonDataTable
                  formikKey="tested_lead_negative"
                  initialValues={{
                    lead_result: 'Negative',
                    test_kit_manufacturer: '',
                    component_location_tested: '',
                  }}
                  formikData={LrrpConfigurationFormik}
                  setUnsavedTestInfo={setUnsavedTestInfo}
                  unsavedTestInfo={unsavedTestInfo}
                />
              )}
              {option === LEAD_RESPONSE_REASON[2] && (
                <GenericLeadReasonDataTable
                  formikKey="tested_lead_positive"
                  initialValues={{
                    lead_result: 'Positive',
                    test_kit_manufacturer: '',
                    component_location_tested: '',
                  }}
                  formikData={LrrpConfigurationFormik}
                  setUnsavedTestInfo={setUnsavedTestInfo}
                  unsavedTestInfo={unsavedTestInfo}
                />
              )}
            </>
          )}
        </>
      )}

      {projectDetailsData &&
        projectDetailsData.source_system_id === SOURCE_SYSTEMS.HOME_DEPOT && ( // Home depot specific
          <HomeDepotFields
            homeDepotOverrideOptions={homeDepotOverrideOptions}
            hdEpaDocs={hdEpaDocs}
            source_system_id={projectDetailsData?.source_system_id}
            projectId={projectId}
            epaLeadReasontoUpdate={epaLeadReasontoUpdate}
            setEPALeadReasontoUpdate={setEPALeadReasontoUpdate}
            setReloadForm={setReloadForm}
            setOpenpopup={setOpenpopup}
            redirectBasePath={redirectBasePath}
            setUnsavedTestInfo={setUnsavedTestInfo}
            unsavedTestInfo={unsavedTestInfo}
          />
        )}
    </>
  );
};

export default EPALead;
