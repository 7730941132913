import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { Tooltip } from 'primereact/tooltip';
import { useParams } from 'react-router';
import { Menu } from 'primereact/menu';

import PFTableLoader from '../../../shared/Loader/PFTableLoader';
import usePFDataTable from '../../../../hooks/PFDatatable';
import PFButton from '../../../shared/PFPrime/PFButton.js';
import {
  createExternalProject,
  quoteGridColumns,
  quoteGridData,
} from '../helpers/MeasureSquareQuotes.js';

import ImportFromQuoteTemplate from './ImportFromQuoteTemplate';

const MeasureSquareQuotes = props => {
  const history = useHistory();
  const isGlobalLevel = props?.isGlobalLevel || false;
  let { customerId, opportunityId, projectId } = useParams();
  customerId = customerId || props?.projectDetails?.customer_id;
  opportunityId =
    opportunityId || props?.projectDetails?.project_crm?.opportunity_id;
  const toast = useRef(null);

  const isMSButtonVisibility = props?.msButtonVisibile || false;
  const {
    layout: TableLayout,
    columns: DataTableColumns,
    totalRecords,
    data: tableData,
    rows,
    page,
    sortBy,
    orderBy,
    setDefaultTableProps,
    totalRecordsCount,
  } = usePFDataTable();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const op = useRef(null);

  const gridList = () => {
    setLoading(true);
    quoteGridData(customerId, opportunityId, rows, page, sortBy, orderBy)
      .then(res => {
        if (res?.status === 200) {
          tableData(res?.data?.data?.result || []);
          totalRecords(res?.data?.data?.count || 0);

          setLoading(false);
        }
      })
      .catch(error => {
        setLoading(false);
        if (error?.response?.status === 404 && history) {
          return history.push('/page-not-found');
        }
      });
  };

  const createMeasureSquareProject = () => {
    setLoading(true);
    const data = {
      project_id: projectId || null,
      quote_id: props?.quoteId || null,
      opportunity_id: opportunityId || null,
      client_id: localStorage.getItem('client_id') || null,
    };
    createExternalProject(data, toast, gridList, setLoading);
  };

  useEffect(() => {
    setDefaultTableProps(pre => ({
      ...pre,
      tableStyle: { minWidth: '120rem' },
    }));
    tableData([]);
    totalRecords(0);
    DataTableColumns(
      quoteGridColumns(toast, setLoading, gridList, isGlobalLevel)
    );
  }, []);

  useEffect(() => {
    if (customerId) {
      gridList();
    }
  }, [rows, page, sortBy, orderBy, customerId, opportunityId]);

  let isDisabledCreateMSProject = false;
  if (!props?.msProjectInstaller) {
    isDisabledCreateMSProject = true;
  }
  if (totalRecordsCount !== 0 && !isDisabledCreateMSProject) {
    isDisabledCreateMSProject = true;
  }

  return (
    <>
      <Tooltip target=".disabled-button" />
      <div className="px-3 py-1">
        <Toast ref={toast}></Toast>
        <>
          <div className="col-12 md:col-12 lg:col-12">
            <div className="w-full flex justify-content-end">
              <PFButton
                icon="pi pi-refresh"
                label="Refresh"
                outlined={true}
                onClick={() => gridList()}
                className="mr-2"
              />
              {!isGlobalLevel && !isMSButtonVisibility && (
                <>
                  <Menu
                    model={[
                      {
                        label: 'Create Manually',
                        command: () =>
                          history?.push(
                            `/crm/${customerId}/${opportunityId}/quote/create`
                          ),
                      },
                      { separator: true },
                      {
                        label: 'Choose Template',
                        command: () => setVisible(true),
                      },
                    ]}
                    popup
                    ref={op}
                    id="popup_menu_left"
                    className="w-13rem"
                  />
                  <PFButton
                    label="Create"
                    onClick={e => op.current.toggle(e)}
                    icon="pi pi-plus"
                    className="mr-2"
                  />
                </>
              )}
              {!isGlobalLevel && isMSButtonVisibility && (
                <PFButton
                  icon="pi pi-plus"
                  label="Create project in MeasureSquare"
                  outlined={false}
                  onClick={() => createMeasureSquareProject()}
                  tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                  disabled={isDisabledCreateMSProject}
                  tooltip={`${
                    !props?.msProjectInstaller
                      ? 'Cannot create project in Measure Square without Technician'
                      : ''
                  }`}
                />
              )}
            </div>
          </div>
          {loading ? (
            <PFTableLoader />
          ) : (
            <div className="mt-3">
              <TableLayout />
            </div>
          )}
        </>
      </div>
      {visible && (
        <ImportFromQuoteTemplate
          clientId={props?.clientId}
          setVisible={setVisible}
          visible={visible}
          history={history}
          customerId={customerId}
          opportunityId={opportunityId}
        />
      )}
    </>
  );
};

export default MeasureSquareQuotes;
