//imports
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { InputNumber } from 'primereact/inputnumber';
import { useRef, useEffect } from 'react';
import { Button } from 'primereact/button';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import PFFormikError from './PFFormikError';
import { updateProjectItemUser } from './ProjectItemsService';

export default function EditAssignedTechnicianDialog(props) {
  //variables
  const existingItems = props.existingItems;
  const technicians = props.technicians?.map(item => {
    const fname =
      item.installer_detail?.user?.first_name &&
      item.installer_detail?.user?.first_name?.length > 0
        ? item.installer_detail?.user?.first_name
        : '';
    const mname =
      item.installer_detail?.user?.middle_name &&
      item.installer_detail?.user?.middle_name?.length > 0
        ? ' ' + item.installer_detail?.user?.middle_name
        : '';
    const lname =
      item.installer_detail?.user?.last_name &&
      item.installer_detail?.user?.last_name?.length > 0
        ? ' ' + item.installer_detail?.user?.last_name
        : '';

    return {
      ...item,
      fullname: `${fname}${mname}${lname}`,
    };
  });
  const selectedItem = props.selectedItem;
  const toast = useRef(null);

  const selectedItemRef = useRef(null);

  useEffect(() => {
    if (selectedItem?.project_item_user?.project_item_user_id) {
      selectedItemRef.current = selectedItem;
    }
    if (selectedItemRef.current?.project_item_user?.project_item_user_id) {
      let cost = null;
      let edited_labor_cost_ea = null;
      if (selectedItemRef.current?.project_item_user?.labor_cost_ea) {
        cost = selectedItemRef.current?.project_item_user?.labor_cost_ea;
      }
      if (
        selectedItemRef.current?.project_item_user?.edited_labor_cost_ea &&
        selectedItemRef.current?.project_item_user?.edited_labor_cost_ea >= 0
      ) {
        cost = selectedItemRef.current?.project_item_user?.edited_labor_cost_ea;
        edited_labor_cost_ea =
          selectedItemRef.current?.project_item_user?.edited_labor_cost_ea;
      }
      formik.setValues(prevValues => ({
        ...prevValues,
        selectedTechnician:
          selectedItemRef.current?.project_item_user?.installer_id,
        allocationPercentage:
          selectedItemRef.current?.project_item_user?.percentage,
        editedLaborCostEa: edited_labor_cost_ea,
        viewEditedLaborCostEa: cost,
      }));
    }
  }, [selectedItemRef, selectedItem]);

  //methods
  const handleClose = (projectItem = null, timeout = 0) => {
    formik.resetForm();

    if (timeout > 0) {
      setTimeout(() => {
        props.onHide(projectItem);
      }, timeout);
    } else {
      props.onHide(projectItem);
    }
  };

  const setAlert = (severityValue, summaryValue, life = 1500) => {
    toast.current.show({
      severity: severityValue,
      summary: `${summaryValue}`,
      life,
    });
  };

  const validationSchema = Yup.object().shape({
    allocationPercentage: Yup.number()
      .typeError('Invalid Allocation Percentage')
      .positive('Allocation Percentage Should Be Greater Than 0')
      .integer()
      .min(1)
      .max(100)
      .required(),
    selectedTechnician: Yup.number()
      .typeError('Invalid Technician')
      .positive('Please Select A Valid Technician')
      .required(),
    editedLaborCostEa: Yup.number()
      .typeError('Invalid Labor Cost Value')
      .min(0)
      .nullable(true),
  });

  const formik = useFormik({
    initialValues: {
      allocationPercentage: 0,
      selectedTechnician: 0,
      editedLaborCostEa: 0,
      viewEditedLaborCostEa: 0,
    },
    validationSchema: validationSchema,
    onSubmit: async values => {
      let totalPercentage = 0;

      const allocationPercentage = parseInt(values.allocationPercentage);
      if (isNaN(allocationPercentage)) {
        setAlert('Error', 'Invalid Percentage Value');
        return;
      }

      for (let i = 0; i < existingItems.length; i++) {
        const current = existingItems[i];
        if (
          current.project_item_id === selectedItem.project_item_id &&
          current.project_item_user?.project_item_user_id !==
            selectedItemRef?.current?.project_item_user?.project_item_user_id
        ) {
          totalPercentage += current?.project_item_user?.percentage;
        }
      }

      if (!totalPercentage || isNaN(totalPercentage)) totalPercentage = 0;
      totalPercentage += allocationPercentage;
      if (totalPercentage > 100) {
        setAlert(
          'error',
          'Sum Of Allocation Of All Users Exceeds 100 Percentage, Please Use Another Value'
        );
        return;
      }

      const requestBody = {
        project_item_id: selectedItem.project_item_id,
        installer_id: values.selectedTechnician,
        percentage: values.allocationPercentage,
        edited_labor_cost_ea: values.editedLaborCostEa,
      };

      const updateResponse = await updateProjectItemUser(
        selectedItemRef.current?.project_item_user?.project_item_user_id,
        props.projectId,
        requestBody
      );

      if (updateResponse?.data?.status) {
        setAlert('success', `Technician Details Updated Successfully`);
        handleClose(true);
      } else {
        setAlert(
          'error',
          updateResponse?.data?.message
            ? updateResponse?.data?.message
            : 'Unable To Update Technician Details'
        );
      }
    },
  });

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        header="Edit Technician"
        visible={props.visible}
        onHide={handleClose}
        className="w-6"
        pt={{
          header: { className: 'mb-0 pb-0' },
          content: { className: 'pt-1' },
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className="flex flex-column mt-4">
            <small className="text-xs pb-2 text-red-900">
              * Modifying current configuration will trigger auto calculation,
              if total allocation is 100%
            </small>

            <div>
              <Dropdown
                value={formik.values.selectedTechnician || null}
                options={technicians}
                optionValue="installer_id"
                optionLabel="fullname"
                placeholder="Select Technician"
                onChange={e => {
                  formik.setFieldValue('selectedTechnician', e.value);
                }}
                className="w-full"
                pt={{
                  input: {
                    className: 'p-inputtext p-inputtext-sm',
                  },
                }}
              ></Dropdown>
              <PFFormikError
                touched={formik.touched}
                errors={formik.errors}
                field="selectedTechnician"
              ></PFFormikError>
            </div>

            <div>
              <span className="p-float-label mt-3">
                <InputNumber
                  type="text"
                  className="p-inputtext-sm w-full"
                  id="allocationPercentage"
                  value={formik.values.allocationPercentage}
                  onChange={e => {
                    formik.setFieldValue(
                      'allocationPercentage',
                      !isNaN(parseInt(e.value)) ? parseInt(e.value) : null
                    );
                  }}
                />
                <label htmlFor="allocationPercentage">
                  Allocation Percentage
                </label>
                <PFFormikError
                  touched={formik.touched}
                  errors={formik.errors}
                  field="allocationPercentage"
                ></PFFormikError>
              </span>

              <span className="p-float-label mt-3">
                <InputNumber
                  type="text"
                  className="p-inputtext-sm w-full"
                  id="editedLaborCostEa"
                  value={formik.values.viewEditedLaborCostEa}
                  onChange={e => {
                    const cost = !isNaN(parseFloat(e.value))
                      ? parseFloat(e.value)
                      : null;
                    formik.setValues(prevValues => ({
                      ...prevValues,
                      viewEditedLaborCostEa: cost,
                      editedLaborCostEa: cost,
                    }));
                  }}
                  mode="currency"
                  currency="USD"
                  locale="en-US"
                />

                <label htmlFor="editedLaborCostEa">Labor Cost</label>
              </span>

              <small
                style={{
                  visibility: !isNaN(
                    parseFloat(formik.values.editedLaborCostEa)
                  )
                    ? 'hidden'
                    : 'visible',
                }}
              >
                *The technician Labor Profile Setup will be taken into account
                for computation if the Labor Cost box is left unfilled.
              </small>

              <PFFormikError
                touched={formik.touched}
                errors={formik.errors}
                field="editedLaborCostEa"
              ></PFFormikError>
            </div>
            <br />
            <div className="flex ml-auto">
              <Button
                label="Cancel"
                onClick={() => handleClose(null, 0)}
                size="small"
                outlined
                className="ml-1"
                type="button"
              ></Button>
              <Button
                label="Save"
                size="small"
                className="ml-2"
                severity="primary"
                disabled={
                  !formik.dirty || !formik.isValid || formik.isSubmitting
                }
                type="submit"
              ></Button>
            </div>
          </div>
        </form>
      </Dialog>
    </>
  );
}
