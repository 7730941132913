import React, { useEffect, useState } from 'react';
import { PanelMenu } from 'primereact/panelmenu';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Sidebar } from 'primereact/sidebar';
import { Image } from 'primereact/image';

import {
  CONFIG,
  PF_ADMIN,
  VIEW_DASHBOARD_PERFORMANCE_USERS,
} from '../../../../constants';
import PFLogo from '../../../../assets/images/PFLogo.jpg';

import PullOrder from './PullOrder';

export default function SideMenuList({
  visible,
  setMenuVisible,
  onSideBarLayoutChange,
  DrawerWidths,
}) {
  const { permissionList } = useSelector(state => state.permissionsList);
  const [clientId, setClientId] = useState(
    localStorage.getItem('client_id') || null
  );
  const [imgError, setImgError] = useState(false);
  const [dailogVisible, setDailogVisible] = useState(false);
  const handleLogoError = () => {
    setImgError(true);
  };

  const clientDetails = JSON.parse(localStorage.getItem('client_details'));

  useEffect(() => {
    if (clientId !== localStorage.getItem('client_id')) {
      setClientId(localStorage.getItem('client_id') || null);
    }
  }, [localStorage.getItem('client_id')]);

  const customIcons = (
    <>
      <div className="grid w-12 flex align-items-center ml-0">
        <div className="col-9 pr-0 pl-2 flex justify-content-center pb-0">
          {imgError || clientDetails?.logo === null ? (
            <a href="/dashboard" rel="noreferrer" className="mr-3">
              <Image
                alt="Projects Force"
                className="w-7rem my-2"
                src={PFLogo}
                pt={{
                  image: {
                    className: 'p-1 w-7rem max-h-3rem',
                  },
                }}
              />
            </a>
          ) : (
            <>
              <div className="flex justify-content-center">
                <a href="/dashboard" rel="noreferrer" className="mr-3">
                  <Image
                    alt={clientDetails?.client_name}
                    className="bg-white "
                    src={clientDetails?.logo}
                    onError={handleLogoError}
                    pt={{
                      image: {
                        className: 'max-w-7rem max-h-3rem',
                      },
                    }}
                  />
                </a>
              </div>
            </>
          )}
        </div>
        <div className="col-3">
          <img
            alt="Menu Pin"
            className="pr-2 cursor-pointer hidden xl:block ml-auto mt-1"
            style={{ width: '1.8rem' }}
            src={'/images/menupinfill.svg'}
            onClick={() => {
              onSideBarLayoutChange(1);
              DrawerWidths(true);
              setMenuVisible(false);
            }}
          />
        </div>
        {imgError || clientDetails?.logo === null ? null : (
          <div className="col-9 py-0 text-center pl-2 pr-0">
            <h4 className="text-8 my-0 ">
              Powered By{' '}
              <span className="text-primary text-10">ProjectsForce</span>
            </h4>
          </div>
        )}
      </div>
    </>
  );

  const items = [
    {
      label: <NavLink to={'/getstarted'}>Help</NavLink>,
      icon: 'pi pi-fw pi-question-circle',
      permission: 'HELP',
    },
    {
      label: <NavLink to={'/dashboard'}>Dashboard</NavLink>,
      icon: 'pi pi-fw pi-chart-line',
      permission: 'HOME_M',
    },
    {
      label: 'Project',
      icon: 'pi pi-fw pi-chart-pie',
      permission: 'PROJECT_M',
      className: 'pt-3 menu-level-one',
      items: [
        {
          label: <NavLink to={'/scheduler'}>Scheduler</NavLink>,
          icon: 'pi pi-fw pi-calendar-clock',
          permission: 'SCHEDULER_M',
        },
        // {
        //   label: <NavLink to={'/scheduler-beta'}>Scheduler</NavLink>,
        //   icon: 'pi pi-calendar-clock',
        //   permission: 'SCHEDULER_M',
        // },
        {
          label: <NavLink to={'/calendar'}>Calendar</NavLink>,
          icon: 'pi pi-fw pi-calendar',
          permission: 'VIEW_CALENDAR',
        },
        // {
        //   label: <NavLink to={'/calendar-beta'}>Calendar</NavLink>,
        //   icon: 'pi pi-fw pi-calendar',
        //   permission: 'VIEW_CALENDAR',
        // },
        CONFIG.ENABLE_CUSTOMER_LIST === '1' && {
          label: <NavLink to={'/project/clients'}>Clients</NavLink>,
          icon: 'pi pi-fw pi-users',
          permission: 'CLIENTS_M',
        },
        {
          label: <NavLink to={'/crm/add/customer'}>Add Client</NavLink>,
          icon: 'pi pi-fw pi-user-plus',
          permission: 'CLIENTS_M',
        },
        {
          label: <NavLink to={'/project/items'}>Items</NavLink>,
          icon: 'pi pi-fw pi-list',
          permission: 'ITEMS_M',
        },
        {
          label: <NavLink to={'/project/add'}>Add Project</NavLink>,
          icon: 'pi pi-fw pi-plus-circle',
          permission: 'ADD_PROJECT',
        },
        {
          label: (
            <NavLink
              to={'/pull-home-depot-project'}
              onClick={e => {
                e.preventDefault();
                setDailogVisible(true);
              }}
            >
              Pull Home Depot Project
            </NavLink>
          ),
          icon: 'pi pi-fw pi-plus-circle',
          permission: 'PULL_PROJECT_FROM_SOURCE',
        },
        {
          label: <NavLink to={'/payment-request'}>Payment Request</NavLink>,
          icon: 'pi pi-fw pi-dollar',
          permission: 'PAYMENT_REQUEST',
        },
        {
          label: <NavLink to={'/data-import'}>Data Import</NavLink>,
          icon: 'pi pi-fw pi-download',
          permission: 'UPLOAD_DATA_VIEW',
        },
      ],
    },
    {
      label: 'Admin',
      icon: 'pi pi-fw pi-users',
      permission: 'ADMIN_M',
      className: 'pt-3 menu-level-one',
      items: [
        {
          label: <NavLink to={'/admin/warehouse'}>Warehouse</NavLink>,
          icon: 'pi pi-fw pi-building',
          permission: 'LABOR_PROFILES_M',
        },
        {
          label: <NavLink to={'/admin/laborprofiles'}>Labor Profiles</NavLink>,
          icon: 'pi pi-fw pi-at',
          permission: 'LABOR_PROFILES_M',
        },
        {
          label: (
            <NavLink to={'/admin/usermanagement'}>User Management</NavLink>
          ),
          icon: 'pi pi-fw pi-user',
          permission: 'USER_MGMT_M',
        },
        {
          label: <NavLink to={'/admin/stores'}>Stores</NavLink>,
          icon: 'pi pi-fw pi-building',
          permission: 'STORE_M',
        },
        {
          label: <NavLink to={'/admin/dashboards'}>Dashboards</NavLink>,
          icon: 'pi pi-fw pi-desktop',
          permission: 'DASHBOARD_MGMT_M',
        },
        {
          label: <NavLink to={'/admin/widgets'}>Widgets</NavLink>,
          icon: 'pi pi-fw pi-chart-pie',
          permission: 'DASHBOARD_WIDGET_TEMPLATE',
        },
        {
          label: (
            <NavLink to={'/admin/documentcenter'}>Document Center</NavLink>
          ),
          icon: 'pi pi-fw pi-book',
          permission: 'DOC_CENTER_M',
        },
        {
          label: <NavLink to={'/admin/ruleenginelogs'}>Event Logs</NavLink>,
          icon: 'pi pi-fw pi-clock',
          permission: 'RULE_ENGINE_LOGS_VIEW',
        },
        {
          key: 'tenant_billing',
          label: <NavLink to={'/admin/tenant-billing'}>Tenant Billing</NavLink>,
          icon: 'pi pi-fw pi-money-bill',
          permission: 'ADMIN_M',
        },
        {
          label: <NavLink to={'/admin/configuration'}>Configuration</NavLink>,
          icon: 'pi pi-fw pi-sliders-h',
          permission: 'CONFIGURATION_M',
        },
        {
          key: 'bulletin',
          label: <NavLink to={'/admin/bulletin'}>Bulletin</NavLink>,
          icon: 'pi pi-fw pi-bars',
          permission: 'CONFIGURATION_M',
        },
        {
          key: 'chargeback',
          label: <NavLink to={'/admin/chargeback/add'}>Add Chargeback</NavLink>,
          icon: 'pi pi-fw pi-dollar',
          permission: 'ADD_APPROVE_CHARGEBACK',
        },
        {
          key: 'payroll',
          label: <NavLink to={'/admin/payroll'}>Payroll</NavLink>,
          icon: 'pi pi-fw pi-money-bill',
          permission: 'VIEW_PAYROLL',
        },
        {
          key: 'exported_reports',
          label: (
            <NavLink to={'/admin/export/reports'}>Exported Reports</NavLink>
          ),
          icon: 'pi pi-fw pi-file-export',
          permission: 'VIEW_PAYROLL',
        },

        ...[
          clientId === PF_ADMIN
            ? {
                label: <NavLink to={'/admin/userguide'}>User Guide</NavLink>,
                icon: 'pi pi-fw pi-info-circle',
                permission: 'HOME_M',
              }
            : {},
        ],

        ...[
          clientId === PF_ADMIN &&
          VIEW_DASHBOARD_PERFORMANCE_USERS.includes(
            localStorage.getItem('user_id')
          )
            ? {
                label: (
                  <NavLink to={'/admin/dashboard-performance-analysis'}>
                    Dashboard Analysis
                  </NavLink>
                ),
                icon: 'pi pi-fw pi-info-circle',
                permission: 'HOME_M',
              }
            : {},
        ],
      ],
    },
    ...[
      clientId === PF_ADMIN && {
        label: 'Logs',
        icon: 'pi pi-fw pi-info-circle',
        permission: 'ADMIN_M',
        className: 'pt-3 menu-level-one',
        items: [
          ...[
            clientId === PF_ADMIN
              ? {
                  key: 'login_logs',
                  label: (
                    <NavLink to={'/logs/login-logs'}> Web Login Logs</NavLink>
                  ),
                  icon: 'pi pi-fw pi-info-circle',
                  permission: 'HOME_M',
                }
              : {},
          ],
          ...[
            clientId === PF_ADMIN
              ? {
                  key: 'reset_passowrd_logs',
                  label: (
                    <NavLink to={'/logs/reset-password-logs'}>
                      Web Reset Pasword Logs
                    </NavLink>
                  ),
                  icon: 'pi pi-fw pi-info-circle',
                  permission: 'HOME_M',
                }
              : {},
          ],
          ...[
            clientId === PF_ADMIN
              ? {
                  key: 'customer_login_logs',
                  label: (
                    <NavLink to={'/logs/customer-login-logs'}>
                      Customer Login Logs
                    </NavLink>
                  ),
                  icon: 'pi pi-fw pi-info-circle',
                  permission: 'HOME_M',
                }
              : {},
          ],
          ...[
            clientId === PF_ADMIN
              ? {
                  key: 'customer_register_logs',
                  label: (
                    <NavLink to={'/logs/customer-register-logs'}>
                      Customer Register Logs
                    </NavLink>
                  ),
                  icon: 'pi pi-fw pi-info-circle',
                  permission: 'HOME_M',
                }
              : {},
          ],
          ...[
            clientId === PF_ADMIN
              ? {
                  key: 'customer_reset_password_logs',
                  label: (
                    <NavLink to={'/logs/customer-reset-password-logs'}>
                      Customer Reset Password Logs
                    </NavLink>
                  ),
                  icon: 'pi pi-fw pi-info-circle',
                  permission: 'HOME_M',
                }
              : {},
          ],
        ],
      },
    ],
    {
      label: 'Personnel',
      icon: 'pi pi-fw pi-user-edit',
      permission: 'PERSONNEL_M',
      className: 'py-3',
      items: [
        {
          label: (
            <NavLink to={'/personnel/availabilityupdates'}>
              Availability Updates
            </NavLink>
          ),
          icon: 'pi pi-fw pi-refresh',
          permission: 'AVAILABILITY_UPDATE_M',
        },
        {
          label: (
            <NavLink to={'/admin/usermanagement/user-form/add'}>
              Add New User
            </NavLink>
          ),
          icon: 'pi pi-fw pi-user-plus',
        },
      ],
    },
  ];
  return (
    <Sidebar
      visible={visible}
      onHide={() => setMenuVisible(false)}
      icons={customIcons}
      pt={{
        icons: {
          className: 'w-full flex justify-content-between mt-2',
        },
        closeIcon: {
          className: 'text-primary',
        },
        closeButton: {
          className: `hover:bg-white focus:shadow-none  mr-1 ${
            imgError || clientDetails?.logo === null ? '' : 'mb-3'
          }`,
        },
        content: {
          className: 'pr-0',
        },
        root: {
          className: 'w-14rem',
        },
        header: {
          className: 'p-0',
        },
      }}
    >
      <div className="side-menu-list">
        <PanelMenu
          model={items.filter(menu => {
            if (
              permissionList.includes(menu.permission) ||
              menu.permission == 'HELP'
            ) {
              if (['Legacy Dashboard'].includes(menu.label)) {
                if (['22PF89TG', '10PF26DC'].includes(clientId)) {
                  return true;
                } else {
                  return false;
                }
              }
              if (menu?.items) {
                menu.items = menu.items.filter(submenu => {
                  if (submenu.key === 'bulletin') {
                    if (clientId == PF_ADMIN) {
                      return true;
                    } else {
                      return false;
                    }
                  }
                  if (submenu?.key === 'tenant_billing') {
                    return clientId === PF_ADMIN ? true : false; // show only for PF client
                  }
                  if (
                    ['Legacy Widgets', 'Legacy Dashboards'].includes(
                      submenu.label
                    )
                  ) {
                    if (['22PF89TG', '10PF26DC'].includes(clientId)) {
                      return true;
                    } else {
                      return false;
                    }
                  }
                  return permissionList.includes(submenu.permission);
                });
              }
              return menu;
            }
            return false;
          })}
          className="w-full shadow-none"
          pt={{
            headerLabel: {
              className: 'text-sm w-12',
            },
            label: {
              className: 'w-12',
            },
            menu: {
              className: 'text-sm',
            },
            panel: {
              className: 'shadow-none mb-0',
            },
            headerAction: ({ context }) => ({
              className: `p-0 custom-panel-menu border-none bg-white border-noround ${
                context.active ? 'text-primary font-bold' : undefined
              }`,
            }),
            menuContent: {
              className: 'p-0 bg-white custom-panel-menu border-none',
            },
            action: {
              className:
                'pr-0 py-0 border-none hover:bg-white focus:shadow-none',
            },
            headersubmenuicon: {
              className: 'absolute right-0',
            },
            icon: {
              className: 'pl-2',
            },
          }}
        />
        <PullOrder
          dailogVisible={dailogVisible}
          setDailogVisible={setDailogVisible}
        />
      </div>
    </Sidebar>
  );
}
