import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import React, { useEffect } from 'react';

import { checkPermission } from '../../../utils/Helpers';
import permissions from '../../../config/permissions';

const PdfViewer = ({
  projectId,
  projectInstallers,
  setIsTechnicianDailogOpen,
  isTechnicianDailogOpen,
}) => {
  const [selectedTechnician, setSelectedTechnician] = React.useState([]);

  const projectTechnicians = projectInstallers?.map(item => {
    const firstName =
      item.installer_detail?.user?.first_name &&
      item.installer_detail?.user?.first_name?.length > 0
        ? item.installer_detail?.user?.first_name
        : '';
    const middleName =
      item.installer_detail?.user?.middle_name &&
      item.installer_detail?.user?.middle_name?.length > 0
        ? ' ' + item.installer_detail?.user?.middle_name
        : '';
    const lastName =
      item.installer_detail?.user?.last_name &&
      item.installer_detail?.user?.last_name?.length > 0
        ? ' ' + item.installer_detail?.user?.last_name
        : '';

    return {
      ...item,
      fullname: `${firstName}${middleName}${lastName}`,
    };
  });

  const handlePreview = techinician => {
    setTimeout(() => {
      window.open(
        `/technician-po/${projectId}/${techinician?.installer_id}/${techinician?.installer_detail?.user_id}/`
      );
    }, 1000);
  };

  const handleDefaultPreview = () => {
    setTimeout(() => {
      window.open(`/technician-po/${projectId}/default-po`);
    }, 1000);
  };

  useEffect(() => {
    setSelectedTechnician(projectTechnicians);
  }, [projectInstallers]);

  const user_id = localStorage.getItem('user_id');

  const dailogFooter = () => {
    return (
      <Button
        size="small"
        outlined
        severity="primary"
        label="Cancel"
        onClick={() => setIsTechnicianDailogOpen(false)}
        className="mr-0"
      />
    );
  };

  const filteredRecord = selectedTechnician?.filter(
    record => record?.installer_detail?.user_id == user_id
  );

  const defaultOption = () => {
    return (
      <li className="flex align-items-center justify-content-between">
        <p className="my-3">Default</p>
        <Button
          type="button"
          severity="primary"
          text
          icon="pi pi-external-link"
          outlined
          onClick={() => handleDefaultPreview()}
          size="small"
          label="Preview"
          className=" border-none shadow-none"
        />
      </li>
    );
  };

  const renderTechnician =
    selectedTechnician?.length > 0
      ? selectedTechnician?.map((item, index) => {
          const firstName =
            item.installer_detail?.user?.first_name &&
            item.installer_detail?.user?.first_name?.length > 0
              ? item.installer_detail?.user?.first_name
              : '';
          const lastName =
            item.installer_detail?.user?.last_name &&
            item.installer_detail?.user?.last_name?.length > 0
              ? ' ' + item.installer_detail?.user?.last_name
              : '';
          return (
            <>
              {index === 0 ? defaultOption() : null}
              <li
                className="flex align-items-center justify-content-between"
                key={item?.installer_detail?.installer_id}
              >
                <p className="my-3">{`${firstName} ${lastName}`}</p>
                <Button
                  type="button"
                  severity="primary"
                  text
                  icon="pi pi-external-link"
                  outlined
                  onClick={() => handlePreview(item)}
                  size="small"
                  label="Preview"
                  className=" border-none shadow-none"
                />
              </li>
            </>
          );
        })
      : defaultOption();

  const renderLoggedinTechnician =
    filteredRecord?.length > 0
      ? filteredRecord?.map((item, index) => {
          const firstName =
            item.installer_detail?.user?.first_name &&
            item.installer_detail?.user?.first_name?.length > 0
              ? item.installer_detail?.user?.first_name
              : '';
          const lastName =
            item.installer_detail?.user?.last_name &&
            item.installer_detail?.user?.last_name?.length > 0
              ? ' ' + item.installer_detail?.user?.last_name
              : '';
          return (
            <>
              {index === 0 ? defaultOption() : null}
              <li
                className="flex align-items-center justify-content-between"
                key={item?.installer_detail?.installer_id}
              >
                <p className="my-3">{`${firstName} ${lastName}`}</p>
                <Button
                  type="button"
                  severity="primary"
                  text
                  icon="pi pi-external-link"
                  outlined
                  onClick={() => handlePreview(item)}
                  size="small"
                  label="Preview"
                  className=" border-none shadow-none"
                />
              </li>
            </>
          );
        })
      : defaultOption();
  useEffect(() => {
    setSelectedTechnician(projectTechnicians);
  }, [projectInstallers]);
  return (
    <>
      <Dialog
        draggable={false}
        header="Technician PO"
        className="w-11 md:w-8 xl:w-4"
        visible={isTechnicianDailogOpen}
        onHide={() => {
          setIsTechnicianDailogOpen(false);
        }}
        style={{ height: '70vh' }}
        footer={dailogFooter}
        pt={{
          content: {
            className: 'overflow-auto',
          },
        }}
      >
        <ul className="pl-0">
          {checkPermission(
            permissions?.userManagement?.userManagementViewInstaller
          ) ||
          checkPermission(permissions?.addGlobalInstaller) ||
          checkPermission(
            permissions?.userManagement?.userManagementModifyInstaller
          )
            ? renderLoggedinTechnician
            : renderTechnician}
        </ul>
      </Dialog>
    </>
  );
};
export default PdfViewer;
