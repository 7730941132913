import {
  Grid,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';

import { WIDGET_FILTER_TYPES } from '../constant/constant';

const WidgetTypeFilter = ({ action, widgetFormik }) => {
  return (
    <Grid container direction="row" spacing={1}>
      <Grid item style={{ alignSelf: 'center' }}>
        <span>Widget Filter Type: </span>
      </Grid>
      <Grid item>
        <FormControl>
          <RadioGroup
            row
            value={widgetFormik.values.widget_filter_type}
            onChange={event => {
              widgetFormik.setFieldValue(
                'widget_filter_type',
                event?.target?.value || ''
              );
            }}
          >
            {Object.keys(WIDGET_FILTER_TYPES).map((key, index) => (
              <FormControlLabel
                key={index}
                value={key}
                control={<Radio />}
                label={WIDGET_FILTER_TYPES[key]}
                disabled={action === 'view'}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default WidgetTypeFilter;
