import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { AutoComplete } from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';

import {
  createChargebackReason,
  searchChargebackReason,
} from '../../services/ChargebackService';
import { getStatusOptions } from '../../../../Admin/Stores/Stores.service';
import {
  CHARGEBACK_DEFAULT_STATUS,
  CLIENT_DETAILS,
  CHARGEBACK_PAYOUT_TYPE_OPTION,
  RetainageFrequencyTypeOptions,
} from '../../../../../constants';
import { getUsersList } from '../../../../Admin/UserManagement/UserManagement.service';
import { getPaymentType } from '../../../Billing/Billing.service';
import {
  dateFormatter,
  formatCurrency,
  checkPermission,
} from '../../../../../utils/Helpers';
import PFCalendar from '../../../../shared/PFPrime/PFCalendar';
import permissions from '../../../../../config/permissions';
import SkeletonLoader from '../../../../shared/Loader/skeleton';

const ChargebackSummary = ({ formik, disabledFields, isLoading }) => {
  const [chargebackReasons, setChargebackReasons] = React.useState([]);
  const chargebackReasonRef = useRef(null);
  const debitUserRef = useRef(null);
  const creditUserRef = useRef(null);
  const toast = useRef(null);
  const [chargebackStatusOptions, setChargebackStatusOptions] = React.useState(
    []
  );
  const [paymentTypeOptions, setPaymentTypeOptions] = React.useState([]);
  const [usersListData, setUsersListData] = React.useState([]);

  const [calculateEMIAmount, setCalculateEMIAmount] = React.useState();
  const { action } = useParams();
  React.useEffect(() => {
    getStatusOptions('Chargeback', () => null, setChargebackStatusOptions);
    getPaymentType(setPaymentTypeOptions);
  }, []);

  useEffect(() => {
    if (
      CLIENT_DETAILS?.chargeback_approval_module &&
      !checkPermission(permissions?.chargeback?.edit)
    ) {
      formik.setFieldValue(
        'chargeback_status',
        chargebackStatusOptions.find(
          item => item.status_id === CHARGEBACK_DEFAULT_STATUS.status_id
        )
      );
    }
  }, [chargebackStatusOptions]);

  const handleOnChange = async (fieldId, event = null) => {
    if (
      [
        'material_chargeback',
        'payment_count',
        'labor_chargeback',
        'payout_type_value',
      ].includes(fieldId)
    ) {
      await formik.setFieldValue(
        fieldId,
        event?.value || parseFloat(0).toFixed(2)
      );
      setCalculateEMIAmount(true);
    } else {
      await formik.setFieldValue(fieldId, event?.target?.value || '');
    }
  };

  //Helper to fetch chargeback reason
  const suggestChargebackReasons = async (event = null) => {
    const searchChargebackReasonResponse = await searchChargebackReason(
      event?.query || ''
    );
    if (searchChargebackReasonResponse?.data?.count)
      setChargebackReasons(searchChargebackReasonResponse?.data?.result);
    else setChargebackReasons([]);
  };

  // Helper to add Chargeback Reason
  const addChargebackReason = async value => {
    if (value) {
      const newChargebackReason = await createChargebackReason(value);
      if (newChargebackReason?.data) {
        handleOnChange('chargeback_reason', {
          chargeback_reason_id: newChargebackReason?.data?.chargeback_reason_id,
          name: newChargebackReason?.data?.name,
        });
        suggestChargebackReasons();
        toast?.current?.show({
          severity: 'success',
          summary: newChargebackReason?.message,
          life: 2000,
        });
      }
    }
  };

  // Helper to focus on chargeback reason values
  const displayChargebackReason = () => {
    suggestChargebackReasons();
    if (chargebackReasonRef.current) {
      chargebackReasonRef.current.show();
    }
  };
  // Helper to disabled the button to add values in dropdown
  const onDisabled = (searchResult, value, key) => {
    let res = true;

    if (searchResult?.length > 0 && value && typeof value === 'string') {
      if (value.trim() != '') {
        const length = searchResult?.filter(
          item => item[key]?.toLowerCase() === value?.toLowerCase()
        ).length;
        if (length === 0) {
          res = false;
        }
      }
    }

    if (searchResult?.length === 0) {
      res = false;
    }
    if (!value) {
      res = true;
    }

    return res;
  };

  //Helper to fetch debit credit users
  const suggestDebitCreditUsers = async (event = null) => {
    if (event?.query?.length >= 3) {
      await getUsersList(
        {
          limit: 10,
          offset: 0,
        },
        {},
        () => null,
        setUsersListData,
        () => null,
        event?.query
      );
    }
  };
  // To remove the autosuggestion on Input number field
  React.useEffect(() => {
    const inputElement = document.getElementsByClassName('numberAutocomplete');
    if (inputElement?.length) {
      for (let ele of inputElement) {
        ele?.setAttribute('autoComplete', 'off');
      }
    }
  }, []);

  useEffect(() => {
    if (formik?.values?.payout_type === CHARGEBACK_PAYOUT_TYPE_OPTION[3]?.key) {
      formik.setFieldValue(
        'payout_type_value',
        formik?.values?.total_technician
      );
    }
  }, [formik?.values?.payout_type, formik?.values?.total_technician]);

  React.useEffect(() => {
    if (
      (formik?.values?.labor_chargeback ||
        formik?.values?.material_chargeback) &&
      calculateEMIAmount
    ) {
      let total_chargeback =
        parseFloat(formik?.values?.labor_chargeback || 0) +
        parseFloat(formik?.values?.material_chargeback || 0);
      formik.setFieldValue('total_technician', total_chargeback?.toFixed(2));
    }
    if (formik?.values?.payment_count && calculateEMIAmount) {
      let emi_amount =
        ((formik?.values?.material_chargeback || 0) +
          (formik?.values?.labor_chargeback || 0)) /
        formik?.values?.payment_count;
      formik.setFieldValue('payment_amount_in_emi', emi_amount);
    } else {
      formik.setFieldValue('payment_amount_in_emi', parseFloat(0)?.toFixed(2));
    }
    setCalculateEMIAmount(false);
  }, [calculateEMIAmount]);

  const disabledChargeback = () => {
    return (
      formik?.values?.payout_amount > 0 ||
      (action === 'edit' && !formik?.values?.is_editable)
    );
  };

  return (
    <>
      <div className="grid grid-nogutter col-12">
        <Toast ref={toast} />
        {!isLoading && (
          <div className="col-12 pl-2 pb-2">
            {formik?.values?.total_technician ? (
              <>
                <span className="text-primary">
                  Payment Amount Balance:&nbsp;
                  {!formik?.values?.payment_amount
                    ? formik?.values?.payment_amount_balance ||
                      formik?.values?.payment_amount_balance === 0
                      ? formatCurrency(formik?.values?.payment_amount_balance)
                      : formatCurrency(formik?.values?.total_technician)
                    : formatCurrency(formik?.values?.total_technician)}
                </span>
                <span>&nbsp;out of&nbsp;</span>
                <span className="font-bold">
                  {formik?.values?.total_technician
                    ? formatCurrency(formik?.values?.total_technician)
                    : ''}
                </span>
              </>
            ) : (
              ''
            )}
          </div>
        )}
        <div className="sm:col-12 md:col-4 lg:col-3 xl:col-3 p-2">
          {isLoading ? (
            <SkeletonLoader columnCount={1} columnWidth="20rem" />
          ) : (
            <span className="p-float-label">
              <PFCalendar
                id="repair_date"
                className="w-12"
                onChange={e =>
                  formik?.setFieldValue(
                    'repair_date',
                    dateFormatter(e?.target?.value, 'YYYY-MM-DD')
                  )
                }
                dateFormat={'mm/dd/yy'}
                value={
                  formik?.values?.repair_date
                    ? new Date(
                        dateFormatter(formik?.values?.repair_date, 'MM/DD/YYYY')
                      )
                    : null
                }
                pt={{
                  input: {
                    root: { className: 'p-inputtext-sm' },
                  },
                }}
                disabled={disabledFields || disabledChargeback()}
              />
              <label htmlFor="repair_date">Date of Repair</label>
            </span>
          )}
        </div>
        <div className="sm:col-12 md:col-4 lg:col-3 xl:col-3 p-2">
          {isLoading ? (
            <SkeletonLoader columnCount={1} columnWidth="20rem" />
          ) : (
            <span className="p-float-label">
              <Calendar
                id="chargbackDateCreated"
                className="w-12"
                dateFormat={'mm/dd/yy'}
                value={
                  formik?.values?.created_at
                    ? new Date(formik?.values?.created_at)
                    : new Date()
                }
                pt={{
                  input: {
                    root: { className: 'p-inputtext-sm' },
                  },
                }}
                disabled
              />
              <label htmlFor="chargbackDateCreated">
                Chargeback Created On
              </label>
            </span>
          )}
        </div>

        <div className="sm:col-12 md:col-4 lg:col-3 xl:col-3 p-2">
          {isLoading ? (
            <SkeletonLoader columnCount={1} columnWidth="20rem" />
          ) : (
            <span className="p-float-label">
              <AutoComplete
                ref={chargebackReasonRef}
                id="chargeback_reason"
                className="w-10"
                suggestions={chargebackReasons || []}
                field="name"
                completeMethod={suggestChargebackReasons}
                value={formik?.values?.chargeback_reason || ''}
                onChange={e => {
                  handleOnChange('chargeback_reason', e);
                }}
                delay={500}
                onFocus={displayChargebackReason}
                inputClassName="p-inputtext-sm w-12 border-right-none border-noround-right"
                disabled={disabledFields || disabledChargeback()}
              />
              <label htmlFor="chargeback_reason">Chargeback Reason</label>
              <Button
                icon="pi pi-check"
                className="p-button-primary w-2 border-noround-left height-40"
                disabled={
                  onDisabled(
                    chargebackReasons,
                    formik?.values?.chargeback_reason,
                    'name'
                  ) ||
                  disabledFields ||
                  disabledChargeback()
                }
                onClick={() =>
                  addChargebackReason(formik?.values?.chargeback_reason)
                }
              />
            </span>
          )}
        </div>
        <div className="sm:col-12 md:col-4 lg:col-3 xl:col-3 p-2">
          {isLoading ? (
            <SkeletonLoader columnCount={1} columnWidth="20rem" />
          ) : (
            <span className="p-float-label">
              <Dropdown
                id="chargeback_status"
                className="w-12"
                optionLabel="status"
                options={chargebackStatusOptions}
                value={formik?.values?.chargeback_status}
                onChange={e => {
                  handleOnChange('chargeback_status', e);
                }}
                pt={{
                  input: {
                    className: 'p-inputtext p-inputtext-sm',
                  },
                }}
                disabled={
                  (CLIENT_DETAILS?.chargeback_approval_module &&
                    !checkPermission(permissions?.chargeback?.edit)) ||
                  disabledFields ||
                  disabledChargeback()
                }
              />
              <label htmlFor="chargeback_status">Chargeback Status</label>
            </span>
          )}
        </div>
        <div className="flex flex-wrap col-12">
          <div className="sm:col-12 md:col-4 lg:col-3 xl:col-3 p-2">
            {isLoading ? (
              <SkeletonLoader columnCount={1} columnWidth="20rem" />
            ) : (
              <span className="p-float-label">
                <AutoComplete
                  id="debit_user"
                  className="w-full"
                  suggestions={usersListData || []}
                  ref={debitUserRef}
                  field="user_name"
                  completeMethod={suggestDebitCreditUsers}
                  value={formik?.values?.debit_user}
                  onChange={e => {
                    handleOnChange('debit_user', e);
                  }}
                  forceSelection
                  inputClassName="p-inputtext-sm w-12"
                  onBlur={formik?.handleBlur}
                  disabled={disabledFields || disabledChargeback()}
                />
                <label htmlFor="debit_user">
                  Debit User<i className="text-red-400">*</i>
                </label>
              </span>
            )}
          </div>
          <div className="sm:col-12 md:col-4 lg:col-3 xl:col-3 p-2">
            {isLoading ? (
              <SkeletonLoader columnCount={1} columnWidth="20rem" />
            ) : (
              <span className="p-float-label">
                <AutoComplete
                  id="credit_user"
                  className="w-full"
                  suggestions={usersListData || []}
                  ref={creditUserRef}
                  field="user_name"
                  completeMethod={suggestDebitCreditUsers}
                  value={formik?.values?.credit_user}
                  onChange={e => {
                    handleOnChange('credit_user', e);
                  }}
                  forceSelection
                  inputClassName="p-inputtext-sm w-12"
                  disabled={disabledFields || disabledChargeback()}
                />
                <label htmlFor="credit_user">Credit User</label>
              </span>
            )}
          </div>
          <div className="sm:col-12 md:col-4 lg:col-3 xl:col-3 p-2">
            {isLoading ? (
              <SkeletonLoader columnCount={1} columnWidth="20rem" />
            ) : (
              <span className="p-float-label">
                <InputText
                  id="debit_user_company_name"
                  value={formik?.values?.debit_user?.company_name || ''}
                  className="w-full"
                  inputClassName="p-inputtext-sm w-12"
                  disabled
                />
                <label htmlFor="credit_user">Debit User Company Name</label>
              </span>
            )}
          </div>
          <div className="sm:col-12 md:col-4 lg:col-3 xl:col-3 p-2">
            {isLoading ? (
              <SkeletonLoader columnCount={1} columnWidth="20rem" />
            ) : (
              <span className="p-float-label">
                <InputText
                  id="debit_user_company_name"
                  value={formik?.values?.credit_user?.company_name || ''}
                  className="w-full"
                  inputClassName="p-inputtext-sm w-12"
                  disabled
                />
                <label htmlFor="credit_user">Credit User Company Name</label>
              </span>
            )}
          </div>
        </div>
        <div className="flex flex-wrap col-12">
          <div className="sm:col-12 md:col-4 lg:col-3 xl:col-3 p-2">
            {isLoading ? (
              <SkeletonLoader columnCount={1} columnWidth="20rem" />
            ) : (
              <span className="p-float-label">
                <InputNumber
                  id="material_chargeback"
                  inputId="currency-us"
                  value={formik?.values?.material_chargeback}
                  onChange={e => {
                    handleOnChange('material_chargeback', e);
                  }}
                  mode="currency"
                  currency="USD"
                  locale="en-US"
                  className="p-inputtext-sm w-12"
                  disabled={disabledFields || disabledChargeback()}
                  inputClassName="numberAutocomplete"
                />
                <label htmlFor="material_chargeback">
                  Material Chargeback <i className="text-red-400">*</i>
                </label>
              </span>
            )}
          </div>
          <div className="sm:col-12 md:col-4 lg:col-3 xl:col-3 p-2">
            {isLoading ? (
              <SkeletonLoader columnCount={1} columnWidth="20rem" />
            ) : (
              <span className="p-float-label">
                <InputNumber
                  id="labor_chargeback"
                  inputId="currency-us"
                  value={formik?.values?.labor_chargeback}
                  onChange={e => {
                    handleOnChange('labor_chargeback', e);
                  }}
                  mode="currency"
                  currency="USD"
                  locale="en-US"
                  className="p-inputtext-sm w-12"
                  disabled={disabledFields || disabledChargeback()}
                  inputClassName="numberAutocomplete"
                />
                <label htmlFor="labor_chargeback">Labor Chargeback</label>
              </span>
            )}
          </div>
          <div className="sm:col-12 md:col-4 lg:col-3 xl:col-3 p-2">
            {isLoading ? (
              <SkeletonLoader columnCount={1} columnWidth="20rem" />
            ) : (
              <span className="p-float-label">
                <InputNumber
                  id="total_retail"
                  inputId="currency-us"
                  value={formik?.values?.total_technician}
                  mode="currency"
                  currency="USD"
                  locale="en-US"
                  className="p-inputtext-sm w-12"
                  disabled={true}
                />
                <label htmlFor="total_retail">Total Chargeback</label>
              </span>
            )}
          </div>
          <div className="sm:col-12 md:col-4 lg:col-3 xl:col-3 p-2">
            {isLoading ? (
              <SkeletonLoader columnCount={1} columnWidth="20rem" />
            ) : (
              <span className="p-float-label">
                <Dropdown
                  id="payment_type"
                  className="w-12"
                  optionLabel="payment_type"
                  options={paymentTypeOptions}
                  value={formik?.values?.payment_type}
                  onChange={e => {
                    handleOnChange('payment_type', e);
                  }}
                  pt={{
                    input: {
                      className: 'p-inputtext p-inputtext-sm',
                    },
                  }}
                  disabled={disabledFields || disabledChargeback()}
                />
                <label htmlFor="payment_type">Payment Type</label>
              </span>
            )}
          </div>
        </div>

        <div className="flex flex-wrap col-12">
          <div className="sm:col-12 md:col-4 lg:col-3 xl:col-3 p-2">
            {isLoading ? (
              <SkeletonLoader columnCount={1} columnWidth="20rem" />
            ) : (
              <span className="p-float-label">
                <Dropdown
                  id="payout_type"
                  className="w-12"
                  optionLabel="value"
                  options={CHARGEBACK_PAYOUT_TYPE_OPTION}
                  value={formik?.values?.payout_type}
                  optionValue="key"
                  onChange={e => {
                    handleOnChange('payout_type', e);
                    handleOnChange('payout_type_value', null);
                  }}
                  disabled={disabledFields || disabledChargeback()}
                  pt={{
                    input: {
                      className: 'p-inputtext p-inputtext-sm',
                    },
                  }}
                />
                <label htmlFor="payout_type">
                  Payout Type<i className="text-red-400">*</i>
                </label>
              </span>
            )}
          </div>

          <div className="col-12 md:col-6 lg:col-3">
            {isLoading ? (
              <SkeletonLoader columnCount={1} columnWidth="20rem" />
            ) : (
              <span className="p-float-label">
                {formik?.values?.payout_type ===
                  CHARGEBACK_PAYOUT_TYPE_OPTION[0]?.key ||
                formik?.values?.payout_type ===
                  CHARGEBACK_PAYOUT_TYPE_OPTION[3]?.key ? (
                  <InputNumber
                    inputId="payout_type_value"
                    name="payout_type_value"
                    value={formik?.values?.payout_type_value}
                    onChange={e => handleOnChange('payout_type_value', e)}
                    onBlur={formik.handleBlur}
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                    min={0}
                    disabled={
                      formik?.values?.payout_type ===
                        CHARGEBACK_PAYOUT_TYPE_OPTION[3]?.key ||
                      !formik?.values?.payout_type ||
                      disabledFields ||
                      disabledChargeback()
                    }
                    pt={{
                      root: { className: 'w-full' },
                    }}
                  />
                ) : (
                  <InputNumber
                    inputId="payout_type_value"
                    name="payout_type_value"
                    value={formik?.values?.payout_type_value}
                    onChange={e => handleOnChange('payout_type_value', e)}
                    suffix="%"
                    onBlur={formik.handleBlur}
                    min={0}
                    minFractionDigits={2}
                    pt={{
                      root: { className: 'w-full' },
                    }}
                    disabled={
                      !formik?.values?.payout_type ||
                      disabledFields ||
                      disabledChargeback()
                    }
                  />
                )}
                <label htmlFor="payout_type_value">
                  {formik?.values?.payout_type ===
                    CHARGEBACK_PAYOUT_TYPE_OPTION[1]?.key ||
                  formik?.values?.payout_type ===
                    CHARGEBACK_PAYOUT_TYPE_OPTION[2]?.key
                    ? '% of Pay'
                    : 'Fixed Amount'}
                  <i className="text-red-400">*</i>
                </label>
              </span>
            )}
          </div>
          {!(
            formik?.values?.payout_type ===
              CHARGEBACK_PAYOUT_TYPE_OPTION[2]?.key ||
            formik?.values?.payout_type ===
              CHARGEBACK_PAYOUT_TYPE_OPTION[3]?.key
          ) && (
            <div className="sm:col-12 md:col-4 lg:col-3 xl:col-3 p-2">
              {isLoading ? (
                <SkeletonLoader columnCount={1} columnWidth="20rem" />
              ) : (
                <span className="p-float-label">
                  <Dropdown
                    id="frequency"
                    className="w-12"
                    optionLabel="value"
                    optionValue="key"
                    options={RetainageFrequencyTypeOptions}
                    value={formik?.values?.frequency}
                    onChange={e => {
                      handleOnChange('frequency', e);
                    }}
                    pt={{
                      input: {
                        className: 'p-inputtext p-inputtext-sm',
                      },
                    }}
                    disabled={disabledFields || disabledChargeback()}
                  />
                  <label htmlFor="frequency">Frequency</label>
                </span>
              )}
            </div>
          )}

          {!(
            formik?.values?.payout_type ===
              CHARGEBACK_PAYOUT_TYPE_OPTION[2]?.key ||
            formik?.values?.payout_type ===
              CHARGEBACK_PAYOUT_TYPE_OPTION[3]?.key
          ) && (
            <div className="sm:col-12 md:col-4 lg:col-3 xl:col-3 p-2">
              {isLoading ? (
                <SkeletonLoader columnCount={1} columnWidth="20rem" />
              ) : (
                <span className="p-float-label">
                  <PFCalendar
                    id="emi_date"
                    className="w-12"
                    dateFormat={'mm/dd/yy'}
                    onChange={e =>
                      formik?.setFieldValue(
                        'emi_date',
                        dateFormatter(e?.target?.value, 'YYYY-MM-DD')
                      )
                    }
                    value={
                      formik?.values?.emi_date
                        ? new Date(
                            dateFormatter(
                              formik?.values?.emi_date,
                              'MM/DD/YYYY'
                            )
                          )
                        : null
                    }
                    pt={{
                      input: {
                        root: { className: 'p-inputtext-sm' },
                      },
                    }}
                    disabled={disabledFields || disabledChargeback()}
                  />
                  <label htmlFor="emi_date">Initiation Date</label>
                </span>
              )}
            </div>
          )}

          <div className="sm:col-12 md:col-4 lg:col-3 xl:col-3 p-2">
            {isLoading ? (
              <SkeletonLoader columnCount={1} columnWidth="20rem" />
            ) : (
              <span className="p-float-label">
                <InputNumber
                  id="payment_amount_in_emi"
                  value={formik?.values?.payment_amount_in_emi}
                  className="p-inputtext-sm w-12"
                  mode="currency"
                  currency="USD"
                  locale="en-US"
                  disabled={true}
                />
                <label htmlFor="payment_amount_in_emi">
                  Payment Amount (paid in EMI&apos;s)
                </label>
              </span>
            )}
          </div>
        </div>
        <div className="col-12 p-2">
          {isLoading ? (
            <SkeletonLoader columnCount={1} columnWidth="90rem" />
          ) : (
            <span className="p-float-label">
              <InputTextarea
                id="description"
                className="w-12"
                value={formik?.values?.description}
                onChange={e => {
                  handleOnChange('description', e);
                }}
                rows={5}
                disabled={disabledFields || disabledChargeback()}
              />
              <label htmlFor="description">Description</label>
            </span>
          )}
        </div>
      </div>
    </>
  );
};

export default ChargebackSummary;
