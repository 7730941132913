import { useMemo } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import {
  ActivityResponse,
  ActivityItem,
} from '../Interface/Activites.interface';
import { momentTz } from '../../../../utils/Helpers';
import TableColumnsLoader from '../../../shared/Loader/tableColumnsLoader';

const ActivitiesDataTable = ({
  projectActivityData,
  loading,
}: {
  projectActivityData: ActivityResponse | null;
  loading: boolean;
}) => {
  const columns = [
    {
      field: 'created_at',
      header: 'Date Created',
      body: (row: ActivityItem) => {
        return (
          <>
            {row?.created_at
              ? momentTz(row?.created_at).format('MM-DD-YYYY hh:mm A')
              : ''}
          </>
        );
      },
    },
    {
      field: 'activity_created_date',
      header: 'Date Activity Created',
      body: (row: ActivityItem) => {
        return (
          <>
            {row?.activity_created_date
              ? momentTz(row?.activity_created_date).format(
                  'MM-DD-YYYY hh:mm A'
                )
              : ''}
          </>
        );
      },
    },
    {
      field: 'activity_type',
      header: 'Activity Type',
      body: (row: ActivityItem) => {
        return <>{row?.activity_type ? row?.activity_type : ''}</>;
      },
    },
    {
      field: 'activity_due_date',
      header: 'Activity Due Date',
      body: (row: ActivityItem) => {
        return (
          <>
            {row?.activity_due_date
              ? momentTz(row?.activity_due_date).format('MM-DD-YYYY hh:mm A')
              : ''}
          </>
        );
      },
    },

    {
      field: 'job_number',
      header: 'Job Number',
      body: (row: ActivityItem) => {
        return <>{row?.job_number ? row?.job_number : ''}</>;
      },
    },
    {
      field: 'activity_status',
      header: 'Status',
      body: (row: ActivityItem) => {
        return <>{row?.activity_status ? row?.activity_status : ''}</>;
      },
    },
  ];
  const activityTableLoader = useMemo(() => {
    return TableColumnsLoader(columns, {
      rows: 5,
      isValue: false,
    });
  }, [columns]);
  return (
    <div className="col-12 mt-5">
      <DataTable
        value={loading ? activityTableLoader : projectActivityData?.items}
        header="Activities"
        paginator
        rows={10}
        pt={{
          header: {
            className: 'font-bold text-base',
          },
        }}
      >
        {columns?.map(col => (
          <Column
            key={col.field}
            field={col.field}
            header={col.header}
            {...(!loading
              ? {
                  body: col?.body,
                }
              : {})}
          />
        ))}
      </DataTable>
    </div>
  );
};

export default ActivitiesDataTable;
