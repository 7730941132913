import React, { useState } from 'react';

import { formateNumber } from '../helpers/Quotes';
import PFInputNumber from '../../../shared/PFPrime/PFInputNumber';
import PFFormikError from '../../../shared/PFPrime/PFFormikError';
const DiscountEditor = ({
  rowData,
  editingRows,
  submitAction,
  onBlurDiscount,
  retailerPercentage,
}) => {
  const { values, touched, errors, setFieldValue, setValues } = submitAction;
  const [value, setValue] = useState(formateNumber(values?.discount));
  const isEditing = editingRows[rowData.rowIndex];

  const formateDiscount = values => {
    const discount = formateNumber(values?.discount);

    if (values?.discountType === 'Amount') {
      return `$${discount.toFixed(2)}`;
    }
    if (values?.discountType === 'Percentage') {
      return `${discount.toFixed(2)}%`;
    }
    return '0.00';
  };

  return isEditing ? (
    <div className="vertical-align-top">
      <span className="p-float-label">
        <PFInputNumber
          inputId="discount"
          name="discount"
          minFractionDigits={2}
          value={value}
          mode={'decimal'}
          onChange={(name, value) => setValue(value)}
          onBlur={() => {
            setFieldValue('discount', value);
            onBlurDiscount(value, values, setValues, retailerPercentage);
          }}
          className={touched?.discount && errors?.discount ? 'error' : ''}
          version={2}
          inputStyle={{ width: '100px' }}
          suffix={`${values?.discountType === 'Percentage' ? '%' : ''}`}
          prefix={`${values?.discountType === 'Amount' ? '$' : ''}`}
        />
      </span>
      <PFFormikError touched={touched} errors={errors} field="discount" />
    </div>
  ) : (
    <div className="pt-2">
      <div className="w-full">{`${formateDiscount(rowData)}`}</div>
      <div className="w-full">
        <span className="font-bold">{`$${formateNumber(rowData?.totalDiscount).toFixed(2)}`}</span>
      </div>
    </div>
  );
};

export default DiscountEditor;
