import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import PFButton from '../../shared/PFPrime/PFButton';
import usePFDataTable from '../../../hooks/PFDatatable';
import PFTableLoader from '../../shared/Loader/PFTableLoader';

import { opportunityGridColumns } from './helpers/OpportunityTab';
import { opportunitysGridData } from './helpers/Opportunity';

const OpportunitiesTab = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { customerId } = useParams();
  const {
    layout: TableLayout,
    columns: DataTableColumns,
    totalRecords,
    data: tableData,
    rows,
    page,
    sortBy,
    orderBy,
    setDefaultTableProps,
  } = usePFDataTable();

  const opportunityListing = () => {
    setLoading(true);
    opportunitysGridData(customerId, rows, page, sortBy, orderBy)
      .then(res => {
        if (res?.status === 200) {
          tableData(res?.data?.result);
          totalRecords(res?.data?.count);
          setLoading(false);
        }
      })
      .catch(error => {
        if (error?.response?.status === 404 && history) {
          return history.push('/page-not-found');
        }
        console.log('Error', error);
      });
  };

  useEffect(() => {
    setDefaultTableProps(pre => ({
      ...pre,
      tableStyle: { minWidth: '120rem' },
    }));
    DataTableColumns(opportunityGridColumns({ customerId, history }));
  }, []);

  useEffect(() => {
    opportunityListing();
  }, [rows, page, sortBy, orderBy]);

  useEffect(() => {});
  return (
    <div className="px-3">
      <div className="col-12 md:col-12 lg:col-12">
        <div className="w-full flex justify-content-end">
          <PFButton
            icon="pi pi-refresh"
            label="Refresh"
            outlined={true}
            className="mr-2"
            onClick={opportunityListing}
          />
          <PFButton
            label="Create"
            onClick={() => {
              return history.push(`/crm/${customerId}/lead/create`);
            }}
            icon="pi pi-plus"
          />
        </div>
      </div>
      {loading ? (
        <PFTableLoader />
      ) : (
        <div className="mt-3">
          <TableLayout />
        </div>
      )}
    </div>
  );
};

export default OpportunitiesTab;
