import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Skeleton, Dialog } from 'primereact';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Panel } from 'primereact/panel';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { InputText } from 'primereact/inputtext';

import AnswerComponent from '../../Admin/Questionnaire/AnswerComponent';
import { formatDateTime } from '../../../utils/Helpers';
import { updateQuestionnaireStore } from '../../../redux/slices/questionnaire.slice';
import PreviewCheckList from '../../Admin/Questionnaire/PreviewCheckList';
import { deleteAnswers } from '../../Admin/Questionnaire/questionnaireService';
import { useConfirmDialogContext } from '../../../contexts/ConfirmDialog';

import {
  inspectionList,
  moduleListByFilter,
} from './project-checklist.service';

const ProjectChecklist = ({
  targetId,
  projectDetailsData,
  isRenderConfirmationPopup = true,
}) => {
  const toast = useRef(null);
  const [moduleDetailsData, setModuleDetailsData] = useState([[], [], []]);
  const [moduleList, setModuleList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const { showConfirmationDialog } = useConfirmDialogContext();
  const dispatch = useDispatch();
  const { refetchInspectionDetails } = useSelector(
    state => state.questionnaire
  );

  const [module, setModule] = useState({
    selectedModule: null,
    isShowCheckListPopup: false,
    isNewRecord: false,
  });

  const fetchSubmittedDetails = async () => {
    const response = await inspectionList(targetId);
    if (response.status && response.data?.length) {
      setModuleDetailsData(response.data);
    } else {
      setModuleDetailsData([]);
    }
  };
  const getModuleListByFilter = async () => {
    if (Array.isArray(moduleList) && moduleList.length) return;
    const filters = {
      source_id: projectDetailsData?.source_system?.source_system_id,
      project_type_id: projectDetailsData?.project_type?.project_type_id,
      project_category_id:
        projectDetailsData?.project_category?.project_category_id,
      project_status_id: projectDetailsData?.status?.status_id,
      source_status_id: projectDetailsData?.source_status?.status_id,
    };
    const response = await moduleListByFilter(filters);
    if (response && response.data) setModuleList(response?.data?.result);
  };
  useEffect(() => {
    setLoading(true);
    Promise.all([fetchSubmittedDetails(), getModuleListByFilter()]).then(() =>
      setLoading(false)
    );
  }, []);

  const handleHide = async () => {
    setModule({
      ...module,
      isShowCheckListPopup: false,
    });
    if (refetchInspectionDetails) {
      setLoading(true);
      await fetchSubmittedDetails();
      setLoading(false);
      dispatch(
        updateQuestionnaireStore({
          refetchInspectionDetails: false,
        })
      );
    }
  };
  const onCloseViewCheckList = () => {
    setModule({
      selectedModule: null,
      isShowCheckListPopup: false,
      isNewRecord: false,
    });
    setIsPreviewVisible(false);
  };

  const searchModules = () => {
    if (searchQuery) {
      const lowerQuery = searchQuery.toLowerCase();
      return moduleDetailsData.filter(module => {
        return (
          module.module_name.toLowerCase().includes(lowerQuery) ||
          module.label.toLowerCase().includes(lowerQuery)
        );
      });
    } else {
      return moduleDetailsData;
    }
  };

  const panelHeaderTemplate = options => {
    const className = `${options.className} justify-content-between`;
    return (
      <div className={`${className} pt-2 pb-2`}>
        <div className="flex align-items-center">
          <span className="font-bold"></span>
        </div>
        <div className="flex gap-2 align-items-center">
          <IconField iconPosition="left">
            <InputIcon className="pi pi-search"> </InputIcon>
            <InputText
              placeholder="Search"
              value={searchQuery}
              onChange={e => {
                setSearchQuery(e.target.value);
              }}
            />
          </IconField>
        </div>
      </div>
    );
  };

  const resetAnswers = async params => {
    try {
      const response = await deleteAnswers(
        params?.module_id,
        targetId,
        params?.version
      );
      if (response?.status) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: response?.message || 'Answers refreshed successfully',
          life: 3000,
        });
        await fetchSubmittedDetails();
      }
    } catch (ex) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: ex?.response?.data?.message || 'something went wrong',
        life: 3000,
      });
    } finally {
      dispatch(
        updateQuestionnaireStore({
          isAnswerLoading: false,
        })
      );
    }
  };

  const confirmReset = params => {
    showConfirmationDialog({
      message:
        'This action will delete all your responses against this checklist, would you still like to delete?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => resetAnswers(params),
      rejectLabel: 'Cancel',
      acceptLabel: 'Delete',
      acceptIcon: 'pi pi-trash',
    });
  };

  const tableColumn = [
    {
      field: 'action',
      header: 'Actions',
      style: { ...{ width: '150px' } },
      body: params =>
        loading ? (
          <Skeleton className="mb-2" height="2rem" />
        ) : (
          <div className="flex gap-3">
            <i
              className={`pi pi-eye cursor-pointer`}
              onClick={() => {
                setIsPreviewVisible(true);
                setModule({
                  ...module,
                  selectedModule: params,
                });
              }}
            ></i>
            <i
              className={`pi pi-pencil cursor-pointer`}
              onClick={event => {
                event.preventDefault();
                setModule({
                  selectedModule: params,
                  isShowCheckListPopup: true,
                  isNewRecord: false,
                });
              }}
            ></i>
            <i
              className={`pi pi-trash cursor-pointer`}
              onClick={() => {
                confirmReset(params);
              }}
            ></i>
          </div>
        ),
    },
    {
      field: 'module_name',
      header: 'Checklist',
      sortable: true,
      body: params =>
        loading ? (
          <Skeleton className="mb-2" height="2rem" />
        ) : (
          params.module_name
        ),
    },
    {
      field: 'label',
      header: 'Version',
      sortable: true,
      body: params => {
        return loading ? (
          <Skeleton className="mb-2" height="2rem" />
        ) : (
          <span
            className="text-primary cursor-pointer"
            onClick={event => {
              event.preventDefault();
              setModule({
                selectedModule: params,
                isShowCheckListPopup: true,
                isNewRecord: false,
              });
            }}
          >
            {params.label}
          </span>
        );
      },
    },
    {
      field: 'created_by',
      header: 'Submitted By',
      sortable: true,
      body: params =>
        loading ? (
          <Skeleton className="mb-2" height="2rem" />
        ) : (
          <span className="capitalize">{params.created_by}</span>
        ),
    },
    {
      field: 'created_at',
      header: 'Submitted At',
      sortable: true,
      body: params =>
        loading ? (
          <Skeleton className="mb-2" height="2rem" />
        ) : (
          formatDateTime(params.created_at)
        ),
    },
    {
      field: 'modified_by',
      header: 'Modified By',
      sortable: true,
      body: params =>
        loading ? (
          <Skeleton className="mb-2" height="2rem" />
        ) : (
          <span className="capitalize">{params.modified_by}</span>
        ),
    },
    {
      field: 'modified_at',
      header: 'Modified At',
      sortable: true,
      body: params =>
        loading ? (
          <Skeleton className="mb-2" height="2rem" />
        ) : (
          formatDateTime(params.modified_at)
        ),
    },
  ];
  return (
    <>
      <div className="flex justify-content-end align-items-center gap-3 w-full mb-3">
        {isRenderConfirmationPopup && <ConfirmDialog />}
        <Dropdown
          value={module.selectedModule}
          onChange={e => {
            setModule({
              ...module,
              selectedModule: e.value,
            });
          }}
          options={moduleList}
          className="w-4"
          optionLabel="module_name"
          placeholder="Select a Checklist"
          inputId="module"
          name="module"
        />
        <Button
          size="small"
          label="Create"
          severity="primary"
          icon="pi pi-plus"
          iconPos="left"
          onClick={e => {
            e.preventDefault();
            if (module.selectedModule) {
              setModule({
                ...module,
                isShowCheckListPopup: true,
                isNewRecord: true,
              });
            } else {
              toast.current.show({
                severity: 'error',
                summary: 'Error',
                detail: 'Please select checklist',
                life: 3000,
              });
            }
          }}
        />
      </div>
      <Panel
        headerTemplate={panelHeaderTemplate}
        pt={{
          content: { className: 'p-0' },
        }}
      >
        <DataTable
          value={searchModules()}
          paginator={true}
          rows={10}
          className="w-full"
        >
          {tableColumn.map(eachColumn => (
            <Column key={`${eachColumn?.field}`} {...eachColumn} />
          ))}
        </DataTable>
      </Panel>
      <PreviewCheckList
        isPreviewVisible={isPreviewVisible}
        setIsPreviewVisible={setIsPreviewVisible}
        module={module}
        projectDetailsData={projectDetailsData}
        targetId={targetId}
        onHide={onCloseViewCheckList}
      />
      <Dialog
        header={
          module.isNewRecord
            ? 'Create Checklist'
            : `Update Checklist: ${module?.selectedModule?.module_name}`
        }
        visible={module.isShowCheckListPopup}
        onHide={handleHide}
        style={{ minHeight: '550px', width: '90vw' }}
        pt={{
          header: {
            className: 'bg-blue-500 pt-2 pb-2',
          },
          headertitle: {
            className: 'text-white text-base',
            style: {
              fontFamily: '"Poppins", sans-serif',
            },
          },
          closebutton: {
            className: 'text-white text-sm',
          },
        }}
      >
        <AnswerComponent
          module={module}
          targetId={targetId}
          action={handleHide}
          checkListName={
            `${projectDetailsData?.project_number}_${moduleDetailsData?.length + 1}` ||
            ''
          }
          projectDetailsData={projectDetailsData}
          toast={toast}
        />
      </Dialog>
      <Toast ref={toast} />
    </>
  );
};

export default ProjectChecklist;
