// **** External Utilities ****
import { REACT_APP_ENABLE_PUSH_NOTIFICATION } from '../../../../constants/envConstants';
import { Notifications } from '../../../../constants/notification.constant';
import { URL_CONSTANTS } from '../../../../constants/urlConstants';
import apiService from '../../../../services/api.service';

export const getUserDevices = async userId => {
  try {
    const response = await apiService.getWithDiffBaseUrl(
      `/user-devices?userId=${userId}`,
      {},
      URL_CONSTANTS.NOTIFICATION.baseUrl
    );
    return response.devices;
  } catch (error) {
    console.error(error);
  }
};

export const saveUserDevices = async (
  userId,
  clientId,
  deviceType,
  deviceName,
  firebaseToken,
  enabledNotification = {}
) => {
  try {
    const payload = {
      userId,
      clientId,
      deviceType,
      deviceName,
      firebaseToken,
      enabledNotification,
    };
    const response = await apiService.postWithDiffBaseUrl(
      `/user-device`,
      payload,
      URL_CONSTANTS.NOTIFICATION.baseUrl
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const savePushNotification = async (
  userId,
  clientId,
  type,
  projectId,
  notificationId
) => {
  if (REACT_APP_ENABLE_PUSH_NOTIFICATION === true) {
    try {
      const payload = { userId, clientId, projectId, type, notificationId };
      const response = await apiService.postWithDiffBaseUrl(
        `/notification/publish`,
        payload,
        URL_CONSTANTS.NOTIFICATION.baseUrl
      );
      return response?.data;
    } catch (error) {
      console.error(`Notification send error: ${error}`);
      return {};
    }
  }
};

export const deleteUserDevice = async (userId, deviceId) => {
  try {
    const payload = { userId, deviceId };
    const response = await apiService.deleteWithDiffBaseUrl(
      `/user-device`,
      payload,
      URL_CONSTANTS.NOTIFICATION.baseUrl
    );
    return response?.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getNotificationCount = async (userId, type, clientId) => {
  if (REACT_APP_ENABLE_PUSH_NOTIFICATION === true) {
    try {
      const payload = { userId, type, clientId };
      const response = await apiService.postWithDiffBaseUrl(
        `/notification/count`,
        payload,
        URL_CONSTANTS.NOTIFICATION.baseUrl
      );
      return response;
    } catch (error) {
      console.error(error);
      return 0;
    }
  }
};

export const getNotification = async (userId, type, clientId, page, limit) => {
  if (REACT_APP_ENABLE_PUSH_NOTIFICATION === true) {
    try {
      const payload = { page, limit, userId, type, clientId };
      const response = await apiService.postWithDiffBaseUrl(
        `/notification/list`,
        payload,
        URL_CONSTANTS.NOTIFICATION.baseUrl,
        ''
      );
      return response?.notifications;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

export const updateNotificationViewed = async (
  note_id,
  type,
  user_id,
  viewed,
  reviewed
) => {
  if (REACT_APP_ENABLE_PUSH_NOTIFICATION === true) {
    try {
      const payload = {
        note_id,
        type,
        user_id,
        viewed,
        reviewed,
        clientId: localStorage.getItem('client_id'),
      };
      await apiService.postWithDiffBaseUrl(
        `/notification/view`,
        payload,
        URL_CONSTANTS.NOTIFICATION.baseUrl
      );
    } catch (error) {
      console.error(error);
    }
  }
};

export const getSMSNotifications = async (offset, limit) => {
  if (REACT_APP_ENABLE_PUSH_NOTIFICATION === true) {
    try {
      const smsDetailsRequest = await getNotification(
        localStorage.getItem('user_id'),
        Notifications.SMS,
        localStorage.getItem('client_id'),
        offset,
        limit
      );
      const smsDetailCount = await getNotificationCount(
        localStorage.getItem('user_id'),
        Notifications.SMS,
        localStorage.getItem('client_id')
      );
      const newNotificationList = [];
      if (Array.isArray(smsDetailsRequest)) {
        if (smsDetailsRequest) {
          newNotificationList.push(...smsDetailsRequest);
        }
      }
      return {
        data: newNotificationList,
        count: Number(smsDetailCount.count ?? 0),
      };
    } catch (error) {
      console.error(error);
      return { data: [], count: 0 };
    }
  }
};

export const getEmailNotifications = async (offset, limit) => {
  if (REACT_APP_ENABLE_PUSH_NOTIFICATION === true) {
    try {
      const response = await getNotification(
        localStorage.getItem('user_id'),
        Notifications.EMAIL,
        localStorage.getItem('client_id'),
        offset,
        limit
      );
      const emailCount = await getNotificationCount(
        localStorage.getItem('user_id'),
        Notifications.EMAIL,
        localStorage.getItem('client_id')
      );
      const newEmailList = [];
      if (Array.isArray(response)) {
        if (response) {
          newEmailList.push(...response);
        }
      }
      return { emailList: newEmailList, count: Number(emailCount.count ?? 0) };
    } catch (error) {
      console.error(error);
      return { emailList: [], count: 0 };
    }
  }
};
export const getStoreNotifications = async (offset, limit) => {
  if (REACT_APP_ENABLE_PUSH_NOTIFICATION === true) {
    try {
      const response = await getNotification(
        localStorage.getItem('user_id'),
        Notifications.STORE,
        localStorage.getItem('client_id'),
        offset,
        limit
      );
      const storeNotificationCount = await getNotificationCount(
        localStorage.getItem('user_id'),
        Notifications.STORE,
        localStorage.getItem('client_id')
      );
      const newStoreList = [];
      if (Array.isArray(response)) {
        if (response) {
          newStoreList.push(...response);
        }
      }
      return {
        storeList: newStoreList,
        count: Number(storeNotificationCount.count ?? 0),
      };
    } catch (error) {
      console.error(error);
      return { storeList: [], count: 0 };
    }
  }
};

export const getInstallerNotifications = async (offset, limit) => {
  if (REACT_APP_ENABLE_PUSH_NOTIFICATION === true) {
    try {
      const response = await getNotification(
        localStorage.getItem('user_id'),
        Notifications.INSTALLER,
        localStorage.getItem('client_id'),
        offset,
        limit
      );
      const installerNotificationCount = await getNotificationCount(
        localStorage.getItem('user_id'),
        Notifications.INSTALLER,
        localStorage.getItem('client_id')
      );
      const newInstallerList = [];
      if (Array.isArray(response)) {
        if (response) {
          newInstallerList.push(...response);
        }
      }
      return {
        installerList: newInstallerList,
        count: Number(installerNotificationCount.count ?? 0),
      };
    } catch (error) {
      console.error(error);
      return { installerList: [], count: 0 };
    }
  }
};

export const resetUserNotificationsData = async (userId, clientId) => {
  if (REACT_APP_ENABLE_PUSH_NOTIFICATION === true) {
    try {
      const payload = { userId, clientId };
      const response = await apiService.postWithDiffBaseUrl(
        `/user-notifications-update`,
        payload,
        URL_CONSTANTS.NOTIFICATION.baseUrl,
        ''
      );
      return response.notifications;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

export const getUserDeviceSettings = async (userId, clientId) => {
  try {
    const payload = { userId, clientId };
    const response = await apiService.getWithDiffBaseUrl(
      `/user-devices-settings`,
      payload,
      URL_CONSTANTS.NOTIFICATION.baseUrl
    );
    return response.response;
  } catch (error) {
    console.error(error);
    return {};
  }
};

export const saveUserDeviceSettings = async (
  userId,
  clientId,
  deviceType,
  enabledNotification
) => {
  try {
    const payload = { userId, clientId, deviceType, enabledNotification };
    const response = await apiService.postWithDiffBaseUrl(
      `/user-devices-settings`,
      payload,
      URL_CONSTANTS.NOTIFICATION.baseUrl,
      ''
    );
    return response.device;
  } catch (error) {
    console.error(error);
    return [];
  }
};
