import { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Dropdown } from 'primereact/dropdown';
import { Card } from 'primereact/card';

import PFButton from '../../../shared/PFPrime/PFButton';
import { EPA_PROJECT_TYPE } from '../../../../constants';

const validationSchema = Yup.object().shape({
  lead_result: Yup.string().required('This field is required'),
  test_kit_manufacturer: Yup.string().required('This field is required'),
  component_location_tested: Yup.string().required('This field is required'),
});
const fields = [
  {
    key: 'lead_result',
    label: 'Test Result',
    type: 'dropdown',
    required: true,
    options: [
      { label: 'Positive', value: 'Positive' },
      { label: 'Negative', value: 'Negative' },
    ],
  },
  {
    key: 'test_kit_manufacturer',
    label: 'Kit Manufacturer',
    required: true,
  },
  {
    key: 'component_location_tested',
    label: 'Location Tested',
    required: true,
  },
];
const GenericLeadReasonDataTable = ({
  formikKey,
  initialValues,
  formikData,
  pType = '',
  setUnsavedTestInfo,
  unsavedTestInfo,
}) => {
  const [modalState, setModalState] = useState({
    visible: false,
    mode: null,
    index: null,
    submitType: null,
  });
  const [selectedItem, setSelectedItem] = useState(
    initialValues?.lead_result || ''
  );
  const [confirmDialogProps, setConfirmDialogProps] = useState({
    visible: false,
    rowIndex: null,
  });
  const existingEntries =
    pType == EPA_PROJECT_TYPE.PROJECT_HOME_DEPOT
      ? []
      : formikData?.values[formikKey];
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      let updatedEntries;
      if (pType === EPA_PROJECT_TYPE.PROJECT_HOME_DEPOT) {
        if (
          formikData.values[formikKey] === null ||
          (formikData.values[formikKey].constructor === Object &&
            Object.keys(formikData.values[formikKey]).length === 0)
        ) {
          formikData.values[formikKey] = [];
          updatedEntries = [];
        } else {
          updatedEntries = [...(formikData.values[formikKey] || [])];
        }
      } else {
        updatedEntries = [...(formikData.values[formikKey].test_info || [])];
      }

      if (modalState.mode === 'edit') {
        updatedEntries[modalState.index] = { ...values, isLocal: true };
      } else {
        updatedEntries.push({ ...values, isLocal: true });
      }
      setUnsavedTestInfo(updatedEntries);
      const leadTestInfo = updatedEntries.map(entry => ({
        ...entry,
        component_location_tested: entry.component_location_tested,
        test_kit_manufacturer: entry.test_kit_manufacturer,
      }));

      if (pType === EPA_PROJECT_TYPE.PROJECT_HOME_DEPOT) {
        formikData.setFieldValue(formikKey, leadTestInfo);
      } else {
        formikData.setFieldValue(formikKey, {
          ...formikData.values[formikKey],
          test_info: leadTestInfo,
        });
      }

      if (modalState.submitType === 'saveAndNew') {
        resetForm();
        setSelectedItem(null);
        setModalState({
          visible: true,
          mode: 'add',
          index: null,
          submitType: null,
        });
      } else {
        setModalState({
          ...modalState,
          visible: false,
          mode: null,
          index: null,
        });
      }

      setSubmitting(false);
    },
  });

  useEffect(() => {
    let formikValues = {};
    if (pType === EPA_PROJECT_TYPE.PROJECT_HOME_DEPOT) {
      formikValues = existingEntries;
    } else {
      let initialTestInfo = [];
      if (
        Array.isArray(existingEntries?.test_info) &&
        existingEntries?.test_info?.length > 0
      ) {
        initialTestInfo = existingEntries.test_info;
      } else if (
        Object.keys(existingEntries)?.length > 0 &&
        typeof existingEntries === 'object' &&
        !existingEntries?.test_info
      ) {
        const allValuesEmpty = Object.values(existingEntries).every(
          value => value === ''
        );

        if (allValuesEmpty) {
          initialTestInfo = [];
        } else {
          initialTestInfo = [
            {
              lead_result: initialValues?.lead_result,
              test_kit_manufacturer: existingEntries?.test_kit_manufacturer,
              component_location_tested:
                existingEntries?.component_location_tested,
            },
          ];
        }
      }
      formikValues = {
        ...formikData.values[formikKey],
        test_info: initialTestInfo,
      };
    }
    formikData.setFieldValue(formikKey, formikValues);
  }, [pType, formikKey]);
  useEffect(() => {
    const isLocalTestData =
      Array.isArray(unsavedTestInfo) &&
      unsavedTestInfo?.some(item => item?.isLocal);
    if (isLocalTestData) {
      const handleBeforeUnload = event => {
        event.preventDefault();

        event.returnValue = 'Changes you made may not be saved.';

        return 'Changes you made may not be saved.';
      };

      window.addEventListener('beforeunload', handleBeforeUnload);

      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }
  }, [unsavedTestInfo]);
  const handleEdit = (rowData, rowIndex) => {
    setSelectedItem(rowData.lead_result);
    formik.setValues(rowData);
    setModalState({ visible: true, mode: 'edit', index: rowIndex });
  };

  const handleDeleteConfirmed = () => {
    if (confirmDialogProps.rowIndex !== null) {
      if (pType == EPA_PROJECT_TYPE.PROJECT_HOME_DEPOT) {
        const updatedEntries = formikData.values[formikKey].filter(
          (_, index) => index !== confirmDialogProps.rowIndex
        );
        setUnsavedTestInfo(updatedEntries);
        formikData.setFieldValue(formikKey, updatedEntries);
        setConfirmDialogProps({ visible: false, rowIndex: null });
      } else {
        const updatedEntries = formikData.values[formikKey].test_info?.filter(
          (_, index) => index !== confirmDialogProps.rowIndex
        );
        setUnsavedTestInfo(updatedEntries);
        formikData.setFieldValue(formikKey, {
          ...formikData.values[formikKey],
          test_info: updatedEntries,
        });
        setConfirmDialogProps({ visible: false, rowIndex: null });
      }
    }
  };

  const handleAddClick = () => {
    formik.resetForm();
    setModalState({ visible: true, mode: 'add', index: null });
  };

  const handleModalSubmit = submitType => {
    setModalState({ ...modalState, submitType });
    formik.handleSubmit();
  };

  const actionTemplate = (rowData, rowIndex) => {
    if (!rowData.isLocal) {
      // Disable actions if not local
      return null;
    }
    return (
      <div className="flex">
        <PFButton
          icon="pi pi-pencil"
          type="button"
          outlined
          className="border-none p-0 text-500"
          onClick={() => {
            handleEdit(rowData, rowIndex.rowIndex);
          }}
        />
        <PFButton
          icon="pi pi-trash"
          type="button"
          outlined
          className="border-none p-0 text-500"
          onClick={() => {
            setConfirmDialogProps({
              visible: true,
              rowIndex: rowIndex.rowIndex,
            });
          }}
        />
      </div>
    );
  };

  const dynamicInputs = fields.map((field, index) => {
    if (index == 0) {
      const dropdownOptions = field.options || [];
      return (
        <div className="p-field mt-3 float-label-group" key={field.key}>
          <span className="p-float-label">
            <Dropdown
              id={field.key}
              name={field.key}
              options={dropdownOptions}
              onChange={e => {
                setSelectedItem(e.value);
                formik.setFieldValue(field.key, e.value);
              }}
              value={selectedItem}
              onBlur={formik.handleBlur}
              className={`w-full ${
                formik.touched[field.key] && formik.errors[field.key]
                  ? 'p-invalid '
                  : '' + 'p-inputtext-sm '
              }
              }`}
            />
            <label htmlFor={field.key}>
              {field.label}{' '}
              {field.required && <span className="text-red-500">*</span>}
            </label>
          </span>
          {formik.touched[field.key] && formik.errors[field.key] && (
            <div className="p-error text-xs">{formik.errors[field.key]}</div>
          )}
        </div>
      );
    } else {
      return (
        <div className="p-field mt-3 float-label-group" key={field.key}>
          <span className="p-float-label">
            <InputText
              id={field.key}
              name={field.key}
              value={formik.values[field.key]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`w-full ${
                formik.touched[field.key] && formik.errors[field.key]
                  ? 'p-invalid '
                  : '' + 'p-inputtext-sm '
              }`}
            />
            <label htmlFor={field.key}>
              {field.label}{' '}
              {field.required && <span className="text-red-500">*</span>}
            </label>
          </span>
          {formik.touched[field.key] && formik.errors[field.key] && (
            <div className="p-error text-xs">{formik.errors[field.key]}</div>
          )}
        </div>
      );
    }
  });
  const columns = [
    { body: actionTemplate, header: 'Actions', style: { width: '100px' } },
    ...fields.map(({ key, label }) => ({ field: key, header: label })),
  ];

  return (
    <>
      <ConfirmDialog
        visible={confirmDialogProps.visible}
        message="Are you sure you want to delete this entry?"
        header="Confirmation"
        icon="pi pi-exclamation-triangle"
        onHide={() => setConfirmDialogProps({ visible: false, rowIndex: null })}
        accept={handleDeleteConfirmed}
        reject={() => setConfirmDialogProps({ visible: false, rowIndex: null })}
      />

      <Dialog
        header={modalState.mode === 'edit' ? 'Edit Details' : 'Add Details'}
        visible={modalState.visible}
        onHide={() => setModalState({ ...modalState, visible: false })}
        className="w-11 md:w-8 xl:w-5 p-0"
        footer={
          <div className="flex flex-row-reverse flex-wrap justify-end">
            <PFButton
              label="Save"
              className="p-button-primary m-1"
              type="submit"
              onClick={() => handleModalSubmit('save')}
            />
            <PFButton
              label="Save & Add New"
              outlined
              className="p-button-primary m-1"
              type="submit"
              onClick={() => handleModalSubmit('saveAndNew')}
            />
            <PFButton
              label="Cancel"
              outlined
              className="p-button-primary m-1"
              onClick={() => setModalState({ ...modalState, visible: false })}
            />
          </div>
        }
      >
        <form onSubmit={formik.handleSubmit} className="p-0">
          <div className="w-full p-0">{dynamicInputs}</div>
        </form>
      </Dialog>

      <Card
        className="w-full mt-4"
        style={{ position: 'relative' }}
        title="Lead Test Info"
        pt={{
          title: {
            className: 'text-xl font-bold',
          },
        }}
      >
        <PFButton
          label="Add"
          color="primary"
          icon="pi pi-plus"
          className="absolute top-0 right-0 m-2 focus:shadow-none"
          onClick={handleAddClick}
        />
        <DataTable
          value={
            pType
              ? formikData?.values[formikKey]?.length > 0
                ? formikData?.values[formikKey]
                : []
              : formikData?.values[formikKey]?.test_info?.length > 0
                ? formikData?.values[formikKey]?.test_info
                : []
          }
          paginator
          rows={5}
          emptyMessage="No records found."
        >
          {columns.map((col, index) => (
            <Column key={index} {...col} />
          ))}
        </DataTable>
      </Card>
    </>
  );
};

export default GenericLeadReasonDataTable;
