import React, { useEffect, useState, useRef, memo } from 'react';
import { useDispatch } from 'react-redux';
import { DataTable, Column, Toast, Message, Button, Tag } from 'primereact';

import Apiservice from '../../../../services/api.service';
import TableSkeleton from '../../../shared/Skeleton/TableSkeleton';
import {
  formatDateDefault,
  formatDateTime,
  getInitials,
} from '../../../../utils/Helpers';
import { routeUpdateMapData } from '../../../../redux/slices/route-map.slice';

const InstallerPanel = ({
  tabItem,
  routeId,
  dates,
  refereshTabs,
  fetchTabs,
  isArchiveTab,
}) => {
  const [tabsData, setTabsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const toast = useRef(null);
  const { user_id } = tabItem;
  const dispatch = useDispatch();

  const fetchTabsData = async () => {
    try {
      setIsLoading(true);
      let apiURL = `/routing/history/${user_id}/${formatDateDefault(
        new Date(dates),
        '-'
      )}/${routeId}`;
      if (isArchiveTab) {
        apiURL = `/routing/archive/${user_id}/${formatDateDefault(new Date(dates), '-')}`;
      }
      const response = await Apiservice.get(apiURL);
      if (response && response.length) {
        setTabsData(response);
        const checkInprogress = response?.find(
          record => record?.status === 'INPROGRESS'
        );
        if (checkInprogress) {
          setTimeout(() => {
            fetchTabsData();
          }, 5000);
        }
        if (
          ['COMPLETED', 'REJECTED', 'APPROVED'].includes(
            tabItem.routing_status
          ) ||
          response.find(record => ['COMPLETED'].includes(record.status))
        ) {
          const mapData = [...response];
          mapData.unshift({
            ...response[0],
            job_location: response[0].start_location,
            job_address: response[0].start_location_address,
            isInstallerStartLocation: true,
          });
          dispatch(
            routeUpdateMapData({
              isLoading: false,
              data: mapData,
            })
          );
        } else {
          dispatch(
            routeUpdateMapData({
              isLoading: false,
              data: [],
            })
          );
        }
      } else {
        setTabsData([]);
      }
    } catch (e) {
      setTabsData([]);
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchTabsData();
    return () => {
      dispatch(
        routeUpdateMapData({
          isLoading: false,
          data: [],
        })
      );
    };
  }, [refereshTabs]);

  const createRoute = async () => {
    try {
      setIsLoading(true);
      const response = await Apiservice.patch(`/routing/history`, {
        userId: tabItem?.user_id,
        date: formatDateDefault(dates),
        routeId,
      });
      if (response && response?.status === 'INPROGRESS') {
        toast.current.show({
          severity: 'success',
          summary: 'Route created succefully.',
          life: '3000',
        });
        fetchTabs();
        fetchTabsData();
      }
    } catch (e) {
      toast.current.show({
        severity: 'error',
        summary: 'Something went wrong.',
        life: '3000',
      });
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const approveRejectInstaller = async status => {
    try {
      const response = await Apiservice.post(`/routing/history`, {
        userId: tabItem?.user_id,
        date: formatDateDefault(dates),
        status: status,
        routeId,
      });
      if (
        response &&
        response?.status === (status === 'approved' ? 'APPROVED' : 'REJECTED')
      ) {
        toast.current.show({
          severity: 'success',
          summary: `Request ${status === 'approved' ? 'approved' : 'rejected'} succefully.`,
          life: '3000',
        });
        fetchTabs();
        fetchTabsData();
      }
    } catch (e) {
      toast.current.show({
        severity: 'error',
        summary: 'Something went wrong',
        life: '3000',
      });
      console.error(e);
    }
  };

  const serviceTimeTemplate = rowData => {
    return rowData?.service_time == '' ||
      rowData?.service_time == 'null' ||
      rowData?.service_time === null ||
      rowData?.service_time === 'NaN'
      ? null
      : rowData?.service_time + ' minutes';
  };

  const travelTimeTemplate = rowData => {
    return rowData?.travel_time == '' ||
      rowData?.travel_time == 'null' ||
      rowData?.travel_time === null ||
      rowData?.travel_time === 'NaN'
      ? null
      : rowData?.travel_time + ' minutes';
  };

  const projectNumberBody = rowData => {
    return rowData?.project_number ? (
      rowData?.project_number
    ) : rowData.sort_order ? (
      <img alt="logo" src="/images/the-end.png" width="40" />
    ) : null;
  };

  const statusTemplate = rowData => {
    if (rowData?.status === 'COMPLETED') {
      return (
        <Tag severity="success" className="installer-status">
          <i className="pi pi-check-circle mr-1 text-xs" /> {rowData?.status}
        </Tag>
      );
    } else if (rowData?.status === 'INPROGRESS') {
      return (
        <Tag severity="info" className="installer-status">
          <i className="pi pi-spin pi-spinner  mr-1 text-xs"></i>
          {rowData?.status}
        </Tag>
      );
    } else if (rowData?.status === 'PENDING') {
      return (
        <Tag
          severity="warning"
          className="installer-status"
          value={rowData?.status}
        />
      );
    } else if (rowData?.status === 'REJECTED') {
      return (
        <Tag
          severity="danger"
          className="installer-status"
          value={rowData?.status}
        />
      );
    } else if (rowData?.status === 'APPROVED') {
      return (
        <Tag
          style={{ background: '#14b8a6' }}
          className="installer-status"
          value={rowData?.status}
        />
      );
    }
  };

  return (
    <>
      <Toast ref={toast} />

      {isLoading ? (
        <TableSkeleton />
      ) : (
        <>
          {isArchiveTab ? null : (
            <>
              {tabItem?.routing_status === null ? (
                <div className="text-right">
                  <Button
                    size="small"
                    className="my-2"
                    onClick={() => createRoute()}
                  >
                    Create Route
                  </Button>
                </div>
              ) : tabItem?.routing_status === 'COMPLETED' ? (
                <div className="text-right">
                  <Button
                    size="small"
                    severity="success"
                    className="my-2"
                    onClick={() => approveRejectInstaller('approved')}
                  >
                    Approve
                  </Button>
                  <Button
                    size="small"
                    className="my-2 ml-3"
                    severity="danger"
                    onClick={() => approveRejectInstaller('rejected')}
                  >
                    Reject
                  </Button>
                </div>
              ) : tabItem?.routing_status === 'APPROVED' ? (
                <div className="text-right my-3">
                  <Tag className="ml-1" style={{ background: '#14b8a6' }}>
                    Approved
                  </Tag>
                </div>
              ) : tabItem?.routing_status === 'REJECTED' ? (
                <div className="text-right my-3">
                  <Tag className="ml-1" severity={'danger'}>
                    Rejected
                  </Tag>
                </div>
              ) : (
                <div className="text-right pr-3 my-4">
                  <strong>
                    <i>
                      <i
                        className="pi pi-spin pi-spinner text-primary"
                        style={{ fontSize: '1.1rem' }}
                      ></i>{' '}
                      In Progress...
                    </i>
                  </strong>
                </div>
              )}
            </>
          )}

          {tabsData?.[0]?.sort_order && (
            <Message
              className="border-primary w-full justify-content-start mb-1"
              severity="success"
              content={
                <div className="flex align-items-center">
                  <img alt="logo" src="/images/start.png" width="40" />
                  <div className="ml-2">{` Start Location ( ${getInitials(
                    tabItem.installer_name
                  )}0 ): ${tabsData?.[0]?.from_address}`}</div>
                </div>
              }
            />
          )}
          <DataTable value={tabsData} tableStyle={{ minWidth: '1800px' }}>
            <Column
              field="sort_order"
              header="Stop No"
              style={{ width: '80px' }}
              body={rowData => {
                return rowData.sort_order
                  ? `${getInitials(tabItem.installer_name)}${rowData.sort_order}`
                  : '';
              }}
            />
            <Column field="status" header="Status" body={statusTemplate} />
            <Column
              field="from_address"
              header="From Address"
              style={{ width: '200px' }}
            />
            <Column
              field="to_address"
              header="To Address"
              style={{ width: '200px' }}
            />
            <Column
              field="project_number"
              header="Project Number"
              body={projectNumberBody}
            />
            <Column field="po_number" header="PO Number" />
            <Column field="customer_name" header="Customer Name" />
            <Column field="store_number" header="Store Number" />
            <Column field="project_type" header="Project Type" />
            <Column field="job_category" header="Job Category" />
            <Column
              field="arrival_start_time"
              header="Arrival time"
              body={rowData => {
                return rowData?.arrival_start_time
                  ? formatDateTime(rowData.arrival_start_time)
                  : '';
              }}
            />
            <Column
              field="arrival_end_time"
              header="Departure time"
              body={rowData => {
                return rowData?.arrival_end_time
                  ? formatDateTime(rowData.arrival_end_time)
                  : '';
              }}
            />
            <Column
              field="service_time"
              header="Service Time"
              body={serviceTimeTemplate}
            />
            <Column
              field="travel_time"
              header="Travel Time"
              body={travelTimeTemplate}
            />
          </DataTable>
        </>
      )}
    </>
  );
};

export default memo(InstallerPanel);
