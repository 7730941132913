import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Skeleton, Dialog } from 'primereact';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'jspdf-autotable';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { InputText } from 'primereact/inputtext';
import { Panel } from 'primereact/panel';

import AnswerComponent from '../../Admin/Questionnaire/AnswerComponent';
import ApiService from '../../../services/api.service';
import { URL_CONSTANTS } from '../../../constants/urlConstants';
import { formatDateTime } from '../../../utils/Helpers';
import { updateQuestionnaireStore } from '../../../redux/slices/questionnaire.slice';
import PFButton from '../../shared/PFPrime/PFButton';
import PreviewCheckList from '../Questionnaire/PreviewCheckList';
import { useConfirmDialogContext } from '../../../contexts/ConfirmDialog';
import { deleteAnswers } from '../Questionnaire/questionnaireService';

const UserChecklist = () => {
  const { userId: targetId } = useSelector(state => state.userForm);
  const { showConfirmationDialog } = useConfirmDialogContext();
  const toast = useRef(null);
  const [moduleDetailsData, setModuleDetailsData] = useState([[], [], []]);
  const [moduleList, setModuleList] = useState([]);
  const [module, setModule] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);

  const dispatch = useDispatch();
  const { refetchInspectionDetails } = useSelector(
    state => state.questionnaire
  );

  const fetchSubmittedDetails = async () => {
    try {
      const response = await ApiService.getWithDiffBaseUrl(
        `/api/questionnaire/inspection-submit-details/${targetId}?moduleTags=USER`,
        null,
        URL_CONSTANTS.QUESTIONNAIRE.baseUrl
      );
      if (response.status && response.data?.length) {
        setModuleDetailsData(response.data);
      } else {
        setModuleDetailsData([]);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  const getModuleListByType = async () => {
    const response = await ApiService.getWithDiffBaseUrl(
      `/api/questionnaire/module/type-list/USER`,
      null,
      URL_CONSTANTS.QUESTIONNAIRE.baseUrl
    );
    if (response && response.data && response.data?.result)
      setModuleList(response.data?.result);
  };
  useEffect(() => {
    setLoading(true);
    Promise.all([fetchSubmittedDetails(), getModuleListByType()]).then(() =>
      setLoading(false)
    );
  }, []);

  const handleHide = async () => {
    setModule({
      ...module,
      isShowCheckListPopup: false,
    });
    if (refetchInspectionDetails) {
      setLoading(true);
      await fetchSubmittedDetails();
      setLoading(false);
      dispatch(
        updateQuestionnaireStore({
          refetchInspectionDetails: false,
        })
      );
    }
  };

  const onCloseViewCheckList = () => {
    setModule({
      selectedModule: null,
      isShowCheckListPopup: false,
      isNewRecord: false,
    });
    setIsPreviewVisible(false);
  };

  const panelHeaderTemplate = options => {
    const className = `${options.className} justify-content-between`;
    return (
      <div className={`${className} pt-2 pb-2`}>
        <div className="flex align-items-center">
          <span className="font-bold"></span>
        </div>
        <div className="flex gap-2 align-items-center">
          <IconField iconPosition="left">
            <InputIcon className="pi pi-search"> </InputIcon>
            <InputText
              placeholder="Search"
              value={searchQuery}
              onChange={e => {
                setSearchQuery(e.target.value);
              }}
            />
          </IconField>
        </div>
      </div>
    );
  };

  const tableColumn = [
    {
      field: 'action',
      header: 'Actions',
      style: { ...{ width: '150px' } },
      body: params =>
        loading ? (
          <Skeleton className="mb-2" height="2rem" />
        ) : (
          <div className="flex gap-3">
            <i
              className={`pi pi-eye cursor-pointer`}
              onClick={() => {
                setIsPreviewVisible(true);
                setModule({
                  ...module,
                  selectedModule: params,
                });
              }}
            ></i>
            <i
              className={`pi pi-pencil cursor-pointer`}
              onClick={event => {
                event.preventDefault();
                setModule({
                  selectedModule: params,
                  isShowCheckListPopup: true,
                  isNewRecord: false,
                });
              }}
            ></i>
            <i
              className={`pi pi-trash cursor-pointer`}
              onClick={() => {
                confirmReset(params);
              }}
            ></i>
          </div>
        ),
    },
    {
      field: 'module_name',
      header: 'Checklist',
      sortable: true,
      body: params =>
        loading ? (
          <Skeleton className="mb-2" height="2rem" />
        ) : (
          params.module_name
        ),
    },
    {
      field: 'label',
      header: 'Version',
      sortable: true,
      body: params => {
        return loading ? (
          <Skeleton className="mb-2" height="2rem" />
        ) : (
          <span
            role="button"
            tabIndex={0}
            className="text-primary cursor-pointer"
            onClick={event => {
              event.preventDefault();
              setModule({
                selectedModule: params,
                isShowCheckListPopup: true,
                isNewRecord: false,
              });
            }}
          >
            {params.label}
          </span>
        );
      },
    },
    {
      field: 'created_by',
      header: 'Submitted By',
      sortable: true,
      body: params =>
        loading ? (
          <Skeleton className="mb-2" height="2rem" />
        ) : (
          <span className="capitalize">{params.created_by}</span>
        ),
    },
    {
      field: 'created_at',
      header: 'Submitted At',
      sortable: true,
      body: params =>
        loading ? (
          <Skeleton className="mb-2" height="2rem" />
        ) : (
          formatDateTime(params.created_at)
        ),
    },
    {
      field: 'modified_by',
      header: 'Modified By',
      sortable: true,
      body: params =>
        loading ? (
          <Skeleton className="mb-2" height="2rem" />
        ) : (
          <span className="capitalize">{params.modified_by}</span>
        ),
    },
    {
      field: 'modified_at',
      header: 'Modified At',
      sortable: true,
      body: params =>
        loading ? (
          <Skeleton className="mb-2" height="2rem" />
        ) : (
          formatDateTime(params.modified_at)
        ),
    },
  ];

  const confirmReset = params => {
    showConfirmationDialog({
      message:
        'This action will delete all your responses against this checklist, would you still like to delete?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => resetAnswers(params),
      rejectLabel: 'Cancel',
      acceptLabel: 'Delete',
      acceptIcon: 'pi pi-trash',
    });
  };

  const resetAnswers = async params => {
    try {
      const response = await deleteAnswers(
        params?.module_id,
        targetId,
        params?.version
      );
      if (response?.status) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: response?.message || 'Answers refreshed successfully',
          life: 3000,
        });
        await fetchSubmittedDetails();
      }
    } catch (ex) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: ex?.response?.data?.message || 'something went wrong',
        life: 3000,
      });
    } finally {
      dispatch(
        updateQuestionnaireStore({
          isAnswerLoading: false,
        })
      );
    }
  };

  const searchModules = () => {
    if (searchQuery) {
      const lowerQuery = searchQuery.toLowerCase();
      return moduleDetailsData.filter(module => {
        return (
          module.module_name.toLowerCase().includes(lowerQuery) ||
          module.label.toLowerCase().includes(lowerQuery)
        );
      });
    } else {
      return moduleDetailsData;
    }
  };

  return (
    <>
      <div className="grid   w-12 my-3">
        <div className="col-6 md:col-7"></div>
        <div className="flex col-6 md:col-5 justify-content-end">
          <div className="w-6 mr-3">
            <Dropdown
              value={module.selectedModule}
              onChange={e => {
                setModule({
                  ...module,
                  selectedModule: e.value,
                });
              }}
              options={moduleList}
              className="w-full"
              optionLabel="module_name"
              placeholder="Select a Checklist"
              inputId="module"
              name="module"
            />
          </div>
          <div>
            <PFButton
              size="small"
              label="Create"
              severity="primary"
              icon={'pi pi-plus'}
              iconPos="left"
              onClick={e => {
                e.preventDefault();
                if (module.selectedModule) {
                  setModule({
                    ...module,
                    isShowCheckListPopup: true,
                    isNewRecord: true,
                  });
                } else {
                  toast.current.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Please select Checklist',
                    life: 3000,
                  });
                }
              }}
            />
          </div>
        </div>
      </div>
      <Panel
        headerTemplate={panelHeaderTemplate}
        pt={{
          content: { className: 'p-0' },
        }}
      >
        <DataTable
          value={searchModules()}
          paginator={true}
          rows={10}
          className="w-full"
        >
          {tableColumn.map(eachColumn => (
            <Column key={`${eachColumn?.field}`} {...eachColumn} />
          ))}
        </DataTable>
      </Panel>
      <PreviewCheckList
        isPreviewVisible={isPreviewVisible}
        setIsPreviewVisible={setIsPreviewVisible}
        module={module}
        targetId={targetId}
        onHide={onCloseViewCheckList}
      />

      <Dialog
        header={module.isNewRecord ? 'Create Checklist' : 'Update Checklist'}
        visible={module.isShowCheckListPopup}
        onHide={handleHide}
        style={{ minHeight: '550px', width: '90vw' }}
        pt={{
          header: {
            className: 'bg-blue-500 pt-2 pb-2',
          },
          headertitle: {
            className: 'text-white text-base',
            style: {
              fontFamily: '"Poppins", sans-serif',
            },
          },
          closebutton: {
            className: 'text-white text-sm',
          },
        }}
      >
        <AnswerComponent
          module={module}
          targetId={targetId}
          action={handleHide}
          toast={toast}
        />
      </Dialog>
      <Toast ref={toast} />
    </>
  );
};

export default UserChecklist;
