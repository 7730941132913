import React, { useRef } from 'react';
import { Toast } from 'primereact/toast';

import { SmsCountType, SMSResponse } from './Interface/sms.interface';
import { getSMSCountData, getSMSData } from './service/sms.service';
import SMSTable from './components/SMSTable';

const SMSContainer: React.FC<{
  project_id: number;
  phone_number: string;
}> = ({ project_id, phone_number }) => {
  const [loading, setLoading] = React.useState(true);
  const [smsList, setSMSList] = React.useState<SMSResponse | null>(null);
  const [smsCount, setSMSCount] = React.useState<SmsCountType>({
    sms_log_count: 0,
    sms_response_count: 0,
  });
  const toast = useRef<Toast>(null);
  const loadSMSData = async () => {
    setLoading(true);
    const data = await getSMSData(project_id);
    const smsCountResponse = await getSMSCountData(project_id);

    if (!smsCountResponse.error) {
      setSMSCount({
        sms_log_count: smsCountResponse?.smsCount?.sms_log_count,
        sms_response_count: smsCountResponse?.smsCount?.sms_response_count,
      });
    }
    if (!data?.error) {
      setSMSList(data?.smsData);
    } else {
      toast.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: data?.message,
        life: 3000,
      });
    }
    setLoading(false);
  };
  React.useEffect(() => {
    loadSMSData();
  }, [project_id]);

  return (
    <>
      <Toast ref={toast} />
      <div className="grid grid-nogutter">
        <SMSTable
          loading={loading}
          project_id={project_id}
          setLoading={setLoading}
          toast={toast}
          smsListData={smsList?.data || []}
          loadSMSData={loadSMSData}
          phone_number={phone_number}
          smsCount={smsCount}
        />
      </div>
    </>
  );
};
export default SMSContainer;
