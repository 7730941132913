import { useEffect, useState } from 'react';
import { Button, Grid, TextField, Typography, Paper } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import {
  AddCircleOutline as AddCircleOutlineIcon,
  RemoveCircleOutline as RemoveCircleOutlineIcon,
} from '@material-ui/icons';

import { useStyles } from './GroupBySection.styles';

const AggregationSection = ({ aggrName, measures, action, widgetFormik }) => {
  const [selectedMeasures, setSelectedMeasures] = useState([]);
  const initiateData = () => {
    const selectedDimension = measures?.find(
      a =>
        a?.name ===
        widgetFormik.values?.report_group_by_aggr?.[aggrName]?.dimension
    );
    if (selectedDimension?.measures) {
      setSelectedMeasures(selectedDimension.measures);
    }
  };

  useEffect(() => {
    if (action !== 'create') {
      initiateData();
    }
  }, []);
  return (
    <>
      <Grid item xs={3}>
        <Autocomplete
          fullWidth
          groupBy={option => option.firstLetter}
          options={measures}
          getOptionLabel={option => option && option.value}
          renderInput={params => (
            <TextField {...params} label="Aggregate Field" />
          )}
          disabled={action === 'view' ? true : false}
          value={measures?.find(
            a =>
              a.name ==
              widgetFormik?.values?.report_group_by_aggr?.[aggrName]?.dimension
          )}
          onChange={(event, value) => {
            if (value?.name) {
              setSelectedMeasures(value?.measures);
              widgetFormik.setFieldValue('report_group_by_aggr', {
                ...(widgetFormik?.values?.report_group_by_aggr || {}),
                [aggrName]: {
                  groupName: value?.firstLetter,
                  dimension: value?.name,
                  measureName: value?.value,
                  type: value?.type,
                  meta: value?.meta,
                  report_group_aggr_label: '',
                },
              });
            } else {
              if (widgetFormik?.values?.report_group_by_aggr?.[aggrName]) {
                const copy = widgetFormik?.values?.report_group_by_aggr || {};
                delete copy[aggrName];
                widgetFormik.setFieldValue('report_group_by_aggr', copy);
              }
            }
          }}
        />
      </Grid>
      {Array.isArray(selectedMeasures) &&
        selectedMeasures.length > 0 &&
        widgetFormik?.values?.report_group_by_aggr?.[aggrName] && (
          <>
            <Grid item xs={3}>
              <Autocomplete
                options={selectedMeasures || []}
                disabled={action === 'view' ? true : false}
                getOptionLabel={option => option.aggType}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Summarized By"
                  />
                )}
                value={selectedMeasures.find(
                  e =>
                    e.name ===
                    widgetFormik.values?.report_group_by_aggr?.[aggrName]
                      ?.measureSummarizedBy
                )}
                onChange={(event, value) => {
                  if (value?.name) {
                    widgetFormik.setFieldValue('report_group_by_aggr', {
                      ...widgetFormik.values.report_group_by_aggr,
                      [aggrName]: {
                        ...widgetFormik?.values?.report_group_by_aggr[aggrName],
                        measureSummarizedBy: value?.name,
                      },
                    });
                  } else {
                    if (
                      widgetFormik?.values?.report_group_by_aggr?.[aggrName]
                        ?.measureSummarizedBy
                    ) {
                      const copy =
                        widgetFormik?.values?.report_group_by_aggr || {};
                      delete copy[aggrName].measureSummarizedBy;
                      widgetFormik.setFieldValue('report_group_by_aggr', copy);
                    }
                  }
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Label"
                id="report_group_aggr_label"
                value={
                  widgetFormik?.values?.report_group_by_aggr?.[aggrName]
                    ?.report_group_aggr_label
                }
                onChange={event => {
                  if (event?.target?.value) {
                    widgetFormik.setFieldValue('report_group_by_aggr', {
                      ...widgetFormik.values.report_group_by_aggr,
                      [aggrName]: {
                        ...widgetFormik?.values?.report_group_by_aggr[aggrName],
                        report_group_aggr_label: event?.target?.value,
                      },
                    });
                  } else {
                    if (
                      widgetFormik?.values?.report_group_by_aggr?.[aggrName]
                        ?.report_group_aggr_label
                    ) {
                      const copy =
                        widgetFormik?.values?.report_group_by_aggr || {};
                      delete copy[aggrName].report_group_aggr_label;
                      widgetFormik.setFieldValue('report_group_by_aggr', copy);
                    }
                  }
                }}
                disabled={action === 'view' ? true : false}
              />
            </Grid>
          </>
        )}
    </>
  );
};
const GroupBySection = ({ action, widgetFormik, dimensionList, measures }) => {
  const classes = useStyles();
  const [aggrCountArr, setAggrCountArr] = useState([]);
  const [isChange, setIsChange] = useState(false);

  const handlePush = () => {
    const aggrCountArrCopy = [
      ...aggrCountArr,
      `report_group_aggr_${aggrCountArr.length}`,
    ];
    setAggrCountArr(aggrCountArrCopy);
  };
  const handlePop = index => {
    setIsChange(true);
    const aggrCountArrCopy = [...aggrCountArr];
    aggrCountArrCopy.splice(index, 1);
    const copy = widgetFormik?.values?.report_group_by_aggr;
    if (copy?.[`report_group_aggr_${index}`])
      delete copy[`report_group_aggr_${index}`];
    const aggrValues = Object.values(copy || {});
    const reportAggrObj = {};
    aggrCountArrCopy.forEach((e, i) => {
      reportAggrObj[`report_group_aggr_${i}`] = aggrValues[i];
    });
    widgetFormik.setFieldValue('report_group_by_aggr', reportAggrObj);
  };
  useEffect(() => {
    if (
      widgetFormik.values?.report_group_by_aggr &&
      Object.entries(widgetFormik.values?.report_group_by_aggr).length
    ) {
      setAggrCountArr(Object.keys(widgetFormik.values.report_group_by_aggr));
    } else setAggrCountArr([]);
    setIsChange(false);
  }, [widgetFormik.values?.report_group_by_aggr]);
  return (
    Array.isArray(dimensionList) &&
    dimensionList.length > 0 && (
      <>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h3">Group{/*  and Sort */} By</Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={1}>
          <Grid item xs={3}>
            <Autocomplete
              fullWidth
              key={widgetFormik.values?.report_group_by?.dimension}
              options={dimensionList}
              groupBy={option => option.firstLetter}
              getOptionLabel={option => option && option.value}
              renderInput={params => <TextField {...params} label="Field" />}
              disabled={action === 'view' ? true : false}
              value={
                dimensionList.filter(
                  each =>
                    each.name ===
                    widgetFormik.values?.report_group_by?.dimension
                )?.[0]
              }
              onChange={(event, value) => {
                if (value?.name) {
                  widgetFormik.setFieldValue('report_group_by', {
                    alice: value?.value,
                    dimension: value?.name,
                    group_by_label: '',
                    primaryKeyMeasureName: value?.primaryKeyMeasures?.filter(
                      ({ aggType }) => aggType === 'count'
                    )?.[0]?.name,
                  });
                } else {
                  widgetFormik.setFieldValue('report_group_by', null);
                }
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="Label"
              id="group_by_label"
              value={widgetFormik.values?.report_group_by?.group_by_label || ''}
              onChange={event => {
                widgetFormik.setFieldValue('report_group_by', {
                  ...widgetFormik.values?.report_group_by,
                  group_by_label: event?.target?.value,
                });
              }}
              disabled={action === 'view' ? true : false}
            />
          </Grid>
        </Grid>
        {!isChange &&
          widgetFormik.values?.report_group_by &&
          Array.isArray(measures) && (
            <Grid container item direction="row" alignItems="center">
              <Grid item xs={11}>
                <Paper elevation={0} className={classes.paper}>
                  {aggrCountArr.length > 0 ? (
                    aggrCountArr.map((e, i) => (
                      <Grid
                        container
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        key={e}
                      >
                        <AggregationSection
                          aggrName={`report_group_aggr_${i}`}
                          measures={measures}
                          action={action}
                          widgetFormik={widgetFormik}
                        />
                        {action !== 'view' && (
                          <div className="flex gap-5">
                            <RemoveCircleOutlineIcon
                              color="primary"
                              fontSize="large"
                              onClick={() => handlePop(i)}
                            />
                            {i === aggrCountArr.length - 1 &&
                              aggrCountArr.length < 3 && (
                                <AddCircleOutlineIcon
                                  color="primary"
                                  fontSize="large"
                                  onClick={handlePush}
                                />
                              )}
                          </div>
                        )}
                      </Grid>
                    ))
                  ) : (
                    <Grid item>
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={handlePush}
                        startIcon={<AddCircleOutlineIcon />}
                      >
                        Add Aggregate (max 3 allowed)
                      </Button>
                    </Grid>
                  )}
                </Paper>
              </Grid>
            </Grid>
          )}
      </>
    )
  );
};

export default GroupBySection;
