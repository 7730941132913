import React, { useEffect, useState, useRef, useMemo } from 'react';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { useSelector, useDispatch } from 'react-redux';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';

import FileUploadTemplate from '../../shared/file-upload/file-upload-template';
import { setUserDocumentsForm } from '../../../redux/slices/user-form-documents.slice';
import TableColumnsLoader from '../../shared/Loader/tableColumnsLoader';
import { useConfirmDialogContext } from '../../../contexts/ConfirmDialog';
import PFButton from '../../shared/PFPrime/PFButton';

import { uploadUserFile } from './services/user-form-service';
import { domHandlerCall } from './common';
import {
  editUserDocuments,
  getUserDocumentsData,
} from './services/user-form-documents.service';
const emptyRow = {
  file: '',
  description: '',
  notes: '',
};

const mapTableDataForPayload = tableData => {
  const mappedPayload = tableData
    .map(row => {
      if (!row?.notes || !row?.description) {
        return null;
      } else {
        return {
          notes: row?.notes,
          description: row?.description,
          file: row?.file,
        };
      }
    })
    .filter(i => i !== null);
  return mappedPayload;
};

const UserFormDocuments = () => {
  const { showConfirmationDialog } = useConfirmDialogContext();
  const { isLoading, isFileUploading, documentsData } = useSelector(
    state => state.userFormDocuments
  );
  const { userId } = useSelector(state => state.userForm);
  const dispatch = useDispatch();
  const toast = useRef(null);
  const [isUploaderVisible, setIsUploaderVisible] = useState(false);
  const [newUserDocument, setNewUserDocument] = useState(null);
  const [fileUploaderRowIndex, setFileUploaderRowIndex] = useState();
  const [isDisableAddButton, setIsDisableAddButton] = useState(false);

  const fetchInitiationData = async () => {
    dispatch(setUserDocumentsForm({ isLoading: true }));
    try {
      const initializationData =
        !!userId && (await getUserDocumentsData(userId));
      const { documents } = initializationData;
      dispatch(
        setUserDocumentsForm({
          documentsData: documents,
        })
      );
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setUserDocumentsForm({ isLoading: false }));
    }
  };

  useEffect(() => {
    fetchInitiationData();
    setIsDisableAddButton(false);
  }, [userId]);

  const handleDelete = async rowIndex => {
    let editedData = structuredClone(documentsData);
    editedData.splice(rowIndex, 1);
    setNewUserDocument(null);
    dispatch(
      setUserDocumentsForm({ documentsData: editedData, isLoading: true })
    );
    const payload = mapTableDataForPayload(editedData);
    let deleteResponse;
    try {
      deleteResponse = await editUserDocuments(userId, payload);
      if (deleteResponse?.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Deleted row',
          life: 2000,
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(setUserDocumentsForm({ isLoading: false }));
    }
  };

  const handleDeleteCancel = () => {
    disableEditButton(false);
  };

  const handleOnRowEditCancel = async options => {
    showConfirmationDialog({
      message:
        'Please confirm if you would like to cancel your unsaved changes or delete this record?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle text-xl',
      acceptClassName: 'p-button-primary p-button-sm',
      rejectClassName: 'p-button-sm p-button-outlined',
      accept: () => handleDelete(options?.rowIndex),
      reject: handleDeleteCancel,
      onHide: handleDeleteCancel,
      rejectLabel: 'Cancel',
      acceptLabel: 'Delete',
      className: 'text-sm w-11 md:w-8 lg:w-5',
    });
  };

  const FileTemplate = rowData => {
    return (
      <a
        href={rowData?.file?.url}
        target="_blank"
        rel="noreferrer"
        download={rowData?.file?.name}
      >
        {rowData?.file?.name}
      </a>
    );
  };

  const nameEditor = options => {
    return (
      <InputText
        type="text"
        className="p-inputtext-sm"
        value={options.value}
        onChange={e => options.editorCallback(e.target.value)}
      />
    );
  };

  const handleUploaderClick = () => {
    setIsUploaderVisible(true);
  };

  const handleUserDocumentUpload = async e => {
    try {
      dispatch(setUserDocumentsForm({ isFileUploading: true }));
      setIsUploaderVisible(false);
      if (e?.files && e?.files?.length > 0) {
        setNewUserDocument(e.files[0]);
        const fileUpload = await uploadUserFile(
          userId,
          'documents',
          e.files[0]
        );
        if (fileUpload?.name && fileUpload?.url) {
          let editedDocuments = structuredClone(documentsData);
          const rowData = editedDocuments[fileUploaderRowIndex];
          rowData['file'] = fileUpload;
          editedDocuments[fileUploaderRowIndex] = rowData;
          dispatch(setUserDocumentsForm({ documentsData: editedDocuments }));
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(setUserDocumentsForm({ isFileUploading: false }));
    }
  };

  const fileUploadEditor = options => {
    setFileUploaderRowIndex(options?.rowIndex);
    return (
      <>
        <a
          href={documentsData[options?.rowIndex]?.file?.url}
          target="_blank"
          rel="noreferrer"
          download={documentsData[options?.rowIndex]?.file?.name}
        >
          {documentsData[options?.rowIndex]?.file?.name}
        </a>
        <i
          role="button"
          tabIndex={0}
          className="ml-2 pi pi-upload cursor-pointer"
          onClick={handleUploaderClick}
          onKeyDown={e => {
            if (e?.key === 'Enter' || e?.key === ' ') {
              handleUploaderClick();
            }
          }}
        ></i>
        {isFileUploading && (
          <ProgressSpinner
            style={{ width: '30px', height: '30px' }}
            strokeWidth="6"
            fill="var(--surface-ground)"
            animationDuration=".5s"
          />
        )}
      </>
    );
  };

  const handleAddNewRow = () => {
    dispatch(
      setUserDocumentsForm({
        documentsData: [emptyRow, ...documentsData],
      })
    );
    setTimeout(() => {
      const edit = domHandlerCall('p-row-editor-init');
      edit?.handler[0].click();
    }, 300);
  };
  const disableEditButton = status => {
    setTimeout(() => {
      const edit = domHandlerCall('p-row-editor-init');
      edit?.handler?.forEach(row => {
        row.disabled = status;
      });
      setIsDisableAddButton(status);
    }, 300);
  };

  const onRowEditComplete = async e => {
    let editedDocuments = structuredClone(documentsData);
    let { data, newData, index } = e;
    editedDocuments[index] = {
      file: data?.file,
      notes: newData?.notes,
      description: newData?.description,
    };
    dispatch(
      setUserDocumentsForm({ documentsData: editedDocuments, isLoading: true })
    );
    let editedResponse;
    try {
      const payload = mapTableDataForPayload(editedDocuments);
      editedResponse = await editUserDocuments(userId, payload);
      if (editedResponse?.status === 200) {
        fetchInitiationData();
        toast.current.show({
          severity: 'success',
          summary: 'Updated Documents',
          life: 2000,
        });
        setNewUserDocument(null);
      }
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(setUserDocumentsForm({ isLoading: false }));
      disableEditButton(false);
      setIsDisableAddButton(false);
    }
  };

  const handleRowValidation = e => {
    const validationError = [];
    if (!newUserDocument) {
      validationError.push('File');
    }
    if (!e?.description) {
      validationError.push('Description');
    }
    if (!e?.notes) {
      validationError.push('Notes');
    }

    if (validationError.length > 0) {
      toast.current.show({
        severity: 'error',
        summary: `${validationError.join(',')} required`,
        life: 2000,
      });
      return false;
    }
    return true;
  };

  const documentsTableColumns = [
    {
      field: 'file',
      header: 'File',
      ...(!isLoading
        ? {
            editor: options => fileUploadEditor(options),
            body: rowData => FileTemplate(rowData),
          }
        : {}),
    },
    {
      field: 'description',
      header: 'Description',
      ...(!isLoading
        ? {
            editor: options => nameEditor(options),
          }
        : {}),
    },
    {
      field: 'notes',
      header: 'Notes',
      ...(!isLoading
        ? {
            editor: options => nameEditor(options),
          }
        : {}),
    },
    {
      field: 'edit',
      header: '',
      ...(!isLoading
        ? {
            rowEditor: true,
          }
        : {}),
      headerStyle: { width: '15%', minWidth: '7rem' },
      bodyStyle: { textAlign: 'center' },
    },
  ];

  const documentsTableLoader = useMemo(() => {
    return TableColumnsLoader(documentsTableColumns, {
      rows: 4,
      isValue: false,
    });
  }, [documentsTableColumns]);

  const rowEditorWithCustomButton = (rowData, options) => {
    return (
      <div className="flex justify-content-center align-items-center flex-wrap gap-2">
        {!options.rowEditor.editing && (
          <PFButton
            icon="pi pi-trash"
            outlined
            text
            className="text-white border-none px-0 p-button-danger"
            severity="primary"
            aria-label="Edit"
            onClick={() => handleOnRowEditCancel(options)}
          />
        )}
      </div>
    );
  };

  return (
    <>
      <div className="flex flex-row justify-content-between align-items-center py-3">
        <h4>Documents</h4>
        <Button
          label="Add Documents"
          severity="primary"
          size="small"
          onClick={handleAddNewRow}
          disabled={isDisableAddButton}
        />
      </div>
      <DataTable
        value={isLoading ? documentsTableLoader : documentsData}
        {...(!isLoading
          ? {
              editMode: 'row',
              onRowEditComplete: onRowEditComplete,
              rowEditValidator: handleRowValidation,
              onRowEditCancel: () => {
                disableEditButton(false);
              },
              onRowEditInit: e => {
                setNewUserDocument(e?.data?.file);
                disableEditButton(true);
              },
            }
          : {})}
      >
        {documentsTableColumns?.map(col => (
          <Column key={col.field} {...col} />
        ))}
        {!isLoading && (
          <Column
            rowEditor={true}
            headerStyle={{ width: '15%', minWidth: '10rem' }}
            bodyStyle={{ textAlign: 'center' }}
            body={rowEditorWithCustomButton}
          />
        )}
      </DataTable>
      <Dialog
        header="Upload Document"
        visible={isUploaderVisible}
        style={{ width: '50vw' }}
        onHide={() => setIsUploaderVisible(false)}
      >
        <FileUploadTemplate
          onFileUpload={handleUserDocumentUpload}
          acceptedFormat="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        />
      </Dialog>
      <Toast ref={toast}></Toast>
    </>
  );
};

export default UserFormDocuments;
