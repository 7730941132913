export const REACT_APP_LOGIN_AUTH = process.env.REACT_APP_LOGIN_AUTH;
export const REACT_APP_AUTH_CLIENT_SECRET =
  process.env.REACT_APP_AUTH_CLIENT_SECRET;
export const REACT_APP_AUTH_CLIENT_ID = process.env.REACT_APP_AUTH_CLIENT_ID;
export const REACT_APP_AUTH_REFRESH_GRANT_TYPE =
  process.env.REACT_APP_AUTH_REFRESH_GRANT_TYPE;
export const REACT_APP_YOUTUBE_URL = process.env.REACT_APP_YOUTUBE_URL;
export const REACT_APP_DW_BETA = process.env.REACT_APP_DW_BETA;
export const PUBLIC_URL = process.env.PUBLIC_URL;
export const REACT_APP_DOCUMENTS_MS_API =
  process.env.REACT_APP_DOCUMENTS_MS_API;
export const REACT_APP_GOOGLE_MAPS_KEY = process.env.REACT_APP_GOOGLE_MAPS_KEY;
export const REACT_APP_YOUTUBE_CHANNEL_ID =
  process.env.REACT_APP_YOUTUBE_CHANNEL_ID;
export const REACT_APP_YOUTUBE_KEY = process.env.REACT_APP_YOUTUBE_KEY;
export const REACT_APP_YOUTUBE_MAX_RESULTS =
  process.env.REACT_APP_YOUTUBE_MAX_RESULTS;
export const REACT_APP_GMAPS_KEY = process.env.REACT_APP_GMAPS_KEY;
export const REACT_APP_PSPDFKIT_LICENCE_KEY =
  process.env.REACT_APP_PSPDFKIT_LICENCE_KEY;
export const REACT_APP_PF_ADMIN_ID = process.env.REACT_APP_PF_ADMIN_ID;
export const REACT_APP_CUBE_BASEURL = process.env.REACT_APP_CUBE_BASEURL;
export const REACT_APP_SUPER_CLIENTS = process.env.REACT_APP_SUPER_CLIENTS;
export const REACT_APP_ALLOWED_CLIENTS_FOR_WIDGET_CREATION =
  process.env.REACT_APP_ALLOWED_CLIENTS_FOR_WIDGET_CREATION;
export const REACT_APP_VIEW_DASHBOARD_PERFORMANCE_USERS =
  process.env.REACT_APP_VIEW_DASHBOARD_PERFORMANCE_USERS;
export const REACT_APP_ENABLE_CUSTOMER_LIST =
  process.env.REACT_APP_ENABLE_CUSTOMER_LIST;
export const REACT_APP_AUDIT_TAB_ENABLED =
  process.env.REACT_APP_AUDIT_TAB_ENABLED;
export const REACT_PF_CSV_UPLOAD_SIZE = process.env.REACT_PF_CSV_UPLOAD_SIZE;
export const REACT_APP_CONFIGURATION_AUDIT_LOG_ENABLED =
  process.env.REACT_APP_CONFIGURATION_AUDIT_LOG_ENABLED;

export const REACT_APP_AUTH_AUTHORIZE_GRANT_TYPE =
  process.env.REACT_APP_AUTH_AUTHORIZE_GRANT_TYPE;

export const REACT_APP_RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY;
export const REACT_APP_CRYPTO_SECRET_KEY =
  process.env.REACT_APP_CRYPTO_SECRET_KEY;

export const REACT_APP_SYNCFUSION_LICENCE_KEY =
  process.env.REACT_APP_SYNCFUSION_LICENCE_KEY;

export const REACT_APP_SYSTEM_USER_ID =
  process.env.REACT_APP_SYSTEM_USER_ID || '';
export const REACT_APP_FIREBASE_API_KEY =
  process.env.REACT_APP_FIREBASE_API_KEY;
export const REACT_APP_YOUR_AUTH_DOMAIN =
  process.env.REACT_APP_YOUR_AUTH_DOMAIN;
export const REACT_APP_YOUR_PROJECT_ID = process.env.REACT_APP_YOUR_PROJECT_ID;
export const REACT_APP_YOUR_STORAGE_BUCKET =
  process.env.REACT_APP_YOUR_STORAGE_BUCKET;
export const REACT_APP_YOUR_MESSAGING_SENDER_ID =
  process.env.REACT_APP_YOUR_MESSAGING_SENDER_ID;
export const REACT_APP_YOUR_APP_ID = process.env.REACT_APP_YOUR_APP_ID;
export const REACT_APP_VAPID_KEY = process.env.REACT_APP_VAPID_KEY;

export const REACT_APP_API_SCHEDULER_BASEURL =
  process.env.REACT_APP_API_SCHEDULER_BASEURL;
export const REACT_APP_ENABLE_PUSH_NOTIFICATION =
  process.env.REACT_APP_ENABLE_PUSH_NOTIFICATION !== undefined
    ? (JSON.parse(process.env.REACT_APP_ENABLE_PUSH_NOTIFICATION) ?? true)
    : true;
export const REACT_APP_ENABlE_ONLY_FOR_PF_USER =
  process.env.REACT_APP_ENABlE_ONLY_FOR_PF_USER !== undefined
    ? (JSON.parse(process.env.REACT_APP_ENABlE_ONLY_FOR_PF_USER) ?? true)
    : true;

export const REACT_APP_BULLETIN_BASEURL =
  process.env.REACT_APP_BULLETIN_BASEURL;

export const REACT_APP_API_BASEURL = process.env.REACT_APP_API_BASEURL;
export const REACT_APP_UM_BASEURL = process.env.REACT_APP_UM_BASEURL;

export const REACT_APP_PAYROLL_API_BASEURL =
  process.env.REACT_APP_PAYROLL_API_BASEURL;

export const REACT_APP_CRM_API_BASEURL = process.env.REACT_APP_CRM_API_BASEURL;
export const REACT_APP_AUDIT_LOG_BASEURL =
  process.env.REACT_APP_AUDIT_LOG_BASEURL;
export const REACT_APP_API_PROJECT_BASEURL =
  process.env.REACT_APP_API_PROJECT_BASEURL;
export const REACT_APP_QUESTIONNAIRE_API_BASEURL =
  process.env.REACT_APP_QUESTIONNAIRE_API_BASEURL;
export const REACT_APP_INVENTORY_MS_API =
  process.env.REACT_APP_INVENTORY_MS_API;
export const REACT_APP_NOTIFICATION_BASEURL =
  process.env.REACT_APP_NOTIFICATION_BASEURL;
