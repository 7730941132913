import { React, useState, useEffect } from 'react';
import { Card } from 'primereact/card';

import PFCalendar from '../../shared/PFPrime/PFCalendar';
import PFDropdown from '../../shared/PFPrime/PFDropdown';
import PFTextArea from '../../shared/PFPrime/PFTextArea';
import ApiService from '../../../services/api.service';

const AdditionalFields = ({ formik, action }) => {
  const [workOrderTriageList, setWorkOrderTriageList] = useState([]);
  const [workOrderOriginalCauseList, setWorkOriginalCauseList] = useState([]);
  const [workOrderReasonList, setWorkOrderReasonList] = useState([]);

  const getWoTriageList = async () => {
    const response = await ApiService.get(`/system/types/wo_triage`);

    if (response && response?.length) {
      setWorkOrderTriageList(response);
    }
  };
  const getWoOriginalCauseList = async () => {
    const response = await ApiService.get(`/system/types/wo_original_cause`);
    if (response && response?.length) {
      setWorkOriginalCauseList(response);
    }
  };
  const getWoReasonList = async () => {
    const response = await ApiService.get(`/system/types/wo_reason`);
    if (response && response?.length) {
      setWorkOrderReasonList(response);
    }
  };

  useEffect(() => {
    getWoTriageList();
    getWoOriginalCauseList();
    getWoReasonList();
  }, []);
  return (
    <>
      <Card
        className="mb-3 shadow-none"
        title="Additional Fields"
        pt={{ body: { className: 'px-0' } }}
      >
        <div className="grid">
          <div className="col-12 md:col-4 lg:col-3 mb-3 py-0">
            <span className="p-float-label w-full">
              <PFDropdown
                inputId="wo_triage_id"
                options={workOrderTriageList}
                optionLabel="label"
                value={workOrderTriageList?.find(triage => {
                  return triage?.type_id === formik?.values?.wo_triage_id;
                })}
                onChange={(name, value) => {
                  formik.setFieldValue('wo_triage_id', value?.type_id);
                }}
                pt={{
                  root: {
                    className: 'w-full',
                  },
                }}
                disabled={action === 'view'}
              />
              <label htmlFor="wo_triage_id">Work Order Triage</label>
            </span>
          </div>
          <div className="col-12 md:col-4 lg:col-3 mb-3 py-0">
            <span className="p-float-label w-full">
              <PFCalendar
                inputId="wo_original_install_date"
                pt={{
                  input: {
                    root: { className: 'p-inputtext-sm' },
                  },
                }}
                onChange={e => {
                  formik.setFieldValue('wo_original_install_date', e?.value);
                }}
                value={
                  formik?.values?.wo_original_install_date
                    ? new Date(formik?.values?.wo_original_install_date)
                    : null
                }
                disabled={action === 'view'}
              />

              <label htmlFor="wo_original_install_date">
                Original Install Date{' '}
              </label>
            </span>
          </div>

          <div className="col-12 md:col-4 lg:col-3 mb-3 py-0">
            <span className="p-float-label w-full">
              <PFCalendar
                inputId="wo_wfp_eta"
                value={
                  formik?.values?.wo_wfp_eta
                    ? new Date(formik?.values?.wo_wfp_eta)
                    : null
                }
                onChange={e => {
                  formik.setFieldValue('wo_wfp_eta', e?.value);
                }}
                pt={{
                  input: {
                    root: { className: 'p-inputtext-sm' },
                  },
                }}
                disabled={action === 'view'}
              />

              <label htmlFor="wo_wfp_eta">Work Order WFP/ETA </label>
            </span>
          </div>

          <div className="col-12 md:col-4 lg:col-3 mb-3 py-0">
            <span className="p-float-label w-full">
              <PFDropdown
                inputId="wo_original_cause_id"
                options={workOrderOriginalCauseList}
                optionLabel="label"
                value={workOrderOriginalCauseList?.find(cause => {
                  return (
                    cause?.type_id === formik?.values?.wo_original_cause_id
                  );
                })}
                onChange={(name, value) => {
                  formik.setFieldValue('wo_original_cause_id', value?.type_id);
                }}
                pt={{
                  root: {
                    className: 'w-full',
                  },
                }}
                disabled={action === 'view'}
              />
              <label htmlFor="wo_original_cause_id">
                WO Originating Cause{' '}
              </label>
            </span>
          </div>

          <div className="col-12 md:col-6 lg:col-6 mb-3 py-0">
            <span className="p-float-label w-full">
              <PFTextArea
                id="wo_explaination"
                rows={2}
                value={formik?.values?.wo_explaination}
                className="w-full"
                onChange={e => {
                  formik.setFieldValue('wo_explaination', e?.target?.value);
                }}
                maxlength={500}
                pt={{
                  root: { readOnly: action === 'view' },
                }}
              />
              <label htmlFor="wo_explaination">WO Explanation </label>
            </span>
          </div>

          <div className="col-12 md:col-4 lg:col-3 mb-3 py-0">
            <span className="p-float-label w-full">
              <PFDropdown
                inputId="wo_reason_id"
                options={workOrderReasonList}
                optionLabel="label"
                value={workOrderReasonList.find(triage => {
                  return triage?.type_id === formik?.values?.wo_reason_id;
                })}
                onChange={(name, value) => {
                  formik.setFieldValue('wo_reason_id', value?.type_id);
                }}
                pt={{
                  root: {
                    className: 'w-full',
                  },
                }}
                disabled={action === 'view'}
              />
              <label htmlFor="wo_reason_id">Work Order Reason</label>
            </span>
          </div>
        </div>
      </Card>
    </>
  );
};

export default AdditionalFields;
