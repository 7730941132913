import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import '../../Locations/css/warehouseStyle.css';
import Geocode from 'react-geocode';
import { debounce } from 'lodash';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { AutoComplete } from 'primereact/autocomplete';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { Button } from '@material-ui/core';
import { ProgressSpinner } from 'primereact/progressspinner';

import { transformAddressFromGeocode } from '../../../utils/Helpers';
import { googleMapsKey } from '../../../config/variables';

import { createCustomAddress } from './ScheduleInfo.service';
import AddCustomAddressForm from './AddCustomAddressForm';

export default function AddCustomAddress({
  setCustomAddressLocationPopup,
  getAddressDetailsById,
  isLoaded,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [places, setPlaces] = useState([]);
  const [mapInstance, setMapInstance] = useState(null);
  const [zoom, setZoom] = useState(12);
  const [address, setAddress] = useState('');

  // On Map Load
  const onMapLoad = React.useCallback(map => {
    setMapInstance(map);
  }, []);

  // Center
  const [center, setCenter] = useState({
    lat: 36.11310790984111,
    lng: -115.17653312308538,
  });

  const mapStyles = {
    height: '400px',
    width: '100%',
  };

  useEffect(() => {
    Geocode.setApiKey(googleMapsKey);
    Geocode.setLanguage('en');
  }, []);

  const formik = useFormik({
    initialValues: {
      name: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zipcode: '',
      latitude: '',
      longitude: '',
    },
    enableReinitialize: true,
    onSubmit: async data => {
      setIsLoading(true);
      const response = await createCustomAddress(data);
      if (response) {
        getAddressDetailsById(response.address_id, true);
        setIsLoading(false);
        setCustomAddressLocationPopup(false);
      }
    },
  });

  const searchGeocode = React.useCallback(
    debounce(formValues => {
      updateMapLocation(formValues);
    }, 1000),
    []
  );

  const validateAddress = () => {
    if (
      !formik.values.name ||
      !formik.values.address1 ||
      !formik.values.address2 ||
      !formik.values.city ||
      !formik.values.state
    )
      return false;
    else return true;
  };

  useEffect(() => {
    if (validateAddress()) searchGeocode(formik.values);
  }, [
    formik.values.address1,
    formik.values.address2,
    formik.values.city,
    formik.values.state,
    formik.values.zipcode,
  ]);

  const formattedAddress = formValues => {
    return `${formValues.address1}, ${formValues.address2}, ${formValues.city}, ${formValues.state}, ${formValues.zipcode}`;
  };

  const updateMapLocation = async formValues => {
    const address = formattedAddress(formValues);
    const geoCodeResponse = await Geocode.fromAddress(address);
    if (
      geoCodeResponse &&
      geoCodeResponse.results &&
      geoCodeResponse.results[0]
    ) {
      const coordinates = {
        ...geoCodeResponse.results[0].geometry.location,
      };

      setPlaces([coordinates]);
      setCenter(coordinates);
      setZoom(12);

      if (mapInstance) {
        mapInstance.panTo(coordinates);
        mapInstance.setZoom(12);
      }
    }
  };

  const populateGeocodeResponse = response => {
    const { addressLineOne, addressLineTwo, city, zipcode, state } =
      transformAddressFromGeocode(response.results[0].address_components);
    const latitude =
      typeof response.results[0].geometry.location.lat === 'function'
        ? response.results[0].geometry.location.lat()
        : response.results[0].geometry.location.lat;
    const longitude =
      typeof response.results[0].geometry.location.lng === 'function'
        ? response.results[0].geometry.location.lng()
        : response.results[0].geometry.location.lng;
    formik.setFieldValue('city', city || '');
    formik.setFieldValue('state', state || '');
    formik.setFieldValue('address1', addressLineOne || '');
    formik.setFieldValue('address2', addressLineTwo || '');
    formik.setFieldValue('zipcode', zipcode || '');
    formik.setFieldValue('latitude', latitude);
    formik.setFieldValue('longitude', longitude);
  };

  const handleChange = address => {
    setAddress(address);
  };

  const handleSelect = address => {
    geocodeByAddress(address)
      .then(results => {
        getLatLng(results[0]).then(latLng => {
          const coordinates = {
            lat: latLng.lat,
            lng: latLng.lng,
          };
          setPlaces([coordinates]);
          setCenter(coordinates);
          populateGeocodeResponse({ results });
          setAddress(address);
          if (mapInstance) {
            mapInstance.panTo(coordinates);
            mapInstance.setZoom(12);
          }
          setZoom(12);
        });
      })
      .catch(error => console.error('Error', error));
  };

  const onClickDragLocationSelected = e => {
    const coordinates = {
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    };

    setPlaces([coordinates]);
    setCenter(coordinates);

    if (mapInstance) {
      mapInstance.panTo(coordinates);
      mapInstance.setZoom(12);
    }
    setZoom(12);

    // Get address from latitude & longitude.
    Geocode.fromLatLng(coordinates.lat, coordinates.lng).then(
      response => {
        populateGeocodeResponse(response);
      },
      error => {
        console.error(error);
      }
    );
  };
  return (
    <div className="card flex justify-content-center">
      <form onSubmit={formik.handleSubmit} className="w-12 m-1">
        {isLoading ? (
          <div className="card flex justify-content-center">
            <ProgressSpinner />
          </div>
        ) : (
          <div className="grid pt-1">
            <div className="col-12 md:col-4 lg:col-4 m-0 p-0">
              <AddCustomAddressForm formik={formik} />
            </div>
            <div className="col-12 md:col-8 lg:col-8 p-input-icon-right m-0 p-0">
              <div className="col-12 md:col-12 lg:col-12 p-input-icon-right pb-0">
                <div className="m-0 grid ">
                  <div className="col-6 md:col-6 lg:col-6 p-0 p-0">
                    {isLoaded && (
                      <PlacesAutocomplete
                        value={address}
                        onChange={handleChange}
                        onSelect={handleSelect}
                        searchOptions={{
                          componentRestrictions: { country: 'us' },
                        }}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div>
                            <span className="p-float-label">
                              <AutoComplete
                                {...getInputProps({
                                  className: 'location-search-input mb-1 w-12',
                                })}
                              />
                              <label htmlFor="ac">Search Places</label>
                            </span>

                            <div className="autocomplete-dropdown-container">
                              {loading && <div>Loading...</div>}
                              {suggestions?.map((suggestion, index) => {
                                const className = suggestion.active
                                  ? 'suggestion-item--active'
                                  : 'suggestion-item';
                                const style = suggestion.active
                                  ? {
                                      backgroundColor: '#fafafa',
                                      cursor: 'pointer',
                                    }
                                  : {
                                      backgroundColor: '#ffffff',
                                      cursor: 'pointer',
                                    };
                                return (
                                  <div
                                    key={index + suggestion.description}
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-12 md:col-12 lg:col-12 p-input-icon-right pt-0">
                {isLoaded && (
                  <GoogleMap
                    onClick={onClickDragLocationSelected}
                    onLoad={onMapLoad}
                    mapContainerStyle={mapStyles}
                    zoom={zoom}
                    center={center}
                  >
                    {places?.map((marker, index) => {
                      const position = {
                        lat: parseFloat(marker.lat),
                        lng: parseFloat(marker.lng),
                      };
                      return (
                        <Marker
                          key={index}
                          position={position}
                          onDragEnd={onClickDragLocationSelected}
                          draggable={true}
                        />
                      );
                    })}
                  </GoogleMap>
                )}
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexGrow: 1,
                flexDirection: 'row-reverse',
                gap: '15px',
                marginTop: '15px',
              }}
            >
              <Button
                onClick={() => setCustomAddressLocationPopup(false)}
                color="primary"
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                autoFocus
                disabled={!validateAddress()}
              >
                Save
              </Button>
            </div>
          </div>
        )}
      </form>
    </div>
  );
}
