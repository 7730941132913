import { Tag } from 'primereact/tag';
import axios from 'axios';
import moment from 'moment';

import { CONFIG } from '../../../../constants';
import PFButton from '../../../shared/PFPrime/PFButton';

import { downloadQuote, previewQuote } from './Quotes';

export const quoteGridColumns = (
  showOpportunityNumber = false,
  showOldQuotes = false,
  toast
) => {
  let main = [
    {
      field: 'actions',
      header: 'Actions',
      sortable: false,
      filter: false,
      body: rowData => (
        <div className="flex w-max">
          <PFButton
            icon="pi pi-eye"
            outlined
            text
            className="text-white border-none p-1"
            severity="primary"
            aria-label="View"
            onClick={() => {
              window.open(
                `/crm/${rowData?.customerId}/${rowData.opportunityId}/quote/view/${rowData.quoteId}`
              );
            }}
          />
          {!showOldQuotes && (rowData?.projectCounts || 0) < 1 && (
            <PFButton
              icon="pi pi-pencil"
              outlined
              text
              className="text-white border-none"
              severity="primary"
              aria-label="Edit"
              onClick={() => {
                window.open(
                  `/crm/${rowData?.customerId}/${rowData.opportunityId}/quote/edit/${rowData.quoteId}`
                );
              }}
            />
          )}
          <div className="cursor-pointer">
            <PFButton
              icon="pi pi-download"
              outlined
              text
              className="text-white border-none"
              severity="primary"
              aria-label="Download"
              onClick={e => {
                e.target.disabled = true;
                const { customerId, opportunityId, quoteId } = rowData;
                const download = downloadQuote(
                  customerId,
                  opportunityId,
                  quoteId
                );
                download
                  .then(res => {
                    if (res?.status === 200) {
                      const pdfUrl = res?.data?.data;

                      const link = document.createElement('a');
                      link.href = pdfUrl;
                      link.target = '_blank';
                      link.download = 'your-file.pdf';
                      document.body.appendChild(link);
                      link.click();
                      setTimeout(() => {
                        link.remove();
                      }, 2000);

                      toast.current.show({
                        severity: 'success',
                        summary: 'success',
                        detail: res?.data?.data?.message,
                      });
                      e.target.disabled = false;
                    }
                  })
                  .catch(error => {
                    console.log('error', error);
                  });
              }}
            />
          </div>
          {!showOldQuotes && (
            <div className="cursor-pointer">
              <PFButton
                icon="pi pi-envelope"
                outlined
                text
                className="text-white border-none"
                severity="primary"
                aria-label="Email"
                onClick={() => {
                  const { customerId, opportunityId, quoteId } = rowData;

                  const preview = previewQuote(
                    customerId,
                    opportunityId,
                    quoteId
                  );
                  preview
                    .then(res => {
                      if (res.status === 200) {
                        const newWindow = window.open(
                          'quote-preview',
                          '_blank'
                        );
                        newWindow.document.write(res?.data);
                        newWindow.document.close();
                      }
                    })
                    .catch(err => {
                      console.log('err', err);
                    });
                }}
              />
            </div>
          )}
        </div>
      ),
      className: 'vertical-align-top w-2',
    },
    {
      field: 'opportunityNumber',
      header: 'Lead#',
      sortable: true,
      filter: false,
      hidden: !showOpportunityNumber,
      body: data => <div>{data.opportunityNumber}</div>,
      className: 'vertical-align-top',
    },
    {
      field: 'quoteNumber',
      header: 'Quote #',
      sortable: true,
      filter: false,
      body: data => <div>{data.quoteNumber}</div>,
      className: 'vertical-align-top',
    },
    {
      field: 'createdAt',
      header: 'Created On',
      sortable: true,
      filter: false,
      body: data => {
        return data.createdAt
          ? moment(data.createdAt, 'YYYY-MM-DD hh:mm A').format('MM-DD-YYYY')
          : '';
      },
      className: 'vertical-align-top',
    },
    {
      field: 'expiryDate',
      header: 'Valid Until',
      sortable: true,
      filter: false,
      body: data => {
        return data?.expiryDate
          ? moment(data?.expiryDate, 'YYYY-MM-DD').format('MM-DD-YYYY')
          : '';
      },
      className: 'vertical-align-top',
    },
    {
      field: 'statusName',
      header: 'Status',
      sortable: true,
      filter: false,
      body: data => (
        <Tag value={data.statusName} severity="info" className="w-max"></Tag>
      ),
      className: 'vertical-align-top',
    },
    {
      header: 'Total Cost',
      sortable: true,
      filter: false,
      body: data =>
        `$${parseFloat(data?.lineItems?.subTotal || 0)?.toFixed(2)}`,
      className: 'vertical-align-top  w-1',
    },
    {
      header: 'Total Discount',
      sortable: true,
      filter: false,
      body: data =>
        `$${parseFloat(data?.lineItems?.discount || 0)?.toFixed(2)}`,
      className: 'vertical-align-top w-1',
    },
    {
      header: 'Total Tax',
      sortable: true,
      filter: false,
      body: data =>
        `$${parseFloat(data?.lineItems?.totalTaxAmount || 0)?.toFixed(2)}`,
      className: 'vertical-align-top w-1',
    },
    {
      header: 'Total Quote Amount',
      sortable: true,
      filter: false,
      body: data => `$${parseFloat(data?.lineItems?.total || 0)?.toFixed(2)}`,
      className: 'vertical-align-top w-1',
    },
  ];

  return main;
};

export const quoteGridData = async (
  customerId,
  opportunityId,
  rows,
  page,
  sortBy,
  orderBy,
  isGlobalLevel,
  showOldQuotes = false,
  quoteId = 0,
  mainQuoteId = 0
) => {
  let queryParams = `limit=${rows || 10}`;
  if (page) {
    queryParams = `${queryParams}&page=${page + 1}`;
  }
  if (sortBy) {
    queryParams = `${queryParams}&sortBy=${sortBy}`;
  }
  queryParams = `${queryParams}&isMeasureSquare=0`;
  if (orderBy) {
    queryParams = `${queryParams}&sortOrder=${orderBy == 1 ? 'asc' : 'desc'}`;
  }
  if (isGlobalLevel) {
    return await axios.get(
      `${CONFIG?.API_CRM_URL}/api/${customerId}/quote/list?${queryParams}`
    );
  }
  if (showOldQuotes) {
    queryParams = `${queryParams}&oldQuote=${showOldQuotes}`;
  }
  if (quoteId > 0) {
    queryParams = `${queryParams}&versionQuoteId=${quoteId}`;
  }
  if (mainQuoteId > 0) {
    queryParams = `${queryParams}&versionMainQuoteId=${mainQuoteId}`;
  }

  return await axios.get(
    `${CONFIG?.API_CRM_URL}/api/${customerId}/${opportunityId}/quote/list?${queryParams}`
  );
};
