import * as Yup from 'yup';

export const LocationValidation = Yup.object().shape({
  id: Yup.number().notRequired(),
  name: Yup.string().required('Name is Required'),
  warehouseCode: Yup.string().required('Warehouse code is Required'),
  addressLineOne: Yup.string().required('Address1 is Required'),
  city: Yup.string().required('City is Required'),
  state: Yup.string().required('State is Required'),
  zipcode: Yup.string().required('Zip Code is Required'),
  manager: Yup.string().notRequired(),
  phoneNumber: Yup.string().required('Phone is Required'),
  emailAddress: Yup.string()
    .required('Email Address is Required')
    .email('Please enter valid email address'),
  latitude: Yup.string().notRequired(),
  longitude: Yup.string().notRequired(),
  bins: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required('Name is required'),
      })
    )
    .notRequired(),
});
