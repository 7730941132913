import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { Tag } from 'primereact/tag';
import { Badge } from 'primereact/badge';
import { Tooltip } from 'primereact/tooltip';
import axios from 'axios';
import { InputSwitch } from 'primereact/inputswitch';
import * as Yup from 'yup';
import { Toast } from 'primereact/toast';

import PFDialog from '../shared/PFPrime/PFDialog.js';
import PFButton from '../shared/PFPrime/PFButton.js';
import PFMultiSelect from '../shared/PFPrime/PFMultiSelect.js';
import PFDropdown from '../shared/PFPrime/PFDropdown.js';
import PFCalendar from '../shared/PFPrime/PFCalendar.js';
import { setGlobalFilters } from '../../redux/slices/globalFilters.slice.js';
import {
  checkUrlString,
  getLoginUserId,
  checkPermission,
  matchExactString,
} from '../../utils/Helpers.js';
import { getDefaultFilterList } from '../ProjectManagement/Scheduling/Reports/Reports.service.js';
import PFInputText from '../shared/PFPrime/PFInputText';
import {
  createPayloadFromFormikValues,
  isAtLeastOneFieldSelected,
} from '../Admin/NewConfiguration/SchedularReportConfig/components/AddEditReport.model.js';
import apiService from '../../services/api.service.js';
import { URL_CONSTANTS } from '../../constants/urlConstants.js';
import permissions from '../../config/permissions.js';
import { checkDashboardGlobalFilterApplied } from '../../utils/global-filter.helper';

import { processFilters } from './helpers/filter.helper.js';
import {
  allStore,
  getCustomerList,
  getDefaultFilterValuesById,
  getWorkroomOptions,
} from './GlobalFilters.services/GlobalFilters.service.js';
import GlobalFiltersHeader from './GlobalFiltersHeader.js';

const GlobalFilters = () => {
  const dispatch = useDispatch();
  const [store, setStore] = useState([]);
  const [workroom, setWorkroom] = useState([]);
  const [defaultFiltersOptions, setDefaultFiltersOptions] = useState([]);
  const [globalFilterVisible, setGlobalFilterVisible] = useState(false);
  const [customerList, setCustomerList] = useState([]);
  const [showBadge, setShowBadge] = useState(false);
  const { techniciansLimitList } = useSelector(
    state => state.techniciansLimitList
  );
  const { types } = useSelector(state => state.types);
  const { categories } = useSelector(state => state.categories);
  const { projectStores } = useSelector(state => state.projectStores);
  const { districts } = useSelector(state => state.districts);
  const { sourceStatusType } = useSelector(state => state.sourceStatusType);
  const { workrooms } = useSelector(state => state.workrooms);
  const { projectStatusType } = useSelector(state => state.projectStatusType);
  const { userTypes } = useSelector(state => state.userTypes);
  const currentPath = window?.location?.href;
  const isScheduler = checkUrlString(currentPath, '/scheduler');
  const isCalendar = checkUrlString(currentPath, '/calendar');
  const [dropdownError, setDropdownError] = useState();
  const [loading, setLoading] = useState(false);
  const toast = useRef();
  const [customMsg, setCustomMsg] = useState(false);
  const [clearDefaultFilters, setClearDefaultFilters] = useState(false);
  const isDashboard = matchExactString(currentPath, '/dashboard');
  const iswidgetDetails = checkUrlString(currentPath, '/widget/details');

  let isFetching = false;
  let latestQuery = null;
  let pendingQuery = null;

  const fetchCustomerList = async searchValue => {
    if (isFetching) {
      pendingQuery = searchValue;
      return;
    }
    isFetching = true;
    latestQuery = searchValue;

    try {
      const response = await getCustomerList(searchValue);
      if (response && Array.isArray(response)) {
        setCustomerList(
          response.map(item => ({
            label: `${item.first_name} ${item.last_name} - ${item.primary_phone}`,
            id: item?.customer_id,
          }))
        );
      } else {
        console.error('Invalid response format or empty response');
        setCustomerList([]);
      }
    } catch (error) {
      console.error('Error fetching customer list:', error);
      setCustomerList([]);
    } finally {
      isFetching = false;
      if (pendingQuery !== null && pendingQuery !== latestQuery) {
        const nextQuery = pendingQuery;
        pendingQuery = null;
        fetchCustomerList(nextQuery);
      }
    }
  };

  const [hasCustomFilterView, hasCustomFilterRemove, hasCustomFilterAdd] =
    useMemo(() => {
      return [
        checkPermission(
          permissions?.userManagement?.userManagementCustomFilterView
        ),

        checkPermission(
          permissions?.userManagement?.userManagementCustomFilterRemove
        ),

        checkPermission(
          permissions?.userManagement?.userManagementCustomFilterAdd
        ),
      ];
    }, [localStorage.getItem('permissions')]);

  const fetchDefaultFilterList = async () => {
    const response = await getDefaultFilterList();
    const data = [...(response ?? [])];
    setDefaultFiltersOptions(data);
  };

  const handleFilterChange = async value => {
    let newWorkroom = [];
    let newStoreIds = [];
    let selectedDefaultFiltersValues = {};
    let newInstallerIds = [];
    let newInstallerUserIds = [];
    if (value?.filter_id) {
      const response = await getDefaultFilterValuesById(value?.filter_id);
      if (response?.selectedFilter?.workroomList?.length) {
        newWorkroom = response?.selectedFilter?.workroomList?.map(
          item => item?.label
        );
      }
      if (response?.selectedFilter?.storeList?.length) {
        newStoreIds = response?.selectedFilter?.storeList?.map(
          item => item?.store_id
        );
      }
      if (response?.selectedFilter?.technicianList?.length) {
        newInstallerIds = response?.selectedFilter?.technicianList?.map(
          item => item?.installer_id
        );
      }
      if (response?.selectedFilter?.technicianList?.length) {
        newInstallerUserIds = response?.selectedFilter?.technicianList?.map(
          item => item?.user_id
        );
      }
      selectedDefaultFiltersValues = response?.selectedFilter;

      const selectedDefaultFilters = processFilters(value);
      const updatedSelectedDefaultFilters = {
        ...selectedDefaultFilters,
        dashboardWorkroom: newWorkroom,
        calendarStoreIds: newStoreIds,
        newInstallerIds: newInstallerIds,
        newInstallerUserIds: newInstallerUserIds,
        selectedDefaultFiltersValues: {
          key: value?.filter_name,
          value: selectedDefaultFiltersValues,
        },
      };

      formik?.setFieldValue('defaultFilter', updatedSelectedDefaultFilters);
      formik?.setFieldValue('defaultFilterDropdown', value);
    } else {
      formik?.setFieldValue('defaultFilter', {});
      formik?.setFieldValue('defaultFilterDropdown', {});
    }
    return;
  };

  const formik = useFormik({
    initialValues: {
      defaultFilterDropdown: {},
      customers: [],
      stores: [],
      projectTypes: [],
      projectCategories: [],
      projectWorkrooms: [],
      projectStatus: [],
      districtId: [],
      defaultFilter: {},
      techniciansVal: [],
      sourceStatus: [],
      userType: [],
      dateScheduledFilterStart: [],
      dateScheduledFilterEnd: [],
      dateCompletionFilterStart: [],
      dateCompletionFilterEnd: [],
      checkDateFromFilter: [],
      checkDateToFilter: [],
      dateSoldFromFilter: [],
      dateSoldToFilter: [],
      isDashboardApplicable: 0,
    },
    onSubmit: values => {
      localStorage.setItem('common_filters', JSON.stringify(values));
      const keysWithValues = Object.keys(values).filter(
        key => Array.isArray(values[key]) && values[key].length > 0
      );

      if (
        keysWithValues?.length > 0 ||
        Object.keys(values?.defaultFilter)?.length > 0
      ) {
        setShowBadge(true);
      } else {
        setShowBadge(false);
      }
      dispatch(
        setGlobalFilters({
          customers: values.customers,
          stores: values.stores,
          projectTypes: values.projectTypes,
          projectCategories: values.projectCategories,
          projectWorkrooms: values.projectWorkrooms,
          projectStatus: values.projectStatus,
          techniciansVal: values.techniciansVal,
          districtId: values.districtId,
          defaultFilter: values.defaultFilter,
          sourceStatus: values?.sourceStatus,
          userType: values?.userType,
          dateScheduledFilterStart: values?.dateScheduledFilterStart,
          dateScheduledFilterEnd: values?.dateScheduledFilterEnd,
          dateCompletionFilterStart: values?.dateCompletionFilterStart,
          dateCompletionFilterEnd: values?.dateCompletionFilterEnd,
          checkDateFromFilter: values?.checkDateFromFilter,
          checkDateToFilter: values?.checkDateToFilter,
          dateSoldFromFilter: values?.dateSoldFromFilter,
          dateSoldToFilter: values?.dateSoldToFilter,
          isDashboardApplicable: values?.isDashboardApplicable,
        })
      );
    },
  });
  useEffect(() => {
    fetchDefaultFilterList();
    fetchCustomerList();
  }, []);

  useEffect(() => {
    if (axios.defaults.headers.common['client_id']) {
      dispatch(
        setGlobalFilters({
          customerName: [],
          storeNumber: [],
          projectTypeId: [],
          projectCategoryId: [],
          workroomId: [],
          statusId: [],
          districtId: [],
          defaultFilter: {},
          technicianFilter: [],
          sourceStatus: [],
          userType: [],
          dateScheduledFilterStart: [],
          dateScheduledFilterEnd: [],
          dateCompletionFilterStart: [],
          dateCompletionFilterEnd: [],
          checkDateFromFilter: [],
          checkDateToFilter: [],
          dateSoldFromFilter: [],
          dateSoldToFilter: [],
          defaultFilterDropdown: {},
          isDashboardApplicable: false,
        })
      );
    }
  }, []);

  useEffect(() => {
    const savedFilters = JSON.parse(localStorage.getItem('common_filters'));
    if (savedFilters) {
      formik.setValues(savedFilters);
      dispatch(setGlobalFilters(savedFilters));
    }
  }, []);
  const areCustomFiltersEmpty =
    formik?.values?.customers?.length === 0 &&
    formik?.values?.stores?.length === 0 &&
    formik?.values?.projectTypes?.length === 0 &&
    formik?.values?.projectCategories?.length === 0 &&
    formik?.values?.projectWorkrooms?.length === 0 &&
    formik?.values?.projectStatus?.length === 0 &&
    formik?.values?.districtId?.length === 0 &&
    formik?.values?.techniciansVal.length === 0 &&
    formik?.values?.sourceStatus?.length === 0 &&
    formik?.values?.userType?.length === 0 &&
    formik?.values?.dateScheduledFilterStart?.length === 0 &&
    formik?.values?.dateScheduledFilterEnd?.length === 0 &&
    formik?.values?.checkDateFromFilter?.length === 0 &&
    formik?.values?.checkDateToFilter?.length === 0 &&
    formik?.values?.dateCompletionFilterStart?.length === 0 &&
    formik?.values?.dateCompletionFilterEnd?.length === 0 &&
    formik?.values?.dateSoldFromFilter?.length === 0 &&
    formik?.values?.dateSoldToFilter?.length === 0;

  useEffect(async () => {
    if (areCustomFiltersEmpty) {
      const byDefaultFilterValue = defaultFiltersOptions?.find(item =>
        item?.userFilterInfo
          ? item?.is_default && item?.userFilterInfo
          : item?.is_default
      );

      if (byDefaultFilterValue) {
        await handleFilterChange(byDefaultFilterValue);
      } else {
        formik.setFieldValue('defaultFilter', {});
        formik.setFieldValue('defaultFilterDropdown', {});
      }
      formik.handleSubmit();
    } else {
      formik.setFieldValue('defaultFilter', {});
      formik.setFieldValue('defaultFilterDropdown', {});
    }
  }, [
    clearDefaultFilters,
    defaultFiltersOptions,
    formik?.values?.customers,
    formik?.values?.stores,
    formik?.values?.projectTypes,
    formik?.values?.projectCategories,
    formik?.values?.projectWorkrooms,
    formik?.values?.projectStatus,
    formik?.values?.districtId,
    formik?.values?.techniciansVal,
    formik?.values?.sourceStatus,
    formik?.values?.userType,
    formik?.values?.dateScheduledFilterStart,
    formik?.values?.dateScheduledFilterEnd,
    formik?.values?.checkDateFromFilter,
    formik?.values?.checkDateToFilter,
    formik?.values?.dateCompletionFilterStart,
    formik?.values?.dateCompletionFilterEnd,
    formik?.values?.dateSoldFromFilter,
    formik?.values?.dateSoldToFilter,
  ]);

  const filterOptions = debounce(async event => {
    if (event?.filter?.length > 2) {
      const searchStore = await allStore(event.filter);
      setStore(searchStore);
    } else {
      setStore([]);
    }
  }, 300);

  const filterWorkroomOptions = debounce(async event => {
    if (event?.filter?.length > 2) {
      const searchWorkroom = await getWorkroomOptions(event.filter);
      setWorkroom(searchWorkroom);
    } else {
      setWorkroom([]);
    }
  }, 300);

  const filterCustomerNameOptions = debounce(async event => {
    if (event?.filter?.length > 2) {
      await fetchCustomerList(event?.filter);
    } else {
      await fetchCustomerList();
    }
  }, 300);

  const defaultFiltersTemplate = options => {
    return (
      <span className="flex align-items-center">
        <span>{options?.filter_name}</span>
        {options?.userFilterInfo ? (
          <span className="pl-2 text-xs">
            <Tag value="Custom"></Tag>
          </span>
        ) : (
          ''
        )}
        {options && options?.is_default ? (
          <span className="pl-2 text-xs">
            <Tag severity="success" value="Default"></Tag>
          </span>
        ) : (
          ''
        )}
      </span>
    );
  };
  const handleDateFilters = (value, name) => {
    formik?.setFieldValue(name, [value]);
  };
  const globalFilterHeader = React.useRef(null);
  const globalFilterButtonRef = React.useRef(null);
  const globalFilters = useSelector(state => state.globalFilters);
  useEffect(() => {
    if (
      checkDashboardGlobalFilterApplied(globalFilters)?.isFilterApplied &&
      isDashboard
    ) {
      if (globalFilterHeader.current && globalFilterButtonRef.current) {
        setTimeout(() => {
          globalFilterHeader.current.show({
            currentTarget: globalFilterButtonRef.current,
          });
          setTimeout(() => {
            globalFilterHeader.current.hide();
          }, 3000);
        }, 2000);
      }
    }
  }, [globalFilters]);

  //Save the Filter
  const saveUserFilter = async value => {
    try {
      setLoading(true);
      const userId = getLoginUserId();
      const response = await apiService.post(
        apiService.formatUrl(
          URL_CONSTANTS.SCHEDULER_FILTERS.saveUserLevelFilter,
          userId
        ),
        value
      );
      if (response && response?.status) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'User Filter save successfully.',
        });

        fetchDefaultFilterList();
        customFilterFormik.handleReset();
        formik.handleReset();
      } else {
        toast.current?.show({
          severity: 'error',
          summary: response?.data
            ? `${response?.data}`
            : 'Unable to add record.',
        });
      }
    } catch (error) {
      toast.current.show({
        severity: 'error',
        detail: 'Unable to add record.',
      });
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const customFilterFormikValidation = Yup.object().shape({
    filter_name: Yup.string().required('Required'),
  });
  const customFilterFormik = useFormik({
    initialValues: {
      filter_name: '',
      isActive: false,
    },
    validationSchema: customFilterFormikValidation,
    enableReinitialize: true,
    onSubmit: values => {
      const payload = createPayloadFromFormikValues(values);
      if (
        formik?.values?.techniciansVal ||
        formik?.values?.userType ||
        formik?.values?.dateScheduledFilterStart ||
        formik?.values?.dateScheduledFilterEnd ||
        formik?.values?.checkDateFromFilter ||
        formik?.values?.checkDateToFilter ||
        formik?.values?.dateCompletionFilterStart ||
        formik?.values?.dateCompletionFilterEnd ||
        formik?.values?.dateSoldFromFilter ||
        formik?.values?.dateSoldToFilter
      ) {
        setCustomMsg(true);
      }
      if (!isAtLeastOneFieldSelected(customFilterFormik)) {
        setDropdownError(true);
        return;
      } else {
        setDropdownError(false);
        saveUserFilter(payload);
      }
    },
  });

  const assignFormikValues = () => {
    customFilterFormik.setValues({
      ...customFilterFormik.values,
      store: formik.values.stores?.map(val => val?.store_number),
      projectCategory: formik.values.projectCategories?.map(
        val => val?.project_category_id
      ),
      status: formik.values.projectStatus?.map(val => val?.status_id),
      sourceStatus: formik.values.sourceStatus?.map(val => val?.status_id),
      projectType: formik.values.projectTypes?.map(val => val?.project_type_id),
      district: formik.values.districtId,
      workroom: formik.values.projectWorkrooms?.map(val => val?.type_id),
      customer: formik.values.customers?.map(val => val?.id),
      technician: formik.values?.techniciansVal?.map(val => {
        return val?.user_id;
      }),
    });
  };
  return (
    <>
      <div className="relative" ref={globalFilterButtonRef}>
        {showBadge && (
          <Badge severity="danger" className="absolute top-0 right-0 z-5" />
        )}
        <PFButton
          size="small"
          icon="pi pi-filter"
          outlined
          onMouseEnter={e => globalFilterHeader.current.show(e)}
          className="border-none text-white focus:shadow-none"
          onClick={e => {
            setGlobalFilterVisible(true);
            globalFilterHeader.current.hide(e);
          }}
        />
        <GlobalFiltersHeader
          globalFilterHeader={globalFilterHeader}
          formik={formik}
          customFilterFormik={customFilterFormik}
          setClearDefaultFilters={setClearDefaultFilters}
          fetchCustomerList={fetchCustomerList}
        />
      </div>

      <PFDialog
        header="Global Filters"
        pt={{
          header: {
            className: 'border-bottom-1 border-400 py-5',
            style: { 'background-color': '#eff6ff' },
          },
          content: { className: ' overflow-visible pb-1' },
        }}
        show={globalFilterVisible}
        className="w-10 overflow-y-scroll"
        onHide={() => {
          setGlobalFilterVisible(false);
          customFilterFormik?.handleReset();
          setDropdownError(false);
          setCustomMsg(false);
        }}
        draggable={false}
        footer={
          <>
            {hasCustomFilterView ? (
              <div className="flex align-items-center justify-content-end border-bottom-1 border-400 pb-3">
                <PFButton
                  label="Reset Filter"
                  outlined
                  text
                  onClick={() => {
                    formik.handleReset();
                    formik?.setFieldValue('defaultFilter', {});
                    localStorage.removeItem('common_filters');
                    if (formik?.values?.isDashboardApplicable >= 1) {
                      setGlobalFilters({
                        isDashboardApplicable: 2,
                      });
                      formik?.setFieldValue('isDashboardApplicable', 2);
                    }
                    setGlobalFilterVisible(false);
                    customFilterFormik?.handleReset();
                    setDropdownError(false);
                    fetchCustomerList();
                  }}
                />
                <PFButton
                  label="Apply Filters"
                  onClick={() => {
                    formik.handleSubmit();
                    setGlobalFilterVisible(false);
                  }}
                />

                <InputSwitch
                  checked={
                    formik?.values?.isDashboardApplicable === 0 ? false : true
                  }
                  onChange={e => {
                    formik.setFieldValue(
                      'isDashboardApplicable',
                      e.value ? 1 : 0
                    );
                  }}
                />

                <label htmlFor="applyToDashboard" className=" ml-1 text-base">
                  Apply to Dashboard
                </label>
              </div>
            ) : null}

            {hasCustomFilterAdd ? (
              <form
                onSubmit={customFilterFormik.handleSubmit}
                className="flex flex-column gap-2 mt-3 pt-0"
              >
                <div className="grid p-0">
                  <div className="col-12 flex flex-wrap p-0">
                    {dropdownError && (
                      <div className="col-12 flex justify-content-start">
                        <small className="p-error pl-0">
                          Please select at least one filter value.
                        </small>

                        <small className="pl-0">
                          (Technician, User Type and Date fields are runtime
                          filters and not considered while saving)
                        </small>
                      </div>
                    )}
                    {!dropdownError && customMsg && (
                      <div className="col-12 flex justify-content-start">
                        <small className="pl-0">
                          Technician, User Type and Date fields are runtime
                          filters and not considered while saving.
                        </small>
                      </div>
                    )}
                    <div className="col-12 md:col-4">
                      <span className="p-float-label ">
                        <PFInputText
                          id="filter_name"
                          maxLength={200}
                          value={customFilterFormik?.values?.filter_name}
                          onChange={e => {
                            customFilterFormik.setFieldValue(
                              'filter_name',
                              e?.target?.value
                            );
                          }}
                          className={`w-full text-sm ${
                            customFilterFormik.touched?.filter_name &&
                            customFilterFormik.errors?.filter_name
                              ? 'p-invalid'
                              : ''
                          }`}
                        ></PFInputText>
                        <label htmlFor="filter_name" className="text-sm">
                          Name
                        </label>
                      </span>
                    </div>
                    <div className="col-12 md:col-4">
                      <div className="col-12 flex align-items-center gap-2">
                        <InputSwitch
                          checked={customFilterFormik.values.isActive}
                          onChange={e => {
                            customFilterFormik.setFieldValue(
                              'isActive',
                              e.value
                            );
                          }}
                          className="text-xs"
                        />
                        <label className=" ml-1 text-base">
                          {customFilterFormik.values.isActive
                            ? `Active`
                            : `Inactive`}{' '}
                        </label>
                      </div>
                    </div>
                    <div className="col-12 md:col-4 flex justify-content-end">
                      <PFButton
                        label="Save"
                        type="submit"
                        onClick={() => {
                          assignFormikValues();
                        }}
                        disabled={
                          !customFilterFormik.isValid ||
                          !customFilterFormik?.values?.filter_name
                        }
                        icon={loading ? 'pi pi-spin pi-spinner' : ''}
                        pt={{
                          label: {
                            className: 'flex-none pr-1 m-0',
                          },
                        }}
                        className="flex flex-row-reverse px-5 ml-3 text-sm"
                        size="small"
                      />
                    </div>
                  </div>
                </div>
              </form>
            ) : null}
          </>
        }
        BodyComponent={
          <>
            <Toast ref={toast} />
            <div className="grid mt-2">
              <div className="col-10 md:col-4 lg:col-4 flex flex-direction-row align-items-center">
                <span className="p-float-label w-12">
                  <PFDropdown
                    value={formik?.values?.defaultFilterDropdown}
                    onChange={(name, value) => {
                      handleFilterChange(value);
                    }}
                    options={defaultFiltersOptions || []}
                    itemTemplate={defaultFiltersTemplate}
                    optionLabel="filter_name"
                    name="default_filters"
                    className="w-full"
                    showClear={hasCustomFilterRemove}
                    // showClear={formik?.values?.defaultFilterDropdown ?? false}
                    disabled={!areCustomFiltersEmpty}
                  />

                  <label
                    htmlFor="defaultFilter"
                    className={!areCustomFiltersEmpty ? `text-400` : ''}
                  >
                    Default Filters
                  </label>
                </span>
              </div>
              {hasCustomFilterView ? (
                <form>
                  <div className="flex align-items-center">
                    <span className="font-bold">Custom Filters</span>
                    <i
                      className="custom-target-icon pi pi-info-circle text-primary p-overlay-badge"
                      data-pr-tooltip={`Grayed-out filters are not supported or considered in the ${isDashboard ? 'Dashboard' : 'Scheduler and Calendar'}`}
                      data-pr-position="right"
                      data-pr-at="right+5 top"
                      data-pr-my="left center-2"
                      style={{
                        fontSize: '0.8rem',
                        cursor: 'pointer',
                        marginLeft: '10px',
                      }}
                    ></i>
                    <Tooltip target=".custom-target-icon" />
                    <p className="border-none ml-4 font-italic">
                      ( Custom Filters will override the default filters. )
                    </p>
                  </div>
                  <div className="grid">
                    <div className="col-12 md:col-6 lg:col-4 ">
                      <span className="p-float-label">
                        <PFMultiSelect
                          value={formik?.values?.customers}
                          options={customerList}
                          onChange={e => {
                            formik?.setFieldValue('customers', e.value);
                          }}
                          optionLabel="label"
                          onFilter={e => filterCustomerNameOptions(e)}
                          filter={true}
                          className="w-full max-w-24rem"
                          disabled={!isScheduler}
                        />
                        <label
                          htmlFor="customerName"
                          className={!isScheduler ? `text-400` : ''}
                        >
                          Select Client Name
                        </label>
                      </span>
                    </div>
                    <div className="col-12 md:col-6 lg:col-4 ">
                      <span className="p-float-label">
                        <PFMultiSelect
                          value={formik?.values?.stores}
                          options={
                            store.length > 0
                              ? store?.map(item => ({
                                  store_name: `${item.store_number} - ${item.store_name}`,
                                  store_number: item?.store_number,
                                  store_id: item?.store_id,
                                }))
                              : projectStores?.map(item => ({
                                  store_name: `${item.store_number} - ${item.store_name}`,
                                  store_number: item?.store_number,
                                  store_id: item?.store_id,
                                }))
                          }
                          onChange={e => {
                            formik?.setFieldValue('stores', e.value);
                          }}
                          optionLabel="store_name"
                          onFilter={e => filterOptions(e)}
                          filter={true}
                          className="w-full max-w-24rem"
                        />
                        <label htmlFor="storeNumber">Select Store</label>
                      </span>
                    </div>
                    <div className="col-12 md:col-6 lg:col-4 ">
                      <span className="p-float-label">
                        <PFMultiSelect
                          value={formik?.values?.projectCategories}
                          onChange={e => {
                            formik?.setFieldValue('projectCategories', e.value);
                          }}
                          options={categories}
                          optionLabel="category"
                          filter={true}
                          className="w-full max-w-24rem"
                        />
                        <label htmlFor="projectCategoryId">
                          Select Category
                        </label>
                      </span>
                    </div>
                    <div className="col-12 md:col-6 lg:col-4 ">
                      <span className="p-float-label">
                        <PFMultiSelect
                          value={formik?.values?.projectTypes}
                          onChange={e => {
                            formik?.setFieldValue('projectTypes', e.value);
                          }}
                          options={types}
                          optionLabel="project_type"
                          filter={true}
                          className="w-full max-w-24rem"
                        />
                        <label htmlFor="store_name">Select Project Type</label>
                      </span>
                    </div>
                    <div className="col-12 md:col-6 lg:col-4 ">
                      <span className="p-float-label">
                        <PFMultiSelect
                          value={formik?.values?.projectStatus}
                          onChange={e => {
                            formik?.setFieldValue('projectStatus', e.value);
                          }}
                          options={projectStatusType}
                          optionLabel="status"
                          filter={true}
                          className="w-full max-w-24rem"
                          disabled={
                            !isScheduler && !isDashboard && !iswidgetDetails
                          }
                        />
                        <label
                          htmlFor="statusId"
                          className={
                            !isScheduler && !isDashboard && !iswidgetDetails
                              ? `text-400`
                              : ''
                          }
                        >
                          Select Status
                        </label>
                      </span>
                    </div>
                    <div className="col-12 md:col-6 lg:col-4 ">
                      <span className="p-float-label">
                        <PFMultiSelect
                          value={formik?.values?.sourceStatus}
                          onChange={e => {
                            formik?.setFieldValue('sourceStatus', e.value);
                          }}
                          options={sourceStatusType}
                          optionLabel="status"
                          filter={true}
                          className="w-full max-w-24rem"
                          disabled={!isScheduler}
                        />
                        <label
                          htmlFor="sourceStatus"
                          className={!isScheduler ? `text-400` : ''}
                        >
                          Select Source Status
                        </label>
                      </span>
                    </div>
                    <div className="col-12 md:col-6 lg:col-4 ">
                      <span className="p-float-label">
                        <PFMultiSelect
                          value={formik?.values?.districtId}
                          onChange={e => {
                            formik?.setFieldValue('districtId', e.value);
                          }}
                          options={districts?.map(item => item?.district)}
                          filter={true}
                          className="w-full max-w-24rem"
                          disabled={
                            !isScheduler &&
                            !isCalendar &&
                            !isDashboard &&
                            !iswidgetDetails
                          }
                        />
                        <label
                          htmlFor="store_name"
                          className={
                            !isScheduler &&
                            !isCalendar &&
                            !isDashboard &&
                            !iswidgetDetails
                              ? `text-400`
                              : ''
                          }
                        >
                          Select District
                        </label>
                      </span>
                    </div>
                    <div className="col-12 md:col-6 lg:col-4 ">
                      <span className="p-float-label">
                        <PFMultiSelect
                          value={formik?.values?.projectWorkrooms}
                          onChange={e => {
                            formik?.setFieldValue('projectWorkrooms', e.value);
                          }}
                          options={workroom?.length > 0 ? workroom : workrooms}
                          optionLabel="label"
                          filter={true}
                          onFilter={e => filterWorkroomOptions(e)}
                          className="w-full max-w-24rem"
                        />
                        <label htmlFor="store_name">Select Workroom</label>
                      </span>
                    </div>
                    <div className="col-12 md:col-6 lg:col-4 ">
                      <span className="p-float-label">
                        <PFMultiSelect
                          value={formik?.values?.techniciansVal}
                          onChange={e => {
                            formik?.setFieldValue('techniciansVal', e.value);
                          }}
                          options={techniciansLimitList || []}
                          optionLabel="full_name"
                          filter={true}
                          className="w-full max-w-24rem"
                          maxSelectedLabels={0}
                        />
                        <label htmlFor="technicianFilter">
                          Select Technician
                        </label>
                      </span>
                    </div>
                    <div className="col-12 md:col-6 lg:col-4 ">
                      <span className="p-float-label">
                        <PFMultiSelect
                          value={formik?.values?.userType}
                          onChange={e => {
                            formik?.setFieldValue('userType', e.value);
                          }}
                          options={userTypes}
                          optionLabel="label"
                          filter={true}
                          className="w-full max-w-24rem"
                          disabled={
                            !isScheduler &&
                            !isCalendar &&
                            !isDashboard &&
                            !iswidgetDetails
                          }
                        />
                        <label
                          htmlFor="userType"
                          className={
                            !isScheduler &&
                            !isCalendar &&
                            !isDashboard &&
                            !iswidgetDetails
                              ? `text-400`
                              : ''
                          }
                        >
                          Select User Type
                        </label>
                      </span>
                    </div>

                    <div className="col-12 md:col-6 lg:col-4 ">
                      <span className="p-float-label">
                        <PFCalendar
                          id="date_from"
                          name="date_from"
                          className="w-full max-w-24rem"
                          value={
                            formik.values?.dateScheduledFilterStart?.length > 0
                              ? new Date(
                                  formik.values?.dateScheduledFilterStart[0]
                                )
                              : null
                          }
                          onChange={e => {
                            handleDateFilters(
                              e.value,
                              'dateScheduledFilterStart'
                            );
                            handleDateFilters(
                              e.value,
                              'dateScheduledFilterEnd'
                            );
                          }}
                          format={'DD/MM/YYY'}
                          pt={{
                            input: {
                              root: { className: 'p-inputtext-sm' },
                            },
                          }}
                          disabled={isScheduler || isCalendar}
                        />
                        <label
                          htmlFor="date_from"
                          className={
                            isScheduler || isCalendar ? `text-400` : ''
                          }
                        >
                          Select Schedule Start Date
                        </label>
                      </span>
                    </div>
                    <div className="col-12 md:col-6 lg:col-4 ">
                      <span className="p-float-label">
                        <PFCalendar
                          value={
                            formik?.values?.dateScheduledFilterEnd?.length > 0
                              ? new Date(
                                  formik?.values?.dateScheduledFilterEnd[0]
                                )
                              : null
                          }
                          onChange={e => {
                            handleDateFilters(
                              e?.value,
                              'dateScheduledFilterEnd'
                            );
                          }}
                          disabled={isScheduler || isCalendar}
                          className="w-full max-w-24rem"
                        />
                        <label
                          htmlFor="statusId"
                          className={
                            isScheduler || isCalendar ? `text-400` : ''
                          }
                        >
                          Select Schedule End Date
                        </label>
                      </span>
                    </div>

                    <div className="col-12 md:col-6 lg:col-4 ">
                      <span className="p-float-label">
                        <PFCalendar
                          value={
                            formik.values?.dateCompletionFilterStart?.length > 0
                              ? new Date(
                                  formik.values?.dateCompletionFilterStart[0]
                                )
                              : null
                          }
                          onChange={e => {
                            handleDateFilters(
                              e.value,
                              'dateCompletionFilterStart'
                            );
                            handleDateFilters(
                              e.value,
                              'dateCompletionFilterEnd'
                            );
                          }}
                          pt={{
                            input: {
                              className: 'p-inputtext p-inputtext-sm w-full',
                            },
                          }}
                          disabled={isScheduler || isCalendar}
                          className="w-full max-w-24rem"
                        />
                        <label
                          htmlFor="dateCompletionFilterStart"
                          className={
                            isScheduler || isCalendar ? `text-400` : ''
                          }
                        >
                          Select Completion Start date
                        </label>
                      </span>
                    </div>
                    <div className="col-12 md:col-6 lg:col-4 ">
                      <span className="p-float-label">
                        <PFCalendar
                          value={
                            formik.values?.dateCompletionFilterEnd?.length > 0
                              ? new Date(
                                  formik.values?.dateCompletionFilterEnd[0]
                                )
                              : null
                          }
                          onChange={e =>
                            handleDateFilters(
                              e.value,
                              'dateCompletionFilterEnd'
                            )
                          }
                          disabled={isScheduler || isCalendar}
                          className="w-full max-w-24rem"
                        />
                        <label
                          htmlFor="dateCompletionFilterEnd"
                          className={
                            isScheduler || isCalendar ? `text-400` : ''
                          }
                        >
                          Select Completion End date
                        </label>
                      </span>
                    </div>
                    <div className="col-12 md:col-6 lg:col-4 ">
                      <span className="p-float-label">
                        <PFCalendar
                          value={
                            formik.values?.checkDateFromFilter?.length > 0
                              ? new Date(formik.values?.checkDateFromFilter[0])
                              : null
                          }
                          onChange={e => {
                            handleDateFilters(e.value, 'checkDateFromFilter');
                            handleDateFilters(e.value, 'checkDateToFilter');
                          }}
                          disabled={isScheduler || isCalendar}
                          className="w-full max-w-24rem"
                        />
                        <label
                          htmlFor="checkDateFromFilter"
                          className={
                            isScheduler || isCalendar ? `text-400` : ''
                          }
                        >
                          Select Check date From
                        </label>
                      </span>
                    </div>
                    <div className="col-12 md:col-6 lg:col-4 ">
                      <span className="p-float-label">
                        <PFCalendar
                          value={
                            formik.values?.checkDateToFilter?.length > 0
                              ? new Date(formik.values?.checkDateToFilter[0])
                              : null
                          }
                          onChange={e =>
                            handleDateFilters(e.value, 'checkDateToFilter')
                          }
                          disabled={isScheduler || isCalendar}
                          className="w-full max-w-24rem"
                        />
                        <label
                          htmlFor="checkDateToFilter"
                          className={
                            isScheduler || isCalendar ? `text-400` : ''
                          }
                        >
                          Select Check date To
                        </label>
                      </span>
                    </div>

                    <div className="col-12 md:col-6 lg:col-4 ">
                      <span className="p-float-label">
                        <PFCalendar
                          value={
                            formik?.values?.dateSoldFromFilter?.length > 0
                              ? new Date(formik.values?.dateSoldFromFilter[0])
                              : null
                          }
                          onChange={e => {
                            handleDateFilters(e.value, 'dateSoldFromFilter');
                            handleDateFilters(e.value, 'dateSoldToFilter');
                          }}
                          format={'MM-DD-YYYY'}
                          disabled={isScheduler || isCalendar}
                          className="w-full max-w-24rem"
                        />
                        <label
                          htmlFor="dateSoldFromFilter"
                          className={
                            isScheduler || isCalendar ? `text-400` : ''
                          }
                        >
                          Select Date Sold From
                        </label>
                      </span>
                    </div>
                    <div className="col-12 md:col-6 lg:col-4 ">
                      <span className="p-float-label">
                        <PFCalendar
                          value={
                            formik.values?.dateSoldToFilter?.length > 0
                              ? new Date(formik.values?.dateSoldToFilter[0])
                              : null
                          }
                          onChange={e =>
                            handleDateFilters(e.value, 'dateSoldToFilter')
                          }
                          // disabled={isScheduler || isCalendar}
                          className="w-full max-w-24rem"
                        />
                        <label
                          htmlFor="dateSoldToFilter"
                          className={
                            isScheduler || isCalendar ? `text-400` : ''
                          }
                        >
                          Select Date Sold To
                        </label>
                      </span>
                    </div>
                  </div>
                </form>
              ) : null}
            </div>
          </>
        }
      ></PFDialog>
    </>
  );
};

export default GlobalFilters;
