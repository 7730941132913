import { useRef, useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import {
  FileUpload,
  Toast,
  OverlayPanel,
  ListBox,
  Accordion,
  AccordionTab,
  ConfirmDialog,
} from 'primereact';

import PFEditor from '../../../shared/PFPrime/PFEditor';
import PFButton from '../../../shared/PFPrime/PFButton';
import InputText from '../../../shared/PFPrime/PFInputText';
import PFFormikError from '../../../shared/PFPrime/PFFormikError';
import { URL_CONSTANTS } from '../../../../constants/urlConstants';
import { fetchCustomerInfo } from '../../Clients/helpers/CustomerInfo';
import { checkIfNull } from '../../../../utils/Helpers';
import { useConfirmDialogContext } from '../../../../contexts/ConfirmDialog';

const initialValues = {
  toEmails: [],
  subject: '',
  mailBody: '',
  attachmentList: [],
  selectedTags: [],
  ccEmails: [],
};
const EmailForm = ({
  customerId,
  setReload,
  reload,
  opportunityId,
  quoteId,
  invoiceId,
  paymentId,
  primaryEmailAddress,
}) => {
  const { showConfirmationDialog } = useConfirmDialogContext();
  const toast = useRef(null);
  const uploadHandler = useRef(null);
  const attachmentListRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [customerDetails, setCustomerDetails] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);
  const [isFormChange, setIsFormChange] = useState(false);

  const emailFormik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
      toEmails: Yup.array()
        .of(Yup.string().email('Invalid email address').required('required'))
        .min(1, 'required'),
      subject: Yup.string().trim().required('required'),
      mailBody: Yup.string().required('required'),
    }),
    onSubmit: async values => {
      setLoading(true);
      try {
        const messageData = new FormData();
        messageData.append('toEmails', values.toEmails.toString());
        messageData.append('subject', values.subject);
        messageData.append('mailBody', values.mailBody);
        messageData.append('customerId', customerId);
        if (values?.ccEmails?.length) {
          messageData.append('ccEmails', values.ccEmails.toString());
        }
        if (opportunityId) {
          messageData.append('opportunityId', opportunityId);
        }
        if (quoteId) {
          messageData.append('quoteId', quoteId);
        }
        if (invoiceId) {
          messageData.append('invoiceId', invoiceId);
        }
        if (paymentId) {
          messageData.append('paymentId', paymentId);
        }

        if (Array.isArray(values.attachmentList) && values.attachmentList) {
          values.attachmentList.map(response => {
            if (response?.name) {
              messageData.append('file', response, response?.name);
            }
          });
        }

        const response = await axios.post(
          `${URL_CONSTANTS.CRM.baseUrl}${URL_CONSTANTS.CRM.communication.email.create}`,
          messageData,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
          }
        );
        if (response.status != 201) {
          toast?.current?.show({
            severity: 'error',
            summary: 'Failed to add note',
            life: 2000,
          });
        } else {
          setIsFormChange(false);
          setReload(!reload);
          emailFormik.resetForm({ values: initialValues });
          uploadHandler.current?.clear();
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast?.current?.show({
          severity: 'error',
          summary: 'Something went wrong',
          life: 2000,
        });
        console.error(error);
      }
    },
  });

  const confirmCloseAccordion = index => {
    if (isFormChange) {
      showConfirmationDialog({
        message:
          'Closing window will discard your change, Do you want to proceed?',
        header: 'Discard Confirmation',
        icon: 'pi pi-info-circle text-xl',
        acceptClassName: 'p-button-primary p-button-sm',
        rejectClassName: 'p-button-sm p-button-outlined',
        accept: () => {
          setActiveIndex(index === activeIndex ? null : index);
          setIsFormChange(false);
          emailFormik.resetForm({ values: initialValues });
        },
        reject: () => {},
      });
    } else {
      setActiveIndex(index);
    }
  };

  useEffect(() => {
    if (!primaryEmailAddress) {
      fetchCustomerInfo(customerId, setCustomerDetails, () => {});
    }
  }, []);

  useEffect(() => {
    const defaultToEmail = customerDetails?.customer_emails?.find(
      emailDetails => emailDetails.is_primary
    );
    if (primaryEmailAddress) {
      emailFormik?.setFieldValue('toEmails', [primaryEmailAddress]);
    } else if (defaultToEmail) {
      emailFormik?.setFieldValue('toEmails', [defaultToEmail.email]);
    }
  }, [customerDetails]);

  const renderHeader = () => {
    return (
      <span className="ql-formats">
        <button className="ql-bold" aria-label="Bold"></button>
        <button className="ql-italic" aria-label="Italic"></button>
        <button className="ql-underline" aria-label="Underline"></button>
      </span>
    );
  };
  const attachmentRemoveHandler = name => {
    const newAttachmentList = emailFormik.values.attachmentList.filter(
      file => !(file.name == name)
    );
    emailFormik?.setFieldValue('attachmentList', newAttachmentList);
  };
  const attachmentListTemplate = option => {
    return (
      <div className="flex align-items-center">
        <i
          className="pi pi-times-circle mr-2"
          onClick={() => attachmentRemoveHandler(option.name)}
        ></i>
        <div>{option.name}</div>
      </div>
    );
  };

  return (
    <Accordion
      activeIndex={activeIndex}
      onTabChange={e => confirmCloseAccordion(e.index)}
    >
      <AccordionTab header="Compose">
        <div className="grid pt-2">
          <div className="col-12 md:col-10 lg:col-10">
            <div className="w-full">
              <ReactMultiEmail
                placeholder={
                  <span>
                    To <span className="to-mail-error">*</span>
                  </span>
                }
                emails={emailFormik.values.toEmails}
                onChange={_emails => {
                  emailFormik?.setFieldValue('toEmails', _emails);
                  setIsFormChange(true);
                }}
                validateEmail={email => {
                  return isEmail(email);
                }}
                getLabel={(email, index, removeEmail) => {
                  return (
                    <div data-tag key={index}>
                      {email}
                      <span data-tag-handle onClick={() => removeEmail(index)}>
                        ×
                      </span>
                    </div>
                  );
                }}
              />
              <PFFormikError
                touched={emailFormik.touched}
                errors={emailFormik.errors}
                field="toEmails"
              />
            </div>
          </div>
          <div className="col-12 md:col-2 lg:col-2">
            <div className="w-full">
              <FileUpload
                ref={uploadHandler}
                mode="basic"
                multiple
                name="attachments[]"
                customUpload
                auto
                className
                file={emailFormik.values.attachmentList}
                onSelect={e => {
                  emailFormik?.setFieldValue('attachmentList', [
                    ...e.files,
                    ...emailFormik.values.attachmentList,
                  ]);
                  uploadHandler.current?.clear();
                  setIsFormChange(true);
                }}
                chooseLabel={
                  emailFormik.values.attachmentList.length
                    ? `${emailFormik.values.attachmentList.length}`
                    : ' Browse'
                }
                chooseOptions={{
                  icon: <i className="pi pi-paperclip mr-2"></i>,
                  label: null,
                }}
                onMouseEnter={e => {
                  if (emailFormik.values.attachmentList.length) {
                    attachmentListRef.current.toggle(e);
                  }
                }}
                pt={{
                  basicbutton: {
                    className: 'p-button-sm p-button-outlined w-full',
                  },
                }}
              />
              <OverlayPanel ref={attachmentListRef}>
                <ListBox
                  options={emailFormik.values.attachmentList}
                  itemTemplate={attachmentListTemplate}
                  optionLabel="name"
                  className="w-full md:w-14rem"
                  emptyMessage="No attachment found"
                />
              </OverlayPanel>
            </div>
          </div>
        </div>

        <div className="grid py-2">
          <div className="col-12 md:col-10 lg:col-10">
            <div className="w-full">
              <ReactMultiEmail
                placeholder={<span>CC</span>}
                emails={emailFormik.values.ccEmails}
                onChange={_emails => {
                  emailFormik?.setFieldValue('ccEmails', _emails);
                  setIsFormChange(true);
                }}
                validateEmail={email => {
                  return isEmail(email);
                }}
                getLabel={(email, index, removeEmail) => {
                  return (
                    <div data-tag key={index}>
                      {email}
                      <span data-tag-handle onClick={() => removeEmail(index)}>
                        ×
                      </span>
                    </div>
                  );
                }}
              />
              <PFFormikError
                touched={emailFormik.touched}
                errors={emailFormik.errors}
                field="toEmails"
              />
            </div>
          </div>
          <div className="col-12 md:col-2 lg:col-2">
            <div className="w-full">
              <PFButton
                label="Send"
                onClick={() => emailFormik.handleSubmit()}
                className="btn-mail-send w-full"
                disabled={loading}
                icon={loading ? 'pi pi-spin pi-spinner' : undefined}
              />
            </div>
          </div>
        </div>
        <div className="grid py-2">
          <div className="col-12 md:col-12 lg:col-12">
            <div className="w-full">
              <span className="p-float-label">
                <InputText
                  value={emailFormik.values.subject}
                  name="subject"
                  onChange={event => {
                    emailFormik.handleChange(event);
                    setIsFormChange(true);
                  }}
                />
                <label htmlFor="note">
                  Enter subject <span style={{ color: 'red' }}>*</span>
                </label>
                <PFFormikError
                  touched={emailFormik.touched}
                  errors={emailFormik.errors}
                  field="subject"
                />
              </span>
            </div>
          </div>
        </div>

        <div className="grid py-2">
          <div className="col-12 md:col-12 lg:col-12">
            <PFEditor
              value={emailFormik.values.mailBody}
              style={{ height: '150px' }}
              onTextChange={e => {
                emailFormik?.setFieldValue('mailBody', e.htmlValue);
                if (!checkIfNull(e.htmlValue)) {
                  setIsFormChange(true);
                }
              }}
              headerTemplate={renderHeader()}
              placeholder="Body"
            />
            <PFFormikError
              touched={emailFormik.touched}
              errors={emailFormik.errors}
              field="mailBody"
            />
          </div>
        </div>
        <Toast ref={toast} />
        <ConfirmDialog />
      </AccordionTab>
    </Accordion>
  );
};

export default EmailForm;
