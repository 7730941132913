import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

export default function PFDataTable({
  data,
  columns,
  paginator,
  loading,
  lazy,
  rows,
  first,
  rowsPerPageOptions,
  tableStyle,
  className,
  onPage,
  totalRecords,
  handleDelete,
  onSort,
  dataTableParams,
  filters,
  onFilter,
}) {
  return (
    <DataTable
      loading={loading || false}
      value={data || []}
      paginator={paginator || false}
      lazy={lazy || false}
      rows={rows || 0}
      first={first || 0}
      rowsPerPageOptions={rowsPerPageOptions || []}
      tableStyle={tableStyle || null}
      className={`w-12 ${className}`}
      onPage={onPage || null}
      totalRecords={totalRecords || 0}
      handleDelete={handleDelete}
      onSort={onSort}
      filters={filters}
      onFilter={onFilter}
      {...dataTableParams}
    >
      {columns?.map((col, index) => {
        const {
          field,
          header,
          sortable,
          filter,
          body,
          filterElement,
          filterField,
          filterPlaceholder,
          filterClear,
          filterApply,
          showFilterMatchModes,
        } = col;
        return (
          <Column
            key={index}
            field={field || null}
            header={header || null}
            sortable={sortable || false}
            filter={filter || null}
            body={body || null}
            filterElement={filterElement || null}
            filterField={filterField || null}
            filterPlaceholder={filterPlaceholder || null}
            filterClear={filterClear || null}
            filterApply={filterApply || null}
            showFilterMatchModes={showFilterMatchModes || null}
          ></Column>
        );
      })}
    </DataTable>
  );
}
