import React from 'react';
import { Skeleton } from 'primereact/skeleton';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
const PFTableSkeleton = ({
  numRows = 5,
  rowHeight = '65px',
  dataTableColumns,
  isSearchVisible = false,
  filterCount = 3,
  isHeaderVisible = false,
  isFooterVisible = true,
}) => {
  const items = Array.from({ length: numRows }, (v, i) => i);
  return (
    <DataTable
      value={items}
      className="border-none bg-white skeleton p-datatable-striped w-12"
      footer={
        isFooterVisible && (
          <div className="flex w-full align-items-center justify-content-end ">
            <div className="mr-3 flex gap-5">
              <div className="gap-3 flex">
                <Skeleton className="w-7rem" style={{ height: rowHeight }} />
                <Skeleton className="w-3rem" style={{ height: rowHeight }} />
              </div>
              <div className="flex gap-4">
                <Skeleton className="w-4rem" style={{ height: rowHeight }} />
                <Skeleton className="w-1rem" style={{ height: rowHeight }} />
              </div>
            </div>
          </div>
        )
      }
      pt={{
        footer: {
          className: 'skeleton-footer-height border-none bg-white',
        },
        header: {
          className: isHeaderVisible
            ? 'hidden'
            : `skeleton-header-height border-top-none skeleton-header-bg ${
                isSearchVisible ? 'pb-6' : 'pb-0'
              }`,
        },
      }}
      header={
        <div className="flex w-full align-items-center justify-content-between">
          <div>
            {isSearchVisible && <Skeleton className="w-16rem h-2rem" />}
          </div>
          <div className="mr-3 flex gap-4">
            {Array.from({ length: filterCount }, (_, i) => (
              <Skeleton key={i} className="w-1rem" />
            ))}
          </div>
        </div>
      }
    >
      {dataTableColumns?.map((col, i) => {
        const cellProps = col.options?.setCellProps?.() || {};
        const style = {
          height: rowHeight,
          minWidth: cellProps.style?.minWidth,
          maxWidth: cellProps.style?.maxWidth,
          width: cellProps.style?.width,
        };
        return (
          <Column
            key={i}
            header={col.label || col.header || col.value}
            style={style}
            body={
              col.label || col.header ? (
                <Skeleton style={{ height: rowHeight }} />
              ) : null
            }
          />
        );
      })}
    </DataTable>
  );
};

export default PFTableSkeleton;
