import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

const InstallerTable = ({
  permissions,
  rowData,
  handleAddInstaller,
  viewEditInstaller,
  deleteInstaller,
  handleCloneInstaller,
  schedulerEditPermission,
  loading,
  columnData,
}) => {
  // Dynamic body template
  const dynamicBodyTemplate = (rowData, options) => {
    return rowData[options.field]?.value;
  };
  const header = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <h3 className="text-base font-bold">Technicians</h3>
      {schedulerEditPermission
        ? permissions?.viewEditProject?.actionEditScheduler
        : permissions?.viewEditProject?.addInstaller && (
            <Button
              label="Add Technician"
              size="small"
              type="button"
              onClick={handleAddInstaller}
              className="p-button-outlined bg-reverse-primary flex justify-content-end"
            />
          )}
    </div>
  );

  return (
    <>
      <DataTable value={rowData} header={header}>
        {!loading ? (
          <Column
            headerStyle={{ 'min-width': '8rem' }}
            header=""
            body={(rowData, { rowIndex }) => (
              <div className="flex justify-content-between">
                {permissions?.viewEditProject?.viewInstaller ? (
                  <Button
                    icon="pi pi-eye"
                    className="p-button-text w-1 px-2"
                    onClick={() => viewEditInstaller('view', rowIndex)}
                    type="button"
                    size="small"
                  />
                ) : null}
                {(
                  schedulerEditPermission
                    ? permissions?.viewEditProject?.actionEditScheduler
                    : permissions?.viewEditProject?.editInstaller
                ) ? (
                  <Button
                    icon="pi pi-pencil"
                    className="p-button-text w-1 px-2"
                    onClick={() => viewEditInstaller('edit', rowIndex)}
                    type="button"
                    size="small"
                  />
                ) : null}
                {(schedulerEditPermission
                  ? permissions?.viewEditProject?.actionEditScheduler
                  : permissions?.viewEditProject?.deleteInstaller) &&
                rowData?.hideDeleteIcon?.value === 'no' ? (
                  <Button
                    icon="pi pi-trash"
                    className="p-button-text w-1 px-2"
                    onClick={() => deleteInstaller(rowIndex)}
                    type="button"
                    size="small"
                  />
                ) : null}
                {(schedulerEditPermission
                  ? permissions?.viewEditProject?.actionEditScheduler
                  : permissions?.viewEditProject?.addInstaller) &&
                rowData?.hideCloneIcon?.value === 'no' ? (
                  <Button
                    icon="pi pi-copy"
                    className="p-button-text w-1 px-2"
                    onClick={() => handleCloneInstaller('clone', rowIndex)}
                    type="button"
                    size="small"
                  />
                ) : null}
              </div>
            )}
          />
        ) : null}
        {columnData.map(col => (
          <Column
            key={col.id}
            field={col.id}
            header={col.value}
            body={dynamicBodyTemplate}
          />
        ))}
      </DataTable>
    </>
  );
};

export default InstallerTable;
