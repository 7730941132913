import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: true,
  toggleButtonLabel: 'Select All',
  clientList: [],
};

const routeConfigSlice = createSlice({
  name: 'routeConfig',
  initialState: initialState,
  reducers: {
    routeConfigUpdateClientList(state, { payload }) {
      state.clientList = Object.prototype.hasOwnProperty.call(
        payload,
        'clientList'
      )
        ? payload.clientList
        : state.clientList;
    },
    routeConfigUpdateComponent(state, { payload }) {
      state.isLoading = Object.prototype.hasOwnProperty.call(
        payload,
        'isLoading'
      )
        ? payload.isLoading
        : state.isLoading;
      state.toggleButtonLabel = Object.prototype.hasOwnProperty.call(
        payload,
        'toggleButtonLabel'
      )
        ? payload.toggleButtonLabel
        : state.toggleButtonLabel;
    },
  },
});

export const { routeConfigUpdateClientList, routeConfigUpdateComponent } =
  routeConfigSlice.actions;

export const routeConfigReducer = routeConfigSlice.reducer;
