import React, { useEffect, useState, createContext, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';

import { Shipment } from './components/Shipment.jsx';
import { ShippingTable } from './components/ShippingTable.jsx';
import { ShippingHeader } from './components/ShippingHeader.jsx';

export const ShippingContext = createContext();

export const ShippingContainer = () => {
  const toast = useRef(null);
  const { projectId } = useParams();

  const [shippingContextValue, setShippingContextValue] = useState({
    show: false,
    hide: () => {
      setShippingContextValue(preValue => ({
        ...preValue,
        show: !preValue?.show,
      }));
    },
    visibleMode: {
      visible: 'add',
      data: {},
    },
    setVisibleMode: (data = {}, rest = {}) => {
      setShippingContextValue(preValue => ({
        ...preValue,
        visibleMode: data,
        rest: rest,
      }));
    },
    toast: toast,
  });

  useEffect(() => {
    if (projectId) {
      setShippingContextValue(preValue => ({
        ...preValue,
        project_id: projectId,
      }));
    }
  }, [projectId]);

  return (
    <>
      <ShippingContext.Provider
        value={{ shippingContextValue, setShippingContextValue }}
      >
        <Toast ref={toast} />
        <ShippingHeader />
        <ShippingTable />
        {shippingContextValue?.show && <Shipment />}
      </ShippingContext.Provider>
    </>
  );
};
