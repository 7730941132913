import React, { useRef } from 'react';
import { Toast } from 'primereact/toast';

import EmailTable from './components/EmailTable';
import ComposeEmail from './components/ComposeEmail';
import {
  EmailApiResponse,
  EmailFormValues,
  SearchQuery,
} from './Interface/email.interface';
import { getProjectEmailData, sendEmail } from './service/email.service';

const EmailContainer: React.FC<{
  project_id: number;
  storeEmail: string;
  customerEmail: string;
  project_number: string;
}> = ({ project_id, storeEmail, customerEmail, project_number }) => {
  const [loading, setLoading] = React.useState(true);
  const [emailList, setEmailList] = React.useState<EmailApiResponse | null>(
    null
  );

  const toast = useRef<Toast>(null);
  const loadEmailData = async (query: SearchQuery) => {
    setLoading(true);
    const data = await getProjectEmailData(project_id, query);
    if (!data?.error) {
      setEmailList(data?.emailData);
    } else {
      toast.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: data?.message,
        life: 3000,
      });
    }
    setLoading(false);
  };
  const sentEmail = async (values: EmailFormValues) => {
    const emailData = new FormData();
    emailData.append('to', values.to.toString());
    emailData.append('subject', values.subject);
    emailData.append('body', values.body);
    if (Array.isArray(values.attachments) && values.attachments.length > 0) {
      values.attachments.forEach((file: File, index: number) => {
        emailData.append(`file_${index}`, file, file.name);
      });
    }

    const response = await sendEmail(project_id, emailData);
    if (!response.error) {
      toast.current?.show({
        severity: 'success',
        summary: 'Success',
        detail: response?.message,
        life: 3000,
      });
      loadEmailData({ offset: 0, limit: 10 });
      return true;
    } else {
      toast.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: response?.message,
        life: 3000,
      });
      return false;
    }
  };

  return (
    <>
      <Toast ref={toast} />

      <div className="grid grid-nogutter">
        <ComposeEmail
          sentEmail={sentEmail}
          storeEmail={storeEmail}
          customerEmail={customerEmail}
          project_number={project_number}
        />
        <EmailTable
          loading={loading}
          loadEmailData={loadEmailData}
          emailList={emailList}
        />
      </div>
    </>
  );
};

export default EmailContainer;
