import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode } from 'primereact/api';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { debounce } from 'lodash';

import { UTCConvertor } from '../../../utils/date.helper';
import { URL_CONSTANTS } from '../../../constants/urlConstants';
import ApiService from '../../../services/api.service';
import PageHeader from '../../shared/PageHeader/PageHeader';
import SkeletonLoader from '../../shared/Loader/skeleton';
import { useConfirmDialogContext } from '../../../contexts/ConfirmDialog';
import { AUDIT_LOG_SYSTEM_DEFAULT_REPORT } from '../../../constants';

const ProjectAuditLog = () => {
  const { showConfirmationDialog } = useConfirmDialogContext();
  const [clientName, setClientName] = useState('');
  const roBreadcrumb = [
    {
      link: '/admin/configuration',
      text: 'Company Configurations',
    },
    {
      text: `${clientName}`,
    },
    {
      text: 'Audit Log Reports',
    },
  ];
  const history = useHistory();
  const [auditLogList, setAuditLogList] = useState([]);
  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: null,
    sortOrder: null,
    filters: {
      report_name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      modal_name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      subModal_name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    },
  });
  const [count, setCount] = useState();
  const [searchQuery, setSearchQuery] = useState({
    limit: 10,
    offset: 0,
    searchString: '',
    sortKey: 'created_at',
    sortDirection: 'DESC',
  });
  const [isLoading, setIsLoading] = useState(false);
  const toast = useRef(null);
  const { id: clientId } = useParams();
  const handleClickCreate = () => {
    history.push(`/create-audit-log-report/${clientId}/add`, { action: 'add' });
  };

  const auditLogListRes = async query => {
    setIsLoading(true);
    try {
      let newQueryString;
      newQueryString = Object.entries(query)
        .filter(([, value]) => Boolean(value) || value === 0)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');

      const response = await ApiService.get(
        `${URL_CONSTANTS.AUDIT_LOG.module.list}?${newQueryString}&client_id=${clientId}`
      );

      if (response?.rows?.length > 0) {
        setIsLoading(false);
        setClientName(response?.clientName);
        setAuditLogList(response?.rows);
        setCount(response?.count);
      } else {
        setAuditLogList([]);
        setCount(0);
        setIsLoading(false);
      }
    } catch (e) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: e?.message,
        life: 3000,
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    auditLogListRes(searchQuery);
  }, []);

  const debouncedOnChange = useCallback(
    debounce(query => {
      auditLogListRes(query);
    }, 2000),
    []
  );

  const handleDelete = async value => {
    try {
      const response = await ApiService.delete(
        `${URL_CONSTANTS.AUDIT_LOG.module.deleteAudit}${clientId}/delete/${value}`
      );
      if (response?.status === true) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Deleted Successfully',
          life: 3000,
        });
        auditLogListRes(searchQuery);
      }
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Something went wrong',
        life: 3000,
      });
      console.error(error);
    }
  };

  const headerTittle = () => {
    return (
      <div className="flex align-items-center">
        <i className="pi pi-info-circle"></i>
        <div className="ml-2">Delete Confirmation</div>
      </div>
    );
  };

  const handleDeleteAuditLog = value => {
    showConfirmationDialog({
      message: 'Do you want to delete row?',
      header: () => headerTittle(),
      acceptClassName: 'p-button-sm',
      rejectClassName: 'p-button-sm p-button-outlined',
      accept: () => handleDelete(value),
      reject: () => false,
      onHide: () => false,
      rejectLabel: 'Cancel',
      acceptLabel: 'Delete',
      pt: {
        root: {
          className: 'w-20rem',
        },
        closeButtonIcon: {
          className: 'hidden',
        },
        headericons: {
          className: 'hidden',
        },
        content: {
          className: 'p-3',
        },
        header: {
          className: 'pt-4 px-4 pb-2',
        },
      },
    });
  };

  const handleEditAuditLog = (action, value) => {
    switch (action) {
      case 'edit':
        history.push(`/create-audit-log-report/${clientId}/edit/${value}`, {
          action: action,
          reportId: value,
        });
        break;
      default:
        break;
    }
  };

  const tableColum = [
    {
      name: 'action_buttons',
      label: '',
      sortable: false,
    },
    {
      name: 'report_name',
      label: 'Report Name',
      sortable: true,
      filter: true,
    },
    {
      name: 'modal_name',
      label: 'Module',
      sortable: false,
      filter: true,
    },
    {
      name: 'subModal_name',
      label: 'Sub Module',
      sortable: false,
      filter: true,
    },
    {
      name: 'created_by',
      label: 'Created By',
      sortable: false,
      filter: false,
    },
    {
      name: 'created_at',
      label: 'Created At',
      sortable: true,
      filter: false,
    },
    {
      name: 'modified_by',
      label: 'Modified By',
      sortable: false,
      filter: false,
    },
    {
      name: 'modified_date',
      label: 'Modified At',
      sortable: false,
      filter: false,
    },
  ];

  let dataArray = auditLogList?.map(val => {
    return {
      auditLog_id: val?.audit_log_modal_id,
      created_at: UTCConvertor(val?.created_at),
      report_name: val?.modal_name,
      modal_name: val?.parent_module_name,
      subModal_name: val?.sub_module_name,
      created_by: val?.created_user
        ? `${val.created_user.first_name ? val.created_user.first_name?.replace(/^./, char => char.toUpperCase()) : '-'} 
        ${val.created_user.last_name ? val.created_user.last_name?.replace(/^./, char => char.toUpperCase()) : '-'}`
        : '-',

      modified_by:
        val?.modified_by && val?.modified_user
          ? (val?.modified_user?.first_name
              ? val?.modified_user?.first_name
              : '-') +
            ' ' +
            (val?.modified_user?.last_name
              ? val?.modified_user?.last_name
              : '-')
          : '-',
      modified_date: val?.modified_by ? UTCConvertor(val?.modified_at) : '-',
      action_buttons:
        val?.audit_log_modal_id === AUDIT_LOG_SYSTEM_DEFAULT_REPORT &&
        val?.client_id === clientId ? (
          <i
            className="pi pi-pencil cursor-pointer text-primary"
            role="button"
            tabIndex="0"
            onClick={() => handleEditAuditLog('edit', val?.audit_log_modal_id)}
            onKeyDown={e => {
              if (e?.key === 'Enter' || e?.key === ' ') {
                handleEditAuditLog('edit', val?.audit_log_modal_id);
              }
            }}
          ></i>
        ) : val?.client_id === clientId &&
          val?.audit_log_modal_id !== AUDIT_LOG_SYSTEM_DEFAULT_REPORT ? (
          <div className="flex flex-row gap-3">
            <i
              className="pi pi-pencil cursor-pointer text-primary"
              onClick={() =>
                handleEditAuditLog('edit', val?.audit_log_modal_id)
              }
            ></i>
            <i
              className="pi pi-trash cursor-pointer text-red-600"
              onClick={() => handleDeleteAuditLog(val?.audit_log_modal_id)}
            ></i>
          </div>
        ) : null,
    };
  });

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={searchQuery.searchString}
            onChange={e => {
              const newSearchQuery = {
                limit: 10,
                offset: 0,
                searchString: e.target.value,
              };
              setSearchQuery(newSearchQuery);

              if (
                e?.target?.value?.length > 2 ||
                e?.target?.value?.length === 0
              ) {
                debouncedOnChange(newSearchQuery);
              }
            }}
            placeholder="Keyword Search"
            className="p-inputtext-sm"
          />
        </span>
      </div>
    );
  };

  const handleOnPage = event => {
    const newOffset = event?.page * event?.rows;
    const newQuery = { ...searchQuery, limit: event?.rows, offset: newOffset };
    setSearchQuery(newQuery);
    setlazyState(event);
    auditLogListRes(newQuery);
  };

  const onSort = event => {
    const newSearchQuery = {
      ...searchQuery,
      sortKey: event.sortField,
      sortDirection: event.sortOrder > 0 ? 'ASC' : 'DESC',
    };
    setSearchQuery(newSearchQuery);
    auditLogListRes(newSearchQuery);
    setlazyState(event);
  };

  return (
    <>
      <ConfirmDialog />
      <Toast ref={toast}></Toast>
      <div className="w-full grid grid-nogutter">
        <div className="col-6">
          <PageHeader
            pageTitle={`${clientName} Audit Log Reports`}
            breadCrumbArray={roBreadcrumb}
          />
        </div>
        <div className="col-6 lg:col-6 lg:text-right">
          <div className="w-12 md:w-auto">
            <Button onClick={handleClickCreate} label="Create" size="small" />
          </div>
        </div>
      </div>
      <Card className="w-full mt-4 mb-0 mx-auto">
        <div className="grid">
          {isLoading ? (
            <SkeletonLoader columnCount={4} columnWidth="24%" />
          ) : (
            <DataTable
              value={dataArray}
              dataKey="auditLog_id"
              showGridlines={false}
              size="small"
              stripedRows
              lazy
              paginator
              page={lazyState.page}
              first={lazyState.first}
              rows={lazyState.rows}
              totalRecords={count}
              onPage={handleOnPage}
              header={renderHeader}
              onSort={onSort}
              sortField={lazyState?.sortField}
              sortOrder={lazyState?.sortOrder}
              filters={lazyState.filters}
              rowsPerPageOptions={[10, 20, 50, 100]}
              className="w-full"
            >
              {tableColum.map(col => (
                <Column
                  key={col.name}
                  field={col.name}
                  header={col.label}
                  sortable={col?.sortable}
                  filter={col?.filter}
                  showFilterMenu={false}
                />
              ))}
            </DataTable>
          )}
        </div>
      </Card>
    </>
  );
};

export default ProjectAuditLog;
