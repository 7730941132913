import InventoryAdjustment from './InventoryAdjustment.view';
import InventoryTransfer from './InventoryTransfer.view';
import ProductUnit from './ProductUnit.view';

const panels = [
  {
    header: 'Unit',
    body: ProductUnit,
  },
  {
    header: 'Adjustment',
    body: InventoryAdjustment,
  },
  {
    header: 'Transfers',
    body: InventoryTransfer,
  },
];

export { panels };
