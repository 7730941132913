import GenericTable from '../../shared/GenericTable/GenericTable';

const InstallerTable = ({
  permissions,
  rowData,
  handleAddInstaller,
  viewEditInstaller,
  deleteInstaller,
  handleCloneInstaller,
  schedulerEditPermission,
  loading,
  columnData,
}) => {
  return (
    <GenericTable
      title="Technicians"
      headerLinks={[
        {
          label: 'Add Technician',
          add: schedulerEditPermission
            ? permissions?.viewEditProject?.actionEditScheduler
            : permissions?.viewEditProject?.addInstaller,
          handler: handleAddInstaller,
        },
      ]}
      columnData={columnData}
      rowData={rowData}
      {...(!loading
        ? {
            showActions: { view: true, edit: true, delete: true, clone: true },
            handleView: index => viewEditInstaller('view', index),
            handleEdit: index => viewEditInstaller('edit', index),
            handleDelete: index => deleteInstaller(index),
            handleClone: index => {
              handleCloneInstaller('clone', index);
            },
          }
        : {})}
      permissions={{
        view: permissions?.viewEditProject?.viewInstaller,
        edit: schedulerEditPermission
          ? permissions?.viewEditProject?.actionEditScheduler
          : permissions?.viewEditProject?.editInstaller,
        delete: schedulerEditPermission
          ? permissions?.viewEditProject?.actionEditScheduler
          : permissions?.viewEditProject?.deleteInstaller,
        clone: schedulerEditPermission
          ? permissions?.viewEditProject?.actionEditScheduler
          : permissions?.viewEditProject?.addInstaller,
      }}
    />
  );
};

export default InstallerTable;
