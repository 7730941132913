import axios, { AxiosError } from 'axios';

import { URL_CONSTANTS } from '../../../../constants/urlConstants';
import { SearchQuery } from '../Interface/email.interface';

export const getProjectEmailData = async (
  projectId: number,
  searchQuery: SearchQuery
) => {
  try {
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/email/manage/project/${projectId}`,
      {
        params: searchQuery,
      }
    );
    return {
      error: false,
      message: 'Email list fetched successfully',
      emailData: response.data,
    };
  } catch (error) {
    console.error(error);
    return {
      error: true,
      message: 'No Email Found.',
      emailData: {},
    };
  }
};

export const sendEmail = async (projectId: number, email: FormData) => {
  try {
    const response = await axios.post(
      `${URL_CONSTANTS.API.BASE_URL}/email/manage/${projectId}/create`,
      email
    );
    return {
      error: false,
      message: 'Email has sent successfully.',
      response: response,
    };
  } catch (error) {
    return {
      error: true,
      message:
        error instanceof AxiosError
          ? error?.response?.data?.error
          : 'Error sending email',
    };
  }
};
