import React from 'react';

import PFTabView from '../../../shared/PFPrime/PFTabView';
import OpportunitiesTab from '../../Opportunities/OpportunitiesTab';
import NotesComponent from '../../Communications/components/NotesComponent';
import EmailComponent from '../../Communications/components/EmailComponent';
import SMSComponent from '../../Communications/components/SMSComponent';
import ProjectsListing from '../../Projects/components/ProjectsListing';
import { isCrmEnabled } from '../../../../utils/Helpers';
import MeasureSquareQuotes from '../../Projects/components/MeasureSquareQuotes';

const TAB_HASHMAP = {
  leads: 'Leads',
  quotes: 'Quotes',
  projects: 'Projects',
  notes: 'Notes',
  sms: 'SMS',
  email: 'Email',
};
const CustomerOperations = ({ customerId }) => {
  const isCrm = isCrmEnabled();
  const urlParams = new URLSearchParams(window.location.search);
  const tabName = urlParams.get('tab');
  let activeIndex = 0;
  const CustomerOperationsModel = {
    tabs: [
      ...(isCrm ? [{ header: 'Leads' }] : []),
      ...(isCrm ? [{ header: 'Quotes' }] : []),
      { header: 'Projects', disabled: false },
      { header: 'Notes', disabled: false },
      { header: 'SMS', disabled: false },
      { header: 'Email', disabled: false },
    ],
    components: {
      ...(isCrm && { Leads: <OpportunitiesTab /> }),
      ...(isCrm && {
        Quotes: (
          <div className="py-1 px-3">
            <MeasureSquareQuotes isGlobalLevel={true} />
          </div>
        ),
      }),
      Projects: <ProjectsListing quoteNumber={true} opportunityNumber={true} />,
      Notes: <NotesComponent customerId={customerId} />,
      SMS: <SMSComponent customerId={customerId} />,
      Email: <EmailComponent customerId={customerId} />,
    },
  };
  if (TAB_HASHMAP[tabName]) {
    activeIndex = CustomerOperationsModel.tabs.findIndex(
      tab => tab.header === TAB_HASHMAP[tabName]
    );
  }
  return (
    <div className="w-full pt-3 crm p-0">
      <PFTabView model={CustomerOperationsModel} activeIndex={activeIndex} />
    </div>
  );
};

export default CustomerOperations;
