import React, { useState, useRef } from 'react';
import { useParams } from 'react-router';
import { Toast } from 'primereact';

import PFButton from '../../../../shared/PFPrime/PFButton';
import PFInputText from '../../../../shared/PFPrime/PFInputText';
import { addEditChargeback } from '../../services/ChargebackService';
import { getLoginUserId } from '../../../../../utils/Helpers';
import SkeletonLoader from '../../../../shared/Loader/skeleton';

import NotesList from './NotesList';

const Notes = ({ disabledFields }) => {
  const { chargebackId } = useParams();
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [notes, setNotes] = useState();
  const toast = useRef(null);

  const handleSubmit = async () => {
    const body = {
      notes: {
        note: notes,
        added_time: new Date(),
        added_by: getLoginUserId(),
      },
    };
    setLoading(true);
    let response = await addEditChargeback(body, chargebackId);
    if (response?.data) {
      setReload(true);
      setNotes('');
      toast?.current?.show({
        severity: 'success',
        summary: response?.message,
        life: 2000,
      });
    }
    setLoading(false);
  };

  return loading ? (
    <SkeletonLoader columnCount={1} columnWidth="100%" />
  ) : (
    <>
      <div className="grid p-2">
        <div className="col-12 md:col-11 lg:col-11">
          <div className="w-full">
            <span className="p-float-label">
              <PFInputText
                value={notes}
                name="noteInput"
                onChange={e => {
                  setNotes(e.target.value);
                }}
                disabled={disabledFields}
              />
              <label htmlFor="note">
                Enter Note <span style={{ color: 'red' }}>*</span>
              </label>
            </span>
          </div>
        </div>
        <div className="col-12 md:col-1 lg:col-1">
          <div className="w-full">
            <PFButton
              label="Create"
              onClick={() => handleSubmit()}
              className="btn-mail-send"
              disabled={loading || disabledFields || !notes}
              size="small"
              icon={loading ? 'pi pi-spin pi-spinner' : undefined}
            />
          </div>
        </div>
        <div className="col-12 md:col-12 lg:col-12">
          <NotesList chargebackId={chargebackId} reload={reload} />
        </div>
      </div>

      <Toast ref={toast} />
    </>
  );
};

export default Notes;
