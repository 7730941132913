import React from 'react';
import { useHistory } from 'react-router-dom';

import { checkPermission } from '../../../utils/Helpers';
import permissions from '../../../config/permissions';

import { removeQuote } from './Quote.service';

const statusStyleBodyTemplate = rowData => {
  if (rowData?.status) {
    return (
      <span className={rowData?.status.replace(' ', '_').toLowerCase()}>
        {rowData?.status}
      </span>
    );
  }
};

const actionBodyTemplate = (rowData, data) => {
  const history = useHistory();
  if (rowData?.customerId) {
    return (
      <div className="flex">
        <i
          className={`pi pi-eye cursor-pointer`}
          onClick={() => {
            // history.push(`/client/${rowData?.customerId}/crm/quote/${rowData?.quoteId}/view`);
            const url = `/client/${rowData?.customerId}/crm/quote/${rowData?.quoteId}/view`;
            window.open(url, '_blank');
          }}
        ></i>
        {checkPermission(permissions?.crm?.editQuote) && (
          <>
            <i
              className={`pi pi-pencil ml-3 cursor-pointer`}
              onClick={() => {
                history.push(
                  `/client/${rowData?.customerId}/crm/quote/${rowData?.quoteId}/edit`
                );
              }}
            ></i>
            <i
              className={`pi pi-trash ml-3 cursor-pointer`}
              onClick={() => {
                data?.props?.handleDelete(rowData?.quoteId);
              }}
            ></i>
          </>
        )}
      </div>
    );
  }
};

export const columns = (
  clientFilterTemplate,
  dateFilterTemplate,
  stringFilterTemplate,
  statusFilterTemplate
) => {
  return [
    {
      field: '',
      header: '',
      sortable: false,
      filter: false,
      body: actionBodyTemplate,
    },
    {
      field: 'quoteId',
      header: 'Quote No',
      sortable: true,
      filter: true,
      body: null,
      filterElement: stringFilterTemplate,
      showFilterMatchModes: false,
    },
    {
      field: 'title',
      header: 'Title',
      sortable: true,
      filter: true,
      body: null,
      filterElement: stringFilterTemplate,
      showFilterMatchModes: false,
    },
    {
      field: 'createDate',
      header: 'Created',
      sortable: true,
      filter: true,
      body: null,
      filterElement: dateFilterTemplate,
      showFilterMatchModes: false,
    },
    {
      field: 'expiryDate',
      header: 'Expiry',
      sortable: true,
      filter: true,
      body: null,
      // body: (date) => formatDate(date?.expiryDate),
      filterElement: dateFilterTemplate,
      showFilterMatchModes: false,
    },
    {
      field: 'followupDate',
      header: 'Followup',
      sortable: true,
      filter: true,
      body: null,
      // body: (date) => formatDate(date?.followupDate),
      filterElement: dateFilterTemplate,
      showFilterMatchModes: false,
    },
    {
      field: 'status',
      header: 'Status',
      sortable: true,
      filter: true,
      body: statusStyleBodyTemplate,
      filterElement: statusFilterTemplate,
      showFilterMatchModes: false,
    },
  ];
};

export const quoteListingHeader = () => {
  return (
    <div className="w-12 pl-3 m-0 flex">
      <div className="w-12 text-1xl font-bold p-1"> Quote List</div>
    </div>
  );
};

export const callQuoteList = async ({
  setLoading,
  setTotalRecords,
  setQuoteListing,
}) => {
  setLoading(true);
  const { data } = {
    status: true,
    statusCode: 200,
    data: {
      result: [
        {
          quoteId: 1044,
          clientId: '11MT97PY',
          customerId: 721955,
          createDate: '2023-11-14',
          expiryDate: '2023-11-15',
          followupDate: '2023-11-29',
          title: 'QA quote test one',
          status: 'OPEN',
          amount: {},
          leadSource: {},
          senderDetails: null,
          clientName: 'Vinay Singh',
        },
        {
          quoteId: 1043,
          clientId: '11MT97PY',
          customerId: 721954,
          createDate: '2021-11-10',
          expiryDate: '2023-11-15',
          followupDate: '2023-11-13',
          title: 'sql7',
          status: 'REVIEW',
          amount: {},
          leadSource: {},
          senderDetails: null,
          clientName: 'Sameer Singh',
        },
        {
          quoteId: 1041,
          clientId: '11MT97PY',
          customerId: 721955,
          createDate: '2023-11-14',
          expiryDate: '2023-11-15',
          followupDate: '2023-11-23',
          title: 'QA quote test one',
          status: 'OPEN',
          amount: {},
          leadSource: {},
          senderDetails: null,
          clientName: 'Vinay Singh',
        },
        {
          quoteId: 1040,
          clientId: '11MT97PY',
          customerId: 721954,
          createDate: '2021-11-10',
          expiryDate: '2023-11-15',
          followupDate: '2023-11-13',
          title: 'sql7',
          status: 'OPEN',
          amount: {},
          leadSource: {},
          senderDetails: null,
          clientName: 'Sameer Singh',
        },
      ],
      limit: 10,
      page: '1',
      count: 4,
    },
    message: 'Quote list fetched successfully',
  };

  setTotalRecords(data.count);
  setQuoteListing(data.result);
  setLoading(false);
};

export const deleteQuote = async ({ quoteId, toast, setLoadList }) => {
  try {
    const { status, data } = await removeQuote(quoteId);
    if (status === 200 && data) {
      setLoadList(true);
      toast.current.show({
        severity: 'success',
        summary: 'Quote Deleted',
        detail: data?.message,
      });
    } else
      toast.current.show({
        severity: 'error',
        summary: 'API Error',
        detail: data?.message,
      });
  } catch (ex) {
    toast.current.show({
      severity: 'error',
      summary: 'Error',
      detail: ex?.response?.data?.message || 'Error in Deleting Quote.',
    });
  } finally {
    setLoadList(false);
  }
};
