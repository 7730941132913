import axios, { AxiosError } from 'axios';

import {
  NoteItem,
  NoteStatus,
  SearchQuery,
} from '../interface/notes.interface';
import { URL_CONSTANTS } from '../../../../constants/urlConstants';
import apiService from '../../../../services/api.service';
import { Notifications } from '../../../../constants/notification.constant';
import { REACT_APP_ENABLE_PUSH_NOTIFICATION } from '../../../../constants/envConstants';
import { ACTION } from '../../../../constants/form-actions.constant';
import {
  savePushNotification,
  updateNotificationViewed,
} from '../../../shared/Topbar/Notification/Notification.service';

export const fetchNotesData = async (projectId: number, query: SearchQuery) => {
  try {
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}${apiService.formatUrl(URL_CONSTANTS.PROJECTS.notesTab.notesURL, projectId)}`,
      {
        params: query,
      }
    );

    return {
      error: false,
      message: 'Notes data fetched successfully',
      notesData: response?.data,
    };
  } catch (error: unknown) {
    console.error('Error fetching notes data:', error);
    return {
      error: true,
      message:
        error instanceof AxiosError
          ? error?.response?.data?.error
          : 'Error fetching notes data',
      notesData: null,
    };
  }
};

export const changeNoteVisibilityStatus = async (
  action: string,
  projectId: number,
  noteId: number,
  noteCategoryId: number
) => {
  try {
    const notificationType: Record<number, number> = {
      [Notifications.BACKENDMAPPING.INSTALLER]: 1,
      [Notifications.BACKENDMAPPING.STORE]: 2,
    };

    if (
      REACT_APP_ENABLE_PUSH_NOTIFICATION === true &&
      notificationType[`${noteCategoryId}`] &&
      notificationType[`${noteCategoryId}`] > 0
    ) {
      const type =
        noteCategoryId === Notifications.BACKENDMAPPING.STORE
          ? Notifications.STORE
          : Notifications.INSTALLER;
      let viewed = false;
      let reviewed = false;
      if (action === ACTION.REVIEW) {
        reviewed = true;
      } else {
        viewed = true;
      }
      await updateNotificationViewed(
        noteId,
        type,
        localStorage.getItem('user_id'),
        viewed,
        reviewed
      );
    }

    const response = await apiService.patch(
      apiService.formatUrl(
        URL_CONSTANTS.PROJECTS.notesTab.notesURLWithId,
        projectId,
        noteId
      ),
      action === ACTION.REVIEW ? { reviewed: 1 } : { viewed: 1 }
    );
    return {
      error: false,
      message: 'Note visibility status updated Successfully.',
      data: response,
    };
  } catch (error: unknown) {
    console.error('Error updating notes visibility: ', error);
    return {
      error: true,
      message:
        error instanceof AxiosError
          ? error?.response?.data?.error
          : 'Error updating notes visibility',
      data: null,
    };
  }
};

export const deleteNoteById = async (projectId: number, noteId: number) => {
  try {
    const response = await apiService.delete(
      apiService.formatUrl(
        URL_CONSTANTS.PROJECTS.notesTab.notesURLWithId,
        projectId,
        noteId
      )
    );
    return {
      error: false,
      message: 'Note Deleted Successfully.',
      data: response,
    };
  } catch (error: unknown) {
    console.error('Error deleting note: ', error);
    return {
      error: true,
      message:
        error instanceof AxiosError
          ? error?.response?.data?.error
          : 'Error deleting note',
      data: null,
    };
  }
};

export const getNotesTypeOptions = async () => {
  try {
    const response = await apiService.get(
      URL_CONSTANTS.PROJECTS.notesTab.fetchNotesType
    );
    return {
      error: false,
      message: 'Notes Type fetched successfully',
      options: response,
    };
  } catch (error) {
    console.error('Error fetching notes type:', error);
    return {
      error: true,
      message:
        error instanceof AxiosError
          ? error?.response?.data?.error
          : 'Error fetching notes type',
      options: [],
    };
  }
};

export const addNotes = async (projectId: number, values: NoteItem) => {
  try {
    const response = await apiService.post(
      apiService.formatUrl(URL_CONSTANTS.PROJECTS.notesTab.notesURL, projectId),
      values
    );
    if (
      REACT_APP_ENABLE_PUSH_NOTIFICATION === true &&
      values.note_category_id &&
      [
        Notifications.BACKENDMAPPING.INSTALLER,
        Notifications.BACKENDMAPPING.STORE,
      ].includes(values.note_category_id)
    ) {
      savePushNotification(
        localStorage.getItem('user_id'),
        localStorage.getItem('client_id'),
        values.note_category_id == Notifications.INSTALLER
          ? Notifications.INSTALLER
          : Notifications.STORE,
        projectId,
        response?.project_note_id
      );
    }
    return {
      error: false,
      message: 'Notes Added Successfully.',
      options: response,
    };
  } catch (error: unknown) {
    console.error('Error adding note: ', error);
    return {
      error: true,
      message:
        error instanceof AxiosError
          ? error?.response?.data?.error
          : 'Error adding note',
      data: null,
    };
  }
};

export const updateNote = async (
  projectId: number,
  noteId: number,
  payload: NoteStatus
) => {
  try {
    const response = await apiService.patch(
      apiService.formatUrl(
        URL_CONSTANTS.PROJECTS.notesTab.notesURLWithId,
        projectId,
        noteId
      ),
      payload
    );
    return {
      error: false,
      message: 'Notes Closed Successfully.',
      data: response,
    };
  } catch (error: unknown) {
    console.error('Error closing note: ', error);
    return {
      error: true,
      message:
        error instanceof AxiosError
          ? error?.response?.data?.error
          : 'Error closing note',
      data: null,
    };
  }
};
