//imports
import { Dialog } from 'primereact/dialog';
import { useState } from 'react';
import { Button } from 'primereact/button';

//Services
import { deleteProjectItemUser } from './ProjectItemsService';

export default function DeleteProjectItemUserDialog(props) {
  const selectedItem = props.selectedItem;
  const [isLoading, setIsLoading] = useState(false);

  //methods
  const handleClose = (projectItem = null, timeout = 0) => {
    setIsLoading(false);
    if (timeout > 0) {
      setTimeout(() => {
        props.onHide(projectItem);
      }, timeout);
    } else {
      props.onHide(projectItem);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const response = await deleteProjectItemUser(
      selectedItem.project_item_user?.project_item_user_id,
      props.projectId
    );
    setIsLoading(false);
    if (response?.data?.status) {
      props.showAlert('success', 'Item Technician Deleted Successfully');
      handleClose(true);
    } else {
      props.showAlert('error', 'Unable To Delete Item Technician');
      handleClose(false);
    }
  };

  return (
    <>
      <Dialog
        header="Deletion Alert"
        visible={props.visible}
        onHide={props.onHide}
        className="w-11 md:w-5"
        pt={{
          header: { className: 'mb-0 pb-0' },
          content: { className: 'pt-1' },
        }}
      >
        <div className="flex flex-column">
          <div className="flex">
            <p className="mt-0 text-sm">
              <i className="pi pi-exclamation-triangle"></i> Are you sure you
              want to delete this assigned technician?
            </p>
          </div>
          <div className="flex ml-auto">
            <Button
              label="Delete"
              onClick={handleSubmit}
              size="small"
              className="ml-1"
              disabled={isLoading}
            ></Button>
          </div>
        </div>
      </Dialog>
    </>
  );
}
