import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { Skeleton } from 'primereact/skeleton';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';

import { StatusColors } from '../../../constants';
import {
  checkPermission,
  lockGlobalBar,
  momentTz,
} from '../../../utils/Helpers';
import PFButton from '../PFPrime/PFButton';
import PFDataTable from '../PFPrime/PFDataTable';
import permissions from '../../../config/permissions';

import {
  listCxScheduledProject,
  acceptCxScheduledProject,
  declineCxScheduledProject,
} from './service';
import CxSchedulerValidationDialog from './CxSchedulerValidationDialog';

const ActionAlertTemplate = ({
  handleCxScheduleAction,
  rowData,
  setSelectedProject,
}) => {
  const [declineDialogVisibility, setDeclineDialogVisibility] = useState(false);
  const [reason, setReason] = useState(null);
  return (
    <div className="flex gap-2 align-items-center">
      <PFButton
        tooltip="Accept"
        tooltipOptions={{ position: 'top' }}
        icon="pi pi-check"
        text
        rounded
        size="small"
        severity=" "
        onClick={() =>
          handleCxScheduleAction(rowData?.project_project_id, 'accept', reason)
        }
        pt={{
          icon: {
            className: 'text-green-600',
          },
        }}
      />
      <PFButton
        tooltip="Decline"
        tooltipOptions={{ position: 'top' }}
        icon="pi pi-times"
        style={{ color: StatusColors.PF_RED }}
        text
        rounded
        size="small"
        severity=" "
        onClick={() => {
          setSelectedProject(rowData);
          setDeclineDialogVisibility(true);
        }}
        pt={{
          icon: {
            className: 'text-red-600',
          },
        }}
      />
      {declineDialogVisibility && (
        <Dialog
          visible={declineDialogVisibility}
          header="Decline Reason"
          onHide={() => setDeclineDialogVisibility(false)}
        >
          <div className="flex align-items-center gap-2 flex-wrap w-full mt-1">
            <span className="p-float-label">
              <InputText
                name="declineInput"
                aria-describedby="declineInput"
                onChange={e => setReason(e.target.value)}
                className="w-full height-40"
              />
              <label htmlFor="declineInput">Reason</label>
            </span>
            <span>
              <PFButton
                label="Submit"
                size="small"
                className="height-40"
                disabled={!reason}
                onClick={() => {
                  handleCxScheduleAction(
                    rowData?.project_project_id,
                    'decline',
                    reason
                  );
                  setDeclineDialogVisibility(false);
                }}
              />
            </span>
          </div>
        </Dialog>
      )}
    </div>
  );
};
function CxScheduleAlert({ slideDrawer }) {
  const toast = useRef(null);
  const location = useLocation();
  const hideCxScheduling = !!localStorage.getItem('hide_cx_scheduling');
  const clientDetail = JSON.parse(localStorage.getItem('client_details'));
  const [cxScheduledProjects, setCxScheduledProjects] = useState();
  const [
    cxScheduledProjectsDialogVisibility,
    setCxScheduledProjectsDialogVisibility,
  ] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedProject, setSelectedProject] = useState();
  const [
    cxSchedulerValidationDialogVisibility,
    setCxSchedulerValidationDialogVisibility,
  ] = useState(false);

  const getCustomerRequestedProjects = async () => {
    setLoading(true);
    try {
      const apiResponse = await listCxScheduledProject();
      if (apiResponse?.status && apiResponse?.data) {
        setCxScheduledProjects(apiResponse?.data);
      }
    } catch (ex) {
      console.error(ex);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (!hideCxScheduling && axios.defaults.headers.common['client_id'])
      getCustomerRequestedProjects();
  }, [hideCxScheduling, axios.defaults.headers.common['client_id']]);
  const clientDetails = useMemo(() => {
    try {
      return JSON.parse(localStorage.getItem('client_details'));
    } catch (ex) {
      console.error(ex);
    }
  }, [localStorage.getItem('client_details')]);

  const cxScheduledProjectTemplate = rowData => {
    return loading ? (
      <Skeleton className="w-full" height="3rem" />
    ) : (
      <div
        className="flex gap-2 align-items-center text-primary text-semibold cursor-pointer"
        onClick={() =>
          window.open(`/project/view/${rowData?.project_project_id}`, '_blank')
        }
      >
        <span className="hover:underline">
          {rowData?.project_project_number}
        </span>
      </div>
    );
  };
  const cxScheduledProjectPoNumberTemplate = rowData => {
    return loading ? (
      <Skeleton className="w-full" height="3rem" />
    ) : (
      <span>{rowData?.project_po_number}</span>
    );
  };
  const cxScheduledTechnicianNameTemplate = rowData => {
    return loading ? (
      <Skeleton className="w-full" height="3rem" />
    ) : (
      <span>
        {rowData?.tUserMetaDetails_first_name}{' '}
        {rowData?.tUserMetaDetails_last_name}
      </span>
    );
  };
  const cxScheduledProjectStartTemplate = rowData => {
    return loading ? (
      <Skeleton className="w-full" height="3rem" />
    ) : (
      <span>
        {momentTz(rowData?.tProjectInstallers_date_scheduled_start).format(
          'MM-DD-YYYY hh:mm A'
        )}
      </span>
    );
  };
  const cxScheduledProjectEndTemplate = rowData => {
    return loading ? (
      <Skeleton className="w-full" height="3rem" />
    ) : (
      <span>
        {momentTz(rowData?.tProjectInstallers_date_scheduled_end).format(
          'MM-DD-YYYY hh:mm A'
        )}
      </span>
    );
  };
  const cxScheduledCustomerNameTemplate = rowData => {
    return loading ? (
      <Skeleton className="w-full" height="3rem" />
    ) : (
      <div className="flex flex-start gap-2 flex-wrap">
        <span>{rowData?.tCustomer_first_name}</span>
        <span>{rowData?.tCustomer_last_name}</span>
      </div>
    );
  };
  const cxScheduledCustomerAddressTemplate = rowData => {
    return loading ? (
      <Skeleton className="w-full" height="3rem" />
    ) : (
      <div className="flex flex-start gap-2 flex-wrap">
        {rowData?.tAddress_address1 ? (
          <span>{rowData.tAddress_address1}</span>
        ) : null}
        {rowData?.tAddress_address2 ? (
          <span>{rowData.tAddress_address2}</span>
        ) : null}
        {rowData?.tAddress_city ? <span>{rowData.tAddress_city}, </span> : null}
        {rowData?.tAddress_state ? (
          <span>{rowData.tAddress_state}, </span>
        ) : null}
        {rowData?.tAddress_zipcode ? (
          <span>Zipcode: {rowData.tAddress_zipcode}</span>
        ) : null}
      </div>
    );
  };
  const cxScheduledTechnicianTravelTimeTemplate = rowData => {
    return loading ? (
      <Skeleton className="w-full" height="3rem" />
    ) : (
      <div className="flex flex-start gap-2 flex-wrap">
        <span>{rowData?.project_travel_time}</span>
      </div>
    );
  };
  const cxScheduledTechnicianTravelDistanceTemplate = rowData => {
    return loading ? (
      <Skeleton className="w-full" height="3rem" />
    ) : (
      <div className="flex flex-start gap-2 flex-wrap">
        <span>{rowData?.project_travel_distance}</span>
      </div>
    );
  };
  const cxScheduledRequestRaisedOnTemplate = rowData => {
    return loading ? (
      <Skeleton className="w-full" height="3rem" />
    ) : (
      <div className="flex flex-start gap-2 flex-wrap">
        <span>
          {momentTz(
            rowData?.tCustomerScheduleExecutionSequence_created_at
          ).format('MM-DD-YYYY hh:mm A')}
        </span>
      </div>
    );
  };
  const onActionComplete = async projectId => {
    try {
      await getCustomerRequestedProjects();
      if (
        [
          `/project/view/${projectId}`,
          `/project/edit/${projectId}`,
          `/project/view/${projectId}/`,
          `/project/edit/${projectId}/`,
        ].includes(location.pathname)
      ) {
        window.location.reload();
      }
    } catch (ex) {
      console.error(ex);
    }
  };
  const handleCxScheduleAction = async (projectId, action, reason) => {
    setLoading(true);
    try {
      const updateResponse = await (action === 'accept'
        ? acceptCxScheduledProject(projectId)
        : declineCxScheduledProject(projectId, {
            reason,
          }));
      if (updateResponse?.status) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail:
            updateResponse?.message || `Project ${action}ed successfully.`,
          life: 3000,
        });
        if (action === 'decline' && updateResponse?.data?.executionId) {
          setSelectedProject(prev => ({
            ...prev,
            execution_id: updateResponse?.data?.executionId,
          }));
          setCxSchedulerValidationDialogVisibility(true);
        } else {
          setSelectedProject(null);
          await onActionComplete(projectId);
        }
      }
    } catch (ex) {
      try {
        if (
          'Network Error' === ex?.message ||
          [503, 504].includes(ex?.response?.status)
        ) {
          await handleCxScheduleAction(projectId, action, reason);
        } else {
          if (toast?.current?.show) {
            toast.current.show({
              severity: 'error',
              summary: 'Error',
              detail: ex?.response?.data?.message || 'something went wrong.',
              life: 3000,
            });
          }
        }
      } catch (ex) {
        console.error(ex);
      }
    } finally {
      setLoading(false);
    }
  };
  const cxScheduledActionTemplate = rowData => {
    return loading ? (
      <div className="flex gap-2 align-items-center">
        <Skeleton width="4rem" height="3rem" />
        <Skeleton width="4rem" height="3rem" />
      </div>
    ) : (
      <ActionAlertTemplate
        rowData={rowData}
        handleCxScheduleAction={handleCxScheduleAction}
        setSelectedProject={setSelectedProject}
      />
    );
  };
  const cxScheduletableColumn = [
    {
      header: 'Action',
      body: cxScheduledActionTemplate,
    },
    {
      field: 'project_project_number',
      header: 'Project Number',
      body: cxScheduledProjectTemplate,
    },
    {
      field: 'project_po_number',
      header: 'PO Number',
      body: cxScheduledProjectPoNumberTemplate,
    },
    {
      field: 'tUserMetaDetails_first_name',
      header: 'Technician Name',
      body: cxScheduledTechnicianNameTemplate,
    },
    {
      field: 'project_date_scheduled_start',
      header: 'Schedule Date and Time',
      body: cxScheduledProjectStartTemplate,
    },
    {
      field: 'project_date_scheduled_end',
      header: 'Project End Date and Time',
      body: cxScheduledProjectEndTemplate,
    },
    {
      field: 'tCustomer_first_name',
      header: 'Customer Name',
      body: cxScheduledCustomerNameTemplate,
    },
    {
      field: 'tAddress_address_id',
      header: 'Customer Address',
      body: cxScheduledCustomerAddressTemplate,
    },
    ...(clientDetails?.capture_live_location
      ? [
          {
            field: 'project_project_number',
            header: 'Travel Time',
            body: cxScheduledTechnicianTravelTimeTemplate,
          },
          {
            field: 'project_project_number',
            header: 'Travel Distance',
            body: cxScheduledTechnicianTravelDistanceTemplate,
          },
        ]
      : []),
    {
      field: 'tCustomerScheduleExecutionSequence_created_at',
      header: 'Request Raised On',
      body: cxScheduledRequestRaisedOnTemplate,
    },
  ];

  const isCxAlert = useMemo(() => {
    const alert = !hideCxScheduling && cxScheduledProjects?.count > 0;
    window.cxcScheduleAlert = alert;
    return alert;
  }, [hideCxScheduling, cxScheduledProjects]);

  const headerStyle = useMemo(() => {
    return lockGlobalBar();
  }, [clientDetail]);

  return isCxAlert ? (
    <header
      className="grid grid-nogutter align-items-center transition-ease-in-out transition-duration-500 sm:h-2rem"
      style={
        headerStyle
          ? {
              backgroundColor: StatusColors.PF_PALE_YELLOW,
              color: StatusColors.PF_GREEN,
              zIndex: 1000,
              position: 'fixed',
              top: window.cxcScheduleAlert && window.marqueeText ? 96 : 64,
              width: slideDrawer ? '100%' : 'calc(100% - 225px)',
              left: slideDrawer ? '0px' : '225px',
            }
          : {
              paddingLeft: slideDrawer ? '0px' : '230px',
              backgroundColor: StatusColors.PF_PALE_YELLOW,
              color: StatusColors.PF_GREEN,
            }
      }
    >
      <Toast ref={toast} />
      <div className="w-full flex justify-content-between align-items-center flex-wrap">
        <div className="flex">
          Customer has raised an Online Scheduling request. Please review and
          take the required action.&nbsp;
          <div
            className="text-primary text-bold cursor-pointer hover:underline"
            onClick={() => setCxScheduledProjectsDialogVisibility(true)}
          >
            Click here
          </div>
          &nbsp;to check details.
          <Dialog
            id="cxScheduledProjectsDialog"
            visible={cxScheduledProjectsDialogVisibility}
            onHide={() => setCxScheduledProjectsDialogVisibility(false)}
            header="Customer Scheduled Projects"
            style={{
              overflow: 'auto',
            }}
            className="w-12 lg:w-10"
          >
            <PFDataTable
              columns={cxScheduletableColumn}
              data={cxScheduledProjects?.result || []}
              size="small"
              className="w-full"
              emptyMessage="No request found"
              columnResizeMode="expand"
              resizableColumns={true}
              resizeHelper={true}
            />
          </Dialog>
        </div>
        {checkPermission(permissions?.autoScheduling?.hideBanner) && (
          <div className="flex mr-2">
            <i
              className="pi pi-times-circle cursor-pointer hover:text-base"
              onClick={() => {
                localStorage.setItem('hide_cx_scheduling', 1);
                setCxScheduledProjects(null);
              }}
            ></i>
          </div>
        )}
      </div>
      {cxSchedulerValidationDialogVisibility &&
        selectedProject?.project_project_id &&
        selectedProject?.execution_id && (
          <CxSchedulerValidationDialog
            cxSchedulerValidationDialogVisibility={
              cxSchedulerValidationDialogVisibility
            }
            setCxSchedulerValidationDialogVisibility={visible => {
              onActionComplete(selectedProject?.project_project_id);
              setSelectedProject(null);
              setCxSchedulerValidationDialogVisibility(visible);
            }}
            selectedProject={selectedProject}
          />
        )}
    </header>
  ) : (
    <></>
  );
}
export default CxScheduleAlert;
