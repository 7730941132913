import React, { useState, useEffect } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { Skeleton } from 'primereact/skeleton';

import PFInputSwitch from '../../../shared/PFPrime/PFInputSwitch';
import {
  getUserDeviceSettings,
  saveUserDeviceSettings,
} from '../../../shared/Topbar/Notification/Notification.service';
const KeyValueMapping = {
  sendInstallerNotes: 'Installer Note',
  sendStoreNotes: 'Store Notes',
  sendSms: 'SMS',
  sendEmails: 'Emails',
};

const typeMapping = {
  web: 'Web',
  android: 'Android',
  ios: 'iOS',
};

const PushNotificationSettings = () => {
  const [settingsByType, setSettingsByType] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const fetchDevices = async () => {
    try {
      const response = await getUserDeviceSettings(
        localStorage.getItem('user_id'),
        localStorage.getItem('client_id')
      );
      const tempResponse = {};
      Object.keys(response).forEach(val => {
        if (Object.keys(typeMapping).includes(val)) {
          if (tempResponse[val]) {
            Object.keys(tempResponse[val]).forEach(key => {
              tempResponse[val][key] = val[key] || tempResponse[val][key];
            });
          } else {
            tempResponse[val] = {
              sendInstallerNotes: response[val]?.sendInstallerNotes || false,
              sendStoreNotes: response[val]?.sendStoreNotes || false,
              sendSms: response[val]?.sendSms || false,
              sendEmails: response[val]?.sendEmails || false,
            };
          }
        }
      });
      setSettingsByType(tempResponse);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDevices();
  }, []);

  const handleSwitchChange = async (type, key, e) => {
    const newSettings = { ...settingsByType };
    newSettings[type][key] = e.value;
    setSettingsByType(newSettings);
    await saveUserDeviceSettings(
      localStorage.getItem('user_id'),
      localStorage.getItem('client_id'),
      type,
      {
        ...newSettings[type],
      }
    );
  };

  return (
    <div>
      <div className="flex justify-content-between py-2 mx-2 align-items-center">
        <h3>Configure Push Notifications</h3>
      </div>
      <div className="card mt-3">
        {isLoading && (
          <>
            <div className="flex justify-content-between mb-3">
              <div className="flex  justify-content-between">
                <Skeleton
                  width="10rem"
                  height="3rem"
                  className="m-2"
                ></Skeleton>
                <Skeleton
                  width="12rem"
                  height="3rem"
                  className="m-2"
                ></Skeleton>
              </div>
            </div>
            <div className="flex justify-content-between mb-3">
              <div className="flex  justify-content-between">
                <Skeleton
                  width="10rem"
                  height="3rem"
                  className="m-2"
                ></Skeleton>
                <Skeleton
                  width="12rem"
                  height="3rem"
                  className="m-2"
                ></Skeleton>
              </div>
            </div>
          </>
        )}
        {!isLoading && (
          <TabView>
            {Object.keys(settingsByType).map(type => (
              <TabPanel key={type} header={typeMapping[type]}>
                {Object.keys(settingsByType[type]).map(key => (
                  <div key={key} className="flex align-items-center mb-1 mt-1">
                    <PFInputSwitch
                      checked={settingsByType[type]?.[key]}
                      onChange={e => handleSwitchChange(type, key, e)}
                    />
                    <label className="mr-2 ml-2">{KeyValueMapping[key]}</label>
                  </div>
                ))}
              </TabPanel>
            ))}
          </TabView>
        )}
      </div>
    </div>
  );
};

export default PushNotificationSettings;
