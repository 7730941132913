import React, { useState, useEffect, useRef } from 'react';
import { CircularProgress } from '@material-ui/core';
import { Message } from 'primereact/message';
import { Toast } from 'primereact/toast';

import PFInputText from '../../../shared/PFPrime/PFInputText';
import PFButton from '../../../shared/PFPrime/PFButton';
import apiService from '../../../../services/api.service';
import { CONFIG } from '../../../../constants';

const MSHDLeadDetails = ({ projectId }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const toast = useRef(null);

  const fieldLabel = {
    back_office_order_number: {
      label: 'Back Office Order Number',
      isEditable: false,
    },
    mm_svcs_field1: { label: 'MM SVCS Field1', isEditable: false },
    mm_svcs_field2: { label: 'MM SVCS Field2', isEditable: false },
    mm_svcs_field3: { label: 'MM SVCS Field3', isEditable: false },
    mm_svcs_field4: { label: 'MM SVCS Field4', isEditable: false },
    mm_svcs_field5: { label: 'MM SVCS Field5', isEditable: false },
    mm_svcs_lead_batch_number: {
      label: 'MM SVCS Lead Batch Number',
      isEditable: false,
    },
    mm_svcs_need_ack: { label: 'MM SVCS Need Ack', isEditable: false },
    mm_svcs_rcr_flag: { label: 'MM SVCS RCR Flag', isEditable: false },
    mm_svcs_svs_source_code: {
      label: 'MM SVCS SVS Source Code',
      isEditable: false,
    },
    mm_svcs_svs_type_code: {
      label: 'MM SVCS SVS Type Code',
      isEditable: false,
    },
    mm_svcs_txn_number: { label: 'MM SVCS Txn Number', isEditable: false },
    mm_sv_integration_status: {
      label: 'MM SVCS Integration Status',
      isEditable: false,
    },
    mm_sv_max_amount_due: { label: 'MM SV Max Amount Due', isEditable: false },
    mm_sv_min_amount_due: { label: 'MM SV Min Amount Due', isEditable: false },
    mm_sv_preferred_contact_number: {
      label: 'MM SV Preferred Contact Number',
      isEditable: false,
    },
    mm_sv_svs_order_flag: { label: 'MM SV Order Flag', isEditable: false },
    mm_sv_order_flag: { label: 'MM SV Store Name', isEditable: false },
    mm_sv_store_name: { label: 'MM SV Store Name', isEditable: false },
    mm_sv_store_number: { label: 'MM SV Store Number', isEditable: false },
    mm_sv_order_number: { label: 'MM SV Order Number', isEditable: false },
    mm_sv_actual_end_date: {
      label: 'MM SV Actual End Date',
      isEditable: false,
    },
    mm_sv_actual_start_date: {
      label: 'MM SV Actual Start Date',
      isEditable: false,
    },
    sfi_lead_total: { label: 'SFI Lead Total', isEditable: false },
    sfi_mvendor: { label: 'SFI MVendor', isEditable: true, maxLength: 16 },
    sfim_vendor_name: { label: 'SFIM Vendor Name', isEditable: false },
    sfi_program_group_name: {
      label: 'SFI Program Group Name',
      isEditable: false,
    },
    consultation_date: { label: 'Consultation Date', isEditable: false },
    calculated_year_built: {
      label: 'Calculated Year Built',
      isEditable: false,
    },
    default_message_text: { label: 'Default Message Text', isEditable: false },
    lead_test_req_status: { label: 'Lead Test Req Status', isEditable: false },
    epa_key_rec_status: { label: 'EPA Key Rec Status', isEditable: false },
    epa_key_rec_comments: { label: 'EPA Key Rec Comments', isEditable: false },
  };

  useEffect(() => {
    const result = apiService.getWithDiffBaseUrl(
      `/api/measure-square/hd-lead-info?projectId=${projectId}`,
      undefined,
      CONFIG?.API_CRM_URL
    );

    result
      .then(res => {
        if (res.status) {
          if (Object.keys(res?.data).length > 0) {
            setData(res?.data);
          } else {
            setLoading(false);
          }

          setLoading(false);
        }
      })
      .catch(err => {
        setLoading(false);
        console.error(err);
      });
  }, []);
  const saveHdLeadInfo = async values => {
    try {
      setLoading(true);
      const response = await apiService.patchWithDiffBaseUrl(
        `/api/measure-square/hd-lead-info/sfi-mvendor`,
        {
          projectId: values?.project_id,
          sfiMVendor: values?.sfi_mvendor,
        },
        CONFIG?.API_CRM_URL
      );
      if (response && response?.status) {
        setData(response?.data);
        setLoading(false);
        toast?.current?.show({
          severity: 'success',
          detail: `${response?.message}`,
        });
      } else {
        toast?.current?.show({
          severity: 'error',
          detail: `${response?.message}`,
        });
      }
    } catch (error) {
      console.error(error);
      toast?.current?.show({
        severity: 'error',
        detail: `Something went wrong.`,
      });
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  const handleChange = e => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };
  const handleClick = () => {
    saveHdLeadInfo(data);
  };

  return (
    <div className="grid p-1">
      <Toast ref={toast} />
      {loading ? (
        <div className="col-12 md:col-12 lg:col-12">
          <div className="flex align-items-center justify-content-center">
            <CircularProgress />
          </div>
        </div>
      ) : (
        <>
          {data ? (
            <>
              <div className="col-12 md:col-12 lg:col-12 ">
                <div className="grid flex m-0 p-0">
                  <h3 className="col-12 md:col-6 lg:col-6 text-lg">Lead</h3>
                  <span className="col-12  md:col-6 lg:col-6 text-right">
                    <PFButton label="Save" outlined onClick={handleClick} />
                  </span>
                </div>
              </div>
              {Object.keys(data)?.map(key => {
                return (
                  <>
                    {fieldLabel[key] && (
                      <div className="col-12 md:col-3 lg:col-3 ">
                        <span className="p-float-label">
                          <PFInputText
                            id={key}
                            name={key}
                            type="text"
                            value={
                              fieldLabel[key]?.isEditable
                                ? data[key] || ''
                                : data[key] || 'NA'
                            }
                            disabled={!fieldLabel[key]?.isEditable}
                            onChange={e => {
                              handleChange(e);
                            }}
                            maxLength={fieldLabel[key]?.maxLength}
                          />
                          <label htmlFor="companyName" className="text-sm">
                            {fieldLabel[key]?.label || 'NA'}
                          </label>
                        </span>
                      </div>
                    )}
                  </>
                );
              })}
            </>
          ) : (
            <>
              <div className="col-12 md:col-12 lg:col-12 ">
                <Message text="Home Depot Project Lead not available" />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default MSHDLeadDetails;
