import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Message } from 'primereact/message';
import { Skeleton } from 'primereact/skeleton';

import PageHeader from '../../../shared/PageHeader/PageHeader';
import { getClientData } from '../service/TenantConfig.service';

import SchedulingComponent from './Scheduling';

const CxSchedulingConfig = () => {
  const { id: client_id = localStorage.getItem('client_id') } = useParams();
  const [loading, setLoading] = useState(false);
  const [clientDetail, setClientDetail] = useState();

  const accessBreadcrumb = [
    {
      text: 'Admin',
    },
    {
      link: '/admin/configuration',
      text: 'Company Configurations',
    },
    {
      link: `/admin/company-config/edit/${client_id}`,
      text:
        (!!clientDetail?.cx_scheduling_module && clientDetail?.client_name) ||
        '',
    },
    {
      text: `Customer Scheduling Configurations`,
    },
  ];
  const fetchData = async client_id => {
    setLoading(true);
    try {
      const response = await getClientData(client_id);
      if (response?.data?.client?.client_id?.length) {
        setClientDetail(response?.data?.client);
      }
    } catch (ex) {
      console.log(ex);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (client_id) {
      fetchData(client_id);
    }
  }, [client_id]);

  return (
    <div className="w-full">
      {loading ? (
        <PageHeader
          pageTitle={<Skeleton width="20rem" height="2rem" />}
          breadCrumbArray={accessBreadcrumb.map(() => ({
            text: <Skeleton width="10rem" height="2rem" />,
          }))}
        />
      ) : !!clientDetail?.cx_scheduling_module && clientDetail?.client_name ? (
        <PageHeader
          pageTitle={`Customer Scheduling Configurations`}
          breadCrumbArray={accessBreadcrumb}
        />
      ) : (
        <Message
          className="w-full flex justify-content-center"
          severity="warn"
          text={
            clientDetail?.cx_scheduling_module
              ? 'Client not found'
              : 'Permission denied'
          }
        />
      )}

      {!loading &&
        !!clientDetail?.cx_scheduling_module &&
        clientDetail?.client_name && (
          <div className="mt-4 w-full">
            <SchedulingComponent
              clientId={client_id}
              clientDetail={clientDetail}
            />
          </div>
        )}
    </div>
  );
};

export default CxSchedulingConfig;
