import { useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import { InputSwitch } from 'primereact/inputswitch';
import { BlockUI } from 'primereact/blockui';

import PFDialog from '../../shared/PFPrime/PFDialog';
import { getInstallerByProject } from '../ScheduleInfo/ScheduleInfo.service';

import {
  bulkUpdateLaborItemVisibility,
  deleteProjectItemUsers,
  updateLaborItem,
} from './ProjectItemsService';

const DialogHeader = ({
  selectedItem,
  setBlocked,
  toast,
  handleClose,
  techinicians,
}) => {
  const [isChecked, setIsChecked] = useState(
    !!selectedItem?.hide_from_all_technician
  );
  const [disabled, setDisabled] = useState(false);
  const onCheckUnCheck = async isHide => {
    setIsChecked(isHide);
    setBlocked(true);
    setDisabled(true);
    try {
      const response = await updateLaborItem(
        selectedItem.project_item_id,
        selectedItem.project_id,
        {
          item_id: selectedItem.item_id,
          item_quantity: selectedItem.item_quantity,
          cost_ea: selectedItem.cost_ea,
          edited_labor_cost_ea: selectedItem.edited_labor_cost_ea,
          sell_price: selectedItem.sell_price,
          delivery_type: selectedItem?.delivery_type || undefined,
          master_warehouse_id: selectedItem?.master_warehouse_id,
          project_item_desc: selectedItem?.project_item_desc || '',
          hide_from_all_technician: isHide ? 1 : 0,
        }
      );
      if (response?.data?.status) {
        if (Array.isArray(techinicians) && techinicians.length && isHide) {
          if (selectedItem?.project_item_id) {
            await deleteProjectItemUsers(
              selectedItem.project_id,
              selectedItem.project_item_id
            );
            toast.current.show({
              severity: 'success',
              summary: 'Hide From All Technicians updated successfully',
            });
            handleClose(true);
          }
        } else {
          toast.current.show({
            severity: 'success',
            summary: 'Hide From All Technicians updated successfully',
          });
          handleClose(true);
        }
      }
    } catch (ex) {
      console.error(ex);
      setIsChecked(!isHide);
      toast.current.show({
        severity: 'error',
        summary: 'Unable to update Hide From All Technicians',
      });
    } finally {
      setBlocked(false);
      setDisabled(false);
    }
  };
  return (
    <div className="w-full flex align-items-center justify-content-between flex-wrap">
      <span>Visibility Controls</span>
      <div className="flex align-items-center pr-10">
        <InputSwitch
          id="hideFromAllChecked"
          name="hideFromAllChecked"
          checked={isChecked}
          onChange={e => onCheckUnCheck(e.value)}
          disabled={disabled}
        />
        <label
          htmlFor="hideFromAllChecked"
          className={`ml-2 text-lg ${disabled ? 'text-400' : ''}`}
        >
          Hide From All Technicians
        </label>
      </div>
    </div>
  );
};
export default function VisibilityControlsDialog(props) {
  const allItems = props.laborItems;
  const selectedItemRef = useRef(null);
  const [selectedTechicians, setSelectedTechnicians] = useState([]);
  const [isBlocked, setIsBlocked] = useState(false);
  const [allTechicians, setAllTechnicians] = useState([]);
  const [installersList, setInstallerList] = useState(new Map());
  const toast = useRef(null);
  const pt = {
    headerCell: { className: 'bg-primary' },
  };
  useEffect(() => {
    if (
      props.selectedItem?.project_item_user?.installer_details?.meta?.first_name
        ?.length > 0
    ) {
      selectedItemRef.current = props.selectedItem;
    }
    if (selectedItemRef.current?.project_item_id) {
      const installersMap = new Map();
      const selectedTechiciansArr = [];
      for (let i = 0; i < allItems.length; i++) {
        const item = allItems[i];
        if (item.project_item_id === selectedItemRef.current.project_item_id) {
          installersMap.set(item.project_item_user?.installer_id, item);
          if (item?.project_item_user?.labor_item_visibility === 1) {
            selectedTechiciansArr.push(item);
          }
        }
      }
      setInstallerList(installersMap);
      setSelectedTechnicians(selectedTechiciansArr);
    }
  }, [props.selectedItem]);

  useEffect(() => {
    if (props?.isUnAssginItem) {
      const installerList = getInstallerByProject(props?.projectId);
      const installersMap = new Map();
      const selectedTechiciansArr = [];
      installerList
        .then(res => {
          res?.map(item => {
            const { first_name, last_name, middle_name } =
              item?.installer_detail?.user || [];
            const userObject = {
              project_item_user: {
                installer_id: item?.installer_detail?.installer_id,
                installer_details: {
                  meta: {
                    first_name: first_name,
                    middle_name: middle_name,
                    last_name: last_name,
                  },
                },
              },
            };
            installersMap.set(item?.installer_detail?.installer_id, userObject);
            selectedTechiciansArr.push(userObject);
          });
          setInstallerList(installersMap);

          setAllTechnicians(selectedTechiciansArr);

          if (props.selectedItem?.user_visibility_control?.length === 0) {
            setSelectedTechnicians(selectedTechiciansArr);
          } else {
            let installerIds = [];
            props?.selectedItem?.user_visibility_control.map(item => {
              if (item.installer_id && item?.visibility == 1) {
                installerIds.push(item.installer_id);
              }
            });
            const filteredB = selectedTechiciansArr?.filter(item =>
              installerIds.includes(item.project_item_user.installer_id)
            );
            setSelectedTechnicians(filteredB);
          }
        })
        .catch(err => console.error(err));
    }
  }, [props?.isUnAssginItem]);

  const handleClose = (status = false) => {
    if (status) {
      setAlert('success', 'Updated visibility settings', 1500);
      setTimeout(() => {
        props.onHide(status);
      }, 1500);
    } else {
      props.onHide(status);
    }
  };

  const installerTemplate = rowData => {
    const fname =
      rowData?.project_item_user?.installer_details?.meta?.first_name?.length >
      0
        ? rowData.project_item_user.installer_details.meta.first_name
        : '';
    const mname =
      rowData?.project_item_user?.installer_details?.meta?.middle_name?.length >
      0
        ? ' ' + rowData.project_item_user.installer_details.meta.middle_name
        : '';
    const lname =
      rowData?.project_item_user?.installer_details?.meta?.last_name?.length > 0
        ? ' ' + rowData.project_item_user.installer_details.meta.last_name
        : '';
    if (fname.length > 0 || mname.length > 0 || lname.length > 0) {
      return `${fname}${mname}${lname}`;
    } else {
      return `Technician Not assigned`;
    }
  };

  const columns = [
    {
      selectionMode: 'multiple',
      pt: pt,
    },
    {
      header: 'Technician',
      body: installerTemplate,
      pt: pt,
    },
  ];

  const setAlert = (severityValue, summaryValue, life = 1500) => {
    toast.current.show({
      severity: severityValue,
      summary: `${summaryValue}`,
      life,
    });
  };

  const updateVisibility = async () => {
    if (props?.isUnAssginItem) {
      const installers = selectedTechicians.map(
        item => item?.project_item_user?.installer_id
      );
      const bodyObject = allTechicians?.map(item => {
        return {
          installerId: item?.project_item_user?.installer_id,
          visibility: installers.includes(item?.project_item_user?.installer_id)
            ? 1
            : 0,
        };
      });

      const { project_item_id, project_id } = props.selectedItem;

      const response = await bulkUpdateLaborItemVisibility(
        project_item_id,
        project_id,
        {
          installersList: bodyObject,
          visibility: 1,
          isUnAssginItem: props?.isUnAssginItem ? 1 : 0,
        }
      );
      if (response?.status) {
        handleClose(true);
      } else {
        handleClose(false);
      }
    } else {
      const installers = selectedTechicians.map(
        item => item?.project_item_user?.installer_id
      );
      const visibility = 1;
      const response = await bulkUpdateLaborItemVisibility(
        selectedItemRef.current.project_item_id,
        selectedItemRef.current.project_id,
        {
          installersList: installers,
          visibility: visibility,
        }
      );
      if (response?.status) {
        handleClose(true);
      } else {
        handleClose(false);
      }
    }
  };

  const values = Array.from(installersList.values());
  return (
    <>
      <Toast ref={toast} />
      <PFDialog
        header={
          <DialogHeader
            selectedItem={props.selectedItem}
            setBlocked={setIsBlocked}
            toast={toast}
            handleClose={handleClose}
            selectedTechicians={selectedTechicians}
            techinicians={values}
          />
        }
        show={props.visible}
        hide={handleClose}
        className="w-5"
        pt={{
          header: { className: 'mb-0 pb-0' },
          content: { className: 'pt-2' },
        }}
        BodyComponent={
          <BlockUI blocked={isBlocked} className="w-full">
            <div className=" flex flex-column">
              <small className="mt-2">
                The current item will be visible to all technicians who have
                been selected.
              </small>
              <DataTable
                value={values}
                dataKey="project_item_user.installer_id"
                showGridlines={true}
                size="small"
                selectionMode="checkbox"
                selection={selectedTechicians}
                onSelectionChange={e => {
                  setSelectedTechnicians(e.value);
                }}
                className="mt-3"
              >
                {columns.map((item, index) => (
                  <Column key={index} {...item}></Column>
                ))}
              </DataTable>

              <div className="flex flex-row gap-1 mt-2 ml-auto">
                <Button
                  type="button"
                  label="Save"
                  severity="primary"
                  size="small"
                  onClick={updateVisibility}
                  className="mt-2"
                ></Button>
              </div>
            </div>
          </BlockUI>
        }
      ></PFDialog>
    </>
  );
}
