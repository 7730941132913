import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  data: [],
};

const routeMapSlice = createSlice({
  name: 'routeMapSlice',
  initialState: initialState,
  reducers: {
    routeUpdateMapData(state, { payload }) {
      state.isLoading = Object.prototype.hasOwnProperty.call(
        payload,
        'isLoading'
      )
        ? payload.isLoading
        : state.isLoading;
      state.data = Object.prototype.hasOwnProperty.call(payload, 'data')
        ? payload.data
        : state.data;
    },
  },
});

export const { routeUpdateMapData } = routeMapSlice.actions;

export const routeMapSliceReducer = routeMapSlice.reducer;
