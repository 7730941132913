import React, { useEffect, useMemo, useState } from 'react';
import {
  Grid,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  IconButton,
  Checkbox,
  Tooltip,
  Button,
} from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import { Document, Page, pdfjs } from 'react-pdf';
import { ConfirmDialog } from 'primereact/confirmdialog';

import { useConfirmDialogContext } from '../../../contexts/ConfirmDialog';
import permissions from '../../../config/permissions';
import { checkPermission, formatDateTimeMDYHM } from '../../../utils/Helpers';
import eyeOffIcon from '../../../assets/images/eye-off.svg';
import eyeOnIcon from '../../../assets/images/eye-show.svg';
import offlineIcon from '../../../assets/images/offline.svg';
import onlineIcon from '../../../assets/images/offline-green.svg';
import copyOnIcon from '../../../assets/images/copy-on.svg';
import copyOffIcon from '../../../assets/images/copy-off.svg';
import sohwCXIcon from '../../../assets/images/show-to-cx-on.svg';
import hideCxIcon from '../../../assets/images/show-to-cx-off.svg';
import technicianOffIcon from '../../../assets/images/technician-off.svg';
import technicianOnIcon from '../../../assets/images/technician-on.svg';
import downloadOffIcon from '../../../assets/images/download-icon-inactive.svg';
import deleteOffIcon from '../../../assets/images/delete-icon-inactive.svg';
import expandOffIcon from '../../../assets/images/expand-icon-inactive.svg';
import 'pdfjs-dist/build/pdf.worker.entry';
import { TruncateWithTooltip } from '../../shared/PFPrime/TruncateWithTooltip';
import PFDialog from '../../shared/PFPrime/PFDialog';
import { getPreSignedUrl } from '../../Admin/UserManagement/UserManagement.service';
import { highlightDocument } from '../ProjectInfo/helpers/helpers';

import { useStyles } from './Docs.styles.js';
import SmsIcon from './Components/SmsIcon';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url
).toString();

export default function ThumbnailCard({
  docDetails,
  handleClick,
  handleFileDownload,
  handleInstallerVisiblity,
  handleOfflineAvailability,
  installerVisibleDocuments,
  offlineDocuments,
  relatedOfflineDocumentsMode = false,
  installerVisible,
  reviewPermission,
  selectedJob,
  documentVisibleToInstaller,
  showDocStoreType,
  handleDocUpload,
  permissionCheckBox,
  handlePermissionCheckBox,
  copyRelatedDoc,
  handleSelectRelatedDoc = null,
  handleDeleteDoc,
  handleReviewAvailability,
  getReviewStatus = () => {},
  handleSelectBulkUploadDoc = () => {},
  selectBulkUpload = [],
  categoryVisibility = false,
  receivedFrom = '',
  epaDocumentsStyle = null,
  projectDetailsData,
  setOpenPopup,
  clientDetails,
  setConfirmDialog,
  setPhotoAlert,
  photoCount,
  orderConfigData,
}) {
  const { showConfirmationDialog } = useConfirmDialogContext();
  let documentName = '';
  try {
    if (docDetails) {
      if (docDetails.documetTypeId == 2)
        documentName = docDetails?.templateName;
      else documentName = docDetails?.documentName;
    }
  } catch (error) {
    console.error(error);
  }
  const [showDownload, setShowDownload] = useState(false);
  const classes = useStyles();
  const [isCheckBox, setIsCheckBox] = useState([]);
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  const [preSignedUrl, setPreSignedUrl] = useState();
  const [showSignatureConfirmation, setShowSignatureConfirmation] =
    useState(null);
  const getDocStoreTypeName = type => {
    let response = null;
    if (type === 'INTERNAL') {
      response = 'Internal Document';
    } else if (type === 'SOURCE') {
      response = 'Send to Source';
    } else if (type === 'COMPLETE') {
      response = 'Complete';
    } else if (type === 'NEEDS_REVIEW') {
      response = 'Needs Review';
    }
    return response;
  };

  useEffect(() => {
    if (permissionCheckBox) {
      setIsCheckBox(permissionCheckBox);
    }
  }, [permissionCheckBox]);

  const [isSendPermission, isCompletePermission, isDocDeletePermission] =
    useMemo(
      () => [
        checkPermission(permissions?.documentCenter?.sendToSourceDoc),
        checkPermission(permissions?.documentCenter?.completeDoc),
        checkPermission(permissions?.documentCenter?.deleteDoc),
      ],
      [permissions]
    );
  const showIsLeadRequiredAlertLowes = () => {
    if (
      (projectDetailsData?.epa_lead_assessment_required === 1 ||
        projectDetailsData?.epa_lead_remediation === 1) &&
      projectDetailsData?.project_lead_response_reason === null
    ) {
      return true;
    } else {
      return false;
    }
  };
  const showIsLeadRequiredAlertHD = () => {
    if (
      (projectDetailsData?.epa_lead_assessment_required === 1 ||
        projectDetailsData?.epa_lead_remediation === 1) &&
      projectDetailsData?.epa_override_reason_code_id === null
    ) {
      return true;
    } else {
      return false;
    }
  };

  const cardHTML = !docDetails ? null : (
    <div className="w-12">
      <ConfirmDialog
        group="declarative"
        visible={showSignatureConfirmation}
        onHide={() => setShowSignatureConfirmation(null)}
        message="This document requires a signature. Please confirm all signatures have been captured?"
        header="Confirmation"
        icon="pi pi-exclamation-triangle"
        accept={() => {
          handleDocUpload(
            docDetails?.projectId,
            docDetails?.documentId,
            docDetails?.documentName,
            showSignatureConfirmation,
            true
          );
        }}
        reject={() => {
          setShowSignatureConfirmation(null);
        }}
      />
      <div onClick={() => handleClick(docDetails?.docTemplateId)}>
        <CardActionArea
          onMouseEnter={e => {
            e.preventDefault();
            e.stopPropagation();
            setShowDownload(true);
          }}
          onMouseLeave={e => {
            e.preventDefault();
            e.stopPropagation();
            setShowDownload(false);
          }}
        >
          {!docDetails?.docTemplateId &&
            docDetails?.is_signature_required === 1 && (
              <IconButton classes={{ root: classes.signatureIcone }}>
                <img
                  src={
                    docDetails?.is_signed === 1
                      ? '/images/signature-green.svg'
                      : '/images/signature-grey.svg'
                  }
                  width={30}
                  height={30}
                  alt="signature"
                  style={{ zIndex: 999 }}
                />
              </IconButton>
            )}

          {showDownload && (
            <>
              {showDocStoreType && (
                <>
                  {isSendPermission && (
                    <Button
                      classes={{ root: classes.sendToSourceButton }}
                      color="primary"
                      variant="contained"
                      onClick={e => {
                        e.stopPropagation();
                        if (
                          docDetails?.is_signature_required === 1 &&
                          !docDetails?.is_signed
                        ) {
                          setShowSignatureConfirmation('SOURCE');
                        } else {
                          handleDocUpload(
                            docDetails?.projectId,
                            docDetails?.documentId,
                            docDetails?.documentName,
                            'SOURCE'
                          );
                        }
                      }}
                      className="ml-3 text-xs"
                    >
                      Send To Source
                    </Button>
                  )}
                  {isCompletePermission && (
                    <Button
                      classes={{ root: classes.completeButton }}
                      color="primary"
                      variant="contained"
                      onClick={e => {
                        e.stopPropagation();
                        if (clientDetails?.epa_guardrails_workflow === 1) {
                          const isLeadRequiredShowAlert =
                            projectDetailsData.source_system_id === 2
                              ? showIsLeadRequiredAlertHD()
                              : showIsLeadRequiredAlertLowes();
                          if (isLeadRequiredShowAlert) {
                            setOpenPopup(true);
                            return;
                          }
                        }
                        if (
                          clientDetails?.order_completion_validation
                            ?.enabled === 1 &&
                          orderConfigData !== null &&
                          orderConfigData?.count > photoCount
                        ) {
                          setPhotoAlert(true);
                          setConfirmDialog({
                            header: 'Alert',
                            title: `You must upload at least ${orderConfigData?.count} photo(s) to complete this job.`,
                            isOpen: true,
                            button2Text: 'Ok',
                          });
                          return;
                        } else {
                          handleDocUpload(
                            docDetails?.projectId,
                            docDetails?.documentId,
                            docDetails?.documentName,
                            'COMPLETE'
                          );
                        }
                      }}
                      className="text-xs mt-2 ml-3"
                    >
                      Complete
                    </Button>
                  )}
                </>
              )}
            </>
          )}

          <Card>
            {docDetails?.documentUrl ? (
              <Document
                file={docDetails?.documentUrl}
                className={`${docDetails?.documentUrl ? 'doc-height' : 'doc-template'}`}
                error={
                  <CardMedia
                    classes={{ root: classes.media }}
                    className="doc-height"
                    image={docDetails?.documentThumbnail}
                    title={documentName}
                  />
                }
              >
                <Page
                  pageNumber={1}
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                  height={165}
                />
              </Document>
            ) : (
              <CardMedia
                classes={{ root: classes.media }}
                image={docDetails?.documentThumbnail}
                title={documentName}
              />
            )}
            <CardContent>
              <Typography
                classes={{ root: classes.docName }}
                noWrap
                variant="body2"
                className="w-12 m-0"
              >
                {documentName}
              </Typography>
              <Typography variant="body2" noWrap>
                {docDetails.docCreatedDate}
              </Typography>
            </CardContent>
          </Card>
        </CardActionArea>
      </div>
    </div>
  );

  return docDetails ? (
    <>
      <div className={`grid px-2 w-14rem grid-nogutter`}>
        <Card
          className={`grid grid-nogutter surface-overlay border-2 ${highlightDocument(docDetails, epaDocumentsStyle?.border || 'border-gray-200')}`}
        >
          <div
            className={`col-2 col-md-2 col-lg-2  ${receivedFrom === 'template' || receivedFrom === 'relatedDocuments' ? 'h-14rem' : 'h-20rem'} pt-3`}
          >
            <div className="grid flex flex-row flex-wrap">
              {docDetails?.documetTypeId == 1 &&
                !relatedOfflineDocumentsMode && (
                  <div className="h-2rem">
                    <div className="col-12 col-md-12 col-lg-12 ml-1 ">
                      <Tooltip title={'Bulk Update'}>
                        <Grid>
                          <Checkbox
                            color="secondary"
                            onChange={event => {
                              handleSelectBulkUploadDoc(
                                event,
                                docDetails?.documentId
                              );
                            }}
                            checked={
                              selectBulkUpload?.copiedIds?.includes(
                                docDetails?.documentId
                              ) || false
                            }
                          />
                        </Grid>
                      </Tooltip>
                    </div>
                  </div>
                )}
              <div className="h-2rem">
                <div className="col-12 col-md-12 col-lg-12">
                  <Tooltip title="Expand">
                    <div className="py-1 ml-2 px-1">
                      <img
                        src={expandOffIcon}
                        className="cursor-pointer"
                        alt="Technician"
                        width={14}
                        height={14}
                        onClick={async () => {
                          await getPreSignedUrl(
                            () => null,
                            setPreSignedUrl,
                            docDetails?.documentUrl
                          );
                          setIsPreviewVisible(true);
                        }}
                      />
                    </div>
                  </Tooltip>
                </div>
              </div>

              <div className="h-2rem">
                <div className="col-12 col-md-12 col-lg-12">
                  <Tooltip title="Download">
                    <div className="py-1 ml-2">
                      <img
                        src={downloadOffIcon}
                        className="cursor-pointer"
                        alt="Technician"
                        width={18}
                        height={18}
                        onClick={() => {
                          handleFileDownload(docDetails.documentId);
                        }}
                      />
                    </div>
                  </Tooltip>
                </div>
              </div>
              {docDetails?.documetTypeId == 1 &&
                !relatedOfflineDocumentsMode &&
                installerVisible &&
                (!documentVisibleToInstaller ||
                  (documentVisibleToInstaller &&
                    docDetails?.hide_from_mobile_view === 1)) && (
                  <div className="h-2rem">
                    <div className="col-12 col-md-12 col-lg-12 mt-1">
                      <Tooltip title="Viewable to Technician">
                        <IconButton
                          classes={{
                            root: classes.checkboxRoot,
                          }}
                        >
                          {installerVisibleDocuments?.filter(
                            doc =>
                              doc.documentId === docDetails?.documentId &&
                              doc.isVisibleToInstaller
                          ).length > 0 ? (
                            <>
                              <img
                                src={technicianOnIcon}
                                className="ml-2 cursor-pointer"
                                alt="Technician"
                                width={18}
                                height={18}
                                onClick={() => {
                                  handleInstallerVisiblity(
                                    docDetails?.documentId
                                  );
                                }}
                              />
                            </>
                          ) : (
                            <img
                              src={technicianOffIcon}
                              className="ml-2  cursor-pointer"
                              alt="Technician"
                              width={18}
                              height={18}
                              onClick={() => {
                                handleInstallerVisiblity(
                                  docDetails?.documentId
                                );
                              }}
                              style={{ fill: 'red' }}
                            />
                          )}
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                )}
              {(relatedOfflineDocumentsMode
                ? selectedJob?.project_type == 'Measurement' && installerVisible
                : installerVisible) && (
                <div className="h-2rem">
                  <div className="col-12 col-md-12 col-lg-12 css-online">
                    <Tooltip
                      title={
                        relatedOfflineDocumentsMode &&
                        offlineDocuments?.filter(
                          doc => doc.isRelatedProjectDoucmentOffine
                        ).length >= 2
                          ? 'Only 2 documents are allowed to select '
                          : 'Viewable in Offline'
                      }
                    >
                      <Grid>
                        <IconButton
                          disabled={
                            relatedOfflineDocumentsMode &&
                            offlineDocuments?.filter(
                              doc => doc.isRelatedProjectDoucmentOffine
                            ).length >= 2 &&
                            !(
                              offlineDocuments?.filter(
                                doc =>
                                  docDetails.documentId === doc.documentId &&
                                  doc.isRelatedProjectDoucmentOffine
                              )?.length > 0
                            )
                          }
                        >
                          {relatedOfflineDocumentsMode ? (
                            <>
                              {offlineDocuments?.filter(
                                doc =>
                                  doc.documentId === docDetails?.documentId &&
                                  doc.isRelatedProjectDoucmentOffine
                              ).length > 0 ? (
                                <img
                                  src={onlineIcon}
                                  className="cursor-pointer"
                                  alt="Reviewed"
                                  width={14}
                                  height={14}
                                  onClick={() => {
                                    handleOfflineAvailability(
                                      docDetails?.documentId,
                                      relatedOfflineDocumentsMode
                                    );
                                  }}
                                  disabled={
                                    relatedOfflineDocumentsMode &&
                                    offlineDocuments?.filter(
                                      doc => doc.isRelatedProjectDoucmentOffine
                                    ).length >= 2
                                  }
                                />
                              ) : (
                                <img
                                  src={offlineIcon}
                                  className="cursor-pointer"
                                  alt="Reviewed"
                                  width={14}
                                  height={14}
                                  onClick={() => {
                                    handleOfflineAvailability(
                                      docDetails?.documentId,
                                      relatedOfflineDocumentsMode
                                    );
                                  }}
                                  disabled={
                                    relatedOfflineDocumentsMode &&
                                    offlineDocuments?.filter(
                                      doc => doc.isRelatedProjectDoucmentOffine
                                    ).length >= 2
                                  }
                                />
                              )}
                            </>
                          ) : (
                            <>
                              {offlineDocuments?.filter(
                                doc =>
                                  doc.documentId === docDetails?.documentId &&
                                  doc.isProjectDoucmentOffine
                              ).length > 0 ? (
                                <img
                                  src={onlineIcon}
                                  className="cursor-pointer"
                                  alt="Reviewed"
                                  width={14}
                                  height={14}
                                  onClick={() => {
                                    handleOfflineAvailability(
                                      docDetails?.documentId,
                                      relatedOfflineDocumentsMode
                                    );
                                  }}
                                  disabled={
                                    relatedOfflineDocumentsMode &&
                                    offlineDocuments?.filter(
                                      doc => doc.isRelatedProjectDoucmentOffine
                                    ).length >= 2
                                  }
                                />
                              ) : (
                                <img
                                  src={offlineIcon}
                                  className="cursor-pointer"
                                  alt="Reviewed"
                                  width={14}
                                  height={14}
                                  onClick={() => {
                                    handleOfflineAvailability(
                                      docDetails?.documentId,
                                      relatedOfflineDocumentsMode
                                    );
                                  }}
                                  disabled={
                                    relatedOfflineDocumentsMode &&
                                    offlineDocuments?.filter(
                                      doc => doc.isRelatedProjectDoucmentOffine
                                    ).length >= 2
                                  }
                                />
                              )}
                            </>
                          )}
                        </IconButton>
                      </Grid>
                    </Tooltip>
                  </div>
                </div>
              )}
              {handleSelectRelatedDoc && (
                <div className="h-2rem">
                  <div className="col-12 col-md-12 col-lg-12 ">
                    {copyRelatedDoc?.copiedIds?.includes(
                      docDetails?.documentId
                    ) ? (
                      <Tooltip title="Copy">
                        <img
                          src={copyOnIcon}
                          className="cursor-pointer py-1 ml-1"
                          alt="copy"
                          width={24}
                          height={24}
                          onClick={() =>
                            handleSelectRelatedDoc(docDetails?.documentId)
                          }
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Copy">
                        <img
                          src={copyOffIcon}
                          className="cursor-pointer py-1 ml-1"
                          alt="copy"
                          width={24}
                          height={24}
                          onClick={() =>
                            handleSelectRelatedDoc(docDetails?.documentId)
                          }
                        />
                      </Tooltip>
                    )}

                    <Tooltip title={`Copy`}>
                      <Checkbox
                        color="primary"
                        onChange={() =>
                          handleSelectRelatedDoc(docDetails?.documentId)
                        }
                        checked={copyRelatedDoc?.copiedIds?.includes(
                          docDetails?.documentId
                        )}
                        pt={{
                          box: {
                            className: 'border-noround w-10',
                          },
                        }}
                        className="hidden"
                      />
                    </Tooltip>
                  </div>
                </div>
              )}
              {installerVisible && (
                <div className="h-2rem">
                  <div className={`col-12 col-md-12 col-lg-12 mt-1} `}>
                    <Tooltip
                      title={
                        isCheckBox?.some(
                          doc =>
                            doc?.documentId === docDetails?.documentId &&
                            doc?.isCustomerProjectDoucmentVisible
                        )
                          ? 'Viewable to CX'
                          : 'Viewable to CX'
                      }
                    >
                      <Grid>
                        {isCheckBox?.some(
                          doc =>
                            doc?.documentId === docDetails?.documentId &&
                            doc?.isCustomerProjectDoucmentVisible
                        ) ? (
                          <img
                            src={sohwCXIcon}
                            className="cursor-pointer ml-2"
                            alt="copy"
                            width={18}
                            height={18}
                            onClick={() =>
                              handlePermissionCheckBox(docDetails?.documentId)
                            }
                          />
                        ) : (
                          <img
                            src={hideCxIcon}
                            className="cursor-pointer ml-2"
                            alt="copy"
                            width={18}
                            height={18}
                            onClick={() =>
                              handlePermissionCheckBox(docDetails?.documentId)
                            }
                          />
                        )}

                        <Checkbox
                          color="primary"
                          onChange={() => {
                            handlePermissionCheckBox(docDetails?.documentId);
                          }}
                          disabled={docDetails?.is_signed === 1 ? true : false}
                          checked={isCheckBox?.some(
                            doc =>
                              doc?.documentId === docDetails?.documentId &&
                              doc?.isCustomerProjectDoucmentVisible
                          )}
                          className="hidden"
                        />
                      </Grid>
                    </Tooltip>
                  </div>
                </div>
              )}

              {reviewPermission && (
                <div className="h-2rem">
                  <div className="col-12 col-md-12 col-lg-12">
                    <Tooltip
                      title={
                        <>
                          {docDetails?.reviewedBy?.first_name ? (
                            <>
                              <div>
                                <strong>Reviewed By: </strong>
                                {`${docDetails?.reviewedBy?.first_name || ''} ${docDetails?.reviewedBy?.last_name || ''}`}
                              </div>
                              <div>
                                <strong>Reviewed At: </strong>
                                {`${docDetails?.reviewedAt ? formatDateTimeMDYHM(docDetails?.reviewedAt) : ''}`}
                              </div>
                            </>
                          ) : (
                            'Mark as Reviewed'
                          )}
                        </>
                      }
                    >
                      <Grid>
                        {getReviewStatus(docDetails?.documentId) ||
                        docDetails?.reviewedBy?.first_name ? (
                          <img
                            src={eyeOnIcon}
                            className="ml-2 cursor-pointer"
                            alt="Reviewed"
                            width={16}
                            height={16}
                            onClick={() =>
                              !docDetails?.reviewedBy?.first_name &&
                              handleReviewAvailability(
                                docDetails?.documentId,
                                !getReviewStatus(docDetails?.documentId)
                              )
                            }
                          />
                        ) : (
                          <img
                            src={eyeOffIcon}
                            className="ml-2 cursor-pointer"
                            alt="Reviewed"
                            width={16}
                            height={16}
                            onClick={() =>
                              handleReviewAvailability(
                                docDetails?.documentId,
                                !getReviewStatus(docDetails?.documentId)
                              )
                            }
                          />
                        )}
                      </Grid>
                    </Tooltip>
                  </div>
                </div>
              )}
              {isDocDeletePermission &&
                docDetails?.documentId &&
                1 === docDetails?.documetTypeId && (
                  <div className="h-2rem">
                    <div className="col-12 col-md-12 col-lg-12">
                      <Tooltip title="Delete">
                        <img
                          src={deleteOffIcon}
                          className="ml-2 cursor-pointer"
                          alt="Reviewed"
                          width={16}
                          height={16}
                          onClick={() => {
                            showConfirmationDialog({
                              message: `This action would delete this attachment and cannot be rolled back. Would you like to continue?`,
                              header: 'Confirmation',
                              icon: 'pi pi-exclamation-triangle',
                              accept: () =>
                                handleDeleteDoc(
                                  docDetails?.projectId,
                                  docDetails?.documentId
                                ),
                            });
                          }}
                        />
                      </Tooltip>
                    </div>
                  </div>
                )}

              {docDetails?.is_signature_required && (
                <div className="h-2rem">
                  <div className={`col-12 col-md-12 col-lg-12 mt-1} `}>
                    <SmsIcon docDetails={docDetails} />
                  </div>
                </div>
              )}
            </div>

            <PFDialog
              header="Document Preview"
              show={isPreviewVisible}
              style={{ width: '70vw', height: '90vh' }}
              onHide={() => setIsPreviewVisible(false)}
              BodyComponent={
                <div className="h-full">
                  {docDetails?.documentUrl ? (
                    <iframe
                      src={preSignedUrl}
                      width="100%"
                      height="100%"
                      title="Document Preview"
                    />
                  ) : (
                    <Typography>No document available for preview</Typography>
                  )}
                </div>
              }
            ></PFDialog>
          </div>
          <div
            className={`col-10 col-md-10 col-lg-10   ${receivedFrom === 'template' || receivedFrom === 'relatedDocuments' ? 'h-14rem' : 'h-20rem'} relative py-0 pr-2`}
          >
            <div className="grid grid-nogutter">
              <div className="col-12 col-md-12 col-lg-12">
                <div className="grid px-2 w-12 grid-nogutter">
                  <div className="col-12 col-md-12 col-lg-12">
                    {showDocStoreType && (
                      <>
                        <div className="grid p-1 grid-nogutter flex flex-wrap w-12">
                          <div className="col-12 p-0 flex align-items-center justify-content-center">
                            <div className="font-bold text-xs">Status</div>
                          </div>
                          <div className="col-12 p-0 flex align-items-center justify-content-center">
                            <div
                              className={`font-normal text-xs ${epaDocumentsStyle?.color || ''}`}
                            >{`${docDetails?.documentStoreType ? getDocStoreTypeName(docDetails?.documentStoreType) : '-'}`}</div>
                          </div>
                        </div>
                      </>
                    )}

                    <>
                      {categoryVisibility && (
                        <>
                          {docDetails?.category && (
                            <div className="flex justify-content-center">
                              <hr className="w-5rem mb-0" />
                            </div>
                          )}
                          {docDetails?.category ? (
                            <>
                              <div className="grid p-1 grid-nogutter flex flex-wrap">
                                <div className="col-12 p-0 flex align-items-center justify-content-center">
                                  <div className="font-bold text-xs">
                                    Category
                                  </div>
                                </div>
                                <div className="col-12 p-0 flex align-items-center justify-content-center">
                                  <TruncateWithTooltip
                                    text={docDetails?.category?.category_name}
                                    maxWidth="200px"
                                    className="font-normal text-xs"
                                  />
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="flex justify-content-center">
                                <hr className="w-5rem mb-0" />
                              </div>
                              <div className="grid p-1 grid-nogutter flex flex-wrap">
                                <div className="col-12 p-0 flex align-items-center justify-content-center">
                                  <div className="font-bold text-xs">
                                    Category
                                  </div>
                                </div>

                                <div className="col-12 p-0 flex align-items-center justify-content-center">
                                  <div className="font-normal text-xs">{`-`}</div>
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-12 col-lg-12">
                <>{cardHTML}</>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </>
  ) : (
    <Card
      classes={{ root: classes.add }}
      className={`w-10rem mr-0 mb-0 h-14rem mr-3 ${
        checkPermission(permissions.viewEditProject.tabDocsAddProjectDocs)
          ? 'cursor-pointer'
          : 'cursor-auto'
      }`}
    >
      <CardContent classes={{ root: classes.addIcon }}>
        {checkPermission(permissions.viewEditProject.tabDocsAddProjectDocs) && (
          <AddIcon fontSize="large" className={classes.plusIcon} />
        )}
      </CardContent>
    </Card>
  );
}
