import { makeStyles } from '@material-ui/core/styles';

const PF_GREEN = '#2CAA42';
export const useStyles = makeStyles(theme => ({
  actionIcons: {
    padding: '0px 6px',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  actionAddButton: {
    width: '80%',
    paddingRight: '0px',
    color: '#0074d9',
    borderColor: '#0074d9',
    '& .MuiSvgIcon-root': {
      fill: PF_GREEN,
    },
    '& .MuiButton-endIcon': {
      paddingLeft: '8px',
    },
  },
  actionRemoveButton: {
    width: '80%',
    '& .MuiSvgIcon-root': {
      fill: theme.palette.secondary.main,
    },
  },
  actionIconSpan: {
    display: 'none',
  },
  dataTableWrapper: {
    display: 'table',
    tableLayout: 'fixed',
    width: '100%',
  },
  checkBoxRoot: {
    width: '36px',
  },
  backdropRoot: {
    color: '#fff',
    zIndex: theme.zIndex.drawer + 1,
  },
  actionButtonWrapper: {
    display: 'flex',
    gap: '10px',
  },
  filterButtonWrapper: {
    display: 'flex',
    gap: '20px',
  },
}));
