import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useHistory } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import moment from 'moment';
import { debounce } from 'lodash';

import TableColumnsLoader from '../../../shared/Loader/tableColumnsLoader';
import { useConfirmDialogContext } from '../../../../contexts/ConfirmDialog';
import {
  deleteQuoteTemplate,
  getQuoteTemplateList,
} from '../Service/QuotesTemplate.service';
import {
  ITemplateItem,
  ITemplateListingProps,
} from '../Interface/quoteTemplate.interface';

const TemplateListing: React.FC<ITemplateListingProps> = ({ client_id }) => {
  const history = useHistory();
  const toast = useRef<Toast>(null);
  const [listingData, setListingData] = useState<ITemplateItem[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const { showConfirmationDialog } = useConfirmDialogContext();

  const getQuoteTempsList = async (searchString?: string) => {
    try {
      setLoading(true);
      const response = await getQuoteTemplateList(
        client_id,
        '',
        searchString,
        ''
      );
      setListingData(response?.rows || []);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteClick = (quoteTempId: number) => {
    showConfirmationDialog({
      message: 'Do you want to delete this Quote Template',
      header: 'Confirmation',
      acceptLabel: 'Delete',
      rejectLabel: 'Cancel',
      className: 'm-0',
      accept: () => {
        deleteQuoteTemp(quoteTempId);
      },
    });
  };

  useEffect(() => {
    getQuoteTempsList();
  }, []);

  // deleteQuoteTemplate
  const deleteQuoteTemp = async (quoteTempId: number) => {
    setLoading(true);
    const deleteResponse = await deleteQuoteTemplate(quoteTempId, client_id);
    if (deleteResponse?.status) {
      toast.current?.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Template deleted successfully!',
      });
      getQuoteTempsList();
    }
  };

  const dataArray = listingData?.map(item => {
    return {
      action_buttons: (
        <>
          <i
            className="pi pi-pencil cursor-pointer text-primary mx-3"
            onClick={() =>
              history.push(
                `/admin/Edit/${item?.quotesTemplateId}/quote-template/${client_id}`
              )
            }
          ></i>
          <i
            className="pi pi-trash cursor-pointer text-primary"
            onClick={() => handleDeleteClick(item?.quotesTemplateId)}
          ></i>
        </>
      ),
      tempName: item?.title || '-',
      tag: item?.templateTag?.tagName || '-',
      status: item?.isActive === true ? 'Active' : 'Inactive',
      created_by: item?.createdBy
        ? `${item?.createdUser?.first_name ?? '-'} ${item?.createdUser?.last_name ?? '-'}`
        : '-',
      created_at: moment(item?.createdAt).format('MM-DD-YYYY hh:mm A') || '-',
      modified_by: item?.modifiedBy
        ? `${item?.modifiedUser?.first_name ?? '-'} ${item?.modifiedUser?.last_name ?? '-'}`
        : '-',
      modified_at: item?.modifiedBy
        ? moment(item?.updatedAt).format('MM-DD-YYYY hh:mm A')
        : '-',
    };
  });

  const columns = [
    {
      field: 'action_buttons',
      header: '',
      bodyStyle: { maxWidth: '10rem', padding: '0px' },
      headerStyle: { maxWidth: '10rem', padding: '0px' },
    },
    { field: 'tempName', header: 'Template' },
    { field: 'tag', header: 'Tag' },
    { field: 'status', header: 'Status' },
    { field: 'created_by', header: 'Created By' },
    { field: 'created_at', header: 'Created At' },
    { field: 'modified_by', header: 'Modified By' },
    { field: 'modified_at', header: 'Modified At' },
  ];

  const tableLoader = useMemo(() => {
    return TableColumnsLoader(columns, {
      rows: 10,
      isValue: false,
    });
  }, [loading]);

  const debouncedOnChange = useCallback(
    debounce(query => {
      getQuoteTempsList(query);
    }, 500),
    []
  );

  return (
    <div className="p-0">
      <Toast ref={toast} />
      <div className="flex justify-content-between my-2 w-full">
        <div className="my-1">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              placeholder="Search by Tag or Template"
              onChange={e => debouncedOnChange(e.target.value)}
            />
          </span>
        </div>
        <div className="mx-2 my-1">
          <Button
            icon="pi pi-plus"
            label="Create"
            onClick={() =>
              history.push(`/admin/Create/quote-template/${client_id}`)
            }
          />
        </div>
      </div>
      <div>
        <DataTable
          tableStyle={{ minWidth: '30rem' }}
          value={loading ? tableLoader : dataArray}
          paginator
          rows={10}
          rowsPerPageOptions={[10, 20, 30, 50]}
        >
          {columns.map((col, index) => (
            <Column
              key={index}
              field={col.field}
              header={col.header}
              bodyStyle={col.bodyStyle}
              headerStyle={col.headerStyle}
            />
          ))}
        </DataTable>
      </div>
    </div>
  );
};

export default TemplateListing;
