import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: true,
  credentialsData: [],
  credentialsTypeList: [],
  insuranceTypeList: [],
  isFileUploading: false,
};

const userFormCredentialsSlice = createSlice({
  name: 'userFormCredentials',
  initialState: initialState,
  reducers: {
    setUserCredentialsForm(state, { payload }) {
      state.isLoading = Object.prototype.hasOwnProperty.call(
        payload,
        'isLoading'
      )
        ? payload.isLoading
        : state.isLoading;
      state.credentialsData = Object.prototype.hasOwnProperty.call(
        payload,
        'credentialsData'
      )
        ? payload.credentialsData
        : state.credentialsData;
      state.credentialsTypeList = Object.prototype.hasOwnProperty.call(
        payload,
        'credentialsTypeList'
      )
        ? payload.credentialsTypeList
        : state.credentialsTypeList;
      state.insuranceTypeList = Object.prototype.hasOwnProperty.call(
        payload,
        'insuranceTypeList'
      )
        ? payload.insuranceTypeList
        : state.insuranceTypeList;
      state.isFileUploading = Object.prototype.hasOwnProperty.call(
        payload,
        'isFileUploading'
      )
        ? payload.isFileUploading
        : state.isFileUploading;
    },
  },
});

export const { setUserCredentialsForm } = userFormCredentialsSlice.actions;

export const userFormCredentialsReducer = userFormCredentialsSlice.reducer;
