// **** React Imports ****
import React, { useMemo, useState, useEffect, useRef } from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Image } from 'primereact/image';
import { Tooltip } from 'primereact/tooltip';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { Avatar } from 'primereact/avatar';
import { Card } from 'primereact/card';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { OverlayPanel } from 'primereact/overlaypanel';
import _ from 'lodash';

import {
  checkPermission,
  formatDateTimeMDYHM,
  momentTz,
} from '../../../utils/Helpers';
import permissions from '../../../config/permissions';
import {
  DOCUMENT,
  DOCUMENT_PHOTO_TYPE,
  SOURCE_SYSTEMS,
} from '../../../constants';
import eyeOffIcon from '../../../assets/images/eye-off.svg';
import eyeOnIcon from '../../../assets/images/eye-show.svg';
import deleteOffIcon from '../../../assets/images/delete-icon-inactive.svg';
import expandOffIcon from '../../../assets/images/expand-icon-inactive.svg';
import { getPreSignedUrl } from '../../Admin/UserManagement/UserManagement.service';
import { epaDocumentsStyle } from '../ProjectInfo/helpers/helpers';
import DocPhotosLoader from '../../shared/Loader/DocSkeleton.js';
import { useConfirmDialogContext } from '../../../contexts/ConfirmDialog';

import RotatePhotoComponent from './components/RotatePhotoComponent';
import { UploadActionContext } from './context/context';
import RelatedMeasurementPhotosCard from './components/RelatedMeasurementPhotosCard';
import {
  upLoadDocumentOrPhoto,
  getRelatedDocuments,
  applyInstallerVisibilityDocuments,
  deleteProjectDocument,
  bulkUpdateService,
  updateDocumentByDocumentId,
  getRelatedPOData,
  getDocuments,
  getTemplates,
} from './service/photo.service';
import UploadStoreTypeDialog from './components/UploadStoreTypeDialog';
import {
  getDocStoreTypeName,
  handleTooltipContent,
  showIsLeadRequiredAlertHD,
  showIsLeadRequiredAlertLowes,
} from './helper/helper';
import { TYPE_ID } from './constants/constant';
import {
  DocumentItem,
  DTypeItem,
  FileUploadItem,
  PhotoDetails,
  RelatedOfflineDocumentItem,
  RelatedPOResponse,
  SelectBulkUpload,
  SelectedProjectJob,
  TemplateItem,
  TPhotosContainerProps,
  UploadContextData,
} from './interface/Photos.interface';

import './styles/style.css';

const PhotosContainer = ({
  projectId,
  setReloadForm,
  projectLeadRequired,
  projectDetailsData,
  setOpenPopup,
  photoCount,
  setPhotoAlert,
  setConfirmDialog,
  setPhotoCount,
  orderConfigData,
}: TPhotosContainerProps) => {
  const { showConfirmationDialog } = useConfirmDialogContext();
  const [documents, setDocuments] = React.useState<DocumentItem[]>([]);
  const [relatedDocuments, setRelatedDocuments] = React.useState<
    DocumentItem[]
  >([]);
  const photoCard = useRef<OverlayPanel>(null);
  const imageInputRef = React.useRef<HTMLInputElement>(null);
  const toast = React.useRef<Toast>(null);
  const [photoError, setPhotoError] = React.useState('');
  const [relatedPOResponse, setRelatedPOResponse] =
    React.useState<RelatedPOResponse>();
  const [loading, setLoading] = useState(false);
  const [photosLoader, setPhotosLoader] = useState(false);
  const [reloadList, setReloadList] = useState(false);
  const [selectedJob, setSelectedJob] = React.useState<SelectedProjectJob>();
  const [relatedDocLoading, setRelatedDocLoading] = useState(false);
  const [photoDetailsToRotate, setPhotoDetailsToRotate] =
    useState<PhotoDetails | null>(null);
  const [relatedOfflineDocuments, setRelatedOfflineDocuments] = React.useState<
    RelatedOfflineDocumentItem[]
  >([]);
  const [relatedDocsPayload, setRelatedDocsPayload] = React.useState<
    RelatedOfflineDocumentItem[]
  >([]);
  const [relatedProjectDocsOfflineMode, setRelatedProjectDocsOfflineMode] =
    React.useState(false);
  const [
    isSendPermission,
    isCompletePermission,
    isPhotoDeletePermission,
    reviewPermission,
    addPhotoCategoryPermission,
    editPhotoCategory,
    tabPhotosAddPermission,
    installerVisible,
    isRelatedMeasurementPhotos,
  ] = useMemo(
    () => [
      checkPermission(permissions?.documentCenter?.sendToSourcePhoto),
      checkPermission(permissions?.documentCenter?.completePhoto),
      checkPermission(permissions?.documentCenter?.deletePhoto),
      checkPermission(permissions?.documentCenter?.viewPhotoReview),
      checkPermission(permissions?.documentCenter?.addPhotoCategory),
      !checkPermission(permissions?.documentCenter?.editPhotoCategory),
      checkPermission(permissions.viewEditProject.tabPhotosAdd),
      !checkPermission(
        permissions?.userManagement?.userManagementModifyInstaller
      ),
      checkPermission(permissions?.viewEditProject?.relatedMeasurementPhotos),
    ],
    []
  );

  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [showHoverButton, setShowHoverButton] = useState<number | null>(null);
  const [toolTipDocument, setToolTipDocument] = useState<DocumentItem>();
  const [preSignedUrl, setPreSignedUrl] = useState();
  const [completeButtonDisable, setCompleteButtonDisable] = useState(false);
  const [
    completeDisableForOrderValidation,
    setCompleteDisableForOrderValidation,
  ] = useState(false);

  const [uploadContextValue, setUploadContextValue] = useState({
    show: false,
    permissions: {
      installerVisible: installerVisible,
      reviewPermission: reviewPermission,
      addCategoryPermission: addPhotoCategoryPermission,
      editCategoryPermission: editPhotoCategory,
    },

    document_type_id: 3,
    onShow: () => {
      setUploadContextValue(preValue => ({
        ...preValue,
        show: true,
      }));
    },
    onHide: () => {
      setUploadContextValue(preValue => ({
        ...preValue,
        show: false,
      }));
      setCompleteButtonDisable(false);
      uploadContextValue?.resetContext();
    },
    data: {
      document_store_type: 'INTERNAL',
      is_installer_visibility: 0,
      is_proj_docu_visibility: 0,
      is_customer_visible: 0,
      reviewed_status: 0,
      document_category_id: '',
    },
    handleChange: (data: Partial<UploadContextData>) => {
      setUploadContextValue(preValue => ({
        ...preValue,
        data: { ...preValue?.data, ...data },
      }));
    },
    resetContext: () => {
      setUploadContextValue(preValue => ({
        ...preValue,
        req_from: '',
        data: {
          document_store_type: 'INTERNAL',
          is_installer_visibility: 0,
          is_proj_docu_visibility: 0,
          is_customer_visible: 0,
          reviewed_status: 0,
          document_category_id: '',
        },
      }));
    },
  });

  const [selectBulkUpload, setSelectBulkUpload] = useState<SelectBulkUpload>({
    copiedIds: [],
    count: 0,
  });
  const [reviewIds, setReviewIds] = useState<number[]>([]);
  const documentPhotoTypeId = DOCUMENT_PHOTO_TYPE?.PHOTO;
  const clientDetails = JSON.parse(
    localStorage.getItem('client_details') || ''
  );

  //service call
  const getListOfDocumentsData = async (
    projectId: number,
    documentPhotoTypeId: number
  ) => {
    setLoading(true);
    try {
      const [responseDocuments, responseTemplates] = await Promise.all([
        getDocuments(projectId, documentPhotoTypeId),
        getTemplates(projectId),
      ]);
      const documentsData =
        responseDocuments && Array.isArray(responseDocuments?.items)
          ? responseDocuments?.items
              ?.filter((doc: DTypeItem) => doc?.document_type_id !== 2)
              .map((document: DTypeItem) => {
                return {
                  projectId: document.project_id,
                  documentId: document.document_id,
                  documetTypeId: document.document_type_id,
                  documentName: document.document_name,
                  documentUrl: document.aws_doc_url,
                  docTemplateId: document.document_template?.template_id,
                  documentThumbnail: `/thumbnail.PNG`,
                  documentStoreType: document?.document_store_type || null,
                  docCreatedDate: momentTz(document.created_at).format(
                    'MM-DD-YYYY hh:mm A'
                  ),
                  docCreatedBy: document?.created_by_full_name || null,
                  is_installer_visibility: document.is_installer_visibility,
                  is_proj_docu_visibility: document.is_proj_docu_visibility,
                  is_rel_proj_docu_visibility:
                    document.is_rel_proj_docu_visibility,
                  is_customer_visible: document?.is_customer_visible,
                  is_signed: document?.is_signed,
                  is_signature_required: document?.is_signature_required,
                  category: document?.category,
                  reviewedBy: document?.reviewed_by,
                  reviewedAt: document?.reviewed_at,
                  epa_lead_details: document?.epa_lead_details || null,
                  is_source_uploaded: document?.is_source_uploaded || 0,
                  is_highlight_document: document?.is_highlight_document || 0,
                  is_signed_sms_sent: document?.is_signed_sms_sent,
                  is_sms_loading: false,
                  hide_from_mobile_view: document?.hide_from_mobile_view,
                };
              })
          : [];
      const templatesData =
        responseTemplates && Array.isArray(responseTemplates?.items)
          ? responseTemplates?.items?.map((template: TemplateItem) => {
              return {
                projectId,
                documentId: template.template_id,
                documetTypeId: 2,
                templateName: template.template_name,
                documentName: template.template_file,
                documentUrl: template.template_aws_url,
                docTemplateId: template.template_id,
                documentThumbnail: `/thumbnail.PNG`,
                docCreatedDate: momentTz(template.created_at).format(
                  'MM-DD-YYYY hh:mm A'
                ),
                epa_lead_details: template?.epa_lead_details || null,
                is_source_uploaded: template?.is_source_uploaded || 0,
                is_highlight_document: template?.is_highlight_document || 0,
              };
            })
          : [];

      setDocuments([...documentsData, ...templatesData]);
    } catch (error) {
      console.error(error);
      setLoading(false);
      setDocuments([]);
    } finally {
      setLoading(false);
    }
  };

  const getRelatedDocumentsData = async (
    projectId: number,
    documentPhotoTypeId: number
  ) => {
    try {
      setRelatedDocLoading(true);
      const response = await getRelatedDocuments(
        projectId,
        documentPhotoTypeId
      );
      if (response) {
        setRelatedDocLoading(false);
        setRelatedDocuments(response);
        setRelatedOfflineDocuments(
          response
            ?.filter((doc: DocumentItem) => doc.documetTypeId === 3)
            ?.map((document: DocumentItem) => {
              return {
                documentId: document.documentId,
                isRelatedProjectDoucmentOffine:
                  document.is_rel_proj_docu_visibility ? true : false,
              };
            })
        );
      }
    } catch (error) {
      console.error(error);
      setRelatedDocLoading(false);
      setRelatedDocuments([]);
      setRelatedOfflineDocuments([]);
    } finally {
      setRelatedDocLoading(false);
    }
  };
  const updateDocumentById = async (
    projectId: number,
    documentId: number,
    documentName: string,
    type: string,
    documents: DocumentItem[],
    fileType: string,
    isSigned = undefined
  ) => {
    setLoading(true);
    try {
      let response = null;
      response = await updateDocumentByDocumentId(
        projectId,
        documentId,
        documentName,
        type,
        isSigned
      );
      let docData: DocumentItem | null = null;
      if (response?.data) {
        docData = {
          projectId: response?.data?.project_id,
          documentId: response?.data?.document_id,
          documetTypeId: response?.data?.document_type_id,
          documentName: response?.data?.document_name,
          documentUrl: response?.data?.aws_doc_url,
          docTemplateId: response?.data?.document_template?.template_id || null,
          documentThumbnail: `/thumbnail.PNG`,
          documentStoreType: response?.data?.document_store_type || null,
          docCreatedDate: momentTz(response?.data?.created_at).format(
            'MM-DD-YYYY hh:mm A'
          ),
          docCreatedBy: response?.data?.created_by_full_name || null,
          is_installer_visibility: response?.data?.is_installer_visibility,
          is_proj_docu_visibility: response?.data?.is_proj_docu_visibility,
          is_rel_proj_docu_visibility:
            response?.data?.is_rel_proj_docu_visibility,
        };
        const objIndex = documents.findIndex(
          (obj: DocumentItem) =>
            obj.documentId === documentId && obj.projectId === projectId
        );
        documents[objIndex] = docData;
        setDocuments(documents);
        setReloadForm(true);
        toast.current?.show({
          severity: 'success',
          summary: 'Success',
          detail: `Project ${fileType} status has been updated successfully.`,
        });
      }
    } catch (error) {
      console.error(error);
      toast.current?.show({
        severity: 'error',
        summary: 'Warning',
        detail: `Failed to update store type.`,
      });
    } finally {
      setLoading(false);
    }
  };
  const upLoadDocumentOrPhotos = async (
    projectId: number,
    uploadObj: FileUploadItem
  ) => {
    setLoading(true);
    try {
      const response = await upLoadDocumentOrPhoto(projectId, uploadObj);
      if (response?.data) {
        setReloadList(true);
        let successMessage;
        switch (uploadObj.document_type_id) {
          case 1:
            successMessage = 'Project document added successfully';
            break;
          case 2:
            successMessage = 'Template document added successfully';
            break;
          case 3:
            successMessage = 'Photo added successfully';
            break;
          default:
            successMessage = 'Document added successfully';
        }
        toast.current?.show({
          severity: 'success',
          summary: 'Success',
          detail: `${successMessage} `,
        });
      }
      setReloadForm(true);
    } catch (error) {
      console.error(error);
      toast.current?.show({
        severity: 'error',
        summary: 'Warning',
        detail: `Unable to upload the file. Please try again!`,
      });
    } finally {
      setLoading(false);
      if (typeof setPhotosLoader === 'function') {
        setPhotosLoader(false);
      }
    }
  };

  const getRelatedPOList = async (projectId: number) => {
    try {
      const response = await getRelatedPOData(projectId);

      if (response) {
        setRelatedPOResponse(response);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getRelatedPOList(projectId);
    getListOfDocumentsData(projectId, documentPhotoTypeId);
    if (selectedJob?.project_id) {
      handleJobSelection(selectedJob);
    }
  }, [reloadList]);

  useEffect(() => {
    if (documents) {
      setPhotoCount(documents?.filter(doc => doc.documetTypeId == 3).length);
    }
    if (
      clientDetails?.order_completion_validation?.enabled === 1 &&
      orderConfigData !== null
    ) {
      if (photoCount >= orderConfigData?.count) {
        setCompleteDisableForOrderValidation(false);
      } else {
        setCompleteDisableForOrderValidation(true);
      }
    }
  }, [documents, photoCount, orderConfigData?.count]);

  const handleUpload = (files: File[], documentTypeId: number) => {
    setReloadList(false);
    setLoading(false);
    setPhotosLoader(true);
    const uploadObj: {
      file: { file: File; document_name: string }[];
      document_type_id: number;
    } = {
      file: [],
      document_type_id: documentTypeId,
      ...uploadContextValue?.data,
    };

    Object.values(files).forEach((file: File) => {
      uploadObj?.file?.push({ file: file, document_name: file?.name });
    });

    try {
      upLoadDocumentOrPhotos(projectId, uploadObj).then(() => {
        uploadContextValue?.onHide();
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleJobSelection = (project: SelectedProjectJob) => {
    getRelatedDocumentsData(project.project_id, documentPhotoTypeId);
    setSelectedJob(project);
    setRelatedDocsPayload([]);
  };

  const applyVisibilityDocuments = () => {
    setReloadList(false);
    let queryParam = 'visibilityAndOffline';
    if (relatedProjectDocsOfflineMode) {
      queryParam = 'relatedProjectDocuments';
    }
    if (relatedDocsPayload?.length > 0) {
      applyInstallerVisibilityDocuments(
        projectId,
        queryParam,
        relatedProjectDocsOfflineMode ? relatedDocsPayload : []
      );
    }
  };

  const handleOfflineAvailability = (document_id: number) => {
    const relOfflineDocs = _.cloneDeep(relatedOfflineDocuments);
    const relDocumentsPayload = _.cloneDeep(relatedDocsPayload);

    if (
      relOfflineDocs.filter(doc => doc.documentId == document_id).length > 0
    ) {
      relOfflineDocs.filter(
        doc => doc?.documentId == document_id
      )[0].isRelatedProjectDoucmentOffine = !relOfflineDocs.filter(
        doc => doc.documentId == document_id
      )[0].isRelatedProjectDoucmentOffine;
    }
    if (
      relDocumentsPayload.filter(doc => doc.documentId == document_id)?.length >
        0 &&
      relOfflineDocs.filter(doc => doc.documentId == document_id).length > 0
    ) {
      relDocumentsPayload.filter(
        doc => doc.documentId == document_id
      )[0].isRelatedProjectDoucmentOffine = !relDocumentsPayload.filter(
        doc => doc.documentId == document_id
      )[0].isRelatedProjectDoucmentOffine;
    } else if (
      relDocumentsPayload.filter(doc => doc.documentId == document_id)?.length >
        0 &&
      relOfflineDocs.filter(doc => doc.documentId != document_id).length > 0
    ) {
      relDocumentsPayload.splice(
        relDocumentsPayload.findIndex(doc => doc.documentId == document_id),
        1
      );
    } else {
      relDocumentsPayload.push({
        documentId: document_id,
        isRelatedProjectDoucmentOffine: relOfflineDocs.filter(
          doc => doc.documentId == document_id
        )[0].isRelatedProjectDoucmentOffine,
      });
    }
    setRelatedProjectDocsOfflineMode(true);
    setRelatedDocsPayload(relDocumentsPayload);
    setRelatedOfflineDocuments(relOfflineDocs);
  };

  const handleDocUpload = (
    projectId: number,
    documentId: number,
    documentName: string,
    type: string
  ) => {
    setReloadList(false);
    updateDocumentById(
      projectId,
      documentId,
      documentName,
      type,
      documents,
      'Photo'
    ).then(() => {
      setReloadList(true);
    });
  };

  const handleDeletePhoto = async (projectId: number, id: number) => {
    try {
      await deleteProjectDocument(projectId, id, 'photo');
      return true;
    } catch (ex) {
      console.error(ex);
    } finally {
      setReloadList(prev => !prev);
    }
  };

  const handleSelectBulkUploadDoc = (
    event: { target?: { checked?: boolean } },
    documentId: number
  ) => {
    const idsObject = [];
    documents?.forEach(item => {
      if (item?.documetTypeId === 3) {
        idsObject?.push(item?.documentId);
      }
    });
    setSelectBulkUpload(prevState => {
      if (event && event?.target && event.target.checked) {
        return {
          ...prevState,
          copiedIds: Array.from(
            new Set([...(prevState?.copiedIds || []), documentId])
          ),
          count: idsObject?.length || 0,
        };
      } else {
        return {
          ...prevState,
          copiedIds:
            prevState?.copiedIds?.filter(id => id !== documentId) || [],
          count: idsObject?.length || 0,
        };
      }
    });
  };

  const handleSelectAllBulkUpload = (event: {
    target?: { checked?: boolean };
  }) => {
    const idsObject: number[] = [];
    documents?.forEach(item => {
      if (item?.documetTypeId === 3) {
        idsObject?.push(item?.documentId);
        return true;
      }
    });

    if (event && event?.target && event.target.checked) {
      setSelectBulkUpload(prevState => ({
        ...prevState,
        copiedIds: idsObject,
        count: idsObject?.length || 0,
      }));
    } else {
      setSelectBulkUpload(prevState => ({
        ...prevState,
        copiedIds: [],
        count: idsObject?.length || 0,
      }));
    }
  };

  const bulkUpdateCall = () => {
    setReloadList(false);
    const basePayload = {
      ...uploadContextValue?.data,
      document_category_id:
        uploadContextValue?.data?.document_category_id || null,
    };

    if (uploadContextValue?.data?.document_store_type) {
      basePayload.document_store_type =
        uploadContextValue?.data?.document_store_type || '';
    }
    const payload = {
      document_ids: selectBulkUpload?.copiedIds || [],
      ...basePayload,
    };

    bulkUpdateService(projectId, payload)
      .then(res => {
        if (res?.status) {
          setSelectBulkUpload({ copiedIds: [], count: 0 });
          uploadContextValue?.onHide();
          setReloadList(true);
        }
      })
      .catch(err => {
        console.error(err);
        toast.current?.show({
          severity: 'error',
          detail: `Something went wrong`,
        });
      });
  };

  const reviewedCall = () => {
    setReloadList(false);
    const payload = { document_ids: reviewIds || [], reviewed_status: 1 };

    bulkUpdateService(projectId, payload)
      .then(res => {
        if (res?.status) {
          setReviewIds([]);
          setReloadList(true);
        }
      })
      .catch(err => {
        console.error(err);
        toast.current?.show({
          severity: 'error',
          detail: `Something went wrong`,
        });
      });
  };

  const handleReviewedPhoto = (documentId: number) => {
    if (documentId) {
      setReviewIds(prevState => {
        if (!prevState?.includes(documentId)) {
          return Array.from(new Set([...(prevState || []), documentId]));
        } else {
          return prevState?.filter(id => id !== documentId) || [];
        }
      });
    }
  };

  const handlePreview = async (imageUrl: string) => {
    await getPreSignedUrl(() => null, setPreSignedUrl, imageUrl);
    setIsDialogVisible(true);
  };

  return (
    <div>
      <ConfirmDialog />
      <Toast ref={toast} />
      <UploadActionContext.Provider
        value={{ uploadContextValue, setUploadContextValue }}
      >
        <UploadStoreTypeDialog
          type={'Photo'}
          docInputRef={imageInputRef}
          bulkUpdateHandler={bulkUpdateCall}
          projectDetailsData={projectDetailsData}
          setCompleteButtonDisable={setCompleteButtonDisable}
          completeButtonDisable={completeButtonDisable}
          photoCount={photoCount}
          orderConfigData={orderConfigData}
          completeDisableforOrderValidation={completeDisableForOrderValidation}
          setCompleteDisableforOrderValidation={
            setCompleteDisableForOrderValidation
          }
        />
      </UploadActionContext.Provider>
      <div className="grid w-full gap-4">
        <div className="col-12 m-0 p-0">
          <div className="shadow-1 border-1 border-200">
            <div className="mb-2 col-12">
              <div className="grid flex-row justify-content-between px-4 pt-3">
                <div className="flex justify-content-center pt-3">
                  <span className="pr-1">
                    <Checkbox
                      onChange={event => handleSelectAllBulkUpload(event)}
                      checked={
                        selectBulkUpload?.count > 0 &&
                        selectBulkUpload?.count ==
                          selectBulkUpload?.copiedIds?.length
                      }
                      tooltip="Select All For Bulk Update"
                      tooltipOptions={{ position: 'bottom' }}
                    />
                  </span>

                  <h3>Photos</h3>
                </div>

                <div>
                  <Button
                    onClick={() => {
                      uploadContextValue?.onShow();
                      setUploadContextValue(preValue => ({
                        ...preValue,
                        req_from: 'bulk_upload',
                      }));
                    }}
                    disabled={
                      (selectBulkUpload?.copiedIds || [])?.length > 0
                        ? false
                        : true
                    }
                    label="Bulk Update"
                    size="small"
                    type="button"
                  ></Button>

                  <Button
                    className="ml-2"
                    onClick={reviewedCall}
                    disabled={(reviewIds || [])?.length > 0 ? false : true}
                    label="Apply"
                    size="small"
                    type="button"
                  ></Button>
                </div>
              </div>
            </div>
            <div className="flex flex-column  w-full overflow-auto mt-2">
              <div className="flex px-3 py-0 my-3 mx-0 overflow-auto relative w-full">
                {tabPhotosAddPermission && (
                  <div className="mr-4">
                    {photosLoader ? (
                      <div className="flex justify-items-center">
                        <ProgressSpinner />
                      </div>
                    ) : (
                      <div className="mt-0">
                        <input
                          className="hidden"
                          id="icon-button-file"
                          type="file"
                          multiple
                          ref={imageInputRef}
                          onChange={event => {
                            const files = event.target.files;
                            let isValid = true;
                            let errorMessage = '';
                            if (!files) return;
                            if (files && files?.length > 5) {
                              isValid = false;
                              errorMessage =
                                'Please select up to 5 files only.';
                            } else {
                              for (let i = 0; i < files.length; i++) {
                                const file = files[i];
                                if (file?.type?.split('/')?.[0] !== 'image') {
                                  isValid = false;
                                  errorMessage =
                                    'Only image files are allowed.';
                                  break;
                                } else {
                                  const fileExtension =
                                    file.name.split('.').pop() ?? '';
                                  if (
                                    ['svg'].includes(
                                      fileExtension.toLowerCase()
                                    )
                                  ) {
                                    isValid = false;
                                    errorMessage =
                                      'System does not accept SVG files.';
                                    break;
                                  }
                                }
                              }
                            }

                            if (isValid) {
                              const fileArray = Array.from(files);
                              handleUpload(fileArray, 3);
                              setPhotoError('');
                            } else {
                              uploadContextValue?.onHide();
                              setPhotoError(errorMessage);
                              if (imageInputRef.current) {
                                imageInputRef.current.value = '';
                              }
                            }
                          }}
                        />
                        <span onClick={uploadContextValue?.onShow}>
                          <Avatar
                            className={`w-12rem h-17rem bg-white border-dotted border-300 cursor-pointer border-2 mt-2`}
                          >
                            <i className="pi pi-plus"></i>
                          </Avatar>
                        </span>
                        {photoError && (
                          <p className="ml-2 text-red-600">{photoError}</p>
                        )}
                      </div>
                    )}
                  </div>
                )}
                {loading ? (
                  <DocPhotosLoader numberOfSkeletons={6} height="322px" />
                ) : (
                  documents &&
                  documents.filter(doc => doc.documetTypeId == 3).length > 0 &&
                  documents
                    .filter(doc => doc.documetTypeId == 3)
                    .map(document => (
                      <div
                        className={`grid grid-nogutter mx-3 msurface-overlay border-1 shadow-1  ${
                          epaDocumentsStyle(document, projectLeadRequired) ||
                          'border-gray-200'
                        } `}
                        key={document?.documentId}
                      >
                        <div className="grid w-14rem grid-nogutter">
                          <div className="col-12 col-md-12 col-lg-12 p-0">
                            <div className="grid grid-nogutter">
                              <div className="col-2 col-md-2 col-lg-2 p-0 m-0">
                                <div className="h-2rem">
                                  <div className="col-12 col-md-12 col-lg-12">
                                    <Checkbox
                                      color="secondary"
                                      onChange={event => {
                                        handleSelectBulkUploadDoc(
                                          event,
                                          document?.documentId
                                        );
                                      }}
                                      checked={
                                        selectBulkUpload?.copiedIds?.includes(
                                          document?.documentId
                                        ) || false
                                      }
                                      tooltip="Bulk Update"
                                      tooltipOptions={{ position: 'bottom' }}
                                    />
                                  </div>
                                </div>
                                <div className="h-2rem">
                                  <div className="col-12 col-md-12 col-lg-12 mx-1">
                                    <span className="pl-2">
                                      <img
                                        src={expandOffIcon}
                                        className="cursor-pointer -ml-2 expand_icon"
                                        alt="Technician"
                                        width={13}
                                        height={13}
                                        onClick={() =>
                                          handlePreview(document?.documentUrl)
                                        }
                                        data-pr-tooltip="Expand"
                                        data-pr-position="bottom"
                                      />
                                      <Tooltip target=".expand_icon" />
                                    </span>
                                  </div>
                                </div>
                                {reviewPermission && (
                                  <div className="grid grid-nogutter">
                                    <div className="h-2rem">
                                      <div className="col-12 col-md-12 col-lg-12">
                                        <div
                                          data-pr-tooltip={
                                            document?.reviewedBy?.first_name
                                              ? `Reviewed By: ${document?.reviewedBy?.first_name || ''} ${document?.reviewedBy?.last_name || ''} 
                                              Reviewed At: ${document?.reviewedAt ? formatDateTimeMDYHM(document?.reviewedAt) : ''}`
                                              : 'Mark as Reviewed'
                                          }
                                          data-pr-position="bottom"
                                        >
                                          {reviewIds?.includes(
                                            document?.documentId
                                          ) ||
                                          document?.reviewedBy?.first_name ? (
                                            <img
                                              src={eyeOnIcon}
                                              className="m-0 cursor-pointer"
                                              alt="Reviewed"
                                              width={18}
                                              height={18}
                                              onClick={() =>
                                                !document?.reviewedBy
                                                  ?.first_name
                                                  ? handleReviewedPhoto(
                                                      document?.documentId
                                                    )
                                                  : {}
                                              }
                                            />
                                          ) : (
                                            <img
                                              src={eyeOffIcon}
                                              className="m-0 cursor-pointer"
                                              alt="Reviewed"
                                              width={18}
                                              height={18}
                                              onClick={() =>
                                                handleReviewedPhoto(
                                                  document?.documentId
                                                )
                                              }
                                            />
                                          )}
                                        </div>
                                        <Tooltip target="[data-pr-tooltip]" />
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {isPhotoDeletePermission && (
                                  <div className="h-2rem">
                                    <div className="col-12 col-md-12 col-lg-12">
                                      <div className="flex justify-content-center">
                                        <img
                                          src={deleteOffIcon}
                                          className="m-0 cursor-pointer"
                                          alt="Reviewed"
                                          width={16}
                                          height={16}
                                          onClick={() => {
                                            showConfirmationDialog({
                                              message: `This action would delete this attachment and cannot be rolled back. Would you like to continue?`,
                                              header: 'Confirmation',
                                              icon: 'pi pi-exclamation-triangle',
                                              accept: () =>
                                                handleDeletePhoto(
                                                  document?.projectId,
                                                  document?.documentId
                                                ),
                                            });
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )}

                                <Dialog
                                  header="Image Preview"
                                  visible={isDialogVisible}
                                  style={{
                                    width: '50vw',
                                    height: '70vh',
                                  }}
                                  onHide={() => setIsDialogVisible(false)}
                                >
                                  <div className="h-full">
                                    {preSignedUrl && (
                                      <Image
                                        alt="Preview Image"
                                        src={preSignedUrl}
                                        imageStyle={{
                                          verticalAlign: 'middle',
                                          margin: '0 8px 0 0',
                                          width: '100%',
                                          height: '100%',
                                          padding: '0px',
                                        }}
                                        preview={false}
                                      />
                                    )}
                                  </div>
                                </Dialog>
                              </div>

                              <div className="col-10 col-md-10 col-lg-10 p-0 m-0">
                                <div className="grid grid-nogutter w-full">
                                  <Card
                                    className="py-0 w-full"
                                    pt={{
                                      body: { className: 'py-0 px-0' },
                                      content: { className: 'm-0 pt-1' },
                                    }}
                                  >
                                    <div className="col-12 col-md-12 col-lg-12 p-0">
                                      {document?.documentStoreType ? (
                                        <>
                                          <div className="grid p-1 grid-nogutter flex flex-wrap">
                                            <div className="col-12 p-0 flex align-items-center justify-content-center">
                                              <div className="font-bold text-xs ">
                                                Status
                                              </div>
                                            </div>
                                            <div className="col-12 p-0 flex align-items-center justify-content-center">
                                              <div className="font-normal text-xs">{`${getDocStoreTypeName(document?.documentStoreType)}`}</div>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <div className="grid p-1 grid-nogutter flex flex-wrap">
                                          <div className="col-12 p-0 flex align-items-center justify-content-center">
                                            <div className="font-bold text-xs ">
                                              Status
                                            </div>
                                          </div>
                                          <div className="col-12 p-0 flex align-items-center justify-content-center">
                                            <div className="font-normal text-xs">{`-`}</div>
                                          </div>
                                        </div>
                                      )}

                                      <>
                                        {document?.category && (
                                          <div className="flex justify-content-center">
                                            <hr className="w-5rem mb-0" />
                                          </div>
                                        )}
                                        {document?.category ? (
                                          <>
                                            <div className="grid p-1 grid-nogutter flex flex-wrap">
                                              <div className="col-12 p-0 flex align-items-center justify-content-center">
                                                <div className="font-bold text-xs">
                                                  Category
                                                </div>
                                              </div>
                                              <div className="col-12 p-0 flex align-items-center justify-content-center">
                                                <div className="font-normal text-xs">{` ${document?.category?.category_name}`}</div>
                                              </div>
                                            </div>
                                          </>
                                        ) : (
                                          <>
                                            <div className="flex justify-content-center">
                                              <hr className="w-5rem mb-0" />
                                            </div>
                                            <div className="grid p-1 grid-nogutter flex flex-wrap">
                                              <div className="col-12 p-0 flex align-items-center justify-content-center">
                                                <div className="font-bold text-xs">
                                                  Category
                                                </div>
                                              </div>

                                              <div className="col-12 p-0 flex align-items-center justify-content-center">
                                                <div className="font-normal text-xs">{`-`}</div>
                                              </div>
                                            </div>
                                          </>
                                        )}
                                      </>
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-12 p-0">
                                      <div className="grid m-0">
                                        <>
                                          <OverlayPanel ref={photoCard}>
                                            {toolTipDocument
                                              ? handleTooltipContent(
                                                  toolTipDocument
                                                )
                                              : 'No details available'}
                                          </OverlayPanel>
                                          <div
                                            onMouseEnter={e => {
                                              e.preventDefault();
                                              e.stopPropagation();
                                              setShowHoverButton(
                                                document?.documentId
                                              );
                                              setToolTipDocument(document);
                                              if (photoCard.current) {
                                                photoCard.current.toggle(e);
                                              }
                                            }}
                                            onMouseLeave={e => {
                                              e.preventDefault();
                                              e.stopPropagation();
                                              setShowHoverButton(null);
                                              if (photoCard.current) {
                                                photoCard.current.hide();
                                              }
                                            }}
                                            className="w-full photoToolTipCard"
                                          >
                                            {showHoverButton ===
                                              document?.documentId && (
                                              <div className="hover-buttons w-full">
                                                {isSendPermission && (
                                                  <Button
                                                    className=""
                                                    onClick={e => {
                                                      e.stopPropagation();
                                                      handleDocUpload(
                                                        document?.projectId,
                                                        document?.documentId,
                                                        document?.documentName,
                                                        DOCUMENT.DOCUMENT_SOURCE
                                                      );
                                                    }}
                                                    label="Send To Source"
                                                    size="small"
                                                    type="button"
                                                  ></Button>
                                                )}
                                                {isCompletePermission && (
                                                  <Button
                                                    color="primary"
                                                    className="mt-2 ml-2"
                                                    type="button"
                                                    onClick={e => {
                                                      e.stopPropagation();
                                                      if (
                                                        clientDetails?.epa_guardrails_workflow ===
                                                        TYPE_ID.ONE
                                                      ) {
                                                        const isLeadRequiredShowAlert =
                                                          (projectDetailsData &&
                                                            projectDetailsData.source_system_id) ===
                                                          SOURCE_SYSTEMS.HOME_DEPOT
                                                            ? showIsLeadRequiredAlertHD(
                                                                projectDetailsData
                                                              )
                                                            : showIsLeadRequiredAlertLowes(
                                                                projectDetailsData
                                                              );
                                                        if (
                                                          isLeadRequiredShowAlert
                                                        ) {
                                                          setOpenPopup(true);
                                                          return;
                                                        }
                                                      }
                                                      if (
                                                        clientDetails
                                                          ?.order_completion_validation
                                                          ?.enabled === 1 &&
                                                        orderConfigData !==
                                                          null &&
                                                        orderConfigData?.count >
                                                          photoCount
                                                      ) {
                                                        setPhotoAlert(true);
                                                        setConfirmDialog({
                                                          header: 'Alert',
                                                          title: `You must upload at least ${orderConfigData?.count} photo(s) to complete this job.`,
                                                          isOpen: true,
                                                          button2Text: 'Ok',
                                                        });
                                                      } else {
                                                        handleDocUpload(
                                                          document?.projectId,
                                                          document?.documentId,
                                                          document?.documentName,
                                                          DOCUMENT.DOCUMENT_COMPLETE
                                                        );
                                                      }
                                                    }}
                                                    label="Complete"
                                                    size="small"
                                                  ></Button>
                                                )}
                                              </div>
                                            )}

                                            <div className="w-full text-center">
                                              {document?.documentUrl ? (
                                                <Image
                                                  alt={document?.documentName}
                                                  src={document?.documentUrl}
                                                  imageStyle={{
                                                    verticalAlign: 'middle',
                                                    margin: '0 8px 0 0',
                                                    objectFit: 'contain',
                                                  }}
                                                  onClick={() => {
                                                    setPhotoDetailsToRotate({
                                                      documentStoreType:
                                                        document?.documentStoreType ||
                                                        '',
                                                      documentUrl:
                                                        document?.documentUrl,
                                                      documentName:
                                                        document?.documentName,
                                                      documentId:
                                                        document?.documentId,
                                                      projectId:
                                                        document?.projectId,
                                                    });
                                                  }}
                                                  pt={{
                                                    image: {
                                                      className:
                                                        'w-full h-10rem cursor-pointer object-fit-contain  cursor-pointer',
                                                    },
                                                  }}
                                                />
                                              ) : null}
                                            </div>
                                          </div>
                                        </>
                                      </div>
                                    </div>
                                  </Card>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                )}
              </div>
            </div>
          </div>
        </div>

        {isRelatedMeasurementPhotos && (
          <div className="col-12 m-0 p-0">
            <div className="shadow-1 border-1 border-200">
              <div className="grid">
                <div className="col-12 mt-3 px-4 py-2">
                  <div className="grid flex-row justify-content-between align-content-center w-full mt-1">
                    <div className="col-12 lg:col-6 md:col-6">
                      <h3>Related Measurement Photos</h3>
                    </div>
                    <div className="grid col-12 lg:col-6 md:col-6 flex justify-content-end px-2">
                      <span className="w-full lg:w-7 md:w-7">
                        <Dropdown
                          placeholder="Select Job"
                          options={relatedPOResponse?.items || []}
                          optionLabel="label"
                          onChange={event => {
                            handleJobSelection(event.value);
                          }}
                          value={selectedJob}
                          filter
                          className="w-full"
                          pt={{
                            list: {
                              className: 'text-sm text-overflow-ellipsis',
                            },
                          }}
                        />
                      </span>
                      {relatedDocuments?.length > 0 &&
                        installerVisible &&
                        selectedJob?.project_type == 'Measurement' && (
                          <span className="ml-2 mr-1">
                            <Button
                              onClick={applyVisibilityDocuments}
                              label="Apply"
                              size="small"
                              type="button"
                            ></Button>
                          </span>
                        )}
                    </div>
                  </div>
                </div>

                {relatedDocLoading || loading ? (
                  <div className="w-full p-3">
                    <DocPhotosLoader numberOfSkeletons={6} />
                  </div>
                ) : (
                  <div className="w-full p-3">
                    <div className="flex flex-row flex-nowrap m-0 p-1 overflow-auto relative h-16rem mt-2">
                      {relatedDocuments &&
                      relatedDocuments.filter(doc => doc.documetTypeId === 3)
                        .length > 0 ? (
                        relatedDocuments
                          .filter(doc => doc.documetTypeId === 3)
                          .map(document => (
                            <RelatedMeasurementPhotosCard
                              key={document.documentId}
                              docDetails={document}
                              handleOfflineAvailability={
                                handleOfflineAvailability
                              }
                              relatedOfflineDocumentsMode={true}
                              offlineDocuments={relatedOfflineDocuments}
                              installerVisible={installerVisible}
                              selectedJob={selectedJob}
                              handlePreview={handlePreview}
                            />
                          ))
                      ) : (
                        <div className="grid justify-content-center w-full">
                          <span className="mt-10 font-bold">
                            No Measurement Photos found!
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      {photoDetailsToRotate && (
        <RotatePhotoComponent
          photoDetails={photoDetailsToRotate}
          setPhotoDetailsToRotate={setPhotoDetailsToRotate}
          setReloadList={setReloadList}
        />
      )}
    </div>
  );
};

export default PhotosContainer;
