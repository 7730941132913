import { Image } from 'primereact/image';
import { Tooltip } from 'primereact/tooltip';
import { Card } from 'primereact/card';
import { Avatar } from 'primereact/avatar';

import offlineIcon from '../../../../assets/images/offline.svg';
import onlineIcon from '../../../../assets/images/offline-green.svg';
import expandOffIcon from '../../../../assets/images/expand-icon-inactive.svg';
import { TRelatedMeasurementProps } from '../interface/Photos.interface';

export default function RelatedMeasurementPhotosCard({
  docDetails,
  handleOfflineAvailability,
  relatedOfflineDocumentsMode = false,
  offlineDocuments,
  installerVisible,
  selectedJob,
  handlePreview,
}: TRelatedMeasurementProps) {
  const cardHTML = !docDetails ? null : (
    <div className="w-full h-full">
      <div className="shadow-1 border-1 border-100 h-full w-full">
        <div
          onMouseEnter={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onMouseLeave={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
          className="photoCard m-0 p-0 h-full"
        >
          <div className="flex flex-column justify-content-center align-items-center p-2 mx-0 h-full w-full">
            <Image
              alt={docDetails?.documentName}
              src={docDetails?.documentUrl}
              imageStyle={{
                verticalAlign: 'middle',
                margin: '0 0 0 0',
                objectFit: 'contain',
              }}
              pt={{
                image: {
                  className:
                    'w-10rem h-10rem  object-fit-contain  cursor-pointer',
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );

  return docDetails ? (
    <>
      <div className="grid px-2 w-15rem h-13rem grid-nogutter">
        <Card
          pt={{
            body: { className: 'p-0 h-full' },
            content: { className: 'm-0 p-0 flex h-full' },
          }}
          className="border-gray-200 surface-overlay border-1"
        >
          <div className={`col-2 col-md-2 col-lg-2 px-0`}>
            <>
              <div className="h-2rem p-2">
                <img
                  src={expandOffIcon}
                  className="cursor-pointer expand_icon"
                  alt="Technician"
                  width={12}
                  height={12}
                  onClick={() => handlePreview(docDetails?.documentUrl)}
                  data-pr-tooltip="Expand"
                  data-pr-position="bottom"
                />

                <Tooltip target=".expand_icon" />
              </div>

              {(relatedOfflineDocumentsMode
                ? selectedJob?.project_type == 'Measurement' && installerVisible
                : installerVisible) && (
                <>
                  <div
                    data-pr-tooltip={
                      relatedOfflineDocumentsMode &&
                      offlineDocuments?.filter(
                        doc => doc.isRelatedProjectDoucmentOffine
                      ).length >= 2
                        ? 'Only 2 photos could be saved offline'
                        : 'Viewable in Offline'
                    }
                    data-pr-position="bottom"
                  >
                    <>
                      {offlineDocuments?.filter(
                        doc =>
                          doc.documentId === docDetails?.documentId &&
                          doc.isRelatedProjectDoucmentOffine
                      ).length > 0 ? (
                        <div className="h-2rem p-2">
                          <img
                            src={onlineIcon}
                            className="cursor-pointer"
                            alt="Reviewed"
                            width={14}
                            height={14}
                            onClick={() => {
                              handleOfflineAvailability(docDetails?.documentId);
                            }}
                            // disabled={
                            //   relatedOfflineDocumentsMode &&
                            //   offlineDocuments?.filter(
                            //     doc => doc.isRelatedProjectDoucmentOffine
                            //   ).length >= 2
                            // }
                          />
                        </div>
                      ) : (
                        <div className="h-2rem p-2">
                          <img
                            src={offlineIcon}
                            className="cursor-pointer"
                            alt="Reviewed"
                            width={14}
                            height={14}
                            onClick={() => {
                              handleOfflineAvailability(docDetails?.documentId);
                            }}
                            // disabled={
                            //   relatedOfflineDocumentsMode &&
                            //   offlineDocuments?.filter(
                            //     doc => doc.isRelatedProjectDoucmentOffine
                            //   ).length >= 2
                            // }
                          />
                        </div>
                      )}
                    </>
                  </div>
                  <Tooltip target="[data-pr-tooltip]" />
                </>
              )}
            </>
          </div>
          <div className={`col-10 col-md-10 col-lg-10 p-0 h-full`}>
            {cardHTML}
          </div>
        </Card>
      </div>
    </>
  ) : (
    <div className="grid mx-2 w-15rem h-13rem grid-nogutter">
      <Card
        pt={{
          body: { className: 'p-0 w-15rem h-full' },
          content: { className: 'm-0 p-0  h-full' },
        }}
        className="border-gray-200  border-1 border-dashed"
      >
        <Avatar className={`w-full h-full bg-white  cursor-pointer `}>
          <i className="pi pi-plus"></i>
        </Avatar>
      </Card>
    </div>
  );
}
