import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Grid, Button } from '@material-ui/core';
import { Toast } from 'primereact/toast';

import PageHeader from '../../shared/PageHeader/PageHeader';
import { URL_CONSTANTS } from '../../../constants/urlConstants';

import PdfViewerComponent from './PdfViewerComponent';
import {
  getDocumentById,
  createProjectDocumentFromTemplateDocument,
  updateProjectDocument,
} from './Docs.service';
import { useStyles } from './ViewProjectDocument.styles';

const ViewProjectDocument = () => {
  const { projectId, documentId, documentType } = useParams();
  let history = useHistory();
  const classes = useStyles();
  const [documentData, setDocumentData] = useState();

  const [editMode, setEditMode] = useState(false);
  const [reloadPdf, setReloadPdf] = useState(false);
  // To show the error
  const accessToken = localStorage
    .getItem('cubejsAuthorizationToken')
    ?.split(' ')?.[1];
  const queryParams = new URLSearchParams(window.location.search);
  const docType = queryParams.get('type') || null;

  const toastRef = React.useRef(null);
  function setAlert(severity, title, message) {
    toastRef.current.show({
      severity: severity,
      summary: title,
      detail: message,
      life: 3000,
    });
  }

  const viewProjectDocumentBreadcrumb = [
    {
      text: 'Project Management',
    },
    {
      link: '/project/scheduling',
      text: 'Scheduling',
    },
    {
      link: '/project/readyToScheduleReport',
      text: 'Ready to Schedule Reports',
    },
    {
      link: `/project/view/${projectId}`,
      text: 'Project Details',
    },
    {
      link: '',
      text: 'View Document',
    },
  ];

  React.useEffect(() => {
    getDocumentById(
      projectId,
      documentId,
      setDocumentData,
      () => {},
      setAlert,
      documentType
    );
  }, [reloadPdf]);

  const saveDocument = (fileBlob, storeType = '', hdDocNumber, llDocType) => {
    if (documentType == 2) {
      createProjectDocumentFromTemplateDocument(
        projectId,
        fileBlob,
        documentData.template_file,
        () => {},
        setAlert,
        history,
        storeType,
        hdDocNumber,
        llDocType
      );
    } else if (documentType == 1) {
      updateProjectDocument(
        storeType,
        projectId,
        documentId,
        fileBlob,
        () => {},
        setAlert,
        history,
        setReloadPdf,
        setEditMode,
        hdDocNumber,
        llDocType
      );
    }
  };
  return (
    <>
      <Toast ref={toastRef} position="center" />
      <Grid container direction="column">
        <Grid
          container
          item
          direction="row"
          justifyContent="space-between"
          spacing={2}
        >
          <Grid item>
            <PageHeader
              pageTitle="Documents"
              breadCrumbArray={viewProjectDocumentBreadcrumb}
            />
          </Grid>
          <Grid item classes={{ root: classes.selfAlignGrid }}>
            <Grid
              container
              item
              direction="row"
              justifyContent="flex-start"
              spacing={2}
            >
              {!editMode && docType !== 'related' && (
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => setEditMode(true)}
                  >
                    Edit
                  </Button>
                </Grid>
              )}
              {!editMode && (
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => history.goBack()}
                  >
                    Back
                  </Button>
                </Grid>
              )}
              {editMode && (
                <>
                  <Grid item>
                    <Button
                      color="grey"
                      variant="outlined"
                      onClick={() => setEditMode(false)}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container item alignContent="center">
          <Grid item sm={12}>
            {accessToken && documentData && (
              <PdfViewerComponent
                document={
                  documentData.is_signed || documentData.aws_doc_url
                    ? documentData.aws_doc_url
                    : `${
                        URL_CONSTANTS.API.BASE_URL
                      }/documents/templates/${documentId}/project/${projectId}/render?token=${accessToken}&client_id=${localStorage.getItem(
                        'client_id'
                      )}`
                }
                saveDocument={(blobData, storeType, hdDocNumber, llDocType) =>
                  saveDocument(blobData, storeType, hdDocNumber, llDocType)
                }
                editMode={editMode}
                hdDocCdNumber={documentData?.hd_doc_cd_number}
                llDocumentType={documentData?.ll_document_type}
                sourceSystemId={documentData?.source_system_id}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ViewProjectDocument;
