import React from 'react';

import PFTabView from '../../../shared/PFPrime/PFTabView';
import NotesComponent from '../../../CRM/Communications/components/NotesComponent';
import EmailComponent from '../../../CRM/Communications/components/EmailComponent';
import SMSComponent from '../../../CRM/Communications/components/SMSComponent';
import Items from '../../Invoice/components/Items';
import { COMMUNICATION_TYPE } from '../../../CRM/Communications/constants/constants';

import TermsConditions from './TermsConditions';
import PaymentTerms from './PaymentTerms';
import InvoicePayments from './InvoicePayments';

const InvoiceTabs = ({
  setLaborItems,
  setMerchandiseItems,
  laborItems,
  merchandiseItems,
  mode,
  customerId,
  opportunityId,
  quoteId,
  addtionalItems,
  setAddtionalItems,
  invoiceId,
  setFormPayload,
  formPayload,
  primaryEmailAddress,
  invoicePaymentStatus,
  invoicePendingAmount,
  customerDetails,
}) => {
  const isCommunicationAvailable = mode == 'view';
  const CustomerOperationsModel = {
    tabs: [
      { header: 'Labor', key: 'Labor' },
      { header: 'Product', key: 'Merchandise', disabled: false },
      { header: 'Additional Charges', key: 'addProduct', disabled: false },
      { header: 'Payment Terms', key: 'PaymentTerms', disabled: false },
      ...(isCommunicationAvailable
        ? [{ header: 'Payments', key: 'InvoicePayments', disabled: false }]
        : []),
      { header: 'Terms & Conditions', key: 'TermsConditions', disabled: false },
      ...(isCommunicationAvailable
        ? [{ header: 'Notes', key: 'notes', disabled: false }]
        : []),
      ...(isCommunicationAvailable
        ? [{ header: 'SMS', key: 'sms', disabled: false }]
        : []),
      ...(isCommunicationAvailable
        ? [{ header: 'Email', key: 'email', disabled: false }]
        : []),
    ],
    components: {
      Labor: (
        <Items
          reference="Labor"
          setItems={setLaborItems}
          laborItems={laborItems}
        />
      ),
      Merchandise: (
        <Items
          reference="Merchandise"
          setItems={setMerchandiseItems}
          merchandiseItems={merchandiseItems}
        />
      ),
      addProduct: (
        <Items
          reference="AdditionalProduct"
          setItems={setAddtionalItems}
          addtionalItems={addtionalItems}
          addtitionalItems={true}
        />
      ),
      PaymentTerms: (
        <PaymentTerms
          setFormPayload={setFormPayload}
          formPayload={formPayload}
        />
      ),
      InvoicePayments: (
        <InvoicePayments
          invoice_id={invoiceId}
          invoicePaymentStatus={invoicePaymentStatus}
          invoicePendingAmount={invoicePendingAmount}
          customerDetails={customerDetails}
        />
      ),
      TermsConditions: (
        <TermsConditions
          setFormPayload={setFormPayload}
          formPayload={formPayload}
        />
      ),
      notes: (
        <NotesComponent
          customerId={customerId}
          communicationType={COMMUNICATION_TYPE.invoice}
          communicationId={invoiceId}
          opportunityId={opportunityId}
          quoteId={quoteId}
          invoiceId={invoiceId}
        />
      ),
      sms: (
        <SMSComponent
          customerId={customerId}
          communicationType={COMMUNICATION_TYPE.invoice}
          communicationId={invoiceId}
          opportunityId={opportunityId}
          quoteId={quoteId}
          invoiceId={invoiceId}
        />
      ),
      email: (
        <EmailComponent
          customerId={customerId}
          communicationType={COMMUNICATION_TYPE.invoice}
          communicationId={invoiceId}
          opportunityId={opportunityId}
          quoteId={quoteId}
          invoiceId={invoiceId}
          primaryEmailAddress={primaryEmailAddress}
        />
      ),
    },
  };

  return (
    <div className="w-full pt-3 crm p-0">
      <PFTabView model={CustomerOperationsModel} />
    </div>
  );
};

export default InvoiceTabs;
