import axios from 'axios';

import { CONFIG } from '../../../constants.js';
import { URL_CONSTANTS } from '../../../constants/urlConstants.js';

export const getClientsResponse = async (
  searchQuery,
  setLoading,
  setClientsListData,
  setCount
) => {
  try {
    setLoading(true);
    const newQueryString = Object.entries(searchQuery)
      ?.filter(([, value]) => Boolean(value) || value === 0)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    const response = await axios.get(
      `${CONFIG?.API_CRM_URL}/api/customer?${newQueryString}`
    );
    setClientsListData(response?.data?.items || []);
    setCount(response?.data?.count || 0);
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
};
export const getClientsList = async query => {
  try {
    const response = await axios.get(
      `${URL_CONSTANTS.CRM.baseUrl}${URL_CONSTANTS.CRM.clientList}`,
      {
        params: query,
      }
    );
    return response?.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};
// Get Client Details by Id
export const getClientById = async (
  clientId,
  setLoading,
  setAlert,
  setClientDetailsData
) => {
  try {
    setLoading(true);
    await axios
      .get(`${URL_CONSTANTS.API.BASE_URL}/customers/${clientId}`)
      .then(response =>
        response.data.length > 0 ? setClientDetailsData(response?.data[0]) : []
      );
  } catch (error) {
    console.error(error);
    setAlert('error', 'Error in Fetching Client Details.');
  } finally {
    setLoading(false);
  }
};

// Create a new client
export const addClient = async (createObj, setLoading, setAlert, history) => {
  try {
    let customerPhones = createObj.customer_phones.reduce(function (
      modifiedCustomerPhones,
      customerPhone
    ) {
      let obj = {};
      obj.phone_number = customerPhone.phone_number;
      obj.phone_type_id = customerPhone.phone_type?.phone_type_id;
      modifiedCustomerPhones.push(obj);
      return modifiedCustomerPhones;
    }, []);
    let customerEmails =
      createObj.customer_emails.length == 1 &&
      createObj.customer_emails[0].email === ''
        ? []
        : createObj.customer_emails;

    const response = await axios.post(
      `${URL_CONSTANTS.API.BASE_URL}/customers`,
      {
        first_name: createObj.first_name.trim() || null,
        last_name: createObj.last_name.trim() || null,
        address: [
          {
            address1: createObj.street || null,
            city: createObj.city || null,
            state: createObj.state || null,
            zipcode: createObj.zip || null,
            latitude: createObj.latitude || null,
            longitude: createObj.longitude || null,
          },
        ],
        primary_mode_of_contact: createObj.primary_mode_of_contact || null,
        receive_text_message: createObj.receive_text_message || false,
        is_deleted: createObj.is_deleted || false,
        customer_related_notes: createObj.customer_related_notes || null,
        customer_phones: customerPhones || [],
        customer_emails: customerEmails || [],
      }
    );

    if (response.status === 200) {
      setAlert('success', 'Client has been added successfully.', true, true);
      history.push(`/clients/view/${response?.data[0].customer_id}`);
    }
  } catch (error) {
    setAlert('error', error?.response?.data?.error);
  } finally {
    setLoading(false);
  }
};

// Create a new client
export const updateClient = async (
  clientId,
  updateObj,
  history,
  setLoading,
  setAlert
) => {
  try {
    let customerPhones = updateObj.customer_phones.reduce(function (
      modifiedCustomerPhones,
      customerPhone
    ) {
      let obj = {};
      if (customerPhone.customer_phone_id) {
        obj.customer_phone_id = customerPhone.customer_phone_id;
      }
      obj.phone_number = customerPhone.phone_number;
      obj.phone_type_id = customerPhone.phone_type?.phone_type_id;
      modifiedCustomerPhones.push(obj);
      return modifiedCustomerPhones;
    }, []);
    let customerPhonesDeleted = updateObj.customer_phones_deleted;

    let customerEmails = updateObj.customer_emails
      ?.filter(email => email.email !== '')
      .reduce(function (modifiedCustomerEmails, customerEmail) {
        let obj = {};
        if (customerEmail.customer_email_id) {
          obj.customer_email_id = customerEmail.customer_email_id;
        }
        obj.email = customerEmail.email;
        obj.email_type = customerEmail.email_type;
        modifiedCustomerEmails.push(obj);
        return modifiedCustomerEmails;
      }, []);
    let customerEmailsDeleted = updateObj.customer_emails_deleted;

    const response = await axios.patch(
      `${URL_CONSTANTS.API.BASE_URL}/customers/${clientId}`,
      {
        first_name: updateObj.first_name.trim() || null,
        last_name: updateObj.last_name.trim() || null,
        address: [
          {
            address1: updateObj.street || null,
            city: updateObj.city || null,
            state: updateObj.state || null,
            zipcode: updateObj.zip || null,
            latitude: updateObj.latitude || null,
            longitude: updateObj.longitude || null,
          },
        ],
        primary_mode_of_contact: updateObj.primary_mode_of_contact || null,
        receive_text_message: updateObj.receive_text_message || false,
        is_deleted: updateObj.is_deleted || false,
        customer_related_notes: updateObj.customer_related_notes || null,
        customer_phones: [...customerPhones, ...customerPhonesDeleted] || null,
        customer_emails: [...customerEmails, ...customerEmailsDeleted] || null,
      }
    );

    if (response.status === 200) {
      setAlert('success', 'Client has been updated successfully.', true, true);
      history.push(`/clients/view/${response?.data[0].customer_id}`);
    }
  } catch (error) {
    setAlert('error', error?.response?.data?.error);
  } finally {
    setLoading(false);
  }
};

// Get State dropdown options
export const getStates = async (setStates, setLoading = () => {}) => {
  try {
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/system/state`
    );
    setStates(response?.data);
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
};

// Get Phone types
export const getPhoneTypes = async (setPhoneTypes, setLoading) => {
  try {
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/system/phone-type`
    );
    setPhoneTypes(response?.data);
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
};

export const mergeCustomers = async data => {
  try {
    const response = await axios.post(
      `${CONFIG?.API_CRM_URL}/api/customer/merge-customers`,
      data
    );
    return response;
  } catch (e) {
    console.log(
      'ClientsService',
      'mergeCustomers',
      e && e.message ? e.message : e
    );
  }
};
