import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Avatar, Tabs } from '@material-ui/core';
import { FilterList as FilterListIcon } from '@material-ui/icons';
import { TabContext, TabPanel } from '@material-ui/lab';
import {
  GoogleMap,
  InfoWindow,
  Marker,
  useJsApiLoader,
  DirectionsRenderer,
} from '@react-google-maps/api';
import { useFormik } from 'formik';
import moment from 'moment';

import {
  clientDetails,
  libraries,
  SCHEDULER_MAP,
  TECHNICIAN_TYPES,
  UNASSIGNED,
} from '../../../constants';
import { getInitialsPR } from '../../../utils/Helpers';
import { getTeamList } from '../../Admin/user-form/services/user-form-service';
import { REACT_APP_GOOGLE_MAPS_KEY } from '../../../constants/envConstants';
import colors from '../../../constants/color.constants';
import PFCheckbox from '../PFPrime/PFCheckbox';

import SchedulerMapFilter from './SchedulerMapFilter';
import './mapStyles.css';
import { useStyles } from './SchedulerMap.styles';

const SchedulerMap = ({
  viewEditProjectDetails,
  markers: rootMarkers,
  searchQuery,
  setSearchQuery,
  selected,
  setSelected,
  handleStoresChange,
  handleAssignedChange,
  handleUnAssignedChange,
  checkedAssigned,
  checkedUnAssigned,
  checkedStores,
  currDate,
}) => {
  const [markers, setMarkers] = React.useState([]);

  React.useEffect(() => {
    setDirectionsResponse([]);
    setMarkers(rootMarkers);
  }, [rootMarkers]);
  const [routeDisplay, setRouteDisplay] = useState(false);

  const classes = useStyles();
  const [directions, setDirections] = useState(null);
  const [, setOrigin] = useState(null);
  const [, setError] = useState(null);

  const [teamOptions, setTeamOptions] = useState(null);
  const [defaultLocation, setDefaultLocation] = useState({
    lat: 40.380028,
    lng: -76.331053,
  });
  const [showFilters, setShowFilters] = useState(false);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: REACT_APP_GOOGLE_MAPS_KEY,
    libraries,
  });

  const [mapType, setMapType] = useState('SATELLITE');
  const [mapCenter, setMapCenter] = useState();

  useEffect(() => {
    const clientGeolocation =
      JSON.parse(localStorage.getItem('client_details'))?.client_geolocation ||
      '';
    const geoLocation = {
      lat: clientGeolocation?.lat
        ? parseFloat(clientGeolocation?.lat)
        : defaultLocation.lat,
      lng: clientGeolocation?.lng
        ? parseFloat(clientGeolocation?.lng)
        : defaultLocation.lng,
    };
    setMapCenter(geoLocation);
  }, []);

  const mapStyles = {
    height: '600px',
    width: '100%',
    position: 'relative',
  };

  const options = {
    styles: [{ stylers: [mapStyles] }],
    disableDefaultUI: false,
  };
  useEffect(async () => {
    if (showFilters) {
      const responseTeam = await getTeamList();
      if (responseTeam) setTeamOptions(responseTeam);
    }
  }, [showFilters]);
  const mapRef = useRef();
  const onMapLoad = useCallback(map => {
    mapRef.current = map;
  }, []);

  const handleFilterToggle = () => {
    setShowFilters(!showFilters);
  };

  const handleTabChange = newValue => {
    setMapType(newValue);
  };

  const mapFiltersFormik = useFormik({
    initialValues: {
      category: [],
      installer: [],
      market: [],
      type: [],
      store_number: [],
      status: [],
      team: [],
      start_date: '',
      end_date: '',
    },
    onSubmit: (values, { resetForm }) => {
      resetForm();
    },
    enableReinitialize: true,
  });

  function compare(a, b) {
    var time1 = parseFloat(
      a?.start_time?.replace(':', '.')?.replace(/[^\d.-]/g, '')
    );
    var time2 = parseFloat(
      b?.start_time?.replace(':', '.')?.replace(/[^\d.-]/g, '')
    );
    if (a?.start_time?.match(/.*pm/)) time1 += 12;
    if (b?.start_time?.match(/.*pm/)) time2 += 12;
    if (time1 < time2) return -1;
    if (time1 > time2) return 1;
    return 0;
  }

  const createRoutesForInstaller = reqGroup => {
    let routes = [];
    const group = reqGroup.sort(
      (groupA, groupB) => groupA.seqCount - groupB.seqCount
    );
    const length = group?.length || 0;
    if (length > 0) {
      routes = group?.slice(0, -1)?.map((item, index) => ({
        installer_id: item?.installer_id || null,
        origin: {
          lat: parseFloat(item?.client_lat || 0),
          lng: parseFloat(item?.client_long || 0),
        },
        destination: {
          lat: parseFloat(group[index + 1]?.client_lat || 0),
          lng: parseFloat(group[index + 1]?.client_long || 0),
        },
      }));
    }
    return routes;
  };

  const sortByArival = array => {
    const sortedArray = array.sort((arrivalA, arrivalB) => {
      return arrivalA?.installer_arrival_start_time.localeCompare(
        arrivalB?.installer_arrival_start_time
      );
    });
    return sortedArray;
  };

  const sortedList = markerList => {
    const sortedArray = markerList?.sort((markerA, markerB) => {
      if (markerA.installer_id !== markerB.installer_id) {
        return markerA.installer_id - markerB.installer_id;
      } else {
        return (
          new Date(markerA.schedule_date) - new Date(markerB.schedule_date)
        );
      }
    });

    let currentInstallerId = null;
    let sequence = 0;

    sortedArray.forEach(item => {
      if (
        item.installer_id !== currentInstallerId &&
        item?.worktype?.toLowerCase() === TECHNICIAN_TYPES.SCHEDULED
      ) {
        currentInstallerId = item?.installer_id;
        sequence = 1;
      } else {
        if (item?.worktype?.toLowerCase() === TECHNICIAN_TYPES.SCHEDULED) {
          sequence++;
        }
      }

      if (
        item?.installer_id &&
        item?.worktype?.toLowerCase() === TECHNICIAN_TYPES.SCHEDULED
      ) {
        item.seqCount = sequence;
      }
    });

    return sortedArray;
  };

  const [colorObject, setColorObject] = useState([]);
  const markRouting = markers => {
    const groupBy = Object.groupBy(
      markers,
      ({ installer_id }) => installer_id || UNASSIGNED?.UNASSIGNED
    );

    let route = [];
    Object.keys(groupBy).forEach((installerId, index) => {
      if (installerId !== UNASSIGNED?.UNASSIGNED) {
        setColorObject(preValue => [
          ...preValue,
          { installerId: installerId, color: colors[index].hex },
        ]);
        let group = groupBy[installerId]?.filter(
          e => e.worktype?.toLowerCase() === TECHNICIAN_TYPES.SCHEDULED
        );
        const groupLength = group?.length || 0;
        const arrivalLength =
          group?.filter(i => i?.installer_arrival_start_time)?.length || 0;
        if (groupLength === arrivalLength) {
          group = sortByArival(group);
        }

        route.push(...createRoutesForInstaller(group));
      }
    });
    return route;
  };
  const [markRoutes, setMarkRoutes] = React.useState([]);
  React.useEffect(() => {
    if (markers?.length && routeDisplay) {
      const sorted = sortedList(markers);
      setMarkers(sorted);
      setMarkRoutes(markRouting(sorted));
    }
  }, [markers?.length, routeDisplay]);
  React.useEffect(() => {
    setDirections(null);
    if (
      markers?.length &&
      searchQuery?.installerIds &&
      searchQuery?.installerIds?.split(',').length === 1
    ) {
      setDefaultLocation({
        lat: markers[0]?.client_lat || markers[0]?.latitude,
        lng: markers[0]?.client_long || markers[0]?.longitude,
      });
      const waypoints = markers
        .filter(marker => marker?.client_lat && marker?.client_long)
        .sort(function (a, b) {
          return a?.start_time?.localeCompare(b?.start_time);
        })
        .sort(compare)
        .map(p => {
          return {
            location: new window.google.maps.LatLng(
              p?.client_lat,
              p?.client_long
            ),
            stopover: true,
          };
        });
      if (waypoints.length) {
        const originPoint = new window.google.maps.LatLng(
          markers[0]?.installer_lat,
          markers[0]?.installer_long
        );
        const destination = waypoints.pop().location;
        setOrigin(originPoint);

        const directionsService = new window.google.maps.DirectionsService();
        directionsService.route(
          {
            origin: originPoint,
            destination: destination,
            travelMode: window.google.maps.TravelMode.DRIVING,
            waypoints: waypoints,
          },
          (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
              setDirections(result);
            } else {
              setError(result);
            }
          }
        );
      }
    }
  }, [markers]);
  useEffect(() => {
    if (selected) {
      setSelected(
        markers.filter(marker => marker?.project_id === selected?.project_id)[0]
      );
    }
  }, []);

  useEffect(() => {
    if (
      !mapFiltersFormik?.values?.start_date &&
      !mapFiltersFormik?.values?.end_date
    ) {
      mapFiltersFormik?.setFieldValue('start_date', new Date(currDate));
      mapFiltersFormik?.setFieldValue('end_date', new Date(currDate));
    }
  }, [
    mapFiltersFormik?.values?.start_date,
    mapFiltersFormik?.values?.end_date,
    currDate,
  ]);

  useEffect(() => {
    if (
      mapFiltersFormik?.values?.start_date &&
      mapFiltersFormik?.values?.end_date
    ) {
      const startDate = new Date(mapFiltersFormik?.values?.start_date);
      const endDate = new Date(mapFiltersFormik?.values?.end_date);
      if (startDate.getTime() === endDate.getTime()) {
        setRouteDisplay(true);
      } else {
        setRouteDisplay(false);
      }
    } else if (
      mapFiltersFormik?.values?.start_date == '' &&
      mapFiltersFormik?.values?.end_date == ''
    ) {
      setRouteDisplay(true);
    } else {
      setRouteDisplay(false);
    }
  }, [mapFiltersFormik?.values]);

  useEffect(() => {
    if (
      mapFiltersFormik?.values?.installer?.length === 0 ||
      mapFiltersFormik?.values?.installer?.length > 10
    ) {
      setRouteDisplay(false);
    } else if (
      mapFiltersFormik?.values?.start_date &&
      mapFiltersFormik?.values?.end_date
    ) {
      const startDate = new Date(mapFiltersFormik?.values?.start_date);
      const endDate = new Date(mapFiltersFormik?.values?.end_date);
      if (startDate.getTime() === endDate.getTime()) {
        setRouteDisplay(true);
      } else {
        setRouteDisplay(false);
      }
    } else if (
      mapFiltersFormik?.values?.start_date == '' &&
      mapFiltersFormik?.values?.end_date == ''
    ) {
      setRouteDisplay(true);
    } else {
      setRouteDisplay(false);
    }
  }, [mapFiltersFormik?.values]);
  const [directionsResponse, setDirectionsResponse] = useState([]);
  useEffect(() => {
    if (
      window.google &&
      routeDisplay &&
      searchQuery?.installerIds?.length > 0
    ) {
      const directionsService = new window.google.maps.DirectionsService();
      markRoutes?.forEach(item => {
        directionsService.route(
          {
            origin: item?.origin,
            destination: item?.destination,
            travelMode: window.google.maps.TravelMode.DRIVING,
          },
          (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
              result.installer_id = item?.installer_id;
              setDirectionsResponse(preValue => [...preValue, result]);
            } else {
              console.error(`error fetching in directions: ${result}`);
            }
          }
        );
      });
    }
  }, [window.google, markRoutes, routeDisplay]);

  const getColor = installer_id => {
    return (
      colorObject?.find(col => col?.installerId == installer_id)?.color ||
      colors[0].hex
    );
  };

  return (
    <>
      <TabContext value={mapType}>
        <div className="grid flex justify-content-end schedular-map px-2">
          <div className="col-12 lg:col-5"></div>
          <div className="col-12 lg:col-12">
            <div className="flex justify-content-end w-full pt-2 gap-5">
              <div className="flex align-items-center">
                <PFCheckbox
                  checked={checkedStores}
                  onChange={handleStoresChange}
                  inputId="Stores"
                />
                <label className="ml-2" htmlFor="Stores">
                  Stores
                </label>
              </div>
              <div className="flex align-items-center">
                <PFCheckbox
                  checked={checkedAssigned == 1 ? true : false}
                  onChange={handleAssignedChange}
                  inputId="Assigned"
                />
                <label className="ml-2" htmlFor="Assigned">
                  Assigned
                </label>
              </div>
              <div className="flex align-items-center">
                <PFCheckbox
                  checked={checkedUnAssigned}
                  onChange={handleUnAssignedChange}
                  inputId="Un-Assigned"
                />
                <label className="ml-2" htmlFor="Un-Assigned">
                  Un-Assigned
                </label>
              </div>
            </div>
          </div>
          <div className="col-12 lg:col-5 justify-content-end py-0">
            <Tabs
              value={mapType}
              onChange={handleTabChange}
              indicatorColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
              classes={{
                root: classes.tabRoot,
                scrollButtons: classes.scrollButtons,
                indicator: classes.indicator,
              }}
              TabIndicatorProps={{ children: <span /> }}
            ></Tabs>
          </div>
        </div>
        <div className="col-12 px-2">
          {SCHEDULER_MAP?.map((tab, index) => {
            return (
              <TabPanel value={tab.value} key={index} className="p-0">
                <div className="relative">
                  {showFilters ? (
                    <div className="absolute left-0 top-0 z-5 ml-3 md:ml-8 mt-4 md:mt-8 p-4 map-filter w-10 md:w-8">
                      <SchedulerMapFilter
                        currDate={currDate}
                        teamOptions={teamOptions}
                        mapFiltersFormik={mapFiltersFormik}
                        showFilters={showFilters}
                        setShowFilters={setShowFilters}
                        setSearchQuery={setSearchQuery}
                        setMessageRouteDisplay={() => {}}
                      />
                    </div>
                  ) : (
                    <div
                      className={classes.avatarWrapper}
                      onClick={handleFilterToggle}
                      onKeyDown={e => {
                        if (e.key === 'Enter' || e.key === ' ') {
                          handleFilterToggle();
                        }
                      }}
                      role="button"
                      tabIndex={0}
                    >
                      <Avatar className={classes.avatar}>
                        <FilterListIcon
                          fontSize="large"
                          className={classes.iconRoot}
                        />
                      </Avatar>
                    </div>
                  )}

                  {isLoaded && (
                    <GoogleMap
                      mapContainerStyle={mapStyles}
                      zoom={12}
                      maxZoom={12}
                      center={mapCenter}
                      mapTypeId={mapType}
                      defaultMapTypeId={0}
                      onMapTypeIdChanged={() => {}}
                      options={options}
                      onLoad={onMapLoad}
                      onClick={() => setSelected(null)}
                    >
                      {directions &&
                        markers?.length &&
                        searchQuery?.installerIds?.split(',').length === 1 && (
                          <Marker
                            animation={1}
                            position={{
                              lat: parseFloat(markers[0]?.installer_lat),
                              lng: parseFloat(markers[0]?.installer_long),
                            }}
                            icon={{
                              url: markers[0].installer_image,
                              scaledSize: new window.google.maps.Size(22, 22),
                              origin: new window.google.maps.Point(0, 0),
                              anchor: new window.google.maps.Point(0, 0),
                            }}
                          />
                        )}
                      {markers.map((marker, index) =>
                        marker.store_id ? (
                          <Marker
                            key={index}
                            position={{
                              lat: parseFloat(marker?.latitude),
                              lng: parseFloat(marker?.longitude),
                            }}
                            onClick={() => {
                              setSelected(marker);
                            }}
                            icon={{
                              url:
                                marker?.is_assigned === 'true'
                                  ? `data:image/svg+xml;charset=UTF-8,%3c?xml version='1.0' encoding='UTF-8'?%3e%3csvg width='47px' height='62px' viewBox='0 0 47 62' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3ctitle%3eGroup 8%3c/title%3e%3cg id='Extras' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3e%3cg id='01-14-Schedular-Map-View-Filters-OnHover' transform='translate(-846.000000, -966.000000)'%3e%3cg id='Calendar' transform='translate(113.000000, 606.000000)'%3e%3cg id='Group-8' transform='translate(733.115955, 360.658537)'%3e%3cpath d='M22.9988026,0 C10.2924671,0 0,10.3869718 0,23.2015964 C0,27.9892657 1.43682649,32.436339 3.90098391,36.1273374 L3.92253631,36.1659866 C3.92732573,36.1708177 3.92732573,36.1732333 3.93211515,36.18048 L22.9988026,60.4731707 L42.1038055,36.1273374 L42.091832,36.1273374 C44.5583841,32.436339 46,27.9892657 46,23.2015964 C46,10.3869718 35.7003488,0 22.9988026,0 Z' id='Fill-1' fill='%234D00FF'%3e%3c/path%3e%3ccircle id='photo' fill='%23F3EDFF' cx='22.8497296' cy='22.8497296' r='19.8497296'%3e%3c/circle%3e%3ctext x='22.592077' y='17.592077' fill='black' font-size='10pt' font-weight='bold' text-anchor='middle'%3e${getInitialsPR(
                                      marker?.installer_name
                                    )}%3c/text%3e%3ctext x='22.592077' y='38.592077' font-weight='bold' fill='black' font-size='12pt' text-anchor='middle'%3e${
                                      marker?.seqCount || ''
                                    }%3c/text%3e%3ctext x='22.592077' y='50.592077' fill='black' font-size='6pt' text-anchor='middle'%3e${
                                      marker?.start_time || ''
                                    }%3c/text%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e`
                                  : `data:image/svg+xml;charset=UTF-8,%3c?xml version='1.0' encoding='UTF-8'?%3e%3csvg width='47px' height='62px' viewBox='0 0 47 62' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3ctitle%3eGroup 8%3c/title%3e%3cg id='Extras' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3e%3cg id='01-14-Schedular-Map-View-Filters-OnHover' transform='translate(-760.000000, -1026.000000)'%3e%3cg id='Calendar' transform='translate(113.000000, 606.000000)'%3e%3cg id='Group-8' transform='translate(647.115955, 420.658537)'%3e%3cpath d='M22.9988026,0 C10.2924671,0 0,10.3869718 0,23.2015964 C0,27.9892657 1.43682649,32.436339 3.90098391,36.1273374 L3.92253631,36.1659866 C3.92732573,36.1708177 3.92732573,36.1732333 3.93211515,36.18048 L22.9988026,60.4731707 L42.1038055,36.1273374 L42.091832,36.1273374 C44.5583841,32.436339 46,27.9892657 46,23.2015964 C46,10.3869718 35.7003488,0 22.9988026,0 Z' id='Fill-1' fill='%23363636'%3e%3c/path%3e%3ccircle id='photo' fill='%23EDEDED' cx='22.8497296' cy='22.8497296' r='19.8497296'%3e%3c/circle%3e%3ctext x='22.592077' y='17.592077' fill='black' font-size='10pt' font-weight='bold' text-anchor='middle'%3eU/A%3c/text%3e%3ctext x='22.592077' y='30.592077' fill='black' font-size='6pt' text-anchor='middle'%3e${
                                      marker?.start_time || ''
                                    }%3c/text%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e`,
                              scaledSize: new window.google.maps.Size(22, 22),
                              origin: new window.google.maps.Point(0, 0),
                              anchor: new window.google.maps.Point(15, 15),
                            }}
                          />
                        ) : (
                          <>
                            {routeDisplay &&
                              directionsResponse?.length &&
                              directionsResponse?.map((item, key) => (
                                <>
                                  <DirectionsRenderer
                                    directions={item}
                                    key={key}
                                    options={{
                                      polylineOptions: {
                                        strokeColor: getColor(
                                          item?.installer_id
                                        ),
                                        strokeOpacity: 1.0,
                                        strokeWeight: 4,
                                      },
                                      suppressMarkers: true,
                                    }}
                                  />
                                </>
                              ))}

                            <Marker
                              key={index}
                              position={{
                                lat: parseFloat(marker?.client_lat),
                                lng: parseFloat(marker?.client_long),
                              }}
                              icon={{
                                url:
                                  marker?.is_assigned === 'true'
                                    ? `data:image/svg+xml;charset=UTF-8,%3c?xml version='1.0' encoding='UTF-8'?%3e%3csvg width='47px' height='62px' viewBox='0 0 47 62' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3ctitle%3eGroup 8%3c/title%3e%3cg id='Extras' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3e%3cg id='01-14-Schedular-Map-View-Filters-OnHover' transform='translate(-846.000000, -966.000000)'%3e%3cg id='Calendar' transform='translate(113.000000, 606.000000)'%3e%3cg id='Group-8' transform='translate(733.115955, 360.658537)'%3e%3cpath d='M22.9988026,0 C10.2924671,0 0,10.3869718 0,23.2015964 C0,27.9892657 1.43682649,32.436339 3.90098391,36.1273374 L3.92253631,36.1659866 C3.92732573,36.1708177 3.92732573,36.1732333 3.93211515,36.18048 L22.9988026,60.4731707 L42.1038055,36.1273374 L42.091832,36.1273374 C44.5583841,32.436339 46,27.9892657 46,23.2015964 C46,10.3869718 35.7003488,0 22.9988026,0 Z' id='Fill-1' fill='${encodeURIComponent(
                                        clientDetails?.project_type_config
                                          ?.technician_view_map_pins === true
                                          ? marker.project_color_code ||
                                              marker.config_color_code ||
                                              '#5e5ba6'
                                          : '#4D00FF'
                                      )}'%3e%3c/path%3e%3ccircle id='photo' fill='%23F3EDFF' cx='22.8497296' cy='22.8497296' r='19.8497296'%3e%3c/circle%3e%3ctext x='22.592077' y='17.592077' fill='black' font-size='10pt' font-weight='bold' text-anchor='middle'%3e${getInitialsPR(marker?.installer_name)}%3c/text%3e%3ctext x='22.592077' y='38.592077' font-weight='bold' fill='black' font-size='12pt' text-anchor='middle'%3e${marker?.seqCount || ''}%3c/text%3e%3ctext x='22.592077' y='50.592077' fill='black' font-size='6pt' text-anchor='middle'%3e${marker?.start_time || ''}%3c/text%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e`
                                    : `data:image/svg+xml;charset=UTF-8,%3c?xml version='1.0' encoding='UTF-8'?%3e%3csvg width='47px' height='62px' viewBox='0 0 47 62' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3ctitle%3eGroup 8%3c/title%3e%3cg id='Extras' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3e%3cg id='01-14-Schedular-Map-View-Filters-OnHover' transform='translate(-760.000000, -1026.000000)'%3e%3cg id='Calendar' transform='translate(113.000000, 606.000000)'%3e%3cg id='Group-8' transform='translate(647.115955, 420.658537)'%3e%3cpath d='M22.9988026,0 C10.2924671,0 0,10.3869718 0,23.2015964 C0,27.9892657 1.43682649,32.436339 3.90098391,36.1273374 L3.92253631,36.1659866 C3.92732573,36.1708177 3.92732573,36.1732333 3.93211515,36.18048 L22.9988026,60.4731707 L42.1038055,36.1273374 L42.091832,36.1273374 C44.5583841,32.436339 46,27.9892657 46,23.2015964 C46,10.3869718 35.7003488,0 22.9988026,0 Z' id='Fill-1' fill='${encodeURIComponent(
                                        clientDetails?.project_type_config
                                          ?.technician_view_map_pins === true
                                          ? marker.project_color_code ||
                                              marker.config_color_code ||
                                              '#363636'
                                          : '#363636'
                                      )}'%3e%3c/path%3e%3ccircle id='photo' fill='%23EDEDED' cx='22.8497296' cy='22.8497296' r='19.8497296'%3e%3c/circle%3e%3ctext x='22.592077' y='17.592077' fill='black' font-size='10pt' font-weight='bold' text-anchor='middle'%3eU/A%3c/text%3e%3ctext x='22.592077' y='30.592077' fill='black' font-size='6pt' text-anchor='middle'%3e${marker?.start_time || ''}%3c/text%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e`,
                                scaledSize:
                                  marker?.project_id === selected?.project_id
                                    ? new window.google.maps.Size(70, 70)
                                    : new window.google.maps.Size(41, 55),

                                anchor:
                                  marker?.project_id === selected?.project_id
                                    ? new window.google.maps.Point(15, 15)
                                    : new window.google.maps.Point(15, 15),
                              }}
                              onClick={() => {
                                setSelected(marker);
                              }}
                              onDblClick={() => {
                                viewEditProjectDetails(
                                  selected?.reschedule_reason,
                                  selected?.source_system_id,
                                  selected?.project_id
                                );
                              }}
                            />
                          </>
                        )
                      )}
                      {selected ? (
                        <InfoWindow
                          options={{ zoomControl: true }}
                          position={{
                            lat: parseFloat(
                              selected.client_lat || selected.latitude
                            ),
                            lng: parseFloat(
                              selected.client_long || selected.longitude
                            ),
                          }}
                          onCloseClick={() => {
                            setSelected(null);
                          }}
                        >
                          <div
                            style={{
                              width: '204px',
                              minHeight: '201px',
                              maxHeight: 'fit-content !important',
                              backgroundColor: '#ffffff',
                            }}
                          >
                            <div className="flex justify-content-center align-items-center h-full mt-0">
                              {selected?.installer_image ||
                              selected?.logo_url ? (
                                <img
                                  style={{
                                    maxHeight: '150px',
                                    backgroundColor: '#ffffff',
                                  }}
                                  src={
                                    selected?.installer_image ||
                                    selected?.logo_url ||
                                    '/images/user-profile.svg'
                                  }
                                  width="100%"
                                  alt="User Profile"
                                />
                              ) : (
                                <i className="pi pi-building text-6xl text-500" />
                              )}
                            </div>

                            <div>
                              <h2
                                style={{
                                  margin: '0.5rem 0rem',
                                }}
                              >
                                {selected?.installer_name ||
                                  selected?.source_name}
                              </h2>
                              {selected?.store_id ? (
                                <>
                                  <p
                                    style={{
                                      margin: '0.5rem 0rem',
                                    }}
                                  >
                                    <b>Store#: </b>
                                    {selected?.store_number}
                                  </p>
                                  <p
                                    style={{
                                      margin: '0.5rem 0rem',
                                    }}
                                  >
                                    <b>Store Name: </b>
                                    {selected?.store_name}
                                  </p>
                                  <p
                                    style={{
                                      margin: '0.5rem 0rem',
                                    }}
                                  >
                                    <b>Store Address:</b> <br />
                                    {selected?.address1}
                                    <br />
                                    {`${selected?.city} `}
                                    <br />
                                    {`${selected?.state} `}
                                  </p>
                                </>
                              ) : (
                                <>
                                  <p
                                    style={{
                                      margin: '0.5rem 0rem',
                                    }}
                                  >
                                    <b>Status :</b> {selected?.project_status}
                                  </p>
                                  <p
                                    style={{
                                      margin: '0.5rem 0rem',
                                    }}
                                  >
                                    <b>Type:</b> {selected?.project_type}
                                  </p>
                                  <p
                                    style={{
                                      margin: '0.5rem 0rem',
                                    }}
                                  >
                                    <b>Customer: </b> {selected?.client_name}
                                  </p>
                                  <p
                                    style={{
                                      margin: '0.5rem 0rem',
                                    }}
                                  >
                                    <b>Scheduled Start:</b>
                                    {selected?.schedule_date
                                      ? moment(selected?.schedule_date).format(
                                          'MM-DD-YYYY hh:mm A'
                                        )
                                      : ''}
                                  </p>
                                  <p
                                    style={{
                                      margin: '0.5rem 0rem',
                                    }}
                                  >
                                    <b>Scheduled End:</b>{' '}
                                    {selected?.schedule_date
                                      ? moment(
                                          selected?.schedule_date_end
                                        ).format('MM-DD-YYYY hh:mm A')
                                      : ''}
                                  </p>
                                  <p
                                    style={{
                                      margin: '0.5rem 0rem',
                                    }}
                                  >
                                    <b>Category:</b>{' '}
                                    {selected?.project_category}
                                  </p>
                                  <p
                                    style={{
                                      margin: '0.5rem 0rem',
                                      wordBreak: 'break-all',
                                    }}
                                  >
                                    <b>Address:</b>{' '}
                                    {selected?.installation_address}
                                  </p>
                                </>
                              )}
                            </div>
                          </div>
                        </InfoWindow>
                      ) : null}

                      {directions &&
                        markers?.length &&
                        searchQuery?.installerIds?.split(',').length === 1 && (
                          <DirectionsRenderer
                            options={{
                              directions: directions,
                            }}
                            onLoad={() => {}}
                            onUnmount={() => {}}
                          />
                        )}
                    </GoogleMap>
                  )}
                </div>
              </TabPanel>
            );
          })}
        </div>
      </TabContext>
    </>
  );
};
export default SchedulerMap;
