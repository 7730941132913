import React from 'react';
const PFFormikError = ({ touched, errors, field }) => {
  const fieldValue = field.split('.');

  if (fieldValue.length > 1) {
    const fistIndex = fieldValue[0];
    const secondIndex = fieldValue[1];

    if (
      fistIndex &&
      secondIndex &&
      Object.entries(touched).length > 0 &&
      errors
    ) {
      return touched[fistIndex][secondIndex] ? (
        <div style={{ color: 'red' }} className="px-1 errorStyle">
          {errors?.[fistIndex]?.[secondIndex] || null}
        </div>
      ) : null;
    }
    return null;
  }
  return touched && errors && field && touched[field] ? (
    <div style={{ color: 'red' }} className="px-1 errorStyle">
      {errors[field] || null}
    </div>
  ) : null;
};

export default PFFormikError;
