import axios from 'axios';

import { URL_CONSTANTS } from '../../../../constants/urlConstants';
export const getCustomerAddress = async (params, customerId) => {
  try {
    let url = `${URL_CONSTANTS?.CRM?.baseUrl}/api/customer/address/${customerId}?page=${params?.page + 1 || 1}&limit=${params?.limit || 10}&search=${params?.search || ''}&default=${params?.default || null}`;

    const address = await axios.get(url);

    return address?.data?.data || [];
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const addCustomerAddress = async (body, customerId) => {
  try {
    let url = `${URL_CONSTANTS?.CRM?.baseUrl}/api/customer/add-address/${customerId}`;

    const address = await axios.post(url, body);

    return address?.data || {};
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const changeCustomerPrimaryAddress = async (body, customerId) => {
  try {
    let url = `${URL_CONSTANTS?.CRM?.baseUrl}/api/customer/change-primary-address/${customerId}`;

    const address = await axios.post(url, body);

    return address?.data || {};
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getCustomerInfo = async customerId => {
  try {
    let url = `${URL_CONSTANTS?.CRM?.baseUrl}/api/customer/${customerId}`;

    const data = await axios.get(url);

    return data?.data || {};
  } catch (error) {
    console.error(error);
    return null;
  }
};
export const deleteAddress = async addressId => {
  if (!addressId) {
    console.error('Invalid address ID');
    return null;
  }

  try {
    const url = `${URL_CONSTANTS.CRM.baseUrl}/api/customer/delete-primary-address/${addressId}`;
    const response = await axios.delete(url);
    return response?.data || {};
  } catch (error) {
    console.error(
      'Failed to delete address:',
      error.response?.data?.message || error.message
    );
    return null;
  }
};
