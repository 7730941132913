import {
  REACT_APP_API_BASEURL,
  REACT_APP_API_PROJECT_BASEURL,
  REACT_APP_AUDIT_LOG_BASEURL,
  REACT_APP_BULLETIN_BASEURL,
  REACT_APP_CRM_API_BASEURL,
  REACT_APP_DOCUMENTS_MS_API,
  REACT_APP_INVENTORY_MS_API,
  REACT_APP_LOGIN_AUTH,
  REACT_APP_NOTIFICATION_BASEURL,
  REACT_APP_PAYROLL_API_BASEURL,
  REACT_APP_QUESTIONNAIRE_API_BASEURL,
  REACT_APP_UM_BASEURL,
} from './envConstants';

export const URL_CONSTANTS = {
  API: {
    BASE_URL: REACT_APP_API_BASEURL,
    BULLETIN: REACT_APP_BULLETIN_BASEURL,
  },
  CRM: {
    baseUrl: REACT_APP_CRM_API_BASEURL,
    clientList: '/api/customer',
    communication: {
      notes: {
        create: '/api/communication/note',
        getAll: '/api/communication/note',
      },
      sms: {
        create: '/api/communication/sms',
        getAll: '/api/communication/sms',
      },
      email: {
        create: '/api/communication/email',
        getAll: '/api/communication/email',
        downloadAttachment: '/api/communication/attachment',
      },
    },
    paymentConfig: '/api/clients/payment-config',
    sendOnboardMail: '/api/clients/send-onboard-email',
    resendOnboardMail: '/api/clients/resend-onboard-email',
    checkOnboardingStatus: '/api/clients/check-onboard-status',
    generateOnboardLink: '/api/public/generate-onboarding-link',
    sendPaymentMail: '/api/payment/send-payment-mail',
    getAllPayment: '/api/payment/payment-list',
    projectLinkage: {
      getLinkedProjects: '/api/projects/get-linked-projects',
      setLinkProject: `/api/projects/set-linked-project`,
      unLinkProject: `/api/projects/unlinked-project`,
      linkConfig: `/admin-config`,
    },
  },
  CHARGEBACK: {
    list: '/api/chargeback/list',
    projectSummary: {
      search: '/projects/chargeback/search-by-project-number?project_number=',
    },
    chargebackSummary: {
      chargebackReason: '/api/chargeback/reason/create',
      chargebackCreate: '/api/chargeback/create',
      getChargeback: '/api/chargeback',
    },

    baseUrl: REACT_APP_PAYROLL_API_BASEURL,
  },
  RETAINAGE: {
    baseUrl: REACT_APP_PAYROLL_API_BASEURL,
    get: `/api/user`,
  },
  CREW_MANAGEMENT: {
    baseUrl: REACT_APP_UM_BASEURL,
    crew: {
      get: `/api/user`,
    },
  },
  AUDIT_LOG: {
    baseUrl: REACT_APP_AUDIT_LOG_BASEURL,
    module: {
      getModules: '/audit-log/get-modules',
      getColumnsModule: '/audit-log/master-list',
      createConfiguration: '/audit-log',
      list: '/audit-log/list',
      deleteAudit: '/audit-log/',
      getRowData: '/audit-log/',
      updateConfiguration: '/audit-log',
    },
  },
  COMPANY_CONFIG: {
    baseUrl: REACT_APP_API_BASEURL,
    getAll: `/admin-config`,
    getBySearch: `/admin-config`,
    getById: `/admin-config`,
  },
  PROJECTS: {
    baseUrl: REACT_APP_API_PROJECT_BASEURL,
    dropdowns: {
      workroom: '/system/workroom',
      store: '/stores',
      category: '/projects/master/project-category',
      type: '/projects/master/project-type',
      status: '/system/cubes/master?cube=Project&dimension=status_id',
      installer: '/auth/user/report/get-installers',
      installerType: '/system/installer-work-type',
    },
    endPoints: {
      listCxScheduledProject: '/cx-scheduled/projects',
      acceptCxScheduledProject: '/cx-scheduled/project/%s/accept',
      declineCxScheduledProject: '/cx-scheduled/project/%s/decline',
      validateBusinessWorkHourAndHoliday:
        '/cx-scheduled/project/%s/execution/%s/validate-business-hours',
      validateCxSchedulingEligibleTechniciansByTypeCategoryStoreAndZipcode:
        '/cx-scheduled/project/%s/execution/%s/validate-technician-eligibility',
      validateTechnicianAvailableOnDate:
        '/cx-scheduled/project/%s/execution/%s/validate-technician-availibility',
      validateCalculatedTechnicianServiceAvailableTime:
        '/cx-scheduled/project/%s/execution/%s/validate-technician-service-time-availibility',
      validateAvailableTechnicianWithConsecutiveBreakTime:
        '/cx-scheduled/project/%s/execution/%s/validate-technician-availibility-with-break-time',
      validateTechnicianAfterTimeOff:
        '/cx-scheduled/project/%s/execution/%s/validate-technician-timeoff',
      validateTechnicianCapacity:
        '/cx-scheduled/project/%s/execution/%s/validate-technician-capacity',
      validateCustomerPortalDefaultRotationPercentage:
        '/cx-scheduled/project/%s/execution/%s/validate-technician-rotation-percentage',
      validateAndAssignInstaller:
        '/cx-scheduled/project/%s/execution/%s/validate-and-finalize',
    },
    smsTab: {
      fetchSMSData: '/projects/%s/sms/scheduledMessage',
      patchSMS: '/projects/%s/sms/%s/scheduledMessage',
      fetchSMSHistory: '/projects/%s/sms/history',
      fetchSMSCount: '/projects/%s/sms/sms-count',
    },
    issuesTab: {
      fetchIssues: '/projects/%s/issues',
      fetchOriginatingFrom: '/system/originating-from',
      fetchIssueCategory: '/system/issue-category',
      urlWithIssueId: '/projects/%s/issues/%s',
      fetchItemsWithId: '/system/items/%s',
    },
    timeManagementTab: {
      fetchData: '/projects/%s/time-management',
    },
    notesTab: {
      notesURL: '/projects/%s/notes',
      notesURLWithId: '/projects/%s/notes/%s',
      fetchNotesType: '/system/notes-category',
    },
    additionalFeeTab: {
      additionalCharge: '/projects/%s/payment-request/additional-charge',
      expenses: '/projects/%s/expenses',
      prTypes: '/system/pr-type',
      additionalChargeById: '/projects/%s/payment-request/additional-charge/%s',
      expenseSource: '/system/expense-source',
      expensesById: '/projects/%s/expenses/%s',
    },
  },
  PAYROLL: {
    report: '/payroll/report',
    action: '/payroll/action',
    downlaod: '/payroll/download',
  },
  QUESTIONNAIRE: {
    baseUrl: REACT_APP_QUESTIONNAIRE_API_BASEURL,
    endPoints: {
      getModules: '/api/questionnaire/module/list',
      getModuleById: '/api/questionnaire/module/%s',
      getModuleByTagName: '/api/questionnaire/module/tag/%s',
      getModuleByTagId: '/api/questionnaire/module/tag-id/%s',
      createModule: '/api/questionnaire/module',
      copyModule: '/api/questionnaire/module/copy',
      deleteModule: '/api/questionnaire/module/delete/%s',
      updateModuleById: '/api/questionnaire/module/%s',
      deleteModuleById: '/api/questionnaire/module/%s',

      getQuestions: '/api/questionnaire/module/%s/questions',
      getQuestionById: '/api/questionnaire/module/%s/question/%s',
      addQuestion: '/api/questionnaire/module/%s/question',
      updateQuestionById: '/api/questionnaire/module/%s/question/%s',
      deleteQuestions: '/api/questionnaire/module/%s/questions',
      deleteQuestionById: '/api/questionnaire/module/%s/question/%s',

      getAnswers: '/api/questionnaire/module/%s/target/%s/answers',
      getAnswerById: '/api/questionnaire/module/%s/target/%s/answer/%s',
      saveAnswers: '/api/questionnaire/module/%s/target/%s/answers',
      updateAnswers: '/api/questionnaire/module/%s/target/%s/answers',
      updateAnswerById: '/api/questionnaire/module/%s/target/%s/answer/%s',
      deleteAnswers: '/api/questionnaire/module/%s/target/%s/answers/%s',
      deleteAnswerById: '/api/questionnaire/module/%s/target/%s/answer/%s',
      createCustomizeCheckList:
        '/api/questionnaire/module/customizable-checklist',
    },
  },
  EXPORTED_REPORTS: {
    report: '/report',
    reportList: '/report/payroll',
    downlaod: '/report/download?file=',
  },
  ITEM_SOURCE_COST_EACH: {
    baseUrl: REACT_APP_API_BASEURL,
    getItem: `/stores/store-items`,
    addItem: `/stores/store-item`,
    updateItem: `/stores/store-item`,
    deleteItem: `/stores/store-item`,
    getLaborList: `/system/item-types/2/items`,
  },
  CopyUserCoverage: {
    copyUser: `/auth/user/manage/copy-preferences`,
    addCoverage: `/auth/user/manage/coverage-preference`,
  },
  SERVICE_TIME: {
    baseUrl: REACT_APP_API_BASEURL,
    endPoints: {
      getServiceTimeConfig: '/admin-config/service-time/client/%s',
      addServiceTimeById: '/admin-config/service-time/client/%s/config/%s',
      addServiceTimeByFilter: '/admin-config/service-time/client/%s',
    },
  },
  CX_SCHEDULING: {
    baseUrl: REACT_APP_API_BASEURL,
    endPoints: {
      dayWiseSlotsCount: '/admin-config/cx-scheduling-slot/client/%s/counts', // get
      slotsByDay: '/admin-config/cx-scheduling-slot/client/%s/day/%s', // get
      addSlotInDay: '/admin-config/cx-scheduling-slot/client/%s/day/%s', // post
      updateSlotBySlotId: '/admin-config/cx-scheduling-slot/slot/%s', // put
      deleteSlotBySlotId: '/admin-config/cx-scheduling-slot/slot/%s', // delete
      mergeSlotsByDay:
        '/admin-config/cx-scheduling-slot/client/%s/day/%s/slot-merge',
    },
  },
  BUSINESS_HOURS: {
    baseUrl: REACT_APP_API_BASEURL,
    endPoints: {
      workHours: '/admin-config/business/work-hour/client/%s', // get
      addWorkHours: '/admin-config/business/work-hour/client/%s', // post
      holidays: '/admin-config/business/holiday-calendar/client/%s', // get
      addHoliday: '/admin-config/business/holiday-calendar/client/%s', // post
      updateHolidayByHolidayId:
        '/admin-config/business/holiday-calendar/holiday/%s', // put
      deleteHolidayByHolidayId:
        '/admin-config/business/holiday-calendar/holiday/%s', // delete
      slotsByDay: '/admin-config/business-operation-slot/client/%s/day/%s', // get
      addSlotInDay: '/admin-config/business-operation-slot/client/%s/day/%s', // post
      updateSlotBySlotId: '/admin-config/business-operation-slot/slot/%s', // put
      deleteSlotBySlotId: '/admin-config/business-operation-slot/slot/%s', // delete
      mergeBusinessOperationSlotsByDay:
        '/admin-config/business-operation-slot/client/%s/day/%s/slot-merge',
    },
  },
  ROUTES_PATH_CSV_UPLOAD: {
    GET_UPLOAD_TYPE_URL: '/csv-upload/upload-types',
    GET_UPLOADED_DATA_URL: '/csv-upload',
    POST_UPLOADED_DATA_URL: '/csv-upload',
    GET_PRESIGNED_CSV_URL: '/csv-upload/csv-presigned',
  },
  USER_MANAGEMENT: {
    baseUrl: REACT_APP_UM_BASEURL,
    filters: {
      filterList: `/api/user-list/users`,
    },
    endPoints: {
      workScheduleSlotsByHourId:
        '/auth/user/manage/work-schedule/%s/work-hour/%s/slots', // get
      addWorkScheduleSlotInHour:
        '/auth/user/manage/work-schedule/%s/work-hour/%s/slot', // post
      updateWorkScheduleSlotBySlotId:
        '/auth/user/manage/work-schedule/%s/slot/%s', // put
      deleteWorkScheduleSlotBySlotId:
        '/auth/user/manage/work-schedule/%s/slot/%s', // delete
      mergeWorkScheduleSlotsByHourId:
        '/auth/user/manage/work-schedule/%s/work-hour/%s/slot-merge', // post
      mergeAllWorkScheduleSlots:
        '/auth/user/manage/work-schedule/%s/slot-merge-all', // post

      userJobCoverageSlotsCount: `/auth/user/manage/job-coverage/%s/user-job-coverage/%s/slot-count`,
      userJobCoverageSlots:
        '/auth/user/manage/job-coverage/%s/user-job-coverage/slots', // get
      userJobCoverageSlotsByDay:
        '/auth/user/manage/job-coverage/%s/user-job-coverage/%s/value/%s/day/%s/slots', // get
      addUserJobCoverageSlotInDay:
        '/auth/user/manage/job-coverage/%s/user-job-coverage/%s/value/%s/day/%s/slot', // post
      updateUserJobCoverageSlotBySlotId:
        '/auth/user/manage/job-coverage/%s/slot/%s', // put
      deleteUserJobCoverageSlotBySlotId:
        '/auth/user/manage/job-coverage/%s/slot/%s', // delete
      mergeUserJobCoverageSlotByDay:
        '/auth/user/manage/job-coverage/%s/user-job-coverage/%s/value/%s/day/%s/slot-merge', // post
      mergeAllUserJobCoverageSlots:
        '/auth/user/manage/job-coverage/%s/user-job-coverage/%s/value/%s/slot-merge-all', // post
    },
  },

  DOCUMENT: {
    baseUrl: REACT_APP_DOCUMENTS_MS_API,
  },
  LOGIN: {
    baseUrl: REACT_APP_LOGIN_AUTH,
  },
  ISSUES: {
    getSourceStatus: '/master/get-Status/list?statusType=Issue',
  },
  SCHEDULER_FILTERS: {
    saveAdminLevelFilter: '/admin-config/schedulerFilter/save',
    editAdminLevelFilter: '/admin-config/schedulerFilter/update/%s',
    getAdminLevelFilterById:
      '/admin-config/schedulerFilter/get-scheduler-info/%s',
    deleteAdminLevelFilter: '/admin-config/schedulerFilter/delete-filter/%s',
    setAdminLevelDefaultFilter:
      '/admin-config/schedulerFilter/update-status/%s',
    getAdminLevelFilterList: '/admin-config/schedulerFilter/list?%s',

    saveUserLevelFilter: '/admin-config/userSchedulerFilter/save/%s',
    editUserLevelFilter: '/admin-config/userSchedulerFilter/update/%s/%s',
    getUserLevelFilterById:
      '/admin-config/userSchedulerFilter/get-scheduler-info/%s/%s',
    deleteUserLevelFilter:
      '/admin-config/userSchedulerFilter/delete-filter/%s/%s',
    setUserLevelDefaultFilter:
      '/admin-config/userSchedulerFilter/update-status/%s/%s',
    setUserLevelTechnicianDefaultFilter:
      '/admin-config/userSchedulerFilter/update-restrict-technician/%s/%s',
    getUserLevelFilterList: '/admin-config/userSchedulerFilter/list/%s?%s',

    getStoreList: '/master/get-Store/list?search=%s',
    getCategoryList: '/master/Get-Project-Category/list?search=%s',
    getProjectTypeList: '/master/get-Project-Type/list?search=%s',
    getProjectStatusList:
      '/master/get-Status/list?statusType=Project&search=%s',
    getSourceStatusList: '/master/get-Status/list?statusType=Source&search=%s',
    getDistrictList: '/master/Get-Store/district?search=%s',
    getWorkroomList: '/master/get-Workroom/list?search=%s',
    getCustomerList: '/customers?searchString=%s&offset=0&limit=10',
  },
  INVENTORY: {
    baseUrl: REACT_APP_INVENTORY_MS_API,
  },
  GLOBAL_FILTERS: {
    getDefaultFiltersById:
      '/admin-config/userSchedulerFilter/get-scheduler-info/%s',
  },
  NOTIFICATION: {
    baseUrl: REACT_APP_NOTIFICATION_BASEURL,
  },
  QUOTE_TEMPLATE: {
    baseUrl: REACT_APP_CRM_API_BASEURL,
    createQuoteTemplate: `/api/quotes-template/create`,
    updateQuoteTemplate: `/api/quotes-template/update`,
    deleteQuoteTemplate: `/api/quotes-template/delete`,
    getQuoteTemplateList: `/api/quotes-template/list`,
    getTemplateTagList: `/api/tags/list`,
    createTemplateTag: `/api/tags/create`,
  },
};
