import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: true,
  storeList: [],
  typesList: [],
  categoryList: [],
  userCoveragePreference: [],
  totalCount: 0,
  isFilter: false,
};

const userFormCoverageSlice = createSlice({
  name: 'userFormCoverage',
  initialState: initialState,
  reducers: {
    setUserCoverageForm(state, { payload }) {
      state.isLoading = Object.prototype.hasOwnProperty.call(
        payload,
        'isLoading'
      )
        ? payload.isLoading
        : state.isLoading;
      state.storeList = Object.prototype.hasOwnProperty.call(
        payload,
        'storeList'
      )
        ? payload.storeList
        : state.storeList;
      state.typesList = Object.prototype.hasOwnProperty.call(
        payload,
        'typesList'
      )
        ? payload.typesList
        : state.typesList;
      state.categoryList = Object.prototype.hasOwnProperty.call(
        payload,
        'categoryList'
      )
        ? payload.categoryList
        : state.categoryList;
      state.userCoveragePreference = Object.prototype.hasOwnProperty.call(
        payload,
        'userCoveragePreference'
      )
        ? payload.userCoveragePreference
        : state.userCoveragePreference;
      state.totalCount = Object.prototype.hasOwnProperty.call(
        payload,
        'totalCount'
      )
        ? payload.totalCount
        : state.totalCount;
      state.isFilter = Object.prototype.hasOwnProperty.call(payload, 'isFilter')
        ? payload.isFilter
        : state.isFilter;
    },
  },
});

export const { setUserCoverageForm } = userFormCoverageSlice.actions;

export const userFormCoverageReducer = userFormCoverageSlice.reducer;
