export const Notifications = {
  INSTALLER: 1,
  STORE: 2,
  SMS: 3,
  EMAIL: 4,
  BACKENDMAPPING: {
    INSTALLER: 1,
    STORE: 3,
  },
};
