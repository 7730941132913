import React from 'react';

import UserFormContact from './user-form-contact';
import UserFormAddress from './user-form-address';

const UserFormContactAndAdress = () => {
  return (
    <div className="flex flex-column">
      <UserFormContact />
      <UserFormAddress />
    </div>
  );
};

export default UserFormContactAndAdress;
