import axios from 'axios';

import { URL_CONSTANTS } from '../../../../constants/urlConstants';

export const itemKeys = [
  {
    key: 'item_number',
    value: 'Item No',
    width: '10%',
  },
  {
    key: 'item_desc',
    value: 'Description',
    width: '11%',
  },
  {
    key: 'item_type',
    value: 'Type',
    width: '8%',
  },
  {
    key: 'manufacturer',
    value: 'Manufacturer',
    width: '12%',
  },
  {
    key: 'vendor',
    value: 'Vendor',
    width: '10%',
  },
  {
    key: 'quantity',
    value: 'Quantity',
    width: '9%',
  },
  {
    key: 'unitPrice',
    value: 'Unit Price($)',
    prefix: '$',
    width: '10%',
  },
  {
    key: 'discount',
    value: 'Discount(%)',
    postfix: '%',
    width: '8%',
  },
  {
    postfix: '%',
    width: '8%',
  },
  {
    key: 'totalPrice',
    value: 'Total Price($)',
    prefix: '$',
    width: '8%',
  },
  {
    key: 'action',
    value: 'Action',
    width: '12%',
  },
];
export const lineItemValidator = data => {
  let emptyKeyCount = false;

  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key) && data[key] === '') {
      if (!['manufacturer', 'vendor', 'discount', 'tax'].includes(key)) {
        emptyKeyCount = true;
      }
    }
  }
  return emptyKeyCount;
};

export const handleSuggestions = async (searchString = null) => {
  try {
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/system/items?query=${searchString}&limit=10`
    );

    return response?.data;
  } catch (error) {
    console.log(error);
  }
};
