import React, { useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Grid,
  Typography,
  Button,
  Paper,
  Divider,
  Checkbox,
  TextField,
  FormControlLabel,
  CircularProgress,
} from '@material-ui/core';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { useFormik } from 'formik';
import * as Yup from 'yup';
// **** Custom Components ****
import { useDispatch } from 'react-redux';

import PageHeader from '../../shared/PageHeader/PageHeader';
import { Alert } from '../../shared/Alerts/Alert';
import GenericTable from '../../shared/GenericTable/GenericTable';
import GenericDialog from '../../shared/Dialog/GenericDialog';
import GenericConfirmationDialog from '../../shared/GenericConfirmationDialog/GenericConfirmationDialog';
import { useAlerts } from '../../shared/Alerts/alertsService';
import { SOURCE_SYSTEMS } from '../../../constants';
// **** Services *****
import { checkPermission } from '../../../utils/Helpers';
import permissions from '../../../config/permissions';
// **** Styles/Images/Icons ****
import {
  clearStore,
  fetchProjectStores,
} from '../../../redux/slices/master/projectStores.slice';
import {
  clearDistricts,
  fetchDistricts,
} from '../../../redux/slices/master/districts.slice';
import {
  clearWorkrooms,
  fetchWorkrooms,
} from '../../../redux/slices/master/workrooms.slice';
import TableColumnsLoader from '../../shared/Loader/tableColumnsLoader';

import { useStyles } from './AddViewEditStoreDetails.styles';
import {
  getStoreById,
  updateStore,
  addStore,
  getStoreAssociatesRoles,
  createStoreAssociate,
  updateStoreAssociate,
  deleteAssociate,
  addNewAssociateRole,
} from './Stores.service';
import StoreDetailsForm from './StoreDetailsForm';
import ItemSourceCostEach from './ItemSourceCostEach';

const emailAddressesTableHeaders = [
  {
    id: 'position',
    value: 'Position',
    width: '15%',
  },
  {
    id: 'name',
    value: 'Name',
    width: '10%',
  },
  {
    id: 'email',
    value: 'Email',
    width: '15%',
  },
  {
    id: 'send_email',
    value: 'Send Email',
    width: '12%',
  },
];

const AddViewEditStoreDetails = () => {
  let history = useHistory();

  //Get storeId and action from url params
  const { storeId } = useParams();
  const action = window.location.pathname.split('/')[2];

  const [storeDetailsData, setStoreDetailsData] = React.useState({});
  const [associateRoles, setAssociateRoles] = React.useState([]);

  // To show the error
  const { alert, setAlert, clearAlert } = useAlerts();
  const [isOpen, setIsOpen] = React.useState(false);

  const [loadingPosition, setLoadingPosition] = React.useState(false);
  const [formAction, setFormAction] = React.useState('add');
  const [dialogSettings, setDialogSettings] = React.useState({
    title: 'View Issue',
    button1Text: '',
    button2Text: 'Add',
    showButton1: true,
    showButton2: true,
  });
  const [rowIndex, setRowIndex] = React.useState();
  const [confirmDialog, setConfirmDialog] = React.useState({
    header: '',
    title: '',
    subtitle: '',
  });

  const [reloadForm, setReloadForm] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();

  // State for storing Store Details

  // Breadcrumb for Add,View or Edit Project

  const filter = createFilterOptions();

  let emailAddressesDataList = [];

  const projectDetailsBreadcrumb = [
    {
      link: '',
      text: 'Admin',
    },
    {
      link: '/admin/stores',
      text: 'Stores',
    },
    {
      text:
        action === 'add'
          ? 'New Store'
          : action === 'edit'
            ? 'Edit Store Details'
            : 'Store Details',
    },
  ];

  const clearMasterData = () => {
    dispatch(clearStore());
    dispatch(clearDistricts());
    dispatch(clearWorkrooms());
  };

  const fetchMasterData = () => {
    setTimeout(() => {
      dispatch(fetchProjectStores());
      dispatch(fetchDistricts());
      dispatch(fetchWorkrooms());
    }, 2000);
  };

  // **** Data Modification *****
  if (storeDetailsData?.associates?.count) {
    emailAddressesDataList = storeDetailsData?.associates?.items?.map(val => {
      const associate = associateRoles.find(
        role => role.store_associate_role_id === val.store_associate_role_id
      );
      return {
        position: {
          value: associate?.role_name || '',
          associate: associate,
        },
        name: {
          value: val.name,
        },
        email: {
          value: val.email_id,
        },
        send_email: {
          value: val.send_email ? 'Yes' : 'No',
          booleanValue: val.send_email,
        },
      };
    });
  }

  // **** Form Validation Schema ****
  const StoreFormSchema = Yup.object().shape({
    source: Yup.object().required('Required').nullable(),
    division: Yup.string().trim().nullable(),
    region: Yup.string().trim().nullable(),
    district: Yup.string().trim().nullable(),
    store_name: Yup.string()
      .trim()
      .required('Required')
      .matches(/^[A-Za-z0-9',. ]+$/, 'Please enter a valid Store Name.'),
    store_number: Yup.string().trim().required('Required'),
    is_store_active: Yup.boolean(),
    address: Yup.string().trim().nullable(),
    city: Yup.string()
      .trim()
      .required('Required')
      .matches(/^[a-zA-ZÀ-ÿ-.' ]*$/, 'Please enter a valid City Name.'),
    state: Yup.string().trim().required('Required'),
    zip: Yup.number()
      .typeError('Please enter numbers only')
      .min(1)
      .required('Required'),
    phone_number: Yup.string().trim().nullable(),
    phone_area_city_code: Yup.string().trim().nullable(),
    pse_name: Yup.string().trim().nullable(),
    pse_email: Yup.string().trim().email('Invalid Email').nullable(),
    latitude: Yup.string().trim().nullable(),
    longitude: Yup.string().trim().nullable(),
    type_id: Yup.object().nullable(),
  });

  //** Formik object **
  const formik = useFormik({
    initialValues: {
      source:
        action === 'add'
          ? null
          : {
              source_system_id: storeDetailsData?.source_system_id,
              source_name: storeDetailsData?.source_name,
              logo_url: storeDetailsData?.logo_url,
            },
      logo_url: action === 'add' ? '' : storeDetailsData?.logo_url,
      division: action === 'add' ? '' : storeDetailsData?.division,
      region: action === 'add' ? '' : storeDetailsData?.region,
      district: action === 'add' ? '' : storeDetailsData?.district,
      store_name: action === 'add' ? '' : storeDetailsData?.store_name,
      store_number: action === 'add' ? '' : storeDetailsData?.store_number,
      is_store_active:
        action === 'add'
          ? true
          : storeDetailsData.is_deleted === 1
            ? false
            : true,
      is_private:
        action === 'add'
          ? true
          : storeDetailsData.is_private === 1
            ? true
            : false,
      address: action === 'add' ? '' : storeDetailsData?.address1,
      city: action === 'add' ? '' : storeDetailsData?.city,
      state: action === 'add' ? '' : storeDetailsData?.state,
      zip: action === 'add' ? '' : storeDetailsData?.zipcode,
      phone_number: action === 'add' ? '' : storeDetailsData?.phone_number,
      phone_area_city_code:
        action === 'add' ? '' : storeDetailsData?.phonearea_citycode,
      type_id:
        action === 'add'
          ? ''
          : {
              type_id: storeDetailsData?.type_id,
              label: storeDetailsData?.label,
            },
      latitude:
        action === 'add'
          ? ''
          : storeDetailsData?.latitude
            ? parseFloat(storeDetailsData?.latitude)
            : '',
      longitude:
        action === 'add'
          ? ''
          : storeDetailsData?.longitude
            ? parseFloat(storeDetailsData?.longitude)
            : '',
      pse_name: action === 'add' ? '' : storeDetailsData?.pse_name,
      pse_email: action === 'add' ? '' : storeDetailsData?.pse_email,
      installer_id: action === 'add' ? '' : storeDetailsData?.installer_id,
      master_warehouse_id:
        action === 'add' ? '' : storeDetailsData?.master_warehouse_id,
    },
    validationSchema: StoreFormSchema,
    onSubmit: values => {
      if (action === 'edit') {
        setLoading(true);
        updateStore(
          storeId,
          values,
          history,
          setReloadForm,
          setLoading,
          setAlert,
          clearMasterData,
          fetchMasterData
        );
      } else {
        addStore(
          values,
          history,
          setLoading,
          setAlert,
          setReloadForm,
          clearMasterData,
          fetchMasterData
        );
      }
    },
    enableReinitialize: true,
  });

  // **** Associate Email Form Validation Schema ****
  const AssociateValidationSchema = Yup.object().shape({
    position: Yup.object().required('Required'),
    name: Yup.string().trim().required('Required'),
    email: Yup.string()
      .trim()
      .email('Invalid email format')
      .required('Required'),
    send_email: Yup.string().trim(),
  });

  // **** Formik Form Values ****
  const associateDetailsFormik = useFormik({
    initialValues: {
      position:
        (formAction !== 'add' &&
          emailAddressesDataList[rowIndex]?.position.associate) ||
        '',
      name:
        (formAction !== 'add' &&
          emailAddressesDataList[rowIndex]?.name.value) ||
        '',
      email:
        (formAction !== 'add' &&
          emailAddressesDataList[rowIndex]?.email.value) ||
        '',
      send_email:
        (formAction !== 'add' &&
          emailAddressesDataList[rowIndex]?.send_email.booleanValue) ||
        '',
    },
    onSubmit: (values, { resetForm }) => {
      if (formAction === 'add') {
        createStoreAssociate(
          storeDetailsData?.store_id,
          {
            store_associate_role_id:
              associateDetailsFormik.values.position.store_associate_role_id,
            name: associateDetailsFormik.values.name.trim() || null,
            email_id: associateDetailsFormik.values.email,
            send_email: associateDetailsFormik.values.send_email ? 1 : 0,
          },
          setLoading,
          setAlert,
          setReloadForm,
          setIsOpen
        );
      } else {
        updateStoreAssociate(
          storeDetailsData?.store_id,
          storeDetailsData.associates.items[rowIndex]?.store_associate_id,
          {
            store_associate_role_id:
              associateDetailsFormik.values.position.store_associate_role_id,
            name: associateDetailsFormik.values.name.trim() || null,
            email_id: associateDetailsFormik.values.email,
            send_email: associateDetailsFormik.values.send_email ? 1 : 0,
          },
          setLoading,
          setAlert,
          setReloadForm,
          setIsOpen
        );
      }
      //setSubmitting(false);
      setReloadForm(false);
      resetForm();
    },
    validationSchema: AssociateValidationSchema,
    enableReinitialize: true,
  });

  React.useEffect(() => {
    if (action !== 'view') {
      clearAlert();
    }
    if (action !== 'add') {
      getStoreAssociatesRoles(setLoading, setAssociateRoles);
      getStoreById(
        storeId,
        setLoading,
        setAlert,
        setStoreDetailsData,
        setReloadForm
      );
    }
  }, []);

  // Pre-populate all the fields in view or edit mode
  React.useEffect(() => {
    if (action !== 'add') {
      if (reloadForm) {
        getStoreAssociatesRoles(setLoading, setAssociateRoles);
        getStoreById(
          storeId,
          setLoading,
          setAlert,
          setStoreDetailsData,
          setReloadForm
        );
      }
    }
  }, [reloadForm, action]);

  const classes = useStyles({ status: formik?.values?.status?.status });

  //*** Associate - Action Handlers ***/

  const addNewAssociate = () => {
    setFormAction('add');

    associateDetailsFormik?.setValues({
      position: '',
      name: '',
      email: '',
      send_email: '',
    });

    setDialogSettings(prevState => ({
      ...prevState,
      showButton2: true,
      button2Text: 'Save',
      title: 'Add Associate',
    }));
    setIsOpen(true);
  };

  const viewEditAssociate = (action, index) => {
    setDialogSettings(prevState => ({
      ...prevState,
      showButton2: action === 'view' ? false : true,
      button2Text: action === 'edit' ? 'Save' : '',
      title: (action === 'view' && 'View Associate') || 'Edit Associate',
    }));
    setRowIndex(index);
    setFormAction(action);

    setIsOpen(true);
  };

  const deleteAssociateConfirmation = index => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: true,
      title: `Are you sure you want to Delete Associate?`,
      header: 'Delete Associate',
    });
    setRowIndex(index);
  };

  const onAssociateDeleteConfirmDialog = async () => {
    setReloadForm(false);
    deleteAssociate(
      storeDetailsData.store_id,
      storeDetailsData.associates.items[rowIndex]?.store_associate_id,
      setLoading,
      setAlert,
      setReloadForm,
      setConfirmDialog
    );
  };

  const getAssociateViewEditForm = () => {
    return (
      <Grid container spacing={2} direction="column">
        {/* {JSON.stringify(associateDetailsFormik, null, 2)} */}
        <Grid item xs={12}>
          <Autocomplete
            id="position"
            name="position"
            options={associateRoles || []}
            getOptionLabel={option => {
              if (typeof option === 'string') {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
              // Regular option
              return option.role_name || '';
            }}
            renderInput={params => (
              <TextField
                {...params}
                required={formAction !== 'view'}
                label="Position"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loadingPosition ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
            renderOption={option => option.role_name || option.title}
            value={associateDetailsFormik?.values?.position || ''}
            freeSolo
            onChange={async (event, value) => {
              if (typeof value === 'string') {
                associateDetailsFormik.setFieldValue('position', {
                  role_name: value,
                  role_category: 'Store',
                });
              } else if (value && value.inputValue) {
                // Create a new value from the user input
                setLoadingPosition(true);
                const responseAssociates = await addNewAssociateRole(
                  {
                    role_name: value.inputValue,
                    role_category: 'Store',
                  },
                  setAlert,
                  setAssociateRoles
                );

                const updateAssociate = responseAssociates.find(
                  role => role.role_name === value.inputValue
                );

                associateDetailsFormik.setFieldValue(
                  'position',
                  updateAssociate
                );
                setLoadingPosition(false);
              } else {
                associateDetailsFormik.setFieldValue('position', value);
              }

              // associateDetailsFormik.setFieldValue('position', value);
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              // Suggest the creation of a new value
              if (params.inputValue !== '') {
                filtered.push({
                  inputValue: params.inputValue,
                  title: `Add "${params.inputValue}"`,
                });
              }

              return filtered;
            }}
            required={formAction !== 'view'}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            onBlur={associateDetailsFormik.handleBlur}
            disabled={formAction === 'view'}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="name"
            label="Name"
            name="name"
            onChange={associateDetailsFormik.handleChange}
            onBlur={associateDetailsFormik.handleBlur}
            required={formAction !== 'view'}
            InputProps={{
              readOnly: formAction === 'view' ? true : false,
            }}
            value={associateDetailsFormik?.values?.name}
            InputLabelProps={{ shrink: true }}
            className={classes.input}
            disabled={formAction === 'view'}
          />
        </Grid>

        <Grid item>
          <TextField
            id="email"
            label="Email"
            name="email"
            required={formAction !== 'view'}
            onChange={event => {
              associateDetailsFormik.setFieldValue(
                'email',
                event?.target?.value
              );
            }}
            onBlur={associateDetailsFormik.handleBlur}
            InputProps={{
              readOnly: formAction === 'view' ? true : false,
            }}
            value={associateDetailsFormik?.values?.email}
            InputLabelProps={{ shrink: true }}
            className={classes.input}
            disabled={formAction === 'view'}
          />
        </Grid>

        <Grid item>
          <FormControlLabel
            value="top"
            name="send_email"
            control={
              <Checkbox
                color="primary"
                classes={{
                  root: classes.checkboxRoot,
                }}
                onChange={(event, value) => {
                  associateDetailsFormik.setFieldValue('send_email', value);
                }}
                checked={associateDetailsFormik?.values?.send_email}
              />
            }
            disabled={formAction === 'view'}
            label="Send Email"
            labelPlacement="top"
            classes={{
              root: classes.checkboxFormRoot,
              labelPlacementTop: classes.checkboxLabelPlacement,
              label: classes.checkboxLabel,
            }}
          />
        </Grid>
      </Grid>
    );
  };

  const emailAddressTableLoader = useMemo(() => {
    return TableColumnsLoader(emailAddressesTableHeaders, {
      rows: 4,
      isValue: true,
    });
  }, [emailAddressesTableHeaders]);
  return (
    <>
      {/* {JSON.stringify(formik, null, 2)} */}
      <form className={classes.formWrapper}>
        <Grid container direction="column" spacing={2}>
          {alert.exists && (
            <Grid item>
              {' '}
              <Alert />
            </Grid>
          )}
          <Grid
            container
            item
            direction="row"
            justifyContent="space-between"
            spacing={2}
          >
            <Grid item>
              <PageHeader
                pageTitle={
                  action === 'add'
                    ? 'New Store'
                    : action === 'edit'
                      ? 'Edit Store Details'
                      : 'Store Details'
                }
                breadCrumbArray={projectDetailsBreadcrumb}
              />
            </Grid>

            <Grid item classes={{ root: classes.selfAlignGrid }}>
              <Grid
                container
                item
                direction="row"
                justifyContent="flex-start"
                spacing={2}
              >
                {action === 'view' ? (
                  <>
                    {checkPermission(permissions?.stores?.storesEdit) && (
                      <Grid item>
                        <Button
                          color="primary"
                          variant="outlined"
                          disabled={loading}
                          onClick={() =>
                            history.push(`/stores/edit/${storeId}`)
                          }
                        >
                          Edit
                        </Button>
                      </Grid>
                    )}
                    {checkPermission(permissions?.stores?.storesAdd) && (
                      <Grid item>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() => {
                            history.push('/stores/add');
                          }}
                        >
                          Add New
                        </Button>
                      </Grid>
                    )}
                  </>
                ) : (
                  <>
                    <Grid item>
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => {
                          if (action === 'edit') {
                            formik.resetForm();
                            history.push(`/stores/view/${storeId}`);
                          } else {
                            history.goBack();
                          }
                        }}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        color="primary"
                        variant="contained"
                        disabled={!formik?.dirty || !formik.isValid || loading}
                        onClick={() => {
                          formik.handleSubmit();
                        }}
                      >
                        Save
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item className={classes.fullWidthGridItem}>
            <Paper square className={classes.paperRoot} elevation={0}>
              <Grid container direction="column">
                <Grid
                  container
                  item
                  direction="row"
                  justifyContent="space-between"
                  className={classes.containerHeader}
                  xs={12}
                >
                  <Grid
                    container
                    item
                    xs={4}
                    spacing={1}
                    alignItems="center"
                    classes={{ root: classes.statusWrapper }}
                    className="mb-3"
                  >
                    <Grid item>
                      <Typography variant="h3">Store Details</Typography>
                    </Grid>
                  </Grid>

                  {/* It is comment as of now and based on final discussion this will be uncomment and remove */}
                  {/* <Grid
                    container
                    item
                    xs={9}
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={1}
                    classes={{ root: classes.buttonWrapper }}
                  >
                    <Grid item>
                      <img
                        alt="Business"
                        width="15"
                        height="15"
                        src={'/images/formIcons/Close.png'}
                      />
                    </Grid>
                  </Grid> */}
                </Grid>
                <Divider />

                <Grid item className={classes.containerContent}>
                  <StoreDetailsForm
                    loading={loading}
                    setLoading={setLoading}
                    formik={formik}
                    action={action}
                    associateRoles={associateRoles}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          {action !== 'add' ? (
            <Grid item className={classes.fullWidthGridItem}>
              <Grid container direction="column" className="p-3 pt-1 bg-white">
                <Grid item className={classes.fullWidthGridItem}>
                  <GenericTable
                    title="Email Addresses"
                    columnData={emailAddressesTableHeaders}
                    rowData={
                      loading ? emailAddressTableLoader : emailAddressesDataList
                    }
                    {...(!loading
                      ? {
                          showActions: { view: true, edit: true, delete: true },
                        }
                      : {})}
                    headerLinks={[
                      {
                        label: 'Add Item',
                        handler: addNewAssociate,
                      },
                    ]}
                    handleView={index => viewEditAssociate('view', index)}
                    handleEdit={index => viewEditAssociate('edit', index)}
                    handleDelete={index => deleteAssociateConfirmation(index)}
                  />
                </Grid>
              </Grid>
            </Grid>
          ) : (
            ''
          )}
          {formik.values?.source?.source_system_id === SOURCE_SYSTEMS.LOWES &&
          action !== 'add' ? (
            <ItemSourceCostEach />
          ) : null}
        </Grid>
      </form>
      <GenericDialog
        fullwidth
        isOpen={isOpen}
        handleClose={() => {
          setIsOpen(false);
          associateDetailsFormik?.handleReset();
        }}
        handleSave={associateDetailsFormik.handleSubmit}
        dialogSettings={dialogSettings}
        disabledButton2={
          !associateDetailsFormik?.touched ||
          !associateDetailsFormik?.dirty ||
          (associateDetailsFormik?.dirty && !associateDetailsFormik?.isValid)
        }
        disabledButton1={false}
      >
        <form> {getAssociateViewEditForm()}</form>
      </GenericDialog>
      {/* Confirmation dialog for delete */}
      <GenericConfirmationDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        onConfirmDialog={onAssociateDeleteConfirmDialog}
      />
    </>
  );
};

export default AddViewEditStoreDetails;
