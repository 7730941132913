import { ProjectDetailsData } from '../interface/Photos.interface';

//Photo Type Helper
export const getDocStoreTypeName = (type?: null | string) => {
  let response = null;
  if (type === 'INTERNAL') {
    response = 'Internal Photo';
  } else if (type === 'SOURCE') {
    response = 'Send to Source';
  } else if (type === 'COMPLETE') {
    response = 'Complete';
  } else if (type === 'NEEDS_REVIEW') {
    response = 'Needs Review';
  }
  return response;
};
//ToolTilContent
export const handleTooltipContent = (value: {
  docCreatedBy?: string;
  docCreatedDate?: string;
}) => {
  const truncatedCreatedBy =
    value?.docCreatedBy && value?.docCreatedBy?.length > 150
      ? `${value?.docCreatedBy?.split(' ')?.slice(0, 15)?.join(' ')} |||`
      : value?.docCreatedBy;

  const content = `${truncatedCreatedBy} (${value?.docCreatedDate})`;

  return content;
};

//
export const showIsLeadRequiredAlertLowes = (
  projectDetailsData?: ProjectDetailsData
) => {
  if (
    (projectDetailsData?.epa_lead_assessment_required === 1 ||
      projectDetailsData?.epa_lead_remediation === 1) &&
    projectDetailsData?.project_lead_response_reason === null
  ) {
    return true;
  } else {
    return false;
  }
};

export const showIsLeadRequiredAlertHD = (
  projectDetailsData?: ProjectDetailsData
) => {
  if (
    (projectDetailsData?.epa_lead_assessment_required === 1 ||
      projectDetailsData?.epa_lead_remediation === 1) &&
    projectDetailsData?.epa_override_reason_code_id === null
  ) {
    return true;
  } else {
    return false;
  }
};
