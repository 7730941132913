import React, { useState } from 'react';

import { formateNumber } from '../helpers/Quotes';
import PFInputNumber from '../../../shared/PFPrime/PFInputNumber';
import PFFormikError from '../../../shared/PFPrime/PFFormikError';
const QtyEditor = ({
  rowData,
  editingRows,
  submitAction,
  onQtyBlur,
  retailerPercentage,
}) => {
  const { values, touched, errors, setFieldValue, setValues } = submitAction;
  const [value, setValue] = useState(formateNumber(values?.quantity || 0));
  const isEditing = editingRows[rowData.rowIndex];
  return isEditing ? (
    <div className="vertical-align-top">
      <span className="p-float-label">
        <PFInputNumber
          inputId="quantity"
          name="quantity"
          minFractionDigits={2}
          value={value}
          onChange={(name, value) => setValue(value)}
          onBlur={() => {
            setFieldValue('quantity', value);
            onQtyBlur(value, values, setValues, retailerPercentage);
          }}
          className={
            touched?.quantity && errors?.quantity ? 'error w-full' : 'w-full'
          }
          version={2}
          inputStyle={{ width: '100px' }}
        />
      </span>
      <PFFormikError touched={touched} errors={errors} field="quantity" />
    </div>
  ) : (
    <div className="pt-2">{`${formateNumber(rowData?.quantity).toFixed(2)}`}</div>
  );
};

export default QtyEditor;
