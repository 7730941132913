import React, { useRef } from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Button } from 'primereact/button';
import { CSVLink } from 'react-csv';

const ExportWidgetTableData = ({
  exportData,
  widget,
  groupByHandler,
  filename = 'export',
}) => {
  const exportOverlayRef = useRef(null);

  const hideExportOverlay = () => {
    exportOverlayRef?.current?.hide();
  };

  const isValidUrl = urlString => {
    try {
      return Boolean(new URL(urlString));
    } catch (error) {
      console.error(error);
      return false;
    }
  };
  let urlReport = false;

  let exportCSVData = exportData?.slice(1)?.map(val => {
    return val?.map(arrVal => {
      if (isValidUrl(arrVal)) {
        urlReport = true;
        return `=HYPERLINK(""${arrVal}"")`;
      } else {
        return arrVal;
      }
    });
  });
  let newExportCSVData = [exportData[0], ...exportCSVData];

  const exportPdf = () => {
    import('jspdf').then(jsPDF => {
      import('jspdf-autotable').then(() => {
        if (Array.isArray(exportData) && exportData?.length > 0) {
          const doc = new jsPDF.default('l', 'pt');
          const exportColumns = exportData[0];
          const pdfData = exportData?.slice(1);
          doc.autoTable(exportColumns, pdfData, {
            styles: { fontSize: 6, cellWidth: urlReport && 58 },
            willDrawCell: data => {
              if (isValidUrl(data?.cell?.raw)) {
                doc.link(data.cell.x, data.cell.y, 20, 20, {
                  url: data.cell.text.join(''),
                });
                data.cell.text = 'Click Here';
              }
            },
          });
          doc.save(filename);
        }
      });
    });
    hideExportOverlay();
  };
  return (
    <div className="flex gap-2">
      {widget?.backToGroupBy && (
        <Button
          size="small"
          severity="primary"
          variant="contained"
          onClick={groupByHandler}
        >
          Cancel Preview
        </Button>
      )}
      {widget?.report_group_by ? (
        <Button
          severity="primary"
          size="small"
          variant="contained"
          onClick={groupByHandler}
        >
          Export Preview
        </Button>
      ) : (
        <>
          <Button
            severity="primary"
            variant="contained"
            outlined
            size="small"
            onClick={e => exportOverlayRef?.current?.toggle(e)}
          >
            Export
          </Button>
          <OverlayPanel ref={exportOverlayRef}>
            <div className="flex flex-column gap-2">
              <CSVLink data={newExportCSVData} filename={filename}>
                <Button
                  size="small"
                  severity="primary"
                  outlined
                  onClick={hideExportOverlay}
                >
                  Export in CSV
                </Button>
              </CSVLink>
              <Button
                size="small"
                severity="primary"
                outlined
                onClick={exportPdf}
              >
                Export in PDF
              </Button>
            </div>
          </OverlayPanel>
        </>
      )}
    </div>
  );
};

export default ExportWidgetTableData;
