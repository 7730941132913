export const QUESTION_TYPES = {
  text: {
    id: 'text',
    label: 'Text',
  },
  rating5: {
    id: 'rating5',
    label: 'Rating 1 - 5',
  },
  rating10: {
    id: 'rating10',
    label: 'Rating 1 - 10',
  },
  list: {
    id: 'list',
    label: 'List',
  },
  table: {
    id: 'table',
    label: 'Table',
  },
  card: {
    id: 'card',
    label: 'Card',
  },
};
