import axios, { AxiosError } from 'axios';

import { IssueItem, ItemParams } from '../Interface/issues.interface';
import { URL_CONSTANTS } from '../../../../constants/urlConstants';
import apiService from '../../../../services/api.service';

export const fetchIssuesData = async (projectId: number) => {
  try {
    const response = await apiService.get(
      apiService.formatUrl(
        URL_CONSTANTS.PROJECTS.issuesTab.fetchIssues,
        projectId
      )
    );

    return {
      error: false,
      message: 'Issues data fetched successfully',
      issuesData: response,
    };
  } catch (error: unknown) {
    console.error('Error fetching issues data:', error);
    return {
      error: true,
      message:
        error instanceof AxiosError
          ? error?.response?.data?.error
          : 'Error fetching issues data',
      billingData: null,
    };
  }
};

export const getOriginatingFromList = async () => {
  try {
    const response = await apiService.get(
      URL_CONSTANTS.PROJECTS.issuesTab.fetchOriginatingFrom
    );
    return {
      error: false,
      message: 'Originated from fetched successfully',
      options: response,
    };
  } catch (error: unknown) {
    console.error('Error fetching Originated from data:', error);
    return {
      error: true,
      message:
        error instanceof AxiosError
          ? error?.response?.data?.error
          : 'Error fetching Originated from data',
      options: [],
    };
  }
};

export const getCategoryList = async () => {
  try {
    const response = await apiService.get(
      URL_CONSTANTS.PROJECTS.issuesTab.fetchIssueCategory
    );
    return {
      error: false,
      message: 'Category fetched successfully',
      options: response,
    };
  } catch (error) {
    console.error('Error fetching category data:', error);
    return {
      error: true,
      message:
        error instanceof AxiosError
          ? error?.response?.data?.error
          : 'Error fetching category data',
      options: [],
    };
  }
};

export const getSourceStatusList = async () => {
  try {
    const response = await apiService.get(URL_CONSTANTS.ISSUES.getSourceStatus);
    return {
      error: false,
      message: 'Status fetched successfully',
      options: response?.data,
    };
  } catch (error) {
    console.error('Error fetching Status data:', error);
    return {
      error: true,
      message:
        error instanceof AxiosError
          ? error?.response?.data?.error
          : 'Error fetching Status data',
      options: [],
    };
  }
};

export const getItems = async (query: ItemParams | null) => {
  try {
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}${URL_CONSTANTS.ITEM_SOURCE_COST_EACH.getLaborList}`,
      {
        params: query,
      }
    );

    return {
      error: false,
      message: 'Items fetched successfully',
      options: response.data,
    };
  } catch (error) {
    console.error('Error fetching Items data:', error);
    return {
      error: true,
      message:
        error instanceof AxiosError
          ? error?.response?.data?.error
          : 'Error fetching Items data',
      options: [],
    };
  }
};
export const getItemsWithId = async (itemId: number | null) => {
  try {
    const response = await apiService.get(
      apiService.formatUrl(
        URL_CONSTANTS.PROJECTS.issuesTab.fetchItemsWithId,
        itemId
      )
    );

    return {
      error: false,
      message: 'Items fetched successfully',
      options: response,
    };
  } catch (error) {
    console.error('Error fetching Items data:', error);
    return {
      error: true,
      message:
        error instanceof AxiosError
          ? error?.response?.data?.error
          : 'Error fetching Items data',
      options: [],
    };
  }
};

export const createIssue = async (projectId: number, values: IssueItem) => {
  try {
    const response = await apiService.post(
      apiService.formatUrl(
        URL_CONSTANTS.PROJECTS.issuesTab.fetchIssues,
        projectId
      ),
      values
    );
    return {
      error: false,
      message: 'Issues added successfully',
      response: response,
    };
  } catch (error) {
    console.error('Error adding Issues:', error);
    return {
      error: true,
      message:
        error instanceof AxiosError
          ? error?.response?.data?.error
          : 'Error adding Issues',
      response: {},
    };
  }
};

export const updateIssue = async (
  projectId: number,
  issueId: number,
  values: IssueItem
) => {
  try {
    const response = await apiService.patch(
      apiService.formatUrl(
        URL_CONSTANTS.PROJECTS.issuesTab.urlWithIssueId,
        projectId,
        issueId
      ),
      values
    );
    return {
      error: false,
      message: 'Issues updated successfully',
      response: response,
    };
  } catch (error) {
    console.error('Error updating Issues:', error);
    return {
      error: true,
      message:
        error instanceof AxiosError
          ? error?.response?.data?.error
          : 'Error updating Issues',
      response: {},
    };
  }
};

export const deleteIssue = async (projectId: number, issueId: number) => {
  try {
    const response = await apiService.delete(
      apiService.formatUrl(
        URL_CONSTANTS.PROJECTS.issuesTab.urlWithIssueId,
        projectId,
        issueId
      )
    );
    return {
      error: false,
      message: 'Issues deleted successfully',
      response: response,
    };
  } catch (error) {
    console.error('Error in deleting Issues:', error);
    return {
      error: true,
      message:
        error instanceof AxiosError
          ? error?.response?.data?.error
          : 'Error in deleting Issues',
      response: {},
    };
  }
};
