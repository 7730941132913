import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Message } from 'primereact/message';

import PFInputText from '../../../shared/PFPrime/PFInputText';
import PFButton from '../../../shared/PFPrime/PFButton';
import PFInputNumber from '../../../shared/PFPrime/PFInputNumber';

const PaymentTerms = ({
  setFormPayload,
  formPayload,
  isVersionActive = true,
}) => {
  const queryParams = useParams();
  const { mode } = queryParams;
  const [conditions, setConditions] = useState([]);
  const [title, setTitle] = useState('');
  const [payment, setPayment] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const onCancel = delteIndex => {
    const newArray = conditions.filter((_, index) => index !== delteIndex);
    setConditions(newArray);
  };

  useEffect(() => {
    setFormPayload(preValues => ({ ...preValues, paymentTerms: conditions }));
  }, [conditions]);

  useEffect(() => {
    setConditions(formPayload?.paymentTerms || []);
  }, []);

  return (
    <div
      className="w-full py-3"
      style={{ overflow: 'auto', minHeight: '500px' }}
    >
      {mode !== 'view' && isVersionActive && (
        <>
          <div className="p-inputgroup">
            <PFInputText
              placeholder={'Payment Terms'}
              onChange={e => setTitle(e.target.value)}
              value={title || ''}
              className="xl:w-8 md:w-6 w-7"
            />
            <PFInputNumber
              placeholder={'Payment'}
              name="payment"
              onChange={(name, value) => {
                setPayment(value);
                setErrorMessage('');
              }}
              mode={'currency'}
              currency="USD"
              minFractionDigits={2}
              value={payment || ''}
              className="xl:w-3 md:w-3 w-3"
            />

            <PFButton
              label={'Add'}
              icon={'pi pi-plus'}
              onClick={() => {
                setConditions(preValues => [
                  ...preValues,
                  { description: title, payment: payment },
                ]);

                setTitle('');
                setPayment(0);
                setErrorMessage('');
              }}
              outlined={false}
              disabled={title.trim() && payment ? false : true}
              className="xl:w-1 lg:w-1 md:w-2 sm:w-2"
            />
          </div>
          {errorMessage && (
            <span style={{ color: 'red' }} className="text-sm ml-1">
              {errorMessage}
            </span>
          )}
        </>
      )}

      <div>
        {conditions?.length <= 0 && mode === 'view' ? (
          <Message
            severity="info"
            text="Payment terms not available."
            className="border-primary w-full justify-content-start "
            style={{
              border: 'solid #696cff',
              borderWidth: '0 0 0 6px',
            }}
            pt={{
              root: {
                className: 'surface-ground',
              },
            }}
          />
        ) : (
          <>
            <div className="p-datatable p-component p-datatable-responsive-scroll">
              <div
                className="p-datatable-wrapper py-0"
                style={{ overflow: 'auto', minHeight: '500px' }}
              >
                <table className="p-datatable-table w-full">
                  <tbody className="p-datatable-tbody">
                    {conditions?.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <div className="flex flex-wrap align-items-center">
                            <span className="mr-2">{`${index + 1}. ${item?.description || ''} : $${
                              parseFloat(item?.payment)?.toFixed(2) || ''
                            }`}</span>
                            {mode !== 'view' && (
                              <PFButton
                                icon="pi pi-times"
                                outlined
                                text
                                severity="primary"
                                className="text-white border-none"
                                aria-label="Cancel"
                                onClick={() => onCancel(index)}
                              />
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PaymentTerms;
