export const defaultFilterDisplayHeader = {
  customerList: { key: 'Client Name', value: 'first_name' },
  districtList: { key: 'District', value: 'district' },
  projectCategoryList: { key: 'Category', value: 'category' },
  projectTypeList: { key: 'Project Type', value: 'project_type' },
  sourceStatusList: { key: 'Source Status', value: 'status' },
  statusList: { key: 'Status', value: 'status' },
  storeList: { key: 'Store', value: ['store_number', 'store_name'] },
  workroomList: { key: 'Workroom', value: 'label' },
  technicianList: {
    key: 'Technicians',
    value: ['meta.first_name', 'meta.last_name'],
  },
};

export const displayFilterHeader = [
  {
    key: 'customers',
    value: ['Client Name', 'label'],
  },
  {
    key: 'stores',
    value: ['Stores', 'store_name'],
  },
  {
    key: 'projectCategories',
    value: ['Category', 'category'],
  },
  {
    key: 'projectTypes',
    value: ['Project Type', 'project_type'],
  },
  {
    key: 'projectStatus',
    value: ['Status', 'status'],
  },
  {
    key: 'projectWorkrooms',
    value: ['Workroom', 'label'],
  },
  {
    key: 'techniciansVal',
    value: ['Technician', 'full_name'],
  },
  {
    key: 'sourceStatus',
    value: ['Source Status', 'status'],
  },
  {
    key: 'districtId',
    value: ['District'],
  },
  {
    key: 'userType',
    value: ['User Type', 'label'],
  },
  {
    key: 'dateScheduledFilterStart',
    value: ['Schedule Start Date'],
  },
  {
    key: 'dateScheduledFilterEnd',
    value: ['Schedule End Date'],
  },
  {
    key: 'dateCompletionFilterStart',
    value: ['Completion Start Date'],
  },
  {
    key: 'dateCompletionFilterEnd',
    value: ['Completion End Date'],
  },
  {
    key: 'checkDateFromFilter',
    value: ['Check Date From'],
  },
  {
    key: 'checkDateToFilter',
    value: ['Check Date To'],
  },
  {
    key: 'dateSoldFromFilter',
    value: ['Date Sold From'],
  },
  {
    key: 'dateSoldToFilter',
    value: ['Date Sold To'],
  },
];
