import React, { useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import type { ColumnBodyOptions } from 'primereact/column';
import { Button } from 'primereact/button';
import { Skeleton } from 'primereact/skeleton';
import { Toast } from 'primereact/toast';

import { BillingItem, ColumnMeta } from '../Interface/billing.interface';
import permissions from '../../../../config/permissions';
import { renderDate } from '../../../../utils/dates.helper';
import { deletePayment } from '../service/billing.service';
import { CURRENCY } from '../../../../constants/currency.constant';
import { useConfirmDialogContext } from '../../../../contexts/ConfirmDialog';

import PaymentRequestsForm from './PaymentRequestsForm';
const PaymentRequestsTable: React.FC<{
  paymentRequestsDisplayList: BillingItem[] | undefined;
  loading: boolean;
  project_id: number;
  loadBillingData: () => void;
  toast: React.RefObject<Toast | null>;
}> = ({
  paymentRequestsDisplayList,
  loading,
  project_id,
  loadBillingData,
  toast,
}) => {
  const [dialogFormObj, setDialogFormObj] = useState({
    visible: false,
    formAction: null as string | null,
    index: null as number | null,
  });
  const { showConfirmationDialog } = useConfirmDialogContext();
  const updateDialogFormObj = (newState: Partial<typeof dialogFormObj>) => {
    setDialogFormObj(prev => ({ ...prev, ...newState }));
  };
  const viewEditPaymentRequest = (formAction: string, index: number) => {
    updateDialogFormObj({
      visible: true,
      formAction: formAction,
      index: index,
    });
  };
  const handleAccept = async (rowIndex: number) => {
    if (paymentRequestsDisplayList?.[rowIndex]?.payment_request_id) {
      const response = await deletePayment(
        project_id,
        paymentRequestsDisplayList?.[rowIndex].payment_request_id
      );
      if (!response.error) {
        toast.current?.show({
          severity: 'success',
          summary: 'Success',
          detail: response.message,
          life: 3000,
        });
        loadBillingData();
      } else {
        toast.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: response?.message,
          life: 3000,
        });
      }
    }
  };

  const deletePaymentRequest = (rowIndex: number) => {
    showConfirmationDialog({
      message: 'Are you sure you want to delete Payment Request ?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      defaultFocus: 'accept',
      accept: () => handleAccept(rowIndex),
    });
  };
  const actionsTemplate = (rowIndex: number) =>
    !loading ? (
      <div className="inline-flex align-items-center">
        {permissions?.viewEditProject?.tabBilling && (
          <Button
            icon="pi pi-eye"
            outlined
            className="border-none px-0 text-900 w-2rem"
            onClick={() => viewEditPaymentRequest('view', rowIndex)}
            type="button"
          />
        )}
        {permissions?.viewEditProject?.tabBillingModify && (
          <Button
            icon="pi pi-pencil"
            outlined
            className="border-none px-0 text-900 w-2rem"
            onClick={() => viewEditPaymentRequest('edit', rowIndex)}
            type="button"
          />
        )}
        {permissions?.viewEditProject?.tabBillingDelete && (
          <Button
            icon="pi pi-trash"
            outlined
            className="border-none px-0 text-900 w-2rem"
            onClick={() => deletePaymentRequest(rowIndex)}
            type="button"
          />
        )}
      </div>
    ) : null;

  const skeletonTemplate = (): React.ReactNode => {
    return <Skeleton width="100%" height="1.5rem" />;
  };
  // Dynamic body template
  const dynamicBodyTemplate = (
    rowData: BillingItem,
    options: ColumnBodyOptions
  ): React.ReactNode => {
    const { rowIndex } = options;
    if (loading) {
      return skeletonTemplate();
    }
    if (options.field === 'actions') {
      return actionsTemplate(rowIndex);
    }
    if (options.field === 'payment_status') {
      return rowData?.['status']?.status;
    }
    if (options.field === 'check_date') {
      return renderDate(rowData[options.field], 'MM-dd-yyyy');
    }
    if (options.field === 'check_amount') {
      return rowData[options.field] && !isNaN(Number(rowData[options.field]))
        ? CURRENCY.DOLLAR + Number(rowData[options.field]).toFixed(2)
        : '';
    }
    if (options.field === 'requested_amount') {
      return rowData[options.field] && !isNaN(Number(rowData[options.field]))
        ? CURRENCY.DOLLAR + Number(rowData[options.field]).toFixed(2)
        : '';
    }
    // Default rendering for other fields
    const value = rowData[options.field as keyof BillingItem];
    return typeof value === 'string' || typeof value === 'number'
      ? value
      : null;
  };

  const columns: ColumnMeta[] = [
    {
      field: 'actions',
      header: '',
      headerStyle: { minWidth: '120px' },
    },
    {
      field: 'payment_number',
      header: 'Payment Number',
    },
    {
      field: 'payment_status',
      header: 'Payment Status',
    },
    {
      field: 'payment_type',
      header: 'Payment Type',
    },
    {
      field: 'originating_po',
      header: 'Originating Labor PO',
    },
    {
      field: 'check_date',
      header: 'Check Date',
    },
    {
      field: 'check_number',
      header: 'Check Number',
    },
    {
      field: 'check_amount',
      header: 'Check Amount',
    },
    {
      field: 'requested_amount',
      header: 'Requested Amount',
    },
  ];
  const header = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <h3 className="text-base font-bold">Payment Requests</h3>
      {permissions?.viewEditProject?.tabBillingAdd && (
        <Button
          label="Add Item"
          size="small"
          type="button"
          onClick={() => {
            updateDialogFormObj({
              visible: true,
              formAction: 'add',
              index: null,
            });
          }}
          className="p-button-outlined bg-reverse-primary flex justify-content-end"
        />
      )}
    </div>
  );

  return (
    <div className="col-12 mt-4">
      <DataTable value={paymentRequestsDisplayList} header={header}>
        {columns.map(col => (
          <Column
            key={col.field}
            field={col.field}
            header={col.header}
            body={dynamicBodyTemplate}
          />
        ))}
      </DataTable>
      <PaymentRequestsForm
        dialogFormObj={dialogFormObj}
        updateDialogFormObj={updateDialogFormObj}
        project_id={project_id}
        loadBillingData={loadBillingData}
        toast={toast}
        paymentRequestsDisplayList={paymentRequestsDisplayList}
      />
    </div>
  );
};

export default PaymentRequestsTable;
