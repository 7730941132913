import { AxiosError } from 'axios';

import { URL_CONSTANTS } from '../../../../constants/urlConstants';
import apiService from '../../../../services/api.service';

// Get Billing Data
export const fetchTimeManagementData = async (projectId: number) => {
  try {
    const response = await apiService.get(
      apiService.formatUrl(
        URL_CONSTANTS.PROJECTS.timeManagementTab.fetchData,
        projectId
      )
    );
    return {
      error: false,
      message: 'Time Management fetched successfully',
      data: response,
    };
  } catch (error: unknown) {
    console.error('Error fetching Time Management data:', error);
    return {
      error: true,
      message:
        error instanceof AxiosError
          ? error?.response?.data?.error
          : 'Error fetching Time Management data',
      data: null,
    };
  }
};
