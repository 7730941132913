import React from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import { DataView } from 'primereact/dataview';
import { Card } from 'primereact/card';
import { Toast } from 'primereact/toast';
import { useDispatch } from 'react-redux';
import { Tooltip } from 'primereact/tooltip';
import moment from 'moment';

import { SMSHistoryResponse } from '../Interface/sms.interface';
import {
  getProjectSMSHistory,
  markMessageAsReviewed,
} from '../service/sms.service';
import { setCallNotification } from '../../../../redux/slices/page-header.slice';
import { convertGMTToISOString } from '../../../../utils/dates.helper';

interface SMSHistoryProps {
  visible: boolean;
  project_id: number;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  toast: React.RefObject<Toast | null>;
}

const SMSHistory: React.FC<SMSHistoryProps> = ({
  visible,
  project_id,
  setVisible,
  toast,
}) => {
  const [loadingMessageHistory, setLoadingMessageHistory] =
    React.useState(false);
  const [smsHistoryList, setSMSHistoryList] = React.useState<
    SMSHistoryResponse[]
  >([]);
  const dispatch = useDispatch();
  const fetchSMSHistory = async () => {
    setLoadingMessageHistory(true);
    const response = await getProjectSMSHistory(project_id);
    if (!response.error) {
      setSMSHistoryList(response?.smsData);
    }
    setLoadingMessageHistory(false);
  };
  React.useEffect(() => {
    if (visible) fetchSMSHistory();
  }, [project_id, visible]);

  const handleSmsHistoryClickReview = async (sms_response_log_id: number) => {
    setLoadingMessageHistory(true);
    const response = await markMessageAsReviewed(sms_response_log_id);
    if (!response?.error) {
      toast.current?.show({
        severity: 'success',
        summary: 'Success',
        detail: response?.message,
        life: 3000,
      });
      fetchSMSHistory();
      dispatch(setCallNotification(true));
    } else {
      toast.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: response?.message,
        life: 3000,
      });
    }
    setLoadingMessageHistory(false);
  };
  const makeSMSThread = (
    notification: SMSHistoryResponse,
    key: string,
    type: 'parent' | 'child'
  ) => {
    return (
      <div
        className={`flex flex-column  mt-3 ${type === 'parent' ? 'w-17rem' : 'ml-auto'}`}
        style={{ fontSize: '10px' }}
      >
        <div
          className={`flex justify-content-between ${type === 'parent' ? '' : 'w-17rem'}`}
        >
          <span className="text-600 font-bold">
            {' '}
            {type === 'child' ? `Client` : ` ${notification.Sender_name}`}
          </span>
          <span>
            <i
              className={
                notification.status === 'New'
                  ? 'pi pi-clock text-yellow-400'
                  : 'pi pi-check text-green-500'
              }
              style={{ fontSize: '12px' }}
            />
            &nbsp;
            {type === 'child' && notification?.delivered_date_time
              ? convertGMTToISOString(notification?.delivered_date_time)
              : notification?.delivered_date_time
                ? moment(notification?.delivered_date_time).format(
                    'MM-DD-YYYY hh:mm A'
                  )
                : null}
          </span>
        </div>
        <Card
          key={key}
          className={`border-500 border-1 text-xs shadow-none ${type === 'parent' ? 'surface-100' : 'bg-primary-100 w-17rem'}`}
          pt={{ body: { className: 'p-1' }, content: { className: 'p-1' } }}
        >
          <div
            className="white-space-normal"
            style={{ wordBreak: 'break-all' }}
          >
            {notification.message}
          </div>
        </Card>

        {type === 'child' && notification ? (
          <div className="w-17rem">
            {notification.reviewed === 'true' ? (
              <>
                <div className="mt-1">
                  <Tooltip
                    target=".reviewedIcon"
                    style={{ fontSize: '12px' }}
                  />
                  <i
                    className={'pi pi-verified text-primary reviewedIcon'}
                    style={{ fontSize: '12px' }}
                    data-pr-tooltip="Reviewed By"
                    data-pr-position="bottom"
                  />
                  &nbsp;
                  <span className="font-bold">
                    {notification.reviewed_by_user}
                  </span>{' '}
                  <br />
                  <em>
                    {notification.reveiwed_time
                      ? convertGMTToISOString(notification?.reveiwed_time)
                      : 'N/A'}
                  </em>
                </div>
              </>
            ) : (
              <Button
                label="Review"
                className="p-1"
                text
                style={{ fontSize: '12px' }}
                size="small"
                onClick={() =>
                  notification.sms_response_log_id
                    ? handleSmsHistoryClickReview(
                        notification.sms_response_log_id
                      )
                    : null
                }
              />
            )}
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <Dialog
      header="Message History"
      pt={{
        header: { className: 'border-bottom-1 border-400 p-3' },
        headerTitle: { className: 'text-base' },
      }}
      visible={visible}
      style={{ width: '25vw' }}
      onHide={() => {
        if (!visible) return;
        setVisible(false);
      }}
    >
      {loadingMessageHistory ? (
        <div className="flex justify-center">
          <ProgressSpinner
            style={{ width: '50px', height: '50px' }}
            fill="var(--surface-ground)"
          />
        </div>
      ) : smsHistoryList?.length ? (
        <DataView
          value={smsHistoryList.sort(
            (a, b) =>
              new Date(a.delivered_date_time || '').getTime() -
              new Date(b.delivered_date_time || '').getTime()
          )}
          itemTemplate={notification => {
            let responses: SMSHistoryResponse[] = [];

            // Safe JSON Parsing
            try {
              responses = Array.isArray(notification.responses)
                ? notification.responses
                : JSON.parse(notification.responses || '[]');
            } catch (error) {
              console.error('Error parsing responses JSON:', error);
              responses = []; // Default to empty array on error
            }

            return (
              <>
                {makeSMSThread(
                  notification,
                  notification.aws_message_id,
                  'parent'
                )}
                {responses
                  .filter(response => response?.delivered_date_time) // Ensure valid date
                  .sort(
                    (a, b) =>
                      new Date(a.delivered_date_time || '').getTime() -
                      new Date(b.delivered_date_time || '').getTime()
                  )
                  .map((responseNotification, index) => {
                    return responseNotification.message !== null
                      ? makeSMSThread(
                          responseNotification,
                          `${notification.aws_message_id}_${index}`,
                          'child'
                        )
                      : null; // Explicitly return null to avoid React warnings
                  })}
              </>
            );
          }}
        />
      ) : (
        <div className="p-text-center">No SMS History Available</div>
      )}
    </Dialog>
  );
};
export default SMSHistory;
