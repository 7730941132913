import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const usePFDataTable = props => {
  const [first, setFirst] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [rows, setRows] = useState(10);
  const [page, setPage] = useState(0);
  const [sortBy, setSortBy] = useState('');
  const [orderBy, setOrderBy] = useState('DESC');

  const [rowsPerPageOptions, setRowsPerPageOptions] = useState([5, 10, 25, 50]);
  const onPage = event => {
    setDefaultTableProps(preProps => ({
      ...preProps,
      sortField: '',
      sortOrder: 1,
      rows: event.rows,
    }));
    setFirst(event.first);
    setRows(event.rows);
    setPage(event.page);
  };

  const onSort = event => {
    const field = event?.sortField;
    const order = event.sortOrder;
    setDefaultTableProps(preProps => ({
      ...preProps,
      sortField: field,
      sortOrder: order,
    }));
    setSortBy(field);
    setOrderBy(order);
  };

  const [defaultTableProps, setDefaultTableProps] = useState({
    paginator: true,
    rows: rows,
    rowsPerPageOptions,
    totalRecords: totalRecords,
    size: 'small',
    lazy: true,
    first: first,
    stripedRows: true,
    sortField: sortBy,
    sortOrder: orderBy,
    onPage: onPage,
    onSort: onSort,
    paginatorTemplate:
      'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown',
    currentPageReportTemplate:
      'Showing {first} to {last} of {totalRecords} entries',
  });
  useEffect(() => {
    if (props?.dataTableProps) {
      setDefaultTableProps(() => ({
        ...defaultTableProps,
        ...props?.dataTableProps,
      }));
    }
  }, [props]);

  useEffect(() => {
    setDefaultTableProps(() => ({
      ...defaultTableProps,
      totalRecords: totalRecords || 0,
    }));
  }, [totalRecords]);

  useEffect(() => {
    setDefaultTableProps(() => ({ ...defaultTableProps, rows: rows }));
  }, [rows]);

  useEffect(() => {
    setDefaultTableProps(() => ({ ...defaultTableProps, first: first }));
  }, [first]);

  useEffect(() => {
    setDefaultTableProps(() => ({
      ...defaultTableProps,
      rowsPerPageOptions: rowsPerPageOptions,
    }));
  }, [rowsPerPageOptions]);

  const Layout = () => (
    <div className="card">
      <DataTable value={data} {...defaultTableProps}>
        {columns?.map((col, index) => (
          <Column {...col} key={index} style={{ width: 'fit-content' }} />
        ))}
      </DataTable>
    </div>
  );

  return {
    layout: Layout,
    columns: setColumns,
    data: setData,
    totalRecords: setTotalRecords,
    rows: rows,
    page: page,
    sortBy: sortBy,
    orderBy: orderBy,
    first: first,
    setFirst: setFirst,
    setRowsPerPageOptions: setRowsPerPageOptions,
    setDefaultTableProps: setDefaultTableProps,
    totalRecordsCount: totalRecords,
  };
};

export default usePFDataTable;
