import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Calendar } from 'primereact/calendar';
import { InputNumber } from 'primereact/inputnumber';
import { RadioButton } from 'primereact/radiobutton';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';

import { getPercentageValue, momentTz } from '../../../../utils/Helpers';
import { addNotes } from '../../Notes/Notes.service';
import {
  COMMISSION_WORK_TYPE,
  scheduleInfoUserCustomAction,
  scheduleInfoUserFillAction,
  scheduleInfoUserSkipAction,
  scheduleInfoUserWipeAction,
  TECHNICIAN_FILTER_MESSAGE,
} from '../../../../constants';
import { validateReschedule } from '../../../../utils/reschedule.helper';

const InstallerForm = ({
  isOpen,
  loading,
  setIsOpen,
  handleClose,
  installerFormik,
  dialogSettings,
  setDialogSettings,
  installerOptions,
  formAction,
  installerTypeOptions,
  filteredInstallerTypeOptions,
  project_id,
  isCommissionTypeTechnicianFound,
  isSalesCommissionOpted,
  setPercentageAllocation,
  percentageAllocation,
}) => {
  const [dialogNotesSettings, setDialogNotesSettings] = useState({
    title: 'Reschedule Confirmation',
    button1Text: 'Skip',
    button2Text: 'Continue',
    showButton1: true,
    showButton2: true,
  });
  const [isNotesOpen, setIsNotesOpen] = useState(false);
  const [dateStartEndChange, setDateStartEndChange] = useState(false);
  const [isCommisiontype, setIsCommisiontype] = useState(false);
  const [dialogProps, setDialogProps] = useState({
    visible: false,
    props: {},
  });

  const { techniciansLimitList } = useSelector(
    state => state.techniciansLimitList
  );

  const tentativeTypeId = installerTypeOptions?.find(
    type => type?.worktype === 'Tentative Schedule'
  )?.worktypeid;
  const scheduleTypeId = installerTypeOptions?.find(
    type => type?.worktype === 'Schedule'
  )?.worktypeid;
  const commissionTypeId = installerTypeOptions?.find(
    type => type?.worktype === 'Commission'
  )?.worktypeid;
  const getInstallerTypeOptions = () => {
    if (formAction === 'add') {
      return (isCommissionTypeTechnicianFound &&
        installerFormik?.type !== COMMISSION_WORK_TYPE.ID) ||
        !isSalesCommissionOpted
        ? filteredInstallerTypeOptions?.filter(
            e => e?.worktypeid != COMMISSION_WORK_TYPE.ID
          ) || filteredInstallerTypeOptions
        : filteredInstallerTypeOptions;
    } else if (
      formAction === 'edit' &&
      installerFormik?.initialValues?.type === tentativeTypeId
    ) {
      return installerTypeOptions?.filter(
        type => type?.worktype === 'Schedule'
      );
    } else {
      return (isCommissionTypeTechnicianFound &&
        installerFormik?.type !== COMMISSION_WORK_TYPE.ID) ||
        !isSalesCommissionOpted
        ? filteredInstallerTypeOptions?.filter(
            e => e?.worktypeid != COMMISSION_WORK_TYPE.ID
          )
        : filteredInstallerTypeOptions;
    }
  };
  const [button2Text, setButton2Text] = useState();

  const handleButton2Text = worktypeid => {
    if (worktypeid !== undefined && worktypeid === tentativeTypeId) {
      setButton2Text('Tentative Schedule');
    } else if (worktypeid === scheduleTypeId) {
      setButton2Text('Schedule');
    } else {
      setButton2Text('Save');
    }
  };

  // --------------

  const NotesFormikSchema = Yup.object().shape({
    note_text: Yup.string().trim(),
  });

  const installerHandleSubmit = isEdit => {
    if (isEdit && dateStartEndChange) {
      notesFormik.setValues({
        type: '',
        note_text: '',
        follow_up_date: null,
      });
      setDialogNotesSettings(prevState => ({
        ...prevState,
        title: 'Reschedule Confirmation',
        showButton2: true,
      }));
      setIsNotesOpen(true);
      setDateStartEndChange(false);
      notesFormik?.handleReset();
    } else {
      const technicianNameObj =
        installerOptions?.find(
          installer =>
            installer.installer_id === installerFormik.values.installer_id
        ) || '';
      const technicianName = technicianNameObj
        ? `${technicianNameObj?.first_name} ${technicianNameObj?.last_name}`
        : '';
      setDialogSettings(prevState => ({
        ...prevState,
        button2Text: '...saving',
        disabledButton1: true,
        disabledButton2: true,
      }));
      validateReschedule(
        {
          installerId: installerFormik.values.installer_id,
          projectId: project_id,
          projectStartDateTime: installerFormik.values.date_scheduled_start,
          projectEndDateTime: installerFormik.values.date_scheduled_end,
          technician_name: technicianName,
        },
        setDialogProps,
        setIsOpen,
        async action => {
          try {
            if (action) {
              installerFormik.handleSubmit();
            }
          } catch (error) {
            console.log(error, '...error addNotes');
          } finally {
            setDialogSettings(prevState => ({
              ...prevState,
              button2Text: 'Save',
              disabledButton1: false,
              disabledButton2: false,
            }));
          }
        }
      );
    }
  };

  const notesFormik = useFormik({
    initialValues: {
      type: 6,
      note_text: '',
      follow_up_date: null,
    },
    validationSchema: NotesFormikSchema,
    onSubmit: async values => {
      const technicianNameObj =
        installerOptions?.find(
          installer =>
            installer.installer_id === installerFormik.values.installer_id
        ) || '';
      const technicianName = technicianNameObj
        ? `${technicianNameObj?.first_name} ${technicianNameObj?.last_name}`
        : '';
      setDialogNotesSettings(prevState => ({
        ...prevState,
        button2Text: '...saving',
        disabledButton1: true,
        disabledButton2: true,
      }));
      validateReschedule(
        {
          installerId: installerFormik.values.installer_id,
          projectId: project_id,
          projectStartDateTime: installerFormik.values.date_scheduled_start,
          projectEndDateTime: installerFormik.values.date_scheduled_end,
          technician_name: technicianName,
        },
        setDialogProps,
        setIsOpen,
        async action => {
          try {
            if (action) {
              if (values?.note_text?.length > 0) {
                await addNotes(
                  project_id,
                  values,
                  () => {},
                  () => {},
                  () => {},
                  setIsNotesOpen
                );
              } else {
                setIsNotesOpen(false);
              }
              installerFormik.handleSubmit();
            }
          } catch (error) {
            console.log(error, '...error addNotes');
          } finally {
            setDialogNotesSettings(prevState => ({
              ...prevState,
              button2Text: 'Continue',
              disabledButton1: false,
              disabledButton2: false,
            }));
          }
        }
      );
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (installerFormik?.values?.type === COMMISSION_WORK_TYPE?.ID) {
      setIsCommisiontype(true);
    } else {
      setIsCommisiontype(false);
    }
  }, [installerFormik?.values?.type]);

  const footerContent = (
    <div>
      {dialogSettings.showButton1 && (
        <Button
          label="Cancel"
          onClick={handleClose}
          type="button"
          size="small"
          disabled={dialogSettings?.disabledButton1 || false}
        />
      )}
      {dialogSettings.showButton2 && (
        <Button
          label={button2Text || dialogSettings.button2Text || 'Add'}
          onClick={() =>
            formAction === 'edit'
              ? installerHandleSubmit(true)
              : installerHandleSubmit()
          }
          disabled={
            !installerFormik.dirty ||
            !installerFormik.isValid ||
            installerFormik.isSubmitting ||
            installerFormik?.initialValues === installerFormik?.values ||
            (installerFormik?.values.userAction ===
              scheduleInfoUserCustomAction &&
              !percentageAllocation) ||
            dialogSettings?.disabledButton2
          }
          loading={loading}
          size="small"
          type="button"
        />
      )}
    </div>
  );
  const notesFooterContent = (
    <div>
      <Button
        label={dialogNotesSettings.button1Text}
        onClick={() => {
          setIsNotesOpen(false);
          notesFormik.handleReset();
        }}
        type="button"
        size="small"
        disabled={dialogNotesSettings?.disabledButton1 || false}
      />
      <Button
        label={dialogSettings.button2Text}
        onClick={() => notesFormik.handleSubmit()}
        disabled={dialogNotesSettings?.disabledButton2 || false}
        size="small"
        type="button"
      />
    </div>
  );

  const updatedInstallerTypeOptions = [
    ...installerTypeOptions,
    installerFormik?.values?.type === scheduleTypeId
      ? !installerTypeOptions.some(type => type.worktypeid === scheduleTypeId)
        ? { worktypeid: scheduleTypeId, worktype: 'Schedule' }
        : null
      : installerFormik?.values?.type === tentativeTypeId
        ? !installerTypeOptions.some(
            type => type.worktypeid === tentativeTypeId
          )
          ? { worktypeid: tentativeTypeId, worktype: 'Tentative Schedule' }
          : null
        : null,
  ].filter(Boolean);
  return (
    <>
      <Dialog
        header={dialogSettings.title}
        pt={{
          header: { className: 'border-bottom-1 border-400 py-1' },
          headerTitle: { className: 'text-base' },
          content: { className: 'overflow-visible pb-1' },
        }}
        className="overflow-y-auto"
        visible={isOpen}
        style={{ width: '35vw' }}
        onHide={handleClose}
        footer={footerContent}
      >
        <form>
          <div className="col-12">
            <span className="p-float-label">
              <Dropdown
                id="installer_id"
                className="w-12"
                value={techniciansLimitList.find(
                  val =>
                    val.installer_id === installerFormik?.values?.installer_id
                )}
                onChange={e => {
                  installerFormik.handleBlur(e);
                  if (e.value) {
                    installerFormik.setFieldValue(
                      'installer_id',
                      e.value.installer_id
                    );
                  } else {
                    installerFormik.setFieldValue('installer_id', '');
                  }
                }}
                onBlur={installerFormik.handleBlur}
                disabled={formAction === 'view'}
                options={techniciansLimitList}
                optionLabel="full_name"
                placeholder="Select Technician"
                filter
              />

              <label htmlFor="installer_id" className="text-xs">
                Technician<span className="text-red-500">*</span>
              </label>
            </span>
            {installerFormik.touched.installer_id &&
            installerFormik.errors.installer_id ? (
              <div className="text-xs text-red-500">
                {installerFormik.errors.installer_id}
              </div>
            ) : null}
          </div>
          <div className="col-12">
            <span className="p-float-label">
              <Dropdown
                id="type"
                name="type"
                className="w-12"
                value={
                  installerTypeOptions?.find(
                    type => type.worktypeid === installerFormik?.values?.type
                  ) || null
                }
                onChange={event => {
                  installerFormik.handleBlur(event);
                  installerFormik.setFieldValue(
                    'type',
                    event?.value?.worktypeid
                  );
                  handleButton2Text(event?.value?.worktypeid);
                  if (!event?.value) {
                    installerFormik.setFieldValue('type', '');
                  }
                  if (
                    !!installerFormik?.values?.date_scheduled_start ||
                    !!installerFormik?.values?.date_scheduled_end
                  ) {
                    console.info('date');
                  } else {
                    if (
                      event?.value?.worktypeid === tentativeTypeId ||
                      event?.value?.worktypeid === scheduleTypeId
                    ) {
                      installerFormik.setFieldValue(
                        'date_scheduled_start',
                        `${momentTz().format('YYYY-MM-DD')}T08:00`
                      );
                      installerFormik.setFieldValue(
                        'date_scheduled_end',
                        `${momentTz().format('YYYY-MM-DD')}T17:00`
                      );
                    } else {
                      installerFormik.setFieldValue('date_scheduled_start', '');
                      installerFormik.setFieldValue('date_scheduled_end', '');
                    }
                  }
                  if (event?.value?.worktypeid === commissionTypeId) {
                    setIsCommisiontype(true);
                    installerFormik.setFieldValue('date_scheduled_start', '');
                    installerFormik.setFieldValue('date_scheduled_end', '');
                  } else {
                    setIsCommisiontype(false);
                  }
                }}
                disabled={
                  formAction === 'view' ||
                  (formAction === 'edit' &&
                    installerFormik?.initialValues?.type === scheduleTypeId) ||
                  (formAction === 'edit' &&
                    installerFormik?.initialValues?.type === commissionTypeId)
                }
                options={
                  ((formAction === 'edit' || formAction === 'view') &&
                    installerFormik?.initialValues?.type === scheduleTypeId) ||
                  ((formAction === 'edit' || formAction === 'view') &&
                    installerFormik?.initialValues?.type ===
                      commissionTypeId) ||
                  ((formAction === 'edit' || formAction === 'view') &&
                    installerFormik?.initialValues?.type === tentativeTypeId)
                    ? updatedInstallerTypeOptions
                    : getInstallerTypeOptions()
                }
                optionLabel="worktype"
                filter
              />

              <label htmlFor="type" className="text-xs">
                Type<span className="text-red-500">*</span>
              </label>
            </span>
            {installerFormik.touched.type && installerFormik.errors.type ? (
              <div className="text-xs text-red-500">
                {installerFormik.errors.type}
              </div>
            ) : null}
          </div>
          {!isCommisiontype && (
            <>
              <div className="col-12">
                <span className="p-float-label w-full">
                  <Calendar
                    id="date_scheduled_start"
                    className="w-full"
                    selectionMode="single"
                    showTime
                    hourFormat="12"
                    value={
                      installerFormik?.values?.date_scheduled_start
                        ? new Date(
                            installerFormik?.values?.date_scheduled_start
                          )
                        : null
                    }
                    showButtonBar
                    onChange={date => {
                      setDateStartEndChange(true);
                      installerFormik.setFieldTouched(
                        'date_scheduled_start',
                        true
                      );
                      if (date.target.value) {
                        installerFormik.setFieldValue(
                          'date_scheduled_start',
                          `${date.target.value}`
                        );
                      } else {
                        installerFormik.setFieldValue(
                          'date_scheduled_start',
                          ''
                        );
                      }
                    }}
                    disabled={formAction === 'view' ? true : false}
                    onBlur={installerFormik?.handleBlur}
                  />
                  <label htmlFor="date_scheduled_start">
                    Start Date & Time
                  </label>
                </span>
                {installerFormik.touched.date_scheduled_start &&
                installerFormik.errors.date_scheduled_start ? (
                  <div className="text-xs text-red-500">
                    {installerFormik.errors.date_scheduled_start}
                  </div>
                ) : null}
              </div>
              <div className="col-12">
                <span className="p-float-label w-full">
                  <Calendar
                    id="date_scheduled_end"
                    showButtonBar
                    className="w-full"
                    selectionMode="single"
                    showTime
                    hourFormat="12"
                    value={
                      installerFormik?.values?.date_scheduled_end
                        ? new Date(installerFormik?.values?.date_scheduled_end)
                        : null
                    }
                    onChange={date => {
                      setDateStartEndChange(true);
                      installerFormik.setFieldTouched(
                        'date_scheduled_end',
                        true
                      );
                      if (date.target.value) {
                        installerFormik.setFieldValue(
                          'date_scheduled_end',
                          `${date.target.value}`
                        );
                      } else {
                        installerFormik.setFieldValue('date_scheduled_end', '');
                      }
                    }}
                    disabled={formAction === 'view' ? true : false}
                    onBlur={installerFormik?.handleBlur}
                  />
                  <label htmlFor="date_scheduled_end">End Date & Time</label>
                </span>
                {(installerFormik.touched.date_scheduled_end ||
                  installerFormik.touched.date_scheduled_start) &&
                installerFormik.errors.date_scheduled_end ? (
                  <div className="text-xs text-red-500">
                    {installerFormik.errors.date_scheduled_end}
                  </div>
                ) : null}
              </div>
              <div className="col-12">
                <span className="p-float-label w-full">
                  <Calendar
                    id="installer_arrival_start_time"
                    className="w-full"
                    selectionMode="single"
                    timeOnly
                    hourFormat="12"
                    value={
                      installerFormik.values.installer_arrival_start_time
                        ? (() => {
                            const [hours, minutes, seconds] =
                              installerFormik.values.installer_arrival_start_time.split(
                                ':'
                              );
                            const date = new Date();
                            date.setHours(hours, minutes, seconds, 0);
                            return date;
                          })()
                        : null
                    }
                    showButtonBar
                    onChange={e => {
                      if (e.value) {
                        const date = e.value;
                        const formattedTime = [
                          date.getHours().toString().padStart(2, '0'), // HH
                          date.getMinutes().toString().padStart(2, '0'), // mm
                          date.getSeconds().toString().padStart(2, '0'), // ss
                        ].join(':');

                        installerFormik.setFieldTouched(
                          'installer_arrival_start_time',
                          true
                        );
                        installerFormik.setFieldValue(
                          'installer_arrival_start_time',
                          formattedTime
                        );
                      } else {
                        installerFormik.setFieldValue(
                          'installer_arrival_start_time',
                          ''
                        );
                      }
                    }}
                    disabled={formAction === 'view' ? true : false}
                    onBlur={installerFormik?.handleBlur}
                  />
                  <label htmlFor="installer_arrival_start_time">
                    Technician Arrival Start Time
                  </label>
                </span>
                {installerFormik.touched.installer_arrival_start_time &&
                installerFormik.errors.installer_arrival_start_time ? (
                  <div className="text-xs text-red-500">
                    {installerFormik.errors.installer_arrival_start_time}
                  </div>
                ) : null}
              </div>
              <div className="col-12">
                <span className="p-float-label w-full">
                  <Calendar
                    id="installer_arrival_end_time"
                    className="w-full"
                    selectionMode="single"
                    timeOnly
                    showButtonBar
                    hourFormat="12"
                    value={
                      installerFormik.values.installer_arrival_end_time
                        ? (() => {
                            const [hours, minutes, seconds] =
                              installerFormik.values.installer_arrival_end_time.split(
                                ':'
                              );
                            const date = new Date();
                            date.setHours(hours, minutes, seconds, 0);
                            return date;
                          })()
                        : null
                    }
                    onChange={e => {
                      if (e.value) {
                        const date = e.value;
                        const formattedTime = [
                          date.getHours().toString().padStart(2, '0'), // HH
                          date.getMinutes().toString().padStart(2, '0'), // mm
                          date.getSeconds().toString().padStart(2, '0'), // ss
                        ].join(':');

                        installerFormik.setFieldTouched(
                          'installer_arrival_end_time',
                          true
                        );
                        installerFormik.setFieldValue(
                          'installer_arrival_end_time',
                          formattedTime
                        );
                      } else {
                        installerFormik.setFieldValue(
                          'installer_arrival_end_time',
                          ''
                        );
                      }
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change time',
                      style: { display: formAction === 'view' && 'none' },
                    }}
                    disabled={formAction === 'view' ? true : false}
                    onBlur={installerFormik?.handleBlur}
                  />
                  <label htmlFor="installer_arrival_end_time">
                    Technician Arrival End Time
                  </label>
                </span>
                {installerFormik.touched.installer_arrival_end_time &&
                installerFormik.errors.installer_arrival_end_time ? (
                  <div className="text-xs text-red-500">
                    {installerFormik.errors.installer_arrival_end_time}
                  </div>
                ) : null}
              </div>
              {formAction === 'add' && (
                <div>
                  <div className="pl-2 my-3">
                    <small className="text-xs text-color">
                      {TECHNICIAN_FILTER_MESSAGE}
                    </small>
                  </div>
                  <div className="flex flex-wrap gap-2">
                    <div className="flex align-items-center col-4">
                      <RadioButton
                        name="action"
                        value={scheduleInfoUserWipeAction}
                        onChange={e =>
                          installerFormik?.setFieldValue('userAction', e?.value)
                        }
                        checked={
                          installerFormik?.values.userAction ===
                          scheduleInfoUserWipeAction
                        }
                      />
                      <label htmlFor="skip" className="ml-2">
                        Full Allocation
                      </label>
                    </div>
                    <div className="flex align-items-center col-5">
                      <RadioButton
                        name="action"
                        value={scheduleInfoUserCustomAction}
                        onChange={e =>
                          installerFormik?.setFieldValue('userAction', e?.value)
                        }
                        checked={
                          installerFormik?.values.userAction ===
                          scheduleInfoUserCustomAction
                        }
                      />
                      <label htmlFor="skip" className="ml-2">
                        Custom Allocation
                      </label>
                      {installerFormik?.values.userAction ===
                        scheduleInfoUserCustomAction && (
                        <span className="w-2rem">
                          <InputNumber
                            id="percentage"
                            value={percentageAllocation}
                            onChange={e => {
                              const percent = e.value
                                ? getPercentageValue(Number(e.value))
                                : 0;

                              setPercentageAllocation(percent);
                              installerFormik?.setFieldValue(
                                'percentage',
                                percent
                              );
                            }}
                            suffix={'%'}
                            placeholder="%"
                            inputClassName="w-4rem"
                            pt={{
                              root: {
                                className: 'h-2rem px-2',
                              },
                            }}
                            maxLength={6}
                            max={100}
                            min={0}
                          />
                        </span>
                      )}
                    </div>
                    <div className="flex align-items-center col-4">
                      <RadioButton
                        name="action"
                        value={scheduleInfoUserFillAction}
                        onChange={e =>
                          installerFormik?.setFieldValue('userAction', e?.value)
                        }
                        checked={
                          installerFormik?.values.userAction ===
                          scheduleInfoUserFillAction
                        }
                      />
                      <label htmlFor="skip" className="ml-2">
                        Allocate Remaining
                      </label>
                    </div>
                    <div className="flex align-items-center col-4">
                      <RadioButton
                        inputId="skip"
                        name="action"
                        value={scheduleInfoUserSkipAction}
                        onChange={e =>
                          installerFormik?.setFieldValue('userAction', e?.value)
                        }
                        checked={
                          installerFormik?.values.userAction ===
                          scheduleInfoUserSkipAction
                        }
                      />
                      <label htmlFor="skip" className="ml-2">
                        Leave Unassigned
                      </label>
                    </div>
                  </div>
                </div>
              )}

              {formAction === 'edit' && (
                <div className="pl-2 my-2">
                  <small>
                    Replacing this technician would update all items assignment
                    and calculated Labor Cost, Would you still like to continue
                    ?
                  </small>
                </div>
              )}
            </>
          )}
        </form>
      </Dialog>
      {dialogProps.visible && (
        <ConfirmDialog visible={dialogProps.visible} {...dialogProps.props} />
      )}
      {isNotesOpen && (
        <form>
          <Dialog
            header={dialogNotesSettings.title}
            pt={{
              header: { className: 'border-bottom-1 border-400 py-1' },
              headerTitle: { className: 'text-base' },
              content: { className: 'overflow-visible pb-1' },
            }}
            visible={isNotesOpen}
            style={{ width: '35vw' }}
            onHide={() => {
              setIsNotesOpen(false);
              notesFormik.handleReset();
            }}
            footer={notesFooterContent}
          >
            <b className="text-sm">
              Please confirm, if you would like to reschedule this job for{' '}
              <span className="font-bold">
                {installerFormik?.values?.date_scheduled_start
                  ? momentTz(
                      installerFormik?.values?.date_scheduled_start
                    ).format('YYYY-MM-DD hh:mm A')
                  : ''}{' '}
                -{' '}
                {installerFormik?.values?.date_scheduled_end
                  ? momentTz(
                      installerFormik?.values?.date_scheduled_end
                    ).format('YYYY-MM-DD hh:mm A')
                  : ''}
              </span>
            </b>
            <div className="col-12">
              <span className="p-float-label w-full">
                <InputTextarea
                  id="note_text"
                  onChange={notesFormik.handleChange}
                  onBlur={notesFormik.handleBlur}
                  value={notesFormik?.values?.note_text}
                  rows={2}
                  cols={30}
                  className="w-full"
                />
                <label htmlFor="note_text">Note</label>
              </span>
            </div>
          </Dialog>
        </form>
      )}
    </>
  );
};

export default InstallerForm;
