import axios from 'axios';
import { AnyObject } from 'yup/lib/types';

import { URL_CONSTANTS } from '../../../../constants/urlConstants';
import apiService from '../../../../services/api.service';

export const tagListService = async (
  id: string | number,
  param: string | number
) => {
  try {
    let queryParam = '';

    if (typeof param === 'string') {
      queryParam = `&searchQuery=${encodeURIComponent(param)}`;
    } else if (typeof param === 'number') {
      queryParam = `&tagId=${param}`;
    }

    const res = await apiService.getWithDiffBaseUrl(
      `${URL_CONSTANTS?.QUOTE_TEMPLATE?.getTemplateTagList}?clientId=${id}${queryParam}`,
      null,
      URL_CONSTANTS?.QUOTE_TEMPLATE?.baseUrl
    );
    return res || [];
  } catch (e) {
    console.error('tagListService', e);
    return {};
  }
};

export const createTagListingService = async (body = {}) => {
  try {
    const res = await apiService.postWithDiffBaseUrl(
      `${URL_CONSTANTS?.QUOTE_TEMPLATE?.createTemplateTag}`,
      body,
      URL_CONSTANTS.QUOTE_TEMPLATE.baseUrl
    );
    console.log('rcreateTagListingServices', res);
    return res || {};
  } catch (e) {
    console.error('QuoteTemplate.service', 'createTagListingService', e);
    return e;
  }
};

export const createQuoteTemplate = async (value: AnyObject) => {
  try {
    const response = await apiService.postWithDiffBaseUrl(
      `${URL_CONSTANTS?.QUOTE_TEMPLATE?.createQuoteTemplate}`,
      value,
      URL_CONSTANTS.QUOTE_TEMPLATE.baseUrl
    );
    return response;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getQuoteTemplateList = async (
  clientId: string | number,
  tempId?: string | number,
  searchValue?: string,
  status?: string
) => {
  try {
    let queryParam = '';
    if (tempId) {
      queryParam = `&quotesTemplateId=${tempId}`;
    }
    if (searchValue) {
      queryParam = `&searchQuery=${searchValue}`;
    }
    if (status) {
      queryParam = `&isActive=true`;
    }
    const response = await apiService.getWithDiffBaseUrl(
      `${URL_CONSTANTS?.QUOTE_TEMPLATE?.getQuoteTemplateList}?clientId=${clientId}${queryParam}`,
      null,
      URL_CONSTANTS?.QUOTE_TEMPLATE?.baseUrl
    );
    return response?.data;
  } catch (error) {
    console.error(error);
    return {};
  }
};

export const deleteQuoteTemplate = async (
  tempId: number,
  client_id: string | number
) => {
  try {
    const res = await apiService.deleteWithDiffBaseUrl(
      `${URL_CONSTANTS?.QUOTE_TEMPLATE?.deleteQuoteTemplate}/${tempId}?clientId=${client_id}`,
      null,
      URL_CONSTANTS.QUOTE_TEMPLATE.baseUrl
    );
    return res;
  } catch (error) {
    console.error(error);
    return {};
  }
};

export const updateQuoteTemplate = async (value: AnyObject, tempId: string) => {
  // return false;

  try {
    const res = await apiService.putWithDiffBaseUrl(
      `${URL_CONSTANTS?.QUOTE_TEMPLATE?.updateQuoteTemplate}/${tempId}`,
      value,
      URL_CONSTANTS.QUOTE_TEMPLATE.baseUrl
    );
    return res;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getSignedUrl = async (url: File) => {
  try {
    const formData = new FormData();
    formData.append('documentFile', url);
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    const uploadResponse = await axios.post(
      `${URL_CONSTANTS?.API?.BASE_URL}/system/file-upload/temp`,
      formData,
      { headers: headers }
    );
    return uploadResponse?.data?.data?.[0];
  } catch (error) {
    console.error(error);
    return {};
  }
};

export const getPreSignedUrlforPhotos = async (url: File) => {
  try {
    const response = await axios.post(
      `${URL_CONSTANTS.API.BASE_URL}/documents/common/generate-signed-url`,
      { document_url: url }
    );
    return response?.data?.document_signed_url;
  } catch (error) {
    console.log(error);
  }
};
