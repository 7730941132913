import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Toast } from 'primereact/toast';
import { ConfirmDialog } from 'primereact/confirmdialog';

import PFInputText from '../../../../shared/PFPrime/PFInputText';
import PFButton from '../../../../shared/PFPrime/PFButton';
import PFDataTable from '../../../../shared/PFPrime/PFDataTable';
import PFCheckbox from '../../../../shared/PFPrime/PFCheckbox';
import apiSerive from '../../../../../services/api.service';
import { formatDateTime } from '../../../../../utils/Helpers';
import { SchedulerCustomFilters } from '../../../../../constants';
import { URL_CONSTANTS } from '../../../../../constants/urlConstants';
import { fetchTechniciansLimitList } from '../../../../../redux/slices/master/technicianLimit.slice';
import TableColumnsLoader from '../../../../shared/Loader/tableColumnsLoader';

import AddEditReportDailog from './AddEditReport.view';

const FilterReport = () => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [deleteRowData, setDeleteRowData] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [reloadList, setReloadList] = useState(false);
  const [action, setAction] = useState('');
  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 1,
  });
  const [tableData, setTableData] = useState([]);
  const [searchQuery, setSearchQuery] = React.useState({
    limit: 10,
    offset: 0,
  });
  const [count, setCount] = useState();
  const [globalSearch, setGlobalSearch] = useState('');
  const dt = useRef(null);
  const toast = useRef(null);
  const filterType = window.location.pathname.split('/')[2];
  const { user } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const getTableData = async () => {
    try {
      setIsLoading(true);
      let newQueryString;
      newQueryString = Object.entries(searchQuery)
        .filter(([, value]) => Boolean(value) || value === 0)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
      let response;
      if (filterType == SchedulerCustomFilters.ADMIN) {
        response = await apiSerive.get(
          apiSerive.formatUrl(
            URL_CONSTANTS.SCHEDULER_FILTERS.getAdminLevelFilterList,
            newQueryString
          )
        );
      } else if (filterType == SchedulerCustomFilters.USER) {
        response = await apiSerive.get(
          apiSerive.formatUrl(
            URL_CONSTANTS.SCHEDULER_FILTERS.getUserLevelFilterList,
            user,
            newQueryString
          )
        );
      }
      if (response && response?.data) {
        setTableData(response?.data?.rows);
        setCount(response?.data?.count);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getFilterDataById = async id => {
    try {
      let response;
      if (filterType == SchedulerCustomFilters.ADMIN) {
        response = await apiSerive.get(
          apiSerive.formatUrl(
            URL_CONSTANTS.SCHEDULER_FILTERS.getAdminLevelFilterById,
            id
          )
        );
      } else if (filterType == SchedulerCustomFilters.USER) {
        response = await apiSerive.get(
          apiSerive.formatUrl(
            URL_CONSTANTS.SCHEDULER_FILTERS.getUserLevelFilterById,
            id,
            user
          )
        );
      }
      if (response && response?.status) {
        setSelectedRowData(response?.data);
        setVisible(true);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const setDefaultFilter = async (id, payload) => {
    try {
      let response;
      if (filterType == SchedulerCustomFilters.ADMIN) {
        response = await apiSerive.patch(
          apiSerive.formatUrl(
            URL_CONSTANTS.SCHEDULER_FILTERS.setAdminLevelDefaultFilter,
            id
          ),
          payload
        );
      } else if (filterType == SchedulerCustomFilters.USER) {
        response = await apiSerive.patch(
          apiSerive.formatUrl(
            URL_CONSTANTS.SCHEDULER_FILTERS.setUserLevelDefaultFilter,
            id,
            user
          ),
          payload
        );
      }
      if (response && response?.status) {
        setReloadList(true);
        toast.current?.show({
          severity: 'success',
          summary: `Filter Default Status Updated Successfully.`,
        });
      } else {
        setReloadList(false);
        toast.current?.show({
          severity: 'error',
          summary: response?.data
            ? `${response?.data}`
            : 'Unable to set to default.',
        });
      }
    } catch (error) {
      setReloadList(false);
      console.error(error);
    } finally {
      setReloadList(false);
    }
  };
  const setTechnicianVisibilityDefaultFilter = async (id, payload) => {
    try {
      const response = await apiSerive.patch(
        apiSerive.formatUrl(
          URL_CONSTANTS.SCHEDULER_FILTERS.setUserLevelTechnicianDefaultFilter,
          id,
          user
        ),
        payload
      );

      if (response && response?.status) {
        setReloadList(true);
        dispatch(fetchTechniciansLimitList());
        toast.current?.show({
          severity: 'success',
          summary: `Technician Default Visibility Status Updated Successfully.`,
        });
      } else {
        setReloadList(false);
        toast.current?.show({
          severity: 'error',
          summary: response?.data
            ? `${response?.data}`
            : 'Unable to set to default.',
        });
      }
    } catch (error) {
      setReloadList(false);
      console.error(error);
    } finally {
      setReloadList(false);
    }
  };
  const deleteFilter = async id => {
    try {
      let response;
      if (filterType == SchedulerCustomFilters.ADMIN) {
        response = await apiSerive.patch(
          apiSerive.formatUrl(
            URL_CONSTANTS.SCHEDULER_FILTERS.deleteAdminLevelFilter,
            id
          ),
          { is_deleted: 1 }
        );
      } else if (filterType == SchedulerCustomFilters.USER) {
        response = await apiSerive.patch(
          apiSerive.formatUrl(
            URL_CONSTANTS.SCHEDULER_FILTERS.deleteUserLevelFilter,
            id,
            user
          ),
          { is_deleted: 1 }
        );
      }

      if (response && response?.status) {
        setReloadList(true);
        toast.current?.show({
          severity: 'success',
          summary: `Record is deleted successfully.`,
        });
      } else {
        setReloadList(false);
        toast.current?.show({
          severity: 'error',
          summary: response?.data
            ? `${response?.data}`
            : 'Unable to delete record.',
        });
      }
      setDeleteRowData(null);
    } catch (error) {
      setReloadList(false);
      console.error(error);
    } finally {
      setReloadList(false);
      setDeleteRowData(null);
    }
  };
  useEffect(() => {
    if (reloadList) {
      getTableData();
    }
  }, [reloadList]);
  useEffect(() => {
    getTableData(searchQuery);
  }, [searchQuery]);

  const onDefaultChange = (rowData, value) => {
    let payload = {};
    if (value) {
      payload = {
        is_default: 1,
      };
    } else {
      payload = {
        is_default: 0,
      };
    }
    setDefaultFilter(rowData.filter_id, payload);
  };
  const onDefaultTechnicianVisibility = (rowData, value) => {
    let payload = {};
    if (value) {
      payload = {
        restrict_technician_visibility: 1,
      };
    } else {
      payload = {
        restrict_technician_visibility: 0,
      };
    }
    setTechnicianVisibilityDefaultFilter(rowData.filter_id, payload);
  };
  const tableCol = [
    {
      field: '',
      header: 'Actions',
      ...(!isLoading
        ? {
            body: rowData => {
              return (
                <div>
                  {filterType === SchedulerCustomFilters.ADMIN ||
                  (filterType === SchedulerCustomFilters.USER &&
                    rowData?.userFilterInfo) ? (
                    <i
                      className="pi pi-pencil cursor-pointer text-primary pr-1"
                      role="button"
                      tabIndex={0}
                      onClick={() => {
                        getFilterDataById(rowData?.filter_id);
                        setAction('edit');
                      }}
                      onKeyDown={e => {
                        if (e.key === 'Enter' || e.key === ' ') {
                          getFilterDataById(rowData?.filter_id);
                          setAction('edit');
                        }
                      }}
                    ></i>
                  ) : (
                    <span className="pr-3 w-1rem"></span>
                  )}

                  <i
                    className="pi pi-trash cursor-pointer text-primary pl-3"
                    onClick={() => {
                      setDeleteRowData(rowData?.filter_id);
                      setConfirmDelete(true);
                    }}
                    role="button"
                    tabIndex={0}
                    onKeyDown={e => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        setDeleteRowData(rowData?.filter_id);
                        setConfirmDelete(true);
                      }
                    }}
                  ></i>
                </div>
              );
            },
          }
        : {}),
      style: { width: '80px' },
    },
    {
      field: 'default',
      header: 'Default',
      ...(!isLoading
        ? {
            body: rowData => {
              return (
                <span className="text-xm pl-2">
                  <PFCheckbox
                    checked={rowData?.is_default == 1}
                    name={`${rowData.filter_id}-${rowData.project_type_id}`}
                    onChange={event => {
                      onDefaultChange(rowData, event.checked);
                    }}
                  />
                </span>
              );
            },
          }
        : {}),
      style: { width: '60px' },
    },
    ...(filterType == SchedulerCustomFilters.USER
      ? [
          {
            field: 'default',
            header: 'Restrict Technician Visibility',
            ...(!isLoading
              ? {
                  body: rowData => {
                    return (
                      <div className="text-xm pl-2 text-center">
                        <PFCheckbox
                          checked={rowData?.restrict_technician_visibility == 1}
                          name={`${rowData.filter_id}-${rowData.project_type_id}`}
                          onChange={event => {
                            onDefaultTechnicianVisibility(
                              rowData,
                              event.checked
                            );
                          }}
                        />
                      </div>
                    );
                  },
                }
              : {}),
            style: { width: '60px' },
          },
        ]
      : []),

    {
      field: 'filter_name',
      header: 'Filter Name',
      ...(!isLoading
        ? {
            body: rowData => {
              return <>{rowData?.filter_name}</>;
            },
          }
        : {}),
    },
    ...(filterType == SchedulerCustomFilters.ADMIN
      ? [
          {
            field: 'created_at',
            header: 'Created At',
            ...(!isLoading
              ? {
                  body: rowData =>
                    rowData?.created_at
                      ? formatDateTime(rowData?.created_at)
                      : '',
                }
              : {}),
            style: { minWidth: '180px' },
          },
        ]
      : []),
    ...(filterType == SchedulerCustomFilters.ADMIN
      ? [
          {
            field: 'createdBy',
            header: 'Created By',
            ...(!isLoading
              ? {
                  body: rowData => {
                    return (
                      <>
                        {rowData?.createdBy
                          ? `${rowData?.createdBy?.first_name} ${rowData?.createdBy?.last_name}`
                          : ''}
                      </>
                    );
                  },
                }
              : {}),
          },
        ]
      : []),
    ...(filterType == SchedulerCustomFilters.ADMIN
      ? [
          {
            field: 'modified_at',
            header: 'Modified At',
            ...(!isLoading
              ? {
                  body: rowData =>
                    rowData?.modified_at
                      ? formatDateTime(rowData?.modified_at)
                      : '',
                }
              : {}),
            style: { minWidth: '180px' },
          },
        ]
      : []),
    ...(filterType == SchedulerCustomFilters.ADMIN
      ? [
          {
            field: 'modifiedBy',
            header: 'Modified By',
            ...(!isLoading
              ? {
                  body: rowData => {
                    return (
                      <>
                        {rowData?.modifiedBy
                          ? `${rowData?.modifiedBy?.first_name} ${rowData?.modifiedBy?.last_name}`
                          : ''}
                      </>
                    );
                  },
                }
              : {}),
          },
        ]
      : []),
  ];
  const handleOnPage = event => {
    setSearchQuery(prevState => ({
      ...prevState,
      limit: event.rows,
      offset: event.first,
    }));
    setlazyState(event);
  };
  let intervalVal = '';
  const handleSearch = searchInputVal => {
    const searchString = searchInputVal?.trim();

    try {
      clearTimeout(intervalVal);
    } catch (err) {
      console.error(err);
    }
    intervalVal = setTimeout(() => {
      setSearchQuery({ limit: 10, offset: 0, search: searchString });
      setlazyState({
        first: 0,
        rows: 10,
        page: 1,
      });
    }, 400);
  };
  const renderHeader = () => {
    return (
      <div className="flex justify-content-between flex-wrap pt-1">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <PFInputText
            id="search"
            className="w-12"
            value={globalSearch}
            placeholder="Keyword Search"
            // className="p-inputtext-sm"
            onChange={e => {
              setGlobalSearch(e?.target?.value);
              if (
                e?.target?.value?.length > 2 ||
                e?.target?.value?.length < 1
              ) {
                handleSearch(e?.target?.value);
              }
            }}
          />
        </span>
        <span className="p-float-label pl-2">
          <PFButton
            id="shade"
            className="w-12"
            label=" + Add  "
            onClick={() => {
              setSelectedRowData(null);
              setAction('add');
              setVisible(true);
            }}
          />
        </span>
      </div>
    );
  };
  const header = renderHeader();
  let dataTableParams = {
    ref: dt,
    searchQuery: searchQuery,
    header: header,
  };
  const handleDelete = () => {
    if (deleteRowData) {
      deleteFilter(deleteRowData);
    }
  };

  const filterTableLoader = useMemo(() => {
    return TableColumnsLoader(tableCol, {
      rows: 4,
      isValue: false,
    });
  }, [tableCol]);

  return (
    <>
      <div className="grid pt-2">
        <Toast ref={toast}></Toast>
        <PFDataTable
          data={isLoading ? filterTableLoader : tableData}
          columns={tableCol}
          dataTableParams={dataTableParams}
          lazy
          paginator={!isLoading}
          page={lazyState.page}
          first={lazyState.first}
          rows={lazyState.rows}
          onPage={handleOnPage}
          totalRecords={count}
          rowsPerPageOptions={[10, 20, 50, 100]}
          columnResizeMode="expand"
          resizableColumns
        ></PFDataTable>
      </div>

      {visible && (
        <AddEditReportDailog
          visible={visible}
          setVisible={setVisible}
          action={action}
          selectedRowData={selectedRowData}
          setSelectedRowData={setSelectedRowData}
          setReloadList={setReloadList}
          filterType={filterType}
          userId={user}
          toast={toast}
        />
      )}
      {confirmDelete && deleteRowData && (
        <ConfirmDialog
          group="declarative"
          visible={confirmDelete}
          closable={false}
          draggable={false}
          message="Do you want to delete this filter ?"
          header="Confirmation"
          icon="pi pi-exclamation-triangle"
          accept={() => {
            handleDelete();
          }}
          reject={() => {
            setConfirmDelete(false);
            setDeleteRowData(null);
          }}
        />
      )}
    </>
  );
};
export default FilterReport;
