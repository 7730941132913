import { useDispatch } from 'react-redux';

import { fetchTechnicians } from './technician.slice';
import { fetchTypes } from './types.slice';
import { fetchCategories } from './categories.slice';
import { fetchProjectStores } from './projectStores.slice';
import { fetchAllProjectStores } from './projectAllStores.slice';
import { fetchDistricts } from './districts.slice';
import { fetchUserTypes } from './userTypes.slice';
import { fetchWorkrooms } from './workrooms.slice';
import { fetchInstallerWorkType } from './installerWorkType.slice';
import { fetchSourceStatusType } from './sourceStatusType.slice';
import { fetchProjectStatusType } from './projectStatusType.slice';
import { fetchTechniciansLimitList } from './technicianLimit.slice';
import { fetchSourceSystems } from './sourceSystems.slice';

const fetchMasterDataHook = () => {
  const dispatch = useDispatch();

  const fetchMasterData = () => {
    dispatch(fetchTechnicians());
    dispatch(fetchTypes());
    dispatch(fetchCategories());
    dispatch(fetchProjectStores());
    dispatch(fetchAllProjectStores());
    dispatch(fetchDistricts());
    dispatch(fetchUserTypes());
    dispatch(fetchWorkrooms());
    dispatch(fetchInstallerWorkType());
    dispatch(fetchSourceStatusType());
    dispatch(fetchProjectStatusType());
    dispatch(fetchTechniciansLimitList());
    dispatch(fetchSourceSystems());
  };

  return fetchMasterData;
};

export default fetchMasterDataHook;
