import React, { useEffect, useRef, useState } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import { Card } from 'primereact/card';
import { Skeleton } from 'primereact/skeleton';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Tag } from 'primereact/tag';

import {
  deleteHolidayByHolidayId,
  getHolidays,
} from '../service/BusinessHourConfigService';
import { dayjsFormatDateTime } from '../../../../utils/Helpers';
import PFButton from '../../../shared/PFPrime/PFButton';
import { useConfirmDialogContext } from '../../../../contexts/ConfirmDialog';

import AddHolidayCalendarDialog from './AddHolidayCalendarDialog';

const HolidayCalendarComponent = ({ clientId }) => {
  const { showConfirmationDialog } = useConfirmDialogContext();
  if (!clientId) return <></>;
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);
  const [holidayCalendar, setHolidayCalendar] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [addEditDialogContent, setAddEditDialogContent] = useState();

  const EventNameTemplate = rowData => {
    return loading ? (
      <Skeleton className="w-full" height="3rem" />
    ) : (
      <span>{rowData?.event_name}</span>
    );
  };

  const StartTimeTemplate = rowData => {
    try {
      return loading ? (
        <Skeleton className="w-full" height="3rem" />
      ) : (
        <span>
          {dayjsFormatDateTime(rowData?.start_date, 'MM-DD-YYYY hh:mm A')}
        </span>
      );
    } catch (ex) {
      console.error(ex);
      return <></>;
    }
  };

  const EndTimeTemplate = rowData => {
    try {
      return loading ? (
        <Skeleton className="w-full" height="3rem" />
      ) : (
        <span>
          {dayjsFormatDateTime(rowData?.end_date, 'MM-DD-YYYY hh:mm A')}
        </span>
      );
    } catch (ex) {
      console.error(ex);
      return <></>;
    }
  };

  const FullDayTemplate = rowData => {
    return loading ? (
      <Skeleton className="w-full" height="3rem" />
    ) : (
      <span>
        {rowData?.is_full_day ? (
          <Tag
            severity="success"
            value="Yes"
            size="small"
            className="w-3rem"
          ></Tag>
        ) : (
          <Tag
            severity="danger"
            value="No"
            size="small"
            className="w-3rem"
          ></Tag>
        )}
      </span>
    );
  };

  const actionTemplate = rowData => {
    return loading ? (
      <Skeleton className="w-full" height="3rem" />
    ) : (
      <div className="flex gap-2 w-full">
        <PFButton
          severity=" "
          icon="pi pi-pencil"
          text
          rounded
          onClick={() => {
            setAddEditDialogContent(rowData);
            setIsVisible(true);
          }}
        />
        <PFButton
          severity=" "
          icon="pi pi-trash"
          text
          rounded
          onClick={() => {
            showConfirmationDialog({
              message: `Are you sure you want to delete ${rowData?.event_name} holiday?`,
              header: 'Confirmation',
              icon: 'pi pi-exclamation-triangle',
              accept: () => callDeleteHoliday(rowData?.holiday_id),
            });
          }}
        />
      </div>
    );
  };

  const callDeleteHoliday = async holidayId => {
    setLoading(true);
    try {
      const apiResponse = await deleteHolidayByHolidayId(holidayId);
      if (apiResponse?.status) {
        toast.current.show({
          severity: 'success',
          summary: apiResponse?.message || 'Record deleted Successfully',
          life: 3000,
        });
        const remainingHolidayCalendar = holidayCalendar.filter(
          each => each.holiday_id != holidayId
        );
        setHolidayCalendar(remainingHolidayCalendar);
      } else {
        toast.current.show({
          severity: 'error',
          summary:
            apiResponse.message || 'Error occurred while deleting record',
          life: 1500,
        });
      }
    } catch (ex) {
      console.error(ex);
      toast.current.show({
        severity: 'error',
        detail: (ex.response && ex.response.message) || 'Something Went Wrong',
        life: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  const callGeHolidayCalendar = async () => {
    setLoading(true);
    try {
      const apiResponse = await getHolidays(clientId);

      if (apiResponse?.status && Array.isArray(apiResponse.data)) {
        setHolidayCalendar(apiResponse.data);
      }
    } catch (ex) {
      console.error(ex);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (clientId) {
      callGeHolidayCalendar();
    }
  }, [clientId]);
  const CardHeader = (
    <div className="flex justify-content-between align-items-center">
      <p>Holiday Calendar</p>
      <span className="flex flex-end align-items-center">
        <PFButton
          label="Add holiday"
          size="small"
          icon="pi pi-plus"
          onClick={() => {
            setAddEditDialogContent(null);
            setIsVisible(true);
          }}
        />
      </span>
    </div>
  );
  return (
    <Card
      title={CardHeader}
      className="mt-3"
      pt={{
        body: {
          className: 'pt-1',
        },
        title: {
          className: 'p-0 mb-0 mt-0',
        },
        content: {
          className: 'p-0 mt-0',
        },
      }}
    >
      <Toast ref={toast} />
      <DataTable
        value={Array.isArray(holidayCalendar) ? holidayCalendar : []}
        key="holiday-key"
        dataKey="holiday"
        className="w-full"
        emptyMessage="No available holidays"
      >
        <Column
          key="key_action"
          field="action"
          body={actionTemplate}
          className="w-5rem"
        />
        <Column
          key="key_event_name"
          field="event_name"
          header="Event"
          body={EventNameTemplate}
        />
        <Column
          key="key_start_time"
          field="start_time"
          header="Start Time"
          body={StartTimeTemplate}
        />
        <Column
          key="key_end_time"
          field="end_time"
          header="End Time"
          body={EndTimeTemplate}
        />
        <Column
          key="key_is_full_day"
          field="is_full_day"
          header="Full Day"
          body={FullDayTemplate}
        />
      </DataTable>
      <ConfirmDialog />
      {isVisible && (
        <AddHolidayCalendarDialog
          key="add-holiday"
          clientId={clientId}
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          holidayDetail={addEditDialogContent}
          setHolidayCalendar={setHolidayCalendar}
        />
      )}
    </Card>
  );
};

export default HolidayCalendarComponent;
