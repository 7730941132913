import { useEffect, useRef } from 'react';
import { Grid, Select, MenuItem } from '@material-ui/core';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { useHistory } from 'react-router-dom';
import { Card } from 'primereact/card';

import PFButton from '../../shared/PFPrime/PFButton';
import { formikProjectMapping } from '../../Admin/Configuration/LrrpConfiguration.constant';
import GenericLeadReasonDataTable from '../../Admin/Configuration/LrrpConfigurationComponents/GenericLeadReasonDataTable';

import HdEpaDocsTable from './HdEpaDocsTable';
import { getHomeDepotData, updateHomeDepot } from './HomeDepot.services';

const HomeDepotFields = ({
  homeDepotOverrideOptions,
  hdEpaDocs,
  source_system_id,
  projectId,
  epaLeadReasontoUpdate,
  setEPALeadReasontoUpdate,
  setReloadForm,

  setOpenpopup,
  setUnsavedTestInfo,
}) => {
  const toast = useRef(null);
  let history = useHistory();

  useEffect(() => {
    getData();
  }, [projectId]);

  const formik = useFormik({
    initialValues: {
      lswp_required: '',
      lead_test_req: '',
      lswp_followed: '',
      hd_lead_test_result: '',
      epa_override_reason_code_id: '',
      test_info: [],
    },
    onSubmit: async values => {
      if (source_system_id === 2) {
        const response = await updateHomeDepot(
          projectId,
          values,
          () => {},
          epaLeadReasontoUpdate,
          setUnsavedTestInfo
        );
        if (response) {
          getData();
          toast.current.show({
            severity: 'success',
            summary: `Success`,
            detail: 'Lead details updated successfully.',
            life: '2500',
          });
          setOpenpopup(false);
          setEPALeadReasontoUpdate(false);
          setReloadForm(true);
          history.push(`/project/view/${projectId}`);
        } else {
          toast.current.show({
            severity: 'error',
            summary: `Something went wrong.`,
            detail: response?.data?.error,
            life: '2500',
          });
        }
      }
    },
    enableReinitialize: true,
  });
  const getData = async () => {
    const response = await getHomeDepotData(projectId, () => {});
    formikProjectMapping(formik, response);
  };

  const handleSubmitButton = async () => {
    await formik.handleSubmit();
  };
  return (
    <>
      <Grid item xs={12} className="text-right">
        <PFButton
          color="primary"
          variant="contained"
          onClick={handleSubmitButton}
          disabled={formik?.isSubmitting || !formik?.isValid}
          size="small"
          label={epaLeadReasontoUpdate ? 'Submit and Complete' : 'Submit'}
        />
      </Grid>
      <Toast ref={toast} />
      <Card className="w-12 mt-3">
        <Grid
          container
          item
          direction="row"
          justifyContent="space-between"
          spacing={2}
        >
          <Grid item xs={3}>
            <label htmlFor="LSWP_Required">LSWP Required :</label>
            <Select
              label="LSWP Required"
              name="LSWP_Required"
              value={formik?.values?.lswp_required}
              onChange={event => {
                formik.setFieldValue('lswp_required', event?.target?.value);
              }}
            >
              <MenuItem value={1}>Yes</MenuItem>
              <MenuItem value={0}>No</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={3}>
            <label htmlFor="Lead_Test_Required">Lead Test Required :</label>
            <Select
              label="Lead Test Required"
              name="Lead_Test_Required"
              value={formik?.values?.lead_test_req}
              onChange={event => {
                formik.setFieldValue('lead_test_req', event?.target?.value);
              }}
            >
              <MenuItem value={1}>Yes</MenuItem>
              <MenuItem value={0}>No</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={3}>
            <label htmlFor="LSWP-Followed">LSWP Followed :</label>
            <Select
              label="LSWP Followed"
              name="LSWP-Followed"
              value={formik?.values?.lswp_followed}
              onChange={event => {
                formik.setFieldValue('lswp_followed', event?.target?.value);
              }}
            >
              <MenuItem value={1}>Yes</MenuItem>
              <MenuItem value={0}>No</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={3}>
            <label htmlFor="Lead-Response">Lead Response:</label>
            <Select
              label="Lead Response"
              name="Lead-Response"
              value={formik?.values?.hd_lead_test_result}
              onChange={event => {
                formik.setFieldValue(
                  'hd_lead_test_result',
                  event?.target?.value
                );
              }}
            >
              <MenuItem value={1}>Positive</MenuItem>
              <MenuItem value={0}>Negative</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={3}>
            <label htmlFor="Override-Reason">Override Reason:</label>
            <Select
              label="Override Reason"
              name="Override-Reason"
              value={formik?.values?.epa_override_reason_code_id}
              onChange={event => {
                formik.setFieldValue(
                  'epa_override_reason_code_id',
                  event?.target?.value
                );
              }}
            >
              {homeDepotOverrideOptions?.map(item => (
                <MenuItem value={item?.reason_code} key={item?.reason_code}>
                  {item?.reason_desc}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </Card>

      {hdEpaDocs?.length > 0 && <HdEpaDocsTable rowData={hdEpaDocs} />}
      <GenericLeadReasonDataTable
        formikKey="test_info"
        initialValues={
          formik?.values?.hd_lead_test_result == 0
            ? {
                lead_result: 'Negative',
                test_kit_manufacturer: '',
                component_location_tested: '',
              }
            : {
                lead_result: 'Positive',
                test_kit_manufacturer: '',
                component_location_tested: '',
              }
        }
        formikData={formik}
        pType="home_depot"
        setUnsavedTestInfo={setUnsavedTestInfo}
      />
    </>
  );
};

export default HomeDepotFields;
