import { useState, useEffect, useCallback, useRef } from 'react';
import { Tooltip, Toast, Chip } from 'primereact';

import PFDataTable from '../../../shared/PFPrime/PFDataTable';
import PFInputText from '../../../shared/PFPrime/PFInputText';
import apiService from '../../../../services/api.service';
import { URL_CONSTANTS } from '../../../../constants/urlConstants';
import SkeletonLoader from '../../../shared/Loader/skeleton';
import TableLoader from '../../../shared/Loader/TableLoader';
import { isCrmEnabled, formatDateTime } from '../../../../utils/Helpers';
import { generateUrlParams } from '../helper/helper';

import NotesForm from './NotesForm';

const NotesList = ({
  customerId,
  reload,
  communicationType,
  communicationId,
  setReload,
  opportunityId,
  quoteId,
  invoiceId,
  paymentId,
  isVersionActive = true,
}) => {
  const [notesList, setNotesList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalRecordsCount, setTotalRecords] = useState(0);
  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: 'created_at',
    sortOrder: -1,
  });
  const [globalFilterValue, setGlobalFilterValue] = useState('');

  const toast = useRef(null);

  const tableColum = [
    {
      field: 'created_at',
      header: 'Date',
      sortable: true,
      filter: false,
      style: { width: '15%' },
      body: params => formatDateTime(params.created_at),
    },
    {
      field: 'createdBy',
      header: 'Created By',
      sortable: true,
      filter: false,
      style: { width: '15%' },
    },
    {
      field: 'note',
      header: 'Note',
      sortable: true,
      filter: false,
    },
  ];
  if (!communicationType && !communicationId) {
    tableColum.push({
      field: '',
      header: 'Reference',
      sortable: false,
      filter: false,
      hidden: !isCrmEnabled(),
      style: { width: '12%' },
      body: params => {
        if (params.parent_id && params.parent_type && params.parent_number) {
          let redirectUrl = '';
          if (params.parent_type == 'opportunity') {
            redirectUrl = `/crm/${customerId}/lead/view/${params.parent_id}`;
          }
          if (params.parent_type == 'quote') {
            redirectUrl = `/crm/${customerId}/${params.parent_opportunity_key}/quote/view/${params.parent_id}`;
          }
          if (params.parent_type == 'invoice') {
            redirectUrl = `/crm/invoice/view/${params.parent_invoice_key}`;
          }
          return (
            <Chip
              label={params.parent_number}
              icon="pi pi-external-link"
              onClick={() => window.open(redirectUrl)}
              style={{ cursor: 'pointer' }}
              className="text-sm"
            />
          );
        }
        return null;
      },
    });
  }
  const fetchNotesList = useCallback(async () => {
    try {
      const urlParams = generateUrlParams({
        offset: lazyState.first,
        limit: lazyState.rows,
        sortField: lazyState.sortField,
        sortOrder: lazyState.sortOrder > 0 ? 'ASC' : 'DESC',
        globalSearch: globalFilterValue,
        communicationType,
        communicationId,
      });
      const response = await apiService.getWithDiffBaseUrl(
        `${URL_CONSTANTS.CRM.communication.notes.getAll}/${customerId}?${urlParams}`,
        null,
        URL_CONSTANTS.CRM.baseUrl
      );
      if (response) {
        setNotesList(response.data);
        setTotalRecords(response.count);
      }
      setLoading(false);
    } catch (error) {
      toast?.current?.show({
        severity: 'error',
        summary: 'Something went wrong',
        life: 2000,
      });
      setLoading(false);
      console.error(error);
    }
  }, [lazyState, globalFilterValue]);

  useEffect(() => {
    fetchNotesList();
  }, [customerId, reload, lazyState, globalFilterValue]);

  const renderHeader = () => {
    return (
      <div className="grid m-1 pl-2 pr-2">
        <div className="col-9 p-0">
          {isVersionActive && (
            <NotesForm
              customerId={customerId}
              reload={reload}
              setReload={setReload}
              opportunityId={opportunityId}
              quoteId={quoteId}
              invoiceId={invoiceId}
              paymentId={paymentId}
            />
          )}
        </div>

        <div className="col-3 flex justify-content-end align-items-center p-0">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <PFInputText
              value={globalFilterValue}
              onChange={e => {
                setGlobalFilterValue(e.target.value);
              }}
              placeholder="Keyword Search"
            />
          </span>
        </div>
      </div>
    );
  };
  const dataTableParams = { header: renderHeader() };

  return loading ? (
    <>
      <SkeletonLoader columnCount={1} columnWidth="100%" />
      <TableLoader columnCount={3} noOfRow={4} mTopBottom={0} />
    </>
  ) : (
    <>
      <PFDataTable
        columns={tableColum}
        data={notesList}
        dataTableParams={dataTableParams}
        lazy={true}
        dataKey="id"
        paginator={true}
        rows={lazyState.rows}
        rowsPerPageOptions={[5, 10, 50]}
        tableStyle={{ minWidth: '50rem' }}
        onPage={event => {
          setlazyState({
            ...lazyState,
            first: event.first,
            pageCount: event.pageCount,
            rows: event.rows,
          });
        }}
        first={lazyState.first}
        totalRecords={totalRecordsCount}
        onSort={event =>
          setlazyState({
            ...lazyState,
            sortField: event.sortField,
            sortOrder: event.sortOrder,
          })
        }
        sortField={lazyState.sortField}
        sortOrder={lazyState.sortOrder}
      />

      <Tooltip target=".custom-target-icon" />

      <Toast ref={toast} />
    </>
  );
};

export default NotesList;
