import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useParams } from 'react-router';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { OverlayPanel } from 'primereact/overlaypanel';
import { InputNumber } from 'primereact/inputnumber';
import { AutoComplete } from 'primereact/autocomplete';
import { debounce } from 'lodash';
import { CSVLink } from 'react-csv';
import { Skeleton } from 'primereact/skeleton';

import { formatCurrency } from '../../../../../utils/Helpers';
import {
  addEditChargeback,
  fetchItems,
  getMerchandiseItems,
} from '../../services/ChargebackService';
import { domHandlerCall } from '../../../../Admin/user-form/common';
import { useConfirmDialogContext } from '../../../../../contexts/ConfirmDialog';
import PFButton from '../../../../shared/PFPrime/PFButton';

const emptyLaborItemRow = {
  item: '',
  itemDescription: '',
  quantity: null,
  quantity_adjusted: null,
  cost: null,
};

const cols = [
  { field: 'item', header: 'Item No' },
  { field: 'itemDescription', header: 'Item Description' },
  { field: 'quantity', header: 'Original Qty' },
  { field: 'quantity_adjusted', header: 'Adjusted Qty' },
  { field: 'cost', header: 'Cost' },
];

const exportColumns = cols.map(col => ({
  title: col.header,
  dataKey: col.field,
}));

const MerchandiseItems = ({ toast, disabledFields }) => {
  const { showConfirmationDialog } = useConfirmDialogContext();
  const [isLoading, setIsLoading] = React.useState(false);
  let [merchandiseItemsList, setMerchandiseItemsList] = React.useState([]);
  let [merchandiseItemCount, setMerchandiseItemCount] = React.useState(0);
  const [itemsList, setItemsList] = React.useState([]);
  const [itemDesc, setItemDesc] = React.useState('');

  const exportOverlayRef = useRef(null);
  const { chargebackId } = useParams();

  const [filteredItemsList, setFilteredItemsList] = React.useState();
  const addressSection = useRef(null);
  const [isDisableAddButton, setIsDisableAddButton] = useState(false);
  const tableDataState = {
    limit: 10,
    page: 1,
    sortField: 'chargeback_merchandise_item_id',
    sortOrder: 1,
  };
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const dt = useRef(null);

  // Fetch Labor Items list
  const fetchInitiationData = async searchValue => {
    setIsLoading(true);
    try {
      const urlParams = {
        ...tableDataState,
        sortOrder: tableDataState.sortOrder === 1 ? 'asc' : 'desc',
      };
      if (searchValue || globalFilterValue)
        urlParams.globalSearch = searchValue || globalFilterValue;
      const initializationData = await getMerchandiseItems(
        chargebackId,
        urlParams
      );
      const { result, count } = initializationData?.data || {};
      setMerchandiseItemCount(count);
      if (count) {
        const merchandiseItems = result?.map(row => {
          return {
            item: row?.item,
            itemDescription: row?.item?.item_desc,
            quantity: row?.quantity,
            quantity_adjusted: row?.quantity_adjusted,
            cost: row?.cost,
            chargeback_merchandise_item_id: row?.chargeback_merchandise_item_id,
          };
        });
        setMerchandiseItemsList(merchandiseItems);
      }
    } catch (error) {
      console.error(error);
      toast.current.show({
        severity: 'error',
        summary: 'Something went wrong',
        life: 2000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Helper to fetch items data
  const fetchItemsData = async () => {
    let response = await fetchItems();

    const merchandiseItems = response?.filter(res => res?.item_type_id !== 2);
    setItemsList(merchandiseItems);
  };

  useEffect(() => {
    fetchInitiationData();
    fetchItemsData();
  }, []);

  //Add new row
  const handleAddNewRow = () => {
    setItemDesc('');
    setMerchandiseItemsList(prev => [emptyLaborItemRow, ...prev]);
    setTimeout(() => {
      const edit = domHandlerCall('p-row-editor-init', addressSection.current);
      edit?.handler[0].click();
    }, 300);
  };

  // // Validate the row before save
  const handleRowValidation = e => {
    const validationError = [];

    if (!e?.item) {
      validationError.push('Item');
    }
    if (!e?.quantity) {
      validationError.push('Quantity');
    }

    const isValid = !validationError.length > 0;
    if (!isValid) {
      const errorMessage = `${validationError.join(',')} Required`;
      toast.current.show({
        severity: 'error',
        summary: errorMessage,
        life: 2000,
      });
      return false;
    }
    return isValid;
  };

  // Save newly added or edit data
  const onRowEditComplete = async e => {
    setIsLoading(true);
    let originalMerchandiseItems = structuredClone(merchandiseItemsList);
    let { newData, index } = e;
    let body = {
      merchandiseItems: {
        item_id: newData?.item?.item_id,
        quantity: newData?.quantity,
        quantity_adjusted: newData?.quantity_adjusted,
        cost: newData?.cost,
      },
    };
    if (newData?.chargeback_merchandise_item_id)
      body.merchandiseItems.chargeback_merchandise_item_id =
        newData.chargeback_merchandise_item_id;
    Object.keys(body.merchandiseItems).forEach(key => {
      if (
        body.merchandiseItems[key] === null ||
        body.merchandiseItems[key] === undefined
      ) {
        delete body.merchandiseItems[key];
      }
    });
    let convertNewData = {
      ...newData,
      itemDescription: newData?.item?.item_desc,
    };
    originalMerchandiseItems[index] = convertNewData;
    setMerchandiseItemsList(originalMerchandiseItems);

    let editedResponse;
    try {
      editedResponse = await addEditChargeback(body, chargebackId);
      if (editedResponse?.statusCode === 200) {
        toast.current.show({
          severity: 'success',
          summary: editedResponse?.message,
          life: 2000,
        });
      }
    } catch (err) {
      console.error(err);
      toast.current.show({
        severity: 'error',
        summary: 'Something went wrong',
        life: 2000,
      });
    } finally {
      setIsLoading(false);
    }
    fetchInitiationData();
    disableEditButton(false);
  };

  // Delete selected data
  const handleDelete = async (rowIndex, id) => {
    let deleteResponse;
    setIsLoading(true);

    try {
      if (id) {
        let body = { merchandiseItems: { chargeback_merchandise_item_id: id } };
        deleteResponse = await addEditChargeback(body, chargebackId, true);
        if (deleteResponse?.statusCode === 200) {
          toast.current.show({
            severity: 'success',
            summary: deleteResponse?.message,
            life: 2000,
          });
        } else {
          toast.current.show({
            severity: 'error',
            summary: 'Something went wrong',
            life: 2000,
          });
          return;
        }
      }
      let editedMerchandiseItems = structuredClone(merchandiseItemsList);
      editedMerchandiseItems.splice(rowIndex, 1);
      setMerchandiseItemsList(editedMerchandiseItems);
    } catch (err) {
      console.error(err);
      toast.current.show({
        severity: 'error',
        summary: 'Something went wrong',
        life: 2000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteCancel = () => {
    disableEditButton(false);
  };

  const handleDeleteDialogHide = () => {
    disableEditButton(false);
  };

  const handleOnRowEditCancel = async (rowData, options) => {
    showConfirmationDialog({
      message:
        'Please confirm if you would like to cancel your unsaved changes or delete this record?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () =>
        handleDelete(
          options?.rowIndex,
          rowData?.chargeback_merchandise_item_id
        ),
      reject: handleDeleteCancel,
      onHide: handleDeleteDialogHide,
      rejectLabel: 'Cancel',
      acceptLabel: 'Delete',
      acceptIcon: 'pi pi-trash',
    });
  };

  const disableEditButton = status => {
    setTimeout(() => {
      const edit = domHandlerCall('p-row-editor-init', addressSection.current);
      edit?.handler?.forEach(row => {
        row.disabled = status;
      });
      setIsDisableAddButton(status);
    }, 300);
  };

  // Helper to show input field
  const textEditor = options => {
    return (
      <InputText
        type="text"
        disabled={options?.field === 'itemDescription'}
        className="p-inputtext-sm w-10rem"
        value={
          (options?.field === 'itemDescription' && itemDesc) || options.value
        }
        onChange={e => options.editorCallback(e.target.value)}
      />
    );
  };

  // Helper to show numeric field
  const numberEditor = options => {
    return (
      <InputNumber
        value={options?.value}
        onChange={e => options.editorCallback(e?.value)}
      />
    );
  };

  // Helper to show currency field
  const currencyEditor = options => {
    return (
      <InputNumber
        value={options?.value}
        onChange={e => options.editorCallback(e?.value)}
        mode="currency"
        currency="USD"
        locale="en-US"
      />
    );
  };

  //Merchandise Item option Template
  const merchandiseOptionTemplate = option => {
    return (
      <div className="flex align-items-center">
        <div>
          {option?.item_number} {option?.item_desc}
        </div>
      </div>
    );
  };
  const search = () => {
    const newItemsList = itemsList?.filter(res => res?.item_type_id !== 2);
    setFilteredItemsList(newItemsList);
  };
  // Helper to show item field
  const itemEditor = options => {
    return (
      <AutoComplete
        value={options?.value}
        suggestions={filteredItemsList || []}
        field="item_number"
        completeMethod={search}
        onChange={e => {
          setItemDesc(e.target.value?.item_desc);
          options.editorCallback(e?.target?.value);
        }}
        itemTemplate={merchandiseOptionTemplate}
        dropdown
        pt={{
          root: { className: 'height-40' },
        }}
      />
    );
  };

  //Show merchandise item column value
  const merchandiseItemColumnValue = option => {
    return isLoading ? (
      <Skeleton />
    ) : (
      <div className="flex align-items-center">
        <div>
          {option?.item?.item_number} {option?.item?.item_desc}
        </div>
      </div>
    );
  };

  // Helper to export CSV file
  const exportCSV = () => {
    exportOverlayRef?.current?.hide();
  };

  // Data to be exported in pdf/csv
  const exportData = useMemo(
    () =>
      merchandiseItemsList?.map(val => ({
        item: val?.item
          ? `${val?.item?.item_number} ${val?.item?.item_desc}`
          : null,
        itemDescription: val?.item?.item_desc,
        quantity: val?.quantity,
        quantity_adjusted: val?.quantity_adjusted,
        cost: val?.cost ? formatCurrency(val?.cost) : null,
      })),
    [merchandiseItemsList]
  );

  // Helper to export pdf file
  const exportPdf = () => {
    import('jspdf').then(jsPDF => {
      import('jspdf-autotable').then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(exportColumns, exportData);
        doc.save('chargeback-products.pdf');
        exportOverlayRef?.current?.hide();
      });
    });
  };
  const debouncedOnChange = useCallback(debounce(fetchInitiationData, 500), []);
  // To render header
  const renderHeader = () => {
    return (
      <div className="flex justify-content-between">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            className="p-inputtext-sm"
            placeholder="Search keyword"
            value={globalFilterValue}
            onChange={e => {
              setGlobalFilterValue(e.target.value);
              debouncedOnChange(e.target.value);
            }}
            disabled={merchandiseItemCount < 1}
          />
        </span>
        <div className="flex gap-4 align-items-center">
          {
            <div className="icon-round">
              <i
                className="icon pi pi-download cursor-pointer"
                onClick={e =>
                  merchandiseItemCount && exportOverlayRef?.current?.toggle(e)
                }
                style={{
                  opacity: merchandiseItemCount || 0.5,
                }}
              />
              <OverlayPanel ref={exportOverlayRef}>
                <div className="flex flex-column gap-2">
                  <CSVLink data={exportData} filename={'chargeback-products'}>
                    <Button
                      color="primary"
                      outlined
                      onClick={() => exportCSV(false)}
                      label="Export in CSV"
                    />
                  </CSVLink>

                  <Button color="primary" outlined onClick={exportPdf}>
                    Export in PDF
                  </Button>
                </div>
              </OverlayPanel>
            </div>
          }
          <div className="icon-round">
            <i
              className="icon pi pi-refresh cursor-pointer"
              onClick={() => fetchInitiationData()}
            />
          </div>
        </div>
      </div>
    );
  };

  const header = renderHeader();

  const rowEditorWithCustomButton = (rowData, options) => {
    return (
      <div className="flex justify-content-center align-items-center flex-wrap gap-2">
        {!options.rowEditor.editing && (
          <PFButton
            icon="pi pi-trash"
            outlined
            text
            className="text-white border-none px-0 p-button-danger"
            severity="primary"
            aria-label="Edit"
            onClick={() => handleOnRowEditCancel(rowData, options)}
          />
        )}
      </div>
    );
  };

  return (
    <>
      <div ref={addressSection}>
        <div className="flex flex-row justify-content-end align-items-center pb-3">
          <Button
            label="Add Product"
            size="small"
            onClick={handleAddNewRow}
            disabled={isDisableAddButton || disabledFields}
            outlined
          />
        </div>
        <DataTable
          ref={dt}
          value={merchandiseItemsList}
          header={header}
          editMode="row"
          onRowEditComplete={onRowEditComplete}
          onRowEditCancel={() => {
            disableEditButton(false);
            // handleDeleteCancel(e?.index);
          }}
          rowEditValidator={handleRowValidation}
          onRowEditInit={() => {
            disableEditButton(true);
          }}
          lazy
          dataKey="merchandiseItems"
          paginator
          rows={10}
          totalRecords={merchandiseItemsList?.length}
        >
          {!disabledFields && !isLoading && (
            <Column
              rowEditor
              bodyStyle={{ textAlign: 'center' }}
              headerStyle={{ width: '10%', minWidth: '7rem' }}
            ></Column>
          )}
          {!isLoading && (
            <Column
              rowEditor={true}
              headerStyle={{ width: '15%', minWidth: '10rem' }}
              bodyStyle={{ textAlign: 'center' }}
              body={rowEditorWithCustomButton}
            />
          )}
          <Column
            field="item"
            header="Product No"
            body={merchandiseItemColumnValue}
            editor={options => itemEditor(options)}
            sortable
          />
          <Column
            field="itemDescription"
            header="Product Description"
            editor={options => textEditor(options)}
            sortable
            body={params => (isLoading ? <Skeleton /> : params.itemDescription)}
          />
          <Column
            field="quantity_adjusted"
            header="Adjusted Qty"
            editor={options => numberEditor(options)}
            sortable
            body={params =>
              isLoading ? <Skeleton /> : params.quantity_adjusted
            }
          />
          <Column
            field="quantity"
            header="Original Qty"
            editor={options => numberEditor(options)}
            sortable
            body={params => (isLoading ? <Skeleton /> : params.quantity)}
          />
          <Column
            field="cost"
            header="Cost"
            editor={options => currencyEditor(options)}
            sortable
            body={params =>
              isLoading ? <Skeleton /> : formatCurrency(params?.cost)
            }
          />
        </DataTable>
        <ConfirmDialog />
      </div>
    </>
  );
};

export default MerchandiseItems;
