import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { Provider } from 'react-redux';
import { CssBaseline } from '@material-ui/core';

import App from './App';
import { AlertsProvider } from './components/shared/Alerts/alertsService';
import { store } from './redux';
import { ConfirmDialogContextProvider } from './contexts/ConfirmDialog';
import { Theme } from './utils/theme-palette/Theme';

const AppWrapper = () => {
  return (
    <>
      <CssBaseline />
      <MuiThemeProvider theme={Theme}>
        <DndProvider backend={HTML5Backend}>
          <AlertsProvider>
            <BrowserRouter>
              <Provider store={store}>
                <ConfirmDialogContextProvider>
                  <App />
                </ConfirmDialogContextProvider>
              </Provider>
            </BrowserRouter>
          </AlertsProvider>
        </DndProvider>
      </MuiThemeProvider>
    </>
  );
};

export default AppWrapper;
