import React, { useRef, FocusEvent } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import * as Yup from 'yup';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import { AutoComplete } from 'primereact/autocomplete';
import type { AutoCompleteCompleteEvent } from 'primereact/autocomplete';
import { debounce } from 'lodash';

import {
  IssueCategory,
  IssueItem,
  IssueStatus,
  Item,
} from '../Interface/issues.interface';
import { ACTION } from '../../../../constants/form-actions.constant';
import { syntheticEvent } from '../../../../utils/event.helper';
import {
  createIssue,
  getCategoryList,
  getItems,
  getItemsWithId,
  getOriginatingFromList,
  getSourceStatusList,
  updateIssue,
} from '../service/issues.service';
import { FEATURE_LIST } from '../../../Admin/NewConfiguration/EditCompanyConfig';
import { NO_OPTIONS_MSG } from '../../../../constants/string.constant';

interface IssuesFormProps {
  dialogFormObj: {
    visible: boolean;
    formAction: string | null;
    rowData: IssueItem | null;
  };
  updateDialogFormObj: (
    // eslint-disable-next-line no-unused-vars
    newState: Partial<IssuesFormProps['dialogFormObj']>
  ) => void;
  project_id: number;
  loadIssuesData: () => void;
  toast: React.RefObject<Toast | null>;
}

const IssuesForm: React.FC<IssuesFormProps> = ({
  dialogFormObj,
  updateDialogFormObj,
  project_id,
  loadIssuesData,
  toast,
}) => {
  const [loading, setLoading] = React.useState<number>(0);
  const itemRef = useRef<AutoComplete>(null);
  const [itemSearchResults, setItemSearchResults] = React.useState<Item[] | []>(
    []
  );
  const [options, setOptions] = React.useState<{
    originatingFromOptions: string[];
    categoryOptions: IssueCategory[] | [];
    statusOptions: IssueStatus[] | [];
  }>({ originatingFromOptions: [], categoryOptions: [], statusOptions: [] });

  const isSelecting = useRef(false);
  const getConvertToLaborItemPermission = (): boolean => {
    try {
      const clientDetails = localStorage.getItem('client_details');
      if (!clientDetails) return false;

      const parsedDetails = JSON.parse(clientDetails);
      if (!parsedDetails?.tenant_permissions?.length) return false;

      const permissions =
        parsedDetails.tenant_permissions[0]?.included_permissions;
      if (!Array.isArray(permissions)) return false;

      return FEATURE_LIST[3]?.key
        ? permissions.includes(FEATURE_LIST[3].key)
        : false;
    } catch (error) {
      console.error('Error parsing client details:', error);
      return false;
    }
  };

  const suggestItem = debounce(
    async (event: AutoCompleteCompleteEvent | null, item_id: number | null) => {
      let params = null;
      if (event) {
        const query = event?.query;
        params = query
          ? { query, limit: 10, offset: 0 }
          : { limit: 10, offset: 0 };
      }
      const itemsResponse = item_id
        ? await getItemsWithId(item_id)
        : await getItems(params);

      if (
        !itemsResponse.error &&
        itemsResponse?.options?.items &&
        Array.isArray(itemsResponse.options.items)
      ) {
        const laborList = itemsResponse?.options?.items?.map((item: Item) => {
          return {
            item_id: item.item_id,
            item_name: `${item?.item_number} - ${item?.item_desc}`,
          };
        });
        setItemSearchResults(laborList);
      } else {
        const laborItem = {
          item_id: itemsResponse?.options?.item_id,
          item_name: `${itemsResponse.options?.item_number}- ${itemsResponse.options?.item_desc}`,
        };

        issuesFormik.setFieldValue('item', laborItem);
      }
    },
    300
  );

  const displayCompanyRef = (event: FocusEvent<HTMLInputElement>): void => {
    if (isSelecting.current) return;
    suggestItem({ originalEvent: event, query: event.target.value }, null);
    if (itemRef.current) {
      itemRef.current.show();
    }
  };
  const handleSelectItem = () => {
    isSelecting.current = true;
    setTimeout(() => (isSelecting.current = false), 100);
  };

  const loadOriginatingFrom = async () => {
    setLoading(2);
    const response = await getOriginatingFromList();
    setLoading(3);
    if (!response?.error && Array.isArray(response.options)) {
      return response.options;
    } else {
      toast.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: response?.message,
        life: 3000,
      });
    }

    return [];
  };
  const loadCategory = async () => {
    setLoading(2);
    const response = await getCategoryList();
    setLoading(3);
    if (!response?.error && Array.isArray(response.options)) {
      return response.options;
    } else {
      toast.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: response?.message,
        life: 3000,
      });
    }

    return [];
  };
  const loadStatus = async () => {
    setLoading(2);
    const response = await getSourceStatusList();
    setLoading(3);
    if (!response?.error && Array.isArray(response.options)) {
      return response?.options;
    } else {
      toast.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: response?.message,
        life: 3000,
      });
    }
    return [];
  };

  React.useEffect(() => {
    const loadOptions = async () => {
      const originatingFrom = options.originatingFromOptions?.length
        ? options.originatingFromOptions
        : await loadOriginatingFrom();

      const category = options.categoryOptions?.length
        ? options.categoryOptions
        : await loadCategory();
      const status = options.statusOptions?.length
        ? options.statusOptions
        : await loadStatus();

      setOptions(prevOptions => ({
        ...prevOptions,
        originatingFromOptions: originatingFrom,
        categoryOptions: category,
        statusOptions: status,
      }));
    };

    if (dialogFormObj.visible) {
      loadOptions();
    }
  }, [dialogFormObj.visible]);
  // **** Form Validation Schema ****
  const IssueValidationSchema = Yup.object().shape({
    originated_from: Yup.string()
      .trim()
      .required('Originated From is required'),
    dollar_amount: Yup.number()
      .typeError('Dollar Amount must be a number')
      .min(0.01, `Dollar amount should be greater than 0`)
      .max(
        99999999.99,
        'Dollar amount must be less than or equal to 99999999.99'
      ),
    description: Yup.string().trim().required('Description is required'),
    issue_category_id: Yup.number().required('Category is required'),
  });

  // **** Formik Form Values ****
  const issuesFormik = useFormik<IssueItem>({
    initialValues: {
      date_created: null,
      originated_from: '',
      store_associate_name: '',
      dollar_amt: null,
      description: '',
      issue_category_id: null,
      rts_follow_up_date: null,
      quantity: null,
      issue_total_amount: null,
      item: null,
      issue_status: null,
    },
    onSubmit: async values => {
      setLoading(1);
      let response;
      const { item, ...rest } = values;
      const saveValues: IssueItem = {
        ...rest,
        item_id: Number(item?.item_id),
      };
      if (dialogFormObj.formAction === ACTION.ADD) {
        response = await createIssue(project_id, saveValues);
      } else {
        if (dialogFormObj.rowData?.project_issue_id)
          response = await updateIssue(
            project_id,
            dialogFormObj.rowData?.project_issue_id,
            saveValues
          );
      }
      if (!response?.error) {
        toast.current?.show({
          severity: 'success',
          summary: 'Success',
          detail: response?.message,
          life: 3000,
        });
        issuesFormik.handleReset(syntheticEvent);
        updateDialogFormObj({ visible: false });
        loadIssuesData();
      } else {
        toast.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: response.message,
          life: 3000,
        });
      }
      setLoading(0);
    },
    validationSchema: IssueValidationSchema,
    enableReinitialize: true,
    initialTouched: {},
  });

  React.useEffect(() => {
    const formValues: IssueItem = {
      date_created: null,
      originated_from: '',
      store_associate_name: '',
      dollar_amt: null,
      description: '',
      issue_category_id: null,
      rts_follow_up_date: null,
      quantity: null,
      issue_total_amount: null,
      item: null,
      issue_status: null,
    };
    // Only proceed if index and formAction are valid
    if (dialogFormObj.formAction !== ACTION.ADD && dialogFormObj.rowData) {
      if (dialogFormObj?.rowData?.date_created == null) {
        formValues.date_created = null;
      } else if (dialogFormObj?.rowData?.date_created instanceof Date) {
        formValues.date_created = dialogFormObj?.rowData?.date_created;
      } else {
        formValues.date_created = new Date(
          dialogFormObj?.rowData?.date_created as string | number
        );
      }

      formValues.originated_from = dialogFormObj.rowData.originated_from || '';
      formValues.store_associate_name =
        dialogFormObj.rowData.store_associate_name || '';
      formValues.dollar_amt = dialogFormObj.rowData.dollar_amt || null;
      formValues.description = dialogFormObj.rowData.description || '';
      formValues.issue_category_id =
        dialogFormObj.rowData.issue_category_id || null;

      if (dialogFormObj?.rowData?.project?.rts_follow_up_date == null) {
        formValues.rts_follow_up_date = null;
      } else if (
        dialogFormObj?.rowData?.project?.rts_follow_up_date instanceof Date
      ) {
        formValues.rts_follow_up_date =
          dialogFormObj?.rowData?.project?.rts_follow_up_date;
      } else {
        formValues.rts_follow_up_date = new Date(
          dialogFormObj?.rowData?.project?.rts_follow_up_date as string | number
        );
      }
      if (dialogFormObj.rowData?.item_id) {
        suggestItem(null, dialogFormObj.rowData?.item_id);
      }
      formValues.quantity = dialogFormObj.rowData.quantity || null;
      formValues.issue_total_amount =
        dialogFormObj.rowData.issue_total_amount || null;
      formValues.issue_status = dialogFormObj.rowData.issue_status || null;
    }
    // Set the form values to Formik
    issuesFormik.setValues(formValues);
  }, [dialogFormObj.rowData, dialogFormObj.visible, dialogFormObj.formAction]);

  React.useEffect(() => {
    const dollarAmount = issuesFormik.values.dollar_amt
      ? Number(issuesFormik.values.dollar_amt)
      : 0;

    const quantity = Number(issuesFormik.values.quantity) || 0;
    const totalAmount = dollarAmount * quantity;

    issuesFormik.setFieldValue('issue_total_amount', totalAmount);
  }, [issuesFormik.values.dollar_amt, issuesFormik.values.quantity]);

  const issueTotalDollar = !isNaN(issuesFormik.values.issue_total_amount ?? 0)
    ? (
        Math.floor((issuesFormik.values.issue_total_amount ?? 0) * 100) / 100
      ).toFixed(2)
    : '0.00';

  const footerContent = (
    <div>
      <Button
        label="Cancel"
        onClick={e => {
          updateDialogFormObj({ visible: false });
          issuesFormik.handleReset(e);
        }}
        type="button"
        size="small"
      />
      <Button
        label="Save"
        onClick={() => issuesFormik.handleSubmit()}
        loading={loading == 1}
        autoFocus
        disabled={
          !(issuesFormik?.dirty && issuesFormik?.isValid) ||
          loading === 1 ||
          dialogFormObj.formAction === 'view'
        }
        size="small"
        type="button"
      />
    </div>
  );

  const handleDateChange = (value: Date | null | undefined, field: string) => {
    // Convert UTC date to local date
    return value ? issuesFormik.setFieldValue(field, value) : null;
  };
  return (
    <Dialog
      header={
        dialogFormObj.formAction === ACTION.ADD
          ? 'Add Issue'
          : dialogFormObj.formAction === ACTION.EDIT
            ? 'Edit Issue'
            : 'View Issue'
      }
      pt={{
        header: { className: 'border-bottom-1 border-400 py-1' },
        headerTitle: { className: 'text-base' },
        content: { className: 'overflow-visible pb-1' },
      }}
      className="overflow-y-scroll"
      visible={dialogFormObj.visible}
      style={{ width: '25vw' }}
      onHide={() => {
        if (!dialogFormObj.visible) return;
        updateDialogFormObj({ visible: false });
        issuesFormik.handleReset(syntheticEvent);
      }}
      footer={footerContent}
    >
      <form className="mt-2">
        <div className="col-12">
          <span className="p-float-label w-full">
            <Calendar
              id="date_created"
              name="date_created"
              className="w-full"
              selectionMode="single"
              onChange={e => {
                handleDateChange(e?.value, 'date_created');
              }}
              onBlur={issuesFormik.handleBlur}
              disabled={dialogFormObj.formAction === ACTION.VIEW}
              value={issuesFormik?.values?.date_created}
            />
            <label htmlFor="date_created">Date Created</label>
          </span>
        </div>
        <div className="col-12">
          <span className="p-float-label w-full">
            <Dropdown
              value={issuesFormik.values.originated_from}
              id="originated_from"
              onChange={issuesFormik.handleChange}
              onBlur={issuesFormik.handleBlur}
              options={options.originatingFromOptions}
              className="w-full"
              showClear
              disabled={dialogFormObj.formAction === ACTION.VIEW}
              appendTo={() => document.body}
              emptyMessage={
                loading === 2 ? (
                  <div className="flex justify-center">
                    <ProgressSpinner
                      style={{ width: '20px', height: '20px' }}
                    />
                  </div>
                ) : (
                  NO_OPTIONS_MSG
                )
              }
            />
            <label htmlFor="status" className="text-xs">
              Originated From<span className="text-red-500">*</span>
            </label>
          </span>
          {issuesFormik.touched.originated_from &&
          issuesFormik.errors.originated_from ? (
            <div className="text-xs text-red-500">
              {issuesFormik.errors.originated_from}
            </div>
          ) : null}
        </div>
        <div className="col-12">
          <span className="p-float-label w-full">
            <InputText
              id="store_associate_name"
              value={issuesFormik?.values?.store_associate_name || ''}
              disabled={dialogFormObj.formAction === ACTION.VIEW}
              className="w-full"
              onChange={e => {
                issuesFormik.handleBlur(e);
                issuesFormik.setFieldValue(
                  'store_associate_name',
                  e.target.value
                );
              }}
            />
            <label htmlFor="store_associate_name" className="text-xs">
              Store Associate Name
            </label>
          </span>
        </div>
        <div className="col-12">
          <span className="p-float-label w-full">
            <InputNumber
              inputId="dollar_amt"
              className="w-full"
              inputClassName="w-full"
              placeholder=""
              value={issuesFormik.values.dollar_amt}
              onBlur={issuesFormik.handleBlur}
              onValueChange={e =>
                issuesFormik.setFieldValue(
                  'dollar_amt',
                  e.value ? Number(e.value) : ''
                )
              }
              mode="currency"
              currency="USD"
              useGrouping={false}
              disabled={dialogFormObj.formAction === ACTION.VIEW}
            />
            <label htmlFor="dollar_amt" className="text-xs">
              Dollar Amount
            </label>
          </span>
        </div>
        <div className="col-12">
          <span className="p-float-label w-full">
            <InputText
              id="description"
              value={issuesFormik?.values?.description || ''}
              disabled={dialogFormObj.formAction === ACTION.VIEW}
              className="w-full"
              onChange={e => {
                issuesFormik.handleBlur(e);
                issuesFormik.setFieldValue('description', e.target.value);
              }}
            />
            <label htmlFor="description" className="text-xs">
              Description<span className="text-red-500">*</span>
            </label>
          </span>
          {issuesFormik.touched.description &&
          issuesFormik.errors.description ? (
            <div className="text-xs text-red-500">
              {issuesFormik.errors.description}
            </div>
          ) : null}
        </div>
        <div className="col-12">
          <span className="p-float-label w-full">
            <Dropdown
              value={issuesFormik.values.issue_category_id}
              id="issue_category_id"
              onChange={issuesFormik.handleChange}
              onBlur={issuesFormik.handleBlur}
              options={options.categoryOptions}
              optionLabel="category"
              className="w-full"
              showClear
              optionValue="issue_category_id"
              disabled={dialogFormObj.formAction === ACTION.VIEW}
              appendTo={() => document.body}
              emptyMessage={
                loading === 2 ? (
                  <div className="flex justify-center py-2">
                    <ProgressSpinner
                      style={{ width: '20px', height: '20px' }}
                    />
                    <span className="ml-2">Loading...</span>
                  </div>
                ) : (
                  NO_OPTIONS_MSG
                )
              }
            />
            <label htmlFor="issue_category_id" className="text-xs">
              Category<span className="text-red-500">*</span>
            </label>
          </span>
          {issuesFormik.touched.issue_category &&
          issuesFormik.errors.issue_category ? (
            <div className="text-xs text-red-500">
              {issuesFormik.errors.issue_category}
            </div>
          ) : null}
        </div>
        <div className="col-12">
          <span className="p-float-label w-full">
            <Calendar
              id="rts_follow_up_date"
              name="rts_follow_up_date"
              className="w-full"
              selectionMode="single"
              onChange={e => {
                handleDateChange(e?.value, 'rts_follow_up_date');
              }}
              onBlur={issuesFormik.handleBlur}
              disabled={dialogFormObj.formAction === ACTION.VIEW}
              value={issuesFormik?.values?.rts_follow_up_date}
            />
            <label htmlFor="check_date">Follow Up Date</label>
          </span>
        </div>
        <div className="col-12">
          <span className="p-float-label w-full">
            <Dropdown
              value={issuesFormik.values.issue_status}
              appendTo={() => document.body}
              id="issue_status"
              onChange={issuesFormik.handleChange}
              onBlur={issuesFormik.handleBlur}
              options={options.statusOptions}
              optionLabel="status"
              optionValue="status_id"
              className="w-full"
              showClear
              disabled={dialogFormObj.formAction === ACTION.VIEW}
              emptyMessage={
                loading === 2 ? (
                  <div className="flex justify-content-center">
                    <ProgressSpinner
                      style={{ width: '20px', height: '20px' }}
                    />
                  </div>
                ) : (
                  NO_OPTIONS_MSG
                )
              }
            />
            <label htmlFor="issue_status" className="text-xs">
              Status
            </label>
          </span>
        </div>
        {getConvertToLaborItemPermission() && (
          <div className="col-12">
            <span className="p-float-label">
              <AutoComplete
                ref={itemRef}
                id="item"
                suggestions={itemSearchResults}
                field="item_name"
                completeMethod={(e: AutoCompleteCompleteEvent) =>
                  suggestItem(e, null)
                }
                value={issuesFormik.values.item}
                onSelect={handleSelectItem}
                onChange={e => {
                  issuesFormik.setFieldValue('item', e.value);
                  issuesFormik.setFieldValue('quantity', 1);
                }}
                onFocus={displayCompanyRef}
                className="w-full"
                delay={500}
                disabled={dialogFormObj.formAction === ACTION.VIEW}
              />

              <label htmlFor="item" className="text-xs">
                Item #
              </label>
            </span>
          </div>
        )}
        <div className="col-12">
          <span className="p-float-label w-full">
            <InputNumber
              inputId="quantity"
              inputClassName="w-full"
              className="w-full"
              showButtons
              placeholder=""
              value={issuesFormik.values.quantity}
              onChange={e => {
                issuesFormik.setFieldValue(
                  'quantity',
                  e.value ? Number(e.value) : ''
                );
              }}
              disabled={dialogFormObj.formAction === ACTION.VIEW}
              useGrouping={false}
              min={1}
            />
            <label htmlFor="quantity" className="text-xs">
              Quantity
            </label>
          </span>
          {issuesFormik.touched.quantity && issuesFormik.errors.quantity ? (
            <div className="text-xs text-red-500">
              {issuesFormik.errors.quantity}
            </div>
          ) : null}
        </div>
        <div className="col-12 w-full">
          <span className="p-float-label w-full">
            <InputNumber
              inputId="issue_total_amount"
              className="w-full"
              inputClassName="w-full"
              placeholder=""
              mode="currency"
              currency="USD"
              value={issueTotalDollar ? Number(issueTotalDollar) : 0.0}
              disabled
              useGrouping={false}
            />
            <label htmlFor="issue_total_amount" className="text-xs">
              Issue Total $
            </label>
          </span>
        </div>
      </form>
    </Dialog>
  );
};
export default IssuesForm;
