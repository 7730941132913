import axios, { AxiosError } from 'axios';
import { isEqual } from 'date-fns';

import { URL_CONSTANTS } from '../../../../constants/urlConstants';
import { MessageDetails } from '../Interface/sms.interface';
import { updateNotificationViewed } from '../../../shared/Topbar/Notification/Notification.service';
import { Notifications } from '../../../../constants/notification.constant';
import apiService from '../../../../services/api.service';

export const getSMSData = async (projectId: number) => {
  try {
    const response = await apiService.get(
      apiService.formatUrl(
        URL_CONSTANTS.PROJECTS.smsTab.fetchSMSData,
        projectId
      )
    );
    return {
      error: false,
      message: 'SMS list fetched successfully',
      smsData: response,
    };
  } catch (error: unknown) {
    return {
      error: true,
      message:
        error instanceof AxiosError
          ? error?.response?.data?.error
          : 'No SMS Found.',
      smsData: {},
    };
  }
};

export const createScheduledMessage = async (
  projectId: number,
  values: MessageDetails
) => {
  const isMsgSent =
    values?.scheduled_date && isEqual(values?.scheduled_date, new Date());

  try {
    const response = await apiService.post(
      apiService.formatUrl(
        URL_CONSTANTS.PROJECTS.smsTab.fetchSMSData,
        projectId
      ),
      values
    );
    return {
      error: false,
      message: isMsgSent
        ? 'Message sent successfully.'
        : 'Scheduled message added successfully.',
      smsData: response,
    };
  } catch (error: unknown) {
    return {
      error: true,
      message:
        error instanceof AxiosError
          ? error?.response?.data?.error
          : 'Error in adding Scheduled message.',
      smsData: {},
    };
  }
};

export const editScheduledMessage = async (
  projectId: number,
  sms_log_id: number,
  values: MessageDetails
) => {
  try {
    const response = await apiService.patch(
      apiService.formatUrl(
        URL_CONSTANTS.PROJECTS.smsTab.patchSMS,
        projectId,
        sms_log_id
      ),
      values
    );
    return {
      error: false,
      message: response?.message,
      smsData: response,
    };
  } catch (error: unknown) {
    return {
      error: true,
      message:
        error instanceof AxiosError
          ? error?.response?.data?.error
          : 'Error in editing Scheduled message.',
      smsData: {},
    };
  }
};

export const deleteScheduledMessage = async (
  projectId: number,
  sms_log_id: number
) => {
  try {
    const response = await apiService.delete(
      apiService.formatUrl(
        URL_CONSTANTS.PROJECTS.smsTab.patchSMS,
        projectId,
        sms_log_id
      )
    );
    return {
      error: false,
      message: response?.message || 'Message deleted successfully.',
      smsData: response.data,
    };
  } catch (error) {
    return {
      error: true,
      message:
        error instanceof AxiosError
          ? error?.response?.data?.error
          : 'Error in deleting message.',
      smsData: {},
    };
  }
};

export const getProjectSMSHistory = async (projectId: number) => {
  try {
    const response = await apiService.get(
      apiService.formatUrl(
        URL_CONSTANTS.PROJECTS.smsTab.fetchSMSHistory,
        projectId
      )
    );

    return {
      error: false,
      message: 'Message history fetched successfully.',
      smsData: response.data,
    };
  } catch (error) {
    return {
      error: true,
      message:
        error instanceof AxiosError
          ? error?.response?.data?.error
          : 'Error in fetching message history.',
      smsData: {},
    };
  }
};

export const markMessageAsReviewed = async (sms_log_id: number) => {
  try {
    const query = `id=${sms_log_id}`;
    const response = await axios.patch(
      `${URL_CONSTANTS.API.BASE_URL}/customers/message/reviewed?${query}`
    );
    await updateNotificationViewed(
      sms_log_id,
      Notifications.SMS,
      localStorage.getItem('user_id'),
      false,
      true
    );
    return {
      error: false,
      message: 'Message reviewed successfully.',
      smsData: response.data,
    };
  } catch (error) {
    return {
      error: true,
      message:
        error instanceof AxiosError
          ? error?.response?.data?.error
          : 'Error in reviewing message.',
      smsData: {},
    };
  }
};

export const getSMSCountData = async (projectId: number) => {
  try {
    const response = await apiService.get(
      apiService.formatUrl(
        URL_CONSTANTS.PROJECTS.smsTab.fetchSMSCount,
        projectId
      )
    );

    return {
      error: false,
      message: 'Message Count fetched successfully.',
      smsCount: response?.data?.[0],
    };
  } catch (error) {
    return {
      error: true,
      message:
        error instanceof AxiosError
          ? error?.response?.data?.error
          : 'Error in fetching message count.',
      smsCount: {},
    };
  }
};
