import React, { useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import type {
  DataTableRowEditCompleteEvent,
  DataTableRowEditValidatorOptions,
} from 'primereact/datatable';
import { Column } from 'primereact/column';
import type { ColumnBodyOptions, ColumnEditorOptions } from 'primereact/column';
import { Skeleton } from 'primereact/skeleton';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { Calendar } from 'primereact/calendar';
import { OverlayPanel } from 'primereact/overlaypanel';

import { useConfirmDialogContext } from '../../../../contexts/ConfirmDialog';
import {
  SMSLog,
  ColumnMeta,
  MessageDetails,
  SmsCountType,
} from '../Interface/sms.interface';
import { renderDate } from '../../../../utils/dates.helper';
import { DomHandlerCall } from '../../pf-prime/Prime';
import {
  deleteScheduledMessage,
  editScheduledMessage,
} from '../service/sms.service';

import SMSForm from './SMSForm';
import SMSHistory from './SMSHistory';

const SMSTable: React.FC<{
  project_id: number;
  toast: React.RefObject<Toast | null>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  smsListData: SMSLog[];
  loadSMSData: () => Promise<void>;
  phone_number: string;
  smsCount: SmsCountType;
}> = ({
  project_id,
  toast,
  loading,
  setLoading,
  smsListData,
  loadSMSData,
  phone_number,
  smsCount,
}) => {
  const tableRow = useRef(null);
  const [visible, setVisible] = React.useState(false);
  const { showConfirmationDialog } = useConfirmDialogContext();
  const op = useRef<OverlayPanel | null>(null);
  const [selectedMessage, setSelectedMessage] = React.useState<string | null>(
    null
  );

  const showOverlay = (
    e: React.MouseEvent<HTMLSpanElement>,
    message: string
  ) => {
    setSelectedMessage(message);
    op.current?.toggle(e, null);
  };

  // Dynamic body template
  const dynamicBodyTemplate = (
    rowData: SMSLog,
    options: ColumnBodyOptions
  ): React.ReactNode => {
    if (loading) {
      return <Skeleton width="100%" height="1.5rem" />;
    }

    if (
      options.field === 'requested_date_time' ||
      options.field === 'created_at'
    ) {
      return renderDate(rowData[options.field], 'MM-dd-yyyy hh:mm:ss a');
    }
    if (options.field === 'sender') {
      return rowData[options.field] || 'System';
    }
    if (options.field === 'message') {
      return (
        <>
          <div
            className="white-space-nowrap overflow-hidden text-overflow-ellipsis"
            onMouseEnter={e => showOverlay(e, rowData.message)}
          >
            {rowData.message}
          </div>
        </>
      );
    }

    // Default rendering for other fields
    const value = rowData[options.field as keyof SMSLog];
    return typeof value === 'string' || typeof value === 'number'
      ? value
      : null;
  };

  const header = (
    <div className="flex flex-wrap align-items-center w-full justify-content-between">
      <div className="flex flex-wrap col-9">
        <SMSForm
          project_id={project_id}
          toast={toast}
          loadSMSData={loadSMSData}
          phone_number={phone_number}
        />
      </div>
      <div className="flex flex-wrap align-items-center justify-content-end">
        <Tag
          className="mr-2"
          icon="pi pi-arrow-down"
          severity="danger"
          pt={{
            icon: { style: { marginLeft: '3px' } },
          }}
          value={smsCount.sms_response_count || 0}
        ></Tag>
        <Tag
          className="mr-2"
          icon="pi pi-arrow-up"
          severity="info"
          pt={{
            icon: { style: { marginLeft: '3px' } },
          }}
          value={smsCount.sms_log_count || 0}
        ></Tag>
        <Button
          outlined
          label="View SMS History"
          size="small"
          onClick={() => setVisible(true)}
          type="button"
        />
      </div>
    </div>
  );
  const textEditor = (options: ColumnEditorOptions) => {
    return (
      <InputText
        value={options.value}
        onChange={e => options.editorCallback?.(e.target.value)}
        className="w-full"
      />
    );
  };
  const dateEditor = (options: ColumnEditorOptions) => {
    return (
      <Calendar
        value={new Date(options.value)}
        onChange={e => options.editorCallback?.(e.value)}
        showTime
        hourFormat="12"
        appendTo={null}
        minDate={new Date()}
      />
    );
  };
  const disableEditButton = (status: boolean): void => {
    setTimeout(() => {
      if (!tableRow.current) return;

      const editElements = DomHandlerCall('p-row-editor-init');

      if (editElements?.handler?.length) {
        editElements.handler.forEach((row: SMSLog) => {
          if (row instanceof HTMLButtonElement) {
            row.disabled = status;
          }
        });
      }
    }, 300);
  };
  const onRowEditComplete = async (
    e: DataTableRowEditCompleteEvent
  ): Promise<void> => {
    setLoading(true);

    const { newData, index } = e;
    const sms_log_id = smsListData[index].sms_log_id;
    const updatedMessage: MessageDetails = {
      message: newData.message,
      scheduled_date: newData.requested_date_time || new Date(),
      receiver_primary_number: phone_number,
    };

    const response = await editScheduledMessage(
      project_id,
      sms_log_id,
      updatedMessage
    );
    if (!response.error) {
      toast.current?.show({
        severity: 'success',
        summary: 'Success',
        detail: response?.message,
        life: 3000,
      });
      loadSMSData();
    } else {
      toast.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: response?.message,
        life: 3000,
      });
    }
    disableEditButton(false);
  };

  const columns: ColumnMeta[] = [
    {
      field: 'requested_date_time',
      header: 'Scheduled Date and Time',
      editor: dateEditor,
      headerStyle: { width: '10%' },
    },
    {
      field: 'message',
      header: 'Message',
      editor: textEditor,
      headerStyle: { width: '20%' },
    },
    {
      field: 'sender',
      header: 'Sender',
      headerStyle: { width: '20%' },
    },
    {
      field: 'created_at',
      header: 'Date Created',
      headerStyle: { width: '20%' },
    },
  ];
  const handleDeleteCancel = () => {
    disableEditButton(false);
  };

  const handleDeleteDialogHide = () => {
    disableEditButton(false);
  };

  const handleDelete = async (index: number) => {
    setLoading(true);
    const sms_log_id = smsListData[index].sms_log_id;
    const response = await deleteScheduledMessage(project_id, sms_log_id);
    if (!response.error) {
      toast.current?.show({
        severity: 'success',
        summary: 'Success',
        detail: response?.message,
        life: 3000,
      });
      loadSMSData();
    } else {
      toast.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: response?.message,
        life: 3000,
      });
    }
    setLoading(false);
    disableEditButton(false);
  };

  const handleOnRowEditCancel = async (
    e: DataTableRowEditCompleteEvent
  ): Promise<void> => {
    showConfirmationDialog({
      message:
        'Please confirm if you would like to cancel your unsaved changes or delete this record?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => handleDelete(e.index),
      reject: handleDeleteCancel,
      onHide: handleDeleteDialogHide,
      rejectLabel: 'Cancel',
      acceptLabel: 'Delete',
      acceptIcon: 'pi pi-trash',
    });
  };
  const handleRowValidation = (
    data: SMSLog,
    options: DataTableRowEditValidatorOptions<SMSLog[]>
  ): boolean => {
    const { rowIndex } = options;

    const validationErrors: string[] = [];

    const isChanged =
      smsListData[rowIndex].message !== data.message ||
      renderDate(
        smsListData[rowIndex].requested_date_time,
        'MM-dd-yyyy hh:mm:ss a'
      ) !== renderDate(data.requested_date_time, 'MM-dd-yyyy hh:mm:ss a');
    if (!isChanged) {
      toast.current?.show({
        severity: 'info',
        summary: 'No changes detected',
        life: 2000,
      });
      return false;
    }

    if (!data.message) {
      validationErrors.push('Message');
    }

    const isValid = validationErrors.length === 0;

    if (!isValid) {
      const errorMessage = `${validationErrors.join(', ')} Required`;
      toast.current?.show({
        severity: 'error',
        summary: errorMessage,
        life: 2000,
      });
      return false;
    }

    return true;
  };
  const rowEditorWithCustomButton = (
    data: SMSLog,
    options: ColumnBodyOptions
  ) => {
    const editingRows = options.props?.editingRows as
      | Record<string, SMSLog>
      | undefined;
    const isEditing = editingRows?.[data.sms_log_id] !== undefined;
    return (
      <div className="flex justify-content-center align-items-center flex-wrap gap-2">
        {!isEditing && (
          <Button
            icon="pi pi-trash"
            outlined
            text
            type="button"
            className="text-white border-none px-0 p-button-danger"
            aria-label="Edit"
            onClick={() => {
              handleOnRowEditCancel({
                newData: data,
                data: data,
                index: smsListData.findIndex(
                  item => item.sms_log_id === data.sms_log_id
                ),
                originalEvent: {} as React.SyntheticEvent,
                field: '',
              });
            }}
          />
        )}
      </div>
    );
  };

  return (
    <div className="col-12 mt-4">
      <DataTable
        value={smsListData}
        header={header}
        editMode="row"
        dataKey="sms_log_id"
        ref={tableRow}
        onRowEditCancel={() => {
          disableEditButton(false);
        }}
        onRowEditInit={() => {
          disableEditButton(true);
        }}
        onRowEditComplete={onRowEditComplete}
        rowEditValidator={handleRowValidation}
      >
        <Column
          rowEditor
          headerStyle={{ width: '7%', minWidth: '7rem' }}
          bodyStyle={{ textAlign: 'center' }}
        />
        <Column
          header="Actions"
          headerStyle={{ width: '7%', minWidth: '7rem' }}
          bodyStyle={{ textAlign: 'center' }}
          body={rowEditorWithCustomButton}
        />

        {columns.length > 0
          ? columns.map(col => (
              <Column
                key={col.field}
                field={col.field}
                header={col.header}
                body={dynamicBodyTemplate}
                editor={col.editor}
                headerStyle={col.headerStyle}
              />
            ))
          : null}
      </DataTable>
      <OverlayPanel
        ref={op}
        pt={{
          root: { className: 'z-50 surface-100' },
          closeButton: {
            style: { width: '1.2rem', height: '1.2rem' },
            className: 'text-primary',
          },
          closeIcon: {
            style: { width: '0.8rem', height: '0.8rem' },
            className: 'text-white',
          },
        }}
        showCloseIcon
      >
        <div
          className="overflow-auto"
          style={{
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-word',
            maxWidth: '20rem',
            maxHeight: '10rem',
          }}
        >
          {selectedMessage}
        </div>
      </OverlayPanel>
      <SMSHistory
        project_id={project_id}
        visible={visible}
        setVisible={setVisible}
        toast={toast}
      />
    </div>
  );
};

export default SMSTable;
