import axios from 'axios';
import { useHistory } from 'react-router-dom/';
import { isBrowser } from 'react-device-detect';

import {
  REACT_APP_AUTH_CLIENT_SECRET,
  REACT_APP_AUTH_CLIENT_ID,
  REACT_APP_AUTH_REFRESH_GRANT_TYPE,
  REACT_APP_AUTH_AUTHORIZE_GRANT_TYPE,
} from '../constants/envConstants';
import { authURL } from '../config/variables';

export const refreshToken = refresh_token => {
  let error = false;
  if (refresh_token) {
    axios
      .post(`${authURL}/token`, {
        refresh_token: refresh_token,
        client_secret: REACT_APP_AUTH_CLIENT_SECRET,
        client_id: REACT_APP_AUTH_CLIENT_ID,
        grant_type: REACT_APP_AUTH_REFRESH_GRANT_TYPE,
      })
      .then(res => {
        if (
          res?.status === 200 &&
          res.data.refresh_token &&
          res.data.access_token
        ) {
          localStorage.setItem('access_token', res.data.access_token);
          localStorage.setItem('refresh_token', res.data.refresh_token);
        } else {
          error = true;
        }
      })
      .catch(function (error) {
        error = true;
        console.log(error);
        localStorage.clear();
        useHistory.push('/');
      });
  } else {
    error = true;
  }
  if (error === true) {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('exp');
    window.location = window.location.origin;
  }
};

export const generateToken = (code, setToken) => {
  axios
    .post(`${authURL}/token`, {
      code: code,
      client_secret: REACT_APP_AUTH_CLIENT_SECRET,
      client_id: REACT_APP_AUTH_CLIENT_ID,
      grant_type: REACT_APP_AUTH_AUTHORIZE_GRANT_TYPE,
      device_type: isBrowser ? 'web' : 'mobile',
    })
    .then(res => {
      if (
        res?.status === 200 &&
        res.data.refresh_token &&
        res.data.access_token
      ) {
        setToken(res.data);
      }
    })
    .catch(function (error) {
      console.log(error);
      window.location = window.location.origin;
    });
};
