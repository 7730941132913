import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Column } from 'primereact/column';
import { Skeleton } from 'primereact/skeleton';
import { DataTable } from 'primereact/datatable';
import { Avatar } from 'primereact/avatar';
import { Badge } from 'primereact/badge';
import { Toast } from 'primereact/toast';
import { Tooltip } from 'primereact/tooltip';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Checkbox } from 'primereact/checkbox';
import { ConfirmDialog } from 'primereact/confirmdialog';
import dayjs from 'dayjs';
import { ListBox } from 'primereact/listbox';

import PFDialog from '../../shared/PFPrime/PFDialog';
import { WORK_HOURS } from '../../../constants';

import { getUserJobCoverageValueWithSlotsCount } from './services/user-form-job-assign-coverage.service';
import { getWorkHours } from './services/user-form-availability.service';
import AddEditUserJobCoverageSlotDialog from './components/AddEditUserJobCoverageSlotDialog';
import AddEditUserJobCoverageSlotOfflineDialog from './components/AddEditUserJobCoverageSlotOfflineDialog';

const enabledClasses = 'cursor-pointer bg-green-500 hover:bg-green-700';
const disabledClasses = 'surface-700 pointer-events-none opacity-60';
const SelectedJobCoverageValueList = ({
  coverageDialogVisible,
  setCoverageDialogVisible,
  selectedCoverageValues,
  isOffline,
  formik,
}) => {
  const toast = useRef(null);
  const copyToAllRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [coverageSlotValues, setCoverageSlotValues] = useState([]);
  const [workingDays, setWorkingDays] = useState([]);
  const [workHours, setWorkHours] = useState(WORK_HOURS);
  const [isVisible, setIsVisible] = useState(false);
  const [addEditDialogContent, setAddEditDialogContent] = useState();
  const [copyToAllCoverageValues, setCopyToAllCoverageValues] = useState({});
  const [dialogProps, setDialogProps] = useState({
    visible: false,
    props: {},
  });
  const hasParts =
    selectedCoverageValues?.parts &&
    typeof selectedCoverageValues?.parts === 'object' &&
    Object.keys(selectedCoverageValues?.parts).length;
  const findAndSetWorkingDays = data => {
    const workHourWorkingDays = [];
    data.forEach(eachDay => {
      if (eachDay.is_working) workHourWorkingDays.push(eachDay.day);
    });
    setWorkHours(data);
    setWorkingDays(workHourWorkingDays);
  };
  const callGetWorkHours = async () => {
    setLoading(true);
    try {
      const getApiResponse = await getWorkHours(
        selectedCoverageValues.raw.user_id
      );
      if (
        Array.isArray(getApiResponse?.data?.userWorkDetails) &&
        getApiResponse.data.userWorkDetails.length
      ) {
        findAndSetWorkingDays(getApiResponse.data.userWorkDetails);
      }
    } catch (ex) {
      console.error(ex);
    }
  };
  const callUserCoverageValueWithSlotsCount = async () => {
    try {
      const uniqueKey = `${selectedCoverageValues.raw.project_typeId}_${selectedCoverageValues.raw.project_categoryId}_${selectedCoverageValues.raw.coverage_by}`;
      let prevCategorySlots = formik?.values?.slots?.[uniqueKey] || [];
      setLoading(true);
      let result = {};
      if (!isOffline) {
        result = await getUserJobCoverageValueWithSlotsCount(
          selectedCoverageValues.raw.user_id,
          selectedCoverageValues.raw.user_coverage_preference_id,
          selectedCoverageValues.raw.coverage_values
        );
      }
      const data = [];
      const foundCoverageSlot = new Set();
      if (Array.isArray(result?.data) && result.data.length) {
        for (const element of result.data) {
          if (element.coverage_value in selectedCoverageValues.parts) {
            data.push({
              value: selectedCoverageValues.parts[element.coverage_value],
              action: element,
            });
            foundCoverageSlot.add(element.coverage_value);
          }
        }
      }
      for (const coverageKey in selectedCoverageValues.parts) {
        if (!foundCoverageSlot.has(coverageKey)) {
          const coverageIndex = isOffline
            ? prevCategorySlots.findIndex(({ key }) => key === coverageKey)
            : -1;
          const prevCoverageSlotsObj =
            coverageIndex > -1 && prevCategorySlots.length
              ? prevCategorySlots[coverageIndex]
              : {
                  key: coverageKey,
                  slots: {},
                };
          data.push({
            value: selectedCoverageValues.parts[coverageKey],
            action: {
              coverage_value: coverageKey,
              Monday:
                (Array.isArray(prevCoverageSlotsObj.slots?.Monday) &&
                  prevCoverageSlotsObj.slots.Monday.length) ||
                0,
              Tuesday:
                (Array.isArray(prevCoverageSlotsObj.slots?.Tuesday) &&
                  prevCoverageSlotsObj.slots.Tuesday.length) ||
                0,
              Wednesday:
                (Array.isArray(prevCoverageSlotsObj.slots?.Wednesday) &&
                  prevCoverageSlotsObj.slots.Wednesday.length) ||
                0,
              Thursday:
                (Array.isArray(prevCoverageSlotsObj.slots?.Thursday) &&
                  prevCoverageSlotsObj.slots.Thursday.length) ||
                0,
              Friday:
                (Array.isArray(prevCoverageSlotsObj.slots?.Friday) &&
                  prevCoverageSlotsObj.slots.Friday.length) ||
                0,
              Saturday:
                (Array.isArray(prevCoverageSlotsObj.slots?.Saturday) &&
                  prevCoverageSlotsObj.slots.Saturday.length) ||
                0,
              Sunday:
                (Array.isArray(prevCoverageSlotsObj.slots?.Sunday) &&
                  prevCoverageSlotsObj.slots.Sunday.length) ||
                0,
            },
          });
        }
      }
      if (data.length) setCoverageSlotValues(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (hasParts) callGetWorkHours().then(callUserCoverageValueWithSlotsCount);
    else {
      setCoverageSlotValues(selectedCoverageValues);
    }
  }, [selectedCoverageValues]);

  const handleCopy = (eachCoverageValue, value, name) => {
    try {
      if (value) {
        const uniqueKey = `${copyToAllCoverageValues.from.project_typeId}_${copyToAllCoverageValues.from.project_categoryId}_${copyToAllCoverageValues.from.coverage_by}`;
        const prevCategorySlots = formik?.values?.slots?.[uniqueKey] || [];
        const coverageIndex = prevCategorySlots.findIndex(
          ({ key }) => key === copyToAllCoverageValues.from.coverage_value
        );
        const prevCoverageSlotsObj =
          coverageIndex > -1 && prevCategorySlots.length
            ? prevCategorySlots[coverageIndex]
            : {
                key: copyToAllCoverageValues.from.coverage_value,
                slots: {},
              };
        setCopyToAllCoverageValues({
          ...copyToAllCoverageValues,
          to: {
            ...copyToAllCoverageValues.to,
            [eachCoverageValue]: value,
          },
        });
        const currentCoverageIndex = prevCategorySlots.findIndex(
          ({ key }) => key === eachCoverageValue
        );
        const currentCoverageSlotsObj =
          currentCoverageIndex > -1
            ? prevCategorySlots[currentCoverageIndex]
            : {
                key: eachCoverageValue,
                slots: {},
              };
        currentCoverageSlotsObj.slots = structuredClone(
          prevCoverageSlotsObj.slots
        );
        formik.setFieldValue('slots', {
          ...formik.values.slots,
          [uniqueKey]:
            currentCoverageIndex > -1
              ? prevCategorySlots.map(element =>
                  element.key === eachCoverageValue
                    ? currentCoverageSlotsObj
                    : element
                )
              : [...prevCategorySlots, currentCoverageSlotsObj],
        });
        const daysSlotCount = {};
        for (const key in currentCoverageSlotsObj.slots) {
          if (Array.isArray(currentCoverageSlotsObj.slots[key])) {
            daysSlotCount[key] = currentCoverageSlotsObj.slots[key].length;
          }
        }
        setCoverageSlotValues(prev => {
          const updatedCoverageSlotValues = [];
          prev.forEach(eachSlot => {
            if (eachSlot.action.coverage_value === eachCoverageValue) {
              updatedCoverageSlotValues.push({
                ...eachSlot,
                action: {
                  ...eachSlot.action,
                  ...daysSlotCount,
                },
              });
            } else {
              updatedCoverageSlotValues.push(eachSlot);
            }
          });
          return updatedCoverageSlotValues;
        });
        toast.current.show({
          severity: 'success',
          summary: `Slots copied successfully to ${name}`,
          life: 3000,
        });
      }
    } catch (ex) {
      console.error(ex);
      toast.current.show({
        severity: 'error',
        summary: `Something went wrong`,
        life: 3000,
      });
    }
  };
  const setCopyDialogProps = (action, eachCoverage, checked, name) => {
    if (action) {
      setDialogProps({
        visible: true,
        props: {
          message: (
            <div>
              Please confirm if you would like to copy all days slots of{' '}
              <b>{copyToAllCoverageValues.from.coverage_name} </b> to{' '}
              <b>{name}</b>?
              <br />
              On click confirm, all the current days slots of{' '}
              <b>{name} Job Coverage</b> will be deleted.
            </div>
          ),
          header: 'Copy Slot Confirmation',
          icon: 'pi pi-info-circle',
          acceptClassName: 'p-button-danger',
          accept: () => handleCopy(eachCoverage, checked, name),
          rejectLabel: 'Cancel',
          acceptLabel: 'Confirm',
          onHide: () => setCopyDialogProps(false),
        },
      });
    } else {
      setDialogProps({
        visible: false,
        props: {},
      });
    }
  };
  const columns = [
    {
      field: 'value',
      header: 'Value',
      style: { width: '250px', fontSize: '14px' },
    },
    {
      field: 'action',
      header: '',
      ...(loading
        ? {}
        : {
            body: rowData => {
              let hasSlots = null;
              const dayRows = workHours.map((eachDay, index) => {
                if (rowData.action[eachDay?.day]) {
                  hasSlots = true;
                }
                return (
                  <Avatar
                    key={`coverage-slot-avatar-${index}`}
                    label={eachDay?.day}
                    size="small"
                    onClick={
                      workingDays.includes(eachDay?.day)
                        ? () => {
                            setAddEditDialogContent({
                              user_id: selectedCoverageValues.raw.user_id,
                              user_job_coverage_id:
                                selectedCoverageValues.raw
                                  .user_coverage_preference_id,
                              coverage_value: rowData.action.coverage_value,
                              coverage_name: rowData.value,
                              project_categoryId:
                                selectedCoverageValues.raw.project_categoryId,
                              project_typeId:
                                selectedCoverageValues.raw.project_typeId,
                              coverage_by:
                                selectedCoverageValues.raw.coverage_by,
                              copyToCoverageValues:
                                selectedCoverageValues.parts,
                              day: eachDay?.day,
                              start_time: new Date(
                                `${dayjs(new Date()).format('MM-DD-YYYY')} ${eachDay.start_time}`
                              ),
                              end_time: new Date(
                                `${dayjs(new Date()).format('MM-DD-YYYY')} ${eachDay.end_time}`
                              ),
                            });
                            setIsVisible(true);
                          }
                        : () =>
                            toast.current.show({
                              severity: 'error',
                              summary: `${eachDay?.day} is business off`,
                              life: 1500,
                            })
                    }
                    className={`w-5rem p-overlay-badge text-white text-sm ${
                      workingDays.includes(eachDay?.day)
                        ? enabledClasses
                        : disabledClasses
                    }`}
                  >
                    {workingDays.includes(eachDay?.day) ? (
                      <Badge
                        value={rowData.action[eachDay?.day]}
                        severity={
                          rowData.action[eachDay?.day] ? 'primary' : 'secondary'
                        }
                      ></Badge>
                    ) : (
                      <Badge value="off" severity="danger"></Badge>
                    )}
                  </Avatar>
                );
              });
              return (
                <div className="flex flex-start align-items-center gap-5 flex-wrap">
                  {dayRows}
                  {isOffline && hasSlots && (
                    <>
                      <i
                        className="material-icons-sharp md-32 md-copy_all hover:text-xl cursor-pointer ml-2"
                        onClick={e => {
                          const copyToHashMap = {};
                          if (hasParts) {
                            Object.keys(selectedCoverageValues.parts).forEach(
                              val => {
                                if (val !== rowData.action.coverage_value) {
                                  copyToHashMap[val] =
                                    copyToAllCoverageValues?.to?.[val] || false;
                                }
                              }
                            );
                          }
                          if (Object.keys(copyToHashMap).length) {
                            setCopyToAllCoverageValues({
                              from: {
                                coverage_value: rowData.action.coverage_value,
                                coverage_name: rowData.value,
                                project_categoryId:
                                  selectedCoverageValues.raw.project_categoryId,
                                project_typeId:
                                  selectedCoverageValues.raw.project_typeId,
                                coverage_by:
                                  selectedCoverageValues.raw.coverage_by,
                              },
                              to: copyToHashMap,
                            });
                            copyToAllRef.current.toggle(e);
                          }
                        }}
                        data-pr-tooltip="Copy To"
                        data-pr-position="top"
                        data-pr-my="center right-8"
                        role="button"
                        tabIndex={0}
                        onKeyDown={e => {
                          if (e?.key === 'Enter' || e?.key === ' ') {
                            e.preventDefault();
                            copyToAllRef.current.toggle(e);
                          }
                        }}
                      ></i>
                      <Tooltip target=".md-copy_all" />
                    </>
                  )}
                </div>
              );
            },
          }),
    },
  ];
  const loaderTable = useMemo(() => {
    const loaderObj = {
      value: <Skeleton width="10rem" height="3rem" />,
      action: (
        <div className="flex align-items-center gap-5 flex-wrap">
          <Skeleton width="5rem" height="3rem" />
          <Skeleton width="5rem" height="3rem" />
          <Skeleton width="5rem" height="3rem" />
          <Skeleton width="5rem" height="3rem" />
          <Skeleton width="5rem" height="3rem" />
          <Skeleton width="5rem" height="3rem" />
          <Skeleton width="5rem" height="3rem" />
        </div>
      ),
    };
    return [1, 2, 3, 4, 5].map(() => loaderObj);
  }, [columns]);
  return (
    <PFDialog
      header="Coverage Values"
      show={coverageDialogVisible}
      hide={() => setCoverageDialogVisible(false)}
      className="w-11 lg:w-8"
      BodyComponent={
        hasParts ? (
          <>
            <DataTable
              value={loading ? loaderTable : coverageSlotValues}
              emptyMessage="No record found"
            >
              {columns.map((eachCol, index) => (
                <Column key={`selected_job_coverage_${index}`} {...eachCol} />
              ))}
            </DataTable>

            {isVisible && !isOffline && (
              <AddEditUserJobCoverageSlotDialog
                isVisible={isVisible}
                setIsVisible={setIsVisible}
                slotDetail={addEditDialogContent}
                workHours={workHours}
                setCoverageSlotValues={setCoverageSlotValues}
              />
            )}
            {isVisible && isOffline && (
              <AddEditUserJobCoverageSlotOfflineDialog
                isVisible={isVisible}
                setIsVisible={setIsVisible}
                slotDetail={addEditDialogContent}
                workHours={workHours}
                setCoverageSlotValues={setCoverageSlotValues}
                setCopyToAllCoverageValues={setCopyToAllCoverageValues}
                formik={formik}
              />
            )}
            {dialogProps.visible && (
              <ConfirmDialog
                visible={dialogProps.visible}
                {...dialogProps.props}
              />
            )}
            <Toast ref={toast}></Toast>
            <OverlayPanel ref={copyToAllRef}>
              <div className="flex gap-2 align-items-center flex-column justify-content-start">
                {Object.keys(copyToAllCoverageValues?.to || {}).map(
                  (eachCoverage, index) => {
                    return (
                      <div
                        key={`${index}_${eachCoverage}`}
                        className="flex w-full"
                      >
                        <Checkbox
                          inputId={`${index}_${eachCoverage}`}
                          name="coverage_value"
                          onChange={e =>
                            setCopyDialogProps(
                              true,
                              eachCoverage,
                              e.checked,
                              selectedCoverageValues.parts[eachCoverage]
                            )
                          }
                          checked={copyToAllCoverageValues.to[eachCoverage]}
                          disabled={copyToAllCoverageValues.to[eachCoverage]}
                        />
                        <label
                          htmlFor={`${index}_${eachCoverage}`}
                          className={`ml-2 ${copyToAllCoverageValues.to[eachCoverage] ? 'opacity-40' : ''}`}
                        >
                          {selectedCoverageValues.parts[eachCoverage]}
                        </label>
                      </div>
                    );
                  }
                )}
              </div>
            </OverlayPanel>
          </>
        ) : (
          <ListBox
            listStyle={{ maxHeight: '250px' }}
            value={coverageSlotValues}
            options={coverageSlotValues.map(value => ({
              label: value,
              value,
            }))}
            optionLabel="label"
            className="w-full"
          />
        )
      }
    ></PFDialog>
  );
};
export default SelectedJobCoverageValueList;
