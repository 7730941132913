import {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';

const AlertsContext = createContext();

export const AlertsProvider = ({ children }) => {
  const initialState = useRef({
    exists: false,
    type: null,
    message: null,
    isTemporary: null,
    scrollWindow: null,
  });

  const [state, setState] = useState(initialState.current);

  const setAlert = useCallback(
    (type, message, isTemporary = false, scrollWindow = true) => {
      setState({
        exists: true,
        type,
        message,
        isTemporary,
        scrollWindow,
      });
    },
    []
  );

  const clearAlert = useCallback(() => {
    setState(initialState.current);
  }, []);

  return (
    <AlertsContext.Provider value={{ alert: state, setAlert, clearAlert }}>
      {children}
    </AlertsContext.Provider>
  );
};

// Custom hook
export const useAlerts = () => {
  return useContext(AlertsContext);
};
