import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import PFInputText from '../../shared/PFPrime/PFInputText';
import PFDropdown from '../../shared/PFPrime/PFDropdown';
import PFButton from '../../shared/PFPrime/PFButton';
import PFDataTable from '../../shared/PFPrime/PFDataTable';

import ViewTableComponent from './ViewTableComponent';
import { QUESTION_TYPES } from './questionnaire.contants';

const MAX_LIST_ITEMS = 10;

const TypeTableComponent = forwardRef((props, ref) => {
  const { questionDialogContent } = useSelector(state => state.questionnaire);
  const [formEntries, setFormEntries] = useState([]);
  const formRefs = useRef([]);

  useEffect(() => {
    if (questionDialogContent?.question_type?.id === QUESTION_TYPES.table.id) {
      const sortedFields = [
        ...(questionDialogContent?.format_field_types || []),
      ]
        .sort((a, b) => (a.position || 0) - (b.position || 0))
        .map(field => ({
          question_type_label: field.format_question_label || '',
          question_type: QUESTION_TYPES?.[field.format_question_type] || '',
          options: field.options || [],
          config: { position: field?.position || 1 },
          position: field?.position || 0,
          newOption: '',
        }));

      setFormEntries(sortedFields);
    } else {
      setFormEntries([
        {
          question_type_label: '',
          question_type: '',
          options: [],
          newOption: '',
          config: { position: 1 },
        },
      ]);
    }
  }, [questionDialogContent]);

  const formik = useFormik({
    initialValues: {
      columns: formEntries,
    },
    validationSchema: Yup.object().shape({
      columns: Yup.array().of(
        Yup.object().shape({
          question_type_label: Yup.string()
            .trim()
            .required('Field Label is required'),
          question_type: Yup.object().shape({
            id: Yup.string().required('Field type is required'),
            label: Yup.string().required('Field type is required'),
          }),
          options: Yup.array().when('question_type.id', {
            is: 'list',
            then: Yup.array()
              .of(
                Yup.object().shape({
                  label: Yup.string()
                    .trim()
                    .min(1, 'Option cannot be empty')
                    .required('Option cannot be empty'),
                })
              )
              .min(1, 'Options are required')
              .required('Options are required'),
          }),
          newOption: Yup.string(),
        })
      ),
    }),
    onSubmit: values => {
      console.log('Final JSON Output:', JSON.stringify(values, null, 2));
    },
    enableReinitialize: true,
  });

  const handleAddColumn = insertIndex => {
    const newColumn = {
      question_type_label: '',
      question_type: '',
      options: [],
      newOption: '',
      config: { position: insertIndex + 2 }, // Set position
    };

    let updatedColumns = [...formik.values.columns];
    updatedColumns.splice(insertIndex + 1, 0, newColumn); // Insert at the clicked index

    updatedColumns = updatePositions(updatedColumns); // Recalculate positions

    setFormEntries(updatedColumns);
    formik.setFieldValue('columns', updatedColumns);
  };

  const updatePositions = columns => {
    return columns.map((col, index) => ({
      ...col,
      config: { position: index + 1 },
    }));
  };

  const handleRemoveColumn = index => {
    if (formik.values.columns.length > 1) {
      let updatedColumns = [...formik.values.columns];
      updatedColumns.splice(index, 1);
      updatedColumns = updatePositions(updatedColumns); // Update positions after removal
      setFormEntries(updatedColumns);
      formik.setFieldValue('columns', updatedColumns);
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleAddOption(index);
    }
  };

  const handleAddOption = index => {
    const newOption = formik.values.columns[index].newOption?.trim();
    if (!newOption) return;

    let updatedColumns = [...formik.values.columns];
    let currentOptions = updatedColumns[index].options || [];

    if (currentOptions.length < MAX_LIST_ITEMS) {
      updatedColumns[index].options = [...currentOptions, { label: newOption }];
      updatedColumns[index].newOption = ''; // Clear input after adding
      formik.setFieldValue('columns', updatedColumns);
    }
  };

  const handleRemoveOption = (columnIndex, optionIndex) => {
    let updatedColumns = _.cloneDeep(formik.values.columns);
    updatedColumns[columnIndex].options.splice(optionIndex, 1);
    formik.setFieldValue('columns', updatedColumns);
  };

  const listOptionTextEditor = options => {
    return (
      <PFInputText
        type="text"
        value={options.value}
        onChange={e => options.editorCallback(e.target.value)}
      />
    );
  };

  const onListOptionCellEditComplete = (e, columnIndex) => {
    let updatedColumns = _.cloneDeep(formik.values.columns);
    updatedColumns[columnIndex].options[e.rowIndex].label = e.newRowData.label;
    formik.setFieldValue('columns', updatedColumns);
  };

  useImperativeHandle(ref, () => ({
    validateAndGetValues: async () => {
      const errors = await formik.validateForm();
      if (Object.keys(errors).length !== 0) {
        formik.setTouched({
          columns: formik.values.columns.map(() => ({
            question_type_label: true,
            question_type: true,
            options: true,
          })),
        });
        const firstInvalidIndex = formik?.values?.columns?.findIndex(
          (_, index) =>
            errors.columns?.[index]?.question_type_label ||
            errors.columns?.[index]?.question_type ||
            errors.columns?.[index]?.options
        );

        // Scroll to the first invalid form
        if (firstInvalidIndex !== -1 && formRefs.current[firstInvalidIndex]) {
          formRefs?.current[firstInvalidIndex]?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }
        return null; // Return null if validation fails
      }
      return formik.values; // Return values if valid
    },
  }));

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        {formik.values.columns.map((column, index) => (
          <div
            key={index}
            ref={el => (formRefs.current[index] = el)}
            className={`grid flex p-2 md:p-3 gap-2 bg-gray-100 mt-3 p-card`}
          >
            {/* Question Type Label */}
            <div className="flex flex-column col-12 md:col-3">
              <span className="p-float-label w-full">
                <PFInputText
                  name={`columns.${index}.question_type_label`}
                  value={formik.values.columns[index].question_type_label}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="w-full"
                />
                <label htmlFor="field_label">
                  Field Label <span style={{ color: 'red' }}>*</span>
                </label>
              </span>
              {formik.touched.columns?.[index]?.question_type_label &&
                formik.errors.columns?.[index]?.question_type_label && (
                  <div className="mt-1 text-sm text-red-600">
                    {formik.errors.columns[index].question_type_label}
                  </div>
                )}
            </div>

            {/* Question Type Dropdown */}
            <div className="flex flex-column col-12 md:col-3">
              <span className="p-float-label w-full">
                <PFDropdown
                  value={formik.values.columns[index].question_type}
                  onChange={(name, value) =>
                    formik.setFieldValue(
                      `columns.${index}.question_type`,
                      value
                    )
                  }
                  options={[
                    QUESTION_TYPES.rating10,
                    QUESTION_TYPES.rating5,
                    QUESTION_TYPES.list,
                    QUESTION_TYPES.text,
                  ]}
                  optionLabel="label"
                  placeholder="Select Question Type"
                  className="w-full"
                />
                <label htmlFor="field_type">
                  Field Type <span style={{ color: 'red' }}>*</span>
                </label>
              </span>
              {formik.touched.columns?.[index]?.question_type &&
                (formik.errors.columns?.[index]?.question_type?.id ||
                  formik.errors.columns?.[index]?.question_type?.label) && (
                  <div className="mt-1 text-sm text-red-600">
                    {formik.errors.columns[index].question_type.id ||
                      formik.errors.columns[index].question_type.label}
                  </div>
                )}
            </div>

            {/* Options Input for List Type */}
            {formik.values.columns[index].question_type?.id ===
              QUESTION_TYPES.list.id && (
              <div className="col-12 md:col-4">
                <div className="p-inputgroup">
                  <span className="p-float-label">
                    <PFInputText
                      name={`columns.${index}.newOption`}
                      value={formik.values.columns[index].newOption}
                      onChange={e =>
                        formik.setFieldValue(
                          `columns.${index}.newOption`,
                          e.target.value
                        )
                      }
                      onKeyDown={e => handleKeyDown(e, index)}
                    />
                    <label htmlFor="add_list">Add List Option</label>
                  </span>
                  <PFButton
                    label="Add"
                    icon="pi pi-plus"
                    onClick={() => handleAddOption(index)}
                    disabled={!formik.values.columns[index].newOption?.trim()}
                  />
                </div>
                {formik.touched.columns?.[index]?.options &&
                  formik.errors.columns?.[index]?.options && (
                    <div className="mt-1 text-sm text-red-600">
                      {formik.errors.columns[index].options}
                    </div>
                  )}
              </div>
            )}

            {/* Delete Column Button */}
            <div className="flex col-12 md:col-1 align-items-center h-4rem gap-2 justify-content-center">
              {formik.values.columns.length > 1 && (
                <i
                  className="pi pi-trash text-2xl text-red-500 cursor-pointer"
                  onClick={() => handleRemoveColumn(index)}
                />
              )}
              <i
                className="pi pi-plus-circle text-2xl text-primary cursor-pointer"
                onClick={() => handleAddColumn(index)}
              />
            </div>

            {/* Editable Options Table */}
            {formik.values.columns[index].question_type?.id ===
              QUESTION_TYPES.list.id &&
              formik.values.columns[index].options.length > 0 && (
                <PFDataTable
                  editMode="cell"
                  columns={[
                    {
                      field: '',
                      header: '',
                      style: { width: '32px' },
                      sortable: false,
                      filter: false,
                      body: (_, option) => (
                        <i
                          className="pi pi-trash cursor-pointer text-red-500"
                          onClick={() =>
                            handleRemoveOption(index, option.rowIndex)
                          }
                        />
                      ),
                    },
                    {
                      field: 'label',
                      header: 'List Options',
                      sortable: false,
                      filter: false,
                      editor: row => listOptionTextEditor(row),
                      onCellEditComplete: e =>
                        onListOptionCellEditComplete(e, index),
                    },
                  ]}
                  tableStyle={{ width: '100%' }}
                  data={formik.values.columns[index].options}
                  stripedRows
                  scrollable
                  scrollHeight="250px"
                />
              )}
          </div>
        ))}
      </form>
      <div className="mt-3">
        {formEntries.length > 1 && (
          <>
            <ViewTableComponent
              question={questionDialogContent}
              previewTable={formik.values.columns}
              formik={formik}
              mode={'edit'}
            />
          </>
        )}
      </div>
    </>
  );
});

TypeTableComponent.displayName = 'TypeTableComponent';
export default TypeTableComponent;
