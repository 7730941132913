import React from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Button, Box, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import NumberFormat from 'react-number-format';
import { Skeleton } from 'primereact/skeleton';

import { getActiveInstallers } from './LaborProfiles.service';
import { useStyles } from './LaborProfiles.styles';
const ProfileDetails = ({ setAlert, formik, action, profileId, loading }) => {
  const classes = useStyles();
  const history = useHistory();
  const [activeInstallersListData, setActiveInstallersListData] =
    React.useState();
  React.useEffect(async () => {
    await getActiveInstallers(setActiveInstallersListData, setAlert);
  }, [profileId]);
  const withValueLimit = ({ floatValue }) =>
    (!floatValue ? null : floatValue) <= 100;
  return (
    <Grid container spacing={3}>
      <Grid item container spacing={2} direction="row" xs={12}>
        <Box className={classes.boxColumn} m={1}>
          {loading ? (
            <Skeleton className="w-full" height="3rem" />
          ) : action === 'add' && activeInstallersListData ? (
            <Autocomplete
              name="installer_id"
              options={activeInstallersListData}
              onBlur={formik?.handleBlur}
              openOnFocus={true}
              onChange={(event, value) =>
                formik.setFieldValue('installer_id', value.installer_id)
              }
              getOptionLabel={option =>
                option && `${option.first_name} ${option.last_name}`
              }
              required
              renderInput={params => (
                <TextField
                  {...params}
                  name="installer_id"
                  label="Technician Name"
                  placeholder="Select Technician"
                  InputLabelProps={{ shrink: true }}
                  required
                  error={
                    formik.touched.installer_id && formik.errors.installer_id
                  }
                  helperText={
                    formik.touched.installer_id && formik.errors.installer_id
                  }
                />
              )}
            />
          ) : (
            <TextField
              name="installer_name"
              label="Technician Name"
              value={formik?.values?.installer_id || ''}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                readOnly: action === 'view' ? true : false,
              }}
              disabled
            />
          )}
        </Box>
        <Box className={classes.boxColumn} m={1}>
          {loading ? (
            <Skeleton className="w-full" height="3rem" />
          ) : (
            <NumberFormat
              id="default_percent_paid"
              name="default_percent_paid"
              inputProps={{ maxLength: 6 }}
              required={action === 'edit' || action === 'add'}
              allowNegative={false}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label={`Default Percentage Paid To Worker`}
              customInput={TextField}
              inputmode="numeric"
              InputLabelProps={{ shrink: true }}
              decimalScale={2}
              suffix={'%'}
              isAllowed={withValueLimit}
              value={
                formik?.values?.default_percent_paid ||
                formik?.values?.default_percent_paid === '0%'
                  ? formik?.values?.default_percent_paid
                  : ''
              }
              error={
                formik.touched.default_percent_paid &&
                formik.errors.default_percent_paid
              }
              helperText={
                formik.touched.default_percent_paid &&
                formik.errors.default_percent_paid
              }
              disabled={action === 'view' ? true : false}
            />
          )}
        </Box>
        <Box className={classes.boxColumn} m={1}>
          {loading ? (
            <Skeleton className="w-full" height="3rem" />
          ) : (
            <TextField
              name="cloned_from"
              label="Cloned From"
              value={formik?.values?.cloned_from || ''}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                readOnly: true,
              }}
              disabled
            />
          )}
        </Box>
      </Grid>
      {(action === 'add' || action === 'edit') && (
        <Grid item container spacing={2}>
          <Grid item>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                if (action === 'edit') {
                  history.push(`/admin/laborprofiles`);
                } else {
                  history.goBack();
                }
              }}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              disabled={!formik?.dirty || !formik.isValid}
              onClick={() => {
                formik.handleSubmit();
              }}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default ProfileDetails;
