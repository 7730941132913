import React, { useState, useEffect, useRef } from 'react';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Tooltip } from 'primereact/tooltip';
import { TreeTable } from 'primereact/treetable';
import { InputText } from 'primereact/inputtext';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';

import apiService from '../../../services/api.service';
import { URL_CONSTANTS } from '../../../constants/urlConstants';
import './css/style.css';
import PFButton from '../../shared/PFPrime/PFButton';
import { formatDateTimeMDY, formatDateTimeMDYHM } from '../../../utils/Helpers';
import PFTableLoader from '../../shared/Loader/PFTableLoader';
import { useConfirmDialogContext } from '../../../contexts/ConfirmDialog';

import SearchChildProject from './components/SearchChildProject';
import SyncDialog from './components/SyncDialog';

export default function ProjectLinkageContainer({
  customerDetails,
  currentProject,
  clientDetails,
}) {
  const toast = useRef(null);
  const { showConfirmationDialog } = useConfirmDialogContext();
  const { customer_id, client_id } = customerDetails?.[0] || {};

  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const [syncData, setSyncData] = useState(null);
  const [childProject, setChildProject] = useState(null);
  const [searchVisible, setSearchVisible] = useState(false);
  const [globalFilter, setGlobalFilter] = useState('');
  const [syncDialogEnable, setSyncDialogEnable] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [first, setFirst] = useState(0);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(10);
  const [config, setConfig] = useState({ force_sync: false, options: [] });
  const [selectedCols, setSelectedCols] = useState([]);
  const columns = [
    {
      field: 'number_of_addcharge',
      header: '# of Exceptions',
      className: 'w-10rem',
    },
    {
      field: 'pr_number',
      header: '# of PR Numbers',
      className: 'w-10rem',
    },
    {
      field: 'project_type.project_type',
      header: 'Type',
      className: 'w-10rem',
    },
    {
      field: 'project_category.category',
      header: 'Category',
      className: 'w-10rem',
    },
    {
      field: 'date_sold',
      header: 'Date Sold',
      className: 'w-10rem',
      body: data => formatDateTimeMDY(data?.data?.date_sold),
    },
    {
      field: 'technician_name',
      header: 'Technician Name',
      className: 'w-10rem',
    },
    {
      field: 'scheduled_date_time',
      header: 'Schedule Date and Time',
      className: 'w-15rem',
      body: data => formatDateTimeMDYHM(data?.data?.scheduled_date_time),
    },
    {
      field: 'completion_date',
      header: 'Completion Date',
      body: data =>
        data?.data?.completion_date
          ? formatDateTimeMDY(data?.data?.completion_date)
          : null,
      className: 'w-10rem',
    },
    {
      field: 'project_number',
      header: 'Project#',
      className: 'w-18rem',
    },
    {
      field: 'po_number',
      header: 'PO#',
      className: 'w-15rem',
    },
    {
      field: 'project_status',
      header: 'Status',
      className: 'w-10rem',
    },
    {
      field: 'source_status',
      header: 'Source Status',
      className: 'w-10rem',
    },
    {
      field: 'soruce_portal_url',
      header: 'Source portal URL',
      className: 'w-15rem',
      body: data =>
        data?.data?.soruce_portal_url ? (
          <a
            href={data?.data?.soruce_portal_url}
            target="_blank"
            rel="noreferrer"
          >
            Open in Portal
          </a>
        ) : null,
    },
    {
      field: 'check_date',
      header: 'Check Date',
      body: data =>
        data?.data?.check_date
          ? formatDateTimeMDY(data?.data?.check_date)
          : null,
      className: 'w-10rem',
    },
    {
      field: 'check_number',
      header: 'Check Number',
      className: 'w-10rem',
    },
    {
      field: 'check_amount',
      header: 'Check Amount',
      className: 'w-10rem',
    },
    {
      field: 'total_sale_amount',
      header: 'Total Sale Amount',
      className: 'w-10rem',
    },
  ];

  useEffect(() => {
    const cols =
      clientDetails?.related_po_columns ||
      clientDetails?.tenant_configuration?.related_po_columns ||
      [];
    if (cols?.length > 0) {
      const mergedColumns = cols?.map(apiColumn => {
        const staticColumn = columns?.find(
          col => col?.field === apiColumn?.field
        );

        const mergedColumn = {
          ...apiColumn,
          className: staticColumn?.className || 'w-15rem',
        };

        if (staticColumn?.body) {
          mergedColumn.body = staticColumn?.body;
        }

        return mergedColumn;
      });

      setSelectedCols([...mergedColumns]);
    } else {
      setSelectedCols(columns);
    }
  }, [clientDetails]);
  const getList = () => {
    if (customer_id) {
      setLoading(true);
      const linkProjects = apiService.getWithDiffBaseUrl(
        URL_CONSTANTS.CRM.projectLinkage?.getLinkedProjects,
        { page, rows, customer_id, project_id: currentProject },
        URL_CONSTANTS.CRM.baseUrl
      );
      linkProjects
        .then(project => {
          if (project?.status) {
            setTotalRecords(project?.data?.total_count || 0);
            setProjects(project?.data?.data || []);
            setLoading(false);
          }
        })
        .catch(error => {
          console.error('Error fetching projects:', error);
          setLoading(false);
          toast?.current?.show({
            severity: 'error',
            detail: error?.response?.data?.message || 'Something went wrong',
          });
        });
    }
  };
  const getConfig = () => {
    if (client_id) {
      const linkProjects = apiService.post(
        URL_CONSTANTS.CRM.projectLinkage?.linkConfig,
        {
          client_id: client_id,
        }
      );
      linkProjects
        .then(config => {
          if (config?.status) {
            const { project_linkage } = config?.data?.client || [];
            if (project_linkage) setConfig(project_linkage);
          }
        })
        .catch(error => {
          console.error('Error fetching projects:', error);
        });
    }
  };

  useEffect(() => {
    getList();
    getConfig();
  }, [first, rows, page]);

  const onPage = event => {
    setFirst(event.first);
    setRows(event.rows);
    setPage(event.page);
  };

  const unLinkProjectCall = body => {
    setLoading(true);
    const unlinkProjects = apiService.postWithDiffBaseUrl(
      URL_CONSTANTS.CRM.projectLinkage?.unLinkProject,
      body,
      URL_CONSTANTS.CRM.baseUrl
    );
    unlinkProjects.then(res => {
      if (res.status) {
        toast?.current?.show({
          severity: 'success',
          detail: res?.message || 'Project unlinked successfully',
        });
        getList();
      }
    });
  };

  const unLinkConfirmation = props => {
    showConfirmationDialog({
      message:
        'Delinking would not remove the sync data and would make this project as a Parent project. Would you still like to continue ?',
      icon: 'pi pi-exclamation-triangle text-xl',
      accept: () => {
        unLinkProjectCall(props);
      },
      acceptLabel: 'Yes',
      rejectLabel: 'No',
      reject: () => false,
      header: 'Confirmation',
      acceptClassName: 'p-button-primary p-button-sm',
      rejectClassName: 'p-button-sm p-button-outlined',
      style: { width: '40vw' },
      breakpoints: { '960px': '90vw' },
    });
  };

  const syncProject = data => {
    setLoading(true);
    const linkProject = apiService.postWithDiffBaseUrl(
      URL_CONSTANTS.CRM.projectLinkage.setLinkProject,
      data,
      URL_CONSTANTS.CRM.baseUrl
    );
    linkProject
      .then(res => {
        toast?.current?.show({
          severity: 'success',
          detail: res?.message || 'Project linked successfully',
        });
        getList();
      })
      .catch(error => {
        toast?.current?.show({
          severity: 'error',
          detail: error?.response?.data?.message || 'Something went wrong',
        });
        setLoading(false);
      });
  };

  const actionTemplate = node => {
    const { project_id, child_linkage = null } = node.data;
    return (
      <div className={node?.child_key ? 'ml-6' : ''}>
        <PFButton
          icon="pi pi-pencil"
          outlined
          text
          className="text-white border-none focus:shadow-none shadow-none"
          severity="secondary"
          aria-label="Edit"
          onClick={() => window.open(`/project/edit/${project_id}`, '_blank')}
          pt={{ icon: { className: 'text-color' } }}
        />
        <PFButton
          icon="pi pi-eye"
          outlined
          text
          className="text-white border-none focus:shadow-none shadow-none"
          severity="secondary"
          aria-label="view"
          onClick={() => window.open(`/project/view/${project_id}`, '_blank')}
          pt={{ icon: { className: 'text-color' } }}
        />
        {config?.enabled && !child_linkage && (
          <>
            {node?.child_key && customer_id ? (
              <>
                <Tooltip target=".unlink" />
                <img
                  alt="Unlink"
                  className="ml-3 cursor-pointer width-18 unlink"
                  src="/images/UnlinkIcon.svg"
                  height={30}
                  data-pr-tooltip="Unlink Child"
                  onClick={() => {
                    unLinkConfirmation({
                      linked_project_id: project_id,
                      project_id: node?.data?.parent_project_id,
                    });
                  }}
                />
              </>
            ) : (
              <>
                <Tooltip target=".link" />
                <img
                  alt="Link"
                  className="ml-3 cursor-pointer width-18 link"
                  src="/images/link-Icon.svg"
                  data-pr-tooltip="Link Child"
                  height={30}
                  onClick={() => {
                    setChildProject(project_id);
                    setSearchVisible(true);
                  }}
                />
              </>
            )}
          </>
        )}
      </div>
    );
  };

  const getHeader = () => {
    return (
      <div className="flex justify-content-start">
        <div className="p-input-icon-left">
          <i className="pi pi-search"></i>
          <InputText
            type="search"
            onInput={e => setGlobalFilter(e.target.value)}
            placeholder="Search Project"
          />
        </div>
      </div>
    );
  };

  let header = getHeader();
  const [expandedKeys, setExpandedKeys] = useState([]);

  useEffect(() => {
    const length = projects.length;
    if (length > 0) {
      setExpandedKeys(Array.from({ length }, (_, index) => index));
    }
  }, [projects]);
  const rowClassName = node => {
    if (currentProject == node?.data?.project_id) {
      return {
        'current-highlight d-none': currentProject == node?.data?.project_id,
      };
    }
    return { 'parent-highlight': node.child_key === undefined };
  };
  return (
    <div className="surface-50" style={{ margin: '-20px' }}>
      <Toast ref={toast} />
      {loading ? (
        <PFTableLoader />
      ) : (
        <div className="card">
          {searchVisible && customer_id && (
            <SearchChildProject
              searchVisible={searchVisible}
              setSearchVisible={setSearchVisible}
              setSyncDialogEnable={setSyncDialogEnable}
              customer_id={customer_id}
              child_project={childProject}
              setSyncData={setSyncData}
              config={config}
              syncProject={syncProject}
            />
          )}
          {syncData && !config?.force_sync && (
            <SyncDialog
              setSearchVisible={setSearchVisible}
              syncDialogEnable={syncDialogEnable}
              setSyncDialogEnable={setSyncDialogEnable}
              syncData={syncData}
              config={config}
              syncProject={syncProject}
            />
          )}

          <div className="px-4">
            <h2>Related Projects</h2>
          </div>

          <TreeTable
            togglerTemplate={<></>}
            value={projects}
            tableStyle={{ minWidth: '1200px' }}
            globalFilter={globalFilter}
            header={header}
            paginator={true}
            rowsPerPageOptions={[5, 10, 25, 50]}
            first={first}
            rows={rows}
            totalRecords={totalRecords}
            onPage={onPage}
            lazy={true}
            columnResizeMode="expand"
            pt={{
              header: {
                className: 'bg-white',
              },
              treetable: 'pl-5',
            }}
            expandedKeys={config?.enabled ? expandedKeys : []}
            rowClassName={rowClassName}
          >
            <Column
              body={actionTemplate}
              expander={true}
              className="w-15rem"
              pt={{
                treetable: 'pl-5',
              }}
            />
            {selectedCols.map((col, index) => (
              <Column
                key={index}
                field={col.field}
                header={col.header}
                className={col.className}
                body={col.body}
              />
            ))}
          </TreeTable>
        </div>
      )}
      <ConfirmDialog />
    </div>
  );
}
