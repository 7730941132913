import ReactSpeedometer from 'react-d3-speedometer';

import { storeDashboardAnalysis } from '../service/widget.service';
const GaugeChartComponent = ({
  cubeResponse,
  target,
  annotation,
  dashboardDetails,
  dashboardLogDetails,
}) => {
  const responseKey = cubeResponse?.length
    ? Object.keys(cubeResponse?.[0])?.[0]
    : '';

  let formatter = null;
  if (annotation && Object.keys(annotation)) {
    Object.keys(annotation).forEach(key => {
      if (annotation[key]?.meta?.format) {
        formatter = eval(annotation[key].meta.format);
      }
    });
  }

  const maxValue =
    cubeResponse?.[0]?.[responseKey] == null
      ? 0
      : cubeResponse?.[0]?.[responseKey];

  const formatTextValue = () => {
    if (formatter && cubeResponse?.[0]?.[responseKey]) {
      return formatter(cubeResponse?.[0]?.[responseKey]) || 0;
    }
    return cubeResponse?.[0]?.[responseKey] || 0;
  };
  if (dashboardDetails && dashboardLogDetails) {
    storeDashboardAnalysis({
      ...dashboardLogDetails,
      ...dashboardDetails,
      executionTime: new Date().getTime() - dashboardDetails.start_time,
      end_time: new Date().getTime(),
    });
  }
  return (
    <div>
      <div
        className="gauge-container"
        style={{
          display: 'flex',
          flexWrap: 'nowrap',
          flexDirection: 'column',
          textAlign: 'center',
          position: 'relative',
        }}
      >
        <label
          style={{
            position: 'absolute',
            top: '89px',
            width: '100%',
            fontSize: '13px',
          }}
        >
          {formatTextValue()}
        </label>
        <ReactSpeedometer
          forceRender={true}
          maxValue={target < maxValue ? maxValue : target == 0 ? 100 : target}
          value={maxValue}
          needleColor="black"
          segments={200}
          maxSegmentLabels={0}
          needleHeightRatio={0.5}
          width={170}
          height={170}
          ringWidth={14}
          textColor="transparent"
        />
      </div>
    </div>
  );
};

export default GaugeChartComponent;
