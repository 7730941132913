import React from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';

const Loader = () => (
  <div className="w-full h-full flex justify-content-center align-items-center">
    <ProgressSpinner />
  </div>
);

export default Loader;
