import axios from 'axios';

import { URL_CONSTANTS } from '../../../constants/urlConstants';

export const getSystemTenants = async () => {
  try {
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/system/tenants`
    );
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const getClientDetails = async () => {
  try {
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/system/client-details`
    );
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};
