import React, { useState, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { AutoComplete } from 'primereact/autocomplete';
import { Dropdown } from 'primereact/dropdown';
import { useFormik } from 'formik';
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from 'primereact/inputtextarea';
import { BlockUI } from 'primereact/blockui';

import { dateFormatter } from '../../../../../utils/Helpers';
import {
  addEditChargeback,
  createPayoutCategory,
  searchPayoutCategory,
} from '../../services/ChargebackService';

const PayoutForm = ({
  visible,
  chargebackPayout,
  setShowPayoutDialog,
  setRefreshList,
  paymentTypeOptions,
  paymentStatusOptions,
  chargebackId,
}) => {
  const toast = useRef(null);

  const formik = useFormik({
    initialValues: {
      ...chargebackPayout,
    },
    enableReinitialize: true,
  });
  const paymentCategoriesRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [paymentCategoriesSearchResults, setPaymentCategoriesSearchResults] =
    useState([]);
  const [releasePayoutChecked, setReleasePayoutChecked] = useState(
    !!chargebackPayout?.chargeback_payout_id
  );

  const handleSubmit = async () => {
    const body = {
      payment_date: formik.values?.payment_date,
      payment_category_id: formik.values?.payment_category?.payment_category_id,
      payment_type_id: formik.values?.payment_type?.payment_type_id,
      payment_status_id: formik.values?.payment_status?.status_id,
      payment_amount: formik.values?.payment_amount,
      payment_txn_id: formik.values?.payment_txn_id,
      description: formik.values?.description,
    };
    if (chargebackPayout?.chargeback_payout_id)
      body.chargeback_payout_id = chargebackPayout.chargeback_payout_id;
    setLoading(true);
    let response = await addEditChargeback({ payouts: body }, chargebackId);
    if (response?.data) {
      toast?.current?.show({
        severity: 'success',
        summary: response?.message,
        life: 2000,
      });
    }
    setLoading(false);
    setRefreshList(prev => !prev);
    handleClose();
  };

  const handleClose = () => {
    setShowPayoutDialog(false);
  };

  const suggestPaymentCategories = async (event = null) => {
    const searchPaymentCategoriesResponse = await searchPayoutCategory(
      event?.query || ''
    );
    if (searchPaymentCategoriesResponse?.data?.count)
      setPaymentCategoriesSearchResults(
        searchPaymentCategoriesResponse?.data?.result
      );
    else setPaymentCategoriesSearchResults([]);
  };
  const handlePaymentCategoriesFocus = () => {
    suggestPaymentCategories();
    if (paymentCategoriesRef.current) {
      paymentCategoriesRef.current.show();
    }
  };

  const addPaymentCategory = async name => {
    if (name) {
      const newCategory = await createPayoutCategory({ name });
      if (newCategory?.data) {
        formik.setFieldValue('payment_category', {
          payment_category_id: newCategory?.data?.payment_category_id,
          name: newCategory?.data?.name,
        });
        suggestPaymentCategories();
        toast?.current?.show({
          severity: 'success',
          summary: newCategory?.message,
          life: 2000,
        });
      }
    }
  };

  const onDisabled = (searchResult, value, key) => {
    let res = true;
    if (searchResult?.length > 0 && value && typeof value === 'string') {
      if (value.trim() != '') {
        const length = searchResult?.filter(
          item => item[key]?.toLowerCase() === value?.toLowerCase()
        ).length;
        if (length === 0) {
          res = false;
        }
      }
    }
    if (searchResult?.length === 0) {
      res = false;
    }
    if (!value) {
      res = true;
    }
    return res;
  };

  return (
    <Dialog
      header="Chargeback Payment"
      style={{ width: '50vw' }}
      visible={visible}
      onHide={handleClose}
    >
      <div className="card flex justify-content-center">
        <Toast ref={toast} />
        <BlockUI blocked={loading}>
          <div className="grid">
            <div className="flex flex-row col-12">
              <div className="col-6">
                <span className="p-float-label">
                  <Calendar
                    inputId="payment_date"
                    className="w-full"
                    onChange={e =>
                      formik?.setFieldValue(
                        'payment_date',
                        dateFormatter(e?.target?.value, 'YYYY-MM-DD')
                      )
                    }
                    dateFormat={'mm/dd/yy'}
                    value={
                      formik?.values?.payment_date
                        ? new Date(
                            dateFormatter(
                              formik?.values?.payment_date,
                              'MM/DD/YYYY'
                            )
                          )
                        : null
                    }
                    pt={{
                      input: {
                        className: 'p-inputtext p-inputtext-sm w-full',
                      },
                    }}
                  />
                  <label htmlFor="payment_date">Payment Date</label>
                </span>
              </div>
              <div className="col-6">
                <span className="p-float-label">
                  <AutoComplete
                    ref={paymentCategoriesRef}
                    id="payment_category"
                    aria-describedby="payment_category-help"
                    className="w-10"
                    suggestions={paymentCategoriesSearchResults || []}
                    field="name"
                    completeMethod={suggestPaymentCategories}
                    value={formik?.values?.payment_category}
                    onChange={e => {
                      formik.setFieldValue('payment_category', e.target.value);
                    }}
                    inputClassName="p-inputtext-sm w-12 border-right-none border-noround-right"
                    onFocus={handlePaymentCategoriesFocus}
                  />
                  <label htmlFor="categoryId">Payment Category</label>
                  <Button
                    icon="pi pi-check"
                    className="p-button-primary w-2 border-noround-left height-40"
                    disabled={onDisabled(
                      paymentCategoriesSearchResults,
                      formik?.values?.payment_category,
                      'name'
                    )}
                    tooltip={
                      onDisabled(
                        paymentCategoriesSearchResults,
                        formik?.values?.payment_category,
                        'name'
                      )
                        ? ''
                        : 'Add Category'
                    }
                    tooltipOptions={{ position: 'top' }}
                    onClick={() =>
                      addPaymentCategory(formik?.values?.payment_category)
                    }
                  />
                </span>
              </div>
            </div>
            <div className="flex flex-row col-12">
              <div className="col-6">
                <span className="p-float-label">
                  <Dropdown
                    id="payment_type"
                    className="w-12"
                    optionLabel="payment_type"
                    options={paymentTypeOptions}
                    value={formik?.values?.payment_type}
                    defaultValue={formik?.values?.payment_type}
                    onChange={e => {
                      formik.setFieldValue('payment_type', e.value);
                    }}
                    pt={{
                      input: {
                        className: 'p-inputtext p-inputtext-sm',
                      },
                    }}
                  />
                  <label htmlFor="payment_type">Payment Type</label>
                </span>
              </div>
              <div className="col-6">
                <span className="p-float-label">
                  <Dropdown
                    id="payment_status"
                    className="w-12"
                    optionLabel="status"
                    options={paymentStatusOptions}
                    value={formik?.values?.payment_status}
                    defaultValue={formik?.values?.payment_status}
                    onChange={e => {
                      formik.setFieldValue('payment_status', e.value);
                    }}
                    pt={{
                      input: {
                        className: 'p-inputtext p-inputtext-sm',
                      },
                    }}
                  />
                  <label htmlFor="payment_status">Payment Status</label>
                </span>
              </div>
            </div>
            <div className="flex flex-row col-12">
              <div className="col-6">
                <span className="p-float-label">
                  <InputNumber
                    inputId="payment_amount"
                    name="payment_amount"
                    value={formik?.values?.payment_amount}
                    onValueChange={e => {
                      formik.setFieldValue('payment_amount', e.value);
                    }}
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                    className="w-12"
                    min={0}
                  />
                  <label htmlFor="payment_amount">Payment Amount</label>
                </span>
              </div>
              <div className="col-6">
                <span className="p-float-label">
                  <InputText
                    inputId="payment_txn_id"
                    name="payment_txn_id"
                    value={formik?.values?.payment_txn_id}
                    onChange={e => {
                      formik.setFieldValue('payment_txn_id', e.target.value);
                    }}
                    className="w-12"
                  />
                  <label htmlFor="payment_txn_id">Payment Transaction</label>
                </span>
              </div>
            </div>
            <div className="col-12">
              <span className="p-float-label ml-2">
                <InputTextarea
                  id="description"
                  value={formik?.values?.description}
                  onChange={e => {
                    formik.setFieldValue('description', e.target.value);
                  }}
                  className="p-inputtext-sm w-12 "
                  rows={5}
                  cols={50}
                />
                <label htmlFor="description">Note</label>
              </span>
            </div>
            <div className="col-12 flex align-items-center ">
              <Checkbox
                id="releasePayout"
                name="releasePayout"
                type="checkbox"
                checked={releasePayoutChecked}
                onChange={e => {
                  setReleasePayoutChecked(e.target.checked);
                }}
                className="ml-2"
              />
              <label htmlFor="releasePayout" className="ml-2">
                Yes, I confirm to proceed with the payment of this Chargeback
              </label>
            </div>
          </div>
          <div className="col-12 md:col-12 flex justify-content-end">
            <div className="flex gap-2">
              <Button
                label="Cancel"
                outlined
                className="mr-2"
                size="small"
                onClick={handleClose}
              />
              <Button
                label="Save"
                size="small"
                disabled={!releasePayoutChecked}
                onClick={handleSubmit}
              />
            </div>
          </div>
        </BlockUI>
      </div>
    </Dialog>
  );
};
export default PayoutForm;
