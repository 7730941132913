import React, {
  cloneElement,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Button } from 'primereact/button';

import { PFSideBar } from '../../../prime/Prime';

const OpenFormButton = forwardRef(
  ({ children, buttonText, handleOnClick, className = '' }, ref) => {
    const [visible, setVisible] = useState(false);
    const [editableData, setEditableData] = useState(null);

    useImperativeHandle(ref, () => ({
      displayEditForm(data) {
        setVisible(true);
        setEditableData(data);
      },
    }));

    const onButtonClickHandler = e => {
      if (handleOnClick) handleOnClick(e);
      else {
        e.preventDefault();
        setVisible(true);
      }
    };

    const onHideSidebar = () => {
      setVisible(false);
      setEditableData(null);
    };

    return (
      <>
        <div className={`grid justify-content-end m-1`}>
          <Button
            type="button"
            outlined
            size="small"
            className={`${className}`}
            onClick={onButtonClickHandler}
          >
            {buttonText}
          </Button>
        </div>
        {children && (
          <PFSideBar
            onHide={onHideSidebar}
            visible={visible}
            headerText={
              editableData ? `${editableData?.name} Warehouse` : 'Add Warehouse'
            }
            pt={{
              header: {
                className: 'justify-content-between bg-primary',
              },
              icons: {
                className: 'w-12',
              },
              content: {
                className: 'surface-100',
              },
            }}
          >
            {cloneElement(children, {
              setVisible,
              editableData,
              onHideSidebar,
            })}
          </PFSideBar>
        )}
      </>
    );
  }
);
OpenFormButton.displayName = 'OpenFormButton';
export default OpenFormButton;
