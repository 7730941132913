import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { capitalize } from 'lodash';
import { Card, InputText, Button, SelectButton } from 'primereact';
import { useSelector, useDispatch } from 'react-redux';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';

import {
  routeConfigUpdateClientList,
  routeConfigUpdateComponent,
} from '../../../redux/slices/route-config.slice';
import Loader from '../../shared/Loader/Loader';
import { getClientList } from '../Bulletin/Bulletin.service';
import PageHeader from '../../shared/PageHeader/PageHeader';
import ApiService from '../../../services/api.service';
import { PF_ADMIN } from '../../../constants';
import { useConfirmDialogContext } from '../../../contexts/ConfirmDialog';

const RoConfiguration = () => {
  const { showConfirmationDialog } = useConfirmDialogContext();
  const roBreadcrumb = [
    {
      link: '/admin/configuration',
      text: 'Company Configurations',
    },
    {
      text: 'Route Configuration',
    },
  ];
  const history = useHistory();
  const { isLoading, clientList } = useSelector(state => state.routeConfig);
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState('');
  const [orderBy, setOrderBy] = useState('ASC');
  const clientId = localStorage.getItem('client_id');
  useEffect(async () => {
    const clientListResponse = await getClientList();
    dispatch(
      routeConfigUpdateClientList({
        clientList: clientListResponse.map(client => ({
          ...client,
          isEnabled: client.routing_module,
        })),
      })
    );
    dispatch(routeConfigUpdateComponent({ isLoading: false }));
  }, []);

  const handleCardToggle = client_id => {
    const newClientList = clientList.map(client => {
      if (client.client_id === client_id) {
        return {
          ...client,
          isEnabled: !client.isEnabled,
        };
      } else {
        return client;
      }
    });
    dispatch(routeConfigUpdateClientList({ clientList: newClientList }));
  };

  const toast = useRef(null);

  const accept = async () => {
    try {
      toast.current.show({
        severity: 'info',
        summary: 'Info',
        detail: 'Route configuration updating',
        life: 3000,
      });
      await ApiService.patch('/system/configure-route-permission', clientList);
      toast.current.show({
        severity: 'success',
        summary: 'Confirmed',
        detail: 'Route configuration saved successfully',
        life: 3000,
      });
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed to save route configuration',
        life: 3000,
      });
      console.error(error);
    }
  };

  const reject = () => {
    toast.current.show({
      severity: 'error',
      summary: 'Rejected',
      detail: 'Fail to save this Route Configuration',
      life: 3000,
    });
  };

  const confirm1 = () => {
    showConfirmationDialog({
      message: 'Are you sure you want to save this Route Configuration?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept,
      reject,
    });
  };

  const sortingTemplate = option => {
    return <i className={option.icon}></i>;
  };

  if (clientId != PF_ADMIN) {
    history.push(`/dashboard`);
  }

  return (
    <>
      <div className="grid pl-3 w-12">
        <div className="co-12 md:col-6">
          <PageHeader
            pageTitle="Route Configuration"
            breadCrumbArray={roBreadcrumb}
          />
        </div>
        <div className="co-12 md:col-6 flex align-items-center md:justify-content-end">
          <span className="p-input-icon-left mr-2">
            <InputText
              value={searchQuery}
              onChange={e => setSearchQuery(e.target.value)}
              placeholder="Search"
              className="w-12 pl-3"
            />
          </span>
          <span className="p-input-icon-left mr-2">
            <SelectButton
              value={orderBy}
              onChange={e => {
                setOrderBy(e?.value);
              }}
              itemTemplate={sortingTemplate}
              options={[
                { icon: 'pi pi-sort-alpha-up', value: 'ASC' },
                { icon: 'pi pi-sort-alpha-down', value: 'DESC' },
              ]}
            />
          </span>
          <Button
            onClick={confirm1}
            size="small"
            label="Save"
            severity="primary"
          />
        </div>
      </div>

      {isLoading ? (
        <Loader />
      ) : (
        <div className="flex flex-column mt-2">
          <div className="flex flex-row flex-wrap mt-2">
            {clientList
              ?.filter(clientObj => {
                if (searchQuery) {
                  return (
                    clientObj.client_name
                      ?.toLocaleUpperCase()
                      ?.includes(searchQuery?.toLocaleUpperCase()) ||
                    clientObj.client_id
                      ?.toLocaleUpperCase()
                      ?.includes(searchQuery?.toLocaleUpperCase())
                  );
                }
                return true;
              })
              .sort((first, second) => {
                if (orderBy == 'ASC') {
                  return first.client_name.localeCompare(second.client_name);
                }
                return second.client_name.localeCompare(first.client_name);
              })
              .map(client => (
                <Card
                  key={client.client_id}
                  className="m-2 flip"
                  style={{
                    // border: client.isEnabled ? '2px solid var(--primary-color)' : 'none',
                    background: `linear-gradient(to bottom, #a8c0ff, #3f2b96)`,
                    cursor: 'pointer',
                  }}
                  onClick={() => handleCardToggle(client.client_id)}
                >
                  <div
                    className="flex flex-row justify-content-between"
                    style={{ width: '200px', height: '40px' }}
                  >
                    <span style={{ color: 'white' }}>
                      {capitalize(client.client_name)}
                    </span>
                    <span className="">
                      {client.isEnabled ? (
                        <i
                          className="pi pi-check-circle"
                          style={{ color: '#fff' }}
                        ></i>
                      ) : (
                        <i
                          className="pi pi-circle"
                          style={{ color: '#fff' }}
                        ></i>
                      )}
                    </span>
                  </div>
                </Card>
              ))}
          </div>
          <Toast ref={toast} />
          <ConfirmDialog />
        </div>
      )}
    </>
  );
};

export default RoConfiguration;
