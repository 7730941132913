import React, { useState, useEffect } from 'react';
import { Card } from 'primereact/card';
import { Toast } from 'primereact/toast';

import PFDataTable from '../../../shared/PFPrime/PFDataTable';
import PFTableLoader from '../../../shared/Loader/PFTableLoader';
import PFButton from '../../../shared/PFPrime/PFButton';
import PFColorPicker from '../../../shared/PFPrime/PFColorPicker';
import PFDropdown from '../../../shared/PFPrime/PFDropdown';
import PFCheckbox from '../../../shared/PFPrime/PFCheckbox';
import PFInputText from '../../../shared/PFPrime/PFInputText';
import { formatDateTime } from '../../../../utils/Helpers';
import {
  getColorList,
  getProjectTypeColour,
  multiPartUpdateTenantData,
  updateColorType,
} from '../service/TenantConfig.service';
import { CLIENT_DETAILS, Colors } from '../../../../constants';

const LinkedColorTypes = ({ toast, colorCodeTableColorList }) => {
  const [isChecked, setIsChecked] = useState(
    CLIENT_DETAILS?.project_type_config?.technician_view_map_pins || false
  );
  const [globalSearchType, setGlobalSearchType] = useState('');
  const [colorValue, setColorValue] = useState({});
  const [editingRowId, setEditingRowId] = useState(null);
  const [colorList, setColorList] = useState([]);
  const [projectType, setProjectType] = useState([]);
  const [reloadList, setReloadList] = useState(false);
  const [filteredProjectType, setFilteredProjectType] = useState([]);

  const [projectTypePagination, setProjectTypePagination] = useState({
    first: 0,
    rows: 10,
    page: 1,
  });

  const optionsList = async () => {
    const response = await getColorList();
    setColorList(response?.data?.rows);
  };
  useEffect(() => {
    optionsList();
  }, [colorCodeTableColorList]);
  const clientId = localStorage.getItem('client_id');

  const handleCheckboxChange = async event => {
    setIsChecked(event?.target?.checked);
    const payload = {
      client_id: clientId,
      project_type_config: {
        technician_view_map_pins: !isChecked,
        Technician_view_job_cards: true,
      },
    };
    const response = await multiPartUpdateTenantData(payload, true);
    if (response) {
      toast.current?.show({
        severity: 'success',
        summary: `Color Code Enable On Technician View & Map Pins`,
      });
    } else {
      toast.current?.show({
        severity: 'error',
        summary: `An error occured while updating`,
      });
    }
  };
  const handleEditClick = rowData => {
    setEditingRowId(rowData.project_type);
  };

  const colorOptions = colorList?.map(color => ({
    label: color.color_name,
    value: color.color_code,
    colorConfigId: color.color_config_id,
    template: (
      <div className="flex items-center">
        <div
          className="flex w-1rem h-1rem mr-4"
          style={{
            backgroundColor: color.color_code,
          }}
        ></div>
        <span>{color.color_name}</span>
      </div>
    ),
  }));

  const handleSearchType = searchInputVal => {
    const searchString = searchInputVal?.trim()?.toLowerCase();

    if (searchString.length > 0) {
      const filtered = projectType?.filter(
        item =>
          item?.project_type?.toLowerCase()?.includes(searchString) ||
          item?.project_type_colors_map[0]?.color_config?.color_name
            ?.toLowerCase()
            ?.includes(searchString)
      );
      setFilteredProjectType(filtered);
    } else {
      setFilteredProjectType(projectType);
    }
  };
  useEffect(() => {
    setFilteredProjectType(projectType);
  }, [projectType]);

  const getProjectType = async () => {
    try {
      const typeRequest = await getProjectTypeColour();

      setProjectType(typeRequest);
    } catch (error) {
      console.error(error);
    } finally {
      setReloadList(false);
    }
  };
  useEffect(() => {
    getProjectType();
  }, [reloadList, colorCodeTableColorList]);

  const handleColorChange = async (
    project_type_color_mapping_id,
    project_type_id,
    color_config_id,
    value
  ) => {
    if (color_config_id) {
      setColorValue(prevValues => {
        const updatedValues = { ...prevValues, [project_type_id]: value };
        return updatedValues;
      });

      try {
        const payload = {
          project_type_color_mapping_id,
          project_type_id,
          color_config_id,
        };
        const res = await updateColorType(payload);
        const response = await getProjectTypeColour();
        if (response && res) {
          toast.current?.show({
            severity: 'success',
            summary: `Color Updated Successfully`,
          });
        } else {
          toast.current?.show({
            severity: 'error',
            summary: `An Error Occurred While Updating`,
          });
        }
      } catch (error) {
        console.error(error);
      }
      setReloadList(true);
    }
  };

  const linkedTypeTableCol = [
    {
      field: '',
      header: 'Actions',
      body: rowData => (
        <div className="flex w-full items-center">
          <PFButton
            icon="pi pi-pencil"
            outlined
            text
            className="text-white border-none px-0"
            severity="primary"
            aria-label="Edit"
            onClick={() => handleEditClick(rowData)}
          />
        </div>
      ),
      style: { maxWidth: '40px' },
    },

    {
      field: 'type',
      header: 'Type',
      body: rowData => (
        <div className="flex w-4rem">
          {rowData?.project_type ? rowData?.project_type : ''}
        </div>
      ),
      style: { minWidth: '80px' },
    },
    {
      field: 'color_code',
      header: 'Color Code',
      body: rowData => {
        const defaultColor = Colors?.PF_VIVOLET;
        const matchedColor =
          rowData?.project_type_colors_map?.[0]?.color_config?.color_code;
        const currentColor = matchedColor || defaultColor;
        return (
          <div className="flex w-12rem">
            {editingRowId === rowData.project_type ? (
              <PFDropdown
                value={colorValue[matchedColor] || ''}
                name="colorCode"
                options={colorOptions}
                optionLabel="label"
                optionValue="value"
                placeholder="Select a Color Code"
                onChange={(name, value) => {
                  const selectedOption = colorOptions.find(
                    option => option.value === value
                  );
                  handleColorChange(
                    rowData?.project_type_colors_map[0] &&
                      rowData?.project_type_colors_map[0]
                        ?.project_type_color_mapping_id,
                    rowData.project_type_id,
                    selectedOption?.colorConfigId,
                    value
                  );
                  setEditingRowId(null);
                }}
                filter={true}
                className="custom-color-dropdown"
                appendTo="self"
                showClear={true}
                itemTemplate={option => option.template}
              />
            ) : (
              <div className="flex w-4rem">
                <PFColorPicker disabled={true} value={currentColor} />
              </div>
            )}
          </div>
        );
      },
      style: { maxWidth: '80px' },
    },
    {
      field: 'color_name',
      header: 'Color Name',
      body: rowData => (
        <div className="flex w-11rem p-0">
          {rowData?.project_type_colors_map.length > 0
            ? rowData?.project_type_colors_map[0]?.color_config?.color_name
            : '-'}
        </div>
      ),
      style: { minWidth: '100px' },
    },
    {
      field: 'created_at',
      header: 'Created At',
      body: rowData =>
        rowData?.project_type_colors_map?.[0]?.created_at
          ? formatDateTime(rowData?.project_type_colors_map?.[0]?.created_at)
          : '',
      style: { minWidth: '60px', maxWidth: '100px' },
    },
    {
      field: 'created_by',
      header: 'Created By',
      body: rowData =>
        rowData?.project_type_colors_map?.[0]?.created_by_user
          ? `${rowData?.project_type_colors_map?.[0]?.created_by_user.first_name} ${rowData?.project_type_colors_map?.[0]?.created_by_user.last_name}`
          : '',
      style: { minWidth: '60px' },
    },
    {
      field: 'modified_at',
      header: 'Modified At',
      body: rowData =>
        rowData?.project_type_colors_map?.[0]?.modified_at
          ? formatDateTime(rowData?.project_type_colors_map?.[0]?.modified_at)
          : '-',
      style: { minWidth: '60px', maxWidth: '100px' },
    },
    {
      field: 'modified_by',
      header: 'Modified By',
      body: rowData =>
        rowData?.project_type_colors_map?.[0]?.modified_by_user
          ? `${rowData?.project_type_colors_map?.[0]?.modified_by_user.first_name} ${rowData?.project_type_colors_map?.[0]?.modified_by_user.last_name}`
          : '-',
      style: { minWidth: '60px' },
    },
  ];

  const renderTypeHeader = () => {
    return (
      <>
        <label htmlFor="applicableOn" className="ml-2 font-bold">
          Applicable on
        </label>
        <div className="flex items-center justify-center">
          <div className="flex justify-content-between align-items-center w-full ">
            <div className="flex items-center">
              <div className="flex items-center">
                <PFCheckbox
                  id="highlight_store_notes_1"
                  name="highlight_store_notes_1"
                  checked={true}
                />
                <label
                  htmlFor="highlight_store_notes_1"
                  className="ml-2 font-bold"
                >
                  Technician View Job Cards
                </label>
              </div>
              <div className="flex items-center ml-6">
                <PFCheckbox
                  id="highlight_store_notes_2"
                  name="highlight_store_notes_2"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
                <label
                  htmlFor="highlight_store_notes_2"
                  className="ml-2 font-bold"
                >
                  Technician View & Map Pins
                </label>
              </div>
            </div>
          </div>

          <div className="flex justify-content-end">
            <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <PFInputText
                id="search"
                value={globalSearchType}
                placeholder="Type & Color Name"
                className="p-inputtext-sm w-12"
                onChange={e => {
                  setGlobalSearchType(e?.target?.value);
                  handleSearchType(e?.target?.value);
                }}
              />
            </span>
          </div>
        </div>
      </>
    );
  };

  const handleOnPageMapping = event => {
    setProjectTypePagination({
      first: event.first,
      rows: event.rows,
      page: event.page + 1,
    });
  };

  const typeHeader = renderTypeHeader();
  const datatypeTableParams = {
    header: typeHeader,
    headerStyle: {
      display: 'flex',
      alignItems: 'center',
    },
  };
  return (
    <>
      <h2>Linked Types</h2>
      <Card>
        <div className="grid">
          <Toast ref={toast} />
          <div className="w-full mt-3">
            {reloadList ? (
              <PFTableLoader />
            ) : (
              <PFDataTable
                dataTableParams={datatypeTableParams}
                columns={linkedTypeTableCol}
                data={filteredProjectType}
                totalRecords={projectType?.length}
                paginator
                page={projectTypePagination.page}
                first={projectTypePagination.first}
                rows={projectTypePagination.rows}
                onPage={handleOnPageMapping}
                scrollable
                scrollHeight="400px"
                rowsPerPageOptions={[10, 20, 50, 100]}
              />
            )}
          </div>
        </div>
      </Card>
    </>
  );
};

export default LinkedColorTypes;
