import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: true,
  licenseData: [],
};

const userFormLicenseSlice = createSlice({
  name: 'userFormLicense',
  initialState: initialState,
  reducers: {
    setUserLicenseForm(state, { payload }) {
      state.isLoading = Object.prototype.hasOwnProperty.call(
        payload,
        'isLoading'
      )
        ? payload.isLoading
        : state.isLoading;
      state.licenseData = Object.prototype.hasOwnProperty.call(
        payload,
        'licenseData'
      )
        ? payload.licenseData
        : state.licenseData;
    },
  },
});

export const { setUserLicenseForm } = userFormLicenseSlice.actions;

export const userFormLicenseReducer = userFormLicenseSlice.reducer;
