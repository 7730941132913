import React, { useState, useRef, useMemo } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Grid, IconButton, Button, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Visibility as VisibilityIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Tag } from 'primereact/tag';

import PageHeader from '../../shared/PageHeader/PageHeader';
import GenericDataTable from '../../shared/GenericDataTable/GenericDataTable';
import GenericDialog from '../../shared/Dialog/GenericDialog';
import GenericConfirmationDialog from '../../shared/GenericConfirmationDialog/GenericConfirmationDialog';
import { Alert } from '../../shared/Alerts/Alert';
import { checkPermission } from '../../../utils/Helpers';
import permissions from '../../../config/permissions';
import { useAlerts } from '../../shared/Alerts/alertsService';
import PFAutoComplete from '../../shared/PFPrime/PFAutoComplete.jsx';
import PFInputText from '../../shared/PFPrime/PFInputText.js';
import PFButton from '../../shared/PFPrime/PFButton.js';
import PFMultiSelect from '../../shared/PFPrime/PFMultiSelect.js';
import PFDropdown from '../../shared/PFPrime/PFDropdown.js';
import { YesNoDropdown } from '../../../constants.js';
import TableColumnsLoader from '../../shared/Loader/tableColumnsLoader';

import { useStyles } from './LaborProfiles.styles';
import {
  getLaborProfilesList,
  getActiveInstallers,
  deleteLaborProfileItemById,
  cloneLaborProfile,
  getInstallers,
  getWorkroom,
  searchTechnicialList,
  cloneLaborItem,
  getHasNoLaborProfile,
} from './LaborProfiles.service';

function handleRowHover(event, row, rowIndex) {
  let control = document.getElementById(rowIndex);
  if (control) control.style.display = 'block';
}

function handleRowHoverLeave(event, row, rowIndex) {
  let control = document.getElementById(rowIndex);
  if (control?.style?.display) control.style.display = 'none';
}

const LaborProfiles = () => {
  const laborProfilesBreadcrumb = [
    {
      text: 'Admin',
    },
    {
      text: 'Labor Profiles',
    },
  ];
  const cloneLaborProfileValidationSchema = Yup.object().shape({
    installer_id: Yup.string().trim().required('Required').nullable(),
  });
  const formik = useFormik({
    initialValues: {
      installer_id: '',
    },
    validationSchema: cloneLaborProfileValidationSchema,
    enableReinitialize: true,
  });
  const [loading, setLoading] = React.useState(true);
  const [reloadList, setReloadList] = React.useState(false);
  const { alert, setAlert, clearAlert } = useAlerts();
  const [isOpen, setIsOpen] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState({
    limit: 10,
    offset: 0,
  });
  const history = useHistory();
  const classes = useStyles();
  const [laborProfilesListData, setLaborProfilesListData] = React.useState([]);
  const [activeInstallersListData, setActiveInstallersListData] =
    React.useState([]);
  const [count, setCount] = React.useState();
  const [laborProfile, setLaborProfile] = React.useState();
  const [visible, setVisible] = React.useState(false);
  const [laborRowData, setLaborRowData] = React.useState({});
  const [selectInstallerId, setSelectInstallerId] = useState(null);
  const [roleOptions, setRoleOptions] = React.useState([]);
  const [filteredRoleOptions, setFilteredRoleOptions] = React.useState([]);
  const [searchStringValue, setSearchStringValue] = React.useState({});
  const [technicianName, setTechnicianName] = React.useState();
  const [confirmDialog, setConfirmDialog] = React.useState({
    header: '',
    title: '',
    subtitle: '',
  });
  const laborRoleRef = useRef(null);
  const [workRoomOptions, setWorkRoomOptions] = React.useState([]);
  const [searchTechnicianData, setSearchTechnicianData] = React.useState([]);
  const [selectedItems, setSelectedItems] = React.useState(null);
  const [hasLabor, setHasLabor] = React.useState(YesNoDropdown[1]?.value);
  const [laborloading, setLaborloading] = React.useState(false);
  const dialogSettings = {
    title: '',
    button1Text: '',
    button2Text: '',
    showButton1: true,
    showButton2: true,
  };
  const [selectedWorkroom, setSelectedWorkroom] = React.useState([]);
  const [workroomIds, setWorkroomIds] = React.useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const toast = useRef(null);

  const hasNoLaborProfileList = () => {
    getHasNoLaborProfile(hasLabor, setLaborloading, setSearchTechnicianData);
  };

  React.useEffect(() => {
    hasNoLaborProfileList();
  }, [hasLabor]);

  const resetFilter = () => {
    clearState();
    setHasLabor(YesNoDropdown[1]?.value);
    hasNoLaborProfileList();
  };

  React.useEffect(async () => {
    setSearchTechnicianData([]);
  }, [selectedWorkroom]);

  React.useEffect(async () => {
    if (selectedWorkroom) {
      const workroomtypeIds = selectedWorkroom?.map(item => item?.type_id);
      const workroomTypeIdsString = workroomtypeIds?.join(',');
      setWorkroomIds(workroomTypeIdsString);
    }
  }, [selectedWorkroom]);

  const handleCloneClick = dataIndex => {
    setLaborRowData(dataArray[dataIndex]);
    setSelectInstallerId(dataIndex);
    setVisible(true);
    getHasNoLaborProfile(hasLabor, setLaborloading, setSearchTechnicianData);
  };

  const onSelectionChange = e => {
    setSelectedItems(e.value);
    setLaborRowData(dataArray[selectInstallerId]);
  };

  const dataTableColumns = [
    {
      name: '',
      options: {
        sort: false,
        filter: false,
        viewColumns: false,

        setCellProps: () => ({
          style: { width: '200px', minHeight: '40px' },
        }),
        ...(!loading
          ? {
              customBodyRenderLite: dataIndex => {
                return (
                  <div style={{ display: 'none' }} id={dataIndex}>
                    <div className="flex align-items-center">
                      {checkPermission(permissions?.laborProfiles?.menu) && (
                        <span>
                          <IconButton classes={{ root: classes.actionIcons }}>
                            <Link
                              to={`/laborprofiles/view/${
                                dataArray && dataArray[dataIndex]?.installer_id
                              }`}
                            >
                              <VisibilityIcon />
                            </Link>
                          </IconButton>
                        </span>
                      )}
                      {checkPermission(
                        permissions?.laborProfiles?.laborProfileEdit
                      ) && (
                        <span>
                          <IconButton classes={{ root: classes.actionIcons }}>
                            <Link
                              to={`/laborprofiles/edit/${
                                dataArray && dataArray[dataIndex]?.installer_id
                              }`}
                            >
                              <EditIcon />
                            </Link>
                          </IconButton>
                        </span>
                      )}
                      {checkPermission(
                        permissions?.laborProfiles?.laborProfileDelete
                      ) && (
                        <span>
                          <IconButton
                            classes={{ root: classes.actionIcons }}
                            onClick={() => confirmDelete(dataArray[dataIndex])}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </span>
                      )}

                      {checkPermission(
                        permissions?.laborProfiles?.laborProfileCopy
                      ) && (
                        <i
                          className="pi pi-clone ml-1"
                          onClick={() => handleCloneClick(dataIndex)}
                          role="button"
                          tabIndex={0}
                          onKeyDown={e => {
                            if (e?.key === 'Enter' || e?.key === ' ') {
                              handleCloneClick(dataIndex);
                            }
                          }}
                        ></i>
                      )}
                    </div>
                  </div>
                );
              },
            }
          : {}),
      },
    },
    {
      name: 'installer_name',
      label: 'Technician Name',
      options: {
        filter: true,
        sort: true,
        viewColumns: false,
      },
    },
    {
      name: 'default_percentage_paid',
      label: 'Default percentage paid',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { width: '20%', minHeight: '40px' },
        }),
      },
    },
    {
      name: 'cloned_from',
      label: 'Cloned From',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { width: '25%', minHeight: '40px' },
        }),
      },
    },
  ];
  React.useEffect(() => {
    getInstallers(setRoleOptions);
    getWorkroom(setWorkRoomOptions);
  }, []);

  React.useEffect(async () => {
    getLaborProfilesList(
      searchQuery,
      setLoading,
      setLaborProfilesListData,
      setCount
    );
    await getActiveInstallers(setActiveInstallersListData, setAlert);
    clearAlert();
  }, [searchQuery, reloadList]);

  React.useEffect(async () => {
    await searchTechnicialList(
      searchStringValue?.laborRole,
      workroomIds,
      technicianName,
      setSearchTechnicianData,
      hasLabor,
      setLaborloading
    );
  }, [searchStringValue, workroomIds, technicianName]);

  let dataArray = laborProfilesListData?.map(val => {
    return {
      installer_name: val.installer_name,
      default_percentage_paid: val.default_percentage_paid + '%',
      installer_id: val.installer_id,
      installer_labor_profile_id: val.installer_labor_profile_id,
      cloned_from: val?.cloned_from,
    };
  });
  const viewPermission = checkPermission(permissions?.laborProfiles?.menu);
  const dataTableOptions = {
    download: false,
    print: false,
    filter: false,
    selectableRows: 'none',
    responsive: 'standard',
    filterType: 'multiselect',
    tableBodyMinHeight: '300px',
    count: count,
    serverSide: true,
    textLabels: {
      body: {
        noMatch: !loading && !dataArray.length && 'No records found',
      },
    },
    onChangeRowsPerPage: numberOfRows => {
      setSearchQuery(prevState => ({
        ...prevState,
        limit: numberOfRows,
        offset: 0,
      }));
    },
    onChangePage: currentPage => {
      setSearchQuery(prevState => ({
        ...prevState,
        offset: currentPage * searchQuery.limit,
      }));
    },
    onColumnSortChange: (changedColumn, direction) => {
      setSearchQuery(prevState => ({
        ...prevState,
        sortKey: changedColumn,
        sortDirection: direction.toUpperCase(),
      }));
    },
    onSearchChange: searchText => {
      handleSearch(searchText);
    },
    onSearchClose: () => {},
    searchProps: {
      onKeyUp: e => {
        if (e.target.defaultValue && e.keyCode === 13) {
          setSearchQuery(prevState => ({
            ...prevState,
            searchString: e.target.defaultValue,
            offset: 0,
          }));
        }
      },
    },
    onFilterChange: () => {},
    rowsPerPage: searchQuery.limit,
    rowsPerPageOptions: [10, 20, 50, 100],
    setRowProps: (row, dataIndex, rowIndex) => {
      return {
        onMouseEnter: e => handleRowHover(e, row, rowIndex),
        onMouseLeave: e => handleRowHoverLeave(e, row, rowIndex),
        ...(viewPermission
          ? {
              onDoubleClick: () => {
                history.push(
                  `/laborprofiles/view/${dataArray && dataArray[dataIndex]?.installer_id}`
                );
              },
            }
          : {}),
      };
    },
    pagination: !loading,
  };
  let intervalVal = '';
  const handleSearch = searchInputVal => {
    const searchString = searchInputVal?.trim();

    /** Timeout will help to let multiple characters in the TextInput,* and API call would be optimized to get Values for Input of few chars at once,* rather than for every single character*/
    try {
      clearTimeout(intervalVal);
    } catch (err) {
      console.log(err);
    }
    intervalVal = setTimeout(() => {
      setSearchQuery(prevState => ({
        ...prevState,
        searchString: searchString,
      }));
    }, 500);
  };

  //confirm delete
  const confirmDelete = profileData => {
    setLaborProfile({
      installer_id: profileData.installer_id,
      installer_labor_profile_id: profileData.installer_labor_profile_id,
    });
    setConfirmDialog({
      ...confirmDialog,
      isOpen: true,
      title:
        'Are you sure to delete the Labor Profile? You will not be able to recover this again',
      header: 'Delete Labor Profile',
    });
  };

  //delete labor
  const deleteLaborProfileItem = () => {
    setReloadList(false);
    deleteLaborProfileItemById(
      laborProfile,
      setLoading,
      setReloadList,
      setAlert
    );
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
  };

  const handleClone = () => {
    setReloadList(false);
    let cloneData = {
      installerId: formik?.values?.installer_id,
      laborProfileid: laborProfile.installer_labor_profile_id,
    };
    cloneLaborProfile(cloneData, setLoading, setAlert, setReloadList);
    formik.setFieldValue('installer_id', null);
    setIsOpen(false);
  };

  // Helper to set search parameter
  const setSearchRoleString = (field, value) => {
    setSearchStringValue(prevState => ({
      ...prevState,
      [field]: value || null,
    }));
  };

  //Helper to populate options as per entered value
  const search = (event, options, label, setFilteredOptions) => {
    setTimeout(() => {
      let _filteredOptions;

      if (!event?.query?.trim().length) {
        _filteredOptions = [...options];
      } else {
        _filteredOptions = options?.filter(opt => {
          return (
            opt[label]?.toLowerCase()?.indexOf(event?.query?.toLowerCase()) !==
            -1
          );
        });
      }
      setFilteredOptions(_filteredOptions);
    }, 250);
  };
  // Helper to show options on focus
  const handleFocus = field => {
    if (field.current) {
      field.current.show();
    }
  };

  const RoleTemplated = item => {
    return <div className="flex align-items-center">{item?.role_name}</div>;
  };

  const handleLaborClone = async () => {
    setReloadList(false);
    const installerIds = selectedItems?.map(item => item?.installer_id);

    const payload = {
      selectedTechnicianIds: installerIds,
      sourceProfileId: laborRowData?.installer_id,
      overrideExisting: true,
    };
    const response = await cloneLaborItem(payload);
    if (response) {
      setOpenDialog(false);
      setVisible(false);
      setReloadList(true);
      if (response?.success) {
        toast.current.show({
          severity: 'success',
          summary: response?.message,
          detail: response?.data?.error,
          life: '2500',
        });
      }
    } else {
      toast.current.show({
        severity: 'error',
        summary: 'Something went wrong.',
        detail: 'Something went wrong.',
        life: '2500',
      });
    }
  };
  const clearState = () => {
    setLaborRowData('');
    setSearchStringValue('');
    setSelectedWorkroom([]);
    setTechnicianName('');
    setSelectedItems();
    setWorkroomIds();
  };

  const closeConfirmation = () => {
    setOpenDialog(false);
  };

  const nameTemplate = rowData => {
    return rowData?.first_name + ' ' + rowData?.last_name;
  };

  const hasLaborProfileBody = rowData => {
    return rowData?.has_labor_profile === 1 ? (
      <Tag severity="success" value="Yes" />
    ) : (
      <Tag severity="danger" value="No" />
    );
  };

  const disabledFields = (technicianNameLength, workroomIdsLength) => {
    return technicianNameLength || workroomIdsLength;
  };

  const tableLoader = useMemo(() => {
    return TableColumnsLoader(dataTableColumns, {
      rows: searchQuery?.limit,
      isValue: false,
    });
  }, [dataTableColumns]);
  const technicianTableColumns = [
    {
      field: 'isChecked',
      header: '',
      ...(!laborloading
        ? {
            selectionMode: 'multiple',
          }
        : {}),
    },
    {
      field: 'first_name',
      header: 'Technician Name',
      ...(!laborloading
        ? {
            body: nameTemplate,
          }
        : {}),
    },
    {
      field: 'has_labor_profile',
      header: 'Has Labor Profile',
      ...(!laborloading
        ? {
            body: hasLaborProfileBody,
          }
        : {}),
    },
  ];
  const technicianTableLoader = useMemo(() => {
    return TableColumnsLoader(technicianTableColumns, {
      rows: 5,
      isValue: false,
    });
  }, [technicianTableColumns]);

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        header="Confirmation"
        visible={openDialog}
        onHide={() => setOpenDialog(false)}
        draggable={false}
        className="w-11 lg:w-4"
      >
        <p>
          <i className="pi pi-exclamation-triangle text-lg mr-2 mt-1"></i>
          {hasLabor === 'No'
            ? `This would create new labor profile of the selected technician(s). Would you like to proceed ?`
            : `This would override the existing labor profile of the selected technician(s). Would you still like to proceed with this action ?`}
        </p>

        <div className="grid">
          <div className="col-12 text-right mt-3">
            <PFButton
              severity="primary"
              outlined
              label="Cancel"
              onClick={closeConfirmation}
            />
            <PFButton
              label="Confirm"
              onClick={handleLaborClone}
              className="ml-2"
            />
          </div>
        </div>
      </Dialog>
      <Grid container spacing={2} direction="column">
        {alert.exists && (
          <Grid item>
            <Alert />
          </Grid>
        )}
        <Grid
          container
          item
          direction="row"
          justifyContent="space-between"
          spacing={2}
        >
          <Grid item>
            <PageHeader
              pageTitle="Labor Profiles"
              breadCrumbArray={laborProfilesBreadcrumb}
            />
          </Grid>
          {checkPermission(permissions?.laborProfiles?.laborProfileAdd) && (
            <Grid item classes={{ root: classes.selfAlignGrid }}>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => history.push(`/laborprofiles/add`)}
                >
                  Add New
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Dialog
          header="Clone Technician"
          visible={visible}
          className="w-11 xl:w-7 height-800"
          onHide={() => {
            setVisible(false);
            clearState();
            setHasLabor(YesNoDropdown[1]?.value);
          }}
          draggable={false}
        >
          <div className="grid py-2">
            <div className="col-12 lg:col-6">
              <span className="p-float-label w-full">
                <PFDropdown
                  value={hasLabor}
                  optionLabel="value"
                  options={YesNoDropdown}
                  onChange={(name, value) => setHasLabor(value)}
                />
                <label htmlFor="hasLaborProfile">Has Labor Profile</label>
              </span>
            </div>
            <div className="col-12 lg:col-6">
              <span className="p-float-label">
                <PFAutoComplete
                  refs={laborRoleRef}
                  itemTemplate={RoleTemplated}
                  suggestions={filteredRoleOptions}
                  completeMethod={e =>
                    search(e, roleOptions, 'role_name', setFilteredRoleOptions)
                  }
                  onChange={e => {
                    if (e?.target?.value?.role_id) {
                      setSearchRoleString(
                        'laborRole',
                        e?.target?.value?.role_id,
                        e?.target?.value?.role_name
                      );
                    } else {
                      setSearchRoleString('laborRole', e?.target?.value);
                    }
                    setSelectedWorkroom([]);
                    setTechnicianName();
                    setWorkroomIds();
                  }}
                  field="role_name"
                  value={
                    roleOptions?.find(
                      opt => opt?.role_id === searchStringValue?.laborRole
                    ) ??
                    searchStringValue?.laborRole ??
                    ''
                  }
                  onFocus={() => handleFocus(laborRoleRef)}
                  id="laborRole"
                  pt={{
                    root: {
                      className: 'w-full',
                    },
                  }}
                  disabled={disabledFields(
                    technicianName?.length > 0,
                    workroomIds?.length > 0
                  )}
                />
                <label
                  htmlFor="laborRole"
                  className={
                    disabledFields(
                      technicianName?.length > 0,
                      workroomIds?.length > 0
                    )
                      ? 'text-400'
                      : ''
                  }
                >
                  Search by Role
                </label>
              </span>
            </div>

            <div className="col-12 lg:col-6">
              <div className="card flex justify-content-center">
                <span className="p-float-label w-full">
                  <PFMultiSelect
                    value={selectedWorkroom}
                    onChange={e => {
                      setSelectedWorkroom(e.value || []);
                      setSearchStringValue();
                      setTechnicianName();
                    }}
                    options={workRoomOptions}
                    optionLabel="label"
                    filter
                    maxSelectedLabels={3}
                    className="w-full"
                    pt={{
                      label: {
                        className: 'text-sm',
                      },
                      trigger: {
                        className: 'height-40',
                      },
                      labelcontainer: {
                        className: 'height-40',
                      },
                    }}
                    disabled={disabledFields(
                      searchStringValue?.laborRole,
                      technicianName?.length > 0
                    )}
                  />
                  <label
                    htmlFor="laborRole"
                    className={
                      disabledFields(
                        searchStringValue?.laborRole,
                        technicianName?.length > 0
                      )
                        ? 'text-400'
                        : ''
                    }
                  >
                    Select Workroom
                  </label>
                </span>
              </div>
            </div>
            <div className="col-12 lg:col-6">
              <span className="p-float-label w-full">
                <PFInputText
                  i="pi pi-search"
                  type="text"
                  value={technicianName}
                  onChange={e => {
                    setTechnicianName(e?.target?.value);
                    if (e?.target?.value) {
                      setSearchStringValue();
                      setSelectedWorkroom([]);
                      setWorkroomIds();
                    }
                  }}
                  disabled={disabledFields(
                    searchStringValue?.laborRole,
                    workroomIds?.length > 0
                  )}
                />
                <label
                  htmlFor="laborRole"
                  className={
                    disabledFields(
                      searchStringValue?.laborRole,
                      workroomIds?.length > 0
                    )
                      ? 'text-400'
                      : ''
                  }
                >
                  Search by Name
                </label>
              </span>
            </div>
            <div className="col-12 lg:col-12 text-right">
              <PFButton
                label="Clear Filter"
                outlined={true}
                onClick={resetFilter}
                className="mr-2"
              />
              <PFButton
                label="Clone"
                onClick={() => setOpenDialog(true)}
                disabled={!selectedItems?.length}
              />
            </div>
          </div>

          <DataTable
            value={laborloading ? technicianTableLoader : searchTechnicianData}
            {...(!laborloading
              ? {
                  paginator: true,
                  rows: 5,
                  rowsPerPageOptions: [5, 10, 25, 50],
                  selection: selectedItems,
                  onSelectionChange: e => onSelectionChange(e),
                }
              : {})}
          >
            {technicianTableColumns.map((col, index) => (
              <Column key={index} {...col}></Column>
            ))}
          </DataTable>
        </Dialog>

        <Grid item className="overflow-auto w-12">
          <GenericDataTable
            columns={dataTableColumns}
            data={loading ? tableLoader : dataArray}
            options={dataTableOptions}
          />
        </Grid>
      </Grid>
      {/* Confirmation dialog for delete */}
      <GenericConfirmationDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        onConfirmDialog={deleteLaborProfileItem}
      />
      {/* Clone Dialog */}
      <GenericDialog
        fullwidth
        isOpen={isOpen}
        handleClose={() => {
          setIsOpen(false);
          formik?.handleReset();
        }}
        handleSave={() => handleClone()}
        dialogSettings={dialogSettings}
        disabledButton2={!formik.dirty || !formik.isValid}
        disabledButton1={false}
      >
        <Grid container spacing={2} direction="column">
          <Grid item>
            <Autocomplete
              name="active_installer"
              options={activeInstallersListData}
              onBlur={formik?.handleBlur}
              openOnFocus={true}
              onChange={(event, value) => {
                if (value !== null) {
                  formik.setFieldValue('installer_id', value.installer_id);
                } else {
                  formik.setFieldValue('installer_id', null);
                }
              }}
              getOptionLabel={option =>
                option && `${option.first_name} ${option.last_name}`
              }
              renderInput={params => (
                <TextField
                  {...params}
                  label="Technician Name"
                  InputLabelProps={{ shrink: true }}
                  required
                />
              )}
            />
          </Grid>
        </Grid>
      </GenericDialog>
    </>
  );
};

export default LaborProfiles;
