import React from 'react';
import { useParams } from 'react-router';
import { Card } from 'primereact/card';
import { Skeleton } from 'primereact/skeleton';

import PFInputText from '../../../shared/PFPrime/PFInputText';
import PFDropdown from '../../../shared/PFPrime/PFDropdown';
import PFInputNumber from '../../../shared/PFPrime/PFInputNumber';
import PFFormikError from '../../../shared/PFPrime/PFFormikError';
import useIndustryType from '../../../../hooks/useIndustryType';

const CompanyInformation = ({
  handleChange,
  values,
  errors,
  setFieldValue,
  touched,
  loading,
}) => {
  let { mode } = useParams();
  mode = mode || window.location.pathname.split('/')[2];
  const { industryTypes } = useIndustryType();

  return (
    <Card className="mt-3" style={{ boxShadow: 'none' }}>
      <div htmlFor="Company Information">
        <div className="grid px-1">
          {mode === 'add' && (
            <div className="text-1xl font-bold px-2 py-3 col-12">
              <strong>Company Information</strong>
            </div>
          )}

          <div className="col-12 md:col-6 lg:col-3 ">
            <div className="w-full">
              {loading ? (
                <Skeleton className="w-full" height="40px" />
              ) : (
                <span className="p-float-label">
                  <PFInputText
                    inputId="companyName"
                    name="company_name"
                    value={values?.company_name || ''}
                    onChange={handleChange}
                    className={
                      touched?.company_name && errors?.company_name
                        ? 'error'
                        : ''
                    }
                    disabled={mode === 'view' ? true : false}
                    maxLength={50}
                  />
                  <label htmlFor="companyName" className="text-sm">
                    Company Name
                  </label>
                </span>
              )}
              <PFFormikError
                touched={touched}
                errors={errors}
                field="company_name"
              />
            </div>
          </div>
          <div className="col-12 md:col-6 lg:col-3 ">
            <div className="w-full">
              {loading ? (
                <Skeleton className="w-full" height="40px" />
              ) : (
                <span className="p-float-label">
                  <PFInputText
                    inputId="jobTitle"
                    name="job_title"
                    value={values?.job_title || ''}
                    onChange={handleChange}
                    className={
                      touched?.job_title && errors?.job_title ? 'error' : ''
                    }
                    disabled={mode === 'view' ? true : false}
                    maxLength={50}
                  />
                  <label htmlFor="jobTitle" className="text-sm">
                    Job Title
                  </label>
                </span>
              )}
              <PFFormikError
                touched={touched}
                errors={errors}
                field="job_title"
              />
            </div>
          </div>
          <div className="col-12 md:col-6 lg:col-3 ">
            <div className="w-full">
              {loading ? (
                <Skeleton className="w-full" height="40px" />
              ) : (
                <span className="p-float-label">
                  <PFDropdown
                    options={industryTypes}
                    optionLabel="label"
                    optionValue="type_id"
                    name="industry_id"
                    filter={true}
                    value={values?.industry_id || ''}
                    onChange={setFieldValue}
                    className={
                      touched?.industry_id && errors?.industry_id ? 'error' : ''
                    }
                    disabled={mode === 'view' ? true : false}
                  />
                  <label htmlFor="Industry" className="text-sm">
                    Industry
                  </label>
                </span>
              )}
              <PFFormikError
                touched={touched}
                errors={errors}
                field="industry_id"
              />
            </div>
          </div>
          <div className="col-12 md:col-6 lg:col-3 ">
            <div className="w-full">
              {loading ? (
                <Skeleton className="w-full" height="40px" />
              ) : (
                <span className="p-float-label">
                  <PFInputText
                    id="companySize"
                    name="company_size"
                    type="text"
                    value={values?.company_size || ''}
                    onChange={handleChange}
                    className={
                      touched?.company_size && errors?.company_size
                        ? 'error'
                        : ''
                    }
                    disabled={mode === 'view' ? true : false}
                    maxLength={50}
                  />
                  <label htmlFor="compnaySize" className="text-sm">
                    Company Size
                  </label>
                </span>
              )}
              <PFFormikError
                touched={touched}
                errors={errors}
                field="company_size"
              />
            </div>
          </div>
          <div className="col-12 md:col-6 lg:col-3 ">
            <div className="w-full">
              {loading ? (
                <Skeleton className="w-full" height="40px" />
              ) : (
                <span className="p-float-label">
                  <PFInputNumber
                    inputId="annualRevenue"
                    name="annual_revenue"
                    mode={'currency'}
                    currency="USD"
                    minFractionDigits={2}
                    value={
                      values?.annual_revenue || (mode === 'view' ? '0' : '')
                    }
                    onChange={setFieldValue}
                    className={`w-full ${
                      touched?.annual_revenue && errors?.annual_revenue
                        ? 'error'
                        : ''
                    }`}
                    disabled={mode === 'view' ? true : false}
                    version={2}
                  />
                  <label htmlFor="AnnualRevenue" className="text-sm">
                    Annual Revenue
                  </label>
                </span>
              )}
              <PFFormikError
                touched={touched}
                errors={errors}
                field="annual_revenue"
              />
            </div>
          </div>
          <div className="col-12 md:col-6 lg:col-9 ">
            <div className="w-full">
              {loading ? (
                <Skeleton className="w-full" height="40px" />
              ) : (
                <span className="p-float-label">
                  <PFInputText
                    id="customer_related_notes"
                    name="customer_related_notes"
                    type="text"
                    value={
                      values?.customer_related_notes ||
                      (mode === 'view' ? 'Not Available' : '')
                    }
                    onChange={handleChange}
                    className={
                      touched?.customer_related_notes &&
                      errors?.customer_related_notes
                        ? 'error'
                        : ''
                    }
                    disabled={mode === 'view' ? true : false}
                    maxLength={50}
                  />

                  <label htmlFor="customer_related_notes" className="text-sm">
                    Customer Related Notes
                  </label>
                </span>
              )}
              <PFFormikError
                touched={touched}
                errors={errors}
                field="customer_related_notes"
              />
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default CompanyInformation;
