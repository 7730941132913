import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Card } from 'primereact/card';
import { Tag } from 'primereact/tag';
import { Skeleton } from 'primereact/skeleton';

import PFInputText from '../../../shared/PFPrime/PFInputText';
import PFDropdown from '../../../shared/PFPrime/PFDropdown';
import PFFormikError from '../../../shared/PFPrime/PFFormikError';
import PFButton from '../../../shared/PFPrime/PFButton';
import PFDialog from '../../../shared/PFPrime/PFDialog';
import useStateMaster from '../../../../hooks/useStateMaster';
import { modeOfContact } from '../helpers/ClientInfo';
import PFInputSwitch from '../../../shared/PFPrime/PFInputSwitch';
import PFGoogleMap from '../../../shared/PFPrime/PFGoogleMap';

import EmailDialogBody from './EmailDialogBody';
import PhoneDialogBody from './PhoneDialogBody';

const ClientInfo = ({
  handleChange,
  values,
  errors,
  setFieldValue,
  touched,
  loading,
}) => {
  const { gMapFieldLayout, address } = PFGoogleMap();
  let { mode } = useParams();
  mode = mode || window.location.pathname.split('/')[2];
  const { states } = useStateMaster();
  const [phoneBtnLabelContact, setPhoneBtnLabelContact] = useState('');
  const [mailBtnLabelEmail, setMailBtnLabelEmail] = useState('');
  const [dialog, setDialog] = useState(false);
  const [dialogFor, setDialogFor] = useState('phone');
  useEffect(() => {
    if (values?.customer_phones.length > 0) {
      const primaryPhoneNumberObject = values?.customer_phones.find(
        item => item.is_primary
      );
      setPhoneBtnLabelContact(primaryPhoneNumberObject?.phone_number);
    } else {
      setPhoneBtnLabelContact('');
    }
    if (values?.customer_emails.length > 0) {
      const primaryEmailNumberObject = values?.customer_emails.find(
        item => item.is_primary
      );
      setMailBtnLabelEmail(primaryEmailNumberObject?.email);
    } else {
      setMailBtnLabelEmail('');
    }
  }, [values]);

  useEffect(() => {
    if (address) {
      setFieldValue('address.city', address.city);
      setFieldValue('address.state', address.stateCode);
      setFieldValue('address.zipcode', address.zipcode);
      setFieldValue(
        'address.address1',
        `${address.addressLineOne} ${address.addressLineTwo}`
      );
    }
  }, [address]);

  return (
    <Card style={{ boxShadow: 'none' }}>
      <div htmlFor="General Information">
        <div className="grid crm px-1">
          <div className="text-1xl font-bold px-2 py-3 col-12 md:col-12 lg:col-12">
            <strong>General Information</strong>
          </div>
          <div className="col-12 md:col-6 lg:col-4 xl:col-3">
            <div className="w-full">
              {loading ? (
                <Skeleton className="w-full" height="40px" />
              ) : (
                <>
                  <span className="p-float-label">
                    <PFInputText
                      id="clientFirstName"
                      name="first_name"
                      type="text"
                      value={values?.first_name || ''}
                      onChange={handleChange}
                      className={
                        touched?.first_name && errors?.first_name ? 'error' : ''
                      }
                      disabled={mode === 'view' ? true : false}
                      minLength={1}
                      maxLength={50}
                    />
                    <label htmlFor="clientFirstName">
                      First Name <span style={{ color: 'red' }}>*</span>
                    </label>
                  </span>
                  <PFFormikError
                    touched={touched}
                    errors={errors}
                    field="first_name"
                  />
                </>
              )}
            </div>
          </div>
          <div className="col-12 md:col-6 lg:col-4 xl:col-3">
            <div className="w-full">
              {loading ? (
                <Skeleton className="w-full" height="40px" />
              ) : (
                <>
                  <span className="p-float-label">
                    <PFInputText
                      id="clientLastName"
                      name="last_name"
                      type="text"
                      value={values?.last_name || ''}
                      onChange={handleChange}
                      className={
                        touched?.last_name && errors?.last_name ? 'error' : ''
                      }
                      disabled={mode === 'view' ? true : false}
                      minLength={1}
                      maxLength={50}
                    />
                    <label htmlFor="clientLastName">
                      Last Name <span style={{ color: 'red' }}>*</span>
                    </label>
                  </span>
                  <PFFormikError
                    touched={touched}
                    errors={errors}
                    field="last_name"
                  />
                </>
              )}
            </div>
          </div>
          <div className="col-12 md:col-6 lg:col-4 xl:col-6">
            <div className="w-full flex justify-content-end">
              {loading ? (
                <Skeleton className="w-4rem" height="2rem" />
              ) : (
                <PFInputSwitch
                  name="is_deleted"
                  onChange={e => setFieldValue('is_deleted', !e.value)}
                  checked={!values?.is_deleted}
                  disabled={mode === 'view' ? true : false}
                />
              )}
              {loading ? (
                <Skeleton className="w-3rem ml-3" height="2rem" />
              ) : (
                <Tag
                  severity={values?.is_deleted ? 'warning' : 'success'}
                  value={values?.is_deleted ? 'Inactive' : 'Active'}
                  className="ml-3"
                ></Tag>
              )}
            </div>
          </div>
          <div className="col-12 md:col-6 lg:col-4 xl:col-2">
            <div className="w-full">
              {loading ? (
                <Skeleton className="w-full" height="40px" />
              ) : (
                gMapFieldLayout({ disabled: mode != 'add' ? true : false })
              )}
            </div>
          </div>
          <div className="col-12 md:col-6 lg:col-4 xl:col-2">
            <div className="w-full">
              {loading ? (
                <Skeleton className="w-full" height="40px" />
              ) : (
                <>
                  <span className="p-float-label">
                    <PFInputText
                      id="street"
                      name="address.address1"
                      type="text"
                      value={values?.address?.address1 || ''}
                      onChange={handleChange}
                      className={
                        touched?.address?.address1 && errors?.address?.address1
                          ? 'error'
                          : ''
                      }
                      disabled={mode != 'add' ? true : false}
                      minLength={1}
                      maxLength={50}
                    />
                    <label htmlFor="street">
                      Street Address 1 <span style={{ color: 'red' }}>*</span>
                    </label>
                  </span>
                  <PFFormikError
                    touched={touched}
                    errors={errors}
                    field="address.address1"
                  />
                </>
              )}
            </div>
          </div>
          <div className="col-12 md:col-6 lg:col-4 xl:col-2">
            <div className="w-full">
              {loading ? (
                <Skeleton className="w-full" height="40px" />
              ) : (
                <>
                  <span className="p-float-label">
                    <PFInputText
                      id="block"
                      name="address.address2"
                      type="text"
                      value={values?.address?.address2 || ''}
                      onChange={handleChange}
                      className={
                        touched?.address?.address2 && errors?.address?.address2
                          ? 'error'
                          : ''
                      }
                      disabled={mode != 'add' ? true : false}
                      minLength={1}
                      maxLength={50}
                    />
                    <label htmlFor="block">Street Address 2</label>
                  </span>
                  <PFFormikError
                    touched={touched}
                    errors={errors}
                    field="address.address2"
                  />
                </>
              )}
            </div>
          </div>
          <div className="col-12 md:col-6 lg:col-4 xl:col-2">
            <div className="w-full">
              {loading ? (
                <Skeleton className="w-full" height="40px" />
              ) : (
                <>
                  <span className="p-float-label">
                    <PFInputText
                      id="city"
                      name="address.city"
                      type="text"
                      value={values?.address?.city || ''}
                      onChange={handleChange}
                      className={
                        touched?.address?.city && errors?.address?.city
                          ? 'error'
                          : ''
                      }
                      disabled={mode != 'add' ? true : false}
                      minLength={1}
                      maxLength={50}
                    />
                    <label htmlFor="city">
                      City <span style={{ color: 'red' }}>*</span>
                    </label>
                  </span>
                  <PFFormikError
                    touched={touched}
                    errors={errors}
                    field="address.city"
                  />
                </>
              )}
            </div>
          </div>
          <div className="col-12 md:col-6 lg:col-4 xl:col-2">
            <div className="w-full">
              {loading ? (
                <Skeleton className="w-full" height="40px" />
              ) : (
                <>
                  <span className="p-float-label">
                    <PFDropdown
                      options={states}
                      optionLabel="state_name"
                      optionValue="state_code"
                      name="address.state"
                      filter={true}
                      value={values?.address?.state || ''}
                      onChange={setFieldValue}
                      className={
                        touched?.address?.state && errors?.address?.state
                          ? 'error'
                          : ''
                      }
                      disabled={mode != 'add' ? true : false}
                    />
                    <label htmlFor="state">
                      State <span style={{ color: 'red' }}>*</span>
                    </label>
                  </span>
                  <PFFormikError
                    touched={touched}
                    errors={errors}
                    field="address.state"
                  />
                </>
              )}
            </div>
          </div>
          <div className="col-12 md:col-6 lg:col-4 xl:col-2">
            <div className="w-full">
              {loading ? (
                <Skeleton className="w-full" height="40px" />
              ) : (
                <>
                  <span className="p-float-label">
                    <PFInputText
                      inputId="zipCode"
                      name="address.zipcode"
                      value={values?.address?.zipcode || ''}
                      onChange={handleChange}
                      className={
                        touched?.address?.zipcode && errors?.address?.zipcode
                          ? 'error'
                          : ''
                      }
                      disabled={mode != 'add' ? true : false}
                      minLength={1}
                      maxLength={10}
                    />
                    <label htmlFor="Zip">
                      Zip Code <span style={{ color: 'red' }}>*</span>
                    </label>
                  </span>
                  <PFFormikError
                    touched={touched}
                    errors={errors}
                    field="address.zipcode"
                  />
                </>
              )}
            </div>
          </div>
          <div className="col-12 md:col-6 lg:col-4 xl:col-2">
            <div className="w-full p-inputgroup">
              {loading ? (
                <Skeleton className="w-full" height="40px" />
              ) : (
                <>
                  <PFInputText
                    disabled={true}
                    placeholder={'Add Phone Number'}
                    value={phoneBtnLabelContact ? phoneBtnLabelContact : ''}
                    className={
                      touched?.customer_phones && errors?.customer_phones
                        ? 'w-9 error'
                        : 'w-9'
                    }
                  />
                  <PFButton
                    label={mode === 'view' ? 'View' : 'Add'}
                    icon={mode === 'view' ? '' : 'pi pi-plus'}
                    onClick={() => {
                      setDialog(!dialog);
                      setDialogFor('phone');
                    }}
                    outlined={true}
                  />
                </>
              )}
            </div>
            <PFFormikError
              touched={touched}
              errors={errors}
              field="customer_phones"
            />
          </div>
          <div className="col-12 md:col-6 lg:col-4 xl:col-2">
            <div className="w-full p-inputgroup">
              {loading ? (
                <Skeleton className="w-full" height="40px" />
              ) : (
                <>
                  <PFInputText
                    disabled={true}
                    placeholder={'Add Email'}
                    value={mailBtnLabelEmail ? mailBtnLabelEmail : []}
                    className={
                      touched?.customer_emails && errors?.customer_emails
                        ? 'w-9 error'
                        : 'w-9 '
                    }
                  />

                  <PFButton
                    label={mode === 'view' ? 'View' : 'Add'}
                    icon={mode === 'view' ? '' : 'pi pi-plus'}
                    onClick={() => {
                      setDialog(!dialog);
                      setDialogFor('email');
                    }}
                    outlined={true}
                  />
                </>
              )}
            </div>
            <PFFormikError
              touched={touched}
              errors={errors}
              field="customer_emails"
            />
          </div>
          <div className="col-12 md:col-6 lg:col-2">
            <div className="w-full">
              {loading ? (
                <Skeleton className="w-full" height="40px" />
              ) : (
                <>
                  <span className="p-float-label">
                    <PFDropdown
                      options={modeOfContact}
                      optionLabel="name"
                      optionValue="name"
                      name="primary_mode_of_contact"
                      filter={false}
                      value={values?.primary_mode_of_contact || ''}
                      onChange={setFieldValue}
                      className={
                        touched?.primary_mode_of_contact &&
                        errors?.primary_mode_of_contact
                          ? 'error'
                          : ''
                      }
                      disabled={mode === 'view' ? true : false}
                    />
                    <label htmlFor="primary_mode_of_contact">
                      Primary Mode of Contact{' '}
                      <span style={{ color: 'red' }}>*</span>
                    </label>
                  </span>
                  <PFFormikError
                    touched={touched}
                    errors={errors}
                    field="primary_mode_of_contact"
                  />
                </>
              )}
            </div>
          </div>
          <div className="col-12 md:col-6 lg:col-4 xl:col-2">
            <div className="w-full px-1 xl:-mt-2">
              <label htmlFor="receive_promotional_emails" className="text-xs">
                <span className="text-color-secondary">
                  Receive Promotional Emails
                </span>
              </label>

              <div className="align-items-center justify-content-center ">
                <span className="p-float-label">
                  {loading ? (
                    <Skeleton className="w-4rem" height="2rem" />
                  ) : (
                    <PFInputSwitch
                      name="receive_promotional_emails"
                      onChange={e =>
                        setFieldValue('receive_promotional_emails', e.value)
                      }
                      checked={values?.receive_promotional_emails}
                      disabled={mode === 'view' ? true : false}
                    />
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="col-12 md:col-6 lg:col-4 xl:col-3">
            <div className="w-full px-1 xl:-mt-2">
              <label htmlFor="primary_mode_of_contact" className="text-xs ">
                <span className="text-color-secondary">
                  Receive Text Message
                </span>
              </label>
              <div className="align-items-center justify-content-center">
                <span className="p-float-label flex px-1 pt-1">
                  {loading ? (
                    <Skeleton className="w-4rem" height="2rem" />
                  ) : (
                    <PFInputSwitch
                      name="receive_text_message"
                      onChange={e =>
                        setFieldValue('receive_text_message', e.value)
                      }
                      checked={values?.receive_text_message}
                      disabled={mode === 'view' ? true : false}
                    />
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>

        <PFDialog
          show={dialog}
          hide={setDialog}
          header={`${dialogFor === 'phone' ? 'Contact' : 'Email'} Details`}
          contentClassName="py-0 px-2"
          headerClassName="py-3 px-3"
          BodyComponent={
            dialogFor === 'phone' ? (
              <PhoneDialogBody
                phoneCollection={setFieldValue}
                data={values}
                mode={mode}
              />
            ) : (
              <EmailDialogBody
                emailCollection={setFieldValue}
                data={values}
                mode={mode}
              />
            )
          }
          className="p-3 bg-white w-11 lg:w-8"
        />
      </div>
    </Card>
  );
};

export default ClientInfo;
