import React, { useRef } from 'react';
import { Skeleton } from 'primereact/skeleton';
import { Toast } from 'primereact/toast';
import { Card } from 'primereact/card';

import { DAY, DAYS, NOT_APPLICABLE } from '../../../constants/string.constant';
import { renderDate } from '../../../utils/dates.helper';

import { TimeManagementResponse } from './interface/timeManagement.interface';
import { fetchTimeManagementData } from './service/timeManagement.service';
import { parseFloatTurnTime } from './helper/string-format.helper';

const TimeManagementContainer = ({ project_id }: { project_id: number }) => {
  const [timeManagementData, setTimeManagementData] =
    React.useState<TimeManagementResponse | null>(null);
  const [loading, setLoading] = React.useState(false);

  const toast = useRef<Toast>(null);

  const loadInitialData = async () => {
    setLoading(true);
    const response = await fetchTimeManagementData(project_id);
    if (!response?.error) {
      setTimeManagementData(response?.data);
    } else {
      toast.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: response?.message,
        life: 3000,
      });
    }
    setLoading(false);
  };

  React.useEffect(() => {
    loadInitialData();
  }, [project_id]);

  return (
    <>
      <Toast />
      <Card className="surface-card shadow-2 p-3 border-1 border-50 border-round">
        <div className="grid grid-nogutter text-xs">
          <div className="sm:col-12 md:col-4 lg:col-4 xl:col-4 p-2">
            <span className="p-float-label">
              {loading ? (
                <Skeleton className="w-full" height="3rem" />
              ) : (
                <>
                  <span className="font-bold text-600">
                    Date Product Available:&nbsp;&nbsp;
                  </span>
                  <span>
                    {renderDate(
                      timeManagementData?.date_product_available,
                      'MM-dd-yyyy'
                    ) || NOT_APPLICABLE}
                  </span>
                </>
              )}
            </span>
          </div>
          <div className="sm:col-12 md:col-4 lg:col-4 xl:col-4 p-2">
            <span className="p-float-label">
              {loading ? (
                <Skeleton className="w-full" height="3rem" />
              ) : (
                <>
                  <span className="font-bold text-600">
                    Date Scheduled DT:&nbsp;&nbsp;
                  </span>
                  <span>
                    {renderDate(
                      timeManagementData?.date_scheduled,
                      'MM-dd-yyyy'
                    ) || NOT_APPLICABLE}
                  </span>
                </>
              )}
            </span>
          </div>
          <div className="sm:col-12 md:col-4 lg:col-4 xl:col-4 p-2">
            <span className="p-float-label">
              {loading ? (
                <Skeleton className="w-full" height="3rem" />
              ) : (
                <>
                  <span className="font-bold text-600">
                    Sold to Product Available Turn Time: &nbsp;&nbsp;
                  </span>
                  <span>
                    {timeManagementData?.sold_to_product_available_turn_time
                      ? `${parseFloatTurnTime(
                          timeManagementData.sold_to_product_available_turn_time,
                          2
                        )}${DAYS}`
                      : NOT_APPLICABLE}
                  </span>
                </>
              )}
            </span>
          </div>
          <div className="sm:col-12 md:col-4 lg:col-4 xl:col-4 p-2">
            <span className="p-float-label">
              {loading ? (
                <Skeleton className="w-full" height="3rem" />
              ) : (
                <>
                  <span className="font-bold text-600">
                    Available To Scheduled Turn Time: &nbsp;&nbsp;
                  </span>
                  <span>
                    {timeManagementData?.available_to_schedule_turn_time
                      ? `${parseFloatTurnTime(
                          timeManagementData.available_to_schedule_turn_time,
                          2
                        )} ${DAYS}`
                      : NOT_APPLICABLE}
                  </span>
                </>
              )}
            </span>
          </div>
          <div className="sm:col-12 md:col-4 lg:col-4 xl:col-4 p-2">
            <span className="p-float-label">
              {loading ? (
                <Skeleton className="w-full" height="3rem" />
              ) : (
                <>
                  <span className="font-bold text-600">
                    Available to Start Turn Time: &nbsp;&nbsp;
                  </span>
                  <span>
                    {timeManagementData?.available_to_start_turn_time
                      ? `${parseFloatTurnTime(
                          timeManagementData.available_to_start_turn_time,
                          2
                        )} ${DAYS}`
                      : NOT_APPLICABLE}
                  </span>
                </>
              )}
            </span>
          </div>
          <div className="sm:col-12 md:col-4 lg:col-4 xl:col-4 p-2">
            <span className="p-float-label">
              {loading ? (
                <Skeleton className="w-full" height="3rem" />
              ) : (
                <>
                  <span className="font-bold text-600">
                    Sold To Complete Turn Time: &nbsp;&nbsp;
                  </span>
                  <span>
                    {timeManagementData?.sold_to_complete_turn_time
                      ? `${parseFloatTurnTime(
                          timeManagementData.sold_to_complete_turn_time,
                          2
                        )} ${DAYS}`
                      : NOT_APPLICABLE}
                  </span>
                </>
              )}
            </span>
          </div>
          <div className="sm:col-12 md:col-4 lg:col-4 xl:col-4 p-2">
            <span className="p-float-label">
              {loading ? (
                <Skeleton className="w-full" height="3rem" />
              ) : (
                <>
                  <span className="font-bold text-600">
                    Date Completed DT:&nbsp;&nbsp;
                  </span>
                  <span>
                    {renderDate(
                      timeManagementData?.date_completed,
                      'MM-dd-yyyy'
                    ) || NOT_APPLICABLE}
                  </span>
                </>
              )}
            </span>
          </div>
          <div className="sm:col-12 md:col-4 lg:col-4 xl:col-4 p-2">
            <span className="p-float-label">
              {loading ? (
                <Skeleton className="w-full" height="3rem" />
              ) : (
                <>
                  <span className="font-bold text-600">
                    Product Available to Complete Turn Time:&nbsp;&nbsp;
                  </span>
                  <span>
                    {timeManagementData?.product_available_to_complete_turn_time
                      ? `${parseFloatTurnTime(
                          timeManagementData.product_available_to_complete_turn_time,
                          2
                        )} ${DAYS}`
                      : NOT_APPLICABLE}
                  </span>
                </>
              )}
            </span>
          </div>
          <div className="sm:col-12 md:col-4 lg:col-4 xl:col-4 p-2">
            <span className="p-float-label">
              {loading ? (
                <Skeleton className="w-full" height="3rem" />
              ) : (
                <>
                  <span className="font-bold text-600">Aging:&nbsp;&nbsp;</span>
                  <span>
                    {timeManagementData?.aging
                      ? `${timeManagementData.aging || 0} ${
                          timeManagementData?.aging > 1 ? DAYS : DAY
                        }`
                      : NOT_APPLICABLE}
                  </span>
                </>
              )}
            </span>
          </div>
          <div className="sm:col-12 md:col-4 lg:col-4 xl:col-4 p-2">
            <span className="p-float-label">
              {loading ? (
                <Skeleton className="w-full" height="3rem" />
              ) : (
                <>
                  <span className="font-bold text-600">
                    Date Scheduled Date to Start Turn Time:&nbsp;&nbsp;
                  </span>
                  <span>
                    {timeManagementData?.date_scheduled_date_to_start_turn_time
                      ? `${timeManagementData.date_scheduled_date_to_start_turn_time || 0} ${
                          timeManagementData?.date_scheduled_date_to_start_turn_time >
                          1
                            ? DAYS
                            : DAY
                        }`
                      : NOT_APPLICABLE}
                  </span>
                </>
              )}
            </span>
          </div>
          <div className="sm:col-12 md:col-4 lg:col-4 xl:col-4 p-2">
            <span className="p-float-label">
              {loading ? (
                <Skeleton className="w-full" height="3rem" />
              ) : (
                <>
                  <span className="font-bold text-600">
                    Date Scheduled Date to Completion Turn Time:&nbsp;&nbsp;
                  </span>
                  <span>
                    {timeManagementData?.date_scheduled_date_to_completion_turn_time
                      ? `${timeManagementData.date_scheduled_date_to_completion_turn_time || 0} ${
                          timeManagementData?.date_scheduled_date_to_completion_turn_time >
                          1
                            ? DAYS
                            : DAY
                        }`
                      : NOT_APPLICABLE}
                  </span>
                </>
              )}
            </span>
          </div>
        </div>
      </Card>
    </>
  );
};

export default TimeManagementContainer;
