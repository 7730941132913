import React, { useState, useMemo } from 'react';
import { useParams } from 'react-router';
import { Grid } from '@material-ui/core';

import { tableValueFormatter } from '../../utils/Helpers';
import PageHeader from '../shared/PageHeader/PageHeader';
import GenericDataTable from '../shared/GenericDataTable/GenericDataTable';
import { Alert } from '../shared/Alerts/Alert';
import { useAlerts } from '../shared/Alerts/alertsService';
import TableColumnsLoader from '../shared/Loader/tableColumnsLoader';

import { getBillingList } from './services/Billing.service';
import { useStyles } from './css/Billing.styles';
import { BILLING_SKELETON_COLUMN_DATA } from './constants/skeleton.constants';

const Billing = () => {
  const { payment_request_id } = useParams();

  const [loading, setLoading] = React.useState(true);
  const { alert, setAlert } = useAlerts();

  const [searchQuery, setSearchQuery] = React.useState({
    limit: 10,
    offset: 0,
    sortKey: 'created_at',
    sortDirection: 'DESC',
  });
  const classes = useStyles();
  const [billingDetails, setBillingDetails] = useState({});

  React.useEffect(() => {
    getBillingList(
      searchQuery,
      setLoading,
      setBillingDetails,
      setAlert,
      payment_request_id
    );
  }, [searchQuery]);

  let dataArray = [];
  billingDetails?.data?.rows?.forEach(val => {
    const record = {};
    billingDetails?.data?.tableConfig?.table_configuration?.forEach(headers => {
      let value = tableValueFormatter(headers, val);
      record[headers.name] = value;
    });
    dataArray.push(record);
  });
  let intervalVal = '';
  const handleSearch = searchInputVal => {
    const searchString = searchInputVal?.trim();

    /** Timeout will help to let multiple characters in the TextInput,* and API call would be optimized to get Values for Input of few chars at once,* rather than for every single character*/
    try {
      clearTimeout(intervalVal);
    } catch (err) {
      console.log(err);
    }
    intervalVal = setTimeout(() => {
      setLoading(true);
      setSearchQuery(prevState => ({
        ...prevState,
        offset: 0,
        searchString: searchString,
      }));
    }, 500);
  };

  const dataTableOptions = {
    download: false,
    print: false,
    resizableColumns: false,
    selectableRows: 'none',
    responsive: 'standard',
    filter: false,
    filterType: 'multiselect',
    tableBodyMinHeight: '300px',
    count: billingDetails.count || 0,
    serverSide: true,
    textLabels: {
      body: {
        noMatch: !loading && !dataArray.length && 'No records found',
      },
    },
    onChangeRowsPerPage: numberOfRows => {
      setLoading(true);
      setSearchQuery(prevState => ({
        ...prevState,
        limit: numberOfRows,
        offset: 0,
      }));
    },
    onChangePage: currentPage => {
      setLoading(true);
      setSearchQuery(prevState => ({
        ...prevState,
        offset: currentPage * searchQuery.limit,
      }));
    },
    onColumnSortChange: (changedColumn, direction) => {
      setLoading(true);
      setSearchQuery(prevState => ({
        ...prevState,
        sortKey: changedColumn,
        sortDirection: direction.toUpperCase(),
      }));
    },
    searchProps: {
      onKeyUp: e => {
        if (e.target.defaultValue && e.keyCode === 13) {
          handleSearch(e.target.defaultValue);
        }
      },
    },
    onSearchClose: () => {
      setLoading(true);
      setSearchQuery(prevState => ({
        ...prevState,
        searchString: '',
      }));
    },
    rowsPerPage: searchQuery.limit,
    rowsPerPageOptions: [10, 20, 50, 100],
    confirmFilters: true,
    pagination: !loading,
  };

  const tableLoader = useMemo(() => {
    return TableColumnsLoader(BILLING_SKELETON_COLUMN_DATA, {
      rows: searchQuery?.limit,
      isValue: false,
    });
  }, [BILLING_SKELETON_COLUMN_DATA]);

  return (
    <>
      <Grid container spacing={2} direction="column">
        {alert.exists && (
          <Grid item>
            {' '}
            <Alert />
          </Grid>
        )}
        <Grid
          container
          item
          direction="row"
          justifyContent="space-between"
          spacing={2}
        >
          <Grid item>
            <PageHeader pageTitle="Payment Request" />
          </Grid>
        </Grid>

        <Grid item classes={{ root: classes.dataTableWrapper }}>
          <GenericDataTable
            columns={
              loading
                ? BILLING_SKELETON_COLUMN_DATA
                : billingDetails?.data?.tableConfig?.table_configuration
            }
            data={loading ? tableLoader : dataArray}
            options={dataTableOptions}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Billing;
