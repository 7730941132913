import React, { useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useSelector, useDispatch } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { RadioButton } from 'primereact/radiobutton';
import { Toast } from 'primereact/toast';
import { Tag } from 'primereact/tag';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { assignWidgets } from '../WidgetTemplate.service';
import { setAssignWidgets } from '../../../../../redux/slices/assign-widgets.slice';
import SkeletonLoader from '../../../../shared/Loader/skeleton';

const ACTIONS = {
  SKIP: 'skip',
  OVERRIDE: 'override',
  CREATE: 'create',
};

const AssignWidgets = () => {
  const {
    isAssignWidgetDialogVisible,
    isClientsListLoading,
    selectedClients,
    validateTableData,
  } = useSelector(state => state.assignWidgets);
  const dispatch = useDispatch();
  const toast = useRef(null);

  useEffect(() => {
    formik.setValues(validateTableData);
  }, [isAssignWidgetDialogVisible]);

  const formik = useFormik({
    initialValues: [validateTableData],
    validationSchema: Yup.array().of(
      Yup.object({
        clientId: Yup.string().required('Client ID is required'),
        widgetReferenceId: Yup.string().required('Widget ID is required'),
        action: Yup.string()
          .oneOf([ACTIONS.SKIP, ACTIONS.OVERRIDE, ACTIONS.CREATE])
          .required('Action is required'),
      })
    ),
  });

  const handleAssignWidgetsSave = async () => {
    try {
      const payload = validateTableData?.map(row => {
        return {
          clientId: row.clientId,
          widgetReferenceId: row.widgetReferenceId,
          action: row.action,
        };
      });
      const saveWidgetsAssignment = await assignWidgets({ widgets: payload });
      if (saveWidgetsAssignment?.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Assigned widgets successfully',
          life: 2000,
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      handleHide();
    }
  };

  const handleHide = () => {
    formik.resetForm();
    dispatch(
      setAssignWidgets({
        isAssignWidgetDialogVisible: false,
        selectedClients: [],
        selectedWidgets: [],
        validateTableData: [],
      })
    );
  };

  const AssignWidgetsFooter = (
    <div>
      <Button
        label="Cancel"
        icon="pi pi-times"
        onClick={handleHide}
        severity="danger"
        outlined
      />
      <Button
        label="Save"
        icon="pi pi-check"
        onClick={handleAssignWidgetsSave}
        disabled={
          selectedClients?.length === 0 || !formik.dirty || !formik.isValid
        }
        outlined
      />
    </div>
  );

  const ActionTemplate = ({ rowIndex }) => {
    return (
      <div className="flex flex-wrap gap-3">
        <div className="flex align-items-center">
          <RadioButton
            inputId="skip"
            name="action"
            value="Skip"
            onChange={() => {
              let updatedTableData = structuredClone(validateTableData);
              updatedTableData[rowIndex] = {
                ...updatedTableData[rowIndex],
                action: ACTIONS.SKIP,
              };
              formik.setValues(updatedTableData);
            }}
            checked={validateTableData[rowIndex]?.action === ACTIONS.SKIP}
          />
          <label htmlFor="skip" className="ml-2">
            Skip
          </label>
        </div>
        {validateTableData[rowIndex]?.operation === 'Existing' && (
          <div className="flex align-items-center">
            <RadioButton
              inputId="override"
              name="action"
              value="Override"
              onChange={() => {
                let updatedTableData = structuredClone(validateTableData);
                updatedTableData[rowIndex] = {
                  ...updatedTableData[rowIndex],
                  action: ACTIONS.OVERRIDE,
                };
                formik.setValues(updatedTableData);
              }}
              checked={validateTableData[rowIndex]?.action === ACTIONS.OVERRIDE}
            />
            <label htmlFor="override" className="ml-2">
              Override
            </label>
          </div>
        )}
        {validateTableData[rowIndex]?.operation === 'New' && (
          <div className="flex align-items-center">
            <RadioButton
              inputId="create"
              name="action"
              value="Create"
              onChange={() => {
                let updatedTableData = structuredClone(validateTableData);
                updatedTableData[rowIndex] = {
                  ...updatedTableData[rowIndex],
                  action: ACTIONS.CREATE,
                };
                formik.setValues(updatedTableData);
              }}
              checked={validateTableData[rowIndex]?.action === ACTIONS.CREATE}
            />
            <label htmlFor="create" className="ml-2">
              Create
            </label>
          </div>
        )}
      </div>
    );
  };

  const StatusTemplate = ({ operation }) => {
    return <Tag severity="info" value={operation} className="w-8"></Tag>;
  };

  return (
    <>
      <Dialog
        header="Assign Widgets to Tenants"
        visible={isAssignWidgetDialogVisible}
        style={{ width: '80vw' }}
        onHide={handleHide}
        footer={AssignWidgetsFooter}
      >
        {isClientsListLoading ? (
          <SkeletonLoader columnCount={20} columnWidth="24%" />
        ) : (
          <div className="flex flex-column">
            <DataTable
              value={validateTableData}
              scrollable
              scrollHeight="400px"
            >
              <Column field="tenantName" header="Tenant Name"></Column>
              <Column field="widgetName" header="Widget Name"></Column>
              <Column
                field="operation"
                header="Status"
                body={StatusTemplate}
              ></Column>
              <Column
                field="action"
                header="Action"
                body={e => ActionTemplate(e)}
              />
            </DataTable>
          </div>
        )}
      </Dialog>
      <Toast ref={toast} />
    </>
  );
};

export default AssignWidgets;
