import React, { useEffect, useState, memo } from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import PFButton from '../../shared/PFPrime/PFButton';
import PFDialog from '../../shared/PFPrime/PFDialog';
import PFDropdown from '../../shared/PFPrime/PFDropdown';
import PFMultiSelect from '../../shared/PFPrime/PFMultiSelect';
import {
  getTemplateData,
  getSourceOptions,
  createEmailTempConfig,
  getTemplateConfigById,
  updateEmailTempConfig,
} from '../template.service';
import { ACTIONS } from '../../../constants/email-template.constant';
import { formAction } from '../../../constants';
const TemplateConfigurationPopup = memo(
  ({
    openTempConfigDialog,
    handleConfigClosePopup,
    tempConfigAction,
    templateConfigId,
    reloadEmailTemplateConfig,
    toast,
  }) => {
    const isViewMode = tempConfigAction === ACTIONS.VIEW;
    const { types } = useSelector(state => state.types);
    const { categories } = useSelector(state => state.categories);
    const { projectStatusType } = useSelector(state => state.projectStatusType);
    const { sourceStatusType } = useSelector(state => state.sourceStatusType);
    const { districts } = useSelector(state => state.districts);
    const { workrooms } = useSelector(state => state.workrooms);
    const { projectAllStores } = useSelector(state => state.projectAllStores);
    const [stores, setStoreOptions] = useState(projectAllStores);
    const [emailTemplates, setEmailTemplates] = useState([]);
    const [source, setSourceOptions] = useState([]);
    const [showStoreOption, setShowStoreOption] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const fetchEmailTemplates = async () => {
      try {
        const resp = await getTemplateData();
        setEmailTemplates(resp.success ? resp.emailTemplates : []);
      } catch (error) {
        console.error(error);
        setEmailTemplates([]);
      }
    };

    const fetchSourceOptions = async () => {
      try {
        const resp = await getSourceOptions();
        setSourceOptions(resp || []);
      } catch (error) {
        console.error(error);
        setSourceOptions([]);
      }
    };

    useEffect(() => {
      fetchEmailTemplates();
      fetchSourceOptions();
    }, []);

    useEffect(() => {
      if (
        (tempConfigAction === formAction.EDIT ||
          tempConfigAction === ACTIONS.VIEW) &&
        source.length > 0
      ) {
        fetchEmailTemplateConfig(templateConfigId);
      }
    }, [tempConfigAction, source]);

    const formik = useFormik({
      initialValues: {
        template: '',
        source: [],
        linkTo: 'Project',
        type: [],
        category: [],
        status: [],
        sourceStatus: [],
        coverageBy: '',
        workroom: [],
        district: [],
        store: [],
      },
      validationOnBlur: false,
      validationSchema: Yup.object({
        template: Yup.string().required('Template is required'),
        source: Yup.array().min(1, 'Source is required'),
        linkTo: Yup.string(),
        type: Yup.array().min(1, 'Type is required'),
        category: Yup.array().min(1, 'Category is required'),
        status: Yup.array().min(1, 'Status is required'),
        sourceStatus: Yup.array().min(1, 'Source status is required'),
        coverageBy: Yup.string().required('Coverage By is required'),
        workroom: Yup.mixed().test(
          'is-array-or-null',
          null,
          value => Array.isArray(value) || value === null
        ),
        district: Yup.mixed().test(
          'is-array-or-null',
          null,
          value => Array.isArray(value) || value === null
        ),
        store: Yup.array().min(1, 'Store is required'),
      }),
      onSubmit: async values => {
        try {
          setIsLoading(true);
          const processField = async (field, referenceData, idKey) => {
            if (values[field]?.length) {
              const selectedIds = await getIds(referenceData, idKey);
              if (
                JSON.stringify(values[field]) === JSON.stringify(selectedIds)
              ) {
                values[field] = null;
              }
            } else {
              values[field] = null;
            }
          };

          await Promise.all([
            processField('source', source, 'source_system_id'),
            processField('category', categories, 'project_category_id'),
            processField('type', types, 'project_type_id'),
            processField('status', projectStatusType, 'status_id'),
            processField('sourceStatus', sourceStatusType, 'status_id'),
            processField('store', projectAllStores, 'store_id'),
            processField('workroom', workrooms, 'type_id'),
            processField('district', districts, 'district'),
          ]);
          if (tempConfigAction === formAction.EDIT) {
            await updateEmailTempConfig(values, templateConfigId);
            toast.current.show({
              severity: 'success',
              detail: 'Template configuration has been updated successfully.',
            });
          } else {
            await createEmailTempConfig(values);
            toast.current.show({
              severity: 'success',
              detail: 'Template configuration has been created successfully.',
            });
          }
          reloadEmailTemplateConfig();
          handleConfigClosePopup();
        } catch (error) {
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: error?.message,
          });
          reloadEmailTemplateConfig();
          handleConfigClosePopup();
        } finally {
          setIsLoading(false);
        }
      },
    });
    const fetchEmailTemplateConfig = async templateConfigId => {
      try {
        const response = await getTemplateConfigById(templateConfigId);
        const configData = response.success
          ? response.emailTemplateConfigData
          : null;
        if (configData) {
          let {
            template_id,
            source_id,
            linkTo,
            project_type_id,
            project_category_id,
            internal_status_id,
            source_status_id,
            coverage_by,
            workroom_id,
            district_id,
            store_id,
          } = configData;

          if (coverage_by === 'Stores') {
            setShowStoreOption(true);
          }

          if (coverage_by === 'Workroom') {
            const workroomIds = workroom_id
              ? workroom_id
              : await getIds(workrooms, 'type_id');

            const storeByWorkroom = projectAllStores.filter(store =>
              workroomIds.includes(store.type_id)
            );

            setShowStoreOption(true);
            setStoreOptions(storeByWorkroom);
          }

          if (coverage_by === 'District') {
            const dist = district_id
              ? district_id
              : await getIds(districts, 'district');

            const storeByDistrict = projectAllStores.filter(store =>
              dist.includes(store.district)
            );

            setShowStoreOption(true);
            setStoreOptions(storeByDistrict);
          }

          if (!source_id) {
            source_id = await getIds(source, 'source_system_id');
          }
          if (!project_type_id) {
            project_type_id = await getIds(types, 'project_type_id');
          }
          if (!project_category_id) {
            project_category_id = await getIds(
              categories,
              'project_category_id'
            );
          }
          if (!internal_status_id) {
            internal_status_id = await getIds(projectStatusType, 'status_id');
          }
          if (!source_status_id) {
            source_status_id = await getIds(sourceStatusType, 'status_id');
          }
          if (!district_id) {
            district_id = await getIds(districts, 'district');
          }
          if (!workroom_id) {
            workroom_id = await getIds(workrooms, 'type_id');
          }
          if (!store_id) {
            store_id = await getIds(projectAllStores, 'store_id');
          }
          formik.setFieldValue('template', template_id);
          formik.setFieldValue('source', source_id);
          formik.setFieldValue('linkTo', linkTo);
          formik.setFieldValue('type', project_type_id);
          formik.setFieldValue('category', project_category_id);
          formik.setFieldValue('status', internal_status_id);
          formik.setFieldValue('sourceStatus', source_status_id);
          formik.setFieldValue('coverageBy', coverage_by);
          formik.setFieldValue('workroom', workroom_id);
          formik.setFieldValue('district', district_id);
          formik.setFieldValue('store', store_id);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const getIds = (data, key) => {
      const id = data.map(cat => cat[key]);
      return id;
    };

    const handleWorkroomChange = e => {
      formik.setFieldValue('workroom', e.value);
      formik.setFieldValue('store', []);
      const storeByWorkroom = projectAllStores.filter(store => {
        return e.value.includes(store.type_id);
      });
      const storeIds = storeByWorkroom.map(store => store.store_id);
      formik.setFieldValue('store', storeIds);
      setShowStoreOption(true);
      setStoreOptions(storeByWorkroom);
    };

    const handleDistrictChange = e => {
      formik.setFieldValue('district', e.value);
      formik.setFieldValue('store', []);
      const storeByDistrict = projectAllStores.filter(store => {
        return e.value.includes(store.district);
      });
      const storeIds = storeByDistrict.map(item => item.store_id);
      formik.setFieldValue('store', storeIds);
      setShowStoreOption(true);
      setStoreOptions(storeByDistrict);
    };
    const isFormTouched = Object.keys(formik.touched).length > 0;

    return (
      <div>
        <PFDialog
          show={openTempConfigDialog}
          hide={handleConfigClosePopup}
          header="Template Configuration"
          className="w-7 h-25rem"
          footer={
            <>
              {!isLoading && (
                <PFButton
                  onClick={() => handleConfigClosePopup()}
                  label="Cancel"
                />
              )}
              {!isViewMode ? (
                <PFButton
                  disabled={!isFormTouched || isLoading}
                  onClick={formik.handleSubmit}
                  label={isLoading ? 'Saving...' : 'Save'}
                  icon={isLoading && 'pi pi-spin pi-spinner'}
                />
              ) : (
                <></>
              )}
            </>
          }
          BodyComponent={
            <form
              onSubmit={formik.handleSubmit}
              className="p-1 flex flex-column gap-2"
            >
              <div className="grid mt-2">
                {/* Link To */}
                <div className="col-12 md:col-4">
                  <span className="p-float-label">
                    <PFDropdown
                      id="linkTo"
                      name="linkTo"
                      placeholder="Link To"
                      className="w-full h-3rem"
                      options={[{ label: 'Project' }]}
                      optionValue="label"
                      value={formik.values.linkTo}
                      disabled={true}
                    />
                    <label
                      htmlFor="template"
                      className="block mb-2 font-medium"
                    >
                      Link To
                    </label>
                  </span>
                </div>
                <div className="col-12 md:col-4">
                  <span className="p-float-label">
                    <PFDropdown
                      disabled={isViewMode}
                      id="template"
                      name="template"
                      placeholder="Select Template"
                      className="w-full h-3rem"
                      value={formik.values.template}
                      options={emailTemplates.map(temp => ({
                        label: temp.template_name,
                        value: temp.id,
                      }))}
                      onChange={(event, value) => {
                        formik.setFieldValue('template', value);
                      }}
                      optionValue="value"
                      onBlur={formik.handleBlur}
                    />
                    <label
                      htmlFor="template"
                      className="block mb-2 font-medium"
                    >
                      Template
                      <span className="text-red-500 font-bold">*</span>
                    </label>
                  </span>
                  {formik.touched.template && formik.errors.template && (
                    <small className="p-error">{formik.errors.template}</small>
                  )}
                </div>
                <div className="col-12 md:col-4">
                  <span className="p-float-label">
                    <PFMultiSelect
                      disabled={isViewMode}
                      onBlur={formik.handleBlur}
                      filter
                      id="source"
                      name="source"
                      placeholder="Select Source"
                      className="w-full"
                      value={formik.values.source}
                      optionValue="value"
                      optionLabel="label"
                      options={source?.map(item => ({
                        label: item?.source_name,
                        value: item?.source_system_id,
                      }))}
                      onChange={e => {
                        formik.setFieldValue('source', e.value);
                      }}
                    />
                    <label htmlFor="source" className="block mb-2 font-medium">
                      Source
                      <span className="text-red-500 font-bold">*</span>{' '}
                    </label>
                  </span>

                  {formik.touched.source && formik.errors.source && (
                    <small className="p-error">{formik.errors.source}</small>
                  )}
                </div>

                {/* Type */}
                <div className="col-12 md:col-4">
                  <span className="p-float-label">
                    <PFMultiSelect
                      disabled={isViewMode}
                      onBlur={formik.handleBlur}
                      filter
                      id="type"
                      name="type"
                      placeholder="Select Type"
                      className="w-full"
                      options={types.map(item => ({
                        label: item.project_type,
                        value: item?.project_type_id,
                      }))}
                      optionLabel="label"
                      value={formik.values.type}
                      onChange={e => {
                        formik.setFieldValue('type', e.value);
                      }}
                    />
                    <label htmlFor="type" className="block mb-2 font-medium">
                      Type
                      <span className="text-red-500 font-bold">*</span>
                    </label>
                  </span>
                  {formik.touched.type && formik.errors.type && (
                    <small className="p-error">{formik.errors.type}</small>
                  )}
                </div>

                {/* Category */}
                <div className="col-12 md:col-4">
                  <span className="p-float-label">
                    <PFMultiSelect
                      disabled={isViewMode}
                      onBlur={formik.handleBlur}
                      filter
                      id="category"
                      name="category"
                      placeholder="Select Category"
                      className="w-full"
                      options={categories.map(cat => ({
                        label: cat.category,
                        value: cat?.project_category_id,
                      }))}
                      optionLabel="label"
                      value={formik.values.category}
                      onChange={e => {
                        formik.setFieldValue('category', e.value);
                      }}
                    />
                    <label
                      htmlFor="category"
                      className="block mb-2 font-medium"
                    >
                      Category
                      <span className="text-red-500 font-bold">*</span>
                    </label>
                  </span>
                  {formik.touched.category && formik.errors.category && (
                    <small className="p-error">{formik.errors.category}</small>
                  )}
                </div>

                {/* Status */}
                <div className="col-12 md:col-4">
                  <span className="p-float-label">
                    <PFMultiSelect
                      disabled={isViewMode}
                      onBlur={formik.handleBlur}
                      filter
                      id="status"
                      name="status"
                      placeholder="Select Status"
                      className="w-full"
                      options={projectStatusType.map(item => ({
                        label: item.status,
                        value: item?.status_id,
                      }))}
                      optionLabel="label"
                      optionValue="value"
                      value={formik.values.status}
                      onChange={e => {
                        formik.setFieldValue('status', e.value);
                      }}
                    />
                    <label htmlFor="status" className="block mb-2 font-medium">
                      Status
                      <span className="text-red-500 font-bold">*</span>
                    </label>
                  </span>
                  {formik.touched.status && formik.errors.status && (
                    <small className="p-error">{formik.errors.status}</small>
                  )}
                </div>

                {/* Source Status */}
                <div className="col-12 md:col-4">
                  <span className="p-float-label">
                    <PFMultiSelect
                      disabled={isViewMode}
                      onBlur={formik.handleBlur}
                      filter
                      id="sourceStatus"
                      name="sourceStatus"
                      placeholder="Select Status"
                      className="w-full"
                      options={sourceStatusType.map(status => ({
                        label: status.status,
                        value: status?.status_id,
                      }))}
                      optionLabel="label"
                      value={formik.values.sourceStatus}
                      onChange={e =>
                        formik.setFieldValue('sourceStatus', e.value)
                      }
                    />
                    <label
                      htmlFor="sourceStatus"
                      className="block mb-2 font-medium"
                    >
                      Source Status
                      <span className="text-red-500 font-bold">*</span>
                    </label>
                  </span>
                  {formik.touched.sourceStatus &&
                    formik.errors.sourceStatus && (
                      <small className="p-error">
                        {formik.errors.sourceStatus}
                      </small>
                    )}
                </div>

                {/* Coverage By */}
                <div className="col-12 md:col-4">
                  <span className="p-float-label">
                    <PFDropdown
                      disabled={isViewMode}
                      onBlur={formik.handleBlur}
                      id="coverageBy"
                      name="coverageBy"
                      optionValue="label"
                      optionLabel="label"
                      placeholder="Select Coverage By"
                      className="w-full h-3rem"
                      value={formik.values.coverageBy}
                      onChange={(event, value) => {
                        setShowStoreOption(false);
                        formik.setFieldValue('coverageBy', value);
                        formik.setFieldValue('district', []);
                        formik.setFieldValue('workroom', []);
                        formik.setFieldValue('store', []);
                        if (value === 'Stores') {
                          setStoreOptions(projectAllStores);
                          setShowStoreOption(true);
                        }
                        formik.setFieldValue('coverageBy', value);
                      }}
                      options={[
                        { label: 'Stores', value: 1 },
                        { label: 'Workroom', value: 2 },
                        { label: 'District', value: 3 },
                      ]}
                    />
                    <label
                      htmlFor="coverageBy"
                      className="block mb-2 font-medium"
                    >
                      Coverage By
                      <span className="text-red-500 font-bold">*</span>
                    </label>
                  </span>
                  {formik.touched.coverageBy && formik.errors.coverageBy && (
                    <small className="p-error">
                      {formik.errors.coverageBy}
                    </small>
                  )}
                </div>

                {/* Workroom */}
                {formik.values.coverageBy === 'Workroom' && (
                  <div className="col-12 md:col-4">
                    <span className="p-float-label">
                      <PFMultiSelect
                        disabled={isViewMode}
                        onBlur={formik.handleBlur}
                        filter
                        id="workroom"
                        name="workroom"
                        placeholder="Select Workroom"
                        className="w-full h-3rem"
                        value={formik.values.workroom}
                        onChange={handleWorkroomChange}
                        options={workrooms.map(item => ({
                          label: item.label,
                          value: item?.type_id,
                        }))}
                        optionLabel="label"
                        optionValue="value"
                      />
                      <label
                        htmlFor="workroom"
                        className="block mb-2 font-medium"
                      >
                        Workroom
                        <span className="text-red-500 font-bold">*</span>
                      </label>
                    </span>
                  </div>
                )}
                {formik.values.coverageBy === 'District' && (
                  <div className="col-12 md:col-4">
                    <span className="p-float-label">
                      <PFMultiSelect
                        disabled={isViewMode}
                        onBlur={formik.handleBlur}
                        filter
                        id="district"
                        name="district"
                        placeholder="Select District"
                        className="w-full h-3rem"
                        value={formik.values.district}
                        onChange={handleDistrictChange}
                        options={districts.map(item => ({
                          label: item.district,
                        }))}
                        optionLabel="label"
                        optionValue="label"
                      />
                      <label
                        htmlFor="district"
                        className="block mb-2 font-medium"
                      >
                        District
                        <span className="text-red-500 font-bold">*</span>
                      </label>
                    </span>
                  </div>
                )}

                {/* Stores */}
                {showStoreOption && (
                  <div className="col-12 md:col-4">
                    <span className="p-float-label">
                      <PFMultiSelect
                        disabled={isViewMode}
                        onBlur={formik.handleBlur}
                        filter
                        id="stores"
                        name="stores"
                        placeholder="Select Stores"
                        className="w-full"
                        value={formik.values.store}
                        onChange={e => {
                          formik.setFieldValue('store', e.value);
                        }}
                        options={stores.map(item => ({
                          label: `${item?.store_number}-${item?.store_name}`,
                          value: item?.store_id,
                        }))}
                        optionLabel="label"
                        optionValue="value"
                      />
                      <label
                        htmlFor="stores"
                        className="block mb-2 font-medium"
                      >
                        Stores
                        <span className="text-red-500 font-bold">*</span>
                      </label>
                    </span>
                    {formik.touched.store && formik.errors.store && (
                      <small className="p-error">{formik.errors.store}</small>
                    )}
                  </div>
                )}
              </div>
            </form>
          }
        />
      </div>
    );
  }
);

TemplateConfigurationPopup.displayName = 'TemplateConfigurationPopup';
export { TemplateConfigurationPopup };
