import React, { useState, useEffect, useRef } from 'react';
import { Column, Button, MultiSelect, Dropdown, InputText } from 'primereact';
import { useSelector, useDispatch } from 'react-redux';
import { Toast } from 'primereact/toast';
import { Skeleton } from 'primereact/skeleton';
import { TreeTable } from 'primereact/treetable';

import { setUserCoverageForm } from '../../../redux/slices/user-form-coverage.slice';
import SkeletonLoader from '../../shared/Loader/skeleton';
import PFButton from '../../shared/PFPrime/PFButton';
import PFInputSwitch from '../../shared/PFPrime/PFInputSwitch';
import { useConfirmDialogContext } from '../../../contexts/ConfirmDialog';

import {
  getUserCoveragePreferences,
  deleteAllPreferences,
  searchDistrict,
} from './services/user-form-coverage.service';
import './user-form-coverage.css';
import UserCoverageFormUserList from './UserCoverageFormUserList';
import SelectedCoverageValueList from './SelectedCoverageValueList';
import { COVERAGE_TYPE, transformCoverageData, ACTION } from './Helper';
import CoverageDelete from './components/CoverageDelete';
import CoverageClone from './components/CoverageClone';

const coverageTypeList = [
  { id: 2, label: 'Store' },
  { id: 3, label: 'District' },
];

const ALL_CATEGORY_OPTION = {
  project_category_id: null,
  category: 'All',
};

const UserFormCoverage = ({ jobCoverage }) => {
  const { showConfirmationDialog } = useConfirmDialogContext();
  const {
    isLoading,
    storeList,
    typesList,
    categoryList,
    totalCount,
    isFilter,
  } = useSelector(state => state.userFormCoverage);

  const [selectedRecord, setSelectedRecord] = useState(null);
  const [refreshCoverageData, setRefreshCoverageData] = useState(false);
  const [paginator, setPaginator] = useState({ first: 0, rows: 10, page: 1 });
  const [categoryValue, setCategoryValue] = useState([]);
  const [coverageType, setCoverageType] = useState(null);
  const [category, setCategory] = useState([]);
  const [type, setType] = useState();
  const { userId } = useSelector(state => state.userForm);
  const dispatch = useDispatch();
  const toast = useRef(null);
  const [visible, setVisible] = useState(false);
  const [districtList, setDistrictList] = useState([]);
  const [copyUserCoverage, setCopyUserCoverage] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [groupDelete, setGroupDelete] = useState(0);
  const [coverageDialogVisible, setCoverageDialogVisible] = useState(false);
  const [selectedCoverageValues, setSelectedCoverageValues] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [isCloneOrEdit, setIsCloneOrEdit] = useState(0);
  const [expandedKeys, setExpandedKeys] = useState({});
  const [toggle, setToggle] = useState(false);

  const districtOption = async (event = null) => {
    const districtRequest = await searchDistrict(event?.query || '');
    setDistrictList(districtRequest);
  };

  useEffect(() => {
    districtOption();
  }, []);

  const onGroupDeleteButtonClick = rowData => {
    setGroupDelete(1);
    setItemToDelete(rowData);
  };

  const headerTemplate = rowData => {
    return (
      <>
        {rowData?.children ? (
          <span>
            <PFButton
              icon="pi pi-pencil"
              type="button"
              outlined
              className="border-none px-0 text-500"
              pt={{
                button: { className: 'text-500 vertical-align-middle' },
                root: { className: 'vertical-align-middle' },
              }}
              onClick={() => {
                setSelectedRecord(rowData);
                setIsCloneOrEdit(ACTION.GROUP_EDIT);
              }}
            />

            <PFButton
              icon="pi pi-clone"
              type="button"
              outlined
              className="border-none px-0 text-500"
              onClick={() => {
                setSelectedRecord(rowData);
                setIsCloneOrEdit(ACTION.GROUP_CLONE);
              }}
            />
            <PFButton
              icon="pi pi-trash"
              type="button"
              outlined
              className="border-none px-0 text-500"
              onClick={() => onGroupDeleteButtonClick(rowData)}
            />
          </span>
        ) : null}
      </>
    );
  };

  const onDeleteButtonClick = rowData => {
    setItemToDelete(rowData);
  };
  const CoverageTypeTemplate = rowData => {
    if (rowData?.children) {
      return <></>;
    } else {
      let coverageType = null;
      if (rowData?.data?.coverage_by === COVERAGE_TYPE.STORE) {
        coverageType = 'Store';
      } else if (rowData?.data?.coverage_by === COVERAGE_TYPE.ZIP_CODE) {
        coverageType = 'Zip Code';
      } else if (rowData?.data?.coverage_by === COVERAGE_TYPE.DISTRICT) {
        coverageType = 'District';
      }
      return <>{coverageType}</>;
    }
  };
  const toggleAll = checked => {
    setToggle(checked);
    const newExpandedKeys = {};
    if (checked) {
      tableData?.forEach(node => {
        newExpandedKeys[node.key] = true;
        if (node?.children) {
          node.children.forEach(child => {
            newExpandedKeys[child.key] = true;
          });
        }
      });
    }
    setExpandedKeys(newExpandedKeys);
  };
  const CoverageValueTemplate = rowData => {
    if (rowData?.children) {
      return <></>;
    } else {
      let parts = null;
      let counts = null;
      let coverageValue = null;
      let total = null;
      if (rowData?.data?.coverage_by === COVERAGE_TYPE.STORE) {
        const coverageValuesArray = rowData?.data?.coverage_values?.map(value =>
          Number(value)
        );
        const FilteredCoverageValue = storeList?.filter(item => {
          return coverageValuesArray?.includes(item?.store_id);
        });
        coverageValue = FilteredCoverageValue;
        parts = coverageValue.map(
          part => `${part.store_number}- ${part.store_name}`
        );
        counts = parts.length;
        total = storeList.length;
      } else if (rowData?.data?.coverage_by === COVERAGE_TYPE.ZIP_CODE) {
        coverageValue = Array.isArray(rowData?.data?.coverage_values)
          ? rowData?.data?.coverage_values?.map(items => items).join(', ')
          : rowData?.data?.coverage_values;
        parts = coverageValue.split(',').map(part => part.trim());
        counts = parts.length;
      } else if (rowData?.data?.coverage_by === COVERAGE_TYPE.DISTRICT) {
        coverageValue = Array.isArray(rowData?.data?.coverage_values)
          ? rowData?.data?.coverage_values?.map(items => items)
          : rowData?.data?.coverage_values;
        parts = coverageValue?.map(part => part.trim());
        counts = parts.length;
        total = districtList.length;
      }

      return (
        <div
          style={{
            cursor: 'pointer',
            color: 'blue',
            textDecoration: 'underline',
          }}
        >
          <Button
            label={`${counts} / ${total} Selected`}
            size="small"
            text
            onClick={() => {
              setSelectedCoverageValues(parts);
              setCoverageDialogVisible(true);
            }}
            className="mx-auto md:mb-2"
          />
        </div>
      );
    }
  };
  const handleEditRow = rowData => {
    const record = {
      ...rowData,
      children: [
        {
          data: {
            coverage_by: rowData.data.coverage_by,
            project_categoryId: rowData.data.project_categoryId,
            coverage_values: rowData.data.coverage_values,
            user_coverage_preference_id:
              rowData.data.user_coverage_preference_id,
          },
        },
      ],
    };
    record.rowEdit = true;
    record.header = 'Edit Single Coverage';
    setIsCloneOrEdit(ACTION.ROW_EDIT);
    setSelectedRecord(record);
  };

  const handleCloneRow = rowData => {
    const record = {
      ...rowData,
      data: { ...rowData.data },
      children: [
        {
          data: {
            coverage_by: rowData.data.coverage_by,
            project_categoryId: rowData.data.project_categoryId,
            coverage_values: rowData.data.coverage_values,
          },
        },
      ],
    };
    record.rowClone = true;
    record.header = 'Clone Single Coverage';
    setIsCloneOrEdit(ACTION.ROW_CLONE);
    setSelectedRecord(record);
  };

  const actionButtonTemplate = rowData => {
    if (rowData?.children) {
      return <>{rowData?.data?.project_type}</>;
    } else {
      return (
        <span>
          <PFButton
            icon="pi pi-pencil"
            type="button"
            outlined
            className="border-none p-0 text-500"
            pt={{ button: { className: 'text-500' } }}
            onClick={() => handleEditRow(rowData)}
          />
          <PFButton
            icon="pi pi-clone"
            type="button"
            outlined
            className="border-none p-0 text-500"
            onClick={() => handleCloneRow(rowData)}
          />
          <PFButton
            icon="pi pi-trash"
            type="button"
            outlined
            className="border-none p-0 text-500"
            onClick={() => onDeleteButtonClick(rowData)}
          />
        </span>
      );
    }
  };

  const typesEditor = () => {
    return (
      <Dropdown
        value={type}
        optionLabel="project_type"
        options={typesList}
        onChange={e => setType(e.value)}
        placeholder="Select a Type"
        filter={true}
        className="w-full h-40rem"
        style={{ height: '40px' }}
        pt={{
          input: {
            className: 'p-inputtext p-inputtext-sm ',
          },
        }}
      />
    );
  };

  const fetchInitiationData = async () => {
    dispatch(setUserCoverageForm({ isLoading: true }));
    try {
      const intializationData =
        !!userId &&
        (await getUserCoveragePreferences(
          userId,
          paginator,
          type,
          category,
          coverageType,
          categoryValue
        ));

      const {
        storeList,
        typesList,
        categoryList,
        userCoveragePreference,
        totalCount,
        isFilter,
        userCoveragePreferenceData,
      } = intializationData;

      if (userCoveragePreferenceData && userCoveragePreferenceData?.length) {
        const tableData = transformCoverageData(
          userCoveragePreferenceData,
          categoryList
        );
        setTableData(tableData);
      } else {
        setTableData([]);
      }

      const groupedCoverageData = userCoveragePreference?.reduce((acc, row) => {
        const coverageType = coverageTypeList?.find(
          item => item.id === row.coverage.type
        );
        let coverageValue = null;
        if (coverageType?.id === COVERAGE_TYPE.STORE) {
          const coverageValuesArray = row?.coverage?.values.map(value =>
            Number(value)
          );
          const FilteredCoverageValue = storeList?.filter(item => {
            return coverageValuesArray?.includes(item?.store_id);
          });
          coverageValue = FilteredCoverageValue;
        } else if (coverageType?.id === COVERAGE_TYPE.ZIP_CODE) {
          coverageValue = Array.isArray(row?.coverage?.values)
            ? row?.coverage?.values?.map(items => items).join(', ')
            : row?.coverage?.values;
        } else if (coverageType?.id === COVERAGE_TYPE.DISTRICT) {
          coverageValue = row.coverage.values;
        }
        const foundType = typesList?.find(
          item => item?.project_type_id == row?.type_coverage_id
        );
        const type_name = foundType?.project_type;

        if (!acc[type_name]) {
          acc[type_name] = [];
        }

        acc[type_name].push({
          type_coverage_id: foundType,
          type_name: foundType?.project_type,
          category_coverage_ids: categoryList?.filter(
            item => item.project_category_id === row?.category_coverage_ids
          ),
          coverage_type: coverageType,
          coverage_value: coverageValue,
          record_id: row?.user_coverage_preference_id,
          user_id: row?.user_id,
        });

        return acc;
      }, {});
      const mappedCoverageData = Object?.values(groupedCoverageData)?.flat();
      dispatch(
        setUserCoverageForm({
          storeList,
          typesList,
          categoryList,
          userCoveragePreference: mappedCoverageData,
          totalCount,
          isFilter,
        })
      );
    } catch (error) {
      console.error('error', error);
    } finally {
      dispatch(setUserCoverageForm({ isLoading: false }));
    }
  };

  useEffect(() => {
    fetchInitiationData();
  }, [userId, refreshCoverageData, paginator, totalCount, isFilter]);

  const handleReset = () => {
    setType(null);
    setCategory([]);
    setCoverageType(null);
    setCategoryValue([]);
    setRefreshCoverageData(false);

    setTimeout(() => {
      fetchInitiationData();
      setRefreshCoverageData(true);
    }, 0);
  };

  const deleteAllPreferenceHandler = () => {
    showConfirmationDialog({
      message: 'Are you sure you want to delete all the preferences?',
      header: 'Delete Confirmation',
      icon: 'pi pi-exclamation-triangle',
      // acceptClassName: 'p-button-danger',
      accept: async () => {
        toast?.current?.show({
          severity: 'info',
          summary: 'Deleting records',
          life: 2000,
        });
        await deleteAllPreferences(userId);
        setRefreshCoverageData(!refreshCoverageData);
        fetchInitiationData();
      },
      reject: () => {},
    });
  };

  const rowClassName = rowData => {
    return {
      'p-highlight': rowData?.children && rowData?.children?.length > 0,
    };
  };
  return (
    <>
      <Toast ref={toast} />
      {isLoading ? (
        <>
          <div className="flex justify-content-between mb-3">
            <Skeleton width="10rem" height="4rem" className="m-2"></Skeleton>
            <div className="flex  justify-content-between">
              <Skeleton width="10rem" height="3rem" className="m-2"></Skeleton>
              <Skeleton width="12rem" height="3rem" className="m-2"></Skeleton>
              <Skeleton width="10rem" height="3rem" className="m-2"></Skeleton>
            </div>
          </div>
          <div className="flex justify-content-between">
            <div className="flex  justify-content-between">
              <Skeleton width="10rem" height="4rem" className="m-2"></Skeleton>
              <Skeleton width="10rem" height="3rem" className="m-2"></Skeleton>
              <Skeleton width="12rem" height="3rem" className="m-2"></Skeleton>
              <Skeleton width="10rem" height="3rem" className="m-2"></Skeleton>
            </div>
            <div className="flex  justify-content-between">
              <Skeleton width="5rem" height="3rem" className="m-2"></Skeleton>
              <Skeleton width="5rem" height="3rem" className="m-2"></Skeleton>
            </div>
          </div>
          <Skeleton height="2rem" width="15rem" className="m-2"></Skeleton>
          <SkeletonLoader columnCount={20} columnWidth="24%" />
        </>
      ) : (
        <>
          {totalCount > 0 || isFilter ? (
            <>
              <div className="flex justify-content-between py-3">
                {!jobCoverage ? (
                  <h3 className="col-12 md:col-4 lg:col-6">Access Coverage</h3>
                ) : null}

                <div className="grid flex w-full justify-content-end py-3 mx-2">
                  <div className="flex md:text-right pb-0">
                    <Button
                      label="Delete All"
                      size="small"
                      outlined
                      onClick={() => deleteAllPreferenceHandler()}
                      className="mx-2 md:mb-2"
                      severity="primary"
                    />
                    <Button
                      label="Copy Coverage to User"
                      size="small"
                      outlined
                      onClick={() => setCopyUserCoverage(true)}
                      className="mx-2 md:mb-2"
                      severity="primary"
                    />
                    <Button
                      label="Add Coverage"
                      size="small"
                      severity="primary"
                      onClick={() => {
                        setVisible(true);
                        setIsCloneOrEdit(ACTION.CREATE);
                      }}
                      className="md:mb-2"
                    />
                  </div>
                </div>
              </div>

              <div>
                <div className="grid py-1">
                  <h4 className="col-12 md:col-8 ml-2 my-0">Filters</h4>
                </div>

                <div className="grid mt-0">
                  <div className="col-12 md:col-4 lg:col-8">
                    <div className="grid">
                      <div className="col-12 md:col-4 lg:col-3 mb-3 py-0">
                        <span className="p-float-label">
                          {typesEditor()}
                          <label htmlFor="type">Type</label>
                        </span>
                      </div>
                      <div className="col-12 md:col-4 lg:col-3  py-0">
                        <span className="p-float-label">
                          <MultiSelect
                            value={category}
                            optionLabel="category"
                            filter={true}
                            options={[ALL_CATEGORY_OPTION, ...categoryList]}
                            onChange={event => {
                              setCategory(event?.value);
                            }}
                            placeholder="Select a Category"
                            style={{ height: '40px' }}
                            className="w-full"
                            pt={{
                              input: {
                                className: 'p-inputtext p-inputtext-sm',
                              },
                            }}
                          />
                          <label htmlFor="category">Category</label>
                        </span>
                      </div>
                      <div className="col-12 md:col-4 lg:col-3 mb-3 py-0">
                        <span className="p-float-label">
                          <Dropdown
                            key="coverageTypeList"
                            className="mr-3 w-full"
                            value={coverageType}
                            options={coverageTypeList}
                            style={{ height: '40px' }}
                            onChange={event => {
                              setCoverageType(event?.value);
                              setCategoryValue([]);
                            }}
                          />
                          <label htmlFor="coverage_type">Coverage Type</label>
                        </span>
                      </div>

                      {coverageType !== null ? (
                        <div className="col-12 md:col-4 lg:col-3 mb-3 py-0">
                          <span className="p-float-label">
                            {coverageType?.id === COVERAGE_TYPE.ZIP_CODE ? (
                              <InputText
                                value={categoryValue}
                                style={{ height: '40px' }}
                                onChange={event => {
                                  setCategoryValue(event?.target?.value);
                                }}
                                disabled={!coverageType}
                              />
                            ) : (
                              <MultiSelect
                                value={categoryValue}
                                options={
                                  coverageType?.id === COVERAGE_TYPE.STORE
                                    ? storeList.map(store => ({
                                        label: `${store.store_number} - ${store.store_name}`,
                                        value: store.store_id,
                                        store_number: store.store_number,
                                      }))
                                    : coverageType?.id ===
                                        COVERAGE_TYPE.DISTRICT
                                      ? districtList?.map(
                                          dist => dist?.district
                                        )
                                      : []
                                }
                                style={{ height: '40px' }}
                                filter={true}
                                className="mr-3 w-12"
                                placeholder="Select coverage value"
                                onChange={event => {
                                  setCategoryValue(event.value);
                                }}
                                disabled={!coverageType}
                                pt={{
                                  input: {
                                    className: 'p-inputtext p-inputtext-sm',
                                  },
                                }}
                              />
                            )}

                            <label htmlFor="coverage_value">
                              Coverage value
                            </label>
                          </span>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  <div className="col-12 md:col-3 lg:col-4">
                    <div className="grid">
                      <div className="col-12 md:col-4 lg:col-3 col-offset-6 py-0 ">
                        <Button
                          label="Reset"
                          size="small"
                          outlined
                          onClick={handleReset}
                          severity="primary"
                          className="w-full"
                        />
                      </div>
                      <div className="col-12 md:col-4 lg:col-3  py-0  ">
                        <Button
                          label="Apply"
                          size="small"
                          severity="primary"
                          className="w-full"
                          onClick={fetchInitiationData}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex align-items-center mb-1">
                  <PFInputSwitch
                    className="mx-2 md:mb-2"
                    checked={toggle}
                    onChange={e => toggleAll(e.value)}
                  />
                  <label htmlFor="enableExpandedView" className="mr-2 mb-2">
                    Enable Expanded View
                  </label>
                </div>
              </div>

              <TreeTable
                value={tableData}
                columnResizeMode="expand"
                tableStyle={{ minWidth: '60rem' }}
                rowClassName={rowClassName}
                lazy
                paginator
                first={paginator.first}
                rows={paginator.rows}
                onPage={event => {
                  setPaginator(event);
                }}
                totalRecords={totalCount}
                pageLinkSize={6}
                expandedKeys={expandedKeys}
                onToggle={e => setExpandedKeys(e.value)}
                scrollable
                scrollHeight="500px"
              >
                <Column
                  body={rowData => headerTemplate(rowData)}
                  className="w-15rem"
                  expander={true}
                />
                <Column
                  field="project_type"
                  body={rowData => actionButtonTemplate(rowData)}
                  header="Type"
                />
                <Column field="category" header="Category" />
                <Column
                  field=""
                  header="Coverage Type"
                  body={CoverageTypeTemplate}
                />
                <Column
                  field=""
                  header="Coverage Value"
                  body={CoverageValueTemplate}
                />
              </TreeTable>

              <SelectedCoverageValueList
                coverageDialogVisible={coverageDialogVisible}
                setCoverageDialogVisible={setCoverageDialogVisible}
                selectedCoverageValues={selectedCoverageValues}
              />

              <UserCoverageFormUserList
                copyUserCoverage={copyUserCoverage}
                setCopyUserCoverage={setCopyUserCoverage}
                userId={userId}
                toast={toast}
              />
            </>
          ) : (
            <div className="mt-3 pt-4">
              <div className="grid py-3">
                <h3 className="col-12 md:col-8 ml-2">
                  Coverage Preferences{' '}
                  <span className="info-message">
                    <i>
                      ( Default to all stores, categories, and project types )
                    </i>
                  </span>
                </h3>
              </div>
              <div className="text-center">
                <div className="grid py-3 justify-content-center">
                  <Button
                    label="Add New Coverage"
                    size="small"
                    severity="primary"
                    onClick={() => {
                      setVisible(true);
                      setIsCloneOrEdit(ACTION.CREATE);
                    }}
                    className="md:mb-2"
                  />
                </div>
              </div>
            </div>
          )}
        </>
      )}

      {selectedRecord || visible ? (
        <CoverageClone
          selectedRecord={selectedRecord}
          categoryList={categoryList}
          typesList={typesList}
          coverageTypeList={coverageTypeList}
          storeList={storeList}
          districtList={districtList}
          setSelectedRecord={setSelectedRecord}
          isCloneOrEdit={isCloneOrEdit}
          setVisible={setVisible}
          userId={userId}
          setRefreshCoverageData={setRefreshCoverageData}
          refreshCoverageData={refreshCoverageData}
        />
      ) : null}

      {itemToDelete && (
        <CoverageDelete
          closeDeleteConfirmationPopup={() => {
            setGroupDelete(0);
            setItemToDelete(null);
          }}
          groupDelete={groupDelete}
          setRefreshCoverageData={setRefreshCoverageData}
          refreshCoverageData={refreshCoverageData}
          itemToDelete={itemToDelete}
          userId={userId}
        />
      )}
    </>
  );
};

export default UserFormCoverage;
