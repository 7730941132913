import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: true,
  isFileUploading: false,
  teamReviewTypeList: [],
  teamReviewData: [],
};

const userFormTeamReviewSlice = createSlice({
  name: 'userFormTeamReview',
  initialState: initialState,
  reducers: {
    setUserTeamReviewForm(state, { payload }) {
      state.isLoading = Object.prototype.hasOwnProperty.call(
        payload,
        'isLoading'
      )
        ? payload.isLoading
        : state.isLoading;
      state.isFileUploading = Object.prototype.hasOwnProperty.call(
        payload,
        'isFileUploading'
      )
        ? payload.isFileUploading
        : state.isFileUploading;
      state.teamReviewData = Object.prototype.hasOwnProperty.call(
        payload,
        'teamReviewData'
      )
        ? payload.teamReviewData
        : state.teamReviewData;
      state.teamReviewTypeList = Object.prototype.hasOwnProperty.call(
        payload,
        'teamReviewTypeList'
      )
        ? payload.teamReviewTypeList
        : state.teamReviewTypeList;
    },
  },
});

export const { setUserTeamReviewForm } = userFormTeamReviewSlice.actions;

export const userFormTeamReviewReducer = userFormTeamReviewSlice.reducer;
