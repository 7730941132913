import axios from 'axios';

import { formatDateDefault } from '../../utils/Helpers';
import { getQueryResult } from '../Admin/Widget/service/widget.service';
import { CONFIG } from '../../../src/constants';

//to Add Task
export const addTask = async (
  request,
  taskProjectId,
  tableName,
  setLoading
) => {
  setLoading(true);

  const copy_to_users = [];
  for (const item of request?.copyTo || []) {
    copy_to_users?.push(item?.user_id);
  }
  const convertedFollowUp =
    request?.followUp === null ? null : formatDateDefault(request?.followUp);

  const convertedDueDate =
    request?.dueDate === null ? null : formatDateDefault(request?.dueDate);
  let addTaskUrl;
  if (taskProjectId) {
    addTaskUrl = `${CONFIG.API_BASE_URL}/projects/${taskProjectId}/task`;
  } else {
    addTaskUrl = `${CONFIG.API_BASE_URL}/tasks`;
  }

  try {
    const response = await axios.post(addTaskUrl, {
      copy_to_users,
      title: request?.taskTitle,
      severity: request?.severity,
      category: request?.category,
      assigned_user_id: request?.assignedToId,
      status: request?.status,
      due_date: convertedDueDate,
      follow_up_date: convertedFollowUp,
      description: request?.description,
    });
    setLoading(false);
    if (response) {
      return response?.data;
    }
  } catch (error) {
    console.log(error);
    setLoading(false);

    return null;
  }
};

//to Add Widget Project Task
export const addProjectTask = async (
  request,
  taskProjectId,
  tableName,
  setLoading
) => {
  setLoading(true);
  const copy_to_users = [];

  for (const item of request?.copyTo || []) {
    copy_to_users?.push(item?.user_id);
  }
  const convertedFollowUp =
    request?.followUp === null ? null : formatDateDefault(request?.followUp);

  const convertedDueDate =
    request?.dueDate === null ? null : formatDateDefault(request?.dueDate);

  let addTaskUrl;

  if (taskProjectId && tableName === 'Tasks') {
    addTaskUrl = `${CONFIG.API_BASE_URL}/tasks`;
  }

  if (taskProjectId && tableName === 'Project') {
    addTaskUrl = `${CONFIG.API_BASE_URL}/projects/${taskProjectId}/task`;
  }

  try {
    const response = await axios.post(addTaskUrl, {
      copy_to_users,
      title: request?.taskTitle,
      severity: request?.severity,
      category: request?.category,
      assigned_user_id: request.assignedToId,
      status: request?.status,
      due_date: convertedDueDate,
      follow_up_date: convertedFollowUp,
      description: request?.description,
    });
    if (response) {
      setLoading(false);
      return response?.data;
    }
  } catch (error) {
    console.log(error);
    setLoading(false);
    return null;
  }
};

// Edit Task
export const callUpdateTaskApi = async (payload, taskProjectId, setLoading) => {
  setLoading(true);
  const taskId = payload.taskId;

  const copy_to_users = [];
  for (const item of payload?.copyTo || []) {
    copy_to_users?.push(item?.user_id);
  }

  const convertedFollowUp =
    payload?.followUp === null ? null : formatDateDefault(payload?.followUp);

  const convertedDueDate =
    payload?.dueDate === null ? null : formatDateDefault(payload?.dueDate);

  let editTaskUrl = `${CONFIG.API_BASE_URL}/tasks/${taskId}`;

  if (taskProjectId) {
    editTaskUrl = `${CONFIG.API_BASE_URL}/projects/${taskProjectId}/task/${taskId}`;
  }

  const request = {
    copy_to_users,
    title: payload?.taskTitle,
    severity: payload?.severity,
    category: payload?.category,
    assigned_user_id: payload.assignedToId,
    status: payload?.status,
    due_date: convertedDueDate,
    follow_up_date: convertedFollowUp,
    description: payload?.description,
  };
  try {
    const response = await axios.patch(editTaskUrl, request);
    setLoading(false);
    if (response) {
      return response;
    }
  } catch (error) {
    setLoading(false);
    return error;
  }
};

// callWidgetUpdateTaskApi;
export const updateProjectTask = async (
  payload,
  taskProjectId,
  tableName,
  setLoading
) => {
  setLoading(true);
  const taskId = payload?.taskId;

  const copy_to_users = [];

  for (const item of payload?.copyTo || []) {
    copy_to_users?.push(item?.user_id);
  }

  const convertedFollowUp =
    payload?.followUp === null ? null : formatDateDefault(payload?.followUp);

  const convertedDueDate =
    payload?.dueDate === null ? null : formatDateDefault(payload?.dueDate);

  let editTaskUrl;

  if (taskProjectId && tableName === 'Tasks') {
    editTaskUrl = `${CONFIG.API_BASE_URL}/tasks/${taskId}`;
  }

  if (taskProjectId && tableName === 'Project') {
    editTaskUrl = `${CONFIG.API_BASE_URL}/projects/${taskProjectId}/task/${taskId}`;
  }

  const request = {
    copy_to_users,
    title: payload?.taskTitle,
    severity: payload?.severity,
    category: payload?.category,
    assigned_user_id: payload?.assignedToId,
    status: payload?.status,
    due_date: convertedDueDate,
    follow_up_date: convertedFollowUp,
    description: payload?.description,
  };
  try {
    const response = await axios.patch(editTaskUrl, request);
    setLoading(false);
    if (response) {
      return response;
    }
  } catch (error) {
    setLoading(false);
    return error;
  }
};

// Task List
export const getTaskListAPI = async (
  typeOfProject,
  taskProjectId,
  setLoading,
  lazyState
) => {
  const projectId = parseInt(taskProjectId);
  const limit = lazyState?.rows;
  const offset = lazyState?.first;

  let getTaskListUrl;
  if (typeOfProject != 'projectType') {
    getTaskListUrl = `${CONFIG.API_BASE_URL}/tasks?limit=${limit}&offset=${offset}`;
  }

  if (taskProjectId) {
    getTaskListUrl = `${CONFIG.API_BASE_URL}/projects/${projectId}/task?limit=${limit}&offset=${offset}`;
  }
  try {
    setLoading(true);
    const response = await axios.get(getTaskListUrl);
    if (response) {
      return response?.data;
    }
  } catch (error) {
    console.error(error);
    return null;
  } finally {
    setLoading(false);
  }
};

// Widget Project Task List
export const getWidgetProjectTaskListAPI = async (
  taskProjectId,
  setLoading,
  lazyState,
  tableName
) => {
  const projectId = parseInt(taskProjectId);
  const limit = lazyState?.rows;
  const offset = lazyState?.first;

  let getTaskListUrl;

  if (taskProjectId && tableName === 'Tasks') {
    getTaskListUrl = `${CONFIG.API_BASE_URL}/tasks?limit=${limit}&offset=${offset}`;
  }

  if (taskProjectId && tableName === 'Project') {
    getTaskListUrl = `${CONFIG.API_BASE_URL}/projects/${projectId}/task?limit=${limit}&offset=${offset}`;
  }
  try {
    setLoading(true);
    const response = await axios.get(getTaskListUrl);
    if (response) {
      return response?.data;
    }
  } catch (error) {
    console.error(error);
    return null;
  } finally {
    setLoading(false);
  }
};

// Task List
export const getNoteList = async () => {
  try {
    const response = await axios.get(`${CONFIG.API_BASE_URL}/tasks`);
    if (response) {
      return response?.data;
    }
  } catch (error) {
    console.error(error);
    return null;
  }
};

// get Name list
export const getAssignToList = async searchString => {
  try {
    const response = await axios.get(
      `${CONFIG.API_BASE_URL}/tasks/users?searchString=${searchString}`
    );
    if (response) {
      return response?.data;
    }
  } catch (error) {
    console.error(error);
    return null;
  }
};

// Add Note
export const addNoteAPI = async (
  description,
  taskId,
  taskProjectId,
  tableName
) => {
  const projectId = parseInt(taskProjectId);
  let addNoteUrl;
  if (tableName != 'Project') {
    addNoteUrl = `${CONFIG.API_BASE_URL}/tasks/${taskId}/notes`;
  }
  if (taskProjectId && tableName != 'Tasks') {
    addNoteUrl = `${CONFIG.API_BASE_URL}/projects/${projectId}/task-notes/${taskId}`;
  }
  try {
    const response = await axios.post(addNoteUrl, {
      description,
    });
    if (response) {
      return response?.data;
    }
  } catch (error) {
    console.error(error);
    return null;
  }
};

// Note List
export const noteList = async (
  taskId,
  typeOfProject,
  taskProjectId,
  setLoading,
  tableName
) => {
  const projectId = parseInt(taskProjectId);
  let noteListUrl;

  if (tableName != 'Project') {
    noteListUrl = `${CONFIG.API_BASE_URL}/tasks/${taskId}/notes`;
  }
  if (taskProjectId && tableName != 'Tasks') {
    noteListUrl = `${CONFIG.API_BASE_URL}/projects/${projectId}/task-notes/${taskId}`;
  }

  try {
    setLoading(true);
    const response = await axios.get(noteListUrl);
    if (response) {
      return response?.data;
    }
  } catch (error) {
    console.error(error);
    return null;
  } finally {
    setLoading(false);
  }
};

export const createCategory = async categoryName => {
  try {
    const filesResponse = await axios.post(
      `${CONFIG.API_BASE_URL}/system/task-category`,
      {
        category_name: categoryName,
      }
    );
    return filesResponse;
  } catch (error) {
    console.error(error);
  }
};

export const searchCategoryName = async searchString => {
  const query = {
    limit: 10,
    offset: 0,
    dimensions: ['TaskCategory.category_name', 'TaskCategory.category_id'],
    filters: [
      {
        member: 'TaskCategory.category_name',
        values: [searchString],
        operator: 'startsWith',
      },
    ],
  };
  try {
    const searchCategoryResponse = await getQueryResult(query);
    return searchCategoryResponse?.data;
  } catch (error) {
    console.error(error);
  }
};
