import React from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Calendar } from 'primereact/calendar';
import { Toast } from 'primereact/toast';
import { InputTextarea } from 'primereact/inputtextarea';

import { MessageDetails } from '../Interface/sms.interface';
import { createScheduledMessage } from '../service/sms.service';
import { syntheticEvent } from '../../../../utils/event.helper';

interface SMSFormProps {
  project_id: number;
  toast: React.RefObject<Toast | null>;
  loadSMSData: () => Promise<void>;
  phone_number: string;
}

const SMSForm: React.FC<SMSFormProps> = ({
  project_id,
  toast,
  loadSMSData,
  phone_number,
}) => {
  const [saveLoading, setSaveLoading] = React.useState<boolean>(false);

  const SMSValidationSchema = Yup.object().shape({
    message: Yup.string().trim().required('Message is required'),
  });

  // **** Formik Form Values ****
  const smsFormik = useFormik<MessageDetails>({
    initialValues: {
      message: '',
      scheduled_date: null,
    },
    onSubmit: async values => {
      setSaveLoading(true);
      const apiValues = {
        message: values.message,
        scheduled_date: values?.scheduled_date || new Date(),
        receiver_primary_number: phone_number,
      };
      const response = await createScheduledMessage(project_id, apiValues);
      if (!response.error) {
        toast.current?.show({
          severity: 'success',
          summary: 'Success',
          detail: response?.message,
          life: 3000,
        });

        loadSMSData();
        smsFormik?.handleReset(syntheticEvent);
      } else {
        toast.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: response?.message,
          life: 3000,
        });
      }
      setSaveLoading(false);
    },
    validationSchema: SMSValidationSchema,
    enableReinitialize: true,
  });

  return (
    <form className="flex flex-row flex-wrap align-items-center w-full">
      <div className="col-3">
        <div className="w-full pb-2">
          <span className="p-float-label">
            <Calendar
              id="schedule"
              value={smsFormik.values.scheduled_date}
              onChange={e =>
                smsFormik?.setFieldValue('scheduled_date', e.value)
              }
              onBlur={smsFormik.handleBlur}
              showTime
              hourFormat="12"
              appendTo={null}
              minDate={new Date()}
            />
            <label htmlFor="schedule">Schedule</label>
          </span>
        </div>
      </div>

      <div className="col-6">
        <div className="w-full">
          <span className="p-float-label">
            <InputTextarea
              value={smsFormik.values.message}
              name="message"
              onChange={smsFormik.handleChange}
              onBlur={smsFormik.handleBlur}
              className="w-full"
              autoResize
            />
            <label htmlFor="message">
              Enter Your Message <span style={{ color: 'red' }}>*</span>
            </label>
          </span>
        </div>
      </div>
      <div className="col-2">
        <Button
          label="Send"
          onClick={() => smsFormik.handleSubmit()}
          disabled={!smsFormik?.dirty || !smsFormik?.isValid || saveLoading}
          size="small"
          className="align-items-center justify-content-center w-full"
          icon={saveLoading ? 'pi pi-spin pi-spinner' : 'pi pi-send'}
        />
      </div>
    </form>
  );
};
export default SMSForm;
