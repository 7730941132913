import React, { useCallback } from 'react';
import { DataTable } from 'primereact/datatable';
import type { DataTablePageEvent } from 'primereact/datatable';
import { Column } from 'primereact/column';
import type { ColumnBodyOptions } from 'primereact/column';
import { Skeleton } from 'primereact/skeleton';
import { InputText } from 'primereact/inputtext';
import { debounce } from 'lodash';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Badge } from 'primereact/badge';
import { Tooltip } from 'primereact/tooltip';

import { renderDate } from '../../../../utils/dates.helper';
import {
  ColumnMeta,
  ProjectNote,
  ProjectNotesResponse,
  SearchQuery,
} from '../interface/notes.interface';
import {
  NOTE_TYPE,
  NOTES_STATUS,
  VIEW_NOTE_TYPES,
} from '../constants/notes.constant';
import { checkPermission } from '../../../../utils/Helpers';
import permissions from '../../../../config/permissions';
import {
  changeNoteVisibilityStatus,
  deleteNoteById,
  fetchNotesData,
  updateNote,
} from '../service/notes.service';
import { NOT_APPLICABLE } from '../../../../constants/string.constant';
import { ACTION } from '../../../../constants/form-actions.constant';
import { useConfirmDialogContext } from '../../../../contexts/ConfirmDialog';
import {
  HD_CATEGORY,
  SOURCE_STATUS,
  SOURCE_SYSTEMS,
} from '../../../../constants';

import NotesForm from './NotesForm';

const NotesTable: React.FC<{
  project_id: number;
  toast: React.RefObject<Toast | null>;
  source_system_id: number;
  category: string;
}> = ({ project_id, toast, source_system_id, category }) => {
  const [notesData, setNotesData] = React.useState<ProjectNotesResponse | null>(
    null
  );
  const [loading, setLoading] = React.useState(true);
  const { showConfirmationDialog } = useConfirmDialogContext();

  const [searchQuery, setSearchQuery] = React.useState({
    limit: 10,
    offset: 0,
    search: '',
    first: 0,
    rows: 10,
  });
  const [dialogFormObj, setDialogFormObj] = React.useState({
    visible: false,
    formAction: null as string | null,
  });

  const updateDialogFormObj = (newState: Partial<typeof dialogFormObj>) => {
    setDialogFormObj(prev => ({ ...prev, ...newState }));
  };

  const getFilteredQuery = (
    query: SearchQuery
  ): Omit<SearchQuery, 'first' | 'rows'> => {
    const { search, limit, offset } = query;

    return search
      ? { limit: limit, offset: offset, search: search }
      : { limit: limit, offset: offset };
  };

  const loadNotesData = async (query: SearchQuery) => {
    setLoading(true);
    const data = await fetchNotesData(project_id, query);
    if (!data?.error) {
      setNotesData(data.notesData);
    } else {
      toast.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: data?.message,
        life: 3000,
      });
    }
    setLoading(false);
  };
  const debounceFetchNotes = useCallback(
    debounce(query => {
      const queryParam = getFilteredQuery(query);

      loadNotesData(queryParam);
    }, 500),
    []
  );

  React.useEffect(() => {
    debounceFetchNotes(searchQuery);
  }, [searchQuery, debounceFetchNotes]);
  const checkDeletePermission = (noteCategoryId: number) => {
    const notesPermissionArray = [];
    if (checkPermission(permissions?.viewEditProject?.deleteRetailerNotes)) {
      notesPermissionArray.push(NOTE_TYPE.RETAILER);
    }
    if (checkPermission(permissions?.viewEditProject?.deleteInstallerNotes)) {
      notesPermissionArray.push(NOTE_TYPE.INSTALLER);
    }
    if (checkPermission(permissions?.viewEditProject?.deleteInternalNotes)) {
      notesPermissionArray.push(NOTE_TYPE.INTERNAL);
    }
    if (checkPermission(permissions?.viewEditProject?.deleteScheduledNotes)) {
      notesPermissionArray.push(NOTE_TYPE.SCHEDULED);
    }
    if (checkPermission(permissions?.viewEditProject?.deleteDeliveryNotes)) {
      notesPermissionArray.push(NOTE_TYPE.DELIVERY);
    }
    if (notesPermissionArray?.includes(noteCategoryId)) {
      return true;
    }
    return false;
  };
  const viewNotes = async (action: string, rowData: ProjectNote) => {
    setLoading(true);
    const statusResponse = await changeNoteVisibilityStatus(
      action,
      project_id,
      rowData.project_note_id,
      rowData.note_category_id
    );
    if (!statusResponse?.error) {
      toast.current?.show({
        severity: 'success',
        summary: 'Success',
        detail: statusResponse.message,
        life: 3000,
      });
      const query = getFilteredQuery(searchQuery);
      loadNotesData(query);
    } else {
      toast.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: statusResponse.message,
        life: 3000,
      });
    }
    setLoading(false);
  };
  const handleAccept = async (rowData: ProjectNote) => {
    setLoading(true);
    const response = await deleteNoteById(project_id, rowData.project_note_id);
    if (!response?.error) {
      toast.current?.show({
        severity: 'success',
        summary: 'Success',
        detail: response.message,
        life: 3000,
      });
      const query = getFilteredQuery(searchQuery);
      loadNotesData(query);
    } else {
      toast.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: response?.message,
        life: 3000,
      });
    }
  };

  const deleteNote = (rowData: ProjectNote) => {
    showConfirmationDialog({
      message: 'Are you sure you want to delete note?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      defaultFocus: 'accept',
      accept: () => handleAccept(rowData),
    });
  };
  const closeNotes = async (rowData: ProjectNote) => {
    setLoading(true);

    const payload = {
      source_status: SOURCE_STATUS.CLOSED,
    };

    const response = await updateNote(
      project_id,
      rowData.project_note_id,
      payload
    );
    if (!response?.error) {
      toast.current?.show({
        severity: 'success',
        summary: 'Success',
        detail: response.message,
        life: 3000,
      });
      const query = getFilteredQuery(searchQuery);
      loadNotesData(query);
    } else {
      toast.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: response?.message,
        life: 3000,
      });
    }
  };
  const actionsTemplate = (rowData: ProjectNote) =>
    !loading ? (
      <div className="inline-flex align-items-center">
        {Object.values(NOTE_TYPE).includes(rowData?.note_category_id) &&
          !rowData?.viewed_by_user && (
            <>
              <Button
                icon="pi pi-eye"
                outlined
                className="border-none px-0 w-2rem view"
                data-pr-tooltip="Mark Viewed"
                data-pr-position="bottom"
                onClick={() => viewNotes(ACTION.VIEW, rowData)}
                type="button"
                severity="info"
                size="small"
              />
              <Tooltip target=".view" className="text-xs" />
            </>
          )}
        {Object.values(NOTE_TYPE).includes(rowData?.note_category_id) &&
          !rowData?.reviewed_by_user && (
            <>
              <Button
                icon="pi pi-thumbs-up"
                outlined
                className="border-none px-0 w-2rem review"
                data-pr-tooltip="Mark Reviewed"
                data-pr-position="bottom"
                onClick={() => viewNotes(ACTION.REVIEW, rowData)}
                type="button"
                severity="success"
                size="small"
              />
              <Tooltip target=".review" className="text-xs" />
            </>
          )}
        {checkDeletePermission(rowData?.note_category_id) && (
          <>
            <Button
              icon="pi pi-trash"
              outlined
              severity="danger"
              className="border-none px-0 w-2rem delete"
              data-pr-tooltip="Delete"
              data-pr-position="bottom"
              onClick={() => deleteNote(rowData)}
              type="button"
              size="small"
            />
            <Tooltip target=".delete" className="text-xs" />
          </>
        )}
        {rowData?.source_status === SOURCE_STATUS.OPEN &&
          source_system_id === SOURCE_SYSTEMS.HOME_DEPOT &&
          category === HD_CATEGORY.BLINDS && (
            <Button
              icon="pi pi-lock-open p-0"
              type="button"
              tooltip="Close"
              tooltipOptions={{
                position: 'top',
                mouseTrack: true,
                mouseTrackTop: 10,
              }}
              severity="info"
              outlined
              className="border-none px-0 text-500"
              onClick={() => closeNotes(rowData)}
            />
          )}
      </div>
    ) : null;

  // Dynamic body template
  const dynamicBodyTemplate = (
    rowData: ProjectNote,
    options: ColumnBodyOptions
  ): React.ReactNode => {
    if (loading) {
      return <Skeleton width="100%" height="1.5rem" />;
    }

    if (options.field === 'actions') {
      return actionsTemplate(rowData);
    }
    if (options.field === 'status') {
      return rowData?.viewed_by_user && rowData?.reviewed_by_user ? (
        <>
          <Badge
            value={NOTES_STATUS.VIEW}
            size="normal"
            className="mb-2"
          ></Badge>
          <Badge value="Reviewed" size="normal" severity="success"></Badge>
        </>
      ) : rowData?.reviewed_by_user ? (
        <Badge
          value={NOTES_STATUS.REVIEW}
          severity="success"
          size="normal"
        ></Badge>
      ) : rowData?.viewed_by_user ? (
        <Badge value="Viewed" size="normal"></Badge>
      ) : (
        NOT_APPLICABLE
      );
    }
    if (options.field === 'type') {
      return rowData?.category?.category_name || '';
    }

    if (options.field === 'created_at') {
      return renderDate(rowData[options.field], 'MM-dd-yyyy hh:mm a');
    }
    if (options.field === 'created_by') {
      return (
        <span className="capitalize">
          {rowData.external_note_created_by &&
          rowData.external_note_created_by !== null
            ? rowData.external_note_created_by
            : rowData.created_by && rowData.created_by !== null
              ? `${rowData?.created_by?.first_name} ${rowData?.created_by?.last_name}`
              : ''}
        </span>
      );
    }

    if (options.field === 'viewed_at') {
      return (
        (rowData['viewed_by_user'] !== null &&
          renderDate(rowData[options.field], 'MM-dd-yyyy hh:mm a')) ||
        NOT_APPLICABLE
      );
    }
    if (options.field === 'reviewed_at') {
      return (
        (rowData['reviewed_by_user'] !== null &&
          renderDate(rowData[options.field], 'MM-dd-yyyy hh:mm a')) ||
        NOT_APPLICABLE
      );
    }
    if (options.field === 'viewed_by') {
      return (
        <span className="capitalize">
          {rowData['viewed_by_user'] && rowData['viewed_by_user'] !== null
            ? `${rowData?.viewed_by_user?.first_name} ${rowData?.viewed_by_user?.last_name}`
            : '-'}
        </span>
      );
    }
    if (options.field === 'reviewed_by') {
      return (
        <span className="capitalize">
          {rowData['reviewed_by_user'] && rowData['reviewed_by_user'] !== null
            ? `${rowData?.reviewed_by_user?.first_name} ${rowData?.reviewed_by_user?.last_name}`
            : '-'}
        </span>
      );
    }
    if (options.field === 'follow_up_date') {
      return renderDate(rowData[options.field], 'MM-dd-yyyy');
    }

    // Default rendering for other fields
    const value = rowData[options.field as keyof ProjectNote];
    return typeof value === 'string' || typeof value === 'number'
      ? value
      : null;
  };
  const onPageChange = (event: DataTablePageEvent) => {
    setSearchQuery(prev => ({
      ...prev,
      offset: event?.page ? event?.page * event?.rows : 0,
      limit: event.rows,
      first: event.first,
      rows: event.rows,
    }));
  };

  const columns: ColumnMeta[] = [
    {
      field: 'actions',
      header: '',
      headerStyle: { minWidth: '10rem' },
    },

    {
      field: 'type',
      header: 'Type',
    },
    {
      field: 'note_text',
      header: 'Note',
      headerStyle: { minWidth: '12rem' },
    },
    {
      field: 'follow_up_date',
      header: 'Follow Up Date',
      headerStyle: { minWidth: '7rem' },
    },
    {
      field: 'created_by',
      header: 'Created By',
      headerStyle: { minWidth: '9rem' },
    },
    {
      field: 'created_at',
      header: 'Created At',
      headerStyle: { minWidth: '10rem' },
    },
    {
      field: 'status',
      header: 'Status',
      headerStyle: { minWidth: '7rem' },
    },
    {
      field: 'viewed_by',
      header: 'Viewed By',
      headerStyle: { minWidth: '9rem' },
    },

    {
      field: 'viewed_at',
      header: 'Viewed At',
      headerStyle: { minWidth: '10rem' },
    },
    {
      field: 'reviewed_by',
      header: 'Reviewed By',
      headerStyle: { minWidth: '9rem' },
    },

    {
      field: 'reviewed_at',
      header: 'Reviewed At',
      headerStyle: { minWidth: '10rem' },
    },
    ...(source_system_id === SOURCE_SYSTEMS.HOME_DEPOT &&
    category === HD_CATEGORY.BLINDS
      ? [
          {
            field: 'source_status',
            header: 'Source Status',
          },
        ]
      : []),
  ];
  const header = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <h3 className="text-base font-bold">Notes</h3>
      <div className="flex align-items-center gap-2">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            className="p-inputtext-sm"
            placeholder="Search keyword"
            value={searchQuery?.search ? searchQuery?.search : ''}
            onChange={e => {
              setSearchQuery(prev => ({
                ...prev,
                search: e.target.value,
              }));
            }}
          />
        </span>
        <Button
          outlined
          label="Add Item"
          onClick={() => {
            updateDialogFormObj({
              visible: true,
              formAction: ACTION.ADD,
            });
          }}
          type="button"
          size="small"
        />
      </div>
    </div>
  );
  const rowClassName = (rowData: ProjectNote) => {
    const notesTypeId = rowData?.note_category_id;
    const isViewed = rowData?.viewed_by_user;
    const isReviewed = rowData?.reviewed_by_user;
    if (
      !isViewed &&
      !isReviewed &&
      notesTypeId === VIEW_NOTE_TYPES[2].note_category_id
    ) {
      return 'bg-pink-100';
    }
  };
  return (
    <div className="col-12 mt-4">
      <DataTable
        value={notesData?.items || []}
        totalRecords={notesData?.count}
        header={header}
        paginator
        rows={searchQuery.rows}
        rowsPerPageOptions={[10, 20, 50, 100]}
        onPage={onPageChange}
        first={searchQuery.first}
        lazy
        rowClassName={rowClassName}
      >
        {columns.map(col => (
          <Column
            key={col.field}
            field={col.field}
            header={col.header}
            body={dynamicBodyTemplate}
            headerStyle={col.headerStyle}
          />
        ))}
      </DataTable>
      <NotesForm
        dialogFormObj={dialogFormObj}
        updateDialogFormObj={updateDialogFormObj}
        project_id={project_id}
        loadNotesData={loadNotesData}
        toast={toast}
      />
    </div>
  );
};

export default NotesTable;
