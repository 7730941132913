import * as Yup from 'yup';

const initialValues = {
  installer_id: '',
  type: '',
  date_scheduled_start: '',
  date_scheduled_end: '',
  installer_arrival_start_time: '',
  installer_arrival_end_time: '',
};

const InstallerFormikSchema = Yup.object().shape({
  installer_id: Yup.string().trim().required('Required'),
  type: Yup.number().required('Type is Required'),
  date_scheduled_start: Yup.string().when('type', {
    is: val => val === 1 || val === 7, // Tentative or Schedule
    then: Yup.string().required('Start Date & Time is Required'),
    otherwise: Yup.string(),
  }),
  date_scheduled_end: Yup.string().when('type', {
    is: val => val === 1 || val === 7, // Tentative or Schedule
    then: Yup.string().required('End Date and Time is Required'),
    otherwise: Yup.string(),
  }),
});

const handleFormValidate = values => {
  let error = {};
  const startDate = new Date(values?.date_scheduled_start);
  const endDate = new Date(values?.date_scheduled_end);

  if (
    values?.date_scheduled_end &&
    values?.date_scheduled_start &&
    endDate < startDate
  ) {
    error.date_scheduled_end = 'End Date must be greater than Start Date.';
  }
  if (
    values?.installer_arrival_start_time &&
    values?.installer_arrival_end_time &&
    values?.installer_arrival_start_time >= values?.installer_arrival_end_time
  ) {
    error.installer_arrival_end_time =
      'End Time must be greater than Start Time.';
  }
  return error;
};

export { InstallerFormikSchema, handleFormValidate, initialValues };
