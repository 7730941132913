import React, { useEffect, useRef, useState } from 'react';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { BlockUI } from 'primereact/blockui';
import { MultiSelect } from 'primereact/multiselect';

import ApiService from '../../../services/api.service';
import PFPickList from '../../shared/PFPrime/PFPickList';
import PFFormikError from '../../shared/PFPrime/PFFormikError';
import { URL_CONSTANTS } from '../../../constants/urlConstants';
import PageHeader from '../../shared/PageHeader/PageHeader';
import { DEFAULT_AUDIT_LOG_SUBMODULE_ID } from '../../../constants';

import { auditLogSubmit } from './helpers/ProjectAuditLog';

const ProjectAuditLogCreate = () => {
  const history = useHistory();
  const location = useLocation();
  const { id: clientId } = useParams();
  const { action, reportId } = location.state || {};
  const actionMode = action
    ? action
    : window?.location?.pathname?.split('/')[2];
  const reportedId = reportId
    ? reportId
    : window?.location?.pathname?.split('/')[3];
  const roBreadcrumb = [
    {
      link: '/admin/configuration',
      text: 'Company Configurations',
    },
    {
      link: `/audit-log-reports/${clientId}`,
      text: 'Audit Log',
    },
    {
      text: actionMode === 'add' ? 'Create Report' : 'Edit Report',
    },
  ];

  const [moduleNameList, setModuleNameList] = useState([]);
  const [subModuleList, setSubModuleList] = useState([]);
  const [moduleList, setModuleList] = useState([]);
  const [source, setSource] = useState([]);
  const [target, setTarget] = useState([]);
  const [updatedData, setUpdatedData] = useState(null);
  const [blocked, setBlocked] = useState(false);
  const [reloadTarget, setReloadTarget] = useState(true);

  const toast = useRef(null);
  const submitAction = auditLogSubmit(
    toast,
    history,
    actionMode,
    updatedData,
    setBlocked,
    clientId
  );
  const { handleSubmit, handleChange, values, errors, touched, setFieldValue } =
    submitAction;

  useEffect(async () => {
    try {
      const response = await ApiService.get(
        URL_CONSTANTS.AUDIT_LOG.module.getModules
      );
      let filteredItems = response?.filter(obj => obj?.parent_id === 0);
      setModuleList(response);
      setModuleNameList(filteredItems);
      if (
        Array.isArray(filteredItems) &&
        filteredItems.length === 1 &&
        actionMode !== 'edit'
      ) {
        const selectedModule = filteredItems[0];
        const listValue = response?.filter(
          obj => obj?.parent_id === selectedModule?.module_id
        );
        setFieldValue('moduleId', selectedModule?.module_id);
        setSubModuleList(listValue);

        if (selectedModule?.module_id && !values?.subModuleId) {
          setFieldValue('subModuleId', [DEFAULT_AUDIT_LOG_SUBMODULE_ID]);
          handleSelectSubModule([DEFAULT_AUDIT_LOG_SUBMODULE_ID]);
        }
      }
    } catch (e) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: e?.message,
        life: 3000,
      });
    }
  }, []);

  useEffect(() => {
    if (actionMode === 'edit' && values?.subModuleId?.length > 0) {
      handleSelectSubModule(values?.subModuleId);
    }
  }, [actionMode, values?.subModuleId?.length]);

  useEffect(async () => {
    if (actionMode === 'edit') {
      try {
        const response = await ApiService.get(
          `${URL_CONSTANTS.AUDIT_LOG.module.getRowData}${clientId}/detail/${reportedId}`
        );
        setUpdatedData({
          reportName: response?.data?.modal_name || '',
          moduleId: response?.data?.module?.parent_module?.module_id || '',
          subModuleId: response?.data?.module_id || '',
          fields: response?.data?.log_fields || [],
          audit_log_modal_id: response?.data?.audit_log_modal_id || '',
          client_id: clientId,
          meta: response?.data?.meta || null,
        });
        setTarget(response?.data?.log_fields);
      } catch (e) {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: e?.message,
          life: 3000,
        });
      }
    }
  }, [actionMode]);

  useEffect(() => {
    if (updatedData) {
      const listValue = moduleList?.filter(
        obj => obj?.parent_id === updatedData?.moduleId
      );
      setSubModuleList(listValue);
    }
  }, [updatedData, moduleList]);

  const toCapitalizedCase = str => {
    return str
      .replace(/_/g, ' ')
      .replace(/\b\w/g, firstChar => firstChar.toUpperCase());
  };

  const handleSelectSubModule = async event => {
    if (event) {
      try {
        const payload = {
          sub_module_id: event,
        };
        const response = await ApiService.post(
          `${URL_CONSTANTS.AUDIT_LOG.module.getColumnsModule}`,
          payload
        );
        const columnArray = [];
        response?.data?.rows?.sort()?.forEach(item => {
          columnArray.push({
            field: item?.field,
            title: toCapitalizedCase(item?.title),
            meta: item?.meta ? item?.meta : null,
          });
        });
        const filterTarget = target?.filter(item =>
          columnArray?.some(srcItem => srcItem?.field === item?.field)
        );
        setTarget(filterTarget);
        setSource(columnArray);
        setReloadTarget(true);
      } catch (e) {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: e?.message,
          life: 3000,
        });
      }
    }
  };

  useEffect(() => {
    if (reloadTarget) {
      if (target?.length > 0) {
        const filteredSourceData = source.filter(
          field =>
            !target.some(logFieldItem => field.field === logFieldItem.field)
        );
        setSource(filteredSourceData);
        setReloadTarget(false);
      }
    }
  }, [reloadTarget, target, source]);

  const itemTemplate = item => {
    return (
      <div className="flex flex-wrap p-2 align-items-center gap-3">
        <div className="flex-1 flex flex-column gap-2">
          <span className="font-bold">{item?.title}</span>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (target?.length > 0) {
      setFieldValue('fields', target);
    }
  }, [target]);

  return (
    <>
      <Toast ref={toast}></Toast>
      <form className="w-full" onSubmit={handleSubmit}>
        <div className="grid w-full grid-nogutter">
          <div className="col-8 lg:col-8">
            <PageHeader
              pageTitle={
                actionMode === 'add'
                  ? 'Create Audit Log Report'
                  : 'Update Audit Log Report'
              }
              breadCrumbArray={roBreadcrumb}
            />
          </div>
          <div className="col-4 lg:col-4 lg:text-right">
            <Button
              label={blocked ? 'Saving' : 'Save'}
              icon={blocked && 'pi pi-spin pi-spinner'}
              size="small"
              onClick={handleSubmit}
              disabled={
                !submitAction?.dirty ||
                blocked ||
                !(target?.length > 0) ||
                !values?.reportName
              }
            />
          </div>
        </div>
        <BlockUI blocked={blocked} className="w-full">
          <Card subTitle="Basic Information" className="w-full mt-4">
            <div className="grid w-full my-0 mx-auto">
              <div className="col-4">
                <div className="p-float-label">
                  <InputText
                    id="name"
                    value={values?.reportName || ''}
                    onChange={handleChange}
                    name="reportName"
                    className={
                      touched?.reportName && errors?.reportName
                        ? 'error p-inputtext-sm w-full my-0 mx-auto'
                        : 'p-inputtext-sm w-full my-0 mx-auto'
                    }
                  />
                  <label htmlFor="name">Report Name</label>
                </div>

                <PFFormikError
                  touched={touched}
                  errors={errors}
                  field="reportName"
                />
              </div>
              <div className="col-4">
                <div className="p-float-label">
                  <Dropdown
                    options={moduleNameList}
                    optionLabel="module_name"
                    optionValue="module_id"
                    onChange={e => {
                      const listValue = moduleList?.filter(
                        obj => obj?.parent_id === e?.value
                      );
                      setFieldValue('moduleId', e?.value);
                      setSubModuleList(listValue);
                    }}
                    value={
                      values?.moduleId ||
                      (moduleNameList?.length === 1
                        ? moduleNameList[0]?.module_id
                        : '')
                    }
                    disabled={moduleNameList?.length === 1}
                    id="module"
                    name="moduleId"
                    placeholder="Select a Module"
                    className={
                      touched?.moduleId && errors?.moduleId
                        ? 'error w-full my-0 mx-auto p-inputtext-sm'
                        : 'w-full my-0 mx-auto p-inputtext-sm'
                    }
                  />
                  <label htmlFor="module">Select a Module</label>
                </div>
                <PFFormikError
                  touched={touched}
                  errors={errors}
                  field="moduleId"
                />
              </div>
              <div className="col-4">
                <div className="p-float-label">
                  <MultiSelect
                    onChange={e => {
                      setFieldValue('subModuleId', e?.value);
                      handleSelectSubModule(e?.value);
                      if (!e.value) {
                        setSource([]);
                      }
                    }}
                    options={subModuleList?.map(option => ({
                      ...option,
                      disabled:
                        option.module_id === DEFAULT_AUDIT_LOG_SUBMODULE_ID,
                    }))}
                    optionLabel="module_name"
                    optionValue="module_id"
                    value={
                      values?.moduleId
                        ? values?.subModuleId || [
                            DEFAULT_AUDIT_LOG_SUBMODULE_ID,
                          ]
                        : []
                    }
                    placeholder="Select a Sub-module"
                    className={
                      touched?.subModuleId && errors?.subModuleId
                        ? 'error w-full my-0 mx-auto p-inputtext-sm'
                        : 'w-full my-0 mx-auto p-inputtext-sm'
                    }
                    name="subModuleId"
                    id="subModule"
                    maxSelectedLabels={1}
                    showSelectAll={false}
                    pt={{
                      header: { className: 'hidden' },
                    }}
                  />
                  <label htmlFor="subModule">Select a Sub-module</label>
                </div>
                <PFFormikError
                  touched={touched}
                  errors={errors}
                  field="subModuleId"
                />
              </div>
            </div>
          </Card>
          {values?.subModuleId && (
            <Card subTitle="Field Selection" className="w-full  mt-4">
              <div className="grid w-full mb-0 mx-auto mt-4">
                <div className="col-12">
                  <PFPickList
                    name="fields"
                    source={source}
                    target={target}
                    onChange={event => {
                      setSource(event?.source);
                      setTarget(event?.target);
                    }}
                    itemTemplate={itemTemplate}
                    breakpoint="1280px"
                    sourceHeader="Available"
                    targetHeader="Selected"
                    sourceStyle={{ height: '24rem' }}
                    targetStyle={{ height: '24rem' }}
                    filter
                    filterBy="title"
                    sourceFilterPlaceholder="Search by name"
                    targetFilterPlaceholder="Search by name"
                    pt={{
                      header: {
                        className: 'p-2 text-sm text-center font-bold',
                      },
                      item: { className: 'p-0 text-sm' },
                      filterinput: {
                        className:
                          'text-sm p-2 border-noround border-300 border-bottom-none',
                      },
                      filtercontainer: { className: 'p-0  border-none' },
                      'filterinput:hover': {
                        filterinput: { className: 'border-noround ' },
                      },
                    }}
                  />
                </div>
              </div>
            </Card>
          )}
        </BlockUI>
      </form>
    </>
  );
};

export default ProjectAuditLogCreate;
