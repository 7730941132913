import axios from 'axios';

import { CONFIG } from '../../../../constants';
import { URL_CONSTANTS } from '../../../../constants/urlConstants';
import {
  DTypeItem,
  FileUploadItem,
  RelatedPOItem,
} from '../interface/Photos.interface';
import { momentTz } from '../../../../utils/Helpers';

export const getDocuments = async (
  projectId: number,
  documentTypeId: number
) => {
  try {
    const requestDocuments = await axios.get(
      `${CONFIG.API_BASE_URL}/projects/${projectId}/documents?dtype=${documentTypeId}`
    );

    return requestDocuments?.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};
export const getTemplates = async (projectId: number) => {
  try {
    const requestTemplates = await axios.get(
      `${CONFIG.API_BASE_URL}/projects/${projectId}/templates`
    );
    return requestTemplates?.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const updateDocumentByDocumentId = async (
  projectId: number,
  documentId: number,
  documentName: string,
  type: string,
  isSigned = undefined
) => {
  try {
    return await axios.patch(
      `${CONFIG.API_BASE_URL}/projects/${projectId}/documents/${documentId}`,
      {
        document_name: documentName || null,
        document_store_type: type || null,
        isSigned: isSigned,
      }
    );
  } catch (error) {
    console.error(error);
  }
};

export const upLoadDocumentOrPhoto = async (
  projectId: number,
  uploadObj: FileUploadItem
) => {
  try {
    const formData = new FormData();
    if (Array.isArray(uploadObj?.file)) {
      uploadObj?.file?.forEach(fileObj => {
        formData.append(`file`, fileObj?.file);
      });
    } else {
      formData.append('file', uploadObj?.file);

      formData.append(
        'document_name',
        uploadObj?.document_name ? uploadObj?.document_name : 'null'
      );
    }

    formData.append(
      'document_type_id',
      uploadObj?.document_type_id ? `${uploadObj?.document_type_id}` : 'null'
    );

    formData.append(
      'document_store_type',
      uploadObj?.document_store_type
        ? `${uploadObj?.document_store_type}`
        : 'null'
    );
    if (uploadObj?.hd_doc_cd_number) {
      formData.append(
        'hd_doc_cd_number',
        uploadObj?.hd_doc_cd_number ? `${uploadObj?.hd_doc_cd_number}` : 'null'
      );
    }
    if (uploadObj?.ll_document_type) {
      formData.append(
        'll_document_type',
        uploadObj?.ll_document_type ? `${uploadObj?.ll_document_type}` : 'null'
      );
    }
    formData.append(
      'document_category_id',
      uploadObj?.document_category_id?.document_category_id
        ? `${uploadObj?.document_category_id?.document_category_id}`
        : 'null'
    );

    formData.append(
      'is_customer_visible',
      uploadObj?.is_customer_visible ? `${uploadObj?.is_customer_visible}` : '0'
    );
    formData.append(
      'is_installer_visibility',
      uploadObj?.is_installer_visibility
        ? `${uploadObj?.is_installer_visibility}`
        : '0'
    );
    formData.append(
      'is_proj_docu_visibility',
      uploadObj?.is_proj_docu_visibility
        ? `${uploadObj?.is_proj_docu_visibility}`
        : '0'
    );
    formData.append(
      'reviewed_status',
      uploadObj?.reviewed_status ? `${uploadObj?.reviewed_status}` : '0'
    );
    formData.append(
      'is_editable',
      uploadObj?.is_editable ? `${uploadObj?.is_editable}` : '0'
    );

    const headers = {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json',
    };
    const response = await axios.post(
      `${CONFIG.API_BASE_URL}/projects/${projectId}/documents`,
      formData,
      { headers: headers }
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const applyInstallerVisibilityDocuments = async (
  projectId: number,
  type: string,
  installerVisibleDocuments: unknown
) => {
  try {
    const response = await axios.post(
      `${CONFIG.API_BASE_URL}/projects/${projectId}/documents/settings?type=${type}`,
      installerVisibleDocuments
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const getRelatedDocuments = async (
  projectId: number,
  documentTypeId: number
) => {
  try {
    const response = await axios.get(
      `${CONFIG.API_BASE_URL}/projects/${projectId}/documents?dtype=${documentTypeId}&type=related`
    );
    //mapping response
    const documentsData = response?.data?.items?.map((document: DTypeItem) => {
      return {
        projectId: document.project_id,
        documentId: document.document_id,
        documetTypeId: document.document_type_id,
        documentName: document.document_name,
        documentUrl: document.aws_doc_url,
        docTemplateId: document.document_template?.template_id,
        documentThumbnail: `/thumbnail.PNG`,
        docCreatedDate: momentTz(document.created_at).format(
          'MM-DD-YYYY hh:mm A'
        ),
        is_installer_visibility: document.is_installer_visibility,
        is_proj_docu_visibility: document.is_proj_docu_visibility,
        is_rel_proj_docu_visibility: document.is_rel_proj_docu_visibility,
      };
    });

    return documentsData;
  } catch (error) {
    console.error(error);
  }
};

export const deleteProjectDocument = async (
  projectId: number,
  documentId: number,
  type: string
) => {
  try {
    const deleteStatus = await axios.delete(
      `${URL_CONSTANTS?.API?.BASE_URL}/projects/${projectId}/documents/${documentId}/${type}`
    );
    return deleteStatus?.data;
  } catch (err) {
    console.error(err);
  }
};

export const bulkUpdateService = async (project_id: number, body: unknown) => {
  try {
    const res = await axios.patch(
      `${URL_CONSTANTS?.API?.BASE_URL}/projects/${project_id}/documents/bulk-update`,
      body
    );
    return res?.data || {};
  } catch (e) {
    console.error('docs.service', 'catetoryCreateService', e);
    return {};
  }
};
export const categoryCreateService = async (body: unknown) => {
  try {
    const res = await axios.post(
      `${URL_CONSTANTS?.API?.BASE_URL}/projects/document-category/create-document-category`,
      body
    );
    return res?.data || {};
  } catch (e) {
    console.error('docs.service', 'catetoryCreateService', e);
    return {};
  }
};

export const categoryListService = async (search: string) => {
  try {
    const res = await axios.get(
      `${URL_CONSTANTS?.API?.BASE_URL}/projects/document-category/get-document-category`,
      { params: { search } }
    );
    return res?.data || {};
  } catch (e) {
    console.error('docs.service', 'catetoryListService', e);
    return {};
  }
};

export const getRelatedPOData = async (projectId: number) => {
  try {
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/projects/${projectId}/related-po/list`
    );
    return response?.data
      ? {
          detail_fee_number: response?.data?.detail_fee_number || '',
          items:
            response?.data?.items?.map((item: RelatedPOItem) => ({
              ...item,
              label: `${item.project_number} ${item.project_type}`,
            })) || [],
        }
      : { detail_fee_number: '', items: [] };
  } catch (error) {
    console.error(error);
  }
};
