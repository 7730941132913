import { AxiosError } from 'axios';

import { URL_CONSTANTS } from '../../../../constants/urlConstants';
import apiService from '../../../../services/api.service';
import {
  AdditionalChargeItem,
  ExpenseItem,
} from '../interface/additionalFee.interface';

export const fetchAdditionalChargeData = async (projectId: number) => {
  try {
    const response = await apiService.get(
      apiService.formatUrl(
        URL_CONSTANTS.PROJECTS.additionalFeeTab.additionalCharge,
        projectId
      )
    );

    return {
      error: false,
      message: 'Additional Charge data fetched successfully',
      data: response,
    };
  } catch (error: unknown) {
    console.error('Error fetching Additional Charge data:', error);
    return {
      error: true,
      message:
        error instanceof AxiosError
          ? error?.response?.data?.error
          : 'Error fetching Additional Charge data',
      data: null,
    };
  }
};

export const fetchExpensesData = async (projectId: number) => {
  try {
    const response = await apiService.get(
      apiService.formatUrl(
        URL_CONSTANTS.PROJECTS.additionalFeeTab.expenses,
        projectId
      )
    );

    return {
      error: false,
      message: 'Expenses data fetched successfully',
      data: response,
    };
  } catch (error: unknown) {
    console.error('Error fetching Expenses data:', error);
    return {
      error: true,
      message:
        error instanceof AxiosError
          ? error?.response?.data?.error
          : 'Error fetching Expenses data',
      data: null,
    };
  }
};
export const getPrTypes = async () => {
  try {
    const response = await apiService.get(
      URL_CONSTANTS.PROJECTS.additionalFeeTab.prTypes
    );
    return {
      error: false,
      message: 'PR Types fetched successfully',
      data: response,
    };
  } catch (error: unknown) {
    console.error('Error fetching PR Types data:', error);
    return {
      error: true,
      message:
        error instanceof AxiosError
          ? error?.response?.data?.error
          : 'Error fetching PR Types data',
      data: null,
    };
  }
};
export const createAdditionalCharge = async (
  projectId: number,
  values: AdditionalChargeItem
) => {
  try {
    const response = await apiService.post(
      apiService.formatUrl(
        URL_CONSTANTS.PROJECTS.additionalFeeTab.additionalCharge,
        projectId
      ),
      values
    );
    return {
      error: false,
      message: 'Additional Charge created successfully',
      data: response,
    };
  } catch (error: unknown) {
    console.error('Error creating additional charge:', error);
    return {
      error: true,
      message:
        error instanceof AxiosError
          ? error?.response?.data?.error
          : 'Error creating additional charge:',
      data: null,
    };
  }
};

export const updateAdditionalCharge = async (
  projectId: number,
  chargeId: number,
  updateObj: AdditionalChargeItem
) => {
  try {
    const response = await apiService.patch(
      apiService.formatUrl(
        URL_CONSTANTS.PROJECTS.additionalFeeTab.additionalChargeById,
        projectId,
        chargeId
      ),
      updateObj
    );
    return {
      error: false,
      message: 'Additional Charge updated successfully',
      data: response,
    };
  } catch (error: unknown) {
    console.error('Error updating additional charge:', error);
    return {
      error: true,
      message:
        error instanceof AxiosError
          ? error?.response?.data?.error
          : 'Error updating additional charge:',
      data: null,
    };
  }
};

export const deleteAdditionalChargeById = async (
  projectId: number,
  chargeId: number
) => {
  try {
    const response = await apiService.delete(
      apiService.formatUrl(
        URL_CONSTANTS.PROJECTS.additionalFeeTab.additionalChargeById,
        projectId,
        chargeId
      )
    );
    return {
      error: false,
      message: 'Additional Charge deleted successfully',
      data: response,
    };
  } catch (error: unknown) {
    console.error('Error deleting additional charge:', error);
    return {
      error: true,
      message:
        error instanceof AxiosError
          ? error?.response?.data?.error
          : 'Error deleting additional charge:',
      data: null,
    };
  }
};

export const getExpenseSource = async () => {
  try {
    const response = await apiService.get(
      URL_CONSTANTS.PROJECTS.additionalFeeTab.expenseSource
    );
    return {
      error: false,
      message: 'Expense Source fetched successfully',
      data: response,
    };
  } catch (error: unknown) {
    console.error('Error fetching Expense Source data:', error);
    return {
      error: true,
      message:
        error instanceof AxiosError
          ? error?.response?.data?.error
          : 'Error fetching Expense Source data',
      data: null,
    };
  }
};

export const createExpense = async (projectId: number, values: ExpenseItem) => {
  try {
    const response = await apiService.post(
      apiService.formatUrl(
        URL_CONSTANTS.PROJECTS.additionalFeeTab.expenses,
        projectId
      ),
      values
    );
    return {
      error: false,
      message: 'Expense created successfully',
      data: response,
    };
  } catch (error: unknown) {
    console.error('Error creating expense:', error);
    return {
      error: true,
      message:
        error instanceof AxiosError
          ? error?.response?.data?.error
          : 'Error creating expense:',
      data: null,
    };
  }
};

export const updateExpense = async (
  projectId: number,
  projectExpenseId: number,
  updateObj: ExpenseItem
) => {
  try {
    const response = await apiService.patch(
      apiService.formatUrl(
        URL_CONSTANTS.PROJECTS.additionalFeeTab.expensesById,
        projectId,
        projectExpenseId
      ),
      updateObj
    );
    return {
      error: false,
      message: 'Expense updated successfully',
      data: response,
    };
  } catch (error: unknown) {
    console.error('Error updating expense:', error);
    return {
      error: true,
      message:
        error instanceof AxiosError
          ? error?.response?.data?.error
          : 'Error updating expense:',
      data: null,
    };
  }
};

export const deleteExpenseById = async (
  projectId: number,
  projectExpenseId: number
) => {
  try {
    const response = await apiService.delete(
      apiService.formatUrl(
        URL_CONSTANTS.PROJECTS.additionalFeeTab.expensesById,
        projectId,
        projectExpenseId
      )
    );
    return {
      error: false,
      message: 'Expense deleted successfully',
      data: response,
    };
  } catch (error: unknown) {
    console.error('Error deleting expense:', error);
    return {
      error: true,
      message:
        error instanceof AxiosError
          ? error?.response?.data?.error
          : 'Error deleting expense:',
      data: null,
    };
  }
};
