import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Card } from 'primereact/card';
import { Checkbox } from 'primereact/checkbox';
import { Tooltip } from 'primereact/tooltip';

import SkeletonLoader from '../Loader/Loader';

import useDebounce from './useDebounce';
import { useStyles } from './CheckboxList.styles';

const CheckboxList = ({
  columns,
  data = [],
  selectedItems,
  onCheckboxChange,
  searchTerm: parentSearchTerm,
  sortBy: parentSortBy,
  sortOrder: parentSortOrder,
  unselectedBackground = 'white',
  loading,
  updateDefaultDashboardId,
  defaultDashboardId,
}) => {
  const classes = useStyles();
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState(parentSearchTerm);
  const [localSortBy, setLocalSortBy] = useState(parentSortBy);
  const [localSortOrder, setLocalSortOrder] = useState(parentSortOrder);
  const debouncedSearchTerm = useDebounce(searchTerm, 500); // 500ms delay

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  useEffect(() => {
    function filterAndSortData() {
      let filtered = filteredData.length > 0 ? filteredData : data;

      if (filteredData.length > 0) setFilteredData([]);

      // Filter by search term
      if (debouncedSearchTerm) {
        filtered = filtered.filter(item =>
          columns.some(col =>
            item[col.key]
              .toLowerCase()
              .includes(debouncedSearchTerm.toLowerCase())
          )
        );
      }

      // Sort by dropdown selection
      if (localSortBy) {
        filtered.sort((a, b) => {
          if (localSortOrder === 'asc') {
            return String(a[localSortBy]).toLowerCase() <
              String(b[localSortBy]).toLowerCase()
              ? 1
              : -1;
          } else {
            return String(a[localSortBy]).toLowerCase() >
              String(b[localSortBy]).toLowerCase()
              ? 1
              : -1;
          }
        });
      }

      setFilteredData(filtered);
    }
    filterAndSortData();
  }, [debouncedSearchTerm, localSortBy, localSortOrder, columns]);

  const sortOrderOptions = [
    { label: 'None', value: 'null' },
    { label: 'Ascending', value: 'asc' },
    { label: 'Descending', value: 'desc' },
  ];

  const handleSortBy = e => {
    setLocalSortBy(e.value);
    setLocalSortOrder('null');
  };

  const renderCellValue = (item, col, index) => {
    // function to render the data
    const value = item[col.key];

    return (
      <>
        {value.length >= 26 && (
          <Tooltip target={`.custom-tooltip-${index}-${col.key}`}>
            {value}
          </Tooltip>
        )}
        <span
          className={`${col.bold === true ? 'font-bold' : 'font-normal'} ${
            col.italic === true ? 'font-italic' : 'font-normal'
          } 
          white-space-nowrap overflow-hidden text-overflow-ellipsis block w-20rem md:w-18rem sm:w-10rem custom-tooltip-${index}-${
            col.key
          }`}
        >
          {value}
        </span>
      </>
    );
  };

  return (
    <div className="p-1">
      <div className="grid p-2 align-items-center">
        <span className="p-float-label col-4 p-0 ">
          <InputText
            id="search"
            value={searchTerm}
            className="min-w-full"
            onChange={e => setSearchTerm(e.target.value)}
          />
          <label htmlFor="search">Search</label>
        </span>
        <span className="col-2">
          <Dropdown
            id="sortBy"
            value={localSortBy}
            className="min-w-full"
            options={columns.map(col => ({ label: col.label, value: col.key }))}
            onChange={e => handleSortBy(e)}
            placeholder="Sort By"
          />
        </span>

        <span className="p-float-label col-2">
          <Dropdown
            id="sortOrder"
            value={localSortOrder}
            className="min-w-full"
            options={sortOrderOptions}
            onChange={e => setLocalSortOrder(e.value)}
            placeholder="Order"
          />
        </span>
      </div>
      <div className={`grid ${classes.cardBackground} overflow-x-hidden`}>
        {filteredData.length === 0 && loading === true && (
          <>
            <div className="col-12 md:col-6 lg:col-4 h-20em pad-3">
              <SkeletonLoader
                columnCount={1}
                columnWidth="100%"
                columnHeight="12rem"
              />
            </div>
            <div className="col-12 md:col-6 lg:col-4 h-20em pad-3">
              <SkeletonLoader
                columnCount={1}
                columnWidth="100%"
                columnHeight="12rem"
              />
            </div>
            <div className="col-12 md:col-6 lg:col-4 h-20em pad-3">
              <SkeletonLoader
                columnCount={1}
                columnWidth="100%"
                columnHeight="12rem"
              />
            </div>
            <div className="col-12 md:col-6 lg:col-4 h-20em pad-3">
              <SkeletonLoader
                columnCount={1}
                columnWidth="100%"
                columnHeight="12rem"
              />
            </div>
            <div className="col-12 md:col-6 lg:col-4 h-20em pad-3">
              <SkeletonLoader
                columnCount={1}
                columnWidth="100%"
                columnHeight="12rem"
              />
            </div>
            <div className="col-12 md:col-6 lg:col-4 h-20em pad-3">
              <SkeletonLoader
                columnCount={1}
                columnWidth="100%"
                columnHeight="12rem"
              />
            </div>
          </>
        )}
        {filteredData.map((item, index) => (
          <div className={`col-12 md:col-6 lg:col-4 h-20em pad-3`} key={index}>
            <Card
              className={`flex align-items-center gap-2 w-full pad-3 ${
                selectedItems.some(i => i.name === item.name)
                  ? 'bg-blue-400'
                  : unselectedBackground
              } min-h-0 md:min-h-full max-h-0 md:max-h-full`}
              key={index}
              pt={{
                body: {
                  className: 'w-full',
                },
              }}
              title={
                <div className="card flex justify-content-start w-full">
                  <Checkbox
                    inputId={`cb${index}`}
                    checked={selectedItems.some(i => i.name === item.name)}
                    onChange={() => onCheckboxChange(item)}
                  />
                  <label
                    htmlFor={`cb${index}`}
                    className={`ml-2 md:ml-1 w-10 xl:w-11 ${
                      selectedItems.some(i => i.name === item.name)
                        ? 'text-blue-50'
                        : 'text-color-secondary'
                    }`}
                  >
                    <div className="pad-3 ">
                      {columns.map(
                        col =>
                          col.isTitle && (
                            <div key={col.key}>
                              {renderCellValue(item, col, index)}
                            </div>
                          )
                      )}
                    </div>
                  </label>
                  {updateDefaultDashboardId && (
                    <Checkbox
                      inputId={`cb${index}`}
                      checked={item.id == defaultDashboardId}
                      onChange={() => updateDefaultDashboardId(item.id)}
                      tooltip="Mark as a default"
                    />
                  )}
                </div>
              }
              subTitle={
                <div
                  className={`card flex flex-wrap justify-content-start ${
                    selectedItems.some(i => i.name === item.name)
                      ? 'text-blue-50'
                      : 'text-color-secondary'
                  }`}
                >
                  <div>
                    {columns.map(
                      col =>
                        col.isSubTitle && (
                          <div key={col.key}>
                            {renderCellValue(item, col, index)}
                          </div>
                        )
                    )}
                  </div>
                </div>
              }
            >
              <div
                className={`card flex flex-wrap justify-content-start ${
                  selectedItems.some(i => i.name === item.name)
                    ? 'text-blue-50'
                    : 'text-color-secondary'
                }`}
              >
                <div>
                  {columns.map(
                    col =>
                      !col.isTitle &&
                      !col.isSubTitle && (
                        <div key={col.key}>
                          {renderCellValue(item, col, index)}
                        </div>
                      )
                  )}
                </div>
              </div>
            </Card>
          </div>
        ))}
      </div>
    </div>
  );
};

CheckboxList.defaultProps = {
  data: [],
};

export default CheckboxList;
