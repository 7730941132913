/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { debounce } from 'lodash';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { InputText } from 'primereact/inputtext';
import { Panel } from 'primereact/panel';
import { Checkbox } from 'primereact/checkbox';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Divider } from 'primereact/divider';
import { Skeleton } from 'primereact/skeleton';
import { useSelector } from 'react-redux';

import { StatusColors } from '../../../constants';
import { getReportData } from '../services/report';
import { mergeAndDuplicateValues } from '../helpers/scheduler-calendar.helper';

const READY_TO_SCHEDULE_STATUS_ID = 6;
const ReadyToScheduleReport = ({
  filter,
  handleSetFilter,
  handleSetLoader,
}) => {
  const { rtsFilter, isSHowSchedulerComponent } = useSelector(
    state => state.newScheduler
  );

  const { projectNumber } = useParams();
  const showColumnsRef = useRef(null);
  const tdClass =
    'h-3rem text-center flex align-items-center justify-content-center';
  const columnProps = {
    // className: 'w-10rem min-w-10rem',
    headerStyle: {
      textAlign: 'center',
    },
    pt: {
      headercontent: {
        className: 'flex justify-content-center',
      },
      bodycell: {
        className: 'p-1',
      },
      bodyrow: {
        className: 'ready_to_schedule_report',
      },
    },
  };
  const [columns, setColumns] = useState({
    count: 16,
    selected: {
      project_id: true,
      rts_follow_up_date: true,
      date_sold: true,
      customerId: true,
      mobile_number: true,
      home_phone: true,
      storeNumber: true,
      project: true,
      category: true,
      market: true,
      projectTypeId: true,
      statusId: true,
      imsStatus: true,
      installation_address: true,
      districtId: true,
      workroomId: true,
    },
  });
  const [readyToScheduleLoader, setReadyToScheduleLoader] = useState(false);
  const [readyToScheduleData, setReadyToScheduleData] = useState({
    count: 0,
    data: [],
  });
  const [readyToScheduleFilter, setReadyToScheduleFilter] = useState({
    filter: {},
    pageSettings: {
      pageSizes: [10, 20, 50, 100],
      limit: 10,
      offset: 0,
      sortField: null,
      sortOrder: null,
    },
  });
  const loaderTable = useMemo(() => {
    const loaderObj = {};
    for (const key in columns.selected) {
      loaderObj[key] = <Skeleton width="10rem" height="3rem" />;
    }
    return [1, 2, 3, 4, 5, 6].map(() => loaderObj);
  }, [columns]);
  const onRowDragStart = (event, data) => {
    try {
      event.dataTransfer.setData('eventData', JSON.stringify(data || {})); // Store event data
    } catch (ex) {
      console.error(ex, 'drag start');
    }
  };
  const handleSetReadyToScheduleFilter = params => {
    if (params && typeof params === 'object' && !Array.isArray(params)) {
      setReadyToScheduleFilter({
        ...readyToScheduleFilter,
        ...params,
      });
    }
  };

  const callReadyToScheduleApi = async localFilter => {
    setReadyToScheduleLoader(true);
    try {
      let apiFilters = {};
      if (typeof rtsFilter === 'object' && !Array.isArray(rtsFilter)) {
        apiFilters = { ...apiFilters, ...rtsFilter };
      }
      if (localFilter?.projectNumber) {
        apiFilters = { ...apiFilters, searchQuery: localFilter?.projectNumber };
      }
      apiFilters = {
        ...apiFilters,
        ...readyToScheduleFilter.filter,
        ...(typeof localFilter === 'object' && !Array.isArray(localFilter)
          ? localFilter
          : {}),
        projectNumber: undefined,
      };
      const urlParams = {
        ...apiFilters,
        limit: readyToScheduleFilter.pageSettings.limit,
        offset: readyToScheduleFilter.pageSettings.offset,
        sortKey: readyToScheduleFilter.pageSettings?.sortField,
        sortDirection: readyToScheduleFilter.pageSettings?.sortOrder
          ? readyToScheduleFilter.pageSettings?.sortOrder > 0
            ? 'DESC'
            : 'ASC'
          : undefined,
      };
      const apiResponse = await getReportData(
        READY_TO_SCHEDULE_STATUS_ID,
        urlParams
      );
      if ('count' in apiResponse && Array.isArray(apiResponse?.items)) {
        const readyToScheduleDataArray = apiResponse?.items?.map(val => {
          return {
            rts_follow_up_date: val.rts_follow_up_date
              ? moment(val.rts_follow_up_date?.slice(0, 10)).format(
                  'MM-DD-YYYY'
                )
              : null,
            date_sold: moment(val.date_sold?.slice(0, 10))?.format(
              'MM-DD-YYYY'
            ),
            customerId: val?.client_name,
            category: val?.category,
            market: val?.market,
            project_description: val?.category,
            project: val?.project_number,
            storeNumber: val?.store_number,
            installer: val?.Installer,
            statusId: val?.project_status,
            projectTypeId: val?.project_type,
            mobile_number: val?.mobile_phone,
            home_phone: val?.home_phone,
            imsStatus: val?.IMS_status,
            project_id: val?.project_id,
            installation_address: val?.installation_address,
            districtId: val?.district ? val?.district : null,
            workroomId: val?.workroom_label ? val?.workroom_label : null,
            raw: val,
          };
        });
        setReadyToScheduleData({
          count: apiResponse?.count,
          data: readyToScheduleDataArray,
        });
        if (localFilter.projectNumber && readyToScheduleDataArray.length) {
          const calendarFilter = {
            projectTypeIds: readyToScheduleDataArray[0].raw?.project_type_id,
            projectCategoryId:
              readyToScheduleDataArray[0].raw?.project_category_id,
            projectStoreId: readyToScheduleDataArray[0].raw?.store_id,
            isJobCoverage: true,
          };
          handleSetFilter({
            calendarFilter,
            selectedRow: readyToScheduleDataArray[0],
            previousData: calendarFilter,
          });
        }
        return readyToScheduleDataArray;
      }
    } catch (error) {
      console.log(error);
    } finally {
      setReadyToScheduleLoader(false);
    }
    return true;
  };

  useEffect(() => {
    const params = {};
    if (projectNumber) {
      params.projectNumber = projectNumber;
      handleSetLoader({ calendarLoader: true });
    }
    if (isSHowSchedulerComponent) callReadyToScheduleApi(params);
  }, [
    rtsFilter,
    readyToScheduleFilter.pageSettings,
    projectNumber,
    isSHowSchedulerComponent,
  ]);

  const handleRecordClick = event => {
    const className = event?.originalEvent?.target?.getAttribute('class');
    if (
      event?.data?.raw?.project_id &&
      className &&
      !className.includes(`open_${event?.data?.raw?.project_id}`)
    ) {
      handleSetLoader({
        calendarLoader: true,
      });
      let calendarFilter = {};
      let initialData = {
        projectTypeIds: event.data.raw?.project_type_id,
        projectCategoryId: event.data.raw?.project_category_id,
        projectStoreId: event.data.raw?.store_id,
        isJobCoverage: true,
      };
      if (
        filter?.filterTechnician &&
        Object.keys(filter.filterTechnician)?.length > 0
      ) {
        if (filter?.filterTechnician?.installerIds) {
          calendarFilter = {
            projectTypeIds: event.data.raw?.project_type_id,
            projectCategoryId: event.data.raw?.project_category_id,
            projectStoreId: event.data.raw?.store_id,
            isJobCoverage: true,
            installerIds: filter?.filterTechnician?.installerIds,
          };
        } else {
          calendarFilter = {
            projectTypeIds: filter?.filterTechnician?.projectTypeIds
              ? mergeAndDuplicateValues(
                  event.data.raw?.project_type_id,
                  filter?.filterTechnician?.projectTypeIds
                )
              : event.data.raw?.project_type_id,
            projectCategoryId: filter?.filterTechnician?.projectCategoryId
              ? mergeAndDuplicateValues(
                  event.data.raw?.project_category_id,
                  filter?.filterTechnician?.projectCategoryId
                )
              : event.data.raw?.project_category_id,
            projectStoreId: filter?.filterTechnician?.projectStoreId
              ? mergeAndDuplicateValues(
                  event.data.raw?.store_id,
                  filter?.filterTechnician?.projectStoreId
                )
              : event.data.raw?.store_id,
            workRoomIds: filter?.filterTechnician?.workRoomIds,
            districts: filter?.filterTechnician?.districts,
            userTypeIds: filter?.filterTechnician?.userTypeIds,

            isJobCoverage: true,
          };
        }
      } else {
        calendarFilter = {
          projectTypeIds: event.data.raw?.project_type_id,
          projectCategoryId: event.data.raw?.project_category_id,
          projectStoreId: event.data.raw?.store_id,
          isJobCoverage: true,
        };
      }

      handleSetFilter({
        calendarFilter,
        selectedRow: event.data,
        previousData: initialData,
      });
    }
  };
  const handleSelectColumn = e => {
    setColumns({
      count: e.checked ? columns.count + 1 : columns.count - 1,
      selected: {
        ...columns.selected,
        [e.value]: e.checked,
      },
    });
  };
  const debounceOnSearch = useCallback(
    debounce(callReadyToScheduleApi, 500),
    []
  );
  const panelHeaderTemplate = options => {
    const className = `${options.className} justify-content-between`;
    return (
      <div className={`${className} pt-2 pb-2`}>
        <div className="flex align-items-center">
          <span className="font-bold">Ready to Schedule Report</span>
        </div>
        <div className="flex gap-2 align-items-center">
          <IconField iconPosition="left">
            <InputIcon className="pi pi-search"> </InputIcon>
            <InputText
              placeholder="Search"
              value={readyToScheduleFilter.filter?.searchQuery}
              onChange={e => {
                handleSetReadyToScheduleFilter({
                  filter: {
                    ...readyToScheduleFilter.filter,
                    searchQuery: e.target.value,
                  },
                });
                debounceOnSearch({ searchQuery: e.target.value });
              }}
              disabled={readyToScheduleLoader}
            />
          </IconField>
          <i
            className="material-icons-sharp md-36 md-view_column hover:text-xl"
            onClick={e => showColumnsRef.current.toggle(e)}
          ></i>
          <OverlayPanel
            ref={showColumnsRef}
            style={{ left: '-200px', position: 'absolute' }}
          >
            <span className="font-semibold">Select Columns</span>
            <Divider />
            {READY_TO_SCHEDULE_TABLE_COLUMNS.map(each => (
              <div key={each.field} className="flex align-items-center">
                <Checkbox
                  inputId={each.field}
                  name="columns"
                  value={each.field}
                  onChange={handleSelectColumn}
                  checked={!!columns.selected?.[each.field]}
                  className="mt-2"
                />

                <label htmlFor={each.field} className="ml-2">
                  {each.header}
                </label>
              </div>
            ))}
          </OverlayPanel>
        </div>
      </div>
    );
  };
  const dragTemplate = (rowData, key) => {
    if (readyToScheduleLoader) return <Skeleton width="10rem" height="3rem" />;
    let html = rowData?.[key] || '-';
    if ('project_id' === key) {
      html = (
        <i
          className={`pi pi-eye cursor-pointer open_${rowData?.project_id}`}
          onClick={() =>
            window.open(`/project/view/${rowData?.project_id}`, '_blank')
          }
        ></i>
      );
    } else if ('statusId' === key) {
      html = (
        <span
          style={{
            color:
              (rowData?.statusId === 'Completed' && StatusColors.PF_GREEN) ||
              (rowData?.statusId === 'Cancelled' && StatusColors.PF_RED) ||
              StatusColors.PF_YELLOW,
            display: 'block',
          }}
        >
          {rowData?.statusId || '-'}
        </span>
      );
    }
    return (
      <div
        className={tdClass}
        draggable={true}
        onDragStart={e => onRowDragStart(e, rowData)}
        data-index={rowData?.project_id}
      >
        {html}
      </div>
    );
  };

  const READY_TO_SCHEDULE_TABLE_COLUMNS = [
    {
      field: 'project_id',
      header: '',
      body: rowData => dragTemplate(rowData, 'project_id'),
      style: {
        minWidth: '100px',
      },
      className: 'max-w-6rem w-15rem text-center',
      hidden: !columns.selected.project_id,
    },
    {
      field: 'rts_follow_up_date',
      header: 'RTS Follow-up Date',
      body: rowData => dragTemplate(rowData, 'rts_follow_up_date'),
      sortable: true,
      hidden: !columns.selected.rts_follow_up_date,
    },
    {
      field: 'date_sold',
      header: 'Date Sold',
      body: rowData => dragTemplate(rowData, 'date_sold'),
      sortable: true,
      hidden: !columns.selected.date_sold,
    },
    {
      field: 'customerId',
      header: 'Client Name',
      body: rowData => dragTemplate(rowData, 'customerId'),
      sortable: true,
      hidden: !columns.selected.customerId,
    },
    {
      field: 'mobile_number',
      header: 'Mobile No.',
      body: rowData => dragTemplate(rowData, 'mobile_number'),
      sortable: true,
      hidden: !columns.selected.mobile_number,
    },
    {
      field: 'home_phone',
      header: 'Home Phone',
      body: rowData => dragTemplate(rowData, 'home_phone'),
      sortable: true,
      hidden: !columns.selected.home_phone,
    },
    {
      field: 'storeNumber',
      header: 'Store #',
      body: rowData => dragTemplate(rowData, 'storeNumber'),
      sortable: true,
      hidden: !columns.selected.storeNumber,
    },
    {
      field: 'project',
      header: 'Project #',
      body: rowData => dragTemplate(rowData, 'project'),
      sortable: true,
      hidden: !columns.selected.project,
    },
    {
      field: 'category',
      header: 'Category',
      body: rowData => dragTemplate(rowData, 'category'),
      sortable: true,
      hidden: !columns.selected.category,
    },
    {
      field: 'market',
      header: 'Market',
      body: rowData => dragTemplate(rowData, 'market'),
      hidden: !columns.selected.market,
    },
    {
      field: 'projectTypeId',
      header: 'Type',
      body: rowData => dragTemplate(rowData, 'projectTypeId'),
      sortable: true,
      hidden: !columns.selected.projectTypeId,
    },
    {
      field: 'statusId',
      header: 'Status',
      body: rowData => dragTemplate(rowData, 'statusId'),
      sortable: true,
      hidden: !columns.selected.statusId,
    },
    {
      field: 'imsStatus',
      header: 'Source Status',
      body: rowData => dragTemplate(rowData, 'imsStatus'),
      hidden: !columns.selected.imsStatus,
    },
    {
      field: 'installation_address',
      header: 'Installation Address',
      body: rowData => dragTemplate(rowData, 'installation_address'),
      hidden: !columns.selected.installation_address,
    },
    {
      field: 'districtId',
      header: 'District',
      body: rowData => dragTemplate(rowData, 'districtId'),
      hidden: !columns.selected.districtId,
    },
    {
      field: 'workroomId',
      header: 'Workroom',
      body: rowData => dragTemplate(rowData, 'workroomId'),
      hidden: !columns.selected.workroomId,
    },
  ];

  return (
    <div className="grid-content">
      <Panel
        headerTemplate={panelHeaderTemplate}
        pt={{
          content: { className: 'p-0' },
        }}
      >
        <DataTable
          value={readyToScheduleLoader ? loaderTable : readyToScheduleData.data}
          scrollable
          scrollHeight="400px"
          pt={{
            bodyrow: {
              className: 'ready_to_schedule_report',
            },
          }}
          {...(readyToScheduleLoader
            ? {}
            : {
                onRowDoubleClick: handleRecordClick,
                paginator: true,
                selectionMode: 'single',
                rows: readyToScheduleFilter.pageSettings?.limit,
                rowsPerPageOptions:
                  readyToScheduleFilter?.pageSettings?.pageSizes,
                totalRecords: readyToScheduleData.count,
                first: readyToScheduleFilter.pageSettings?.offset,
                paginatorTemplate:
                  'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown',
                currentPageReportTemplate:
                  'Showing {first} to {last} of {totalRecords} entries',
                lazy: true,
                onPage: args => {
                  handleSetReadyToScheduleFilter({
                    pageSettings: {
                      ...readyToScheduleFilter.pageSettings,
                      limit: args?.rows,
                      offset: args?.first,
                    },
                  });
                },
                onSort: args => {
                  handleSetReadyToScheduleFilter({
                    pageSettings: {
                      ...readyToScheduleFilter.pageSettings,
                      sortField: args.sortField,
                      sortOrder: args.sortOrder,
                    },
                  });
                },
                sortField: readyToScheduleFilter.pageSettings?.sortField,
                sortOrder: readyToScheduleFilter.pageSettings?.sortOrder,
              })}
        >
          {READY_TO_SCHEDULE_TABLE_COLUMNS.map((each, index) => (
            <Column {...each} {...columnProps} key={`${each.field}_${index}`} />
          ))}
        </DataTable>
      </Panel>
    </div>
  );
};

export default ReadyToScheduleReport;
