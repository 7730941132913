import { useState, useEffect, useRef } from 'react';
import { Skeleton } from 'primereact/skeleton';
import moment from 'moment-timezone';
import { DataTable, Column, Button } from 'primereact';
import { Link } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { Tooltip } from 'primereact/tooltip';

import ExportWidgetTableData from '../components/ExportWidgetTableData';
import WidgetRenderer from '../../Widget/components/WidgetRenderer';
import WidgetTableRenderer from '../../Widget/components/WidgetTableRenderer';
import {
  getChartQuery,
  getTableQuery,
  routingWidgetDefaultCondition,
} from '../../Widget/helpers/query';
import { getQueryResult } from '../../Widget/service/widget.service';
import { getLoginUserId } from '../../../../utils/Helpers';
import './DashboardWidgetRenderer.css';
import WidgetGroupedTableRenderer from '../../Widget/components/WidgetGroupedTableRenderer';
import {
  INVENTORY_ADJUSTMENT_OBJECT_ID,
  INVENTORY_ADJUSTMENT_PROJECT_OBJECT_ID,
  INVENTORY_ADJUSTMENT_TYPE,
  INVENTORY_ADJUSTMENT_TYPES,
  INVENTORY_ADJUSTMENT_TYPE_PROJECT,
  DASHBOARD_RETRY_LIMIT,
} from '../../Widget/constant/constant';
import {
  adjustmentDispatch,
  adjustmentUndoDispatch,
} from '../../../ProjectManagement/Inventory/services/adjustment.services';
import { getRedirectUrl } from '../Widgets/WidgetRoutes';

const DashboardWidgetRenderer = ({
  widgetDetails,
  filterDetails,
  homeFilters,
  applyFilters,
  clearFilters,
  homeFilterDateDimension,
  onClickHandler,
  type,
  dashboardDetails = {},
  setDisabledDashboard,
  setIsShowDelayPopUp = () => {},
}) => {
  const [tablePagination, setTablePagination] = useState({
    filters: null,
  });
  const [widgetTemplate, setWidgetTemplate] = useState(
    widgetDetails?.widget_template
  );
  const [paginator, setPaginator] = useState({
    first: 0,
    currentPage: 0,
    perPage: 10,
  });
  const [orderBy, setOrderBy] = useState({
    columns: '',
    direction: 1,
  });
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState(null);
  const [refreshWidget, setRefreshWidget] = useState(false);
  const [tabGrpDep, setTabGrpDep] = useState(false);
  const [dashboardLogDetails, SetDashboardLogDetails] = useState({});

  const widgetRetryCountRef = useRef(0);

  let chartQuery = null;
  if (widgetTemplate.widget_display_type[0] === 'table-report') {
    chartQuery = getTableQuery(widgetTemplate);
  } else {
    chartQuery = getChartQuery(widgetTemplate);
  }
  const toast = useRef(null);
  const [inventoryLoading, setInventoryLoading] = useState(false);
  const fetchData = async () => {
    try {
      setLoading(true);

      if ('user-filter' == widgetTemplate.widget_filter_type && filterDetails) {
        chartQuery.filters.push(filterDetails.userFilterCondition);
        dashboardLogDetails.filter_type = 'user-filter';
        dashboardLogDetails.filter_condition =
          filterDetails.userFilterCondition;
      } else if (
        'company-filter' == widgetTemplate.widget_filter_type &&
        filterDetails
      ) {
        chartQuery.filters.push(filterDetails.companyFilterCondition);
        dashboardLogDetails.filter_type = 'company-filter';
        dashboardLogDetails.filter_condition =
          filterDetails.companyFilterCondition;
      } else if (
        'assigned-to-self' == widgetTemplate.widget_filter_type &&
        filterDetails
      ) {
        chartQuery.filters.push({
          member: 'UserMeta.user_id',
          values: [getLoginUserId()],
          operator: 'equals',
        });
        dashboardLogDetails.filter_type = 'assigned-to-self';
        dashboardLogDetails.filter_condition = [
          {
            member: 'UserMeta.user_id',
            values: [getLoginUserId()],
            operator: 'equals',
          },
        ];
      } else if (
        'company-and-assigned' == widgetTemplate.widget_filter_type &&
        filterDetails
      ) {
        chartQuery.filters.push({
          member: 'UserMeta.user_id',
          values:
            filterDetails?.companyUsers?.length > 0
              ? filterDetails?.companyUsers
              : ['0'],
          operator: 'equals',
        });
        dashboardLogDetails.filter_type = 'company-and-assigned';
        dashboardLogDetails.filter_condition = [
          {
            member: 'UserMeta.user_id',
            values:
              filterDetails?.companyUsers?.length > 0
                ? filterDetails?.companyUsers
                : ['0'],
            operator: 'equals',
          },
        ];
      } else if (
        'user-and-assigned-to-self' == widgetTemplate.widget_filter_type &&
        filterDetails
      ) {
        const userAndAssignToSelfFilter = {
          or: [
            {
              member: 'UserMeta.user_id',
              values: [getLoginUserId()],
              operator: 'equals',
            },
          ],
        };
        dashboardLogDetails.filter_type = 'user-and-assigned-to-self';
        if (filterDetails.userFilterCondition) {
          userAndAssignToSelfFilter.or.push(filterDetails.userFilterCondition);
        }
        chartQuery.filters.push(userAndAssignToSelfFilter);
        dashboardLogDetails.filter_condition = userAndAssignToSelfFilter;
      }
      dashboardLogDetails.globalFilters = [];
      if (applyFilters && homeFilters.length) {
        homeFilters.forEach(item =>
          dashboardLogDetails.globalFilters.push(item.member)
        );
        chartQuery.filters.push(...homeFilters);
      }
      if (applyFilters && homeFilterDateDimension?.length) {
        homeFilterDateDimension.forEach(item =>
          dashboardLogDetails.globalFilters.push(item.dimension)
        );
        homeFilterDateDimension.forEach(timeRecord => {
          chartQuery?.timeDimensions?.push({
            dimension: timeRecord.dimension,
            dateRange: timeRecord.dateRange,
          });
        });
      }
      if (widgetTemplate?.route_widget) {
        chartQuery.filters.push(routingWidgetDefaultCondition());
      }

      dashboardLogDetails.chartQuery = chartQuery;
      dashboardLogDetails.widget_name = widgetTemplate.widget_name;
      dashboardLogDetails.widget_template_id =
        widgetTemplate.widget_template_id;
      dashboardLogDetails.widget_type = widgetTemplate.widget_display_type[0];
      dashboardLogDetails.widget_retry_count = widgetRetryCountRef.current;
      SetDashboardLogDetails({ ...dashboardLogDetails });
      const response = await getQueryResult(
        chartQuery,
        widgetDetails.widget_id
      );
      if (
        !response ||
        (response?.error &&
          response.error.includes('wait') &&
          widgetRetryCountRef?.current < DASHBOARD_RETRY_LIMIT)
      ) {
        setTimeout(() => {
          if (widgetRetryCountRef?.current < DASHBOARD_RETRY_LIMIT) {
            setRefreshWidget(!refreshWidget);
            widgetRetryCountRef.current =
              (widgetRetryCountRef?.current || 0) + 1;
          }
        }, 700);
      }
      setChartData(response);
      setLoading(false);
    } catch (error) {
      setLoading(true);
      // retry for widget in case got failed it retry count is less than DASHBOARD_RETRY_LIMIT
      if (widgetRetryCountRef?.current < DASHBOARD_RETRY_LIMIT) {
        setTimeout(() => {
          widgetRetryCountRef.current = (widgetRetryCountRef?.current || 0) + 1;
        }, 700);
      }
      console.error(error);
    }
  };

  const fetchTableData = async (
    params = tablePagination,
    orderByConfiguration = orderBy,
    paginatorConfiguration = paginator
  ) => {
    setTabGrpDep(!tabGrpDep);
    if ('user-filter' == widgetTemplate.widget_filter_type && filterDetails) {
      chartQuery.filters.push(filterDetails.userFilterCondition);
      dashboardLogDetails.filter_type = 'user-filter';
      dashboardLogDetails.filter_condition = filterDetails.userFilterCondition;
    } else if (
      'company-filter' == widgetTemplate.widget_filter_type &&
      filterDetails
    ) {
      chartQuery.filters.push(filterDetails.companyFilterCondition);
      dashboardLogDetails.filter_type = 'company-filter';
      dashboardLogDetails.filter_condition =
        filterDetails.companyFilterCondition;
    } else if (
      'assigned-to-self' == widgetTemplate.widget_filter_type &&
      filterDetails
    ) {
      chartQuery.filters.push({
        member: 'UserMeta.user_id',
        values: [getLoginUserId()],
        operator: 'equals',
      });
      dashboardLogDetails.filter_type = 'assigned-to-self';
      dashboardLogDetails.filter_condition = [
        {
          member: 'UserMeta.user_id',
          values: [getLoginUserId()],
          operator: 'equals',
        },
      ];
    } else if (
      'company-and-assigned' == widgetTemplate.widget_filter_type &&
      filterDetails
    ) {
      chartQuery.filters.push({
        member: 'UserMeta.user_id',
        values:
          filterDetails?.companyUsers?.length > 0
            ? filterDetails?.companyUsers
            : ['0'],
        operator: 'equals',
      });
      dashboardLogDetails.filter_type = 'company-and-assigned';
      dashboardLogDetails.filter_condition = [
        {
          member: 'UserMeta.user_id',
          values:
            filterDetails?.companyUsers?.length > 0
              ? filterDetails?.companyUsers
              : ['0'],
          operator: 'equals',
        },
      ];
    } else if (
      'user-and-assigned-to-self' == widgetTemplate.widget_filter_type &&
      filterDetails
    ) {
      const userAndAssignToSelfFilter = {
        or: [
          {
            member: 'UserMeta.user_id',
            values: [getLoginUserId()],
            operator: 'equals',
          },
        ],
      };
      dashboardLogDetails.filter_type = 'user-and-assigned-to-self';
      if (filterDetails.userFilterCondition) {
        userAndAssignToSelfFilter.or.push(filterDetails.userFilterCondition);
      }
      chartQuery.filters.push(userAndAssignToSelfFilter);
      dashboardLogDetails.filter_condition = userAndAssignToSelfFilter;
    }
    dashboardLogDetails.globalFilters = [];
    if (applyFilters && homeFilters.length) {
      homeFilters.forEach(item =>
        dashboardLogDetails.globalFilters.push(item.member)
      );
      chartQuery.filters.push(...homeFilters);
    }
    if (applyFilters && homeFilterDateDimension?.length) {
      homeFilterDateDimension.forEach(item =>
        dashboardLogDetails.globalFilters.push(item.dimension)
      );
      homeFilterDateDimension.forEach(timeRecord => {
        chartQuery?.timeDimensions?.push({
          dimension: timeRecord.dimension,
          dateRange: timeRecord.dateRange,
        });
      });
    }
    if (orderByConfiguration?.columns && orderByConfiguration?.direction) {
      chartQuery.order = [
        [
          orderByConfiguration?.columns,
          orderByConfiguration?.direction > 0 ? 'asc' : 'desc',
        ],
      ];
    }
    chartQuery.limit = paginatorConfiguration.perPage;
    chartQuery.offset =
      paginatorConfiguration.currentPage * paginatorConfiguration.perPage;
    if (params.filters && Object.keys(params.filters)?.length) {
      const tempFilters = [];
      const tempTimeDimension = [];
      Object.keys(params.filters).forEach(key => {
        if (chartData?.annotation?.dimensions?.[key]?.type == 'time') {
          if (params?.filters?.[key]) {
            params.filters?.[key].constraints.forEach(filterKey => {
              if (filterKey?.value && filterKey.value?.length) {
                tempTimeDimension.push({
                  dimension: key,
                  dateRange: [
                    moment(filterKey.value[0]).format('Y-M-D'),
                    moment(filterKey.value[1]).format('Y-M-D'),
                  ],
                });
              }
            });
          }
        } else if (params.filters?.[key]) {
          params.filters?.[key].constraints.forEach(filterKey => {
            if (filterKey.value) {
              tempFilters.push({
                member: key,
                values: [filterKey.value?.toString()],
                operator: filterKey.matchMode,
              });
            }
          });
        }
      });
      if (tempFilters.length) {
        chartQuery.filters.push(...tempFilters);
      }
      if (tempTimeDimension) {
        chartQuery.timeDimensions.push(...tempTimeDimension);
      }
    }

    if (
      widgetTemplate?.custom_properties?.reportTablePrimaryKey &&
      !widgetTemplate?.report_group_by
    ) {
      chartQuery.dimensions.push(
        widgetTemplate?.custom_properties?.reportTablePrimaryKey
      );
    }

    const isHavingProjectAdjustmentType =
      widgetTemplate?.table_configuration?.some(
        item => item?.measureName === INVENTORY_ADJUSTMENT_TYPE_PROJECT
      );

    if (isHavingProjectAdjustmentType) {
      chartQuery.dimensions.push(INVENTORY_ADJUSTMENT_PROJECT_OBJECT_ID);
    }

    const isHavingInventoryAdjustmentType =
      widgetTemplate?.table_configuration?.some(
        item => item?.measureName === INVENTORY_ADJUSTMENT_TYPE
      );

    if (isHavingInventoryAdjustmentType) {
      chartQuery.dimensions.push(INVENTORY_ADJUSTMENT_OBJECT_ID);
    }
    if (widgetTemplate?.custom_properties?.reportTableSecondaryKey) {
      chartQuery.dimensions.push(
        widgetTemplate?.custom_properties?.reportTableSecondaryKey
      );
    }
    dashboardLogDetails.chartQuery = chartQuery;
    dashboardLogDetails.widget_name = widgetTemplate.widget_name;
    dashboardLogDetails.widget_template_id = widgetTemplate.widget_template_id;
    dashboardLogDetails.widget_type = widgetTemplate.widget_display_type[0];
    SetDashboardLogDetails({ ...dashboardLogDetails });
    chartQuery.total = true;
    const response = await getQueryResult(chartQuery);
    setChartData(response);
    setTotalCount(response?.total);
  };

  useEffect(() => {
    if (filterDetails) {
      if (widgetTemplate.widget_display_type[0] == 'table-report') {
        setLoading(true);
        fetchTableData(tablePagination, orderBy, paginator).then(() => {
          setLoading(false);
        });
      } else {
        fetchData();
      }
    }
  }, [refreshWidget, paginator, orderBy, widgetTemplate, filterDetails]);

  useEffect(() => {
    if (widgetRetryCountRef?.current >= DASHBOARD_RETRY_LIMIT) {
      if (setDisabledDashboard) setDisabledDashboard(true);
      setIsShowDelayPopUp(true);
    }
  }, [widgetRetryCountRef?.current]);

  const refreshWidgetHandler = () => {
    setRefreshWidget(!refreshWidget);
  };
  const onTableChangeHandler = params => {
    setTablePagination(params);
    fetchTableData(params, orderBy);
  };

  useEffect(() => {
    if (applyFilters) {
      if (widgetTemplate.widget_display_type[0] == 'table-report') {
        setLoading(true);
        Promise.all([fetchTableData(tablePagination, orderBy)]).then(() => {
          setLoading(false);
        });
      } else {
        fetchData();
      }
    }
    if (clearFilters) {
      if (widgetTemplate.widget_display_type[0] == 'table-report') {
        setLoading(true);
        Promise.all([fetchTableData(tablePagination, orderBy)]).then(() => {
          setLoading(false);
        });
      } else {
        fetchData();
      }
    }
  }, [applyFilters, clearFilters]);

  let excelData = [];
  let headingKeys = [];
  let headings = [];
  const tableConfiguration = [];
  widgetTemplate.table_configuration.forEach(record => {
    if (!headingKeys.includes(record.measureName)) {
      headingKeys.push(record.measureName);
      tableConfiguration.push(record);
    }
    if (!headings.includes(record.alice)) headings.push(record.alice);
  });
  excelData.push(headings);
  chartData?.data?.forEach(record => {
    const excelRecord = [];
    headingKeys.forEach(key => {
      const formatter = chartData?.annotation?.dimensions[key]?.meta?.format
        ? eval(chartData?.annotation?.dimensions[key]?.meta?.format)
        : undefined;
      if (formatter) {
        excelRecord.push(formatter(record[key]));
      } else {
        excelRecord.push(record[key]);
      }
    });
    excelData.push(excelRecord);
  });
  const chartLoader = chart_type => {
    if (['pie-chart', 'gauge', 'card'].includes(chart_type)) {
      if (chart_type === 'card') {
        let element = document.getElementById(widgetDetails.widget_id);
        if (element) {
          element.style.border = '1px Solid  var(--primary-color)';
          element.style.background = 'none';
        }
        return <Skeleton width="12rem" height="2rem"></Skeleton>;
      } else {
        return <Skeleton shape="circle" size="7.5rem"></Skeleton>;
      }
    } else if (['line-chart', 'bar-chart'].includes(chart_type)) {
      return (
        <div className="skeleton-class align-items-end">
          <Skeleton width="22%" height="100%" className="mr-1"></Skeleton>
          <Skeleton width="22%" height="70%" className="mr-1"></Skeleton>
          <Skeleton width="22%" height="45%" className="mr-1"></Skeleton>
          <Skeleton width="22%" height="24%" className="mr-1"></Skeleton>
        </div>
      );
    } else if (chart_type === 'table-report') {
      const items = Array.from({ length: 5 }, (v, i) => i);

      const bodyTemplate = () => {
        return <Skeleton></Skeleton>;
      };
      return (
        <DataTable
          value={items}
          className="p-datatable-striped"
          style={{ width: '100%' }}
        >
          <Column className="w-3" body={bodyTemplate}></Column>
          <Column className="w-3" body={bodyTemplate}></Column>
          <Column className="w-3" body={bodyTemplate}></Column>
          <Column className="w-3" body={bodyTemplate}></Column>
        </DataTable>
      );
    }
  };

  const handleInventoryDispatch = async id => {
    try {
      setInventoryLoading(true);
      const dispatchResponse = await adjustmentDispatch(id);
      if (dispatchResponse?.status) {
        toast.current.show({
          severity: 'success',
          summary: 'Success!',
          detail: 'Adjustment Updated successfully',
          life: 3000,
        });
        setRefreshWidget(!refreshWidget);
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Something went wrong.',
          life: 2000,
        });
      }
    } catch (error) {
      console.error(error);
      toast.current.show({
        severity: 'error',
        summary: 'Something went wrong.',
        life: 2000,
      });
    } finally {
      setInventoryLoading(false);
    }
  };

  const handleInventoryUndoDispatch = async id => {
    try {
      setInventoryLoading(true);
      const undoDispatchResponse = await adjustmentUndoDispatch(id);
      if (undoDispatchResponse?.status) {
        toast.current.show({
          severity: 'success',
          summary: 'Success!',
          detail: 'Adjustment Updated successfully',
          life: 3000,
        });
        setRefreshWidget(!refreshWidget);
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Something went wrong.',
          life: 2000,
        });
      }
    } catch (error) {
      console.error(error);
      toast.current.show({
        severity: 'error',
        summary: 'Something went wrong.',
        life: 2000,
      });
    } finally {
      setInventoryLoading(false);
    }
  };

  const actionButtonsColumn = {
    alice: '',
    measureName: '',
    columnTypeAction: true,
    width: '10%',
    options: {
      sort: false,
      filter: false,

      customBodyRenderLite: dataIndex => {
        let primaryKeyColumn = '';
        let secondaryKeyColumn = '';
        Object.keys(chartData?.annotation?.dimensions)?.forEach(key => {
          if (chartData?.annotation.dimensions[key]?.meta?.primaryKey) {
            primaryKeyColumn = key;
          }
          if (chartData?.annotation?.dimensions[key]?.meta?.secondaryKey) {
            secondaryKeyColumn = key;
          }
        });
        let redirectDetails = null;
        if (primaryKeyColumn) {
          const tableName = primaryKeyColumn?.split('.')[0];
          const id = dataIndex[primaryKeyColumn];
          const secondaryObject = {
            secondaryTable: secondaryKeyColumn?.split('.')[0],
            secondaryId: dataIndex[secondaryKeyColumn],
          };
          redirectDetails = getRedirectUrl(tableName, id, { secondaryObject });
        } else {
          return null;
        }
        const isProjectAllocatedAdjustmentType =
          dataIndex?.[INVENTORY_ADJUSTMENT_TYPE_PROJECT] ===
          INVENTORY_ADJUSTMENT_TYPES.ALLOCATED;
        const isProjectDispatchedAdjustmentType =
          dataIndex?.[INVENTORY_ADJUSTMENT_TYPE_PROJECT] ===
          INVENTORY_ADJUSTMENT_TYPES.DISPATCHED;
        const isInventoryAllocatedAdjustmentType =
          dataIndex?.[INVENTORY_ADJUSTMENT_TYPE] ===
          INVENTORY_ADJUSTMENT_TYPES.ALLOCATED;
        const isInventoryDispatchedAdjustmentType =
          dataIndex?.[INVENTORY_ADJUSTMENT_TYPE] ===
          INVENTORY_ADJUSTMENT_TYPES.DISPATCHED;

        if (isInventoryAllocatedAdjustmentType) {
          const adjustment_object_id =
            dataIndex?.[INVENTORY_ADJUSTMENT_OBJECT_ID];
          return (
            <div className="flex justify-content-center gap-2 m-2">
              <Button
                className="border-none p-button-primary"
                icon="pi pi-refresh"
                text
                outlined
                severity="primary"
                tooltip="Dispatch"
                onClick={() => handleInventoryDispatch(adjustment_object_id)}
                disabled={inventoryLoading}
              ></Button>
            </div>
          );
        }
        if (isInventoryDispatchedAdjustmentType) {
          const adjustment_object_id =
            dataIndex?.[INVENTORY_ADJUSTMENT_OBJECT_ID];
          return (
            <div className="flex justify-content-center gap-2 m-2">
              <Button
                className="border-none p-button-primary"
                icon="pi pi-undo"
                text
                outlined
                severity="primary"
                tooltip="Return to Inventory"
                onClick={() =>
                  handleInventoryUndoDispatch(adjustment_object_id)
                }
                disabled={inventoryLoading}
              ></Button>
            </div>
          );
        }
        if (!redirectDetails) return null;
        return (
          <div className="flex  justify-content-center gap-2 m-2">
            {redirectDetails?.viewUrl && (
              <Link to={redirectDetails.viewUrl} className="text-color-900">
                <i className="pi pi-eye"></i>
              </Link>
            )}
            {redirectDetails?.editUrl && (
              <Link to={redirectDetails.editUrl} className="text-color-900">
                <i className="pi pi-pencil"></i>
              </Link>
            )}
            {isProjectAllocatedAdjustmentType && (
              <>
                <Tooltip target=".dispatch-icon" />
                <i
                  className="pi pi-refresh cursor-pointer text-color-900 dispatch-icon"
                  role="button"
                  tabIndex="0"
                  onClick={() =>
                    handleInventoryDispatch(
                      dataIndex?.[INVENTORY_ADJUSTMENT_PROJECT_OBJECT_ID]
                    )
                  }
                  onKeyDown={e => {
                    if (e?.key === 'Enter' || e?.key === ' ') {
                      handleInventoryDispatch(
                        dataIndex?.[INVENTORY_ADJUSTMENT_PROJECT_OBJECT_ID]
                      );
                    }
                  }}
                  data-pr-tooltip="Dispatch"
                  data-pr-position="top"
                ></i>
              </>
            )}
            {isProjectDispatchedAdjustmentType && (
              <>
                <Tooltip target=".undo-dispatch-icon" />
                <i
                  className="pi pi-undo cursor-pointer text-color-900 undo-dispatch-icon"
                  role="button"
                  tabIndex="0"
                  onClick={() =>
                    handleInventoryUndoDispatch(
                      dataIndex?.[INVENTORY_ADJUSTMENT_PROJECT_OBJECT_ID]
                    )
                  }
                  onKeyDown={e => {
                    if (e?.key === 'Enter' || e?.key === ' ') {
                      handleInventoryUndoDispatch(
                        dataIndex?.[INVENTORY_ADJUSTMENT_PROJECT_OBJECT_ID]
                      );
                    }
                  }}
                  data-pr-tooltip="Return to Inventory"
                  data-pr-position="top"
                ></i>
              </>
            )}
          </div>
        );
      },
    },
  };
  tableConfiguration.unshift(actionButtonsColumn);
  const handleGroupByExport = () => {
    let widget = structuredClone(widgetTemplate);
    if (widget.backToGroupBy) {
      widget.table_configuration = widget.backToGroupBy.table_configuration;
      widget.report_group_by = widget.backToGroupBy.report_group_by;
      delete widget.backToGroupBy;
    } else {
      widget.backToGroupBy = {
        table_configuration: [...widget.table_configuration],
        report_group_by: widget.report_group_by,
      };
      widget.table_configuration.unshift({
        alice: widget?.report_group_by?.alice,
        measureName: widget?.report_group_by?.dimension,
      });
      delete widget.report_group_by;
    }
    setWidgetTemplate(widget);
  };

  return (
    <>
      {type == 'home' && (
        <div className="excel-export-container">
          <div className="excel-export-wrapper">
            {widgetDetails.chart_type == 'table-report' && (
              <div
                className={`mr-3 ${widgetDetails.chart_type == 'table-report' ? 'pt-3' : null}`}
              >
                <ExportWidgetTableData
                  widget={widgetTemplate}
                  groupByHandler={handleGroupByExport}
                  exportData={excelData}
                  filename={`${widgetTemplate?.widget_display_type[0]}_${
                    paginator?.currentPage + 1
                  }_${moment().format('Y_M_D')}`}
                />
              </div>
            )}
            {widgetTemplate?.widget_display_type?.[0] !==
              'external-link-button' && (
              <i
                className={`pi pi-refresh text-sm mr-1 ${
                  widgetDetails.chart_type == 'table-report' ? 'pt-3' : null
                }`}
                role="button"
                tabIndex="0"
                onClick={refreshWidgetHandler}
                onKeyDown={e => {
                  if (e?.key === 'Enter' || e?.key === ' ') {
                    refreshWidgetHandler();
                  }
                }}
              ></i>
            )}
          </div>
        </div>
      )}
      <div
        style={{
          width: '100%',
          maxHeight: widgetDetails?.chart_type === 'table-report' && '88%',
          overflow: widgetDetails?.chart_type === 'table-report' && 'auto',
        }}
      >
        {loading ? (
          <div className="chart-loader">
            {chartLoader(widgetTemplate?.widget_display_type?.[0])}
          </div>
        ) : widgetDetails.chart_type == 'table-report' ? (
          widgetTemplate?.report_group_by ? (
            <WidgetGroupedTableRenderer
              widgetDetails={widgetTemplate}
              data={chartData?.data}
              tablePagination={tablePagination}
              fetchTableData={fetchTableData}
              annotation={chartData?.annotation?.dimensions}
              totalCount={totalCount}
              orderBy={orderBy}
              setOrderBy={setOrderBy}
              paginator={paginator}
              setPaginator={setPaginator}
              filters={homeFilters}
              homeFilterDateDimension={homeFilterDateDimension}
              tabGrpDep={tabGrpDep}
              filterDetails={filterDetails}
            />
          ) : (
            <WidgetTableRenderer
              table_configuration={tableConfiguration}
              data={chartData?.data}
              tablePagination={tablePagination}
              onTableChangeHandler={onTableChangeHandler}
              annotation={chartData?.annotation?.dimensions}
              totalCount={totalCount}
              orderBy={orderBy}
              setOrderBy={setOrderBy}
              paginator={paginator}
              setPaginator={setPaginator}
              dashboardLogDetails={dashboardLogDetails}
              dashboardDetails={dashboardDetails}
            />
          )
        ) : (
          <div className="px-3 flex justify-content-center">
            <WidgetRenderer
              widget_type={widgetTemplate?.widget_display_type?.[0]}
              cubeResponse={chartData?.data}
              target={widgetTemplate?.chart_configuration?.measureTarget}
              annotation={chartData?.annotation}
              formikData={widgetTemplate}
              onClickHandler={onClickHandler}
              widgetId={widgetDetails.widget_id}
              showButtonPreview={false}
              dashboardLogDetails={dashboardLogDetails}
              dashboardDetails={dashboardDetails}
            />
          </div>
        )}
      </div>
      <Toast ref={toast} />
    </>
  );
};

export default DashboardWidgetRenderer;
