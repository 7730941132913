import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { PROJECT_REPORT } from '../../../constants';
import PFButton from '../../CRM/PFPrime/PFButton';
import SkeletonLoader from '../../shared/Loader/skeleton';
import { setSelectedViewMore } from '../../../redux/slices/scheduler-view-more-event.slice';
import { setFilterTechinicianView } from '../../../redux/slices/scheduler-technician-view.slice';

const SchedulerTabs = ({
  buttonHandler,
  initialLoader,
  view,
  isScheduler,
  isFiltersSelected,
}) => {
  const dispatch = useDispatch();
  const selectedViewMore = JSON.parse(localStorage.getItem('view-more-jobs'));
  const count = selectedViewMore || 1;

  useEffect(() => {
    localStorage.setItem('view-more-jobs', JSON.stringify(count));
    dispatch(setSelectedViewMore(JSON.stringify(count)));
  }, [count]);

  useEffect(() => {
    dispatch(
      setFilterTechinicianView({
        isTechnicialFilterApplied: false,
      })
    );
  }, []);

  return (
    <>
      <div className={`grid w-12 py-2 grid-nogutter`}>
        <div className="col-12 lg:col-3 flex align-items-center pl-0"></div>

        <>
          <div className="col-12 lg:col-6 mt-2 flex flex-wrap align-items-center sm:justify-content-center md:mt-0 calendar-head">
            {initialLoader ? (
              <div className="flex gap-2">
                <SkeletonLoader
                  columnCount={1}
                  columnHeight="2.5rem"
                  columnWidth="6rem"
                />
                <SkeletonLoader
                  columnCount={1}
                  columnHeight="2.5rem"
                  columnWidth="7rem"
                />
                {isScheduler && (
                  <>
                    <SkeletonLoader
                      columnCount={1}
                      columnHeight="2.5rem"
                      columnWidth="4rem"
                    />
                    <SkeletonLoader
                      columnCount={1}
                      columnHeight="2.5rem"
                      columnWidth="5rem"
                    />
                  </>
                )}
              </div>
            ) : (
              <>
                <>
                  {isFiltersSelected ? (
                    <>
                      <PFButton
                        outlined={
                          view === PROJECT_REPORT.FULL_CALENDAR ? false : true
                        }
                        severity="primary"
                        onClick={() =>
                          buttonHandler(PROJECT_REPORT.FULL_CALENDAR)
                        }
                        label="Calendar"
                      />

                      <PFButton
                        outlined={
                          view === PROJECT_REPORT.CALENDAR ? false : true
                        }
                        severity="primary"
                        onClick={() => buttonHandler(PROJECT_REPORT.CALENDAR)}
                        label="Technician View"
                      />
                    </>
                  ) : null}
                  {isScheduler && isFiltersSelected && (
                    <>
                      <PFButton
                        outlined={view === PROJECT_REPORT.MAP ? false : true}
                        severity="primary"
                        onClick={() => buttonHandler(PROJECT_REPORT.MAP)}
                        label="Map"
                      />
                      <PFButton
                        severity="primary"
                        outlined={view === PROJECT_REPORT.SPLIT ? false : true}
                        onClick={() => buttonHandler(PROJECT_REPORT.SPLIT)}
                        label="Split View"
                      />
                    </>
                  )}
                </>
              </>
            )}
          </div>
        </>
      </div>
    </>
  );
};

export default SchedulerTabs;
