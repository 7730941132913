import { createSlice } from '@reduxjs/toolkit';

const widgetFilter = JSON.parse(localStorage.getItem('widget-filter'));

const initialState = {
  activeDashboard: widgetFilter?.activeDashboard || null,
  activeWidget: widgetFilter?.activeWidget || null,
  widgetDetails: widgetFilter?.widgetDetails || {},
  tabs: null,
  clientId: '',
  sideDrawer: '',
  callNotification: false,
};

const pageHeaderSlice = createSlice({
  name: 'pageHeader',
  initialState: initialState,
  reducers: {
    setPageHeaderComponent(state, { payload }) {
      if (payload?.activeWidget?.widgetTemplate?.widget_template_id) {
        state.widgetDetails = {
          ...state.widgetDetails,
          [payload.activeWidget.widgetTemplate.widget_template_id]: {
            activeDashboard: Object.prototype.hasOwnProperty.call(
              payload,
              'activeDashboard'
            )
              ? payload.activeDashboard
              : state.activeDashboard,
            activeWidget: Object.prototype.hasOwnProperty.call(
              payload,
              'activeWidget'
            )
              ? payload.activeWidget
              : state.activeWidget,
          },
        };
      }
      state.activeDashboard = Object.prototype.hasOwnProperty.call(
        payload,
        'activeDashboard'
      )
        ? payload.activeDashboard
        : state.activeDashboard;
      state.activeWidget = Object.prototype.hasOwnProperty.call(
        payload,
        'activeWidget'
      )
        ? payload.activeWidget
        : state.activeWidget;
      localStorage.setItem('widget-filter', JSON.stringify(state));
    },
    setTabs(state, { payload }) {
      return { ...state, tabs: payload };
    },
    setClientId(state, { payload }) {
      return { ...state, clientId: payload };
    },
    setSideDrawer(state, { payload }) {
      return { ...state, sideDrawer: payload };
    },
    setCallNotification(state, { payload }) {
      return { ...state, callNotification: payload };
    },
  },
});

export const {
  setPageHeaderComponent,
  setTabs,
  setClientId,
  setSideDrawer,
  setCallNotification,
} = pageHeaderSlice.actions;

export const pageHeaderReducer = pageHeaderSlice.reducer;
