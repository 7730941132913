import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Checkbox } from 'primereact/checkbox';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';

import SkeletonLoader from '../../../../shared/Loader/skeleton';
import { setAssignWidgets } from '../../../../../redux/slices/assign-widgets.slice';
import { assignWidgetsValidate } from '../WidgetTemplate.service';
import {
  getCurrentClientId,
  getLoginUserId,
  toTitleCase,
} from '../../../../../utils/Helpers';
import { authURL } from '../../../../../config/variables';

const AssignWidgetsValidate = () => {
  const {
    isAssignWidgetValidateDialogVisible,
    isClientsListLoading,
    clientsList,
    selectedClients,
    selectedWidgets,
  } = useSelector(state => state.assignWidgets);
  const dispatch = useDispatch();
  const [userId, setUserId] = useState(getLoginUserId());
  const [currentClientId, setCurrentClientId] = useState(getCurrentClientId());

  const fetchInitiationData = async () => {
    setUserId(getLoginUserId());
    setCurrentClientId(getCurrentClientId());
    if (isClientsListLoading === false) {
      dispatch(
        setAssignWidgets({
          isClientsListLoading: true,
        })
      );
    }
    try {
      const clientsResponse = await axios.get(
        `${authURL}/get-all-user-tenants?user_id=${userId}`
      );
      const clients = clientsResponse?.data
        ?.map(client => {
          return { ...client, client_name: toTitleCase(client?.client_name) };
        })
        ?.filter(
          client =>
            client?.client_id?.toLowerCase() !== currentClientId?.toLowerCase()
        )
        .sort((a, b) => {
          return a.client_name.localeCompare(b.client_name);
        });
      dispatch(
        setAssignWidgets({
          clientsList: clients,
        })
      );
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(
        setAssignWidgets({
          isClientsListLoading: false,
        })
      );
    }
  };

  useEffect(async () => {
    await fetchInitiationData();
  }, []);

  const handleAssignWidgetsSave = async () => {
    try {
      dispatch(
        setAssignWidgets({
          isClientsListLoading: true,
        })
      );
      const validateResponse = await assignWidgetsValidate({
        clients: selectedClients,
        widgets: selectedWidgets,
      });
      const mapTableData = validateResponse?.map((row, index) => {
        return {
          clientId: row.clientId,
          tenantName: row.tenantName,
          widgetName: row.widgetName,
          widgetReferenceId: row.widgetReferenceId,
          operation: row.operation,
          rowIndex: index,
          action:
            row.operation?.toLowerCase() === 'existing' ? 'override' : 'create',
        };
      });
      dispatch(
        setAssignWidgets({
          isAssignWidgetDialogVisible: true,
          isAssignWidgetValidateDialogVisible: false,
          validateTableData: mapTableData,
        })
      );
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(
        setAssignWidgets({
          isClientsListLoading: false,
        })
      );
    }
  };

  const handleHide = () => {
    dispatch(
      setAssignWidgets({
        isAssignWidgetValidateDialogVisible: false,
        isAssignWidgetDialogVisible: false,
        selectedClients: [],
      })
    );
  };

  const AssignWidgetsFooter = (
    <div>
      <Button
        label="Cancel"
        icon="pi pi-times"
        onClick={handleHide}
        severity="danger"
        outlined
      />
      <Button
        label="Validate"
        icon="pi pi-check"
        onClick={handleAssignWidgetsSave}
        disabled={selectedClients?.length === 0}
        outlined
      />
    </div>
  );

  const onClientCheck = e => {
    let checkedClients = [...selectedClients];
    if (e.checked) {
      checkedClients.push(e.value);
    } else {
      checkedClients = checkedClients.filter(
        client => client?.client_id !== e.value?.client_id
      );
    }
    dispatch(setAssignWidgets({ selectedClients: checkedClients }));
  };

  return (
    <Dialog
      header="Assign Widgets to Tenants"
      visible={isAssignWidgetValidateDialogVisible}
      style={{ width: '80vw' }}
      footer={AssignWidgetsFooter}
    >
      {isClientsListLoading ? (
        <SkeletonLoader columnCount={20} columnWidth="24%" />
      ) : (
        <div className="flex flex-column">
          <div className="grid">
            {clientsList?.map(client => {
              return (
                <div
                  key={client?.client_id}
                  className="col-3 white-space-nowrap overflow-hidden text-overflow-ellipsis"
                >
                  <Checkbox
                    inputId={client?.client_id}
                    name="Client List"
                    value={client}
                    onChange={onClientCheck}
                    checked={
                      selectedClients &&
                      selectedClients?.some(
                        item => item.client_id === client?.client_id
                      )
                    }
                  />
                  <label
                    htmlFor={client?.client_id}
                    className="ml-2 white-space-nowrap overflow-hidden text-overflow-ellipsis"
                  >
                    {client?.client_name}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </Dialog>
  );
};

export default AssignWidgetsValidate;
