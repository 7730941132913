import axios from 'axios';

import pageMetaConstant from '../constants/PageMeta.constants';
import apiService from '../../../services/api.service';

export const getPageMetaInfoBySlug = async identifier => {
  const response = await apiService.post(
    `/${pageMetaConstant.getPageMetaEndpoint()}`,
    identifier
  );

  return response;
};

export const getPageMetaUpdateTable = async (first, limit) => {
  const response = await apiService.get(
    `/${pageMetaConstant.getPageMetaUpdatesEndpoint(first, limit)}`
  );
  return response;
};

export const putEditPageMetaInfo = async data => {
  const response = await axios.put(
    `${pageMetaConstant.baseUrl}/${pageMetaConstant.putEditPageMetaEndpoint()}`,
    data
  );
  return response;
};
