import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: true,
  crewListData: [],
  crewMemberList: [],
};

const userFormCrewSlice = createSlice({
  name: 'userFormCrew',
  initialState: initialState,
  reducers: {
    setUserCrewForm(state, { payload }) {
      state.isLoading = Object.prototype.hasOwnProperty.call(
        payload,
        'isLoading'
      )
        ? payload.isLoading
        : state.isLoading;
      state.crewListData = Object.prototype.hasOwnProperty.call(
        payload,
        'crewListData'
      )
        ? payload.crewListData
        : state.crewListData;
      state.crewMemberList = Object.prototype.hasOwnProperty.call(
        payload,
        'crewMemberList'
      )
        ? payload.crewMemberList
        : state.crewMemberList;
    },
  },
});

export const { setUserCrewForm } = userFormCrewSlice.actions;

export const userFormCrewReducer = userFormCrewSlice.reducer;
