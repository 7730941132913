import React, { useState, useCallback, useEffect, memo } from 'react';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { Card } from 'primereact/card';
import { ProgressBar } from 'primereact/progressbar';

import PFDropdown from '../shared/PFPrime/PFDropdown';
import PageHeader from '../shared/PageHeader/PageHeader';
import PFButton from '../shared/PFPrime/PFButton';
import { formatDateTimeMDYHM } from '../../utils/Helpers';
import { csvUploadConfig } from '../../constants';
import { URL_CONSTANTS } from '../../constants/urlConstants';
import apiService from '../../services/api.service';
import usePFDataTable from '../../hooks/PFDatatable';
import SkeletonLoader from '../shared/Loader/skeleton';

const ImportData = memo(() => {
  const { ERROR_MESSAGES, PF_CSV_UPLOAD_SIZE } = csvUploadConfig;
  const { ROUTES_PATH_CSV_UPLOAD: ROUTES_PATH } = URL_CONSTANTS;

  const {
    layout: TableLayout,
    columns: DataTableColumns,
    totalRecords,
    data: tableData,

    setDefaultTableProps,
  } = usePFDataTable();
  const fileUploadRef = React.useRef(null);
  const [progress, setProgress] = useState(0);
  const [uploadedRecords, setUploadedRecords] = useState([]);

  const toast = React.useRef(null);
  const [uploadBtnEnabled, setUploadBtnEnabled] = useState(false);
  const [tableLoading, setTableLoading] = useState(true);
  const [formPayload, setFormPayload] = useState({
    upload_type: '',
    file: '',
  });
  const [items, setItems] = useState([]);

  const getUploadedCsv = async () => {
    setTableLoading(true);
    const { status, data } = await apiService.get(
      ROUTES_PATH.GET_UPLOADED_DATA_URL
    );

    if (status) {
      const records = data.reverse();
      totalRecords(records.length || 0);
      tableData(records);
      setUploadedRecords(records);
      setTableLoading(false);
    }
  };

  const getUploadedTypes = async () => {
    const { status, data } = await apiService.get(
      ROUTES_PATH.GET_UPLOAD_TYPE_URL
    );
    if (status) {
      setItems(data);
    }
    return data;
  };

  useEffect(() => {
    getUploadedTypes();
    getUploadedCsv();
  }, []);

  useEffect(() => {
    if (formPayload?.file?.objectURL) {
      const timer = setTimeout(() => setProgress(100), 0);

      return () => clearTimeout(timer);
    }
  }, [formPayload?.file?.objectURL]);

  useEffect(() => {
    if (formPayload?.upload_type && formPayload?.file) {
      setUploadBtnEnabled(true);
    } else {
      setUploadBtnEnabled(false);
    }
  }, [formPayload]);

  const storeFile = useCallback(async () => {
    if (formPayload?.upload_type && formPayload.file) {
      const formBody = new FormData();
      formBody.append('upload_type_id', formPayload?.upload_type);
      formBody.append('files', formPayload?.file);
      const store = await apiService.post(
        ROUTES_PATH.POST_UPLOADED_DATA_URL,
        formBody
      );
      if (store.status) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: store.message,
          life: 3000,
        });
        await getUploadedCsv();
        setFormPayload({
          upload_type: '',
          file: '',
        });
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: store.message,
          life: 3000,
        });
      }
      setProgress(0);
    }
  }, [formPayload]);

  const getUrl = async url => {
    try {
      const urlGenerate = await apiService.post(
        ROUTES_PATH.GET_PRESIGNED_CSV_URL,
        { url }
      );
      if (urlGenerate.status) {
        window.open(urlGenerate.data, '_blank');
        return urlGenerate.data;
      }
    } catch (e) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: e.message || 'Something went wrong',
        life: 3000,
      });
    }
  };

  const columns = useCallback(() => {
    let main = [
      {
        field: 'file_name',
        header: 'File Name',
        sortable: false,
        filter: false,
      },
      {
        field: 'upload_type_id',
        header: 'Uploaded Type',
        sortable: false,
        filter: false,
        body: data => (
          <>
            {`${
              data.upload_type_id
                ? (items?.find(i => i.upload_type_id === data.upload_type_id)
                    ?.upload_type ?? '')
                : ''
            }`}
          </>
        ),
      },

      {
        field: 'created_at',
        header: 'Uploaded Date',
        sortable: false,
        filter: false,
        body: data => (
          <>{`${data.created_at ? formatDateTimeMDYHM(data.created_at) : ''}`}</>
        ),
      },

      {
        field: 'status',
        header: 'Status',
        sortable: false,
        filter: false,
      },
      {
        field: 'total_records',
        header: 'Total Records',
        sortable: false,
        filter: false,
      },
      {
        field: 'success_records',
        header: 'Sucess',
        sortable: false,
        filter: false,
      },
      {
        field: 'failed_records',
        header: 'Failed',
        sortable: false,
        filter: false,
      },
      {
        field: 'error_file_url',
        header: 'Error File URL',
        sortable: false,
        filter: false,
        body: data => (
          <>
            {data.error_file_url ? (
              <i
                className="pi pi-download cursor-pointer"
                onClick={() => getUrl(data.error_file_url)}
                onKeyPress={e => {
                  if (e.key === 'Enter' || e.key === 'Space') {
                    getUrl(data.error_file_url);
                  }
                }}
                tabIndex="0"
                role="button"
                aria-label="Delete file"
              ></i>
            ) : (
              ''
            )}
          </>
        ),
      },
    ];
    return main;
  }, [items]);

  useEffect(() => {
    setDefaultTableProps(preProps => ({ ...preProps, lazy: false }));
    DataTableColumns(columns());
  }, [items]);

  const onSelect = e => {
    const maxFileSize = PF_CSV_UPLOAD_SIZE;
    if (e?.files && e?.files[0]) {
      let message = '';
      let error = false;
      if (e.files[0].type !== 'text/csv') {
        message = ERROR_MESSAGES.FILE_TYPE_ERROR;
        error = true;
      } else if (e.files[0].size > maxFileSize) {
        message = ERROR_MESSAGES.FILE_SIZE_ERROR;
        error = true;
      } else if (uploadedRecords.find(i => i.file_name === e.files[0].name)) {
        message = ERROR_MESSAGES.FILE_EXISTS;
        error = true;
      } else {
        error = false;
        setFormPayload(pre => ({ ...pre, file: e?.files[0] }));
      }
      if (error) {
        setFormPayload(pre => ({ ...pre, file: '' }));
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: message,
          life: 3000,
        });
      }
    } else {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: ERROR_MESSAGES.FILE_NOT_SUPPORTED,
        life: 3000,
      });
    }

    fileUploadRef.current?.clear();
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="grid w-full flex m-0">
        <div className="col-12 md:col-12 lg:col-12 p-0 mt-1">
          <PageHeader pageTitle="Data Import"> </PageHeader>
        </div>
        <Card style={{ boxShadow: 'none' }} className="w-full mb-2 p-0">
          <div className="col-12 md:col-12 lg:col-12 p-0 mt-1 card">
            <div className="grid py-3">
              <div className="col-3 md:col-3 lg:col-3 mb-3 py-0">
                <div className="p-inputgroup flex-1">
                  <PFDropdown
                    name="upload_type"
                    value={formPayload?.upload_type ?? ''}
                    options={items}
                    optionLabel="upload_type"
                    optionValue="upload_type_id"
                    placeholder="Select Upload Type"
                    onChange={(name, value) =>
                      setFormPayload({ ...formPayload, [name]: value })
                    }
                  />
                </div>
              </div>
              <div className="col-3 md:col-3 lg:col-3 mb-3 py-0 flex gap-3">
                <FileUpload
                  ref={fileUploadRef}
                  mode="basic"
                  name="file"
                  accept="text/csv"
                  maxFileSize={PF_CSV_UPLOAD_SIZE}
                  customUpload
                  auto
                  chooseLabel="Browse"
                  onSelect={onSelect}
                  pt={{
                    basicbutton: { className: 'p-button-sm height-40' },
                  }}
                  disabled={formPayload?.file?.objectURL}
                />
                <PFButton
                  label="Upload"
                  disabled={!uploadBtnEnabled}
                  onClick={storeFile}
                />
              </div>
            </div>
            <div className="col-12 md:col-12 lg:col-12 p-0 ">
              {formPayload?.file?.objectURL && (
                <div className="flex gap-3">
                  <span>{formPayload?.file?.name}</span>
                  <ProgressBar
                    value={progress}
                    className="w-3 mt-1"
                    style={{ height: '14px' }}
                  ></ProgressBar>
                  <i
                    className="pi pi-times text-red-500 cursor-pointer mt-1"
                    onClick={() => {
                      setFormPayload(pre => ({ ...pre, file: '' }));
                      setProgress(0);
                    }}
                    onKeyPress={e => {
                      if (e.key === 'Enter' || e.key === 'Space') {
                        setFormPayload(pre => ({ ...pre, file: '' }));
                        setProgress(0);
                      }
                    }}
                    tabIndex="0"
                    role="button"
                    aria-label="Delete file"
                  ></i>
                </div>
              )}
            </div>
          </div>
        </Card>
      </div>
      <div className="w-full">
        <div className="w-full flex justify-content-end mb-2">
          <PFButton
            label={` Refresh`}
            icon="pi pi-refresh"
            outlined={true}
            onClick={getUploadedCsv}
          />
        </div>

        {tableLoading ? <SkeletonLoader /> : <TableLayout />}
      </div>
    </>
  );
});
ImportData.displayName = 'ImportData';
export default ImportData;
