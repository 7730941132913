import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import PFInputText from '../../shared/PFPrime/PFInputText';
import PFButton from '../../shared/PFPrime/PFButton';
import PFFormikError from '../../shared/PFPrime/PFFormikError';
import {
  binCreateService,
  binUpdateService,
} from '../services/Warehouse.service';

const BinForm = ({ dialogProps, toast, data, warehouseId, binListing }) => {
  const { handleSubmit, handleChange, values, errors, touched, setFieldValue } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        binCode: '',
        binName: '',
      },
      validationSchema: Yup.object().shape({
        binCode: Yup.string().required('required').max(20),
        binName: Yup.string().required('required').max(20),
      }),
      onSubmit: async values => {
        let req;
        if (data?._id) {
          const binId = data?._id;
          req = await binUpdateService(values, warehouseId, binId);
        } else {
          req = await binCreateService(values, warehouseId);
        }

        if (req?.status) {
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: `${req?.message}`,
            life: 3000,
          });
          binListing();
          dialogProps(preValue => ({ ...preValue, show: false }));
        } else {
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: `${req?.message}`,
            life: 3000,
          });
          dialogProps(preValue => ({ ...preValue, show: false }));
        }
      },
    });

  useEffect(() => {
    if (data) {
      setFieldValue('binCode', data?.binCode || '');
      setFieldValue('binName', data?.binName || '');
    }
  }, [data]);

  return (
    <>
      <form className="w-full" onSubmit={handleSubmit}>
        <div className="grid py-2">
          <div className="col">
            <span className="p-float-label">
              <PFInputText
                name="binCode"
                type="text"
                value={values?.binCode || ''}
                onChange={handleChange}
                className={touched?.binCode && errors?.binCode ? 'error' : ''}
              />
              <label htmlFor="binCode">
                Bin Code <span className="text-red-500">*</span>
              </label>
            </span>
            <PFFormikError touched={touched} errors={errors} field="binCode" />
          </div>
          <div className="col">
            <span className="p-float-label">
              <PFInputText
                name="binName"
                type="text"
                value={values?.binName || ''}
                onChange={handleChange}
                className={touched?.binName && errors?.binName ? 'error' : ''}
              />
              <label htmlFor="binName">
                Bin Name <span className="text-red-500">*</span>
              </label>
            </span>
            <PFFormikError touched={touched} errors={errors} field="binName" />
          </div>
        </div>
        <div className="grid">
          <div className="col flex justify-content-end flex-wrap">
            <PFButton
              label={'Cancel'}
              className="mr-2"
              severity="primary"
              outlined
              onClick={() =>
                dialogProps(preValue => ({ ...preValue, show: false }))
              }
            />
            <PFButton label={'Save'} type={'submit'} />
          </div>
        </div>
      </form>
    </>
  );
};

export default BinForm;
