import { useEffect, useRef, useState } from 'react';

import {
  REACT_APP_PSPDFKIT_LICENCE_KEY,
  PUBLIC_URL,
} from '../../../constants/envConstants';

import UploadStoreTypeDialog from './UploadStoreTypeDialog';

export default function PdfViewerComponent({
  document,
  saveDocument,
  editMode,
  sourceSystemId,
  hdDocCdNumber,
  llDocumentType,
}) {
  const [openDialogStoreType, setOpenDialogStoreType] = useState(false);
  const [storeType, setStoreType] = useState('');
  const [instance, setInstance] = useState(null);
  const containerRef = useRef(null);
  const [hdDocNumber, setHdDocNumber] = useState(hdDocCdNumber);
  const [llDocType, setLlDocType] = useState(llDocumentType);

  const onUploadHandler = () => {
    instance.exportPDF().then(buffer => {
      const blob = new Blob([buffer], { type: 'application/pdf' });
      saveDocument(blob, storeType, hdDocNumber, llDocType);
    });
  };
  useEffect(() => {
    const container = containerRef.current;
    let PSPDFKit;
    const downloadButton = {
      type: 'custom',
      id: 'download-pdf',
      title: 'Save',
      className: 'save-button',
      onPress: () => {
        setOpenDialogStoreType(true);
      },
    };

    (async function () {
      PSPDFKit = await import('pspdfkit');

      const hiddenToolbarItems = [
        'sidebar-document-outline',
        'sidebar-annotations',
        'sidebar-bookmarks',
        'pan',
        'zoom-mode',
        'annotate',
        'ink',
        'highlighter',
        'text-highlighter',
        'ink-eraser',
        'signature',
        'image',
        'stamp',
        'note',
        'text',
        'line',
        'arrow',
        'rectangle',
        'ellipse',
        'polygon',
        'polyline',
        'print',
        'document-editor',
        'document-crop',
        'document-comparison',
        'search',
      ];
      const items = PSPDFKit.defaultToolbarItems.filter(
        item => !hiddenToolbarItems.includes(item.type)
      );

      if (editMode) items.push(downloadButton);
      const tempInstance = await PSPDFKit.load({
        container,
        toolbarItems: items,
        formDesignMode: true,
        document: document,
        licenseKey: REACT_APP_PSPDFKIT_LICENCE_KEY,
        baseUrl: `${window.location.protocol}//${window.location.host}/${PUBLIC_URL}`,
        initialViewState: new PSPDFKit.ViewState({ readOnly: !editMode }),
        styleSheets: [`/customStyles.css`],
      });
      setInstance(tempInstance);
    })();

    return () => PSPDFKit && PSPDFKit.unload(container);
  }, [editMode]);

  return (
    <>
      <UploadStoreTypeDialog
        openDialogStoreType={openDialogStoreType}
        setOpenDialogStoreType={setOpenDialogStoreType}
        storeType={storeType}
        setStoreType={setStoreType}
        onUploadHandler={onUploadHandler}
        hdDocCdNumber={hdDocNumber}
        setHdDocCdNumber={setHdDocNumber}
        llDocumentType={llDocType}
        setLlDocumentType={setLlDocType}
        sourceSystemId={sourceSystemId}
      />
      <div ref={containerRef} style={{ width: '100%', height: '100vh' }} />
    </>
  );
}
