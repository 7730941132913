import React, { useState, useEffect } from 'react';
import { Card } from 'primereact/card';

import PFInputText from '../../PFPrime/PFInputText';
import PFInputNumber from '../../PFPrime/PFInputNumber';
import PFButton from '../../PFPrime/PFButton';
import PFAutoComplete from '../../PFPrime/PFAutoComplete';
import { checkPermission } from '../../../../utils/Helpers';
import permissions from '../../../../config/permissions';

import {
  lineItemValidator,
  handleSuggestions,
  itemKeys,
} from './LineItems.model';

export default function LineItems({ values, errors, setFieldValue, isView }) {
  const [newRowFlag, setNewRowFlag] = useState(false);
  const [updateItem, setUpdateItem] = useState();
  const [items, setItems] = useState(
    Array.isArray(values?.lineItems) ? values?.lineItems : []
  );
  const [newRow, setNewRow] = useState('');
  const [subTotal, setSubTotal] = useState(0);
  const [netPrice, setNetPrice] = useState(subTotal);
  const [itemSuggestions, setItemSuggestions] = useState([]);
  const [selectedValue, setSelectedValue] = useState('');
  const [selectedItem, setSelectedItem] = useState('');
  const [item, setItem] = useState([]);
  const [lineItemSave, setLineItemSave] = useState(false);
  const [updatePrice, setUpdatePrice] = useState(false);
  const ITEM_KEYS = isView ? itemKeys.filter(e => e.key != 'action') : itemKeys;

  const appendNewRow = () => {
    setNewRow({
      itemId: '',
      item_number: '',
      item_desc: '',
      item_type: '',
      manufacturer: '',
      vendor: '',
      quantity: '',
      unitPrice: '',
      discount: 0,
      tax: 0,
      totalPrice: '',
    });
    setNewRowFlag(true);
  };

  useEffect(() => {
    if (lineItemSave) {
      const validate = lineItemValidator(newRow);
      setNewRowFlag(validate);
      setLineItemSave(!lineItemSave);
      if (!validate) setItems([...items, newRow]);
    }
  }, [lineItemSave]);

  const totalPriceCalculator = index => {
    if (index == 0 || index != -1) {
      let itemArray = items;
      const { quantity, unitPrice, discount, tax } = itemArray[index];
      if (quantity && unitPrice) {
        let totalPrice = quantity * unitPrice;
        if (!isNaN(discount)) totalPrice -= (totalPrice * discount) / 100;
        if (!isNaN(tax)) totalPrice += +((totalPrice * tax) / 100);
        itemArray[index].totalPrice = totalPrice.toFixed(2);
        setItems(itemArray);
        setUpdatePrice(true);
      }
    } else {
      const { quantity, unitPrice, discount, tax } = newRow;
      if (quantity && unitPrice) {
        let totalPrice = quantity * unitPrice;
        if (!isNaN(discount)) totalPrice -= (totalPrice * discount) / 100;
        if (!isNaN(tax)) totalPrice += +((totalPrice * tax) / 100);
        setNewRow({ ...newRow, totalPrice: totalPrice.toFixed(2) });
      }
    }
  };

  useEffect(() => {
    if (items.length > 0 || updatePrice) {
      const subTotal = items
        .reduce((sum, currentItem) => {
          return parseFloat(sum) + parseFloat(currentItem.totalPrice);
        }, 0)
        .toFixed(2);

      setSubTotal(subTotal);
      setFieldValue('lineItems', items);
      setUpdatePrice(false);
    }
  }, [items, updatePrice]);

  useEffect(() => {
    let netTotal = parseFloat(subTotal);
    if (values?.lineItemsDiscount || values?.lineItemsTax) {
      if (!isNaN(values?.lineItemsDiscount))
        netTotal -= (subTotal * parseFloat(values?.lineItemsDiscount)) / 100;
      if (!isNaN(values?.lineItemsTax))
        netTotal += (netTotal * parseFloat(values.lineItemsTax)) / 100;
      setNetPrice(netTotal.toFixed(2));
    } else {
      setNetPrice(netTotal.toFixed(2));
    }
    setFieldValue('netTotal', +netTotal.toFixed(2));
  }, [values?.lineItemsDiscount, values?.lineItemsTax, subTotal]);

  const getSuggestions = query => {
    const response = handleSuggestions(query);
    response.then(data => {
      setItem(data || []);
      const suggestions = data?.map(item => `${item.item_number}`);
      setItemSuggestions(suggestions);
    });
  };

  const removeLineItem = index => {
    const splicedItems = [...items];
    splicedItems.splice(index, 1);
    setItems(splicedItems);
  };

  //Helper to indicate which row to update
  const updateLineItem = index => {
    setUpdateItem(index);
  };

  useEffect(() => {
    if (selectedValue !== '') {
      setSelectedItem(item[selectedValue]);
    }
  }, [selectedValue]);

  useEffect(() => {
    if (selectedItem) {
      setNewRow({
        ...newRow,
        itemId: selectedItem?.item_id,
        item_number: selectedItem?.item_number,
        item_desc: selectedItem?.item_desc,
        item_type: selectedItem?.item_type?.type_name,
        manufacturer: selectedItem?.meta_type_manufacturer?.value,
        vendor: selectedItem?.meta_type_vendor?.value,
      });
      setFieldValue('itemId', selectedItem?.item_id);
    }
  }, [selectedItem]);

  return (
    <Card className="mt-3 w-full border-200 border-solid border-1">
      <div htmlFor="Line Items w-full">
        <div className="w-12 text-1xl font-bold p-1">Line Items</div>
        <div className="grid flex flex-row flex-wrap w-full m-0 mt-2">
          <div className="w-12 w-full">
            <table className="w-full lineItemTable">
              <thead>
                <tr>
                  {ITEM_KEYS.map(({ key, value, width }) => (
                    <th key={key} className="text-sm text-left" width={width}>
                      {value}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {items?.length > 0
                  ? items.map((row, index) => (
                      <tr
                        key={index}
                        className="text-center  border-bottom-1 border-primary-100"
                      >
                        {ITEM_KEYS.map(({ key, prefix = '', postfix = '' }) => (
                          <td key={key}>
                            {key === 'action' ? (
                              <span className="">
                                <i
                                  className="pi pi-trash mr-2 cursor-pointer text-primary"
                                  onClick={() => removeLineItem(index)}
                                ></i>
                                {(updateItem != 0 && !updateItem) ||
                                updateItem == -1 ? (
                                  <i
                                    className="pi pi-pencil cursor-pointer text-primary"
                                    onClick={() => updateLineItem(index)}
                                  ></i>
                                ) : (
                                  ''
                                )}
                                {(updateItem == 0 || updateItem) &&
                                updateItem != -1 ? (
                                  <>
                                    <i
                                      className="pi pi-times ml-1 cursor-pointer text-primary"
                                      onClick={() => setUpdateItem(-1)}
                                    ></i>
                                    <i
                                      className="pi pi-check ml-1 cursor-pointer text-primary"
                                      onClick={() => setUpdateItem(-1)}
                                    ></i>
                                  </>
                                ) : (
                                  ''
                                )}
                              </span>
                            ) : key == 'quantity' && updateItem == index ? (
                              <PFInputNumber
                                name="quantity"
                                value={`${row[key] ? prefix : ''}${row[key] || '-'} ${
                                  row[key] ? postfix : ''
                                }`}
                                onChange={(e, value) => {
                                  let newItem = items;
                                  newItem[index].quantity = value;
                                  setItems(newItem);
                                }}
                                placeholder="Quantity*"
                                onBlur={() => totalPriceCalculator(index)}
                                minFractionDigits={2}
                              />
                            ) : key == 'unitPrice' && updateItem == index ? (
                              <PFInputNumber
                                inputId="unitPrice"
                                name="unitPrice"
                                value={`${row[key] || '-'} `}
                                onChange={(e, value) => {
                                  let newItem = items;
                                  newItem[index].unitPrice = value;
                                  setItems(newItem);
                                }}
                                placeholder="Unit Price*"
                                onBlur={() => totalPriceCalculator(index)}
                                minFractionDigits={2}
                                currency="USD"
                                mode="currency"
                              />
                            ) : key == 'discount' && updateItem == index ? (
                              <PFInputNumber
                                inputId="discount"
                                name="discount"
                                value={`${row[key] || '-'}`}
                                onChange={(e, value) => {
                                  let newItem = items;
                                  newItem[index].discount = value;
                                  setItems(newItem);
                                }}
                                minFractionDigits={2}
                                max={100}
                                placeholder="Discount"
                                onBlur={() => totalPriceCalculator(index)}
                                suffix="%"
                              />
                            ) : key == 'tax' && updateItem == index ? (
                              <PFInputNumber
                                inputId="tax"
                                name="tax"
                                value={`${row[key] || '-'}`}
                                onChange={(e, value) => {
                                  let newItem = items;
                                  newItem[index].tax = value;
                                  setItems(newItem);
                                }}
                                onBlur={() => totalPriceCalculator(index)}
                                minFractionDigits={2}
                                max={100}
                                placeholder="Tax"
                                suffix="%"
                              />
                            ) : (
                              <>
                                {row[key] ? prefix : ''}
                                {row[key] || '-'}
                                {row[key] ? postfix : ''}
                              </>
                            )}
                          </td>
                        ))}
                      </tr>
                    ))
                  : !newRowFlag && (
                      <tr className="border-bottom-1 border-primary-100">
                        <td colSpan="10 align-items-center text-center">
                          No Items found
                        </td>
                      </tr>
                    )}
                {!isView && newRowFlag && (
                  <tr className="border-bottom">
                    <td className="px-2">
                      <span>
                        <PFAutoComplete
                          suggestions={itemSuggestions}
                          selectedValue={setSelectedValue}
                          getSuggestions={getSuggestions}
                          inputId="itemId"
                          value={newRow?.item_number}
                          placeholder="Search Item*"
                        />
                      </span>
                    </td>
                    <td className="px-2">
                      <PFInputText
                        type="text"
                        name="itemName"
                        value={newRow?.item_desc || ''}
                        placeholder="Description*"
                        disabled={true}
                      />
                    </td>
                    <td className="px-2">
                      <PFInputText
                        type="text"
                        name="itemType"
                        value={newRow?.item_type || ''}
                        placeholder="Type*"
                        disabled={true}
                      />
                    </td>
                    <td className="px-2">
                      <PFInputText
                        type="text"
                        name="manufacturer"
                        value={newRow?.manufacturer || ''}
                        placeholder="Manufacturer"
                        disabled={true}
                      />
                    </td>
                    <td className="px-2">
                      <PFInputText
                        type="text"
                        name="vendor"
                        value={newRow?.vendor || ''}
                        placeholder="Vendor"
                        disabled={true}
                      />
                    </td>
                    <td className="px-2">
                      <PFInputNumber
                        name="quantity"
                        value={newRow?.quantity || ''}
                        onChange={(e, value) =>
                          setNewRow({ ...newRow, quantity: value })
                        }
                        placeholder="Quantity*"
                        onBlur={() => totalPriceCalculator(-1)}
                        minFractionDigits={2}
                      />
                    </td>
                    <td className="px-2">
                      <PFInputNumber
                        inputId="unitPrice"
                        name="unitPrice"
                        value={newRow?.unitPrice || ''}
                        onChange={(e, value) => {
                          setNewRow({ ...newRow, unitPrice: value });
                        }}
                        placeholder="Unit Price*"
                        onBlur={() => totalPriceCalculator(-1)}
                        minFractionDigits={2}
                        currency="USD"
                        mode="currency"
                      />
                    </td>
                    <td className="px-2">
                      <PFInputNumber
                        inputId="discount"
                        name="discount"
                        value={newRow?.discount || '0'}
                        onChange={(e, value) => {
                          setNewRow({ ...newRow, discount: value || '0' });
                        }}
                        minFractionDigits={2}
                        max={100}
                        placeholder="Discount"
                        onBlur={() => totalPriceCalculator(-1)}
                        suffix="%"
                      />
                    </td>
                    <td className="px-2">
                      <PFInputNumber
                        inputId="tax"
                        name="tax"
                        value={newRow?.tax || '0'}
                        onChange={(e, value) => {
                          setNewRow({ ...newRow, tax: value || '0' });
                        }}
                        onBlur={() => totalPriceCalculator(-1)}
                        minFractionDigits={2}
                        max={100}
                        placeholder="Tax"
                        suffix="%"
                      />
                    </td>
                    <td className="px-2">
                      <span className="">${newRow?.totalPrice || 0}</span>
                    </td>
                    <td className="px-2">
                      <span className="">
                        {!lineItemValidator(newRow) && (
                          <i
                            className="pi pi-check ml-1 cursor-pointer text-primary"
                            onClick={() => setLineItemSave(true)}
                          ></i>
                        )}
                        <i
                          className="pi pi-times ml-1 cursor-pointer text-primary"
                          onClick={() => setNewRowFlag(false)}
                        ></i>
                      </span>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className=" w-12 flex">
            <div className="col-6 md:col-6 lg:col-6 p-0 mt-1">
              <PFButton
                label="Add Item"
                onClick={appendNewRow}
                disabled={
                  newRowFlag ||
                  isView ||
                  !checkPermission(permissions?.crm?.editUnitPrice) ||
                  (updateItem == 0 && updateItem != -1)
                }
              />
            </div>

            <div className="col-6 md:col-6 lg:col-6 p-0 mt-1 flex flex-column align-items-end">
              <div className="w-full flex">
                <div className="col-9  text-right">Sub Total -</div>
                <div className="col-3">{`$${subTotal}`}</div>
              </div>
              <div className="w-full flex">
                <div className="col-9  text-right mt-2">Discount -</div>
                <div className="col-3">
                  <PFInputNumber
                    inputId="lineItemsDiscount"
                    name="lineItemsDiscount"
                    value={values?.discount || ''}
                    onChange={setFieldValue}
                    minFractionDigits={2}
                    suffix="%"
                    disabled={
                      isView ||
                      !checkPermission(permissions?.crm?.editDiscount) ||
                      !checkPermission(permissions?.crm?.addDiscount)
                    }
                  />
                  <div style={{ color: 'red' }} className="px-1 errorStyle">
                    {errors?.discount || null}
                  </div>
                </div>
              </div>
              <div className="w-full flex">
                <div className="col-9  text-right mt-2">Tax -</div>
                <div className="col-3">
                  <PFInputNumber
                    inputId="lineItemsTax"
                    name="lineItemsTax"
                    value={values?.tax || ''}
                    // onChange={handleChange}
                    onChange={setFieldValue}
                    minFractionDigits={2}
                    suffix="%"
                    disabled={isView}
                  />
                  <div style={{ color: 'red' }} className="px-1 errorStyle">
                    {errors?.tax || null}
                  </div>
                </div>
              </div>
              <div className="w-full flex">
                <div className="col-9  text-right">Total Price -</div>
                <div className="col-3 font-bold">{`$${netPrice}`}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
}
