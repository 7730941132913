import React, { useState, useRef, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import axios from 'axios';
import { Button } from 'primereact/button';
import { InputOtp } from 'primereact/inputotp';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import * as Yup from 'yup'; // Import Yup

import {
  REACT_APP_LOGIN_AUTH,
  REACT_APP_AUTH_CLIENT_ID,
} from '../../../../constants/envConstants';
import { decryptEmail } from '../../../../utils/security.helper';

import { useStyles } from './Login.styles';
import Welcome from './Welcome';

const OtpPage = () => {
  const classes = useStyles();
  const toast = useRef(null);
  const location = useLocation();
  const history = useHistory();
  const authorizeForm = useRef(React.createRef());
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const token = location.search.split('token=')[1];

  const validationSchema = Yup.object().shape({
    otp: Yup.string()
      .length(6, 'OTP must be exactly 6 digits')
      .required('OTP is required'),
  });

  useEffect(() => {
    if (!token) {
      history.push('/');
    }
    toast.current.show({
      severity: 'success',
      summary: 'Success',
      detail:
        'For security purposes, an OTP is required to proceed. Your one-time code has been sent to your email.',
    });
  }, []);

  const formik = useFormik({
    initialValues: {
      otp: '',
    },
    validationSchema,
    onSubmit: async values => {
      try {
        setLoading(true);
        const decryptedEmail = decryptEmail(token);

        const response = await axios.post(
          `${REACT_APP_LOGIN_AUTH}/verify-otp`,
          {
            email: decryptedEmail,
            otp: values.otp,
            method: 'POST',
          }
        );

        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: response.data.message,
        });
        localStorage.removeItem('isShowMfaConfirmation');
        setFormData({ otp: values.otp, email: decryptedEmail });
        authorizeForm.current.submit();
      } catch (error) {
        console.log('OTP-error', error);
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: error?.response?.data?.message || 'Invalid OTP',
        });
      } finally {
        setLoading(false);
      }
    },
  });

  const resendOtp = async () => {
    try {
      setLoading(true);
      const decryptedEmail = decryptEmail(token);

      const response = await axios.post(`${REACT_APP_LOGIN_AUTH}/resend-otp`, {
        username: decryptedEmail,
        method: 'POST',
      });

      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: response.data.message,
      });
    } catch (error) {
      console.log('Resend-OTP-error', error);
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed to resend OTP',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <form
        ref={authorizeForm}
        action={`${REACT_APP_LOGIN_AUTH}/authorize-user`}
        method="post"
      >
        <input type="hidden" name="otp" value={formData.otp} />
        <input type="hidden" name="username" value={formData.email} />
        <input
          type="hidden"
          name="client_id"
          value={REACT_APP_AUTH_CLIENT_ID}
        />
        <input type="hidden" name="response_type" value="code" />
        <input type="hidden" name="state" value="/" />
      </form>
      <div
        className={`${classes.LoginContainer} flex align-items-center justify-content-center`}
      >
        <div>
          <img
            src="/images/circle-background.png"
            alt="Project Force"
            className="circle-bg mb-3"
          />
          <div className="text-center pt-5">
            <img
              src="/images/PF-white-logo.png"
              alt="Project Force"
              className="mb-3"
              style={{ maxWidth: '180px' }}
            />
            <p className="px-3" style={{ maxWidth: '470px', margin: 'auto' }}>
              ProjectsForce puts you in control of your work, keeping both you
              and your clients on-track towards completing the job and getting
              paid.
            </p>
          </div>
          <div className="flex col-12 pb-0">
            <Welcome />
            <div className="flex col-6 flex h-100 w-100 align-center wrap px-5 pt-0 pb-1 login-container-right ">
              <div className="flex login-right-inner w-12">
                <div className="login-section w-12 py-4">
                  <h1 className="text-center mt-4 md:mt-0">
                    Enter One Time Password
                  </h1>
                  <form onSubmit={formik.handleSubmit}>
                    <div>
                      <div className={`col-12 p-0 p-input-icon-right mb-3`}>
                        <InputOtp
                          id="otp"
                          value={formik.values.otp}
                          onChange={e => formik.setFieldValue('otp', e.value)}
                          className="otp-input"
                          length={6}
                        />
                        {formik.touched.otp && formik.errors.otp ? (
                          <div className="error">{formik.errors.otp}</div>
                        ) : null}
                      </div>

                      <div className="mt-2">
                        <p>
                          {`OTP sent to`}{' '}
                          <span className="font-bold">
                            {decryptEmail(token)}.
                          </span>{' '}
                          {`Please check your inbox. If you haven't received it, click here to `}
                          <span
                            className="blue-text"
                            role="button"
                            tabIndex={0}
                            onClick={resendOtp}
                            onKeyDown={e => {
                              if (e?.key === 'Enter' || e?.key === ' ') {
                                resendOtp();
                              }
                            }}
                          >
                            resend
                          </span>
                        </p>
                      </div>
                      <div className="mt-2">
                        By using ProjectsForce, you agree to our{' '}
                        <a href="https://projectsforce.com/privacy-policy">
                          Privacy Policy
                        </a>
                        .
                      </div>
                      <Button
                        icon={loading ? 'pi pi-spin pi-spinner' : ''}
                        severity="primary"
                        type="submit"
                        label="Verify"
                        pt={{
                          label: {
                            className: 'flex-none w-4rem',
                          },
                        }}
                        className="mt-3 flex justify-content-center w-full mt-3 flex-row-reverse"
                        disabled={loading}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OtpPage;
