import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Card } from 'primereact/card';

import PFInputText from '../../../shared/PFPrime/PFInputText';
import { fetchCustomerInfo } from '../../Clients/helpers/CustomerInfo';
import { toTitleCase } from '../../../../utils/Helpers';

const ClientInfomation = ({
  customerId,
  globalCustomerDetails,
  setBreadCrumbData = null,
  leadAddress = null,
}) => {
  const history = useHistory();
  const [customerDetails, setCustomerDetails] = useState('');

  useEffect(() => {
    const customerFullName = `${customerDetails?.first_name} ${customerDetails?.last_name}`;
    if (setBreadCrumbData) {
      setBreadCrumbData(prev => {
        return { ...prev, clientName: toTitleCase(customerFullName) };
      });
    }
    if (globalCustomerDetails) globalCustomerDetails(customerDetails);
  }, [customerDetails]);

  useEffect(() => {
    if (customerId) {
      fetchCustomerInfo(customerId, setCustomerDetails, () => {}, history);
    }
  }, [customerId]);

  return (
    <Card style={{ boxShadow: 'none' }}>
      <div htmlFor="Client Information">
        <div className="grid">
          <div className="text-1xl font-bold px-2 py-2 col-12 md:col-12 lg:col-12">
            <h3>Client Information</h3>
          </div>
          <div className="col-12 md:col-6 lg:col-2">
            <div className="w-full">
              <Link to={`/crm/view/customer/${customerId}`}>
                <span className="p-float-label">
                  <PFInputText
                    id="clientId"
                    name="client_id"
                    type="text"
                    value={customerId}
                    disabled={true}
                    className="text-primary"
                  />
                  <label htmlFor="clientFirstName">Client#</label>
                </span>
              </Link>
            </div>
          </div>
          <div className="col-12 md:col-6 lg:col-3">
            <div className="w-full">
              <span className="p-float-label">
                <PFInputText
                  id="firstName"
                  name="first_name"
                  type="text"
                  value={customerDetails?.first_name || ''}
                  disabled={true}
                />
                <label htmlFor="clientFirstName">First Name</label>
              </span>
            </div>
          </div>
          <div className="col-12 md:col-6 lg:col-3">
            <div className="w-full">
              <span className="p-float-label">
                <PFInputText
                  id="lastName"
                  name="last_name"
                  type="text"
                  value={customerDetails?.last_name || ''}
                  disabled={true}
                />
                <label htmlFor="clientFirstName">Last Name</label>
              </span>
            </div>
          </div>
          <div className="col-12 md:col-6 lg:col-2">
            <div className="w-full">
              <span className="p-float-label">
                <PFInputText
                  id="phoneNumber"
                  name="primary_phone"
                  type="text"
                  value={
                    customerDetails?.customer_phones?.find(
                      item => item.is_primary
                    )?.phone_number || ''
                  }
                  disabled={true}
                />
                <label htmlFor="primary_phone">Phone</label>
              </span>
            </div>
          </div>
          <div className="col-12 md:col-6 lg:col-2">
            <div className="w-full">
              <span className="p-float-label">
                <PFInputText
                  id="primaryEmail"
                  name="primary_email"
                  type="text"
                  value={
                    customerDetails?.customer_emails?.find(
                      item => item.is_primary
                    )?.email || ''
                  }
                  disabled={true}
                />
                <label htmlFor="primaryEmail">Email</label>
              </span>
            </div>
          </div>
        </div>
        <div className="grid py-2">
          <div className="col-12 md:col-6 lg:col-3">
            <div className="w-full">
              <span className="p-float-label">
                <PFInputText
                  id="sddress1"
                  name="address1"
                  type="text"
                  value={
                    leadAddress?.address1 ||
                    customerDetails?.address?.address1 ||
                    ''
                  }
                  disabled={true}
                />
                <label htmlFor="clientFirstName">Street Address 1</label>
              </span>
            </div>
          </div>
          <div className="col-12 md:col-6 lg:col-3">
            <div className="w-full">
              <span className="p-float-label">
                <PFInputText
                  id="sddress2"
                  name="address2"
                  type="text"
                  value={
                    leadAddress?.address2 ||
                    customerDetails?.address?.address2 ||
                    ''
                  }
                  disabled={true}
                />
                <label htmlFor="clientFirstName">Street Address 2</label>
              </span>
            </div>
          </div>
          <div className="col-12 md:col-6 lg:col-2">
            <div className="w-full">
              <span className="p-float-label">
                <PFInputText
                  id="city"
                  name="city"
                  type="text"
                  value={
                    leadAddress?.city || customerDetails?.address?.city || ''
                  }
                  disabled={true}
                />
                <label htmlFor="clientFirstName">City</label>
              </span>
            </div>
          </div>
          <div className="col-12 md:col-6 lg:col-2">
            <div className="w-full">
              <span className="p-float-label">
                <PFInputText
                  id="state"
                  name="state"
                  type="text"
                  value={
                    leadAddress?.state ||
                    customerDetails?.address?.stateName ||
                    ''
                  }
                  disabled={true}
                />
                <label htmlFor="clientFirstName">State</label>
              </span>
            </div>
          </div>
          <div className="col-12 md:col-6 lg:col-2">
            <div className="w-full">
              <span className="p-float-label">
                <PFInputText
                  id="postal_code"
                  name="postal_code"
                  type="text"
                  value={
                    leadAddress?.zipcode ||
                    customerDetails?.address?.zipcode ||
                    ''
                  }
                  disabled={true}
                />
                <label htmlFor="clientFirstName">Zip Code</label>
              </span>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

// eslint-disable-next-line spellcheck/spell-checker
export default ClientInfomation;
