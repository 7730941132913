/* eslint-disable spellcheck/spell-checker */
import React, { useEffect, useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Document, Page, pdfjs } from 'react-pdf';
import { useDispatch } from 'react-redux';
import { Toast } from 'primereact/toast';
import jsPDF from 'jspdf';
import { ProgressSpinner } from 'primereact/progressspinner';

import PFDialog from '../../shared/PFPrime/PFDialog';
import { UTCConvertor } from '../../../utils/date.helper';
import PFButton from '../../shared/PFPrime/PFButton';
import { updateQuestionnaireStore } from '../../../redux/slices/questionnaire.slice';

import { QUESTION_TYPES } from './questionnaire.contants';
import { getAnswers } from './questionnaireService';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url
).toString();

const PreviewCheckList = ({
  isPreviewVisible,
  module,
  projectDetailsData,
  targetId,
  checkListName = '',
  onHide,
}) => {
  if (
    !module?.selectedModule?.module_id ||
    !module?.selectedModule?.module_name ||
    !targetId ||
    !isPreviewVisible
  ) {
    return <></>;
  }
  const dispatch = useDispatch();
  const toast = useRef(null);
  const [numPages, setNumPages] = useState(null);
  const [allData, setAllData] = useState({
    label: '',
    answers: [],
  });
  const [pdfUrl, setPdfUrl] = useState(null);
  const [isViewLoading, setIsViewLoading] = useState(false);
  const [tableData, setTableData] = useState({});
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const onLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const getPageWidth = () => {
    if (windowWidth < 650) {
      return windowWidth * 0.95;
    } else if (windowWidth < 1024) {
      return windowWidth * 0.85;
    } else {
      return 1000;
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    callGetAnswers();
  }, [module.selectedModule.module_id]);

  const convertTableAnswerToResponseFormat = apiData => {
    const formattedData = {};

    apiData.forEach(question => {
      if (question.question_type === QUESTION_TYPES.table.id) {
        const parentId = question.question_id;
        let answerData = [];

        if (
          Array.isArray(question.format_field_types) &&
          question.format_field_types.length
        ) {
          // Check if update mode (array of arrays)
          if (Array.isArray(question.format_field_types[0])) {
            answerData = question.format_field_types.map(row =>
              row.map(field => ({
                question_format_id: field.question_format_id,
                answer: field.answer || '',
                format_question_label: field.format_question_label,
                config: field.config,
                position: field.position || field?.config?.position,
                options: field.options,
                row_number: field.row_number,
                format_question_type: field.format_question_type,
              }))
            );
          } else {
            // Create mode: wrap array of objects into one row
            answerData = [
              question.format_field_types.map(field => ({
                question_format_id: field.question_format_id,
                answer: field.answer || '',
                format_question_label: field.format_question_label,
                config: field.config,
                position: field.position || field?.config?.position,
                options: field.options,
                row_number: field.row_number,
                format_question_type: field.format_question_type,
              })),
            ];
          }
        } else {
          answerData = [[]];
        }

        formattedData[parentId] = {
          question_id: parentId,
          answer: answerData,
          // Also keep the original fields for column definitions
          format_field_types: question.format_field_types,
        };
      }
    });
    return formattedData;
  };

  const callGetAnswers = async () => {
    dispatch(updateQuestionnaireStore({ isAnswerLoading: true }));
    try {
      const response = await getAnswers(
        module.selectedModule.module_id,
        targetId,
        module.selectedModule?.version ? false : true,
        module.selectedModule?.version
      );
      if (response?.status && response?.data) {
        if (response?.data?.result?.[0]) {
          setAllData({
            ...allData,
            label: response?.data?.result?.[0]?.label
              ? response?.data?.result?.[0]?.label
              : checkListName,
          });
        }
        setAllData({
          ...allData,
          answers: response.data?.result,
        });

        const initialTableAnswerData = convertTableAnswerToResponseFormat(
          response.data?.result
        );
        setTableData(initialTableAnswerData);
        onClickShowPdf();
      }
    } catch (ex) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: ex?.response?.data?.message || 'something went wrong',
        life: 3000,
      });
    } finally {
      dispatch(updateQuestionnaireStore({ isAnswerLoading: false }));
    }
  };

  const getFieldLabel = fieldId => {
    return QUESTION_TYPES?.[fieldId]?.label || fieldId;
  };

  const sortTableAnswerFields = field => {
    const sortedColumns = field
      .slice()
      .sort(
        (a, b) =>
          (a?.position || a?.config?.position || 0) -
          (b?.position || b?.config?.position || 0)
      );

    return sortedColumns || [];
  };

  const getTableAnswerColumns = fields => {
    if (!Array.isArray(fields) || !fields.length) return [];
    if (Array.isArray(fields[0])) {
      return sortTableAnswerFields(fields[0]);
    }
    return sortTableAnswerFields(fields);
  };

  const transformRowsToObjects = rows => {
    if (!rows || !Array.isArray(rows)) return [];
    const finalRows = rows.map((row, rowIndex) => {
      const rowObj = { _rowId: rowIndex };

      row.forEach(field => {
        rowObj.row_number = field.row_number;
        rowObj[
          `row_${field.row_number}_position_${field.position || field.config.position}`
        ] =
          QUESTION_TYPES.list.id === field.format_question_type
            ? field.options.find(obj => obj.option_id == field.answer)?.label
            : field.answer;
        rowObj[
          `options_row_${field.row_number}_position_${field.position || field.config.position}`
        ] =
          Array.isArray(field.options) && field.options.length > 0
            ? field.options
            : [];
      });
      return rowObj;
    });
    return finalRows;
  };

  const getCardFields = eachAnswer => {
    if (eachAnswer.question_type !== QUESTION_TYPES.card.id) return [];
    if (
      eachAnswer.answer &&
      Array.isArray(eachAnswer.answer) &&
      eachAnswer.answer.length
    ) {
      return eachAnswer.answer;
    }
    if (
      eachAnswer.format_field_types &&
      Array.isArray(eachAnswer.format_field_types)
    ) {
      return eachAnswer.format_field_types.map((field, index) => ({
        ...field,
        answer: field.answer || '',
        config: {
          x: field.config?.x ?? index % 6,
          y: field.config?.y ?? Math.floor(index / 6),
          w: field.config?.w ?? 3,
          h: field.config?.h ?? 2,
        },
      }));
    }
    return [];
  };

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = pdfUrl; // URL of the PDF file
    link.download = `${module?.selectedModule?.module_name}.pdf`; // Specify the file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const renderStar = (count, view) => {
    return Array.from({ length: count }, (_, index) => (
      <div key={index}>
        <img
          alt="star"
          width="18"
          height="18"
          src={
            index + 1 <= view
              ? '/images/formIcons/star.png'
              : '/images/formIcons/estar.png'
          }
        />
      </div>
    ));
  };

  const onClickShowPdf = async () => {
    setIsViewLoading(true);
    const content = document.getElementById('pdf-show');
    const doc = new jsPDF();
    doc.html(content, {
      callback: function (doc) {
        const url = doc.output('bloburl');
        setPdfUrl(url);
        setIsViewLoading(false);
      },
      html2canvas: {
        scale: 0.125,
      },
      x: 10,
      y: 10,
    });
  };

  return (
    <div>
      <Toast ref={toast} />
      <PFDialog
        header="Checklist Preview"
        show={isPreviewVisible}
        style={{ width: '70vw', height: '90vh' }}
        onHide={() => onHide()}
        className={'relative'}
        BodyComponent={
          <div className="h-full">
            <div
              className="absolute z-1"
              style={{
                bottom: '20px',
                right: '30px',
              }}
            >
              <PFButton
                icon="pi pi-download"
                rounded
                size="small"
                aria-label="download"
                onClick={() => handleDownload()}
                tooltip="Download"
                tooltipOptions={{ position: 'top' }}
              />
            </div>
            {pdfUrl !== null && pdfUrl && !isViewLoading ? (
              <Document
                file={pdfUrl}
                onLoadSuccess={onLoadSuccess}
                loading={
                  <div className="w-full h-full flex justify-content-center align-items-center">
                    <ProgressSpinner />
                  </div>
                }
              >
                {Array.from({ length: numPages }, (_, i) => i + 1).map(
                  pageNumber => (
                    <Page
                      key={pageNumber}
                      pageNumber={pageNumber}
                      width={getPageWidth()}
                      renderTextLayer={false}
                      renderAnnotationLayer={false}
                      className={'flex justify-content-center'}
                    />
                  )
                )}
              </Document>
            ) : (
              <div className="h-full w-full flex align-items-center justify-content-center">
                <ProgressSpinner
                  style={{ width: '30px', height: '30px' }}
                  strokeWidth="6"
                  fill="var(--surface-ground)"
                  animationDuration=".5s"
                />
              </div>
            )}
          </div>
        }
      ></PFDialog>
      <div className="hidden">
        <div
          id="pdf-show"
          style={{
            width: 1520,
            fontFamily: 'Inter var, sans-serif',
          }}
        >
          {module?.selectedModule?.module_name && (
            <div
              className="bg-blue-500 p-5 text-white text-4xl mb-5 font-bold border-round-md"
              style={{
                fontFamily: 'Inter bold, sans-serif',
              }}
            >
              Checklist: {module?.selectedModule?.module_name}
            </div>
          )}
          {projectDetailsData && (
            <div
              className="bg-gray-300 p-2 px-4 text-2xl mb-3 font-bold border-round-md"
              style={{
                fontFamily: 'Inter bold, sans-serif',
              }}
            >
              Project Details
            </div>
          )}
          {module?.selectedModule?.module_name && (
            <div>
              {projectDetailsData && (
                <div className="w-full my-2">
                  <div className="m-0 grid bg-gray-100 p-2 border-round-md">
                    {projectDetailsData?.source_system?.source_name && (
                      <div className="col-4">
                        <p className="m-0 text-2xl">
                          <span
                            className="font-bold"
                            style={{
                              fontFamily: 'Inter var, sans-serif',
                            }}
                          >
                            Source:{' '}
                          </span>
                          {projectDetailsData?.source_system?.source_name}
                        </p>
                      </div>
                    )}
                    {projectDetailsData?.project_number && (
                      <div className="col-4">
                        <p className="m-0 text-2xl">
                          <span
                            className="font-bold"
                            style={{
                              fontFamily: 'Inter var, sans-serif',
                            }}
                          >
                            Project#:{' '}
                          </span>
                          {projectDetailsData?.project_number}
                        </p>
                      </div>
                    )}
                    {projectDetailsData?.po_number && (
                      <div className="col-4">
                        <p className="m-0 text-2xl">
                          <span
                            className="font-bold"
                            style={{
                              fontFamily: 'Inter var, sans-serif',
                            }}
                          >
                            PO#:{' '}
                          </span>
                          {projectDetailsData?.po_number}
                        </p>
                      </div>
                    )}
                    {projectDetailsData?.project_type?.project_type && (
                      <div className="col-4">
                        <p className="m-0 text-2xl">
                          <span
                            className="font-bold"
                            style={{
                              fontFamily: 'Inter var, sans-serif',
                            }}
                          >
                            Type:{' '}
                          </span>
                          {projectDetailsData?.project_type?.project_type}
                        </p>
                      </div>
                    )}
                    {projectDetailsData?.project_category?.category && (
                      <div className="col-4">
                        <p className="m-0 text-2xl">
                          <span
                            className="font-bold"
                            style={{
                              fontFamily: 'Inter var, sans-serif',
                            }}
                          >
                            Category:{' '}
                          </span>
                          {projectDetailsData?.project_category?.category}
                        </p>
                      </div>
                    )}
                    {(projectDetailsData?.customer?.first_name ||
                      projectDetailsData?.customer?.last_name) && (
                      <div className="col-4">
                        <p className="m-0 text-2xl">
                          <span
                            className="font-bold"
                            style={{
                              fontFamily: 'Inter var, sans-serif',
                            }}
                          >
                            Client Name:{' '}
                          </span>
                          {(projectDetailsData?.customer?.first_name || '') +
                            ' ' +
                            (projectDetailsData?.customer?.last_name || '')}
                        </p>
                      </div>
                    )}
                    {projectDetailsData?.status?.status && (
                      <div className="col-4">
                        <p className="m-0 text-2xl">
                          <span
                            className="font-bold"
                            style={{
                              fontFamily: 'Inter var, sans-serif',
                            }}
                          >
                            Status:{' '}
                          </span>
                          {projectDetailsData?.status?.status}
                        </p>
                      </div>
                    )}
                    {projectDetailsData?.modified_at && (
                      <div className="col-4">
                        <p className="m-0 text-2xl">
                          <span
                            className="font-bold"
                            style={{
                              fontFamily: 'Inter var, sans-serif',
                            }}
                          >
                            Follow-Up Date:{' '}
                          </span>
                          {UTCConvertor(projectDetailsData?.modified_at, false)}
                        </p>
                      </div>
                    )}
                    {projectDetailsData?.created_at && (
                      <div className="col-4">
                        <p className="m-0 text-2xl">
                          <span
                            className="font-bold"
                            style={{
                              fontFamily: 'Inter var, sans-serif',
                            }}
                          >
                            Completion Date:{' '}
                          </span>
                          {UTCConvertor(projectDetailsData?.created_at, false)}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
          {allData.label && (
            <div
              className="bg-gray-300 p-2 px-4 text-2xl mb-3 font-bold border-round-md"
              style={{
                fontFamily: 'Inter bold, sans-serif',
              }}
            >
              Checklist Name : {allData.label}
            </div>
          )}
          <div>
            {allData.answers.map((eachAnswer, index) => {
              const isTable = [QUESTION_TYPES.table.id].includes(
                eachAnswer?.question_type
              );
              return (
                <div key={index}>
                  <div className="flex flex-column gap-2 border-1 border-400 border-round-xl p-4 mt-4">
                    <h3
                      className="font-bold w-10 p-1 text-2xl"
                      style={{
                        fontFamily: 'Inter bold, sans-serif',
                      }}
                    >
                      {index + 1}. {eachAnswer?.question}
                    </h3>
                    {eachAnswer?.question_type ===
                      QUESTION_TYPES.rating10.id && (
                      <div className="flex w-full">
                        <span
                          className="flex align-items-center gap-1 text-2xl"
                          style={{
                            fontFamily: 'Inter var, sans-serif',
                          }}
                        >
                          {renderStar(
                            10,
                            Number(allData.answers?.[index]?.answer) || 0
                          )}
                        </span>
                      </div>
                    )}

                    {eachAnswer?.question_type ===
                      QUESTION_TYPES.rating5.id && (
                      <div className="flex w-full">
                        <span
                          className="flex align-items-center gap-1 text-2xl"
                          style={{
                            fontFamily: 'Inter var, sans-serif',
                          }}
                        >
                          {renderStar(
                            5,
                            Number(allData?.answers?.[index]?.answer) || 0
                          )}
                        </span>
                      </div>
                    )}
                    {eachAnswer?.question_type === QUESTION_TYPES.list.id && (
                      <div className="flex w-full">
                        <span
                          className="text-2xl"
                          style={{
                            fontFamily: 'Inter var, sans-serif',
                          }}
                        >
                          {allData?.answers?.[index]?.options.find(
                            obj =>
                              obj.option_id == allData?.answers?.[index]?.answer
                          )?.label || ''}
                        </span>
                      </div>
                    )}

                    {eachAnswer?.question_type === QUESTION_TYPES.text.id && (
                      <div className="flex w-full">
                        <span
                          className="text-2xl"
                          style={{
                            fontFamily: 'Inter var, sans-serif',
                          }}
                        >
                          {allData?.answers?.[index]?.answer || ''}
                        </span>
                      </div>
                    )}

                    {isTable && (
                      <div className="w-full">
                        <DataTable
                          value={transformRowsToObjects(
                            tableData[eachAnswer?.question_id]?.answer
                          )}
                          scrollable
                          scrollHeight="400px"
                          stripedRows
                          tableStyle={{ tableLayout: 'fixed', width: '100%' }}
                          showGridlines
                        >
                          {getTableAnswerColumns(
                            eachAnswer?.format_field_types
                          ).map(column => (
                            <Column
                              key={column.question_format_id}
                              field={`${column.position || column.config.position}`}
                              header={
                                <span
                                  className="text-2xl"
                                  style={{
                                    fontFamily: 'Inter bold, sans-serif',
                                  }}
                                >
                                  {column.format_question_label}
                                </span>
                              }
                              body={rowData => {
                                const answerKey = `row_${rowData.row_number}_position_${column.position || column.config.position}`;

                                const currentValue = rowData[answerKey];
                                const fieldType = column?.format_question_type;

                                if (fieldType === QUESTION_TYPES.list.id) {
                                  return (
                                    <span
                                      className="text-2xl"
                                      style={{
                                        fontFamily: 'Inter bold, sans-serif',
                                      }}
                                    >
                                      {currentValue}
                                    </span>
                                  );
                                }
                                if (fieldType === QUESTION_TYPES.text.id) {
                                  return (
                                    <span
                                      className="text-2xl"
                                      style={{
                                        fontFamily: 'Inter bold, sans-serif',
                                      }}
                                    >
                                      {currentValue}
                                    </span>
                                  );
                                }
                                if (fieldType === QUESTION_TYPES.rating5.id) {
                                  return (
                                    <span
                                      className="text-2xl flex gap-1 align-items-center"
                                      style={{
                                        fontFamily: 'Inter bold, sans-serif',
                                      }}
                                    >
                                      {renderStar(5, Number(currentValue) || 0)}
                                    </span>
                                  );
                                }
                                if (fieldType === QUESTION_TYPES.rating10.id) {
                                  return (
                                    <span
                                      className="text-2xl flex gap-1 align-items-center"
                                      style={{
                                        fontFamily: 'Inter bold, sans-serif',
                                      }}
                                    >
                                      {renderStar(
                                        10,
                                        Number(currentValue) || 0
                                      )}
                                    </span>
                                  );
                                }
                                return (
                                  <span
                                    className="text-2xl"
                                    style={{
                                      fontFamily: 'Inter bold, sans-serif',
                                    }}
                                  >
                                    {getFieldLabel(fieldType)}
                                  </span>
                                );
                              }}
                            />
                          ))}
                        </DataTable>
                      </div>
                    )}

                    {eachAnswer?.question_type === QUESTION_TYPES.card.id && (
                      <div className="w-full">
                        {(() => {
                          const cardFields = getCardFields(eachAnswer);
                          return (
                            <div className="flex flex-column">
                              {cardFields.map(field => (
                                <div
                                  key={field.question_format_id}
                                  data-grid={{
                                    x: field.config.x,
                                    y: field.config.y,
                                    w: field.config.w,
                                    h: field.config.h,
                                  }}
                                  className="mb-2"
                                >
                                  <div
                                    className="font-bold text-2xl"
                                    style={{
                                      fontFamily: 'Inter bold, sans-serif',
                                      display: 'block',
                                      wordBreak: 'break-all',
                                      height: 31,
                                      overflow: 'hidden',
                                      whiteSpace: 'nowrap',
                                      textOverflow: 'ellipsis',
                                    }}
                                  >
                                    {field.format_question_label}
                                  </div>
                                  <div>
                                    {(() => {
                                      switch (field.format_question_type) {
                                        case QUESTION_TYPES.text.id:
                                          return (
                                            <span
                                              className="text-2xl"
                                              style={{
                                                fontFamily:
                                                  'Inter bold, sans-serif',
                                                display: 'block',
                                                wordBreak: 'break-all',
                                                height: 31,
                                                overflow: 'hidden',
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis',
                                              }}
                                            >
                                              {field.answer || ''}
                                            </span>
                                          );
                                        case QUESTION_TYPES.list.id:
                                          return (
                                            <span
                                              className="text-2xl"
                                              style={{
                                                fontFamily:
                                                  'Inter bold, sans-serif',
                                                display: 'block',
                                                wordBreak: 'break-all',
                                                height: 31,
                                                overflow: 'hidden',
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis',
                                              }}
                                            >
                                              {field?.options?.find(
                                                obj =>
                                                  obj.option_id == field.answer
                                              )?.label || ''}
                                            </span>
                                          );
                                        case QUESTION_TYPES.rating5.id:
                                          return (
                                            <span
                                              className="text-2xl flex gap-1 align-items-center"
                                              style={{
                                                fontFamily:
                                                  'Inter bold, sans-serif',
                                              }}
                                            >
                                              {renderStar(
                                                5,
                                                Number(field.answer) || 0
                                              )}
                                            </span>
                                          );
                                        case QUESTION_TYPES.rating10.id:
                                          return (
                                            <span
                                              className="text-2xl flex gap-1 align-items-center"
                                              style={{
                                                fontFamily:
                                                  'Inter bold, sans-serif',
                                              }}
                                            >
                                              {renderStar(
                                                10,
                                                Number(field.answer) || 0
                                              )}
                                            </span>
                                          );
                                        default:
                                          return (
                                            <span
                                              className="text-2xl"
                                              style={{
                                                fontFamily:
                                                  'Inter bold, sans-serif',
                                                display: 'block',
                                                wordBreak: 'break-all',
                                                height: 31,
                                                overflow: 'hidden',
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis',
                                              }}
                                            >
                                              {field.format_question_label}
                                            </span>
                                          );
                                      }
                                    })()}
                                  </div>
                                </div>
                              ))}
                            </div>
                          );
                        })()}
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewCheckList;
