import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { Skeleton } from 'primereact/skeleton';

import { getQuoteTemplateList } from '../../../Admin/QuoteTemplate/Service/QuotesTemplate.service';

const ImportFromQuoteTemplate = props => {
  const [option, setOption] = useState([]);
  const [loading, setLoading] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const getQuoteList = async () => {
    try {
      setLoading(true);
      const response = await getQuoteTemplateList(
        props?.clientId,
        '',
        '',
        true
      );
      setOption(
        response?.rows?.map(item => ({
          templateId: item?.quotesTemplateId,
          templateName: `${item?.title} - ${item?.templateTag?.tagName ? item?.templateTag?.tagName : ''}`,
        }))
      );
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getQuoteList(props?.clientId);
  }, []);

  return (
    <Dialog
      header="Select Quote Template"
      visible={props?.visible}
      style={{ width: '25vw' }}
      onHide={() => {
        if (!props?.visible) return;
        props?.setVisible(false);
      }}
      draggable={false}
      footer={
        <>
          <Button
            label="Close"
            text
            outlined
            onClick={() => props?.setVisible(false)}
          />
          <Button
            label="Proceed"
            onClick={() =>
              props?.history?.push(
                `/crm/${props?.customerId}/${props?.opportunityId}/${selectedTemplate?.templateId}/quote/create`
              )
            }
            disabled={!selectedTemplate}
          />
        </>
      }
      pt={{
        header: {
          className: 'pb-0',
        },
      }}
    >
      {loading ? (
        <>
          <Skeleton height="2rem" className="w-full md:w-14rem my-2"></Skeleton>
        </>
      ) : (
        <>
          <Dropdown
            options={option || []}
            optionLabel="templateName"
            placeholder="Select a template"
            className="w-full md:w-14rem mt-4"
            value={selectedTemplate}
            onChange={e => setSelectedTemplate(e?.value)}
            filter={true}
          />
        </>
      )}
    </Dialog>
  );
};

export default ImportFromQuoteTemplate;
