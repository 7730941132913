import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import { getActiveBulletin } from '../Admin/Bulletin/Bulletin.service';
import useToken from '../../hooks/useToken';
import PageHeader from '../shared/PageHeader/PageHeader';
import { momentTz } from '../../utils/Helpers';
import { BULLETIN } from '../../constants';

import { getProjectForceVideos } from './services/GetStarted.service';

const GetStarted = () => {
  const clientsBreadcrumb = [
    {
      text: 'Get Started',
      link: '/getstarted',
    },
  ];

  const { accessToken } = useToken();

  const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
      '&.MuiPaper-root': { border: 'none', borderRadius: '0px' },
      '& .video-container': {
        position: 'relative',
        width: '100%',
        paddingBottom: '56.25%',
        height: '0',
      },
      '& .video': {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        border: '0',
        borderRadius: '20px',
        padding: '12px',
      },
      '& .video-content': {
        paddingBottom: '16px',
      },
    },
    cover: {
      width: 151,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    imageList: {
      width: 500,
      height: 450,
    },
    media: {
      width: '100%',
    },
    responsiveVideo: {
      width: '100%',
      display: 'flex',
      maxWidth: '100%',
      overflow: 'hidden',
      borderRadius: '0px',
      flexDirection: 'column',
    },
    bulletinGrid: { border: '1px solid #c3c3c3', borderRadius: '25px' },
  }));
  const classes = useStyles();
  const [, setLoading] = useState();
  const [getVideos, setVideos] = useState([]);
  const [bulletinList, setBulletinList] = useState([]);
  useEffect(async () => {
    getProjectForceVideos(setLoading, setVideos);
    const bulletinList = await getActiveBulletin(
      BULLETIN.HOME,
      momentTz(moment()).utc().format('YYYY-MM-DD HH:mm:ss')
    );
    setBulletinList(bulletinList);
  }, []);

  let videoJSX = null;
  if (getVideos.length) {
    videoJSX = getVideos
      .filter(fresponse => fresponse?.id?.kind === 'youtube#video')
      .map(response => {
        return (
          <Grid
            item
            sm={4}
            key={response?.etag}
            className={classes.responsiveVideo}
          >
            <Link
              underline="none"
              component={RouterLink}
              to={`/getstarted/delete/${response?.id?.videoId}`}
            >
              <Card className={classes.root}>
                <CardActionArea className={'video-container'}>
                  <iframe
                    width="400"
                    height="140"
                    src={`https://www.youtube.com/embed/${response?.id?.videoId}`}
                    title={response?.snippet?.title}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    className={'video'}
                  ></iframe>
                </CardActionArea>
                <CardContent className={'video-content'}>
                  <Typography gutterBottom variant="h3" component="h2">
                    {response?.snippet?.title}
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>
        );
      });
  }

  const getBulletinMessage = bulletinObj => {
    if (bulletinObj?.is_important && bulletinObj?.url) {
      return (
        <>
          <div className="text-color col-10 md:col-6 lg:col-8">
            {bulletinObj?.message}
          </div>
          <div className="col-12 md:col-5 lg:col-3 text-right p-0">
            <p
              className="text-xs text-primary cursor-pointer"
              onClick={() => window.open(bulletinObj?.url, '_blank')}
              aria-label="Read more about the bulletin"
            >
              Read more
            </p>
          </div>
        </>
      );
    }
    if (bulletinObj?.is_important) {
      return <div className="col-11">{bulletinObj?.message}</div>;
    }
    if (bulletinObj?.url) {
      return (
        <>
          <div className="text-color col-10 md:col-6 lg:col-8">
            {bulletinObj?.message}
          </div>
          <div className="col-11 md:col-5 lg:col-3 text-right p-0">
            <p
              className="text-xs text-primary cursor-pointer"
              onClick={() => window.open(bulletinObj?.url, '_blank')}
            >
              Read more
            </p>
          </div>
        </>
      );
    }
    return <div className="col-11">{bulletinObj?.message}</div>;
  };
  return !accessToken ? (
    <>Please Log-in to access the application</>
  ) : (
    <>
      <Grid container spacing={2} direction="column">
        <Grid
          container
          item
          direction="row"
          justifyContent="space-between"
          spacing={2}
        >
          <Grid item>
            <PageHeader
              pageTitle="How it works"
              breadCrumbArray={clientsBreadcrumb}
            />
          </Grid>
        </Grid>
        <Grid container>
          {Array.isArray(bulletinList) && bulletinList?.length > 0 && (
            <Grid item sm={4} className="p-2 pr-0 bg-white">
              <div className="h-full overflow-auto max-h-16rem">
                <List className="p-0">
                  {bulletinList?.map(bulletin => (
                    <ListItem
                      alignItems="flex-start"
                      className="flex align-items-center"
                      key={bulletin?.message}
                    >
                      <div className="grid flex align-items-center w-12 border-bottom-1 border-100 pb-2">
                        <div className="col-1 pl-0">
                          <i className="pi pi-megaphone w-1rem text-xs text-primary"></i>
                        </div>
                        {getBulletinMessage(bulletin)}
                      </div>
                    </ListItem>
                  ))}
                </List>
              </div>
            </Grid>
          )}
          {videoJSX}
        </Grid>
      </Grid>
    </>
  );
};

export default GetStarted;
