import React, { useEffect, useState } from 'react';
import { NotificationProvider } from 'pf-web-notification-library';
import { useDispatch, useSelector } from 'react-redux';
import { ConfirmDialog } from 'primereact/confirmdialog';
import axios from 'axios';

import {
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_YOUR_AUTH_DOMAIN,
  REACT_APP_YOUR_PROJECT_ID,
  REACT_APP_YOUR_STORAGE_BUCKET,
  REACT_APP_YOUR_MESSAGING_SENDER_ID,
  REACT_APP_YOUR_APP_ID,
  REACT_APP_VAPID_KEY,
  REACT_APP_ENABlE_ONLY_FOR_PF_USER,
  REACT_APP_ENABLE_PUSH_NOTIFICATION,
} from '../../constants/envConstants';
import { URL_CONSTANTS } from '../../constants/urlConstants';
import {
  setCallNotification,
  setClientId,
  setTabs,
} from '../../redux/slices/page-header.slice';
import { redirectPage } from '../../utils/Helpers';
import {
  saveUserDevices,
  updateNotificationViewed,
} from '../shared/Topbar/Notification/Notification.service';
import { emailReviewRead } from '../ProjectManagement/EmailNotifications/EmailNotifications.service';
import { markMessageAsRead } from '../ProjectManagement/SMS/SmsNotifications.service';
import {
  storeUpdateReadStatus,
  installerUpdateReadStatus,
} from '../ProjectManagement/ProjectInfo/ProjectDetails.service';
import { Notifications } from '../../constants/notification.constant';
import clearMasterDataHook from '../../redux/slices/master/clearMasterDataHook';
import { setPermission } from '../../redux/slices/permission.slice';
import { useConfirmDialogContext } from '../../contexts/ConfirmDialog';

const PushNotificationProvider = ({ setLoading }) => {
  const { showConfirmationDialog } = useConfirmDialogContext();
  const dispatch = useDispatch();
  const isPFUser =
    JSON.parse(localStorage.getItem('isPFUser') ?? 'false') ?? false;
  let pushNotificationEnabled =
    REACT_APP_ENABlE_ONLY_FOR_PF_USER === false
      ? REACT_APP_ENABLE_PUSH_NOTIFICATION
      : isPFUser;
  const pageHeader = useSelector(state => state.pageHeader);
  const callNotification = pageHeader?.callNotification;
  const permissionsListReducer = useSelector(state => state.permissionsList);
  const tenantMapping = permissionsListReducer?.tenantMapping;
  const [confirmTabOpen, setConfirmTabOpen] = useState(false);
  const [confirmTenantSwitch, setConfirmTenantSwitch] = useState(false);
  const tabs = pageHeader?.tabs;
  const isIOS =
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  const isChrome = /CriOS/i.test(navigator.userAgent);
  if (isIOS === true && isChrome === true) {
    pushNotificationEnabled = false;
  }

  useEffect(() => {
    if (confirmTabOpen === true && confirmTenantSwitch === true) {
      const client_id = localStorage.getItem('client_name');
      if (!tabs?.[String(client_id)])
        dispatch(setTabs({ ...tabs, [String(client_id)]: [] }));

      if (history.location.pathname !== '/dashboard') {
        history.push('/dashboard');
        window.location.reload();
      } else {
        window.location.reload();
      }
      setConfirmTabOpen(false);
      setConfirmTenantSwitch(false);
    }
  }, [confirmTabOpen, confirmTenantSwitch]);

  const firebaseConfig = {
    apiKey: REACT_APP_FIREBASE_API_KEY,
    authDomain: REACT_APP_YOUR_AUTH_DOMAIN,
    projectId: REACT_APP_YOUR_PROJECT_ID,
    storageBucket: REACT_APP_YOUR_STORAGE_BUCKET,
    messagingSenderId: REACT_APP_YOUR_MESSAGING_SENDER_ID,
    appId: REACT_APP_YOUR_APP_ID,
  };

  const vapidKey = REACT_APP_VAPID_KEY;
  const clearMasterData = clearMasterDataHook();

  const getAllUserPermissionsForTenant = async tenant_id => {
    if (localStorage.getItem('user_id')) {
      try {
        const response = await axios.get(
          `${URL_CONSTANTS.LOGIN.baseUrl}/get-all-user-permissions-for-tenant?client_id=${tenant_id}&user_id=${localStorage.getItem('user_id')}`
        );
        if (response?.data) {
          dispatch(setPermission(response.data));
          localStorage.setItem('permissions', JSON.stringify(response?.data));
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const switchTenant = (client_id, data) => {
    clearMasterData();
    localStorage.setItem('client_id', client_id);
    const selectedClient = tenantMapping.filter(
      row => row.client_id === client_id
    );
    const client_name = selectedClient[0]['client_name'];
    localStorage.setItem('client_name', client_name);
    dispatch(setClientId(String(client_id)));
    getAllUserPermissionsForTenant(client_id).then(() => {
      handleNotificationRedirect(data);
      setConfirmTenantSwitch(true);
    });
  };

  const tenantSwitchReject = () => {
    //Nothing to do for now.
  };

  const tenantSwitchConfirm = (client_id, data) => {
    showConfirmationDialog({
      message:
        'If you continue your tenant will be updated and you will loose any unsaved changes. Do you want to continue?',
      header: 'Tenant Switch confirmation',
      icon: 'pi pi-exclamation-triangle',
      defaultFocus: 'accept',
      accept: () => switchTenant(client_id, data),
      reject: tenantSwitchReject,
    });
  };

  const saveFirebaseToken = async token => {
    if (token) {
      const client_id = localStorage.getItem('client_id');
      const user_id = localStorage.getItem('user_id');
      setLoading(true);
      try {
        await saveUserDevices(
          user_id,
          client_id,
          'web',
          navigator?.vendor,
          token
        );
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const setCallNotifications = value => {
    if (callNotification !== value) dispatch(setCallNotification(value));
  };

  const readInstallerNotes = (notification_id, project_id) => {
    installerUpdateReadStatus(
      notification_id,
      1,
      localStorage.getItem('user_id')
    );
    updateNotificationViewed(
      notification_id,
      Notifications.INSTALLER,
      localStorage.getItem('user_id'),
      true,
      false
    ).then(() => {
      dispatch(setCallNotification(true));
    });
    dispatch(setCallNotification(true));
    redirectPage(`/project/view/${project_id}/#tab=notes`);
    setConfirmTabOpen(true);
  };

  const readStoreNotes = (notification_id, project_id) => {
    storeUpdateReadStatus(notification_id, 3, localStorage.getItem('user_id'));
    updateNotificationViewed(
      notification_id,
      Notifications.STORE,
      localStorage.getItem('user_id'),
      true,
      false
    ).then(() => {
      dispatch(setCallNotification(true));
    });
    redirectPage(`/project/view/${project_id}/#tab=notes`);
    setConfirmTabOpen(true);
  };

  const readEmail = async (notification_id, project_id) => {
    await emailReviewRead(notification_id, 'read');
    dispatch(setCallNotification(true));
    redirectPage(`/project/view/${project_id}/#tab=email`);
    setConfirmTabOpen(true);
  };

  const readSMS = async (notification_id, project_id) => {
    await markMessageAsRead(notification_id, false);
    dispatch(setCallNotification(true));
    redirectPage(`/project/view/${project_id}/#tab=sms`);
    setConfirmTabOpen(true);
  };

  const handleNotificationRedirect = data => {
    const processredirect = {
      1: readInstallerNotes,
      2: readStoreNotes,
      3: readSMS,
      4: readEmail,
    };
    processredirect[Number(data.data.type)](
      data.data.notification_id,
      data.data.project_id
    );
  };

  const handleNotification = data => {
    if (
      localStorage.getItem('client_id').toLowerCase() ===
      data.data.client_id.toLowerCase()
    ) {
      handleNotificationRedirect(data);
    } else {
      tenantSwitchConfirm(data.data.client_id, data);
    }
  };

  const showHideNotification = data => {
    return (
      localStorage.getItem('client_id').toLowerCase() ===
      data.data.client_id.toLowerCase()
    );
  };

  return (
    <>
      {pushNotificationEnabled && (
        <NotificationProvider
          firebaseConfig={firebaseConfig}
          vapidKey={vapidKey}
          saveToken={saveFirebaseToken}
          triggerApi={setCallNotifications}
          handleClick={handleNotification}
          handleVisibility={showHideNotification}
        />
      )}
      <ConfirmDialog />
    </>
  );
};

export default PushNotificationProvider;
