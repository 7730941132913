import React from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { DataTable } from 'primereact/datatable';
import { RadioButton } from 'primereact/radiobutton';
import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';
import { Skeleton } from 'primereact/skeleton';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';

import {
  ALLOCATION_PROJECT_ITEMS,
  ItemLinkageConfigOptions,
  PF_ADMIN,
  SALES_COMMISSION_TYPE,
} from '../../../../constants';
import { getPercentageValue } from '../../../../utils/Helpers';
import PFCheckbox from '../../../shared/PFPrime/PFCheckbox';
import PFInputSwitch from '../../../shared/PFPrime/PFInputSwitch';
import OrderValidationConfig from '../../NewConfiguration/components/OrderValidationConfig';

import {
  ADDITIONAL_FEATURE_TYPE,
  commonPt,
  companyConfigTableOptions,
  JOB_ASSIGNMENT_ROTATION_VALUES,
  MFA_EXPIRE_IN_DAYS_OPTIONS,
  RESET_PASSWORD_DAYS_OPTIONS,
} from './../company-config.constant';
import SchedulerConfig from './../SchedularReportConfig/SchedularConfig/SchedualarConfig';
import {
  AdditionalFeaturesProps,
  CustomDropdownChangeEvent,
  RowData,
  CheckboxChangeEvent,
  InputNumberChangeEvent,
} from './AdditionalFeatures.types';

const AdditionalFeatures: React.FC<AdditionalFeaturesProps> = ({
  loading,
  allocationPermission,
  allocationOption,
  handleRadioButton,
  setAllocationPermission,
  setBtnDisables,
  allocationPercentage,
  setAllocationPercentage,
  formik,
  convertString,
  btnDisables,
  client_id,
  pfClientsId,
  forceSyncDisabled,
  groupSourceOpt,
  responseData,
  categoryOptions,
  handleCheckbox,
  featureList,
  openModal,
  setOpenModal,
}) => {
  const getAutoAssignTechniciansValue = (event: { checked?: boolean }) => {
    if (!event?.checked) return 0;
    if (
      formik?.values?.auto_assign_technicians_value &&
      formik?.values?.auto_assign_technicians_value !== '0'
    ) {
      return formik?.values?.auto_assign_technicians_value;
    } else if (
      responseData?.auto_assign_technicians_value &&
      responseData?.auto_assign_technicians_value !== '0'
    ) {
      return responseData?.auto_assign_technicians_value;
    } else {
      return JOB_ASSIGNMENT_ROTATION_VALUES[0]?.value;
    }
  };

  const schedulingAssignmentColumnsData = [
    companyConfigTableOptions,
    {
      ...(!loading
        ? {
            body: (rowData: RowData) => {
              if (
                rowData.type ===
                ADDITIONAL_FEATURE_TYPE.SKIP_TECHNICIAN_ASSIGNMENT_CLONING
              ) {
                return (
                  <div className="col-3 p-0">
                    <PFCheckbox
                      id="skip_technician_assignment_cloning"
                      name="skip_technician_assignment_cloning"
                      checked={
                        formik.values.skip_technician_assignment_cloning == true
                      }
                      onChange={(event: { checked: boolean }) => {
                        formik.setFieldValue(
                          'skip_technician_assignment_cloning',
                          event?.checked ? 1 : 0
                        );
                        setBtnDisables(false);
                      }}
                      className="pt-1"
                    ></PFCheckbox>
                  </div>
                );
              }
              if (rowData?.type === ADDITIONAL_FEATURE_TYPE.SCHEDULER_CONFIG) {
                return (
                  <SchedulerConfig
                    formik={formik}
                    setBtnDisables={setBtnDisables}
                    btnDisables={btnDisables}
                  />
                );
              }
              if (rowData.type === ADDITIONAL_FEATURE_TYPE.FIXED_TOP_BAR) {
                return (
                  <div className="col-3 p-0">
                    <PFCheckbox
                      id="fixed_top_bar"
                      name="fixed_top_bar"
                      checked={formik.values.fixed_top_bar == 1}
                      onChange={(event: { checked: boolean }) => {
                        formik.setFieldValue(
                          'fixed_top_bar',
                          event.checked ? 1 : 0
                        );
                        setBtnDisables(false);
                      }}
                      className="pt-1"
                    ></PFCheckbox>
                  </div>
                );
              }
              if (rowData.type === ADDITIONAL_FEATURE_TYPE.FIXED_PROJECT_BAR) {
                return (
                  <div className="col-3 p-0">
                    <PFCheckbox
                      id="fixed_project_bar"
                      name="fixed_project_bar"
                      checked={formik.values.fixed_project_bar == 1}
                      onChange={(event: { checked: boolean }) => {
                        formik.setFieldValue(
                          'fixed_project_bar',
                          event.checked ? 1 : 0
                        );
                        setBtnDisables(false);
                      }}
                      className="pt-1"
                    ></PFCheckbox>
                  </div>
                );
              }
              if (rowData?.type === ADDITIONAL_FEATURE_TYPE.RELATED_POS) {
                return (
                  <div className="col-8 pl-0 py-0">
                    <Button
                      label="Configure"
                      size="small"
                      outlined
                      onClick={() => {
                        window.open(
                          `/admin/company/configuration/related-po/edit/${client_id || pfClientsId}`,
                          '_blank'
                        );
                      }}
                    />
                  </div>
                );
              }

              if (
                rowData.type === ADDITIONAL_FEATURE_TYPE.ALLOCATION_BEHAVIOUR
              ) {
                return (
                  <div>
                    {ALLOCATION_PROJECT_ITEMS.map((item, index) => (
                      <div
                        key={index}
                        className="col-6 md:col-6 lg:col-12 my-1 py-0 px-0 min-h-32 align-items-center flex"
                      >
                        <div className="flex align-items-center">
                          <RadioButton
                            value={item}
                            checked={item === allocationPermission}
                            onChange={e => {
                              handleRadioButton(e?.target?.value, true);
                              setAllocationPermission(e?.target?.value);
                              setBtnDisables(false);
                            }}
                          />
                          <label
                            htmlFor={`${index}`}
                            className="ml-2 py-0 text-sm"
                          >
                            {convertString(item)}
                          </label>
                          {item === 'CUSTOM_ALLOCATION' && allocationOption && (
                            <InputNumber
                              id="allocation_percentage"
                              className="w-36 ml-4"
                              value={allocationPercentage}
                              onChange={(event: InputNumberChangeEvent) => {
                                const percentage = getPercentageValue(
                                  event?.value
                                );
                                setAllocationPercentage(percentage);
                                formik.setFieldValue(
                                  'allocation_percentage',
                                  percentage
                                );
                                setBtnDisables(false);
                              }}
                              placeholder="%"
                              suffix={'%'}
                              max={100}
                              maxLength={6}
                              min={0}
                              pt={{
                                root: {
                                  className: 'h-2rem pb-2',
                                },
                                input: {
                                  root: {
                                    className:
                                      'w-full border-noround border-bottom-1 border-top-none border-x-none p-0 shadow-none border-gray-500 ',
                                  },
                                },
                              }}
                            />
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                );
              }

              if (
                rowData.type === ADDITIONAL_FEATURE_TYPE.EPA_GUARDRAILS_WORKFLOW
              ) {
                return (
                  <div className="col-3 p-0">
                    <PFCheckbox
                      id="epa_guardrails_workflow"
                      name="epa_guardrails_workflow"
                      checked={
                        formik.values.epa_guardrails_workflow == 1
                          ? true
                          : false
                      }
                      onChange={(event: { checked: boolean }) => {
                        formik.setFieldValue(
                          'epa_guardrails_workflow',
                          event.checked ? 1 : 0
                        );
                        setBtnDisables(false);
                      }}
                    ></PFCheckbox>
                  </div>
                );
              }
              if (
                rowData?.type ===
                ADDITIONAL_FEATURE_TYPE.ORDER_COMPLETION_VALIDATION
              ) {
                return (
                  <div className="col-12 py-0 px-0 flex">
                    <div className="col-3 pl-0">
                      <PFCheckbox
                        id="order_completion_validation"
                        name="order_completion_validation"
                        checked={
                          formik?.values?.order_completion_validation
                            ?.enabled == true
                        }
                        onChange={(event: { checked: boolean }) => {
                          formik.setFieldValue(
                            'order_completion_validation.enabled',
                            event.checked ? 1 : 0
                          );
                          formik.handleSubmit();
                          setBtnDisables(false);
                        }}
                        className="pt-1"
                      ></PFCheckbox>
                    </div>
                    <div className="col-8">
                      <Button
                        label="Configure"
                        size="small"
                        outlined
                        onClick={() => {
                          setOpenModal(true);
                        }}
                        disabled={
                          formik?.values?.order_completion_validation
                            ?.enabled == false
                        }
                      />
                    </div>
                    {openModal && (
                      <OrderValidationConfig
                        openModal={openModal}
                        setOpenModal={setOpenModal}
                      />
                    )}
                  </div>
                );
              }

              if (
                rowData?.type ===
                ADDITIONAL_FEATURE_TYPE.AUTO_ASSIGN_TECHNICIANS
              ) {
                return (
                  <div className="grid flex align-items-center">
                    <div className="col-3">
                      <PFCheckbox
                        id="auto_assign_technicians"
                        name="auto_assign_technicians"
                        onChange={(event: { checked: boolean }) => {
                          formik.setFieldValue(
                            'auto_assign_technicians',
                            event?.checked
                          );
                          formik.setFieldValue(
                            'auto_assign_technicians_value',
                            getAutoAssignTechniciansValue(event)
                          );
                          setBtnDisables(false);
                        }}
                        checked={
                          formik?.values?.auto_assign_technicians == 1
                            ? true
                            : false
                        }
                      />
                    </div>
                    <div className="col-6">
                      <span className="p-float-label">
                        <Dropdown
                          id="auto_assign_technicians_value"
                          name="auto_assign_technicians_value"
                          options={JOB_ASSIGNMENT_ROTATION_VALUES || []}
                          optionLabel="value"
                          onChange={(event: CustomDropdownChangeEvent) => {
                            formik.setFieldValue(
                              'auto_assign_technicians_value',
                              event?.value
                            );
                            setBtnDisables(false);
                          }}
                          value={formik?.values?.auto_assign_technicians_value}
                          className="w-12"
                          disabled={
                            formik?.values?.auto_assign_technicians == 1
                              ? false
                              : true
                          }
                        />
                        <label htmlFor="auto_assign_technicians_value">
                          Auto Assign Technicians
                        </label>
                      </span>
                    </div>
                  </div>
                );
              }
              return (
                <PFCheckbox
                  inputId={`checkbox_${String(rowData.key)}`}
                  onChange={(e: { checked: boolean }) => {
                    handleCheckbox(String(rowData?.key), e?.checked);
                    setBtnDisables(false);
                  }}
                  checked={rowData.checked}
                />
              );
            },
          }
        : {}),
    },
  ];

  const projectLinkageColumnsData = [
    companyConfigTableOptions,
    {
      ...(!loading
        ? {
            body: (rowData: RowData) => {
              if (
                rowData.type ===
                ADDITIONAL_FEATURE_TYPE.ITEM_LINKAGE_CONFIGURATION
              ) {
                return (
                  <div className="grid flex align-items-center">
                    <div className="col-3">
                      <div className="flex align-items-center">
                        <PFCheckbox
                          id="EnabledOrDisabled"
                          name="EnabledOrDisabled"
                          onChange={(event: { checked: boolean }) => {
                            formik.setFieldValue(
                              'project_linkage.enabled',
                              event?.checked
                            );
                            setBtnDisables(false);
                          }}
                          checked={
                            formik?.values?.project_linkage?.enabled || false
                          }
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <MultiSelect
                        value={formik?.values?.project_linkage?.options || []}
                        onChange={e => {
                          formik.setFieldValue(
                            'project_linkage.options',
                            e?.value
                          );
                          setBtnDisables(false);
                        }}
                        options={ItemLinkageConfigOptions || []}
                        optionLabel="name"
                        placeholder="Set Default Configuration"
                        maxSelectedLabels={3}
                        className="w-full"
                        disabled={!formik?.values?.project_linkage?.enabled}
                        pt={{
                          label: {
                            className: 'text-sm',
                          },
                        }}
                      />
                    </div>
                    <div className="col-3">
                      <div className="flex align-items-center">
                        <PFCheckbox
                          id="ForceSync"
                          name="ForceSync"
                          onChange={(event: { checked: boolean }) => {
                            formik.setFieldValue(
                              'project_linkage.force_sync',
                              event?.checked
                            );
                            setBtnDisables(false);
                          }}
                          checked={
                            formik?.values?.project_linkage?.force_sync || false
                          }
                          disabled={
                            forceSyncDisabled ||
                            !formik?.values?.project_linkage?.enabled
                          }
                        />
                        <label htmlFor="ForceSync" className="ml-2">
                          Force Sync
                        </label>
                      </div>
                    </div>
                  </div>
                );
              }
              if (
                rowData?.type === ADDITIONAL_FEATURE_TYPE.GROUP_SOURCE_CATEGORY
              ) {
                return (
                  <div className="col-12 py-0 px-0 flex">
                    <div className="col-3 p-0 flex align-items-center">
                      <PFCheckbox
                        id="group_source_category_opted"
                        name="group_source_category_opted"
                        checked={formik.values.group_source_category_opted == 1}
                        onChange={(event: { checked: boolean }) => {
                          formik.setFieldValue(
                            'group_source_category_opted',
                            event.checked ? 1 : 0
                          );
                          formik.handleSubmit();
                          setBtnDisables(false);
                        }}
                      ></PFCheckbox>
                    </div>
                    <div className="col-8 p-0">
                      <Button
                        label="Configure"
                        size="small"
                        outlined
                        onClick={() => {
                          window.open(
                            `/admin/configuration/group-source-category`,
                            '_blank'
                          );
                        }}
                        disabled={
                          groupSourceOpt === false ||
                          formik?.values?.group_source_category_opted === 0
                        }
                      />
                    </div>
                  </div>
                );
              }
              if (rowData?.type === ADDITIONAL_FEATURE_TYPE.Audit_log) {
                return (
                  <>
                    <div className="col-6 md:col-9 lg:col-9 flex flex-start p-0">
                      <Button
                        label="Configure"
                        size="small"
                        onClick={() =>
                          window.open(
                            `/audit-log-reports/${client_id || pfClientsId}`,
                            '_blank'
                          )
                        }
                      />
                    </div>
                  </>
                );
              }

              return (
                <PFCheckbox
                  inputId={`checkbox_${String(rowData?.key)}`}
                  onChange={(e: { checked: boolean }) => {
                    handleCheckbox(String(rowData?.key), e?.checked);
                    setBtnDisables(false);
                  }}
                  checked={rowData.checked}
                />
              );
            },
          }
        : {}),
    },
  ];

  const productInventoryColumnsData = [
    companyConfigTableOptions,
    {
      ...(!loading
        ? {
            body: (rowData: RowData) => {
              if (
                rowData.type ===
                ADDITIONAL_FEATURE_TYPE.SHIPPING_AND_RECEIVING_MODULE
              ) {
                return (
                  <div className="col-3 p-0">
                    <PFCheckbox
                      id="shipping_receiving"
                      name="shipping_receiving"
                      checked={formik.values.shipping_receiving == 1}
                      onChange={(event: { checked: boolean }) => {
                        formik.setFieldValue(
                          'shipping_receiving',
                          event.checked ? 1 : 0
                        );
                        setBtnDisables(false);
                      }}
                      className="pt-1"
                    ></PFCheckbox>
                  </div>
                );
              }
              return (
                <PFCheckbox
                  inputId={`checkbox_${rowData?.key}`}
                  onChange={(e: { checked: boolean }) => {
                    handleCheckbox(String(rowData?.key), e?.checked);
                    setBtnDisables(false);
                  }}
                  checked={rowData.checked}
                />
              );
            },
          }
        : {}),
    },
  ];

  const salesCommissionColumnsData = [
    companyConfigTableOptions,
    {
      ...(!loading
        ? {
            body: (rowData: RowData) => {
              if (
                rowData.type === ADDITIONAL_FEATURE_TYPE.SALES_COMMISSION_OPTED
              ) {
                return (
                  <div className="col-12 py-0 px-0 flex">
                    <div className="col-3 p-0 flex align-items-center">
                      <PFCheckbox
                        id="sales_commission_opted"
                        name="sales_commission_opted"
                        checked={formik.values.sales_commission_opted == 1}
                        onChange={(event: { checked: boolean }) => {
                          formik.setFieldValue(
                            'sales_commission_opted',
                            event.checked ? 1 : 0
                          );
                          if (!event?.checked) {
                            formik.setFieldValue(
                              'sales_commission_criteria',
                              ''
                            );
                          }
                          if (
                            event.checked &&
                            !!formik.values?.sales_commission_criteria
                          ) {
                            setBtnDisables(false);
                          } else if (!event.checked) {
                            setBtnDisables(false);
                          } else {
                            setBtnDisables(true);
                          }
                        }}
                      ></PFCheckbox>
                    </div>
                    {formik?.values?.sales_commission_opted == 1 && (
                      <div className="col-8 p-0">
                        <span className="p-float-label">
                          <Dropdown
                            id="sales_commission_criteria"
                            name="sales_commission_criteria"
                            options={SALES_COMMISSION_TYPE}
                            optionLabel="label"
                            optionValue="value"
                            onChange={(event: CustomDropdownChangeEvent) => {
                              formik.setFieldValue(
                                event?.target?.name,
                                event?.value
                              );
                              setBtnDisables(false);
                            }}
                            className="w-12 xl:w-6"
                            value={formik?.values?.sales_commission_criteria}
                          ></Dropdown>
                          <label htmlFor="salesCommissionCriteria">
                            Sales Commission Criteria
                            <span className="text-red-500">*</span>
                          </label>
                        </span>
                      </div>
                    )}
                  </div>
                );
              }
              if (rowData.type === ADDITIONAL_FEATURE_TYPE.COPY_TOTAL_SALE) {
                return (
                  <div className="col-3 p-0">
                    <PFCheckbox
                      id="copy_total_sales"
                      name="copy_total_sales"
                      checked={formik.values.copy_total_sales == 1}
                      onChange={(event: { checked: boolean }) => {
                        formik.setFieldValue(
                          'copy_total_sales',
                          event.checked ? 1 : 0
                        );
                        setBtnDisables(false);
                      }}
                      className="pt-1"
                    ></PFCheckbox>
                  </div>
                );
              }
            },
          }
        : {}),
    },
  ];

  const financePaymentColumnsData = [
    companyConfigTableOptions,
    {
      ...(!loading
        ? {
            body: (rowData: RowData) => {
              if (
                rowData.type ===
                  ADDITIONAL_FEATURE_TYPE.PAYMENT_CONFIGURATION &&
                (pfClientsId === PF_ADMIN || formik?.values?.crm_module === 1)
              ) {
                return (
                  <div className="grid grid-nogutter">
                    <div className="col-12 md:col-4 lg:col-6 py-0 flex align-items-center">
                      {loading ? (
                        <Skeleton className="w-2" height="30px" />
                      ) : (
                        <PFInputSwitch
                          name="pf-Inclusive"
                          size="small"
                          onChange={(event: InputNumberChangeEvent) => {
                            formik.setFieldValue(
                              'is_line_item_visible',
                              event?.value ?? ''
                            );
                            setBtnDisables(false);
                          }}
                          checked={
                            Number(formik.values?.is_line_item_visible)
                              ? true
                              : false
                          }
                          className="align-items-center mx-2"
                        />
                      )}
                      <span className="p-float-label">
                        Show line items to customer
                      </span>
                    </div>

                    <div className="col-12 md:col-8 lg:col-6 py-0 flex align-items-center justify-content-center ">
                      <label
                        htmlFor="due_days"
                        className="w-3 md:w-12 text-right mr-2 text-xs"
                      >
                        Payment Due Days:
                      </label>
                      {loading ? (
                        <Skeleton className="w-full" height="40px" />
                      ) : (
                        <InputNumber
                          id="due_days"
                          className="w-5"
                          value={formik.values.due_days}
                          onChange={(event: InputNumberChangeEvent) => {
                            formik.setFieldValue(
                              'due_days',

                              event?.value ?? ''
                            );
                            setBtnDisables(false);
                          }}
                          inputClassName="w-11"
                        />
                      )}
                    </div>
                  </div>
                );
              }
            },
          }
        : {}),
    },
  ];

  const userAccessSecurityColumnsData = [
    companyConfigTableOptions,
    {
      ...(!loading
        ? {
            body: (rowData: RowData) => {
              if (
                rowData.type ===
                ADDITIONAL_FEATURE_TYPE.RESET_PASSWORD_EXPIRE_IN
              ) {
                return (
                  <div className="col-12 md:col-4 lg:col-4 py-0">
                    <span className="p-float-label">
                      <Dropdown
                        id="reset_password_in_days"
                        name="reset_password_in_days"
                        value={Number(formik.values.reset_password_in_days)}
                        options={RESET_PASSWORD_DAYS_OPTIONS}
                        optionValue="value"
                        onChange={(event: CustomDropdownChangeEvent) => {
                          formik.setFieldValue(
                            event?.target?.name,
                            event?.value
                          );
                          setBtnDisables(false);
                        }}
                        className="w-full"
                        optionLabel="label"
                        placeholder="Select days"
                      />
                      <label htmlFor="reset_password_in_days">Days</label>
                    </span>
                    {formik.touched.reset_password_in_days &&
                    formik.errors.reset_password_in_days ? (
                      <div className="text-red-500 text-xs">
                        {formik.errors.reset_password_in_days}
                      </div>
                    ) : null}
                  </div>
                );
              }

              if (rowData.type === ADDITIONAL_FEATURE_TYPE.MFA_EXPIRE_IN_DAYS) {
                return (
                  <div className="col-12 md:col-4 lg:col-4 py-0">
                    <span className="p-float-label">
                      <Dropdown
                        id="mfa_expire_in_days"
                        name="mfa_expire_in_days"
                        value={Number(formik.values.mfa_expire_in_days)}
                        options={MFA_EXPIRE_IN_DAYS_OPTIONS}
                        optionValue="value"
                        onChange={(event: CustomDropdownChangeEvent) => {
                          formik.setFieldValue(
                            event?.target?.name,
                            event?.value
                          );
                          setBtnDisables(false);
                        }}
                        className="w-full"
                        optionLabel="label"
                        placeholder="Select days"
                      />
                      <label htmlFor="mfa_expire_in_days">Days</label>
                    </span>
                    {formik.touched.mfa_expire_in_days &&
                    formik.errors.mfa_expire_in_days ? (
                      <div className="text-red-500 text-xs">
                        {formik.errors.mfa_expire_in_days}
                      </div>
                    ) : null}
                  </div>
                );
              }
            },
          }
        : {}),
    },
  ];

  const workflowApprovalColumnsData = [
    companyConfigTableOptions,
    {
      ...(!loading
        ? {
            body: (rowData: RowData) => {
              if (
                rowData.type ===
                ADDITIONAL_FEATURE_TYPE.CHARGEBACK_APPROVAL_MODULE
              ) {
                return (
                  <div className="col-3 p-0">
                    <PFCheckbox
                      id="chargeback_approval_module"
                      name="chargeback_approval_module"
                      checked={formik.values.chargeback_approval_module == 1}
                      onChange={(event: { checked: boolean }) => {
                        formik.setFieldValue(
                          'chargeback_approval_module',
                          event.checked ? 1 : 0
                        );
                        setBtnDisables(false);
                      }}
                      className="pt-1"
                    ></PFCheckbox>
                  </div>
                );
              }

              if (
                rowData.type ===
                ADDITIONAL_FEATURE_TYPE.DOCUMENT_AUTO_PULL_RETRY
              ) {
                return (
                  <div className="grid grid-nogutter flex align-items-center">
                    <div className="col-3">
                      <div className="flex align-items-center">
                        <PFCheckbox
                          id="document_auto_pull_retry"
                          name="document_auto_pull_retry"
                          onChange={(e: CheckboxChangeEvent) => {
                            formik.setFieldValue(
                              'document_auto_pull_retry.enabled',
                              e?.checked ? 1 : 0
                            );
                            setBtnDisables(false);
                          }}
                          checked={
                            formik?.values?.document_auto_pull_retry?.enabled ==
                            true
                          }
                          tooltip="This feature will pull docs for Lowes source system"
                        />
                      </div>
                    </div>
                    <div className="col-9 md:col-6">
                      <MultiSelect
                        value={
                          formik?.values?.document_auto_pull_retry?.options ||
                          []
                        }
                        onChange={e => {
                          formik.setFieldValue(
                            'document_auto_pull_retry.options',
                            e?.value
                          );
                          setBtnDisables(false);
                        }}
                        filter
                        options={categoryOptions || []}
                        optionLabel="name"
                        placeholder="Set Category"
                        maxSelectedLabels={3}
                        className="w-full md:w-20rem"
                        disabled={
                          !formik?.values?.document_auto_pull_retry?.enabled
                        }
                        pt={{
                          label: {
                            className: 'text-sm',
                          },
                        }}
                      />
                    </div>
                  </div>
                );
              }

              if (
                rowData.type === ADDITIONAL_FEATURE_TYPE.HIGHLIGHT_STORE_NOTES
              ) {
                return (
                  <div className="col-3 p-0">
                    <PFCheckbox
                      id="highlight_store_notes"
                      name="highlight_store_notes"
                      checked={formik.values.highlight_store_notes == true}
                      onChange={(event: { checked: boolean }) => {
                        formik.setFieldValue(
                          'highlight_store_notes',
                          event.checked ? 1 : 0
                        );
                        setBtnDisables(false);
                      }}
                      className="pt-1"
                    ></PFCheckbox>
                  </div>
                );
              }

              if (
                rowData.type ===
                ADDITIONAL_FEATURE_TYPE.DO_NOT_OVERRIDE_LABOR_COST
              ) {
                return (
                  <div className="col-3 p-0">
                    <PFCheckbox
                      id="do_not_override_labor_cost"
                      name="do_not_override_labor_cost"
                      checked={formik.values.do_not_override_labor_cost == true}
                      onChange={(event: { checked: boolean }) => {
                        formik.setFieldValue(
                          'do_not_override_labor_cost',
                          event.checked ? 1 : 0
                        );
                        setBtnDisables(false);
                      }}
                      className="pt-1"
                    ></PFCheckbox>
                  </div>
                );
              }

              if (
                rowData.type ===
                ADDITIONAL_FEATURE_TYPE.ASSIGN_TECHNICIAN_ON_REVENUE_EXCLUDED_ITEMS
              ) {
                return (
                  <div className="col-3 p-0">
                    <PFCheckbox
                      id="assign_technician_on_revenue_excluded_items"
                      name="assign_technician_on_revenue_excluded_items"
                      checked={
                        formik.values
                          .assign_technician_on_revenue_excluded_items == true
                      }
                      onChange={(event: { checked: boolean }) => {
                        formik.setFieldValue(
                          'assign_technician_on_revenue_excluded_items',
                          event.checked ? 1 : 0
                        );
                        setBtnDisables(false);
                      }}
                      className="pt-1"
                    ></PFCheckbox>
                  </div>
                );
              }
              if (
                rowData.type ===
                ADDITIONAL_FEATURE_TYPE.SEND_TENTATIVE_SCHEDULE_NOTE_TO_SOURCE
              ) {
                return (
                  <div className="col-3 p-0">
                    <PFCheckbox
                      id="send_tentative_schedule_note_to_source"
                      name="send_tentative_schedule_note_to_source"
                      checked={
                        formik.values.send_tentative_schedule_note_to_source ==
                        true
                      }
                      onChange={(event: { checked: boolean }) => {
                        formik.setFieldValue(
                          'send_tentative_schedule_note_to_source',
                          event.checked ? 1 : 0
                        );
                        setBtnDisables(false);
                      }}
                      className="pt-1"
                    ></PFCheckbox>
                  </div>
                );
              }
              return (
                <PFCheckbox
                  inputId={`checkbox_${rowData.key}`}
                  onChange={(e: { checked: boolean }) => {
                    handleCheckbox(String(rowData.key), e?.checked);
                    setBtnDisables(false);
                  }}
                  checked={rowData.checked}
                />
              );
            },
          }
        : {}),
    },
  ];

  const customizationColumnsData = [
    companyConfigTableOptions,
    {
      ...(!loading
        ? {
            body: (rowData: RowData) => {
              if (
                rowData?.type === ADDITIONAL_FEATURE_TYPE.PROJECT_COLOR_CODE
              ) {
                return (
                  <Button
                    label="Configure"
                    size="small"
                    outlined
                    onClick={() => {
                      window.open(`/project-color-code`, '_blank');
                    }}
                  />
                );
              }

              if (
                rowData.type ===
                ADDITIONAL_FEATURE_TYPE.SHOW_LINE_ITEMS_ON_DOCUMENTS
              ) {
                return (
                  <div className="col-3 p-0">
                    <PFCheckbox
                      id="show_line_items_on_documents"
                      name="show_line_items_on_documents"
                      checked={formik.values.show_line_items_on_documents == 1}
                      onChange={(event: { checked: boolean }) => {
                        formik.setFieldValue(
                          'show_line_items_on_documents',
                          event.checked ? 1 : 0
                        );
                        setBtnDisables(false);
                      }}
                      className="pt-1"
                    ></PFCheckbox>
                  </div>
                );
              }
            },
          }
        : {}),
    },
  ];

  const schedulingAssignmentFields = [
    featureList[1],
    {
      type: ADDITIONAL_FEATURE_TYPE.SKIP_TECHNICIAN_ASSIGNMENT_CLONING,
      label: 'Skip Technician Assignment while Cloning',
    },
    {
      type: ADDITIONAL_FEATURE_TYPE.SCHEDULER_CONFIG,
      label: 'Scheduler',
    },
    {
      type: ADDITIONAL_FEATURE_TYPE.FIXED_TOP_BAR,
      label: 'Lock Global Bar',
    },
    {
      type: ADDITIONAL_FEATURE_TYPE.FIXED_PROJECT_BAR,
      label: 'Lock Project Details Bar',
    },
    {
      type: ADDITIONAL_FEATURE_TYPE.RELATED_POS,
      label: 'Related POs',
    },
    {
      type: ADDITIONAL_FEATURE_TYPE.ALLOCATION_BEHAVIOUR,
      label: 'Set Default Allocation Behaviour',
    },
    {
      type: ADDITIONAL_FEATURE_TYPE.EPA_GUARDRAILS_WORKFLOW,
      label: 'EPA Guardrails Workflow',
    },
    {
      type: ADDITIONAL_FEATURE_TYPE.ORDER_COMPLETION_VALIDATION,
      label: 'Order Completion Validation',
    },
    {
      type: ADDITIONAL_FEATURE_TYPE.AUTO_ASSIGN_TECHNICIANS,
      label: 'Auto Assign Technicians',
    },
  ];

  const projectLinkageFields = [
    {
      type: ADDITIONAL_FEATURE_TYPE.ITEM_LINKAGE_CONFIGURATION,
      label: 'Project Linkage Module',
    },
    {
      type: ADDITIONAL_FEATURE_TYPE.GROUP_SOURCE_CATEGORY,
      label: 'Group Source Category',
    },

    featureList[0],
    featureList[3],
    {
      type: ADDITIONAL_FEATURE_TYPE.Audit_log,
      label: 'Audit Log',
    },
  ];

  const productInventoryFields = [
    featureList[2],
    {
      type: ADDITIONAL_FEATURE_TYPE.SHIPPING_AND_RECEIVING_MODULE,
      label: 'Shipping and Receiving',
    },
  ];

  const salesCommissionFields = [
    {
      type: ADDITIONAL_FEATURE_TYPE.SALES_COMMISSION_OPTED,
      label: 'Sales Commission Opted',
    },
    {
      type: ADDITIONAL_FEATURE_TYPE.COPY_TOTAL_SALE,
      label: 'Copy Total Sales',
    },
  ];

  const financialPaymentFields = [
    ...(pfClientsId === PF_ADMIN || formik.values.crm_module === 1
      ? [
          {
            type: ADDITIONAL_FEATURE_TYPE.PAYMENT_CONFIGURATION,
            label: 'Payment Configuration',
          },
        ]
      : []),
  ];

  const userAccessSecurityFields = [
    {
      type: ADDITIONAL_FEATURE_TYPE.RESET_PASSWORD_EXPIRE_IN,
      label: 'Reset Password In Days',
    },
    {
      type: ADDITIONAL_FEATURE_TYPE.MFA_EXPIRE_IN_DAYS,
      label: 'MFA expire In Days',
    },
  ];

  const workflowApprovalFields = [
    {
      type: ADDITIONAL_FEATURE_TYPE.CHARGEBACK_APPROVAL_MODULE,
      label: 'Chargeback Approval Module',
    },
    {
      type: ADDITIONAL_FEATURE_TYPE.DOCUMENT_AUTO_PULL_RETRY,
      label: 'Document Auto Pull and Retry',
    },
    {
      type: ADDITIONAL_FEATURE_TYPE.HIGHLIGHT_STORE_NOTES,
      label: 'Highlight Store Notes',
    },
    {
      type: ADDITIONAL_FEATURE_TYPE.DO_NOT_OVERRIDE_LABOR_COST,
      label: 'Do Not Override Labor Cost',
    },

    {
      type: ADDITIONAL_FEATURE_TYPE.SEND_TENTATIVE_SCHEDULE_NOTE_TO_SOURCE,
      label: 'Send Tentative Schedule Note to Source',
    },
    {
      type: ADDITIONAL_FEATURE_TYPE.ASSIGN_TECHNICIAN_ON_REVENUE_EXCLUDED_ITEMS,
      label: 'Assign Technician On Revenue Excluded Items',
    },
  ];

  const visualEnhancementsFields = [
    {
      type: ADDITIONAL_FEATURE_TYPE.PROJECT_COLOR_CODE,
      label: 'Color Code',
    },
    {
      type: ADDITIONAL_FEATURE_TYPE.SHOW_LINE_ITEMS_ON_DOCUMENTS,
      label: 'Show Line Items On Documents',
    },
  ];

  return (
    <div className="card bg-white">
      <TabView
        pt={{
          root: {
            className: 'flex-wrap lg:flex',
          },
          navContainer: {
            className: 'w-12 lg:w-3',
          },
          panelContainer: {
            className: 'w-12 lg:w-9',
          },

          nav: {
            className: 'flex-column',
          },
        }}
        activeIndex={0}
      >
        <TabPanel
          header="Scheduling & Assignment"
          pt={{
            ...commonPt,
          }}
        >
          <DataTable
            value={schedulingAssignmentFields}
            className="p-datatable-striped"
          >
            {schedulingAssignmentColumnsData?.map((col, index) => (
              <Column key={index} {...col} />
            ))}
          </DataTable>
        </TabPanel>

        <TabPanel
          header="Project Management & Linkage"
          pt={{
            ...commonPt,
          }}
        >
          <DataTable
            value={projectLinkageFields}
            className="p-datatable-striped"
          >
            {projectLinkageColumnsData?.map((col, index) => (
              <Column key={index} {...col} />
            ))}
          </DataTable>
        </TabPanel>

        <TabPanel
          header="Product & Inventory Management"
          pt={{
            ...commonPt,
          }}
        >
          <DataTable
            value={productInventoryFields}
            className="p-datatable-striped"
          >
            {productInventoryColumnsData?.map((col, index) => (
              <Column key={index} {...col} />
            ))}
          </DataTable>
        </TabPanel>

        <TabPanel
          header="Sales & Commission Tracking"
          pt={{
            ...commonPt,
          }}
        >
          <DataTable
            value={salesCommissionFields}
            className="p-datatable-striped"
          >
            {salesCommissionColumnsData?.map((col, index) => (
              <Column key={index} {...col} />
            ))}
          </DataTable>
        </TabPanel>

        {(pfClientsId === PF_ADMIN || formik?.values?.crm_module === 1) && (
          <TabPanel
            header="Financial & Payment Configurations"
            pt={{
              ...commonPt,
            }}
          >
            <DataTable
              value={financialPaymentFields}
              className="p-datatable-striped"
            >
              {financePaymentColumnsData?.map((col, index) => (
                <Column key={index} {...col} />
              ))}
            </DataTable>
          </TabPanel>
        )}
        <TabPanel
          header="User Access & Security"
          pt={{
            ...commonPt,
          }}
        >
          <DataTable
            value={userAccessSecurityFields}
            className="p-datatable-striped"
          >
            {userAccessSecurityColumnsData?.map((col, index) => (
              <Column key={index} {...col} />
            ))}
          </DataTable>
        </TabPanel>

        <TabPanel
          header="Workflow & Approval"
          pt={{
            ...commonPt,
          }}
        >
          <DataTable
            value={workflowApprovalFields}
            className="p-datatable-striped"
          >
            {workflowApprovalColumnsData?.map((col, index) => (
              <Column key={index} {...col} />
            ))}
          </DataTable>
        </TabPanel>

        <TabPanel
          header="Visual Enhancements & Customization"
          pt={{
            ...commonPt,
          }}
        >
          <DataTable
            value={visualEnhancementsFields}
            className="p-datatable-striped"
          >
            {customizationColumnsData?.map((col, index) => (
              <Column key={index} {...col} />
            ))}
          </DataTable>
        </TabPanel>
      </TabView>
    </div>
  );
};

export default AdditionalFeatures;
