import UserFormDetails from './user-form-details';
import UserFormPreferences from './user-form-preferences';
import UserFormTeamReviews from './user-form-team-reviews';
import UserFormDocuments from './user-form-documents';
import UserFormCredentials from './user-form-credentials';
import UserFormAssignedDashboards from './user-form-dashboards';
import UserFormContactAndAdress from './user-form-contact-and-address';
import UserFormRetainage from './user-form-retainage';
import UserFormCrew from './user-form-crew';
import jobAssignmentCoverage from './user-form-job-assignment';
import UserChecklist from './UserChecklist';
import UserFormSchedulerFiters from './user-form-scheduler-filters';

export const userFormTabs = [
  { header: 'User Details', component: UserFormDetails },
  { header: 'Assigned Dashboard', component: UserFormAssignedDashboards },
  { header: 'Contact & Address', component: UserFormContactAndAdress },
  { header: 'Access Coverage', component: UserFormPreferences },
  { header: 'Job Assignment Coverage', component: jobAssignmentCoverage },
  { header: 'Team Reviews', component: UserFormTeamReviews },
  { header: 'Documents', component: UserFormDocuments },
  { header: 'Credentials', component: UserFormCredentials },
  { header: 'Crews', component: UserFormCrew },
  { header: 'Retainage', component: UserFormRetainage },
  { header: 'Checklist', component: UserChecklist },
  { header: 'Global Filters', component: UserFormSchedulerFiters },
];
