import { useEffect, useState, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Tooltip, Tag, Toast, Chip, ConfirmDialog } from 'primereact';

import PageHeader from '../../shared/PageHeader/PageHeader';
import PFDataTable from '../../shared/PFPrime/PFDataTable';
import SkeletonLoader from '../../shared/Loader/skeleton';
import TableLoader from '../../shared/Loader/TableLoader';
import { PF_ADMIN } from '../../../constants';
import apiService from '../../../services/api.service';
import { URL_CONSTANTS } from '../../../constants/urlConstants';

const breadcrumb = [
  {
    link: '/admin/configuration',
    text: 'Company Configurations',
  },
  {
    text: 'Payments Configuration',
  },
];

const statusTemplate = (statusId, title) => {
  const template = {
    340: <Tag className="mr-2" severity="info" value={title}></Tag>,
    341: <Tag className="mr-2" severity="warning" value={title}></Tag>,
    342: <Tag className="mr-2" severity="success" value={title}></Tag>,
  };
  return template[statusId] || null;
};
const TYPE_OPTIONS = [
  { id: 1, name: 'Amount', icon: 'pi pi-dollar' },
  { id: 2, name: 'Percentage', icon: 'pi pi-percentage' },
];

const INCLUSIVE_EXCLUSIVE_OPTIONS = {
  1: 'to Tenant',
  2: 'to Customer',
};

const getPFShareTemplate = rowData => {
  if (!rowData?.pf_share?.pf_share_value || !rowData?.pf_share?.pf_share_type) {
    return null;
  }
  const foundOption = TYPE_OPTIONS.find(
    item => item.id == rowData.pf_share.pf_share_type
  );
  const icon = foundOption ? foundOption.icon : null;
  const value = rowData.pf_share.pf_share_value || null;

  let label;
  if (rowData.pf_share.pf_share_type === 1) {
    label = `${value} ${INCLUSIVE_EXCLUSIVE_OPTIONS[rowData.pf_share.pf_share]}`;
  } else if (rowData.pf_share.pf_share_type === 2) {
    label = `${value} % ${INCLUSIVE_EXCLUSIVE_OPTIONS[rowData.pf_share.pf_share]}`;
  }

  return (
    <Chip
      className="text-sm"
      label={label}
      icon={icon === 'pi pi-dollar' && icon}
    />
  );
};
const getStripeShareTemplate = rowData => {
  if (!rowData.stripe_share.stripe_share) {
    return null;
  }
  return (
    <Chip
      className="text-sm"
      label={`${INCLUSIVE_EXCLUSIVE_OPTIONS[rowData.stripe_share.stripe_share]}`}
    />
  );
};

const PaymentsConfiguration = () => {
  const history = useHistory();
  const clientId = localStorage.getItem('client_id');
  const [tenantList, setTenantList] = useState([]);
  const [loading, setLoading] = useState(true);
  const statusList = [
    {
      status_id: 340,
      status: 'Pending',
    },
    {
      status_id: 341,
      status: 'In Progress',
    },
    {
      status_id: 342,
      status: 'Completed',
    },
  ];
  const toast = useRef(null);

  const fetchTenantsData = useCallback(async () => {
    try {
      const response = await apiService.getWithDiffBaseUrl(
        `${URL_CONSTANTS.CRM.paymentConfig}`,
        null,
        URL_CONSTANTS.CRM.baseUrl
      );

      const data =
        response?.data?.map(record => {
          return {
            ...record,
            pf_share: {
              pf_share: record.pf_share
                ? parseInt(record.pf_share)
                : record.pf_share,
              pf_share_value: record.pf_share_value
                ? parseFloat(record.pf_share_value)
                : record.pf_share_value,
              pf_share_type: record.pf_share_type
                ? parseInt(record.pf_share_type)
                : record.pf_share_type,
            },
            stripe_share: {
              stripe_share: record.stripe_share
                ? parseInt(record.stripe_share)
                : record.stripe_share,
            },
          };
        }) || [];
      setTenantList(data);
      setLoading(false);
    } catch (error) {
      toast?.current?.show({
        severity: 'error',
        summary: 'Something went wrong',
        life: 2000,
      });
      setLoading(false);
      console.error(error);
    }
  }, []);

  useEffect(() => {
    fetchTenantsData();
  }, []);

  if (clientId != PF_ADMIN) {
    history.push(`/dashboard`);
  }

  const toCapitalizedCase = str => {
    return str?.toLowerCase()?.replace(/\b\w/g, match => match?.toUpperCase());
  };

  const handelEditRow = rowData => {
    history.push('/payments/config/edit', { data: rowData });
  };

  const tableColum = [
    {
      field: '',
      header: '',
      body: rowData => {
        return (
          <i
            className="pi pi-pencil cursor-pointer text-primary"
            role="button"
            tabIndex="0"
            onClick={() => handelEditRow(rowData)}
            onKeyDown={e => {
              if (e?.key === 'Enter' || e?.key === ' ') {
                handelEditRow(rowData);
              }
            }}
          ></i>
        );
      },
    },
    {
      field: 'client_name',
      header: 'Tenant',
      sortable: true,
      filter: true,
      body: rowData => {
        return <span>{toCapitalizedCase(rowData?.client_name)}</span>;
      },
    },
    {
      field: 'pf_share',
      header: 'Platform Fee',
      sortable: false,
      filter: false,
      body: rowData => {
        return getPFShareTemplate(rowData);
      },
    },
    {
      field: 'stripe_share',
      header: 'Stripe Fee',
      sortable: false,
      filter: false,
      body: rowData => {
        return getStripeShareTemplate(rowData);
      },
    },
    {
      field: 'OnBoardingStatus',
      header: 'Status',
      sortable: false,
      filter: false,
      body: rowData => {
        const statusObj = statusList.find(
          status => rowData.onboarding_status_id == status.status_id
        );
        return statusTemplate(statusObj?.status_id, statusObj?.status);
      },
    },
  ];

  return (
    <>
      <div className="w-full flex flex-row justify-content-between">
        <div className="col-6">
          <PageHeader
            pageTitle="Payments Configuration"
            breadCrumbArray={breadcrumb}
          />
        </div>
      </div>
      <Card className="w-full quote border-solid border-1 border-200 border-noround p-0 quoteListing">
        {loading ? (
          <>
            <SkeletonLoader columnCount={1} columnWidth="100%" />
            <TableLoader columnCount={3} noOfRow={4} mTopBottom={0} />
          </>
        ) : (
          <PFDataTable
            paginator
            rows={10}
            columns={tableColum}
            data={tenantList}
            dataKey="id"
            rowsPerPageOptions={[5, 10, 50]}
            tableStyle={{ minWidth: '50rem' }}
            stripedRows={true}
            editMode={'row'}
          />
        )}

        <Tooltip target=".custom-target-icon" />
      </Card>
      <Toast ref={toast} />
      <ConfirmDialog />
    </>
  );
};
export default PaymentsConfiguration;
