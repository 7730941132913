import { Toast } from 'primereact/toast';
import { useRef } from 'react';

import AdditionalChargesTable from './components/AdditionalChargesTable';
import ExpensesTable from './components/ExpensesTable';

const AdditionalFeeContainer = ({ project_id }: { project_id: number }) => {
  const toast = useRef<Toast>(null);

  return (
    <>
      <Toast ref={toast} />
      <AdditionalChargesTable project_id={project_id} toast={toast} />
      <ExpensesTable project_id={project_id} toast={toast} />
    </>
  );
};

export default AdditionalFeeContainer;
