import React, { useEffect, useState, useRef, useMemo } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useSelector, useDispatch } from 'react-redux';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';

import FileUploadTemplate from '../../shared/file-upload/file-upload-template';
import { setUserCredentialsForm } from '../../../redux/slices/user-form-credentials.slice';
import { getStates } from '../../ProjectManagement/Clients/Clients.service';
import TableColumnsLoader from '../../shared/Loader/tableColumnsLoader';
import { getPreSignedUrl } from '../UserManagement/UserManagement.service';

import { domHandlerCall } from './common';
import {
  getUserCredentialsData,
  addUserCredentials,
  updateUserCredentials,
  deleteUserCredentials,
  getUserCredentialsTypeData,
} from './services/user-form-credentials.service';
import { uploadUserFile } from './services/user-form-service';

const emptyRow = {
  user_id: '',
  status: '',
  type: '',
  issuing_body: '',
  expiration_date: new Date(),
  city: '',
  state: '',
  description: '',
  number: '',
  file: '',
  certificate_number: '',
  created_by: '',
  created_at: '',
  modified_by: '',
  modified_at: '',
};

const mapTableDataForPayload = tableData => {
  if (!tableData?.type || !tableData?.expiration_date) {
    console.log('Invalid row data', tableData);
    return null;
  }

  const mappedPayload = {
    type: tableData.type?.credential_type_id || null,
    issuing_body: tableData?.issuing_body || '',
    name: tableData?.name || null,
    city: tableData?.city || null,
    state_id: tableData?.state_id || 0,
    description: tableData?.description || null,
    number: tableData?.number || null,
    expiration_date: tableData?.expiration_date || '',
    file: tableData?.file || {},
    certificate_number: tableData?.certificate_number || null,
  };

  return mappedPayload;
};

const UserFormCredentials = () => {
  const { isLoading, isFileUploading, credentialsTypeList, credentialsData } =
    useSelector(state => state.userFormCredentials);
  const { userId } = useSelector(state => state?.userForm);
  const dispatch = useDispatch();
  const toast = useRef(null);
  const [fileUploaderRowIndex, setFileUploaderRowIndex] = useState();
  const [isUploaderVisible, setIsUploaderVisible] = useState(false);
  const [isNewRow, setIsNewRow] = useState(false);
  const [visible, setVisible] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [rowData, setRowData] = useState({});
  const [states, setStates] = useState();

  useEffect(async () => {
    getStates(setStates);
    dispatch(setUserCredentialsForm({ isLoading: true }));
    try {
      const credentialsTypeList = await getUserCredentialsTypeData();
      dispatch(
        setUserCredentialsForm({ credentialsTypeList: credentialsTypeList })
      );
    } catch (error) {
      console.error('error', error);
    } finally {
      dispatch(setUserCredentialsForm({ isLoading: false }));
    }
  }, []);

  const fetchInitiationData = async () => {
    dispatch(setUserCredentialsForm({ isLoading: true }));
    try {
      const credentials = !!userId && (await getUserCredentialsData(userId));
      const mappedData = credentials?.map(row => {
        return {
          status:
            new Date() < new Date(row?.expiration_date) ? 'Active' : 'Expired',
          type: credentialsTypeList?.find(
            item => item?.credential_type_id == row?.type
          ),
          user_credentials_id: row?.user_credentials_id,
          name: row?.name,
          issuing_body: row?.issuing_body,
          expiration_date: row?.expiration_date,
          number: row?.number,
          city: row?.city,
          state_id: row?.state_id,
          description: row?.description,
          certificate_number: row?.certificate_number,
          file: row?.file,
          created_by: `${row?.created_by.first_name + ' ' + row?.created_by.last_name}`,
          created_at: row?.created_at,
          modified_by: `${row?.modified_by.first_name ? row?.modified_by.first_name + ' ' + row?.modified_by.last_name : ''}`,
          modified_at: row?.modified_at,
        };
      });
      dispatch(
        setUserCredentialsForm({
          credentialsData: mappedData,
        })
      );
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setUserCredentialsForm({ isLoading: false }));
    }
  };

  useEffect(() => {
    if (credentialsTypeList.length > 0) {
      fetchInitiationData();
    }
  }, [userId, credentialsTypeList]);

  const handleDelete = async e => {
    const id = e?.user_credentials_id;
    try {
      setShowDialog(false);
      const deleteResponse = await deleteUserCredentials(id);
      if (deleteResponse?.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Credentials Deleted',
          life: 2000,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setVisible(false);
      fetchInitiationData();
    }
  };

  const footerContent = rowData => {
    return (
      <div>
        <Button
          label="Cancel"
          icon="pi pi-times"
          onClick={() => setShowDialog(false)}
          className="p-button-text"
        />
        <Button
          label="Delete"
          icon="pi pi-check"
          onClick={() => handleDelete(rowData)}
          autoFocus
        />
      </div>
    );
  };
  const handleOnRowEditCancel = async e => {
    setVisible(false);
    if (isNewRow && e?.index === 0) {
      const editedData = structuredClone(credentialsData);
      editedData.splice(0, 1);
      dispatch(
        setUserCredentialsForm({
          credentialsData: editedData,
        })
      );
    }
    setIsNewRow(false);
  };

  const StatusTemplate = rowData => {
    return (
      rowData?.status && (
        <span>
          <Tag
            value={rowData?.status}
            severity={rowData?.status === 'Active' ? 'success' : 'warning'}
          />
        </span>
      )
    );
  };

  const CredentialsTypeTemplate = rowData => {
    return <span>{rowData?.type?.type_name}</span>;
  };

  const ExpirationDateTemplate = rowData => {
    return (
      <span>
        {rowData?.expiration_date
          ? new Date(rowData?.expiration_date)?.toLocaleDateString()
          : ''}
      </span>
    );
  };

  const PhoneTemplate = rowData => {
    return <span>{rowData?.number}</span>;
  };

  const CertificateTemplate = rowData => {
    return <span>{rowData?.certificate_number}</span>;
  };

  const credentialsTypeEditor = options => {
    return (
      <Dropdown
        value={options?.value}
        optionLabel="type_name"
        options={credentialsTypeList}
        onChange={e => options.editorCallback(e.value)}
        placeholder="Select a Type"
      />
    );
  };

  const stateEditor = options => {
    return (
      <Dropdown
        value={options?.value}
        optionLabel="label"
        options={dropdownStates}
        onChange={e => options.editorCallback(e.value)}
        placeholder="Select a State"
        filter
      />
    );
  };

  const stateTemplate = rowData => {
    const state = states?.find(s => s.state_id === rowData?.state_id);
    return <span>{state ? state.state_name : '-'}</span>;
  };
  const nameEditor = options => {
    return (
      <div className="flex flex-column">
        <InputText
          type="text"
          value={options.value}
          onChange={e => options.editorCallback(e.target.value)}
        />
      </div>
    );
  };

  const certificateNumberEditor = options => {
    return (
      <div className="flex flex-column">
        <InputText
          value={options.value}
          onChange={e => options.editorCallback(e.target.value)}
          maxLength={100}
        />
      </div>
    );
  };

  const phoneEditor = options => {
    return (
      <div className="flex flex-column">
        <InputMask
          mask="(999) 999-9999"
          value={options.value}
          onChange={e => options.editorCallback(e.target.value)}
        />
      </div>
    );
  };

  const handleSignedUrl = async (event, value) => {
    event.preventDefault();
    const docUrl = value;
    const preUrl = await getPreSignedUrl(
      () => {},
      () => {},
      docUrl
    );
    window.open(preUrl, '_blank');
  };

  const fileTemplate = rowData => {
    return (
      <a
        href={'javascript:void(0)'}
        target="_blank"
        rel="noreferrer"
        download={rowData?.file?.name}
        onClick={event =>
          handleSignedUrl(event, rowData?.file?.url?.split('?')[0])
        }
      >
        {rowData?.file?.name}
      </a>
    );
  };
  const CreationDateTemplate = rowData => {
    return (
      <span>
        {rowData?.created_at
          ? new Date(rowData?.created_at)?.toLocaleDateString()
          : ''}
      </span>
    );
  };
  const UpdatedDateTemplate = rowData => {
    return (
      <span>
        {rowData?.modified_at
          ? new Date(rowData?.modified_at)?.toLocaleDateString()
          : ''}
      </span>
    );
  };
  const handleUploaderClick = () => {
    setIsUploaderVisible(true);
  };

  const fileUploadEditor = options => {
    setFileUploaderRowIndex(options?.rowIndex);
    return (
      <>
        <a
          href={credentialsData[options?.rowIndex]?.file?.url}
          target="_blank"
          rel="noreferrer"
          download={credentialsData[options?.rowIndex]?.file?.name}
        >
          {credentialsData[options?.rowIndex]?.file?.name}
        </a>
        <i
          role="button"
          tabIndex={0}
          className="ml-2 pi pi-upload cursor-pointer"
          onClick={handleUploaderClick}
          onKeyDown={e => {
            if (e?.key === 'Enter' || e?.key === ' ') {
              handleUploaderClick();
            }
          }}
        ></i>
        {isFileUploading && (
          <ProgressSpinner
            style={{ width: '30px', height: '30px' }}
            strokeWidth="6"
            fill="var(--surface-ground)"
            animationDuration=".5s"
          />
        )}
      </>
    );
  };

  const handleAddNewRow = () => {
    dispatch(
      setUserCredentialsForm({
        credentialsData: [emptyRow, ...credentialsData],
      })
    );
    setIsNewRow(true);
    setVisible(true);
    setTimeout(() => {
      const edit = domHandlerCall('p-row-editor-init');
      edit?.handler[0].click();
    }, 300);
  };
  const onRowEditComplete = async e => {
    const { newData, index } = e;
    const newCredentials = structuredClone(credentialsData);
    if (newData) {
      newData.file = {
        url: credentialsData[index]?.file?.url,
        name: credentialsData[index]?.file?.name,
      };
    }
    newCredentials[index] = newData;
    dispatch(
      setUserCredentialsForm({
        credentialsData: newCredentials,
        isLoading: true,
      })
    );
    const payload = mapTableDataForPayload(newData);
    if (isNewRow && index === 0) {
      try {
        const addResponse = await addUserCredentials(userId, payload);
        if (addResponse?.status === 200) {
          toast.current.show({
            severity: 'success',
            summary: 'Credentials Added',
            life: 2000,
          });
        }
        setIsNewRow(false);
      } catch (err) {
        console.error(err);
      } finally {
        dispatch(setUserCredentialsForm({ isLoading: false }));
        setVisible(false);
        fetchInitiationData();
      }
    } else {
      setIsNewRow(false);
      try {
        const payload = mapTableDataForPayload(newCredentials[index]);
        const editedResponse = await updateUserCredentials(
          userId,
          newData?.user_credentials_id,
          payload
        );
        if (editedResponse?.status === 200) {
          toast.current.show({
            severity: 'success',
            summary: 'Credentials Updated',
            life: 2000,
          });
        }
      } catch (err) {
        console.error(err);
      } finally {
        dispatch(setUserCredentialsForm({ isLoading: false }));
        setVisible(false);
        fetchInitiationData();
      }
    }
  };

  const handleRowValidation = e => {
    const validationError = [];
    if (!e?.type) {
      validationError.push('Type');
    }
    if (!e?.issuing_body) {
      validationError.push('Issuing Body');
    }
    if (!e?.expiration_date) {
      validationError.push('Expiration Date');
    }

    if (validationError.length > 0) {
      toast.current.show({
        severity: 'error',
        summary: `${validationError.join(',')} required`,
        life: 2000,
      });
      return false;
    }
    return true;
  };

  const dateEditor = options => {
    return (
      <Calendar
        value={options.value ? new Date(options.value) : new Date()}
        onChange={e => options.editorCallback(e.target.value)}
      />
    );
  };

  const handleUserDocumentUpload = async e => {
    try {
      dispatch(setUserCredentialsForm({ isFileUploading: true }));
      setIsUploaderVisible(false);
      if (e?.files && e.files.length > 0) {
        try {
          const fileUpload = await uploadUserFile(
            userId,
            'credentials',
            e.files[0]
          );
          if (fileUpload?.name && fileUpload?.url) {
            let editedDocuments = structuredClone(credentialsData);
            const rowData = editedDocuments[fileUploaderRowIndex];
            rowData['file'] = fileUpload;
            editedDocuments[fileUploaderRowIndex] = rowData;
            dispatch(
              setUserCredentialsForm({ credentialsData: editedDocuments })
            );
          } else {
            toast.current.show({
              severity: 'error',
              summary: `Uploaded File Type is not supported`,
              life: 3000,
            });
          }
        } catch (error) {
          console.error(error);
          toast.current.show({
            severity: 'error',
            summary: `An error occurred while uploading file.`,
            life: 2000,
          });
        }
      } else {
        throw new Error('No files uploaded');
      }
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(setUserCredentialsForm({ isFileUploading: false }));
    }
  };
  const dropdownStates = useMemo(
    () =>
      states?.map(state => ({
        label: state.state_name,
        value: state.state_id,
      })),
    [states]
  );

  const deleteRowTemplate = e => {
    if (e?.type !== '') {
      return (
        <i
          className="pi pi-trash"
          onClick={() => {
            setShowDialog(true);
            setRowData(e);
          }}
          role="button"
          tabIndex={0}
          onKeyDown={e => {
            if (e?.key === 'Enter' || e?.key === ' ') {
              setShowDialog(true);
              setRowData(e);
            }
          }}
        ></i>
      );
    } else {
      return null;
    }
  };
  const credentialsTableColumns = [
    {
      field: 'edit',
      header: '',
      ...(!isLoading
        ? {
            rowEditor: true,
          }
        : {}),
      headerStyle: { width: '15%', minWidth: '7rem' },
      bodyStyle: { textAlign: 'center' },
    },
    {
      headerStyle: { width: '10%', minWidth: '4rem' },
      ...(!isLoading
        ? {
            body: rowData => deleteRowTemplate(rowData),
          }
        : {}),
    },
    {
      field: 'type',
      header: 'Type',
      ...(!isLoading
        ? {
            editor: options => credentialsTypeEditor(options),
            body: rowData => CredentialsTypeTemplate(rowData),
          }
        : {}),
      headerStyle: { minWidth: '16rem' },
    },
    {
      field: 'issuing_body',
      header: 'Issuing Body',
      ...(!isLoading
        ? {
            editor: options => nameEditor(options),
          }
        : {}),
      headerStyle: { minWidth: '15rem' },
    },
    {
      field: 'name',
      header: 'Name',
      ...(!isLoading
        ? {
            editor: options => nameEditor(options),
          }
        : {}),
      headerStyle: { minWidth: '15rem' },
    },
    {
      field: 'certificate_number',
      header: 'Number',
      ...(!isLoading
        ? {
            editor: options => certificateNumberEditor(options),
            body: rowData => CertificateTemplate(rowData),
          }
        : {}),
      headerStyle: { minWidth: '15rem' },
    },
    {
      field: 'city',
      header: 'City',
      ...(!isLoading
        ? {
            editor: options => nameEditor(options),
          }
        : {}),
      headerStyle: { minWidth: '15rem' },
    },
    {
      field: 'state_id',
      header: 'State',
      ...(!isLoading
        ? {
            editor: options => stateEditor(options),
            body: rowData => stateTemplate(rowData),
          }
        : {}),
      headerStyle: { minWidth: '15rem' },
    },
    {
      field: 'expiration_date',
      header: 'Expiration Date',
      headerStyle: { minWidth: '6rem' },
      ...(!isLoading
        ? {
            body: rowData => ExpirationDateTemplate(rowData),
            editor: options => dateEditor(options),
          }
        : {}),
    },
    {
      field: 'number',
      header: 'Phone Number',
      ...(!isLoading
        ? {
            editor: options => phoneEditor(options),
            body: rowData => PhoneTemplate(rowData),
          }
        : {}),
      headerStyle: { minWidth: '15rem' },
    },
    {
      field: 'status',
      header: 'Status',
      ...(!isLoading
        ? {
            body: rowData => StatusTemplate(rowData),
          }
        : {}),
      headerStyle: { minWidth: '6rem' },
    },
    {
      field: 'file',
      header: 'File',
      ...(!isLoading
        ? {
            editor: options => fileUploadEditor(options),
            body: rowData => fileTemplate(rowData),
          }
        : {}),
      headerStyle: { minWidth: '6rem' },
    },
    {
      field: 'created_at',
      header: 'Created At',
      ...(!isLoading
        ? {
            body: rowData => CreationDateTemplate(rowData),
          }
        : {}),

      headerStyle: { minWidth: '6rem' },
    },
    {
      field: 'created_by',
      header: 'Created By',
      headerStyle: { minWidth: '6rem' },
    },
    {
      field: 'modified_at',
      header: 'Updated At',
      ...(!isLoading
        ? {
            body: rowData => UpdatedDateTemplate(rowData),
          }
        : {}),
      headerStyle: { minWidth: '6rem' },
    },
    {
      field: 'modified_by',
      header: 'Updated By',
      headerStyle: { minWidth: '6rem' },
    },
  ];
  const credentialsTableLoader = useMemo(() => {
    return TableColumnsLoader(credentialsTableColumns, {
      rows: 4,
      isValue: false,
    });
  }, [credentialsTableColumns]);

  return (
    <>
      <div className="flex flex-row justify-content-between align-items-center py-3">
        <h4>Credentials</h4>
        <Button
          label="Add Credentials"
          severity="primary"
          size="small"
          onClick={handleAddNewRow}
          disabled={visible}
        />
      </div>
      <Dialog
        header="Delete Confirmation"
        visible={showDialog}
        style={{ width: '50vw' }}
        onHide={() => {
          if (!showDialog) return;

          setShowDialog(false);
        }}
        footer={() => footerContent(rowData)}
      >
        <p className="m-0">
          Please confirm if you would like to cancel your unsaved changes or
          delete this record?
        </p>
      </Dialog>
      <DataTable
        value={isLoading ? credentialsTableLoader : credentialsData}
        {...(!isLoading
          ? {
              editMode: 'row',
              onRowEditComplete: onRowEditComplete,
              rowEditValidator: handleRowValidation,
              onRowEditCancel: handleOnRowEditCancel,
              resizableColumns: true,
            }
          : {})}
      >
        {credentialsTableColumns?.map(col => (
          <Column key={col.field} {...col} />
        ))}
      </DataTable>

      <Toast ref={toast}></Toast>
      <Dialog
        header="Upload Document"
        visible={isUploaderVisible}
        style={{ width: '50vw' }}
        onHide={() => setIsUploaderVisible(false)}
      >
        <FileUploadTemplate
          onFileUpload={handleUserDocumentUpload}
          acceptedFormat="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        />
      </Dialog>
    </>
  );
};

export default UserFormCredentials;
