import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Skeleton } from 'primereact/skeleton';
import { Checkbox } from 'primereact/checkbox';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { debounce } from 'lodash';

import PageHeader from '../../shared/PageHeader/PageHeader';
import PFButton from '../../shared/PFPrime/PFButton';
import { CLIENT_DETAILS, StatusColors } from '../../../constants';
import TableColumnsLoader from '../../shared/Loader/tableColumnsLoader';
import { checkPermission } from '../../../utils/Helpers';
import permissions from '../../../config/permissions';
import PFDialog from '../../shared/PFPrime/PFDialog';

import { getClientsList } from './Clients.service';
import ChildClientList from './ChildClientList';

const accessBreadcrumb = [
  {
    text: 'Project Management',
  },
  {
    text: 'Clients',
  },
];
const Clients = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const phoneNumber = urlParams.get('phone_number');
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [mergeClientRequest, setMergeClientRequest] = useState(null);
  const [clientList, setClientList] = useState({
    count: 0,
    data: [],
  });
  const [clientListOptions, setClientListOptions] = useState({
    filter: {
      search: phoneNumber || undefined,
    },
    pageSettings: {
      pageSizes: [10, 20, 50, 100],
      limit: 10,
      offset: 0,
      sortField: null,
      sortOrder: null,
    },
  });
  const [mergeClientPopUpDefaultProps, setMergeClientPopUpDefaultProps] =
    useState({
      show: false,
      header: <span className="px-3">Clients</span>,
      hide: e => {
        setMergeClientPopUpDefaultProps(prevState => ({
          ...prevState,
          show: e,
        }));
      },
    });

  const onMergeClientPopUpHide = useCallback(() => {
    setMergeClientRequest(null);
    setMergeClientPopUpDefaultProps(prevState => ({
      ...prevState,
      show: false,
    }));
    fetchClientList();
  }, []);

  const handleMergeRequest = (e, customerId) => {
    if (e.target.checked) {
      setMergeClientRequest({ parent: customerId });
    } else {
      setMergeClientRequest(null);
    }
  };
  const handleSetClientListOptions = params => {
    if (params && typeof params === 'object' && !Array.isArray(params)) {
      setClientListOptions({
        ...clientListOptions,
        ...params,
      });
    }
  };

  const fetchClientList = async localFilter => {
    setLoading(true);
    try {
      const apiFilters = {
        ...clientListOptions.filter,
        ...(typeof localFilter === 'object' && !Array.isArray(localFilter)
          ? localFilter
          : {}),
      };
      const urlParams = {
        limit: clientListOptions?.pageSettings?.limit || 10,
        offset: clientListOptions?.pageSettings?.offset || 0,
        sortKey: clientListOptions?.pageSettings?.sortField || 'customer_name',
        sortDirection: !isNaN(clientListOptions?.pageSettings?.sortOrder)
          ? clientListOptions.pageSettings.sortOrder === -1
            ? 'DESC'
            : 'ASC'
          : undefined,
        ...apiFilters,
      };
      const response = await getClientsList(urlParams);
      if (response?.count && response?.items?.length) {
        setClientList({
          count: response.count,
          data: response.items,
        });
      }
    } catch (ex) {
      console.log(ex);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (CLIENT_DETAILS?.client_id) {
      fetchClientList();
    }
  }, [CLIENT_DETAILS?.client_id]);
  const getEditPermission = checkPermission(permissions?.clients?.editClient);

  const CLIENT_LIST_COLUMNS = [
    {
      field: '',
      header: '',
      body: rowData => {
        return loading ? (
          <Skeleton className="w-10rem" height="2rem" />
        ) : (
          <div className="flex align-items-center justify-center gap-2">
            <PFButton
              icon="pi pi-eye"
              severity=" "
              rounded={true}
              text={true}
              aria-label="view"
              onClick={() =>
                window.open(
                  `/crm/view/customer/${rowData?.customer_id}`,
                  '_blank'
                )
              }
              pt={{ icon: { className: 'text-color' } }}
            />
            {getEditPermission && (
              <PFButton
                icon="pi pi-pencil"
                severity=" "
                rounded={true}
                text={true}
                aria-label="Edit"
                onClick={() =>
                  window.open(
                    `/crm/edit/customer/${rowData?.customer_id}`,
                    '_blank'
                  )
                }
                pt={{ icon: { className: 'text-color' } }}
              />
            )}

            <span>
              <Checkbox
                checked={mergeClientRequest?.parent === rowData?.customer_id}
                onChange={e => handleMergeRequest(e, rowData?.customer_id)}
              />
            </span>
          </div>
        );
      },
    },
    {
      field: 'source_name',
      header: 'Source',
      body: rowData => {
        return loading ? (
          <Skeleton className="w-full" height="2rem" />
        ) : rowData?.source_url ? (
          <img
            width="40"
            height="30"
            alt="ProjectForce"
            src={rowData.source_url}
          />
        ) : (
          rowData?.source_name
        );
      },
      sortable: false,
      filter: false,
      hidden: false,
    },
    {
      field: 'customer_name',
      header: 'Client Name',
      ...(loading
        ? {
            body: <Skeleton className="w-full" height="2rem" />,
          }
        : {}),
      sortable: true,
      filter: false,
      hidden: false,
    },
    {
      field: 'mobile_phone',
      header: 'Mobile Phone',
      ...(loading
        ? {
            body: <Skeleton className="w-full" height="2rem" />,
          }
        : {}),
      sortable: true,
      filter: false,
      hidden: false,
    },
    {
      field: 'email',
      header: 'Email Address',
      ...(loading
        ? {
            body: <Skeleton className="w-full" height="2rem" />,
          }
        : {}),
      sortable: true,
      hidden: false,
    },
    {
      field: 'billing_address',
      header: 'Billing Address',
      ...(loading
        ? {
            body: <Skeleton className="w-full" height="2rem" />,
          }
        : {}),
    },
    {
      field: 'is_deleted',
      header: 'Status',
      ...(loading
        ? {
            body: <Skeleton className="w-full" height="2rem" />,
          }
        : {
            body: rowData => {
              return (
                <span
                  style={{
                    color: rowData.is_deleted
                      ? StatusColors.PF_RED
                      : StatusColors.PF_GREEN,
                  }}
                >
                  {rowData.is_deleted ? 'Inactive' : 'Active'}
                </span>
              );
            },
          }),
    },
  ];
  const tableLoader = useMemo(() => {
    return TableColumnsLoader(CLIENT_LIST_COLUMNS, {
      rows: clientListOptions.pageSettings?.limit || 10,
      isValue: false,
    });
  }, [CLIENT_LIST_COLUMNS]);
  const debounceOnSearch = useCallback(debounce(fetchClientList, 500), []);

  return (
    <div className="w-full">
      <div className="flex justify-content-between align-items-center">
        <div className="w-6">
          <PageHeader
            pageTitle={`Clients`}
            breadCrumbArray={accessBreadcrumb}
          />
        </div>
        <div className="flex justify-content-end align-items-center gap-2 w-6">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              value={clientListOptions?.filter?.search}
              onChange={e => {
                debounceOnSearch({ search: e.target.value });
                handleSetClientListOptions({
                  filter: {
                    ...(clientListOptions.filter || {}),
                    search: e.target.value,
                  },
                });
              }}
              placeholder="Keyword Search"
              className="p-inputtext-sm"
            />
          </span>
          <PFButton
            disabled={mergeClientRequest ? false : true}
            onClick={() =>
              setMergeClientPopUpDefaultProps(preValue => ({
                ...preValue,
                show: true,
              }))
            }
            label="Merge"
          />
          <PFButton
            disabled={!checkPermission(permissions?.clients?.addClient)}
            onClick={() => history.push(`/crm/add/customer`)}
            label="Add Client"
          />
        </div>
      </div>
      <div className="mt-4 w-full">
        <DataTable
          value={loading ? tableLoader : clientList.data}
          scrollable
          scrollHeight="630px"
          pt={{
            bodyrow: {
              className: 'ready_to_schedule_report',
            },
          }}
          {...(loading
            ? {}
            : {
                paginator: true,
                rows: clientListOptions.pageSettings?.limit,
                rowsPerPageOptions: clientListOptions?.pageSettings?.pageSizes,
                totalRecords: clientList.count,
                first: clientListOptions.pageSettings?.offset,
                paginatorTemplate:
                  'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown',
                currentPageReportTemplate:
                  'Showing {first} to {last} of {totalRecords} entries',
                lazy: true,
                onPage: args => {
                  handleSetClientListOptions({
                    pageSettings: {
                      ...clientListOptions.pageSettings,
                      limit: args?.rows,
                      offset: args?.first,
                    },
                  });
                  fetchClientList({
                    limit: args?.rows,
                    offset: args?.first,
                  });
                },
                onSort: args => {
                  handleSetClientListOptions({
                    pageSettings: {
                      ...clientListOptions.pageSettings,
                      sortField: args.sortField,
                      sortOrder: args.sortOrder,
                    },
                  });
                  fetchClientList({
                    sortKey: args.sortField,
                    sortDirection: args.sortOrder === -1 ? 'DESC' : 'ASC',
                  });
                },
                sortField: clientListOptions.pageSettings?.sortField,
                sortOrder: clientListOptions.pageSettings?.sortOrder,
              })}
        >
          {CLIENT_LIST_COLUMNS.map((each, index) => (
            <Column {...each} key={`${each.field}_${index}`} />
          ))}
        </DataTable>
      </div>
      {mergeClientRequest?.parent && (
        <PFDialog
          {...mergeClientPopUpDefaultProps}
          BodyComponent={
            <ChildClientList
              mergeClientRequest={mergeClientRequest}
              setMergeClientRequest={setMergeClientRequest}
              onMergeClientPopUpHide={onMergeClientPopUpHide}
            />
          }
          pt={{
            root: {
              className: 'w-7',
            },
          }}
        />
      )}
    </div>
  );
};

export default Clients;
