import React from 'react';
export const AccessDenied = () => {
  return (
    <div className="text-700 text-center mt-7 w-full">
      <h2>
        We&apos;re sorry, but you do not have permission to access this page.
      </h2>
    </div>
  );
};
