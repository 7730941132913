import React, { useState, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
// eslint-disable-next-line import/no-named-as-default
import ReCAPTCHA from 'react-google-recaptcha';
import * as Yup from 'yup';

import { encryptEmail, encrypt } from '../../../../utils/security.helper';
import {
  REACT_APP_LOGIN_AUTH,
  REACT_APP_AUTH_CLIENT_ID,
  REACT_APP_RECAPTCHA_KEY,
} from '../../../../constants/envConstants';

import { useStyles } from './Login.styles';
import Welcome from './Welcome';

const SHOW_RECAPTCHA_MAX_LIMIT = 1;

const Login = () => {
  const classes = useStyles();
  const toast = useRef(null);
  const history = useHistory();
  const authorizeForm = useRef(React.createRef());
  const [formData, setFormData] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const loginSubmitCount = parseInt(
    localStorage.getItem('loginSubmitCount') || 0
  );

  const loginValidation = {
    email: Yup.string()
      .required('Email field is required.')
      .email('Email must be a valid email.')
      .matches(/^(?!.*@[^,]*,)/),
    password: Yup.string().required('Password field is required.'),
    reCaptcha:
      loginSubmitCount >= SHOW_RECAPTCHA_MAX_LIMIT
        ? Yup.string().required('Please complete the reCAPTCHA.').nullable()
        : Yup.string().nullable(),
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      reCaptcha: '',
    },
    validationSchema: Yup.object().shape(loginValidation),
    onSubmit: values => {
      setLoading(true);
      const username = values.email;
      const password = values.password;
      const reCaptcha = values.reCaptcha;
      const isPFUser = values.email.includes('@projectsforce.com') ?? false;
      localStorage.setItem('isPFUser', isPFUser);
      axios
        .post(`${REACT_APP_LOGIN_AUTH}/check.v1`, {
          username,
          password: encrypt(password),
          reCaptcha,
          method: 'POST',
        })
        .then(response => {
          if (response?.data?.status) {
            if (response?.data?.otpVerified) {
              setFormData(values);
              authorizeForm.current.submit();
            } else {
              history.push(`/verify-otp?token=${encryptEmail(values.email)}`);
              return null;
            }
            toast.current.show({
              severity: 'success',
              summary: 'Success',
              detail: 'Login Successfully',
            });
          }
          if (response?.data?.message === "Username or password didn't match") {
            localStorage.setItem('loginSubmitCount', loginSubmitCount + 1);
            toast.current.show({
              severity: 'error',
              summary: 'Login Failed',
              detail: 'Invalid Credentials',
            });
            setLoading(false);
          }
        })
        .catch(error => {
          localStorage.setItem('loginSubmitCount', loginSubmitCount + 1);
          toast.current.show({
            severity: 'error',
            summary: 'Login Failed',
            detail: error?.response?.data?.message || 'Invalid Credentials',
          });
          setLoading(false);
        });
    },
  });

  const handleToggle = () => {
    setShowPassword(current => !current);
  };

  return (
    <>
      <Toast ref={toast} />
      <form
        ref={authorizeForm}
        action={`${REACT_APP_LOGIN_AUTH}/authorize.v1`}
        method="post"
      >
        <input type="hidden" name="username" value={formData.email} />
        <input
          type="hidden"
          name="password"
          value={encrypt(formData.password)}
        />
        <input
          type="hidden"
          name="client_id"
          value={REACT_APP_AUTH_CLIENT_ID}
        />
        <input type="hidden" name="response_type" value="code" />
        <input type="hidden" name="state" value="/" />
      </form>
      <div
        className={`${classes.LoginContainer} flex align-items-center justify-content-center`}
      >
        <div>
          <img
            src="/images/circle-background.png"
            alt="Project Force"
            className="circle-bg mb-3"
            // style={{ maxWidth: '350px' }}
          />
          <div className="text-center pt-5">
            <img
              src="/images/PF-white-logo.png"
              alt="Project Force"
              className="mb-3"
              style={{ maxWidth: '180px' }}
            />
            <p className="px-3" style={{ maxWidth: '470px', margin: 'auto' }}>
              ProjectsForce puts you in control of your work, keeping both you
              and your clients on-track towards completing the job and getting
              paid.{' '}
            </p>
          </div>
          <div className="flex col-12 pb-0">
            <Welcome />
            <div className="flex col-6 flex h-100 w-100 align-center wrap px-5 pt-0 pb-1 login-container-right ">
              <div className="flex login-right-inner w-12">
                <div className="login-section w-12 py-4">
                  <h1 className="text-center mt-4 md:mt-0">LOG IN</h1>
                  <form onSubmit={formik.handleSubmit}>
                    <div>
                      <div
                        className={`${
                          formik.touched.email && formik.errors.email
                            ? 'validation-error'
                            : ''
                        } col-12 p-0 p-input-icon-right mb-3 `}
                      >
                        <span className="p-float-label">
                          <i className="pi pi-user"></i>
                          <InputText
                            autoComplete="off"
                            id="email"
                            onChange={formik.handleChange}
                            value={formik.values.email}
                            onBlur={formik.handleBlur}
                          />
                          <label htmlFor="email">Email*</label>
                        </span>

                        {formik.touched.email && formik.errors.email ? (
                          <div className="error">{formik.errors.email}</div>
                        ) : null}
                      </div>

                      <div
                        className={`${
                          formik.touched.password && formik.errors.password
                            ? 'validation-error'
                            : ''
                        } col-12 p-0 p-input-icon-right mb-3`}
                      >
                        <span className="p-float-label">
                          <i className="pi pi-lock"></i>
                          <InputText
                            type={showPassword ? 'text' : 'password'}
                            autoComplete="off"
                            id="password"
                            onChange={formik.handleChange}
                            value={formik.values.password}
                            onBlur={formik.handleBlur}
                          />
                          <label htmlFor="password">Password*</label>
                          {showPassword ? (
                            <i
                              className="pi pi-eye-slash"
                              role="button"
                              tabIndex={0}
                              onClick={handleToggle}
                              onKeyDown={e => {
                                if (e?.key === 'Enter' || e?.key === ' ') {
                                  handleToggle();
                                }
                              }}
                            ></i>
                          ) : (
                            <i
                              className="pi pi-eye"
                              role="button"
                              tabIndex={0}
                              onClick={handleToggle}
                              onKeyDown={e => {
                                if (e?.key === 'Enter' || e?.key === ' ') {
                                  handleToggle();
                                }
                              }}
                            ></i>
                          )}
                        </span>
                        {formik.touched.password && formik.errors.password ? (
                          <div className="error">{formik.errors.password}</div>
                        ) : null}
                      </div>

                      {loginSubmitCount >= SHOW_RECAPTCHA_MAX_LIMIT && (
                        <div className={` col-12 p-0 p-input-icon-right mb-3`}>
                          <ReCAPTCHA
                            sitekey={REACT_APP_RECAPTCHA_KEY}
                            onChange={captcha =>
                              formik.setFieldValue('reCaptcha', captcha)
                            }
                          />
                          {formik.errors.reCaptcha ? (
                            <div className="error">
                              {formik.errors.reCaptcha}
                            </div>
                          ) : null}
                        </div>
                      )}

                      <div className="mt-2">
                        <Link to="/forget-password">Forgot Password ?</Link>
                      </div>
                      <div className="mt-2">
                        By using ProjectsForce, you agree to our{' '}
                        <a href="https://projectsforce.com/privacy-policy">
                          Privacy Policy
                        </a>
                        .
                      </div>
                      <Button
                        icon={loading ? 'pi pi-spin pi-spinner' : ''}
                        severity="primary"
                        type="submit"
                        label="Log in"
                        pt={{
                          label: {
                            className: 'flex-none w-4rem',
                          },
                        }}
                        className="mt-3 flex justify-content-center w-full mt-3 flex-row-reverse"
                        disabled={loading}
                      ></Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
