import React from 'react';
import { Card } from 'primereact/card';

import PFTextArea from '../../PFPrime/PFTextArea';

export default function TermsCondition({
  errors,
  clientDetails,
  quoteDetails,
  isView,
  isEdit,
}) {
  return (
    <Card className="mt-3 w-full border-200 border-solid border-1">
      <div htmlFor="Terms and Conditions">
        <div className="w-12 text-1xl font-bold p-1">Terms and Condition</div>
        <PFTextArea
          id="termAndConditions"
          name="termAndConditions"
          type="text"
          value={
            isView || isEdit
              ? quoteDetails?.termAndConditions
              : clientDetails?.terms_and_conditions
          }
          disabled={true}
        />
        <div style={{ color: 'red' }} className="px-1 errorStyle">
          {errors?.termAndConditions || null}
        </div>
      </div>
    </Card>
  );
}
