import React, { useState, useRef, useEffect, useCallback } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import moment from 'moment';
import { Toast } from 'primereact/toast';
import { debounce } from 'lodash';

import PFButton from '../shared/PFPrime/PFButton.js';
import PFDataTable from '../shared/PFPrime/PFDataTable.js';
import TableLoader from '../shared/Loader/TableLoader.js';
import PFInputText from '../shared/PFPrime/PFInputText.js';
import { ACTIONS } from '../../constants/email-template.constant.js';
import { formAction } from '../../constants.js';
import PFDialog from '../shared/PFPrime/PFDialog.js';

import { getTemplateData, deleteEmailTemplate } from './template.service.js';
import { TemplateConfiguration } from './components/TemplateConfiguration.jsx';
import { CreateTemplateDialog } from './components/CreateEmailTemplate.jsx';

export const EmailTemplates = () => {
  const toast = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [emailTemplates, setEmailTemplates] = useState([]);

  const [pagination, setPagination] = useState({
    limit: 10,
    offset: 0,
    first: 0,
  });
  const [templateLoader, setTemplateLoader] = useState(true);
  const [templateId, setTemplateId] = useState(0);
  const [templatesCount, setTemplatesCount] = useState();

  const [openDialog, setOpenDialog] = useState(false);
  const [action, setAction] = useState(ACTIONS.CREATE);
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [showDeletePopup, setShowDeletePopup] = useState(false);

  const getEmailTemplateData = async () => {
    setTemplateLoader(true);
    try {
      const resp = await getTemplateData(pagination);
      if (resp.success) {
        setTemplatesCount(resp.totalTemplatesCount);
        setEmailTemplates(resp.emailTemplates);
      } else {
        setTemplatesCount(0);
        setEmailTemplates([]);
      }
    } catch (error) {
      console.error(error);
      setTemplatesCount(0);
      setEmailTemplates([]);
    } finally {
      setTemplateLoader(false);
    }
  };

  useEffect(() => {
    getEmailTemplateData();
  }, [pagination]);

  const handleClosePopup = () => {
    setOpenDialog(false);
    setAction(ACTIONS.CREATE);
    setShowDeletePopup(false);
  };

  const reloadEmailTemplates = () => {
    getEmailTemplateData();
  };

  const handleDelete = async id => {
    try {
      const response = await deleteEmailTemplate(id);
      toast.current.show({
        severity: 'success',
        detail: response?.message || 'Email template deleted successfully.',
      });
      setShowDeletePopup(false);
    } catch (error) {
      toast.current.show({
        severity: 'error',
        detail: error?.message,
      });
    } finally {
      reloadEmailTemplates();
    }
  };

  let tempConfigData = emailTemplates?.map(val => {
    return {
      ...val,
      action_buttons: (
        <div className="flex flex-row gap-2">
          <i
            className="pi pi-eye cursor-pointer text-blue-500 hover:text-blue-700"
            onClick={() => {
              setOpenDialog(true);
              setTemplateId(val?.id);
              setAction(ACTIONS.VIEW);
            }}
            role="button"
            tabIndex="0"
            onKeyDown={e => {
              if (e.key === 'Enter' || e.key === ' ') {
                setOpenDialog(true);
                setTemplateId(val?.id);
                setAction(ACTIONS.VIEW);
              }
            }}
          ></i>
          <i
            className="pi pi-pencil cursor-pointer text-blue-500 hover:text-blue-700"
            onClick={() => {
              setOpenDialog(true);
              setTemplateId(val?.id);
              setAction(formAction.EDIT);
            }}
            role="button"
            tabIndex="0"
            onKeyDown={e => {
              if (e.key === 'Enter' || e.key === ' ') {
                setOpenDialog(true);
                setTemplateId(val?.id);
                setAction(formAction.EDIT);
              }
            }}
          ></i>
          <i
            className="pi pi-trash cursor-pointer text-blue-500 hover:text-blue-700"
            onClick={() => {
              setTemplateId(val?.id);
              setShowDeletePopup(true);
            }}
            role="button"
            tabIndex="0"
            onKeyDown={e => {
              if (e.key === 'Enter' || e.key === ' ') {
                setTemplateId(val?.id);
                setShowDeletePopup(true);
              }
            }}
          ></i>
        </div>
      ),
    };
  });

  const templateConfigColumns = [
    {
      field: 'action_buttons',
      header: '',
      body: params => params?.action_buttons,
    },
    {
      header: 'Template',
      field: 'template_name',
    },

    {
      field: 'created_at',
      header: 'Created At',
      body: params => moment(params?.created_at).format('MM-DD-YYYY hh:mm A'),
    },
    {
      field: 'created_by',
      header: 'Created By',
    },
    {
      field: 'created_at',
      header: 'Modified At',
      body: params => moment(params?.modified_at).format('MM-DD-YYYY hh:mm A'),
    },
    {
      field: 'modified_by',
      header: 'Modified By',
    },
  ];

  const handleTabChange = e => {
    if (e.index === 0) {
      reloadEmailTemplates();
    }
    setActiveIndex(e.index);
  };

  const handleSearch = searchInputVal => {
    const query = searchInputVal?.trim();
    if (!query) {
      setPagination(prevState => ({
        ...prevState,
        offset: 0,
        query: '',
      }));
      return;
    }

    if (query.length < 3) return;

    if (query.length >= 3) {
      setPagination(prevState => ({
        ...prevState,
        offset: 0,
        query: query,
      }));
    }
  };

  const debouncedKeywordSearch = useCallback(debounce(handleSearch, 1000), []);

  return (
    <>
      <Toast ref={toast} />
      <TabView
        activeIndex={activeIndex}
        onTabChange={e => handleTabChange(e)}
        pt={{
          root: {
            className: 'w-full h-50rem',
          },
        }}
      >
        <TabPanel
          pt={{
            root: {
              className: 'text-right',
            },
          }}
          header="Templates"
        >
          <>
            <div className="flex justify-content-end mt-2 mb-2">
              <div className="flex gap-2">
                <span className="p-input-icon-left">
                  <i className="pi pi-search" />
                  <PFInputText
                    value={globalFilterValue}
                    onChange={e => {
                      setGlobalFilterValue(e.target.value);
                      debouncedKeywordSearch(e.target.value);
                    }}
                    placeholder="Keyword Search"
                  />
                </span>
                <PFButton
                  label={'Create Template'}
                  onClick={() => {
                    setOpenDialog(true);
                  }}
                  outlined
                  // className="mt-2 mr-2 mb-2"
                />
              </div>
            </div>
            <div>
              {templateLoader ? (
                <div
                  className="flex flex-column align-items-center justify-content-center h-12rem"
                  style={{ gap: '1rem' }}
                >
                  <TableLoader columnCount={3} noOfRow={10} mTopBottom={0} />
                </div>
              ) : (
                <>
                  <PFDataTable
                    data={tempConfigData}
                    columns={templateConfigColumns}
                    paginator={true}
                    rows={pagination?.limit}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    lazy={true}
                    totalRecords={templatesCount || 0}
                    first={pagination?.first}
                    onPage={event => {
                      setPagination(prev => ({
                        ...prev,
                        offset: event.first,
                        limit: event.rows,
                        first: event.first,
                      }));
                    }}
                  />
                </>
              )}
            </div>
            {openDialog && (
              <CreateTemplateDialog
                toast={toast}
                templateId={templateId}
                isOpen={openDialog}
                action={action}
                handleClosePopup={handleClosePopup}
                reloadEmailTemplates={reloadEmailTemplates}
              />
            )}
            {
              <PFDialog
                show={showDeletePopup}
                hide={handleClosePopup}
                header="Delete Template"
                className="w-4"
                footer={
                  <div>
                    <PFButton
                      label="No"
                      text
                      outlined
                      onClick={() => setShowDeletePopup(false)}
                    />
                    <PFButton
                      label="Yes"
                      onClick={() => {
                        handleDelete(templateId);
                      }}
                    />
                  </div>
                }
                BodyComponent={
                  <div className="flex">
                    <p>
                      <i className="pi pi-exclamation-triangle"></i> Are you
                      sure you want to delete this template? Deleting it will
                      also remove the associated template configuration.
                    </p>
                  </div>
                }
              />
            }
          </>
        </TabPanel>
        <TabPanel
          header="Template Configuration"
          pt={{
            root: {
              className: 'text-right',
            },
          }}
        >
          <TemplateConfiguration />
        </TabPanel>
      </TabView>
    </>
  );
};
