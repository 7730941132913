import React, { useState, useEffect, memo, useRef, useCallback } from 'react';
import { debounce } from 'lodash';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { Toast } from 'primereact/toast';
import { Icon, Typography, Box, Checkbox } from '@material-ui/core';

import GenericDataTable from '../../shared/GenericDataTable/GenericDataTable';
import PFButton from '../../shared/PFPrime/PFButton';
import PFInputText from '../../shared/PFPrime/PFInputText';
import { ALERT } from '../message';

import { useStyles } from './Clients.styles';
import { getClientsResponse, mergeCustomers } from './Clients.service';

const ChildClientList = memo(
  ({
    mergeClientRequest,
    setMergeClientRequest,
    onMergeClientPopUpHide,
    alert,
  }) => {
    const toast = useRef(null);
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [mergeLoading, setMergeLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState({
      limit: 10,
      offset: 0,
      search: '',
    });
    const [count, setCount] = useState();
    const [clientsListData, setClientsListData] = useState([]);
    const [childList, setChildList] = useState([]);
    const [childListObjects, setChildListObjects] = useState([]);

    const debouncedChangeHandler = useCallback(
      debounce(value => {
        if (value?.search.trim()) {
          getClientsResponse(value, setLoading, setClientsListData, setCount);
        } else {
          setClientsListData([]);
        }
      }, 500),
      []
    );

    useEffect(() => {
      if (clientsListData?.length === 0 && childListObjects?.length) {
        setClientsListData(childListObjects);
        setCount(childListObjects?.length || 0);
      }
    }, [clientsListData?.length]);

    const clientListingObject = val => {
      return {
        source_name: val.source_url ? (
          <Icon fontSize="large" component="span">
            <img
              width="40"
              height="30"
              alt="ProjectForce"
              src={val.source_url}
            />
          </Icon>
        ) : (
          <Box className={classes.sourceName}>
            <Typography variant="subtitle2">{val.source_name}</Typography>
          </Box>
        ),
        customer_id: val.customer_id,
        customer_name: (
          <Typography className={classes.customerName}>
            {val.customer_name}
          </Typography>
        ),
        mobile_phone: val.mobile_phone,
        email: <Typography className={classes.email}>{val.email}</Typography>,
        billing_address: val.billing_address,
        is_deleted: val?.is_deleted ? val?.is_deleted : 'Active',
      };
    };
    const clientsListing = clientsListData
      ?.filter(item => item.customer_id !== Number(mergeClientRequest?.parent))
      ?.map(val => {
        return clientListingObject(val);
      });

    useEffect(() => {
      debouncedChangeHandler(searchQuery);
    }, [searchQuery?.search]);

    let intervalVal = '';
    const handleSearch = searchInputVal => {
      const searchString = searchInputVal?.trim();

      /** Timeout will help to let multiple characters in the TextInput,* and API call would be optimized to get Values for Input of few chars at once,* rather than for every single character*/
      try {
        clearTimeout(intervalVal);
      } catch (err) {
        console.log(err);
      }
      intervalVal = setTimeout(() => {
        setLoading(true);
        setSearchQuery(prevState => ({
          ...prevState,
          offset: 0,
          search: searchString,
        }));
      }, 500);
    };

    const dataTableOptions = {
      download: false,
      print: false,
      filter: false,
      selectableRows: 'none',
      responsive: 'standard',
      tableBodyMinHeight: '300px',
      count: count,
      serverSide: true,
      textLabels: {
        body: {
          noMatch: !loading && !clientsListing.length && 'No records found',
        },
      },
      onChangeRowsPerPage: numberOfRows => {
        setLoading(true);
        setSearchQuery(prevState => ({
          ...prevState,
          limit: numberOfRows,
          offset: 0,
        }));
      },
      onChangePage: currentPage => {
        setLoading(true);
        setSearchQuery(prevState => ({
          ...prevState,
          offset: currentPage * searchQuery.limit,
        }));
      },
      onColumnSortChange: (changedColumn, direction) => {
        setLoading(true);
        setSearchQuery(prevState => ({
          ...prevState,
          sortKey: changedColumn,
          sortDirection: direction.toUpperCase(),
        }));
      },
      onSearchChange: searchText => {
        handleSearch(searchText);
      },
      onSearchClose: () => {},
      searchProps: {
        onKeyUp: e => {
          if (e.target.defaultValue && e.keyCode === 13) {
            setLoading(true);
            setSearchQuery(prevState => ({
              ...prevState,
              search: e.target.defaultValue,
              offset: 0,
            }));
          }
        },
      },
      rowsPerPage: searchQuery.limit,
      rowsPerPageOptions: [10, 20, 50, 100],
    };

    const dataTableColumns = [
      {
        name: 'Child',
        options: {
          sort: false,
          filter: false,
          viewColumns: false,

          setCellProps: () => ({
            style: { minWidth: '150px', maxWidth: '150px' },
          }),
          customBodyRenderLite: dataIndex => {
            return (
              <span id={dataIndex}>
                <span>
                  <Checkbox
                    checked={childList?.includes(
                      clientsListing[dataIndex]?.customer_id
                    )}
                    onChange={e =>
                      handleChildRequest(
                        e,
                        clientsListing ? clientsListing[dataIndex] : null
                      )
                    }
                  />
                </span>
              </span>
            );
          },
        },
      },
      {
        name: 'source_name',
        label: 'Source',
        options: {
          sort: false,
          filter: false,
          viewColumns: false,
          setCellProps: () => ({
            style: { minWidth: '100px', maxWidth: '100px' },
          }),
        },
      },
      { name: 'customer_name', label: 'Client Name', options: { sort: true } },
      { name: 'mobile_phone', label: 'Mobile Phone', options: { sort: true } },
      { name: 'email', label: 'Email Address', options: { sort: true } },
      {
        name: 'billing_address',
        label: 'Billing Address',
        options: { sort: false },
      },
      {
        name: 'is_deleted',
        label: 'Status',
        options: {
          sort: false,
          filter: false,
          setCellProps: () => ({
            style: { width: '15%' },
          }),
          customBodyRender: value => (
            <Typography
              variant="subtitle2"
              className={
                (value === 'Active' && classes.statusColorActive) ||
                (value === 'Inactive' && classes.statusColorInactive)
              }
            >
              {value}
            </Typography>
          ),
        },
      },
    ];

    const handleChildRequest = (e, child) => {
      if (e.target.checked) {
        setChildList(preValue => [...preValue, child?.customer_id]);
        setChildListObjects(preValue => [...preValue, child]);
      } else {
        setChildList(childList?.filter(i => i !== child?.customer_id) || []);
        setChildListObjects(
          childListObjects?.filter(
            i => i?.customer_id !== child?.customer_id
          ) || []
        );
      }
    };

    useEffect(() => {
      setMergeClientRequest(preValue => ({ ...preValue, childs: childList }));
    }, [childList.length]);

    const apiServiceMergeCustomers = async () => {
      const responce = await mergeCustomers(mergeClientRequest);
      if (responce.status) {
        if (typeof alert === 'function')
          alert('success', 'Customer merged successfully', false, true);
        if (typeof alert === 'object') {
          alert.current.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Customer merged successfully',
          });
        }

        onMergeClientPopUpHide();
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Something went wrong',
        });
        setMergeLoading(false);
      }
    };

    const accept = () => {
      setMergeLoading(true);
      apiServiceMergeCustomers(mergeClientRequest);
    };

    const confirm = event => {
      confirmPopup({
        target: event.currentTarget,
        message: ALERT.PROJECT_CLIENT_TRANSFER_LEAD,
        icon: 'pi pi-info-circle',
        defaultFocus: 'accept',
        acceptClassName: 'p-button-info',
        rejectLabel: 'Cancel',
        accept,
        reject: () => {
          return false;
        },
      });
    };

    return (
      <>
        <Toast ref={toast} />
        <ConfirmPopup className="w-3" />
        <div className="grid py-2">
          <div className="col">
            <div className="">
              <span className="p-input-icon-right w-full">
                <i className="pi pi-search" />
                <PFInputText
                  type="text"
                  value={searchQuery?.search || ''}
                  placeholder="Search client name, phone number, email"
                  onChange={e => {
                    setSearchQuery({
                      ...searchQuery,
                      search: e.target.value || '',
                    });
                  }}
                />
              </span>
            </div>
          </div>
          <div className="col">
            <div className="flex justify-content-end align-items-center">
              <PFButton
                label={`Merge & Delete ${childList.length > 0 ? `(${childList.length})` : ''}`}
                disabled={!childList.length}
                onClick={confirm}
                loading={mergeLoading}
              />
            </div>
          </div>
        </div>

        <GenericDataTable
          options={dataTableOptions}
          columns={dataTableColumns}
          data={clientsListing}
        />
      </>
    );
  }
);
ChildClientList.displayName = 'ChildClientList';
export default ChildClientList;
