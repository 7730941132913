import { useEffect, useState } from 'react';
import { Skeleton } from 'primereact/skeleton';

import { momentTz } from '../../../utils/Helpers';
import { NOT_APPLICABLE } from '../../../constants/string.constant';

import { getActivitiesData } from './services/activities.service';
import { ActivityResponse } from './Interface/Activites.interface';
import ActivitiesDataTable from './components/ActivityDataTable';

const ActivitiesContainer = ({ projectId }: { projectId: number }) => {
  const [projectActivityData, setProjectActivityData] =
    useState<ActivityResponse | null>(null);
  const [loading, setLoading] = useState(false);
  const getProjectActivitiesData = async () => {
    try {
      setLoading(true);
      const response = await getActivitiesData(projectId);
      if (response) {
        setLoading(false);
        setProjectActivityData({
          count: response?.count,
          items: response?.items,
        });
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (projectId) {
      getProjectActivitiesData();
    }
  }, [projectId]);

  return (
    <div className="grid grid-nogutter text-xs">
      <div className="sm:col-12 md:col-4 lg:col-4 xl:col-4 p-2">
        <span className="p-float-label">
          {loading ? (
            <Skeleton className="w-full" height="3rem" />
          ) : (
            <>
              <span className="font-bold text-600">
                Activity Type:&nbsp;&nbsp;
              </span>
              <span>
                {projectActivityData?.items?.length
                  ? projectActivityData?.items?.[0]?.activity_type
                  : NOT_APPLICABLE}
              </span>
            </>
          )}
        </span>
      </div>
      <div className="sm:col-12 md:col-4 lg:col-4 xl:col-4 p-2">
        <span className="p-float-label">
          {loading ? (
            <Skeleton className="w-full" height="3rem" />
          ) : (
            <>
              <span className="font-bold text-600">
                Date Activity Created:&nbsp;&nbsp;
              </span>
              <span>
                {projectActivityData?.items &&
                projectActivityData?.items[0]?.activity_created_date
                  ? momentTz(
                      projectActivityData?.items[0]?.activity_created_date
                    ).format('MM-DD-YYYY hh:mm A')
                  : NOT_APPLICABLE}
              </span>
            </>
          )}
        </span>
      </div>
      <div className="sm:col-12 md:col-4 lg:col-4 xl:col-4 p-2">
        <span className="p-float-label">
          {loading ? (
            <Skeleton className="w-full" height="3rem" />
          ) : (
            <>
              <span className="font-bold text-600">
                Activity Due Date:&nbsp;&nbsp;
              </span>
              <span>
                {projectActivityData?.items &&
                projectActivityData?.items[0]?.activity_due_date
                  ? momentTz(
                      projectActivityData?.items[0]?.activity_due_date
                    ).format('MM-DD-YYYY hh:mm A')
                  : NOT_APPLICABLE}
              </span>
            </>
          )}
        </span>
      </div>
      <div className="sm:col-12 md:col-4 lg:col-4 xl:col-4 p-2">
        <span className="p-float-label">
          {loading ? (
            <Skeleton className="w-full" height="3rem" />
          ) : (
            <>
              <span className="font-bold text-600">
                Activity Status:&nbsp;&nbsp;
              </span>
              <span>
                {projectActivityData?.items[0]?.activity_status
                  ? projectActivityData?.items[0]?.activity_status
                  : NOT_APPLICABLE}
              </span>
            </>
          )}
        </span>
      </div>
      <ActivitiesDataTable
        projectActivityData={projectActivityData}
        loading={loading}
      />
    </div>
  );
};

export default ActivitiesContainer;
