import React, { useEffect, useState, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tag } from 'primereact/tag';
import { Sidebar } from 'primereact/sidebar';
import { Toast } from 'primereact/toast';

import apiService from '../../../../services/api.service';
import {
  formatDateTimeMDYHM,
  formatDateDefault,
} from '../../../../utils/Helpers';
import TableSkeleton from '../../../shared/Skeleton/TableSkeleton';

import InstallerModal from './InstallerModal';

const History = ({ state }) => {
  const [routingList, setRoutingList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const toast = useRef(null);

  const widgetId = state?.widgetTemplate?.widget_template_id;

  const callGetRouting = async () => {
    setLoading(true);
    try {
      const response = await apiService.get(`/routing/${widgetId}`);
      setRoutingList(response);
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Something went wrong',
        detail: error?.message,
        life: '3000',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    callGetRouting();
  }, []);

  const searchCriteria = rowData => {
    const startDateTime = rowData?.route_start_date;
    const startEndTime = rowData?.route_end_date;
    return (
      formatDateDefault(new Date(startDateTime), '-') +
      ' - ' +
      formatDateDefault(new Date(startEndTime), '-')
    );
  };

  const executionDateTime = rowData => {
    const dateTime = rowData?.created_at;
    let formattedDateTime = formatDateTimeMDYHM(dateTime);
    return formattedDateTime;
  };

  const completionDateTime = rowData => {
    const dateTime = rowData?.completion_date;
    let formattedDateTime = formatDateTimeMDYHM(dateTime);
    return formattedDateTime;
  };

  const getStatus = status => {
    switch (status) {
      case 'PENDING':
        return 'warning';
      case 'COMPLETED':
        return 'success';
    }
  };

  const statusTemplate = rowData => {
    return <Tag value={rowData.status} severity={getStatus(rowData.status)} />;
  };

  const customHeader = (
    <React.Fragment>
      <div className="grid w-12 text-white m-0 text-center justify-content-center">
        <div className="w-12">
          <h2 className="m-0">History</h2>
        </div>
        <div className="grid w-12">
          <div className="col-12 md:col-4 pb-0">
            <h4 className="mt-2 mb-0 text-white">
              <span className="font-light"> Widget Name:</span>{' '}
              {state?.widgetTemplate?.widget_name}
            </h4>
          </div>
          <div className="col-12 md:col-4 pb-0">
            <h4 className="mt-2 mb-0 text-white">
              <span className="font-light">Start Date:</span>{' '}
              {formatDateDefault(new Date(selectedItem?.route_start_date), '-')}
            </h4>
          </div>
          <div className="col-12 md:col-4 pb-0">
            <h4 className="mt-2 mb-0 text-white">
              <span className="font-light">End Date:</span>{' '}
              {formatDateDefault(new Date(selectedItem?.route_end_date), '-')}
            </h4>
          </div>
        </div>
      </div>
    </React.Fragment>
  );

  return (
    <>
      {loading ? (
        <TableSkeleton className="mt-3" />
      ) : (
        <DataTable
          className="mt-3"
          value={routingList?.data}
          tableStyle={{ minWidth: '50rem' }}
          selectionMode="single"
          selection={setSelectedItem}
          onSelectionChange={e => {
            if (e?.value?.status === 'COMPLETED') {
              setSelectedItem(e.value);
              setVisible(true);
            } else {
              setSelectedItem(null);
              setVisible(false);
            }
          }}
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          lazy
        >
          <Column
            field="route_start_date"
            header="Search Criteria"
            body={searchCriteria}
          ></Column>

          <Column
            field="route_start_date"
            header="Execution Start"
            body={executionDateTime}
          ></Column>
          <Column
            field="completion_date"
            header="Execution End"
            body={completionDateTime}
          ></Column>
          <Column field="full_name" header="Search By"></Column>
          <Column field="status" header="Status" body={statusTemplate}></Column>
        </DataTable>
      )}

      {selectedItem && (
        <Sidebar
          visible={visible}
          onHide={() => {
            setVisible(false);
            setSelectedItem(null);
            setLoading(false);
          }}
          fullScreen
          icons={customHeader}
          pt={{
            icons: {
              className: 'w-12',
            },
            header: {
              className: 'bg-primary',
            },
          }}
        >
          <InstallerModal selectedItem={selectedItem} widgetId={widgetId} />
        </Sidebar>
      )}
      <Toast ref={toast} />
    </>
  );
};

export default History;
