import { makeStyles } from '@material-ui/core/styles';

import { StatusColors, Colors } from '../../../constants';

export const useStyles = makeStyles(theme => ({
  aestrik: {
    color: theme.palette.error.main,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  checkboxRoot: {
    padding: '9px 0px',
    width: '23px',
    height: '23px',
  },
  disableSection: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  everyWeek: {
    border: `1px dashed ${Colors.PF_GREYBORDER}`,

    height: '29px',
    width: '35px',
    margin: '0px 10px',
  },
  notes: {
    border: `1px dashed ${Colors.PF_GREYBORDER}`,
  },
  selfAlignGrid: {
    alignSelf: 'flex-end',
  },
  sourceName: {
    height: '53px',
    display: 'flex',
    alignItems: 'center',
  },
  actionIcons: {
    padding: '0px 6px',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  recurringSection: {
    border: `1px dashed ${Colors.PF_GREYBORDER}`,
  },
  recurringDayList: {
    paddingLeft: '30px',
  },
  dataTableWrapper: {
    display: 'table',
    tableLayout: 'fixed',
    width: '100%',
  },
  statusColorPending: { color: theme.palette.grey[800] },
  statusColorAcknowledged: { color: StatusColors.PF_GREEN },
  statusColorContactOffice: { color: StatusColors.PF_YELLOW },
  statusColorWithdrawn: { color: theme.palette.grey[800], opacity: 0.5 },
  statusColorCancelled: { color: StatusColors.PF_RED },
}));
