import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { capitalize } from 'lodash';
import { Card, InputText, Button, SelectButton } from 'primereact';
import { useSelector, useDispatch } from 'react-redux';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';

import Loader from '../../shared/Loader/Loader';
import { getClientList } from '../Bulletin/Bulletin.service';
import PageHeader from '../../shared/PageHeader/PageHeader';
import ApiService from '../../../services/api.service';
import {
  warrantyConfigUpdateClientList,
  warrantyConfigUpdateComponent,
} from '../../../redux/slices/warranty-config.slice';
import { PF_ADMIN } from '../../../constants';
import { useConfirmDialogContext } from '../../../contexts/ConfirmDialog';

const WarrantyConfiguration = () => {
  const { showConfirmationDialog } = useConfirmDialogContext();
  const roBreadcrumb = [
    {
      link: '/admin/configuration',
      text: 'Company Configurations',
    },
    {
      text: 'Warranty Configurations',
    },
  ];
  const history = useHistory();
  const { isLoading, clientList } = useSelector(state => state.warrantyConfig);
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState('');
  const [orderBy, setOrderBy] = useState('ASC');
  const clientId = localStorage.getItem('client_id');
  useEffect(async () => {
    const clientListResponse = await getClientList();
    dispatch(
      warrantyConfigUpdateClientList({
        clientList: clientListResponse.map(client => ({
          ...client,
          isEnabled: client.warranty_module,
        })),
      })
    );
    dispatch(warrantyConfigUpdateComponent({ isLoading: false }));
  }, []);

  const handleCardToggle = client_id => {
    const newClientList = clientList.map(client => {
      if (client.client_id === client_id) {
        return {
          ...client,
          isEnabled: !client.isEnabled,
        };
      } else {
        return client;
      }
    });
    dispatch(warrantyConfigUpdateClientList({ clientList: newClientList }));
  };

  const toast = useRef(null);

  const accept = async () => {
    try {
      toast.current.show({
        severity: 'info',
        summary: 'Info',
        detail: 'Warranty configuration updating',
        life: 3000,
      });
      const response = await ApiService.patch(
        '/system/configure-warranty-permission',
        clientList
      );
      if (response) {
        toast.current.show({
          severity: 'success',
          summary: 'Confirmed',
          detail: 'Warranty configuration saved successfully',
          life: 3000,
        });
      }
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed to save Warranty configuration',
        life: 3000,
      });
      console.error(error);
    }
  };

  const reject = () => {
    toast.current.show({
      severity: 'error',
      summary: 'Rejected',
      detail: 'Fail to save this Warranty Configuration',
      life: 3000,
    });
  };

  const confirmSaveConfiguration = () => {
    showConfirmationDialog({
      message: 'Are you sure you want to save this Warranty Configuration?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept,
      reject,
    });
  };

  const sortingTemplate = option => {
    return <i className={option.icon}></i>;
  };

  if (clientId !== PF_ADMIN) {
    history.push(`/dashboard`);
  }

  return (
    <>
      <div className="w-full ">
        <div className="grid grid-nogutter w-full flex flex-row justify-content-between">
          <div className="col-12 lg:col-6">
            <PageHeader
              pageTitle="Warranty Configuration"
              breadCrumbArray={roBreadcrumb}
            />
          </div>
          <div className="col-12 lg:col-6 block lg:justify-content-end">
            <div className="grid grid-nogutter flex-column md:flex-row w-12 md:w-auto justify-content-end">
              <span className="p-input-icon-left mr-2 flex-none flex mt-3 md:mt-0">
                <InputText
                  value={searchQuery}
                  onChange={e => setSearchQuery(e.target.value)}
                  placeholder="Search"
                  className="w-12 pl-3"
                />
              </span>
              <span className="p-input-icon-left mr-2 my-3 md:my-0">
                <SelectButton
                  value={orderBy}
                  onChange={e => {
                    setOrderBy(e?.value);
                  }}
                  itemTemplate={sortingTemplate}
                  options={[
                    { icon: 'pi pi-sort-alpha-up', value: 'ASC' },
                    { icon: 'pi pi-sort-alpha-down', value: 'DESC' },
                  ]}
                />
              </span>
              <Button
                onClick={confirmSaveConfiguration}
                label="Save"
                size="small"
                severity="primary"
              />
            </div>
          </div>
        </div>
      </div>

      {isLoading ? (
        <Loader />
      ) : (
        <div className="flex flex-column mt-2">
          <div className="grid flex flex-row flex-wrap mt-2">
            {clientList
              ?.filter(clientObj => {
                if (searchQuery) {
                  return (
                    clientObj.client_name
                      ?.toLocaleUpperCase()
                      ?.includes(searchQuery?.toLocaleUpperCase()) ||
                    clientObj.client_id
                      ?.toLocaleUpperCase()
                      ?.includes(searchQuery?.toLocaleUpperCase())
                  );
                }
                return true;
              })
              .sort((first, second) => {
                if (orderBy === 'ASC') {
                  return first.client_name.localeCompare(second.client_name);
                }
                return second.client_name.localeCompare(first.client_name);
              })
              .map((client, index) => (
                <div key={index} className="col-12 sm:col-6 lg:col-3">
                  <Card
                    key={client.client_id}
                    className="flip"
                    style={{
                      background: `linear-gradient(to bottom, #a8c0ff, #3f2b96)`,
                      cursor: 'pointer',
                    }}
                    onClick={() => handleCardToggle(client.client_id)}
                    pt={{
                      content: {
                        className: 'py-0 md:py-2',
                      },
                    }}
                  >
                    <div
                      className="flex flex-row justify-content-between align-items-center"
                      style={{ height: '40px' }}
                    >
                      <span style={{ color: 'white' }}>
                        {capitalize(client.client_name)}
                      </span>
                      <span className="ml-3">
                        {client.isEnabled ? (
                          <i className="pi pi-check-circle text-white"></i>
                        ) : (
                          <i className="pi pi-circle text-white"></i>
                        )}
                      </span>
                    </div>
                  </Card>
                </div>
              ))}
          </div>
          <Toast ref={toast} />
          <ConfirmDialog />
        </div>
      )}
    </>
  );
};

export default WarrantyConfiguration;
