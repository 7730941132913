import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

import { quoteInfoInit } from './components/QuoteInfo.model';
import { clientInfoInit } from './components/ClientInfo.model';
import { quoteAmountInit } from './components/QuoteAmount.model';
import { termsInit } from './components/TermsCondition.model';
import { notesInit } from './components/Notes.model';
import { getQuoteById } from './Quote.service';

export const formatDate = date => {
  return String(new Date(date)).toLowerCase() !== 'invalid date'
    ? moment(date).add(1, 'd').format('YYYY-MM-DD')
    : '';
};

const initialValues = {
  ...quoteInfoInit,
  ...notesInit,
  // ...leadSourceInit,
  ...termsInit,
  ...clientInfoInit,
  ...quoteAmountInit,
};

const validationSchema = Yup.object().shape({
  // ...quoteValidation,
  // ...leadSourceValidation,
  // ...clientInfoValidation,
  // ...quoteAmountValidation,
  // ...termsConsValidation,
});

export const callGetQuoteById = async ({ quoteId, setLoading, toast, cb }) => {
  setLoading(0);
  try {
    const { status, data } = await getQuoteById(quoteId);
    if (status === 200 && data?.data) {
      cb(data.data);
    } else
      toast.current.show({
        severity: 'error',
        summary: 'API Error',
        detail: data?.message,
      });
  } catch (ex) {
    toast.current.show({
      severity: 'error',
      summary: 'Error',
      detail: ex?.response?.data?.message || 'Error in fetching Quote detail.',
    });
  } finally {
    setLoading(-2);
  }
};

const handleSave = async (values, { toast, history }) => {
  toast.current.show({
    severity: 'success',
    summary: 'API success',
    detail: 'Quote added successfully',
  });
  history.push(`/client/721957/crm/quote/1003/view`);
};

export const sendQuote = async ({ toast, setLoading }) => {
  toast.current.show({
    severity: 'success',
    summary: 'API Success',
    detail: 'Quote sent successfully',
  });
  setLoading(-2);
};
export const quoteSubmit = quoteProps => {
  return useFormik({
    enableReinitialize: true,
    initialValues: quoteProps?.initialValue || initialValues,
    validationSchema: validationSchema,
    onSubmit: async values => {
      await handleSave(values, quoteProps);
    },
  });
};
