import React, { useState, useEffect, createContext, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { Card } from 'primereact/card';

import { AddEditViewReceiving } from './components/AddEditViewReceiving';
import { Receiving } from './components/Receiving';
import { ReceiptTable } from './components/ReceiptTable';

export const ReceivingContext = createContext();

export const ReceivingContainer = () => {
  const toast = useRef(null);
  const { projectId } = useParams();
  const [receivingContextValue, setReceivingContextValue] = useState({
    show: false,
    hide: () => {
      setReceivingContextValue(preValue => ({
        ...preValue,
        show: !preValue?.show,
      }));
    },
    visibleMode: {
      visible: 'add',
      data: {},
      disabled: false,
    },
    updateContextValue: data => {
      setReceivingContextValue(preValue => ({
        ...preValue,
        ...data,
      }));
    },
    toast: toast,
  });

  useEffect(() => {
    if (projectId) {
      setReceivingContextValue(preValue => ({
        ...preValue,
        project_id: projectId,
      }));
    }
  }, [projectId]);
  return (
    <ReceivingContext.Provider
      value={{ receivingContextValue, setReceivingContextValue }}
    >
      <Toast ref={toast} />
      <div className="flex flex-wrap gap-4 p-4">
        <Card pt={{ body: { className: 'pt-0' } }} className="w-full shadow-2">
          <Receiving />
        </Card>
        <Card pt={{ body: { className: 'pt-0' } }} className="w-full shadow-2">
          <ReceiptTable />
        </Card>
      </div>
      {receivingContextValue?.show ? <AddEditViewReceiving /> : null}
    </ReceivingContext.Provider>
  );
};
