import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Toast } from 'primereact/toast';
import _ from 'lodash';

import { PFDataTableEdit, PFButton } from '../pf-prime/Prime';

import {
  adjustmentData,
  addNewRow,
  updateRowData,
  displayDelayed,
  getAdjustments,
  resetCount,
} from './InventoryAdjustment.model';
import {
  addAdjustment,
  adjustmentClearState,
} from './reducers/InventoryAdjustmentSlice';
import { setData as setProductData } from './reducers/ProductDetailsSlice';
import { addWarehouse } from './reducers/WarehouseSlice';
import TableLoader from './Loader/TableLoader';
import {
  adjustmentDispatch,
  adjustmentUndoDispatch,
  getInventoryAdjustmetns,
  getWarehouseInventories,
  getInventoryProduct,
  createAdjustment,
  updateAdjustment,
} from './services/adjustment.services';

const InventoryAdjustment = () => {
  const toast = useRef(null);
  const dispatch = useDispatch();
  let { itemInfo, projectInfo } = useSelector(
    state => state.inventoryManagment?.ProductInfo
  );

  let { listItems: adjustmentItems } = useSelector(
    state => state.inventoryManagment?.Adjustment
  );

  const [data, setData] = useState({});
  const [rowInserted, setRowInserted] = useState(false);
  const [updateRow, setUpdateRow] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [deletedRow, setDeletedRow] = useState(null);
  const [loading, setLoading] = useState(true);
  const [dataTableloading, setDataTableloading] = useState(false);
  const [onCancel, setOnCancel] = useState(false);
  const [dataTableConfig, setDataTableConfig] = useState({
    first: 0,
    rows: 10,
  });

  useEffect(() => {
    return () => {
      dispatch(adjustmentClearState());
      setData({});
      setRowInserted(false);
      setIsDisabled(false);
      setDeletedRow(null);
      setLoading(true);
      setDataTableloading(false);
      setOnCancel(false);
      resetCount();
    };
  }, []);

  useEffect(() => {
    if (itemInfo?._id) {
      getAdjustments(
        itemInfo?._id,
        dispatch,
        setDataTableloading,
        itemInfo?.productId,
        projectInfo?.projectId
      );
    }
    if (itemInfo?.unit?.length === 0) {
      setIsDisabled(true);
    }
  }, [itemInfo]);

  useEffect(() => {
    if (onCancel) {
      if (itemInfo?._id) {
        getAdjustments(
          itemInfo?._id,
          dispatch,
          setDataTableloading,
          itemInfo?.productId,
          projectInfo?.projectId
        );
      }
      setOnCancel(false);
    }
  }, [onCancel]);

  const [selectedWarehouse, setSelectedWarehouse] = useState(null);

  useEffect(() => {
    setData(
      adjustmentData(
        setUpdateRow,
        setIsDisabled,
        setDeletedRow,
        setOnCancel,
        setSelectedWarehouse,
        selectedWarehouse
      )
    );
    setLoading(false);
  }, []);

  useEffect(() => {
    setSelectedWarehouse(null);
    if (
      data?.listItems &&
      data?.listItems?.length === 0 &&
      adjustmentItems?.length > 0
    ) {
      const newSet = {
        ...data,
        listItems: _.cloneDeep(adjustmentItems).reverse(),
      };
      setData(newSet);
    }
  }, [data, adjustmentItems]);

  useEffect(() => {
    setSelectedWarehouse(null);
    setData(s => ({
      ...s,
      listItems: _.cloneDeep(adjustmentItems).reverse(),
    }));
  }, [adjustmentItems]);

  useEffect(() => {
    setSelectedWarehouse(null);
    if (updateRow) {
      setDataTableloading(true);
      updateRow.newData.projectId = projectInfo.projectId;
      updateRow.newData.projectNumber = projectInfo.projectNumber;
      updateRowData(
        updateRow,
        data,
        setData,
        'U',
        dispatch,
        toast,
        setDataTableloading
      );
    }
  }, [updateRow]);

  useEffect(() => {
    setSelectedWarehouse(null);
    setDataTableloading(true);
    if (deletedRow) {
      updateRowData(
        deletedRow,
        data,
        setData,
        'D',
        dispatch,
        toast,
        setDataTableloading
      );
    }
  }, [deletedRow]);

  useEffect(() => {
    if (rowInserted) displayDelayed(rowInserted, setRowInserted);
  }, [rowInserted]);

  const handleInventoryUndoDispatch = async (e, rowData) => {
    try {
      setDataTableloading(true);
      const adjustmentId = rowData?._id;
      const undoDispatchResponse = await adjustmentUndoDispatch(adjustmentId);
      if (undoDispatchResponse?.status) {
        toast.current.show({
          severity: 'success',
          summary: 'Success!',
          detail: 'Adjustment Updated successfully',
          life: 3000,
        });
        const newAdjustments = await getInventoryAdjustmetns(
          rowData?.productId
        );
        const list = newAdjustments?.map((item, i) => {
          const newKeys = {
            index: i,
            validateAdjustment: true,
            errorAdjustment: [],
          };
          return { ...item, ...newKeys };
        });
        dispatch(addAdjustment({ item: list.length > 0 ? list : [] }));
        const newWarehouseInventories = await getWarehouseInventories(
          rowData?.productId
        );
        dispatch(addWarehouse({ item: newWarehouseInventories || [] }));
        const updatedProductData = await getInventoryProduct(
          itemInfo?.productId
        );
        if (updatedProductData?.status && updatedProductData?.data) {
          dispatch(setProductData({ item: updatedProductData?.data }));
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setDataTableloading(false);
    }
  };

  const handleInventoryDispatch = async (e, rowData) => {
    try {
      setDataTableloading(true);
      const adjustmentId = rowData?._id;
      const dispatchResponse = await adjustmentDispatch(adjustmentId);
      if (dispatchResponse?.status) {
        toast.current.show({
          severity: 'success',
          summary: 'Success!',
          detail: 'Adjustment Updated successfully',
          life: 3000,
        });
        const updatedAdjustments = await getInventoryAdjustmetns(
          rowData?.productId
        );
        const list = updatedAdjustments?.map((item, i) => {
          const newKeys = {
            index: i,
            validateAdjustment: true,
            errorAdjustment: [],
          };
          return { ...item, ...newKeys };
        });
        dispatch(addAdjustment({ item: list.length > 0 ? list : [] }));
        const updatedWarehouseInventories = await getWarehouseInventories(
          rowData?.productId
        );
        dispatch(addWarehouse({ item: updatedWarehouseInventories || [] }));
        const updatedProductData = await getInventoryProduct(
          itemInfo?.productId
        );
        if (updatedProductData?.status && updatedProductData?.data) {
          dispatch(setProductData({ item: updatedProductData?.data }));
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setDataTableloading(false);
    }
  };

  const handleInventoryOnOrder = async (e, rowData) => {
    try {
      setDataTableloading(true);
      const createPayload = {
        productId: rowData.productId,
        date: new Date(rowData.date),
        adjustmentType: 'Add',
        warehouseId: rowData.warehouseId,
        quantityToAdjust: rowData.quantityToAdjust,
        unit: rowData.unit,
        noteText: rowData.noteText,
        binId: rowData.binId,
      };
      const updatePayload = {
        _id: rowData._id,
        productId: rowData.productId,
        date: new Date(rowData.date),
        adjustmentType: 'Allocated',
        warehouseId: rowData.warehouseId,
        binId: rowData.binId,
        quantityToAdjust: rowData.quantityToAdjust,
        unit: rowData.unit,
        noteText: rowData.noteText,
        convertedQuantityToAdjust: rowData.convertedQuantityToAdjust,
        purchasePricePerUnit: rowData.purchasePricePerUnit,
        salesPricePerUnit: rowData.salesPricePerUnit,
        projectId: projectInfo.projectId,
        projectNumber: projectInfo.projectNumber,
      };
      await createAdjustment(createPayload);
      const dispatchResponse = await updateAdjustment(updatePayload);
      if (dispatchResponse) {
        toast.current.show({
          severity: 'success',
          summary: 'Success!',
          detail: 'Adjustment Updated successfully',
          life: 3000,
        });
        const updatedAdjustments = await getInventoryAdjustmetns(
          rowData?.productId
        );
        const list = updatedAdjustments?.map((item, i) => {
          const newKeys = {
            index: i,
            validateAdjustment: true,
            errorAdjustment: [],
          };
          return { ...item, ...newKeys };
        });
        dispatch(addAdjustment({ item: list.length > 0 ? list : [] }));
        const updatedWarehouseInventories = await getWarehouseInventories(
          rowData?.productId
        );
        dispatch(addWarehouse({ item: updatedWarehouseInventories || [] }));
        const updatedProductData = await getInventoryProduct(
          itemInfo?.productId
        );
        if (updatedProductData?.status && updatedProductData?.data) {
          dispatch(setProductData({ item: updatedProductData?.data }));
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setDataTableloading(false);
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <div style={{ minHeight: '70vh' }} className="inventory">
        <div className="col-12 md:col-6 lg:col-11 p-input-icon-right mb-3 m-0 p-0 ">
          <h2>Inventory Adjustment</h2>
        </div>
        <div className="col-12 md:col-6 lg:col-1 p-input-icon-right mb-3 m-0 p-0">
          <div className="flex justify-content-end">
            <PFButton
              icon="pi pi-plus"
              onClick={() => {
                setDataTableConfig(pre => ({ ...pre, first: 0 }));

                addNewRow(data, setData, setRowInserted, itemInfo);
              }}
              className="mb-1"
              disabled={isDisabled}
            />
          </div>
        </div>
        <div>
          {loading ? (
            <TableLoader columnCount={5} noOfRow={4} mTopBottom={0} />
          ) : (
            <PFDataTableEdit
              data={data || []}
              setIsDisabled={setIsDisabled}
              paginator={true}
              first={dataTableConfig?.first || 0}
              rows={dataTableConfig?.rows || 0}
              loading={dataTableloading}
              isInventoryAdjustment={true}
              handleInventoryDispatch={handleInventoryDispatch}
              handleInventoryUndoDispatch={handleInventoryUndoDispatch}
              handleInventoryOnOrderDispatch={handleInventoryOnOrder}
              setFirst={e => {
                setDataTableConfig(pre => ({ ...pre, first: e }));
              }}
              isDisabled={isDisabled}
              setRows={e => {
                setDataTableConfig(pre => ({ ...pre, rows: e }));
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default InventoryAdjustment;
