import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: true,
  installersList: [],
  isSelectDialogOpen: false,
  newEventInfo: {},
  viewChangeFlag: false,
  initialInstaller: {},
  mode: null,
};

const installerViewCalendarSlice = createSlice({
  name: 'installerViewCalendar',
  initialState: initialState,
  reducers: {
    setInstallerViewCalendar(state, { payload }) {
      state.isLoading = Object.prototype.hasOwnProperty.call(
        payload,
        'isLoading'
      )
        ? payload.isLoading
        : state.isLoading;
      state.installersList = Object.prototype.hasOwnProperty.call(
        payload,
        'installersList'
      )
        ? payload.installersList
        : state.installersList;
      state.viewChangeFlag = Object.prototype.hasOwnProperty.call(
        payload,
        'viewChangeFlag'
      )
        ? payload.viewChangeFlag
        : state.viewChangeFlag;
      state.isSelectDialogOpen = Object.prototype.hasOwnProperty.call(
        payload,
        'isSelectDialogOpen'
      )
        ? payload.isSelectDialogOpen
        : state.isSelectDialogOpen;
      state.newEventInfo = Object.prototype.hasOwnProperty.call(
        payload,
        'newEventInfo'
      )
        ? payload.newEventInfo
        : state.newEventInfo;
      state.initialInstaller = Object.prototype.hasOwnProperty.call(
        payload,
        'initialInstaller'
      )
        ? payload.initialInstaller
        : state.initialInstaller;
      state.mode = Object.prototype.hasOwnProperty.call(payload, 'mode')
        ? payload.mode
        : state.mode;
    },
  },
});

export const { setInstallerViewCalendar } = installerViewCalendarSlice.actions;

export const installerViewCalendarReducer = installerViewCalendarSlice.reducer;
