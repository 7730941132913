import {
  Grid,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';

const RouteConfiguration = ({
  action,
  widgetFormik,
  setRoutingWidgetHandler,
}) => {
  return (
    <>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12} md={4}>
          <Typography variant="h3">Route Configuration</Typography>
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={1}>
        <Grid item style={{ alignSelf: 'center' }}>
          <span>Is Routing Widget? </span>
        </Grid>
        <Grid item>
          <FormControl>
            <RadioGroup
              row
              value={widgetFormik.values.route_widget}
              defaultValue={0}
              onChange={event => {
                setRoutingWidgetHandler(parseInt(event?.target?.value));
                widgetFormik.setFieldValue(
                  'route_widget',
                  parseInt(event?.target?.value)
                );
              }}
            >
              <FormControlLabel
                value={1}
                control={<Radio />}
                label="Yes"
                disabled={action === 'view'}
              />
              <FormControlLabel
                value={0}
                control={<Radio />}
                label="No"
                disabled={action === 'view'}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

export default RouteConfiguration;
