import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, IconButton, Button } from '@material-ui/core';
import {
  Visibility as VisibilityIcon,
  Refresh as RefreshIcon,
} from '@material-ui/icons';
import { JSONTree } from 'react-json-tree';
import moment from 'moment';

import PageHeader from '../../shared/PageHeader/PageHeader';
import GenericDataTable from '../../shared/GenericDataTable/GenericDataTable';
import GenericDialog from '../../shared/Dialog/GenericDialog';
import GenericConfirmationDialog from '../../shared/GenericConfirmationDialog/GenericConfirmationDialog';
import { Alert } from '../../shared/Alerts/Alert';
import { useAlerts } from '../../shared/Alerts/alertsService';
import TableColumnsLoader from '../../shared/Loader/tableColumnsLoader';

import { getLogsList, triggerPipelineLogs } from './RuleEngineLogs.service';
import { useStyles } from './RuleEngineLogs.styles';

function handleRowHover(event, row, rowIndex) {
  let control = document.getElementById(rowIndex);
  if (control) control.style.display = 'block';
}

function handleRowHoverLeave(event, row, rowIndex) {
  let control = document.getElementById(rowIndex);
  if (control?.style?.display) control.style.display = 'none';
}

const RuleEngineLogs = () => {
  const { id } = useParams();
  const [pullOrderRuleType, setPullOrderRullType] = React.useState(
    id ? id : ''
  );
  const clientsBreadcrumb = [
    {
      text: 'Admin',
    },
    {
      text: 'Event Logs',
    },
  ];

  const [loading, setLoading] = React.useState(true);
  const [searchQuery, setSearchQuery] = React.useState({
    limit: 10,
    offset: 0,
    sortDirection: 'DESC',
    sortKey: 'created_at',
  });

  const classes = useStyles();
  const [logListData, setLogListData] = React.useState([]);

  const { alert, setAlert, clearAlert } = useAlerts();
  const [payloadData, setPayloadData] = React.useState([]);
  const [reloadForm, setReloadForm] = React.useState(false);
  const [logListCount, setLogListCount] = React.useState();
  const [dialogSettings, setDialogSettings] = React.useState({
    title: '',
    button1Text: '',
    button2Text: '',
    showButton1: true,
    showButton2: false,
  });
  const [confirmDialog, setConfirmDialog] = React.useState({
    header: '',
    title: '',
    subtitle: '',
    isOpen: false,
  });
  const [isOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => {
    clearAlert();
  }, []);

  React.useEffect(() => {
    getLogsList(
      searchQuery,
      () => {},
      setLoading,
      setLogListData,
      setLogListCount
    );
  }, [searchQuery, reloadForm, setLoading]);

  const dataTableColumns = [
    {
      name: '',
      options: {
        sort: true,
        filter: false,
        viewColumns: false,
        setCellProps: () => ({
          style: { minWidth: '150px', maxWidth: '150px' },
        }),
        ...(!loading
          ? {
              customBodyRenderLite: dataIndex => {
                return (
                  <span style={{ display: 'none' }} id={dataIndex}>
                    <span id={dataIndex}>
                      <span>
                        <IconButton
                          classes={{ root: classes.actionIcons }}
                          onClick={() => {
                            viewPayloadLog(
                              'view',
                              dataIndex,
                              dataArray && dataArray[dataIndex]
                            );
                          }}
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </span>
                      <span>
                        <IconButton
                          classes={{ root: classes.actionIcons }}
                          onClick={() => confirmTrigger(dataArray[dataIndex])}
                        >
                          <RefreshIcon />
                        </IconButton>
                      </span>
                    </span>
                  </span>
                );
              },
            }
          : {}),
      },
    },
    {
      name: 'project_number',
      label: 'Project Number',

      options: {
        sort: false,
        filter: false,
        viewColumns: true,
        setCellProps: () => ({
          style: { minWidth: '90px' },
        }),
      },
    },
    {
      name: 'po_number',
      label: 'P.O. Number',

      options: {
        sort: false,
        filter: false,
        viewColumns: true,
        setCellProps: () => ({
          style: { minWidth: '90px', padding: '0px  10px 0px 10px' },
        }),
      },
    },
    {
      name: 'event_name',
      label: 'Event Name',

      options: {
        sort: true,
        filter: false,
        viewColumns: true,
        setCellProps: () => ({
          style: { minWidth: '210px', maxWidth: '210px' },
        }),
      },
    },
    {
      name: 'execution_status',
      label: 'Event Status',

      options: {
        sort: true,
        filter: false,
        viewColumns: true,
        setCellProps: () => ({
          style: { minWidth: '80px', maxWidth: '80px' },
        }),
      },
    },
    {
      name: 'execution_response',
      label: 'Event Response',

      options: {
        sort: true,
        filter: false,
        viewColumns: true,
        setCellProps: () => ({
          style: { width: '15%' },
        }),
      },
    },
    {
      name: 'retry_count',
      label: 'Retry Count',

      options: {
        sort: false,
        filter: false,
        viewColumns: true,
        setCellProps: () => ({
          style: {
            minWidth: '80px',
            maxWidth: '80px',
            padding: '0px  10px 0px 10px',
          },
        }),
      },
    },
    {
      name: 'created_at',
      label: 'Created At',

      options: {
        sort: true,
        filter: false,
        viewColumns: true,
        setCellProps: () => ({
          style: { minWidth: '120px', maxWidth: '120px' },
        }),
      },
    },
    {
      name: 'modified_at',
      label: 'Modified At',

      options: {
        sort: true,
        filter: false,
        viewColumns: true,
        setCellProps: () => ({
          style: { minWidth: '120px', maxWidth: '120px' },
        }),
      },
    },
  ];

  let intervalVal = '';
  const handleSearch = searchInputVal => {
    const searchString = searchInputVal?.trim();

    /** Timeout will help to let multiple characters in the TextInput,* and API call would be optimized to get Values for Input of few chars at once,* rather than for every single character*/
    try {
      clearTimeout(intervalVal);
    } catch (err) {
      console.log(err);
    }
    intervalVal = setTimeout(() => {
      setLoading(true);
      setSearchQuery(prevState => ({
        ...prevState,
        offset: 0,
        searchQuery: searchString,
      }));
      setPullOrderRullType(searchString);
    }, 500);
  };

  let dataArray =
    logListData?.map(val => {
      const exec_response = JSON.parse(val?.execution_response);
      let execution_response = 'Invalid Response';
      if (exec_response?.data) {
        const data = JSON.parse(exec_response?.data);
        if (data?.status_info) {
          execution_response = data.status_info;
        }
      }
      return {
        pipeline_event_id: val.pipeline_event_id,
        client_id: val.client_id,
        project_id: val.project_id,
        project_number: val.project_number,
        po_number: val.po_number,
        topic_name: val.topic_name,
        event_name: val.event_name,
        payload_url: val.payload,
        execution_response,
        execution_status: val.execution_status,
        retry_count: val.retry_count,
        created_at: moment(val.created_at).format('MM-DD-YYYY hh:mm A'),
        modified_at: moment(val.modified_at).format('MM-DD-YYYY hh:mm A'),
      };
    }) || [];

  const viewPayloadLog = (action, index, rowData) => {
    setPayloadData(rowData);
    setDialogSettings(prevState => ({
      ...prevState,
      showButton2: action === 'view' ? false : true,
      button2Text: action === 'view' ? false : true,
      title: 'View Pipeline JSON Data',
    }));

    setIsOpen(true);
  };

  const onConfirmDialog = () => {
    setReloadForm(false);
    let pipelineIdAddObj = {
      pipeline_event_id: payloadData?.pipeline_event_id,
    };
    triggerPipelineLogs(
      pipelineIdAddObj,
      setLoading,
      setAlert,
      setReloadForm,
      setConfirmDialog
    );
  };
  const confirmTrigger = rowData => {
    setPayloadData(rowData);
    setConfirmDialog({
      ...confirmDialog,
      isOpen: true,
      title: `Are you sure you want to re-trigger this event?  This action will not be taken back.`,
      header: 'Re-trigger Event',
      button2Text: 'Yes',
      button1Text: 'No',
    });
  };
  const dataTableOptions = {
    download: false,
    print: false,
    filter: false,
    resizableColumns: false,
    selectableRows: 'none',
    responsive: 'standard',
    filterType: 'multiselect',
    tableBodyMinHeight: '300px',
    count: logListCount || 0,
    serverSide: true,
    searchText: pullOrderRuleType && pullOrderRuleType,
    textLabels: {
      body: {
        noMatch: !loading && !dataArray.length && 'No records found',
      },
    },
    onChangeRowsPerPage: numberOfRows => {
      setLoading(true);
      setSearchQuery(prevState => ({
        ...prevState,
        limit: numberOfRows,
        offset: 0,
      }));
    },
    onChangePage: currentPage => {
      setLoading(true);
      setSearchQuery(prevState => ({
        ...prevState,
        offset: currentPage * searchQuery.limit,
      }));
    },
    onColumnSortChange: (changedColumn, direction) => {
      setLoading(true);
      setSearchQuery(prevState => ({
        ...prevState,
        sortKey: changedColumn,
        sortDirection: direction.toUpperCase(),
      }));
    },
    searchProps: {
      onKeyUp: e => {
        if (e.target.defaultValue && e.keyCode === 13) {
          setLoading(true);
          setSearchQuery(prevState => ({
            ...prevState,
            searchQuery: e.target.defaultValue,
            offset: 0,
          }));
          handleSearch(e.target.defaultValue);
        }
      },
    },
    onSearchChange: searchText => {
      handleSearch(searchText);
    },
    onSearchClose: () => {
      handleSearch('');
      setPullOrderRullType('');
      setLoading(true);
      setSearchQuery(prevState => ({
        ...prevState,
        searchQuery: '',
      }));
    },
    rowsPerPage: searchQuery.limit,
    rowsPerPageOptions: [10, 20, 50, 100],
    setRowProps: (row, dataIndex, rowIndex) => {
      return {
        onMouseEnter: e => handleRowHover(e, row, rowIndex),
        onMouseLeave: e => handleRowHoverLeave(e, row, rowIndex),
      };
    },
    confirmFilters: true,
    pagination: !loading,
  };

  const jsonTreeTheme = {
    base00: '#272822',
    base01: '#383830',
    base02: '#49483e',
    base03: '#75715e',
    base04: '#a59f85',
    alignItems: 'left',
    nestedNodeLabel: ({ style }, keyPath, nodeType, expanded) => ({
      style: {
        textTransform: expanded ? 'uppercase' : style.textTransform,
        fontSize: '12px',
        fontWeight: expanded ? 'bold' : style.fontWeight,
      },
    }),
    nestedKeyLabel: {
      fontSize: '12px',
    },
    value: {
      fontSize: '12px',
    },
  };
  const getTriggerData = () => {
    return (
      <Grid container spacing={2} direction="column">
        <JSONTree data={payloadData?.payload_url} theme={jsonTreeTheme} />
      </Grid>
    );
  };

  const tableLoader = useMemo(() => {
    return TableColumnsLoader(dataTableColumns, {
      rows: searchQuery?.limit,
      isValue: false,
    });
  }, [dataTableColumns]);

  return (
    <>
      <Grid container spacing={2} direction="column">
        {alert.exists && (
          <Grid item>
            {' '}
            <Alert />
          </Grid>
        )}
        <Grid
          container
          item
          direction="row"
          justifyContent="space-between"
          spacing={2}
        >
          <Grid item>
            <PageHeader
              pageTitle="Event Logs"
              breadCrumbArray={clientsBreadcrumb}
            />
          </Grid>

          {!loading && (
            <Grid item justifyContent="flex-end">
              <Button
                color="primary"
                variant="contained"
                onClick={() =>
                  getLogsList(
                    searchQuery,
                    () => {},
                    setLoading,
                    setLogListData,
                    setLogListCount
                  )
                }
              >
                Refresh
              </Button>
            </Grid>
          )}
        </Grid>

        <Grid item classes={{ root: classes.dataTableWrapper }}>
          <GenericDataTable
            columns={dataTableColumns}
            data={loading ? tableLoader : dataArray}
            options={dataTableOptions}
          />
        </Grid>
        <GenericDialog
          fullwidth
          isOpen={isOpen}
          handleClose={() => {
            setIsOpen(false);
          }}
          dialogSettings={dialogSettings}
          disabledButton1={false}
        >
          <form>{getTriggerData()}</form>
        </GenericDialog>
        {/* Confirmation dialog for Triggering Logs */}
        <GenericConfirmationDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
          onConfirmDialog={onConfirmDialog}
        />
      </Grid>
    </>
  );
};

export default RuleEngineLogs;
