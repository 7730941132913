import { fetchWarehouseList } from '../../Locations/services/Warehouse.service';
import apiService from '../../../services/api.service';

import { setAllWarehouses } from './reducers/WarehouseSlice';

export const getAllWarehouses = dispatch => {
  fetchWarehouseList()
    .then(res => {
      dispatch(setAllWarehouses(res));
    })
    .catch(err => {
      console.error(err);
    });
};

export const updateExcludeCommission = async (item_id, payload) => {
  try {
    const response = await apiService.patch(
      `/system/items/${item_id}`,
      payload
    );
    if (response) {
      return response;
    }
  } catch (error) {
    console.error(error);
  }
};
