import React, { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Tag } from 'primereact/tag';
import { Skeleton } from 'primereact/skeleton';
import { Column } from 'primereact/column';

import {
  getTaskListAPI,
  getWidgetProjectTaskListAPI,
} from './TaskManagement.service';

const TaskList = ({
  setFormData,
  refereshList,
  typeOfProject,
  taskProjectId,
  tableName,
}) => {
  const [taskLists, setTaskLists] = useState([]);
  const [widgetTaskLists, setWidgetTaskLists] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: null,
    sortOrder: null,
  });
  const handleOnPage = event => {
    setlazyState(event);
  };

  const getWidgetTaskList = async () => {
    const response = await getWidgetProjectTaskListAPI(
      taskProjectId,
      setLoading,
      lazyState,
      tableName
    );
    if (response) {
      setWidgetTaskLists(response);
    }
  };
  useEffect(() => {
    if (tableName === 'Project' || tableName === 'Tasks') {
      getWidgetTaskList();
    }
  }, [refereshList, lazyState?.first, lazyState, tableName]);

  const getTaskList = async () => {
    const response = await getTaskListAPI(
      typeOfProject,
      taskProjectId,
      setLoading,
      lazyState
    );
    if (response) {
      setTaskLists(response);
    }
  };
  useEffect(() => {
    if (tableName !== 'Project' && tableName !== 'Tasks') {
      getTaskList();
    }
  }, [refereshList, lazyState?.first, lazyState, taskProjectId]);

  const getSeverity = severity => {
    switch (severity) {
      case 'High':
        return 'danger';

      case 'Medium':
        return 'warning';

      case 'Low':
        return 'info';

      case 'renewal':
        return null;
    }
  };

  const getStatus = status => {
    switch (status) {
      case 'Open':
        return 'info';
      case 'Completed':
        return 'success';

      case 'renewal':
        return null;
    }
  };

  const severityTemplate = rowData => {
    return (
      <Tag
        value={rowData?.severity}
        severity={getSeverity(rowData?.severity)}
      />
    );
  };

  const statusTemplate = rowData => {
    return (
      <Tag value={rowData?.status} severity={getStatus(rowData?.status)} />
    );
  };

  const fullName = rowData => {
    return rowData?.first_name && rowData?.last_name
      ? rowData.first_name + ' ' + rowData.last_name
      : '';
  };

  const copyTo = rowData => {
    if (
      (typeOfProject === 'projectType' || tableName === 'Project') &&
      tableName != 'Tasks'
    ) {
      return (
        <div style={{ maxHeight: '100px', overflowY: 'auto' }}>
          {(rowData?.project_task_users?.length &&
            rowData?.project_task_users
              ?.map(item =>
                item?.UserMetum?.first_name && item?.UserMetum?.last_name
                  ? item.UserMetum.first_name + ' ' + item.UserMetum.last_name
                  : ''
              )
              .join(', ')) ||
            ''}
        </div>
      );
    } else {
      return (
        <div style={{ maxHeight: '100px', overflowY: 'auto' }}>
          {(rowData?.task_users?.length &&
            rowData?.task_users
              ?.map(item =>
                item?.UserMetum?.first_name && item?.UserMetum?.last_name
                  ? item.UserMetum.first_name + ' ' + item.UserMetum.last_name
                  : ''
              )
              .join(', ')) ||
            ''}
        </div>
      );
    }
  };

  const items = Array.from({ length: 8 }, (v, i) => i);

  const bodyTemplate = () => {
    return <Skeleton></Skeleton>;
  };
  const dataList =
    tableName === 'Project' || tableName === 'Tasks'
      ? widgetTaskLists
      : taskLists;
  return (
    <>
      <div className="border-200 border-1 shadow-1 px-3 py-2 h-full">
        {loading ? (
          <>
            <DataTable value={items} className="p-datatable-striped">
              <Column
                field="Due Date"
                header="Due Date"
                style={{ width: '25%' }}
                body={bodyTemplate}
              ></Column>
              <Column
                field="Severity"
                header="Severity"
                style={{ width: '25%' }}
                body={bodyTemplate}
              ></Column>
              <Column
                field="Title"
                header="Title"
                style={{ width: '25%' }}
                body={bodyTemplate}
              ></Column>
              <Column
                field="Category"
                header="Category"
                style={{ width: '25%' }}
                body={bodyTemplate}
              ></Column>
            </DataTable>
          </>
        ) : (
          <div className="card h-full" style={{ overflow: 'auto' }}>
            <DataTable
              style={{ minWidth: '1000px' }}
              value={dataList?.data}
              paginator
              rowsPerPageOptions={[5, 10, 100]}
              currentPageReportTemplate="{first} to {last} of {totalRecords}"
              onRowClick={e => setFormData(e.data)}
              lazy
              page={lazyState?.page}
              first={lazyState?.first}
              rows={lazyState?.rows}
              totalRecords={dataList?.total_records}
              onPage={handleOnPage}
            >
              <Column
                className="text-sm px-2"
                field="due_date"
                header="Due Date"
                body={rowData => rowData?.due_date}
                style={{ width: '150px' }}
              ></Column>
              <Column
                className="text-sm px-2"
                field="severity"
                header="Severity"
                style={{ width: '150px' }}
                body={severityTemplate}
              ></Column>
              <Column
                className="text-sm px-2"
                field="title"
                header="Title"
                style={{ width: '400px' }}
              ></Column>
              <Column
                className="text-sm px-2"
                field="category"
                header="Category"
                style={{ width: '150px' }}
              ></Column>
              <Column
                className="text-sm px-2"
                field="status"
                header="Status"
                style={{ width: '150px' }}
                body={statusTemplate}
              ></Column>
              <Column
                className="text-sm px-2"
                field="first_name"
                header="Assigned To"
                style={{ width: '200px' }}
                body={fullName}
              ></Column>
              <Column
                className="text-sm px-2"
                field="follow_up_date"
                header="Follow Up Date"
                body={rowData => rowData?.follow_up_date}
                style={{ width: '200px' }}
              ></Column>
              <Column
                className="text-sm px-2"
                field="first_name"
                header="Copy To Users"
                body={copyTo}
                style={{ width: '200px' }}
              ></Column>
            </DataTable>
          </div>
        )}
      </div>
    </>
  );
};

export default TaskList;
