import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import React from 'react';

import { RenderColumnFieldsTypes } from '../types/render-column-fields.types';

const RenderColumnFields: React.FC<RenderColumnFieldsTypes> = ({
  type,
  options,
  annotation,
  masterData,
  updateDataArray,
  reportTablePrimaryKey,
}) => {
  const keySplit = options?.field ? options.field.split('.') : [];

  const key = options?.field ?? '';

  const editable =
    key !== undefined ? annotation?.[key]?.meta?.isEditable : undefined;

  const isColumnReference = key
    ? annotation[key]?.meta?.columnReference
    : undefined;

  const rowDataValue =
    keySplit?.length >= 2 &&
    options?.rowData &&
    typeof options?.rowData === 'object' &&
    options?.rowData?.[keySplit[0]]?.[keySplit[1]] !== undefined &&
    options?.rowData?.[keySplit[0]]?.[keySplit[1]] !== null
      ? type === 'number' &&
        !isNaN(Number(options?.rowData?.[keySplit[0]]?.[keySplit[1]]))
        ? Number(options?.rowData?.[keySplit[0]]?.[keySplit[1]])
        : options?.rowData?.[keySplit[0]]?.[keySplit[1]]
      : options?.value;

  const numericValue: number | null | undefined =
    type === 'number' ? Number(rowDataValue) : undefined;

  const stringValue: string | null | undefined =
    typeof rowDataValue === 'string' || rowDataValue === null
      ? rowDataValue
      : undefined;

  const itemId = options?.rowData[reportTablePrimaryKey];
  if (editable !== true) return options.value;
  switch (type) {
    case 'dropdown':
      return (
        <div className="flex">
          <div className="p-inputgroup flex-1">
            <Dropdown
              value={
                keySplit?.length >= 2 &&
                options?.rowData &&
                typeof options?.rowData === 'object' &&
                options?.rowData[keySplit[0]] &&
                typeof options.rowData[keySplit[0]] === 'object'
                  ? options?.rowData[keySplit[0]][keySplit[1]]
                  : options?.value
              }
              optionLabel="label"
              options={
                Array.isArray(options?.field && masterData?.[options?.field])
                  ? masterData?.[key]?.map(record => record)
                  : []
              }
              onChange={e => {
                options?.editorCallback?.(e?.value, options?.index);
                updateDataArray(
                  options?.rowData[keySplit[0]]
                    ? options?.rowData[keySplit[0]][keySplit[1]]
                    : options?.value,
                  isColumnReference,
                  itemId
                );
              }}
              placeholder="Select value..."
              className="p-column-filter"
            />
          </div>
        </div>
      );

    case 'number':
      return (
        <div className="p-inputgroup flex-1">
          <InputNumber
            value={numericValue}
            onValueChange={e => {
              options?.editorCallback?.(e?.target?.value);
            }}
            onBlur={e => {
              const inputValue = Number(e?.target?.value);

              if (inputValue > 0 && options?.value !== e?.target?.value) {
                updateDataArray(
                  options?.rowData[keySplit[0]]
                    ? options?.rowData[keySplit[0]][keySplit[1]]
                    : options?.value,
                  isColumnReference,
                  itemId
                );
              }
            }}
            maxFractionDigits={5}
            placeholder="Enter number..."
            onKeyDown={e => e?.stopPropagation()}
          />
        </div>
      );

    default:
      return (
        <div className="p-inputgroup flex-1">
          <InputText
            type="text"
            value={stringValue}
            onChange={e => {
              options?.editorCallback?.(e?.target?.value);
            }}
            placeholder="Enter value..."
            onKeyDown={e => e?.stopPropagation()}
            onBlur={e => {
              if (
                e?.target?.value?.length > 0 &&
                options?.value !== e?.target?.value
              ) {
                updateDataArray(
                  options?.rowData[keySplit[0]]
                    ? options?.rowData[keySplit[0]][keySplit[1]]
                    : options?.value,
                  isColumnReference,
                  itemId
                );
              }
            }}
          />
        </div>
      );
  }
};

export default RenderColumnFields;
