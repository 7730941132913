import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { Skeleton } from 'primereact/skeleton';

import { DISTANCE_UNIT_OPTIONS } from '../../../constants';
import PFButton from '../../shared/PFPrime/PFButton';
import PFDropdown from '../../shared/PFPrime/PFDropdown';
import PFInputText from '../../shared/PFPrime/PFInputText';

import {
  editCapacity,
  getCapacity,
} from './services/user-form-job-assign-coverage.service';
import { maxProjects } from './Helper';

const UserJobAssignCapacity = () => {
  const [unit, setUnit] = useState('');
  const [projects, setProjects] = useState(null);
  const [travelDistance, setTravelDistance] = useState(null);
  const [refreshCoverageData, setRefreshCoverageData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const { user } = useParams();
  const toast = useRef(null);
  const validateForm = () => {
    const newErrors = {};
    if (!projects) newErrors.projects = 'Max Projects is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const fetchCapacity = async () => {
    setIsLoading(true);
    try {
      const getResponse = await getCapacity(user);
      setProjects(
        maxProjects.find(item => item.id === getResponse.max_no_projects)
      );
      setTravelDistance(getResponse.max_travel_distance);
      setUnit(
        DISTANCE_UNIT_OPTIONS.find(item => item.id === getResponse.coverage_by)
      );
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchCapacity();
  }, [user, refreshCoverageData]);

  const handleSave = async () => {
    if (!validateForm()) return;
    const payload = {
      max_projects: projects?.id,
      max_travel_distance: travelDistance,
      distance_unit: unit?.id,
    };
    try {
      const capacityResponse = await editCapacity(user, payload);
      if (capacityResponse) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'The Capacity has been successfully Saved.',
          life: 3000,
        });
        setRefreshCoverageData(!refreshCoverageData);
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: 'An error occurred while saving the record.',
          life: 3000,
        });
      }
    } catch (error) {
      console.error(error);
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'An error occurred while saving the record.',
        life: 3000,
      });
    }
  };

  return (
    <>
      <Toast ref={toast} />
      {isLoading ? (
        <div className="flex justify-content-between">
          <div className="flex">
            <Skeleton width="10rem" height="3rem" className="m-2"></Skeleton>
            <Skeleton width="10rem" height="3rem" className="m-2"></Skeleton>
            <Skeleton width="12rem" height="3rem" className="m-2"></Skeleton>
          </div>
          <div>
            <Skeleton width="10rem" height="3rem" className="m-2"></Skeleton>
          </div>
        </div>
      ) : (
        <>
          <div className="flex flex-row justify-content-between align-items-center mb-3">
            <div className="grid w-full mt-3">
              <div className="col-12 md:col-4 lg:col-3 mb-3 py-0">
                <span className="p-float-label">
                  <PFDropdown
                    value={projects}
                    key="coverageTypeList"
                    name="max_project"
                    options={maxProjects}
                    optionLabel="label"
                    onChange={(name, event) => {
                      setProjects(event);
                      setErrors(prevErrors => ({
                        ...prevErrors,
                        projects: null,
                      }));
                    }}
                    className="mr-3 w-full"
                    style={{ height: '40px' }}
                  />
                  <label htmlFor="max_project">Max Projects in a Day</label>
                </span>
                {errors.projects && (
                  <small className="p-error">{errors.projects}</small>
                )}
              </div>
              <div className="col-12 md:col-4 lg:col-3  py-0">
                <span className="p-float-label">
                  <PFInputText
                    name="max_travel_distance"
                    value={travelDistance}
                    onChange={event => {
                      const value = event.target.value;
                      if (value === '' || /^\d+(\.\d{0,2})?$/.test(value)) {
                        setTravelDistance(value);
                      }
                    }}
                  />
                  <label htmlFor="max_travel_distance">
                    Max Travel Distance
                  </label>
                </span>
                {errors.travelDistance && (
                  <small className="p-error">{errors.travelDistance}</small>
                )}
              </div>
              <div className="col-12 md:col-4 lg:col-3  py-0">
                <span className="p-float-label">
                  <PFDropdown
                    key="distance_unit"
                    className="mr-3 w-full"
                    value={unit}
                    name="distance_unit"
                    options={DISTANCE_UNIT_OPTIONS}
                    optionLabel="label"
                    style={{ height: '40px' }}
                    onChange={(name, event) => {
                      setUnit(event);
                      setErrors(prevErrors => ({ ...prevErrors, unit: null }));
                    }}
                  />
                  <label htmlFor="distance_unit">Unit</label>
                </span>
                {errors.unit && (
                  <small className="p-error">{errors.unit}</small>
                )}
              </div>
            </div>
            <div>
              <PFButton
                label="Save"
                severity="primary"
                size="small"
                onClick={handleSave}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default UserJobAssignCapacity;
