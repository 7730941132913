import React from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { Card } from 'primereact/card';

import { EmailTemplates } from '../../../EmailTemplates/EmailTemplatesContainer';
import { AdditionalFeaturesProps } from '../AdditionalFeatures/AdditionalFeatures.types';
import TemplateListing from '../../QuoteTemplate/Components/TemplateListing';
import { commonPt } from '../company-config.constant';

const Templates: React.FC<AdditionalFeaturesProps> = ({
  client_id,
  client,
}) => {
  return (
    <div className="card bg-white">
      <TabView
        pt={{
          root: {
            className: 'flex-wrap lg:flex',
          },
          navContainer: {
            className: 'w-12 lg:w-3',
          },
          panelContainer: {
            className: 'w-12 lg:w-9',
          },

          nav: {
            className: 'flex-column',
          },
        }}
        activeIndex={parseInt(client?.personalized_emails) ? 0 : 1}
      >
        {parseInt(client?.personalized_emails) && (
          <TabPanel
            header="Personalised Emails"
            pt={{
              ...commonPt,
            }}
          >
            <Card>
              <EmailTemplates />
            </Card>
          </TabPanel>
        )}

        <TabPanel
          header="Quotes"
          pt={{
            ...commonPt,
          }}
        >
          <Card className="p-0">
            <TemplateListing
              client_id={client_id ? client_id : client?.client_id}
            />
          </Card>
        </TabPanel>
      </TabView>
    </div>
  );
};

export default Templates;
