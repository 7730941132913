export const truncateDescriptionText = (
  text: string,
  isLeftMenuOpen: boolean,
  maxWidth: number = 62
): string => {
  if (!text) return '';
  const screenWidth = window.innerWidth;
  const maxLength =
    screenWidth < 580
      ? isLeftMenuOpen
        ? 30
        : 42
      : screenWidth < 768
        ? isLeftMenuOpen
          ? 42
          : 60
        : screenWidth < 1024
          ? 30
          : screenWidth < 1280
            ? isLeftMenuOpen
              ? 40
              : 50
            : isLeftMenuOpen
              ? maxWidth
              : 75;

  return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
};
