import { formateNumber } from './Quotes';
export const itemAmountcalculation = (
  values,
  setCalculatedMargin,
  setValues,
  retailerPercentage
) => {
  const quantity = formateNumber(values?.quantity || 0);
  const unitPrice = formateNumber(values?.unitPrice || 0);
  const totalCost = formateNumber((quantity || 0) * (unitPrice || 0));
  const retailEach = formateNumber(values?.retailEach || 0);
  const totalRetail = formateNumber((quantity || 0) * (retailEach || 0));
  const discountType = values?.discountType || 'Percentage';
  const discount = formateNumber(values?.discount || 0);
  let totalDiscount = formateNumber(discount || 0);
  if (discountType === 'Percentage') {
    totalDiscount = formateNumber((totalRetail || 0) * ((discount || 0) / 100));
  }
  const tax = formateNumber(values?.tax || 0);
  const taxAmount = formateNumber(
    ((totalRetail || 0) - (totalDiscount || 0)) * ((tax || 0) / 100)
  );
  const totalPrice = formateNumber(
    (totalRetail || 0) - (totalDiscount || 0) + (taxAmount || 0)
  );

  const retailerRate = formateNumber(retailerPercentage || 0);
  let totalRevenue =
    values.revenueFromAPI !== 0
      ? formateNumber(values.revenueFromAPI * quantity)
      : formateNumber(totalPrice || 0);
  if (retailerRate > 0) {
    totalRevenue =
      values.revenueFromAPI !== 0
        ? formateNumber(values.revenueFromAPI * quantity)
        : formateNumber(((totalPrice || 0) * (retailerRate || 0)) / 100);
  }
  const marginCal =
    (((totalRevenue || 0) - (totalCost || 0)) /
      (totalRevenue > 0 ? totalRevenue : 1)) *
    100;

  const margin = formateNumber(marginCal > 0 ? marginCal : 0);

  setCalculatedMargin(margin);
  setValues(pre => ({
    ...pre,
    totalCost: totalCost,
    totalRetail: totalRetail,
    totalDiscount: totalDiscount,
    taxAmount: taxAmount,
    totalPrice: totalPrice,
    margin: margin,
    totalRevenue: totalRevenue,
  }));
};

export const onQtyBlur = (value, values, setValues, retailerPercentage) => {
  const quantity = formateNumber(value || 0);
  let retailerRate = formateNumber(retailerPercentage || 0);
  retailerRate = retailerRate === 0 ? 100 : retailerRate;
  if (quantity !== values?.quantity) {
    const unitPrice = formateNumber(values?.unitPrice || 0);
    const totalCost = formateNumber(unitPrice * quantity);
    let retailEach = formateNumber(values?.retailEach || 0);

    const totalRetail = formateNumber(retailEach * quantity);
    const discountType = values?.discountType || 'Amount';
    const discount = formateNumber(values?.discount || 0);
    let totalDiscount = formateNumber(discount);
    if (discountType === 'Percentage') {
      totalDiscount = formateNumber(totalRetail * (discount / 100));
    }
    const tax = formateNumber(values?.tax || 0);
    const taxAmount = formateNumber(totalRetail * (tax / 100));

    const totalRevenue =
      values.revenueFromAPI !== 0
        ? values.totalRevenue === 0
          ? formateNumber(values.revenueFromAPI * quantity)
          : formateNumber((values.totalRevenue / values.quantity) * quantity)
        : formateNumber(totalRetail * (retailerRate / 100));

    const totalPrice = formateNumber(totalRetail - totalDiscount + taxAmount);

    const profit = formateNumber(totalRevenue - totalCost);

    let margin =
      values.revenueFromAPI !== 0
        ? values.totalRevenue === 0
          ? formateNumber((profit / totalRevenue) * 100)
          : formateNumber(values?.margin)
        : formateNumber((profit / totalRevenue) * 100);

    if (profit < 0) {
      margin = -margin;
    }
    setValues(pre => ({
      ...pre,
      quantity,
      unitPrice,
      totalCost,
      retailEach,
      totalRetail,
      discountType,
      discount,
      totalDiscount,
      tax,
      taxAmount,
      totalRevenue,
      totalPrice,
      margin: margin !== Infinity ? margin : 0,
    }));
  }
};

export const onBlurUnitPrice = (value, values, setValues) => {
  const unitPrice = formateNumber(value || 0);

  if (unitPrice !== values?.unitPrice) {
    const quantity = formateNumber(values?.quantity || 0);
    const unitPrice = formateNumber(value || 0);
    const totalCost = formateNumber(unitPrice * quantity);
    const eachRevenue = formateNumber(values.totalRevenue / quantity);
    const revenue = formateNumber(eachRevenue - unitPrice / eachRevenue);
    const revenueApi = formateNumber(
      values.revenueFromAPI - unitPrice / values.revenueFromAPI
    );
    const totalRevenue =
      values.revenueFromAPI !== 0
        ? values.totalRevenue === 0
          ? formateNumber(revenueApi * quantity)
          : formateNumber(revenue * quantity)
        : formateNumber(values?.totalRevenue || 0);

    const profit = formateNumber(totalRevenue - totalCost);

    let margin = formateNumber((profit / totalRevenue) * 100);

    if (profit < 0) {
      margin = -margin;
    }
    let retailEach = formateNumber(values?.retailEach || 0);

    const totalRetail = formateNumber(values?.totalRetail || 0);
    const discountType = values?.discountType || 'Amount';
    const discount = formateNumber(values?.discount || 0);
    const totalDiscount = formateNumber(values?.totalDiscount || 0);

    const tax = formateNumber(values?.tax || 0);
    const taxAmount = formateNumber(values?.taxAmount || 0);

    const totalPrice = formateNumber(totalRetail - totalDiscount + taxAmount);

    setValues(pre => ({
      ...pre,
      quantity,
      unitPrice,
      totalCost,
      retailEach,
      totalRetail,
      discountType,
      discount,
      totalDiscount,
      tax,
      taxAmount,
      totalRevenue,
      totalPrice,
      margin: margin !== Infinity ? margin : 0,
    }));
  }
};

export const onBlurRetailEach = (
  value,
  values,
  setValues,
  retailerPercentage
) => {
  const retailEach = formateNumber(value || 0);
  let retailerRate = formateNumber(retailerPercentage || 0);
  retailerRate = retailerRate === 0 ? 100 : retailerRate;
  if (retailEach !== values?.retailEach) {
    const quantity = formateNumber(values?.quantity || 0);
    const unitPrice = formateNumber(values?.unitPrice || 0);
    const totalCost = formateNumber(values?.totalCost || 0);

    const totalRetail = formateNumber(retailEach * quantity);

    const discountType = values?.discountType || 'Amount';
    const discount = formateNumber(values?.discount || 0);
    let totalDiscount = formateNumber(discount || 0);
    if (discountType === 'Percentage') {
      totalDiscount = formateNumber(
        (totalRetail || 0) * ((discount || 0) / 100)
      );
    }

    const tax = formateNumber(values?.tax || 0);
    const taxAmount = formateNumber((totalRetail || 0) * ((tax || 0) / 100));

    const totalRevenue =
      values.revenueFromAPI !== 0
        ? values.totalRevenue === 0
          ? formateNumber(values.revenueFromAPI * quantity)
          : formateNumber((values.totalRevenue / quantity) * quantity)
        : formateNumber(totalRetail * (retailerRate / 100));
    const totalPrice = formateNumber(totalRetail - totalDiscount + taxAmount);

    const profit = formateNumber(totalRevenue - totalCost);

    let margin = formateNumber((profit / totalRevenue) * 100);

    if (profit < 0) {
      margin = -margin;
    }
    setValues(pre => ({
      ...pre,
      quantity,
      unitPrice,
      totalCost,
      retailEach,
      totalRetail,
      discountType,
      discount,
      totalDiscount,
      tax,
      taxAmount,
      totalRevenue,
      totalPrice,
      margin: margin !== Infinity ? margin : 0,
    }));
  }
};

export const onBlurMargin = (value, values, setValues, retailerPercentage) => {
  const margin = formateNumber(value || 0);
  let retailerRate = formateNumber(retailerPercentage || 0);
  retailerRate = retailerRate === 0 ? 100 : retailerRate;
  if (margin !== values?.margin) {
    const quantity = formateNumber(values?.quantity || 0);
    const unitPrice = formateNumber(values?.unitPrice || 0);
    const totalCost = formateNumber(unitPrice * quantity);
    let retailEach = formateNumber(0);
    let totalRetail = formateNumber(0);
    const discountType = values?.discountType || 'Amount';
    const discount = formateNumber(values?.discount || 0);
    let totalDiscount = formateNumber(discount || 0);
    const tax = formateNumber(values?.tax || 0);
    let taxAmount = formateNumber(0);
    let totalRevenue = 0;
    if (margin !== 100) {
      totalRevenue = formateNumber(totalCost / (1 - margin / 100));
    } else {
      totalRevenue = formateNumber(totalCost);
    }

    totalRetail =
      values.revenueFromAPI !== 0
        ? formateNumber(values.totalRetail)
        : formateNumber(totalRevenue / (retailerRate / 100));
    retailEach = formateNumber(totalRetail / quantity);
    let totalPrice = formateNumber(0);
    if (discountType === 'Percentage') {
      totalDiscount = formateNumber(totalRetail * (discount / 100));
    }

    taxAmount = formateNumber(totalRetail * (tax / 100));

    totalPrice = formateNumber(totalRetail - totalDiscount + taxAmount);

    retailEach = formateNumber(totalRetail / quantity);
    taxAmount = formateNumber(totalRetail * (tax / 100));
    totalPrice =
      values.revenueFromAPI !== 0
        ? formateNumber(values.totalPrice)
        : formateNumber(totalRevenue / (retailerRate / 100) + taxAmount);

    setValues(pre => ({
      ...pre,
      quantity,
      unitPrice,
      totalCost,
      retailEach,
      totalRetail,
      discountType,
      discount,
      totalDiscount,
      tax,
      taxAmount,
      totalRevenue,
      totalPrice,
      margin,
    }));
  }
};

export const onBlurDiscountType = (value, values, setValues) => {
  const discountType = value || 'Amount';

  if (discountType !== values?.discountType) {
    const quantity = formateNumber(values?.quantity || 0);
    const unitPrice = formateNumber(values?.unitPrice || 0);
    const totalCost = formateNumber(unitPrice * quantity);
    let retailEach = formateNumber(values?.retailEach || 0);
    let discount = formateNumber(values?.discount || 0);

    const totalRetail = formateNumber(values?.totalRetail || 0);

    let totalDiscount = formateNumber(discount || 0);
    if (discountType === 'Percentage') {
      totalDiscount = formateNumber(totalRetail * (discount / 100));
    }
    const tax = formateNumber(values?.tax || 0);
    const taxAmount = formateNumber(totalRetail * (tax / 100));

    const totalRevenue =
      values.revenueFromAPI !== 0
        ? values.totalRevenue === 0
          ? formateNumber(values.revenueFromAPI * quantity)
          : formateNumber(values.totalRevenue || 0)
        : formateNumber(values?.totalRevenue || 0);

    const totalPrice = formateNumber(totalRetail - totalDiscount + taxAmount);
    const margin = formateNumber(values?.margin || 0);

    setValues(pre => ({
      ...pre,
      quantity,
      unitPrice,
      totalCost,
      retailEach,
      totalRetail,
      discountType,
      discount,
      totalDiscount,
      tax,
      taxAmount,
      totalRevenue,
      totalPrice,
      margin,
    }));
  }
};

export const onBlurDiscount = (value, values, setValues) => {
  const discount = formateNumber(value || 0);

  if (discount !== values?.discount) {
    const quantity = formateNumber(values?.quantity || 0);
    const unitPrice = formateNumber(values?.unitPrice || 0);
    const totalCost = formateNumber(unitPrice * quantity);
    let retailEach = formateNumber(values?.retailEach || 0);

    const totalRetail = formateNumber(retailEach * quantity);
    const discountType = values?.discountType || 'Amount';

    let totalDiscount = formateNumber(discount || 0);
    if (discountType === 'Percentage') {
      totalDiscount = formateNumber(totalRetail * (discount / 100));
    }
    const tax = formateNumber(values?.tax || 0);
    const taxAmount = formateNumber(totalRetail * (tax / 100));
    const totalRevenue =
      values.revenueFromAPI !== 0
        ? values.totalRevenue === 0
          ? formateNumber(values.revenueFromAPI * quantity)
          : formateNumber(values.totalRevenue || 0)
        : formateNumber(values?.totalRevenue || 0);

    const totalPrice = formateNumber(totalRetail - totalDiscount + taxAmount);
    const margin = formateNumber(values?.margin || 0);

    setValues(pre => ({
      ...pre,
      quantity,
      unitPrice,
      totalCost,
      retailEach,
      totalRetail,
      discountType,
      discount,
      totalDiscount,
      tax,
      taxAmount,
      totalRevenue,
      totalPrice,
      margin,
    }));
  }
};

export const onBlurTax = (value, values, setValues) => {
  const tax = formateNumber(value || 0);

  if (tax !== values?.tax) {
    const quantity = formateNumber(values?.quantity || 0);
    const unitPrice = formateNumber(values?.unitPrice || 0);
    const totalCost = formateNumber(values?.totalCost || 0);
    let retailEach = formateNumber(values?.retailEach || 0);

    const totalRetail = formateNumber(values?.totalRetail || 0);
    const discountType = values?.discountType || 'Amount';
    const discount = formateNumber(values?.discount || 0);
    const totalDiscount = formateNumber(values?.totalDiscount || 0);

    const taxAmount = formateNumber(totalRetail * (tax / 100));
    const margin = formateNumber(values?.margin);
    const totalRevenue =
      values.revenueFromAPI !== 0
        ? values.totalRevenue === 0
          ? formateNumber(values.revenueFromAPI * quantity)
          : formateNumber(values.totalRevenue || 0)
        : formateNumber(values?.totalRevenue || 0);
    const totalPrice = formateNumber(totalRetail - totalDiscount + taxAmount);

    setValues(pre => ({
      ...pre,
      quantity,
      unitPrice,
      totalCost,
      retailEach,
      totalRetail,
      discountType,
      discount,
      totalDiscount,
      tax,
      taxAmount,
      totalRevenue,
      totalPrice,
      margin,
    }));
  }
};
