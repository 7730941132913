import React, { useRef } from 'react';
import { Skeleton } from 'primereact/skeleton';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

import { CURRENCY } from '../../../constants/currency.constant';
import { renderDate } from '../../../utils/dates.helper';
import { NOT_APPLICABLE } from '../../../constants/string.constant';

import { fetchBillingData, getQuickBooksData } from './service/billing.service';
import { BillingResponse } from './Interface/billing.interface';
import PaymentRequestsTable from './components/PaymentRequestsTable';

const BillingContainer = ({ project_id }: { project_id: number }) => {
  const [billingData, setBillingData] = React.useState<BillingResponse | null>(
    null
  );
  const [loading, setLoading] = React.useState(true);

  const toast = useRef<Toast>(null);

  const loadBillingData = async () => {
    setLoading(true);
    const data = await fetchBillingData(project_id);
    if (!data?.error) {
      setBillingData(data?.billingData);
    } else {
      toast.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: data?.message,
        life: 3000,
      });
    }
    setLoading(false);
  };

  React.useEffect(() => {
    loadBillingData();
  }, [project_id]);

  const fetchQuickBooks = async () => {
    setLoading(true);
    const data = await getQuickBooksData(project_id);
    if (!data.error) {
      toast.current?.show({
        severity: 'success',
        summary: 'Success',
        detail: data.message,
        life: 3000,
      });
      loadBillingData();
    } else {
      toast.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: data?.message,
        life: 3000,
      });
    }
    setLoading(false);
  };

  return (
    <>
      <Toast ref={toast} />

      <div className="grid grid-nogutter text-xs">
        <div className="flex col-12 justify-content-end">
          <Button
            label="Generate Payment"
            size="small"
            type="button"
            onClick={fetchQuickBooks}
            disabled={
              billingData?.quickbook?.quickbooks_payment_transaction_id != null
            }
          />
        </div>
        <div className="sm:col-12 md:col-4 lg:col-3 xl:col-3 p-2">
          <span className="p-float-label">
            {loading ? (
              <Skeleton className="w-full" height="3rem" />
            ) : (
              <>
                <span className="font-bold text-600">
                  Check Date:&nbsp;&nbsp;
                </span>
                <span>
                  {billingData?.items?.[0]?.check_number
                    ? renderDate(
                        billingData?.items?.[0]?.check_date,
                        'MM-dd-yyyy'
                      )
                    : NOT_APPLICABLE}
                </span>
              </>
            )}
          </span>
        </div>
        <div className="sm:col-12 md:col-4 lg:col-3 xl:col-3 p-2">
          <span className="p-float-label">
            {loading ? (
              <Skeleton className="w-full" height="3rem" />
            ) : (
              <>
                <span className="font-bold text-600">
                  Check Number:&nbsp;&nbsp;
                </span>
                <span>
                  {billingData?.items?.[0]?.check_number || NOT_APPLICABLE}
                </span>
              </>
            )}
          </span>
        </div>
        <div className="sm:col-12 md:col-4 lg:col-3 xl:col-3 p-2">
          <span className="p-float-label">
            {loading ? (
              <Skeleton className="w-full" height="3rem" />
            ) : (
              <>
                <span className="font-bold text-600">
                  Check Amount:&nbsp;&nbsp;
                </span>
                <span>
                  {billingData?.items?.[0]?.check_number
                    ? billingData?.items?.[0]?.check_amount
                      ? CURRENCY.DOLLAR +
                        Number(billingData?.items?.[0]?.check_amount)?.toFixed(
                          2
                        )
                      : NOT_APPLICABLE
                    : NOT_APPLICABLE}
                </span>
              </>
            )}
          </span>
        </div>
        <div className="sm:col-12 md:col-4 lg:col-3 xl:col-3 p-2">
          <span className="p-float-label">
            {loading ? (
              <Skeleton className="w-full" height="3rem" />
            ) : (
              <>
                <span className="font-bold text-600">
                  Total Labor Amount:&nbsp;&nbsp;
                </span>
                <span>
                  {Array.isArray(billingData?.items) &&
                  billingData?.items.length &&
                  billingData?.items[0]?.project?.total_labor_amount
                    ? `${CURRENCY.DOLLAR}${billingData?.items[0]?.project?.total_labor_amount}`
                    : NOT_APPLICABLE}
                </span>
              </>
            )}
          </span>
        </div>
        <div className="sm:col-12 md:col-4 lg:col-3 xl:col-3 p-2">
          <span className="p-float-label">
            {loading ? (
              <Skeleton className="w-full" height="3rem" />
            ) : (
              <>
                <span className="font-bold text-600">
                  Bill Number:&nbsp;&nbsp;
                </span>
                <span>
                  {billingData?.quickbook?.bill_number || NOT_APPLICABLE}
                </span>
              </>
            )}
          </span>
        </div>
        <div className="sm:col-12 md:col-4 lg:col-3 xl:col-3 p-2">
          <span className="p-float-label">
            {loading ? (
              <Skeleton className="w-full" height="3rem" />
            ) : (
              <>
                <span className="font-bold text-600">
                  Bill Transaction ID:&nbsp;&nbsp;
                </span>
                <span>
                  {billingData?.quickbook?.bill_txn_id || NOT_APPLICABLE}
                </span>
              </>
            )}
          </span>
        </div>
        <div className="sm:col-12 md:col-4 lg:col-3 xl:col-3 p-2">
          <span className="p-float-label">
            {loading ? (
              <Skeleton className="w-full" height="3rem" />
            ) : (
              <>
                <span className="font-bold text-600">
                  Invoice Number:&nbsp;&nbsp;
                </span>
                <span>
                  {billingData?.quickbook?.quickbooks_invoice_number
                    ? `${billingData?.quickbook?.quickbooks_invoice_number}`
                    : NOT_APPLICABLE}
                </span>
              </>
            )}
          </span>
        </div>
        <div className="sm:col-12 md:col-4 lg:col-3 xl:col-3 p-2">
          <span className="p-float-label">
            {loading ? (
              <Skeleton className="w-full" height="3rem" />
            ) : (
              <>
                <span className="font-bold text-600">
                  Invoice Transaction ID:&nbsp;&nbsp;
                </span>
                <span>
                  {billingData?.quickbook?.quickbooks_invoice_transaction_id
                    ? `${billingData?.quickbook?.quickbooks_invoice_transaction_id}`
                    : NOT_APPLICABLE}
                </span>
              </>
            )}
          </span>
        </div>
        <div className="sm:col-12 md:col-4 lg:col-3 xl:col-3 p-2">
          <span className="p-float-label">
            {loading ? (
              <Skeleton className="w-full" height="3rem" />
            ) : (
              <>
                <span className="font-bold text-600">
                  Payment Transaction ID:&nbsp;&nbsp;
                </span>
                <span>
                  {billingData?.quickbook?.quickbooks_payment_transaction_id
                    ? `${billingData?.quickbook?.quickbooks_payment_transaction_id}`
                    : NOT_APPLICABLE}
                </span>
              </>
            )}
          </span>
        </div>

        <PaymentRequestsTable
          paymentRequestsDisplayList={billingData?.items}
          loading={loading}
          project_id={project_id}
          loadBillingData={loadBillingData}
          toast={toast}
        />
      </div>
    </>
  );
};

export default BillingContainer;
