import React, { useRef, useEffect, useState } from 'react';
import axios from 'axios';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { MultiSelect } from 'primereact/multiselect';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';

import PageHeader from '../../shared/PageHeader/PageHeader';
import ApiService from '../../../services/api.service';
import { URL_CONSTANTS } from '../../../constants/urlConstants';
interface SyncItem {
  label: string;
  payload: {
    action: string;
  };
  confirmationMessage: string;
}

const syncList: SyncItem[] = [
  {
    label: 'Sync Type',
    payload: { action: 'project-types:sync' },
    confirmationMessage: 'Are you sure you want to sync type in Redis?',
  },
  {
    label: 'Sync Category',
    payload: { action: 'project-categories:sync' },
    confirmationMessage: 'Are you sure you want to sync category in Redis?',
  },
  {
    label: 'Sync Issue Category',
    payload: { action: 'issue-categories:sync' },
    confirmationMessage:
      'Are you sure you want to issue category type in Redis?',
  },
  {
    label: 'Sync Statuses',
    payload: { action: 'status:sync' },
    confirmationMessage: 'Are you sure you want to sync statuses in Redis?',
  },
  {
    label: 'Sync Store',
    payload: { action: 'store:sync' },
    confirmationMessage: 'Are you sure you want to sync store in Redis?',
  },
  {
    label: 'Sync Installer Work Type',
    payload: { action: 'installerWorkType:sync' },
    confirmationMessage:
      'Are you sure you want to sync installer work type in Redis?',
  },
  {
    label: 'Sync User Type',
    payload: { action: 'userType:sync' },
    confirmationMessage: 'Are you sure you want to sync user type in Redis?',
  },
  {
    label: 'Sync District',
    payload: { action: 'district:sync' },
    confirmationMessage: 'Are you sure you want to sync district in Redis?',
  },
  {
    label: 'Sync Workroom',
    payload: { action: 'workroom:sync' },
    confirmationMessage: 'Are you sure you want to sync workroom in Redis?',
  },
  {
    label: 'Sync Installers',
    payload: { action: 'installerGlobalConfig:sync' },
    confirmationMessage: 'Are you sure you want to sync installers in Redis?',
  },
  {
    label: 'Sync Tenant details',
    payload: { action: 'clientDetails:sync' },
    confirmationMessage:
      'Are you sure you want to sync tenant details in Redis?',
  },
];
const userManagementBreadcrumb = [
  {
    text: 'Cache Sync',
  },
];
const RedisSync = () => {
  const toast = useRef<Toast>(null);
  const [clientList, setClientList] = useState([]);
  const [selectedClientList, setSelectedClientList] = useState([]);

  const getClientList = async () => {
    try {
      const response = await axios.get(
        `${URL_CONSTANTS.API.BULLETIN}/api/bulletin/client-list`
      );
      setClientList(response?.data);
    } catch (error) {
      console.log(error);
      setClientList([]);
    }
  };

  useEffect(() => {
    getClientList();
  }, []);

  const postSync = (record: SyncItem) => {
    try {
      ApiService.post('/master/redis-sync', {
        clients: selectedClientList,
        action: record.payload.action,
      });
      toast?.current?.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Sync has been triggered.',
      });
    } catch (error) {
      console.error('Error in sync : ', error);
      toast?.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Something went wrong.',
      });
    }
  };

  const confirm = (
    event: React.MouseEvent<HTMLButtonElement>,
    record: SyncItem
  ) => {
    confirmPopup({
      target: event.currentTarget,
      message: record.confirmationMessage,
      icon: 'pi pi-exclamation-triangle',
      defaultFocus: 'reject',
      accept: () => postSync(record),
      reject: () => {},
    });
  };

  return (
    <div className="w-full">
      <div className="flex flex-col md:flex-row justify-content-between mb-4">
        <div className="flex">
          <PageHeader
            pageTitle="Cache Sync"
            breadCrumbArray={userManagementBreadcrumb}
            icon={null}
          />
        </div>
      </div>
      <Toast ref={toast} />
      <ConfirmPopup />
      <div className="card mb-4">
        <MultiSelect
          value={selectedClientList}
          onChange={e => setSelectedClientList(e.value)}
          options={clientList}
          placeholder="Select Clients"
          maxSelectedLabels={3}
          className="w-full md:w-20rem"
          optionLabel="client_name"
          optionValue="client_id"
          filter
        />
      </div>

      {syncList.map(record => {
        return (
          <Button
            key={record.label}
            label={record.label}
            onClick={e => confirm(e, record)}
            className="m-2"
            disabled={selectedClientList.length ? false : true}
            tooltip={
              selectedClientList.length ? '' : 'Please select least one tenant'
            }
          />
        );
      })}
    </div>
  );
};
export default RedisSync;
