import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { FloatLabel } from 'primereact/floatlabel';

import { formatDateDefault, handleMasterSearch } from '../../../utils/Helpers';
import PFButton from '../../CRM/PFPrime/PFButton';
import { getDropdownDetails } from '../../ProjectManagement/Scheduling/Reports/Reports.service';
import { SCHEDULER_MAP_MESSAGE } from '../../ProjectManagement/message';
import PFMultiSelect from '../PFPrime/PFMultiSelect';
import PFCalendar from '../PFPrime/PFCalendar';
import PFDialog from '../PFPrime/PFDialog';

const SchedulerMapFilter = ({
  currDate,
  teamOptions,
  mapFiltersFormik,
  showFilters,
  setShowFilters,
  setSearchQuery,
  setMessageRouteDisplay,
}) => {
  const [installerList, setInstallerList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [storeList, setStoreList] = useState([]);
  const [projectStatusList, setProjectStatusList] = useState({});
  const [sourceStatusOptions, setSourceStatusOptions] = useState([]);

  const { techniciansLimitList } = useSelector(
    state => state.techniciansLimitList
  );
  const { types } = useSelector(state => state.types);
  const { projectStores } = useSelector(state => state.projectStores);
  const { categories } = useSelector(state => state.categories);
  const { projectStatusType } = useSelector(state => state.projectStatusType);
  const { sourceStatusType } = useSelector(state => state.sourceStatusType);

  const setMasterScheduler = () => {
    setInstallerList(techniciansLimitList?.slice(0, 10));
    setTypeList(types);
    setCategoryList(categories?.slice(0, 10));
    setStoreList(projectStores);
    setProjectStatusList(projectStatusType);
    setSourceStatusOptions(sourceStatusType);
  };

  useEffect(() => {
    if (showFilters) setMasterScheduler();
  }, [showFilters]);

  const handleCategorySearch = searchString => {
    const returnCategory = handleMasterSearch(
      searchString,
      categories,
      'category'
    );
    setCategoryList(returnCategory);
  };

  const handleInstallerSearch = searchString => {
    const returnInstaller = handleMasterSearch(
      searchString,
      techniciansLimitList,
      'full_name'
    );
    setInstallerList(returnInstaller);
  };

  const fetchStoreList = async storeSearchValue => {
    const result = await getDropdownDetails({
      fieldName: 'store',
      searchValue: storeSearchValue,
    });
    if (result) setStoreList(result);
  };
  const debouncedSearchStore = debounce(storeSearchValue => {
    fetchStoreList(storeSearchValue);
  }, 300);

  const handleApplyFilter = () => {
    setSearchQuery(prevState => ({
      ...prevState,
      projectCategoryIds: mapFiltersFormik?.values?.category
        .map(category => category?.project_category_id)
        .join(','),
      installerIds: mapFiltersFormik?.values?.installer
        .map(installer => installer?.installer_id)
        ?.join(','),
      projectTypeIds: mapFiltersFormik?.values?.type
        ?.map(type => type?.project_type_id)
        .join(','),
      storeIds: mapFiltersFormik?.values?.store_number
        ?.map(store => store?.store_id)
        .join(','),
      statusId: mapFiltersFormik?.values?.status
        ?.map(status => status?.status_id)
        .join(','),
      teams: mapFiltersFormik?.values?.team
        ?.map(team => team?.team_id)
        .join(','),
      startDate: mapFiltersFormik?.values?.start_date
        ? formatDateDefault(mapFiltersFormik?.values?.start_date)
        : currDate,
      endDate: mapFiltersFormik?.values?.end_date
        ? formatDateDefault(mapFiltersFormik?.values?.end_date)
        : currDate,
      sourceStatusIds: mapFiltersFormik?.values?.imsStatus
        ?.map(sourceStatus => sourceStatus?.status_id)
        .join(','),
    }));
  };
  return (
    <>
      <PFDialog
        header="Filters"
        show={showFilters}
        className="w-11 lg:w-5"
        hide={() => {
          setShowFilters(false);
        }}
        draggable={false}
        pt={{
          headertitle: {
            className: 'text-2xl ml-3',
          },
        }}
        BodyComponent={
          <>
            <div className="grid w-12 mx-0 pt-2">
              <div className="col-12 mt-1">
                <FloatLabel>
                  <PFMultiSelect
                    value={mapFiltersFormik?.values?.category || []}
                    onChange={e => {
                      const value = e?.value;
                      mapFiltersFormik.setFieldValue('category', value);
                    }}
                    options={categoryList ?? []}
                    optionLabel="category"
                    maxSelectedLabels={2}
                    filter
                    filterPlaceholder="Type to search..."
                    showSelectAll={false}
                    onFilter={e => {
                      handleCategorySearch(e?.filter);
                    }}
                    pt={{
                      root: {
                        className: 'w-full',
                      },
                    }}
                  />
                  <label htmlFor="technician">Category</label>
                </FloatLabel>
              </div>
              <div className="col-12 mt-1">
                <FloatLabel>
                  <PFMultiSelect
                    value={mapFiltersFormik?.values?.installer || []}
                    onChange={e => {
                      const value = e?.value;
                      mapFiltersFormik.setFieldValue('installer', value);
                    }}
                    options={installerList ?? []}
                    optionLabel="full_name"
                    maxSelectedLabels={2}
                    filter
                    filterPlaceholder="Type to search..."
                    showSelectAll={false}
                    onFilter={e => {
                      handleInstallerSearch(e?.filter);
                    }}
                    pt={{
                      root: {
                        className: 'w-full',
                      },
                    }}
                  />
                  <label htmlFor="technician">Technician</label>
                </FloatLabel>
              </div>
              <div className="col-12 mt-1">
                <FloatLabel>
                  <PFMultiSelect
                    value={mapFiltersFormik?.values?.type || []}
                    onChange={e => {
                      const value = e?.value;
                      mapFiltersFormik.setFieldValue('type', value);
                    }}
                    options={typeList ?? []}
                    optionLabel="project_type"
                    maxSelectedLabels={2}
                    filter
                    filterPlaceholder="Type to search..."
                    showSelectAll={false}
                    pt={{
                      root: {
                        className: 'w-full',
                      },
                    }}
                  />
                  <label htmlFor="type">Type</label>
                </FloatLabel>
              </div>
              <div className="col-12 mt-1">
                <FloatLabel>
                  <PFMultiSelect
                    value={mapFiltersFormik?.values?.store_number || []}
                    onChange={e => {
                      const value = e?.value;
                      mapFiltersFormik.setFieldValue('store_number', value);
                    }}
                    options={storeList ?? []}
                    optionLabel={option =>
                      option.store_name
                        ? `${option.store_number} - ${option.store_name}`
                        : ''
                    }
                    maxSelectedLabels={1}
                    filter
                    filterPlaceholder="Type to search..."
                    showSelectAll={false}
                    filterBy="store_name,store_number"
                    onFilter={e => {
                      debouncedSearchStore(e?.filter);
                    }}
                    pt={{
                      root: {
                        className: 'w-full',
                      },
                    }}
                  />
                  <label htmlFor="store">Store</label>
                </FloatLabel>
              </div>
              <div className="col-12 mt-1">
                <FloatLabel>
                  <PFMultiSelect
                    value={mapFiltersFormik?.values?.team || []}
                    onChange={e => {
                      const value = e?.value;
                      mapFiltersFormik.setFieldValue('team', value);
                    }}
                    options={teamOptions ?? []}
                    optionLabel="team_name"
                    maxSelectedLabels={2}
                    filter
                    filterPlaceholder="Type to search..."
                    showSelectAll={false}
                    pt={{
                      root: {
                        className: 'w-full',
                      },
                    }}
                  />
                  <label htmlFor="team">Team</label>
                </FloatLabel>
              </div>
              <div className="col-12 mt-1">
                <FloatLabel>
                  <PFCalendar
                    id="start_date"
                    name="start_date"
                    dateFormat="mm/dd/yy"
                    value={mapFiltersFormik?.values?.start_date || null}
                    onChange={e => {
                      mapFiltersFormik?.setFieldValue('start_date', e.value);
                      mapFiltersFormik?.setFieldValue('end_date', e.value);
                    }}
                    className="PFCalendar-lg"
                    appendTo="self"
                  />
                  <label htmlFor="start_date">Start Date</label>
                </FloatLabel>
              </div>
              <div className="col-12 mt-1">
                <FloatLabel>
                  <PFCalendar
                    id="end_date"
                    name="end_date"
                    dateFormat="mm/dd/yy"
                    value={mapFiltersFormik?.values?.end_date || null}
                    onChange={e =>
                      mapFiltersFormik?.setFieldValue('end_date', e.value)
                    }
                    className="PFCalendar-lg"
                    appendTo="self"
                  />
                  <label htmlFor="end_date">End Date</label>
                </FloatLabel>
              </div>
              <div className="col-12 mt-1">
                <FloatLabel>
                  <PFMultiSelect
                    value={mapFiltersFormik?.values?.status || []}
                    onChange={e => {
                      const value = e?.value;
                      mapFiltersFormik.setFieldValue('status', value);
                    }}
                    options={projectStatusList ?? []}
                    optionLabel="status"
                    maxSelectedLabels={2}
                    filter
                    filterPlaceholder="Type to search..."
                    showSelectAll={false}
                    pt={{
                      root: {
                        className: 'w-full',
                      },
                    }}
                  />
                  <label htmlFor="status">Status</label>
                </FloatLabel>
              </div>

              <div className="col-12 mt-1">
                <FloatLabel>
                  <PFMultiSelect
                    value={mapFiltersFormik?.values?.imsStatus || []}
                    onChange={e => {
                      const value = e?.value;
                      mapFiltersFormik.setFieldValue('imsStatus', value);
                    }}
                    options={sourceStatusOptions ?? []}
                    optionLabel="status"
                    maxSelectedLabels={2}
                    filter
                    filterPlaceholder="Type to search..."
                    showSelectAll={false}
                    pt={{
                      root: {
                        className: 'w-full',
                      },
                    }}
                  />
                  <label htmlFor="imsStatus">Source Status</label>
                </FloatLabel>
              </div>

              <div className="col-12 text-right">
                <PFButton
                  label="Clear"
                  outlined
                  severity="primery"
                  onClick={() => {
                    setMessageRouteDisplay(SCHEDULER_MAP_MESSAGE?.DEFAULT);
                    mapFiltersFormik.setFieldValue('start_date', null);
                    mapFiltersFormik.setFieldValue('end_date', null);
                    mapFiltersFormik.handleSubmit();
                    setSearchQuery(prevState => {
                      const { assigned, unAssigned } = prevState;
                      return {
                        assigned,
                        unAssigned,
                        startDate: currDate,
                        endDate: currDate,
                      };
                    });
                  }}
                />
                <PFButton
                  label="Apply Filters"
                  severity="primery"
                  onClick={() => {
                    handleApplyFilter();
                    setShowFilters(false);
                  }}
                  className="ml-2"
                />
              </div>
            </div>
          </>
        }
      ></PFDialog>
    </>
  );
};
export default SchedulerMapFilter;
