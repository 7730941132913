import { makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles(() => ({
  paper: {
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    borderRadius: '0px',
    overflow: 'hidden',
    marginBottom: '10px',
  },
}));
