import React from 'react';
import { Grid, TextField, Select, MenuItem } from '@material-ui/core';
import { useLocation } from 'react-router-dom';

import {
  Colors,
  nonAvailableReason,
  notificationConfirmationValue,
  postRenovationCleaningMethod,
  StatusColors,
  workAreasContainedValue,
} from '../../../../constants';

const TestedLeadPositive = ({
  LrrpConfigurationFormik,
  setBtnDisables = () => {},
}) => {
  const { pathname } = useLocation();
  const isAdminConfigPath =
    /^\/admin\/(configuration|company-config)\/?.*$/.test(pathname);
  return (
    <>
      <Grid container item direction="row" spacing={2}>
        {isAdminConfigPath && (
          <>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                className="p-1"
                id="name"
                label="Test Kit Manufacturer"
                name="name"
                value={
                  LrrpConfigurationFormik.values?.tested_lead_positive
                    ?.test_kit_manufacturer
                }
                onChange={event => {
                  LrrpConfigurationFormik.setFieldValue(
                    'tested_lead_positive',
                    {
                      ...LrrpConfigurationFormik.values.tested_lead_positive,
                      test_kit_manufacturer: event.target.value,
                    }
                  );
                  setBtnDisables(false);
                }}
                required
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                id="name"
                label="Component Location Tested"
                name="name"
                className="p-1"
                value={
                  LrrpConfigurationFormik.values?.tested_lead_positive
                    ?.component_location_tested
                }
                onChange={event => {
                  LrrpConfigurationFormik.setFieldValue(
                    'tested_lead_positive',
                    {
                      ...LrrpConfigurationFormik.values.tested_lead_positive,
                      component_location_tested: event.target.value,
                    }
                  );
                  setBtnDisables(false);
                }}
                required
              />
            </Grid>
          </>
        )}

        <Grid item xs={12} md={6} lg={3}>
          <label htmlFor="notificationConfirmation">
            Notification Confirmation <span className="text-red-500">*</span>
          </label>
          <Select
            label="Notification Confirmation"
            value={
              LrrpConfigurationFormik.values?.tested_lead_positive
                ?.notification_confirmation
            }
            onChange={event => {
              LrrpConfigurationFormik.setFieldValue('tested_lead_positive', {
                ...LrrpConfigurationFormik.values.tested_lead_positive,
                notification_confirmation: event.target.value,
              });
              setBtnDisables(false);
            }}
          >
            {notificationConfirmationValue.map(notificationValue => (
              <MenuItem key={notificationValue} value={notificationValue}>
                {notificationValue}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <label htmlFor="isCommonAreaInvolved">
            Is Common Area Involved <span className="text-red-500">*</span>
          </label>
          <Select
            label="LRRP Activity Auto Complete"
            value={
              LrrpConfigurationFormik.values?.tested_lead_positive
                ?.is_common_area_involved
            }
            onChange={event => {
              LrrpConfigurationFormik.setFieldValue('tested_lead_positive', {
                ...LrrpConfigurationFormik.values.tested_lead_positive,
                is_common_area_involved: event.target.value,
              });
              setBtnDisables(false);
            }}
          >
            <MenuItem value={'yes'}>Yes</MenuItem>
            <MenuItem value={'N/A'}>N/A</MenuItem>
          </Select>
        </Grid>
        {LrrpConfigurationFormik.values?.tested_lead_positive
          ?.is_common_area_involved === nonAvailableReason && (
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              label="Reason Common Area"
              name="Reason Common Area"
              className="p-1"
              value={
                LrrpConfigurationFormik.values?.tested_lead_positive
                  ?.reason_common_area
              }
              onChange={event => {
                LrrpConfigurationFormik.setFieldValue('tested_lead_positive', {
                  ...LrrpConfigurationFormik.values.tested_lead_positive,
                  reason_common_area: event.target.value,
                });
                setBtnDisables(false);
              }}
              required
              inputProps={{ maxLength: 500 }}
            />
          </Grid>
        )}

        <Grid item xs={12} md={6} lg={3}>
          <label
            htmlFor="workAreaSignage"
            className="text-sm font-medium text-bluegray-500"
          >
            Work Area Signage <span className="text-red-500">*</span>
          </label>
          <Select
            label="Work Area Signage"
            value={
              LrrpConfigurationFormik.values?.tested_lead_positive
                ?.work_area_signage
            }
            onChange={event => {
              LrrpConfigurationFormik.setFieldValue('tested_lead_positive', {
                ...LrrpConfigurationFormik.values.tested_lead_positive,
                work_area_signage: event.target.value,
              });
              setBtnDisables(false);
            }}
          >
            <MenuItem value={'yes'}>Yes</MenuItem>
            <MenuItem value={'N/A'}>N/A</MenuItem>
          </Select>
        </Grid>
        {LrrpConfigurationFormik.values?.tested_lead_positive
          ?.work_area_signage === nonAvailableReason && (
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              label="Signage Explanation"
              name="Signage Explanation"
              className="p-1"
              value={
                LrrpConfigurationFormik.values?.tested_lead_positive
                  ?.signage_explanation
              }
              onChange={event => {
                LrrpConfigurationFormik.setFieldValue('tested_lead_positive', {
                  ...LrrpConfigurationFormik.values.tested_lead_positive,
                  signage_explanation: event.target.value,
                });
                setBtnDisables(false);
              }}
              required
              inputProps={{ maxLength: 500 }}
            />
          </Grid>
        )}

        <Grid item xs={12} md={6} lg={3}>
          <label htmlFor="workAreasContained">
            Work Areas Contained <span className="text-red-500">*</span>
          </label>
          <Select
            label="Work Areas Contained"
            value={
              LrrpConfigurationFormik?.values?.tested_lead_positive
                ?.work_areas_contained
            }
            onChange={event => {
              LrrpConfigurationFormik?.setFieldValue('tested_lead_positive', {
                ...LrrpConfigurationFormik?.values?.tested_lead_positive,
                work_areas_contained: event?.target?.value,
              });
            }}
          >
            <MenuItem key="placeholder" value="placeholder" disabled>
              <span style={{ color: Colors.PF_GRAY }}>
                {' '}
                Please make a selection
              </span>
            </MenuItem>
            {workAreasContainedValue?.map(reason => (
              <MenuItem key={reason} value={reason}>
                {reason}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <label
            htmlFor="prohibitedPractices"
            className="text-sm font-medium text-bluegray-500"
          >
            Prohibited Practices <span className="text-red-500">*</span>
          </label>
          <Select
            label="Prohibited Practices"
            value={
              LrrpConfigurationFormik.values?.tested_lead_positive
                ?.prohibited_practices
            }
            onChange={event => {
              LrrpConfigurationFormik.setFieldValue('tested_lead_positive', {
                ...LrrpConfigurationFormik.values.tested_lead_positive,
                prohibited_practices: event.target.value,
              });
              setBtnDisables(false);
            }}
          >
            <MenuItem value={'yes'}>Yes</MenuItem>
            <MenuItem value={'N/A'}>N/A</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <label
            htmlFor="wasteContained"
            className="text-sm font-medium text-bluegray-500"
          >
            Waste Contained <span className="text-red-500">*</span>
          </label>
          <Select
            label="Waste Contained"
            value={
              LrrpConfigurationFormik.values?.tested_lead_positive
                ?.waste_contained
            }
            onChange={event => {
              LrrpConfigurationFormik.setFieldValue('tested_lead_positive', {
                ...LrrpConfigurationFormik.values.tested_lead_positive,
                waste_contained: event.target.value,
              });
              setBtnDisables(false);
            }}
          >
            <MenuItem value={'yes'}>Yes</MenuItem>
            <MenuItem
              value={
                LrrpConfigurationFormik.values?.tested_lead_positive
                  ?.waste_contained === 'no'
                  ? 'no'
                  : 'N/A'
              }
            >
              N/A
            </MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <label
            htmlFor="worksiteProperlyCleaned"
            className="text-sm font-medium text-bluegray-500"
          >
            Worksite Properly Cleaned <span className="text-red-500">*</span>
          </label>
          <Select
            label="Worksite Properly Cleaned"
            value={
              LrrpConfigurationFormik.values?.tested_lead_positive
                ?.worksite_properly_cleaned
            }
            onChange={event => {
              LrrpConfigurationFormik.setFieldValue('tested_lead_positive', {
                ...LrrpConfigurationFormik.values.tested_lead_positive,
                worksite_properly_cleaned: event.target.value,
              });
              setBtnDisables(false);
            }}
          >
            <MenuItem value={'yes'}>Yes</MenuItem>
            <MenuItem value={'N/A'}>N/A</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <label>
            Post Renovation Cleaning Method{' '}
            <span style={{ color: StatusColors.PF_RED_ORANGE }}>*</span>
          </label>
          <Select
            label="Post Renovation Cleaning Method"
            value={
              LrrpConfigurationFormik.values?.tested_lead_positive
                ?.post_renovation_cleaning_method
            }
            onChange={event => {
              LrrpConfigurationFormik.setFieldValue('tested_lead_positive', {
                ...LrrpConfigurationFormik.values.tested_lead_positive,
                post_renovation_cleaning_method: event.target.value,
              });
              setBtnDisables(false);
            }}
          >
            {postRenovationCleaningMethod.map(cleaningMethod => (
              <MenuItem key={cleaningMethod} value={cleaningMethod}>
                {cleaningMethod}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        {LrrpConfigurationFormik?.values?.tested_lead_positive
          ?.post_renovation_cleaning_method ===
          postRenovationCleaningMethod?.[0] && (
          <>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                type="number"
                label="Number of Wet Cloths"
                name="Number of Wet Cloths"
                className="p-1"
                value={
                  LrrpConfigurationFormik.values?.tested_lead_positive
                    ?.number_of_wet_cloths
                }
                onChange={event => {
                  LrrpConfigurationFormik.setFieldValue(
                    'tested_lead_positive',
                    {
                      ...LrrpConfigurationFormik.values.tested_lead_positive,
                      number_of_wet_cloths: event.target.value,
                    }
                  );
                  setBtnDisables(false);
                }}
                required
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                type="number"
                label="Number of Dry Cloths"
                name="Number of Dry Cloths"
                className="p-1"
                value={
                  LrrpConfigurationFormik.values?.tested_lead_positive
                    ?.number_of_dry_cloths
                }
                onChange={event => {
                  LrrpConfigurationFormik.setFieldValue(
                    'tested_lead_positive',
                    {
                      ...LrrpConfigurationFormik.values.tested_lead_positive,
                      number_of_dry_cloths: event.target.value,
                    }
                  );
                  setBtnDisables(false);
                }}
                required
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                label="Cleaning Verification Results"
                name="Cleaning Verification Results"
                value={
                  LrrpConfigurationFormik.values?.tested_lead_positive
                    ?.cleaning_verification_results
                }
                onChange={event => {
                  LrrpConfigurationFormik.setFieldValue(
                    'tested_lead_positive',
                    {
                      ...LrrpConfigurationFormik.values.tested_lead_positive,
                      cleaning_verification_results: event.target.value,
                    }
                  );
                  setBtnDisables(false);
                }}
                required
                inputProps={{ maxLength: 500 }}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12} md={6} lg={3} className="mb-3">
          <label
            htmlFor="isChildOccupiedFacility"
            className="text-sm font-medium text-bluegray-500"
          >
            Is child occupied facility <span className="text-red-500">*</span>
          </label>
          <Select
            label="Is child occupied facility"
            value={
              LrrpConfigurationFormik.values?.tested_lead_positive
                ?.is_child_occupied_facility
            }
            onChange={event => {
              LrrpConfigurationFormik.setFieldValue('tested_lead_positive', {
                ...LrrpConfigurationFormik.values.tested_lead_positive,
                is_child_occupied_facility: event.target.value,
              });
              setBtnDisables(false);
            }}
          >
            <MenuItem value={'yes'}>Yes</MenuItem>
            <MenuItem value={'N/A'}>N/A</MenuItem>
          </Select>
        </Grid>
        {LrrpConfigurationFormik.values?.tested_lead_positive
          ?.is_child_occupied_facility === nonAvailableReason && (
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              label="Reason Child Facility"
              name="Reason Child Facility"
              className="p-1"
              value={
                LrrpConfigurationFormik.values?.tested_lead_positive
                  ?.reason_child_facility
              }
              onChange={event => {
                LrrpConfigurationFormik.setFieldValue('tested_lead_positive', {
                  ...LrrpConfigurationFormik.values.tested_lead_positive,
                  reason_child_facility: event.target.value,
                });
                setBtnDisables(false);
              }}
              required
              inputProps={{ maxLength: 500 }}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default TestedLeadPositive;
