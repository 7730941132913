export const WIDGET_FILTER_TYPES = {
  'normal-filter': 'Standard (No Coverage)',
  'user-filter': 'User Coverage',
  'company-filter': 'Company',
  'assigned-to-self': 'Assigned to User',
  'user-and-assigned-to-self': 'User Coverage and Assigned To User',
  'company-and-assigned': 'Company & Assigned',
};

export const INVENTORY_ADJUSTMENT_PROJECT_TABLE = 'Project';
export const INVENTORY_ADJUSTMENT_TYPE_PROJECT = 'Project.adjustment_type';
export const INVENTORY_ADJUSTMENT_TYPE = 'InventoryAdjustment.adjustment_type';
export const INVENTORY_ADJUSTMENT_PROJECT_OBJECT_ID =
  'Project.adjustment_object_id';
export const INVENTORY_ADJUSTMENT_TABLE = 'InventoryAdjustment';
export const INVENTORY_ADJUSTMENT_OBJECT_ID =
  'InventoryAdjustment.adjustment_object_id';
export const CHARGEBACK_KEY = 'Chargebacks';
export const PROJECT_KEY = 'project';

export const INVENTORY_ADJUSTMENT_TYPES = {
  DISPATCHED: 'Dispatched',
  ALLOCATED: 'Allocated',
  ON_ORDER: 'OnOrder',
};

export const CUBE_LABELS = {
  USER_DETAILS: 'User Details',
  USER_META: 'UserMeta',
};

export const ORDER_BY = [
  { value: 'ASC', name: 'asc' },
  { value: 'DESC', name: 'desc' },
];

export const DASHBOARD_RETRY_LIMIT = 2;

export const onCellEditComplete = e => {
  let { rowData, newValue, field } = e;
  let updatedValue = null;
  Object.keys(e?.rowData)?.forEach(key => {
    const splitKey = key?.split('.');
    if (
      splitKey?.length > 1 &&
      e.newRowData[splitKey[0]] &&
      e.newRowData[splitKey[0]][splitKey[1]]
    ) {
      updatedValue = e?.newRowData[splitKey[0]][splitKey[1]];
    }
  });
  rowData[field] = updatedValue || newValue;
};

export const getFieldType = (selectedValue, masterData, annotation) => {
  if (masterData[selectedValue] && masterData[selectedValue]?.length > 0) {
    return 'dropdown';
  }
  if (annotation && selectedValue && annotation[selectedValue]) {
    const fieldType = annotation[selectedValue]?.type;
    if (fieldType === 'string') {
      return 'string';
    } else if (fieldType === 'number') {
      return 'number';
    } else if (fieldType === 'time') {
      return 'date';
    }
  }
  return 'string';
};

export const defaultRowsPerPageOptions = [
  10, 25, 50, 100, 500, 1000, 2000, 5000,
];
export const bulkUpdateRowsPerPageOptions = [10, 25, 50, 100, 200, 250];
export const groupByRowsPerPageOptions = [10, 25, 50, 100, 500];
export const removeStatusValues = ['Scheduled', 'Tentatively Scheduled'];
export const filterMasterData = masterData => {
  return {
    ...masterData,
    'Project.status': masterData['Project.status']?.filter(
      item => !removeStatusValues?.includes(item?.value) ?? []
    ),
  };
};

export const getEditableKeys = tableAnnotation => {
  if (!tableAnnotation || typeof tableAnnotation !== 'object') return [];

  return Object.keys(tableAnnotation)?.filter(
    key => tableAnnotation[key]?.meta?.isEditable === true
  );
};

export const headerTitle = (alice, isEditable, isEdit) => {
  return (
    <div className="flex align-items-center gap-2 text-xs">
      {isEditable && isEdit && <i className="pi pi-pencil text-color" />}
      {alice}
    </div>
  );
};

export const hilightCell = (isEditable, isEdit) => {
  return isEditable
    ? 'text-color cursor-pointer'
    : isEdit
      ? 'text-400'
      : 'text-color';
};

export const isUpdateDisabled = (
  selectedCells,
  values,
  filterEditableRecords,
  filterMasterDataList,
  annotation
) => {
  if (!selectedCells?.length) return true;

  return values?.some(field => {
    const filteredValue = filterEditableRecords()?.find(
      item => item?.alice === field?.columnField
    );
    const fieldType = getFieldType(
      filteredValue?.measureName || '',
      filterMasterDataList,
      annotation
    );

    if (!field?.columnField?.trim()) return true;

    switch (fieldType) {
      case 'dropdown':
        return !field?.dropdownField;
      case 'string':
        return !field?.inputField?.trim();
      case 'number':
        return !field?.inputFieldNumber;
      default:
        return false;
    }
  });
};
