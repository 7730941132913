import React from 'react';
import { Card } from 'primereact/card';

import PageHeader from '../../shared/PageHeader/PageHeader';

import LaborTableNew from './LaborTableNew.js';
import ProductTableNew from './ProductTableNew.js';

const ItemsNew = ({ formik }) => {
  const itemsBreadcrumb = [
    {
      text: 'Project Management',
    },
    {
      text: 'Items',
    },
    {
      text: 'Items Listing',
    },
  ];

  return (
    <>
      <div className="w-full grid">
        <PageHeader
          pageTitle="Items Listing"
          breadCrumbArray={itemsBreadcrumb}
        />
      </div>
      <Card
        className="w-full mt-4 mb-0 mx-auto"
        pt={{ content: { className: 'p-0' } }}
      >
        <LaborTableNew itemId={formik?.values?.item_id} />
      </Card>
      <Card
        className="w-full mt-4 mb-0 mx-auto"
        pt={{ content: { className: 'p-0' } }}
      >
        <ProductTableNew itemId={formik?.values?.item_id} />
      </Card>
    </>
  );
};

export default ItemsNew;
