import React, { useEffect, useRef, useState } from 'react';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { Checkbox } from 'primereact/checkbox';
import { Toast } from 'primereact/toast';
import { AutoComplete } from 'primereact/autocomplete';
import './project-item.css';

import AddEditMerchandiseItemDialog from '../Items/AddEditMerchandiseItemDialog';
import { getMerchandiseItemsList } from '../Items/Items.service';

import { getMerchandiseItems, getLinkedItemsList } from './ProjectItemsService';
import AddProjectMerchandiseItemDialog from './AddProjectMerchandiseItemDialog';
import DeleteProjectItemDialog from './DeleteProjectItemDialog';
import EditProjectMerchandiseItemDialog from './EditProjectMerchandiseItemDialog';
import ProductItemCardView from './ProductItemCardView';

export default function ProjectMerchandiseItemsGrid(props) {
  //console.log('props', props);

  //permissions
  const viewAllMerchandiseCost = props.viewAllMerchandiseCost
    ? props.viewAllMerchandiseCost
    : false;
  const addAllMerchandiseCost = props.addAllMerchandiseCost
    ? props.addAllMerchandiseCost
    : false;
  const editAllMerchandiseCost = props.editAllMerchandiseCost
    ? props.editAllMerchandiseCost
    : false;
  const tabProjectItemsMerchandiseAdd = props.tabProjectItemsMerchandiseAdd
    ? props.tabProjectItemsMerchandiseAdd
    : false;
  const tabProjectItemsMerchandiseModify =
    props.tabProjectItemsMerchandiseModify
      ? props.tabProjectItemsMerchandiseModify
      : false;
  const tabProjectItemsMerchandiseDelete =
    props.tabProjectItemsMerchandiseDelete
      ? props.tabProjectItemsMerchandiseDelete
      : false;

  const projectInvoiceItems = props?.projectInvoiceItems;
  const handleInvoiceItems = props?.handleInvoiceItems;

  //States and Other Variables
  const projectId = props.projectId;
  const projectNumber = props.projectNumber;
  const [updateMerchandiseItems, setUpdateMerchandiseItems] = useState(true);
  const [merchandiseItems, setMerchandiseItems] = useState([]);
  const [isAddMerchandiseDialogOpen, setIsAddMerchandiseDialogOpen] =
    useState(false);
  const [isAssignMerchandiseDialogOpen, setIsAssignMerchandiseDialogOpen] =
    useState(false);
  const [isDeleteMerchandiseItemVisible, setIsDeleteMerchandiseItemVisible] =
    useState(false);
  const [
    isEditProjectMerchandiseItemDialogVisible,
    setIsEditProjectMerchandiseItemDialogVisible,
  ] = useState(false);
  const [loadSystemItems, setLoadSystemItems] = useState(true);
  const [systemItems, setSystemItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const toast = useRef(null);
  const [childrenItem, setChildrenItem] = useState([]);
  const [selectedJob, setSelectedJob] = React.useState();
  const [items, setItems] = useState([]);
  const menuRef = useRef(null);
  const [selectAll, setSelectAll] = useState({
    reqFrom: 'self',
    value: false,
  });
  const [nonInvoicedItems, setNonInvoicedItems] = useState(true);
  const [cloneItem, setCloneItem] = useState(false);
  const [isFromClone, setIsFromClone] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (selectAll?.reqFrom === 'self' && merchandiseItems?.length > 0) {
      merchandiseItems.map(rowData => {
        const invoiceGenerated = props?.projectInvoiceItems.find(
          item =>
            item.project_item_id === rowData.project_item_id &&
            item?.invoice?.status !== 'Cancelled'
        );
        if (!invoiceGenerated) {
          props?.handleInvoiceItems(rowData, selectAll?.value);
        }
      });
    }
  }, [selectAll?.value, merchandiseItems]);

  const merchandiseMenuOptions = [
    {
      label: 'Add Product Item',
      icon: 'pi pi-user-plus',
      command: () => {
        setIsAddMerchandiseDialogOpen(true);
      },
    },
    ...(tabProjectItemsMerchandiseAdd
      ? [
          {
            label: 'Assign Product Item',
            icon: 'pi pi-users',
            command: () => {
              setCloneItem(false);
              setIsAssignMerchandiseDialogOpen(true);
            },
          },
        ]
      : []),
  ];

  //Mount
  useEffect(() => {
    if (!props.isLinked && updateMerchandiseItems) {
      getMerchandiseItems(projectId)
        .then(res => {
          setMerchandiseItems(
            res?.data?.status && res?.data?.status === true ? res.data.data : []
          );
        })
        .catch(err => {
          setMerchandiseItems([]);
          console.error(err);
        });
      setUpdateMerchandiseItems(false);
      if (filteredData?.child_projects?.[0]?.project_id) {
        getLinkedProductItem(filteredData?.child_projects?.[0]?.project_id);
      }
    }
  }, [updateMerchandiseItems]);

  useEffect(() => {
    if (props.updateMerchandiseItems) {
      setUpdateMerchandiseItems(true);
    }
  }, [props.updateMerchandiseItems]);

  useEffect(() => {
    if (loadSystemItems) {
      getMerchandiseItemsList(
        {},
        setLoadSystemItems,
        result => result?.count && setSystemItems(result?.items)
      );
    }
  }, [loadSystemItems]);

  if (merchandiseItems?.length >= 0) {
    props.updateParentMerchandiseItems(merchandiseItems);
  } else {
    props.updateParentMerchandiseItems([]);
  }

  useEffect(() => {
    getList();
  }, []);

  //Methods
  const showAlert = (severity, message, lifespan = 1500) => {
    toast.current.show({
      severity: severity,
      summary: message,
      lifespan: lifespan,
    });
  };

  const onEditProjectMerchandiseItemDialogHide = projectItemEdited => {
    setSelectedItem(null);
    setIsEditProjectMerchandiseItemDialogVisible(false);
    if (projectItemEdited && projectItemEdited === true) {
      setUpdateMerchandiseItems(true);
    }
  };

  const onDeleteProjectItemDialog = projectItemDeleted => {
    setIsDeleteMerchandiseItemVisible(false);
    if (projectItemDeleted) {
      const merchandiseItemsList = [...merchandiseItems];
      const index = merchandiseItemsList.findIndex(
        item => item.project_item_id === selectedItem.project_item_id
      );
      if (index > -1) {
        merchandiseItemsList.splice(index, 1);
        setMerchandiseItems(merchandiseItemsList);
      }
    }
  };

  const onAddProjectMerchandise = projectItemCreated => {
    setIsAssignMerchandiseDialogOpen(false);
    if (projectItemCreated) {
      setUpdateMerchandiseItems(true);
    }
  };

  //Templates
  const merchandiseCardHeaderTemplate = () => {
    return (
      <>
        <div className="flex justify-content-start align-content-center align-items-center">
          <h3>{props.isLinked ? 'Linked Product Items' : 'Product Items'}</h3>
          {!props.isLinked && (
            <div className="ml-auto align-items-center flex">
              {props?.CRMEnabled ? (
                <>
                  <Checkbox
                    onChange={() =>
                      setSelectAll(preState => ({
                        reqFrom: 'self',
                        value: !preState?.value,
                      }))
                    }
                    checked={selectAll?.value || false}
                    className="mr-2"
                    disabled={nonInvoicedItems || props.isLinked}
                  ></Checkbox>
                  <label htmlFor="selectAllInvoice" className="mr-3">
                    Select All Invoice
                  </label>
                </>
              ) : null}
              {!props.isLinked && (
                <Button
                  type="button"
                  onClick={event => menuRef.current.toggle(event)}
                  icon="pi pi-plus"
                  outlined
                  label="Product Item"
                  size="small"
                ></Button>
              )}
            </div>
          )}
          <div className="flex"></div>
        </div>
      </>
    );
  };

  const getList = () => {
    const customerId = props.customerDetails?.[0]?.customer_id;
    if (customerId) {
      const linkProjects = getLinkedItemsList(customerId, props?.project);
      linkProjects
        .then(project => {
          if (project?.status) {
            const projectsData = project?.data?.data;

            const groupedProjects = projectsData?.reduce((acc, item) => {
              const parentProject = item?.data;
              const parentProjectId = parentProject?.project_id;
              let existingParent = acc?.find(
                p => p?.parent_project_id === parentProjectId
              );
              const linkedChildData = projectsData.find(
                child =>
                  child?.data?.child_linkage?.linked_project_id ==
                  props?.projectId
              );
              if (
                linkedChildData?.data?.child_linkage?.project_id &&
                props.isLinked
              ) {
                getLinkedProductItem(
                  linkedChildData?.data?.child_linkage?.project_id
                );
              }

              if (!existingParent) {
                existingParent = {
                  parent_project_id: parentProjectId,
                  child_projects: [],
                };
                acc?.push(existingParent);
              }
              if (item?.children) {
                item?.children.forEach(child => {
                  existingParent?.child_projects?.push({
                    project_id: child?.data?.project_id,
                    project_number: child?.data?.project_number,
                    project_type: child?.data?.project_type?.project_type,
                  });
                });
              }
              return acc;
            }, []);

            setChildrenItem(groupedProjects);
          }
        })
        .catch(error => {
          console.error('Error fetching projects:', error);
        });
    }
  };

  const filteredData = childrenItem?.find(
    item => item?.parent_project_id == props?.projectId
  );

  const getLinkedProductItem = async projectId => {
    setIsLoading(true);
    try {
      const res = await getMerchandiseItems(projectId);
      setMerchandiseItems(
        res?.data?.status && res?.data?.status === true ? res.data.data : []
      );
      setIsLoading(false);
    } catch (err) {
      console.error('Error Setting Labor Items: ', err);
      setMerchandiseItems([]);
      setIsLoading(false);
    }
  };

  const search = event => {
    let filteredItems = filteredData?.child_projects
      ?.filter(project =>
        project?.project_number
          ?.toLowerCase()
          .includes(event?.query?.toLowerCase())
      )
      ?.map(item => ({
        label: item?.project_number,
        value: item?.project_id,
      }));
    setItems(filteredItems);
  };

  useEffect(() => {
    if (filteredData && filteredData?.child_projects?.length > 0) {
      const data = filteredData?.child_projects?.[0];
      setSelectedJob({
        label: data?.project_number,
        value: data?.project_id,
      });
      getLinkedProductItem(filteredData?.child_projects?.[0]?.project_id);
    }
  }, [filteredData]);

  const handleJobSelection = project => {
    getLinkedProductItem(project?.value);
  };

  //Component
  return (
    <Card
      className="shadow-none"
      header={merchandiseCardHeaderTemplate}
      pt={{ body: { className: 'py-0 px-0' } }}
    >
      <Toast ref={toast} />
      <Menu
        model={merchandiseMenuOptions}
        popup
        ref={menuRef}
        pt={{ action: { className: 'py-2 text-sm' } }}
      />

      {props.isLinked && filteredData?.child_projects?.length > 1 && (
        <div className="flex mb-5">
          <AutoComplete
            value={selectedJob}
            suggestions={items}
            completeMethod={search}
            onChange={e => setSelectedJob(e.value)}
            onSelect={e => handleJobSelection(e.value)}
            dropdown
            field="label"
          />
        </div>
      )}

      {isAddMerchandiseDialogOpen && (
        <AddEditMerchandiseItemDialog
          visible={isAddMerchandiseDialogOpen}
          setIsAddMerchandiseItemDialogVisible={setIsAddMerchandiseDialogOpen}
          itemId={null}
          itemsReload={setLoadSystemItems}
          setMerchandiseLoading={() => {}}
          action="add"
          rowData={null}
          showAlert={showAlert}
        ></AddEditMerchandiseItemDialog>
      )}

      {isAssignMerchandiseDialogOpen && (
        <AddProjectMerchandiseItemDialog
          visible={isAssignMerchandiseDialogOpen}
          projectId={projectId}
          onHide={onAddProjectMerchandise}
          itemsList={systemItems}
          addAllMerchandiseCost={addAllMerchandiseCost}
          quoteNumber={props?.quoteNumber}
          CRMEnabled={props?.CRMEnabled || false}
          isMeasureSquareEnabled={props?.isMeasureSquareEnabled}
          selectedProjectItem={cloneItem && selectedItem}
          isFromClone={isFromClone}
          setIsFromClone={setIsFromClone}
        ></AddProjectMerchandiseItemDialog>
      )}

      {isDeleteMerchandiseItemVisible && selectedItem && (
        <DeleteProjectItemDialog
          visible={isDeleteMerchandiseItemVisible}
          projectId={projectId}
          selectedItem={selectedItem}
          onHide={onDeleteProjectItemDialog}
          showAlert={showAlert}
        ></DeleteProjectItemDialog>
      )}

      {isEditProjectMerchandiseItemDialogVisible && selectedItem && (
        <EditProjectMerchandiseItemDialog
          visible={isEditProjectMerchandiseItemDialogVisible}
          projectId={projectId}
          selectedProjectItem={selectedItem}
          onHide={onEditProjectMerchandiseItemDialogHide}
          itemsList={systemItems}
          editAllMerchandiseCost={editAllMerchandiseCost}
          quoteNumber={props?.quoteNumber}
          CRMEnabled={props?.CRMEnabled || false}
          isMeasureSquareEnabled={props?.isMeasureSquareEnabled || false}
          warehouseList={props?.warehouseList || []}
        ></EditProjectMerchandiseItemDialog>
      )}

      <ProductItemCardView
        setUpdateMerchandiseItems={setUpdateMerchandiseItems}
        merchandiseItems={merchandiseItems}
        tabProjectItemsMerchandiseModify={tabProjectItemsMerchandiseModify}
        tabProjectItemsMerchandiseDelete={tabProjectItemsMerchandiseDelete}
        setIsEditProjectMerchandiseItemDialogVisible={
          setIsEditProjectMerchandiseItemDialogVisible
        }
        setIsDeleteMerchandiseItemVisible={setIsDeleteMerchandiseItemVisible}
        setSelectedItem={setSelectedItem}
        projectInvoiceItems={projectInvoiceItems}
        handleInvoiceItems={handleInvoiceItems}
        CRMEnabled={props?.CRMEnabled || false}
        invoiceItems={props?.invoiceItems}
        setNonInvoicedItems={setNonInvoicedItems}
        viewAllMerchandiseCost={viewAllMerchandiseCost}
        isMeasureSquareEnabled={props?.isMeasureSquareEnabled}
        isSalesCommissionOpted={props?.isSalesCommissionOpted}
        projectId={projectId}
        projectNumber={projectNumber}
        setIsAssignMerchandiseDialogOpen={setIsAssignMerchandiseDialogOpen}
        setCloneItem={setCloneItem}
        setIsFromClone={setIsFromClone}
        viewProductItemStatus={props.viewProductItemStatus}
        addProductItemStatus={props.addProductItemStatus}
        editProductItemStatus={props.editProductItemStatus}
        linkedItem={props.isLinked}
        loading={isLoading}
      />
    </Card>
  );
}
