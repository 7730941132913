import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { Button } from 'primereact/button';
import { useHistory, useParams } from 'react-router-dom';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { AutoComplete } from 'primereact/autocomplete';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { InputSwitch } from 'primereact/inputswitch';
import { Message } from 'primereact/message';

import PageHeader from '../../../shared/PageHeader/PageHeader';
import Items from '../../../CRM/Quotes/components/Items';
import PaymentTerms from '../../../CRM/Quotes/components/PaymentTerms';
import TermsConditions from '../../../CRM/Quotes/components/TermsConditions';
import {
  createQuoteTemplate,
  createTagListingService,
  getQuoteTemplateList,
  tagListService,
  updateQuoteTemplate,
  getSignedUrl,
} from '../Service/QuotesTemplate.service';
import {
  IFormPayload,
  ILineItem,
  IRouteParams,
  ITag,
  ITagValue,
  IQuoteDocument,
  IPreSignedUrlState,
  IDocumentUploadedFile,
} from '../Interface/quoteTemplate.interface';
import { useConfirmDialogContext } from '../../../../contexts/ConfirmDialog';
import { ALLOWED_FILES_EXTENSION } from '../../../../constants';

import AddViewDocAndPhoto from './AddViewDocAndPhoto';

const QuotesTemplate = () => {
  const { action, quoteId, id } = useParams<IRouteParams>();
  const toast = useRef<Toast>(null);
  const history = useHistory();
  const breadcrumb = [
    {
      text: 'Admin',
    },
    {
      link: '/admin/company-config/edit/${id}',
      text: 'Company Configurations',
    },
    {
      text: 'Quote Template',
    },
  ];
  const [laborItems, setLaborItems] = useState<object[]>([]);
  const [merchandiseItems, setMerchandiseItems] = useState<object[]>([]);
  const [isForm, setIsForm] = useState(false);

  const [formPayload, setFormPayload] = useState<IFormPayload>({
    quoteTemplateName: '',
    opportunityId: '',
    amount: {},
  });
  const [tagSuggestionValue, setTagSuggestionValue] = useState<ITag[]>([]);
  const [tagName, setTagName] = useState<ITag>({ id: 0, name: '' });
  const [isPreviewVisible, setIsPreviewVisible] = useState<boolean>(false);
  const [uploadedFiles, setUploadedFiles] = useState<IDocumentUploadedFile[]>(
    []
  );
  const [preSignedUrl, setPreSignedUrl] = useState<IPreSignedUrlState>({
    url: '',
    type: null,
  });
  const [deletedDocumentFileIds, setDeletedDocumentFileIds] = useState<
    number[]
  >([]);
  const [checked, setChecked] = useState<boolean>(false);
  const { showConfirmationDialog } = useConfirmDialogContext();
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    const items = [...laborItems, ...merchandiseItems];
    setFormPayload(preValues => ({
      ...preValues,
      lineItems: { items: items },
    }));
  }, [laborItems, merchandiseItems]);

  const suggestTagList = async (
    event: { query: string | number } | string | number
  ) => {
    const query =
      typeof event === 'object' && 'query' in event ? event.query : event;
    try {
      const searchTagList = await tagListService(id, query);
      if (Array.isArray(searchTagList) && searchTagList.length > 0) {
        const tags: ITag[] = searchTagList.map((tag: ITagValue) => ({
          id: tag.tagId,
          name: tag.tagName,
        }));
        setTagSuggestionValue(tags);
      } else {
        setTagSuggestionValue([]);
      }
    } catch (error) {
      console.error('Error fetching tag list:', error);
      setTagSuggestionValue([]);
    }
  };

  const addTagName = async (value: ITag) => {
    if (value) {
      const newTagName = await createTagListingService({
        clientId: id,
        tagName: value,
      });
      if (
        Array.isArray(newTagName?.identifiers) &&
        newTagName?.identifiers?.length > 0
      ) {
        suggestTagList(newTagName?.identifiers[0]?.tagId);
        toast?.current?.show({
          severity: 'success',
          summary: `${newTagName?.identifiers[0]?.tagName ?? newTagName?.identifiers[0]?.tagName} - tag name created successfully`,
          life: 2000,
        });
      } else {
        toast.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: `${newTagName?.response?.data?.message ? newTagName?.response?.data?.message : 'Failed to save tag name'}`,
          life: 2000,
        });
      }
    }
  };

  const handleDocsUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      setLoading(true);
      const files = event.target.files;
      if (!files || files.length === 0) return;

      const allowedFiles = Array.from(files).filter(file =>
        ALLOWED_FILES_EXTENSION.includes(
          file.name.slice(file.name.lastIndexOf('.')).toLowerCase()
        )
      );

      if (allowedFiles.length !== files.length) {
        toast.current?.show({
          severity: 'error',
          detail: 'Only .pdf, .doc, .xls, .csv  files are allowed!',
          life: 1000,
        });
        event.target.value = '';
        return;
      }

      const uploadedFileDetails: IDocumentUploadedFile[] = await Promise.all(
        allowedFiles.map(async file => {
          const previewUrl = URL.createObjectURL(file);

          const response = await getSignedUrl(file);
          const signedUrl = response;
          return {
            name: file.name,
            url: previewUrl,
            signedUrl,
            timeStamp: new Date().toISOString(),
            isFetched: false,
          };
        })
      );

      setUploadedFiles(prevFiles => [...prevFiles, ...uploadedFileDetails]);
      event.target.value = '';
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  // Prepare files for AddViewDocAndPhoto
  const filesForAddView = uploadedFiles?.map(file => ({
    name: file?.name,
    url: file?.url,
    timeStamp: file?.timeStamp,
  }));
  // Function to handle delete
  const handleDeleteDoc = (indexToDelete: number) => {
    showConfirmationDialog({
      header: 'Confirmation',
      message: 'Are you sure you want to delete this document?',
      accept: () => {
        setUploadedFiles(prevFiles => {
          const fileToDelete = prevFiles[indexToDelete];
          if (fileToDelete.isFetched && fileToDelete.quotesTemplateDocumentId) {
            setDeletedDocumentFileIds(prev => [
              ...prev,
              fileToDelete.quotesTemplateDocumentId as number,
            ]);
          }

          return prevFiles.filter((_, index) => index !== indexToDelete);
        });
      },
    });
  };

  const fetchQuoteTempData = async () => {
    try {
      setLoading(true);
      const response = await getQuoteTemplateList(id, quoteId, '', '');
      if (response?.rows) {
        const lineItems = response.rows[0]?.lineItems || [];

        const laborItems: object[] = [];
        const productItems: object[] = [];

        lineItems.forEach((item: ILineItem) => {
          if (item.itemTypeId === 2) {
            laborItems.push(item);
          } else {
            productItems.push(item);
          }
        });
        setLaborItems(laborItems);
        setMerchandiseItems(productItems);

        setTagName({
          id: response?.rows?.[0]?.templateTagId
            ? response?.rows?.[0]?.templateTagId
            : 0,
          name: response?.rows?.[0]?.templateTag?.tagName
            ? response?.rows?.[0]?.templateTag?.tagName
            : '',
        });
        setFormPayload(pre => ({
          ...pre,
          quoteTemplateName: response?.rows?.[0]?.title || '',
          paymentTerms: response?.rows?.[0]?.paymentTermAndConditions || [],
          termAndConditions: response?.rows?.[0]?.termAndConditions || [],
          items: { items: lineItems },
        }));
      }
      setChecked(response?.rows?.[0]?.isActive);
      setUploadedFiles(
        (response?.rows?.[0]?.documents as IQuoteDocument[]).map(doc => ({
          name: doc.quotDocumentName,
          url: doc.presignedUrl,
          timeStamp: new Date().toISOString(),
          isFetched: true,
          quotesTemplateDocumentId: doc.quotesTemplateDocumentId,
        }))
      );
      console.log('uploadedFiles 1211', uploadedFiles);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (action === 'Edit' && quoteId) {
      fetchQuoteTempData();
    }
  }, [quoteId]);

  const saveQuoteTemplates = async () => {
    try {
      const payloadObject = {
        clientId: id,
        title: formPayload?.quoteTemplateName,
        templateTagId: tagName?.id ? tagName?.id : tagSuggestionValue[0]?.id,
        paymentTerms: formPayload?.paymentTerms || [],
        termAndConditions: formPayload?.termAndConditions || [],
        lineItems: { items: formPayload?.lineItems?.items },
        documentFile: uploadedFiles
          .filter(file => !file.isFetched)
          .map(pdfFile => ({
            file: pdfFile?.signedUrl,
          })),
        documentFileIds: deletedDocumentFileIds,
        isActive: checked ? 1 : 0,
      };

      let response;
      if (action === 'Edit') {
        response = await updateQuoteTemplate(payloadObject, quoteId);
      } else {
        response = await createQuoteTemplate(payloadObject);
      }
      if (response?.status !== 500) {
        toast.current?.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Quote Template saved successfully',
        });
        setTimeout(() => {
          history.push(`/admin/company-config/edit/${id}`);
        }, 500);
      } else {
        toast.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: `${response?.response?.data?.message ? response?.response?.data?.message : 'Failed to save Quote Template'}`,
          life: 2000,
        });
      }
    } catch (error) {
      console.error('Error saving quote template:', error);
      toast.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed to save Quote Template',
      });
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="flex w-full justify-content-between align-items-center mb-2">
        <PageHeader
          pageTitle={`${action} Quote Template`}
          breadCrumbArray={breadcrumb}
          icon=""
        />
        <div className="flex justify-content-end">
          <Button
            label="Back"
            outlined
            className="my-2 mx-1"
            onClick={() => history.push(`/admin/company-config/edit/${id}`)}
          />
          <Button
            label="Save"
            className="my-2 mx-1"
            onClick={() => saveQuoteTemplates()}
            disabled={!formPayload?.quoteTemplateName}
          />
        </div>
      </div>
      <Card className="w-full">
        <div className="grid mb-2">
          <div className="col-12 md:col-3 lg:col-3 mb-3  py-0">
            <span className="p-float-label  ">
              <InputText
                className="w-12"
                value={formPayload?.quoteTemplateName}
                onChange={e =>
                  setFormPayload(prev => ({
                    ...prev,
                    quoteTemplateName: e.target.value,
                  }))
                }
              />
              <label htmlFor="tenant_id">Template Name</label>
            </span>
          </div>
          <div className="col-12 md:col-3 lg:col-3 mb-3  py-0">
            <span className="p-float-label">
              <AutoComplete
                id="chargeback_reason"
                className="w-10"
                suggestions={tagSuggestionValue || []}
                field="name"
                completeMethod={suggestTagList}
                value={tagName || {}}
                onChange={e => {
                  setTagName(e?.target?.value);
                }}
                delay={500}
                inputClassName="p-inputtext-sm w-12 border-right-none border-noround-right"
              />
              <label htmlFor="chargeback_reason">Tag</label>
              <Button
                icon="pi pi-plus"
                className="p-button-primary w-2 border-noround-left height-40"
                tooltip="Add Tag"
                onClick={() => {
                  if (Array.isArray(tagName)) {
                    addTagName(tagName[0]);
                  } else {
                    addTagName(tagName);
                  }
                }}
                disabled={!tagSuggestionValue}
              />
            </span>
          </div>
          <div className="col-12 md:col-3 lg:col-3 mb-3 py-0 flex align-items-center">
            <div className="text-base mx-1">Status:</div>
            <div className="flex justify-content-center align-items-center gap-2 p-1">
              <span>{checked ? 'Active' : 'Inactive'}</span>
              <InputSwitch
                checked={checked}
                onChange={e => setChecked(e.value)}
              />
            </div>
          </div>
        </div>
        <TabView className="w-full">
          <TabPanel header="Item">
            <>
              <Items
                reference="Labor"
                setItems={setLaborItems}
                laborItems={laborItems}
                merchandiseItems={[]}
                retailerPercentage={0}
                isForm={isForm}
                setIsForm={setIsForm}
                title="Labor Line Items"
                addBtnLabel="Add Labor Line Item"
              />
              <Items
                reference="Merchandise"
                setItems={setMerchandiseItems}
                merchandiseItems={merchandiseItems}
                laborItems={[]}
                retailerPercentage={1}
                isForm={isForm}
                setIsForm={setIsForm}
                title="Product Line Items"
                addBtnLabel="Add Product Line Item"
              />
            </>
          </TabPanel>
          <TabPanel header="Documents">
            <AddViewDocAndPhoto
              type="Document"
              uploadedFiles={filesForAddView}
              handleDocsUpload={handleDocsUpload}
              setPreSignedUrl={setPreSignedUrl}
              setIsPreviewVisible={setIsPreviewVisible}
              handleDeleteDoc={handleDeleteDoc}
              loading={loading}
            />
          </TabPanel>
          <TabPanel header="Payment Terms">
            <PaymentTerms
              setFormPayload={setFormPayload}
              formPayload={formPayload}
            />
          </TabPanel>
          <TabPanel header="Terms and Conditions">
            <TermsConditions
              setFormPayload={setFormPayload}
              formPayload={formPayload}
            />
          </TabPanel>
        </TabView>
      </Card>
      <Dialog
        header="Document Preview"
        visible={isPreviewVisible}
        style={{ width: '70vw', height: '90vh' }}
        onHide={() => setIsPreviewVisible(false)}
      >
        <div className="h-full">
          {preSignedUrl?.type === 'Document' ? (
            <iframe
              src={preSignedUrl?.url}
              width="100%"
              height="100%"
              title="Document Preview"
            />
          ) : (
            <Message text={`No Document preview available`} />
          )}
        </div>
      </Dialog>
    </>
  );
};

export default QuotesTemplate;
