import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import { setOpportunityStatus as statusAction } from '../redux/slices/opportunityStatusMaster.slice';
import { URL_CONSTANTS } from '../constants/urlConstants';

const useOpportunityStatus = () => {
  const dispatch = useDispatch();
  const { status } = useSelector(
    state => state?.masters?.opportunityStatusReducer
  );
  const [opportunityStatus, setOpportunityStatus] = useState(status);

  const getOpportunityStatus = async () => {
    try {
      if (opportunityStatus.length === 0) {
        const response = await axios.get(
          `${URL_CONSTANTS.API.BASE_URL}/system/status?statusType=Opportunity`
        );
        setOpportunityStatus(response?.data);
        dispatch(statusAction(response?.data));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getOpportunityStatus();
  }, []);

  return { opportunityStatus };
};

export default useOpportunityStatus;
