import React from 'react';
import { Card } from 'primereact/card';

import PageHeader from '../../../shared/PageHeader/PageHeader';

import FilterReport from './components/FilterReports';

const SchedulerRTSReportFilter = () => {
  const accessBreadcrumb = [
    {
      text: 'Admin',
    },
    {
      link: '/admin/configuration',
      text: 'Company Configurations',
    },

    {
      text: `Scheduler RTS Report Filters`,
    },
  ];

  return (
    <>
      <div className="w-full">
        <PageHeader
          pageTitle={`Scheduler RTS Report Filters`}
          breadCrumbArray={accessBreadcrumb}
          className="pl-2"
        />
        <Card className="mt-4 pt-2" pt={{ body: { className: 'pt-0  mx-2' } }}>
          <FilterReport />
        </Card>
      </div>
    </>
  );
};
export default SchedulerRTSReportFilter;
