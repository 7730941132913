import React from 'react';

import ApiService from '../../../services/api.service.js';

import UnhandledError from './UnhandledError.jsx';

class ErrorBoundaryComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    ApiService.post('/unhandled-error-handler', {
      error: {
        name: error.name,
        message: error.message,
        stack: error.stack,
      },
      errorInfo,
      user_id: localStorage.getItem('user_id'),
      time: new Date(),
      client_id: localStorage.getItem('client_id'),
      url: window.location.href,
    });
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    if (this.state.errorInfo) {
      return <UnhandledError />;
    }

    return this.props.children;
  }
}

export default ErrorBoundaryComponent;
