export const NOTE_TYPE = {
  INSTALLER: 1,
  RETAILER: 2,
  STORE: 3,
  INTERNAL: 4,
  ITEM: 5,
  RESCHEDULE: 6,
  BUSTED: 7,
  SCHEDULED: 8,
  DELIVERY: 25,
  DELIVERY_COMPLETE: 24,
};

export const VIEW_NOTE_TYPES = [
  {
    note_category_id: 1,
    category_name: 'Installer',
  },
  {
    note_category_id: 2,
    category_name: 'Retailer',
  },
  {
    note_category_id: 3,
    category_name: 'Store',
  },
  {
    note_category_id: 4,
    category_name: 'Internal ',
  },
  {
    note_category_id: 5,
    category_name: 'Item',
  },
  {
    note_category_id: 6,
    category_name: 'Reschedule',
  },
  {
    note_category_id: 7,
    category_name: 'Busted',
  },
  {
    note_category_id: 8,
    category_name: 'Scheduled',
  },
  {
    note_category_id: 25,
    category_name: 'Delivery',
  },
  {
    note_category_id: 24,
    category_name: 'Delivery Complete',
  },
];

export const NOTES_STATUS = {
  VIEW: 'Viewed',
  REVIEW: 'Reviewed',
};
