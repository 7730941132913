import React, { useRef } from 'react';
import { Toast } from 'primereact/toast';

import { IssuesData } from './Interface/issues.interface';
import { fetchIssuesData } from './service/issues.service';
import IssuesTable from './components/IssuesTable';

const IssuesContainer = ({ project_id }: { project_id: number }) => {
  const [issuesData, setIssuesData] = React.useState<IssuesData | null>(null);
  const [loading, setLoading] = React.useState(true);

  const toast = useRef<Toast>(null);

  const loadIssuesData = async () => {
    setLoading(true);
    const data = await fetchIssuesData(project_id);
    if (!data?.error) {
      setIssuesData(data?.issuesData);
    } else {
      toast.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: data?.message,
        life: 3000,
      });
    }
    setLoading(false);
  };

  React.useEffect(() => {
    loadIssuesData();
  }, [project_id]);

  return (
    <>
      <Toast ref={toast} />

      <IssuesTable
        issuesList={issuesData?.items}
        loading={loading}
        project_id={project_id}
        loadIssuesData={loadIssuesData}
        toast={toast}
      />
    </>
  );
};

export default IssuesContainer;
