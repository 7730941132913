import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: true,
  routingFormik: null,
  isRoutingFormValid: false,
};

const userFormRoutingSlice = createSlice({
  name: 'userFormRouting',
  initialState: initialState,
  reducers: {
    setUserRoutingForm(state, { payload }) {
      state.isLoading = Object.prototype.hasOwnProperty.call(
        payload,
        'isLoading'
      )
        ? payload.isLoading
        : state.isLoading;
      state.routingFormik = Object.prototype.hasOwnProperty.call(
        payload,
        'routingFormik'
      )
        ? payload.routingFormik
        : state.routingFormik;
      state.isRoutingFormValid = Object.prototype.hasOwnProperty.call(
        payload,
        'isRoutingFormValid'
      )
        ? payload.isRoutingFormValid
        : state.isRoutingFormValid;
    },
  },
});

export const { setUserRoutingForm } = userFormRoutingSlice.actions;

export const userFormRoutingReducer = userFormRoutingSlice.reducer;
