import React, { useRef } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { ConfirmPopup } from 'primereact/confirmpopup';

import { userSettingTabs } from './components/userSettingTabs';

const UserSettingsContainer = () => {
  const toast = useRef(null);
  const [activeTabIndex, setActiveTabIndex] = React.useState(0);

  const handleTabChange = e => {
    setActiveTabIndex(e.index);
  };

  return (
    <>
      <ConfirmPopup />
      <div className="grid w-12 m-0">
        <div className="py-2 action-button-wrapper ">
          <div className="flex flex-row action-button-section justify-content-end gap-0"></div>
        </div>
      </div>

      <div className="flex flex-column w-full user-detail-form p-3 bg-white">
        <TabView
          scrollable
          activeIndex={activeTabIndex}
          onTabChange={handleTabChange}
        >
          {userSettingTabs.map(tab => {
            if (tab.isVisible === true) {
              const Component = tab.component;
              return (
                <TabPanel key={tab.header} header={tab.header}>
                  <Component />
                </TabPanel>
              );
            }
          })}
        </TabView>
        <ConfirmDialog />
      </div>

      <Toast ref={toast} />
    </>
  );
};

export default UserSettingsContainer;
