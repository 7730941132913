import React, { useState } from 'react';
import { InputNumber } from 'primereact/inputnumber';

export const PFNumberInputWithNegative = ({
  value: propValue = null,
  onChange,
  readOnly = false,
  className = '',
  useGrouping = false,
  mode = 'decimal',
  minFractionDigits = 0,
  maxFractionDigits = 0,
  maxLength,
  label = '',
  inputId = '',
  currency,
  inputStyle,
  pt,
  ...remainingProps
}: {
  value?: number | null;
  // eslint-disable-next-line no-unused-vars
  onChange?: (value: number | null) => void;
  readOnly?: boolean;
  className?: string;
  useGrouping?: boolean;
  mode?: 'decimal' | 'currency';
  minFractionDigits?: number;
  maxFractionDigits?: number;
  maxLength?: number;
  label?: string;
  inputId?: string;
  currency?: string;
  inputStyle?: React.CSSProperties;
  pt?: Record<string, unknown>;
}) => {
  const [value, setValue] = useState<number | null>(propValue);

  const handleInputChange = (e: { value: number | null }) => {
    const newValue = Object.is(e.value, -0) ? null : e.value;
    setValue(newValue);
    if (onChange) onChange(newValue);
  };

  return (
    <span className="p-float-label">
      <InputNumber
        value={value}
        onChange={handleInputChange}
        readOnly={readOnly}
        className={`w-full ${className}`}
        useGrouping={useGrouping}
        inputId={inputId}
        mode={mode}
        currency={currency}
        minFractionDigits={minFractionDigits}
        maxFractionDigits={maxFractionDigits}
        maxLength={maxLength}
        inputStyle={inputStyle}
        pt={pt}
        {...remainingProps}
      />
      <label htmlFor={inputId}>{label}</label>
    </span>
  );
};
