import React from 'react';
import { Card } from 'primereact/card';

import PFInputText from '../../PFPrime/PFInputText';
import PFCalendar from '../../PFPrime/PFCalendar';
import PFDropdown from '../../PFPrime/PFDropdown';
import PFFormikError from '../../PFPrime/PFFormikError';

import { quoteStatus, recurrences } from './QuoteInfo.model';

export default function QuoteInfo({
  handleChange,
  values,
  errors,
  touched,
  setFieldValue,
  isView,
  isEdit,
}) {
  return (
    <Card className="w-full  border-200 border-solid border-1">
      <div htmlFor="Quote Info">
        <div className="w-12 text-1xl font-bold p-1">General Info</div>
        <div className="grid flex flex-row flex-wrap w-full m-0 mt-2">
          <div className="formgroup-inline w-full">
            <div className="quote-title  align-items-center justify-content-center w-3">
              <span className="p-float-label p-1">
                <PFInputText
                  id="title"
                  name="title"
                  type="text"
                  value={values?.title || ''}
                  onChange={handleChange}
                  disabled={isView}
                />
                <label htmlFor="title">
                  Title
                  <span style={!isView ? { color: 'red' } : { color: 'none' }}>
                    *
                  </span>
                </label>
              </span>
              <PFFormikError
                touched={touched}
                errors={errors}
                field={'title'}
              />
            </div>
            <div className="quote-created-date align-items-center justify-content-center w-3">
              <span className="p-float-label p-1">
                <PFCalendar
                  id="createDate"
                  name="createDate"
                  value={values?.createDate || ''}
                  onChange={handleChange}
                  disabled={isView}
                  className="border-noround"
                />
                <label htmlFor="quote-created-date">
                  Create Date
                  <span style={!isView ? { color: 'red' } : { color: 'none' }}>
                    *
                  </span>
                </label>
              </span>
              <PFFormikError
                touched={touched}
                errors={errors}
                field={'createDate'}
              />
            </div>
            <div className="quote-expiry-date align-items-center justify-content-center w-3">
              <span className="p-float-label p-1">
                <PFCalendar
                  id="expiryDate"
                  name="expiryDate"
                  value={values?.expiryDate || ''}
                  onChange={handleChange}
                  disabled={isView}
                  className="border-noround"
                />
                <label htmlFor="quote-expiry-date">
                  Expiry Date
                  <span style={!isView ? { color: 'red' } : { color: 'none' }}>
                    *
                  </span>
                </label>
              </span>
              <PFFormikError
                touched={touched}
                errors={errors}
                field={'expiryDate'}
              />
            </div>
            <div className="quote-followup-date align-items-center justify-content-center w-3">
              <span className="p-float-label p-1">
                <PFCalendar
                  id="followupDate"
                  name="followupDate"
                  value={values?.followupDate || ''}
                  onChange={handleChange}
                  disabled={isView}
                  className="border-noround"
                />
                <label htmlFor="quote-followup-date">Followup Date</label>
              </span>
              <div style={{ color: 'red' }} className="px-1 errorStyle">
                {errors?.followupDate || null}
              </div>
            </div>
            <div className="grid flex flex-row flex-wrap w-full m-0 py-1">
              <div className="quote-status align-items-center justify-content-center w-3">
                <span className="p-float-label p-1">
                  <PFDropdown
                    value={values?.status || 'OPEN'}
                    onChange={setFieldValue}
                    options={quoteStatus}
                    optionLabel="name"
                    placeholder="Status"
                    optionValue="name"
                    name="status"
                    disabled={!isEdit}
                  />
                  <label htmlFor="quote-status">Status</label>
                </span>
                <div style={{ color: 'red' }} className="px-1 errorStyle">
                  {errors?.status || null}
                </div>
              </div>
              <div className="quote-recurrence align-items-center justify-content-center w-3">
                <span className="p-float-label p-1">
                  <PFDropdown
                    value={values?.recurrence || ''}
                    onChange={setFieldValue}
                    options={recurrences}
                    optionLabel="name"
                    placeholder="Recurrence"
                    optionValue="name"
                    name="recurrence"
                    disabled={isView}
                  />
                  <label htmlFor="quote-recurrence">Recurrence</label>
                </span>
                <div style={{ color: 'red' }} className="px-1 errorStyle">
                  {errors?.recurrence || null}
                </div>
              </div>
              <div className="quote-lead-by align-items-center justify-content-center w-3">
                <span className="p-float-label p-1">
                  <PFInputText
                    id="leadBy"
                    name="leadBy"
                    type="text"
                    value={values?.leadBy || ''}
                    onChange={handleChange}
                    disabled={isView}
                  />

                  <label htmlFor="leadBy">Lead By</label>
                </span>
                <div style={{ color: 'red' }} className="px-1 errorStyle">
                  {errors?.leadBy || null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
}
