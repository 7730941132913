import React, { useState, useEffect, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Card } from 'primereact/card';
import { Dialog } from 'primereact/dialog';
import { Message } from 'primereact/message';
import { Toast } from 'primereact/toast';
import { TabView, TabPanel } from 'primereact/tabview';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import PFInputText from '../../../shared/PFPrime/PFInputText';
import PFButton from '../../../shared/PFPrime/PFButton';
import PFPickList from '../../../shared/PFPrime/PFPickList';
import apiService from '../../../../services/api.service';
import PageHeader from '../../../shared/PageHeader/PageHeader';
import PFRadioButton from '../../../shared/PFPrime/PFRadioButton';
import PFDialog from '../../../shared/PFPrime/PFDialog';
import { COVERAGE_BY, formAction } from '../../../../constants';

import {
  INDEX_TO_TAB_MAPPING,
  categoryValidateType,
  sortByCategory,
  updateIsArchival,
  updateIsArchivalWhenRotation,
  updateIsArchivalWhenTypeIdChanges,
  updateIsArchivalWhenUserServiceTime,
} from './Helper';

const AddEditGroupSource = () => {
  const { id } = useParams();
  let action = window.location.pathname.split('/')[4];
  const toast = useRef(null);
  const history = useHistory();
  const [source, setSource] = useState([]);
  const [target, setTarget] = useState([]);

  const [initialValues, setInitialValues] = React.useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategoriesIds, setSelectedCatgoriesIds] = useState([]);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [tabsData, setTabsData] = useState([]);
  const [storeList, setStoreList] = useState([]);
  const [revertCategory, setRevertCategory] = useState(false);
  const [revertCategoryTemp, setRevertCategoryTemp] = useState([]);
  const [validationLoading, setValidationLoading] = useState(false);
  const [migrationLoader, setMigrationLoader] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [revertLoading, setRevertLoading] = useState(false);
  const [visitedPages, setVisitedPages] = useState({
    userAccessCoverageRes: false,
    userJobCoverageRes: false,
    clientServiceTimeRes: false,
    userRotationPercentRes: false,
    userServiceTimeRes: false,
  });

  const [totalRecords, setTotalRecords] = useState({
    userAccessCoverageRes: 0,
    userJobCoverageRes: 0,
    clientServiceTimeRes: 0,
    userRotationPercentRes: 0,
    userServiceTimeRes: 0,
  });
  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 1,
  });

  const accessBreadcrumb = [
    {
      text: 'Admin',
    },
    {
      link: '/admin/configuration',
      text: 'Company Configurations',
    },
    {
      link: '/admin/configuration/group-source-category',
      text: 'Group Source Category',
    },

    {
      text: `Category Configurations`,
    },
  ];

  const addParentCategory = async value => {
    try {
      const response = await apiService.post(
        `/system/parent-category/group-create`,
        value
      );
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  const getCategoryList = async () => {
    try {
      const response = await apiService.get(
        `/system/parent-category/category-list`
      );
      if (response && response?.data?.length) {
        const responseData = sortByCategory(response?.data, 'category');
        setCategoryList(responseData);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getGroupById = async () => {
    try {
      const response = await apiService.get(`/system/parent-category/${id}`);
      if (response && response?.status) {
        setInitialValues(response?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getCoveragePreference = async (parentId, payload) => {
    try {
      const response = await apiService.post(
        `/auth/user/manage/validate/${parentId}`,
        payload
      );
      return response;
    } catch (error) {
      console.error(error);
    }
  };
  const createCategory = async payload => {
    try {
      const response = await apiService.post(
        `/system/parent-category/create`,
        payload
      );
      return response;
    } catch (error) {
      console.error(error);
    }
  };
  const revertSubCategory = async () => {
    if (revertCategoryTemp.length) {
      try {
        setRevertLoading(true);
        const response = await apiService.put(
          `/system/parent-category/update/${id}`,
          {
            parent_id: id,
            categoryIds: revertCategoryTemp.map(
              record => record.project_category_id
            ),
          }
        );
        if (response && response?.status) {
          toast.current?.show({
            severity: 'success',
            summary: `Category Updated Successfully`,
          });
          handleRevertCategory();
        } else {
          toast.current?.show({
            severity: 'error',
            summary: `Unable to update Category`,
          });
        }
        return response;
      } catch (error) {
        console.error(error);
      } finally {
        setRevertLoading(false);
      }
    }
  };
  const getStore = async () => {
    try {
      const response = await apiService.get(`/stores`);
      setStoreList(response);
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getStore();

    getCategoryList();
  }, []);
  useEffect(() => {
    if (id) {
      getGroupById();
    }
  }, [id]);
  const formik = useFormik({
    initialValues: {
      group_name: null,
      sub_category: null,
    },
    validationSchema: Yup.object({
      group_name: Yup.string().required('Required'),
    }),
    onSubmit: async values => {
      let response;
      if (action === formAction.ADD) {
        let addObj = {
          category: values?.group_name,
          is_parent_category: 1,
        };

        response = await addParentCategory(addObj);
        if (response && response?.status) {
          toast.current?.show({
            severity: 'success',
            summary: 'Group created successfully',
          });

          setTimeout(() => {
            history.push(
              `/admin/configuration/group-source-category/edit/${response?.data?.project_category_id}`
            );
          }, 3000);
        } else {
          toast.current?.show({
            severity: 'error',
            summary: response?.message
              ? `${response?.message}`
              : 'Unable to create group.',
          });
        }
      }
    },
  });
  useEffect(() => {
    if (initialValues && initialValues?.length > 0) {
      formik.setValues(prevValues => ({
        ...prevValues,
        group_name: initialValues[0]?.category,
      }));
      if (initialValues[0]) {
        setTarget(initialValues[0]?.parentCategory);
      }
    }
  }, [initialValues]);
  useEffect(() => {
    if (categoryList && categoryList?.length > 0) {
      setSource(categoryList);
    }
  }, [categoryList]);

  const handleMoveToTarget = event => {
    const updatedSource = source.filter(record => {
      const foundResult = event.value.find(
        innerRecord =>
          innerRecord.project_category_id == record.project_category_id
      );
      if (!foundResult) {
        return true;
      }
    });
    setSource(updatedSource);
    setTarget([...event.value, ...target]);
  };

  const handleMoveToSource = async event => {
    if (event?.value) {
      setRevertCategory(true);
      setRevertCategoryTemp(event?.value);
    }
  };
  const handleRevertCategory = () => {
    if (revertCategoryTemp.length) {
      const updatedSource = target.filter(record => {
        const foundResult = revertCategoryTemp.find(
          innerRecord =>
            innerRecord.project_category_id == record.project_category_id
        );
        if (!foundResult) {
          return true;
        }
      });
      setSource([...revertCategoryTemp, ...source]);
      setTarget(updatedSource);
      setRevertCategoryTemp([]);
      setRevertCategory(false);
    }
  };
  const handleDailogSave = async () => {
    setMigrationLoader(true);
    try {
      let payload = {
        parent_id: id,
        categoryIds: selectedCategoriesIds,
        userAccessCoverageRes: tabsData?.userAccessCoverageRes,
        userJobCoverageRes: tabsData?.userJobCoverageRes,
        clientServiceTimeRes: tabsData?.clientServiceTimeRes,
        userRotationPercentRes: tabsData?.userRotationPercentRes,
        userServiceTimeRes: tabsData?.userServiceTimeRes,
      };
      const response = await createCategory(payload);
      if (response && response?.status) {
        toast.current?.show({
          severity: 'success',
          summary: 'Record updated Successfully',
        });
        setDialogVisible(false);
        setTimeout(() => {
          history.push(`/admin/configuration/group-source-category`);
        }, 3000);
        setActiveIndex(0);
        setlazyState({
          first: 0,
          rows: 10,
          page: 1,
        });
      } else {
        toast.current?.show({
          severity: 'error',
          summary: 'Unable to update record',
        });
      }
    } catch (error) {
      console.log('Error in migration : ', error);
    } finally {
      setMigrationLoader(false);
    }
  };
  const handleCheck = (rowData, key, status) => {
    if (key == categoryValidateType.userAccessCoverageRes) {
      setTabsData(prevState => {
        prevState[key]?.forEach(record => {
          if (record?.user_id === rowData?.user_id) {
            if (record?.project_type_id === rowData?.project_type_id) {
              record.is_archival = 1;
            }
          }
        });
        return {
          ...prevState,
          [key]: prevState[key].map(item => {
            return item?.user_coverage_preference_id ===
              rowData?.user_coverage_preference_id
              ? { ...item, is_archival: status ? 0 : 1 }
              : item;
          }),
        };
      });
    } else if (key == categoryValidateType.userJobCoverageRes) {
      setTabsData(prevState => {
        prevState[key]?.forEach(record => {
          if (record?.user_id === rowData?.user_id) {
            if (record?.project_type_id === rowData?.project_type_id) {
              record.is_archival = 1;
            }
          }
        });
        return {
          ...prevState,
          [key]: prevState[key].map(item => {
            return item?.user_job_coverage_id === rowData?.user_job_coverage_id
              ? { ...item, is_archival: status ? 0 : 1 }
              : item;
          }),
        };
      });
    } else if (key == categoryValidateType.clientServiceTimeRes) {
      setTabsData(prevState => {
        prevState[key]?.forEach(record => {
          if (record?.type_id === rowData?.type_id) {
            record.is_archival = 1;
          }
        });
        return {
          ...prevState,
          [key]: prevState[key]?.map(item =>
            item?.config_id === rowData?.config_id
              ? { ...item, is_archival: status ? 0 : 1 }
              : item
          ),
        };
      });
    } else if (key == categoryValidateType.userRotationPercentRes) {
      setTabsData(prevState => {
        prevState[key]?.forEach(record => {
          if (record?.user_id === rowData?.user_id) {
            if (record?.project_type_id === rowData?.project_type_id) {
              record.is_archival = 1;
            }
          }
        });
        return {
          ...prevState,
          [key]: prevState[key].map(item => {
            return item?.user_job_coverage_id === rowData?.user_job_coverage_id
              ? { ...item, is_archival: status ? 0 : 1 }
              : item;
          }),
        };
      });
    } else if (key == categoryValidateType.userServiceTimeRes) {
      setTabsData(prevState => {
        prevState[key]?.forEach(record => {
          if (record?.user_id === rowData?.user_id) {
            if (record?.project_type_id === rowData?.project_type_id) {
              record.is_archival = 1;
            }
          }
        });
        return {
          ...prevState,
          [key]: prevState[key].map(item => {
            return item?.user_job_coverage_id === rowData?.user_job_coverage_id
              ? { ...item, is_archival: status ? 0 : 1 }
              : item;
          }),
        };
      });
    }
  };

  const radioTemplate = (rowData, key) => {
    return (
      <>
        <span className="text-xm">
          <PFRadioButton
            checked={rowData?.is_archival == 0}
            name={`${rowData.user_id}-${rowData.project_type_id}`}
            value={true}
            onChange={(name, value) => {
              handleCheck(rowData, key, value);
            }}
          />
        </span>
      </>
    );
  };

  const durationTemplate = (rowData, key) => {
    if (key == categoryValidateType.userServiceTimeRes) {
      return (
        <span>
          {' '}
          {rowData?.service_time
            ? `${rowData?.service_time} minutes`
            : `0.00 minutes`}
        </span>
      );
    } else {
      return (
        <span>
          {rowData?.duration_value
            ? `${rowData?.duration_value} minutes`
            : `0.00 minutes`}
        </span>
      );
    }
  };

  const CoverageTypeTemplate = rowData => {
    return (
      <span>
        {rowData?.coverage_by === COVERAGE_BY.ZIPCODE ? (
          <span>{'Zipcode'}</span>
        ) : rowData?.coverage_by === COVERAGE_BY.STORE ? (
          <span>{'Store'}</span>
        ) : rowData?.coverage_by === COVERAGE_BY.DISTRICT ? (
          <span>{'District'}</span>
        ) : null}
      </span>
    );
  };
  const CoveragValueTemplate = rowData => {
    const storeIds = storeList.map(item => item.store_id);

    if (rowData?.coverage_by === COVERAGE_BY.STORE) {
      const storeDetails = storeList
        .filter(item =>
          rowData?.coverage_values?.includes(item.store_id.toString())
        )
        .map(store => `${store.store_number} - ${store.store_name}`);
      return (
        <span>
          {storeIds
            ? storeDetails.join(', ')
            : rowData.coverage_values.join(', ')}
        </span>
      );
    } else {
      return <spam>{rowData.coverage_values.join(', ')}</spam>;
    }
  };

  const onPage = (event, key) => {
    setVisitedPages(previousData => {
      return {
        ...previousData,
        [key]: event.first + event.rows >= totalRecords[key],
      };
    });
    setlazyState(event);
  };

  const renderDataTable = (key, data) => {
    return (
      <DataTable
        value={data || []}
        rowGroupMode="subheader"
        groupRowsBy={
          key != categoryValidateType.clientServiceTimeRes
            ? 'user_id'
            : `project_type`
        }
        sortMode="single"
        sortField={
          key != categoryValidateType.clientServiceTimeRes
            ? 'user_id'
            : `project_type`
        }
        sortOrder={1}
        scrollable
        rowGroupHeaderTemplate={rowData => {
          return groupHeaderTemplate(rowData, key);
        }}
        tableStyle={{ minWidth: '50rem' }}
        className="mt-2"
        paginator
        rows={10}
        currentPageReportTemplate="{first} to {last} of {totalRecords}"
        paginatorTemplate="RowsPerPageDropdown  PrevPageLink CurrentPageReport NextPageLink "
        first={lazyState.first}
        totalRecords={totalRecords[key]}
        onPage={event => onPage(event, key)}
      >
        <Column
          header=""
          className="min-w-10rem"
          body={rowData => {
            return radioTemplate(rowData, key);
          }}
        ></Column>
        {key !== categoryValidateType.clientServiceTimeRes ? (
          <Column
            field="project_type"
            header="Type"
            className="min-w-5rem"
          ></Column>
        ) : null}
        <Column
          field="category"
          header="Category"
          className="min-w-5rem"
          body={rowData => {
            return (
              <div className="flex gap-2">
                {rowData.category}
                <i className="pi pi-arrow-right"></i>
                {formik?.values?.group_name}
              </div>
            );
          }}
        ></Column>

        {key == categoryValidateType.userAccessCoverageRes ||
        key == categoryValidateType.userJobCoverageRes ? (
          <Column
            header="Coverage Type"
            body={CoverageTypeTemplate}
            className="min-w-5rem"
          ></Column>
        ) : null}

        {key == categoryValidateType.userAccessCoverageRes ||
        key == categoryValidateType.userJobCoverageRes ? (
          <Column
            field="coverage_values"
            header="Coverage Value"
            body={CoveragValueTemplate}
            className="min-w-10rem"
          ></Column>
        ) : null}
        {key == categoryValidateType.clientServiceTimeRes ||
        key == categoryValidateType.userServiceTimeRes ? (
          <Column
            field="duration_value"
            header="Duaration"
            className="min-w-10rem"
            body={rowData => {
              return durationTemplate(rowData, key);
            }}
          ></Column>
        ) : null}
        {key == categoryValidateType.userRotationPercentRes ? (
          <Column
            field="rotation_percentage"
            header="Rotation %"
            className="min-w-10rem"
            body={rowData => {
              return rowData?.rotation_percentage
                ? `${rowData?.rotation_percentage}%`
                : '0.00%';
            }}
          ></Column>
        ) : null}
      </DataTable>
    );
  };
  const groupHeaderTemplate = (rowData, key) => {
    return (
      <React.Fragment>
        {key == categoryValidateType.clientServiceTimeRes ? (
          <span className="font-bold">{rowData?.project_type}</span>
        ) : (
          <span className="font-bold">{`${rowData?.first_name} ${rowData?.last_name}`}</span>
        )}
      </React.Fragment>
    );
  };

  const onValidateHandler = async () => {
    if (target.length) {
      setValidationLoading(true);
      try {
        const selectedCategoriesId = target.map(
          item => item.project_category_id
        );
        const response = await getCoveragePreference(id, {
          categoryIds: selectedCategoriesId,
        });
        if (response && response?.status) {
          setTabsData({
            userAccessCoverageRes: updateIsArchival(
              response?.data?.userAccessCoverageRes
            ),
            userJobCoverageRes: updateIsArchival(
              response?.data?.userJobCoverageRes
            ),
            clientServiceTimeRes: updateIsArchivalWhenTypeIdChanges(
              response?.data?.clientServiceTimeRes
            ),
            userRotationPercentRes: updateIsArchivalWhenRotation(
              response?.data?.userRotationPercentRes
            ),
            userServiceTimeRes: updateIsArchivalWhenUserServiceTime(
              response?.data?.userServiceTimeRes
            ),
          });

          setTotalRecords({
            userAccessCoverageRes:
              response?.data?.userAccessCoverageRes?.length,
            userJobCoverageRes: response?.data?.userJobCoverageRes?.length,
            clientServiceTimeRes: response?.data?.clientServiceTimeRes?.length,
            userRotationPercentRes:
              response?.data?.userRotationPercentRes?.length,
            userServiceTimeRes: response?.data?.userServiceTimeRes?.length,
          });
          setVisitedPages({
            userAccessCoverageRes:
              response?.data?.userAccessCoverageRes?.length <= 10
                ? true
                : false,
            userJobCoverageRes:
              response?.data?.userJobCoverageRes?.length <= 10 ? true : false,
            clientServiceTimeRes:
              response?.data?.clientServiceTimeRes?.length <= 10 ? true : false,
            userRotationPercentRes:
              response?.data?.userRotationPercentRes?.length <= 10
                ? true
                : false,
            userServiceTimeRes:
              response?.data?.userServiceTimeRes?.length <= 10 ? true : false,
          });
          setDialogVisible(true);
          setSelectedCatgoriesIds(selectedCategoriesId);
        }
      } catch (error) {
        console.error('Error in fetching validation details : ', error);
      } finally {
        setValidationLoading(false);
      }
    } else {
      toast.current?.show({
        severity: 'error',
        summary: `Please select at least one category.`,
      });
    }
  };

  return (
    <div className="w-full">
      <PageHeader
        pageTitle={`Group Source Category`}
        breadCrumbArray={accessBreadcrumb}
      />
      <Card className="mt-4" pt={{ body: { className: 'pt-0' } }}>
        <Toast ref={toast}></Toast>
        <form onSubmit={formik.handleSubmit}>
          <div className="col-12 flex flex-wrap">
            <div className="col-12 md:col-8 lg:col-8 md:ml-8 lg:ml-8 md:mr-2 lg:mr-2 ">
              <span className="p-float-label">
                <PFInputText
                  id="group_name"
                  name="group_name"
                  value={formik.values.group_name || ''}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={`w-full ${
                    formik.touched?.group_name && formik.errors?.group_name
                      ? 'p-invalid'
                      : ''
                  }`}
                  maxLength={200}
                  disabled={action === formAction.EDIT}
                />
                <label htmlFor="group_name">Group Name</label>
              </span>
            </div>

            <div className="col-12 md:col-2 lg:col-2">
              {action !== formAction.EDIT ? (
                <PFButton
                  type="submit"
                  className="w-full"
                  label="Save"
                  disabled={!formik.isValid}
                ></PFButton>
              ) : (
                <PFButton
                  icon={`${validationLoading ? 'pi pi-spin pi-spinner' : undefined}`}
                  className="w-full"
                  label="Validate"
                  onClick={onValidateHandler}
                  disabled={validationLoading}
                ></PFButton>
              )}
            </div>
            <div className="col-12 md:col-2 lg:col-2"></div>
          </div>
          {action === formAction.EDIT ? (
            <>
              <div className="col-12">
                <PFPickList
                  source={sortByCategory(source, 'category')}
                  target={sortByCategory(target, 'category')}
                  itemTemplate={item => <div>{item?.category}</div>}
                  sourceHeader="Source"
                  targetHeader="Target"
                  responsive={true}
                  sourceStyle={{ height: '23rem' }}
                  targetStyle={{ height: '23rem' }}
                  filter
                  filterBy="category"
                  dataKey="project_category_id"
                  sourceFilterPlaceholder="Search"
                  targetFilterPlaceholder="Search"
                  onMoveToTarget={handleMoveToTarget}
                  onMoveAllToTarget={handleMoveToTarget}
                  onMoveToSource={handleMoveToSource}
                  onMoveAllToSource={handleMoveToSource}
                />
                <Dialog
                  header="Conflict Coverage Type"
                  visible={dialogVisible}
                  onHide={() => {
                    setDialogVisible(false);
                    setTarget(target);
                    setActiveIndex(0);
                    setlazyState({
                      first: 0,
                      rows: 10,
                      page: 1,
                    });
                  }}
                  className="w-7"
                >
                  <Message
                    className="border-none  justify-content-start text-xs"
                    severity="info"
                    text="Kindly take a look at the revised mappings for the grouped source categories. Please select which option you would want to choose in the case of a conflict."
                  />

                  <div className="card mt-2">
                    <TabView
                      activeIndex={activeIndex}
                      onTabChange={() => setActiveIndex(activeIndex)}
                      pt={{
                        content: {
                          className: 'overflow-auto',
                        },
                      }}
                    >
                      <TabPanel
                        header="User Coverage"
                        rightIcon={
                          activeIndex > 0
                            ? 'pi pi-check-circle ml-2 text-green-500'
                            : null
                        }
                        pt={{
                          content: {
                            className: 'overflow-auto',
                            style: { height: '300px' },
                          },
                        }}
                      >
                        {renderDataTable(
                          categoryValidateType.userAccessCoverageRes,
                          tabsData?.userAccessCoverageRes
                        )}
                      </TabPanel>
                      <TabPanel
                        header="Job Coverage"
                        rightIcon={
                          activeIndex > 1
                            ? 'pi pi-check-circle ml-2 text-green-500'
                            : null
                        }
                        pt={{
                          content: {
                            className: 'overflow-auto',
                            style: { height: '300px' },
                          },
                        }}
                      >
                        {renderDataTable(
                          categoryValidateType.userJobCoverageRes,
                          tabsData?.userJobCoverageRes
                        )}
                      </TabPanel>

                      <TabPanel
                        header="Rotation %"
                        rightIcon={
                          activeIndex > 2
                            ? 'pi pi-check-circle ml-2 text-green-500'
                            : null
                        }
                        pt={{
                          content: {
                            className: 'overflow-auto',
                            style: { height: '300px' },
                          },
                        }}
                      >
                        {renderDataTable(
                          categoryValidateType.userRotationPercentRes,
                          tabsData?.userRotationPercentRes
                        )}
                      </TabPanel>
                      <TabPanel
                        header="User Service Time"
                        rightIcon={
                          activeIndex > 3
                            ? 'pi pi-check-circle ml-2 text-green-500'
                            : null
                        }
                        pt={{
                          content: {
                            className: 'overflow-auto',
                            style: { height: '300px' },
                          },
                        }}
                      >
                        {renderDataTable(
                          categoryValidateType.userServiceTimeRes,
                          tabsData?.userServiceTimeRes
                        )}
                      </TabPanel>

                      <TabPanel
                        header="Service Time"
                        rightIcon={
                          activeIndex > 4
                            ? 'pi pi-check-circle ml-2 text-green-500'
                            : null
                        }
                        pt={{
                          content: {
                            className: 'overflow-auto',
                            style: { height: '300px' },
                          },
                        }}
                      >
                        {renderDataTable(
                          categoryValidateType.clientServiceTimeRes,
                          tabsData?.clientServiceTimeRes
                        )}
                      </TabPanel>
                    </TabView>
                  </div>
                  <div className="col-12 md:col-12 px-0 text-right">
                    {activeIndex > 0 ? (
                      <PFButton
                        label="Previous"
                        className="w-12 md:w-2 lg:w-2 ml-2"
                        onClick={() => {
                          setActiveIndex(activeIndex - 1);
                        }}
                        icon={`${validationLoading ? 'pi pi-spin pi-spinner' : undefined}`}
                      />
                    ) : (
                      <></>
                    )}

                    {activeIndex < 4 ? (
                      <PFButton
                        label="Next"
                        className="w-12 md:w-2 lg:w-2 ml-2"
                        onClick={() => {
                          setActiveIndex(activeIndex + 1);
                          setlazyState({
                            first: 0,
                            rows: 10,
                            page: 1,
                          });
                        }}
                        icon={`${validationLoading ? 'pi pi-spin pi-spinner' : undefined}`}
                        disabled={
                          !visitedPages[INDEX_TO_TAB_MAPPING[activeIndex]]
                        }
                      />
                    ) : (
                      <PFButton
                        label="Migrate"
                        className="w-12 md:w-2 lg:w-2 ml-2"
                        onClick={() => {
                          handleDailogSave();
                        }}
                        icon={`${validationLoading ? 'pi pi-spin pi-spinner' : undefined}`}
                        disabled={
                          !visitedPages[INDEX_TO_TAB_MAPPING[activeIndex]] ||
                          migrationLoader
                        }
                      />
                    )}
                  </div>
                </Dialog>
              </div>
              <PFDialog
                header="Confirmation"
                show={revertCategory}
                style={{ width: '450px' }}
                hide={() => {
                  setRevertCategory(false);
                  setRevertCategoryTemp([]);
                }}
                footer={
                  <div>
                    <PFButton
                      label="No"
                      text
                      outlined
                      onClick={() => {
                        setRevertCategory(false);
                        setRevertCategoryTemp([]);
                      }}
                    />
                    <PFButton
                      label="Yes"
                      icon={`${revertLoading ? 'pi pi-spin pi-spinner' : undefined}`}
                      onClick={revertSubCategory}
                      disabled={revertLoading}
                    />
                  </div>
                }
                draggable={false}
                BodyComponent={
                  <p className="m-0">
                    Reverting Category will overwrite the existing coverages. Do
                    you want to proceed?
                  </p>
                }
              ></PFDialog>
            </>
          ) : null}
        </form>
      </Card>
    </div>
  );
};
export default AddEditGroupSource;
