import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons/';

import { useStyles } from './GenericDialog.styles';

const GenericDialog = ({
  isOpen,
  handleClose,
  handleSave,
  children,
  button2Text,
  dialogSettings,
  disabledButton1,
  disabledButton2,
  dialogWidth,
}) => {
  const classes = useStyles({ scrollHidden: dialogSettings?.isScrollHidden });

  return (
    <>
      <Dialog
        fullWidth
        maxWidth={dialogWidth ? dialogWidth : 'xs'}
        open={isOpen}
        aria-labelledby="form-dialog-title"
        className={classes.dialog}
        classes={{ paperScrollPaper: classes.scrollPaper }}
      >
        <DialogTitle
          id="form-dialog-title"
          className={classes.dialogTitle}
          disableTypography
        >
          <Typography variant="h3" className={classes.dialogHeader}>
            {dialogSettings.title}
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </DialogTitle>
        <Divider className={classes.divider} />
        <DialogContent>{children}</DialogContent>
        <Divider className={classes.divider} />
        <DialogActions className={classes.dialogFooter}>
          {dialogSettings.showButton1 && (
            <Button
              color="primary"
              variant="outlined"
              onClick={handleClose}
              disabled={disabledButton1}
            >
              {(dialogSettings.button1Text && dialogSettings.button1Text) ||
                'Cancel'}
            </Button>
          )}
          {dialogSettings.showButton2 && (
            <Button
              color="primary"
              variant="contained"
              disabled={disabledButton2}
              onClick={handleSave}
            >
              {button2Text
                ? button2Text
                : dialogSettings.button2Text
                  ? dialogSettings.button2Text
                  : 'Save'}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GenericDialog;
