import { ConfirmDialog } from 'primereact/confirmdialog';
import type { ConfirmDialogProps } from 'primereact/confirmdialog/confirmdialog';
import { FC, ReactNode, createContext, useContext, useState } from 'react';

interface ConfirmDialogOptions {
  message?: ReactNode;
  header?: ReactNode;
  icon?: string;
  accept?: () => void;
  reject?: () => void;
  position?:
    | 'center'
    | 'top'
    | 'bottom'
    | 'left'
    | 'right'
    | 'top-left'
    | 'top-right'
    | 'bottom-left'
    | 'bottom-right';
  acceptLabel?: string;
  rejectLabel?: string;
  acceptIcon?: string;
  onHide?: () => void;
  pt?: Record<string, unknown>;
  className?: string;
  style?: Record<string, unknown>;
  breakpoints?: Record<string, string>;
  defaultFocus?: string;
  acceptClassName?: string;
  rejectClassName?: string;
}

interface ConfirmDialogContextValue {
  // eslint-disable-next-line no-unused-vars
  showConfirmationDialog: (options: ConfirmDialogOptions) => void;
}

const ConfirmDialogContext = createContext<
  ConfirmDialogContextValue | undefined
>(undefined);

interface ConfirmDialogProviderProps {
  children: ReactNode;
}

export const ConfirmDialogContextProvider: FC<ConfirmDialogProviderProps> = ({
  children,
}) => {
  const [visible, setVisible] = useState(false);
  const [options, setOptions] = useState<ConfirmDialogOptions | null>(null);

  const showConfirmationDialog = (options: ConfirmDialogOptions) => {
    setOptions(options);
    setVisible(true);
  };

  const onHide = () => {
    options?.onHide?.();
    setVisible(false);
  };

  const dialogProps: ConfirmDialogProps = {
    visible,
    ...(options || {}),
    onHide,
    position: options?.position ?? 'center',
  };

  return (
    <ConfirmDialogContext.Provider value={{ showConfirmationDialog }}>
      {options && <ConfirmDialog {...dialogProps} />}
      {children}
    </ConfirmDialogContext.Provider>
  );
};

export const useConfirmDialogContext = () => {
  const context = useContext(ConfirmDialogContext);
  if (!context) {
    throw new Error(
      'useConfirmDialogContext must be used within a ConfirmDialogContextProvider'
    );
  }
  return context;
};
