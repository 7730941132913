// **** React Imports ****
import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
// **** External Utilities ****
import {
  Grid,
  IconButton,
  CircularProgress,
  Icon,
  Box,
  Typography,
  Button,
  TextField,
  LinearProgress,
} from '@material-ui/core';
import {
  Visibility as VisibilityIcon,
  Edit as EditIcon,
} from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import moment from 'moment';

import { checkPermission } from '../../../../utils/Helpers';
import PageHeader from '../../../shared/PageHeader/PageHeader';
import GenericDataTable from '../../../shared/GenericDataTable/GenericDataTable';
import { Alert } from '../../../shared/Alerts/Alert';
import permissions from '../../../../config/permissions';
import { useAlerts } from '../../../shared/Alerts/alertsService';

import { useStyles } from './Reports.styles';
import {
  getReportResponse,
  getTableFiltersOptions,
  getDropdownDetails,
} from './Reports.service';

function handleRowHover(event, row, rowIndex) {
  let control = document.getElementById(rowIndex);
  control.style.display = 'block';
}

function handleRowHoverLeave(event, row, rowIndex) {
  let control = document.getElementById(rowIndex);
  if (control?.style?.display) control.style.display = 'none';
}

const Reports = () => {
  const params = new URLSearchParams(window.location.search);
  const [reportName, setReportName] = useState('');
  const reportId = params.get('reportId');
  const schedulingBreadcrumb = [
    {
      link: `/project/report?reportId=${reportName}`,
      text: reportName,
    },
  ];
  const [loading, setLoading] = React.useState();
  // To show the error
  const { alert, clearAlert } = useAlerts();
  const [searchQuery, setSearchQuery] = React.useState({
    limit: 10,
    offset: 0,
  });
  const history = useHistory();
  const [storeNumberOptions, setStoreNumberOptions] = React.useState([]);
  const [customerOptions, setCustomerOptions] = React.useState([]);
  const [statusOptions, setStatusOptions] = React.useState([]);
  const [sourceStatusOptions, setSourceStatusOptions] = React.useState([]);
  const [selectedFilters, setSelectedFilters] = React.useState({});
  const [installerOptions, setInstallerOptions] = React.useState([]);
  const [projectTypeOptions, setProjectTypeOptions] = React.useState([]);
  const [reportData, setReportData] = React.useState([]);
  const [storeOpen, setStoreOpen] = React.useState(false);
  const [storeLoading, setStoreLoading] = React.useState(false);
  const [customerOpen, setCustomerOpen] = React.useState(false);
  const [customerLoading, setCustomerLoading] = React.useState(false);

  React.useEffect(() => {
    clearAlert();
  }, []);
  React.useEffect(() => {
    // let report;
    let scheduledTodayFlag = undefined;
    // switch (reportName) {
    //   case 'Ready to Schedule':
    //     report = 'ready-to-schedule';
    //     break;
    //   case 'On-Hold':
    //     report = 'projects-on-hold';
    //     break;
    //   case 'Scheduled Today':
    //     report = 'projects-scheduled';
    //     scheduledTodayFlag = true;
    //     break;
    //   case 'Waiting For Product':
    //     report = 'projects-waiting-for-product';
    //     break;
    //   case 'Unable to Complete':
    //     report = 'incomplete-projects';
    //     break;
    //   case 'Scheduled Tomorrow':
    //     report = 'projects-scheduled';
    //     scheduledTodayFlag = false;
    //     break;
    //   case 'Scheduled':
    //     report = 'projects-scheduled';
    //     scheduledTodayFlag = false;
    //     break;
    //   case 'Contract Pending':
    //     report = 'projects-contract-pending';
    //     break;
    //   default:
    //     report = 'ready-to-schedule';
    // }
    getReportResponse(
      reportId,
      scheduledTodayFlag,
      searchQuery,
      selectedFilters,
      setLoading,
      setReportData,
      setReportName
    );
  }, [reportId, searchQuery, selectedFilters]);
  // To fetch the filter options
  React.useEffect(() => {
    getTableFiltersOptions(
      setStoreNumberOptions,
      setCustomerOptions,
      setStatusOptions,
      setInstallerOptions,
      setProjectTypeOptions,
      setSourceStatusOptions
    );
  }, []);
  const classes = useStyles();
  const handleFilterSubmit = applyNewFilters => {
    let appliedFiltersList = applyNewFilters();
    let selectedFilterObject = {};
    appliedFiltersList.map((data, index) => {
      if (data?.length) {
        let columnKey = dataTableColumns[index]?.name;
        let selectedFilterOptionsKey = [];
        switch (columnKey) {
          case 'storeNumber':
            selectedFilterOptionsKey = data.map(val => val.store_number);
            break;
          case 'customerId':
            selectedFilterOptionsKey = data.map(val => val.customer_id);
            break;
          case 'imsStatus':
            selectedFilterOptionsKey = data.map(val => val.status_id);
            break;
          case 'statusId':
            selectedFilterOptionsKey = data.map(val => val.status_id);
            break;
          case 'installerId':
            selectedFilterOptionsKey = data.map(val => val.installer_id);
            break;
          case 'projectTypeId':
            selectedFilterOptionsKey = data.map(val => val.project_type_id);
            break;
          default:
            selectedFilterOptionsKey = [];
        }
        selectedFilterObject = {
          ...selectedFilterObject,
          [columnKey]: selectedFilterOptionsKey,
        };
      }
    });
    setSelectedFilters(selectedFilterObject);
  };
  const fetchFilterData = async (fieldName, searchValue) => {
    if (searchValue.length > 2) {
      if (fieldName == 'customers') {
        setCustomerOpen(false);
        setCustomerLoading(true);
      } else {
        setStoreLoading(true);
        setStoreOpen(false);
      }

      const dataList = await getDropdownDetails({
        reportId,
        fieldName,
        searchValue,
      });
      if (fieldName == 'customers') {
        setCustomerOptions(dataList);
        setCustomerOpen(true);
        setCustomerLoading(false);
      } else {
        setStoreNumberOptions(dataList);
        setStoreOpen(true);
        setStoreLoading(false);
      }
    }
  };

  const dataTableColumns = [
    {
      name: '',
      options: {
        sort: false,
        filter: false,
        viewColumns: false,

        setCellProps: () => ({
          style: { minWidth: '61px', maxWidth: '85px' },
        }),
        customBodyRenderLite: dataIndex => {
          return (
            <span style={{ display: 'none' }} id={dataIndex}>
              {checkPermission(permissions.project.viewProject) && (
                <span>
                  <IconButton classes={{ root: classes.actionIcons }}>
                    <Link
                      to={`/project/view/${dataArray && dataArray[dataIndex]?.project_id}`}
                    >
                      <VisibilityIcon />
                    </Link>
                  </IconButton>
                </span>
              )}
              {checkPermission(permissions.project.editProject) && (
                <span>
                  <IconButton classes={{ root: classes.actionIcons }}>
                    <Link
                      to={`/project/edit/${dataArray && dataArray[dataIndex]?.project_id}`}
                    >
                      <EditIcon />
                    </Link>
                  </IconButton>
                </span>
              )}
            </span>
          );
        },
      },
    },
    {
      name: 'source_name',
      label: 'Source',
      options: {
        sort: false,
        filter: false,
        viewColumns: false,
        setCellProps: () => ({ style: { minWidth: '85px', maxWidth: '85px' } }),
      },
    },
    {
      name: 'date_sold',
      label: 'Date Sold',
      options: {
        filter: false,
        setCellProps: () => ({
          style: { minWidth: '106px', maxWidth: '80px' },
        }),
      },
    },
    {
      name: 'storeNumber',
      label: 'Store No.',
      options: {
        sort: false,
        filter: true,
        filterType: 'custom',
        customFilterListOptions: {
          render: v => v.map(val => `${val.store_number}-${val.store_name}`),
        },
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            return (
              <>
                <Autocomplete
                  name="store"
                  noOptionsText="Search for store"
                  open={storeOpen}
                  onClose={() => {
                    setStoreOpen(false);
                  }}
                  onOpen={() => {
                    setStoreOpen(true);
                  }}
                  multiple
                  options={storeNumberOptions || []}
                  onInputChange={event =>
                    fetchFilterData('store', event.currentTarget.value)
                  }
                  onChange={(event, value) => {
                    filterList[index] = value.map(val => val);
                    onChange(filterList[index], index, column);
                  }}
                  value={filterList[index]}
                  getOptionLabel={option =>
                    option && `${option.store_number}-${option.store_name}`
                  }
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Store"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
                {storeLoading ? (
                  <LinearProgress />
                ) : (
                  <p className={classes.hintLabel}>
                    Type at least 3 character to search
                  </p>
                )}
              </>
            );
          },
        },
        serverSide: true,
        setCellProps: () => ({
          style: { minWidth: '90px', maxWidth: '90px' },
        }),
      },
    },
    {
      name: 'installerId',
      label: 'Technician',
      options: {
        sort: false,
        setCellProps: () => ({
          style: { minWidth: '170px', maxWidth: '170px' },
        }),
        filter: true,
        filterType: 'custom',
        customFilterListOptions: {
          render: v => v.map(val => `${val?.first_name} ${val?.last_name}`),
        },
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            return (
              <Autocomplete
                name="installers"
                multiple
                options={installerOptions || []}
                onChange={(event, value) => {
                  filterList[index] = value.map(val => val);
                  onChange(filterList[index], index, column);
                }}
                value={filterList[index]}
                getOptionLabel={option =>
                  option && `${option?.first_name} ${option?.last_name}`
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Installers"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            );
          },
        },
        serverSide: true,
      },
    },
    {
      name: 'project_number',
      label: 'Project',
      options: {
        sort: true,
        filter: false,
        setCellProps: () => ({ style: { minWidth: '130px' } }),
      },
    },
    {
      name: 'customerId',
      label: 'Client Name',
      options: {
        sort: false,
        filterType: 'custom',
        customFilterListOptions: {
          render: v =>
            v.map(
              val =>
                `${val.first_name} ${val.last_name} ${
                  val.client_primary_phone ? '-' + val.client_primary_phone : ''
                }`
            ),
        },
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            return (
              <>
                <Autocomplete
                  name="client_name"
                  noOptionsText="Search for client name"
                  multiple
                  open={customerOpen}
                  onOpen={() => {
                    setCustomerOpen(true);
                  }}
                  onClose={() => {
                    setCustomerOpen(false);
                  }}
                  options={customerOptions || []}
                  onInputChange={event =>
                    fetchFilterData('customers', event.currentTarget.value)
                  }
                  onChange={(event, value) => {
                    filterList[index] = value.map(val => val);
                    onChange(filterList[index], index, column);
                  }}
                  value={filterList[index]}
                  getOptionLabel={option =>
                    option &&
                    ` ${option.first_name} ${option.last_name} - ${
                      option.primary_phone ? option.primary_phone : ''
                    }`
                  }
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Client Name"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
                {customerLoading ? (
                  <LinearProgress />
                ) : (
                  <p className={classes.hintLabel}>
                    Type at least 3 character to search
                  </p>
                )}
              </>
            );
          },
        },
        setCellProps: () => ({
          style: { minWidth: '140px', maxWidth: '80px' },
        }),
      },
    },
    {
      name: 'projectTypeId',
      label: 'Type',
      options: {
        sort: false,
        filterType: 'custom',
        customFilterListOptions: {
          render: v => v.map(val => val?.project_type),
        },
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            return (
              <Autocomplete
                name="project_type"
                multiple
                options={projectTypeOptions || []}
                onChange={(event, value) => {
                  filterList[index] = value.map(val => val);
                  onChange(filterList[index], index, column);
                }}
                value={filterList[index]}
                getOptionLabel={option => option && option.project_type}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Project Type"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            );
          },
        },
        setCellProps: () => ({
          style: { minWidth: '107px', maxWidth: '110px' },
        }),
      },
    },
    {
      name: 'imsStatus',
      label: 'Source Status',
      options: {
        sort: false,
        filterType: 'custom',
        customFilterListOptions: {
          render: v => v.map(val => val?.status),
        },
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            return (
              <Autocomplete
                name="IMS_status"
                multiple
                options={sourceStatusOptions || []}
                onChange={(event, value) => {
                  filterList[index] = value.map(val => val);
                  onChange(filterList[index], index, column);
                }}
                value={filterList[index]}
                getOptionLabel={option => option && option?.status}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Source Status"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            );
          },
        },
        setCellProps: () => ({
          style: { minWidth: '107px', maxWidth: '110px' },
        }),
      },
    },
    {
      name: 'statusId',
      label: 'Status',
      options: {
        sort: false,

        filterType: 'custom',
        customFilterListOptions: {
          render: v => v.map(val => val?.status),
        },
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            return (
              <Autocomplete
                name="status"
                multiple
                options={statusOptions || []}
                onChange={(event, value) => {
                  filterList[index] = value.map(val => val);
                  onChange(filterList[index], index, column);
                }}
                value={filterList[index]}
                getOptionLabel={option => option && option?.status}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Status"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            );
          },
        },
        setCellProps: () => ({
          style: { minWidth: '107px', maxWidth: '110px' },
        }),
      },
    },
    {
      name: 'current_activity',
      label: 'Activity Type',
      options: {
        sort: false,
        filter: false,
        setCellProps: () => ({
          style: { minWidth: '140px', maxWidth: '80px' },
        }),
      },
    },
    {
      name: 'current_activity_due_date',
      label: 'Activity Due Date',
      options: {
        sort: false,
        filter: false,
        setCellProps: () => ({
          style: { minWidth: '160px', maxWidth: '80px' },
        }),
      },
    },
  ];

  let dataArray = reportData?.items?.map(val => {
    return {
      source_name: val.logo_url ? (
        <Icon fontSize="large" component="span">
          <img width="40" height="30" alt="ProjectForce" src={val.logo_url} />
        </Icon>
      ) : (
        <Box className={classes.sourceName}>
          <Typography variant="subtitle2">{val.source_name}</Typography>
        </Box>
      ),
      project_description: val.category,
      date_sold: val.date_sold
        ? moment(val.date_sold).format('MM-DD-YYYY')
        : null,
      storeNumber: val.store_number,
      installerId: val.Installer,
      project_number: val.project_number,
      customerId: (
        <Typography variant="subtitle2" color="primary">
          {val.client_name}
        </Typography>
      ),
      projectTypeId: val.project_type,
      statusId: (
        <Typography
          variant="subtitle2"
          className={
            (val.project_status === 'Completed' &&
              classes.statusColorCompleted) ||
            (val.project_status === 'Completed' &&
              classes.statusColorCancelled) ||
            classes.statusColor
          }
        >
          {val.project_status}
        </Typography>
      ),
      imsStatus: (
        <Typography
          variant="subtitle2"
          className={
            (val.IMS_status === 'Completed' && classes.statusColorCompleted) ||
            (val.IMS_status === 'Completed' && classes.statusColorCancelled) ||
            classes.statusColor
          }
        >
          {val.IMS_status}
        </Typography>
      ),
      project_id: val.project_id,
      current_activity: val.current_activity,
      current_activity_due_date:
        val.current_activity_due_date &&
        moment(val.current_activity_due_date).format('MM-DD-YYYY hh:mm A'),
    };
  });
  let intervalVal = '';
  const handleSearch = searchInputVal => {
    const searchString = searchInputVal?.trim();

    /** Timeout will help to let multiple characters in the TextInput,* and API call would be optimized to get Values for Input of few chars at once,* rather than for every single character*/
    try {
      clearTimeout(intervalVal);
    } catch (err) {
      console.log(err);
    }
    intervalVal = setTimeout(() => {
      setLoading(true);
      setSearchQuery(prevState => ({
        ...prevState,
        offset: 0,
        searchQuery: searchString,
      }));
    }, 500);
  };
  const viewPermission = checkPermission(permissions?.project?.menu);
  const dataTableOptions = {
    download: false,
    print: false,
    selectableRows: false,
    responsive: 'standard',
    filterType: 'multiselect',
    tableBodyMinHeight: '300px',
    count: reportData?.count,
    serverSide: true,
    textLabels: {
      body: {
        noMatch: !loading && !dataArray?.length && 'No records found',
      },
    },
    onChangeRowsPerPage: numberOfRows => {
      setLoading(true);
      setSearchQuery(prevState => ({
        ...prevState,
        limit: numberOfRows,
        offset: 0,
      }));
    },
    onChangePage: currentPage => {
      setLoading(true);
      setSearchQuery(prevState => ({
        ...prevState,
        offset: currentPage * searchQuery.limit,
      }));
    },
    onColumnSortChange: (changedColumn, direction) => {
      setLoading(true);
      setSearchQuery(prevState => ({
        ...prevState,
        sortKey: changedColumn,
        sortDirection: direction,
      }));
    },

    onSearchChange: searchText => {
      handleSearch(searchText);
    },

    rowsPerPage: searchQuery.limit,
    rowsPerPageOptions: [10, 20, 50, 100],
    setRowProps: (row, dataIndex, rowIndex) => {
      return {
        onMouseEnter: e => handleRowHover(e, row, rowIndex),
        onMouseLeave: e => handleRowHoverLeave(e, row, rowIndex),
        ...(viewPermission
          ? {
              onDoubleClick: () => {
                history.push(
                  `/project/view/${dataArray ? dataArray[dataIndex].project_id : ''}`
                );
              },
            }
          : {}),
      };
    },
    confirmFilters: true,

    // Calling the applyNewFilters parameter applies the selected filters to the table
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
      return (
        <div style={{ marginTop: '40px' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleFilterSubmit(applyNewFilters)}
          >
            Apply Filters
          </Button>
        </div>
      );
    },

    onFilterChange: (column, filterList, type, changedColumnIndex) => {
      let selectedFilterOptionsKey = [];
      switch (column) {
        case 'storeNumber':
          selectedFilterOptionsKey = filterList[changedColumnIndex].map(
            val => val.store_number
          );
          break;
        case 'customerId':
          selectedFilterOptionsKey = filterList[changedColumnIndex].map(
            val => val.customer_id
          );
          break;
        case 'imsStatus':
          selectedFilterOptionsKey = filterList[changedColumnIndex].map(
            val => val.status_id
          );
          break;
        case 'statusId':
          selectedFilterOptionsKey = filterList[changedColumnIndex].map(
            val => val.status_id
          );
          break;
        case 'installerId':
          selectedFilterOptionsKey = filterList[changedColumnIndex].map(
            val => val.installer_id
          );
          break;
        case 'projectTypeId':
          selectedFilterOptionsKey = filterList[changedColumnIndex].map(
            val => val.project_type_id
          );
          break;
        default:
          selectedFilterOptionsKey = [];
      }
      setSelectedFilters(prevState => {
        return { ...prevState, [column]: selectedFilterOptionsKey };
      });
    },
  };

  return (
    <>
      <Grid container spacing={2} direction="column">
        {alert.exists && (
          <Grid item>
            {' '}
            <Alert />
          </Grid>
        )}
        <Grid container item direction="row" alignItems="center">
          <Grid item xs={6}>
            <PageHeader
              pageTitle="Scheduling"
              breadCrumbArray={schedulingBreadcrumb}
            />
          </Grid>
          <Grid item xs={6}>
            <Grid container justifyContent="flex-start">
              {loading && <CircularProgress />}
            </Grid>
          </Grid>
        </Grid>
        <Grid item classes={{ root: classes.dataTableWrapper }}>
          <GenericDataTable
            title={reportName}
            columns={dataTableColumns}
            data={dataArray}
            options={dataTableOptions}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Reports;
