import { Button } from 'primereact';
const ButtonComponent = ({ formikData }) => {
  let startURL =
    formikData?.custom_properties?.buttonInfoUrl?.split('://')?.length;

  return (
    <Button
      label={formikData?.widget_name || ''}
      onClick={() =>
        [
          'readOnlyWidget',
          'tabularWidget',
          'routingWidget',
          'dashboardWidget',
        ].includes(formikData?.custom_properties?.buttonWidgetType)
          ? false
          : formikData?.custom_properties?.openIn?.code == 'new-tab'
            ? window.open(
                startURL > 1
                  ? formikData?.custom_properties?.buttonInfoUrl
                  : `https://${formikData?.custom_properties?.buttonInfoUrl}`
              )
            : (window.location.href = startURL
                ? formikData?.custom_properties?.buttonInfoUrl
                : `https://${formikData?.custom_properties?.buttonInfoUrl}`)
      }
      pt={{
        root: {
          className: 'h-3rem',
          style: {
            background: formikData?.custom_properties?.buttonColor,
            color: formikData?.custom_properties?.buttonTextColor,
          },
        },
      }}
    />
  );
};
export default ButtonComponent;
