import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isClientsListLoading: true,
  clientsList: [],
  selectedClients: [],
  isAssignWidgetDialogVisible: false,
  isAssignWidgetValidateDialogVisible: false,
  selectedWidgets: [],
  validateTableData: [],
};

const assignWidgetsSlice = createSlice({
  name: 'assignWidgets',
  initialState: initialState,
  reducers: {
    setAssignWidgets(state, { payload }) {
      state.isClientsListLoading = Object.prototype.hasOwnProperty.call(
        payload,
        'isClientsListLoading'
      )
        ? payload.isClientsListLoading
        : state.isClientsListLoading;
      state.clientsList = Object.prototype.hasOwnProperty.call(
        payload,
        'clientsList'
      )
        ? payload.clientsList
        : state.clientsList;
      state.selectedClients = Object.prototype.hasOwnProperty.call(
        payload,
        'selectedClients'
      )
        ? payload.selectedClients
        : state.selectedClients;
      state.isAssignWidgetDialogVisible = Object.prototype.hasOwnProperty.call(
        payload,
        'isAssignWidgetDialogVisible'
      )
        ? payload.isAssignWidgetDialogVisible
        : state.isAssignWidgetDialogVisible;
      state.isAssignWidgetValidateDialogVisible =
        Object.prototype.hasOwnProperty.call(
          payload,
          'isAssignWidgetValidateDialogVisible'
        )
          ? payload.isAssignWidgetValidateDialogVisible
          : state.isAssignWidgetValidateDialogVisible;
      state.selectedWidgets = Object.prototype.hasOwnProperty.call(
        payload,
        'selectedWidgets'
      )
        ? payload.selectedWidgets
        : state.selectedWidgets;
      state.validateTableData = Object.prototype.hasOwnProperty.call(
        payload,
        'validateTableData'
      )
        ? payload.validateTableData
        : state.validateTableData;
    },
  },
});

export const { setAssignWidgets } = assignWidgetsSlice.actions;

export const assignWidgetsReducer = assignWidgetsSlice.reducer;
