import React, { useRef, useState } from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';
import { ProgressSpinner } from 'primereact/progressspinner'; // Import Spinner for loading indication

import { distanceInMileage } from '../services/calendar.service';

const JobDistanceOverlay = ({ selectedRow, eventInfo, localProp }) => {
  const [jobDistance, setJobDistance] = useState('');
  const [loading, setLoading] = useState(false); // Track loading state
  const jobDistancePanelRef = useRef(null); // Local OverlayPanel ref

  const renderMileageTooltipContent = async (sourceId, destinationId) => {
    setLoading(true); // Set loading state to true
    try {
      const response = await distanceInMileage(sourceId, destinationId);
      const measurement = response?.data?.data?.data?.measurement || '-';
      const unit = response?.data?.data?.data?.unit || '-';
      setJobDistance(
        !isNaN(measurement)
          ? `Distance: ${measurement ? measurement : '-'} ${unit}`
          : 'Distance: -'
      );
    } catch (error) {
      console.error(error);
      setJobDistance('Error loading distance');
    } finally {
      setLoading(false);
    }
  };

  const showOverlay = async (event, eventInfo, localProp) => {
    if (jobDistancePanelRef.current) {
      const sourceId = selectedRow?.project_id;
      const destinationId = eventInfo
        ? eventInfo?.event?.extendedProps?.project_id
        : localProp?.project_id;

      await renderMileageTooltipContent(sourceId, destinationId);
      jobDistancePanelRef.current.show(event); // Show OverlayPanel
    }
  };

  const hideOverlay = () => {
    if (jobDistancePanelRef.current) {
      setJobDistance(''); // Clear job distance
      jobDistancePanelRef.current.hide(); // Hide OverlayPanel
    }
  };

  return (
    <>
      <i
        className="job-distance pi pi-map-marker absolute right-0 mt-2 mr-6 z-5 cursor-pointer"
        onMouseEnter={event => showOverlay(event, eventInfo, localProp)}
        onMouseLeave={hideOverlay}
      ></i>
      <div>
        <OverlayPanel ref={jobDistancePanelRef}>
          {loading ? (
            <div className="flex justify-center">
              <ProgressSpinner className="w-2rem h-2rem m-0 p-0" />
            </div>
          ) : (
            <div>{jobDistance}</div>
          )}
        </OverlayPanel>
      </div>
    </>
  );
};

export default JobDistanceOverlay;
