// **** External Utilities ****
import axios from 'axios';

import { CONFIG } from '../../../constants';
import { URL_CONSTANTS } from '../../../constants/urlConstants';

//Get Originating From
export const getSupportTypeList = async (setSupportTypeList, project_type) => {
  try {
    const type =
      project_type === 'Project' || project_type === 'Installation'
        ? 'Installations'
        : project_type === 'Details' || project_type === 'Measurement'
          ? 'Measurements'
          : project_type;
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/system/support-type`
    );

    const supportTypes =
      response.data
        ?.filter(val => val.support_category === type)
        .map(val => val) || [];

    setSupportTypeList(supportTypes);
  } catch (error) {
    console.log(error);
  }
};

// Get Permitting Data
export const getNeedSupportData = async (
  projectId,
  setLoading,
  setNeedSupportResponse
) => {
  try {
    const limit = 50;
    const offset = 0;
    setLoading(true);
    const response = await axios.get(
      `${CONFIG.API_BASE_URL}/projects/${projectId}/support?limit=${limit}&offset=${offset}`
    );

    setNeedSupportResponse(response.data);
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
};

//Add Support
export const createSupport = async (
  projectId,
  values,
  setLoading,
  setAlert,
  setReloadList,
  setOpen
) => {
  try {
    setOpen(false);
    setLoading(true);
    const response = await axios.post(
      `${URL_CONSTANTS.API.BASE_URL}/projects/${projectId}/support`,
      values
    );
    if (response) {
      setReloadList(true);
      setAlert('success', 'Support Request added successfully.', false, true);
    }
  } catch (error) {
    console.error(error);
    setAlert('error', 'Error in Creating Support Request.', false, true);
    setLoading(false);
  }
};

//Update Suuport
export const updateSupport = async (
  projectId,
  permitId,
  values,
  setLoading,
  setAlert,
  setReloadList,
  setOpen
) => {
  try {
    setOpen(false);
    setLoading(true);
    const response = await axios.patch(
      `${URL_CONSTANTS.API.BASE_URL}/projects/${projectId}/support/${permitId}`,
      values
    );
    if (response) {
      setReloadList(true);
      setAlert('success', 'Support Request updated successfully.', false, true);
    }
  } catch (error) {
    console.error(error);
    setAlert('error', 'Error in updating Support Request.', false, true);
    setLoading(false);
  }
};

//Delete Support
export const deleteSupport = async (
  projectId,
  permitId,
  setLoading,
  setAlert,
  setReloadList,

  setConfirmDialog
) => {
  try {
    setConfirmDialog(prevState => ({
      ...prevState,
      isOpen: false,
    }));
    setLoading(true);
    const response = await axios.delete(
      `${URL_CONSTANTS.API.BASE_URL}/projects/${projectId}/support/${permitId}`
    );
    if (response) {
      setReloadList(true);
      setAlert('success', 'Support Request Deleted successfully.', false, true);
    }
  } catch (error) {
    console.error(error);
    setAlert('error', 'Error in Deleting Support Request', false, true);
    setLoading(false);
  }
};
