import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Card } from 'primereact/card';
import { Toast } from 'primereact/toast';

import PFButton from '../../../shared/PFPrime/PFButton';
import PFDataTable from '../../../shared/PFPrime/PFDataTable';
import PFInputText from '../../../shared/PFPrime/PFInputText';
import apiService from '../../../../services/api.service';
import PageHeader from '../../../shared/PageHeader/PageHeader';

const GroupSourceCategory = () => {
  const [parentCategoryList, setParentCategoryList] = useState([]);
  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 1,
  });
  const [searchQuery, setSearchQuery] = useState({
    limit: 10,
    offset: 0,
    search: '',
  });
  const [globalSearch, setGlobalSearch] = useState('');
  const [count, setCount] = useState();

  const dt = useRef(null);
  const toast = useRef(null);
  const history = useHistory();
  const accessBreadcrumb = [
    {
      text: 'Admin',
    },
    {
      link: '/admin/configuration',
      text: 'Company Configurations',
    },

    {
      text: `Category Configurations`,
    },
  ];

  const getParentCategoryList = async search => {
    try {
      const response = await apiService.get(
        `/system/parent-category/list/${search?.offset}/${search?.limit}?searchString=${search?.search}`
      );
      if (response && response?.data) {
        setParentCategoryList(response?.data?.recordList);
        setCount(response?.data?.count);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getParentCategoryList(searchQuery);
  }, []);

  useEffect(() => {
    getParentCategoryList(searchQuery);
  }, [searchQuery]);
  const groupCol = [
    {
      field: '',
      header: 'Actions',
      body: rowData => (
        <div className="flex w-full pl-0">
          <PFButton
            icon="pi pi-pencil"
            outlined
            text
            className="text-white border-none px-0"
            severity="primary"
            aria-label="Edit"
            onClick={() => {
              history.push(
                `/admin/configuration/group-source-category/edit/${rowData?.project_category_id}`
              );
            }}
          />
        </div>
      ),
      style: { minWidth: '10rem', maxWidth: '10rem' },
    },
    {
      field: 'category',
      header: 'Category',
      body: rowData => <div className="flex">{rowData?.category}</div>,
      style: { minWidth: '20rem' },
    },
  ];
  const handleOnPage = event => {
    setSearchQuery(prevState => ({
      ...prevState,
      limit: event.rows,
      offset: event.first,
    }));
    setlazyState(event);
  };
  let intervalVal = '';
  const handleSearch = searchInputVal => {
    const searchString = searchInputVal?.trim();
    try {
      clearTimeout(intervalVal);
    } catch (err) {
      console.log(err);
    }
    intervalVal = setTimeout(() => {
      setSearchQuery({ limit: 10, offset: 0, search: searchString });
      setlazyState({
        first: 0,
        rows: 10,
        page: 1,
      });
    }, 400);
  };
  const renderHeader = () => {
    return (
      <div className="grid col-12">
        <div className="col-6">
          <div className="flex">
            <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <PFInputText
                id="search"
                value={globalSearch}
                placeholder="Keyword Search"
                className="p-inputtext-sm w-12"
                onChange={e => {
                  setGlobalSearch(e?.target?.value);
                  handleSearch(e?.target?.value);
                }}
              />
            </span>
          </div>
        </div>
        <div className="col-6">
          <div className="flex justify-content-end">
            <span className="p-float-label">
              <PFButton
                id="shade"
                className="w-12 px-4"
                label="Add New Category"
                onClick={() => {
                  history.push(
                    `/admin/configuration/group-source-category/add`
                  );
                }}
              />
            </span>
          </div>
        </div>
      </div>
    );
  };

  const header = renderHeader();
  let dataTableParams = {
    ref: dt,
    searchQuery: searchQuery,
    header: header,
  };
  return (
    <>
      <div className="w-full">
        <PageHeader
          pageTitle={`Group Source Category`}
          breadCrumbArray={accessBreadcrumb}
          className="pl-2"
        />
        <Card className="mt-4" pt={{ body: { className: 'pt-0  mx-2' } }}>
          <div className="grid pt-0">
            <Toast ref={toast} />
            <div className="w-full mt-3">
              <PFDataTable
                header={header}
                dataTableParams={dataTableParams}
                columns={groupCol}
                data={parentCategoryList}
                lazy
                paginator
                page={lazyState.page}
                first={lazyState.first}
                rows={lazyState.rows}
                onPage={handleOnPage}
                totalRecords={count}
                rowsPerPageOptions={[10, 20, 50, 100]}
                tableStyle={{ minWidth: '50rem' }}
              />
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};
export default GroupSourceCategory;
