import CryptoJS from 'crypto-js';

import { REACT_APP_CRYPTO_SECRET_KEY } from '../constants/envConstants';
const secretKey = REACT_APP_CRYPTO_SECRET_KEY;
export const encrypt = data => {
  const CRYPTOJS_KEY_PARSE = CryptoJS.enc.Hex.parse(
    CryptoJS.enc.Utf8.parse(secretKey)
  );
  const CRYPTOJS_IV = CryptoJS.enc.Hex.parse(
    CryptoJS.enc.Utf8.parse(secretKey.split('').reverse().join(''))
  );

  const encryptData = CryptoJS.AES.encrypt(
    CryptoJS.enc.Utf8.parse(data),
    CRYPTOJS_KEY_PARSE,
    {
      iv: CRYPTOJS_IV,
    }
  );

  return encryptData.toString();
};

export const encryptEmail = emailContent => {
  return CryptoJS.AES.encrypt(emailContent, secretKey).toString();
};

export const decryptEmail = encryptedContent => {
  const bytes = CryptoJS.AES.decrypt(encryptedContent, secretKey);
  return bytes.toString(CryptoJS.enc.Utf8);
};
