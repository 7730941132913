import axios from 'axios';

import { CONFIG } from '../../../../constants';
import apiService from '../../../../services/api.service';

export const lineItemList = async (searchString, typeId = 6) => {
  try {
    let url = `${CONFIG.API_BASE_URL}/system/item-types/${typeId}/items`;
    if (searchString) {
      url = `${url}/?limit=100&offset=0&query=${searchString}`;
    }
    return await axios.get(url);
  } catch (err) {
    console.error(err);
    return {};
  }
};

export const mVendorList = async () => {
  try {
    return await axios.get(
      `${CONFIG.API_CRM_URL}/api/master-data/mvendor-list`
    );
  } catch (err) {
    console.error(err);
    return {};
  }
};

export const getQuotesDocument = async actionId => {
  try {
    const quoteDocuments = await apiService.getWithDiffBaseUrl(
      `/api/measure-square/quote-documents?quoteId=${actionId}`,
      undefined,
      CONFIG?.API_CRM_URL
    );
    return quoteDocuments;
  } catch (error) {
    console.error('getQuotesDocument', error);
    return [];
  }
};
