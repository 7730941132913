import { Image } from 'primereact/image';
import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';
import { Tooltip } from 'primereact/tooltip';

import { IDocumentsViewCardProps } from '../Interface/quoteTemplate.interface';

const DocumentsViewCard: React.FC<IDocumentsViewCardProps> = ({
  documentInfo,
  type,
  className = '',
}) => {
  const [pdfError, setPdfError] = useState(false);
  const isPDF = documentInfo?.title?.toLowerCase()?.endsWith('.pdf');
  const getFormattedFileName = () => {
    const fileName = documentInfo?.title?.replace(/_/g, ' ') || '';
    const [name, ext] = fileName.split(/\.(?=[^.]+$)/);
    return name.length > 12 ? `${name.slice(0, 12)}... .${ext}` : fileName;
  };

  return (
    <div className={`${className}`}>
      <div>
        {type === 'Document' ? (
          isPDF && !pdfError ? (
            <Document
              file={documentInfo?.file_path}
              className="p-0 m-0"
              onLoadError={() => setPdfError(true)}
            >
              <Page
                pageNumber={1}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                height={224}
              />
            </Document>
          ) : (
            <Image
              alt="file preview"
              src={'/thumbnail.PNG'}
              pt={{
                image: {
                  className: 'h-13.5rem mt-3 object-fit-contain',
                },
              }}
              preview={false}
            />
          )
        ) : null}

        {type === 'Photos' && (
          <Image
            alt="Preview Image"
            src={documentInfo?.file_path}
            pt={{
              image: {
                className: 'h-13rem ',
              },
            }}
            preview={false}
          />
        )}
      </div>

      <div className="p-0">
        {/* PrimeReact Tooltip */}
        <Tooltip target=".file-title-tooltip" />

        <div
          className="text-sm font-bold w-[150px] whitespace-nowrap overflow-hidden text-overflow-ellipsis file-title-tooltip cursor-pointer"
          data-pr-tooltip={documentInfo?.title?.replace(/_/g, ' ')}
          data-pr-position="bottom"
        >
          {getFormattedFileName()}
        </div>

        <div className="text-sm">{documentInfo?.time_stamp}</div>
      </div>
    </div>
  );
};

export default DocumentsViewCard;
