import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import _ from 'lodash';

import { storeDashboardAnalysis } from '../service/widget.service';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineChartComponent = ({
  cubeResponse,
  annotation,
  dashboardDetails,
  dashboardLogDetails,
}) => {
  const labels = [];
  const values = [];
  if (cubeResponse && cubeResponse.length) {
    const copiedResponse = _.cloneDeep(cubeResponse);
    for (const item of copiedResponse) {
      for (const k of Object.keys(item)) {
        if (
          k?.includes('avg') ||
          k?.includes('sum') ||
          k?.includes('count') ||
          k?.includes('min') ||
          k?.includes('max')
        ) {
          values.push(item[k]);
          delete item[k];
          labels.push(
            Object.keys(item)
              ?.filter(f => f !== undefined)
              ?.map(record => {
                let formatter = null;
                Object.keys(item)?.forEach(i => {
                  if (annotation?.[i]?.meta?.format) {
                    formatter = eval(annotation[i].meta.format);
                  }
                });

                return formatter ? formatter(item[record]) : item[record];
              })
              ?.join(' | ')
          );
        }
      }
    }
  }

  const data = {
    labels: labels,
    responsive: true,

    datasets: [
      {
        label: '',
        data: values,
        borderWidth: 1,
        fill: false,
      },
    ],
  };
  const options = {
    maintainAspectRatio: false,
    rotation: 270, // start angle in degrees
    circumference: 180, // sweep angle in degrees
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      colors: {
        enabled: true,
        forceOverride: true,
      },
    },
  };
  if (dashboardDetails && dashboardLogDetails) {
    storeDashboardAnalysis({
      ...dashboardLogDetails,
      ...dashboardDetails,
      executionTime: new Date().getTime() - dashboardDetails.start_time,
      end_time: new Date().getTime(),
    });
  }
  return <Line data={data} options={options} />;
};

export default LineChartComponent;
