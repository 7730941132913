import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  customers: [],
  stores: [],
  projectTypes: [],
  projectCategories: [],
  projectWorkrooms: [],
  projectStatus: [],
  districtId: [],
  defaultFilter: [],
  techniciansVal: [],
  sourceStatus: [],
  userType: [],
  dateScheduledFilterStart: [],
  dateScheduledFilterEnd: [],
  dateCompletionFilterStart: [],
  dateCompletionFilterEnd: [],
  checkDateFromFilter: [],
  checkDateToFilter: [],
  dateSoldFromFilter: [],
  dateSoldToFilter: [],
  isDashboardApplicable: 0,
};

const globalFiltersSlice = createSlice({
  name: 'globalFilters',
  initialState: initialState,
  reducers: {
    setGlobalFilters(state, { payload }) {
      state.customers = Object.prototype.hasOwnProperty.call(
        payload,
        'customers'
      )
        ? payload.customers
        : state.customers;
      state.stores = Object.prototype.hasOwnProperty.call(payload, 'stores')
        ? payload.stores
        : state.stores;
      state.projectTypes = Object.prototype.hasOwnProperty.call(
        payload,
        'projectTypes'
      )
        ? payload.projectTypes
        : state.projectTypes;
      state.projectCategories = Object.prototype.hasOwnProperty.call(
        payload,
        'projectCategories'
      )
        ? payload.projectCategories
        : state.projectCategories;
      state.projectWorkrooms = Object.prototype.hasOwnProperty.call(
        payload,
        'projectWorkrooms'
      )
        ? payload.projectWorkrooms
        : state.projectWorkrooms;
      state.projectStatus = Object.prototype.hasOwnProperty.call(
        payload,
        'projectStatus'
      )
        ? payload.projectStatus
        : state.projectStatus;
      state.districtId = Object.prototype.hasOwnProperty.call(
        payload,
        'districtId'
      )
        ? payload.districtId
        : state.districtId;
      state.defaultFilter = Object.prototype.hasOwnProperty.call(
        payload,
        'defaultFilter'
      )
        ? payload.defaultFilter
        : state.defaultFilter;
      state.techniciansVal = Object.prototype.hasOwnProperty.call(
        payload,
        'techniciansVal'
      )
        ? payload.techniciansVal
        : state.techniciansVal;
      state.sourceStatus = Object.prototype.hasOwnProperty.call(
        payload,
        'sourceStatus'
      )
        ? payload.sourceStatus
        : state.sourceStatus;
      state.userType = Object.prototype.hasOwnProperty.call(payload, 'userType')
        ? payload.userType
        : state.userType;
      state.dateScheduledFilterStart = Object.prototype.hasOwnProperty.call(
        payload,
        'dateScheduledFilterStart'
      )
        ? payload.dateScheduledFilterStart
        : state.dateScheduledFilterStart;
      state.dateScheduledFilterEnd = Object.prototype.hasOwnProperty.call(
        payload,
        'dateScheduledFilterEnd'
      )
        ? payload.dateScheduledFilterEnd
        : state.dateScheduledFilterEnd;
      state.dateCompletionFilterStart = Object.prototype.hasOwnProperty.call(
        payload,
        'dateCompletionFilterStart'
      )
        ? payload.dateCompletionFilterStart
        : state.dateCompletionFilterStart;
      state.dateCompletionFilterEnd = Object.prototype.hasOwnProperty.call(
        payload,
        'dateCompletionFilterEnd'
      )
        ? payload.dateCompletionFilterEnd
        : state.dateCompletionFilterEnd;
      state.checkDateFromFilter = Object.prototype.hasOwnProperty.call(
        payload,
        'checkDateFromFilter'
      )
        ? payload.checkDateFromFilter
        : state.checkDateFromFilter;
      state.checkDateToFilter = Object.prototype.hasOwnProperty.call(
        payload,
        'checkDateToFilter'
      )
        ? payload.checkDateToFilter
        : state.checkDateToFilter;
      state.dateSoldFromFilter = Object.prototype.hasOwnProperty.call(
        payload,
        'dateSoldFromFilter'
      )
        ? payload.dateSoldFromFilter
        : state.dateSoldFromFilter;
      state.dateSoldToFilter = Object.prototype.hasOwnProperty.call(
        payload,
        'dateSoldToFilter'
      )
        ? payload.dateSoldToFilter
        : state.dateSoldToFilter;
      state.isDashboardApplicable = Object.prototype.hasOwnProperty.call(
        payload,
        'isDashboardApplicable'
      )
        ? payload.isDashboardApplicable
        : state.isDashboardApplicable;
    },
  },
});

export const { setGlobalFilters } = globalFiltersSlice.actions;

export const globalFiltersSliceReducer = globalFiltersSlice.reducer;
