import { URL_CONSTANTS } from '../../../constants/urlConstants';
import ApiService from '../../../services/api.service';

export const getModules = async () => {
  return await ApiService.getWithDiffBaseUrl(
    URL_CONSTANTS.QUESTIONNAIRE.endPoints.getModules,
    null,
    URL_CONSTANTS.QUESTIONNAIRE.baseUrl
  );
};
export const getModuleById = async moduleId => {
  return await ApiService.getWithDiffBaseUrl(
    ApiService.formatUrl(
      URL_CONSTANTS.QUESTIONNAIRE.endPoints.getModuleById,
      moduleId
    ),
    null,
    URL_CONSTANTS.QUESTIONNAIRE.baseUrl
  );
};
export const getModuleByTagName = async (moduleTag, moduleId) => {
  return await ApiService.getWithDiffBaseUrl(
    ApiService.formatUrl(
      URL_CONSTANTS.QUESTIONNAIRE.endPoints.getModuleByTagName,
      moduleId
    ),
    null,
    URL_CONSTANTS.QUESTIONNAIRE.baseUrl
  );
};
export const getQuestions = async moduleId => {
  return await ApiService.getWithDiffBaseUrl(
    ApiService.formatUrl(
      URL_CONSTANTS.QUESTIONNAIRE.endPoints.getQuestions,
      moduleId
    ),
    null,
    URL_CONSTANTS.QUESTIONNAIRE.baseUrl
  );
};
export const addQuestion = async (moduleId, payload) => {
  return await ApiService.postWithDiffBaseUrl(
    ApiService.formatUrl(
      URL_CONSTANTS.QUESTIONNAIRE.endPoints.addQuestion,
      moduleId
    ),
    payload,
    URL_CONSTANTS.QUESTIONNAIRE.baseUrl
  );
};
export const updateQuestionById = async (moduleId, questionId, payload) => {
  return await ApiService.putWithDiffBaseUrl(
    ApiService.formatUrl(
      URL_CONSTANTS.QUESTIONNAIRE.endPoints.updateQuestionById,
      moduleId,
      questionId
    ),
    payload,
    URL_CONSTANTS.QUESTIONNAIRE.baseUrl
  );
};
export const deleteQuestions = async moduleId => {
  return await ApiService.deleteWithDiffBaseUrl(
    ApiService.formatUrl(
      URL_CONSTANTS.QUESTIONNAIRE.endPoints.deleteQuestions,
      moduleId
    ),
    null,
    URL_CONSTANTS.QUESTIONNAIRE.baseUrl
  );
};
export const deleteQuestionById = async (moduleId, questionId) => {
  return await ApiService.deleteWithDiffBaseUrl(
    ApiService.formatUrl(
      URL_CONSTANTS.QUESTIONNAIRE.endPoints.deleteQuestionById,
      moduleId,
      questionId
    ),
    null,
    URL_CONSTANTS.QUESTIONNAIRE.baseUrl
  );
};
export const getAnswers = async (moduleId, targetId, isNewRecord, version) => {
  const params = isNewRecord
    ? `?isNewRecord=${isNewRecord}`
    : `?version=${version}`;
  return await ApiService.getWithDiffBaseUrl(
    ApiService.formatUrl(
      URL_CONSTANTS.QUESTIONNAIRE.endPoints.getAnswers,
      moduleId,
      targetId
    ) + params,
    null,
    URL_CONSTANTS.QUESTIONNAIRE.baseUrl
  );
};
export const getAnswerById = async (moduleId, targetId, answerId) => {
  return await ApiService.getWithDiffBaseUrl(
    ApiService.formatUrl(
      URL_CONSTANTS.QUESTIONNAIRE.endPoints.getAnswerById,
      moduleId,
      targetId,
      answerId
    ),
    null,
    URL_CONSTANTS.QUESTIONNAIRE.baseUrl
  );
};
export const addAnswers = async (moduleId, targetId, payload) => {
  return await ApiService.postWithDiffBaseUrl(
    ApiService.formatUrl(
      URL_CONSTANTS.QUESTIONNAIRE.endPoints.saveAnswers,
      moduleId,
      targetId
    ),
    payload,
    URL_CONSTANTS.QUESTIONNAIRE.baseUrl
  );
};
export const updateAnswers = async (moduleId, targetId, payload) => {
  return await ApiService.putWithDiffBaseUrl(
    ApiService.formatUrl(
      URL_CONSTANTS.QUESTIONNAIRE.endPoints.updateAnswers,
      moduleId,
      targetId
    ),
    payload,
    URL_CONSTANTS.QUESTIONNAIRE.baseUrl
  );
};
export const updateAnswerById = async (
  moduleId,
  targetId,
  answerId,
  payload
) => {
  return await ApiService.putWithDiffBaseUrl(
    ApiService.formatUrl(
      URL_CONSTANTS.QUESTIONNAIRE.endPoints.updateAnswerById,
      moduleId,
      targetId,
      answerId
    ),
    payload,
    URL_CONSTANTS.QUESTIONNAIRE.baseUrl
  );
};
export const eraseAnswers = async (moduleId, targetId) => {
  return await ApiService.patchWithDiffBaseUrl(
    ApiService.formatUrl(
      URL_CONSTANTS.QUESTIONNAIRE.endPoints.deleteAnswers,
      moduleId,
      targetId
    ),
    null,
    URL_CONSTANTS.QUESTIONNAIRE.baseUrl
  );
};
export const deleteAnswers = async (moduleId, targetId, version) => {
  return await ApiService.deleteWithDiffBaseUrl(
    ApiService.formatUrl(
      URL_CONSTANTS.QUESTIONNAIRE.endPoints.deleteAnswers,
      moduleId,
      targetId,
      version
    ),
    null,
    URL_CONSTANTS.QUESTIONNAIRE.baseUrl
  );
};
export const deleteAnswerById = async (moduleId, targetId, answerId) => {
  return await ApiService.deleteWithDiffBaseUrl(
    ApiService.formatUrl(
      URL_CONSTANTS.QUESTIONNAIRE.endPoints.deleteAnswerById,
      moduleId,
      targetId,
      answerId
    ),
    null,
    URL_CONSTANTS.QUESTIONNAIRE.baseUrl
  );
};
export const addModule = async payload => {
  try {
    const response = await ApiService.postWithDiffBaseUrl(
      ApiService.formatUrl(URL_CONSTANTS.QUESTIONNAIRE.endPoints.createModule),
      payload,
      URL_CONSTANTS.QUESTIONNAIRE.baseUrl
    );
    return { error: null, data: response };
  } catch (error) {
    return { error: error, data: null };
  }
};
export const copyModule = async payload => {
  try {
    const response = await ApiService.postWithDiffBaseUrl(
      ApiService.formatUrl(URL_CONSTANTS.QUESTIONNAIRE.endPoints.copyModule),
      payload,
      URL_CONSTANTS.QUESTIONNAIRE.baseUrl
    );
    return { error: null, data: response };
  } catch (error) {
    return { error: error, data: null };
  }
};
export const deleteModule = async moduleId => {
  try {
    const response = await ApiService.deleteWithDiffBaseUrl(
      ApiService.formatUrl(
        URL_CONSTANTS.QUESTIONNAIRE.endPoints.deleteModule,
        moduleId
      ),
      null,
      URL_CONSTANTS.QUESTIONNAIRE.baseUrl
    );
    return { error: null, data: response };
  } catch (error) {
    return { error: error, data: null };
  }
};
export const createCustomizeCheckList = async (payload, moduleId) => {
  return await ApiService.postWithDiffBaseUrl(
    ApiService.formatUrl(
      URL_CONSTANTS.QUESTIONNAIRE.endPoints.createCustomizeCheckList
    ),
    { ...payload, moduleId },
    URL_CONSTANTS.QUESTIONNAIRE.baseUrl
  );
};
