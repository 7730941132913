import { useEffect, useRef, memo } from 'react';

import {
  PUBLIC_URL,
  REACT_APP_PSPDFKIT_LICENCE_KEY,
} from '../../../constants/envConstants';
let PSPDFKit;

const cleanJson = _jsonObj => {
  let jsonObj = _jsonObj;
  if (jsonObj) {
    jsonObj['skippedPdfObjectIds'] = [];
    return jsonObj;
  } else return null;
};

const PdfViewerComponentNew = memo(props => {
  const containerRef = useRef(null);
  let instanceRef = useRef(null);

  const fieldOptions = useRef([]);

  let readOnlyState = useRef(false);
  try {
    if (typeof props.readOnly === 'boolean') {
      readOnlyState.current = props.readOnly;
    }
  } catch (error) {
    console.error(error);
  }

  let instantJsonObj = null;
  if (props.existingJson && props.existingJson['annotations']) {
    instantJsonObj = props.existingJson;
    instantJsonObj = cleanJson(instantJsonObj);
  } else instantJsonObj = null;

  let documentPath = '';
  if (props.document && props.document.trim().length > 0) {
    documentPath = props.document;
  } else {
    return (
      <div style={{ color: 'red' }}>
        <h2>Invalid File Path</h2>
        <p>Please check if the file path is correct</p>
      </div>
    );
  }

  if (props.fieldOptions && props.fieldOptions.length > 0) {
    fieldOptions.current = props.fieldOptions;
  }

  const exportPDFData = async () => {
    return instanceRef.current.exportInstantJSON();
  };
  const exportPDFBuffer = async () => {
    return instanceRef.current.exportPDF({ flatten: false });
  };
  const findDuplicates = async () => {
    let duplicates = new Set();
    const instance = instanceRef.current;
    try {
      const pagesAnnotations = await Promise.all(
        Array.from({ length: instance.totalPageCount }).map((_, pageIndex) =>
          instance.getAnnotations(pageIndex)
        )
      );

      const allAnnotations = pagesAnnotations
        .map(pageList => pageList.toJS())
        .flat();

      for (let i = 0; i < allAnnotations.length; i++) {
        const annotA = allAnnotations[i];
        for (let j = 0; j < allAnnotations.length; j++) {
          const annotB = allAnnotations[j];
          if (
            annotA.id != annotB.id &&
            annotA.boundingBox.left == annotB.boundingBox.left &&
            annotA.pageIndex == annotB.pageIndex &&
            annotA.boundingBox.top == annotB.boundingBox.top
          ) {
            try {
              duplicates.add(annotA.formFieldName);
              duplicates.add(annotA.formFieldName);
            } catch (error) {
              console.error(error);
            }
          }
        }
      }
    } catch (exception) {
      console.error(exception);
    }
    return duplicates;
  };

  useEffect(() => {
    const container = containerRef.current;

    (async function () {
      PSPDFKit = await import('pspdfkit');
      PSPDFKit.unload(container);

      await PSPDFKit.load({
        container,
        document: documentPath,

        baseUrl: `${window.location.protocol}//${window.location.host}/${PUBLIC_URL}`,

        licenseKey: REACT_APP_PSPDFKIT_LICENCE_KEY,

        toolbarItems: props.toolbarItems || [],

        annotationTooltipCallback: customAnnotationCallback,

        instantJSON: instantJsonObj?.annotations ? instantJsonObj : null,

        initialViewState: new PSPDFKit.ViewState({
          enableAnnotationToolbar: true,
          readOnly: readOnlyState.current,
        }),

        styleSheets: [`/pspdf.css`],
      }).then(instance => {
        //do something here
        instance.exportPDFData = exportPDFData;
        instance.exportPDFBuffer = exportPDFBuffer;
        instance.findDuplicates = findDuplicates;
        instanceRef.current = instance;

        try {
          props.setParentRef(instanceRef.current);
        } catch (error) {
          console.error(error);
        }
      });
    })();

    const customAnnotationCallback = annotation => {
      if (annotation instanceof PSPDFKit.Annotations.WidgetAnnotation) {
        //main container
        const div = instanceRef.current.contentDocument.createElement('div');
        div.classList.add('custom__properties');

        //header
        const header = instanceRef.current.contentDocument.createElement('div');
        header.classList.add('custom__properties__header');
        div.appendChild(header);

        //first heading
        const label = instanceRef.current.contentDocument.createElement('h3');
        label.classList.add('custom__properties__heading');
        label.innerHTML = 'Select Data:';
        header.appendChild(label);

        const selectDiv =
          instanceRef.current.contentDocument.createElement('div');
        selectDiv.classList.add('custom__properties__options__container');
        div.appendChild(selectDiv);

        const select1 =
          instanceRef.current.contentDocument.createElement('select');
        select1.classList.add('custom__properties__select');
        selectDiv.appendChild(select1);

        const baseOption =
          instanceRef.current.contentDocument.createElement('option');
        baseOption.value = -1;
        baseOption.textContent = 'Select';
        select1.appendChild(baseOption);

        fieldOptions.current.map(item => {
          if (item.name === 'Project') {
            const option =
              instanceRef.current.contentDocument.createElement('option');
            option.value = item.name;
            option.textContent = item.name;
            select1.appendChild(option);
          }
        });

        //Custom Field
        const todaysDateParentOption =
          instanceRef.current.contentDocument.createElement('option');
        todaysDateParentOption.value = 'Custom Date';
        todaysDateParentOption.textContent = 'Custom Date';
        select1.appendChild(todaysDateParentOption);

        const select2 =
          instanceRef.current.contentDocument.createElement('select');
        select2.classList.add('custom__properties__select');
        selectDiv.appendChild(select2);

        select1.addEventListener('change', e => {
          try {
            var i,
              L = select2.options.length - 1;
            for (i = L; i >= 0; i--) {
              select2.remove(i);
            }
          } catch (error) {
            console.error(error);
          }

          let cube = fieldOptions.current.filter(item => {
            return item.name === e.target.value;
          });
          if (cube.length > 0) {
            cube = cube[0];
            cube.dimensions = cube.dimensions.sort((a, b) =>
              a.shortTitle.localeCompare(b.shortTitle)
            );
            cube.dimensions.map(dimension => {
              const option =
                instanceRef.current.contentDocument.createElement('option');
              option.value = dimension.key;
              option.textContent = dimension.shortTitle;
              select2.appendChild(option);
            });
          } else {
            try {
              const targetValue = e.target.value;
              switch (targetValue) {
                case 'Custom Date': {
                  //Yesterday Date
                  const yesterdayOption =
                    instanceRef.current.contentDocument.createElement('option');
                  yesterdayOption.value = 'Yesterday';
                  yesterdayOption.textContent = 'Yesterday';
                  select2.appendChild(yesterdayOption);

                  //Today Date
                  const todayOption =
                    instanceRef.current.contentDocument.createElement('option');
                  todayOption.value = 'Today';
                  todayOption.textContent = 'Today';
                  select2.appendChild(todayOption);

                  //Tommorow Date
                  const tommorowOption =
                    instanceRef.current.contentDocument.createElement('option');
                  tommorowOption.value = 'Tommorow';
                  tommorowOption.textContent = 'Tommorow';
                  select2.appendChild(tommorowOption);
                  break;
                }
              }
            } catch (error) {
              console.error(error);
            }
          }
        });

        select2.addEventListener('change', () => {});

        const footerDiv =
          instanceRef.current.contentDocument.createElement('div');
        footerDiv.classList.add('custom__properties__options__container');
        div.appendChild(footerDiv);

        const input =
          instanceRef.current.contentDocument.createElement('input');
        input.setAttribute('value', 'Save');
        input.setAttribute('type', 'button');
        input.setAttribute('id', 'finalizeButton');
        input.classList.add('custom__properties__button');
        input.type = 'button';
        footerDiv.appendChild(input);

        input.addEventListener('click', () => {
          if (select1.value && select2.value && select1.value != -1) {
            const name = `~${select1.value}:${select2.value}~`;
            let isCustomValue = false;
            //add custom values here
            let customValues = new Set(['Custom Date']);
            if (customValues.has(select1.value)) {
              isCustomValue = true;
            }
            copyAnnotation(annotation, name, isCustomValue);
          } else {
            select1.focus();
          }
        });

        const input2 =
          instanceRef.current.contentDocument.createElement('input');
        input2.setAttribute('value', 'Delete');
        input2.setAttribute('type', 'button');
        input2.setAttribute('id', 'finalizeButton');
        input2.classList.add('custom__properties__button');
        input2.classList.add('button__danger');
        input2.type = 'button';
        footerDiv.appendChild(input2);

        input2.addEventListener('click', () => {
          removeAnnotation(annotation);
        });

        const toolItem = {
          type: 'custom',
          node: div,
          onPress: function () {},
        };

        return [toolItem];
      } else {
        return [];
      }
    };

    const copyAnnotation = (annotation, newText, isCustomValue = true) => {
      try {
        //this will hold values
        const createAnnotations = [];

        // create an annotation copy
        const widgetId = `${newText}_${PSPDFKit.generateInstantId()}`;
        const widget = new PSPDFKit.Annotations.WidgetAnnotation({
          id: widgetId,
          pageIndex: annotation.pageIndex,
          formFieldName: widgetId,
          boundingBox: new PSPDFKit.Geometry.Rect(annotation.boundingBox),
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: PSPDFKit.Color.BLACK,
          font: 'Helvetica',
          fontSize: 12,
          fontColor: PSPDFKit.Color.BLACK,
          customData: {
            text: newText,
            widget_id: widgetId,
            isCustomValue: isCustomValue,
          },
          readOnly: true,
          isEditable: false,
        });

        const formField = new PSPDFKit.FormFields.TextFormField({
          name: widgetId,
          annotationIds: new PSPDFKit.Immutable.List([widget.id]),
          value: newText,
          font: 'Helvetica',
          fontSize: 12,
          fontColor: PSPDFKit.Color.BLACK,
          readOnly: true,
        });

        createAnnotations.push(widget);
        createAnnotations.push(formField);
        instanceRef.current.create(createAnnotations);

        //remove the existing annotation
        removeAnnotation(annotation);
      } catch (error) {
        console.error(error);
      }
    };

    const removeAnnotation = annotation => {
      if (annotation) {
        try {
          if (annotation.name) {
            instanceRef.current.delete(annotation);
          } else {
            //This Element cannot be deleted
          }
        } catch (error) {
          console.error(error);
        }
      }

      try {
        instanceRef.current.setViewState(viewState =>
          viewState.set('formDesignMode', false)
        );
        instanceRef.current.setViewState(viewState =>
          viewState.set('formDesignMode', true)
        );
      } catch (error) {
        console.error(error);
      }
    };

    return () => PSPDFKit && PSPDFKit.unload(container);
  });

  return <div ref={containerRef} style={{ width: '100%', height: '100vh' }} />;
});
PdfViewerComponentNew.displayName = 'PdfViewerComponentNew';
export default PdfViewerComponentNew;
