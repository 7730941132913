import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { TabView, TabPanel } from 'primereact/tabview';

import PFInputSwitch from '../../shared/PFPrime/PFInputSwitch';
import ApiService from '../../../services/api.service';

import UserFormAvailability from './user-form-availability';
import UserJobAssignRotation from './user-form-job-assig-rotation';
import UserJobAssignServiceTime from './user-form-job-assign-service-time';
import UserJobAssignCapacity from './user-form-job-assign-capacity';
import { routeAvailability } from './services/user-form-job-assign-coverage.service';
import UserJobAssignCoverage from './user-form-job-assign-coverage';

const JobAssignmentCoverage = () => {
  const [checked, setChecked] = useState(false);
  const [routingPreference, setRoutingPreference] = useState({});
  const { user } = useParams();

  const routingAvailability = async isChecked => {
    const payload = {
      is_available_for_routing: isChecked ? 1 : 0,
      routing_preference: { ...routingPreference },
    };
    try {
      await routeAvailability(user, payload);
      // Handle response if needed
    } catch (error) {
      console.error(error);
    }
  };

  const getRoutePreference = async () => {
    try {
      const response = await ApiService.get(
        `/auth/user/manage/routing-preferences/${user}`
      );
      setChecked(response?.is_job_assignment_enabled ? true : false);
      setRoutingPreference({
        distance_unit: response?.coverage_by,
        end_location: response?.end_location,
        from_to_home_payable: response?.home_payable,
        max_projects: response?.max_no_projects,
        start_location: response?.start_location,
        max_travel_distance: response?.max_travel_distance,
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getRoutePreference();
  }, [user]);

  const handleSwitchChange = e => {
    const newCheckedValue = e.value;
    setChecked(newCheckedValue);
    routingAvailability(newCheckedValue);
  };

  return (
    <div>
      <div className="flex justify-content-between py-2 mx-2 align-items-center">
        <h3>Job Assignment Coverage</h3>
        <div className="flex align-items-center mb-1">
          <PFInputSwitch
            className="mx-2 md:mb-2"
            checked={checked}
            onChange={handleSwitchChange}
          />
          <label
            htmlFor="enableConfigurationForROAndCXScheduling"
            className="mr-2 mb-2"
          >
            Enable Configuration for RO and CX Scheduling
          </label>
        </div>
      </div>
      <div className="card mt-3">
        <TabView>
          <TabPanel header="Work Schedule">
            <UserFormAvailability />
          </TabPanel>
          <TabPanel header="Job Coverage">
            <UserJobAssignCoverage />
          </TabPanel>
          <TabPanel header="Rotation%">
            <UserJobAssignRotation />
          </TabPanel>
          <TabPanel header="Service Time">
            <UserJobAssignServiceTime />
          </TabPanel>
          <TabPanel header="Capacity">
            <UserJobAssignCapacity />
          </TabPanel>
        </TabView>
      </div>
    </div>
  );
};
export default JobAssignmentCoverage;
