import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { Toast } from 'primereact/toast';

import {
  PFTag,
  PFDropdown,
  PFTextBox,
  PFDataTable,
  PFAutoComplete,
  PFNumberBox,
} from '../pf-prime/Prime';
import { PFNumberInputWithNegative } from '../../shared/PFPrime/PFNumberInputWithNegative';
import { formatNagativeCurrency } from '../../../utils/Helpers';

import unitConverter from './helper';
import {
  status,
  productType,
  updateItemInSql,
  addUpdateProduct,
  validator,
} from './ProductDetails.model';

const ProductDetails = () => {
  const toast = useRef(null);
  const dispatch = useDispatch();
  let { itemInfo: productDetails } = useSelector(
    state => state.inventoryManagment?.ProductInfo
  );
  let { listOfWarehouses } = useSelector(
    state => state.inventoryManagment?.Warehouse
  );
  const [editDetails, setEditDetails] = useState({});
  const [inEdit, setInEdit] = useState([]);
  const [itemSqlDb, setItemSqlDb] = useState({});
  const [field, setField] = useState({});

  let { unit } = productDetails;
  let units = _.cloneDeep(unit);
  units?.map(item => {
    item['parentId'] = item?.pId;
    item['fractionAble'] = false;
  });

  const editorCallback = (field, value) => {
    setField({ key: field, value: value });

    const calculationUsedValue = [
      'salesPrice',
      'purchasePrice',
      'purchasePricePerUnit',
      'salesPricePerUnit',
      'sourcePrice',
      'threshold',
      'totalPurchaseAmount',
      'totalSalesAmount',
    ].includes(field);
    if (calculationUsedValue && !value) {
      value = 0;
    }
    setItemSqlDb({ [field]: value });
    setEditDetails({ [field]: value });
  };

  const onSave = () => {
    const { validation, error } = validator({ ...field });

    if (validation) {
      setInEdit([]);
      const mysqlUpdate = updateItemInSql(
        productDetails?.productId,
        itemSqlDb,
        productDetails
      );
      mysqlUpdate
        .then(() => {
          const reqBody = { ...editDetails, _id: productDetails?._id };
          addUpdateProduct(reqBody, dispatch, toast, () => {});
        })
        .catch(err => {
          toast.current.show({
            severity: 'error',
            summary: 'Error!',
            detail: err?.response?.data?.error,
            life: 3000,
          });
        });
    } else {
      toast.current.show({
        severity: 'error',
        summary: 'Error!',
        detail: error,
        life: 3000,
      });
    }
  };

  const onCancle = () => {
    setEditDetails({});
  };

  const columns = [
    { field: 'titleA', header: 'Product Details' },
    { field: 'valueA', header: '' },
    { field: 'titleB', header: '' },
    { field: 'valueB', header: '' },
  ];
  const data = [
    {
      titleA: <div className="font-bold">Status</div>,
      valueA: (
        <div className="text-left">
          {inEdit.includes('status') ? (
            <>
              <div className="flex">
                <PFDropdown
                  value={productDetails?.status}
                  placeholder="Select Staus"
                  options={status}
                  optionLabel="name"
                  selectedOption={productDetails?.status}
                  onChange={e => editorCallback('status', e)}
                />
                <i
                  className={`pi pi-check hand-cursor ml-2 mt-3`}
                  onClick={() => {
                    onSave();
                    setInEdit([]);
                  }}
                ></i>
                <i
                  className={`pi pi-times hand-cursor ml-2 mt-3`}
                  onClick={() => {
                    onCancle();
                    setInEdit([]);
                  }}
                ></i>
              </div>
            </>
          ) : (
            <>
              <i
                className={`pi pi-pencil hand-cursor mr-2`}
                onClick={() => setInEdit(['status'])}
              ></i>
              <PFTag
                value={`${productDetails?.status || ''}`}
                severity={`${productDetails?.status === 'Active' ? 'success' : ''}`}
              />
            </>
          )}
        </div>
      ),
      titleB: <div className="font-bold">Product Type</div>,
      valueB: (
        <div className="text-left">
          {inEdit.includes('productType') ? (
            <>
              <div className="flex">
                <PFAutoComplete
                  value={productDetails?.productType}
                  onChange={e => editorCallback('productType', e)}
                  suggestions={productType.map(item => item.name) || []}
                />
                <i
                  className={`pi pi-check hand-cursor ml-2 mt-3`}
                  onClick={() => {
                    onSave();
                  }}
                ></i>
                <i
                  className={`pi pi-times hand-cursor ml-2 mt-3`}
                  onClick={() => {
                    onCancle();
                    setInEdit([]);
                  }}
                ></i>
              </div>
            </>
          ) : (
            <>
              <i
                className={`pi pi-pencil hand-cursor mr-2`}
                onClick={() => setInEdit(['productType'])}
              ></i>
              {`${productDetails?.productType || ''}`}
            </>
          )}
        </div>
      ),
    },
    {
      titleA: <div className="font-bold">Product Name</div>,
      valueA: (
        <div className="text-left">
          {inEdit.includes('productName') ? (
            <>
              <div className="flex">
                <PFTextBox
                  value={productDetails?.productName}
                  onChange={e => editorCallback('productName', e)}
                />

                <i
                  className={`pi pi-check hand-cursor ml-2 mt-3`}
                  onClick={() => {
                    onSave();
                  }}
                ></i>
                <i
                  className={`pi pi-times hand-cursor ml-2 mt-3`}
                  onClick={() => {
                    onCancle();
                    setInEdit([]);
                  }}
                ></i>
              </div>
            </>
          ) : (
            <>
              {/* Its temprarily disabled*/}
              {/* <i
                className={`pi pi-pencil hand-cursor mr-2`}
                onClick={() => setInEdit(['productName'])}
              ></i> */}
              <span className="ml-4">{`${productDetails?.productName || ''}`}</span>
            </>
          )}
        </div>
      ),
      titleB: <div className="font-bold">Product Number</div>,
      valueB: (
        <div className="text-left">
          {inEdit.includes('productNumber') ? (
            <>
              <div className="flex">
                <PFNumberBox
                  value={productDetails?.productNumber}
                  onChange={e => editorCallback('productNumber', e)}
                />

                <i
                  className={`pi pi-check hand-cursor ml-2 mt-3`}
                  onClick={() => {
                    onSave();
                  }}
                ></i>
                <i
                  className={`pi pi-times hand-cursor ml-2 mt-3`}
                  onClick={() => {
                    onCancle();
                    setInEdit([]);
                  }}
                ></i>
              </div>
            </>
          ) : (
            <>
              {/* Its temprarily disabled*/}
              {/* <i
                className={`pi pi-pencil hand-cursor mr-2`}
                onClick={() => setInEdit(['productNumber'])}
              ></i> */}
              <span className="ml-4">{`${productDetails?.productNumber || ''}`}</span>
            </>
          )}
        </div>
      ),
    },
    {
      titleA: <div className="font-bold">Total Warehouses</div>,
      valueA: (
        <div className="text-left">
          <span className="ml-4">{`${listOfWarehouses?.length || '0'}`}</span>
        </div>
      ),
      titleB: (
        <div className="font-bold">
          Threshold
          {`${
            units?.find(unit => unit?.isLowest === 1)?.name
              ? ` (${units?.find(unit => unit?.isLowest === 1)?.name})`
              : ''
          } `}
        </div>
      ),
      valueB: (
        <div className="text-left">
          {inEdit.includes('threshold') ? (
            <>
              <div className="flex">
                <PFNumberBox
                  value={productDetails?.threshold}
                  onChange={e => editorCallback('threshold', e)}
                />

                <i
                  className={`pi pi-check hand-cursor ml-2 mt-3`}
                  onClick={() => {
                    onSave();
                    setInEdit([]);
                  }}
                ></i>
                <i
                  className={`pi pi-times hand-cursor ml-2 mt-3`}
                  onClick={() => {
                    onCancle();
                    setInEdit([]);
                  }}
                ></i>
              </div>
            </>
          ) : (
            <>
              <i
                className={`pi pi-pencil hand-cursor mr-2`}
                onClick={() => setInEdit(['threshold'])}
              ></i>

              {`${productDetails?.threshold || '0'}`}
            </>
          )}
        </div>
      ),
    },
    {
      titleA: (
        <div className="font-bold">
          Purchase Price (
          {units?.find(unit => unit?.isLowest === 1)?.name || 'each'})
        </div>
      ),
      valueA: (
        <div className="text-left">
          {inEdit.includes('purchasePrice') ? (
            <>
              <div className="flex">
                <PFNumberBox
                  value={productDetails?.purchasePrice}
                  onChange={e => editorCallback('purchasePrice', e)}
                  mode={'decimal'}
                  minFractionDigits={2}
                  maxFractionDigits={2}
                />

                <i
                  className={`pi pi-check hand-cursor ml-2 mt-3`}
                  onClick={() => {
                    onSave();
                    setInEdit([]);
                  }}
                ></i>
                <i
                  className={`pi pi-times hand-cursor ml-2 mt-3`}
                  onClick={() => {
                    onCancle();
                    setInEdit([]);
                  }}
                ></i>
              </div>
            </>
          ) : (
            <>
              <i
                className={`pi pi-pencil hand-cursor mr-2`}
                onClick={() => setInEdit(['purchasePrice'])}
              ></i>
              {`$ ${(Math.floor((productDetails?.purchasePrice || 0) * 100) / 100).toFixed(2)}`}
            </>
          )}
        </div>
      ),
      titleB: (
        <div className="font-bold">
          Sales price (
          {units?.find(unit => unit?.isLowest === 1)?.name || 'each'})
        </div>
      ),
      valueB: (
        <div className="text-left">
          {inEdit.includes('salesPrice') ? (
            <>
              <div className="flex">
                <PFNumberInputWithNegative
                  currency={'USD'}
                  inputId={'currency-us'}
                  value={productDetails?.salesPrice}
                  onChange={e => editorCallback('salesPrice', e)}
                  mode={'decimal'}
                  minFractionDigits={2}
                  maxFractionDigits={2}
                  inputStyle={{ width: '100%' }}
                  pt={{
                    input: {
                      root: { autoComplete: 'off' },
                    },
                  }}
                />

                <i
                  className={`pi pi-check hand-cursor ml-2 mt-3`}
                  onClick={() => {
                    onSave();
                    setInEdit([]);
                  }}
                ></i>
                <i
                  className={`pi pi-times hand-cursor ml-2 mt-3`}
                  onClick={() => {
                    onCancle();
                    setInEdit([]);
                  }}
                ></i>
              </div>
            </>
          ) : (
            <>
              <i
                className={`pi pi-pencil hand-cursor mr-2`}
                onClick={() => setInEdit(['salesPrice'])}
              ></i>
              {formatNagativeCurrency(
                `${(Math.floor((productDetails?.salesPrice || 0) * 100) / 100).toFixed(2)}`
              )}
            </>
          )}
        </div>
      ),
    },
    {
      titleA: <div className="font-bold">Total On-Hand</div>,
      valueA: (
        <div className="text-left">
          <span className="ml-4">
            {productDetails?.totalQuantityAvailable !== 0 && units?.length > 0
              ? unitConverter(productDetails?.totalQuantityAvailable, units)
              : productDetails?.totalQuantityAvailable}
          </span>
        </div>
      ),
      titleB: <div className="font-bold">Total Available</div>,
      valueB: (
        <div className="text-left">
          <span className="ml-4">
            {productDetails?.totalQuantityInHand !== 0 && units?.length > 0
              ? unitConverter(productDetails?.totalQuantityInHand, units)
              : productDetails?.totalQuantityInHand}
          </span>
        </div>
      ),
    },
    {
      titleA: <div className="font-bold">Total Allocated</div>,
      valueA: (
        <div className="text-left">
          <span className="ml-4">
            {productDetails?.totalQuantityAllocated !== 0 && units?.length > 0
              ? unitConverter(productDetails?.totalQuantityAllocated, units)
              : productDetails?.totalQuantityAllocated}
          </span>
        </div>
      ),
      titleB: <div className="font-bold">Total Dispatched</div>,
      valueB: (
        <div className="text-left">
          <span className="ml-4">
            {productDetails?.totalQuantityDispatched !== 0 && units?.length > 0
              ? unitConverter(productDetails?.totalQuantityDispatched, units)
              : productDetails?.totalQuantityDispatched}
          </span>
        </div>
      ),
    },
    {
      titleA: <div className="font-bold">Total Purchase Amount</div>,
      valueA: (
        <div className="text-left">
          <span className="ml-4">{`$ ${(
            Math.floor((productDetails?.totalPurchaseAmount || 0) * 100) / 100
          ).toFixed(2)}`}</span>
        </div>
      ),
      titleB: <div className="font-bold">Total sales amount </div>,
      valueB: (
        <div className="text-left">
          <span className="ml-4">
            {`$ ${(Math.floor((productDetails?.totalSalesAmount || 0) * 100) / 100).toFixed(2)}`}
          </span>
        </div>
      ),
    },
    {
      titleA: <div className="font-bold">Purchase Description</div>,
      valueA: (
        <div className="text-left">
          {inEdit.includes('purchaseDescription') ? (
            <>
              <div className="flex">
                <PFTextBox
                  value={productDetails?.purchaseDescription || ''}
                  onChange={e => editorCallback('purchaseDescription', e)}
                />

                <i
                  className={`pi pi-check hand-cursor ml-2 mt-3`}
                  onClick={() => {
                    onSave();
                    setInEdit([]);
                  }}
                ></i>
                <i
                  className={`pi pi-times hand-cursor ml-2 mt-3`}
                  onClick={() => {
                    onCancle();
                    setInEdit([]);
                  }}
                ></i>
              </div>
            </>
          ) : (
            <>
              <i
                className={`pi pi-pencil hand-cursor mr-2`}
                onClick={() => setInEdit(['purchaseDescription'])}
              ></i>
              {`${productDetails?.purchaseDescription || ''}`}
            </>
          )}
        </div>
      ),
      titleB: <div className="font-bold">Sales Description</div>,
      valueB: (
        <div className="text-left">
          {inEdit.includes('salesDescription') ? (
            <>
              <div className="flex">
                <PFTextBox
                  value={productDetails?.salsesDescription || ''}
                  onChange={e => editorCallback('salesDescription', e)}
                />

                <i
                  className={`pi pi-check hand-cursor ml-2 mt-3`}
                  onClick={() => {
                    onSave();
                    setInEdit([]);
                  }}
                ></i>
                <i
                  className={`pi pi-times hand-cursor ml-2 mt-3`}
                  onClick={() => {
                    onCancle();
                    setInEdit([]);
                  }}
                ></i>
              </div>
            </>
          ) : (
            <>
              <i
                className={`pi pi-pencil hand-cursor mr-2`}
                onClick={() => setInEdit(['salesDescription'])}
              ></i>
              {`${productDetails?.salesDescription || ''}`}
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className="">
      <Toast ref={toast} />
      <PFDataTable
        columns={columns}
        data={data}
        paginator={false}
        wrapperClass="inventory-datatable"
        // loading={dataTableloading}
        loading={false}
      />
    </div>
  );
};

export default ProductDetails;
