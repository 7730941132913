import { PROJECT_REPORT } from '../../../constants';
import { getLocalClientDetails, momentTz } from '../../../utils/Helpers';
import { EVENT_COLORS } from '../constants/constants';

import { eventColorGenerator } from './color-codes.helper';
import { mapProjectTypeToColor } from './helpers';

export const getPeriod = type => {
  let period;
  switch (type) {
    case 'Day':
    case 'TimelineDay':
      period = 'D';
      break;
    case 'Week':
    case 'TimelineWeek':
      period = 'W';
      break;
    case 'Month':
    case 'TimelineMonth':
      period = 'M';
      break;
  }
  return period;
};
export const getDefaultView = () => {
  let defaultView = 'TimelineDay';
  const client_details = getLocalClientDetails();
  if (client_details?.default_calender_view) {
    switch (client_details.default_calender_view.toLowerCase()) {
      case 'day':
        defaultView = 'TimelineDay';
        break;
      case 'week':
        defaultView = 'TimelineWeek';
        break;
      case 'month':
        defaultView = 'TimelineMonth';
        break;
      default:
        defaultView = 'TimelineDay';
    }
  }
  return defaultView;
};
export const getCalendarDefaultView = () => {
  let defaultView = 'Day';
  const client_details = getLocalClientDetails();
  if (client_details?.default_calender_view) {
    switch (client_details.default_calender_view.toLowerCase()) {
      case 'day':
        defaultView = 'Day';
        break;
      case 'week':
        defaultView = 'Week';
        break;
      case 'month':
        defaultView = 'Month';
        break;
      default:
        defaultView = 'Day';
    }
  }
  return defaultView;
};

export const selectedRapidSlotSyncFusion = selectInfo => {
  const startSlotTime = new Date(selectInfo?.data?.StartTime);
  const endSlotTime = new Date(selectInfo?.data?.EndTime);
  startSlotTime?.setMinutes(startSlotTime?.getMinutes() + 60);

  const timeDifference = endSlotTime?.getTime() - startSlotTime?.getTime();
  const minutesDifference = timeDifference / (1000 * 60);
  return { minutesDifference, startSlotTime };
};
export const mergeAndDuplicateValues = (primary, secondary) => {
  const toArray = value => {
    if (Array.isArray(value)) return value.map(String);
    if (typeof value === 'string') return value.split(',');
    if (typeof value === 'number') return [String(value)];
    return [];
  };

  const uniqueValues = Array.from(
    new Set([...toArray(primary), ...toArray(secondary)])
  );

  return uniqueValues.join(',');
};

export const projectRecordData = (
  projectDetailsRecords,
  eventDataLocal,
  view
) => {
  for (const cal of projectDetailsRecords) {
    if (cal?.Id && cal?.StartTime && cal?.EndTime) {
      let eventDataLocalEventColor = {
        bgColor: EVENT_COLORS[1]?.bgColor,
        textColor: EVENT_COLORS[1]?.textColor,
      };
      if (
        view !== PROJECT_REPORT.FULL_CALENDAR &&
        cal.eventData?.project_type &&
        (cal?.eventData?.config_color_code ||
          cal?.eventData?.project_color_code)
      ) {
        const mappedColor = mapProjectTypeToColor(
          cal?.eventData?.config_color_code ||
            cal?.eventData?.project_color_code,
          cal?.eventData?.project_type,
          cal?.eventData?.project_status,
          cal?.eventData?.color_code
        );

        eventDataLocalEventColor = {
          bgColor: mappedColor?.bgColor,
          textColor: mappedColor?.textColor,
        };
      } else if (
        view === PROJECT_REPORT.FULL_CALENDAR &&
        cal?.eventData?.project_type &&
        cal?.eventData?.installer_id
      ) {
        const generatedColor = eventColorGenerator(
          cal?.eventData?.installer_id
        );
        eventDataLocalEventColor = {
          bgColor: generatedColor,
          textColor: '#21272A',
        };
      }
      const eventLocalData = {
        Id: cal?.Id,
        recordId: cal?.Id,
        EndTime: cal?.EndTime,
        StartTime: cal?.StartTime,
        ResourceId: cal?.ResourceId,
        IsAllDay: cal?.IsAllDay,
        RecurrenceRule: cal?.RecurrenceRule,
        eventData: {
          installer_name: cal?.eventData?.installer_name,
          id: cal?.eventData?.project_id,
          title: cal?.eventData?.project_desc || '',
          resourceId: cal?.eventData?.installer_id,
          backgroundColor: eventDataLocalEventColor?.bgColor,
          textColor: eventDataLocalEventColor?.textColor,
          border: 'none',
          installer_id: cal?.eventData?.installer_id,
          project_id: cal?.eventData?.project_id,
          start: cal?.eventData?.schedule_date,
          end: cal?.eventData?.schedule_end_date,
          project_desc: cal?.eventData?.project_desc,
          store_number: cal?.eventData?.store_number,
          project_type: cal?.eventData?.project_type,
          source_system_id: cal?.eventData?.source_system_id,
          category: cal?.eventData?.category,
          project_status: cal?.eventData?.project_status,
          project_number: cal?.eventData?.project_number,
          client_name: cal?.eventData?.client_name,
          Time:
            momentTz(cal?.eventData?.schedule_date).format('hh:mm A') +
            ' - ' +
            momentTz(cal?.eventData?.schedule_end_date).format('hh:mm A'),
          mobile_phone: cal?.eventData?.mobile_phone,
          address: cal?.eventData?.address,
          timeoff_status: cal?.eventData?.status_id,
          time_off_start_date: cal?.eventData?.time_off_start_date,
          time_off_end_date: cal?.eventData?.time_off_end_date,
          total_sale_amount: cal?.eventData?.total_sale_amount,
          total_revenue: cal?.eventData?.total_revenue,
          confirmation_status: cal?.eventData?.confirmation_status,
          is_customer_requested: cal?.eventData?.is_customer_requested,
          overlap: true,
          background:
            'repeating-linear-gradient(135deg, #696969 10px,#696969 12px,transparent 12px,transparent 20px)',

          schedule_date: cal?.eventData?.schedule_date,
          schedule_end_date: cal?.eventData?.schedule_end_date,
          basic_labor_sum: cal?.eventData?.basic_labor_sum,
        },
      };
      eventDataLocal.push(eventLocalData);
    }
  }
};

export const blockTimeOffRecordData = (timeOffRecords, eventDataLocal) => {
  for (const cal of timeOffRecords) {
    if (cal?.Id && cal?.StartTime && cal?.EndTime) {
      if (cal?.isBlockedEvents) {
        //Event is Type of Blocked Event
        const eventLocalData = {
          Id: cal?.Id,
          recordId: cal?.Id,
          EndTime: cal?.eventData?.end,
          StartTime: cal?.eventData?.start,
          ResourceId: cal?.ResourceId,
          IsAllDay: cal?.IsAllDay,
          RecurrenceRule: cal?.RecurrenceRule,
          isBlockedEvents: cal?.isBlockedEvents,
          eventData: {
            installer_name: cal?.eventData?.installer_name,
            id: cal?.Id,
            title: cal?.eventData?.title || '',
            timeOffRequestId: cal?.eventData?.timeOffRequestId,
            resourceId: cal?.eventData?.installer_id,
            backgroundColor: EVENT_COLORS[4]?.bgColor,
            textColor: EVENT_COLORS[4]?.textColor,
            border: 'none',
            installer_id: cal?.eventData?.installer_id,
            start: cal?.eventData?.start,
            end: cal?.eventData?.end,
            status_id: cal?.eventData?.status_id,
            is_blocked_time: cal?.eventData?.is_blocked_time,
            is_recurring_every_other_week:
              cal?.eventData?.is_recurring_every_other_week,
            overlap: true,
            background:
              'repeating-linear-gradient(135deg, #696969 10px,#696969 12px,transparent 12px,transparent 20px)',
          },
        };
        eventDataLocal.push(eventLocalData);
      } else {
        if (cal?.eventData?.status_id === 88) {
          let StartTime = cal?.eventData?.start
            ? momentTz(cal?.eventData?.start)?.format()
            : null;
          let EndTime = cal?.eventData?.isRecurring
            ? `${cal?.eventData?.start?.split('T')[0]}T${momentTz(cal?.eventData?.end)?.format()?.split('T')[1]}`
            : momentTz(cal?.eventData?.end)?.format();
          let rrule = {
            freq: 'weekly',
            interval: cal?.eventData?.is_recurring_every_other_week,
            byweekday: [
              ...(cal?.eventData?.sun ? ['su'] : []),
              ...(cal?.eventData?.mon ? ['mo'] : []),
              ...(cal?.eventData?.tue ? ['tu'] : []),
              ...(cal?.eventData?.wed ? ['we'] : []),
              ...(cal?.eventData?.thus ? ['th'] : []),
              ...(cal?.eventData?.fri ? ['fr'] : []),
              ...(cal?.eventData?.sat ? ['sa'] : []),
            ],
            until: momentTz(cal?.eventData?.end).format(),
            dtstart: momentTz(cal?.eventData?.start).format(),
            byDay: [
              ...(cal?.eventData?.sun ? ['SU'] : []),
              ...(cal?.eventData?.mon ? ['MO'] : []),
              ...(cal?.eventData?.tue ? ['TU'] : []),
              ...(cal?.eventData?.wed ? ['WE'] : []),
              ...(cal?.eventData?.thus ? ['TH'] : []),
              ...(cal?.eventData?.fri ? ['FR'] : []),
              ...(cal?.eventData?.sat ? ['SA'] : []),
            ],
          };
          let RecurrenceRule = cal?.eventData?.isRecurring
            ? `FREQ=WEEKLY;BYDAY=${rrule?.byDay?.join(',')};INTERVAL=${rrule?.interval};UNTIL=${new Date(
                `${cal?.eventData?.end}`
              )
                ?.toISOString()
                ?.replace(/[-:]/g, '')
                ?.replace(/\.\d{3}Z$/, 'Z')}`
            : '';
          const eventLocalData = {
            Id: cal?.Id,
            recordId: cal?.Id,
            EndTime,
            StartTime,
            ResourceId: cal?.ResourceId,
            IsAllDay: cal?.IsAllDay,
            RecurrenceRule: RecurrenceRule,
            isApprovedEventsAvailabilityTimeOff: true,
            eventData: {
              installer_name: cal?.eventData?.installer_name,
              id: cal?.Id,
              title: cal?.eventData?.title || '',
              timeOffRequestId: cal?.eventData?.timeOffRequestId,
              resourceId: cal?.eventData?.installer_id,
              backgroundColor: 'rgb(161, 161, 161, 0.4)',
              textColor: EVENT_COLORS[4]?.textColor,
              border: 'none',
              installer_id: cal?.eventData?.installer_id,
              start: cal?.eventData?.start,
              end: cal?.eventData?.end,
              status_id: cal?.eventData?.status_id,
              is_blocked_time: cal?.eventData?.is_blocked_time,
              is_recurring_every_other_week:
                cal?.eventData?.is_recurring_every_other_week,
              overlap: true,
              rrule: rrule,
              allDay: cal?.eventData?.allDay ? true : false,
              background:
                'repeating-linear-gradient(135deg, #696969 10px,#696969 12px,transparent 12px,transparent 20px)',
            },
          };
          eventDataLocal.push(eventLocalData);
        } else if (cal?.eventData?.status_id === 86) {
          let StartTime = cal?.eventData?.start
            ? momentTz(cal?.eventData?.start)?.format()
            : null;
          let EndTime = cal?.eventData?.isRecurring
            ? `${cal?.eventData?.start?.split('T')[0]}T${momentTz(cal?.eventData?.end)?.format()?.split('T')[1]}`
            : momentTz(cal?.eventData?.end)?.format();
          let rrule = {
            freq: 'weekly',
            interval: cal?.eventData?.is_recurring_every_other_week,
            byweekday: [
              ...(cal?.eventData?.sun ? ['su'] : []),
              ...(cal?.eventData?.mon ? ['mo'] : []),
              ...(cal?.eventData?.tue ? ['tu'] : []),
              ...(cal?.eventData?.wed ? ['we'] : []),
              ...(cal?.eventData?.thus ? ['th'] : []),
              ...(cal?.eventData?.fri ? ['fr'] : []),
              ...(cal?.eventData?.sat ? ['sa'] : []),
            ],
            until: momentTz(cal?.eventData?.end).format(),
            dtstart: momentTz(cal?.eventData?.start).format(),
            byDay: [
              ...(cal?.eventData?.sun ? ['SU'] : []),
              ...(cal?.eventData?.mon ? ['MO'] : []),
              ...(cal?.eventData?.tue ? ['TU'] : []),
              ...(cal?.eventData?.wed ? ['WE'] : []),
              ...(cal?.eventData?.thus ? ['TH'] : []),
              ...(cal?.eventData?.fri ? ['FR'] : []),
              ...(cal?.eventData?.sat ? ['SA'] : []),
            ],
          };
          let RecurrenceRule = cal?.eventData?.isRecurring
            ? `FREQ=WEEKLY;BYDAY=${rrule?.byDay?.join(',')};INTERVAL=${rrule?.interval};UNTIL=${new Date(
                `${cal?.eventData?.end}`
              )
                ?.toISOString()
                ?.replace(/[-:]/g, '')
                ?.replace(/\.\d{3}Z$/, 'Z')}`
            : '';
          const eventLocalData = {
            Id: cal?.Id,
            recordId: cal?.Id,
            EndTime,
            StartTime,
            ResourceId: cal?.ResourceId,
            IsAllDay: cal?.IsAllDay,
            RecurrenceRule: RecurrenceRule,
            isTentativeTimeOff: true,
            eventData: {
              installer_name: cal?.eventData?.installer_name,
              id: cal?.Id,
              title: cal?.eventData?.title || '',
              timeOffRequestId: cal?.eventData?.timeOffRequestId,
              resourceId: cal?.eventData?.installer_id,
              backgroundColor: EVENT_COLORS[4]?.bgColor,
              textColor: EVENT_COLORS[4]?.textColor,
              border: 'none',
              installer_id: cal?.eventData?.installer_id,
              start: cal?.eventData?.start,
              end: cal?.eventData?.end,
              status_id: cal?.eventData?.status_id,
              is_blocked_time: cal?.eventData?.is_blocked_time,
              is_recurring_every_other_week:
                cal?.eventData?.is_recurring_every_other_week,
              overlap: true,
              rrule: rrule,
              allDay: cal?.eventData?.allDay ? true : false,
              background:
                'repeating-linear-gradient(135deg, #696969 10px,#696969 12px,transparent 12px,transparent 20px)',
            },
          };
          eventDataLocal.push(eventLocalData);
        }
      }
    }
  }
};
