import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: true,
  rolesList: [],
  userDetailsFormik: null,
  isUserDetailsValid: false,
  isUserDetailsFormDirty: false,
  newUserImage: null,
  userImage: null,
  userName: '',
  userDetails: null,
};

const userFormDetailsSlice = createSlice({
  name: 'userFormDetails',
  initialState: initialState,
  reducers: {
    clearState(state) {
      state.isLoading = true;
      state.rolesList = [];
      state.userDetailsFormik = null;
      state.isUserDetailsValid = false;
      state.isUserDetailsFormDirty = false;
      state.newUserImage = null;
      state.userImage = null;
      state.userName = null;
      state.userDetails = null;
    },
    setUserDetailsForm(state, { payload }) {
      state.isLoading = Object.prototype.hasOwnProperty.call(
        payload,
        'isLoading'
      )
        ? payload.isLoading
        : state.isLoading;
      state.rolesList = Object.prototype.hasOwnProperty.call(
        payload,
        'rolesList'
      )
        ? payload.rolesList
        : state.rolesList;
      state.userDetailsFormik = Object.prototype.hasOwnProperty.call(
        payload,
        'userDetailsFormik'
      )
        ? payload.userDetailsFormik
        : state.userDetailsFormik;
      state.isUserDetailsValid = Object.prototype.hasOwnProperty.call(
        payload,
        'isUserDetailsValid'
      )
        ? payload.isUserDetailsValid
        : state.isUserDetailsValid;
      state.isUserDetailsFormDirty = Object.prototype.hasOwnProperty.call(
        payload,
        'isUserDetailsFormDirty'
      )
        ? payload.isUserDetailsFormDirty
        : state.isUserDetailsFormDirty;
      state.newUserImage = Object.prototype.hasOwnProperty.call(
        payload,
        'newUserImage'
      )
        ? payload.newUserImage
        : state.newUserImage;
      state.userImage = Object.prototype.hasOwnProperty.call(
        payload,
        'userImage'
      )
        ? payload.userImage
        : state.userImage;
      state.userName = Object.prototype.hasOwnProperty.call(payload, 'userName')
        ? payload.userName
        : state.userName;
      state.userDetails = Object.prototype.hasOwnProperty.call(
        payload,
        'userDetails'
      )
        ? payload.userDetails
        : state.userDetails;
    },
  },
});

export const { setUserDetailsForm, clearState } = userFormDetailsSlice.actions;

export const userFormDetailsReducer = userFormDetailsSlice.reducer;
