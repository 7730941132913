import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';

import {
  addProjectNotes,
  updateInstallerSchedule,
} from '../services/calendar.service';
import { validateReschedule } from '../../../utils/reschedule.helper';
import { momentTz } from '../../../utils/Helpers';
type TProps = {
  calenderRefreshHandler: () => void;
  rescheduleDialogData: {
    isRescheduleDialogOpen: boolean;
    rescheduleDropInfo: {
      currentDetails: {
        eventData: {
          project_id: string;
          project_status: string;
          installer_id: number;
          schedule_date: Date;
          schedule_end_date: Date;
        };
        ResourceId: string;
      };

      endDateStr: Date;
      startDateStr: Date;
    };
  };
  // eslint-disable-next-line no-unused-vars
  setRescheduleDialogData: (param: object) => void;
  toast: React.RefObject<Toast>;
  resourceData: [{ id: number; title: string }];
  setDialogProps: () => null;
  setIsOpen: () => null;
};

const RescheduleJobDialog = ({
  calenderRefreshHandler,
  rescheduleDialogData,
  setRescheduleDialogData,
  toast,
  resourceData,
  setDialogProps,
}: TProps) => {
  const { projectStatusType } = useSelector(
    (state: {
      projectStatusType: { projectStatusType: [{ [key: string]: unknown }] };
    }) => state.projectStatusType
  );
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      type: 6,
      note_text: '',
      follow_up_date: null,
    },
    validationSchema: Yup.object({
      note_text: Yup.string().trim(),
    }),
    onSubmit: async values => {
      if (values?.note_text?.length > 0) {
        setLoading(true);
        await addProjectNotes(
          parseInt(
            rescheduleDialogData?.rescheduleDropInfo?.currentDetails?.eventData
              ?.project_id
          ),
          {
            note_category_id: values.type,
            note_text: values.note_text.trim() || null,
            follow_up_date: values.follow_up_date,
          }
        );
      }
      if (rescheduleDialogData?.isRescheduleDialogOpen) {
        setLoading(true);
        const statusType = projectStatusType?.find(
          item =>
            item?.status ===
            rescheduleDialogData?.rescheduleDropInfo?.currentDetails?.eventData
              ?.project_status
        );

        const updateObj = {
          installerId: parseInt(
            rescheduleDialogData?.rescheduleDropInfo?.currentDetails?.ResourceId
          ),
          projectId: parseInt(
            rescheduleDialogData?.rescheduleDropInfo?.currentDetails?.eventData
              ?.project_id
          ),
          projectStartDateTime: momentTz(
            rescheduleDialogData?.rescheduleDropInfo?.startDateStr
          ),
          projectEndDateTime: momentTz(
            rescheduleDialogData?.rescheduleDropInfo?.endDateStr
          ),
          old_installerId:
            rescheduleDialogData?.rescheduleDropInfo.currentDetails?.eventData?.installer_id?.toString(),
          old_startDateTime: momentTz(
            rescheduleDialogData?.rescheduleDropInfo?.currentDetails?.eventData
              ?.schedule_date
          ),
          old_endDateTime: momentTz(
            rescheduleDialogData?.rescheduleDropInfo?.currentDetails?.eventData
              ?.schedule_end_date
          ),
          status_id: statusType?.status_id || '',
        };
        const technicianName = resourceData.find(
          ({ id }) => id == updateObj.installerId
        );
        validateReschedule(
          {
            ...updateObj,
            technician_name: technicianName?.title || '',
          },
          setDialogProps,
          (value: boolean) => {
            setRescheduleDialogData({
              ...rescheduleDialogData,
              isRescheduleDialogOpen: value,
            });
          },
          async (action: boolean) => {
            try {
              if (action) {
                const response = await updateInstallerSchedule(updateObj);
                if (response && response?.data && response?.data?.status) {
                  setLoading(false);
                  toast.current.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: response?.data?.message
                      ? `${response?.data?.message}`
                      : 'Project has been scheduled successfully',
                  });
                }
              }
            } catch (error) {
              setLoading(false);
              console.error(error, '...error updateInstallerSchedule');
              toast.current.show({
                severity: 'error',
                summary: 'Error',
                detail: 'Something went Wrong.',
              });
            } finally {
              // setIsOpen(false);
              setLoading(false);
              setRescheduleDialogData({
                isRescheduleDialogOpen: false,
                rescheduleDropInfo: {},
              });
              formik.handleReset(undefined);
              calenderRefreshHandler();
            }
          }
        );
      }
    },
    enableReinitialize: true,
  });
  const footerContent = (
    <div>
      <Button
        label="Skip"
        onClick={() => {
          setRescheduleDialogData({
            isRescheduleDialogOpen: false,
            rescheduleDropInfo: {},
          });
        }}
        outlined
        disabled={loading}
        size="small"
      />
      <Button
        label={loading ? 'Submitting...' : `Continue`}
        disabled={loading}
        onClick={() => {
          formik.handleSubmit();
        }}
        type="submit"
        size="small"
      />
    </div>
  );
  return (
    <>
      <Dialog
        header="Reschedule Confirmation"
        visible={rescheduleDialogData?.isRescheduleDialogOpen}
        style={{ width: '30vw' }}
        footer={footerContent}
        closable={false}
        draggable={false}
        pt={{
          header: {
            className: 'bg-teal-50 border-bottom-1 py-3 ',
          },
          headerTitle: {
            className: 'text-lg',
          },
          content: {
            className: 'pb-2',
          },
        }}
        onHide={() => {
          setRescheduleDialogData({
            isRescheduleDialogOpen: false,
            rescheduleDropInfo: {},
          });
        }}
      >
        <form>
          <div className="grid text-sm flex-wrap pb-0">
            <b className="col-12 font-bold text-color-secondary mt-2">
              Please confirm, if you would like to reschedule this job for{' '}
              {momentTz(
                rescheduleDialogData?.rescheduleDropInfo?.startDateStr
              )?.format('YYYY-MM-DD hh:mm A')}{' '}
              -{' '}
              {momentTz(
                rescheduleDialogData?.rescheduleDropInfo?.endDateStr
              )?.format('YYYY-MM-DD hh:mm A')}
            </b>
            <div className="p-float-label col-10">
              <InputText
                id="note_text"
                name="note_text"
                className="w-full text-sm"
                onChange={e => {
                  formik.setFieldValue('note_text', e.target.value);
                }}
                onBlur={formik.handleBlur}
                value={formik?.values?.note_text || ''}
              />
              <label htmlFor="note_text"> Note</label>
            </div>
          </div>
        </form>
      </Dialog>
    </>
  );
};

export default RescheduleJobDialog;
