import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  buttonGrid: {
    color: theme.palette.common.white,
    fontWeight: 600,
    '& .MuiSvgIcon-root': {
      fill: theme.palette.common.white,
    },
    '&.react-grid-item': {
      height: '50px !important',
      minHeight: '50px !important',
    },
    '&.MuiPaper-root': {
      '&:hover': {
        opacity: 0.6,
      },
    },
    '& .chart-loader': {
      display: 'none',
    },
  },
  widgetPaper: {
    height: 'inherit',
    width: 'inherit',
    border: '1px Solid #0074d9',
    borderRadius: '5px',
    padding: '10px 20px 5px 20px',
    '& *': {
      cursor: 'pointer',
    },
  },
  widgetPaperGauge: {
    height: '280px',
    width: 'inherit',
    boxShadow: '0 0 5px rgb(0 0 0 / 30%)',
    borderRadius: '0px',
    padding: '10px',
    border: 'none',
    '& *': {
      cursor: 'pointer',
    },
  },
  widgetPaperPie: {
    height: '350px',
    width: 'inherit',
    boxShadow: '0 0 5px rgb(0 0 0 / 30%)',
    borderRadius: '0px',
    padding: '10px',
    border: 'none',
    '& *': {
      cursor: 'pointer',
    },
  },
  addIconWrapper: {
    height: '100%',
  },
  addIcon: {
    width: '24px',
    height: '24px',
  },
  backdropRoot: {
    color: '#fff',
    fill: '#fff',
    zIndex: theme.zIndex.drawer + 1,
  },
  buttonContainer: {
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  gaugeContainer: {
    padding: '0',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  dashboardButton: {
    width: '100%',
    height: '100%',
  },
  widgetContainer: {
    width: '100%',
  },
  layout: props => ({
    background: props?.isDragging
      ? `url('/images/drag-background.svg')`
      : 'none',
    backgroundRepeat: props?.isDragging && 'repeat-y',
    backgroundPosition: props?.isDragging && '0px -4px',
    backgroundSize: props?.isDragging && '100% 52px',
    '& .react-grid-item.static': {
      position: 'absolute !important',
    },
  }),
  widgetHeaderContainer: {
    marginBottom: '0px',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
