import { React, useState, useRef, useEffect } from 'react';
import { Card } from 'primereact/card';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import 'primeflex/primeflex.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { InputNumber } from 'primereact/inputnumber';

import { getWarrantyInfo, saveWarrantyInfo } from './WarrantyInfo.services';
const WarrantyInfo = ({ projectId }) => {
  const [warrantyDetails, setWarrantyDetails] = useState({});
  const toast = useRef(null);
  useEffect(async () => {
    const response = await getWarrantyInfo(projectId);
    if (response) {
      setWarrantyDetails(response.data);
    }
  }, [projectId]);
  const warrantyInfoValidationSchema = Yup.object().shape({
    model: Yup.string().trim(),
    capacity: Yup.string().trim(),
    serial_number: Yup.string().trim(),
    transfer_switch_1_serial_number: Yup.string().trim(),
    transfer_switch_2_serial_number: Yup.string().trim(),
    warranty_date: Yup.date(),
    warranty_expiration: Yup.date(),
    warranty_control_number: Yup.string().trim(),
    service_agreement_date: Yup.date(),
    service_agreement_expiration: Yup.date(),
    contact_date_next_service: Yup.date(),
    remote_monitoring_opt_date: Yup.date(),
    remote_monitoring_expiration_date: Yup.date(),
    ssid: Yup.string().trim(),
    max_address: Yup.string(),
  });

  const warrantyInfoFormik = useFormik({
    initialValues: {
      project_warranty_id: warrantyDetails?.project_warranty_id || '',
      model: warrantyDetails?.model || '',
      capacity: warrantyDetails?.capacity || '',
      serial_number: warrantyDetails?.serial_number || '',
      transfer_switch_1_serial_number:
        warrantyDetails?.transfer_switch_1_serial_number || '',
      transfer_switch_2_serial_number:
        warrantyDetails?.transfer_switch_2_serial_number || '',
      warranty_date: warrantyDetails?.warranty_date || '',
      warranty_expiration: warrantyDetails?.warranty_expiration || '',
      warranty_control_number: warrantyDetails?.warranty_control_number || '',
      service_agreement_date: warrantyDetails?.service_agreement_date || '',
      service_agreement_expiration_date:
        warrantyDetails?.service_agreement_expiration_date || '',
      contact_date_next_service:
        warrantyDetails?.contact_date_next_service || '',
      service_contract_amount: warrantyDetails?.service_contract_amount || 0,
      remote_monitoring_opt_date:
        warrantyDetails?.remote_monitoring_opt_date || '',
      remote_monitoring_expiration_date:
        warrantyDetails?.remote_monitoring_expiration_date || '',
      ssid: warrantyDetails?.ssid || '',
      max_address: warrantyDetails?.max_address || '',
    },
    validationSchema: warrantyInfoValidationSchema,
    onSubmit: async values => {
      const payload = {
        project_warranty_id: values?.project_warranty_id
          ? values?.project_warranty_id
          : undefined,
        model: values?.model ? values?.model : null,
        capacity: values?.capacity ? values?.capacity : null,
        serial_number: values?.serial_number ? values?.serial_number : null,
        transfer_switch_1_serial_number: values?.transfer_switch_1_serial_number
          ? values?.transfer_switch_1_serial_number
          : null,
        transfer_switch_2_serial_number: values?.transfer_switch_2_serial_number
          ? values?.transfer_switch_2_serial_number
          : null,
        warranty_date: values?.warranty_date ? values?.warranty_date : null,
        warranty_expiration: values?.warranty_expiration
          ? values?.warranty_expiration
          : null,
        warranty_control_number: values?.warranty_control_number
          ? values?.warranty_control_number
          : null,

        service_agreement_date: values?.service_agreement_date
          ? values?.service_agreement_date
          : null,
        service_agreement_expiration_date:
          values?.service_agreement_expiration_date
            ? values?.service_agreement_expiration_date
            : null,
        contact_date_next_service: values?.contact_date_next_service
          ? values?.contact_date_next_service
          : null,
        service_contract_amount: values?.service_contract_amount
          ? values?.service_contract_amount
          : null,
        remote_monitoring_opt_date: values?.remote_monitoring_opt_date
          ? values?.remote_monitoring_opt_date
          : null,
        remote_monitoring_expiration_date:
          values?.remote_monitoring_expiration_date
            ? values?.remote_monitoring_expiration_date
            : null,
        ssid: values?.ssid ? values?.ssid : null,
        max_address: values?.max_address ? values?.max_address : null,
      };
      if (values?.project_warranty_id) {
        const response = await saveWarrantyInfo(projectId, payload);

        if (response) {
          setWarrantyDetails(response.data);
        }

        if (response?.status === 200) {
          toast.current.show({
            severity: 'success',
            summary: 'Warranty Info updated successfully.',
          });
        } else {
          toast.current.show({
            severity: 'error',
            summary: 'Unable to update Warranty Info',
          });
        }
      } else {
        const response = await saveWarrantyInfo(projectId, payload);
        if (response) {
          setWarrantyDetails(response.data);
        }

        if (response?.status === 200) {
          toast.current.show({
            severity: 'success',
            summary: 'Warranty Info added successfully.',
          });
        } else {
          toast.current.show({
            severity: 'error',
            summary: 'Unable to add Warranty Info',
          });
        }
      }
    },
    enableReinitialize: true,
  });

  const headerTemplate = () => {
    return (
      <div className="flex justify-content-between align-content-center align-items-center pr-3 pt-3">
        <h3>Warranty Details</h3>
        <Button
          type="button"
          label="Save"
          size="small"
          severity="primary"
          disabled={
            warrantyInfoFormik.dirty && warrantyInfoFormik.isValid
              ? false
              : true
          }
          onClick={warrantyInfoFormik.handleSubmit}
        />
      </div>
    );
  };
  return (
    <>
      <Toast ref={toast}></Toast>
      <Card
        className="mb-3 shadow-none"
        header={headerTemplate}
        pt={{ body: { className: 'pt-0 px-0' } }}
      >
        <div className="grid">
          <div className="grid col-12">
            <div className="col-12 sm:col-12 md:col-6 lg:col-4">
              <span className="p-float-label">
                <InputText
                  id="model"
                  className="p-inputtext-sm w-full"
                  value={warrantyInfoFormik?.values?.model || ''}
                  onChange={warrantyInfoFormik.handleChange}
                  onBlur={warrantyInfoFormik.handleBlur}
                  maxLength={256}
                />
                <label htmlFor="model" className="text-sm">
                  Model
                </label>
              </span>
            </div>
            <div className="col-12 sm:col-12 md:col-6 lg:col-8">
              <span className="p-float-label">
                <InputText
                  id="capacity"
                  className="p-inputtext-sm w-full"
                  value={warrantyInfoFormik?.values?.capacity}
                  onChange={warrantyInfoFormik.handleChange}
                  onBlur={warrantyInfoFormik.handleBlur}
                  size="sm"
                  maxLength={256}
                />
                <label htmlFor="Capacity" className="text-sm">
                  Capacity
                </label>
              </span>
            </div>
          </div>
          <div className="grid col-12">
            <div className="col-12 sm:col-12 md:col-12">
              <span className="p-float-label">
                <InputText
                  id="serial_number"
                  className="p-inputtext-sm w-full"
                  value={warrantyInfoFormik?.values?.serial_number}
                  onChange={warrantyInfoFormik.handleChange}
                  onBlur={warrantyInfoFormik.handleBlur}
                  maxLength={256}
                />
                <label htmlFor="serial_number" className="text-sm">
                  Serial Number
                </label>
              </span>
            </div>
          </div>
          <div className="grid col-12">
            <div className="col-12 sm:col-12 md:col-6 lg:col-4">
              <span className="p-float-label">
                <InputText
                  className="p-inputtext-sm w-full"
                  id="transfer_switch_1_serial_number"
                  value={
                    warrantyInfoFormik?.values?.transfer_switch_1_serial_number
                  }
                  onChange={warrantyInfoFormik.handleChange}
                  onBlur={warrantyInfoFormik.handleBlur}
                  maxLength={256}
                />
                <label
                  htmlFor="transfer_switch_1_serial_number"
                  className="text-sm"
                >
                  Transfer Switch 1 Serial Number
                </label>
              </span>
            </div>
            <div className="col-12 sm:col-12 md:col-6 lg:col-8">
              <span className="p-float-label">
                <InputText
                  className="p-inputtext-sm w-full"
                  id="transfer_switch_2_serial_number"
                  value={
                    warrantyInfoFormik?.values?.transfer_switch_2_serial_number
                  }
                  onChange={warrantyInfoFormik.handleChange}
                  onBlur={warrantyInfoFormik.handleBlur}
                  maxLength={256}
                />
                <label
                  htmlFor="transfer_switch_2_serial_number"
                  className="text-sm"
                >
                  Transfer Switch 2 Serial Number
                </label>
              </span>
            </div>
          </div>
          <div className="grid col-12">
            <div className="col-12 sm:col-12 md:col-6 lg:col-3">
              <span className="p-float-label">
                <Calendar
                  className="p-inputtext-sm w-full"
                  id="warranty_date"
                  value={
                    warrantyInfoFormik?.values?.warranty_date
                      ? new Date(warrantyInfoFormik?.values?.warranty_date)
                      : ''
                  }
                  onChange={warrantyInfoFormik.handleChange}
                  onBlur={warrantyInfoFormik.handleBlur}
                  appendTo={'self'}
                />
                <label htmlFor="warranty_date" className="text-sm">
                  Warranty Date
                </label>
              </span>
              {warrantyInfoFormik?.touched.warranty_date &&
              warrantyInfoFormik?.errors?.warranty_date ? (
                <div className="text-red-500 text-sm">
                  {warrantyInfoFormik?.errors?.warranty_date}
                </div>
              ) : null}
            </div>
            <div className="col-12 sm:col-12 md:col-6 lg:col-3">
              <span className="p-float-label">
                <Calendar
                  className="p-inputtext-sm w-full"
                  id="warranty_expiration"
                  value={
                    warrantyInfoFormik?.values?.warranty_expiration
                      ? new Date(
                          warrantyInfoFormik?.values?.warranty_expiration
                        )
                      : ''
                  }
                  onChange={warrantyInfoFormik.handleChange}
                  onBlur={warrantyInfoFormik.handleBlur}
                  appendTo={'self'}
                />
                <label htmlFor="warranty_expiration" className="text-sm">
                  Warranty Expiration
                </label>
              </span>
            </div>
            <div className="col-12 sm:col-12 md:col-6 lg:col-6">
              <span className="p-float-label">
                <InputText
                  className="p-inputtext-sm w-full"
                  id="warranty_control_number"
                  value={warrantyInfoFormik?.values?.warranty_control_number}
                  onChange={warrantyInfoFormik.handleChange}
                  onBlur={warrantyInfoFormik.handleBlur}
                  maxLength={256}
                />
                <label htmlFor="warranty_control_number" className="text-sm">
                  Warranty Control Number
                </label>
              </span>
            </div>

            <div className="col-12 sm:col-12 md:col-6 lg:col-6">
              <span className="p-float-label">
                <Calendar
                  className="p-inputtext-sm w-full text-xs"
                  id="service_agreement_date"
                  value={
                    warrantyInfoFormik?.values?.service_agreement_date
                      ? new Date(
                          warrantyInfoFormik?.values?.service_agreement_date
                        )
                      : ''
                  }
                  onChange={warrantyInfoFormik.handleChange}
                  onBlur={warrantyInfoFormik.handleBlur}
                  appendTo={'self'}
                />
                <label htmlFor="service_agreement_date" className="text-sm">
                  Service Agreement Date
                </label>
              </span>
            </div>
            <div className="col-12 sm:col-12 md:col-6 lg:col-6">
              <span className="p-float-label">
                <Calendar
                  className="p-inputtext-sm w-full"
                  id="service_agreement_expiration_date"
                  value={
                    warrantyInfoFormik?.values
                      ?.service_agreement_expiration_date
                      ? new Date(
                          warrantyInfoFormik?.values?.service_agreement_expiration_date
                        )
                      : ''
                  }
                  onChange={warrantyInfoFormik.handleChange}
                  onBlur={warrantyInfoFormik.handleBlur}
                  appendTo={'self'}
                />
                <label htmlFor="service_agreement_expiration_date">
                  Service Agreement Expiration Date
                </label>
              </span>
            </div>
            <div className="col-12 sm:col-12 md:col-6 lg:col-6">
              <span className="p-float-label">
                <Calendar
                  className="p-inputtext-sm w-full"
                  id="contact_date_next_service"
                  value={
                    warrantyInfoFormik?.values?.contact_date_next_service
                      ? new Date(
                          warrantyInfoFormik?.values?.contact_date_next_service
                        )
                      : ''
                  }
                  onChange={warrantyInfoFormik.handleChange}
                  onBlur={warrantyInfoFormik.handleBlur}
                  appendTo={'self'}
                />
                <label htmlFor="contact_date_next_service" className="text-sm">
                  Contact Date for Next Service
                </label>
              </span>
            </div>
            <div className="col-12 sm:col-12 md:col-12 lg:col-6">
              <span className="p-float-label">
                <InputNumber
                  className="p-inputtext-sm w-full"
                  id="service_contract_amount"
                  value={warrantyInfoFormik?.values?.service_contract_amount}
                  onChange={event => {
                    warrantyInfoFormik.setFieldValue(
                      'service_contract_amount',
                      event.value
                    );
                  }}
                  onBlur={warrantyInfoFormik.handleBlur}
                  mode="currency"
                  currency="USD"
                />
                <label htmlFor="service_contract_amount" className="text-sm">
                  Service Contract Amount
                </label>
              </span>
            </div>
          </div>
          <div className="grid col-12">
            <div className="col-12 sm:col-12 md:col-6 lg:col-6">
              <span className="p-float-label">
                <Calendar
                  className="p-inputtext-sm w-full"
                  id="remote_monitoring_opt_date"
                  value={
                    warrantyInfoFormik?.values?.remote_monitoring_opt_date
                      ? new Date(
                          warrantyInfoFormik?.values?.remote_monitoring_opt_date
                        )
                      : ''
                  }
                  onChange={warrantyInfoFormik.handleChange}
                  onBlur={warrantyInfoFormik.handleBlur}
                  appendTo={'self'}
                />
                <label htmlFor="remote_monitoring_opt_date" className="text-sm">
                  Remote Monitoring Opt In Date
                </label>
              </span>
            </div>
            <div className="col-12 sm:col-12 md:col-6 lg:col-6">
              <span className="p-float-label">
                <Calendar
                  className="p-inputtext-sm w-full"
                  id="remote_monitoring_expiration_date"
                  value={
                    warrantyInfoFormik?.values
                      ?.remote_monitoring_expiration_date
                      ? new Date(
                          warrantyInfoFormik?.values?.remote_monitoring_expiration_date
                        )
                      : ''
                  }
                  onChange={warrantyInfoFormik.handleChange}
                  onBlur={warrantyInfoFormik.handleBlur}
                  appendTo={'self'}
                />
                <label
                  htmlFor="remote_monitoring_expiration_date"
                  className="text-sm"
                >
                  Remote Monitoring Expiration Date
                </label>
              </span>
            </div>
          </div>
          <div className="grid col-12">
            <div className="col-12 sm:col-12 md:col-6 lg:col-4">
              <span className="p-float-label">
                <InputText
                  className="p-inputtext-sm w-full"
                  id="ssid"
                  value={warrantyInfoFormik?.values?.ssid}
                  onChange={warrantyInfoFormik.handleChange}
                  onBlur={warrantyInfoFormik.handleBlur}
                  maxLength={256}
                />
                <label htmlFor="ssid" className="text-sm">
                  SSID
                </label>
              </span>
            </div>
            <div className="col-12 sm:col-12 md:col-6 lg:col-8">
              <span className="p-float-label">
                <InputText
                  className="p-inputtext-sm w-full"
                  id="max_address"
                  value={warrantyInfoFormik?.values?.max_address}
                  onChange={warrantyInfoFormik.handleChange}
                  onBlur={warrantyInfoFormik.handleBlur}
                  appendTo={'self'}
                  maxLength={1000}
                />
                <label htmlFor="max_address" className="text-sm">
                  MAX Address
                </label>
              </span>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};
export default WarrantyInfo;
