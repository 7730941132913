import axios from 'axios';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { CONFIG } from '../../../../constants';
import PFButton from '../../../shared/PFPrime/PFButton';
import { URL_CONSTANTS } from '../../../../constants/urlConstants';
export const invoiceBreadcrumb = (customerId, mode, breadCrumbData) => {
  let data = [];
  data = [
    ...data,

    {
      text: 'Clients',
      link: `/project/clients`,
    },
  ];
  if (breadCrumbData?.clientName && breadCrumbData?.customerId) {
    data = [
      ...data,
      {
        text: `${breadCrumbData?.clientName || ''}`,
        link: `/crm/view/customer/${breadCrumbData?.customerId}`,
      },
      {
        text: `Lead`,
        link: `/crm/view/customer/${breadCrumbData?.customerId}`,
      },
    ];
  }
  if (
    breadCrumbData?.opportunityId &&
    breadCrumbData?.opportunityNumber &&
    breadCrumbData?.customerId
  ) {
    data = [
      ...data,
      {
        text: `${breadCrumbData?.opportunityNumber}`,

        link: `/crm/${breadCrumbData?.customerId}/lead/view/${breadCrumbData?.opportunityId}`,
      },
    ];
  }
  if (breadCrumbData?.customerId && breadCrumbData?.opportunityId) {
    data = [
      ...data,
      {
        text: `Quotes`,
        link: `/crm/${breadCrumbData?.customerId}/quotes/${breadCrumbData?.opportunityId}`,
      },
    ];
  }

  if (
    breadCrumbData?.quoteId &&
    breadCrumbData?.quoteNumber &&
    breadCrumbData?.customerId &&
    breadCrumbData?.opportunityId
  ) {
    data = [
      ...data,
      {
        text: `${breadCrumbData?.quoteNumber}`,
        link: `/crm/${breadCrumbData?.customerId}/${breadCrumbData?.opportunityId}/quote/view/${breadCrumbData?.quoteId}`,
      },
    ];
  }
  if (
    breadCrumbData?.customerId &&
    breadCrumbData?.opportunityId &&
    breadCrumbData?.projectId
  ) {
    data = [
      ...data,
      {
        text: `Invoices`,
        link: `/crm/${breadCrumbData?.customerId}/${breadCrumbData?.opportunityId}/project/view/${breadCrumbData?.projectId}`,
      },
    ];
  }

  if (breadCrumbData?.invoiceId && breadCrumbData?.invoiceNumber) {
    data = [
      ...data,
      {
        text: `${breadCrumbData?.invoiceNumber}`,
        link: `/crm/invoice/view/${breadCrumbData?.invoiceId}`,
      },
    ];
  }

  return data;
};

export const getInvoiceStatus = async () => {
  try {
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/system/status?statusType=Invoice`
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const createInvoice = async payload => {
  let url = `${CONFIG?.API_CRM_URL}/api/invoice/create`;
  return await axios.post(url, payload);
};

export const updateInvoice = async (payload, id) => {
  let url = `${CONFIG?.API_CRM_URL}/api/invoice/update/${id}`;
  return await axios.put(url, payload);
};

export const getInvoice = async invoiceId => {
  let url = `${CONFIG?.API_CRM_URL}/api/invoice/${invoiceId}`;
  return await axios.get(url);
};

export const getInvoiceList = async projectId => {
  let url = `${CONFIG?.API_CRM_URL}/api/invoice/list/${projectId}`;
  return await axios.get(url);
};

export const getProjectInvoiceItems = async projectId => {
  let url = `${CONFIG?.API_CRM_URL}/api/invoice/items/${projectId}`;
  return await axios.get(url);
};

export const invoiceGridColumns = () => {
  const linkStyle = {
    textDecoration: 'none',
    color: '#609af8',
    cursor: 'pointer',
  };

  let main = [
    {
      field: '',
      header: '',
      sortable: false,
      filter: false,
      body: rowData => (
        <div className="flex w-max">
          <PFButton
            icon="pi pi-eye"
            outlined
            text
            className="text-white border-none"
            severity="primary"
            aria-label="View"
            onClick={() => {
              window.open(`/crm/invoice/view/${rowData?.invoice_id}`);
            }}
          />

          {rowData?.status !== 'Cancelled' && (
            <PFButton
              icon="pi pi-pencil"
              outlined
              text
              className="text-white border-none"
              severity="primary"
              aria-label="Edit"
              onClick={() => {
                window.open(`/crm/invoice/edit/${rowData?.invoice_id}`);
              }}
            />
          )}
        </div>
      ),
    },
    {
      field: 'invoice_no',
      header: 'Invoice#',
      sortable: true,
      filter: false,
    },
    {
      field: 'opportunity_id',
      header: 'Lead#',
      sortable: true,
      filter: false,
      body: rowData => (
        <div>
          <Link
            to={`/crm/${rowData?.customer_id}/lead/view/${rowData?.opportunity_id}`}
            target="_blank"
            rel="noopener noreferrer"
            style={linkStyle}
          >
            {rowData?.opportunity_number}
          </Link>
        </div>
      ),
    },
    {
      field: 'quote_number',
      header: 'Quote#',
      sortable: true,
      filter: false,
      body: rowData => (
        <div>
          <Link
            to={`/crm/${rowData?.customer_id}/${rowData?.opportunity_id}/quote/view/${rowData?.quote_id}`}
            target="_blank"
            rel="noopener noreferrer"
            style={linkStyle}
          >
            {rowData?.quote_number}
          </Link>
        </div>
      ),
    },
    {
      field: 'status',
      header: 'Status',
      sortable: true,
      filter: false,
    },
    {
      field: 'created_at',
      header: 'Created Date',
      sortable: true,
      filter: false,
      body: data => {
        return data.created_at
          ? moment(data.created_at, 'YYYY-MM-DD hh:mm A').format('MM-DD-YYYY')
          : '';
      },
    },
    {
      field: 'expiry_date',
      header: 'Due Date',
      sortable: true,
      filter: false,
      body: data => {
        return data.expiry_date
          ? moment(data.expiry_date, 'YYYY-MM-DD hh:mm A').format('MM-DD-YYYY')
          : '';
      },
    },
    {
      field: 'total_discount',
      header: 'Discount ($)',
      sortable: true,
      filter: false,
      body: rowData => `$ ${rowData?.total_discount?.toFixed(2)}`,
    },
    {
      field: 'total_tax',
      header: 'Tax ($)',
      sortable: true,
      filter: false,
      body: rowData => `$ ${rowData?.total_tax?.toFixed(2)}`,
    },
    {
      field: 'total_invoice_amount',
      header: 'Payable Amount ($)',
      sortable: true,
      filter: false,
      body: rowData => `$ ${rowData?.total_invoice_amount?.toFixed(2)}`,
    },
  ];

  return main;
};
