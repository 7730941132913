import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import type { ColumnBodyOptions } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Skeleton } from 'primereact/skeleton';
import { Toast } from 'primereact/toast';
import React from 'react';

import permissions from '../../../../config/permissions';
import { CURRENCY } from '../../../../constants/currency.constant';
import { ACTION } from '../../../../constants/form-actions.constant';
import { NOT_APPLICABLE } from '../../../../constants/string.constant';
import { useConfirmDialogContext } from '../../../../contexts/ConfirmDialog';
import {
  ColumnMeta,
  ExpenseItem,
  ExpensesResponse,
} from '../interface/additionalFee.interface';
import {
  deleteExpenseById,
  fetchExpensesData,
} from '../service/additionalFee.service';

import ExpenseForm from './ExpenseForm';

const ExpensesTable: React.FC<{
  project_id: number;
  toast: React.RefObject<Toast | null>;
}> = ({ project_id, toast }) => {
  const [expensesData, setExpensesData] =
    React.useState<ExpensesResponse | null>(null);
  const [loading, setLoading] = React.useState(true);
  const { showConfirmationDialog } = useConfirmDialogContext();

  const [dialogFormObj, setDialogFormObj] = React.useState({
    visible: false,
    formAction: null as string | null,
    rowData: null as ExpenseItem | null,
  });

  const updateDialogFormObj = (newState: Partial<typeof dialogFormObj>) => {
    setDialogFormObj(prev => ({ ...prev, ...newState }));
  };

  const loadExpensesData = async () => {
    setLoading(true);
    const response = await fetchExpensesData(project_id);
    if (!response?.error) {
      setExpensesData(response.data);
    } else {
      toast.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: response?.message,
        life: 3000,
      });
    }
    setLoading(false);
  };

  React.useEffect(() => {
    loadExpensesData();
  }, [project_id]);

  const handleAccept = async (rowData: ExpenseItem) => {
    setLoading(true);
    if (rowData.project_expense_id) {
      const response = await deleteExpenseById(
        project_id,
        rowData.project_expense_id
      );
      if (!response?.error) {
        toast.current?.show({
          severity: 'success',
          summary: 'Success',
          detail: response.message,
          life: 3000,
        });

        loadExpensesData();
      } else {
        toast.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: response?.message,
          life: 3000,
        });
      }
    }
  };

  const deleteExpense = (rowData: ExpenseItem) => {
    showConfirmationDialog({
      message: 'Are you sure you want to delete the Expense?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      defaultFocus: 'accept',
      accept: () => handleAccept(rowData),
    });
  };
  const viewEditExpense = (formAction: string, rowData: ExpenseItem) => {
    updateDialogFormObj({
      visible: true,
      formAction: formAction,
      rowData: rowData,
    });
  };

  const actionsTemplate = (rowData: ExpenseItem) =>
    !loading ? (
      <div className="inline-flex align-items-center">
        {permissions?.viewEditProject?.tabAdditionalFeeExpensesView && (
          <Button
            icon="pi pi-eye"
            outlined
            className="border-none px-0 w-2rem"
            onClick={() => viewEditExpense(ACTION.VIEW, rowData)}
            type="button"
            size="small"
          />
        )}
        {permissions?.viewEditProject?.tabAdditionalFeeExpensesEdit && (
          <Button
            icon="pi pi-pencil"
            outlined
            className="border-none px-0 w-2rem"
            onClick={() => viewEditExpense(ACTION.EDIT, rowData)}
            type="button"
            size="small"
          />
        )}
        {permissions?.viewEditProject?.tabAdditionalFeeExpensesDelete && (
          <Button
            icon="pi pi-trash"
            outlined
            severity="danger"
            className="border-none px-0 w-2rem"
            onClick={() => deleteExpense(rowData)}
            type="button"
            size="small"
          />
        )}
      </div>
    ) : null;

  // Dynamic body template
  const dynamicBodyTemplate = (
    rowData: ExpenseItem,
    options: ColumnBodyOptions
  ): React.ReactNode => {
    if (loading) {
      return <Skeleton width="100%" height="1.5rem" />;
    }

    if (options.field === 'actions') {
      return actionsTemplate(rowData);
    }
    if (options.field === 'expense_source') {
      return rowData?.expense_source?.source_name || NOT_APPLICABLE;
    }

    if (options.field === 'created_by') {
      if (
        rowData?.expense_created_user?.first_name &&
        rowData?.expense_created_user?.last_name
      ) {
        return (
          <div className="capitalize">
            {rowData.expense_created_user.first_name}{' '}
            {rowData.expense_created_user.last_name}
          </div>
        );
      }
      return '-';
    }

    if (options.field === 'dollar_amount') {
      return `${CURRENCY.DOLLAR} ${rowData[options.field]}`;
    }
    // Default rendering for other fields
    const value = rowData[options.field as keyof ExpenseItem];
    return typeof value === 'string' || typeof value === 'number'
      ? value
      : null;
  };

  const columns: ColumnMeta[] = [
    {
      field: 'actions',
      header: '',
      headerStyle: { minWidth: '10rem' },
    },

    {
      field: 'expense_source',
      header: 'Expense Source',
    },
    {
      field: 'dollar_amount',
      header: 'Dollar Amount',
      headerStyle: { minWidth: '7rem' },
    },
    {
      field: 'description',
      header: 'Description',
      headerStyle: { minWidth: '9rem' },
    },
    {
      field: 'notes',
      header: 'Notes',
      headerStyle: { minWidth: '10rem' },
    },

    {
      field: 'created_by',
      header: 'Created By',
      headerStyle: { minWidth: '10rem' },
    },
  ];
  const header = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <h3 className="text-base font-bold">Expenses</h3>
      <div className="flex align-items-center gap-2">
        {permissions?.viewEditProject?.tabAdditionalFeeAdd ? (
          <Button
            outlined
            label="Add Item"
            onClick={() => {
              updateDialogFormObj({
                visible: true,
                formAction: ACTION.ADD,
              });
            }}
            type="button"
            size="small"
          />
        ) : null}
      </div>
    </div>
  );

  return (
    <div className="col-12 mt-4">
      <DataTable
        value={expensesData?.items || []}
        totalRecords={expensesData?.count}
        header={header}
      >
        {columns.map(col => (
          <Column
            key={col.field}
            field={col.field}
            header={col.header}
            body={dynamicBodyTemplate}
            headerStyle={col.headerStyle}
          />
        ))}
      </DataTable>
      <ExpenseForm
        dialogFormObj={dialogFormObj}
        updateDialogFormObj={updateDialogFormObj}
        project_id={project_id}
        loadExpensesData={loadExpensesData}
        toast={toast}
      />
    </div>
  );
};

export default ExpensesTable;
