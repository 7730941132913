import React, { useState, useEffect, useRef } from 'react';
import { Tabs, Tab } from '@mui/material';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { useStyles } from './DraggableTabs.styles';

const DraggableTab = ({
  index,
  tab,
  nameProperty,
  moveTab,
  onTabClick,
  tabClasses,
  isSelected,

  setIsDragging,
}) => {
  const classes = useStyles();
  const ref = useRef(null);

  const dragStart = index => {
    setIsDragging(true);
    return { index };
  };
  const [, drag] = useDrag({
    type: 'TAB',
    item: dragStart(index),
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    end: () => setIsDragging(false),
  });

  const [, drop] = useDrop({
    accept: 'TAB',
    hover: item => {
      if (item.index !== index) {
        moveTab(item.index, index);
        item.index = index;
      }
    },
  });

  drag(drop(ref));

  return (
    <div
      ref={ref}
      className={`${classes.tab} ${isSelected ? classes.selectedTab : classes.unSelectedTab} min-w-max`}
    >
      <Tab
        label={tab[nameProperty]}
        onClick={e => onTabClick(e, tab.value.toString())}
        className={`${tabClasses.tabTextColor} ${classes.tab} ${isSelected ? classes.selectedTab : classes.unSelectedTab} capitalize`}
        value={tab.id}
      />
    </div>
  );
};

const DraggableTabs = ({
  allTabs,
  nameProperty,
  onTabClick,
  tabsValue,
  tabsOption,
  handleAddIcon,
  showAdd,
  handleTabState,
}) => {
  const classes = useStyles();

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [tabList, setTabList] = useState([]);

  useEffect(() => {
    setTabList(allTabs);
  }, [allTabs]);

  const moveTab = (dragIndex, hoverIndex) => {
    const newTabList = [...tabList];
    const [draggedTab] = newTabList.splice(dragIndex, 1);
    newTabList.splice(hoverIndex, 0, draggedTab);
    setTabList(newTabList);
    handleTabState(newTabList);
    if (selectedTabIndex === dragIndex) {
      setSelectedTabIndex(hoverIndex);
    } else if (selectedTabIndex === hoverIndex) {
      setSelectedTabIndex(dragIndex);
    }
  };

  const checkTabValue = () => {
    let selectedTabIndex = 0;
    if (tabList?.length > 0) {
      tabList.forEach((element, indx) => {
        if (element?.value.toString() === tabsValue.toString())
          selectedTabIndex = indx;
      });
    }
    return selectedTabIndex;
  };

  const handleTabClick = (index, value) => {
    setSelectedTabIndex(index);
    onTabClick(value);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Tabs
        value={checkTabValue()}
        variant={tabsOption.variant}
        scrollButtons={tabsOption.scrollButtons}
        aria-label={tabsOption.ariaLabel}
        classes={{
          root: classes.tabRoot,
          scrollButtons: classes.scrollButtons,
          indicator: classes.indicator,
        }}
      >
        {tabList?.length > 0 &&
          tabList?.map((tab, index) => (
            <DraggableTab
              key={index}
              index={index}
              tab={tab}
              nameProperty={nameProperty}
              moveTab={moveTab}
              tabClasses={classes}
              onTabClick={() => handleTabClick(index, tab?.value.toString())}
              isSelected={checkTabValue() === index}
              isDragging={isDragging}
              setIsDragging={setIsDragging}
            />
          ))}
        {showAdd && (
          <div className="bg-primary shadow-1 text-white border-noround opacity-100">
            <Tab
              label={
                <div className="flex align-items-center gap-1 text-white">
                  <i className="pi pi-plus-circle"></i> Add
                </div>
              }
              className="bg-primary shadow-1 text-white border-noround opacity-100"
              value="newTab"
              onClick={() => handleAddIcon()}
            />
          </div>
        )}
      </Tabs>
    </DndProvider>
  );
};

export default DraggableTabs;
