import { useRef } from 'react';
import { Toast } from 'primereact/toast';

import NotesTable from './components/NotesTable';

const NotesContainer = ({
  project_id,
  source_system_id,
  category,
}: {
  project_id: number;
  source_system_id: number;
  category: string;
}) => {
  const toast = useRef<Toast>(null);

  return (
    <>
      <Toast ref={toast} />
      <NotesTable
        project_id={project_id}
        toast={toast}
        source_system_id={source_system_id}
        category={category}
      />
    </>
  );
};

export default NotesContainer;
