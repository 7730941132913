import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'primereact/button';

import { checkPermission } from '../../../utils/Helpers';
import permissions from '../../../config/permissions';

import ChargebackList from './ChargebackList';

const ChargebackTab = ({ projectId }) => {
  const history = useHistory();
  return (
    <div className="flex flex-column gap-1 w-full">
      <div className="flex w-full justify-content-between">
        <div className="flex">
          <span className="text-xl font-semibold">Chargeback</span>
        </div>
        {checkPermission(permissions?.chargeback?.add) && (
          <div className="flex">
            <Button
              label="Add Chargeback"
              size="small"
              icon="pi pi-plus"
              iconPos="right"
              onClick={() => {
                history.push(`/admin/chargeback/add/project/${projectId}`);
              }}
            />
          </div>
        )}
      </div>
      <ChargebackList project_id={projectId} />
    </div>
  );
};

export default ChargebackTab;
