import React from 'react';
import { Dropdown } from 'primereact/dropdown';

export default function PFDropdown({
  value,
  name,
  onChange,
  options,
  optionLabel,
  placeholder,
  filter,
  className,
  optionValue,
  disabled,
}) {
  return (
    <Dropdown
      value={value || ''}
      name={name || ''}
      onChange={e => onChange(name, e.value)}
      options={options || []}
      optionLabel={optionLabel || ''}
      optionValue={optionValue || ''}
      placeholder={placeholder || ''}
      filter={filter || false}
      className={`w-full p-inputtext-sm ${className || ''}`}
      disabled={disabled || false}
    />
  );
}
