import React from 'react';
import { Grid, Box, TextField, CircularProgress } from '@material-ui/core';

import { useStyles } from './Extracolumn.styles.js';

const Extracolumn = ({ projectDetailsData, loading }) => {
  const classes = useStyles();

  return (
    <>
      {!loading ? (
        <Grid container spacing={4} direction="column">
          <Grid item xs={12} lg={12} md={12} sm={12}>
            <Box display="flex" flexDirection="row" flexWrap="wrap">
              {projectDetailsData?.extra_fields !== null &&
                projectDetailsData?.extra_fields !== undefined &&
                Object.entries(projectDetailsData?.extra_fields) &&
                Object.entries(projectDetailsData?.extra_fields).map(
                  (value, index) => (
                    <Box className={classes.boxColumn} m={1} key={index}>
                      <TextField
                        id="floorsoft_transaction_id"
                        label={value[0]}
                        value={value[1]}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Box>
                  )
                )}
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Grid container justifyContent="center">
          <CircularProgress />
        </Grid>
      )}
    </>
  );
};

export default Extracolumn;
