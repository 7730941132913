import React, { useState, useRef } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { Toast } from 'primereact';

import PFButton from '../../../shared/PFPrime/PFButton';
import PFInputText from '../../../shared/PFPrime/PFInputText';
import PFFormikError from '../../../shared/PFPrime/PFFormikError';
import { URL_CONSTANTS } from '../../../../constants/urlConstants';

const initialValues = {
  noteInput: '',
};
const NotesForm = ({
  customerId,
  reload,
  setReload,
  opportunityId,
  quoteId,
  invoiceId,
  paymentId,
}) => {
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);
  const notesFormik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
      noteInput: Yup.string().trim().required('required'),
    }),
    onSubmit: async values => {
      setLoading(true);
      try {
        const response = await axios.post(
          `${URL_CONSTANTS.CRM.baseUrl}${URL_CONSTANTS.CRM.communication.notes.create}`,
          {
            noteInput: values.noteInput,
            customerId,
            opportunityId: opportunityId || undefined,
            quoteId: quoteId || undefined,
            invoiceId: invoiceId || undefined,
            paymentId: paymentId || undefined,
          }
        );
        if (response.status != 201) {
          toast?.current?.show({
            severity: 'error',
            summary: 'Failed to add note',
            life: 2000,
          });
        } else {
          setReload(!reload);
          notesFormik.resetForm({ values: initialValues });
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast?.current?.show({
          severity: 'error',
          summary: 'Something went wrong',
          life: 2000,
        });
        console.error(error);
      }
    },
  });

  return (
    <>
      <div className="flex py-2 p-inputgroup flex-1 w-full">
        <div className="w-full">
          <span className="p-float-label">
            <PFInputText
              value={notesFormik.values.noteInput}
              name="noteInput"
              onChange={notesFormik.handleChange}
              className={'border-noround border-round-left'}
              pt={{
                input: {
                  root: {
                    className: 'border-noround-left',
                  },
                },
              }}
            />
            <label htmlFor="note">
              Enter Note <span style={{ color: 'red' }}>*</span>
            </label>
          </span>
        </div>

        <PFButton
          label="Create"
          onClick={() => notesFormik.handleSubmit()}
          className="btn-mail-send"
          disabled={loading}
          size="small"
          icon={loading ? 'pi pi-spin pi-spinner' : 'pi pi-plus'}
        />
      </div>
      <PFFormikError
        touched={notesFormik.touched}
        errors={notesFormik.errors}
        field="noteInput"
      />
      <Toast ref={toast} />
    </>
  );
};

export default NotesForm;
