import React from 'react';
import ReactDOM from 'react-dom';
import { registerLicense } from '@syncfusion/ej2-base';

// Registering Syncfusion license key
// eslint-disable-next-line no-undef
registerLicense(process.env.REACT_APP_SYNCFUSION_LICENSE);
import AppWrapper from './AppWrapper';

ReactDOM.render(<AppWrapper />, document.getElementById('root'));
