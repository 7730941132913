import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  breadcrumb: {
    width: '6px',
    height: '6px',
  },
  breadcrumbIconWrapper: {
    paddingBottom: '4px',
  },
}));
