import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup'; // Import Yup
import { Skeleton } from 'primereact/skeleton';

import PFButton from '../../shared/PFPrime/PFButton';
import PFMultiSelect from '../../shared/PFPrime/PFMultiSelect';

import { createCustomizeCheckList } from './questionnaireService';

const CustomizeCheckList = forwardRef(({ mode, toast }, ref) => {
  const [typesOptions, setTypesOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [sourceStatusOptions, setSourceStatusOptions] = useState([]);
  const [sourceSystemOptions, setSourceSystemOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const { isLoading, selectedModule, customizeCheckList } = useSelector(
    state => state.questionnaire
  );
  const { types } = useSelector(state => state?.types);
  const { sourceSystems } = useSelector(state => state?.sourceSystems);
  const { categories } = useSelector(state => state?.categories);
  const { projectStatusType } = useSelector(state => state?.projectStatusType);
  const { sourceStatusType } = useSelector(state => state?.sourceStatusType);

  useEffect(() => {
    if (mode === 'add') {
      formik.setValues({
        projectType: {
          isAll: 1, // Indicating all are selected
          selectedId: typesOptions?.map(option => option.value), // Select all options
        },
        projectCategories: {
          isAll: 1,
          selectedId: categoryOptions?.map(option => option.value),
        },
        projectStatus: {
          isAll: 1,
          selectedId: statusOptions?.map(option => option.value),
        },
        sourceStatus: {
          isAll: 1,
          selectedId: sourceStatusOptions?.map(option => option.value),
        },
      });
    } else if (customizeCheckList && mode === 'update') {
      const initialValues = {
        projectType: {
          isAll: customizeCheckList?.project_type?.is_all,
          selectedId: getSelectedIds(
            customizeCheckList?.project_type?.is_all,
            customizeCheckList?.project_type?.selected_id?.map(
              item => item?.project_type_id
            ),
            typesOptions
          ),
        },
        projectCategories: {
          isAll: customizeCheckList?.project_categories?.is_all,
          selectedId: getSelectedIds(
            customizeCheckList?.project_categories?.is_all,
            customizeCheckList?.project_categories?.selected_id?.map(
              item => item?.project_category_id
            ),
            categoryOptions
          ),
        },
        projectStatus: {
          isAll: customizeCheckList?.project_status?.is_all,
          selectedId: getSelectedIds(
            customizeCheckList?.project_status?.is_all,
            customizeCheckList?.project_status?.selected_id?.map(
              item => item?.status_id
            ),
            statusOptions
          ),
        },
        sourceStatus: {
          isAll: customizeCheckList?.source_status?.is_all,
          selectedId: getSelectedIds(
            customizeCheckList?.source_status?.is_all,
            customizeCheckList?.source_status?.selected_id?.map(
              item => item?.status_id
            ),
            sourceStatusOptions
          ),
        },
        sourceSystem: {
          isAll: customizeCheckList?.source_system?.is_all,
          selectedId: getSelectedIds(
            customizeCheckList?.source_system?.is_all,
            customizeCheckList?.source_system?.selected_id?.map(
              item => item?.source_system_id
            ),
            sourceSystemOptions
          ),
        },
      };

      formik.setValues(initialValues);
    }
  }, [
    customizeCheckList,
    mode,
    typesOptions,
    categoryOptions,
    statusOptions,
    sourceStatusOptions,
    sourceSystemOptions,
  ]);

  const getSelectedIds = (isAll, selectedIds, options) => {
    return isAll
      ? options?.map(option => option?.value)
      : selectedIds?.map(id => id);
  };

  useEffect(() => {
    fetchDropDownData();
  }, []);

  const fetchDropDownData = async () => {
    try {
      const typeOption = types?.map(type => ({
        label: type?.project_type,
        value: type?.project_type_id,
      }));

      const categoryOption = categories?.map(category => ({
        label: category?.category,
        value: category?.project_category_id,
      }));

      const projectStatusOption = projectStatusType?.map(projectStatus => ({
        label: projectStatus?.status,
        value: projectStatus?.status_id,
      }));

      const sourceStatusOption = sourceStatusType?.map(sourceStatus => ({
        label: sourceStatus?.status,
        value: sourceStatus?.status_id,
      }));

      const sourceSystemOption = sourceSystems?.map(sourceSystem => ({
        label: sourceSystem?.source_name,
        value: sourceSystem?.source_system_id,
      }));

      setTypesOptions(typeOption);
      setCategoryOptions(categoryOption);
      setStatusOptions(projectStatusOption);
      setSourceStatusOptions(sourceStatusOption);
      setSourceSystemOptions(sourceSystemOption);
    } catch (error) {
      console.error('Error fetching project data:', error);
    }
  };

  const getSelectedItemsLabel = selectedItems => {
    const count = selectedItems?.length || 0;
    return count === 1 ? '1 item selected' : `${count} items selected`;
  };
  // Define the validation schema using Yup
  const validationSchema = Yup.object({
    projectType: Yup.object({
      selectedId: Yup.array()
        .min(1, 'Please select at least one project type')
        .required('Project Type is required'),
    }).required(),
    projectCategories: Yup.object({
      selectedId: Yup.array()
        .min(1, 'Please select at least one category')
        .required('Category is required'),
    }).required(),
    projectStatus: Yup.object({
      selectedId: Yup.array()
        .min(1, 'Please select at least one status')
        .required('Status is required'),
    }).required(),
    sourceStatus: Yup.object({
      selectedId: Yup.array()
        .min(1, 'Please select at least one source status')
        .required('Source Status is required'),
    }).required(),
    sourceSystem: Yup.object({
      selectedId: Yup.array()
        .min(1, 'Please select at least one source')
        .required('Source is required'),
    }).required(),
  });

  const formik = useFormik({
    initialValues: {
      projectType: {
        isAll: 0,
        selectedId: [],
      },
      projectCategories: {
        isAll: 0,
        selectedId: [],
      },
      projectStatus: {
        isAll: 0,
        selectedId: [],
      },
      sourceStatus: {
        isAll: 0,
        selectedId: [],
      },
      sourceSystem: {
        isAll: 0,
        selectedId: [],
      },
    },
    validationSchema,
    onSubmit: async values => {
      setLoading(true);
      try {
        const response = await createCustomizeCheckList(
          values,
          selectedModule?.module_id
        );
        if (response?.status) {
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: response?.message,
            life: 3000,
          });
        }
      } catch (error) {
        console.error('Error occurred:', error); // You can log the error for debugging
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: error?.message || 'An unexpected error occurred',
          life: 3000,
        });
      } finally {
        setLoading(false);
      }
    },
  });

  useImperativeHandle(ref, () => ({
    validateAndGetValues: async () => {
      const errors = await formik.validateForm();
      if (Object.keys(errors).length !== 0) {
        formik.setTouched({
          projectType: { selectedId: true },
          projectCategories: { selectedId: true },
          projectStatus: { selectedId: true },
          sourceStatus: { selectedId: true },
          sourceSystem: { selectedId: true },
        });
        return null; // Return null if validation fails
      }
      return formik.values; // Return values if valid
    },
  }));

  const handleMultiSelectChange = (field, value, options) => {
    // Check if all options are selected
    const isAllSelected = value?.length === options?.length;

    // If all options are selected, set isAll to 1, else set it to 0
    formik.setFieldValue(field, {
      isAll: isAllSelected ? 1 : 0,
      selectedId: value,
    });
  };

  return (
    <div className="flex flex-column w-full">
      {isLoading ? (
        <div className="p-card flex flex-column mb-4 p-4">
          <div className="grid justify-content-between">
            <div className="col-4">
              <Skeleton width="100%" height="3rem" />
            </div>
            <div className="col-4">
              <Skeleton className="ml-auto" width="6rem" height="3rem" />
            </div>
          </div>
          <div className="grid">
            <div className="col-12 md:col-4">
              <Skeleton width="100%" height="3rem" />
            </div>
            <div className="col-12 md:col-4">
              <Skeleton width="100%" height="3rem" />
            </div>
            <div className="col-12 md:col-4">
              <Skeleton width="100%" height="3rem" />
            </div>
            <div className="col-12 md:col-4">
              <Skeleton width="100%" height="3rem" />
            </div>
            <div className="col-12 md:col-4">
              <Skeleton width="100%" height="3rem" />
            </div>
          </div>
        </div>
      ) : (
        <>
          <div
            className={`${mode === 'update' ? 'p-card' : ''} flex flex-column mb-4 p-4`}
          >
            <div className="flex flex-row justify-content-between mb-2">
              <div className="text-lg md:text-2xl">{`Customize Checklist`}</div>
              {mode === 'update' && (
                <div>
                  <PFButton
                    onClick={formik.handleSubmit}
                    size="small"
                    label="Save"
                    severity="primary"
                    icon={loading ? 'pi pi-spin pi-spinner' : `pi pi-save`}
                    iconPos="left"
                    disabled={loading}
                  />
                </div>
              )}
            </div>
            <div className="flex flex-column gap-2">
              <div className="grid mb-2">
                {/* Source System MultiSelect */}
                <div className="flex flex-column col-12 md:col-4">
                  <span className={`p-float-label`}>
                    <PFMultiSelect
                      value={formik?.values?.sourceSystem?.selectedId || []}
                      options={sourceSystemOptions}
                      onChange={e =>
                        handleMultiSelectChange(
                          'sourceSystem',
                          e?.value,
                          sourceSystemOptions
                        )
                      }
                      panelClassName="z-index-9999"
                      className="w-full text-sm "
                      filter={true}
                      appendTo={null}
                      optionLabel="label"
                      optionValue="value"
                      maxSelectedLabels={0}
                      selectedItemsLabel={getSelectedItemsLabel(
                        formik?.values?.sourceSystem?.selectedId
                      )}
                    />
                    <label htmlFor="sourceSystem">Select Source</label>
                  </span>
                  {formik?.errors?.sourceSystem &&
                    formik?.touched?.sourceSystem && (
                      <div
                        className={`text-red-500 ${mode === 'add' && 'text-xs'}`}
                      >
                        {formik?.errors?.sourceSystem?.selectedId}
                      </div>
                    )}
                </div>

                {/* Project Type MultiSelect */}
                <div className="flex flex-column col-12 md:col-4">
                  <span className={`p-float-label`}>
                    <PFMultiSelect
                      value={formik?.values?.projectType?.selectedId || []}
                      options={typesOptions}
                      onChange={e =>
                        handleMultiSelectChange(
                          'projectType',
                          e?.value,
                          typesOptions
                        )
                      }
                      panelClassName="z-index-9999"
                      className="w-full text-sm"
                      filter={true}
                      appendTo={null}
                      optionLabel="label"
                      optionValue="value"
                      maxSelectedLabels={0}
                    />
                    <label htmlFor="projectType">Select Project Type</label>
                  </span>
                  {formik?.errors?.projectType &&
                    formik?.touched?.projectType && (
                      <div
                        className={`text-red-500 ${mode === 'add' && 'text-xs'}`}
                      >
                        {formik?.errors?.projectType?.selectedId}
                      </div>
                    )}
                </div>

                {/* Project Category MultiSelect */}
                <div className="flex flex-column col-12 md:col-4">
                  <span className={`p-float-label`}>
                    <PFMultiSelect
                      value={
                        formik?.values?.projectCategories?.selectedId || []
                      }
                      options={categoryOptions}
                      onChange={e =>
                        handleMultiSelectChange(
                          'projectCategories',
                          e?.value,
                          categoryOptions
                        )
                      }
                      panelClassName="z-index-9999"
                      className="w-full text-sm"
                      filter={true}
                      appendTo={null}
                      optionLabel="label"
                      optionValue="value"
                      maxSelectedLabels={0}
                    />
                    <label htmlFor="projectCategories">Select Category</label>
                  </span>
                  {formik?.errors?.projectCategories &&
                    formik?.touched?.projectCategories && (
                      <div
                        className={`text-red-500 ${mode === 'add' && 'text-xs'}`}
                      >
                        {formik?.errors?.projectCategories?.selectedId}
                      </div>
                    )}
                </div>

                {/* Project Status MultiSelect */}
                <div className="flex flex-column col-12 md:col-4">
                  <span className={`p-float-label`}>
                    <PFMultiSelect
                      value={formik?.values?.projectStatus?.selectedId || []}
                      options={statusOptions}
                      onChange={e =>
                        handleMultiSelectChange(
                          'projectStatus',
                          e?.value,
                          statusOptions
                        )
                      }
                      panelClassName="z-index-9999"
                      className="w-full text-sm"
                      filter={true}
                      appendTo={null}
                      optionLabel="label"
                      optionValue="value"
                      maxSelectedLabels={0}
                    />
                    <label htmlFor="projectStatus">Select Status</label>
                  </span>
                  {formik?.errors?.projectStatus &&
                    formik?.touched?.projectStatus && (
                      <div
                        className={`text-red-500 ${mode === 'add' && 'text-xs'}`}
                      >
                        {formik?.errors?.projectStatus?.selectedId}
                      </div>
                    )}
                </div>

                {/* Source Status MultiSelect */}
                <div className="flex flex-column col-12 md:col-4">
                  <span className={`p-float-label`}>
                    <PFMultiSelect
                      value={formik?.values?.sourceStatus?.selectedId || []}
                      options={sourceStatusOptions}
                      onChange={e =>
                        handleMultiSelectChange(
                          'sourceStatus',
                          e?.value,
                          sourceStatusOptions
                        )
                      }
                      panelClassName="z-index-9999"
                      className="w-full text-sm"
                      filter={true}
                      appendTo={null}
                      optionLabel="label"
                      optionValue="value"
                      maxSelectedLabels={0}
                    />
                    <label htmlFor="sourceStatus">Select Source Status</label>
                  </span>
                  {formik?.errors?.sourceStatus &&
                    formik?.touched?.sourceStatus && (
                      <div
                        className={`text-red-500 ${mode === 'add' && 'text-xs'}`}
                      >
                        {formik?.errors?.sourceStatus?.selectedId}
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
});

CustomizeCheckList.displayName = 'CustomizeCheckList';
export default CustomizeCheckList;
