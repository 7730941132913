import React from 'react';
import { Typography, Link, Grid } from '@material-ui/core';

import { useStyles } from '../AccessDenied/AccessDenied.styles';

const UnhandledError = () => {
  const classes = useStyles();

  return (
    <Grid container justifyContent="center">
      <Grid item>
        <Grid container justifyContent="center" className={classes.container}>
          <Typography variant={'h2'}> Something went wrong </Typography>
        </Grid>
        <Grid container justifyContent="center">
          <Typography variant={'body1'}>
            Oops! Looks like something went wrong.
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant={'body1'}>
            Write an email to &nbsp;
            <Link
              href={`mailto:support@projectsforce.com`}
              underline="none"
              className={classes.root}
            >
              support@projectsforce.com
            </Link>
            &nbsp; if the issue still persists.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UnhandledError;
