import React from 'react';
import { Tooltip } from 'primereact/tooltip';
import moment from 'moment';
import { Skeleton } from 'primereact/skeleton';

import { IAddViewDocAndPhotoProps } from '../Interface/quoteTemplate.interface';

import DocumentsViewCard from './DocumentViewCard';

const AddViewDocAndPhoto: React.FC<IAddViewDocAndPhotoProps> = ({
  type,
  uploadedFiles,
  handleDocsUpload,
  setPreSignedUrl,
  setIsPreviewVisible,
  handleDeleteDoc = () => {},
  loading = false,
}) => {
  return (
    <div className="flex align-items-center items-start m-3 overflow-x-auto">
      <div>
        <div
          className="w-10rem h-17rem mx-2 mb-2 border-dashed border-gray-500 bg-gray-200 cursor-pointer flex align-items-center justify-content-center text-2xl"
          onClick={() => document.getElementById('file-upload')?.click()}
        >
          <input
            id="file-upload"
            type="file"
            accept=".pdf, .doc, .docx, .xls, .xlsx, . csv, image/*"
            multiple
            style={{ display: 'none' }}
            onChange={handleDocsUpload}
          />
          <i className="pi pi-plus"></i>
        </div>
      </div>

      {uploadedFiles?.map((file, index) => (
        <div
          key={index}
          className="mx-2 mb-2 w-12rem h-17rem grid-nogutter grid border-2 border-gray-200 pr-2 pt-2"
        >
          <div className="col-2">
            <div className="col-12 col-md-12 col-lg-12">
              <Tooltip
                target=".expand-icon"
                content="Expand"
                position="bottom"
              />
              <div className="expand-icon">
                <i
                  className="pi pi-arrow-up-right-and-arrow-down-left-from-center"
                  onClick={async () => {
                    setPreSignedUrl({ url: file.url as string, type: type });
                    setIsPreviewVisible(true);
                  }}
                />
              </div>
              {/* delete icon */}
              <Tooltip
                target=".delete-icon"
                content="Delete"
                position="bottom"
              />
              <div className="delete-icon mt-2">
                <i
                  onClick={() => handleDeleteDoc(index)}
                  className="pi pi-trash"
                />
              </div>
            </div>
          </div>
          <div className="col-10 overflow-hidden">
            <DocumentsViewCard
              documentInfo={{
                title: file.name,
                file_path: file.url,
                time_stamp: moment(file?.timeStamp).format(
                  'MM-DD-YYYY hh:mm A'
                ),
              }}
              type={type}
              className="w-12rem h-15rem"
            />
          </div>
        </div>
      ))}

      {loading && (
        <Skeleton
          key={uploadedFiles.length}
          width="12rem"
          height="17rem"
          className="mx-2"
        />
      )}
    </div>
  );
};

export default AddViewDocAndPhoto;
