import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: true,
  isDeleteDialogOpen: false,
  statesList: [],
  addressData: [],
  deleteRowIndex: null,
  addressIdToDelete: null,
};

const userFormAddressSlice = createSlice({
  name: 'userFormAddress',
  initialState: initialState,
  reducers: {
    setUserAddressForm(state, { payload }) {
      state.isLoading = Object.prototype.hasOwnProperty.call(
        payload,
        'isLoading'
      )
        ? payload.isLoading
        : state.isLoading;
      state.statesList = Object.prototype.hasOwnProperty.call(
        payload,
        'statesList'
      )
        ? payload.statesList
        : state.statesList;
      state.addressData = Object.prototype.hasOwnProperty.call(
        payload,
        'addressData'
      )
        ? payload.addressData
        : state.addressData;
      state.isDeleteDialogOpen = Object.prototype.hasOwnProperty.call(
        payload,
        'isDeleteDialogOpen'
      )
        ? payload.isDeleteDialogOpen
        : state.isDeleteDialogOpen;
      state.deleteRowIndex = Object.prototype.hasOwnProperty.call(
        payload,
        'deleteRowIndex'
      )
        ? payload.deleteRowIndex
        : state.deleteRowIndex;
      state.addressIdToDelete = Object.prototype.hasOwnProperty.call(
        payload,
        'addressIdToDelete'
      )
        ? payload.addressIdToDelete
        : state.addressIdToDelete;
    },
  },
});

export const { setUserAddressForm } = userFormAddressSlice.actions;

export const userFormAddressReducer = userFormAddressSlice.reducer;
