import React, { useState, useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';

import { getBulletinMessage, momentTz } from '../../../utils/Helpers';
import { BULLETIN } from '../../../constants';

import { getActiveBulletin } from './Bulletin.service';

const BulletinMarquee = () => {
  const [bulletinList, setBulletinList] = useState([]);

  useEffect(async () => {
    if (axios.defaults.headers.common['client_id']) {
      const bulletinList = await getActiveBulletin(
        BULLETIN.TOPBAR,
        momentTz(moment()).utc().format('YYYY-MM-DD HH:mm:ss')
      );

      setBulletinList(bulletinList);
    }
  }, [axios.defaults.headers.common['client_id']]);

  useEffect(() => {
    window.marqueeText = false;
    if (bulletinList?.length > 0) {
      window.marqueeText = true;
    }
  }, [bulletinList?.length]);

  return (
    <>
      {bulletinList?.length ? (
        <marquee className="w-full mt-2">
          {bulletinList?.map((bulletin, index) => (
            <span className="mr-4" key={index}>
              <i className="pi pi-megaphone w-1rem text-xs text-primary"></i>
              <span className="text-color">{getBulletinMessage(bulletin)}</span>
            </span>
          ))}
        </marquee>
      ) : null}
    </>
  );
};

export default BulletinMarquee;
