import axios from 'axios';

import apiService from '../../../services/api.service';
import { REACT_APP_LOGIN_AUTH } from '../../../constants/envConstants';

import projectItemsConstant from './projectItemsConstant';

export const getLaborItems = async projectId => {
  try {
    const response = await axios.get(
      `${projectItemsConstant.baseUrl}/${projectItemsConstant.getLaborItemsEndpoint(projectId)}`
    );
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getMerchandiseItems = async projectId => {
  try {
    const response = await axios.get(
      `${projectItemsConstant.baseUrl}/${projectItemsConstant.getMerchandiseItemsEndpoint(
        projectId
      )}`
    );

    return response;
  } catch (error) {
    console.error(error);
  }
};

export const createMerchandiseItem = async (projectId, data) => {
  try {
    const response = await axios.post(
      `${projectItemsConstant.baseUrl}/${projectItemsConstant.getCreateMerchandiseEndpoint(
        projectId
      )}`,
      data
    );

    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getSystemItems = async (urlParam = '') => {
  try {
    const response = await axios.get(
      `${projectItemsConstant.baseUrl}/system/items${urlParam}`
    );
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const deleteProjectItem = async (projectItemId, projectId) => {
  try {
    const response = await axios.delete(
      `${projectItemsConstant.baseUrl}/${projectItemsConstant.getDeleteProjectItemEndpoint(
        projectItemId,
        projectId
      )}`
    );
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const updateMerchandiseItem = async (projectItemId, projectId, data) => {
  try {
    const response = await axios.patch(
      `${projectItemsConstant.baseUrl}/${projectItemsConstant.getUpdateMerchandiseEndpoint(
        projectItemId,
        projectId
      )}`,
      data
    );

    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const createLaborItem = async (projectId, data) => {
  try {
    const response = await axios.post(
      `${projectItemsConstant.baseUrl}/${projectItemsConstant.getCreateLaborEndpoint(projectId)}`,
      data
    );

    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const updateLaborItem = async (projectItemId, projectId, data) => {
  try {
    const response = await axios.patch(
      `${projectItemsConstant.baseUrl}/${projectItemsConstant.getUpdateLaborItemEndpoint(
        projectItemId,
        projectId
      )}`,
      data
    );
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const bulkUpdateLaborItemVisibility = async (
  projectItemId,
  projectId,
  data
) => {
  try {
    const url = `/${projectItemsConstant.getUpdateLaborItemVisibilityEndpoint(
      projectItemId,
      projectId
    )}`;

    const response = await apiService.post(url, data);
    return response;
  } catch (error) {
    console.error('Error: ', error);
    return null;
  }
};

export const getProjectInstallers = async projectId => {
  try {
    const response = await axios.get(
      `${projectItemsConstant.baseUrl}/${projectItemsConstant.getProjectInstallersEndpoint(
        projectId
      )}`
    );
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const assignProjectItemUser = async (projectId, data) => {
  try {
    const assignResponse = await axios.post(
      `${projectItemsConstant.baseUrl}/${projectItemsConstant.getAssignProjectItemUserEndpoint(
        projectId
      )}`,
      data
    );
    return assignResponse;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const updateProjectItemUser = async (
  projectItemUserId,
  projectId,
  data
) => {
  try {
    const updateResponse = await axios.patch(
      `${projectItemsConstant.baseUrl}/${projectItemsConstant.getUpdateItemUserEndpoint(
        projectItemUserId,
        projectId
      )}`,
      data
    );
    return updateResponse;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const deleteProjectItemUser = async (projectItemUserId, projectId) => {
  try {
    const deleteResponse = await axios.delete(
      `${projectItemsConstant.baseUrl}/${projectItemsConstant.getDeleteItemUserEndpoint(
        projectItemUserId,
        projectId
      )}`
    );
    return deleteResponse;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const calculateProjectLaborCost = async projectId => {
  try {
    const response = await axios.get(
      `${projectItemsConstant.baseUrl}/${projectItemsConstant.getProjectLaborCostEndpoint(
        projectId
      )}`
    );

    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const calculateProjectItemLaborCost = async (
  projectItemId,
  projectId
) => {
  try {
    const response = await axios.get(
      `${projectItemsConstant.baseUrl}/${projectItemsConstant.getProjectItemLaborCostEndpoint(
        projectItemId,
        projectId
      )}`
    );

    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getSystemStatuses = async () => {
  try {
    const response = await axios.get(
      `${projectItemsConstant.baseUrl}/${projectItemsConstant.getSystemStatusEndpoint()}`
    );

    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const createLaborMasterItem = async (projectId, values) => {
  try {
    const response = await axios.post(
      `${projectItemsConstant.baseUrl}/system/items`,
      values
    );
    if (response) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const createMerchandiseMasterItem = async (projectId, values) => {
  try {
    const formData = new FormData();
    formData.append('item_number', values.item_number);
    formData.append('item_desc', values.item_desc);
    formData.append('item_type_id', values.item_type_id);
    if (!values.file && values.image_url)
      formData.append('image_url', values.image_url);
    formData.append('image_type', 'upload');
    if (values.file) formData.append('file', values.file);
    formData.append('cost_price', values.cost_price);
    formData.append('retail_price', values.retail_price);
    formData.append('inventory_managment', values.inventory_managment);
    formData.append('express_ship', values.express_ship);

    let headers = {
      'Content-Type': 'multipart/form-data',
    };
    const response = await axios.post(
      `${projectItemsConstant.baseUrl}/system/items`,
      formData,
      {
        headers: headers,
      }
    );

    if (response) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getUserProfile = async () => {
  try {
    const response = await apiService.getWithDiffBaseUrl(
      `/auth/user/profile`,
      undefined,
      REACT_APP_LOGIN_AUTH
    );
    return response;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getProjectCalculations = async projectId => {
  try {
    const response = await apiService.get(
      `/${projectItemsConstant.getProjectCalculationsEndpoint(projectId)}`
    );

    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getDefaultPo = async (projectId, setDefaultPoList) => {
  try {
    const response = await apiService.get(
      `/projects/${projectId}/v1/project-item/list`
    );
    if (response) setDefaultPoList(response);
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};
