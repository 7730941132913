import { SOURCE_SYSTEMS } from '../../../../constants';

export const projectLeadRequiredCondition = (
  {
    source_system_id = null,
    year_built = null,
    epa_lead_assessment_required = null,
    epa_lead_remediation = null,
  },
  action
) => {
  return (
    action !== 'add' &&
    (source_system_id === SOURCE_SYSTEMS.HOME_DEPOT ||
      source_system_id === SOURCE_SYSTEMS.LOWES) &&
    ((source_system_id === SOURCE_SYSTEMS.LOWES &&
      parseInt(year_built) < 1978) ||
      epa_lead_assessment_required === 1 ||
      epa_lead_remediation === 1)
  );
};

export const epaDocumentsStyle = (document, projectLeadRequired = false) => {
  const pendingColor = 'orange-500';
  const sucessColor = 'green-500';
  const failedColor = 'red-500';
  if (!projectLeadRequired) {
    return '';
  }

  switch (document?.epa_lead_details?.document_status) {
    case 'Pending':
      return {
        border: `border-solid border-${pendingColor} border-round-md border-3 p-0`,
        color: `text-${pendingColor}`,
      };

    case 'Approved':
      return {
        border: `border-solid border-${sucessColor} border-round-md border-3 p-0`,
        color: `text-${sucessColor}`,
      };

    case 'fails':
      return {
        border: `border-solid border-${failedColor} border-round-md border-3 p-0`,
        color: `text-${failedColor}`,
      };

    default:
      return '';
  }
};

export const highlightDocument = (document, defaultBorder) => {
  const highlightedColor = 'border-orange-500';
  const successColor = 'border-green-500';

  if (document?.is_highlight_document) {
    return document?.is_source_uploaded ? successColor : highlightedColor;
  }

  return defaultBorder;
};

export const needSupportRequiredCondition = needSupportResponse => {
  if (
    Array.isArray(needSupportResponse?.items) &&
    needSupportResponse?.items?.length > 0
  ) {
    const recentItem = needSupportResponse?.items?.reduce((latest, current) => {
      return new Date(current.created_at) > new Date(latest.created_at)
        ? current
        : latest;
    });

    const isRecentValid =
      recentItem.support_status === 'Pending' ||
      recentItem.support_status === 'In Progress';

    return {
      result: isRecentValid,
      recent_status: isRecentValid ? recentItem.support_status : null,
    };
  } else {
    return {
      result: false,
      recent_status: null,
    };
  }
};
