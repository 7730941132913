import * as Yup from 'yup';

import { CLIENT_DETAILS } from '../../../../constants';
const { group_source_category_opted } = CLIENT_DETAILS || {};

export const generalInformationInitialValues = {
  customerId: '',
  typeId: '',
  categoryId: '',
  sourceId: '',
  referralSource: '',
  leadOwnerUserId: '',
  source_category_id: '',
  statusId: 302,
  description: '',
  follow_up_date: null,
};

let generalInformationValidationSchema = {
  customerId: Yup.number().required('required'),
  typeId: Yup.number().required('required'),
  categoryId: Yup.number().required('required'),
  sourceId: Yup.number().required('required'),
  referralSource: Yup.string().max(50).nullable(),
  leadOwnerUserId: Yup.string().required('required'),
  statusId: Yup.number().required('required'),
  description: Yup.string().max(150).nullable(),
};

if (group_source_category_opted || false) {
  generalInformationValidationSchema = {
    ...generalInformationValidationSchema,
    source_category_id: Yup.number().required('required'),
  };
}

export { generalInformationValidationSchema };
