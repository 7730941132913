import { useState, useEffect, useCallback, useRef } from 'react';
import { Tooltip, Toast } from 'primereact';
import { debounce } from 'lodash';
import { Skeleton } from 'primereact/skeleton';

import PFDataTable from '../../../../shared/PFPrime/PFDataTable';
import PFInputText from '../../../../shared/PFPrime/PFInputText';
import { getNotes } from '../../services/ChargebackService';
import { formatDate } from '../../../../../utils/Helpers';

const NotesList = ({ chargebackId, reload, setReload }) => {
  const [notesList, setNotesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecordsCount, setTotalRecords] = useState(0);
  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: 'created_at',
    sortOrder: -1,
  });
  const [globalFilterValue, setGlobalFilterValue] = useState('');

  const toast = useRef(null);

  const tableColum = [
    {
      field: 'created_at',
      header: 'Date',
      style: { width: '20%' },
      body: params =>
        loading ? (
          <Skeleton />
        ) : params?.created_at ? (
          formatDate(params?.created_at)
        ) : (
          ''
        ),
    },
    {
      field: 'createdBy',
      header: 'Created By',
      style: { width: '25%' },
      body: params =>
        loading ? (
          <Skeleton />
        ) : (
          `${params?.created?.first_name ? params?.created?.first_name : ''} 
        ${params?.created?.middle_name ? params?.created?.middle_name : ''}
        ${params?.created?.last_name ? params?.created?.last_name : ''}`
        ),
    },
    {
      field: 'note',
      header: 'Note',
      body: params => (loading ? <Skeleton /> : params?.note),
    },
  ];

  const fetchNotesList = async searchValue => {
    try {
      setLoading(true);
      const urlParams = {
        offset: lazyState.first,
        limit: lazyState.rows,
        sortField: lazyState.sortField,
        sortOrder: lazyState.sortOrder > 0 ? 'ASC' : 'DESC',
      };
      if (searchValue || globalFilterValue)
        urlParams.globalSearch = searchValue || globalFilterValue;
      const response = await getNotes(chargebackId, urlParams);
      setTotalRecords(response?.data?.count);
      if (response?.data?.result) {
        setNotesList(response?.data?.result);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      toast?.current?.show({
        severity: 'error',
        summary: 'Something went wrong',
        life: 2000,
      });
      setLoading(false);
      setReload(false);
    }
  };

  useEffect(() => {
    fetchNotesList();
  }, [chargebackId, reload, lazyState]);

  const debouncedOnChange = useCallback(debounce(fetchNotesList, 500), []);
  const renderHeader = () => {
    return (
      <div className="flex justify-content-start">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <PFInputText
            value={globalFilterValue}
            onChange={e => {
              setGlobalFilterValue(e.target.value);
              debouncedOnChange(e.target.value);
            }}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    );
  };
  const dataTableParams = { header: renderHeader() };

  return (
    <>
      <PFDataTable
        columns={tableColum}
        data={notesList}
        stripedRows
        dataTableParams={dataTableParams}
        lazy={true}
        dataKey="id"
        paginator={true}
        rows={lazyState.rows}
        rowsPerPageOptions={[5, 10, 50]}
        tableStyle={{ minWidth: '50rem' }}
        onPage={event => {
          setlazyState({
            ...lazyState,
            first: event.first,
            pageCount: event.pageCount,
            rows: event.rows,
          });
        }}
        first={lazyState.first}
        totalRecords={totalRecordsCount}
        onSort={event =>
          setlazyState({
            ...lazyState,
            sortField: event.sortField,
            sortOrder: event.sortOrder,
          })
        }
        sortField={lazyState.sortField}
        sortOrder={lazyState.sortOrder}
      />

      <Tooltip target=".custom-target-icon" />

      <Toast ref={toast} />
    </>
  );
};

export default NotesList;
