import axios, { AxiosError } from 'axios';

import { URL_CONSTANTS } from '../../../../constants/urlConstants';
import {
  BillingItem,
  BillingResponse,
  PaymentStatus,
} from '../Interface/billing.interface';

// Get Billing Data
export const fetchBillingData = async (projectId: number) => {
  try {
    const response = await axios.get<BillingResponse>(
      `${URL_CONSTANTS.API.BASE_URL}/projects/${projectId}/billing`
    );
    return {
      error: false,
      message: 'Billing data fetched successfully',
      billingData: response?.data,
    };
  } catch (error: unknown) {
    console.error('Error fetching billing data:', error);
    return {
      error: true,
      message:
        error instanceof AxiosError
          ? error?.response?.data?.error
          : 'Error fetching billing data',
      billingData: null,
    };
  }
};

export const getQuickBooksData = async (projectId: number) => {
  try {
    const response = await axios.post(
      `${URL_CONSTANTS.API.BASE_URL}/projects/${projectId}/rule/payment`
    );
    return {
      error: false,
      message: 'Payment Data updated successfully.',
      data: response,
    };
  } catch (error) {
    console.error('Error fetching quickbooks data:', error);
    return {
      error: true,
      message:
        error instanceof AxiosError
          ? error?.response?.data?.error
          : 'Error fetching quickbooks data',
      data: null,
    };
  }
};

export const getPaymentStatus = async (statusType: string) => {
  try {
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/system/status`,
      { params: { statusType: statusType } }
    );
    let statusOptions: PaymentStatus[] = [];
    if (response) {
      statusOptions = response?.data;
      const isTentativeSchedulingEnabled =
        localStorage.getItem('isTentativeSchedulingEnabled') === 'true'
          ? true
          : false;
      if (!isTentativeSchedulingEnabled) {
        statusOptions = statusOptions?.filter(
          item => item.status !== 'Tentatively Scheduled'
        );
      }
    }
    return {
      error: false,
      message: '',
      data: statusOptions,
    };
  } catch (error) {
    console.error('Error fetching Payment Status', error);
    return {
      error: true,
      message:
        error instanceof AxiosError
          ? error?.response?.data?.error
          : 'Error fetching Payment Status',
      data: null,
    };
  }
};

export const getPaymentType = async () => {
  try {
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/system/payment-type`
    );
    return {
      error: false,
      message: '',
      data: response?.data,
    };
  } catch (error) {
    console.error('Error fetching Payment Type', error);
    return {
      error: true,
      message:
        error instanceof AxiosError
          ? error?.response?.data?.error
          : 'Error fetching Payment Type',
      data: null,
    };
  }
};

export const createNewPaymentRequest = async (
  projectId: number,
  values: BillingItem
) => {
  try {
    const createObj = {
      check_amount: values.check_amount || null,
      check_date: values.check_date || null,
      check_number: values.check_number?.toString() || null,
      originating_po: values.originating_po?.toString() || null,
      payment_number: values.payment_number,
      payment_status_id: values.status?.status_id,
      payment_type:
        typeof values.payment_type !== 'string' &&
        values.payment_type?.payment_type,
      requested_amount: values.requested_amount,
    };
    const response = await axios.post(
      `${URL_CONSTANTS.API.BASE_URL}/projects/${projectId}/billing`,
      createObj
    );

    return {
      error: false,
      message: response?.data?.message || 'Payment Request added successfully',
      data: response?.data?.status,
    };
  } catch (error) {
    console.log(error);
    return {
      error: true,
      message:
        error instanceof AxiosError
          ? error?.response?.data?.error
          : 'Failed to create Payment Request',
      data: null,
    };
  }
};

export const editPaymentRequest = async (
  projectId: number,
  values: BillingItem,
  paymentRequestId: number | null
) => {
  try {
    const editObj = {
      check_amount: values.check_amount || null,
      check_date:
        values.check_date instanceof Date ? new Date(values?.check_date) : null,
      check_number: values.check_number?.toString() || null,
      originating_po: values.originating_po?.toString() || null,
      payment_number: values.payment_number,
      payment_status_id: values.status?.status_id,
      payment_type:
        typeof values.payment_type !== 'string' &&
        values.payment_type?.payment_type,
      requested_amount: values.requested_amount,
    };
    const response = await axios.patch(
      `${URL_CONSTANTS.API.BASE_URL}/projects/${projectId}/billing/${paymentRequestId}`,
      editObj
    );
    return {
      error: false,
      message:
        response?.data?.message || 'Payment Request updated successfully',
      data: response?.data?.status,
    };
  } catch (error) {
    console.log(error);
    return {
      error: true,
      message:
        error instanceof AxiosError
          ? error?.response?.data?.error || error?.response?.data?.name
          : 'Failed to update Payment Request',
      data: null,
    };
  }
};

export const deletePayment = async (
  projectId: number,
  paymentRequestId: number | null | undefined
) => {
  try {
    const response = await axios.delete(
      `${URL_CONSTANTS.API.BASE_URL}/projects/${projectId}/billing/${paymentRequestId}`
    );
    return {
      error: false,
      message: 'Payment Request deleted successfully',
      data: response?.data?.status,
    };
  } catch (error) {
    console.log(error);
    return {
      error: true,
      message:
        error instanceof AxiosError
          ? error?.response?.data?.error
          : 'Failed to delete Payment Request',
      data: null,
    };
  }
};
