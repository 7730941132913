import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Column } from 'primereact/column';
import { InputNumber } from 'primereact/inputnumber';
import { Toast } from 'primereact/toast';
import { TreeTable } from 'primereact/treetable';
import { Skeleton } from 'primereact/skeleton';

import PFButton from '../../shared/PFPrime/PFButton';
import PFDropdown from '../../shared/PFPrime/PFDropdown';
import PFInputSwitch from '../../shared/PFPrime/PFInputSwitch';
import PFMultiSelect from '../../shared/PFPrime/PFMultiSelect';
import PFTableLoader from '../../shared/Loader/PFTableLoader';
import { useConfirmDialogContext } from '../../../contexts/ConfirmDialog';

import { getUserCoveragePreferences } from './services/user-form-coverage.service';
import {
  getRotationPercentList,
  getInstallersList,
  deleteGroupRotationPercentage,
  deleteRotationPercentage,
  udpateRotationPercentage,
} from './services/user-form-job-assign-coverage.service';

const UserJobAssignRotation = () => {
  const { showConfirmationDialog } = useConfirmDialogContext();
  const [type, setType] = useState(null);
  const [category, setCategory] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [users, setUsers] = useState([]);
  const { user } = useParams();
  const [treeTableData, setTreeTableData] = useState([]);
  const [first, setFirst] = useState(0);
  const [count, setCount] = useState(0);
  const [reloadList, setReloadList] = useState(false);
  const [expandedKeys, setExpandedKeys] = useState({});
  const [nodes, setNodes] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [editState, setEditState] = useState({
    editedValues: {},
    editKey: null,
    errors: {},
    editingKeys: [],
  });

  const toast = useRef(null);
  const fetchOptionsData = async () => {
    try {
      const [OptionsListData, installerList] = await Promise.all([
        getUserCoveragePreferences(user, { first: 0, rows: 10, page: 1 }),
        getInstallersList(user),
      ]);
      const { categoryList, typesList } = OptionsListData;
      setTypeList(typesList);
      setCategoriesList(categoryList);
      const coverageUsers = installerList?.data?.userFilterRes?.map(record => {
        return {
          usersList: record?.first_name + ' ' + record?.last_name,
          user_id: record?.job_coverage_user_id,
        };
      });
      setUsers(coverageUsers);
    } catch (error) {
      console.error(error);
    }
  };
  const fetchListData = async () => {
    try {
      setIsLoading(true);
      const response = await getRotationPercentList(
        user,
        first,
        10,
        type,
        category,
        userList
      );
      if (response.count === 0) {
        setNodes([]);
        setTreeTableData([]);
      } else {
        setTreeTableData(response?.queryResponse);
        setCount(response?.count);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setReloadList(false);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchOptionsData();
  }, []);
  useEffect(() => {
    fetchListData();
  }, [first, reloadList, count]);
  const groupCategoriesByProjectCategoryIds = categories => {
    const grouped = {};
    categories.forEach(category => {
      const key = category.project_category_ids;
      if (!grouped[key]) {
        grouped[key] = [];
      }
      grouped[key].push(category);
    });
    return grouped;
  };
  useEffect(() => {
    if (treeTableData && treeTableData?.length > 0) {
      const treeData = treeTableData?.map((project, projectIndex) => {
        const groupedCategories = groupCategoriesByProjectCategoryIds(
          project.categories
        );
        const childrn = Object.keys(groupedCategories)?.map((key, i) => {
          const categoryUsers = groupedCategories[key];
          return {
            key: `project_${projectIndex}_category_${key}_${i}`,
            data: {
              project_category_ids: key,
              category: categoryUsers[0]?.category,
              user: `${categoryUsers?.length} Users`,
              rotation_percentage: `Balance: ${(
                Math.floor(
                  (100 -
                    categoryUsers?.reduce(
                      (sum, cat) =>
                        sum + parseFloat(cat?.rotation_percentage || 0),
                      0
                    )) *
                    100
                ) / 100
              ).toFixed(2)}`,
            },
            categoryCount: categoryUsers?.length,
            preference_id: categoryUsers?.map(category => {
              return category?.user_job_coverage_id;
            }),
            children: groupedCategories[key]?.map(category => ({
              key: category.user_job_coverage_id,
              data: {
                client_id: category.client_id,
                user_id: category.user_id,
                project_type_id: category.project_type_id,
                user_job_coverage_id: category.user_job_coverage_id,
                project_category_ids: category.project_category_ids,
                category: category.category,
                first_name: category.first_name,
                last_name: category.last_name,
                rotation_percentage: parseFloat(
                  category?.rotation_percentage || 0
                ),
              },
            })),
          };
        });
        return {
          key: project.user_job_coverage_id,
          data: {
            client_id: project.client_id,
            user_id: project.user_id,
            project_type_id: project.project_type_id,
            user_job_coverage_id: project.user_job_coverage_id,
            project_category_ids: project.project_category_ids,
            project_type: project.project_type,
            rotation_percentage: ``,
          },
          children: childrn,
        };
      });
      setNodes(treeData);
    }
  }, [treeTableData]);

  const handleReset = () => {
    setCategory([]);
    setType([]);
    setUserList([]);
    setReloadList(true);
  };
  const userTemplate = node => {
    return (
      <span>
        {node?.children
          ? node?.data?.user
          : `${node?.data?.first_name || ''} ${node?.data?.last_name || ''}`}
      </span>
    );
  };

  const validatePercentage = (value, nodeId) => {
    if (typeof value !== 'number' || isNaN(value)) return 0;
    const valueStr = value.toString();
    const [, decimalPart] = valueStr.split('.');
    let errorMessage = '';
    if (decimalPart?.length > 2) {
      errorMessage += 'Only two decimal places are allowed. ';
    }
    if (value > 100) {
      errorMessage += 'Value cannot exceed 100%.';
    }
    if (errorMessage) {
      setEditState(prev => ({
        ...prev,
        errors: {
          ...prev.errors,
          [nodeId]: errorMessage.trim(),
        },
      }));
      return null;
    }

    setEditState(prev => {
      const newErrors = { ...prev.errors };
      delete newErrors[nodeId];
      return { ...prev, errors: newErrors };
    });

    return value;
  };

  const editTemplate = node => {
    const hasErrors = Object.keys(editState?.errors).length > 0;
    return (
      <>
        {node?.categoryCount && (
          <div className="text-right flex check-time-w">
            {node?.key === editState?.editKey ? (
              <>
                <PFButton
                  icon="pi pi-check"
                  outlined
                  className="border-none px-0 text-500"
                  onClick={() => onRowEditSave(node)}
                  disabled={hasErrors}
                />
                <PFButton
                  icon="pi pi-times"
                  outlined
                  className="border-none px-0 text-500"
                  onClick={() => onRowEditCancel(node)}
                />
              </>
            ) : (
              <PFButton
                icon="pi pi-pencil"
                outlined
                className="border-none px-0 text-500"
                onClick={() => onRowEditInit(node)}
              />
            )}
          </div>
        )}
      </>
    );
  };

  const actionTemplate = node => {
    return (
      <span>
        {node?.categoryCount && (
          <PFButton
            icon="pi pi-refresh"
            outlined
            className="border-none pr-2 pl-0 text-500"
            onClick={() => confirmDelete(node)}
          />
        )}
      </span>
    );
  };
  const rotationTemplate = node => {
    return node?.children ? (
      node?.data?.rotation_percentage && `${node?.data?.rotation_percentage}%`
    ) : (
      <>
        {editState?.editingKeys?.includes(Number(node?.key)) ? (
          <>
            <InputNumber
              value={
                parseFloat(node?.data?.rotation_percentage?.toFixed(2)) || 0
              }
              onChange={e => {
                const validatedValue = validatePercentage(e?.value, node?.key);
                if (validatedValue !== null) {
                  onEditorValueChange(
                    { node, field: 'rotation_percentage' },
                    validatedValue
                  );
                }
              }}
              mode="decimal"
              minFractionDigits={2}
              suffix="%"
              pt={{
                input: {
                  className: 'w-full',
                },
              }}
              inputClassName="w-12"
              min={0}
              maxLength={7}
              onKeyDown={e => e?.stopPropagation()}
            />
            {editState?.errors[node?.key] && (
              <div className="text-red-500">{editState?.errors[node?.key]}</div>
            )}
          </>
        ) : (
          `${node?.data?.rotation_percentage}%`
        )}
      </>
    );
  };

  const onEditorValueChange = (props, value) => {
    const { node } = props;
    setEditState(prev => ({
      ...prev,
      editedValues: {
        ...prev.editedValues,
        [node.key]: {
          ...prev.editedValues[node.key],
          rotationPercentage: value,
          id: node.key,
        },
      },
    }));
  };

  const findNodeByKey = (nodes, key) => {
    for (let node of nodes) {
      if (node?.key === key) {
        return node;
      }
      if (node?.children) {
        const childNode = findNodeByKey(node?.children, key);
        if (childNode) {
          return childNode;
        }
      }
    }
    return null;
  };
  const findParentNode = (nodes, key) => {
    for (let node of nodes) {
      if (node?.children) {
        for (let child of node.children) {
          if (child?.key === key) {
            return node;
          }
        }
        const parent = findParentNode(node?.children, key);
        if (parent) {
          return parent;
        }
      }
    }
    return null;
  };

  const onRowEditInit = node => {
    let editingKeys = [];
    let editedValues = {};
    if (node?.children) {
      const siblingKeys = node?.children.map(n => Number(n.key));
      editingKeys = [...new Set([...siblingKeys.map(key => Number(key))])];

      editedValues = node?.children.reduce((acc, child) => {
        if (child?.data?.user_id) {
          acc[child?.key] = {
            rotationPercentage: child?.data?.rotation_percentage,
            id: child?.key,
          };
        }
        return acc;
      }, {});
    } else {
      editingKeys = [
        ...new Set([...(editState?.editingKeys || []), Number(node.key)]),
      ];
    }

    setEditState(prev => ({
      ...prev,
      editKey: node?.key,
      editingKeys,
      editedValues,
    }));
  };

  const onRowEditSave = async node => {
    setIsLoading(true);
    const updatedNodes = [...nodes];
    const targetNode = findNodeByKey(updatedNodes, node?.key);
    const parentNode = findParentNode(updatedNodes, node?.key);
    const projectCategoryId = +targetNode?.data?.project_category_ids;
    const userList = Object.values(editState?.editedValues);
    const totalPercentage = userList?.reduce(
      (sum, item) => sum + item.rotationPercentage,
      0
    );
    if (totalPercentage !== 100 && totalPercentage !== 0) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Total percentage should be 0% or 100%',
      });
      setIsLoading(false);
      return;
    }
    const payload = {
      typeId: parentNode?.data?.project_type_id,
      categoryId: projectCategoryId,
      userList,
    };

    const response = await udpateRotationPercentage(
      parentNode?.data?.user_id ? parentNode?.data?.user_id : user,
      payload
    );
    if (response && response?.status) {
      setReloadList(true);
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Rotation percentage updated',
      });
    }
    setEditState({
      editedValues: {},
      editKey: null,
      errors: {},
      editingKeys: [],
    });
    setIsLoading(false);
  };

  const onRowEditCancel = () => {
    setEditState({
      editedValues: {},
      editKey: null,
      errors: {},
      editingKeys: [],
    });
  };
  const onDelete = async node => {
    const updatedNodes = [...nodes];
    const targetNode = findNodeByKey(updatedNodes, node.key);
    let payload;
    if (targetNode?.preference_id) {
      payload = targetNode?.preference_id;
    } else {
      payload = targetNode?.key;
    }
    if (targetNode) {
      if (targetNode?.children) {
        const response = await deleteGroupRotationPercentage(user, payload);
        if (response && response?.status) {
          setReloadList(true);
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Rotation percentage set to 0%',
          });
        }
      } else {
        const response = await deleteRotationPercentage(
          targetNode?.data?.user_id,
          targetNode?.data?.user_job_coverage_id
        );
        if (response && response?.status) {
          setReloadList(true);
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Rotation percentage set to 0%',
          });
        }
      }
    }
  };
  const confirmDelete = node => {
    showConfirmationDialog({
      message: 'Are you sure you want to reset this record rotation ?',
      header: 'Reset Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => onDelete(node),
      reject: () => {},
    });
  };
  const onPage = e => {
    setFirst(e.first);
  };
  const rowClassName = node => {
    return { 'p-highlight': node?.children && node?.children?.length > 0 };
  };
  const projectTypeTemplate = node => {
    return (
      <span>
        <span>{node?.data?.project_type}</span>
      </span>
    );
  };
  const toggleAll = checked => {
    setToggle(checked);
    const newExpandedKeys = {};
    if (checked) {
      nodes?.forEach(node => {
        newExpandedKeys[node.key] = true;
        if (node?.children) {
          node.children.forEach(child => {
            newExpandedKeys[child.key] = true;
          });
        }
      });
    }
    setExpandedKeys(newExpandedKeys);
  };
  return (
    <div className="m-2">
      <div className="flex justify-content-between py-3">
        <h3 className="col-12 md:col-4 lg:col-6">Filters </h3>
      </div>
      <div className="grid mt-0">
        <div className="col-12 md:col-4 lg:col-8">
          <div className="grid">
            <div className="col-12 md:col-4 lg:col-4 mb-3 py-0">
              {isLoading ? (
                <Skeleton className="w-full" height="40px" />
              ) : (
                <span className="p-float-label">
                  <PFDropdown
                    value={type}
                    optionLabel="project_type"
                    options={typeList}
                    onChange={(e, value) => {
                      setType(value);
                    }}
                    placeholder="Select a Type"
                    className="w-full h-40rem"
                    filter={true}
                    style={{ height: '40px' }}
                    pt={{
                      input: {
                        className: 'p-inputtext p-inputtext-sm ',
                      },
                    }}
                  />
                  <label htmlFor="client_timezone">Select Type</label>
                </span>
              )}
            </div>
            <div className="col-12 md:col-4 lg:col-4 mb-3 py-0">
              {isLoading ? (
                <Skeleton className="w-full" height="40px" />
              ) : (
                <span className="p-float-label">
                  <PFMultiSelect
                    value={category}
                    optionLabel="category"
                    options={categoriesList}
                    onChange={event => {
                      setCategory(event?.value);
                    }}
                    placeholder="Select a Category"
                    style={{ height: '40px' }}
                    className="w-full"
                    pt={{
                      input: {
                        className: 'p-inputtext p-inputtext-sm',
                      },
                    }}
                    maxSelectedLabels={1}
                    filter
                  />
                  <label htmlFor="client_timezone">Select category</label>
                </span>
              )}
            </div>
            <div className="col-12 md:col-4 lg:col-4 mb-3 py-0">
              {isLoading ? (
                <Skeleton className="w-full" height="40px" />
              ) : (
                <span className="p-float-label">
                  <PFDropdown
                    className="mr-3 w-full"
                    options={users}
                    optionLabel="usersList"
                    optionValue="user_id"
                    filter={true}
                    name="users"
                    style={{ height: '40px' }}
                    value={userList}
                    onChange={(name, value) => {
                      setUserList(value);
                    }}
                  />
                  <label htmlFor="client_timezone">User</label>
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="col-12 md:col-3 lg:col-4">
          <div className="grid">
            <div className="col-12 md:col-4 lg:col-3 col-offset-6 py-0 ">
              {isLoading ? (
                <Skeleton height="40px" width="w-full"></Skeleton>
              ) : (
                <PFButton
                  label="Reset"
                  size="small"
                  outlined
                  severity="primary"
                  className="w-full"
                  onClick={handleReset}
                />
              )}
            </div>
            <div className="col-12 md:col-4 lg:col-3  py-0  ">
              {isLoading ? (
                <Skeleton height="40px" width="w-full"></Skeleton>
              ) : (
                <PFButton
                  label="Apply"
                  size="small"
                  severity="primary"
                  className="w-full"
                  onClick={() => {
                    fetchListData();
                    // setReloadList(true);
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="flex align-items-center mb-1">
        {isLoading ? (
          <Skeleton height="2rem" width="15rem" className="m-2"></Skeleton>
        ) : (
          <>
            <PFInputSwitch
              className="mx-2 md:mb-2"
              checked={toggle}
              onChange={e => toggleAll(e.value)}
            />
            <label htmlFor="enableExpandedView" className="mr-2 mb-2">
              Enable Expanded View
            </label>
          </>
        )}
      </div>
      <div className="card w-12 pt-2">
        <Toast ref={toast} />
        {isLoading ? (
          <PFTableLoader />
        ) : (
          <TreeTable
            value={nodes}
            editMode="row"
            onRowEditSave={onRowEditSave}
            onRowEditCancel={onRowEditCancel}
            lazy
            paginator
            first={first}
            rows={10}
            onPage={onPage}
            totalRecords={count}
            pageLinkSize={6}
            tableStyle={{ minWidth: '60rem' }}
            rowClassName={rowClassName}
            columnResizeMode="expand"
            expandedKeys={expandedKeys}
            loading={reloadList}
            onToggle={e => setExpandedKeys(e.value)}
          >
            <Column
              body={node => actionTemplate(node)}
              className="w-1"
              pt={{
                root: {
                  className: 'pr-0 assign-accordian-w ',
                },
                rowtoggler: {
                  className: 'm-0',
                },
              }}
              expander={true}
            />
            <Column
              body={node => editTemplate(node)}
              pt={{
                root: {
                  className: 'p-0 assign-editicon-w text-start',
                },
              }}
              expander={false}
            />
            <Column
              field="project_type"
              header="Type"
              body={projectTypeTemplate}
              className="w-12rem"
            />
            <Column
              field="category"
              header="Category"
              className="w-12rem"
            ></Column>
            <Column
              field="user"
              header="User(s)"
              body={userTemplate}
              className="w-10rem"
            ></Column>
            <Column
              field="rotation_percentage"
              header="Rotation%"
              body={rotationTemplate}
              className="w-10rem"
            ></Column>
          </TreeTable>
        )}
      </div>
    </div>
  );
};
export default UserJobAssignRotation;
