import React, { useState, useEffect, useRef } from 'react';
import { Card } from 'primereact/card';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { useFormik } from 'formik';
import { Dropdown } from 'primereact/dropdown';
import { useParams } from 'react-router-dom';
import { debounce } from 'lodash';
import { Toast } from 'primereact/toast';
import { InputNumber } from 'primereact/inputnumber';
import * as Yup from 'yup';

import PFInputNumber from '../../shared/PFPrime/PFInputNumber';
import {
  getLaborItemsList,
  getMerchandiseItemsList,
} from '../../ProjectManagement/Items/Items.service';
import PFInputText from '../../shared/PFPrime/PFInputText';
import PFButton from '../../shared/PFPrime/PFButton';

import {
  AddItemData,
  UpdateItemData,
  deleteRowItemData,
  getItemData,
  searchListItemData,
} from './Stores.service';

const ItemSourceCostEach = () => {
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState([]);
  const [productData, setProductData] = useState([]);

  const { storeId } = useParams();
  const type = {
    labor: 'labor',
    product: 'product',
  };
  const [dialogType, setDialogType] = useState('labor');
  const [reloadMasterItems, setReloadMasterItems] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const itemsList = { systemItems: [] };
  const [projectLaborItems, setProjectLaborItems] = useState(itemsList);
  const [projectMerchandiseItems, setProjectMerchandiseItems] =
    useState(itemsList);
  const [handleList, setHandleList] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const toast = useRef(null);
  const [search, setSearch] = useState('');
  const [productSearch, setProductSearch] = useState('');
  const [debounceTimeout, setDebounceTimeout] = useState(null);

  const validationSchema = Yup.object().shape({
    item_id: Yup.string().required('Item ID is required'),
    cost_ea: Yup.number()
      .required('Source Cost Each is required')
      .min(0, 'Cost must be greater than or equal to 0')
      .max(99999999.99, 'Please enter a valid source cost'),
  });

  const srcCostEachFormik = useFormik({
    initialValues: {
      item_no: '',
      cost_ea: '',
    },
    validationSchema: validationSchema,
    onSubmit: async values => {
      try {
        await AddItemData(storeId, {
          item_id: values?.item_id,
          cost: values?.cost_ea,
        });

        setVisible(false);
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Item added successfully!',
        });
        setVisible(false);
        setTimeout(() => {
          srcCostEachFormik.resetForm({
            values: {
              item_id: '',
              cost_ea: '',
            },
          });
          setSelectedItem(null);
        }, 100);
        setHandleList(!handleList);
      } catch (error) {
        if (error.message == 'Request failed with status code 422') {
          toast.current.show({
            severity: 'error',
            summary: 'Duplicate Entry',
            detail: 'Item already exist!',
          });
        } else {
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: 'Failed to add item!',
          });
        }
      }
    },
    enableReinitialize: true,
  });

  const fetchData = async () => {
    try {
      const [laborResponse, productResponse] = await Promise.all([
        getItemData(storeId, type.labor),
        getItemData(storeId, type.product),
      ]);
      const laborData = laborResponse?.map(item => ({
        item_no: item?.item?.item_number,
        item_desc: item?.item?.item_desc,
        cost_ea: { cost_ea: item.cost },
        store_item_id: item.store_item_id,
        item_id: item.item_id,
        type: 'labor',
      }));

      const productData = productResponse?.map(item => ({
        item_no: item?.item?.item_number,
        item_desc: item?.item?.item_desc,
        cost_ea: { cost_ea: item.cost },
        store_item_id: item.store_item_id,
        item_id: item.item_id,
        type: 'product',
      }));

      setData([...laborData]);
      setProductData([...productData]);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [handleList]);

  const handleSearchChange = event => {
    const searchText = event.target.value;
    setSearch(searchText);
    filterItemList(searchText, 'keyLabor');
  };

  const handleProductSearchChange = event => {
    const searchText = event.target.value;
    setProductSearch(searchText);
    filterItemList(searchText, 'keyProduct');
  };

  const filterItemList = async (searchText, keyFrom) => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    const newDebounceTimeout = setTimeout(async () => {
      try {
        if (searchText?.length < 2) {
          await fetchData();
          return;
        }
        const searchResponse = await searchListItemData(storeId, searchText);
        if (searchResponse.length > 0) {
          const updatedLaborListData = [];
          const updatedProductListData = [];

          searchResponse.forEach(item => {
            const newItem = {
              item_no: item?.item?.item_number,
              item_desc: item?.item?.item_desc,
              cost_ea: { cost_ea: item.cost },
              store_item_id: item.store_item_id,
              item_id: item.item_id,
              type: item.item.item_type_id === 2 ? 'labor' : 'product',
            };

            if (newItem.type === 'labor') {
              updatedLaborListData.push(newItem);
            } else {
              updatedProductListData.push(newItem);
            }
          });

          if (keyFrom === 'keyLabor') setData([...updatedLaborListData]);
          if (keyFrom === 'keyProduct') setProductData(updatedProductListData);
        } else {
          if (keyFrom === 'keyLabor') setData([]);
          if (keyFrom === 'keyProduct') setProductData([]);
        }
      } catch (error) {
        console.error('Error searching item data:', error);
      }
    });
    setDebounceTimeout(newDebounceTimeout);
  };

  const filterOptions = debounce(async event => {
    if (event?.filter?.length > 2) {
      getLaborFilterOption(event.filter);
    }
  }, 300);

  const getLaborFilterOption = (filter = undefined) => {
    getLaborItemsList(
      { query: filter },
      setReloadMasterItems,
      result => result?.count && setProjectLaborItems(result?.items)
    );
  };

  //labor item list
  useEffect(() => {
    getLaborFilterOption();
  }, []);

  const merchandiseFilterOptions = debounce(async event => {
    if (event?.filter?.length > 2) {
      getMerchandiseFilterOption(event.filter);
    }
  }, 300);

  const getMerchandiseFilterOption = (filter = undefined) => {
    getMerchandiseItemsList(
      { query: filter },
      setReloadMasterItems,
      result => result?.count && setProjectMerchandiseItems(result?.items)
    );
  };

  //Merchandise item list
  useEffect(() => {
    getMerchandiseFilterOption();
  }, []);

  const onDeleteButtonClick = rowData => {
    setItemToDelete(rowData);
    setDeleteConfirmation(true);
  };

  const confirmDelete = async () => {
    const store_item_id = itemToDelete?.store_item_id;
    try {
      const deleteRowData = await deleteRowItemData(store_item_id);
      if (deleteRowData.status === true) {
        setHandleList(!handleList);
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Item updated successfully!',
        });
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to update item!',
        });
      }
    } catch (error) {
      console.error('Error updating item data:', error);
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'An error occurred while updating item!',
      });
    }
    setDeleteConfirmation(false);
  };

  const actionTemplate = rowData => {
    return (
      <>
        <PFButton
          icon="pi pi-trash"
          type="button"
          text
          outlined
          className="border-none"
          onClick={() => onDeleteButtonClick(rowData)}
        />
      </>
    );
  };

  const itemNumberTemplate = rowData => {
    return rowData?.item_no;
  };

  const itemDescriptionTemplate = rowData => {
    return rowData?.item_desc;
  };

  const costEachTemplate = rowData => {
    if (rowData.cost_ea) {
      return `$ ${rowData.cost_ea.cost_ea}`;
    } else {
      return null;
    }
  };

  const costEachEditor = rowData => {
    return (
      <div style={{ position: 'relative' }}>
        <InputNumber
          inputId="cost_ea"
          value={rowData.value.cost_ea}
          onValueChange={e =>
            rowData.editorCallback({
              cost_ea: e.value,
            })
          }
          mode="currency"
          currency="USD"
          locale="en-US"
        />
      </div>
    );
  };

  const onRowEditComplete = async editedRowData => {
    const store_item_id = editedRowData?.newData?.store_item_id;
    const item_id = editedRowData?.newData?.item_id;
    const cost = editedRowData?.newData?.cost_ea?.cost_ea;

    if (cost > 99999999.99) {
      toast.current.show({
        severity: 'error',
        detail: 'Please enter a valid source cost',
      });
    } else {
      try {
        const editCostEaResponse = await UpdateItemData(store_item_id, {
          item_id: item_id,
          cost: cost,
        });

        if (editCostEaResponse) {
          setHandleList(!handleList);
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Item updated successfully!',
          });
          setHandleList(!handleList);
        } else {
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: 'Failed to update item!',
          });
        }
      } catch (error) {
        console.error('Error updating item data:', error);
        if (cost === null) {
          toast.current.show({
            severity: 'error',

            detail: 'Source cost each can not be empty',
          });
        } else {
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: 'An error occurred while updating item!',
          });
        }
      }
    }
  };

  const onRowEditInit = () => {};

  const selectedLabotIdTemplate = option => {
    if (option) {
      return (
        <div>
          {option?.item_number} {option?.item_desc}
        </div>
      );
    }
  };

  const laborOptionTemplate = option => {
    return (
      <div className="flex align-items-center">
        <div>
          {option?.item_number}
          {option?.item_desc}
        </div>
      </div>
    );
  };

  return (
    <div>
      <Toast ref={toast} />
      <Card
        title="Lowes Quantity Configuration"
        className="text-bold font-bold my-3"
      >
        <Accordion>
          <AccordionTab header="Labor Items">
            <div className="flex  justify-content-between mb-3 ">
              <div>
                <PFInputText
                  className="w-12"
                  placeholder="Search keyword"
                  value={search}
                  onChange={handleSearchChange}
                />
              </div>
              <PFButton
                label="Add Labor Items"
                onClick={() => {
                  setVisible(true);
                  setDialogType('labor');
                }}
              />
            </div>
            <DataTable
              value={data}
              editMode="row"
              onRowEditComplete={onRowEditComplete}
              tableStyle={{ minWidth: '50rem' }}
              paginator={true}
              rows={5}
              rowsPerPageOptions={[5, 10, 25, 50]}
            >
              <Column
                rowEditor
                style={{
                  width: '100px',
                  minWidth: '100px',
                  paddingRight: '0px',
                }}
                bodyStyle={{ textAlign: 'center' }}
              />
              <Column
                header=""
                style={{
                  width: '100px',
                  minWidth: '100px',
                  paddingLeft: '0px',
                }}
                body={actionTemplate}
              />
              <Column
                header="Item #"
                field="item_no"
                style={{ width: '10%', minWidth: '180px' }}
                body={itemNumberTemplate}
              />
              <Column
                field="item_desc"
                header="Item Description"
                body={itemDescriptionTemplate}
              />
              <Column
                field="cost_ea"
                header="Source Cost Each"
                editor={options => costEachEditor(options)}
                body={costEachTemplate}
                style={{ width: '190px' }}
              />
            </DataTable>
          </AccordionTab>
          <AccordionTab header="Product Items">
            <div className="flex justify-content-between mb-3">
              <div>
                <PFInputText
                  className="w-12"
                  placeholder="Search keyword"
                  value={productSearch}
                  onChange={handleProductSearchChange}
                />
              </div>
              <PFButton
                label="Add Product Items"
                onClick={() => {
                  setVisible(true);
                  setDialogType('product');
                }}
              />
            </div>
            <DataTable
              editMode="row"
              value={productData}
              onRowEditComplete={onRowEditComplete}
              onRowEditInit={onRowEditInit}
              tableStyle={{ minWidth: '50rem' }}
              paginator={true}
              rows={5}
              rowsPerPageOptions={[5, 10, 25, 50]}
            >
              <Column
                rowEditor
                style={{
                  width: '100px',
                  minWidth: '100px',
                  paddingRight: '0px',
                }}
                bodyStyle={{ textAlign: 'center' }}
              ></Column>
              <Column
                header=""
                style={{
                  width: '100px',
                  minWidth: '100px',
                  paddingLeft: '0px',
                }}
                body={actionTemplate}
              />
              <Column
                header="Item #"
                field="item_no"
                style={{ width: '10%', minWidth: '180px' }}
                body={itemNumberTemplate}
              />
              <Column
                field="item_desc"
                header="Item Description"
                body={itemDescriptionTemplate}
              />
              <Column
                field="cost_ea"
                header="Source Cost Each"
                editor={options => costEachEditor(options)}
                body={costEachTemplate}
                style={{ width: '190px' }}
              />
            </DataTable>
          </AccordionTab>
        </Accordion>
      </Card>

      <Dialog
        className="w-11 md:w-4"
        header={`Add ${dialogType === 'labor' ? 'Labor' : 'Product'} Item`}
        visible={visible}
        style={{ minHeight: '60vh' }}
        onHide={() => {
          setVisible(false);
          setTimeout(() => {
            srcCostEachFormik.resetForm({
              values: {
                item_id: '',
                cost_ea: '',
              },
            });
            setSelectedItem(null);
          }, 100);
          setHandleList(!handleList);
        }}
        footer={
          <>
            <PFButton
              label="Cancel"
              outlined
              text
              onClick={() => {
                setVisible(false);
                setTimeout(() => {
                  srcCostEachFormik.resetForm({
                    values: {
                      item_id: '',
                      cost_ea: '',
                    },
                  });
                  setSelectedItem(null);
                }, 100);
                setHandleList(!handleList);
              }}
            />
            <PFButton
              label="Save"
              onClick={srcCostEachFormik.handleSubmit}
              disabled={
                !srcCostEachFormik.isValid ||
                !(
                  srcCostEachFormik.values.item_id &&
                  srcCostEachFormik.values.cost_ea
                )
              }
            />
          </>
        }
        draggable={false}
      >
        <div className="my-4">
          <form onSubmit={srcCostEachFormik.handleSubmit}>
            <div className="grid w-full">
              {dialogType === 'labor' ? (
                <div className="col-12 md:col-4 lg:col-12 mb-3 py-0">
                  <span className="p-float-label h-full">
                    <Dropdown
                      loading={reloadMasterItems}
                      name="item_id"
                      filterBy="item_number,item_desc"
                      value={selectedItem}
                      onChange={e => {
                        setSelectedItem(e.value);
                        srcCostEachFormik.setFieldValue(
                          'item_id',
                          e.value?.item_id
                        );
                      }}
                      options={projectLaborItems}
                      optionLabel="item_desc"
                      placeholder="Select (type 3 letters to search)"
                      showFilterClear
                      filter
                      onFilter={e => filterOptions(e)}
                      valueTemplate={selectedLabotIdTemplate}
                      itemTemplate={laborOptionTemplate}
                      className="labor-item w-12 border h-full"
                      appendTo="self"
                      pt={{
                        input: { className: 'height-40' },
                      }}
                    />
                    <label htmlFor="item_id">Item </label>
                  </span>
                </div>
              ) : (
                <div className="col-12 md:col-4 lg:col-12 mb-3 py-0">
                  <span className="p-float-label h-full">
                    <Dropdown
                      loading={reloadMasterItems}
                      name="item_id"
                      filterBy="item_number,item_desc"
                      value={selectedItem}
                      onChange={e => {
                        setSelectedItem(e.value);
                        srcCostEachFormik.setFieldValue(
                          'item_id',
                          e.value?.item_id
                        );
                      }}
                      options={projectMerchandiseItems}
                      optionLabel="item_desc"
                      placeholder="Select (type 3 letters to search)"
                      showFilterClear
                      filter
                      onFilter={e => merchandiseFilterOptions(e)}
                      valueTemplate={selectedLabotIdTemplate}
                      itemTemplate={laborOptionTemplate}
                      className="labor-item w-12 border h-full"
                      appendTo="self"
                      pt={{
                        input: { className: 'height-40' },
                      }}
                    />
                    <label htmlFor="item_id">Item </label>
                  </span>
                </div>
              )}
              <div className="grid w-full">
                <div className="col-12 md:col-12 lg:col-12 my-3 ml-2 py-0">
                  <span className="p-float-label ">
                    <PFInputNumber
                      id="cost_ea"
                      name="cost_ea"
                      value={srcCostEachFormik?.values?.cost_ea}
                      onChange={(name, value) =>
                        srcCostEachFormik?.setFieldValue(
                          'cost_ea',
                          value !== null ? value || '0.00' : 0
                        )
                      }
                      min={0}
                      minFractionDigits={2}
                      className="w-full"
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      required
                    />
                    <label htmlFor="cost_ea">Source Cost Each</label>
                  </span>
                  {srcCostEachFormik.touched?.cost_ea ||
                  srcCostEachFormik?.errors?.cost_ea ? (
                    <div className="text-red-500 text-xs mt-1">
                      {srcCostEachFormik?.errors?.cost_ea}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </form>
        </div>
      </Dialog>

      <Dialog
        header="Confirm Delete"
        visible={deleteConfirmation}
        style={{ width: '450px' }}
        onHide={() => setDeleteConfirmation(false)}
        footer={
          <div>
            <PFButton
              label="No"
              text
              outlined
              onClick={() => setDeleteConfirmation(false)}
            />
            <PFButton label="Yes" onClick={confirmDelete} />
          </div>
        }
        draggable={false}
      >
        <div>Are you sure you want to delete this item?</div>
      </Dialog>
    </div>
  );
};

export default ItemSourceCostEach;
