import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import React from 'react';
import * as Yup from 'yup';
import { InputTextarea } from 'primereact/inputtextarea';

import { ACTION } from '../../../../constants/form-actions.constant';
import { syntheticEvent } from '../../../../utils/event.helper';
import {
  AdditionalChargeItem,
  ChargeFormProps,
  PRType,
} from '../interface/additionalFee.interface';
import {
  createAdditionalCharge,
  getPrTypes,
  updateAdditionalCharge,
} from '../service/additionalFee.service';

const ChargeForm: React.FC<ChargeFormProps> = ({
  dialogFormObj,
  updateDialogFormObj,
  project_id,
  loadAdditionalChargeData,
  toast,
}) => {
  const [loading, setLoading] = React.useState<number>(0);

  const [prTypeOptions, setPRTypeOptions] = React.useState<PRType[]>([]);

  const loadPRType = async () => {
    setLoading(2);
    const response = await getPrTypes();
    setLoading(3);
    if (!response?.error && Array.isArray(response.data)) {
      setPRTypeOptions(response.data);
    } else {
      toast.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: response?.message,
        life: 3000,
      });
    }

    return [];
  };

  React.useEffect(() => {
    if (dialogFormObj.visible) {
      if (!prTypeOptions?.length) loadPRType();
    }
  }, [dialogFormObj.visible]);
  // **** Form Validation Schema ****
  const ChargeValidationSchema = Yup.object().shape({
    pr_number: Yup.string().trim().required('PR# is required'),
    dollar_amount: Yup.number()
      .transform((value, originalValue) =>
        originalValue === '' ? null : value
      )
      .min(1, `Dollar amount should be greater than 0`)
      .max(99999999.99)
      .required('Dollar Amount is required')
      .nullable(),
    store_associate: Yup.string().trim(),
    pr_type_id: Yup.number()
      .typeError('Choose a Type')
      .required('Type is required')
      .nullable(),
    pr_ims_note: Yup.string().trim().required('Notes is required'),
  });
  // **** Formik Form Values ****
  const chargeFormik = useFormik<AdditionalChargeItem>({
    initialValues: {
      pr_date: null,
      dollar_amount: null,
      pr_ims_note: '',
      pr_number: '',
      pr_type_id: null,
      store_associate: '',
    },
    onSubmit: async values => {
      setLoading(1);
      let response;
      const updateValues = {
        ...values,
        pr_date: values?.pr_date || new Date(),
      };
      if (dialogFormObj.formAction === ACTION.ADD) {
        response = await createAdditionalCharge(project_id, updateValues);
      } else {
        if (dialogFormObj.rowData?.project_additional_charge_id)
          response = await updateAdditionalCharge(
            project_id,
            dialogFormObj.rowData?.project_additional_charge_id,
            updateValues
          );
      }
      if (!response?.error) {
        toast.current?.show({
          severity: 'success',
          summary: 'Success',
          detail: response?.message,
          life: 3000,
        });
        chargeFormik.handleReset(syntheticEvent);
        updateDialogFormObj({ visible: false });
        loadAdditionalChargeData();
      } else {
        toast.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: response.message,
          life: 3000,
        });
      }
      setLoading(0);
    },
    validationSchema: ChargeValidationSchema,
    enableReinitialize: true,
    initialTouched: {},
  });

  React.useEffect(() => {
    const formValues: AdditionalChargeItem = {
      pr_date: new Date(),
      dollar_amount: null,
      pr_ims_note: '',
      pr_number: '',
      pr_type_id: null,
      store_associate: '',
    };

    if (dialogFormObj.formAction !== ACTION.ADD && dialogFormObj.rowData) {
      if (dialogFormObj?.rowData?.pr_date == null) {
        formValues.pr_date = null;
      } else if (dialogFormObj?.rowData?.pr_date instanceof Date) {
        formValues.pr_date = dialogFormObj?.rowData?.pr_date;
      } else {
        formValues.pr_date = new Date(
          dialogFormObj?.rowData?.pr_date as string | number
        );
      }

      formValues.dollar_amount = dialogFormObj.rowData.dollar_amount;
      formValues.pr_ims_note = dialogFormObj.rowData.pr_ims_note || '';
      formValues.pr_number = dialogFormObj.rowData.pr_number || '';
      formValues.pr_type_id = dialogFormObj.rowData.pr_type_id;
      formValues.store_associate = dialogFormObj.rowData.store_associate || '';
      // Set the form values to Formik
      chargeFormik.setValues(formValues);
    } else {
      chargeFormik.handleReset(syntheticEvent);
    }
  }, [dialogFormObj.rowData, dialogFormObj.visible, dialogFormObj.formAction]);

  const footerContent = (
    <div>
      <Button
        label="Cancel"
        onClick={e => {
          chargeFormik.handleReset(e);
          updateDialogFormObj({ visible: false });
        }}
        type="button"
        size="small"
      />
      <Button
        label="Save"
        onClick={() => chargeFormik.handleSubmit()}
        loading={loading == 1}
        autoFocus
        disabled={
          !(chargeFormik?.dirty && chargeFormik?.isValid) ||
          loading === 1 ||
          dialogFormObj.formAction === 'view'
        }
        size="small"
        type="button"
      />
    </div>
  );

  const handleDateChange = (value: Date | null | undefined, field: string) => {
    // Convert UTC date to local date
    return value ? chargeFormik.setFieldValue(field, value) : null;
  };
  return (
    <Dialog
      header={
        dialogFormObj.formAction === ACTION.ADD
          ? 'Add Charge'
          : dialogFormObj.formAction === ACTION.EDIT
            ? 'Edit Charge'
            : 'View Charge'
      }
      pt={{
        header: { className: 'border-bottom-1 border-400 py-1' },
        headerTitle: { className: 'text-base' },
        content: { className: 'overflow-visible pb-1' },
      }}
      className="overflow-y-auto"
      visible={dialogFormObj.visible}
      style={{ width: '30vw' }}
      onHide={() => {
        if (!dialogFormObj.visible) return;
        updateDialogFormObj({ visible: false });
        chargeFormik.handleReset(syntheticEvent);
      }}
      footer={footerContent}
    >
      <form className="mt-2">
        <div className="col-12">
          <span className="p-float-label w-full">
            <Calendar
              id="pr_date"
              name="pr_date"
              className="w-full"
              selectionMode="single"
              onChange={e => {
                handleDateChange(e?.value, 'pr_date');
              }}
              onBlur={chargeFormik.handleBlur}
              disabled={dialogFormObj.formAction === ACTION.VIEW}
              value={chargeFormik?.values?.pr_date || new Date()}
              minDate={new Date()}
            />
            <label htmlFor="pr_date">
              Date<span className="text-red-500">*</span>
            </label>
          </span>
        </div>
        <div className="col-12">
          <span className="p-float-label w-full">
            <InputText
              id="pr_number"
              value={chargeFormik?.values?.pr_number || ''}
              disabled={dialogFormObj.formAction === ACTION.VIEW}
              className="w-full"
              onChange={e => {
                chargeFormik.handleBlur(e);
                chargeFormik.setFieldValue('pr_number', e.target.value);
              }}
            />
            <label htmlFor="pr_number" className="text-xs">
              PR#<span className="text-red-500">*</span>
            </label>
          </span>
          {chargeFormik.touched.pr_number && chargeFormik.errors.pr_number ? (
            <div className="text-xs text-red-500">
              {chargeFormik.errors.pr_number}
            </div>
          ) : null}
        </div>
        <div className="col-12">
          <span className="p-float-label w-full">
            <InputNumber
              inputId="dollar_amount"
              className="w-full"
              inputClassName="w-full"
              placeholder=""
              value={chargeFormik.values.dollar_amount}
              onChange={e => {
                chargeFormik.setFieldValue(
                  'dollar_amount',
                  e.value ? Number(e.value) : ''
                );
              }}
              mode="currency"
              currency="USD"
              useGrouping={false}
              disabled={dialogFormObj.formAction === ACTION.VIEW}
            />
            <label htmlFor="dollar_amount" className="text-xs">
              Dollar Amount<span className="text-red-500">*</span>
            </label>
          </span>
          {chargeFormik.touched.dollar_amount &&
          chargeFormik.errors.dollar_amount ? (
            <div className="text-xs text-red-500">
              {chargeFormik.errors.dollar_amount}
            </div>
          ) : null}
        </div>
        <div className="col-12">
          <span className="p-float-label w-full">
            <InputText
              id="store_associate"
              value={chargeFormik?.values?.store_associate || ''}
              disabled={dialogFormObj.formAction === ACTION.VIEW}
              className="w-full"
              onChange={e => {
                chargeFormik.handleBlur(e);
                chargeFormik.setFieldValue('store_associate', e.target.value);
              }}
            />
            <label htmlFor="store_associate" className="text-xs">
              Store Associate
            </label>
          </span>
        </div>
        <div className="col-12">
          <span className="p-float-label w-full">
            <Dropdown
              value={chargeFormik.values.pr_type_id}
              id="pr_type_id"
              onChange={chargeFormik.handleChange}
              onBlur={chargeFormik.handleBlur}
              options={prTypeOptions}
              className="w-full"
              showClear
              optionLabel="type_name"
              optionValue="pr_type_id"
              disabled={dialogFormObj.formAction === ACTION.VIEW}
              appendTo={() => document.body}
              loading={loading === 2}
              placeholder="Loading..."
            />
            <label htmlFor="status" className="text-xs">
              PR Type<span className="text-red-500">*</span>
            </label>
          </span>
          {chargeFormik.touched.pr_type_id && chargeFormik.errors.pr_type_id ? (
            <div className="text-xs text-red-500">
              {chargeFormik.errors.pr_type_id}
            </div>
          ) : null}
        </div>
        <div className="col-12">
          <span className="p-float-label w-full">
            <InputTextarea
              id="pr_ims_note"
              autoResize
              rows={2}
              value={chargeFormik?.values?.pr_ims_note || ''}
              disabled={dialogFormObj.formAction === ACTION.VIEW}
              className="w-full"
              onChange={e => {
                chargeFormik.handleBlur(e);
                chargeFormik.setFieldValue('pr_ims_note', e.target.value);
              }}
            />
            <label htmlFor="pr_ims_note" className="text-xs">
              Notes<span className="text-red-500">*</span>
            </label>
          </span>
          {chargeFormik.touched.pr_ims_note &&
          chargeFormik.errors.pr_ims_note ? (
            <div className="text-xs text-red-500">
              {chargeFormik.errors.pr_ims_note}
            </div>
          ) : null}
        </div>
      </form>
    </Dialog>
  );
};
export default ChargeForm;
