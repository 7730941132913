import React from 'react';
import { Dialog } from 'primereact/dialog';

import UserSettingsContainer from './UserSettingsContainer';

const UserSettingsDialog = ({ showDialog, handleCloseDialogue }) => {
  const handleDialog = () => {
    handleCloseDialogue(false);
  };

  return (
    <Dialog
      header="User Settings"
      visible={showDialog}
      style={{ minHeight: '60vh', maxWidth: '80vw' }}
      onHide={() => handleDialog()}
      draggable={false}
    >
      <UserSettingsContainer />
    </Dialog>
  );
};

export default UserSettingsDialog;
