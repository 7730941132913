import React, { useEffect, useState } from 'react';
import './TaskManagement.css';
import { Avatar } from 'primereact/avatar';
import { Skeleton } from 'primereact/skeleton';

import { formatDateTime } from '../../utils/Helpers';

import { noteList } from './TaskManagement.service';

const NoteList = ({
  taskId,
  typeOfProject,
  taskProjectId,
  refreshNote,
  tableName,
}) => {
  const [noteLists, setNoteLists] = useState([]);
  const [loading, setLoading] = useState(false);
  const getTaskList = async () => {
    const response = await noteList(
      taskId,
      typeOfProject,
      taskProjectId,
      setLoading,
      tableName
    );
    if (response) {
      setNoteLists(response);
    }
  };

  useEffect(() => {
    if (taskId) getTaskList();
  }, [refreshNote, taskId]);

  return (
    <>
      {loading ? (
        <div className="card mt-3">
          <div className="border-round border-1 surface-border p-2">
            <ul className="m-0 p-0 list-none">
              <li className="mb-3">
                <div className="flex">
                  <Skeleton
                    shape="circle"
                    size="4rem"
                    className="mr-2"
                  ></Skeleton>
                  <div style={{ flex: '1' }}>
                    <Skeleton width="100%" className="mb-2"></Skeleton>
                    <Skeleton width="100%" className="mb-2"></Skeleton>
                    <Skeleton width="100%"></Skeleton>
                  </div>
                </div>
              </li>
              <li>
                <div className="flex">
                  <Skeleton
                    shape="circle"
                    size="4rem"
                    className="mr-2"
                  ></Skeleton>
                  <div style={{ flex: '1' }}>
                    <Skeleton width="100%" className="mb-2"></Skeleton>
                    <Skeleton width="100%" className="mb-2"></Skeleton>
                    <Skeleton width="100%"></Skeleton>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      ) : noteLists.length === 0 ? (
        <p className="text-center">No notes found</p>
      ) : (
        <>
          {noteLists.length &&
            noteLists?.map(list => {
              return (
                <div
                  style={{ maxHeight: '200px', overflow: 'auto' }}
                  className="mt-1"
                  key={list.note_id}
                >
                  <div className="mt-2 border-bottom-1 border-primary-100 pb-2">
                    <p className="m-0 w-12 text-sm">{list?.description}</p>
                    <div className="flex flex-wrap mt-1 align-items-center pr-3">
                      {list.profile_image_url === null ||
                      list.profile_image_url === 'null' ? (
                        <Avatar
                          label={list?.first_name.charAt(0).toUpperCase()}
                          size="snall"
                          style={{
                            backgroundColor: '#2196F3',
                            color: '#ffffff',
                          }}
                          shape="circle"
                          className="mr-2"
                        />
                      ) : (
                        <img
                          className="shadow-2 flex-shrink-0 border-round"
                          src={list.profile_image_url}
                          alt="abhi"
                          height="24"
                          width="24"
                        />
                      )}

                      <div className="flex-1 flex flex-column gap-2">
                        <span className="text-blue-500 text-sm">
                          {list?.first_name} {list?.last_name}
                        </span>
                      </div>
                      <span className="text-sm">
                        {formatDateTime(list?.created_at)}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
        </>
      )}
    </>
  );
};

export default NoteList;
