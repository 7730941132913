import { useEffect, useState } from 'react';
import { InputText, Dropdown } from 'primereact';
import { ColorPicker } from 'primereact/colorpicker';
import { RadioButton } from 'primereact/radiobutton';
import { AutoComplete } from 'primereact/autocomplete';

import { getDashboardList } from '../service/widget.service';
import { isRoEnabled } from '../../../../utils/Helpers';

const ButtonWidget = ({ widgetFormik, action, setRoutingWidgetHandler }) => {
  const [dashboardList, setDashboardList] = useState([]);
  const [searchQuery, setSearchQuery] = useState({ limit: 10, offset: 0 });

  useEffect(() => {
    getDashboardList(searchQuery, setDashboardList);
  }, [searchQuery]);

  const suggestDashboard = event => {
    setSearchQuery(prevState => ({
      ...prevState,
      offset: 0,
      search_query: event?.query,
    }));
  };

  return (
    <>
      <div className="font-bold text-sm mb-2">Configuration</div>
      <div className="card flex flex-column gap-3">
        <div className="flex flex-wrap gap-3">
          <div className="flex align-items-center">
            <RadioButton
              inputId="tabularWidget"
              name="tabularWidget"
              value="tabularWidget"
              disabled={action == 'view'}
              onChange={e => {
                widgetFormik.setFieldValue('custom_properties', {
                  ...widgetFormik.values.custom_properties,
                  buttonWidgetType: e.value,
                });
                setRoutingWidgetHandler(parseInt(0));
                widgetFormik.setFieldValue('route_widget', parseInt(0));
              }}
              checked={
                widgetFormik?.values?.custom_properties?.buttonWidgetType ===
                'tabularWidget'
              }
            />
            <label htmlFor="tabularWidget" className="ml-2 text-sm">
              Tabular
            </label>
          </div>
          {widgetFormik.values.widget_type !== 'card' && (
            <>
              <div className="flex align-items-center">
                <RadioButton
                  inputId="urlWidget"
                  disabled={action == 'view'}
                  name="urlWidget"
                  value="urlWidget"
                  onChange={e => {
                    widgetFormik.setFieldValue('custom_properties', {
                      ...widgetFormik.values.custom_properties,
                      buttonWidgetType: e.value,
                    });
                  }}
                  checked={
                    widgetFormik?.values?.custom_properties
                      ?.buttonWidgetType === 'urlWidget'
                  }
                />
                <label htmlFor="urlWidget" className="ml-2 text-sm">
                  URL
                </label>
              </div>
              <div className="flex align-items-center">
                <RadioButton
                  inputId="dashboardWidget"
                  name="dashboardWidget"
                  value="dashboardWidget"
                  disabled={action == 'view'}
                  onChange={e => {
                    widgetFormik.setFieldValue('custom_properties', {
                      ...widgetFormik.values.custom_properties,
                      buttonWidgetType: e.value,
                    });
                  }}
                  checked={
                    widgetFormik?.values?.custom_properties
                      ?.buttonWidgetType === 'dashboardWidget'
                  }
                />
                <label htmlFor="dashboardWidget" className="ml-2 text-sm">
                  Dashboard
                </label>
              </div>
              <div className="flex align-items-center">
                <RadioButton
                  inputId="readOnlyWidget"
                  name="readOnlyWidget"
                  value="readOnlyWidget"
                  disabled={action == 'view'}
                  onChange={e => {
                    widgetFormik.setFieldValue('custom_properties', {
                      ...widgetFormik.values.custom_properties,
                      buttonWidgetType: e.value,
                    });
                  }}
                  checked={
                    widgetFormik?.values?.custom_properties
                      ?.buttonWidgetType === 'readOnlyWidget'
                  }
                />
                <label htmlFor="ingredient4" className="ml-2 text-sm">
                  Title
                </label>
              </div>
            </>
          )}
          {isRoEnabled() && (
            <div className="flex align-items-center">
              <RadioButton
                inputId="routingWidget"
                name="routingWidget"
                value="routingWidget"
                disabled={action == 'view'}
                onChange={e => {
                  widgetFormik.setFieldValue('custom_properties', {
                    ...widgetFormik.values.custom_properties,
                    buttonWidgetType: e.value,
                  });
                  setRoutingWidgetHandler(parseInt(1));
                  widgetFormik.setFieldValue('route_widget', parseInt(1));
                }}
                checked={
                  widgetFormik?.values?.custom_properties?.buttonWidgetType ===
                    'routingWidget' && widgetFormik?.values?.route_widget === 1
                }
              />
              <label htmlFor="routingWidget" className="ml-2 text-sm">
                Routing
              </label>
            </div>
          )}
        </div>
        <div className="grid">
          <div className="block md:flex align-items-center col-12 lg:col-5 gap-4">
            <ColorPicker
              name="bgColor"
              disabled={action == 'view'}
              inline
              value={widgetFormik?.values?.custom_properties?.buttonColor}
              onChange={e => {
                widgetFormik.setFieldValue('custom_properties', {
                  ...widgetFormik.values.custom_properties,
                  buttonColor: `#${e.value}`,
                });
              }}
            />
            <span className="p-float-label w-full mt-4 md:mt-0">
              <InputText
                name="bgColor"
                disabled={action == 'view'}
                value={widgetFormik?.values?.custom_properties?.buttonColor}
                onChange={e => {
                  widgetFormik.setFieldValue('custom_properties', {
                    ...widgetFormik.values.custom_properties,
                    buttonColor: e.target.value,
                  });
                }}
                className="w-full p-inputtext-sm"
              />
              <label htmlFor="bgColor">Background Color</label>
            </span>
          </div>
          <div className="block md:flex align-items-center col-12 lg:col-5 gap-4 mt-4 md:mt-0">
            <ColorPicker
              name="textColor"
              disabled={action == 'view'}
              inline
              value={widgetFormik?.values?.custom_properties?.buttonTextColor}
              onChange={e => {
                widgetFormik.setFieldValue('custom_properties', {
                  ...widgetFormik.values.custom_properties,
                  buttonTextColor: `#${e.value}`,
                });
              }}
            />
            <span className="p-float-label w-full mt-4 md:mt-0">
              <InputText
                name="textColor"
                disabled={action == 'view'}
                value={widgetFormik?.values?.custom_properties?.buttonTextColor}
                onChange={e => {
                  widgetFormik.setFieldValue('custom_properties', {
                    ...widgetFormik.values.custom_properties,
                    buttonTextColor: e.target.value,
                  });
                }}
                className="w-full p-inputtext-sm"
              />
              <label htmlFor="textColor">Text Color</label>
            </span>
          </div>

          <div className="block lg:flex align-items-center flex-2 gap-3 col-12 lg:col-7">
            {widgetFormik?.values?.custom_properties?.buttonWidgetType !==
              'readOnlyWidget' && (
              <div className="flex flex-column gap-2 col-12 lg:col-5 px-0 lg:px-0">
                <span className="p-float-label w-full">
                  <Dropdown
                    id="openIn"
                    disabled={action == 'view'}
                    value={widgetFormik.values.custom_properties?.openIn}
                    onChange={e => {
                      widgetFormik.setFieldValue('custom_properties', {
                        ...widgetFormik.values.custom_properties,
                        openIn: e.value,
                      });
                    }}
                    options={[
                      { name: 'Same Window', code: 'same-tab' },
                      { name: 'New Window', code: 'new-tab' },
                    ]}
                    optionLabel="name"
                    className="w-full"
                    pt={{
                      input: {
                        className: 'p-inputtext p-inputtext-sm',
                      },
                    }}
                  />
                  <label htmlFor="openIn">Open In</label>
                </span>
              </div>
            )}
            {widgetFormik?.values?.custom_properties?.buttonWidgetType ===
              'urlWidget' && (
              <div className="flex flex-column gap-2 col-12 lg:col-6 px-0 lg:px-3">
                <span className="p-float-label w-full">
                  <InputText
                    id="url"
                    value={widgetFormik.values.custom_properties.buttonInfoUrl}
                    disabled={action === 'view' ? true : false}
                    onChange={e => {
                      widgetFormik.setFieldValue('custom_properties', {
                        ...widgetFormik.values.custom_properties,
                        buttonInfoUrl: e.target.value,
                      });
                    }}
                    className="w-full p-inputtext-sm"
                  />
                  <label htmlFor="url">URL*</label>
                </span>
              </div>
            )}
            {widgetFormik?.values?.custom_properties?.buttonWidgetType ===
              'dashboardWidget' && (
              <div className="flex flex-column gap-2 col-12 lg:col-6 px-0 lg:px-3">
                <span className="p-float-label w-full">
                  <AutoComplete
                    id="dashboard_id"
                    disabled={action == 'view'}
                    className="w-12"
                    inputClassName="p-inputtext-sm w-12"
                    suggestions={dashboardList}
                    field="name"
                    completeMethod={suggestDashboard}
                    value={
                      widgetFormik?.values?.custom_properties
                        ?.attachedDashboard || ''
                    }
                    onChange={e =>
                      widgetFormik.setFieldValue('custom_properties', {
                        ...widgetFormik.values.custom_properties,
                        attachedDashboard: e.target.value,
                      })
                    }
                    delay={300}
                    dropdown
                    placeholder="Search Dashboard"
                  />
                  <label htmlFor="manager_id">Dashboard</label>
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ButtonWidget;
