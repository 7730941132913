import axios from 'axios';

import { convertToQueryParams } from '../../../utils/Helpers';
import { URL_CONSTANTS } from '../../../constants/urlConstants';

// Create a new quote
export const addQuote = async createObj => {
  return await axios.post(
    `${URL_CONSTANTS.CRM.baseUrl}/quote/create`,
    createObj
  );
};

// Create a update quote
export const updateQuote = async (updateObj, quoteId) => {
  return await axios.put(
    `${URL_CONSTANTS.CRM.baseUrl}/quote/${quoteId}`,
    updateObj
  );
};

// Get Quote List
export const getQuoteList = async params => {
  const queryParams = convertToQueryParams(params);
  return await axios.get(
    `${URL_CONSTANTS.CRM.baseUrl}/quote/list${queryParams}`
  );
};

// Get Quote by ID
export const getQuoteById = async () => {
  return {
    status: 200,
    data: {
      data: {
        quoteId: 1003,
        clientId: '11MT97PY',
        customerId: 721948,
        title: 'QA Quote Test',
        createDate: '2023-11-14',
        expiryDate: '2023-11-21',
        followupDate: '2023-11-17',
        status: 'REVIEW',
        logo: null,
        companyName: 'Vista Installations',
        termAndConditions: null,
        recurrence: 'Daily',
        customerBillingAddress: '910 Corbin Street , Jacksonville, NC, 28546',
        customerServiceAddress: null,
        url: null,
        emailMessageId: '<0100018c20884074-582a2804-dbc8-4daa-9c5a-74d',
        sendCount: 1,
        createdBy: '9181f550-4313-11ee-ae9a-12cfcc5e8bdb',
        modifiedBy: '9d7cde53-9711-11ed-bdb6-12cfcc5e8bdb',
        isActive: true,
        isDeleted: false,
        parentQuoteId: null,
        createdAt: '2023-11-14 10:07 AM',
        updatedAt: '2023-11-30 02:00 PM',
        lineItems: {
          items: [
            {
              id: 4,
              quoteId: 1003,
              itemId: 48115,
              quantity: 10,
              unitPrice: 100,
              discount: 10,
              tax: 10,
              totalPrice: 990,
              item_number: '10102',
              item_desc: 'QA Qtest',
              item_type: 'Merchandise',
              manufacturer: '-',
              vendor: '-',
            },
          ],
          subTotal: 990,
          discount: 0,
          tax: 0,
          total: 990,
        },
        notes: [
          {
            id: 4,
            quoteId: 1003,
            text: 'QA Test',
            addedBy: 'b8a9636c-5edf-11ee-8ff3-0a9e7375536f',
            dateTime: 'Invalid Date',
            userDetail: {
              user_id: 'b8a9636c-5edf-11ee-8ff3-0a9e7375536f',
              email: 'quality@projectforce.com',
              is_active: 18,
              client_id: '11MT97PY',
              first_name: 'Quality',
              last_name: 'Testuser',
              companyname: null,
            },
          },
        ],
        amount: {
          depositType: 'Amount',
          depositPercent: 0,
          payableAmount: 500,
          amountToBePaidAfterService: 490,
          paymentTerms: 'Less than 15 Days',
        },
        leadSource: {
          leadBy: 'Deepak',
          commisionType: null,
          commisionPercent: null,
          amount: null,
        },
        senderDetails: {
          name: 'test',
          email: 'test@gmail.com',
          phone: '+913490580250',
        },
        clientName: 'Thomas QA',
        clientDetails: {
          customer_id: 721948,
          billing_address_id: 6658471,
          primary_mode_of_contact: 'Email',
          first_name: 'Thomas',
          last_name: 'QA',
          primary_phone: '(123) 363-5449',
          alternate_phone: null,
          customer_emails: [
            {
              customer_email_id: 26371,
              customer_id: 721948,
              email: 'shahrukh.mansuri@vertisystem.com',
              email_type: 'Primary',
              created_at: '2023-11-01T12:41:18.000Z',
              modified_at: '2023-11-01T12:41:18.000Z',
              extra_fields: null,
            },
          ],
          customer_phones: [
            {
              customer_phone_id: 60367,
              customer_id: 721948,
              phone_number: '(123) 363-5449',
              phone_type_id: 2,
              created_at: '2023-11-01T12:41:18.000Z',
              modified_at: '2023-11-01T12:41:18.000Z',
              extra_fields: null,
            },
          ],
          address: [
            {
              address_id: 6658471,
              address1: '910 Corbin Street ',
              address2: null,
              city: 'Jacksonville',
              state: 'NC',
              state_id: null,
              zipcode: '28546',
              latitude: '34.74802540000000',
              longitude: '-77.35792510000000',
              occupant_type: 'Customer',
              name: null,
              client_id: null,
              created_at: '2023-11-01T12:41:18.000Z',
              modified_at: '2023-11-03T12:23:14.000Z',
              qb_cust_id: null,
              full_address: '910 Corbin Street   Jacksonville NC 28546',
            },
          ],
        },
      },
    },
    message: 'Quote (1003) detail fetched successfully',
  };
};

// Get Quote by ID
export const getQuotePreview = async quoteId => {
  return await axios.get(
    `${URL_CONSTANTS.CRM.baseUrl}/quote/preview/${quoteId}`
  );
};

// Get Quote by ID
export const sendQuoteToClient = async () => {
  return {
    status: 200,
    data: null,
    message: 'Quote (1003) sent successfully',
  };
};

//Delete a Quote by ID
export const removeQuote = async quoteId => {
  return await axios.delete(`${URL_CONSTANTS.CRM.baseUrl}/quote/${quoteId}`);
};
