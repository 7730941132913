import * as Yup from 'yup';

export const clientInfoInitialValues = {
  first_name: '',
  last_name: '',
  address: {
    address1: '',
    city: '',
    state: '',
    zipcode: '',
  },
  primary_mode_of_contact: 'Phone',
  customer_phones: '',
  customer_emails: [],
  receive_text_message: false,
  receive_promotional_emails: false,
  is_deleted: false,
};

export const clientInfoValidationSchema = {
  first_name: Yup.string().required('required').max(50),
  last_name: Yup.string().required('required').max(50),
  address: Yup.object().shape({
    billing_address: Yup.number().nullable(),
    address1: Yup.string().required('required'),
    address2: Yup.string().nullable(),
    city: Yup.string().required('required'),
    state: Yup.string().required('required'),
    zipcode: Yup.string().required('required'),
  }),
  primary_mode_of_contact: Yup.string().nullable(),
  customer_phones: Yup.array().required('required'),
  customer_emails: Yup.array().nullable(),
  receive_text_message: Yup.bool().nullable(),
  receive_promotional_emails: Yup.bool().nullable(),
  is_deleted: Yup.bool().nullable(),
};

export const modeOfContact = [
  { id: 1, name: 'Email' },
  { id: 2, name: 'Phone' },
];
