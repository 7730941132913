import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { WidthProvider, Responsive } from 'react-grid-layout';
import { Rating } from 'primereact/rating';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import PFInputText from '../../shared/PFPrime/PFInputText';
import PFDropdown from '../../shared/PFPrime/PFDropdown';
import PFButton from '../../shared/PFPrime/PFButton';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import PFDataTable from '../../shared/PFPrime/PFDataTable';

import { QUESTION_TYPES } from './questionnaire.contants';

const ResponsiveGridLayout = WidthProvider(Responsive);
const TypeCardComponent = forwardRef(({ question, isViewOnly }, ref) => {
  const [fields, setFields] = useState([]);
  const [layouts, setLayouts] = useState({ lg: [] });
  const [editingIndex, setEditingIndex] = useState(null);
  const [currentCols, setCurrentCols] = useState(6);
  const { questionDialogContent } = useSelector(state => state.questionnaire);

  const formik = useFormik({
    initialValues: {
      id: '',
      question_type: '',
      question_type_label: '',
      options: [],
      newOption: '',
    },
    validationSchema: Yup.object({
      question_type_label: Yup.string().required('Field Label is required'),
      question_type: Yup.object().required('Field Type is required'),
      options: Yup.array().when('question_type.id', {
        is: QUESTION_TYPES.list.id, // List type
        then: Yup.array()
          .of(
            Yup.object().shape({
              label: Yup.string()
                .trim()
                .min(1, 'Option cannot be empty')
                .required('Option cannot be empty'),
            })
          )
          .min(1, 'At least one option is required')
          .required('Options are required'),
      }),
    }),
    onSubmit: (values, { resetForm }) => {
      const newField = {
        id: editingIndex !== null ? fields[editingIndex].id : fields.length,
        question_type: values?.question_type,
        question_type_label: values?.question_type_label,
        options:
          values.question_type?.id === QUESTION_TYPES.list.id
            ? values.options
            : [],
        config: {
          position: fields?.length + 1,
          x: fields.length % currentCols, // Keep x within the available columns
          y: Math.floor(fields.length / currentCols), // Calculate y based on rows
          w: 1,
          h: 1,
        },
      };

      if (editingIndex !== null) {
        const updatedFields = [...fields];
        updatedFields[editingIndex] = values;
        setFields(updatedFields);
        setEditingIndex(null);
      } else {
        setFields([...fields, newField]);
        setLayouts(generateLayouts([...fields, newField]));
      }
      resetForm();
    },
  });

  useEffect(() => {
    const cardData = !isViewOnly ? questionDialogContent : question;
    if (cardData?.format_field_types?.length > 0) {
      const prefilledFields = cardData.format_field_types.map((item, index) => {
        const normalizedQuestionType =
          QUESTION_TYPES[item.format_question_type];

        return {
          id: item.question_format_id, // Use API-provided ID
          question_type: normalizedQuestionType,
          question_type_label: item.format_question_label,
          options: item.options || [],
          config: {
            x: item?.config?.x ?? index % currentCols, // Ensure positioning is maintained
            y: item?.config?.y ?? Math.floor(index / currentCols),
            w: item?.config?.w ?? 3,
            h: item?.config?.h ?? 2,
          },
        };
      });

      setFields(prefilledFields);
      setLayouts({
        lg: prefilledFields.map(field => ({
          i: String(field.id),
          x: field.config.x,
          y: field.config.y,
          w: field.config.w,
          h: field.config.h,
          static: false, // Allow movement
        })),
      });
    }
  }, [questionDialogContent, currentCols]);

  useImperativeHandle(ref, () => ({
    validateAndGetValues: async () => {
      const errors = await formik.validateForm();
      if (Object.keys(errors).length !== 0 && fields?.length === 0) {
        formik.setTouched({
          question_type_label: true,
          question_type: true,
          options: true,
        });
        return null; // Return null if validation fails
      }
      const updatedFields = fields.map((field, index) => ({
        ...field,
        config: {
          ...field.config,
          position: index + 1, // Set position as 1, 2, 3, ...
        },
      }));
      return updatedFields; // Return values with corrected position
    },
  }));

  const handleRemoveField = index => {
    const updatedFields = fields.filter((_, i) => i !== index);
    setFields(updatedFields);
  };

  const handleEditField = index => {
    setEditingIndex(index);
    formik.setValues(fields[index]); // Load field data into form
  };
  const handleAddOption = () => {
    if (formik.values.newOption?.trim()) {
      formik.setFieldValue('options', [
        ...formik.values.options,
        { label: formik.values.newOption.trim() },
      ]);
      formik.setFieldValue('newOption', '');
    }
  };

  const handleRemoveOption = optionIndex => {
    formik.setFieldValue(
      'options',
      formik.values.options.filter((_, i) => i !== optionIndex)
    );
  };

  const generateLayouts = fields => {
    const layouts = fields.map(field => ({
      x: field.config.x,
      y: field.config.y,
      w: field.config.w,
      h: field.config.h,
      i: field.id.toString(),
      isResizable: !isViewOnly,
      isDraggable: !isViewOnly,
    }));
    return layouts;
  };

  const handleLayoutChange = (currentLayout, allLayouts) => {
    setLayouts(allLayouts);

    const updatedFields = fields.map(field => {
      const newPosition = currentLayout.find(
        l => String(l.i) === String(field.id)
      );

      return {
        ...field,
        config: {
          ...field.config,
          x: newPosition?.x ?? field.config?.x ?? 0,
          y: newPosition?.y ?? field.config?.y ?? 0,
          w: newPosition?.w ?? field.config?.w ?? 2,
          h: newPosition?.h ?? field.config?.h ?? 2,
        },
      };
    });

    setFields(updatedFields);
  };

  const onBreakpointChange = (breakpoint, cols) => {
    setCurrentCols(cols);
  };

  const handleResizeStop = async currentLayout => {
    try {
      const updatedFields = fields.map(field => {
        const newLayoutItem = currentLayout.find(
          l => String(l.i) === String(field.id)
        );

        return {
          ...field,
          config: {
            ...field.config,
            x: newLayoutItem?.x ?? field.config?.x ?? 0,
            y: newLayoutItem?.y ?? field.config?.y ?? 0,
            w: newLayoutItem?.w ?? field.config?.w ?? 2,
            h: newLayoutItem?.h ?? field.config?.h ?? 2,
          },
        };
      });

      setFields(updatedFields);
    } catch (error) {
      console.error('Error updating field layout', error);
    }
  };

  const listOptionTextEditor = options => {
    return (
      <PFInputText
        type="text"
        value={options.value}
        onChange={e => options.editorCallback(e.target.value)}
      />
    );
  };

  const onListOptionCellEditComplete = e => {
    let updatedFields = _.cloneDeep(fields);
    let updatedListOptions = structuredClone(formik.values?.options);
    updatedFields[editingIndex].options[e?.rowIndex].label =
      e?.newRowData?.label;
    updatedListOptions[e?.rowIndex] = e?.newRowData;
    setFields(updatedFields);
    formik.setFieldValue('options', updatedListOptions);
  };

  const tableFieldChange = (data, value) => {
    const setData = fields.map(row => {
      if (row.question_type_label === data.question_type_label) {
        return {
          ...row,
          value,
        };
      } else {
        return row;
      }
    });

    if (!isViewOnly) {
      setFields(setData);
    }
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleAddOption();
    }
  };

  const renderField = field => {
    switch (field.question_type?.id) {
      case QUESTION_TYPES.rating5.id:
        return (
          <Rating
            value={field?.value || 3}
            cancel={false}
            readOnly={isViewOnly}
            onChange={e => {
              tableFieldChange(field, e.target.value);
            }}
            className={
              isViewOnly ? 'bg-white border-1 border-round-md border-300' : ''
            }
            style={{ padding: isViewOnly ? '10px' : 0 }}
          />
        );
      case QUESTION_TYPES.rating10.id:
        return (
          <Rating
            value={field?.value || 5}
            onChange={e => {
              tableFieldChange(field, e.target.value);
            }}
            stars={10}
            cancel={false}
            readOnly={isViewOnly}
            className={`${isViewOnly && `bg-white border-1 border-round-md border-300 overflow-auto`} custom-rating`}
            style={{ padding: isViewOnly ? '10px' : 0 }}
          />
        );
      case QUESTION_TYPES.list.id:
        return (
          <PFDropdown
            value={field?.value}
            options={field?.options || []}
            className={`${isViewOnly ? 'pointer-events-none' : ''}`}
            onChange={(name, value) => {
              tableFieldChange(field, value);
            }}
            placeholder="Select"
          />
        );
      case QUESTION_TYPES.text.id:
        return (
          <PFInputText
            value={field?.value || ''}
            onChange={e => {
              tableFieldChange(field, e.target.value);
            }}
            className={`${isViewOnly ? 'pointer-events-none' : ''}`}
          />
        );
      default:
        return <span>{field.question_type_label}</span>;
    }
  };

  return (
    <>
      {isViewOnly && <div className="text-2xl">{`Preview`}</div>}
      {/* Form Input */}
      {!isViewOnly && (
        <form onSubmit={formik.handleSubmit}>
          <div className="grid p-2 md:p-3 gap-2 bg-gray-100 mt-3 mb-3 p-card">
            {/* Field Label */}
            <div className="flex flex-column col-12 md:col-3">
              <span className="p-float-label">
                <PFInputText
                  name="question_type_label"
                  value={formik.values.question_type_label}
                  onChange={formik.handleChange}
                  className="w-full"
                />
                <label htmlFor="field_label">
                  Field Label <span style={{ color: 'red' }}>*</span>
                </label>
              </span>
              {formik.touched.question_type_label &&
                formik.errors.question_type_label && (
                  <div className="text-sm text-red-600">
                    {formik.errors.question_type_label}
                  </div>
                )}
            </div>

            {/* Field Type Dropdown */}
            <div className="flex flex-column col-12 md:col-3">
              <span className="p-float-label">
                <PFDropdown
                  value={formik.values.question_type}
                  onChange={(name, value) =>
                    formik.setFieldValue('question_type', value)
                  }
                  options={[
                    QUESTION_TYPES.rating10,
                    QUESTION_TYPES.rating5,
                    QUESTION_TYPES.list,
                    QUESTION_TYPES.text,
                  ]}
                  optionLabel="label"
                  placeholder="Select Field Type"
                />
                <label htmlFor="field_type">
                  Field Type <span style={{ color: 'red' }}>*</span>
                </label>
              </span>
              {formik.touched.question_type && formik.errors.question_type && (
                <div className="text-sm text-red-600">
                  {formik.errors.question_type}
                </div>
              )}
            </div>

            {/* List Type Options */}
            {formik.values.question_type?.id === QUESTION_TYPES.list.id && (
              <div className="col-12 md:col-3">
                <div className="p-inputgroup">
                  <span className="p-float-label">
                    <PFInputText
                      name="newOption"
                      value={formik.values.newOption || ''}
                      onChange={e =>
                        formik.setFieldValue('newOption', e.target.value)
                      }
                      onKeyDown={e => handleKeyDown(e)}
                    />
                    <label htmlFor="add_list">Add List Option</label>
                  </span>
                  <PFButton
                    label="Add"
                    icon="pi pi-plus"
                    onClick={() => handleAddOption()}
                    disabled={!formik.values.newOption?.trim()}
                  />
                </div>
              </div>
            )}
            {/* Submit Button */}
            <div className="col-12 md:col-2">
              <PFButton
                label={editingIndex !== null ? `Update Field` : `Add Field`}
                type="submit"
                className="ml-auto"
              />
            </div>

            {/* Editable List Options Table */}
            {formik.values.question_type?.id === QUESTION_TYPES.list.id &&
              formik.values.options.length > 0 && (
                <PFDataTable
                  editMode="cell"
                  columns={[
                    {
                      field: '',
                      header: '',
                      style: { width: '32px' },
                      sortable: false,
                      filter: false,
                      body: (_, option) => (
                        <i
                          className="pi pi-trash cursor-pointer text-red-500"
                          onClick={() => handleRemoveOption(option.rowIndex)}
                        />
                      ),
                    },
                    {
                      field: 'label',
                      header: 'List Options',
                      sortable: false,
                      filter: false,
                      editor: row => listOptionTextEditor(row),
                      onCellEditComplete: e => onListOptionCellEditComplete(e),
                    },
                  ]}
                  tableStyle={{ width: '100%' }}
                  data={formik.values.options}
                  stripedRows
                  scrollable
                  scrollHeight="250px"
                />
              )}
          </div>
        </form>
      )}

      {/* Card Grid */}
      {!isViewOnly && <div className="text-2xl">{`Preview`}</div>}
      {fields?.length > 0 && (
        <div className="p-2 md:p-4 bg-gray-100 border-1 border-400 border-round-xl w-full">
          <ResponsiveGridLayout
            className="layout"
            layouts={layouts}
            breakpoints={{ lg: 1920, md: 1440, sm: 780, xs: 480 }}
            cols={{ lg: 6, md: 4, sm: 3, xs: 1 }}
            containerPadding={[10, 10]}
            rowHeight={80}
            compactType={null}
            preventCollision={true}
            onLayoutChange={handleLayoutChange}
            onBreakpointChange={onBreakpointChange} // **Integrate onBreakpointChange here**
            onResizeStop={handleResizeStop}
            isDraggable={!isViewOnly} // **Control draggable based on view mode**
            isResizable={!isViewOnly} // **Control resizing based on view mode**
          >
            {fields.map((field, index) => (
              <div
                key={field.id}
                data-grid={{
                  x: field?.config?.x || index % currentCols,
                  y: field?.config?.y || Math.floor(index / currentCols),
                  w: field?.config?.w || 1,
                  h: field?.config?.h || 1,
                  isResizable: !isViewOnly,
                }}
                className={`${!isViewOnly && 'bg-white cursor-move border-1 border-400 border-round-lg'} p-2 shadow`}
              >
                <div className="flex justify-content-between align-items-center w-full mb-1">
                  <span className="drag-handle font-bold">
                    {field.question_type_label}
                  </span>
                  {!isViewOnly && (
                    <div className="flex gap-2">
                      {/* **Edit Icon** */}
                      <i
                        className="pi pi-pencil text-blue-500 cursor-pointer"
                        onClick={() => handleEditField(index)}
                      />
                      {/* **Delete Icon** */}
                      <i
                        className="pi pi-times text-red-500 cursor-pointer"
                        onClick={() => handleRemoveField(index)}
                      />
                    </div>
                  )}
                </div>
                <div style={{ position: 'relative', zIndex: 1000 }}>
                  {renderField(field)}
                </div>
              </div>
            ))}
          </ResponsiveGridLayout>
        </div>
      )}
    </>
  );
});

TypeCardComponent.displayName = 'TypeCardComponent';
export default TypeCardComponent;
