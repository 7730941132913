import { useLocation } from 'react-router-dom';
import { Grid, TextField } from '@material-ui/core';

const TestedLeadNegative = ({ LrrpConfigurationFormik, setBtnDisables }) => {
  const { pathname } = useLocation();
  const isAdminConfigPath =
    /^\/admin\/(configuration|company-config)\/?.*$/.test(pathname);
  return (
    <>
      {isAdminConfigPath && (
        <Grid container item direction="row" spacing={2}>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              id="name"
              label="Test Kit Manufacturer"
              name="name"
              value={
                LrrpConfigurationFormik.values?.tested_lead_negative
                  ?.test_kit_manufacturer
              }
              onChange={event => {
                LrrpConfigurationFormik.setFieldValue('tested_lead_negative', {
                  ...LrrpConfigurationFormik.values.tested_lead_negative,
                  test_kit_manufacturer: event.target.value,
                });
                setBtnDisables(false);
              }}
              required
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              id="name"
              label="Component Location Tested"
              name="name"
              value={
                LrrpConfigurationFormik.values?.tested_lead_negative
                  ?.component_location_tested
              }
              onChange={event => {
                LrrpConfigurationFormik.setFieldValue('tested_lead_negative', {
                  ...LrrpConfigurationFormik.values.tested_lead_negative,
                  component_location_tested: event.target.value,
                });
                setBtnDisables(false);
              }}
              required
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default TestedLeadNegative;
