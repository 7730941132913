import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment-timezone';
const initialState = {
  isLoading: true,
  installersList: [],
  filterInstallerList: [],
  selectedInstallers: [],
  eventsList: [],
  eventsQueryParams: {
    date: moment(new Date()).format(),
    period: 'W',
  },
  isProjectEditDialogOpen: false,
  projectData: {},
  rescheduleReasons: [],
  projectEditDialogSettings: {
    title: 'Edit Project Info',
    button1Text: '',
    button2Text: 'Save',
    showButton1: true,
    showButton2: true,
  },
  refreshFlag: false,
  viewChangeFlag: false,
  isSelectDialogOpen: false,
  newEventInfo: {},
  dropInfo: {},
  dropDialogData: {},
  isDropDialogOpen: false,
  isWeekendsVisible: false,
  isSelectAllInstallers: false,
  allInclusiveFilter: {},
  rtsFilter: {},
  isSHowSchedulerComponent: false,
  view: '',
  droppedResourceDetails: {},
};

const newSchedulerSlice = createSlice({
  name: 'newScheduler',
  initialState: initialState,
  reducers: {
    setNewSchedulerComponent(state, { payload }) {
      state.isLoading = Object.prototype.hasOwnProperty.call(
        payload,
        'isLoading'
      )
        ? payload.isLoading
        : state.isLoading;
      state.installersList = Object.prototype.hasOwnProperty.call(
        payload,
        'installersList'
      )
        ? payload.installersList
        : state.installersList;
      state.filterInstallerList = Object.prototype.hasOwnProperty.call(
        payload,
        'filterInstallerList'
      )
        ? payload.filterInstallerList
        : state.filterInstallerList;
      state.selectedInstallers = Object.prototype.hasOwnProperty.call(
        payload,
        'selectedInstallers'
      )
        ? payload.selectedInstallers
        : state.selectedInstallers;
      state.eventsList = Object.prototype.hasOwnProperty.call(
        payload,
        'eventsList'
      )
        ? payload.eventsList
        : state.eventsList;
      state.eventsQueryParams = Object.prototype.hasOwnProperty.call(
        payload,
        'eventsQueryParams'
      )
        ? payload.eventsQueryParams
        : state.eventsQueryParams;
      state.isProjectEditDialogOpen = Object.prototype.hasOwnProperty.call(
        payload,
        'isProjectEditDialogOpen'
      )
        ? payload.isProjectEditDialogOpen
        : state.isProjectEditDialogOpen;
      state.projectData = Object.prototype.hasOwnProperty.call(
        payload,
        'projectData'
      )
        ? payload.projectData
        : state.projectData;
      state.rescheduleReasons = Object.prototype.hasOwnProperty.call(
        payload,
        'rescheduleReasons'
      )
        ? payload.rescheduleReasons
        : state.rescheduleReasons;
      state.refreshFlag = Object.prototype.hasOwnProperty.call(
        payload,
        'refreshFlag'
      )
        ? payload.refreshFlag
        : state.refreshFlag;
      state.viewChangeFlag = Object.prototype.hasOwnProperty.call(
        payload,
        'viewChangeFlag'
      )
        ? payload.viewChangeFlag
        : state.viewChangeFlag;
      state.isSelectDialogOpen = Object.prototype.hasOwnProperty.call(
        payload,
        'isSelectDialogOpen'
      )
        ? payload.isSelectDialogOpen
        : state.isSelectDialogOpen;
      state.newEventInfo = Object.prototype.hasOwnProperty.call(
        payload,
        'newEventInfo'
      )
        ? payload.newEventInfo
        : state.newEventInfo;
      state.dropInfo = Object.prototype.hasOwnProperty.call(payload, 'dropInfo')
        ? payload.dropInfo
        : state.dropInfo;
      state.dropDialogData = Object.prototype.hasOwnProperty.call(
        payload,
        'dropDialogData'
      )
        ? payload.dropDialogData
        : state.dropDialogData;
      state.isDropDialogOpen = Object.prototype.hasOwnProperty.call(
        payload,
        'isDropDialogOpen'
      )
        ? payload.isDropDialogOpen
        : state.isDropDialogOpen;
      state.isWeekendsVisible = Object.prototype.hasOwnProperty.call(
        payload,
        'isWeekendsVisible'
      )
        ? payload.isWeekendsVisible
        : state.isWeekendsVisible;
      state.selectedProject = Object.prototype.hasOwnProperty.call(
        payload,
        'selectedProject'
      )
        ? payload.selectedProject
        : state.selectedProject;
      state.isSelectAllInstallers = Object.prototype.hasOwnProperty.call(
        payload,
        'isSelectAllInstallers'
      )
        ? payload.isSelectAllInstallers
        : state.isSelectAllInstallers;

      state.view = Object.prototype.hasOwnProperty.call(payload, 'view')
        ? payload.view
        : state.view;

      state.droppedResourceDetails = Object.prototype.hasOwnProperty.call(
        payload,
        'droppedResourceDetails'
      )
        ? payload.droppedResourceDetails
        : state.droppedResourceDetails;
    },
    setFilter(state, { payload }) {
      state.allInclusiveFilter = payload;
    },
    setRtsFilter(state, { payload }) {
      state.rtsFilter = payload;
      state.isSHowSchedulerComponent = true;
    },
    setIsShowShedulerComponent(state) {
      state.isSHowSchedulerComponent = true;
    },
  },
});

export const {
  setNewSchedulerComponent,
  setFilter,
  setRtsFilter,
  setIsShowShedulerComponent,
} = newSchedulerSlice.actions;

export const newSchedulerReducer = newSchedulerSlice.reducer;
