import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import React from 'react';
import * as Yup from 'yup';
import { InputTextarea } from 'primereact/inputtextarea';

import { ACTION } from '../../../../constants/form-actions.constant';
import { syntheticEvent } from '../../../../utils/event.helper';
import {
  ExpenseFormProps,
  ExpenseItem,
  ExpenseSource,
} from '../interface/additionalFee.interface';
import {
  createExpense,
  getExpenseSource,
  updateExpense,
} from '../service/additionalFee.service';

const ExpenseForm: React.FC<ExpenseFormProps> = ({
  dialogFormObj,
  updateDialogFormObj,
  project_id,
  loadExpensesData,
  toast,
}) => {
  const [loading, setLoading] = React.useState<number>(0);

  const [expenseSourceOptions, setExpenseSourceOptions] = React.useState<
    ExpenseSource[]
  >([]);

  const loadExpenseSource = async () => {
    setLoading(2);
    const response = await getExpenseSource();
    setLoading(3);
    if (!response?.error && Array.isArray(response.data)) {
      setExpenseSourceOptions(response.data);
    } else {
      toast.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: response?.message,
        life: 3000,
      });
    }

    return [];
  };

  React.useEffect(() => {
    if (dialogFormObj.visible) {
      if (!expenseSourceOptions?.length) loadExpenseSource();
    }
  }, [dialogFormObj.visible]);
  // **** Form Validation Schema ****
  const ExpenseValidationSchema = Yup.object().shape({
    description: Yup.string().trim().required('Description is required'),
    dollar_amount: Yup.number()
      .transform((value, originalValue) =>
        originalValue === '' ? null : value
      )
      .min(1, `Dollar amount should be greater than 0`)
      .max(99999999.99)
      .required('Dollar Amount is required')
      .nullable(),
    notes: Yup.string().trim().required('Notes is required'),
    expense_source_id: Yup.number()
      .typeError('Choose a Expense Source')
      .required('Expense Source is required')
      .nullable(),
  });
  // **** Formik Form Values ****
  const expenseFormik = useFormik<ExpenseItem>({
    initialValues: {
      description: '',
      dollar_amount: null,
      notes: '',
      expense_source_id: null,
    },
    onSubmit: async values => {
      setLoading(1);
      let response;

      if (dialogFormObj.formAction === ACTION.ADD) {
        response = await createExpense(project_id, values);
      } else {
        if (dialogFormObj.rowData?.project_expense_id)
          response = await updateExpense(
            project_id,
            dialogFormObj.rowData?.project_expense_id,
            values
          );
      }
      if (!response?.error) {
        toast.current?.show({
          severity: 'success',
          summary: 'Success',
          detail: response?.message,
          life: 3000,
        });
        expenseFormik.handleReset(syntheticEvent);
        updateDialogFormObj({ visible: false });
        loadExpensesData();
      } else {
        toast.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: response.message,
          life: 3000,
        });
      }
      setLoading(0);
    },
    validationSchema: ExpenseValidationSchema,
    enableReinitialize: true,
    initialTouched: {},
  });

  React.useEffect(() => {
    const formValues: ExpenseItem = {
      description: '',
      dollar_amount: null,
      notes: '',
      expense_source_id: null,
    };

    if (dialogFormObj.formAction !== ACTION.ADD && dialogFormObj.rowData) {
      formValues.dollar_amount = dialogFormObj.rowData.dollar_amount;
      formValues.description = dialogFormObj.rowData.description || '';
      formValues.notes = dialogFormObj.rowData.notes || '';
      formValues.expense_source_id = dialogFormObj.rowData.expense_source_id;

      // Set the form values to Formik
      expenseFormik.setValues(formValues);
    } else {
      expenseFormik.handleReset(syntheticEvent);
    }
  }, [dialogFormObj.rowData, dialogFormObj.visible, dialogFormObj.formAction]);

  const footerContent = (
    <div>
      <Button
        label="Cancel"
        onClick={e => {
          expenseFormik.handleReset(e);
          updateDialogFormObj({ visible: false });
        }}
        type="button"
        size="small"
      />
      <Button
        label="Save"
        onClick={() => expenseFormik.handleSubmit()}
        loading={loading == 1}
        autoFocus
        disabled={
          !(expenseFormik?.dirty && expenseFormik?.isValid) ||
          loading === 1 ||
          dialogFormObj.formAction === 'view'
        }
        size="small"
        type="button"
      />
    </div>
  );

  return (
    <Dialog
      header={
        dialogFormObj.formAction === ACTION.ADD
          ? 'Add Expense'
          : dialogFormObj.formAction === ACTION.EDIT
            ? 'Edit Expense'
            : 'View Expense'
      }
      pt={{
        header: { className: 'border-bottom-1 border-400 py-1' },
        headerTitle: { className: 'text-base' },
        content: { className: 'overflow-visible pb-1' },
      }}
      className="overflow-y-auto"
      visible={dialogFormObj.visible}
      style={{ width: '30vw' }}
      onHide={() => {
        if (!dialogFormObj.visible) return;
        updateDialogFormObj({ visible: false });
        expenseFormik.handleReset(syntheticEvent);
      }}
      footer={footerContent}
    >
      <form className="mt-2">
        <div className="col-12">
          <span className="p-float-label w-full">
            <Dropdown
              value={expenseFormik.values.expense_source_id}
              id="expense_source_id"
              onChange={expenseFormik.handleChange}
              onBlur={expenseFormik.handleBlur}
              options={expenseSourceOptions}
              className="w-full"
              showClear
              optionLabel="source_name"
              optionValue="expense_source_id"
              disabled={dialogFormObj.formAction === ACTION.VIEW}
              appendTo={() => document.body}
              loading={loading === 2}
              placeholder="Loading..."
            />
            <label htmlFor="status" className="text-xs">
              Expense Source<span className="text-red-500">*</span>
            </label>
          </span>
          {expenseFormik.touched.expense_source_id &&
          expenseFormik.errors.expense_source_id ? (
            <div className="text-xs text-red-500">
              {expenseFormik.errors.expense_source_id}
            </div>
          ) : null}
        </div>
        <div className="col-12">
          <span className="p-float-label w-full">
            <InputText
              id="pr_number"
              value={expenseFormik?.values?.description || ''}
              disabled={dialogFormObj.formAction === ACTION.VIEW}
              className="w-full"
              onChange={e => {
                expenseFormik.handleBlur(e);
                expenseFormik.setFieldValue('description', e.target.value);
              }}
            />
            <label htmlFor="description" className="text-xs">
              Description<span className="text-red-500">*</span>
            </label>
          </span>
          {expenseFormik.touched.description &&
          expenseFormik.errors.description ? (
            <div className="text-xs text-red-500">
              {expenseFormik.errors.description}
            </div>
          ) : null}
        </div>
        <div className="col-12">
          <span className="p-float-label w-full">
            <InputNumber
              inputId="dollar_amount"
              className="w-full"
              inputClassName="w-full"
              placeholder=""
              value={expenseFormik.values.dollar_amount}
              onChange={e => {
                expenseFormik.setFieldValue(
                  'dollar_amount',
                  e.value ? Number(e.value) : ''
                );
              }}
              mode="currency"
              currency="USD"
              useGrouping={false}
              disabled={dialogFormObj.formAction === ACTION.VIEW}
            />
            <label htmlFor="dollar_amount" className="text-xs">
              Dollar Amount<span className="text-red-500">*</span>
            </label>
          </span>
          {expenseFormik.touched.dollar_amount &&
          expenseFormik.errors.dollar_amount ? (
            <div className="text-xs text-red-500">
              {expenseFormik.errors.dollar_amount}
            </div>
          ) : null}
        </div>
        <div className="col-12">
          <span className="p-float-label w-full">
            <InputTextarea
              id="notes"
              autoResize
              rows={2}
              value={expenseFormik?.values?.notes || ''}
              disabled={dialogFormObj.formAction === ACTION.VIEW}
              className="w-full"
              onChange={e => {
                expenseFormik.handleBlur(e);
                expenseFormik.setFieldValue('notes', e.target.value);
              }}
            />
            <label htmlFor="Notes" className="text-xs">
              Notes<span className="text-red-500">*</span>
            </label>
          </span>
          {expenseFormik.touched.notes && expenseFormik.errors.notes ? (
            <div className="text-xs text-red-500">
              {expenseFormik.errors.notes}
            </div>
          ) : null}
        </div>
      </form>
    </Dialog>
  );
};
export default ExpenseForm;
