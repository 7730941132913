export const SESSION_LINKS = {
  VIEW_LINKS: 'viewLinks',
  EDIT_LINKS: 'editLinks',
};

export const WIDGET_RENDER_FROM = [
  'Report-Details',
  'Dashboard-Widget',
  'Widget-Container',
];
