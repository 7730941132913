import { URL_CONSTANTS } from '../../../constants/urlConstants';
import apiService from '../../../services/api.service';

export const inspectionList = async targetId => {
  try {
    return await apiService.getWithDiffBaseUrl(
      `/api/questionnaire/inspection-submit-details/${targetId}?moduleTags=PROJECT`,
      null,
      URL_CONSTANTS.QUESTIONNAIRE.baseUrl
    );
  } catch (error) {
    console.error(error);
    return null;
  }
};
export const moduleListByFilter = async queryParams => {
  try {
    return await apiService.getWithDiffBaseUrl(
      `/api/questionnaire/module/type-list/PROJECT`,
      queryParams,
      URL_CONSTANTS.QUESTIONNAIRE.baseUrl
    );
  } catch (error) {
    console.error(error);
    return null;
  }
};
