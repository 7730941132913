import React, { useContext } from 'react';

import PFDialog from '../../../shared/PFPrime/PFDialog';
import { ShippingContext } from '../ShippingContainer';

import { Header } from './Header';
import { shippingBody } from './ShippingBody';

export const Shipment = () => {
  const { shippingContextValue } = useContext(ShippingContext);

  return (
    <div className="grid">
      <div className="col-12">
        <PFDialog
          show={shippingContextValue?.show}
          hide={shippingContextValue?.hide}
          header={Header}
          BodyComponent={shippingBody()}
        />
      </div>
    </div>
  );
};
