import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { InputSwitch } from 'primereact/inputswitch';
import { Tooltip } from 'primereact/tooltip';
import { Divider } from 'primereact/divider';

import PFButton from '../../../../shared/PFPrime/PFButton';
import PFMultiSelect from '../../../../shared/PFPrime/PFMultiSelect';
import PFInputText from '../../../../shared/PFPrime/PFInputText';
import PFCalendar from '../../../../shared/PFPrime/PFCalendar';
import { formAction, SchedulerCustomFilters } from '../../../../../constants';

import {
  suggestStore,
  suggestCategory,
  suggestDistrict,
  suggestProjectStatus,
  suggestProjectType,
  suggestSourceStatus,
  suggestWorkRoom,
  saveFilter,
  updateFilter,
  selectedLabel,
  createPayloadFromFormikValues,
  isAtLeastOneFieldSelected,
  processFilterData,
  suggestCustomerOptions,
} from './AddEditReport.model';

const AddEditReportDailog = ({
  visible,
  setVisible,
  action,
  selectedRowData,
  setSelectedRowData,
  setReloadList,
  filterType,
  userId = '',
  toast,
}) => {
  const [storeOptions, setStoreOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [projectTypeOptions, setProjectTypeOptions] = useState([]);
  const [projectStatusOptions, setProjectStatusOptions] = useState([]);
  const [sourceStatusOptions, setSourceStatusOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [workRoomOptions, setWorkroomOptions] = useState([]);
  const [dropdownError, setDropdownError] = useState(false);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const { technicians } = useSelector(state => state.technicians);
  const { userTypes } = useSelector(state => state.userTypes);

  useEffect(() => {
    suggestCategory('', setCategoryOptions);
    suggestStore('', setStoreOptions);
    suggestProjectType('', setProjectTypeOptions);
    suggestProjectStatus('', setProjectStatusOptions);
    suggestSourceStatus('', setSourceStatusOptions);
    suggestDistrict('', setDistrictOptions);
    suggestWorkRoom('', setWorkroomOptions);
    if (filterType == SchedulerCustomFilters.USER) {
      suggestCustomerOptions('', setCustomerOptions);
    }
  }, []);
  const options = {
    storeOptions,
    categoryOptions,
    projectTypeOptions,
    projectStatusOptions,
    sourceStatusOptions,
    districtOptions,
    workRoomOptions,
    customerOptions,
  };
  const setters = {
    setStoreOptions,
    setCategoryOptions,
    setProjectTypeOptions,
    setProjectStatusOptions,
    setSourceStatusOptions,
    setDistrictOptions,
    setWorkroomOptions,
    setCustomerOptions,
  };
  useEffect(() => {
    if (selectedRowData) {
      let filterValues = {};
      if (selectedRowData?.filters && selectedRowData?.filters?.length > 0) {
        filterValues = processFilterData(selectedRowData, options, setters);
      }

      formik.setValues(prevValues => ({
        ...prevValues,
        filter_name: selectedRowData?.filter_name,
        store: filterValues?.store || [],
        projectCategory: filterValues?.projectCategory || [],
        projectType: filterValues?.projectType || [],
        status: filterValues?.status || [],
        sourceStatus: filterValues?.sourceStatus || [],
        district: filterValues?.district || [],
        workroom: filterValues?.workroom || [],
        isActive: selectedRowData?.is_active ? true : false,
        customer: filterValues?.customer || [],
        technician:
          selectedRowData?.selectedFilter?.technicianList?.map(
            value => value?.user_id
          ) || [],
      }));
    }
  }, [selectedRowData]);

  const filtersValidation = Yup.object().shape({
    filter_name: Yup.string().required('Required'),
  });
  const formik = useFormik({
    initialValues: {
      filter_name: '',
      store: [],
      projectCategory: [],
      projectType: [],
      status: [],
      sourceStatus: [],
      district: [],
      workroom: [],
      technician: [],
      isActive: false,
    },
    validationSchema: filtersValidation,
    enableReinitialize: true,
    onSubmit: values => {
      const payload = createPayloadFromFormikValues(values);
      if (!isAtLeastOneFieldSelected(formik)) {
        setDropdownError(true);
        return;
      } else {
        setDropdownError(false);
        if (action === formAction.ADD) {
          saveFilter(
            payload,
            setReloadList,
            toast,
            setVisible,
            filterType,
            userId,
            setLoading
          );
        } else if (action === formAction.EDIT) {
          updateFilter(
            selectedRowData?.filter_id,
            payload,
            setReloadList,
            toast,
            setVisible,
            filterType,
            userId,
            setLoading
          );
        }
      }
    },
  });
  const renderHeader = () => {
    return (
      <div
        className={`p-0 col-12 justify-content-space-between align-items-center flex-wrap`}
      >
        {filterType == SchedulerCustomFilters.USER ? (
          <>
            <span className="col-12 md:col-6 lg:col-6">Global Filters</span>
            <i
              className="custom-target-icon pi pi-info-circle text-primary p-overlay-badge"
              data-pr-tooltip={`Grayed-out filters are not supported or considered.`}
              data-pr-position="right"
              data-pr-at="right+5 top"
              data-pr-my="left center-2"
              style={{
                fontSize: '1rem',
                cursor: 'pointer',
                marginLeft: '10px',
              }}
            ></i>
            <Tooltip target=".custom-target-icon" />
          </>
        ) : (
          <span className="col-12 md:col-6 lg:col-6">
            Scheduler RTS Report Filters
          </span>
        )}
      </div>
    );
  };
  return (
    <>
      <Dialog
        header={renderHeader}
        visible={visible}
        style={{ width: '55vw' }}
        onHide={() => {
          setVisible(false);
          setSelectedRowData(null);
        }}
        pt={{
          header: {
            className: 'bg-teal-50 border-bottom-1',
          },
        }}
      >
        <div className="card w-12">
          <form
            onSubmit={formik.handleSubmit}
            className="flex flex-column gap-2"
          >
            <div className="grid mt-2">
              {filterType == SchedulerCustomFilters.ADMIN ? (
                <div className="col-12 md:col-4 lg:col-4">
                  <span className="p-float-label h-full">
                    <PFInputText
                      id="filter_name"
                      maxLength={200}
                      value={formik?.values?.filter_name}
                      onChange={e => {
                        formik.setFieldValue('filter_name', e?.target?.value);
                      }}
                      className={`w-full h-full text-sm ${
                        formik.touched?.filter_name &&
                        formik.errors?.filter_name
                          ? 'p-invalid'
                          : ''
                      }`}
                    ></PFInputText>
                    <label htmlFor="filter_name" className="text-base">
                      Name
                    </label>
                  </span>
                </div>
              ) : null}

              {filterType == SchedulerCustomFilters.USER ? (
                <div className="col-12 md:col-4 lg:col-4">
                  <span className="p-float-label p-disabled">
                    <PFMultiSelect
                      value={formik.values.customer || []}
                      onChange={e => {
                        formik.setFieldValue('customer', e.value);
                      }}
                      options={
                        Array.isArray(customerOptions)
                          ? customerOptions?.map(option => ({
                              label: `${option?.first_name} ${option?.last_name}-${
                                option.primary_phone ? option.primary_phone : ''
                              }`,
                              value: option.customer_id,
                              customer_id: option.customer_id,
                            }))
                          : []
                      }
                      optionValue="customer_id"
                      filter={true}
                      filterPlaceholder="Min 3 chars to search"
                      onFilter={e => {
                        if (e.filter.length > 2) {
                          suggestCustomerOptions(e.filter, setCustomerOptions);
                        }
                      }}
                      maxSelectedLabels={0}
                      className="w-full text-sm"
                      selectedItemsLabel={selectedLabel(
                        formik?.values?.customer || [],
                        'Item'
                      )}
                      showSelectAll={false}
                      placeholder="Select Client Name"
                    />
                    <label htmlFor="client_name">Select Client Name</label>
                  </span>
                </div>
              ) : null}

              <div className="col-12 md:col-4 lg:col-4">
                <span className="p-float-label">
                  <PFMultiSelect
                    value={formik.values.store || []}
                    options={
                      Array.isArray(storeOptions)
                        ? storeOptions.map(store => ({
                            label: `${store.store_number} - ${store.store_name}`,
                            value: store.store_id,
                            store_number: store.store_number,
                          }))
                        : []
                    }
                    onChange={e => {
                      formik.setFieldValue('store', e.value);
                    }}
                    optionValue="store_number"
                    filter={true}
                    onFilter={e => {
                      suggestStore(e, setStoreOptions);
                    }}
                    maxSelectedLabels={0}
                    className="w-full text-sm"
                    selectedItemsLabel={selectedLabel(
                      formik?.values?.store || [],
                      'Item'
                    )}
                  />
                  <label htmlFor="store">Select Store</label>
                </span>
              </div>
              <div className="col-12 md:col-4 lg:col-4">
                <span className="p-float-label">
                  <PFMultiSelect
                    value={formik.values.projectCategory || []}
                    options={categoryOptions}
                    onChange={e =>
                      formik.setFieldValue('projectCategory', e.value)
                    }
                    onFilter={e => {
                      suggestCategory(e, setCategoryOptions);
                    }}
                    optionLabel="category"
                    optionValue="project_category_id"
                    maxSelectedLabels={0}
                    filter
                    className="w-full text-sm"
                    onBlur={formik.handleBlur}
                    selectedItemsLabel={selectedLabel(
                      formik?.values?.projectCategory || [],
                      'Item'
                    )}
                  />
                  <label htmlFor="projectCategory">
                    Select Project Category
                  </label>
                </span>
              </div>

              <div className="col-12 md:col-4 lg:col-4">
                <span className="p-float-label">
                  <PFMultiSelect
                    value={formik.values.projectType || []}
                    options={projectTypeOptions}
                    onChange={e => formik.setFieldValue('projectType', e.value)}
                    onFilter={e => {
                      suggestProjectType(e, setProjectTypeOptions);
                    }}
                    placeholder="Project Type"
                    optionLabel="project_type"
                    optionValue="project_type_id"
                    filter
                    maxSelectedLabels={0}
                    className="w-full text-sm"
                    selectedItemsLabel={selectedLabel(
                      formik?.values?.projectType || [],
                      'Item'
                    )}
                  />
                  <label htmlFor="projectType">Select Project Type</label>
                </span>
              </div>
              <div className="col-12 md:col-4 lg:col-4">
                <span className="p-float-label">
                  <PFMultiSelect
                    value={formik.values.status || []}
                    options={projectStatusOptions}
                    onChange={e => formik.setFieldValue('status', e.value)}
                    onFilter={e => {
                      suggestProjectStatus(e, setProjectStatusOptions);
                    }}
                    className="w-full text-sm"
                    placeholder="Status"
                    optionLabel="status"
                    optionValue="status_id"
                    filter
                    maxSelectedLabels={0}
                    selectedItemsLabel={selectedLabel(
                      formik?.values?.status || [],
                      'Item'
                    )}
                  />
                  <label htmlFor="status">Select Status</label>
                </span>
              </div>
              <div className="col-12 md:col-4 lg:col-4">
                <span className={`p-float-label  p-disabled`}>
                  <PFMultiSelect
                    value={formik.values.sourceStatus || []}
                    options={sourceStatusOptions}
                    onChange={e =>
                      formik.setFieldValue('sourceStatus', e.value)
                    }
                    onFilter={e => {
                      suggestSourceStatus(e, setSourceStatusOptions);
                    }}
                    className="w-full text-sm"
                    optionLabel="status"
                    optionValue="status_id"
                    filter
                    maxSelectedLabels={0}
                    selectedItemsLabel={selectedLabel(
                      formik?.values?.sourceStatus || [],
                      'Item'
                    )}
                  />
                  <label htmlFor="sourceStatus">Select Source Status</label>
                </span>
              </div>

              <div className="col-12 md:col-4 lg:col-4">
                <span className="p-float-label p-disabled">
                  <PFMultiSelect
                    value={formik.values.district || []}
                    options={districtOptions}
                    onChange={e => formik.setFieldValue('district', e.value)}
                    onFilter={e => {
                      suggestDistrict(e, setDistrictOptions);
                    }}
                    className="w-full text-sm"
                    optionLabel="district"
                    optionValue="district"
                    filter
                    maxSelectedLabels={0}
                    selectedItemsLabel={selectedLabel(
                      formik?.values?.district || [],
                      'Item'
                    )}
                  />
                  <label htmlFor="district">Select District</label>
                </span>
              </div>
              <div className="col-12 md:col-4 lg:col-4">
                <span className="p-float-label">
                  <PFMultiSelect
                    id="workroom"
                    value={formik?.values?.workroom || []}
                    options={workRoomOptions}
                    onChange={e => formik.setFieldValue('workroom', e.value)}
                    onFilter={e => {
                      suggestWorkRoom(e, setWorkroomOptions);
                    }}
                    optionLabel="label"
                    optionValue="type_id"
                    placeholder="Search Type"
                    maxSelectedLabels={0}
                    filter
                    className="w-12 text-sm"
                    selectedItemsLabel={selectedLabel(
                      formik?.values?.workroom || [],
                      'Item'
                    )}
                  />

                  <label htmlFor="workroom">Select Workroom</label>
                </span>
              </div>
              {filterType == SchedulerCustomFilters.USER ? (
                <>
                  <div className="col-12 md:col-4 lg:col-4">
                    <span className="p-float-label">
                      <PFMultiSelect
                        id="technician"
                        value={formik?.values?.technician || []}
                        options={technicians || []}
                        onChange={e =>
                          formik.setFieldValue('technician', e.value)
                        }
                        optionLabel="full_name"
                        optionValue="user_id"
                        placeholder="Search Type"
                        maxSelectedLabels={0}
                        filter
                        className="w-12 text-sm"
                        selectedItemsLabel={selectedLabel(
                          formik?.values?.technician || [],
                          'Item'
                        )}
                      />

                      <label htmlFor="workroom">Select Technician</label>
                    </span>
                  </div>

                  <div className="col-12 md:col-4 lg:col-4">
                    <span className="p-float-label p-disabled">
                      <PFMultiSelect
                        id="userType"
                        value={formik?.values?.userType || []}
                        options={userTypes || []}
                        optionLabel="label"
                        placeholder="Search Type"
                        maxSelectedLabels={0}
                        filter
                        className="w-12 text-sm"
                        selectedItemsLabel={selectedLabel(
                          formik?.values?.userType || [],
                          'Item'
                        )}
                      />

                      <label htmlFor="workroom">Select User Type</label>
                    </span>
                  </div>

                  <div className="col-12 md:col-4 lg:col-4">
                    <span className="p-float-label">
                      <PFCalendar
                        id="date_from"
                        name="date_from"
                        className="w-12"
                        value={
                          formik.values?.dateScheduledFilterStart?.length > 0
                            ? new Date(
                                formik.values?.dateScheduledFilterStart[0]
                              )
                            : null
                        }
                        format={'DD/MM/YYY'}
                        pt={{
                          input: {
                            root: { className: 'p-inputtext-sm' },
                          },
                        }}
                        disabled
                      />
                      <label htmlFor="date_from" className={`text-400`}>
                        Select Schedule Start Date
                      </label>
                    </span>
                  </div>

                  <div className="col-12 md:col-4 lg:col-4">
                    <span className="p-float-label">
                      <PFCalendar
                        id="date_from"
                        name="date_from"
                        className="w-12"
                        value={
                          formik.values?.dateScheduledFilterStart?.length > 0
                            ? new Date(
                                formik.values?.dateScheduledFilterStart[0]
                              )
                            : null
                        }
                        format={'DD/MM/YYY'}
                        pt={{
                          input: {
                            root: { className: 'p-inputtext-sm' },
                          },
                        }}
                        disabled
                      />
                      <label htmlFor="date_from" className={`text-400`}>
                        Select Schedule End Date
                      </label>
                    </span>
                  </div>

                  <div className="col-12 md:col-4 lg:col-4">
                    <span className="p-float-label">
                      <PFCalendar
                        id="date_from"
                        name="date_from"
                        className="w-12"
                        value={
                          formik.values?.dateScheduledFilterStart?.length > 0
                            ? new Date(
                                formik.values?.dateScheduledFilterStart[0]
                              )
                            : null
                        }
                        format={'DD/MM/YYY'}
                        pt={{
                          input: {
                            root: { className: 'p-inputtext-sm' },
                          },
                        }}
                        disabled
                      />
                      <label htmlFor="date_from" className={`text-400`}>
                        Select Completion Start Date
                      </label>
                    </span>
                  </div>

                  <div className="col-12 md:col-4 lg:col-4">
                    <span className="p-float-label">
                      <PFCalendar
                        id="date_from"
                        name="date_from"
                        className="w-12"
                        value={
                          formik.values?.dateScheduledFilterStart?.length > 0
                            ? new Date(
                                formik.values?.dateScheduledFilterStart[0]
                              )
                            : null
                        }
                        format={'DD/MM/YYY'}
                        pt={{
                          input: {
                            root: { className: 'p-inputtext-sm' },
                          },
                        }}
                        disabled
                      />
                      <label htmlFor="date_from" className={`text-400`}>
                        Select Completion End Date
                      </label>
                    </span>
                  </div>

                  <div className="col-12 md:col-4 lg:col-4">
                    <span className="p-float-label">
                      <PFCalendar
                        id="date_from"
                        name="date_from"
                        className="w-12"
                        value={
                          formik.values?.dateScheduledFilterStart?.length > 0
                            ? new Date(
                                formik.values?.dateScheduledFilterStart[0]
                              )
                            : null
                        }
                        format={'DD/MM/YYY'}
                        pt={{
                          input: {
                            root: { className: 'p-inputtext-sm' },
                          },
                        }}
                        disabled
                      />
                      <label htmlFor="date_from" className={`text-400`}>
                        Select Check Date From
                      </label>
                    </span>
                  </div>

                  <div className="col-12 md:col-4 lg:col-4">
                    <span className="p-float-label">
                      <PFCalendar
                        id="date_from"
                        name="date_from"
                        className="w-12"
                        value={
                          formik.values?.dateScheduledFilterStart?.length > 0
                            ? new Date(
                                formik.values?.dateScheduledFilterStart[0]
                              )
                            : null
                        }
                        format={'DD/MM/YYY'}
                        pt={{
                          input: {
                            root: { className: 'p-inputtext-sm' },
                          },
                        }}
                        disabled
                      />
                      <label htmlFor="date_from" className={`text-400`}>
                        Select Check Date To
                      </label>
                    </span>
                  </div>

                  <div className="col-12 md:col-4 lg:col-4">
                    <span className="p-float-label">
                      <PFCalendar
                        id="date_from"
                        name="date_from"
                        className="w-12"
                        value={
                          formik.values?.dateScheduledFilterStart?.length > 0
                            ? new Date(
                                formik.values?.dateScheduledFilterStart[0]
                              )
                            : null
                        }
                        format={'DD/MM/YYY'}
                        pt={{
                          input: {
                            root: { className: 'p-inputtext-sm' },
                          },
                        }}
                        disabled
                      />
                      <label htmlFor="date_from" className={`text-400`}>
                        Select Date Sold From
                      </label>
                    </span>
                  </div>

                  <div className="col-12 md:col-4 lg:col-4">
                    <span className="p-float-label">
                      <PFCalendar
                        id="date_from"
                        name="date_from"
                        className="w-12"
                        value={
                          formik.values?.dateScheduledFilterStart?.length > 0
                            ? new Date(
                                formik.values?.dateScheduledFilterStart[0]
                              )
                            : null
                        }
                        format={'DD/MM/YYY'}
                        pt={{
                          input: {
                            root: { className: 'p-inputtext-sm' },
                          },
                        }}
                        disabled
                      />
                      <label htmlFor="date_from" className={`text-400`}>
                        Select Date Sold To
                      </label>
                    </span>
                  </div>
                </>
              ) : null}
              {filterType == SchedulerCustomFilters.USER ? (
                <>
                  <Divider></Divider>
                  <div className="col-12 md:col-4 lg:col-4">
                    <span className="p-float-label h-full">
                      <PFInputText
                        id="filter_name"
                        maxLength={200}
                        value={formik?.values?.filter_name}
                        onChange={e => {
                          formik.setFieldValue('filter_name', e?.target?.value);
                        }}
                        className={`w-full h-full text-sm ${
                          formik.touched?.filter_name &&
                          formik.errors?.filter_name
                            ? 'p-invalid'
                            : ''
                        }`}
                      ></PFInputText>
                      <label htmlFor="filter_name" className="text-base">
                        Name
                      </label>
                    </span>
                  </div>
                </>
              ) : null}

              <div className="col-12 md:col-3 lg:col-3 flex align-items-center gap-2 ">
                <InputSwitch
                  checked={formik.values.isActive}
                  onChange={e => {
                    formik.setFieldValue('isActive', e.value);
                  }}
                  className="ml-2 mr-1 mt-1 text-xs"
                />
                <label className="pt-1 ml-2 text-base">
                  {formik.values.isActive ? ` Active` : `Inactive`}{' '}
                </label>
              </div>
              {dropdownError && (
                <div className="col-12 md:col-5 lg:col-5 align-content-center">
                  <small className="p-error pl-0">
                    Please select at least one dropdown value.
                  </small>
                </div>
              )}
            </div>
            <div className="flex justify-content-end">
              <PFButton
                label="Cancel"
                className="mr-3"
                onClick={() => {
                  setVisible(false);
                  setSelectedRowData(null);
                }}
                outlined
              />
              <PFButton
                label="Save"
                type="submit"
                disabled={!formik.isValid || loading}
                icon={loading ? 'pi pi-spin pi-spinner' : ''}
                pt={{
                  label: {
                    className: 'flex-none pr-1 m-0',
                  },
                }}
                className="flex flex-row-reverse px-4"
              />
            </div>
          </form>
        </div>
      </Dialog>
    </>
  );
};
export default AddEditReportDailog;
