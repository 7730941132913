import axios from 'axios';

import { URL_CONSTANTS } from '../../../constants/urlConstants';

// Get Notes Data
export const getRelatedPOData = async (
  projectId,
  setLoading,
  setRelatedPOResponse
) => {
  try {
    setLoading(true);
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/projects/${projectId}/related-po/list`
    );
    setRelatedPOResponse(response.data);
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
};
