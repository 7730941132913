import React, { useEffect, useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';

import ApiService from '../../../services/api.service';
import { URL_CONSTANTS } from '../../../constants/urlConstants';
import PFDropdown from '../../shared/PFPrime/PFDropdown';
import TableLoader from '../../shared/Loader/TableLoader';
import './styles.css';
import {
  momentTz,
  convert24HourTimeTo12,
  isValidTimeFormat,
  onlyLettersAndNumbers,
} from '../../../../src/utils/Helpers';
import {
  clientDetails,
  PF_ADMIN,
  AUDIT_LOG_SYSTEM_DEFAULT_REPORT,
  PROJECT_LEAD_TEST_REQ,
  PROJECT_HD_LEAD_TEST_RESULT,
  PROJECT_EPA_LEAD_CONFIGURATION,
  LABOR_AMOUNT,
  PROJECT_ESTIMATED_LABOR_AMOUNT,
} from '../../../constants';
import PFButton from '../../shared/PFPrime/PFButton';
import PFDialog from '../../shared/PFPrime/PFDialog';

const AuditNew = ({ formik }) => {
  const defaultSelectedModalName = 'System Default';
  const [auditValues, setAuditValues] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [dropDownArray, setDropDownArray] = useState([]);
  const [reportValue, setReportValue] = useState('');

  const [isLoading, setIsLoading] = useState(true);
  const [isSortedValues, setIsSortedValues] = useState('');
  const clientId = clientDetails;
  const [visible, setVisible] = useState(null);
  const [epaConfigData, setEpaConfigData] = useState([]);
  const toast = useRef(null);

  const toCapitalizedCase = str => {
    if (!str) return '';
    let prefix = '';
    if (
      str === 'projectInstaller__date_scheduled_start' ||
      str === 'projectInstaller__date_scheduled_end'
    ) {
      prefix = 'Technician ';
    }
    if (
      str === 'project__date_scheduled_start' ||
      str === 'project__date_scheduled_end'
    ) {
      prefix = 'Project ';
    }
    if (str === PROJECT_LEAD_TEST_REQ) {
      return 'Lead Required';
    }
    if (str === PROJECT_HD_LEAD_TEST_RESULT) {
      return 'Lead Response';
    }
    if (str === PROJECT_EPA_LEAD_CONFIGURATION) {
      return 'EPA Lead Test Results';
    }
    if (str === LABOR_AMOUNT) {
      return 'Technician Labor Amount';
    }
    if (str === PROJECT_ESTIMATED_LABOR_AMOUNT) {
      return 'Estimated Labor Amount';
    }

    const parts = str.split('__');
    const valuePart = parts[1] || parts[0];

    return (
      prefix +
      valuePart
        .replace(/_/g, ' ')
        .replace(/\b\w/g, firstChar => firstChar.toUpperCase())
    );
  };

  let retry = 0;

  const handleOnChange = async (event, retryCall = 0) => {
    setAuditValues([]);
    try {
      setIsLoading(true);
      retry++;
      const response = await ApiService.get(
        `${URL_CONSTANTS.AUDIT_LOG.module.getRowData}${event === AUDIT_LOG_SYSTEM_DEFAULT_REPORT ? PF_ADMIN : clientId?.client_id}/detail/${event}`
      );
      const responseAuditLog = await ApiService.postAuditLog(
        `/audit-log/${formik?.values?.project_id}/report`,
        { log_fields: response?.data?.log_fields }
      );

      const auditMockRes = responseAuditLog;

      const changeLog = auditMockRes?.changeLog;

      if (changeLog && changeLog?.length > 0) {
        const dynamicHeaders = Object.keys(changeLog[0]);
        let filteredHeaders = dynamicHeaders?.filter(header =>
          response?.data?.log_fields.some(field => field.field === header)
        );

        const sortedArray = filteredHeaders.slice().sort((a, b) => {
          const indexOfA = response?.data?.log_fields
            ?.map(obj => obj?.field)
            ?.indexOf(a);
          const indexOfB = response?.data?.log_fields
            ?.map(obj => obj?.field)
            ?.indexOf(b);
          return indexOfA - indexOfB;
        });
        const sortedValues = sortedArray?.filter(header =>
          header?.includes('created_at')
        )[0];
        setIsSortedValues(sortedValues);
        setHeaders(sortedArray);
        let result = [auditMockRes.initialData, ...changeLog];

        result = result.map(obj => {
          for (const key in obj) {
            if (isValidTimeFormat(obj[key]?.value)) {
              obj[key] = {
                ...obj[key],
                value: convert24HourTimeTo12(obj[key]?.value),
              };
            }
            if (obj[key]?.isDateField) {
              obj[key] = {
                ...obj[key],
                value: momentTz(obj[key]?.value).format('MM-DD-YYYY hh:mm A'),
                dateValue: obj[key]?.value,
              };
            }
            if (key?.includes('modified_by')) {
              obj[key] = {
                ...obj[key],
                value: onlyLettersAndNumbers(obj[key]?.value)
                  ? 'System'
                  : obj[key]?.value,
              };
            }
            if (obj[key]?.value?.toString()?.includes('_')) {
              obj[key] = {
                ...obj[key],
                value: obj[key]?.value?.replace(/_/g, ' '),
              };
            }
            if (
              typeof obj[key]?.meta === 'object' &&
              Object.keys(obj[key]?.meta)?.length > 0
            ) {
              if (typeof obj[key]?.meta?.format === 'string') {
                try {
                  obj[key].meta.format = eval(`(${obj[key]?.meta?.format})`);
                } catch (error) {
                  console.error('Error converting string to function:', error);
                }
              }
              if (typeof obj[key]?.meta.format === 'function') {
                obj[key] = {
                  ...obj[key],
                  value: obj[key]?.meta?.format(obj[key]?.value),
                };
              } else {
                console.error('Format function is not a valid function');
              }
            }
          }
          return obj;
        });
        setAuditValues(result);
      } else {
        if (retry < 3 && !auditMockRes?.initialData) {
          let r;
          if (!retryCall) {
            r = await ApiService.post(
              `/projects/audit/${formik?.values?.project_id}`,
              {}
            );
            retryCall = r;
          }
          setTimeout(() => {
            handleOnChange(event, retryCall);
          }, 2000);
        } else {
          setHeaders([]);
          setAuditValues([]);
          setIsSortedValues('');
        }
      }
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: error?.message,
        life: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(async () => {
    try {
      setIsLoading(true);
      const response = await ApiService.get(
        `${URL_CONSTANTS.AUDIT_LOG.module.list}?client_id=${clientId?.client_id}`
      );
      const updatedArray = response?.rows.map(item => ({
        audit_log_modal_id: item?.audit_log_modal_id,
        modal_name: item?.modal_name,
      }));
      const defaultSelectedOption = updatedArray?.find(
        option => option?.modal_name === defaultSelectedModalName
      );
      setReportValue(defaultSelectedOption?.audit_log_modal_id || '');
      handleOnChange(defaultSelectedOption?.audit_log_modal_id);
      setDropDownArray(updatedArray);
    } catch (e) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: e?.message,
        life: 3000,
      });
    }
  }, []);

  const renderHeader = () => {
    return (
      <div className="grid">
        <div className="col-12 lg:col-6 flex align-items-center">
          <h3 className="text-xl">Audit Report</h3>
        </div>
        <div className="col-12 md:col-6 pb-0 justify-content-end flex">
          <PFDropdown
            options={dropDownArray}
            placeholder="Select Report"
            optionLabel="modal_name"
            optionValue="audit_log_modal_id"
            name="reportName"
            filter={true}
            value={reportValue || ''}
            onChange={(n, e) => {
              setReportValue(e);
              handleOnChange(e);
            }}
            className="w-full md:w-14rem p-inputtext-sm"
          />
        </div>
      </div>
    );
  };

  const getCellClassName = (rowData, field) => {
    let highlightClassName = 'highlight';
    highlightClassName =
      rowData?.action && rowData?.action?.value.toUpperCase() === 'DELETE'
        ? 'highlightDelete'
        : highlightClassName;
    return rowData[field]?.isUpdated && rowData[field]?.value
      ? highlightClassName
      : '';
  };

  const handleOpenDialog = formattedData => {
    const basicInfo = [];
    const testLeadInfo = [];

    const removeKeys = (obj, keys) => keys?.forEach(key => delete obj[key]);

    if (Array.isArray(formattedData?.test_info)) {
      removeKeys(formattedData, [
        'test_kit_manufacturer',
        'component_location_tested',
        'lead_result',
      ]);
    } else if (
      formattedData?.test_kit_manufacturer &&
      formattedData?.component_location_tested
    ) {
      formattedData.test_info = [
        {
          lead_result:
            Object.keys(formattedData)?.length === 2 ? 'Negative' : 'Positive',
          test_kit_manufacturer: formattedData?.test_kit_manufacturer,
          component_location_tested: formattedData?.component_location_tested,
        },
      ];
      removeKeys(formattedData, [
        'test_kit_manufacturer',
        'component_location_tested',
        'lead_result',
      ]);
    }

    if (Array.isArray(formattedData)) {
      testLeadInfo.push({
        key: 'test_info',
        headers: [...new Set(formattedData?.flatMap(Object.keys))],
        rows: formattedData,
      });
    } else {
      Object.entries(formattedData)?.forEach(([key, value]) => {
        if (typeof value === 'string' || value === null) {
          basicInfo.push({ key, value: value || 'N/A' });
        } else if (Array.isArray(value)) {
          testLeadInfo.push({
            key,
            headers: [...new Set(value?.flatMap(Object.keys))],
            rows: value,
          });
        }
      });
    }
    setEpaConfigData({ basicInfo, testLeadInfo });
    setVisible(true);
  };

  return (
    <>
      <Toast ref={toast}></Toast>

      <div className="grid mt-3">
        {isLoading ? (
          <>
            <TableLoader columnCount={7} noOfRow={15} mTopBottom={0} />
          </>
        ) : (
          <>
            <DataTable
              value={auditValues}
              dataKey="auditLog_id"
              showGridlines={false}
              size="small"
              stripedRows={true}
              lazy={false}
              paginator={false}
              header={renderHeader}
              rows={10}
              rowsPerPageOptions={[10, 20, 50, 100]}
              columnResizeMode="expand"
              resizableColumns
              className="w-full"
              scrollable
              sortOrder={1}
              sortField={`${isSortedValues}.dateValue`}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
              pt={{
                wrapper: {
                  className: 'max-h-30rem',
                },
              }}
            >
              {headers?.map((col, i) => (
                <Column
                  frozen={i === 0 ? true : false}
                  sortable={col?.includes('created_at') ? true : false}
                  key={col}
                  field={`${col}.value`}
                  header={toCapitalizedCase(col)}
                  body={rowData => {
                    let value = rowData[col]?.value;

                    if (typeof value === 'string') {
                      try {
                        const parsed = JSON?.parse(value);
                        if (typeof parsed === 'object' && parsed !== null) {
                          value =
                            Object.keys(parsed)?.length > 0 ? parsed : '-';
                        }
                      } catch (e) {
                        console.error('Error parsing JSON:', value, e);
                      }
                    }
                    return (
                      <>
                        {typeof value === 'object' && value !== null ? (
                          <span className={`${getCellClassName(rowData, col)}`}>
                            <PFButton
                              label="Epa Config"
                              onClick={() => handleOpenDialog(value)}
                              outlined
                              text
                              className="border-none pb-0 focus:shadow-none"
                            />
                          </span>
                        ) : (
                          <span className={`${getCellClassName(rowData, col)}`}>
                            {value}
                          </span>
                        )}
                      </>
                    );
                  }}
                  sortField={`${col}.dateValue`}
                />
              ))}
            </DataTable>

            {visible && (
              <PFDialog
                header="EPA Audit report"
                show={visible}
                className="w-25 rem"
                hide={() => {
                  setEpaConfigData(null);
                  setVisible(false);
                }}
                BodyComponent={
                  epaConfigData ? (
                    <div className="p-3">
                      <div className="grid">
                        {epaConfigData?.basicInfo?.map((item, index) => (
                          <div
                            key={index}
                            className="col-12 md:col-3 lg:col-4 my-2 py-1"
                          >
                            <span className="p-float-label">
                              <InputText
                                value={item?.value || '-'}
                                className="w-full"
                                disabled
                              />
                              <label>
                                {item?.key
                                  ?.replace(/_/g, ' ')
                                  ?.replace(/\b\w/g, match =>
                                    match?.toUpperCase()
                                  )}
                              </label>
                            </span>
                          </div>
                        ))}
                      </div>

                      {epaConfigData?.testLeadInfo?.map((item, index) => {
                        if (Array.isArray(item?.rows) && item?.rows?.length) {
                          return (
                            <div key={index} className="mb-4">
                              <DataTable
                                value={item?.rows}
                                header="Lead Test Info"
                                className="w-full mt-2"
                              >
                                {item?.headers?.map((header, i) => (
                                  <Column
                                    key={i}
                                    field={header}
                                    header={toCapitalizedCase(header)}
                                  />
                                ))}
                              </DataTable>
                            </div>
                          );
                        }
                      })}
                    </div>
                  ) : (
                    'No data'
                  )
                }
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default AuditNew;
