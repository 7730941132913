import React, { useState, useEffect } from 'react';

import PFDropdown from '../../../shared/PFPrime/PFDropdown';
import PFFormikError from '../../../shared/PFPrime/PFFormikError';
import PFButton from '../../../shared/PFPrime/PFButton';
import PFDataTable from '../../../shared/PFPrime/PFDataTable';
import { phoneSubmit, columns } from '../helpers/PhoneDialogBody';
import PFInputMask from '../../../shared/PFPrime/PFInputMask';
import usePhoneCategory from '../../../../hooks/usePhoneCategory';

const PhoneDialogBody = ({ phoneCollection, data, mode }) => {
  const { phoneCategory } = usePhoneCategory();
  const initionalPhoneCategory = phoneCategory.filter(i => i.name === 'Mobile');

  const [phoneObject, setPhoneObject] = useState([]);
  const [updateVlue, setUpdateValue] = useState('');
  const [btnLabel, setBtnLabel] = useState('Add');

  useEffect(() => {
    if (data?.customer_phones) {
      setPhoneObject(data?.customer_phones);
    }
  }, [data]);

  const { handleSubmit, values, errors, touched, setFieldValue } = phoneSubmit(
    setPhoneObject,
    phoneObject?.length <= 0 ? initionalPhoneCategory[0]?.phone_type_id : '',
    updateVlue,
    setUpdateValue,
    setBtnLabel,
    btnLabel
  );
  const [phoneTypeOption, setPhoneTypeOption] = useState([]);

  useEffect(() => {
    setPhoneTypeOption(phoneCategory);
    if (phoneObject?.length > 0) {
      phoneCollection('customer_phones', phoneObject);
    } else {
      phoneCollection('customer_phones', '');
    }
  }, [phoneCategory, phoneObject]);

  const deleteNumber = number => {
    const updatedPhoneObject = phoneObject.filter(
      (item, index) => index !== number
    );
    setPhoneObject(updatedPhoneObject);
  };
  const updateNumber = (rowData, rowIndex, isPrimary = false) => {
    if (isPrimary) {
      setPhoneObject(preValue => {
        const updatedPhoneObject = preValue.map((item, index) => {
          if (index === rowIndex) {
            item['is_primary'] = isPrimary;
          } else {
            item['is_primary'] = !isPrimary;
          }
          return item;
        });
        return updatedPhoneObject;
      });
    } else {
      setUpdateValue({ rowData: rowData, rowIndex: rowIndex });
      setBtnLabel('Update');
    }
  };

  return (
    <>
      {mode !== 'view' && (
        <form className="w-full" onSubmit={handleSubmit}>
          <div className="grid mt-2">
            <div className="col-12 md:col-6 lg:col-5">
              <div className="w-full">
                <span className="p-float-label">
                  <PFDropdown
                    options={phoneTypeOption}
                    optionLabel="name"
                    optionValue="phone_type_id"
                    name="phone_type_id"
                    filter={false}
                    value={values?.phone_type_id || ''}
                    onChange={setFieldValue}
                    className={
                      touched?.phone_type_id && errors?.phone_type_id
                        ? 'error'
                        : ''
                    }
                  />
                  <label htmlFor="phone_type_id" className="text-sm">
                    Contact Category <span style={{ color: 'red' }}>*</span>
                  </label>
                </span>
                <PFFormikError
                  touched={touched}
                  errors={errors}
                  field="phone_type_id"
                />
              </div>
            </div>
            <div className="col-12 md:col-6 lg:col-5">
              <div className="w-full">
                <span className="p-float-label">
                  <PFInputMask
                    id="phone_number"
                    name="phone_number"
                    value={values?.phone_number || ''}
                    onChange={setFieldValue}
                    mask="(999) 999-9999"
                    className={
                      touched?.phone_number && errors?.phone_number
                        ? 'error'
                        : ''
                    }
                    autoComplete="off"
                  />
                  <label htmlFor="phone_number" className="text-sm">
                    Contact Number <span style={{ color: 'red' }}>*</span>
                  </label>
                </span>
                <PFFormikError
                  touched={touched}
                  errors={errors}
                  field="phone_number"
                />
              </div>
            </div>
            <div className="col-12 md:col-6 lg:col-2">
              <div className="w-full">
                <PFButton
                  icon="pi pi-plus"
                  rounded={true}
                  type="button"
                  severity="primary"
                  onClick={handleSubmit}
                  outlined={false}
                />
              </div>
            </div>
          </div>
        </form>
      )}

      <div className={`w-full mt-2 h-16rem`}>
        <PFDataTable
          columns={columns(
            phoneCategory,
            deleteNumber,
            mode,
            initionalPhoneCategory[0]?.phone_type_id,
            updateNumber
          )}
          data={phoneObject}
          scrollable
          scrollHeight="250px"
          stripedRows={true}
        />
      </div>
    </>
  );
};

export default PhoneDialogBody;
