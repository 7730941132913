import React, { useState, useRef, useEffect } from 'react';
import { Toast } from 'primereact/toast';

import PFButton from '../../shared/PFPrime/PFButton';
import PFDialog from '../../shared/PFPrime/PFDialog';
import { apiBinListing, binDeleteService } from '../services/Warehouse.service';
import { WAREHOUSE_BIN } from '../constant/constants';

import BinsTable from './BinsTable';
import BinForm from './BinForm';
const BinContainer = ({ warehouseId, wareHouseName }) => {
  const toast = useRef(null);
  const [bins, setBins] = useState([]);
  const [onEdit, setOnEdit] = useState(null);
  const [defaultDialogProps, setDialogProps] = useState({ show: false });
  const [binFormProps, setBinFormProps] = useState({
    toast: toast,
    dialogProps: setDialogProps,
    setBins: setBins,
  });

  const onHide = () => {
    setDialogProps(preValue => ({ ...preValue, show: false }));
  };

  useEffect(() => {
    if (onEdit) {
      setBinFormProps(preValue => ({
        ...preValue,
        onEdit: onEdit,
        setOnEdit: setOnEdit,
      }));
    }
  }, [onEdit]);

  const dialogBody = (data = null) => (
    <BinForm
      {...binFormProps}
      data={data}
      warehouseId={warehouseId}
      binListing={binListing}
    />
  );

  const onEditRow = data => {
    if (data) {
      setDialogProps(preValue => ({
        ...preValue,
        show: true,
        hide: onHide,
        header: WAREHOUSE_BIN.UPDATE_BIN_LABEL,
        BodyComponent: dialogBody(data),
      }));
    }
  };

  const binListing = async (params = {}) => {
    const payload = { id: warehouseId, params: params };
    const listing = await apiBinListing(payload);
    setBins(listing);
  };

  const onDeleteRow = async data => {
    const { warehouseId: id, _id } = data;
    const deleteBin = await binDeleteService({ id, _id });
    if (deleteBin?.status) {
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: `${deleteBin?.message}`,
        life: 3000,
      });
      binListing();
    } else {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: `${deleteBin?.message}`,
        life: 3000,
      });
    }
  };

  return (
    <div className="p-3">
      <Toast ref={toast}></Toast>
      <div className="grid">
        <div className="col flex justify-content-end flex-wrap">
          <PFButton
            label={WAREHOUSE_BIN.NEW_BIN_LABEL}
            onClick={() =>
              setDialogProps(preValue => ({
                ...preValue,
                show: true,
                hide: onHide,
                header: WAREHOUSE_BIN.NEW_BIN_LABEL,
                BodyComponent: dialogBody(),
              }))
            }
          />
        </div>
      </div>
      <div className="grid">
        <div className="col">
          <BinsTable
            bins={bins}
            onEditRow={onEditRow}
            binListing={binListing}
            onDeleteRow={onDeleteRow}
            wareHouseName={wareHouseName}
          />
        </div>
      </div>

      <PFDialog {...defaultDialogProps} />
    </div>
  );
};

export default BinContainer;
