import React, { useEffect, useState } from 'react';
import { Rating } from 'primereact/rating';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import PFInputText from '../../shared/PFPrime/PFInputText';
import PFDropdown from '../../shared/PFPrime/PFDropdown';

import { QUESTION_TYPES } from './questionnaire.contants';

const ViewTableComponent = ({ question, previewTable, mode, formik }) => {
  const [columns, setColumns] = useState([]);

  // Update state when `question` data changes
  useEffect(() => {
    if (question?.format_field_types) {
      const sortedColumns = sortColumns(question?.format_field_types);
      setColumns(sortedColumns);
    }
  }, [question]);

  const sortColumns = columns => {
    const sortedColumns = columns
      ?.slice()
      ?.sort(
        (a, b) =>
          (previewTable ? a?.config?.position : a?.position || 0) -
          (previewTable ? b?.config?.position : b?.position || 0)
      );

    return sortedColumns;
  };

  useEffect(() => {
    if (mode === 'edit' && previewTable?.length) {
      const data = previewTable.map(item => ({
        format_question_type: item?.question_type?.id,
        format_question_label: item?.question_type_label,
        options: item?.options,
      }));
      const sortedColumns = sortColumns(data);
      setColumns(sortedColumns);
    }
  }, [previewTable, mode]);

  // Function to get the label from Question Types using ID
  const getFieldLabel = fieldId => {
    return QUESTION_TYPES?.[fieldId]?.label || fieldId;
  };

  // Function to handle column re-sizing
  const handleReSizeColumns = async value => {
    try {
      const columnStyle = { width: value?.element?.offsetWidth || '' };
      const typeTableData = previewTable || [];
      const columnsData = typeTableData.map(item => {
        // Update styles only for the matched column
        const isMatchingColumn =
          item.question_type_label === value?.column?.props?.field;
        return {
          question_type: item.question_type,
          question_type_label: item.question_type_label,
          options:
            item?.options?.map(option => ({
              label: option?.label || '',
            })) || [],
          config: {
            ...item.config,
            ...(isMatchingColumn && {
              styles: {
                ...item?.config?.styles,
                ...columnStyle,
              },
            }), // Add styles only when the condition matches
          },
        };
      });

      formik.setFieldValue('columns', columnsData);
    } catch (error) {
      console.error('Error re-sizing columns', error);
    }
  };
  const tableData = [{ id: 1 }]; // Default row

  const tableFieldChange = (data, value) => {
    const setData = columns.map(row => {
      if (row.format_question_label === data.format_question_label) {
        return {
          ...row,
          value,
        };
      } else {
        return row;
      }
    });

    setColumns(setData);
  };
  return (
    <>
      <DataTable
        header={<h3>Preview</h3>}
        value={tableData}
        scrollable={true}
        scrollHeight="400px"
        stripedRows={true}
        responsiveLayout="scroll"
        resizableColumns={mode !== 'preview'} // Enable re-sizing only if mode is not 'preview'
        columnResizeMode="expand"
        showGridlines={true}
        tableStyle={{ tableLayout: 'fixed', width: '100%' }}
        onColumnResizeEnd={value => handleReSizeColumns(value)}
      >
        {columns.map(column => (
          <Column
            key={column?.format_question_label}
            field={column?.format_question_label}
            header={column?.format_question_label}
            style={{
              width: column?.config?.styles
                ? column?.config?.styles?.width
                : '250px',
            }} // Control column width
            body={() => {
              const fieldType = column?.format_question_type;

              if (fieldType === QUESTION_TYPES.list.id) {
                return (
                  <PFDropdown
                    value={column?.value}
                    options={column?.options || []}
                    placeholder="Select"
                    onChange={(name, value) => {
                      tableFieldChange(column, value);
                    }}
                  />
                );
              }
              if (fieldType === QUESTION_TYPES.text.id) {
                return (
                  <PFInputText
                    value={column?.value || ''}
                    onChange={e => {
                      tableFieldChange(column, e.target.value);
                    }}
                  />
                );
              }
              if (fieldType === QUESTION_TYPES.rating5.id) {
                return (
                  <Rating
                    value={column?.value || 3}
                    cancel={false}
                    onChange={e => {
                      tableFieldChange(column, e.target.value);
                    }}
                  />
                );
              }
              if (fieldType === QUESTION_TYPES.rating10.id) {
                return (
                  <Rating
                    value={column?.value || 5}
                    stars={10}
                    cancel={false}
                    onChange={e => {
                      tableFieldChange(column, e.target.value);
                    }}
                  />
                );
              }
              return <span>{getFieldLabel(fieldType)}</span>;
            }}
          />
        ))}
      </DataTable>
    </>
  );
};

export default ViewTableComponent;
