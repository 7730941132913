import React from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';

import { syntheticEvent } from '../../../../utils/event.helper';
import { ACTION } from '../../../../constants/form-actions.constant';
import { NoteCategory, NotesFormProps } from '../interface/notes.interface';
import { addNotes, getNotesTypeOptions } from '../service/notes.service';

const NotesForm: React.FC<NotesFormProps> = ({
  dialogFormObj,
  updateDialogFormObj,
  project_id,
  loadNotesData,
  toast,
}) => {
  const [notesCategoryOptions, setNotesCategoryOptions] = React.useState<
    NoteCategory[] | []
  >([]);

  const [dropdownLoading, setDropdownLoading] = React.useState<boolean>(false);
  const [saveLoading, setSaveLoading] = React.useState<boolean>(false);

  const loadNotesCategory = async () => {
    setDropdownLoading(true);
    const response = await getNotesTypeOptions();
    if (!response?.error && response) {
      setNotesCategoryOptions(response?.options);
    } else {
      toast.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: response?.message,
        life: 3000,
      });
    }
    setDropdownLoading(false);
  };

  React.useEffect(() => {
    if (dialogFormObj.visible) {
      if (!notesCategoryOptions?.length) loadNotesCategory();
    }
  }, [dialogFormObj.visible]);

  const NotesFormikSchema = Yup.object().shape({
    note_category_id: Yup.number().nullable().required('Type is required.'),
    note_text: Yup.string().trim().required('Notes is required.'),
  });

  const notesFormik = useFormik({
    initialValues: {
      note_category_id: null,
      note_text: '',
      follow_up_date: null,
    },
    validationSchema: NotesFormikSchema,
    onSubmit: async values => {
      setSaveLoading(true);
      let response;

      if (dialogFormObj.formAction === ACTION.ADD) {
        response = await addNotes(project_id, values);
      }
      if (!response?.error) {
        toast.current?.show({
          severity: 'success',
          summary: 'Success',
          detail: response?.message,
          life: 3000,
        });
        notesFormik.handleReset(syntheticEvent);
        updateDialogFormObj({ visible: false });
        loadNotesData({ limit: 10, offset: 0 });
      } else {
        toast.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: response.message,
          life: 3000,
        });
      }
      setSaveLoading(false);
    },

    enableReinitialize: true,
    initialTouched: {},
  });

  const footerContent = (
    <div>
      <Button
        label="Cancel"
        onClick={e => {
          updateDialogFormObj({ visible: false });
          notesFormik.handleReset(e);
        }}
        type="button"
        size="small"
      />
      <Button
        label="Save"
        onClick={() => notesFormik.handleSubmit()}
        loading={saveLoading}
        autoFocus
        disabled={!(notesFormik?.dirty && notesFormik?.isValid) || saveLoading}
        size="small"
        type="button"
      />
    </div>
  );

  const handleDateChange = (value: Date | null | undefined) => {
    // Convert UTC date to local date
    return value ? notesFormik.setFieldValue('follow_up_date', value) : null;
  };

  return (
    <Dialog
      header={dialogFormObj.formAction === ACTION.ADD && 'Add Notes'}
      pt={{
        header: { className: 'border-bottom-1 border-400 py-1' },
        headerTitle: { className: 'text-base' },
        content: { className: 'overflow-visible pb-1' },
      }}
      visible={dialogFormObj.visible}
      style={{ width: '25vw' }}
      onHide={() => {
        if (!dialogFormObj.visible) return;
        updateDialogFormObj({ visible: false });
        notesFormik.handleReset(syntheticEvent);
      }}
      footer={footerContent}
    >
      <form className="mt-2">
        <div className="col-12">
          <span className="p-float-label w-full">
            <Dropdown
              value={notesFormik.values.note_category_id}
              id="note_category_id"
              onChange={notesFormik.handleChange}
              onBlur={notesFormik.handleBlur}
              options={notesCategoryOptions}
              optionLabel="category_name"
              optionValue="note_category_id"
              className="w-full"
              showClear
              appendTo={() => document.body}
              loading={dropdownLoading}
            />
            <label htmlFor="note_category_id" className="text-xs">
              Type<span className="text-red-500">*</span>
            </label>
          </span>
          {notesFormik.touched.note_category_id &&
          notesFormik.errors.note_category_id ? (
            <div className="text-xs text-red-500">
              {notesFormik.errors.note_category_id}
            </div>
          ) : null}
        </div>

        <div className="col-12">
          <span className="p-float-label w-full">
            <InputTextarea
              className="w-full"
              placeholder="Note"
              id="note_text"
              value={notesFormik.values.note_text}
              onChange={notesFormik.handleChange}
              onBlur={notesFormik.handleBlur}
              rows={3}
              cols={30}
            />
            <label htmlFor="note_text" className="text-xs">
              Note<span className="text-red-500">*</span>
            </label>
          </span>
          {notesFormik.touched.note_text && notesFormik.errors.note_text ? (
            <div className="text-xs text-red-500">
              {notesFormik.errors.note_text}
            </div>
          ) : null}
        </div>
        <div className="col-12">
          <span className="p-float-label w-full">
            <Calendar
              id="follow_up_date"
              name="follow_up_date"
              className="w-full"
              selectionMode="single"
              onChange={e => {
                handleDateChange(e?.value);
              }}
              value={notesFormik?.values?.follow_up_date}
              minDate={new Date()}
            />
            <label htmlFor="follow_up_date">Follow up Date</label>
          </span>
        </div>
      </form>
    </Dialog>
  );
};
export default NotesForm;
