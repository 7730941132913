import React, { useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  Grid,
  IconButton,
  CircularProgress,
  Typography,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  Tooltip,
} from '@material-ui/core';
import {
  Visibility as VisibilityIcon,
  Edit as EditIcon,
  CancelPresentation as WithDrawIcon,
  Check as CheckIcon,
  CheckCircleRounded as AcknowledgeIcon,
  PermPhoneMsgRounded as ContactOfficeIcon,
  CancelRounded as CancelRoundedIcon,
} from '@material-ui/icons';
import moment from 'moment';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import NumberFormat from 'react-number-format';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { format } from 'date-fns';
import { Toast } from 'primereact/toast';

import permissions from '../../../config/permissions';
import { checkPermission, momentTz } from '../../../utils/Helpers';
import PageHeader from '../../shared/PageHeader/PageHeader';
import GenericDataTable from '../../shared/GenericDataTable/GenericDataTable';
import GenericDialog from '../../shared/Dialog/GenericDialog';
import GenericConfirmationDialog from '../../shared/GenericConfirmationDialog/GenericConfirmationDialog';
import { Alert } from '../../shared/Alerts/Alert';
import { useAlerts } from '../../shared/Alerts/alertsService';
import { getUserProfile } from '../../ProjectManagement/ProjectInfo/ProjectDetails.service';
import PFTableLoader from '../../shared/Loader/PFTableLoader';
import TableColumnsLoader from '../../shared/Loader/tableColumnsLoader';

import { useStyles } from './styles/AvailablityUpdates.styles';
import {
  getAvailabilityUpdatesList,
  getInstallersList,
  getAvailabilityUpdate,
  createAvailabilityUpdate,
  updateAvailabilityUpdate,
  updateAvailabilityUpdateStatus,
} from './services/AvailablityUpdates.service';

function handleRowHover(event, row, rowIndex) {
  let control = document.getElementById(rowIndex);
  if (control) control.style.display = 'block';
}

function handleRowHoverLeave(event, row, rowIndex) {
  let control = document.getElementById(rowIndex);
  if (control?.style?.display) control.style.display = 'none';
}

let weeks = [
  { label: 'Sun', name: 'sun' },
  { label: 'Mon', name: 'mon' },
  { label: 'Tue', name: 'tue' },
  { label: 'Wed', name: 'wed' },
  { label: 'Thu', name: 'thus' },
  { label: 'Fri', name: 'fri' },
  { label: 'Sat', name: 'sat' },
];

const AvailabilityUpdates = () => {
  const availablityUpdatesBreadcrumb = [
    {
      text: 'Personnel',
    },
    {
      text: 'Availability Updates',
    },
  ];
  const [loading, setLoading] = React.useState(true);
  const [loadingAvailability, setLoadingAvailability] = React.useState(false);
  const [formAction, setFormAction] = React.useState('add');
  const loadingPosition = false;
  const [rowIndex, setRowIndex] = React.useState();
  const { alert, setAlert, clearAlert } = useAlerts();
  const [dialogSettings, setDialogSettings] = React.useState({
    title: 'View Issue',
    button1Text: '',
    button2Text: 'Add',
    showButton1: true,
    showButton2: true,
  });
  const [searchQuery, setSearchQuery] = React.useState({
    limit: 10,
    offset: 0,
    sortDirection: 'ASC',
  });
  const classes = useStyles();
  const [userProfile, setUserProfile] = React.useState({});
  const [availabilityUpdatesListData, setAvailabilityUpdatesListData] =
    React.useState([]);
  const [, setAvailabilityUpdatesItemData] = React.useState();
  const [availabilityCount, setAvailabilityCount] = React.useState();
  const [installersList, setInstallersList] = React.useState([]);
  const [updateStatus, setUpdateStatus] = React.useState();
  const [isOpen, setIsOpen] = React.useState(false);
  const [reloadForm, setReloadForm] = React.useState(false);
  const [selectedFilters, setSelectedFilters] = React.useState();
  const [confirmDialog, setConfirmDialog] = React.useState({
    header: '',
    title: '',
    subtitle: '',
    isOpen: false,
  });
  const toast = useRef(null);
  const [getInstallerId, setInstallerId] = React.useState(null);
  const { techniciansLimitList } = useSelector(
    state => state.techniciansLimitList
  );
  const approvalPermission = checkPermission(
    permissions?.availabilityUpdates?.availabilityUpdatesApproval
  );
  const availabilityUpdateForAllUser = checkPermission(
    permissions?.availabilityUpdates.availabilityUpdateForAllUser
  );
  const installerId = localStorage.getItem('installer_id')
    ? Number(localStorage.getItem('installer_id'))
    : '';
  React.useEffect(() => {
    setInstallerId(installerId);
  }, [installerId]);
  const dataTableColumns = [
    {
      name: '',
      options: {
        sort: false,
        filter: false,
        viewColumns: false,
        setCellProps: () => ({
          style: { minWidth: '160px', maxWidth: '160px' },
        }),
        ...(!loading
          ? {
              customBodyRenderLite: dataIndex => {
                return (
                  <span style={{ display: 'none' }} id={dataIndex}>
                    {checkPermission(
                      permissions?.availabilityUpdates?.menu
                    ) && (
                      <span>
                        <Tooltip title="View">
                          <IconButton
                            classes={{ root: classes.actionIcons }}
                            onClick={() =>
                              viewEditAvailabilityUpdate(
                                'view',
                                dataIndex,
                                dataArray && dataArray[dataIndex]
                              )
                            }
                          >
                            <VisibilityIcon />
                          </IconButton>
                        </Tooltip>
                      </span>
                    )}
                    {checkPermission(
                      permissions?.availabilityUpdates?.editAvailabilityUpdates
                    ) &&
                      (moment(
                        dataArray[dataIndex]?.time_off_start_date
                      ).isSameOrAfter(format(new Date(), 'yyyy-MM-dd')) ||
                        dataArray[dataIndex].is_recurring) && (
                        <span>
                          <Tooltip title="Edit">
                            <IconButton
                              classes={{ root: classes.actionIcons }}
                              onClick={() =>
                                viewEditAvailabilityUpdate(
                                  'edit',
                                  dataIndex,
                                  dataArray && dataArray[dataIndex]
                                )
                              }
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        </span>
                      )}
                    {checkPermission(
                      permissions?.availabilityUpdates
                        ?.viewAvailabilityUpdateWithdraw
                    ) &&
                      dataArray &&
                      moment(
                        dataArray[dataIndex]?.time_off_start_date
                      ).isSameOrAfter(format(new Date(), 'yyyy-MM-dd')) && (
                        <span>
                          <Tooltip title="Withdraw">
                            <IconButton
                              classes={{ root: classes.actionIcons }}
                              onClick={() =>
                                confirmStatusUpdation(dataIndex, 'withdraw')
                              }
                            >
                              <WithDrawIcon />
                            </IconButton>
                          </Tooltip>
                        </span>
                      )}
                    {checkPermission(permissions?.availabilityUpdates?.menu) &&
                      dataArray &&
                      moment(
                        dataArray[dataIndex]?.time_off_start_date
                      ).isSameOrAfter(format(new Date(), 'yyyy-MM-dd')) &&
                      approvalPermission === true && (
                        <span>
                          <Tooltip title="Acknowledge">
                            <IconButton
                              classes={{ root: classes.actionIcons }}
                              onClick={() =>
                                confirmStatusUpdation(dataIndex, 'acknowledge')
                              }
                            >
                              <AcknowledgeIcon />
                            </IconButton>
                          </Tooltip>
                        </span>
                      )}
                    {checkPermission(permissions?.availabilityUpdates?.menu) &&
                      dataArray &&
                      moment(
                        dataArray[dataIndex]?.time_off_start_date
                      ).isSameOrAfter(format(new Date(), 'yyyy-MM-dd')) && (
                        <span>
                          <Tooltip title="Contact Office">
                            <IconButton
                              classes={{ root: classes.actionIcons }}
                              onClick={() =>
                                confirmStatusUpdation(
                                  dataIndex,
                                  'contactoffice'
                                )
                              }
                            >
                              <ContactOfficeIcon />
                            </IconButton>
                          </Tooltip>
                        </span>
                      )}
                    {checkPermission(
                      permissions?.availabilityUpdates
                        ?.viewAvailabilityUpdateCancel
                    ) &&
                      dataArray && (
                        <span>
                          <Tooltip title="Cancel">
                            <IconButton
                              classes={{ root: classes.actionIcons }}
                              onClick={() =>
                                confirmStatusUpdation(dataIndex, 'cancel')
                              }
                            >
                              <CancelRoundedIcon />
                            </IconButton>
                          </Tooltip>
                        </span>
                      )}
                  </span>
                );
              },
            }
          : {}),
      },
    },
    {
      name: 'installer_name',
      label: 'Technician',
      options: {
        sort: true,
        filter: true,
      },
    },
    {
      name: 'time_off_start_date',
      label: 'Start Date and Time',
      options: {
        sort: true,
        filter: true,
      },
    },
    {
      name: 'time_off_end_date',
      label: 'End Date and Time',
      options: {
        sort: true,
      },
    },
    {
      name: 'recurring',
      label: 'Recurring',
      options: {
        sort: true,
        ...(!loading
          ? {
              customBodyRender: value => (value ? <CheckIcon /> : ''),
            }
          : {}),
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        sort: true,
        customBodyRender: value => (
          <Typography
            variant="subtitle2"
            className={
              (value === 'Pending' && classes.statusColorPending) ||
              (value === 'Acknowledged' && classes.statusColorAcknowledged) ||
              (value === 'Contact Office' &&
                classes.statusColorContactOffice) ||
              (value === 'Withdrawn' && classes.statusColorWithdrawn) ||
              (value === 'Cancelled' && classes.statusColorCancelled)
            }
          >
            {value}
          </Typography>
        ),
      },
    },
    {
      name: 'createdBy',
      label: 'Created By',
      options: {
        sort: true,
      },
    },
    {
      name: 'created_at',
      label: 'Created At',
      options: {
        sort: true,
      },
    },
  ];

  let intervalVal = '';
  const handleSearch = searchInputVal => {
    const searchString = searchInputVal?.trim();

    /** Timeout will help to let multiple characters in the TextInput,* and API call would be optimized to get Values for Input of few chars at once,* rather than for every single character*/
    try {
      clearTimeout(intervalVal);
    } catch (err) {
      console.error(err);
    }
    intervalVal = setTimeout(() => {
      setLoading(true);
      setSearchQuery(prevState => ({
        ...prevState,
        offset: 0,
        searchString: searchString,
      }));
    }, 500);
  };

  React.useEffect(() => {
    getAvailabilityUpdatesList(
      searchQuery,
      selectedFilters,
      setLoading,
      setAlert,
      setAvailabilityUpdatesListData,
      setAvailabilityCount
    );
    getInstallersList(setLoading, setAlert, setInstallersList);
  }, [searchQuery, selectedFilters, reloadForm]);
  React.useEffect(() => {
    clearAlert();
    getUserProfile(setUserProfile);
  }, []);
  const handleFilterSubmit = applyNewFilters => {
    let appliedFiltersList = applyNewFilters();
    let selectedFilterObject = {};
    appliedFiltersList.map((data, index) => {
      if (data?.length) {
        let columnKey = dataTableColumns[index]?.name;
        let selectedFilterOptionsKey = [];
        switch (columnKey) {
          case 'installer_id':
            selectedFilterOptionsKey = data.map(val => val?.installer_id);
            break;
          case 'time_off_start_date':
            selectedFilterOptionsKey = data.map(val => val.time_off_start_date);
            break;
          case 'time_off_end_date':
            selectedFilterOptionsKey = data.map(val => val.time_off_end_date);
            break;
          case 'recurring':
            selectedFilterOptionsKey = data.map(val => val.recurring);
            break;
          case 'status_id':
            selectedFilterOptionsKey = data.map(val => val.status_id);
            break;
          default:
            selectedFilterOptionsKey = [];
        }
        selectedFilterObject = {
          ...selectedFilterObject,
          [columnKey]: selectedFilterOptionsKey,
        };
      }
    });
    setSelectedFilters(selectedFilterObject);
  };
  let dataArray =
    availabilityUpdatesListData?.map(val => {
      return {
        installer_name: val.installer_name,
        installer_id: val.installer_id,
        user_id: val.user_id,
        user_time_off_request_id: val.user_time_off_request_id,
        time_off_start_date: val.time_off_start_date
          ? moment(val.time_off_start_date).format('MM-DD-YYYY hh:mm A')
          : null,
        time_off_end_date: val.time_off_end_date
          ? moment(val.time_off_end_date).format('MM-DD-YYYY hh:mm A')
          : null,
        recurring: val.recurring,
        status: val.status,
        full_day: val.full_day,
        status_id: val.status_id,
        createdBy: val?.createdBy,
        created_at: val?.created_at,
        created_by: val?.created_by,
      };
    }) || [];

  const dataTableOptions = {
    download: false,
    print: false,
    resizableColumns: false,
    selectableRows: 'none',
    responsive: 'standard',
    filter: false,
    filterType: 'multiselect',
    tableBodyMinHeight: '300px',
    count: availabilityCount || 0,
    serverSide: true,
    textLabels: {
      body: {
        noMatch: !loading && !dataArray.length && 'No records found',
      },
    },
    onChangeRowsPerPage: numberOfRows => {
      setLoading(true);
      setSearchQuery(prevState => ({
        ...prevState,
        limit: numberOfRows,
        offset: 0,
      }));
    },
    onChangePage: currentPage => {
      setLoading(true);
      setSearchQuery(prevState => ({
        ...prevState,
        offset: currentPage * searchQuery.limit,
      }));
    },
    onColumnSortChange: (changedColumn, direction) => {
      setLoading(true);
      setSearchQuery(prevState => ({
        ...prevState,
        sortKey: changedColumn,
        sortDirection: direction.toUpperCase(),
      }));
    },
    searchProps: {
      onKeyUp: e => {
        if (e.target.defaultValue && e.keyCode === 13) {
          handleSearch(e.target.defaultValue);
        }
      },
    },
    onSearchClose: () => {
      setLoading(true);
      setSearchQuery(prevState => ({
        ...prevState,
        searchString: '',
      }));
    },
    rowsPerPage: searchQuery.limit,
    rowsPerPageOptions: [10, 20, 50, 100],
    setRowProps: (row, dataIndex, rowIndex) => {
      return {
        onMouseEnter: e => handleRowHover(e, row, rowIndex),
        onMouseLeave: e => handleRowHoverLeave(e, row, rowIndex),
        onDoubleClick: () => {
          viewEditAvailabilityUpdate(
            'view',
            dataIndex,
            dataArray && dataArray[dataIndex]
          );
        },
      };
    },
    confirmFilters: true,
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
      return (
        <div style={{ marginTop: '40px' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleFilterSubmit(applyNewFilters)}
          >
            Apply Filters
          </Button>
        </div>
      );
    },
    onFilterChange: (column, filterList, type, changedColumnIndex) => {
      let selectedFilterOptionsKey = [];
      switch (column) {
        case 'installer_id':
          selectedFilterOptionsKey = filterList[changedColumnIndex].map(
            val => val?.installer_id
          );
          break;
        case 'status_id':
          selectedFilterOptionsKey = filterList[changedColumnIndex].map(
            val => val.status
          );
          break;
        case 'time_off_start_date':
          selectedFilterOptionsKey = filterList[changedColumnIndex].map(
            val => val.time_off_start_date
          );
          break;
        case 'time_off_end_date':
          selectedFilterOptionsKey = filterList[changedColumnIndex].map(
            val => val.time_off_end_date
          );
          break;
        case 'recurring':
          selectedFilterOptionsKey = filterList[changedColumnIndex].map(
            val => val.recurring
          );
          break;
        case 'created_by':
          selectedFilterOptionsKey = filterList[changedColumnIndex].map(
            val => val.createdBy
          );
          break;
        case 'created_at':
          selectedFilterOptionsKey = filterList[changedColumnIndex].map(
            val => val.created_at
          );
          break;
        default:
          selectedFilterOptionsKey = [];
      }
      setSelectedFilters(prevState => {
        return { ...prevState, [column]: selectedFilterOptionsKey };
      });
    },
    pagination: !loading,
  };
  let filteredInstallerList = techniciansLimitList;
  const currentInstallerId = localStorage.getItem('installer_id');
  if (!availabilityUpdateForAllUser) {
    if (currentInstallerId === 'null') {
      filteredInstallerList = [];
    } else {
      if (techniciansLimitList.length && getInstallerId) {
        filteredInstallerList =
          techniciansLimitList?.filter(
            installer => installer.installer_id === getInstallerId
          ) || [];
      }
    }
  }

  const getAvailabilityUpdatesAddViewEditForm = () => {
    return loadingAvailability ? (
      <Grid container justifyContent="center">
        <PFTableLoader />
      </Grid>
    ) : (
      <Grid container spacing={2} direction="column">
        <Grid
          item
          xs={12}
          className={formAction === 'edit' && classes.disableSection}
        >
          <Autocomplete
            id="installer_name"
            name="installer_name"
            options={filteredInstallerList}
            getOptionLabel={option =>
              option ? `${option.first_name} ${option.last_name}` : ''
            }
            renderInput={params => (
              <TextField
                {...params}
                label="Technician"
                placeholder="Select Staff"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loadingPosition ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
            value={
              installersList?.filter(
                installer =>
                  installer.installer_id ===
                  availabilityUpdatesFormik?.values?.installer_id
              )[0] || ''
            }
            freeSolo
            onChange={async (event, value) => {
              availabilityUpdatesFormik.setFieldValue(
                'installer_id',
                value?.installer_id
              );
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            onBlur={availabilityUpdatesFormik.handleBlur}
            disabled={formAction === 'view' || formAction === 'edit'}
          />
        </Grid>
        <Grid
          item
          xs={12}
          className={`${availabilityUpdatesFormik.values.full_day && classes.disableSection}`}
        >
          <TextField
            autoOk
            id="time_off_start_date"
            name="time_off_start_date"
            label="Unavailability Start Date and Time"
            type="datetime-local"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 900, // 15 min
            }}
            value={
              availabilityUpdatesFormik?.values?.time_off_start_date || null
            }
            disabled={
              formAction === 'view' || availabilityUpdatesFormik.values.full_day
            }
            onChange={date => {
              availabilityUpdatesFormik.setFieldTouched(
                'time_off_start_date',
                true
              );
              availabilityUpdatesFormik.setFieldValue(
                'time_off_start_date',
                date.target.value
              );
            }}
            onBlur={availabilityUpdatesFormik.handleBlur}
            error={
              availabilityUpdatesFormik.touched.time_off_start_date &&
              availabilityUpdatesFormik.errors.time_off_start_date
            }
            helperText={
              availabilityUpdatesFormik.touched.time_off_start_date &&
              availabilityUpdatesFormik.errors.time_off_start_date
            }
          ></TextField>
        </Grid>
        <Grid
          item
          xs={12}
          className={`${availabilityUpdatesFormik.values.full_day && classes.disableSection}`}
        >
          <TextField
            autoOk
            id="time_off_end_date"
            name="time_off_end_date"
            label="Unavailability End Date and Time"
            type="datetime-local"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 900, // 15 min
            }}
            value={availabilityUpdatesFormik?.values?.time_off_end_date || null}
            disabled={
              formAction === 'view' || availabilityUpdatesFormik.values.full_day
            }
            onChange={date => {
              availabilityUpdatesFormik.setFieldTouched(
                'time_off_end_date',
                true
              );
              availabilityUpdatesFormik.setFieldValue(
                'time_off_end_date',
                date?.target?.value
              );
            }}
            onBlur={availabilityUpdatesFormik.handleBlur}
            error={
              availabilityUpdatesFormik.touched.time_off_end_date &&
              availabilityUpdatesFormik.errors.time_off_end_date
            }
            helperText={
              availabilityUpdatesFormik.touched.time_off_end_date &&
              availabilityUpdatesFormik.errors.time_off_end_date
            }
          ></TextField>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            value="top"
            name="full_day"
            control={
              <Checkbox
                color="primary"
                classes={{
                  root: classes.checkboxRoot,
                }}
                value={availabilityUpdatesFormik?.values?.full_day || null}
                onChange={(event, value) => {
                  availabilityUpdatesFormik.setFieldValue('full_day', value);
                  availabilityUpdatesFormik.setFieldValue(
                    'time_off_start_date',
                    moment(
                      availabilityUpdatesFormik?.values?.time_off_start_date
                    )
                      .set({
                        hour: 0,
                        minute: 0,
                        second: 0,
                        millisecond: 0,
                      })
                      .format('YYYY-MM-DDTHH:mm')
                  );
                  availabilityUpdatesFormik.setFieldValue(
                    'time_off_end_date',
                    moment(availabilityUpdatesFormik?.values?.time_off_end_date)
                      .set({
                        hour: 23,
                        minute: 59,
                        second: 59,
                        millisecond: 59,
                      })
                      .format('YYYY-MM-DDTHH:mm')
                  );
                }}
                checked={availabilityUpdatesFormik?.values?.full_day}
              />
            }
            disabled={
              formAction === 'view' ||
              !(
                availabilityUpdatesFormik?.values?.time_off_start_date &&
                availabilityUpdatesFormik?.values?.time_off_end_date
              )
            }
            label="Full Day"
            labelPlacement="right"
            classes={{
              root: classes.checkboxFormRoot,
              labelPlacementTop: classes.checkboxLabelPlacement,
              label: classes.checkboxLabel,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            value="top"
            name="is_recurring"
            control={
              <Checkbox
                color="primary"
                classes={{
                  root: classes.checkboxRoot,
                }}
                onChange={(event, value) => {
                  if (!value) {
                    weeks.map(week =>
                      availabilityUpdatesFormik.setFieldValue(week?.name, false)
                    );
                    availabilityUpdatesFormik.setFieldValue(
                      'is_recurring_every_other_week',
                      0
                    );
                    availabilityUpdatesFormik.setFieldValue(
                      'is_recurring',
                      false
                    );
                  } else {
                    availabilityUpdatesFormik.setFieldValue(
                      'is_recurring_every_other_week',
                      1
                    );
                    availabilityUpdatesFormik.setFieldValue(
                      'is_recurring',
                      value
                    );
                  }
                }}
                checked={availabilityUpdatesFormik?.values?.is_recurring}
              />
            }
            disabled={formAction === 'view'}
            label="Recurring"
            labelPlacement="right"
          />
        </Grid>
        <Grid
          item
          xs={12}
          className={
            classes.recurringSection +
            ' ' +
            `${!availabilityUpdatesFormik?.values?.is_recurring && classes.disableSection}`
          }
        >
          <Typography>
            Recur every
            <NumberFormat
              id="is_recurring_every_other_week"
              name="is_recurring_every_other_week"
              className={classes.everyWeek}
              allowNegative={false}
              onChange={availabilityUpdatesFormik.handleChange}
              onBlur={availabilityUpdatesFormik.handleBlur}
              customInput={TextField}
              inputmode="numeric"
              InputLabelProps={{ shrink: true }}
              value={
                availabilityUpdatesFormik?.values
                  ?.is_recurring_every_other_week || null
              }
              error={
                availabilityUpdatesFormik.touched
                  .is_recurring_every_other_week &&
                availabilityUpdatesFormik.errors.is_recurring_every_other_week
              }
              disabled={formAction === 'view' ? true : false}
              inputProps={{ min: 0, style: { textAlign: 'center' } }}
            />
            week(s) on:
          </Typography>
          {availabilityUpdatesFormik.touched.is_recurring_every_other_week &&
            availabilityUpdatesFormik.errors.is_recurring_every_other_week && (
              <FormHelperText error>
                {availabilityUpdatesFormik.errors.is_recurring_every_other_week}
              </FormHelperText>
            )}
          <Grid container className={classes.recurringDayList}>
            {weeks.map(week => (
              <Grid item xs={3} key={week.name}>
                <FormControlLabel
                  value="top"
                  name="recurring"
                  control={
                    <Checkbox
                      color="primary"
                      classes={{
                        root: classes.checkboxRoot,
                      }}
                      onChange={(event, value) => {
                        availabilityUpdatesFormik.setFieldValue(
                          week.name,
                          value
                        );
                      }}
                      checked={availabilityUpdatesFormik?.values[week.name]}
                    />
                  }
                  disabled={formAction === 'view'}
                  label={week.label}
                  labelPlacement="right"
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="time_off_note"
            label="Notes"
            multiline
            required={formAction === 'edit' || formAction === 'add'}
            onChange={availabilityUpdatesFormik.handleChange}
            onBlur={availabilityUpdatesFormik.handleBlur}
            InputProps={{
              readOnly: formAction === 'view' ? true : false,
            }}
            value={availabilityUpdatesFormik?.values?.time_off_note}
            InputLabelProps={{ shrink: true }}
            error={
              availabilityUpdatesFormik.touched.time_off_note &&
              availabilityUpdatesFormik.errors.time_off_note
            }
            helperText={
              availabilityUpdatesFormik.touched.time_off_note &&
              availabilityUpdatesFormik.errors.time_off_note
            }
            disabled={formAction === 'view'}
          />
        </Grid>
      </Grid>
    );
  };
  // ****Validation Schema ****
  const availabilityUpdatesValidationSchema = Yup.object().shape({
    installer_name: Yup.string().trim(),
    installer_id: Yup.number().required('Required'),
    time_off_start_date: Yup.string().trim().required('Required').nullable(),
    time_off_end_date: Yup.string()
      .trim()
      .required('Required')
      .nullable()
      .test(
        'is-greater',
        'End Date should be greater than Start Date',
        function (value) {
          const { time_off_start_date } = this.parent;
          return (
            moment(value).diff(moment(time_off_start_date), 'minutes') >= 0
          );
        }
      )
      .test(
        'difference-recurrence-selected',
        'There should be a gap of 7 days as Recurring option has been chosen',
        function (value) {
          const { time_off_start_date, is_recurring } = this.parent;
          if (is_recurring) {
            return moment(value).diff(moment(time_off_start_date), 'days') >= 7;
          } else {
            return true;
          }
        }
      )
      .test(
        'is-greater',
        'Atleast one hour gap should be there between Start Time and End Time',
        function (value) {
          const { time_off_start_date } = this.parent;
          return (
            moment(value).diff(moment(time_off_start_date), 'minutes') > 0 &&
            moment(value).diff(moment(time_off_start_date), 'minutes') >= 60
          );
        }
      ),
    is_recurring: Yup.boolean()
      .required()
      .test({
        name: 'weekDayTest',
        message: 'Atleast one week day shuld be selected',
        test: (value, otherFieldvals) => {
          if (value) {
            return (
              otherFieldvals.parent.sun ||
              otherFieldvals.parent.mon ||
              otherFieldvals.parent.tue ||
              otherFieldvals.parent.wed ||
              otherFieldvals.parent.thus ||
              otherFieldvals.parent.fri ||
              otherFieldvals.parent.sat
            );
          }
          return true;
        },
      }),
    is_recurring_every_other_week: Yup.number().when('is_recurring', {
      is: true,
      then: Yup.number()
        .min(1, 'Enter a number greater than 0')
        .required('Required'),
    }),
    status: Yup.string().trim(),
    full_day: Yup.string().trim().required(),
    time_off_note: Yup.string().trim().trim().required('Required'),
    sun: Yup.boolean(),
    mon: Yup.boolean(),
    tue: Yup.boolean(),
    wed: Yup.boolean(),
    thus: Yup.boolean(),
    fri: Yup.boolean(),
    sat: Yup.boolean(),
  });
  // **** Formik Form Values ****
  const availabilityUpdatesFormik = useFormik({
    initialValues: {
      user_id: '',
      installer_id: '',
      time_off_start_date: '',
      time_off_end_date: '',
      full_day: false,
      is_recurring: false,
      is_recurring_every_other_week: 0,
      sun: false,
      mon: false,
      tue: false,
      wed: false,
      thus: false,
      fri: false,
      sat: false,
      time_off_note: '',
    },
    onSubmit: async (values, { resetForm }) => {
      if (formAction === 'add') {
        const response = await createAvailabilityUpdate(
          availabilityUpdatesFormik.values,
          setLoading,
          setAlert,
          setReloadForm,
          setIsOpen
        );
        if (response?.status === false && response?.message) {
          setReloadForm(true);
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: response?.message,
          });
        }
        if (response?.status === 200 || response?.status === true) {
          setReloadForm(true);
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Availability Update added successfully.',
          });
        }
      } else {
        const response = await updateAvailabilityUpdate(
          availabilityUpdatesFormik.values,
          setLoading,
          setAlert,
          setReloadForm,
          setIsOpen
        );
        if (response?.status) {
          setReloadForm(true);
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Availability updated successfully.',
          });
        } else {
          setReloadForm(true);
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: response?.message
              ? response?.message
              : 'Something went wrong!',
          });
        }
      }
      setReloadForm(false);
      resetForm();
    },
    validationSchema: availabilityUpdatesValidationSchema,
    enableReinitialize: true,
  });
  const addNewAvailabilityUpdate = () => {
    setFormAction('add');
    availabilityUpdatesFormik?.setValues({
      user_id: userProfile.user_id,
      time_off_start_date: '',
      time_off_end_date: '',
      full_day: false,
      is_recurring: false,
      is_recurring_every_other_week: 0,
      sun: false,
      mon: false,
      tue: false,
      wed: false,
      thus: false,
      fri: false,
      sat: false,
      time_off_note: '',
    });
    setDialogSettings(prevState => ({
      ...prevState,
      showButton2: true,
      button2Text: 'Save',
      title: 'Add Availability Update',
    }));
    setIsOpen(true);
  };

  const viewEditAvailabilityUpdate = (action, index, rowData) => {
    setFormAction(action);
    getAvailabilityUpdate(
      rowData.installer_id,
      rowData.user_time_off_request_id,
      setLoadingAvailability,
      setAlert,
      setAvailabilityUpdatesItemData
    ).then(availabilityUpdatesItemData =>
      availabilityUpdatesFormik?.setValues({
        installer_id: rowData.installer_id,
        user_time_off_request_id: rowData.user_time_off_request_id,
        time_off_start_date: momentTz(
          availabilityUpdatesItemData?.time_off_start_date
        ).format('YYYY-MM-DDTHH:mm'),
        time_off_end_date: momentTz(
          availabilityUpdatesItemData?.time_off_end_date
        ).format('YYYY-MM-DDTHH:mm'),
        is_recurring: availabilityUpdatesItemData?.is_recurring,
        is_recurring_every_other_week:
          availabilityUpdatesItemData?.is_recurring_every_other_week,
        sun: availabilityUpdatesItemData?.sun,
        mon: availabilityUpdatesItemData?.mon,
        tue: availabilityUpdatesItemData?.tue,
        wed: availabilityUpdatesItemData?.wed,
        thus: availabilityUpdatesItemData?.thus,
        fri: availabilityUpdatesItemData?.fri,
        sat: availabilityUpdatesItemData?.sat,
        time_off_note: availabilityUpdatesItemData?.time_off_note,
        full_day: availabilityUpdatesItemData.full_day ? true : false,
        createdBy: rowData?.createdBy,
        created_at: rowData?.created_at,
      })
    );
    setDialogSettings(prevState => ({
      ...prevState,
      showButton2: action === 'view' ? false : true,
      button2Text: action === 'edit' ? 'Save' : '',
      title:
        (action === 'view' && 'View Availability Update') ||
        'Edit Availability Update',
    }));
    setRowIndex(index);
    setIsOpen(true);
  };
  const confirmStatusUpdation = (index, action) => {
    let title, header, buttonText;
    setRowIndex(index);
    switch (action) {
      case 'withdraw':
        title = 'Are you sure you want to withdraw availability update?';
        header = 'Withdraw availability update';
        buttonText = 'Withdraw';
        setUpdateStatus(90);
        break;
      case 'acknowledge':
        title = 'Are you sure you want to acknowledge availability update?';
        header = 'Acknowledge availability update';
        buttonText = 'Acknowledge';
        setUpdateStatus(88);
        break;
      case 'cancel':
        title = 'Are you sure you want to cancel availability update?';
        header = 'Cancel availability update';
        buttonText = 'Proceed';
        setUpdateStatus(89);
        break;
      case 'contactoffice':
        title =
          'Are you sure you want to change availability status to contact office?';
        header = 'Update Availability Status';
        buttonText = 'Contact Office';
        setUpdateStatus(87);
        break;
    }
    setConfirmDialog({
      ...confirmDialog,
      isOpen: true,
      title: title,
      header: header,
      button2Text: buttonText,
    });
  };

  const onConfirmDialog = () => {
    setReloadForm(false);
    let statusUpdateObj = {
      installer_id: availabilityUpdatesListData[rowIndex]?.installer_id,
      user_time_off_request_id:
        availabilityUpdatesListData[rowIndex]?.user_time_off_request_id,
      installer_user_id: availabilityUpdatesListData[rowIndex]?.user_id,
      status_id: updateStatus,
    };
    updateAvailabilityUpdateStatus(
      statusUpdateObj,
      setLoading,
      setAlert,
      setReloadForm,
      setConfirmDialog
    );
  };

  const tableLoader = useMemo(() => {
    return TableColumnsLoader(dataTableColumns, {
      rows: searchQuery?.limit,
      isValue: false,
    });
  }, [dataTableColumns]);

  return (
    <>
      <Toast ref={toast} />
      <Grid container spacing={2} direction="column">
        {alert.exists && (
          <Grid item>
            {' '}
            <Alert />
          </Grid>
        )}
        <Grid
          container
          item
          direction="row"
          justifyContent="space-between"
          spacing={2}
        >
          <Grid item>
            <PageHeader
              pageTitle="Availability Updates"
              breadCrumbArray={availablityUpdatesBreadcrumb}
            />
          </Grid>
          {checkPermission(
            permissions?.availabilityUpdates?.addAvailabilityUpdates
          ) && (
            <Grid item classes={{ root: classes.selfAlignGrid }}>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={addNewAvailabilityUpdate}
                >
                  Add Availability Update
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>

        {availabilityUpdatesListData && (
          <Grid item classes={{ root: classes.dataTableWrapper }}>
            <GenericDataTable
              columns={dataTableColumns}
              data={loading ? tableLoader : dataArray}
              options={dataTableOptions}
            />
          </Grid>
        )}
      </Grid>
      <GenericDialog
        fullwidth
        isOpen={isOpen}
        handleClose={() => {
          setIsOpen(false);
          availabilityUpdatesFormik?.handleReset();
        }}
        handleSave={availabilityUpdatesFormik.handleSubmit}
        dialogSettings={dialogSettings}
        disabledButton2={
          !availabilityUpdatesFormik?.touched ||
          !availabilityUpdatesFormik?.dirty ||
          (availabilityUpdatesFormik?.dirty &&
            !availabilityUpdatesFormik?.isValid)
        }
        disabledButton1={false}
      >
        <form>{getAvailabilityUpdatesAddViewEditForm()}</form>
      </GenericDialog>
      {/* Confirmation dialog for withdraw/acknowledge/cancel/contactoffice */}
      <GenericConfirmationDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        onConfirmDialog={onConfirmDialog}
      />
    </>
  );
};

export default AvailabilityUpdates;
