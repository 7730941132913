export const VALID_MEASURES = {
  Address: [
    'Address.count',
    'Address.address_id',
    'Address.latitude',
    'Address.longitude',
  ],
  Client: ['Client.count', 'Client.client_id'],
  Companies: [
    'Companies.count',
    'Companies.company_id',
    'Companies.address_id',
  ],
  'Compare Payment Request': [
    'ComparePaymentRequest.count',
    'ComparePaymentRequest.payment_request_id',
    'ComparePaymentRequest.client_id',
    'ComparePaymentRequest.project_id',
    'ComparePaymentRequest.payment_status_id',
    'ComparePaymentRequest.check_amount',
    'ComparePaymentRequest.requested_amount',
    'ComparePaymentRequest.qb_rec_id',
    'ComparePaymentRequest.total_sale_amount',
  ],
  'Confirmation Status': [
    'ConfirmationStatus.status_id',
    'ConfirmationStatus.status',
  ],
  'Credential Type': [
    'CredentialType.count',
    'CredentialType.credential_type_id',
  ],
  'Hd Survey': [
    'HdSurvey.count',
    'HdSurvey.hd_survey_id',
    'HdSurvey.ltua',
    'HdSurvey.installer_voc',
    'HdSurvey.measure_voc',
    'HdSurvey.consultation_voc',
    'HdSurvey.install_arrived_on_time',
    'HdSurvey.work_explanation',
    'HdSurvey.professionalism',
    'HdSurvey.workmanship',
    'HdSurvey.jobsite_clean_up',
    'HdSurvey.consultation_arrived_on_time',
    'HdSurvey.project_timeliness',
    'HdSurvey.project_communication',
    'HdSurvey.interview_length',
  ],
  Customer: [
    'Customer.count',
    'Customer.customer_id',
    'Customer.billing_address_id',
    'Customer.refferal_source_name_id',
    'Customer.refferal_account_source_id',
  ],
  'Customer Address': [
    'CustomerAddress.count',
    'CustomerAddress.address_id',
    'CustomerAddress.latitude',
    'CustomerAddress.longitude',
  ],
  'Customer Email': [
    'CustomerEmail.count',
    'CustomerEmail.customer_email_id',
    'CustomerEmail.customer_id',
  ],
  'Customer Phones': [
    'CustomerPhones.count',
    'CustomerPhones.customer_phone_id',
    'CustomerPhones.customer_id',
    'CustomerPhones.phone_type_id',
  ],
  'Installer Detail': [
    'InstallerDetail.count',
    'InstallerDetail.installer_id',
    'InstallerDetail.client_id',
    'InstallerDetail.shirt_size_id',
  ],
  'Installer Work Type': [
    'InstallerWorkType.count',
    'InstallerWorkType.worktypeid',
    'InstallerWorkType.worktype',
  ],
  'Inventory Adjustment': [
    'InventoryAdjustment.count',
    'InventoryAdjustment.inventory_adjustment_id',
    'InventoryAdjustment.product_id',
    'InventoryAdjustment.warehouse_id',
    'InventoryAdjustment.quantity_to_adjust',
    'InventoryAdjustment.converted_quantity_to_adjust',
    'InventoryAdjustment.cost_of_adjustment_purchase',
    'InventoryAdjustment.cost_of_adjustment_sale',
    'InventoryAdjustment.purchase_price_per_unit',
    'InventoryAdjustment.sales_price_per_unit',
  ],
  'Inventory Product': [
    'InventoryProduct.count',
    'InventoryProduct.inventory_product_id',
    'InventoryProduct.product_id',
    'InventoryProduct.source_price',
    'InventoryProduct.sales_price',
    'InventoryProduct.purchase_price',
    'InventoryProduct.total_quantity_in_hand',
    'InventoryProduct.total_quantity_available',
    'InventoryProduct.total_quantity_allocated',
    'InventoryProduct.threshold',
    'InventoryProduct.sales_price_per_unit',
    'InventoryProduct.purchase_price_per_unit',
    'InventoryProduct.total_purchase_amount',
    'InventoryProduct.total_sales_amount',
  ],
  InventoryTransferFrom: [
    'InventoryTransferFrom.count',
    'InventoryTransferFrom.master_warehouse_id',
    'InventoryTransferFrom.product_id',
    'InventoryTransferFrom.address_id',
    'InventoryTransferFrom.sold_quantity',
    'InventoryTransferFrom.lost_quantity',
    'InventoryTransferFrom.delivered_quantity',
    'InventoryTransferFrom.damaged_quantity',
    'InventoryTransferFrom.available_quantity',
    'InventoryTransferFrom.allotted_quantity',
    'InventoryTransferFrom.actual_quantity',
    'InventoryTransferFrom.actual_purchased_stock_amount',
    'InventoryTransferFrom.actual_sales_stock_amount',
    'InventoryTransferFrom.allocated_purchased_stock_amount',
    'InventoryTransferFrom.allocated_sales_stock_amount',
    'InventoryTransferFrom.available_purchased_stock_amount',
    'InventoryTransferFrom.available_sales_stock_amount',
    'InventoryTransferFrom.damaged_purchased_stock_amount',
    'InventoryTransferFrom.damaged_sales_stock_amount',
    'InventoryTransferFrom.delivered_purchased_stock_amount',
    'InventoryTransferFrom.delivered_sales_stock_amount',
    'InventoryTransferFrom.lost_purchased_stock_amount',
    'InventoryTransferFrom.lost_sales_stock_amount',
    'InventoryTransferTo.count',
    'InventoryTransferTo.master_warehouse_id',
    'InventoryTransferTo.product_id',
    'InventoryTransferTo.address_id',
    'InventoryTransferTo.sold_quantity',
    'InventoryTransferTo.lost_quantity',
    'InventoryTransferTo.delivered_quantity',
    'InventoryTransferTo.damaged_quantity',
    'InventoryTransferTo.available_quantity',
    'InventoryTransferTo.allotted_quantity',
    'InventoryTransferTo.actual_quantity',
    'InventoryTransferTo.actual_purchased_stock_amount',
    'InventoryTransferTo.actual_sales_stock_amount',
    'InventoryTransferTo.allocated_purchased_stock_amount',
    'InventoryTransferTo.allocated_sales_stock_amount',
    'InventoryTransferTo.available_purchased_stock_amount',
    'InventoryTransferTo.available_sales_stock_amount',
    'InventoryTransferTo.damaged_purchased_stock_amount',
    'InventoryTransferTo.damaged_sales_stock_amount',
    'InventoryTransferTo.delivered_purchased_stock_amount',
    'InventoryTransferTo.delivered_sales_stock_amount',
    'InventoryTransferTo.lost_purchased_stock_amount',
    'InventoryTransferTo.lost_sales_stock_amount',
  ],
  'Inventory Transfer': [
    'InventoryTransfer.count',
    'InventoryTransfer.inventory_transfer_id',
    'InventoryTransfer.product_id',
    'InventoryTransfer.transfer_from_warehouse_id',
    'InventoryTransfer.transfer_to_warehouse_id',
    'InventoryTransfer.quantity',
  ],
  'Inventory Warehouse Stock': [
    'InventoryWarehouseStock.count',
    'InventoryWarehouseStock.inventory_warehouse_stock_id',
    'InventoryWarehouseStock.product_id',
    'InventoryWarehouseStock.warehouse_id',
    'InventoryWarehouseStock.actual_quantity',
    'InventoryWarehouseStock.allotted_quantity',
    'InventoryWarehouseStock.available_quantity',
    'InventoryWarehouseStock.damaged_quantity',
    'InventoryWarehouseStock.delivered_quantity',
    'InventoryWarehouseStock.lost_quantity',
    'InventoryWarehouseStock.sold_quantity',
    'InventoryWarehouseStock.purchased_price_per_unit',
    'InventoryWarehouseStock.sales_price_per_unit',
    'InventoryWarehouseStock.actual_purchased_stock_amount',
    'InventoryWarehouseStock.actual_sales_stock_amount',
    'InventoryWarehouseStock.allocated_purchased_stock_amount',
    'InventoryWarehouseStock.allocated_sales_stock_amount',
    'InventoryWarehouseStock.available_purchased_stock_amount',
    'InventoryWarehouseStock.available_sales_stock_amount',
    'InventoryWarehouseStock.damaged_purchased_stock_amount',
    'InventoryWarehouseStock.damaged_sales_stock_amount',
    'InventoryWarehouseStock.delivered_purchased_stock_amount',
    'InventoryWarehouseStock.delivered_sales_stock_amount',
    'InventoryWarehouseStock.lost_purchased_stock_amount',
    'InventoryWarehouseStock.lost_sales_stock_amount',
    'InventoryWarehouseStock.inventory_product_total_quantity_in_hand',
    'InventoryWarehouseStock.inventory_product_total_quantity_available',
    'InventoryWarehouseStock.inventory_product_total_quantity_allocated',
    'InventoryWarehouseStock.inventory_product_total_sales_amount',
    'InventoryWarehouseStock.inventory_product_total_purchase_amount',
    'InventoryWarehouseStock.inventory_product_purchase_price_per_unit',
    'InventoryWarehouseStock.inventory_product_sales_price_per_unit',
    'InventoryWarehouseStock.inventory_product_threshold',
    'InventoryWarehouseStock.inventory_product_purchase_price',
    'InventoryWarehouseStock.inventory_product_sales_price',
    'InventoryWarehouseStock.inventory_product_source_price',
  ],
  'Issue Category': [
    'IssueCategory.count',
    'IssueCategory.issue_category_id',
    'IssueCategory.category',
  ],
  Item: [
    'Item.count',
    'Item.item_id',
    'Item.client_id',
    'Item.item_type_id',
    'Item.cost_price',
    'Item.retail_price',
    'Item.inventory_managment',
    'Item.source_price',
    'Item.warehouse_stock_actual_quantity',
    'Item.item_quantity',
    'Item.inventory_product_total_quantity_in_hand',
    'Item.inventory_product_total_quantity_available',
    'Item.inventory_product_total_quantity_allocated',
    'Item.warehouse_stock_allotted_quantity',
    'Item.warehouse_stock_available_quantity',
    'Item.warehouse_stock_damaged_quantity',
    'Item.warehouse_stock_delivered_quantity',
    'Item.warehouse_stock_lost_quantity',
    'Item.warehouse_stock_sold_quantity',
    'Item.inventory_product_total_sales_amount',
    'Item.inventory_product_total_purchase_amount',
    'Item.inventory_product_purchase_price_per_unit',
    'Item.inventory_product_sales_price_per_unit',
    'Item.inventory_product_threshold',
    'Item.inventory_product_purchase_price',
    'Item.inventory_product_sales_price',
    'Item.inventory_product_source_price',
    'Item.inventory_product_total_quantity_lost',
    'Item.inventory_product_total_quantity_sold',
    'Item.inventory_product_total_quantity_damaged',
    'Item.inventory_product_total_quantity_purchased',
  ],
  'Lowes Survey': [
    'LowesSurvey.count',
    'LowesSurvey.lowes_survey_id',
    'LowesSurvey.po_number',
    'LowesSurvey.client_id',
    'LowesSurvey.installer_name',
    'LowesSurvey.survey_date',
    'LowesSurvey.comments',
    'LowesSurvey.district_number',
    'LowesSurvey.store_number',
    'LowesSurvey.store_name',
    'LowesSurvey.professionalism',
    'LowesSurvey.craftsmanship',
    'LowesSurvey.ltr_score',
    'LowesSurvey.qb_sur_id',
  ],
  Warehouse: [
    'Warehouse.count',
    'Warehouse.master_warehouse_id',
    'Warehouse.product_id',
    'Warehouse.address_id',
    'Warehouse.sold_quantity',
    'Warehouse.lost_quantity',
    'Warehouse.delivered_quantity',
    'Warehouse.damaged_quantity',
    'Warehouse.available_quantity',
    'Warehouse.allotted_quantity',
    'Warehouse.actual_quantity',
    'Warehouse.actual_purchased_stock_amount',
    'Warehouse.actual_sales_stock_amount',
    'Warehouse.allocated_purchased_stock_amount',
    'Warehouse.allocated_sales_stock_amount',
    'Warehouse.available_purchased_stock_amount',
    'Warehouse.available_sales_stock_amount',
    'Warehouse.damaged_purchased_stock_amount',
    'Warehouse.damaged_sales_stock_amount',
    'Warehouse.delivered_purchased_stock_amount',
    'Warehouse.delivered_sales_stock_amount',
    'Warehouse.lost_purchased_stock_amount',
    'Warehouse.lost_sales_stock_amount',
  ],
  'Notes Category': ['NotesCategory.count', 'NotesCategory.note_category_id'],
  'Payment Request': [
    'PaymentRequest.count',
    'PaymentRequest.payment_request_id',
    'PaymentRequest.client_id',
    'PaymentRequest.project_id',
    'PaymentRequest.check_amount',
    'PaymentRequest.requested_amount',
  ],
  'Payment Status': ['PaymentStatus.count', 'PaymentStatus.status_id'],
  'Pr Type': ['PrType.count', 'PrType.pr_type_id', 'PrType.type_name'],
  Project: [
    'Project.count',
    'Project.project_id',
    'Project.confirmation_status_id',
    'Project.customer_id',
    'Project.epa_add_lead_safe_practices_item',
    'Project.epa_is_lead_assessment_docs_submitted',
    'Project.epa_is_renovator_certified',
    'Project.epa_lead_remediation',
    'Project.epa_lead_response_reason_id',
    'Project.epa_lead_test_result_id',
    'Project.epa_override_reason_code_id',
    'Project.epa_permit_required',
    'Project.epa_remove_override',
    'Project.is_pick_up_date_provided',
    'Project.no_of_messages_sent',
    'Project.pick_up_location_id',
    'Project.project_category_id',
    'Project.project_type_id',
    'Project.source_status_id',
    'Project.source_system_id',
    'Project.status_id',
    'Project.store_id',
    'Project.total_labor_amount',
    'Project.total_sale_amount',
    'Project.pickup_required',
    'Project.is_routed',
    'Project.products',
    'Project.adjusted_allocated_quantity',
    'Project.available_to_schedule_turn_time',
    'Project.sold_to_product_available_turn_time',
    'Project.available_to_start_turn_time',
    'Project.sold_to_complete_turn_time',
    'Project.product_available_to_complete_turn_time',
    'Project.cost_of_adjustment_purchase',
    'Project.cost_of_adjustment_sale',
    'Project.purchase_price_per_unit',
    'Project.sales_price_per_unit',
    'Project.total_allocated',
    'Project.adjusted_delivered_quantity',
    'Project.project_warranty_service_contract_amount',
    'Project.retail_price',
    'Project.cost_price',
    'Project.item_capacity',
    'Project.item_allocation_percentage',
    'Project.item_number',
    'Project.item_desc',
    'Project.item_type_id',
    'Project.cost_ea',
    'Project.project_item_labor_cost_ea',
    'Project.project_item_labor_cost_ea_value',
    'Project.item_quantity',
    'Project.total_cost_ea',
    'Project.project_item_margin',
    'Project.item_user_percentage',
    'Project.item_user_total_cost',
    'Project.item_user_quantity',
    'Project.item_user_cost',
    'Project.labor_cost_ea',
    'Project.labor_cost_ea_value',
    'Project.item_technician_completion_date',
    'Project.note_text',
    'Project.item_total_sell_price',
    'Project.sell_price',
    'Project.project_item_user_margin',
    'Project.item_technician_status_value',
    'Project.profit',
    'Project.item_technician_profit',
    'Project.item_profit',
    'Project.source_price',
    'Project.retail_price',
    'Project.cost_price',
    'Project.inventory_product_threshold',
    'Project.total_labor_profit',
    'Project.total_labor_retail',
    'Project.total_labor_cost',
    'Project.total_labor_margin',
    'Project.total_merchandise_profit',
    'Project.total_merchandise_cost',
    'Project.total_merchandise_retail',
    'Project.total_merchandise_margin',
    'Project.total_retail_cost',
    'Project.total_overall_profit',
    'Project.total_overall_margin',
    'Project.total_revenue',
    'Project.total_commission',
    'Project.total_cost',
    'Project.material_chargeback',
    'Project.labor_chargeback',
    'Project.total_retail',
    'Project.total_technician',
    'Project.payment_count',
    'Project.payment_amount_in_emi',
    'Project.payment_amount_balance',
    'Project.payout_amount',
    'Project.rts_to_doc_submit_turn_time',
    'Project.work_start_to_doc_submit_turn_time',
    'Project.work_complete_to_doc_submit_turn_time',
    'Project.sold_to_product_available_turn_time',
    'Project.question_id',
    'Project.module_id',
    'Project.answer',
    'Project.project_item_sales_commission',
    'Project.aging',
    'Project.project_item_quantity',
    'Project.installer_sales_commission',
    'Project.total_exclude_from_commission',
    'Project.close_rate',
    'Project.related_install_amount',
    'Project.key_rec_cost',
    'Project.project_issue_total_amount',
    'Project.po_retail_amount',
    'Project.technician_voc',
    'Project.craftmanship',
    'Project.professionalism',
    'Project.ltr',
  ],
  'Project Hd Order Info': [
    'ProjectHDOrderInfo.key_rec_cost',
    'ProjectHDOrderInfo.po_retail_amount',
  ],
  'Project Activity': [
    'ProjectActivity.count',
    'ProjectActivity.project_activity_id',
    'ProjectActivity.project_id',
  ],
  'Project Additional Charges': [
    'ProjectAdditionalCharges.count',
    'ProjectAdditionalCharges.project_additional_charge_id',
    'ProjectAdditionalCharges.project_id',
    'ProjectAdditionalCharges.pr_type_id',
    'ProjectAdditionalCharges.pr_date',
    'ProjectAdditionalCharges.pr_ims_note',
    'ProjectAdditionalCharges.dollar_amount',
    'ProjectAdditionalCharges.pr_number',
    'ProjectAdditionalCharges.store_associate',
    'ProjectAdditionalCharges.processed',
  ],
  'Project Additional Fields': [
    'ProjectAdditionalFields.count',
    'ProjectAdditionalFields.project_additional_field_id',
    'ProjectAdditionalFields.client_id',
    'ProjectAdditionalFields.project_id',
    'ProjectAdditionalFields.sold_to_product_available_turn_time',
    'ProjectAdditionalFields.available_to_schedule_turn_time',
    'ProjectAdditionalFields.available_to_start_turn_time',
    'ProjectAdditionalFields.sold_to_complete_turn_time',
    'ProjectAdditionalFields.product_available_to_complete_turn_time',
    'ProjectAdditionalFields.ll_vendor_id',
    'ProjectAdditionalFields.ll_job_status',
    'ProjectAdditionalFields.ll_sr_job_status',
    'ProjectAdditionalFields.ll_job_accepted',
    'ProjectAdditionalFields.ll_measure_req_sent',
    'ProjectAdditionalFields.ll_install_paper_date',
    'ProjectAdditionalFields.ll_pgi_invoice_date',
    'ProjectAdditionalFields.related_measurer',
    'ProjectAdditionalFields.close_rate',
    'ProjectAdditionalFields.related_install_amount',
  ],
  'Project Category': [
    'ProjectCategory.count',
    'ProjectCategory.project_category_id',
  ],
  'Project Installer': [
    'ProjectInstaller.count',
    'ProjectInstaller.project_installer_id',
    'ProjectInstaller.project_id',
    'ProjectInstaller.installer_id',
    'ProjectInstaller.labor_amount',
    'ProjectInstaller.client_id',
    'ProjectInstaller.worktypeid',
  ],
  'Project Issues': [
    'ProjectIssues.count',
    'ProjectIssues.project_issue_id',
    'ProjectIssues.project_id',
    'ProjectIssues.issue_category_id',
    'ProjectIssues.dollar_amt',
  ],
  'Project Item': [
    'ProjectItem.count',
    'ProjectItem.project_item_id',
    'ProjectItem.project_id',
    'ProjectItem.item_id',
    'ProjectItem.status_id',
    'ProjectItem.item_quantity',
    'ProjectItem.cost_ea',
    'ProjectItem.labor_cost_ea',
    'ProjectItem.labor_cost_percentage',
    'ProjectItem.client_id',
    'ProjectItem.item_number',
    'ProjectItem.warehouse_stock_actual_quantity',
    'ProjectItem.inventory_product_total_quantity_in_hand',
    'ProjectItem.inventory_product_total_quantity_available',
    'ProjectItem.inventory_product_total_quantity_allocated',
    'ProjectItem.warehouse_stock_allotted_quantity',
    'ProjectItem.warehouse_stock_available_quantity',
    'ProjectItem.warehouse_stock_damaged_quantity',
    'ProjectItem.warehouse_stock_delivered_quantity',
    'ProjectItem.warehouse_stock_lost_quantity',
    'ProjectItem.warehouse_stock_sold_quantity',
    'ProjectItem.sales_commission',
  ],
  'Project Note': [
    'ProjectNote.count',
    'ProjectNote.project_note_id',
    'ProjectNote.project_id',
    'ProjectNote.note_status_id',
    'ProjectNote.note_type_id',
    'ProjectNote.closed_by',
    'ProjectNote.store_id',
  ],
  'Project Permit': [
    'ProjectPermit.count',
    'ProjectPermit.project_permit_id',
    'ProjectPermit.project_id',
    'ProjectPermit.status_id',
    'ProjectPermit.is_emergency_permit',
    'ProjectPermit.is_inspection_required',
    'ProjectPermit.is_permit_required',
  ],
  'Project Status': ['ProjectStatus.count', 'ProjectStatus.status_id'],
  'Project Support': [
    'ProjectSupport.count',
    'ProjectSupport.project_support_id',
    'ProjectSupport.project_id',
    'ProjectSupport.support_type_id',
  ],
  'User Details': [
    'ProjectTaskNoteUserCreatedBy.count',
    'ProjectTaskNoteUserCreatedBy.user_meta_detail_id',
    'ProjectTaskNoteUserCreatedBy.role_id',
    'ProjectTaskUserAssignedTo.count',
    'ProjectTaskUserAssignedTo.user_meta_detail_id',
    'ProjectTaskUserAssignedTo.role_id',
    'ProjectTaskUserCreatedBy.count',
    'ProjectTaskUserCreatedBy.user_meta_detail_id',
    'ProjectTaskUserCreatedBy.role_id',
    'TaskNoteUserCreatedBy.count',
    'TaskNoteUserCreatedBy.user_meta_detail_id',
    'TaskNoteUserCreatedBy.role_id',
    'TaskUserCreatedBy.count',
    'TaskUserCreatedBy.user_meta_detail_id',
    'TaskUserCreatedBy.role_id',
    'UserMeta.count',
    'UserMeta.user_meta_detail_id',
    'UserMeta.role_id',
  ],
  'Project Task Notes': ['ProjectTaskNotes.count', 'ProjectTaskNotes.task_id'],
  'Project Tasks': ['ProjectTasks.count', 'ProjectTasks.task_id'],
  'Project Type': ['ProjectType.count', 'ProjectType.project_type_id'],
  'Project Warranty': [
    'ProjectWarranty.count',
    'ProjectWarranty.project_warranty_id',
    'ProjectWarranty.project_id',
    'ProjectWarranty.service_contract_amount',
  ],
  'Shirt Sizes': ['ShirtSizes.count', 'ShirtSizes.shirt_size_id'],
  'Shoe Sizes': ['ShoeSizes.count', 'ShoeSizes.shoe_size_id'],
  'Source Status': ['SourceStatus.count', 'SourceStatus.status_id'],
  'Source System': ['SourceSystem.count', 'SourceSystem.source_system_id'],
  Store: [
    'Store.count',
    'Store.store_id',
    'Store.source_system_id',
    'Store.store_number',
    'Store.address_id',
    'Store.is_deleted',
    'Store.is_private',
  ],
  'Support Type': [
    'SupportType.count',
    'SupportType.support_type_id',
    'SupportType.support_category',
    'SupportType.type_name',
  ],
  'Task Category': ['TaskCategory.count', 'TaskCategory.category_id'],
  'Task Notes': ['TaskNotes.count', 'TaskNotes.task_id'],
  Tasks: ['Tasks.count', 'Tasks.task_id'],
  Teams: ['Teams.count', 'Teams.team_id'],
  'User Lowes Registration': [
    'UserLowesRegistration.count',
    'UserLowesRegistration.user_lrrp_detail_id',
    'UserLowesRegistration.user_id',
    'UserLowesRegistration.client_id',
    'UserLowesRegistration.team_member_id',
    'UserLowesRegistration.license_status',
  ],
  Users: ['Users.count', 'Users.user_id'],
  Lead: [
    'Opportunity.count',
    'Opportunity.opportunity_id',
    'Opportunity.opportunity_number',
    'Opportunity.customer_id',
    'Opportunity.category_id',
    'Opportunity.source_id',
    'Opportunity.lead_owner_user_id',
    'Opportunity.conversion_rate',
    'Opportunity.quote_exists',
    'Opportunity.project_conversion_ratio',
    'Opportunity.source_status',
    'Opportunity.lead_to_quote_turn_on',
    'Opportunity.quote_to_project_turn_on',
    'Opportunity.lead_to_project_turn_on',
    'Opportunity.contact_person',
    'Opportunity.primary_phone',
    'Opportunity.primary_email',
    'Opportunity.description',
    'Opportunity.alternative_phone',
    'Opportunity.alternative_email',
    'Opportunity.status_id',
    'Opportunity.referral_source',
    'Opportunity.type_id',
    'Opportunity.last_name',
    'Opportunity.created_at',
    'Opportunity.modified_at',
    'Opportunity.quote_number',
    'Opportunity.quote_created_date',
    'Opportunity.quote_followup_date',
    'Opportunity.quote_status',
    'Opportunity.quote_to_project_conversion',
    'Opportunity.project_exists',
  ],
  Quotes: [
    'Quotes.count',
    'Quotes.created_at',
    'Quotes.expiry_date',
    'Quotes.unit_price',
    'Quotes.quote_id',
    'Quotes.total_price',
    'Quotes.amount_to_be_paid_after_service',
    'Quotes.quantity',
    'Quotes.discount',
    'Quotes.tax',
    'Quotes.total_price',
    'Quotes.discount_type',
    'Quotes.tax',
    'Quotes.total_price',
    'Quotes.item_desc',
    'Quotes.item_type',
    'Quotes.opportunity_number',
    'Quotes.project_exists',
    'Quotes.quote_to_project_conversion',
    'Quotes.lead_category_id',
    'Quotes.lead_owner_user_id',
    'Quotes.lead_status_id',
    'Quotes.lead_source_status',
  ],
  Chargebacks: [
    'Chargebacks.count',
    'Chargebacks.chargeback_id',
    'Chargebacks.material_chargeback',
    'Chargebacks.labor_chargeback',
    'Chargebacks.total_retail',
    'Chargebacks.total_technician',
    'Chargebacks.payment_count',
    'Chargebacks.payment_amount_in_emi',
    'Chargebacks.payment_amount_balance',
    'Chargebacks.payout_amount',
  ],
  'Chargeback Reasons': [
    'ChargebackReasons.count',
    'ChargebackReasons.chargeback_reason_id',
  ],
  'Chargeback Payouts': [
    'ChargebackPayouts.count',
    'ChargebackPayouts.chargeback_payout_id',
    'ChargebackPayouts.payment_amount',
  ],
  'Chargeback Payment Categories': [
    'ChargebackPaymentCategories.count',
    'ChargebackPaymentCategories.payment_category_id',
  ],
  Retainage: [
    'Retainage.count',
    'Retainage.retainage_id',
    'Retainage.total_amount',
    'Retainage.pay_percent',
    'Retainage.fixed_amount',
    'Retainage.remaining_balance',
    'Retainage.payout_amount',
  ],
  'Retainage Categories': [
    'RetainageCategories.count',
    'RetainageCategories.category_id',
  ],
  'Retainage Payouts': [
    'RetainagePayouts.count',
    'RetainagePayouts.payout_id',
    'RetainagePayouts.payment_amount',
  ],
  'Retainage Types': ['RetainageTypes.count', 'RetainageTypes.type_id'],
  Crews: ['Crews.count', 'Crews.crew_id', 'Crews.capacity'],
  'Crew Types': ['CrewTypes.count', 'CrewTypes.type_id'],
  'Crew Members': ['CrewMembers.count', 'CrewMembers.member_id'],
  'Chargeback Status': ['ChargebackStatus.count', 'ChargebackStatus.status_id'],
  Checklist: [
    'Checklist.count',
    'Checklist.question_id',
    'Checklist.module_id',
    'Checklist.answer',
  ],
};
