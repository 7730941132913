import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Grid,
  TextField,
  Box,
  InputLabel,
  Input,
  InputAdornment,
  Switch,
  FormControlLabel,
  FormHelperText,
  Select,
  MenuItem,
  Button,
  Tooltip,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import {
  LocationOn as LocationOnIcon,
  Search as SearchIcon,
  CheckSharp as CheckIcon,
} from '@material-ui/icons/';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { useJsApiLoader } from '@react-google-maps/api';
import NumberFormat from 'react-number-format';
import InputMask from 'react-input-mask';
// **** Services *****
import { debounce } from 'lodash';
import { Skeleton } from 'primereact/skeleton';

import { useAlerts } from '../../shared/Alerts/alertsService';
import { getStates } from '../../ProjectManagement/Clients/Clients.service';
import { libraries } from '../../../constants.js';
import { REACT_APP_GOOGLE_MAPS_KEY } from '../../../constants/envConstants';

import {
  addNewWorkRoom,
  getDropdownValues,
  getWorkRoomValues,
  searchWorkrooms,
  getActiveInstallers,
  fetchWarehouseList,
} from './Stores.service';
import { useStyles } from './StoreDetailsForm.styles.js';

const StoreDetailsForm = ({ action, loading, setLoading, formik }) => {
  const classes = useStyles({ status: formik?.values?.status?.status });
  const [sourceOptions, setSourceOptions] = useState([]);
  const [states, setStates] = useState([]);
  const [, setAddress] = useState('');
  const { setAlert } = useAlerts();
  const [workRoomOptions, setWorkRoomOptions] = useState([]);
  const [newWorkRoomValue, setNewWorkRoomValue] = useState(null);
  const [installerList, setInstallerList] = useState([]);
  const [warehouseList, setWarehouseList] = useState([]);
  const { techniciansLimitList } = useSelector(
    state => state.techniciansLimitList
  );
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: REACT_APP_GOOGLE_MAPS_KEY,
    libraries,
  });

  const getInstallers = async () => {
    const response = await getActiveInstallers();
    if (response?.length) {
      setInstallerList(response);
    }
  };
  const getWarehouseList = async () => {
    try {
      const response = await fetchWarehouseList();
      if (response && response?.length) {
        setWarehouseList(response);
      }
    } catch (error) {
      console.error(error);
    }
  };
  // To load independent dropdowns values like Type, Source etc.
  useEffect(() => {
    getDropdownValues(setLoading, setSourceOptions);
    getWorkRoomValues(setWorkRoomOptions, '');
    getInstallers();
  }, []);

  useEffect(() => {
    getStates(setStates, setLoading);
    getWarehouseList();
  }, []);

  const handleChange = address => {
    setAddress(address);
    formik.setFieldValue('address', address);
  };
  const handleSelect = address => {
    setAddress(address);
    formik.setFieldValue('address', address);
    geocodeByAddress(address)
      .then(results => {
        getDetailsFromAddress(results[0].address_components);
        return getLatLng(results[0]);
      })
      .then(latLng => {
        if (formik.values.street !== '') {
          formik.setFieldValue('latitude', latLng.lat);
          formik.setFieldValue('longitude', latLng.lng);
        }
      })
      .catch(error => console.error('Error', error));
  };
  const getDetailsFromAddress = addressComponents => {
    let address = {};
    formik.setFieldValue('latitude', '');
    formik.setFieldValue('longitude', '');
    formik.setFieldValue('street', '');
    addressComponents.forEach(function (c) {
      switch (c.types[0]) {
        case 'street_number':
          address.StreetNumber = c.long_name;
          break;
        case 'route':
          address.StreetName = c.long_name;
          break;
        //case 'neighborhood':
        case 'locality':
          address.City = c.long_name;
          break;
        case 'administrative_area_level_1':
          address.State = c.short_name;
          break;
        case 'postal_code':
          address.Zip = c.long_name;
          break;
        case 'country':
          address.Country = c.long_name;
          break;
      }
    });
    formik.setFieldValue(
      'street',
      address.StreetName && address.StreetName
        ? address.StreetNumber + ' ' + address.StreetName
        : ''
    );
    formik.setFieldValue('city', address.City);
    formik.setFieldValue('state', address.State);
    formik.setFieldValue('zip', address.Zip);
  };

  const onDisabled = (searchResult, value, key) => {
    let res = true;
    if (searchResult?.length > 0 && value && typeof value === 'string') {
      if (value.trim() != '') {
        const length = searchResult.filter(
          item => item[key]?.toLowerCase() === value?.toLowerCase()
        ).length;
        if (length === 0) {
          res = false;
        }
      }
    }
    if (searchResult?.length === 0) {
      res = false;
    }
    if (!value) {
      res = true;
    }
    return res;
  };

  const handleWorkroomChange = async event => {
    const searchString = event?.target?.value;
    setNewWorkRoomValue(searchString);
    const workrooms = await searchWorkrooms(searchString);
    if (workrooms && workrooms?.length > 0) setWorkRoomOptions(workrooms);
  };

  const handleSaveWorkRoom = async () => {
    const data = {
      workroom_name: newWorkRoomValue,
    };
    const response = await addNewWorkRoom(data);
    if (response?.data?.status) {
      setAlert('success', response?.data?.message);
      getWorkRoomValues(setWorkRoomOptions, response?.data?.data?.label);
      formik.setFieldValue('type_id', response?.data?.data);
    }
  };

  return (
    <>
      <Grid container spacing={2} direction="column">
        <Box display="flex" flexDirection="row" flexWrap="wrap">
          <Box m={1} className={classes.boxColumn}>
            {loading ? (
              <Skeleton className="w-full" height="44px" />
            ) : (
              <Autocomplete
                id="source"
                name="source"
                required={action === 'edit' || action === 'add'}
                options={sourceOptions || []}
                disableListWrap
                inputProps={{
                  tabIndex: 1,
                }}
                getOptionLabel={option =>
                  option.source_name ? option.source_name : ''
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Source"
                    required={action === 'edit' || action === 'add'}
                    error={formik.touched.source && formik.errors.source}
                    helperText={formik.touched.source && formik.errors.source}
                  />
                )}
                value={formik.values.source}
                onChange={(event, value) => {
                  formik.setFieldValue('source', value);
                  formik.setFieldValue('logo_url', value?.logo_url);
                }}
                onBlur={formik.handleBlur}
                disabled={action === 'view' || !formik.values.is_private}
              />
            )}
          </Box>
          <Box className={classes.boxColumn} m={1}>
            {loading ? (
              <Skeleton className="w-full" height="44px" />
            ) : (
              <>
                <InputLabel shrink>Source Logo</InputLabel>
                <Input
                  id="input-with-icon-adornment"
                  disabled
                  startAdornment={
                    <InputAdornment position="start">
                      {formik.values?.logo_url ? (
                        <img
                          width="40"
                          height="25"
                          src={formik.values?.logo_url}
                          alt="source logo"
                        />
                      ) : (
                        formik.values?.source?.source_name || ''
                      )}
                    </InputAdornment>
                  }
                />
              </>
            )}
          </Box>
          <Box className={classes.boxColumn} m={1}>
            {loading ? (
              <Skeleton className="w-full" height="44px" />
            ) : (
              <TextField
                id="division"
                label="Division"
                InputProps={{
                  readOnly: action === 'view',
                  tabIndex: 2,
                }}
                disabled={action === 'view'}
                value={formik?.values?.division || ''}
                InputLabelProps={{ shrink: true }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            )}
          </Box>
          <Box className={classes.boxColumn} m={1}>
            {loading ? (
              <Skeleton className="w-full" height="44px" />
            ) : (
              <TextField
                id="region"
                label="Region"
                disabled={action === 'view'}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                InputProps={{
                  readOnly: action === 'view',
                }}
                value={formik?.values?.region || ''}
                InputLabelProps={{ shrink: true }}
              />
            )}
          </Box>

          <Box className={classes.smallbox} m={1}>
            {loading ? (
              <Skeleton className="w-full" height="44px" />
            ) : (
              <FormControlLabel
                value="Store Active"
                control={
                  <Switch
                    color="primary"
                    name="is_store_active"
                    id="is_store_active"
                    onChange={(event, value) => {
                      formik.setFieldValue('is_store_active', value);
                    }}
                    onBlur={formik.handleBlur}
                    checked={formik?.values?.is_store_active}
                    InputProps={{
                      readOnly: action === 'view',
                    }}
                    className={classes.switch}
                    disabled={action === 'view'}
                  />
                }
                classes={{
                  label:
                    'MuiFormLabel-root MuiInputLabel-animated MuiInputLabel-shrink',
                  labelPlacementTop: classes.receiveLabel,
                }}
                color="primary"
                label="Store Active"
                labelPlacement="top"
              />
            )}
          </Box>

          <Box className={classes.boxColumn} m={1}>
            {loading ? (
              <Skeleton className="w-full" height="44px" />
            ) : (
              <TextField
                id="district"
                label="District"
                disabled={action === 'view'}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                InputProps={{
                  readOnly: action === 'view',
                }}
                value={formik?.values?.district || ''}
                InputLabelProps={{ shrink: true }}
              />
            )}
          </Box>

          <Box className={classes.boxColumn} m={1}>
            {loading ? (
              <Skeleton className="w-full" height="44px" />
            ) : (
              <NumberFormat
                id="store_number"
                name="store_number"
                inputProps={{ maxLength: 15 }}
                InputLabelProps={{ shrink: true }}
                required={action === 'edit' || action === 'add'}
                disabled={action === 'view' || !formik.values.is_private}
                allowNegative={false}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label={`Store Number`}
                customInput={TextField}
                inputmode="numeric"
                value={formik?.values?.store_number || ''}
                error={
                  formik.touched.store_number && formik.errors.store_number
                }
                helperText={
                  formik.touched.store_number && formik.errors.store_number
                }
              />
            )}
          </Box>

          <Box className={classes.boxExtendedColumn} ml={1} mt={1}>
            {loading ? (
              <Skeleton className="w-full" height="44px" />
            ) : (
              <TextField
                label="Store Name"
                onChange={event => {
                  formik.setFieldValue('store_name', event.target.value);
                }}
                multiline
                maxRows={4}
                disabled={action === 'view' || !formik.values.is_private}
                required={action === 'edit' || action === 'add'}
                value={formik?.values?.store_name || ''}
                InputProps={{
                  readOnly: action === 'view',
                }}
                InputLabelProps={{ shrink: true }}
                error={formik.touched.store_name && formik.errors.store_name}
                helperText={
                  formik.touched.store_name && formik.errors.store_name
                }
              />
            )}
          </Box>
          <Box
            display="flex"
            m={1}
            className={`${classes.boxColumn} mui-custom-autocomplete`}
          >
            <Box width="75%">
              {loading ? (
                <Skeleton className="w-full" height="44px" />
              ) : (
                <Autocomplete
                  id="workRoom"
                  name="workRoom"
                  options={workRoomOptions || []}
                  className={classes.noDropdownIcon}
                  inputProps={{
                    readOnly: action === 'view',
                  }}
                  clearOnBlur={false}
                  getOptionLabel={option => (option.label ? option.label : '')}
                  renderInput={params => (
                    <TextField {...params} label="Workroom" />
                  )}
                  onInputChange={debounce(handleWorkroomChange, 300)}
                  value={formik?.values?.type_id}
                  onChange={(event, value) => {
                    formik.setFieldValue('label', value?.label);
                    formik.setFieldValue('type_id', value);
                  }}
                  disabled={action === 'view'}
                />
              )}
            </Box>
            <Box width="25%" className={classes.boxBtn}>
              {loading ? (
                <Skeleton className="w-full" height="44px" />
              ) : (
                <Tooltip title="Add Workroom" placement="top">
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<CheckIcon fontSize="inherit" />}
                    className={`${classes.iconBtn} border-round-sm`}
                    style={{
                      padding: '0',
                      border: 'none',
                      maxHeight: '46px',
                      minHeight: '46px',
                    }}
                    disabled={onDisabled(
                      workRoomOptions,
                      newWorkRoomValue,
                      'label'
                    )}
                    onClick={handleSaveWorkRoom}
                  />
                </Tooltip>
              )}
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexDirection="row" flexWrap="wrap">
          {action === 'view' && (
            <Box className={classes.boxColumn} m={1}>
              {loading ? (
                <Skeleton className="w-full" height="44px" />
              ) : (
                <TextField
                  label="Address"
                  id="address"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={action === 'view'}
                  InputProps={{
                    readOnly: action === 'view',
                  }}
                  value={formik?.values?.address || ''}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            </Box>
          )}
          {(action === 'edit' || action === 'add') && (
            <Box className={classes.boxColumn} m={1}>
              {isLoaded && (
                <PlacesAutocomplete
                  value={formik?.values?.address}
                  onChange={handleChange}
                  onSelect={handleSelect}
                  //Restricted search to USA
                  searchOptions={{ componentRestrictions: { country: 'us' } }}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <>
                      <TextField
                        {...getInputProps({
                          placeholder: 'Search Places ...',
                          className: 'location-search-input',
                        })}
                        label="Address"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          endAdornment: action === 'view' && (
                            <InputAdornment position="end">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                        placeholder="Search"
                      />
                      <div className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion, i) => {
                          const className = suggestion.active
                            ? 'suggestion-item--active'
                            : 'suggestion-item';
                          const style = suggestion.active
                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                          return (
                            <Grid
                              container
                              alignItems="center"
                              key={i}
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <Grid item>
                                <LocationOnIcon className={classes.icon} />
                              </Grid>
                              <Grid item xs>
                                <span
                                  key={i}
                                  style={{
                                    fontWeight: suggestion.highlight
                                      ? 700
                                      : 400,
                                  }}
                                >
                                  {suggestion.description}
                                </span>
                              </Grid>
                            </Grid>
                          );
                        })}
                      </div>
                    </>
                  )}
                </PlacesAutocomplete>
              )}
            </Box>
          )}
          <Box className={classes.boxColumn} m={1}>
            {loading ? (
              <Skeleton className="w-full" height="44px" />
            ) : (
              <TextField
                label="City"
                id="city"
                onChange={formik.handleChange}
                disabled={action === 'view'}
                onBlur={formik.handleBlur}
                InputProps={{
                  readOnly: action === 'view',
                }}
                required={action === 'edit' || action === 'add'}
                value={formik?.values?.city || ''}
                InputLabelProps={{ shrink: true }}
                error={formik.touched.city && formik.errors.city}
                helperText={formik.touched.city && formik.errors.city}
              />
            )}
          </Box>
          <Box className={classes.boxColumn} m={1}>
            {loading ? (
              <Skeleton className="w-full" height="44px" />
            ) : (
              <>
                <InputLabel
                  required={action === 'edit' || action === 'add'}
                  shrink
                  id="state-label"
                  className={classes.label}
                >
                  State
                </InputLabel>
                <Select
                  labelId="state-label"
                  name="state"
                  value={formik?.values?.state || ''}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  displayEmpty={true}
                  variant="standard"
                  classes={{ select: classes.sourceSelect }}
                  InputProps={{
                    readOnly: action === 'view' ? true : false,
                  }}
                  disabled={action === 'view' ? true : false}
                  error={formik.touched.state && formik.errors.state}
                >
                  <MenuItem disabled value="">
                    Select
                  </MenuItem>
                  {states.map((state, index) => (
                    <MenuItem key={index} value={state.state_code}>
                      {state.state_name}
                    </MenuItem>
                  ))}
                </Select>
              </>
            )}
            {formik.touched.state && formik.errors.state && (
              <FormHelperText error>{formik.errors.state}</FormHelperText>
            )}
          </Box>
          <Box className={classes.boxColumn} m={1}>
            {loading ? (
              <Skeleton className="w-full" height="44px" />
            ) : (
              <NumberFormat
                id="zip"
                name="zip"
                required={action === 'edit' || action === 'add'}
                inputProps={{ maxLength: 10 }}
                allowNegative={false}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label={`Zip`}
                customInput={TextField}
                inputmode="numeric"
                disabled={action === 'view'}
                value={formik?.values?.zip || ''}
                error={formik.touched.zip && formik.errors.zip}
                helperText={formik.touched.zip && formik.errors.zip}
              />
            )}
          </Box>

          <Box className={classes.boxColumn} m={1}>
            {loading ? (
              <Skeleton className="w-full" height="44px" />
            ) : (
              <InputMask
                mask="(999) 999-9999"
                value={formik?.values?.phone_number || ''}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                maskChar=" "
                placeholder=""
                disabled={action === 'view'}
                InputProps={{
                  readOnly: action === 'view',
                }}
              >
                {() => (
                  <TextField
                    name={`phone_number`}
                    label="Phone Number"
                    InputLabelProps={{ shrink: true }}
                    error={
                      formik.touched.phone_number && formik.errors.phone_number
                    }
                    helperText={
                      formik.touched.phone_number && formik.errors.phone_number
                    }
                  />
                )}
              </InputMask>
            )}
          </Box>

          <Box className={classes.boxColumn} m={1}>
            {loading ? (
              <Skeleton className="w-full" height="44px" />
            ) : (
              <NumberFormat
                id="latitude"
                name="latitude"
                inputProps={{ maxLength: 20 }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label={`Latitude`}
                customInput={TextField}
                inputmode="numeric"
                disabled={action === 'view'}
                value={formik?.values?.latitude || ''}
              />
            )}
          </Box>

          <Box className={classes.boxColumn} m={1}>
            {loading ? (
              <Skeleton className="w-full" height="44px" />
            ) : (
              <NumberFormat
                id="longitude"
                name="longitude"
                inputProps={{ maxLength: 20 }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label={`Longitude`}
                customInput={TextField}
                inputmode="numeric"
                disabled={action === 'view'}
                value={formik?.values?.longitude || ''}
              />
            )}
          </Box>

          <Box className={classes.boxColumn} m={1}>
            {loading ? (
              <Skeleton className="w-full" height="44px" />
            ) : (
              <TextField
                label="PSE Name"
                id="pse_name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                InputProps={{
                  readOnly: action === 'view',
                }}
                disabled={action === 'view'}
                value={formik?.values?.pse_name || ''}
                InputLabelProps={{ shrink: true }}
              />
            )}
          </Box>

          <Box className={classes.boxColumn} m={1}>
            {loading ? (
              <Skeleton className="w-full" height="44px" />
            ) : (
              <TextField
                label="PSE Email"
                id="pse_email"
                type="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={action === 'view'}
                InputProps={{
                  readOnly: action === 'view',
                }}
                value={formik?.values?.pse_email || ''}
                InputLabelProps={{ shrink: true }}
              />
            )}
          </Box>

          {formik?.values?.source?.source_name === 'Home Depot' ? (
            <Box className={classes.boxColumn} m={1}>
              <TextField
                label="Phone Area City Code"
                id="phone_area_city_code"
                disabled={action === 'view'}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                InputProps={{
                  readOnly: action === 'view',
                }}
                value={formik?.values?.phone_area_city_code || ''}
                InputLabelProps={{ shrink: true }}
              />
            </Box>
          ) : (
            ''
          )}
          <Box m={1} className={classes.boxColumn}>
            {loading ? (
              <Skeleton className="w-full" height="44px" />
            ) : (
              <Autocomplete
                id="installer_id"
                name="installer_id"
                options={techniciansLimitList || []}
                disableListWrap
                inputProps={{
                  tabIndex: 1,
                }}
                getOptionLabel={option =>
                  option?.first_name
                    ? `${option.first_name} ${option?.last_name}`
                    : ''
                }
                renderInput={params => (
                  <TextField {...params} label="Default Technician" />
                )}
                value={
                  installerList?.find(installer => {
                    return (
                      installer?.installer_id === formik?.values?.installer_id
                    );
                  }) || ''
                }
                onChange={(event, value) => {
                  formik.setFieldValue('installer_id', value?.installer_id);
                }}
                onBlur={formik.handleBlur}
                disabled={action === 'view'}
              />
            )}
          </Box>

          <Box m={1} className={classes.boxColumn}>
            {loading ? (
              <Skeleton className="w-full" height="44px" />
            ) : (
              <Autocomplete
                id="master_warehouse_id"
                name="master_warehouse_id"
                options={warehouseList || []}
                disableListWrap
                inputProps={{
                  tabIndex: 1,
                }}
                getOptionLabel={option =>
                  option?.name ? `${option.name}` : ''
                }
                renderInput={params => (
                  <TextField {...params} label="Warehouse" />
                )}
                value={
                  warehouseList?.find(warehouse => {
                    return (
                      warehouse?.master_warehouse_id ===
                      formik?.values?.master_warehouse_id
                    );
                  }) || ''
                }
                onChange={(event, value) => {
                  formik.setFieldValue(
                    'master_warehouse_id',
                    value?.master_warehouse_id
                  );
                }}
                onBlur={formik.handleBlur}
                disabled={action === 'view'}
              />
            )}
          </Box>
        </Box>
      </Grid>
    </>
  );
};

export default StoreDetailsForm;
