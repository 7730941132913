import React, { useEffect, useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { Skeleton } from 'primereact/skeleton';
import { BlockUI } from 'primereact/blockui';

import PFEditor from '../../shared/PFPrime/PFEditor';
import { MyQuillToolbar } from '../Helper/Helper';
import { putEditPageMetaInfo } from '../services/PageMeta.services';

const EditPageMeta = ({
  visible,
  onCancelBtnClickHandler,
  onSave,
  rowData,
}) => {
  const { header } = rowData || {};
  const [isLoading, setIsLoading] = useState(true);
  const [blocked, setBlocked] = useState(false);
  const toast = useRef(null);
  const rowInfo = useRef(null);

  const formik = useFormik({
    initialValues: {
      page_identifier: '',
      title: '',
      description: '',
    },

    onSubmit: async values => {
      const { description, ...rest } = values;
      values = rest;

      values.description = description || '';

      values.description = encodeURI(values.description);
      setBlocked(true);
      try {
        const response = await putEditPageMetaInfo(values);
        onSave(rowData, response.data);
        if (response?.data?.status) {
          toast.current.show({
            severity: 'success',
            summary: 'Record Edited Successfully',
            life: 1500,
          });
          setBlocked(false);
          return;
        } else {
          toast.current.show({
            severity: 'error',
            summary: 'Error occurred while editing record',
            life: 1500,
          });
          setBlocked(false);
          return;
        }
      } catch (error) {
        setBlocked(false);
        toast.current.show({
          severity: 'error',
          detail: 'Something Went Wrong ',
          life: 3000,
        });
        throw error;
      }
    },
  });

  useEffect(() => {
    setIsLoading(true);
    if (rowData.page_identifier) {
      rowInfo.current = rowData;
      if (
        rowInfo.current.page_identifier &&
        rowInfo.current.page_identifier.length > 0
      ) {
        formik.setValues(prevValues => ({
          ...prevValues,
          page_identifier: rowInfo.current.page_identifier,
          title: rowInfo.current.header,
          description: decodeURI(rowInfo.current.description),
        }));
      }
    }
    setIsLoading(false);
  }, [rowData, rowInfo]);

  const dialogFooter = (
    <div>
      {!blocked && (
        <Button
          size="small"
          label="Cancel"
          severity="danger"
          type="submit"
          outlined
          onClick={onCancelBtnClickHandler}
          className="p-button p-button-outlined p-button-danger"
        />
      )}
      <Button
        label={blocked ? 'Saving' : 'Save'}
        icon={blocked && 'pi pi-spin pi-spinner'}
        disabled={!formik.dirty || blocked}
        type="submit"
        size="small"
        onClick={formik.handleSubmit}
      />
    </div>
  );

  return (
    <>
      <BlockUI blocked={blocked} className="opacity-30">
        <Dialog
          header={` ${header}- User Guide`}
          visible={visible}
          style={{ width: '50vw' }}
          onHide={onCancelBtnClickHandler}
          footer={dialogFooter}
          className="p-dialog-maximized"
          pt={{
            content: {
              style: { backgroundColor: 'var(--lightgrey)' },
            },
            footer: {
              style: { backgroundColor: 'var(--lightgrey)' },
            },
            header: {
              style: { backgroundColor: 'var(--lightgrey)' },
            },
          }}
        >
          <Toast ref={toast} />
          {isLoading ? (
            <Skeleton className="w-full h-full"></Skeleton>
          ) : (
            <div className="formgrid grid m-2">
              <form onSubmit={formik.handleSubmit} className="w-12 m-1">
                <div className="field col-12 md:col-4 lg:col-4 w-full">
                  <span className="p-float-label">
                    <PFEditor
                      headerTemplate={MyQuillToolbar()}
                      style={{ height: '25rem' }}
                      placeholder="Enter Your Description Here"
                      value={formik.values.description}
                      onTextChange={newValue => {
                        formik.setFieldValue(
                          'description',
                          newValue.htmlValue || ''
                        );
                      }}
                    />
                  </span>
                </div>
              </form>
            </div>
          )}
        </Dialog>
      </BlockUI>
    </>
  );
};

export default EditPageMeta;
