import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card } from 'primereact/card';
import { AutoComplete } from 'primereact/autocomplete';

import PFButton from '../../shared/PFPrime/PFButton';
import PFDropdown from '../../shared/PFPrime/PFDropdown';
import { DateCriteria } from '../../../constants';
import PFCalendar from '../../shared/PFPrime/PFCalendar';
import PFAutoComplete from '../../shared/PFPrime/PFAutoComplete.jsx';
import { dateFormatter, formatCurrency } from '../../../utils/Helpers.js';
import SkeletonLoader from '../../shared/Loader/skeleton.jsx';

import {
  getSearchDropdownOptions,
  searchCompanyName,
} from './services/PayrollService';

const SearchCard = ({
  setSearchString,
  searchString,
  fetchSearchResult,
  searchResult,
  isLoading,
  setRevAppExcSearchObj,
  setSearchResult,
  refreshDropdowns,
  setRefreshDropdowns,
  setDownloadSearch,
  setReportSearchString,
  setPaginationPage,
  setPreviousTechnician,
}) => {
  const [workRoomOptions, setWorkroomOptions] = React.useState([]);
  const [filteredWorkroomOptions, setFilteredWorkroomOptions] = React.useState(
    []
  );
  const [storeOptions, setStoreOptions] = React.useState([]);
  const [filteredStoreOptions, setFilteredStoreOptions] = React.useState([]);
  const [categoryOptions, setCategoryOptions] = React.useState([]);
  const [filteredCategoryOptions, setFilteredCategoryOptions] = React.useState(
    []
  );
  const [typeOptions, setTypeOptions] = React.useState([]);
  const [filteredTypeOptions, setFilteredTypeOptions] = React.useState([]);
  const [statusOptions, setStatusOptions] = React.useState([]);
  const [filteredStatusOptions, setFilteredStatusOptions] = React.useState([]);

  const [filteredTechnicianOptions, setFilteredTechnicianOptions] =
    React.useState([]);
  const [technicianTypeOptions, setTechnicianTypeOptions] = React.useState([]);
  const [filteredTechnicianTypeOptions, setFilteredTechnicianTypeOptions] =
    React.useState([]);
  const [companySearchResults, setCompanySearchResults] = useState([]);
  const [companyName, setCompanyName] = useState(null);
  const { techniciansLimitList } = useSelector(
    state => state.techniciansLimitList
  );

  const workRoomRef = useRef(null);
  const storeRef = useRef(null);
  const categoryRef = useRef(null);
  const typeRef = useRef(null);
  const statusRef = useRef(null);
  const technicianRef = useRef(null);
  const technicianTypeRef = useRef(null);
  const companyRef = useRef(null);

  const suggestCompanyName = async (event = null) => {
    const searchCompanyNameResponse = await searchCompanyName(
      event?.query || ''
    );
    setCompanySearchResults(searchCompanyNameResponse);
  };

  const displayCompanyRef = () => {
    suggestCompanyName();
    if (companyRef.current) {
      companyRef.current.show();
    }
  };
  const handleOnChange = async value => {
    setSearchString(prevState => ({
      ...prevState,
      ['companyId']: value['Companies.company_id'],
    }));
    let filterObj = { ['companyName']: value['Companies.company_name'] };

    setReportSearchString(prevState => ({
      ...prevState,
      ['companyId']: value['Companies.company_id'],
      filter: { ...prevState?.filter, ...filterObj },
    }));

    setCompanyName(value || '');
  };

  // To fetch initial values of all search filter dropdowns
  const fetchInitialValues = async () => {
    let response = await getSearchDropdownOptions();
    setRefreshDropdowns(false);
    setWorkroomOptions(response?.workRoomList);
    setStoreOptions(response?.storeNoList);
    setCategoryOptions(response?.categoryList);
    setTypeOptions(response?.typeList);
    setStatusOptions(response?.statusList);
    setTechnicianTypeOptions(response?.installerTypeList);
  };

  //Helper to populate options as per entered value
  const search = (event, options, label, setFilteredOptions) => {
    setTimeout(() => {
      let _filteredOptions;

      if (!event.query.trim().length) {
        _filteredOptions = [...options];
      } else {
        _filteredOptions = options.filter(opt => {
          return (
            opt[label].toLowerCase()?.indexOf(event.query.toLowerCase()) !== -1
          );
        });
      }
      setFilteredOptions(_filteredOptions);
    }, 250);
  };

  // Helper to show options on focus
  const handleFocus = field => {
    if (field.current) {
      field.current.show();
    }
  };

  const technicianTemplate = item => {
    return (
      <div className="flex align-items-center">
        {item.first_name} {item.last_name}
      </div>
    );
  };

  // Helper to set search parameter
  const setSearchQuery = (field, value, string = '') => {
    if (value) {
      setSearchString(prevState => ({
        ...prevState,
        [field]: value,
      }));
      let filterObj = {};
      if (!['dateFrom', 'dateTo', 'companyId'].includes(field)) {
        filterObj = { [field]: string };
      }
      setReportSearchString(prevState => ({
        ...prevState,
        [field]: value,
        filter: { ...prevState?.filter, ...filterObj },
      }));
    } else {
      if (field === 'technician') {
        setPreviousTechnician(searchString?.[field]);
      }
      setSearchString(prevState => ({
        ...prevState,
        [field]: null,
      }));
      setReportSearchString(prevState => ({
        ...prevState,
        [field]: null,
        filter: { ...prevState.filter, [field]: null },
      }));
    }
  };

  React.useEffect(() => {
    if (refreshDropdowns) fetchInitialValues();
  }, [refreshDropdowns]);

  const cardData = searchResult?.masterCalculation;

  return (
    <>
      <Card
        className="w-full border-noround"
        pt={{ content: { className: 'p-2' }, body: { className: 'p-2' } }}
      >
        <div className="flex justify-content-between align-items-center">
          <div className="font-bold text-900 pl-2">Search Criteria</div>
          <div className="flex gap-2">
            <PFButton
              label="Clear All"
              severity="primary"
              outlined
              onClick={() => {
                setSearchString({ offset: 0 });
                setReportSearchString({});
                setSearchResult([]);
                setDownloadSearch('');
                setCompanyName(null);
              }}
              disabled={
                !searchString?.searchBy ||
                !searchString?.dateFrom ||
                !searchString?.dateTo ||
                isLoading
              }
            />
            <PFButton
              label="Apply"
              severity="primary"
              disabled={
                !searchString?.searchBy ||
                !searchString?.dateFrom ||
                !searchString?.dateTo ||
                isLoading
              }
              onClick={() => {
                if (searchString?.technician === null) {
                  setPreviousTechnician('');
                }
                setPaginationPage(1);
                fetchSearchResult('', '', true);
                setSearchResult();
              }}
            />
          </div>
        </div>
        <div className="flex flex-wrap">
          <div className="col-3">
            <span className="p-float-label">
              <PFDropdown
                optionLabel="value"
                optionValue="key"
                placeholder="Select Date Criteria"
                options={DateCriteria}
                onChange={(name, value) => {
                  let stringValue = DateCriteria?.find(
                    val => val?.key === value
                  )?.value;
                  setSearchQuery('searchBy', value, stringValue);
                  setRevAppExcSearchObj(prevState => ({
                    ...prevState,
                    searchBy: value,
                  }));
                }}
                value={searchString?.searchBy}
                name="date_criteria"
                className={'w-12'}
              />
              <label htmlFor="date_criteria">
                Date Criteria<i className="text-red-400">*</i>
              </label>
            </span>
          </div>
          <div className="col-3">
            <span className="p-float-label">
              <PFCalendar
                id="date_from"
                name="date_from"
                className="w-12"
                onChange={e => {
                  setSearchQuery(
                    'dateFrom',
                    dateFormatter(e?.target?.value, 'YYYY-MM-DD'),
                    dateFormatter(e?.target?.value, 'YYYY-MM-DD')
                  );
                  setRevAppExcSearchObj(prevState => ({
                    ...prevState,
                    dateFrom: dateFormatter(e?.target?.value, 'YYYY-MM-DD'),
                  }));
                }}
                dateFormat={'mm/dd/yy'}
                maxDate={
                  searchString?.dateTo
                    ? new Date(
                        dateFormatter(searchString?.dateTo, 'MM/DD/YYYY')
                      )
                    : null
                }
                value={
                  searchString?.dateFrom
                    ? new Date(
                        dateFormatter(searchString?.dateFrom, 'MM/DD/YYYY')
                      )
                    : null
                }
                pt={{
                  input: {
                    root: { className: 'p-inputtext-sm' },
                  },
                }}
              />
              <label htmlFor="date_from">
                Date From<i className="text-red-400">*</i>
              </label>
            </span>
          </div>
          <div className="col-3">
            <span className="p-float-label">
              <PFCalendar
                id="date_to"
                name="date_to"
                className="w-12"
                onChange={e => {
                  setSearchQuery(
                    'dateTo',
                    dateFormatter(e?.target?.value, 'YYYY-MM-DD'),
                    dateFormatter(e?.target?.value, 'YYYY-MM-DD')
                  );
                  setRevAppExcSearchObj(prevState => ({
                    ...prevState,
                    dateTo: dateFormatter(e?.target?.value, 'YYYY-MM-DD'),
                  }));
                }}
                dateFormat={'mm/dd/yy'}
                minDate={
                  searchString?.dateFrom
                    ? new Date(
                        dateFormatter(searchString?.dateFrom, 'MM/DD/YYYY')
                      )
                    : null
                }
                value={
                  searchString?.dateTo
                    ? new Date(
                        dateFormatter(searchString?.dateTo, 'MM/DD/YYYY')
                      )
                    : null
                }
                pt={{
                  input: {
                    root: { className: 'p-inputtext-sm' },
                  },
                }}
              />
              <label htmlFor="date_to">
                Date To<i className="text-red-400">*</i>
              </label>
            </span>
          </div>
        </div>
        <div className="flex flex-wrap">
          <div className="col-2">
            <span className="p-float-label">
              <PFAutoComplete
                refs={workRoomRef}
                suggestions={filteredWorkroomOptions}
                completeMethod={e =>
                  search(
                    e,
                    workRoomOptions,
                    'label',
                    setFilteredWorkroomOptions
                  )
                }
                field="label"
                onChange={e => {
                  if (e?.target?.value?.type_id) {
                    setSearchQuery(
                      'workroom',
                      e?.target?.value?.type_id,
                      e?.target?.value?.label
                    );
                  } else {
                    setSearchQuery('workroom', e?.target?.value, '');
                  }
                }}
                value={
                  workRoomOptions?.find(
                    opt => opt?.type_id === searchString?.workroom
                  ) || searchString?.workroom
                }
                onFocus={() => handleFocus(workRoomRef)}
                id="workroom"
                pt={{
                  root: {
                    className: 'w-full',
                  },
                }}
              />
              <label htmlFor="workroom">Workroom</label>
            </span>
          </div>
          <div className="col-2">
            <span className="p-float-label">
              <PFAutoComplete
                refs={storeRef}
                suggestions={filteredStoreOptions}
                completeMethod={e =>
                  search(e, storeOptions, 'store_name', setFilteredStoreOptions)
                }
                onChange={e =>
                  e?.target?.value?.store_id
                    ? setSearchQuery(
                        'store',
                        e?.target?.value?.store_id,
                        e?.target?.value?.store_name
                      )
                    : setSearchQuery('store', e?.target?.value, '')
                }
                field="store_name"
                value={
                  storeOptions?.find(
                    opt => opt?.store_id === searchString?.store
                  ) || searchString?.store
                }
                onFocus={() => handleFocus(storeRef)}
                id="storeNumber"
                pt={{
                  root: {
                    className: 'w-full',
                  },
                }}
              />
              <label htmlFor="storeNumber">Store Name</label>
            </span>
          </div>
          <div className="col-2">
            <span className="p-float-label">
              <PFAutoComplete
                refs={categoryRef}
                suggestions={filteredCategoryOptions}
                completeMethod={e =>
                  search(
                    e,
                    categoryOptions,
                    'category',
                    setFilteredCategoryOptions
                  )
                }
                onChange={e =>
                  e?.target?.value?.project_category_id
                    ? setSearchQuery(
                        'category',
                        e?.target?.value?.project_category_id,
                        e?.target?.value?.category
                      )
                    : setSearchQuery('category', e?.target?.value, '')
                }
                field="category"
                value={
                  categoryOptions?.find(
                    opt => opt?.project_category_id === searchString?.category
                  ) || searchString?.category
                }
                onFocus={() => handleFocus(categoryRef)}
                id="category"
                pt={{
                  root: {
                    className: 'w-full',
                  },
                }}
              />
              <label htmlFor="category">Category</label>
            </span>
          </div>
          <div className="col-2">
            <span className="p-float-label">
              <PFAutoComplete
                refs={typeRef}
                suggestions={filteredTypeOptions}
                completeMethod={e =>
                  search(e, typeOptions, 'project_type', setFilteredTypeOptions)
                }
                onChange={e =>
                  e?.target?.value?.project_type_id
                    ? setSearchQuery(
                        'type',
                        e?.target?.value?.project_type_id,
                        e?.target?.value?.project_type
                      )
                    : setSearchQuery('type', e?.target?.value, '')
                }
                field="project_type"
                value={
                  typeOptions?.find(
                    opt => opt?.project_type_id === searchString?.type
                  ) || searchString?.type
                }
                onFocus={() => handleFocus(typeRef)}
                id="type"
                pt={{
                  root: {
                    className: 'w-full',
                  },
                }}
              />
              <label htmlFor="type">Type</label>
            </span>
          </div>
          <div className="col-2">
            <span className="p-float-label">
              <PFAutoComplete
                refs={statusRef}
                suggestions={filteredStatusOptions}
                completeMethod={e =>
                  search(e, statusOptions, 'label', setFilteredStatusOptions)
                }
                field="label"
                onChange={e =>
                  e?.target?.value?.value
                    ? setSearchQuery(
                        'status',
                        e?.target?.value?.value,
                        e?.target?.value?.label
                      )
                    : setSearchQuery('status', e?.target?.value, '')
                }
                value={
                  statusOptions?.find(
                    opt => opt?.value === searchString?.status
                  ) || searchString?.status
                }
                onFocus={() => handleFocus(statusRef)}
                id="status"
                pt={{
                  root: {
                    className: 'w-full',
                  },
                }}
              />
              <label htmlFor="status">Status</label>
            </span>
          </div>
          <div className="col-2">
            <span className="p-float-label">
              <PFAutoComplete
                refs={technicianRef}
                itemTemplate={technicianTemplate}
                suggestions={filteredTechnicianOptions}
                completeMethod={e =>
                  search(
                    e,
                    techniciansLimitList,
                    'first_name',
                    setFilteredTechnicianOptions
                  )
                }
                onChange={e =>
                  e?.target?.value?.user_id
                    ? setSearchQuery(
                        'technician',
                        e?.target?.value?.user_id,
                        e?.target?.value?.first_name
                      )
                    : setSearchQuery('technician', e?.target?.value)
                }
                field="full_name"
                value={
                  techniciansLimitList?.find(
                    opt => opt?.user_id === searchString?.technician
                  ) || searchString?.technician
                }
                onFocus={() => handleFocus(technicianRef)}
                id="technician"
                pt={{
                  root: {
                    className: 'w-full',
                  },
                }}
              />
              <label htmlFor="technician">Technician</label>
            </span>
          </div>
          <div className="col-2">
            <span className="p-float-label">
              <PFAutoComplete
                refs={technicianTypeRef}
                suggestions={filteredTechnicianTypeOptions}
                completeMethod={e =>
                  search(
                    e,
                    technicianTypeOptions,
                    'worktype',
                    setFilteredTechnicianTypeOptions
                  )
                }
                onChange={e =>
                  e?.target?.value?.worktypeid
                    ? setSearchQuery(
                        'technicianType',
                        e?.target?.value?.worktypeid,
                        e?.target?.value?.worktype
                      )
                    : setSearchQuery('technicianType', e?.target?.value)
                }
                field="worktype"
                value={
                  technicianTypeOptions?.find(
                    opt => opt?.worktypeid === searchString?.technicianType
                  ) || searchString?.technicianType
                }
                onFocus={() => handleFocus(technicianTypeRef)}
                id="technicianType"
                pt={{
                  root: {
                    className: 'w-full',
                  },
                }}
              />
              <label htmlFor="technicianType"> Technician Type</label>
            </span>
          </div>
          <div className="col-2">
            <span className="p-float-label">
              <AutoComplete
                ref={companyRef}
                id="companyname"
                suggestions={companySearchResults}
                field="Companies.company_name"
                completeMethod={suggestCompanyName}
                value={companyName || ''}
                onChange={e => handleOnChange(e.value)}
                onFocus={displayCompanyRef}
                className="w-full"
              />
              <label className="z-5" htmlFor="companyname">
                Company Name
              </label>
            </span>
          </div>
        </div>
      </Card>
      {searchResult?.data?.length ? (
        <div className="flex flex-wrap mt-4 gap-3 align-items-stretch w-full">
          <Card
            className="flex border-noround h-6rem justify-content-center align-items-center flex-grow-1  boldFontFamily font-bold"
            pt={{ content: { className: 'p-0' }, body: { className: 'p-0' } }}
          >
            <div className="text-900 text-sm">Labor Retail $</div>
            <div className="text-primary flex justify-content-center text-base">
              {isLoading ? (
                <SkeletonLoader columnCount={1} columnWidth="5rem" />
              ) : (
                formatCurrency(cardData?.total_labor_retail || 0)
              )}
            </div>
          </Card>
          <Card
            className="flex  border-noround h-6rem justify-content-center align-items-center flex-grow-1  boldFontFamily font-bold"
            pt={{ content: { className: 'p-0' }, body: { className: 'p-0' } }}
          >
            <div className="text-sm text-900">Labor Cost $</div>
            <div className="text-base text-primary flex justify-content-center">
              {isLoading ? (
                <SkeletonLoader columnCount={1} columnWidth="5rem" />
              ) : (
                formatCurrency(cardData?.total_labor_cost || 0)
              )}
            </div>
          </Card>
          <Card
            className="flex  border-noround h-6rem justify-content-center align-items-center flex-grow-1  boldFontFamily font-bold"
            pt={{ content: { className: 'p-0' }, body: { className: 'p-0' } }}
          >
            <div className="text-sm text-900">Labor Profit</div>
            <div className="text-base text-primary flex justify-content-center">
              {isLoading ? (
                <SkeletonLoader columnCount={1} columnWidth="5rem" />
              ) : (
                formatCurrency(cardData?.total_profit || 0)
              )}
            </div>
          </Card>
          <Card
            className="flex  border-noround h-6rem justify-content-center align-items-center flex-grow-1  boldFontFamily font-bold"
            pt={{ content: { className: 'p-0' }, body: { className: 'p-0' } }}
          >
            <div className="text-sm text-900">Labor Margin $</div>
            <div className="text-base text-primary flex justify-content-center">
              {isLoading ? (
                <SkeletonLoader columnCount={1} columnWidth="5rem" />
              ) : (
                `${cardData?.total_margin ? parseFloat(cardData?.total_margin)?.toFixed(2) : 0} %`
              )}
            </div>
          </Card>
          <Card
            className="flex border-noround h-6rem justify-content-center align-items-center flex-grow-1  boldFontFamily font-bold"
            pt={{ content: { className: 'p-0' }, body: { className: 'p-0' } }}
          >
            <div className="text-sm text-900">Debit Chargeback $</div>
            <div className="text-base text-primary flex justify-content-center">
              {isLoading ? (
                <SkeletonLoader columnCount={1} columnWidth="5rem" />
              ) : (
                `-${formatCurrency(cardData?.total_chargeback_debit || 0)}`
              )}
            </div>
          </Card>
          <Card
            className="flex  border-noround h-6rem justify-content-center align-items-center flex-grow-1  boldFontFamily font-bold"
            pt={{ content: { className: 'p-0' }, body: { className: 'p-0' } }}
          >
            <div className="text-sm text-900">Credit Chargeback $</div>
            <div className="text-base text-primary flex justify-content-center">
              {isLoading ? (
                <SkeletonLoader columnCount={1} columnWidth="5rem" />
              ) : (
                formatCurrency(cardData?.total_chargeback_credit || 0)
              )}
            </div>
          </Card>
          <Card
            className="flex  border-noround h-6rem justify-content-center align-items-center flex-grow-1  boldFontFamily font-bold"
            pt={{ content: { className: 'p-0' }, body: { className: 'p-0' } }}
          >
            <div className="text-sm text-900">Retainage $</div>
            <div className="text-base text-primary flex justify-content-center">
              {isLoading ? (
                <SkeletonLoader columnCount={1} columnWidth="5rem" />
              ) : (
                `-${formatCurrency(cardData?.total_retainage || 0)}`
              )}
            </div>
          </Card>
          <Card
            className="flex border-noround h-6rem justify-content-center align-items-center flex-grow-1  boldFontFamily font-bold"
            pt={{ content: { className: 'p-0' }, body: { className: 'p-0' } }}
          >
            <div className="text-900 text-sm">Net Pay $</div>
            <div className="text-primary flex justify-content-center text-base">
              {isLoading ? (
                <SkeletonLoader columnCount={1} columnWidth="5rem" />
              ) : (
                formatCurrency(cardData?.total_net_pay || 0)
              )}
            </div>
          </Card>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default SearchCard;
