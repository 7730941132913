import React, { FC, useEffect, useState, useRef } from 'react';
import { debounce } from 'lodash';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

import PFCheckbox from '../../../shared/PFPrime/PFCheckbox';
import PFGoogleMap from '../../../shared/PFPrime/PFGoogleMap';
import PFTableLoader from '../../../shared/Loader/PFTableLoader';
import {
  addCustomerAddress,
  getCustomerAddress,
  changeCustomerPrimaryAddress,
  deleteAddress,
} from '../services/customer.service';
import { useConfirmDialogContext } from '../../../../contexts/ConfirmDialog';

import {
  CustomerAddressMapping,
  ApiResponse,
  gMapAddress,
  Props,
} from './customer-address.interface';

const CustomerAddress: FC<Props> = ({ customerId, customerInfo }) => {
  const toast = useRef<Toast>(null);
  const [data, setData] = useState<ApiResponse>({ data: [], total: 0 });
  const [loading, setLoading] = useState(false);
  const [saveBtnLoading, setSaveBtnLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 0,
    limit: 10,
    search: '',
  });
  const { showConfirmationDialog } = useConfirmDialogContext();

  const { gMapFieldLayout, address: gmapAddress, setAddress } = PFGoogleMap();

  const addressSearch = debounce((searchTerm: string) => {
    setPagination(prev => ({ ...prev, page: 0, search: searchTerm }));
  }, 500);

  const fetchAddresses = async () => {
    setLoading(true);
    try {
      const result = await getCustomerAddress({ ...pagination }, customerId);
      setData(result);
    } catch (error) {
      console.error(error);
      toast.current?.show({
        severity: 'error',
        detail: 'Failed to fetch addresses.',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAddresses();
  }, [pagination, customerId]);

  const onPageChange = (event: { page?: number; rows?: number }) => {
    setPagination(prev => ({
      ...prev,
      page: event.page ?? pagination?.page,
      limit: event.rows ?? pagination?.limit,
    }));
  };

  const storeAddress = async (body: gMapAddress) => {
    setSaveBtnLoading(true);
    try {
      const result = await addCustomerAddress(
        {
          address1:
            `${body.addressLineOne} ${body.addressLineTwo || ''}`.trim(),
          city: body.city,
          state: body.stateCode,
          zipcode: body.zipcode,
          latitude: body.latitude,
          longitude: body.longitude,
        },
        customerId
      );

      if (result?.status) {
        toast.current?.show({
          severity: 'success',
          detail: result?.message,
        });
        setAddress('');
      }
    } catch (error) {
      console.error(error);
      toast.current?.show({
        severity: 'error',
        detail: 'Failed to add address.',
      });
    } finally {
      fetchAddresses();
      setSaveBtnLoading(false);
    }
  };

  const updatePrimaryAddress = async (cust_add_mapping_id: number) => {
    setLoading(true);
    try {
      const response = await changeCustomerPrimaryAddress(
        { cust_add_mapping_id },
        customerId
      );

      if (response.status) {
        toast.current?.show({
          severity: 'success',
          detail: response.message,
        });
        fetchAddresses();
        customerInfo(customerId);
      } else {
        toast.current?.show({
          severity: 'error',
          detail: response.message,
        });
      }
    } catch (error) {
      console.error(error);
      toast.current?.show({
        severity: 'error',
        detail: 'Failed to update primary address.',
      });
    } finally {
      setLoading(false);
    }
  };

  const handlePrimaryChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    rowData: CustomerAddressMapping
  ) => {
    if (event.target.checked) {
      updatePrimaryAddress(rowData.cust_add_mapping_id);
    }
  };

  const showConfirmDialog = (
    event: React.ChangeEvent<HTMLInputElement>,
    rowData: CustomerAddressMapping
  ) => {
    showConfirmationDialog({
      message: 'Are you sure you want to change the primary address?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        handlePrimaryChange(event, rowData);
      },
      reject: () => false,
    });
  };

  const deleteCustomerAddress = async (cust_add_mapping_id: number) => {
    if (!cust_add_mapping_id) {
      toast.current?.show({
        severity: 'error',
        detail: 'Invalid address ID.',
      });
      return;
    }

    setLoading(true);
    try {
      const response = await deleteAddress(cust_add_mapping_id);

      if (response?.status) {
        toast.current?.show({
          severity: 'success',
          detail: response.message,
        });
        fetchAddresses();
      } else {
        toast.current?.show({
          severity: 'error',
          detail:
            response?.message ||
            `You cannot delete this address because it is currently associated with an active order.`,
        });
      }
    } catch (error) {
      console.error(error);
      toast.current?.show({
        severity: 'error',
        detail: 'Failed to delete address.',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = (rowData: CustomerAddressMapping) => {
    deleteCustomerAddress(rowData?.cust_add_mapping_id);
  };

  const deleteUserAddress = (rowData: CustomerAddressMapping) => {
    showConfirmationDialog({
      message: 'Are you sure you want to delete the address?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        handleDelete(rowData);
      },
      reject: () => false,
    });
  };

  const addressTable = () => (
    <DataTable
      value={data?.data ?? []}
      paginator
      lazy
      totalRecords={data?.total ?? 0}
      first={pagination.page * pagination.limit}
      rows={pagination.limit}
      rowsPerPageOptions={[10, 25, 50]}
      loading={loading}
      onPage={onPageChange}
      tableStyle={{ minWidth: '50rem' }}
    >
      <Column
        header="Actions"
        style={{ width: '2%' }}
        body={rowData => (
          <>
            {!rowData?.is_primary && (
              <Button
                icon="pi pi-trash"
                rounded
                text
                aria-label="Delete"
                size="small"
                type="button"
                onClick={() => deleteUserAddress(rowData)}
              />
            )}
          </>
        )}
      />
      <Column
        header="Primary"
        style={{ width: '10%' }}
        body={rowData => (
          <PFCheckbox
            checked={!!rowData.is_primary}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              showConfirmDialog(e, rowData)
            }
            disabled={!!rowData?.is_primary}
          />
        )}
      />
      <Column field="address.full_address" header="Address" />
    </DataTable>
  );

  return (
    <div className="card">
      <Toast ref={toast} />
      <Accordion>
        <AccordionTab
          header={
            <div className="flex align-items-center p-0">Customer Address</div>
          }
        >
          <div className="grid py-2">
            <div className="col-6 p-0 mt-1">
              <div className="px-2 align-items-start justify-content-start flex">
                {gMapFieldLayout()}
                <Button
                  onClick={() => gmapAddress && storeAddress(gmapAddress)}
                  loading={saveBtnLoading}
                  label="Save"
                  type="button"
                  raised
                  size="small"
                  className="ml-2 w-2"
                />
              </div>
            </div>
            <div className="col-6 p-0 mt-1">
              <div className="px-2 flex justify-content-end">
                <InputText
                  placeholder="Search Existing Address"
                  className="w-4"
                  onChange={e => addressSearch(e.target.value)}
                />
              </div>
            </div>
          </div>

          {loading ? <PFTableLoader /> : addressTable()}
        </AccordionTab>
      </Accordion>
    </div>
  );
};

export default CustomerAddress;
