import React, { useEffect, useState } from 'react';

import { apiBinListing } from '../../Locations/services/Warehouse.service';
import PFDropdown from '../../shared/PFPrime/PFDropdown';

export const Bin = ({ options, warehouseId, defaultSetting = {} }) => {
  const [binList, setBinList] = useState([]);

  const binListing = async (params = {}) => {
    const payload = { id: warehouseId, params: params };
    const listing = await apiBinListing(payload);
    setBinList(listing);
  };

  useEffect(() => {
    if (warehouseId) {
      binListing({ limit: 100 });
    }
  }, [warehouseId]);

  useEffect(() => {
    if (warehouseId) {
      if (!options?.value && binList?.data?.length > 0) {
        let defaultBin = binList?.data?.find(item => item?.isDefault);
        if (defaultBin) options.editorCallback(defaultBin?._id);
      }
      if (options?.value) {
        let defaultBin =
          binList?.data?.find(item => item?._id === options?.value) ||
          binList?.data?.find(item => item?.isDefault);
        if (defaultBin) options.editorCallback(defaultBin?._id);
      }
    }
  }, [warehouseId, binList?.data, options?.value]);

  return (
    <>
      <PFDropdown
        value={options?.value}
        placeholder={`${defaultSetting?.placeholder || ''}`}
        options={binList?.data || []}
        optionLabel={`${defaultSetting?.optionLabel || ''}`}
        optionValue={`${defaultSetting?.optionValue || ''}`}
        filter={true}
        onChange={(e, value) => options.editorCallback(value)}
        floatLabel={false}
        showClear={false}
        disabled={
          defaultSetting?.reqFrom && options?.rowData?._id ? true : false
        }
      />
    </>
  );
};
