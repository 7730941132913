import React, { useEffect, useContext } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import { RECEIPT_TYPE_RECEIVING } from '../constants/constants';
import { ReceivingContext } from '../ReceivingContainer';
import PFDialog from '../../../shared/PFPrime/PFDialog';
import PFDropdown from '../../../shared/PFPrime/PFDropdown';
import { getAllTechnicianListService } from '../../Shipping/services/shipping.service';
import PFButton from '../../../shared/PFPrime/PFButton';
import { getPayloadKeys } from '../helpers/helper';
import {
  createReceiptService,
  updateReceiptService,
} from '../services/receiving.service';

import { ReceivingTypeReturn } from './ReceivingTypeReturn';
import { ReceivingTypePickup } from './ReceivingTypePickup';
import { ReceivingTypeDelivery } from './ReceivingTypeDelivery';

export const AddEditViewReceiving = () => {
  const { receivingContextValue, setReceivingContextValue } =
    useContext(ReceivingContext);

  const storeReceipt = async payload => {
    if (receivingContextValue?.visibleMode?.visible === 'add') {
      const store = await createReceiptService(
        receivingContextValue?.project_id,
        receivingContextValue?.projectReceivingData?.project_receiving_id,
        payload
      );
      if (store?.status) {
        setReceivingContextValue(preValue => ({
          ...preValue,
          reloadList: true,
        }));
        receivingContextValue?.toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Receipt added successfully',
        });
      }
    }
    if (receivingContextValue?.visibleMode?.visible === 'edit') {
      const store = await updateReceiptService(
        receivingContextValue?.project_id,
        receivingContextValue?.visibleMode?.data?.project_receiving_id,
        receivingContextValue?.visibleMode?.data?.project_receiving_receipt_id,
        payload
      );

      if (store?.status) {
        setReceivingContextValue(preValue => ({
          ...preValue,
          reloadList: true,
        }));
        receivingContextValue?.toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Receipt update successfully',
        });
      }
    }

    receivingContextValue?.hide();
  };

  const receiptForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      receipt_type: 2,
      box_count: '',
      receipt_date: null,
      receipt_note: '',
      needed: '',
      from_warehouse_id: '',
      to_warehouse_id: '',
      technician_id: '',
      is_received_by_technician: '',
      return_warehouse_id: '',
      bin_location_id: '',
      is_still_in_warehouse: '',
    },

    validationSchema: Yup.object().shape({}),
    onSubmit: values => {
      const payload = getPayloadKeys(values);
      storeReceipt(payload);
    },
  });

  const { handleSubmit, values, setFieldValue, setValues } = receiptForm;

  const technicianList = async () => {
    const response = await getAllTechnicianListService();
    receivingContextValue?.updateContextValue({ technicianList: response });
  };

  useEffect(() => {
    technicianList();
  }, []);

  useEffect(() => {
    if (receivingContextValue?.visibleMode?.visible !== 'add') {
      if (receivingContextValue?.visibleMode?.data)
        setValues(receivingContextValue?.visibleMode?.data);
    }
  }, [receivingContextValue?.visibleMode?.visible]);

  const renderReceivingComponent = () => {
    switch (values?.receipt_type) {
      case 1:
        return <ReceivingTypeDelivery receiptForm={receiptForm} />;
      case 2:
        return <ReceivingTypePickup receiptForm={receiptForm} />;
      case 3:
        return <ReceivingTypeReturn receiptForm={receiptForm} />;
      default:
        return null;
    }
  };

  const body = () => {
    return (
      <div className="grid mt-2">
        <div className="col-12 md:col-4 lg:col-4">
          <span className="p-float-label">
            <PFDropdown
              placeholder="Select Type"
              name="receipt_type"
              value={values?.receipt_type || ''}
              options={RECEIPT_TYPE_RECEIVING}
              onChange={(name, value) => {
                receiptForm?.resetForm();
                setFieldValue(name, value || '');
              }}
              disabled={
                receivingContextValue?.visibleMode?.visible === 'add'
                  ? false
                  : true
              }
            />
            <label htmlFor="type">Type</label>
          </span>
        </div>
        {renderReceivingComponent()}
      </div>
    );
  };
  return (
    <div>
      <PFDialog
        show={receivingContextValue?.show}
        hide={receivingContextValue?.hide}
        header="Receiving"
        BodyComponent={body()}
        footer={() => (
          <>
            {values?.receipt_type && (
              <div className="col-12 md:col-12 lg:col-12 p-0 m-0">
                <div className="flex justify-content-end">
                  <PFButton
                    label="Cancel"
                    primary
                    outlined
                    className="mr-2"
                    onClick={() => receivingContextValue?.hide()}
                  />
                  <PFButton
                    label="Save"
                    primary
                    type="button"
                    disabled={
                      receivingContextValue?.visibleMode?.disabled || false
                    }
                    onClick={handleSubmit}
                  />
                </div>
              </div>
            )}
          </>
        )}
        style={{
          width: '50vw',
          minHeight: '50vh',
        }}
      />
    </div>
  );
};
