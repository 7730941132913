import { Sidebar } from 'primereact/sidebar';

const PFSidebarHeader = headerText => {
  return headerText ? (
    <div className="grid w-12 text-white m-0 text-center justify-content-center">
      <h2 className="m-0">{headerText}</h2>
    </div>
  ) : (
    <></>
  );
};

const PFSideBar = ({
  children,
  visible,
  className,
  headerText = '',
  onHide,
  style,
  ...rest
}) => {
  return (
    <div className={className || ''} style={style || {}}>
      <Sidebar
        icons={PFSidebarHeader(headerText)}
        className="p-0"
        fullScreen
        visible={visible}
        onHide={onHide}
        {...rest}
      >
        <>{children}</>
      </Sidebar>
    </div>
  );
};

export { PFSideBar };
