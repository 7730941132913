import React from 'react';
import MUIDataTable from 'mui-datatables';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const GenericDataTable = ({ title, columns, data, options }) => {
  return (
    <MUIDataTable
      title={title}
      data={data}
      columns={columns}
      options={options}
    />
  );
};

export const WrapperPRDataTable = ({
  showGridlines = false,
  dataValues,
  tableStyle,
  columns = [],
  sortMode = 'single',
  rows = 5,
  rowEditor = false,
  paginator = true,
  totalRecords,
  rowsPerPageOptions = [5, 10, 25, 50],
  ...rest
}) => {
  return (
    <DataTable
      sortMode={sortMode}
      showGridlines={showGridlines}
      value={dataValues}
      tableStyle={tableStyle}
      paginator={paginator}
      rows={rows}
      totalRecords={totalRecords}
      rowsPerPageOptions={rowsPerPageOptions}
      {...rest}
    >
      {columns.map(col => (
        <Column
          key={col.field}
          field={col.field}
          header={col.header}
          sortable={col.sortable || false}
          {...col}
        />
      ))}
      {rowEditor && (
        <Column
          rowEditor
          headerStyle={{ width: '10%', minWidth: '7rem' }}
          bodyStyle={{ textAlign: 'center' }}
        />
      )}
    </DataTable>
  );
};

export default GenericDataTable;
