import React from 'react';

import { formateNumber } from '../helpers/Quotes';
import PFInputNumber from '../../../shared/PFPrime/PFInputNumber';
import PFFormikError from '../../../shared/PFPrime/PFFormikError';
const RevenueEditor = ({ rowData, editingRows, submitAction }) => {
  const { values, touched, errors } = submitAction;
  const value = formateNumber(
    values?.quantity !== '' && values?.totalRevenue !== 0
      ? (values?.totalRevenue || 0) / (values?.quantity || 0)
      : values?.revenueFromAPI
  );

  const isEditing = editingRows[rowData.rowIndex];
  const revenueEachCalculation = data => {
    const totalRevenue = formateNumber(data.totalRevenue);
    const quantity = formateNumber(data?.quantity);
    return formateNumber(totalRevenue / quantity);
  };

  return isEditing ? (
    <div className="vertical-align-top">
      <span className="p-float-label">
        <PFInputNumber
          inputId="totalRevenue"
          name="totalRevenue"
          mode={'currency'}
          currency="USD"
          minFractionDigits={2}
          value={value}
          className={
            touched?.totalRevenue && errors?.totalRevenue ? 'error' : ''
          }
          version={2}
          inputStyle={{ width: '100px' }}
          disabled={true}
        />
      </span>
      <PFFormikError touched={touched} errors={errors} field="totalRevenue" />
    </div>
  ) : (
    <div className="pt-2">
      <div className="w-full">{`$ ${revenueEachCalculation(rowData).toFixed(2)}`}</div>

      <div className="w-full">
        <span className="font-bold">{`$${formateNumber(rowData?.totalRevenue).toFixed(2)}`}</span>
      </div>
    </div>
  );
};

export default RevenueEditor;
