import { CONFIG } from '../../../constants';

const companyConfigConstants = {
  baseUrl: CONFIG.API_BASE_URL,

  tenantDataUpdateEndpoint: () => {
    return `/admin-config/`;
  },
};
export default companyConfigConstants;

export const SchedulerDefaultView = [
  { label: 'Day', value: 'day' },
  { label: 'Week', value: 'week' },
  { label: 'Month', value: 'month' },
];

export const SCHEDULER_FEATURE_TYPE = {
  schedularDefaultView: 'schedularDefaultView',
  schedularRTSreportFilter: 'schedularRTSreportFilter',
  schedularCardView: 'schedularCardView',
};

export const SCHEDULER_CONFIG_DATA = {
  calender_view: {
    card_visibility: [],
  },
};

export const SCHEDULER_CARD_VIEW_OPTIONS = [
  {
    options: [
      {
        value: { basic_labor_quantity: 'Basic Labor Quantity' },
        label: 'Basic Labor Quantity',
      },
      { value: { description: 'Description' }, label: 'Description' },
      { value: { time: 'Time' }, label: 'Time' },
      { value: { store: 'Store #' }, label: 'Store #' },
      { value: { project_type: 'Type' }, label: 'Type' },
      { value: { category: 'Category' }, label: 'Category' },
      { value: { status: 'Status' }, label: 'Status' },
      { value: { total_sale: 'Total Sale $' }, label: 'Total Sale $' },
      { value: { total_revenue: 'Total Revenue $' }, label: 'Total Revenue $' },
      { value: { project_number: 'Project No' }, label: 'Project No' },
      { value: { client_name: 'Client Name' }, label: 'Client Name' },
      { value: { address: 'Address' }, label: 'Address' },
      { value: { phone: 'Phone' }, label: 'Phone' },
    ],
  },
  {
    type: {
      description: 'description',
      time: 'time',
      store: 'store',
      project_type: 'project_type',
      category: 'category',
      status: 'status',
      total_sale: 'total_sale',
      total_revenue: 'total_revenue',
      project_number: 'project_number',
      client_name: 'client_name',
      address: 'address',
      phone: 'phone',
      basic_labor_quantity: 'basic_labor_quantity',
    },
  },
];

export const ADDITIONAL_FEATURE_TYPE = {
  ALLOCATION_BEHAVIOUR: 'allocationBehaviour',
  ITEM_LINKAGE_CONFIGURATION: 'itemLinkageConfiguration',
  SALES_COMMISSION_OPTED: 'salesCommissionOpted',
  PROJECT_COLOR_CODE: 'projectColorCode',
  SKIP_TECHNICIAN_ASSIGNMENT_CLONING: 'skipTechnicianAssignmentCloning',
  GROUP_SOURCE_CATEGORY: 'groupSourceCategory',
  FIXED_TOP_BAR: 'fixedTopBar',
  FIXED_PROJECT_BAR: 'fixedProjectBar',
  SCHEDULER_CONFIG: 'schedulerConfig',
  CHARGEBACK_APPROVAL_MODULE: 'chargebackApprovalModule',
  RESET_PASSWORD_EXPIRE_IN: 'restPasswordExpireIn',
  MFA_EXPIRE_IN_DAYS: 'mfaExpireInDays',
  SHIPPING_AND_RECEIVING_MODULE: 'shipping_receiving',
  HIGHLIGHT_STORE_NOTES: 'highlight_store_notes',
  COPY_TOTAL_SALE: 'copy_total_sales',
  ADMIN_EMAIL: 'admin_email',
  DO_NOT_OVERRIDE_LABOR_COST: 'do_not_override_labor_cost',
  SHOW_LINE_ITEMS_ON_DOCUMENTS: 'show_line_items_on_documents',
  ASSIGN_TECHNICIAN_ON_REVENUE_EXCLUDED_ITEMS:
    'assign_technician_on_revenue_excluded_items',
  EPA_GUARDRAILS_WORKFLOW: 'epa_guardrails_workflow ',
  DOCUMENT_AUTO_PULL_RETRY: 'document_auto_pull_retry',
  PROJECT_NUMBER: 'personalized_private_project_number',
  RELATED_POS: 'related_pos',
  SEND_TENTATIVE_SCHEDULE_NOTE_TO_SOURCE:
    'send_tentative_schedule_note_to_source',
  PAYMENT_CONFIGURATION: 'payment_configuration',
  AUTO_ASSIGN_TECHNICIANS: 'auto_assign_technicians',
  ORDER_COMPLETION_VALIDATION: 'order_completion_validation',
  Audit_log: 'audit_log',
};

export const commonPt = {
  content: {
    className: 'lg:pl-3 mt-3 lg:mt-0',
  },
  headertitle: {
    className: 'white-space-normal',
  },
};

export const companyConfigTableOptions = {
  field: 'label',
  header: 'Features',
  style: { width: '30%' },
};

export const RESET_PASSWORD_DAYS_OPTIONS = [
  { label: '30 Days', value: 30 },
  { label: '60 Days', value: 60 },
  { label: '90 Days', value: 90 },
  { label: '120 Days', value: 120 },
  { label: '180 Days', value: 180 },
];
export const MFA_EXPIRE_IN_DAYS_OPTIONS = [
  { label: 'Daily', value: 1 },
  { label: 'Weekly', value: 7 },
  { label: '15 Days', value: 15 },
  { label: 'Monthly', value: 30 },
  { label: '60 Days', value: 60 },
  { label: '90 Days', value: 90 },
  { label: '120 Days', value: 120 },
  { label: '180 Days', value: 180 },
];
export const DOCUMENT_EXPIRE_IN_DAYS_OPTIONS = [
  { label: '30 Days', value: 30 },
  { label: '60 Days', value: 60 },
  { label: '90 Days', value: 90 },
  { label: '120 Days', value: 120 },
  { label: '180 Days', value: 180 },
];

export const JOB_ASSIGNMENT_ROTATION_VALUES = [
  { id: 1, value: 'Default Store Technician' },
  { id: 2, value: 'Rotation Percentage' },
];
