import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Toast, Dialog } from 'primereact';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { ConfirmDialog } from 'primereact/confirmdialog';

import PFButton from '../../shared/PFPrime/PFButton';
import PageHeader from '../../shared/PageHeader/PageHeader';
import PFDropdown from '../../shared/PFPrime/PFDropdown';
import { updateQuestionnaireStore } from '../../../redux/slices/questionnaire.slice';
import permissions from '../../../config/permissions';
import { checkPermission } from '../../../utils/Helpers';
import PFInputText from '../../shared/PFPrime/PFInputText';
import PFFormikError from '../../shared/PFPrime/PFFormikError';
import { useConfirmDialogContext } from '../../../contexts/ConfirmDialog';
import { fetchTypes } from '../../../redux/slices/master/types.slice';
import { fetchCategories } from '../../../redux/slices/master/categories.slice';
import { fetchProjectStatusType } from '../../../redux/slices/master/projectStatusType.slice';
import { fetchSourceStatusType } from '../../../redux/slices/master/sourceStatusType.slice';
import { fetchSourceSystems } from '../../../redux/slices/master/sourceSystems.slice';

import CustomizeCheckList from './CustomizeCheckList';
import {
  getModules,
  getQuestions,
  addModule,
  copyModule,
  deleteModule,
} from './questionnaireService';
import QuestionDialog from './QuestionDialog';
import QuestionComponent from './QuestionComponent';

const breadcrumb = [
  {
    text: 'Admin',
  },
  {
    text: 'Checklist',
  },
];
const TYPE_OPTIONS = [
  {
    name: 'Project',
    value: 'PROJECT',
    remainingPayload: {
      reference_table: 't_projects',
      reference_table_column: 'project_id',
      config: { max_question_count: 20 },
    },
  },
  {
    name: 'User',
    value: 'USER',
    remainingPayload: {
      reference_table: 't_user_meta_details',
      reference_table_column: 'user_id',
      config: { max_question_count: 20 },
    },
  },
];
const QuestionnaireComponent = ({ isRenderConfirmationPopup = true }) => {
  const { showConfirmationDialog } = useConfirmDialogContext();
  const [isShowModulePopup, setIsShowModulePopup] = useState(false);
  const [isCopy, setIsCopy] = useState(false);
  const [isCreateModuleLoading, setIsCreateModuleLoading] = useState(false);
  const { isLoading, moduleList, moduleCount, questionList, selectedModule } =
    useSelector(state => state.questionnaire);
  const moduleFormik = useFormik({
    initialValues: {
      moduleName: '',
      moduleType: 'PROJECT',
      customizeCheckList: {
        projectType: {
          isAll: 0,
          selectedId: [],
        },
        projectCategories: {
          isAll: 0,
          selectedId: [],
        },
        projectStatus: {
          isAll: 0,
          selectedId: [],
        },
        sourceStatus: {
          isAll: 0,
          selectedId: [],
        },
        sourceSystem: {
          isAll: 0,
          selectedId: [],
        },
      },
    },
    validationSchema: !isCopy
      ? Yup.object().shape({
          moduleName: Yup.string()
            .trim()
            .required('Checklist name is required'),
          moduleType: Yup.string().trim().required('Module type is required'),
        })
      : Yup.object().shape({
          moduleName: Yup.string()
            .trim()
            .required('Checklist name is required'),
        }),
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      if (isCopy) {
        try {
          const payload = {
            module_name: values.moduleName,
            module_id: selectedModule?.module_id?.toString(),
          };
          setIsCreateModuleLoading(true);
          const { error } = await copyModule(payload);
          if (error) {
            setIsCopy(false);
            toast.current.show({
              severity: 'error',
              summary: 'Error',
              detail: error?.response?.data?.message || 'something went wrong',
              life: 3000,
            });
          } else {
            setIsShowModulePopup(false);
            setIsCopy(false);
            callGetModules();
            resetForm();
            toast.current.show({
              severity: 'success',
              summary: 'Success',
              detail: 'Checklist copied successfully',
              life: 3000,
            });
          }
          setIsCreateModuleLoading(false);
        } catch (error) {
          setIsCreateModuleLoading(false);
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: error?.response?.data?.message || 'something went wrong',
            life: 3000,
          });
        }
      } else {
        let customizeCheckListData = null;
        if (values.moduleType === 'PROJECT') {
          customizeCheckListData =
            await customizeChecklistRef.current.validateAndGetValues();
          if (!customizeCheckListData) {
            return;
          }
        }

        setIsCreateModuleLoading(true);
        const foundModuleDetails = TYPE_OPTIONS.find(
          record => record.value === values.moduleType
        );
        try {
          const payload = {
            module_name: values.moduleName,
            module_tag: values.moduleType,
            customized_checkList: customizeCheckListData,
            ...foundModuleDetails.remainingPayload,
          };
          const { error } = await addModule(payload);
          if (error) {
            toast.current.show({
              severity: 'error',
              summary: 'Error',
              detail: error?.response?.data?.message || 'something went wrong',
              life: 3000,
            });
          } else {
            setIsShowModulePopup(false);
            callGetModules();
            resetForm();
            toast.current.show({
              severity: 'success',
              summary: 'Success',
              detail: 'Checklist created successfully',
              life: 3000,
            });
          }
          setIsCreateModuleLoading(false);
        } catch (error) {
          setIsCreateModuleLoading(false);
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: error?.response?.data?.message || 'something went wrong',
            life: 3000,
          });
        }
      }
    },
  });
  const dispatch = useDispatch();
  const editPermission = checkPermission(permissions.questionnaire.edit);

  const toast = useRef(null);
  const customizeChecklistRef = useRef(null); // Ref for CustomizeCheckList

  const callGetQuestions = async moduleId => {
    dispatch(updateQuestionnaireStore({ isLoading: true }));
    try {
      const response = await getQuestions(moduleId);
      if (response?.status && response?.data) {
        dispatch(
          updateQuestionnaireStore({
            questionList: Array.isArray(response?.data?.questions)
              ? response.data?.questions
              : questionList,
            questionCount: Array.isArray(response?.data?.questions)
              ? response?.data?.questions?.length
              : undefined,
            customizeCheckList: response?.data?.customized?.result,
          })
        );
      }
    } catch (ex) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: ex?.response?.data?.message || 'something went wrong',
        life: 3000,
      });
    } finally {
      dispatch(updateQuestionnaireStore({ isLoading: false }));
    }
  };
  const callGetModules = async (getCall = false) => {
    dispatch(updateQuestionnaireStore({ isLoading: true }));
    try {
      const response = await getModules();
      if (response?.status && response?.data) {
        dispatch(
          updateQuestionnaireStore({
            moduleList: Array.isArray(response.data?.result)
              ? response.data.result
              : moduleList,
            moduleCount: response.data?.count
              ? response.data.count
              : moduleCount,
          })
        );
        if (!getCall) {
          handleModuleChange('module', response.data.result[0]);
        }
      }
    } catch (ex) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: ex?.response?.data?.message || 'something went wrong',
        life: 3000,
      });
    } finally {
      dispatch(updateQuestionnaireStore({ isLoading: false }));
    }
  };
  useEffect(() => {
    dispatch(updateQuestionnaireStore({ isLoading: true }));
    dispatch(fetchTypes());
    dispatch(fetchCategories());
    dispatch(fetchProjectStatusType());
    dispatch(fetchSourceStatusType());
    dispatch(fetchSourceSystems());
    callGetModules(true);
  }, []);

  useEffect(() => {
    return () => {
      dispatch(
        updateQuestionnaireStore({
          selectedModule: null,
          questionDialogContent: null,
          questionCount: 0,
          questionList: [],
        })
      );
    };
  }, []);

  const handleAddNewQuestion = () => {
    dispatch(updateQuestionnaireStore({ isQuestionDialogOpen: true }));
  };
  const handleModuleChange = (name, value) => {
    if (value) {
      dispatch(
        updateQuestionnaireStore({
          selectedModule: value,
          questionDialogContent: { module: value },
        })
      );
      callGetQuestions(value?.module_id);
    } else {
      dispatch(
        updateQuestionnaireStore({
          selectedModule: null,
          questionDialogContent: null,
          questionCount: 0,
          questionList: [],
        })
      );
    }
  };

  const deleteModuleQuestions = async module_id => {
    dispatch(updateQuestionnaireStore({ isLoading: true }));
    const response = await deleteModule(module_id);
    if (response?.data?.status) {
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: response?.data?.message || 'Checklist deleted successfully',
        life: 3000,
      });
      callGetQuestions(module_id);
    } else {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail:
          response?.error?.response?.data?.message || 'something went wrong',
        life: 3000,
      });
      dispatch(updateQuestionnaireStore({ isLoading: false }));
    }
  };

  const confirmDelete = module_id => {
    showConfirmationDialog({
      message:
        'This action will delete all questions of this checklist, would you still like to delete?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => deleteModuleQuestions(module_id),
      rejectLabel: 'Cancel',
      acceptLabel: 'Delete All',
      acceptIcon: 'pi pi-trash',
    });
  };
  return (
    <>
      <Toast ref={toast} />
      {isRenderConfirmationPopup && <ConfirmDialog />}
      <div className="grid md:pl-3 xl:w-full">
        <div className="col-12 xl:col-6">
          <PageHeader pageTitle="Checklist" breadCrumbArray={breadcrumb} />
        </div>
        <div className="col-12 xl:col-6 md:flex gap-2 align-items-center justify-content-end">
          <div className="w-full md:w-8">
            <span className="p-float-label">
              <PFDropdown
                value={selectedModule}
                onChange={handleModuleChange}
                options={moduleList}
                disabled={isLoading}
                className="w-full"
                optionLabel="module_name"
                placeholder="Select a Checklist"
                inputId="module"
                name="module"
              />
              <label htmlFor="module">Checklist</label>
            </span>
          </div>
          <div className="flex flex-column md:flex-row gap-2 mt-2 md:mt-0">
            <div className="flex gap-2">
              {editPermission && (
                <div className="w-full md:min-w-max">
                  <PFButton
                    onClick={handleAddNewQuestion}
                    size="small"
                    label="Add Question"
                    severity="primary"
                    icon={isLoading ? 'pi pi-spin pi-spinner' : 'pi pi-plus'}
                    iconPos="left"
                    disabled={isLoading || !selectedModule}
                    className={'px-2 w-full'}
                  />
                </div>
              )}
              <div className="w-full md:min-w-max">
                <PFButton
                  onClick={() => {
                    setIsShowModulePopup(true);
                    moduleFormik.resetForm();
                  }}
                  size="small"
                  label="Create Checklist"
                  severity="primary"
                  icon={'pi pi-plus'}
                  iconPos="left"
                  className={'px-2 w-full'}
                />
              </div>
            </div>
            <div className="flex gap-2">
              <div className="w-full md:min-w-max">
                <PFButton
                  onClick={() => {
                    setIsShowModulePopup(true);
                    setIsCopy(true);
                  }}
                  disabled={isLoading || !selectedModule}
                  size="small"
                  label="Copy Checklist"
                  severity="primary"
                  icon={isLoading ? 'pi pi-spin pi-spinner' : 'pi pi-clone'}
                  iconPos="left"
                  className={'px-2 w-full'}
                />
              </div>
              <div className="w-full md:min-w-max">
                <PFButton
                  onClick={() => {
                    confirmDelete(selectedModule?.module_id);
                  }}
                  disabled={
                    isLoading || !selectedModule || !questionList?.length
                  }
                  size="small"
                  label="Delete"
                  severity="danger"
                  icon={isLoading ? 'pi pi-spin pi-spinner' : 'pi pi-trash'}
                  iconPos="left"
                  className={'w-full'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {selectedModule?.module_tag === 'PROJECT' && (
        <CustomizeCheckList toast={toast} mode={'update'} />
      )}
      <QuestionComponent toast={toast} editPermission={editPermission} />
      <QuestionDialog toast={toast} editPermission={editPermission} />
      <Dialog
        header={isCopy ? 'Copy Checklist' : 'Create Checklist'}
        visible={isShowModulePopup}
        blockScroll={true}
        className={
          isCopy
            ? 'w-12 lg:w-7 xl:w-5 h-auto px-3'
            : 'w-11 md:w-8 min-h-240 h-auto'
        }
        onHide={() => {
          setIsShowModulePopup(false);
          setIsCopy(false);
          moduleFormik.resetForm();
        }}
        contentClassName="pt-3 lg:pt-1"
      >
        <>
          <p className="m-0">
            <div className="grid md:pl-3 w-full md:w-12">
              <div
                className={`${isCopy ? 'col-12 md:col-7' : 'col-12 md:col-5'}`}
              >
                <PFInputText
                  i="pi pi-search"
                  type="text"
                  value={moduleFormik.values.moduleName}
                  onChange={e =>
                    moduleFormik?.setFieldValue('moduleName', e.target.value)
                  }
                  placeholder="Enter checklist Name"
                />
                <PFFormikError
                  touched={moduleFormik.touched}
                  errors={moduleFormik.errors}
                  field="moduleName"
                />
              </div>

              {!isCopy && isShowModulePopup && (
                <div className="col-12 md:col-4">
                  <span className="p-float-label">
                    <PFDropdown
                      optionLabel="name"
                      placeholder="Select Type"
                      options={TYPE_OPTIONS}
                      label="Module"
                      value={moduleFormik.values.moduleType}
                      onChange={(name, value) => {
                        moduleFormik.setFieldValue('moduleType', value);
                      }}
                      appendTo="self"
                    />
                    <label htmlFor="module">Module</label>
                  </span>
                  <PFFormikError
                    touched={moduleFormik.touched}
                    errors={moduleFormik.errors}
                    field="moduleType"
                  />
                </div>
              )}

              <div
                className={`${isCopy ? 'col-12 md:col-5' : 'col-12 md:col-3'}`}
              >
                <PFButton
                  size="small"
                  label={isCopy ? 'Copy Checklist' : 'Create Checklist'}
                  severity="primary"
                  icon={
                    isCreateModuleLoading
                      ? 'pi pi-spin pi-spinner'
                      : 'pi pi-plus'
                  }
                  iconPos="left"
                  onClick={() => moduleFormik.handleSubmit()}
                  disabled={isCreateModuleLoading}
                />
              </div>
            </div>
          </p>
          {!isCopy &&
            isShowModulePopup &&
            moduleFormik.values.moduleType === 'PROJECT' && (
              <div className="mt-5">
                <CustomizeCheckList
                  toast={toast}
                  mode={'add'}
                  ref={customizeChecklistRef}
                />
              </div>
            )}
        </>
      </Dialog>
    </>
  );
};

export default QuestionnaireComponent;
