import React, { useRef, useState } from 'react';
import { Editor } from 'primereact/editor';
import type { EditorTextChangeEvent } from 'primereact/editor';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { InputText } from 'primereact/inputtext';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { isEmail, ReactMultiEmail } from 'react-multi-email';
import { FileUpload } from 'primereact/fileupload';
import type { FileUploadSelectEvent } from 'primereact/fileupload';
import { OverlayPanel } from 'primereact/overlaypanel';
import { ListBox } from 'primereact/listbox';
import { Button } from 'primereact/button';

import { EmailFormValues } from '../Interface/email.interface';
import { syntheticEvent } from '../../../../utils/event.helper';
import 'react-multi-email/style.css';
import { useConfirmDialogContext } from '../../../../contexts/ConfirmDialog';
import { ALLOWED_FILE_TYPES } from '../../../../constants/file-type.constant';

const ComposeEmail: React.FC<{
  project_number: string;
  // eslint-disable-next-line no-unused-vars
  sentEmail: (values: EmailFormValues) => Promise<boolean>;
  storeEmail: string;
  customerEmail: string;
}> = ({ sentEmail, storeEmail, customerEmail, project_number }) => {
  const [activeIndex, setActiveIndex] = useState<number | null | number[]>(
    null
  );
  const { showConfirmationDialog } = useConfirmDialogContext();
  const [loading, setLoading] = React.useState(false);
  const [emailError, setEmailError] = React.useState(false);
  const uploadHandler = useRef<FileUpload | null>(null);
  const attachmentListRef = useRef<OverlayPanel | null>(null);
  const EmailValidation = Yup.object({
    to: Yup.array()
      .of(Yup.string().email('Invalid email format'))
      .min(1, 'At least one email is required'),
    subject: Yup.string().required('Subject field must not be empty.'),
    body: Yup.string().required('Body field must not be empty.'),
  });
  const processEmail = async (values: EmailFormValues) => {
    setLoading(true);
    const response = await sentEmail(values);

    if (response) {
      setActiveIndex(null);
      emailFormik?.handleReset(syntheticEvent);
    }
    setLoading(false);
  };
  const emailFormik = useFormik<EmailFormValues>({
    initialValues: {
      to: [storeEmail || customerEmail],
      subject: `Regarding the ${project_number}`,
      body: '',
    },
    validationSchema: EmailValidation,
    onSubmit: values => {
      if (!emailFormik?.isValid) {
        return;
      }
      processEmail(values);
    },
  });
  // Handle file selection
  const onSelect = (e: FileUploadSelectEvent) => {
    const validFiles = (e.files || []).filter(file =>
      ALLOWED_FILE_TYPES.includes(file.type)
    );
    emailFormik.setFieldTouched('attachments', true, false);
    if (validFiles.length === 0) {
      emailFormik.setFieldError(
        'attachments',
        'Invalid file format. Only JPG, PNG, and PDF are allowed.'
      );
      uploadHandler.current?.clear();
      return;
    }
    const selectedFiles = emailFormik.values.attachments;

    if (Array.isArray(selectedFiles) && selectedFiles?.length) {
      emailFormik.setFieldValue('attachments', [
        ...selectedFiles,
        ...validFiles,
      ]);
    } else {
      emailFormik.setFieldValue('attachments', [...validFiles]);
    }
    uploadHandler.current?.clear();
  };
  const removeAttachment = (fileName: string) => {
    if (emailFormik.values?.attachments?.length)
      emailFormik.setFieldValue(
        'attachments',
        emailFormik.values?.attachments.filter(file => file.name !== fileName)
      );
  };
  const attachmentListTemplate = (option: File) => {
    return (
      <div className="flex align-items-center border-300 border-bottom-1">
        <i
          className="pi pi-times-circle mr-2 text-primary"
          onClick={() => removeAttachment(option.name)}
        ></i>
        <div>{option.name}</div>
      </div>
    );
  };
  const confirmCloseAccordion = (index: number | number[]) => {
    setEmailError(false);
    if (emailFormik?.dirty) {
      showConfirmationDialog({
        message:
          'Closing window will discard your change, Do you want to proceed?',
        header: 'Discard Confirmation',
        icon: 'pi pi-info-circle text-xl',
        acceptClassName: 'p-button-primary p-button-sm',
        rejectClassName: 'p-button-sm p-button-outlined',
        accept: () => {
          setActiveIndex(index === activeIndex ? null : index);
          emailFormik?.handleReset(syntheticEvent);
        },
        reject: () => {},
      });
    } else {
      setActiveIndex(index);
    }
  };

  return (
    <Accordion
      className="w-12"
      onTabChange={e => confirmCloseAccordion(e.index)}
      activeIndex={activeIndex}
    >
      <AccordionTab header="Compose">
        <form>
          <div className="grid py-2">
            <div className="col-12 md:col-10 lg:col-10">
              <div className="w-full">
                <ReactMultiEmail
                  placeholder={
                    <span className="text-color">
                      To <span className="text-xs text-red-500">*</span>
                    </span>
                  }
                  className="border-300"
                  emails={
                    emailFormik.values.to?.filter(val => val !== undefined) ||
                    []
                  }
                  onChange={_emails => {
                    emailFormik?.setFieldValue('to', _emails);
                    emailFormik.setFieldTouched('to', true, false);
                  }}
                  validateEmail={e => {
                    if (!isEmail(e)) {
                      setEmailError(true);
                    } else {
                      setEmailError(false);
                    }
                    return isEmail(e);
                  }}
                  getLabel={(email, index, removeEmail) => {
                    return (
                      <div data-tag key={index}>
                        {email}
                        <span
                          data-tag-handle
                          onClick={() => {
                            removeEmail(index);
                          }}
                        >
                          ×
                        </span>
                      </div>
                    );
                  }}
                />
                {(emailFormik.touched.to && emailFormik.errors.to) ||
                emailError ? (
                  <div className="text-xs text-red-500">
                    {emailFormik.errors.to || 'Invalid email format'}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-12 md:col-2 lg:col-2">
              <div
                className="w-12"
                onMouseEnter={e => {
                  if (
                    emailFormik.values.attachments?.length &&
                    attachmentListRef.current !== null
                  ) {
                    attachmentListRef.current?.show(e, null);
                  }
                }}
                onMouseLeave={() => {
                  if (
                    emailFormik.values.attachments?.length &&
                    attachmentListRef.current !== null
                  ) {
                    attachmentListRef.current.hide();
                  }
                }}
              >
                <FileUpload
                  mode="basic"
                  ref={uploadHandler}
                  name="attachments"
                  multiple
                  customUpload
                  onSelect={onSelect}
                  chooseLabel={
                    emailFormik.values.attachments?.length
                      ? `${emailFormik.values.attachments.length}`
                      : ' Browse'
                  }
                  pt={{ basicButton: { className: 'p-button-sm' } }}
                  auto
                  chooseOptions={{
                    icon: <i className="pi pi-paperclip mr-2"></i>,
                  }}
                />
                {emailFormik.touched.attachments &&
                emailFormik.errors.attachments ? (
                  <div className="text-xs text-red-500 mt-1">
                    {emailFormik.errors.attachments}
                  </div>
                ) : null}
                <OverlayPanel
                  ref={attachmentListRef}
                  pt={{ content: { className: 'p-2' } }}
                >
                  <ListBox
                    options={emailFormik.values.attachments}
                    itemTemplate={attachmentListTemplate}
                    optionLabel="name"
                    className="w-14rem h-14rem overflow-y-scroll"
                    pt={{ wrapper: { className: 'flex' } }}
                    emptyMessage="No attachment found"
                  />
                </OverlayPanel>
              </div>
            </div>
          </div>
          <div className="grid py-2">
            <div className="col-12 md:col-10 lg:col-10">
              <div className="w-full">
                <span className="p-float-label">
                  <InputText
                    className="w-12"
                    value={emailFormik.values.subject}
                    name="subject"
                    onChange={emailFormik.handleChange}
                    onBlur={emailFormik.handleBlur}
                  />
                  <label htmlFor="subject">
                    Enter subject <span className="text-red-500">*</span>
                  </label>
                </span>
                {emailFormik.touched.subject && emailFormik.errors.subject ? (
                  <div className="text-xs text-red-500">
                    {emailFormik.errors.subject}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-12 md:col-2 lg:col-2">
              <div className="w-full">
                <Button
                  label="Send"
                  type="button"
                  size="small"
                  onClick={() => emailFormik?.handleSubmit()}
                  className="btn-mail-send w-full"
                  disabled={
                    !emailFormik.dirty ||
                    !emailFormik.isValid ||
                    loading ||
                    emailError
                  }
                  icon={loading ? 'pi pi-spin pi-spinner' : undefined}
                />
              </div>
            </div>
          </div>

          <div className="grid py-2">
            <div className="col-12 md:col-12 lg:col-12">
              <Editor
                onTextChange={(e: EditorTextChangeEvent) =>
                  emailFormik?.setFieldValue('body', e.htmlValue)
                }
                style={{ height: '320px' }}
                pt={{ root: { className: 'w-12' } }}
              />
            </div>
          </div>
        </form>
      </AccordionTab>
    </Accordion>
  );
};

export default ComposeEmail;
