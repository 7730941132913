import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { debounce } from 'lodash';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { InputMask } from 'primereact/inputmask';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Dropdown } from 'primereact/dropdown';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { Skeleton } from 'primereact/skeleton';

import { isEmail } from '../../../utils/Helpers';
import { setUserCrewMember } from '../../../redux/slices/user-form-crew-member.slice';
import { useConfirmDialogContext } from '../../../contexts/ConfirmDialog';
import PFButton from '../../shared/PFPrime/PFButton';

import { domHandlerCall } from './common';
import {
  addEditCrewMember,
  getUserCrewMemberList,
  removeUserCrewMemberDetailById,
} from './services/user-form-crew.service';

const emptyCrewMemberRow = {
  name: '',
  digital_badge_id: '',
  verification_id: '',
  email: '',
  phone: '',
  doj: '',
  status: '',
};

const MemberStatusOptions = [
  {
    status: 'active',
    value: 'Active',
  },
  {
    status: 'inactive',
    value: 'Inactive',
  },
  {
    status: 'on_leave',
    value: 'On Leave',
  },
];

const UserFormCrewMember = ({ selectedCrew }) => {
  const { showConfirmationDialog } = useConfirmDialogContext();
  const { isLoading, crewMemberData, crewMemberDataCount } = useSelector(
    state => state.userFormCrewMember
  );
  const exportOverlayRef = useRef(null);
  const { userId } = useSelector(state => state.userForm);
  const dispatch = useDispatch();
  const toast = useRef(null);
  const addressSection = useRef(null);
  const [isDisableAddButton, setIsDisableAddButton] = useState(false);
  const [tableDataState, setTableDataState] = useState({
    limit: 10,
    page: 1,
    sortField: 'member_id',
    sortOrder: 1,
  });

  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const dt = useRef(null);

  // Fetch Crew Member List data
  const fetchInitiationData = async searchValue => {
    dispatch(setUserCrewMember({ isLoading: true, isDeleteDialogOpen: false }));
    try {
      const urlParams = {
        ...tableDataState,
        sortBy: tableDataState.sortField,
        sortOrder: tableDataState.sortOrder === 1 ? 'asc' : 'desc',
      };
      if (globalFilterValue || searchValue)
        urlParams.globalSearch = globalFilterValue || searchValue;
      const initializationData =
        !!userId &&
        (await getUserCrewMemberList(userId, selectedCrew?.crew_id, urlParams));
      const { result = [], count = 0 } = initializationData?.data || {};
      const crewMemberDataArray = result?.map(row => {
        return {
          member_id: row?.member_id,
          name: row?.name,
          digital_badge_id: row?.digital_badge_id,
          verification_id: row?.verification_id,
          email: row?.email,
          phone: row?.phone,
          doj: row?.doj,
          status: row?.status === 'on_leave' ? 'On Leave' : row?.status,
        };
      });
      dispatch(
        setUserCrewMember({
          crewMemberData: crewMemberDataArray,
          crewMemberDataCount: count,
        })
      );
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setUserCrewMember({ isLoading: false }));
    }
  };

  useEffect(() => {
    fetchInitiationData();
    setIsDisableAddButton(false);
  }, [userId, tableDataState]);

  const handleOnPage = event => {
    setTableDataState({
      limit: event?.rows,
      page: event.first / event.rows + 1,
      sortField: event?.sortField,
      sortOrder: event?.sortOrder,
    });
  };

  // Add new row
  const handleAddNewRow = () => {
    dispatch(
      setUserCrewMember({
        crewMemberData: [emptyCrewMemberRow, ...crewMemberData],
      })
    );
    setTimeout(() => {
      const edit = domHandlerCall('p-row-editor-init', addressSection.current);
      edit?.handler[0].click();
    }, 300);
  };

  // Validate the row before save
  const handleRowValidation = e => {
    const validationError = [];

    if (!e?.name) {
      validationError.push('Name');
    }
    if (!e?.digital_badge_id) {
      validationError.push('Digital Badge ID');
    }
    if (!e?.email || !isEmail(e?.email)) {
      validationError.push('Email');
    }
    if (!e?.doj) {
      validationError.push('Date of Joining');
    }
    if (!e?.status) {
      validationError.push('Member Status');
    }

    const isValid = !validationError.length > 0;
    if (!isValid) {
      const errorMessage = `${validationError.join(',')} invalid`;
      toast.current.show({
        severity: 'error',
        summary: errorMessage,
        life: 2000,
      });
      return false;
    }
    return isValid;
  };

  // Save newly added or edit data
  const onRowEditComplete = async e => {
    let originalCrewMemberData = structuredClone(crewMemberData);
    let { newData, index } = e;
    originalCrewMemberData[index] = newData;
    dispatch(
      setUserCrewMember({
        crewMemberData: originalCrewMemberData,
        isLoading: true,
      })
    );
    const editedResponse = await addEditCrewMember(
      userId,
      selectedCrew?.crew_id,
      newData
    );
    if (editedResponse?.statusCode === 200) {
      toast.current.show({
        severity: 'success',
        summary: editedResponse?.message,
        life: 2000,
      });
    } else {
      toast.current.show({
        severity: 'error',
        summary: 'something went wrong',
        life: 2000,
      });
    }
    fetchInitiationData();
    dispatch(setUserCrewMember({ isLoading: false }));
    disableEditButton(false);
    setIsDisableAddButton(false);
  };

  // Delete selected data
  const handleDelete = async id => {
    if (id) {
      dispatch(setUserCrewMember({ isLoading: true }));
      const deleteResponse = await removeUserCrewMemberDetailById(
        userId,
        selectedCrew?.crew_id,
        id
      );
      if (deleteResponse?.statusCode === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Crew Member deleted successfully.',
          life: 2000,
        });
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Something went wrong',
          life: 2000,
        });
        return;
      }
    }
    fetchInitiationData();
  };

  const handleDeleteCancel = () => {
    dispatch(setUserCrewMember({ isDeleteDialogOpen: false }));
    disableEditButton(false);
  };

  const handleDeleteDialogHide = () => {
    dispatch(setUserCrewMember({ isDeleteDialogOpen: false }));
    disableEditButton(false);
  };

  const handleOnRowEditCancel = async rowData => {
    dispatch(
      setUserCrewMember({
        isDeleteDialogOpen: true,
        crewMemberIdToDelete: rowData?.member_id,
      })
    );
    showConfirmationDialog({
      message:
        'Please confirm if you would like to cancel your unsaved changes or delete this record?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => handleDelete(rowData?.member_id),
      reject: handleDeleteCancel,
      onHide: handleDeleteDialogHide,
      rejectLabel: 'Cancel',
      acceptLabel: 'Delete',
      acceptIcon: 'pi pi-trash',
    });
  };

  const disableEditButton = status => {
    setTimeout(() => {
      const edit = domHandlerCall('p-row-editor-init', addressSection.current);
      edit?.handler?.forEach(row => {
        row.disabled = status;
      });
      setIsDisableAddButton(status);
    }, 300);
  };
  // Helper to show input field
  const textEditor = options => {
    let label = '';
    switch (options?.field) {
      case 'email':
        label = 'Email';
        break;
      case 'digital_badge_id':
        label = 'Digital Badge ID';
        break;
      case 'name':
        label = 'Name';
        break;
      default:
        label = 'Verification ID';
        break;
    }
    return (
      <span className="p-float-label">
        <InputText
          type="text"
          className="p-inputtext-sm w-10rem"
          value={options.value}
          onChange={e => options.editorCallback(e.target.value)}
        />
        <label htmlFor="lead">
          {label}
          {options?.field !== 'verification_id' && (
            <i className="text-red-400">*</i>
          )}
        </label>
      </span>
    );
  };

  // Helper to show date field
  const dateEditor = options => {
    return (
      <span className="p-float-label">
        <Calendar
          value={options?.value ? new Date(options.value) : null}
          onChange={e =>
            options.editorCallback(dayjs(e?.value).format('YYYY-MM-DD'))
          }
          inputClassName="p-inputtext-sm w-10rem"
        />
        <label htmlFor="lead">
          Date of Joining<i className="text-red-400">*</i>
        </label>
      </span>
    );
  };

  // Helper to show phone field
  const phoneEditor = options => {
    return (
      <span className="p-float-label">
        <InputMask
          mask="(999) 999-9999"
          value={options.value}
          onChange={e => options.editorCallback(e.target.value)}
        />
        <label htmlFor="lead">
          Contact Number<i className="text-red-400">*</i>
        </label>
      </span>
    );
  };

  // Helper to show member status field
  const statusEditor = options => {
    return (
      <span className="p-float-label">
        <Dropdown
          className="p-inputtext-sm w-full p-0"
          value={options.value === 'On Leave' ? 'on_leave' : options.value}
          optionLabel="value"
          optionValue="status"
          options={MemberStatusOptions}
          onChange={e => options.editorCallback(e.target.value)}
          pt={{
            input: { className: 'py-2' },
            item: { className: 'py-2 px-1' },
          }}
        ></Dropdown>
        <label htmlFor="lead">
          Member Status<i className="text-red-400">*</i>
        </label>
      </span>
    );
  };

  //Helper to clear filter
  const clearFilters = () => {
    setGlobalFilterValue('');
    setTableDataState({
      limit: 10,
      page: 1,
      sortField: 'member_id',
      sortOrder: 1,
    });
  };
  // Helper to export CSV file
  const exportCSV = selectionOnly => {
    dt.current.exportCSV({ selectionOnly });
    exportOverlayRef?.current?.hide();
  };

  const cols = [
    { field: 'name', header: 'Name' },
    { field: 'digital_badge_id', header: 'Digital Badge ID' },
    { field: 'verification_id', header: 'Verification ID' },
    { field: 'email', header: 'Email' },
    { field: 'phone', header: 'Contact Number' },
    { field: 'doj', header: 'Date of Joining' },
  ];

  const exportColumns = useMemo(
    () => cols.map(col => ({ title: col.header, dataKey: col.field })),
    []
  );

  // Helper to export pdf file
  const exportPdf = () => {
    import('jspdf').then(jsPDF => {
      import('jspdf-autotable').then(() => {
        const doc = new jsPDF.default(0, 0);

        doc.autoTable(exportColumns, crewMemberData);
        doc.save('crewMemberData.pdf');
        exportOverlayRef?.current?.hide();
      });
    });
  };
  const debouncedOnChange = useCallback(debounce(fetchInitiationData, 500), []);

  // To render header
  const renderHeader = () => {
    return (
      <div className="flex justify-content-between">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            className="p-inputtext-sm"
            placeholder="Search keyword"
            value={globalFilterValue}
            onChange={e => {
              setGlobalFilterValue(e.target.value);
              debouncedOnChange(e.target.value);
            }}
          />
        </span>
        <div className="flex gap-4 align-items-center">
          <Button
            label="Clear"
            icon="pi pi-filter-slash"
            iconPos="left"
            outlined
            size="small"
            onClick={e => clearFilters(e)}
          />
          <div className="icon-round">
            <i
              role="button"
              tabIndex={0}
              className="icon pi pi-download cursor-pointer"
              onClick={e => exportOverlayRef?.current?.toggle(e)}
              onKeyDown={e => {
                if (e.key === 'Enter' || e.key === ' ') {
                  exportOverlayRef?.current?.toggle(e);
                }
              }}
            />
            <OverlayPanel ref={exportOverlayRef}>
              <div className="flex flex-column gap-2">
                <Button
                  color="primary"
                  outlined
                  onClick={() => exportCSV(false)}
                >
                  Export in CSV
                </Button>

                <Button color="primary" outlined onClick={exportPdf}>
                  Export in PDF
                </Button>
              </div>
            </OverlayPanel>
          </div>
          <div className="icon-round">
            <i
              role="button"
              tabIndex={0}
              className="icon pi pi-refresh cursor-pointer"
              onClick={() => fetchInitiationData()}
              onKeyDown={e => {
                if (e?.key === 'Enter' || e?.key === ' ') {
                  fetchInitiationData();
                }
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const header = renderHeader();

  const rowEditorWithCustomButton = (rowData, options) => {
    return (
      <div className="flex justify-content-center align-items-center flex-wrap gap-2">
        {!options.rowEditor.editing && (
          <PFButton
            icon="pi pi-trash"
            outlined
            text
            className="text-white border-none px-0 p-button-danger"
            severity="primary"
            aria-label="Edit"
            onClick={() => handleOnRowEditCancel(rowData)}
          />
        )}
      </div>
    );
  };

  return (
    <>
      <div ref={addressSection}>
        <div className="flex flex-row justify-content-between align-items-center p-3">
          <div className="text-l font-semibold">Crew Members</div>
          <Button
            label="Add Member"
            size="small"
            onClick={handleAddNewRow}
            disabled={isDisableAddButton}
            outlined
          />
        </div>
        <DataTable
          ref={dt}
          // loading={isLoading}
          value={crewMemberData}
          header={header}
          editMode="row"
          onRowEditComplete={onRowEditComplete}
          onRowEditCancel={() => {
            disableEditButton(false);
            // handleDeleteCancel(e?.index);
          }}
          rowEditValidator={handleRowValidation}
          onRowEditInit={() => {
            disableEditButton(true);
          }}
          lazy
          dataKey="member_id"
          paginator
          totalRecords={crewMemberDataCount}
          onSort={event =>
            setTableDataState(prev => ({
              ...prev,
              sortField: event?.sortField,
              sortOrder: event?.sortOrder,
            }))
          }
          page={tableDataState.page}
          rows={tableDataState.limit}
          first={(tableDataState.page - 1) * tableDataState.limit}
          onPage={handleOnPage}
          sortField={tableDataState?.sortField}
          sortOrder={tableDataState?.sortOrder}
          rowsPerPageOptions={[10, 20, 50, 100]}
          onFilter={() => {
            setTableDataState({
              limit: tableDataState.limit,
              page: 1,
              sortField: 'member_id',
              sortOrder: 1,
            });
          }}
        >
          {isLoading ? (
            <Column
              bodyStyle={{ textAlign: 'center' }}
              headerStyle={{ width: '10%', minWidth: '8rem' }}
              body={() => (isLoading ? <Skeleton /> : null)}
            ></Column>
          ) : (
            <Column
              rowEditor
              bodyStyle={{ textAlign: 'center' }}
              headerStyle={{ width: '10%', minWidth: '8rem' }}
            ></Column>
          )}
          {!isLoading && (
            <Column
              rowEditor={true}
              headerStyle={{ width: '15%', minWidth: '10rem' }}
              bodyStyle={{ textAlign: 'center' }}
              body={rowEditorWithCustomButton}
            />
          )}
          <Column
            field="name"
            header="Name"
            editor={options => textEditor(options)}
            body={params => (isLoading ? <Skeleton /> : params?.name)}
            sortable
            headerStyle={{ width: '15%', minWidth: '12rem' }}
            bodyStyle={{ textAlign: 'left' }}
          />
          <Column
            field="digital_badge_id"
            header="Digital Badge ID"
            editor={options => textEditor(options)}
            body={params =>
              isLoading ? <Skeleton /> : params?.digital_badge_id
            }
            sortable
            headerStyle={{ width: '15%', minWidth: '12rem' }}
            bodyStyle={{ textAlign: 'left' }}
          />
          <Column
            field="verification_id"
            header="Verification ID"
            editor={options => textEditor(options)}
            body={params =>
              isLoading ? <Skeleton /> : params?.verification_id
            }
            sortable
            headerStyle={{ width: '15%', minWidth: '12rem' }}
            bodyStyle={{ textAlign: 'left' }}
          />
          <Column
            field="email"
            header="Email"
            editor={options => textEditor(options)}
            body={params => (isLoading ? <Skeleton /> : params?.email)}
            sortable
            headerStyle={{ width: '15%', minWidth: '12rem' }}
            bodyStyle={{ textAlign: 'left' }}
          />
          <Column
            field="phone"
            header="Contact Number"
            editor={options => phoneEditor(options)}
            body={params => (isLoading ? <Skeleton /> : params?.phone)}
            sortable
            headerStyle={{ width: '15%', minWidth: '12rem' }}
            bodyStyle={{ textAlign: 'left' }}
          />
          <Column
            field="doj"
            header="Date of Joining"
            editor={options => dateEditor(options)}
            body={params =>
              isLoading ? (
                <Skeleton />
              ) : params?.doj ? (
                dayjs(params?.doj).format('MM-DD-YYYY')
              ) : (
                ''
              )
            }
            sortable
            headerStyle={{ width: '15%', minWidth: '12rem' }}
            bodyStyle={{ textAlign: 'left' }}
          />
          <Column
            field="status"
            header="Member Status"
            editor={options => statusEditor(options)}
            body={params => (isLoading ? <Skeleton /> : params?.status)}
            className="capitalize"
            sortable
            headerStyle={{ width: '15%', minWidth: '12rem' }}
            bodyStyle={{ textAlign: 'left' }}
          />
        </DataTable>
      </div>
      <Toast ref={toast} />
    </>
  );
};

export default UserFormCrewMember;
