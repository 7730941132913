import { encrypt } from '../../../../utils/security.helper';
import { bulkUpdateApiCall } from '../service/widget.service';

const TriggerApiCall = async ({
  updatedValue,
  columnReference,
  itemId,
  showAlert,
  refreshDashboardList,
}) => {
  const cellObj = {
    ...columnReference,
    value: updatedValue,
  };
  const finalPayload = {
    project_ids: [itemId?.toString()],
    editable_update: [cellObj],
  };

  const jsonStringify = JSON.stringify(finalPayload);
  try {
    const response = await bulkUpdateApiCall({
      update_encrypted_data: encrypt(jsonStringify),
    });
    if (response && response?.status === true) {
      showAlert('success', 'Success!', response?.message);
      refreshDashboardList();
    } else {
      showAlert('warn', 'Error!', response?.message);
    }
  } catch (error) {
    console.error(error);
  }
};

export default TriggerApiCall;
