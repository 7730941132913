import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  isLoading: true,
  listItems: [],
};

const InventoryTransferSlice = createSlice({
  name: 'InventoryTransfer',
  initialState: initialState,
  reducers: {
    transferClearState(state) {
      state.isLoading = true;
      state.listItems = [];
    },

    addTransfer(state, { payload }) {
      state.isLoading = Object.prototype.hasOwnProperty.call(
        payload,
        'isLoading'
      )
        ? payload.isLoading
        : state.isLoading;
      if (Object.prototype.hasOwnProperty.call(payload, 'item')) {
        state.listItems = payload?.item;
      }
    },
  },
});

export const { transferClearState, addTransfer } =
  InventoryTransferSlice.actions;

export const InventoryTransferReducer = InventoryTransferSlice.reducer;
