import React, { useState, useRef, FC } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { base64ToFile } from '../../../../utils/file.helper';
import ApiService from '../../../../services/api.service';
import { RotatePhotoProps } from '../interface/Photos.interface';

// interface RotatePhotoProps {
//   photoDetails: {
//     documentStoreType: string;
//     documentUrl: string;
//     documentName: string;
//     documentId: number;
//     projectId: number;
//   };
//   setPhotoDetailsToRotate: (param: null) => void;

//   setReloadList: React.Dispatch<React.SetStateAction<boolean>>;
// }

const ROTATION_DIRECTION = {
  CLOCK_WISE: 'clockwise',
  ANTI_CLOCK_WISE: 'anticlockwise',
};
const RotatePhotoComponent: FC<RotatePhotoProps> = ({
  photoDetails,
  setPhotoDetailsToRotate,
  setReloadList,
}) => {
  const [rotation, setRotation] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const rotateImage = (direction: 'clockwise' | 'anticlockwise') => {
    setRotation(
      prev => prev + (direction === ROTATION_DIRECTION.CLOCK_WISE ? 90 : -90)
    );
  };

  const getRotatedImage = async (): Promise<string | null> => {
    if (!photoDetails.documentUrl || !canvasRef.current) return null;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    if (!ctx) return null;

    const response = await fetch(photoDetails.documentUrl, {
      mode: 'cors',
      cache: 'no-cache',
    });
    const blob = await response.blob();
    const imageUrl = URL.createObjectURL(blob);
    const img = new Image();
    img.src = imageUrl;
    return new Promise(resolve => {
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        canvas.width = rotation % 180 === 0 ? width : height;
        canvas.height = rotation % 180 === 0 ? height : width;
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.save();
        ctx.translate(canvas.width / 2, canvas.height / 2);
        ctx.rotate((rotation * Math.PI) / 180);
        ctx.drawImage(img, -width / 2, -height / 2);
        ctx.restore();
        resolve(canvas.toDataURL('image/png'));
      };
      img.onerror = () => resolve(null);
    });
  };

  const handleSave = async () => {
    setLoading(true);
    const rotatedImage = await getRotatedImage();
    if (rotatedImage) {
      try {
        const body = new FormData();
        body.append(
          'file',
          base64ToFile(rotatedImage, photoDetails.documentName)
        );
        body.append('document_store_type', photoDetails.documentStoreType);
        await ApiService.patch(
          `/projects/${photoDetails.projectId}/documents/${photoDetails.documentId}`,
          body
        );

        setTimeout(() => {
          setPhotoDetailsToRotate(null);
          setReloadList((pre: boolean) => !pre);
        });
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };
  const footerContent = () => {
    return (
      <div className="flex justify-content-end flex-wrap my-2">
        <Button
          label="Rotate Left"
          icon="pi pi-replay"
          iconPos="left"
          onClick={() => rotateImage('anticlockwise')}
          text
          className="mx-1"
          size="small"
          disabled={loading}
        />
        <Button
          label="Rotate Right"
          icon="pi pi-refresh "
          iconPos="left"
          onClick={() => rotateImage('clockwise')}
          text
          className="mx-1"
          size="small"
          disabled={loading}
        />
        <Button
          label="Save"
          icon={loading ? `pi pi-spin pi-spinner` : `pi pi-save`}
          iconPos="left"
          onClick={handleSave}
          className="mx-1"
          size="small"
          disabled={loading}
        />
      </div>
    );
  };
  return (
    <div className="card flex justify-content-center">
      <Dialog
        header={`Rotate:  ${photoDetails.documentName}`}
        visible={true}
        onHide={() => setPhotoDetailsToRotate(null)}
        style={{ width: '70vw' }}
        footer={footerContent}
      >
        <div>
          <div className="flex justify-content-center">
            <canvas ref={canvasRef} style={{ display: 'none' }} />
            <img
              src={photoDetails.documentUrl}
              alt="Uploaded"
              style={{
                transform: `rotate(${rotation}deg)`,
                maxWidth: '100%',
                transition: 'transform 0.3s ease-in-out',
              }}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default RotatePhotoComponent;
