// **** External Utilities ****
import axios from 'axios';

import { URL_CONSTANTS } from '../../../constants/urlConstants';

// Get Permitting Data
export const getPermittingData = async (
  projectId,
  setLoading,
  setPermittingResponse
) => {
  try {
    setLoading(true);
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/projects/${projectId}/permits`
    );

    setPermittingResponse(response.data);
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
};

// ************* Permitting *************

//Add Permit
export const createPermit = async (
  projectId,
  values,
  setLoading,
  setAlert,
  setReloadList,
  setOpen,
  resetForm
) => {
  try {
    setOpen(false);
    setLoading(true);
    const response = await axios.post(
      `${URL_CONSTANTS.API.BASE_URL}/projects/${projectId}/permits`,
      values
    );
    if (response.status === 200) {
      resetForm();
      setReloadList(true);
      setAlert('success', 'Permit added successfully.', false, true);
    }
  } catch (error) {
    console.error(error);
    setAlert('error', 'Error in Permit creation.', false, true);
    setLoading(false);
  }
};

//Update Permit
export const updatePermit = async (
  projectId,
  permitId,
  values,
  setLoading,
  setAlert,
  setReloadList,
  setOpen,
  resetForm
) => {
  try {
    setLoading(true);
    setOpen(false);
    const response = await axios.patch(
      `${URL_CONSTANTS.API.BASE_URL}/projects/${projectId}/permits/${permitId}`,
      values
    );
    if (response.status === 200) {
      resetForm();
      setAlert('success', 'Permit updated successfully.', false, true);
      setReloadList(true);
    }
  } catch (error) {
    console.error(error);
    setAlert('error', 'Error in updating Permit.', false, true);
    setLoading(false);
  }
};

//Delete Permit
export const deletePermit = async (
  projectId,
  permitId,
  setLoading,
  setAlert,
  setReloadList,

  setConfirmDialog
) => {
  try {
    setLoading(true);
    setConfirmDialog(prevState => ({
      ...prevState,
      isOpen: false,
    }));

    const response = await axios.delete(
      `${URL_CONSTANTS.API.BASE_URL}/projects/${projectId}/permits/${permitId}`
    );
    if (response) {
      setReloadList(true);
      setAlert('success', 'Permit deleted successfully.', false, true);
    }
  } catch (error) {
    console.error(error);
    setAlert('error', 'Error in Deleting Permit', false, true);
    setLoading(false);
  }
};
// Get Notes Data
export const getPermitStatus = async (
  projectId,
  setLoading,
  setPermitOptions
) => {
  try {
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/projects/${projectId}/permits/status/list`
    );
    setPermitOptions(response?.data);
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
};
