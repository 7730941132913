import React, { useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import type { ColumnBodyOptions } from 'primereact/column';
import { Button } from 'primereact/button';
import { Skeleton } from 'primereact/skeleton';
import { Toast } from 'primereact/toast';

import { useConfirmDialogContext } from '../../../../contexts/ConfirmDialog';
import permissions from '../../../../config/permissions';
import { ColumnMeta, IssueItem } from '../Interface/issues.interface';
import { CURRENCY } from '../../../../constants/currency.constant';
import { renderDate } from '../../../../utils/dates.helper';
import { ACTION } from '../../../../constants/form-actions.constant';
import { deleteIssue } from '../service/issues.service';

import IssuesForm from './IssuesForm';

const IssuesTable: React.FC<{
  issuesList: IssueItem[] | undefined;
  project_id: number;
  toast: React.RefObject<Toast | null>;
  loadIssuesData: () => void;
  loading: boolean;
}> = ({ issuesList, project_id, toast, loadIssuesData, loading }) => {
  const [dialogFormObj, setDialogFormObj] = useState({
    visible: false,
    formAction: null as string | null,
    rowData: null as IssueItem | null,
  });
  const { showConfirmationDialog } = useConfirmDialogContext();
  const updateDialogFormObj = (newState: Partial<typeof dialogFormObj>) => {
    setDialogFormObj(prev => ({ ...prev, ...newState }));
  };
  const viewEditIssues = (formAction: string, rowData: IssueItem) => {
    updateDialogFormObj({
      visible: true,
      formAction: formAction,
      rowData: rowData,
    });
  };
  const handleAccept = async (projectIssueId: number) => {
    const response = await deleteIssue(project_id, projectIssueId);
    if (!response?.error) {
      toast.current?.show({
        severity: 'success',
        summary: 'Success',
        detail: response.message,
        life: 3000,
      });
      loadIssuesData();
    } else {
      toast.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: response?.message,
        life: 3000,
      });
    }
  };

  const deleteIssues = (projectIssueId: number) => {
    showConfirmationDialog({
      message: 'Are you sure you want to delete Issue ?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      defaultFocus: 'accept',
      accept: () => handleAccept(projectIssueId),
    });
  };
  const actionsTemplate = (rowData: IssueItem) =>
    !loading ? (
      <div className="inline-flex align-items-center">
        {permissions?.viewEditProject?.tabIssuesView && (
          <Button
            icon="pi pi-eye"
            outlined
            className="border-none px-0 text-900 w-2rem"
            onClick={() => viewEditIssues(ACTION.VIEW, rowData)}
            type="button"
          />
        )}
        {permissions?.viewEditProject?.tabIssuesEdit && (
          <Button
            icon="pi pi-pencil"
            outlined
            className="border-none px-0 text-900 w-2rem"
            onClick={() => viewEditIssues(ACTION.EDIT, rowData)}
            type="button"
          />
        )}
        {permissions?.viewEditProject?.tabIssuesDelete && (
          <Button
            icon="pi pi-trash"
            outlined
            className="border-none px-0 text-900 w-2rem"
            onClick={() =>
              rowData?.project_issue_id &&
              deleteIssues(rowData?.project_issue_id)
            }
            type="button"
          />
        )}
      </div>
    ) : null;

  // Dynamic body template
  const dynamicBodyTemplate = (
    rowData: IssueItem,
    options: ColumnBodyOptions
  ): React.ReactNode => {
    if (loading) {
      return <Skeleton width="100%" height="1.5rem" />;
    }
    if (options.field === 'actions') {
      return actionsTemplate(rowData);
    }
    if (options.field === 'date_created' || options.field === 'modified_at') {
      return renderDate(rowData[options.field], 'MM-dd-yyyy hh:mm a');
    }
    if (options.field === 'category') {
      return rowData?.issue_category?.category;
    }
    if (options.field === 'status') {
      return rowData?.issues_status?.status;
    }

    if (
      options.field === 'dollar_amt' ||
      options.field === 'issue_total_amount'
    ) {
      return rowData[options.field] && !isNaN(Number(rowData[options.field]))
        ? CURRENCY.DOLLAR + Number(rowData[options.field]).toFixed(2)
        : '';
    }
    if (options.field === 'modified_by') {
      return rowData?.modifiedby
        ? rowData?.modifiedby?.first_name + ' ' + rowData?.modifiedby?.last_name
        : '-';
    }
    // Default rendering for other fields
    const value = rowData[options.field as keyof IssueItem];
    return typeof value === 'string' || typeof value === 'number'
      ? value
      : null;
  };

  const columns: ColumnMeta[] = [
    {
      field: 'actions',
      header: '',
      headerStyle: { minWidth: '120px' },
    },
    {
      field: 'date_created',
      header: 'Date Created',
      headerStyle: { minWidth: '180px' },
    },
    {
      field: 'originated_from',
      header: 'Originated From',
      headerStyle: { minWidth: '150px' },
    },
    {
      field: 'employee',
      header: 'Employee',
      headerStyle: { minWidth: '150px' },
    },
    {
      field: 'store_associate_name',
      header: 'Store Associate Name',
      headerStyle: { minWidth: '150px' },
    },
    {
      field: 'dollar_amt',
      header: 'Dollar Amount',
    },
    {
      field: 'description',
      header: 'Description',
      headerStyle: { minWidth: '150px' },
    },
    {
      field: 'category',
      header: 'Category',
      headerStyle: { minWidth: '150px' },
    },
    {
      field: 'status',
      header: 'Status',
    },
    {
      field: 'quantity',
      header: 'Quantity',
    },
    {
      field: 'issue_total_amount',
      header: 'Issue Total $',
    },
    {
      field: 'modified_by',
      header: 'Modified By',
      headerStyle: { minWidth: '150px' },
    },
    {
      field: 'modified_at',
      header: 'Modified At',
      headerStyle: { minWidth: '180px' },
    },
  ];
  const header = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <h3 className="text-base font-bold">Issues</h3>
      {permissions?.viewEditProject?.tabIssuesAdd && (
        <Button
          label="Add Item"
          size="small"
          type="button"
          onClick={() => {
            updateDialogFormObj({
              visible: true,
              formAction: 'add',
              rowData: null,
            });
          }}
          className="p-button-outlined bg-reverse-primary flex justify-content-end"
        />
      )}
    </div>
  );

  return (
    <div className="col-12 mt-4">
      <DataTable value={issuesList} header={header}>
        {columns.map(col => (
          <Column
            key={col.field}
            field={col.field}
            header={col.header}
            body={dynamicBodyTemplate}
            headerStyle={col.headerStyle}
          />
        ))}
      </DataTable>
      <IssuesForm
        dialogFormObj={dialogFormObj}
        updateDialogFormObj={updateDialogFormObj}
        project_id={project_id}
        loadIssuesData={loadIssuesData}
        toast={toast}
      />
    </div>
  );
};

export default IssuesTable;
