import { CONFIG } from '../../../constants';
const pageMetaConstants = {
  baseUrl: CONFIG.API_BASE_URL,

  getPageMetaEndpoint: () => {
    return `overview/page-identifier`;
  },
  getPageMetaUpdatesEndpoint: (offset, limit) => {
    return `overview/?offset=${offset}&limit=${limit}`;
  },
  putEditPageMetaEndpoint: () => {
    return `overview/page-identifier`;
  },
};
export default pageMetaConstants;
