import { useState, useEffect } from 'react';
import { Grid, TextField, Typography } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { Autocomplete } from '@material-ui/lab';

import { ORDER_BY } from '../constant/constant';

const WidgetDetails = ({
  dimensionList,
  measureList,
  action,
  widgetFormik,
  tableList,
  setSelectedChartTableValue,
  selectedChartTable,
  route_widget,
}) => {
  const [selectedMeasures, setSelectedMeasures] = useState([]);

  const initiateData = () => {
    const selectedDimension = measureList?.find(
      a => a.name == widgetFormik.values.chart_configuration.dimension
    );
    if (selectedDimension?.measures) {
      setSelectedMeasures(selectedDimension.measures);
    }
  };

  useEffect(() => {
    if (action !== 'create') {
      initiateData();
    }
  }, []);

  return (
    <>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12} md={4}>
          <Typography variant="h3">Chart Configuration</Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={3} className="mt-3">
          <Autocomplete
            fullWidth
            options={tableList}
            getOptionLabel={option => option && option.value}
            renderInput={params => (
              <TextField {...params} label="Select Table" required />
            )}
            disabled={action === 'view' || route_widget ? true : false}
            value={tableList.find(item => item.value == selectedChartTable)}
            onChange={(event, value) => {
              if (value?.value) {
                widgetFormik.setTouched({
                  ...widgetFormik.touched,
                  chart_configuration: true,
                });
              } else {
                widgetFormik.setTouched({
                  ...widgetFormik.touched,
                  chart_configuration: false,
                });
              }
              setSelectedChartTableValue(value?.value || '', {
                ...widgetFormik.values,
                custom_properties: {
                  ...widgetFormik.values.custom_properties,
                  selectedChartTable: value?.value || '',
                },
              });
            }}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={1}>
        <Grid item xs={12} md={6} lg={2} className="mt-3">
          <Autocomplete
            id="chartType"
            key={selectedChartTable}
            fullWidth
            groupBy={option => option.firstLetter}
            options={measureList}
            getOptionLabel={option => option && option.value}
            renderInput={params => (
              <TextField {...params} label="Field" required />
            )}
            disabled={action === 'view' ? true : false}
            value={measureList?.find(
              a => a.name == widgetFormik.values.chart_configuration.dimension
            )}
            onChange={(event, value) => {
              if (value?.name) {
                widgetFormik.setTouched({
                  ...widgetFormik.touched,
                  chart_configuration: true,
                });
              } else {
                widgetFormik.setTouched({
                  ...widgetFormik.touched,
                  chart_configuration: false,
                });
              }
              setSelectedMeasures(value?.measures);
              let isPrimaryKeySelected = '';
              if (value?.name && value?.primaryKey) {
                isPrimaryKeySelected = value.name == value.primaryKey;
              }
              let summarizedBy = '';
              if (isPrimaryKeySelected) {
                summarizedBy = value?.primaryKeyMeasures?.find(
                  mKey => mKey.aggType == 'count'
                )?.name;
              }
              widgetFormik.setFieldValue('chart_configuration', {
                ...widgetFormik.values.chart_configuration,
                dimension: value?.name,
                // measure: value?.primaryKey || '',
                measureSummarizedBy: summarizedBy,
                isPrimaryKeySelected: isPrimaryKeySelected,
              });
              widgetFormik.setFieldValue('custom_properties', {
                ...widgetFormik.values.custom_properties,
                chartMeta: value?.meta || null,
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={2} className="mt-3">
          <Autocomplete
            id="dimensionMeasures"
            name="dimensionField"
            key={Math.random()}
            options={selectedMeasures || []}
            disabled={action === 'view' ? true : false}
            getOptionLabel={option => option.aggType}
            renderInput={params => (
              <TextField {...params} variant="standard" label="Summarized By" />
            )}
            value={
              selectedMeasures?.find(
                a =>
                  a.name ==
                  widgetFormik.values.chart_configuration.measureSummarizedBy
              ) || ''
            }
            onChange={(event, value) => {
              widgetFormik.setFieldValue('chart_configuration', {
                ...widgetFormik.values.chart_configuration,
                measureSummarizedBy: value?.name || '',
              });
              widgetFormik.setFieldValue('custom_properties', {
                ...widgetFormik.values.custom_properties,
                chartMeta: widgetFormik.values?.custom_properties?.chartMeta
                  ? {
                      ...widgetFormik.values.custom_properties.chartMeta,
                      aggType: value.aggType,
                    }
                  : null,
              });
            }}
          />
        </Grid>
        {widgetFormik.values.widget_type !== 'gauge' &&
          widgetFormik.values.widget_type !== 'card' && (
            <>
              <Grid item xs={12} md={4} lg={3} className="mt-3">
                <Autocomplete
                  id="groupBy"
                  name="groupBy"
                  multiple={true}
                  key={widgetFormik.values.chart_configuration.groupBy}
                  options={dimensionList}
                  disabled={action === 'view' ? true : false}
                  getOptionLabel={option => option && option.value}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Group By"
                    />
                  )}
                  value={widgetFormik.values.chart_configuration.groupBy}
                  onChange={(event, value) => {
                    widgetFormik.setFieldValue('chart_configuration', {
                      ...widgetFormik.values.chart_configuration,
                      groupBy: value || [],
                      orderByField: value?.length ? value[0]?.name : '',
                    });
                  }}
                />
              </Grid>
              {widgetFormik.values?.chart_configuration?.groupBy?.length >
                0 && (
                <>
                  <Grid item xs={12} md={4} lg={2} className="mt-3">
                    <Autocomplete
                      id="chartOrderByField"
                      name="chartOrderByField"
                      key={
                        widgetFormik.values?.chart_configuration?.orderByField
                      }
                      options={dimensionList.filter(orderByObject => {
                        const foundObject =
                          widgetFormik.values.chart_configuration.groupBy.find(
                            groupKey => groupKey.name == orderByObject.name
                          );
                        if (foundObject) {
                          return orderByObject;
                        }
                      })}
                      disabled={action === 'view' ? true : false}
                      getOptionLabel={option => option && option.value}
                      renderInput={params => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Order By Field"
                          required
                        />
                      )}
                      value={dimensionList?.find(
                        a =>
                          a.name ==
                          widgetFormik.values?.chart_configuration?.orderByField
                      )}
                      onChange={(event, value) => {
                        widgetFormik.setFieldValue('chart_configuration', {
                          ...widgetFormik.values.chart_configuration,
                          orderByField: value?.name || '',
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} lg={2} className="mt-3">
                    <Autocomplete
                      id="chartOrderBy"
                      name="chartOrderBy"
                      key={widgetFormik.values.chart_configuration.orderBy}
                      options={ORDER_BY}
                      disabled={action === 'view' ? true : false}
                      getOptionLabel={option => option && option.value}
                      renderInput={params => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Order By"
                          required
                        />
                      )}
                      value={ORDER_BY.find(
                        record =>
                          record.name ==
                          widgetFormik.values.chart_configuration.orderBy
                      )}
                      onChange={(event, value) => {
                        widgetFormik.setFieldValue('chart_configuration', {
                          ...widgetFormik.values.chart_configuration,
                          orderBy: value?.name || '',
                        });
                      }}
                    />
                  </Grid>
                </>
              )}
            </>
          )}

        {widgetFormik.values.widget_type == 'gauge' && (
          <Grid item xs={4}>
            <NumberFormat
              id="customProperties.target"
              disabled={action === 'view' ? true : false}
              allowNegative={false}
              label={`Target`}
              customInput={TextField}
              inputMode="numeric"
              required
              inputProps={{ maxLength: 10 }}
              name="measureTarget"
              onChange={event => {
                widgetFormik.setFieldValue('chart_configuration', {
                  ...widgetFormik.values.chart_configuration,
                  measureTarget: event.target.value,
                });
              }}
              value={widgetFormik.values?.chart_configuration.measureTarget}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default WidgetDetails;
